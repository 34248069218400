<template>
  <InventoryHeader />

  <section class="section">
    <div class="border-box">
      <AddInventory v-if="((addInventoryEditionMode === 'create') || (addInventoryEditionMode === 'edit')) && (isAuth('add-new-inventory') || isAuth('edit-inventory'))" />
      <AddInventoryFilled v-if="(addInventoryEditionMode === 'represent') && addedInventories.length > 0" />

      <InventoryAddList />
    </div>
  </section>

  <section class="section">
    <div class="border-box">
      <InventoryUsed v-if="((inventoryUsedEditionMode === 'create') || (inventoryUsedEditionMode === 'edit')) && (isAuth('add-new-inventory-used') || isAuth('edit-inventory-used'))" />
      <InventoryUsedFilled v-if="(inventoryUsedEditionMode === 'represent') && inventoriesUsed.length > 0" />

      <InventoryUsedList />
    </div>
  </section>

  <section class="section">
    <div class="border-box">
      <InventoryStatus />
      <!--  v-if="(statusEditionMode === 'create') || (statusEditionMode === 'edit')" -->
      <!-- <InventoryStatusFilled v-else /> -->
    </div>
  </section>

</template>

<script>
import { reactive, provide, ref, inject, computed } from 'vue';
import { useStore } from "vuex";
import InventoryHeader from './InventoryHeader.vue';
import AddInventory from './AddInventory.vue';
import AddInventoryFilled from './AddInventoryFilled.vue';
import InventoryAddList from './InventoryAddList.vue';
import InventoryUsed from './InventoryUsed.vue';
import InventoryUsedFilled from './InventoryUsedFilled.vue';
import InventoryUsedList from './InventoryUsedList.vue';
import InventoryStatus from './InventoryStatus.vue';
// import InventoryStatusFilled from './InventoryStatusFilled.vue'
import { useRouter } from 'vue-router';

export default {
  name: "InventoryModule",
  components: { InventoryHeader, AddInventory, AddInventoryFilled, InventoryAddList,
  InventoryUsed, InventoryUsedFilled, InventoryUsedList, InventoryStatus, 
  // InventoryStatusFilled 
  },
  setup() {

    const store = useStore();
    const router = useRouter();

    const { isAuth } = inject('mainUI');

    const addInventoryEditionMode = ref('');
    const inventoryUsedEditionMode = ref('');
    const statusEditionMode = ref('create');

    const addedInventories = computed(() => [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.type === 'Product'));
    const inventoriesUsed = computed(() => [...store.state.tasks].filter(stateTask => stateTask.inputTask && (stateTask.taskStatus === 'Completed')));

    const vendors = computed(() => store.state.vendors);

    const addedInventory = reactive({
      id: '',
      item: '',
      manufacturer: '',
      brand: '',
      variety: '',

      numberOfItems: '',
      quantity: '',
      date: '',
      invoice: '',
      amountPaid: '',
      warrantyExp: '',
      
      vendor: '',
      vendorName: '',
      vendorPhone: '',
      vendorContact: '',
      comments: ''
    });

    const inventoryUsed = reactive({
      id: '',
      date: '',
      input: '',
      brand: '',
      variety: '',
      numberOfItems: '',
      // quantity: '',
      quantity: '',
      task: '',
      assignedTo: '',
      farm: '',
      bed: '',
      season: '',
      cropCycle: '',
      crop: ''
    });

    const selectedAddedInventory = reactive({
      ...addedInventory
    });

    const defaultAddedInventory = {
      ...addedInventory
    }

    const selectedInventoryUsed = reactive({
      ...inventoryUsed
    });

    const defaultInventoryUsed = {
      ...inventoryUsed
    }

    const addNewInventory = () => {
      router.push({
        name: 'vendors', 
        params: {
          pagename: 'vendor-module',
          source: 'inventory-module'
        }
      })
    }

    const editAddedInventory = (field, val) => {
      addedInventory[field] = val;
    }

    const removeAddedInventory = (addedInventoryId) => {
      store.dispatch('removeVendorItem', addedInventoryId)
    };

    const selectAddedInventory = (addedInventoryData, editMode) => {
      if (addedInventoryData) {
        for (let field in addedInventoryData) {
          addedInventory[field] = addedInventoryData[field]
          selectedAddedInventory[field] = addedInventoryData[field]
        }
      }
      addInventoryEditionMode.value = editMode;

      const selectedVendor = vendors.value.find(stateVendor => stateVendor.id === addedInventoryData.vendor);

      addedInventory.vendorContact = selectedVendor.vendorContact;
      selectedAddedInventory.vendorContact = selectedVendor.vendorContact;
      addedInventory.vendorName = selectedVendor.vendor;
      selectedAddedInventory.vendorName = selectedVendor.vendor;
      addedInventory.vendorPhone = selectedVendor.vendorPhone;
      selectedAddedInventory.vendorPhone = selectedVendor.vendorPhone;
    }

    const addInventoryUsed = () => {
      selectInventoryUsed(defaultInventoryUsed, 'create');
      // editionMode.value = 'create';
    }

    const editInventoryUsed = (field, val) => {
      inventoryUsed[field] = val;
    }

    const removeInventoryUsed = (inventoryUsedId) => {
      store.dispatch('removeInventoryUsed', inventoryUsedId)
    };


    const selectInventoryUsed = (inventoryUsedData, editMode) => {
      if (inventoryUsedData) {
        for (let field in inventoryUsedData) {
          inventoryUsed[field] = inventoryUsedData[field]
          selectedInventoryUsed[field] = inventoryUsedData[field]
        }
      }
      inventoryUsedEditionMode.value = editMode;
    }

    provide('addedInventoryDetails', {
      addedInventory,
      defaultAddedInventory,
      selectedAddedInventory,
      addInventoryEditionMode,
      addNewInventory,
      editAddedInventory,
      selectAddedInventory,
      removeAddedInventory
    })

    provide('inventoryUsedDetails', {
      inventoryUsed,
      defaultInventoryUsed,
      selectedInventoryUsed,
      inventoryUsedEditionMode,
      addInventoryUsed,
      editInventoryUsed,
      selectInventoryUsed,
      removeInventoryUsed
    })

    return {
      addInventoryEditionMode,
      inventoryUsedEditionMode,
      statusEditionMode,

      addedInventory,
      inventoryUsed,
      isAuth,
      addedInventories,
      inventoriesUsed
    }
  }
}
</script>

<style></style>