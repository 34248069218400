<template>

<section class="section" ref="section">

<div class="border-box active">
<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Subscription Type</h5>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

  <div class="content-block">
  <div class="payment-details" style="position: relative;">

<div class="content-row d-flex align-items-start">

<div class="checkbox mh">
  <input type="checkbox" id="freeThirtyDay" v-model="subscription.plans.trial"><label for="freeThirtyDay">Free 30 Day Trial</label>
</div>
<!--  :disabled="subscription.subscriptionPeriod === 'Yearly'" -->

<div class="four-column-grid four-column-grid-form text-left" style="max-width: 70%;margin-left: auto;">
<div class="subscription-type-form-dropdown span-3">
<p class="form-label">Billing Type</p>
<!-- <select class="form-control" v-model="subscription.subscriptionPeriod">
<option>Monthly</option>
<option>Yearly</option>
</select> -->
<DropDownList
  :mainObject="'subscription'"
  :mainProperty="'subscriptionPeriod'"
  :injectionName="'subscription'"
  :optionKey="'period-option-subscription'"
  :optionsList="['Monthly', 'Yearly']"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="'Monthly'"
  :isDisabled="subscription.plans.trial"
/>
<p class="fs-xs">10% Discount on Yearly Subscription</p>
</div>
</div> 

</div>

<div class="content-row overflow-auto-mobile"> <!-- overflow-auto -->
<div class="four-column-grid text-left align-end four-column-grid-form">

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basic" v-model="subscription.plans.basic" />
    <label for="basic">Basic</label>
  </div>
  <!-- <p class="form-info red text-end error" 
    style="text-align: left;position: absolute;"
    v-if="activated.basic && !subscription.plans.basic"
  >* Required</p> -->
</div>
<div><p class="form-label active">Price per month</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input class="form-control" v-model="subscription.plans.basicPrice" type="number" step="0.01" disabled />
  </div>
</div>
<div  style="position: relative;"><p class="form-label active">No. of Subscriptions</p>
  <input type="number" class="form-control" 
    v-model="subscription.plans.basicNumber" 
    :disabled="!subscription.plans.basic" 
    @focus="() => {
      activated.basicNumber = true;
      focused.basicNumber = true;
    }"
    @blur="() => {
      focused.basicNumber = false;
    }"
  >
  <div v-if="!subscription.plans.basic" 
    class="form-control"
    style="position: absolute; top: 0; opacity: 0;"
    @click="() => {
      activated.basic = true;
    }"
  ></div>
  <p v-if="subscription.plans.basic && (activated.basicNumber && (Number.parseInt(subscription.plans.basicNumber || 0) <= 0))" class="form-info red text-end error">* Required</p>
  <p v-if="subscription.plans.basicNumber && ((subscription.plans.basicNumber < 0) || !Number.isInteger(subscription.plans.basicNumber))" class="form-info red text-end error">Please insert whole positive number</p>
</div>
<div><p class="form-label active">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control" 
      :value="subscription.plans.basic ? (Number.parseInt(subscription.plans.basicNumber || 0) * Number.parseInt(subscription.plans.basicPrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0" 
    />
  </div>
</div>

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basicPlus" v-model="subscription.plans.plus" />
    <label for="basicPlus">Basic +</label>
  </div>
  <!-- <p class="form-info red text-end error" 
    style="text-align: left;position: absolute;"
    v-if="activated.plus && !subscription.plans.plus"
  >* Required</p> -->
</div>
<div>
  <div class="dollar-prefix">
    <span>$</span>
    <input class="form-control" prefix="$"
      v-model="subscription.plans.plusPrice"
      placeholder="Price per month"
      type="number"
      step="0.01"
      disabled />
  </div>
</div>
<div style="position: relative;">
  <input type="number" class="form-control" 
    v-model="subscription.plans.plusNumber" 
    @focus="() => {
      activated.plusNumber = true;
      focused.plusNumber = true;
    }"
    @blur="() => {
      focused.plusNumber = false;
    }"
    :disabled="!subscription.plans.plus">
  <div v-if="!subscription.plans.plus" 
    class="form-control"
    style="position: absolute; top: 0; opacity: 0;"
    @click="() => {
      activated.plus = true;
    }"
  ></div>
  <p v-if="subscription.plans.plus && (activated.plusNumber && (Number.parseInt(subscription.plans.plusNumber || 0) <= 0))" class="form-info red text-end error">* Required</p>
  <p v-if="subscription.plans.plusNumber && ((subscription.plans.plusNumber < 0) || !Number.isInteger(subscription.plans.plusNumber))" class="form-info red text-end error">Please insert whole positive number</p>
</div>
<div>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control" 
      :value="subscription.plans.plus ? (Number.parseInt(subscription.plans.plusNumber || 0) * Number.parseInt(subscription.plans.plusPrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0"
    />
  </div>
</div>

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basicPlusAdvance" v-model="subscription.plans.advance">
    <label for="basicPlusAdvance">Basic + Advance</label>
  </div>
  <!-- <p class="form-info red text-end error" 
    style="text-align: left;position: absolute;"
    v-if="activated.advance && !subscription.plans.advance"
  >* Required</p> -->
</div>
<div>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control" 
      prefix="$"
      v-model="subscription.plans.advancePrice"
      placeholder="Price per month"
      step="0.01"
      disabled />
  </div>
</div>
<div style="position: relative;">
  <input type="number" class="form-control" 
    v-model="subscription.plans.advanceNumber"
    @focus="() => {
      activated.advanceNumber = true;
      focused.advanceNumber = true;
    }"
    @blur="() => {
      focused.advanceNumber = false;
    }"
    :disabled="!subscription.plans.advance"
    >
    <div v-if="!subscription.plans.advance" 
      class="form-control"
      style="position: absolute; top: 0; opacity: 0;"
      @click="() => {
        activated.advance = true;
      }"
    ></div>
    <p v-if="subscription.plans.advance && (activated.advanceNumber && (Number.parseInt(subscription.plans.advanceNumber || 0) <= 0))" class="form-info red text-end error">* Required</p>
    <p v-if="subscription.plans.advanceNumber && ((subscription.plans.advanceNumber < 0) || !Number.isInteger(subscription.plans.advanceNumber))" class="form-info red text-end error">Please insert whole positive number</p>
</div>
<div>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control"
      :value="subscription.plans.advance ? (Number.parseInt(subscription.plans.advanceNumber || 0) * Number.parseInt(subscription.plans.advancePrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0"
    />
  </div>
</div>

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basicPlusUltimate" v-model="subscription.plans.ultimate">
    <label for="basicPlusUltimate">Basic + Ultimate</label>
  </div>
  <!-- <p class="form-info red text-end error" 
    style="text-align: left;position: absolute;"
    v-if="activated.ultimate && !subscription.plans.ultimate"
  >* Required</p> -->
</div>
<div>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      prefix="$"
      v-model="subscription.plans.ultimatePrice"
      step="0.01"
      disabled
    />
  </div>
</div>
<div style="position: relative;">
  <input type="number" class="form-control" 
    v-model="subscription.plans.ultimateNumber"
    @focus="() => {
      activated.ultimateNumber = true;
      focused.ultimateNumber = true;
    }"
    @blur="() => {
      focused.ultimateNumber = false;
    }"
    :disabled="!subscription.plans.ultimate"
  >
  <div v-if="!subscription.plans.ultimate" 
    class="form-control"
    style="position: absolute; top: 0; opacity: 0;"
    @click="() => {
      activated.ultimate = true;
    }"
  ></div>
  <p v-if="subscription.plans.ultimate && (activated.ultimateNumber && (Number.parseInt(subscription.plans.ultimateNumber || 0) <= 0))" class="form-info red text-end error">* Required</p>
  <p v-if="subscription.plans.ultimateNumber && ((subscription.plans.ultimateNumber < 0) || !Number.isInteger(Number(subscription.plans.ultimateNumber)))" class="form-info red text-end error">Please insert whole positive number</p>
</div>
<div>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control"
      :value="subscription.plans.ultimate ? (Number.parseInt(subscription.plans.ultimateNumber || 0) * Number.parseInt(subscription.plans.ultimatePrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0"
    />
  </div>
</div>

</div>  
</div>

<div class="content-row overflow-auto subscription-result-wrap">
<div class="four-column-grid four-column-grid-form text-left">
<div class="text-center">
<p class="form-label active">Subscription Amount</p>
<div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control" 
      prefix="$"
      v-model="subscription.totalSubscription"
      step="0.01"
      @focus="ev => {
        formatValue(ev, 'totalSubscription', true)
        activated.totalSubscription = true;
        focused.totalSubscription = true;
      }"
      @blur="ev => {
        formatValue(ev, 'totalSubscription', false)
        focused.totalSubscription = false;
      }"
    />
  </div>
  <!-- <p v-if="activated.totalSubscription && (Number.parseInt(subscription.totalSubscription || 0) === 0)" class="form-info red text-end error">* Required</p> -->
</div>
<div class="text-center">
<p class="form-label active">Discount</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      v-model="subscription.discount"
      placeholder="Discount"
      step="0.01"
      prefix="$"
      @focus="ev => {
        formatValue(ev, 'discount', true);
        activated.discount = true;
        focused.discount = true;
      }"
      @blur="ev => {
        formatValue(ev, 'discount', false);
        focused.discount = false;
      }"
    />
  </div>
  <!-- <p v-if="activated.discount && (Number.parseInt(subscription.discount || 0) > 10)" class="form-info red text-end error">Too high value</p> -->
</div>
<div class="text-center">
<p class="form-label active">Taxes</p>
<div class="info-icon-wrap">
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      v-model="subscription.taxes"
      placeholder="Taxes"
      step="0.01"
      prefix="$"
      @focus="ev => {
        formatValue(ev, 'taxes', true);
        activated.taxes = true;
        focused.taxes = true;
      }"
      @blur="ev => {
        formatValue(ev, 'taxes', false);
        focused.taxes = false;
      }"              
    />
  </div>
  <!-- <p v-if="activated.taxes && (Number.parseInt(subscription.taxes || 0) === 0)" class="form-info red text-end error">* Required</p> -->
  <!-- <p v-if="activated.taxes && (Number.parseInt(subscription.taxes || 0) > 10)" class="form-info red text-end error">Too high value</p> -->
  <div class="taxes-button">
    <span class="info-icon"  @mouseover="switchTooltip(true)" @mouseout="switchTooltip(false)">
      <!-- <img src="@/assets/images/question.svg" alt=""> -->
      <img src="@/assets/images/question-mark.png" alt="">
    </span>
  </div>
  </div>
</div>
<div class="text-center">
  <p class="form-label active">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      v-model="subscription.total"
      placeholder="Total"
      step="0.01"
      prefix="$"
      @focus="ev => {
        formatValue(ev, 'total', true);
        activated.total = true;
        focused.total = true;
      }"
      @blur="ev => {
        formatValue(ev, 'total', false);
        focused.total = false;
      }"
    />
  </div>
  <!-- <p v-if="activated.total && (Number.parseInt(subscription.total || 0) <= 0)" class="form-info red text-end error">* Required</p> -->
</div>
</div>  
</div>

<div class="content-row">
  <div class="four-column-grid four-column-grid-form text-left overflow-auto-mobile"> <!-- overflow-auto -->
    <button type="submit" class="btn green"  @click="submitForm">Save</button>
    <button type="submit" class="btn light-blue-20 btn-o"  @click="cancelChanges">Cancel</button> 
    <div class="info-item justify-content-end subscription-details-info">
      <p>Subscription Details</p>
      <div>
        <span class="info-icon"
          @mouseover="switchSubscriptionTooltip(true)"
          @mouseout="switchSubscriptionTooltip(false)"
        >
          <!-- <img src="@/assets/images/question.svg" alt=""> -->
          <img src="@/assets/images/question-mark.png" alt="">
          <div class="tooltip" v-if="subscriptionTooltip">
            Basic: $25/Per Month <br/>
            (Includes User, Farm and Task Management) <br/>
            Basic +: $40/per month <br/>
            (Includes User, Farm, Task Management, Inventory, Equipment and Vendor) <br/>
            Basic + Advance: $55/per month <br/>
            (Includes User, Farm, Task Management, Inventory, Equipment, Vendor, Sales, and Customer) <br/>
            Basic + Ultimate: $65/per month <br/>
            (Includes User, Farm, Task Management, Inventory, Equipment, Vendor, Sales, Customer, and Reports) <br/>
          </div>
        </span>
      </div>
      </div>
  </div>
</div>

<div class="taxes-tooltip" v-if="tooltipIsVisible">
  Tax: {{subscriptionRates.tax}} %
</div>

</div>
</div>

  <!-- Old content -->

<!--<div class="content-block">
  <div style="position: relative;">

 <div class="content-row">
<div class="checkbox"><input type="checkbox" id="freeThirtyDay" v-model="subscription.plans.trial"><label for="freeThirtyDay">Free 30 Day Trial</label></div>  
 </div>

<div class="content-row overflow-auto">
<div class="four-column-grid four-column-grid-form text-left">

<div class="subscription-type-form-dropdown span-3">
<p class="form-label">Billing Type</p>
<select class="form-control" v-model="subscription.subscriptionPeriod">
<option>Monthly</option>
<option>Yearly</option>
</select>
<p class="fs-xs">10% Discount on Yearly Subscription</p>
</div>

</div> 
</div>

<div class="content-row overflow-auto">
<div class="four-column-grid text-left align-end four-column-grid-form">

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basic" v-model="subscription.plans.basic" />
    <label for="basic">Basic</label>
  </div>
  <p class="form-info red text-end" 
    style="text-align: left;position: absolute;"
    v-if="activated.basic && !subscription.plans.basic"
  >* Required</p>
</div>
<div><p class="form-label active">Price per month</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input class="form-control" v-model="subscription.plans.basicPrice" type="number" step="0.01" disabled />
  </div>
</div>
<div style="position: relative;">
  <p class="form-label" :class="{active: focused.basicNumber || (subscription.plans.basicNumber > 0)}">No. of Subscriptions</p>
  <input type="number" class="form-control" 
    v-model="subscription.plans.basicNumber" 
    :disabled="!subscription.plans.basic" 
    @focus="() => {
      activated.basicNumber = true;
      focused.basicNumber = true;
    }"
    @blur="() => {
      focused.basicNumber = false;
    }"
  >
  <div v-if="!subscription.plans.basic" 
    class="form-control"
    style="position: absolute; top: calc(24px + 0.3rem); opacity: 0;"
    @click="() => {
      activated.basic = true;
    }"
  ></div>
  <p v-if="subscription.plans.basicNumber && ((subscription.plans.basicNumber < 0) || !Number.isInteger(subscription.plans.basicNumber))" class="form-info red text-end">Please insert whole positive number</p>
</div>
<div><p class="form-label">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control" 
      :value="subscription.plans.basic ? (Number.parseInt(subscription.plans.basicNumber || 0) * Number.parseInt(subscription.plans.basicPrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0" 
    />
  </div>
</div>

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basicPlus" v-model="subscription.plans.plus" />
    <label for="basicPlus">Basic +</label>
  </div>
  <p class="form-info red text-end" 
    style="text-align: left;position: absolute;"
    v-if="activated.plus && !subscription.plans.plus"
  >* Required</p>
</div>
<div><p class="form-label active">Price per month</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input class="form-control" prefix="$"
      v-model="subscription.plans.plusPrice"
      placeholder="Price per month"
      type="number"
      step="0.01"
      disabled />
  </div>
</div>
<div style="position: relative;">
  <p class="form-label" :class="{active: focused.plusNumber || (subscription.plans.plusNumber > 0)}">No. of Subscriptions</p>
  <input type="number" class="form-control" 
    v-model="subscription.plans.plusNumber" 
    @focus="() => {
      activated.plusNumber = true;
      focused.plusNumber = true;
    }"
    @blur="() => {
      focused.plusNumber = false;
    }"
    :disabled="!subscription.plans.plus">
    <div v-if="!subscription.plans.plus" 
      class="form-control"
      style="position: absolute; top: calc(24px + 0.3rem); opacity: 0;"
      @click="() => {
        activated.plus = true;
      }"
    ></div>
  <p v-if="subscription.plans.plusNumber && ((subscription.plans.plusNumber < 0) || !Number.isInteger(subscription.plans.plusNumber))" class="form-info red text-end">Please insert whole positive number</p>
</div>
<div><p class="form-label">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control" 
      :value="subscription.plans.plus ? (Number.parseInt(subscription.plans.plusNumber || 0) * Number.parseInt(subscription.plans.plusPrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0"
    />
  </div>
</div>

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basicPlusAdvance" v-model="subscription.plans.advance">
    <label for="basicPlusAdvance">Basic + Advance</label>
  </div>
  <p class="form-info red text-end" 
    style="text-align: left;position: absolute;"
    v-if="activated.advance && !subscription.plans.advance"
  >* Required</p>
</div>
<div>
  <p class="form-label active">Price per month</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control" 
      prefix="$"
      v-model="subscription.plans.advancePrice"
      placeholder="Price per month"
      step="0.01"
      disabled />
  </div>
</div>
<div style="position: relative;">
  <p class="form-label" :class="{active: focused.advanceNumber || (subscription.plans.advanceNumber > 0)}">No. of Subscriptions</p>
  <input type="number" class="form-control" 
    v-model="subscription.plans.advanceNumber"
    @focus="() => {
      activated.advanceNumber = true;
      focused.advanceNumber = true;
    }"
    @blur="() => {
      focused.advanceNumber = false;
    }"
    :disabled="!subscription.plans.advance"
    >
    <div v-if="!subscription.plans.advance" 
      class="form-control"
      style="position: absolute; top: calc(24px + 0.3rem); opacity: 0;"
      @click="() => {
        activated.advance = true;
      }"
    ></div>
    <p v-if="subscription.plans.advanceNumber && ((subscription.plans.advanceNumber < 0) || !Number.isInteger(subscription.plans.advanceNumber))" class="form-info red text-end">Please insert whole positive number</p>
</div>
<div><p class="form-label">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control"
      :value="subscription.plans.advance ? (Number.parseInt(subscription.plans.advanceNumber || 0) * Number.parseInt(subscription.plans.advancePrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0"
    />
  </div>
</div>

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basicPlusUltimate" v-model="subscription.plans.ultimate">
    <label for="basicPlusUltimate">Basic + Ultimate</label>
  </div>
  <p class="form-info red text-end" 
    style="text-align: left;position: absolute;"
    v-if="activated.ultimate && !subscription.plans.ultimate"
  >* Required</p>
</div>
<div><p class="form-label active">Price per month</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      prefix="$"
      v-model="subscription.plans.ultimatePrice"
      step="0.01"
      disabled
    />
  </div>
</div>
<div style="position: relative;">
  <p class="form-label" :class="{active: focused.ultimateNumber || (subscription.plans.ultimateNumber > 0)}">No. of Subscriptions</p>
  <input type="number" class="form-control" 
    v-model="subscription.plans.ultimateNumber"
    @focus="() => {
      activated.ultimateNumber = true;
      focused.ultimateNumber = true;
    }"
    @blur="() => {
      focused.ultimateNumber = false;
    }"
    :disabled="!subscription.plans.ultimate"
  >
  <div v-if="!subscription.plans.ultimate" 
    class="form-control"
    style="position: absolute; top: calc(24px + 0.3rem); opacity: 0;"
    @click="() => {
      activated.ultimate = true;
    }"
  ></div>
  <p v-if="subscription.plans.ultimateNumber && ((subscription.plans.ultimateNumber < 0) || !Number.isInteger(Number(subscription.plans.ultimateNumber)))" class="form-info red text-end">Please insert whole positive number</p>
</div>
<div><p class="form-label">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control"
      :value="subscription.plans.ultimate ? (Number.parseInt(subscription.plans.ultimateNumber || 0) * Number.parseInt(subscription.plans.ultimatePrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0"
    />
  </div>
</div>

</div>  
</div>

<div class="content-row overflow-auto">
<div class="four-column-grid four-column-grid-form text-left">
<div class="text-center">
  <p class="form-label" :class="{active: focused.totalSubscription || (subscription.totalSubscription >= 0)}">Subscription Amount</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control" 
      prefix="$"
      v-model="subscription.totalSubscription"
      step="0.01"
      @focus="ev => {
        formatValue(ev, 'totalSubscription', true)
        activated.totalSubscription = true;
        focused.totalSubscription = true;
      }"
      @blur="ev => {
        formatValue(ev, 'totalSubscription', false)
        focused.totalSubscription = false;
      }"
    />
  </div>
  <p v-if="activated.totalSubscription && (Number.parseInt(subscription.totalSubscription || 0) === 0)" class="form-info red text-end">* Required</p>
</div>
<div class="text-center">
  <p class="form-label" :class="{active: focused.discount || (subscription.discount >= 0)}">Discount</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      v-model="subscription.discount"
      placeholder="Discount"
      step="0.01"
      prefix="$"
      @focus="ev => {
        formatValue(ev, 'discount', true);
        activated.discount = true;
        focused.discount = true;
      }"
      @blur="ev => {
        formatValue(ev, 'discount', false);
        focused.discount = false;
      }"
    />
  </div>
  <p v-if="activated.discount && (Number.parseInt(subscription.discount || 0) > 10)" class="form-info red text-end">Too high value</p>
</div>
<div class="text-center">
  <p class="form-label" :class="{active: focused.taxes || (subscription.taxes >= 0)}">Taxes</p>
  <div class="info-icon-wrap">
    <div class="dollar-prefix">
      <span>$</span>
      <input type="number" class="form-control"
        v-model="subscription.taxes"
        placeholder="Taxes"
        step="0.01"
        prefix="$"
        @focus="ev => {
          formatValue(ev, 'taxes', true);
          activated.taxes = true;
          focused.taxes = true;
        }"
        @blur="ev => {
          formatValue(ev, 'taxes', false);
          focused.taxes = false;
        }"              
      />
    </div>
  <p v-if="activated.taxes && (Number.parseInt(subscription.taxes || 0) === 0)" class="form-info red text-end">* Required</p>
  <p v-if="activated.taxes && (Number.parseInt(subscription.taxes || 0) > 10)" class="form-info red text-end">Too high value</p>
  <div class="taxes-button">
    <span class="info-icon"  @mouseover="switchTooltip(true)" @mouseout="switchTooltip(false)">
      <img src="@/assets/images/question.svg" alt="">
    </span>
  </div>
  </div>
</div>
<div class="text-center">
  <p class="form-label" :class="{active: focused.total || (subscription.total >= 0)}">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      v-model="subscription.total"
      placeholder="Total"
      step="0.01"
      prefix="$"
      @focus="ev => {
        formatValue(ev, 'total', true);
        activated.total = true;
        focused.total = true;
      }"
      @blur="ev => {
        formatValue(ev, 'total', false);
        focused.total = false;
      }"
    />
  </div>
  <p v-if="activated.total && (Number.parseInt(subscription.total || 0) <= 0)" class="form-info red text-end">* Required</p>
</div>
</div>  
</div>
<div class="taxes-tooltip" v-if="tooltipIsVisible">
  Tax: {{subscriptionRates.tax}} %
</div>
<div class="content-row">
  <div class="four-column-grid overflow-auto four-column-grid-form text-left">
    <button type="submit" class="btn green" @click="submitForm">Save</button>
    <button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button> 
    <div class="info-item justify-content-end subscription-details-info">
      <p>Subscription Details</p>
      <div>
        <span class="info-icon"
          @mouseover="switchSubscriptionTooltip(true)"
          @mouseout="switchSubscriptionTooltip(false)"
        >
          <img src="@/assets/images/question.svg" alt="">
        </span>
      </div>
      </div>
  </div>
</div>
<div class="info-tooltip" v-if="subscriptionTooltip">
  Basic: $25/Per Month <br/>
  (Includes User, Farm and Task Management) <br/>
  Basic +: $40/per month <br/>
  (Includes User, Farm, Task Management, Inventory, Equipment and Vendor) <br/>
  Basic + Advance: $55/per month <br/>
  (Includes User, Farm, Task Management, Inventory, Equipment, Vendor, Sales, and Customer) <br/>
  Basic + Ultimate: $65/per month <br/>
  (Includes User, Farm, Task Management, Inventory, Equipment, Vendor, Sales, Customer, and Reports) <br/>
</div>

</div>
</div>-->

</div>
</div>
</section>

<!-- <section class="section" ref="section">
<div class="border-box active">
<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Subscription Type</h5>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>
<div class="border-box-content">

<div class="content-block">

<div class="content-row">
<div class="grid-4">
<div class="column">
<div class="checkbox checkbox-lg"><input type="checkbox" id="checkbox1" v-model="subscription.plans.trial"><label for="checkbox1">Free 30 Days Trial</label></div>
</div>
</div>
</div>

<div class="content-row grid-4">
<div class="row">
<div class="col-3 align-self-end">
<div class="form-filed-align">
<div class="form-item">
<div class="checkbox checkbox-lg"><input type="checkbox" id="checkbox2" v-model="subscription.plans.basic"><label for="checkbox2">Basic Plan</label></div>
</div>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label active">Price per month</p>
<input class="form-control" v-model="subscription.plans.basicPrice" type="number" step="0.01" disabled>
</div>
</div>
<div class="col-3 align-self-end">
<div class="form-item">
<p class="form-label" :class="{active: focused.basicNumber || (subscription.plans.basicNumber > 0)}">No. of Subscriptions</p>
<input type="number" class="form-control" 
  v-model="subscription.plans.basicNumber" 
  :disabled="!subscription.plans.basic" 
  @focus="() => {
    activated.basicNumber = true;
    focused.basicNumber = true;
  }"
  @blur="() => {
    focused.basicNumber = false;
  }"
>
<p v-if="subscription.plans.basicNumber && ((subscription.plans.basicNumber < 0) || !Number.isInteger(subscription.plans.basicNumber))" class="form-info red text-end">Please insert whole positive number</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label active">Billing Type</p>
<select class="form-control" v-model="subscription.subscriptionPeriod">
<option>Monthly</option>
<option>Yearly</option>
</select>
<p class="form-info">*10% Discount on Yearly Subscription</p>
</div>
</div>
</div>
</div>

</div>

<div class="content-block">

<div class="content-header">
<h6 class="sub-headding">Add Modules</h6>
</div>

<div class="content-section">

<div class="content-row grid-4">
<div class="row">
<div class="col-3 align-self-end">
<div class="form-filed-align">
<div class="form-item">
<div class="checkbox checkbox-lg"><input type="checkbox" id="checkbox3" v-model="subscription.plans.plus"><label for="checkbox3">Basic +</label></div>
</div>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label active">Price per month</p>
<input class="form-control" prefix="$"
  v-model="subscription.plans.plusPrice"
  placeholder="Price per month"
  type="number"
  step="0.01"
  disabled>
</div>
</div>
<div class="col-3 align-self-end">
<div class="form-item">
<p class="form-label" :class="{active: focused.plusNumber || (subscription.plans.plusNumber > 0)}">No. of Subscriptions</p>
<input type="number" class="form-control" 
  v-model="subscription.plans.plusNumber" 
  @focus="() => {
    activated.plusNumber = true;
    focused.plusNumber = true;
  }"
  @blur="() => {
    focused.plusNumber = false;
  }"
  :disabled="subscription.plans.allinclusive || !subscription.plans.plus">
  <p v-if="subscription.plans.plusNumber && ((subscription.plans.plusNumber < 0) || !Number.isInteger(subscription.plans.plusNumber))" class="form-info red text-end">Please insert whole positive number</p>
</div>
</div>
</div>
</div>

<div class="content-row grid-4">
<div class="row">
<div class="col-3 align-self-end">
<div class="form-filed-align">
<div class="form-item">
<div class="checkbox checkbox-lg"><input type="checkbox" id="checkbox4" v-model="subscription.plans.advance"><label for="checkbox4">Basic + Advance</label></div>
</div>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label active">Price per month</p>
<input type="number" class="form-control" 
  prefix="$"
  v-model="subscription.plans.advancePrice"
  placeholder="Price per month"
  step="0.01"
  disabled>
</div>
</div>
<div class="col-3 align-self-end">
<div class="form-item">
<p class="form-label" :class="{active: focused.advanceNumber || (subscription.plans.advanceNumber > 0)}">No. of Subscriptions</p>
<input type="number" class="form-control" 
  v-model="subscription.plans.advanceNumber"
  @focus="() => {
    activated.advanceNumber = true;
    focused.advanceNumber = true;
  }"
  @blur="() => {
    focused.advanceNumber = false;
  }"
  :disabled="!subscription.plans.advance"
  >
  <p v-if="subscription.plans.advanceNumber && ((subscription.plans.advanceNumber < 0) || !Number.isInteger(subscription.plans.advanceNumber))" class="form-info red text-end">Please insert whole positive number</p>
</div>
</div>
</div>
</div>

<div class="content-row grid-4">
<div class="row">
<div class="col-3 align-self-end">
<div class="form-filed-align">
<div class="form-item">
<div class="checkbox checkbox-lg"><input type="checkbox" id="checkbox5" v-model="subscription.plans.ultimate"><label for="checkbox5">Basic + Ultimate</label></div>
</div>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label active">Price per month</p>
<input type="number" class="form-control"
  prefix="$"
  v-model="subscription.plans.ultimatePrice"
  step="0.01"
  disabled
>
</div>
</div>
<div class="col-3 align-self-end">
<div class="form-item">
<p class="form-label" :class="{active: focused.ultimateNumber || (subscription.plans.ultimateNumber > 0)}">No. of Subscriptions</p>
<input type="number" class="form-control" 
  v-model="subscription.plans.ultimateNumber"
  @focus="() => {
    activated.ultimateNumber = true;
    focused.ultimateNumber = true;
  }"
  @blur="() => {
    focused.ultimateNumber = false;
  }"
  :disabled="!subscription.plans.ultimate"
>
<p v-if="subscription.plans.ultimateNumber && ((subscription.plans.ultimateNumber < 0) || !Number.isInteger(Number(subscription.plans.ultimateNumber)))" class="form-info red text-end">Please insert whole positive number</p>
</div>
</div>
</div>
</div>

<div class="content-row grid-4 grid-full">
<div class="row">
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.totalSubscription || (subscription.totalSubscription >= 0)}">Subscription Amount</p>
<div class="dollar-prefix">
  <span>$</span>
  <input type="number" class="form-control" 
    prefix="$"
    v-model="subscription.totalSubscription"
    step="0.01"
    @focus="ev => {
      formatValue(ev, 'totalSubscription', true)
      activated.totalSubscription = true;
      focused.totalSubscription = true;
    }"
    @blur="ev => {
      formatValue(ev, 'totalSubscription', false)
      focused.totalSubscription = false;
    }"
  />
</div>
<p v-if="activated.totalSubscription && (Number.parseInt(subscription.totalSubscription || 0) === 0)" class="form-info red text-end">* Required</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.discount || (subscription.discount >= 0)}">Discount</p>
<input type="number" class="form-control"
  v-model="subscription.discount"
  placeholder="Discount"
  step="0.01"
  prefix="$"
  @focus="ev => {
    formatValue(ev, 'discount', true);
    activated.discount = true;
    focused.discount = true;
  }"
  @blur="ev => {
    formatValue(ev, 'discount', false);
    focused.discount = false;
  }"
>
<p v-if="activated.discount && (Number.parseInt(subscription.discount || 0) > 10)" class="form-info red text-end">Too high value</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.taxes || (subscription.taxes >= 0)}">Taxes</p>
<div class="info-icon-wrap">
<input type="number" class="form-control"
  v-model="subscription.taxes"
  placeholder="Taxes"
  step="0.01"
  prefix="$"
  @focus="ev => {
    formatValue(ev, 'taxes', true);
    activated.taxes = true;
    focused.taxes = true;
  }"
  @blur="ev => {
    formatValue(ev, 'taxes', false);
    focused.taxes = false;
  }"              
>
<p v-if="activated.taxes && (Number.parseInt(subscription.taxes || 0) === 0)" class="form-info red text-end">* Required</p>
<p v-if="activated.taxes && (Number.parseInt(subscription.taxes || 0) > 10)" class="form-info red text-end">Too high value</p>
<div class="taxes-button">
  <span class="info-icon"  @mouseover="switchTooltip(true)" @mouseout="switchTooltip(false)">
    <img src="@/assets/images/question.svg" alt="">
    <div class="taxes-tooltip" v-if="tooltipIsVisible">
      Tax: {{subscriptionRates.tax}} %
    </div>
  </span>
</div>
</div>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.total || (subscription.total >= 0)}">Total</p>
<div class="dollar-prefix">
  <span>$</span>
  <input type="number" class="form-control"
    v-model="subscription.total"
    placeholder="Total"
    step="0.01"
    prefix="$"
    @focus="ev => {
      formatValue(ev, 'total', true);
      activated.total = true;
      focused.total = true;
    }"
    @blur="ev => {
      formatValue(ev, 'total', false);
      focused.total = false;
    }"
  />
</div>
<p v-if="activated.total && (Number.parseInt(subscription.total || 0) <= 0)" class="form-info red text-end">* Required</p>
</div>
</div>
</div>
</div>

</div>

</div>

<div class="content-block">
<div class="buttons form-buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
<div class="col align-self-center text-end info-item-wrap">
<div class="info-item">
<p>Subscription Details</p>
<div>
  <span class="info-icon"
    @mouseover="switchSubscriptionTooltip(true)"
    @mouseout="switchSubscriptionTooltip(false)"
  >
    <img src="@/assets/images/question.svg" alt="">
    <div class="tooltip" v-if="subscriptionTooltip">
      Basic: $25/Per Month <br/>
      (Includes User, Farm and Task Management) <br/>
      Basic +: $40/per month <br/>
      (Includes User, Farm, Task Management, Inventory, Equipment and Vendor) <br/>
      Basic + Advance: $55/per month <br/>
      (Includes User, Farm, Task Management, Inventory, Equipment, Vendor, Sales, and Customer) <br/>
      Basic + Ultimate: $65/per month <br/>
      (Includes User, Farm, Task Management, Inventory, Equipment, Vendor, Sales, Customer, and Reports) <br/>
    </div>
  </span>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</div>
</section> -->

</template>

<script>
import { ref, watch, inject, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import subscriptionRates from '@/sharedData/subscriptionRates';
import DropDownList from "@/components/Shared/DropDownList.vue";
// import moment from 'moment';

export default {
    name: "ProfileSubscriptionForm",
    props: {
        userType: String,
    },
    setup() {
        const store = useStore();
        const { subscription, paymentRequired, changeSubscriptionField } = inject('subscription'), defaultSubscription = { ...subscription }, periodOptions = ['Monthly', 'Yearly'], noPlanSubmit = ref(false), disabledSubmit = ref(true), tooltipIsVisible = ref(false), sectionHeader = 'Subscription Type';
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const activated = reactive({
            basicNumber: false,
            // inventoryNumber: false,
            // equipmentNumber: false,
            // allinclusiveNumber: false,
            plusNumber: false,
            advanceNumber: false,
            ultimateNumber: false,
            totalSubscription: false,
            discount: false,
            taxes: false,
            total: false,
            basic: false,
            plus: false,
            advance: false,
            ultimate: false
        });
        const focused = reactive({
            basicNumber: false,
            // inventoryNumber: false,
            // equipmentNumber: false,
            // allinclusiveNumber: false,
            plusNumber: false,
            advanceNumber: false,
            ultimateNumber: false,
            totalSubscription: false,
            discount: false,
            taxes: false,
            total: false
        });
        // const formatPrice = (val, field) => {
        //   console.log(subscription.plans.basicPrice, `${val}.00`)
        //   changeSubscriptionField(`${val}.00`, field);
        // }
        const formatValue = (ev, field, active) => {
            const props = field.split('.'), val = active ? Number.parseFloat(ev.target.value) :
                `${ev.target.value}${`${ev.target.value}`.split('.')[1] ? '' : '.00'}`;
            if (subscription[props[1]]) {
                subscription[props[0]][props[1]] = val;
            }
            else {
                subscription[props[0]] = val;
            }
        };
        const toggleSection = () => {
            sectionExpanded.value = !sectionExpanded.value;
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });
        watch(() => (subscription.subscriptionPeriod), (newVal) => {
            subscription.discount = (newVal === 'Yearly') ? `${Math.abs(subscription.totalSubscription / 100) * 10}.00` : `${0}.00`;
            calculateTotal();
        });
        watch(subscription, () => {
            // if (subscription.plans.basic || subscription.plans.allinclusive) {
            if (subscription.plans.basic || subscription.plans.plus ||
                subscription.plans.advance || subscription.plans.ultimate) {
                disabledSubmit.value = !(Number.parseFloat(subscription.total) > 0) ||
                    !(Number.parseFloat(subscription.totalSubscription) > 0) || noPlanSubmit.value;
                subscription.discount = (subscription.subscriptionPeriod === 'Yearly') ? `${Math.abs(subscription.totalSubscription / 100) * 10}.00` : `${0}.00`;
            }
            if (subscription.plans.trial) {
                disabledSubmit.value = !subscription.plans.trial;
            }
        });
        watch(() => ({ ...subscription.plans }), (newVal, oldVal) => {
            /*
            for (let plan in subscription.plans) {
              if (newVal.trial && !oldVal.trial) {
                // if (plan.includes('Price')) subscription.plans[plan] = ''
                if (plan.includes('Number')) subscription.plans[plan] = ''//0
                if (!plan.includes('Number') && !plan.includes('Price') && plan !== 'trial') {
                  subscription.plans[plan] = false
                }
                calculateTotal(true);
              }
              if (newVal.basic && !oldVal.basic) {
                // if (plan.includes('Price') && !plan.includes('basic')) subscription.plans[plan] = ''
                if (plan.includes('Number') && !plan.includes('basic')) subscription.plans[plan] = ''//0
                if (!plan.includes('Number') && !plan.includes('Price') && !plan.includes('basic')) {
                  subscription.plans[plan] = false;
                  noPlanSubmit.value = false;
                }
              }
      
              if (newVal.plus && !oldVal.plus) {
                // if (plan.includes('Price') && !plan.includes('plus')) subscription.plans[plan] = ''
                if (plan.includes('Number') && !plan.includes('plus')) subscription.plans[plan] = ''//0
                if (!plan.includes('Number') && !plan.includes('Price') && !plan.includes('plus')) {
                  subscription.plans[plan] = false;
                  noPlanSubmit.value = false;
                }
              }
      
              if (newVal.advance && !oldVal.advance) {
                // if (plan.includes('Price') && !plan.includes('advance')) subscription.plans[plan] = ''
                if (plan.includes('Number') && !plan.includes('advance')) subscription.plans[plan] = ''//0
                if (!plan.includes('Number') && !plan.includes('Price') && !plan.includes('advance')) {
                  subscription.plans[plan] = false;
                  noPlanSubmit.value = false;
                }
              }
      
              if (newVal.ultimate && !oldVal.ultimate) {
                // if (plan.includes('Price') && !plan.includes('ultimate')) subscription.plans[plan] = ''
                if (plan.includes('Number') && !plan.includes('ultimate')) subscription.plans[plan] = ''//0
                if (!plan.includes('Number') && !plan.includes('Price') && !plan.includes('ultimate')) {
                  subscription.plans[plan] = false;
                  noPlanSubmit.value = false;
                }
              }
              
              // if (newVal.allinclusive && !oldVal.allinclusive) {
              //   // if (plan.includes('Price') && !plan.includes('allinclusive')) subscription.plans[plan] = ''
              //   if (plan.includes('Number') && !plan.includes('allinclusive')) subscription.plans[plan] = ''//0
              //   if (!plan.includes('Number') && !plan.includes('Price') && !plan.includes('allinclusive')) {
              //     subscription.plans[plan] = false;
              //     noPlanSubmit.value = false;
              //   }
              // }
            }
            */
            // if (newVal.basic || newVal.plus || newVal.advance || newVal.ultimate) {
            //   if (subscription.subscriptionPeriod === 'Yearly') {
            //     subscription.plans.trial = false;
            //   }
            // }
            if (newVal.trial && !oldVal.trial) {
              subscription.subscriptionPeriod = 'Monthly'
            }
            subscription.totalSubscription = '0.00';
            subscription.total = '0.00';
            subscription.taxes = '0.00';
            subscription.discount = '0.00';
            calculateTotal();
        });
        watch(() => (subscription.taxes), (newVal) => {
            if (!newVal)
                subscription.taxes = `${0}.00`;
            calculateTotal();
        });
        watch(() => (subscription.discount), (newVal) => {
            if (!newVal)
                subscription.discount = `${0}.00`;
            calculateTotal();
        });
        // watch(() => (subscription.subscriptionPeriod), (newVal) => {
        //   if (subscription.plans.basic || subscription.plans.plus || 
        //     subscription.plans.advance || subscription.plans.ultimate) {
        //     if (newVal === 'Yearly') {
        //       subscription.plans.trial = false;
        //     }
        //   }
        // })
        const calculateTotal = (trial) => {
            const months = (subscription.subscriptionPeriod === 'Yearly') ? 12 : 1;
            const basicField = subscription.plans.basicPrice * subscription.plans.basicNumber;
            // const inventoryField = subscription.plans.inventoryPrice * subscription.plans.inventoryNumber;
            // const equipmentField = subscription.plans.equipmentPrice * subscription.plans.equipmentNumber;
            // const allinclusiveField = subscription.plans.allinclusivePrice * subscription.plans.allinclusiveNumber;
            const plusField = subscription.plans.plusPrice * subscription.plans.plusNumber;
            const advanceField = subscription.plans.advancePrice * subscription.plans.advanceNumber;
            const ultimateField = subscription.plans.ultimatePrice * subscription.plans.ultimateNumber;
            const basicCalc = {
                totalSubscription: 0,
                total: 0,
                taxes: 0
            };
            const plusCalc = {
                totalSubscription: 0,
                total: 0,
                taxes: 0
            };
            const advanceCalc = {
                totalSubscription: 0,
                total: 0,
                taxes: 0
            };
            const ultimateCalc = {
                totalSubscription: 0,
                total: 0,
                taxes: 0
            };
            if (subscription.plans.trial && trial) {
                subscription.totalSubscription = `${0}.00`;
                subscription.total = `${0}.00`;
                subscription.taxes = `${0}.00`;
            }
            // if (
            //   subscription.plans.allinclusive &&
            //   subscription.plans.allinclusivePrice &&
            //   subscription.plans.allinclusiveNumber
            // ) {
            //   subscription.totalSubscription = Math.abs(
            //     allinclusiveField * months
            //   ).toFixed(2)
            //   subscription.total = Math.abs(
            //     ((allinclusiveField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * (100 + 
            //       Number.parseFloat(subscriptionRates.tax))
            //   ).toFixed(2)
            //   subscription.taxes = Math.abs(
            //     ((allinclusiveField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)
            //   ).toFixed(2)
            // }
            if (subscription.plans.basic &&
                (subscription.plans.basicPrice > 0) &&
                (subscription.plans.basicNumber > 0)) {
                // const optionsSum = inventoryField + basicField + equipmentField;
                basicCalc.totalSubscription = Math.abs(basicField * months).toFixed(2);
                basicCalc.total = Math.abs(((basicField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * (100 +
                    Number.parseFloat(subscriptionRates.tax))).toFixed(2);
                basicCalc.taxes = Math.abs(((basicField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)).toFixed(2);
            }
            if (subscription.plans.plus &&
                subscription.plans.plusPrice &&
                subscription.plans.plusNumber) {
                plusCalc.totalSubscription = Math.abs(plusField * months).toFixed(2);
                plusCalc.total = Math.abs(((plusField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * (100 +
                    Number.parseFloat(subscriptionRates.tax))).toFixed(2);
                plusCalc.taxes = Math.abs(((plusField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)).toFixed(2);
            }
            if (subscription.plans.advance &&
                subscription.plans.advancePrice &&
                subscription.plans.advanceNumber) {
                advanceCalc.totalSubscription = Math.abs(advanceField * months).toFixed(2);
                advanceCalc.total = Math.abs(((advanceField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * (100 +
                    Number.parseFloat(subscriptionRates.tax))).toFixed(2);
                advanceCalc.taxes = Math.abs(((advanceField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)).toFixed(2);
            }
            if (subscription.plans.ultimate &&
                subscription.plans.ultimatePrice &&
                subscription.plans.ultimateNumber) {
                ultimateCalc.totalSubscription = Math.abs(ultimateField * months).toFixed(2);
                ultimateCalc.total = Math.abs(((ultimateField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * (100 +
                    Number.parseFloat(subscriptionRates.tax))).toFixed(2);
                ultimateCalc.taxes = Math.abs(((ultimateField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)).toFixed(2);
            }
            console.log(basicCalc, plusCalc, advanceCalc, ultimateCalc);
            subscription.totalSubscription = Math.abs(Number.parseFloat(basicCalc.totalSubscription) +
                Number.parseFloat(plusCalc.totalSubscription) +
                Number.parseFloat(advanceCalc.totalSubscription) +
                Number.parseFloat(ultimateCalc.totalSubscription)).toFixed(2);
            // subscription.total = Math.abs(
            //   Number.parseFloat(basicCalc.total) + 
            //   Number.parseFloat(plusCalc.total) +
            //   Number.parseFloat(advanceCalc.total) + 
            //   Number.parseFloat(ultimateCalc.total)
            // ).toFixed(2);
            subscription.total = Math.abs((Number.parseFloat((subscription.totalSubscription) - Number.parseFloat(subscription.discount)) / 100) * Number.parseFloat(106.87)).toFixed(2);
            // subscription.taxes = Math.abs(
            //   Number.parseFloat(basicCalc.taxes) + 
            //   Number.parseFloat(plusCalc.taxes) +
            //   Number.parseFloat(advanceCalc.taxes) + 
            //   Number.parseFloat(ultimateCalc.taxes)
            // ).toFixed(2);
            subscription.taxes = Math.abs((Number.parseFloat((subscription.totalSubscription) - Number.parseFloat(subscription.discount)) / 100) * Number.parseFloat(6.87)).toFixed(2);
            subscription.plans.basicAvailable = subscription.plans.basicNumber;
            subscription.plans.plusAvailable = subscription.plans.plusNumber;
            subscription.plans.advanceAvailable = subscription.plans.advanceNumber;
            subscription.plans.ultimateAvailable = subscription.plans.ultimateNumber;
        };
        const switchTooltip = (toggle) => {
            tooltipIsVisible.value = toggle;
        };
        const subscriptionTooltip = ref(false);
        const switchSubscriptionTooltip = (toggle) => {
            subscriptionTooltip.value = toggle;
        };
        const cancelChanges = () => {
            for (let prop in defaultSubscription) {
                subscription[prop] = defaultSubscription[prop];
            }
            changeSubscriptionField('represent', 'mode');
        };
        const submitForm = () => {
            // if (!subscription.plans.trial && !subscription.plans.basic 
            //     && !subscription.plans.allinclusive) {
            if (!subscription.plans.trial && !subscription.plans.basic
                && !subscription.plans.plus && !subscription.plans.advance &&
                !subscription.plans.ultimate) {
                noPlanSubmit.value = true;
                console.log('returning');
                return;
            }
            if (
              // (subscription.plans.basic || subscription.plans.plus ||
              //   subscription.plans.advance || subscription.plans.ultimate) &&
              //   (!subscription.plans.basicNumber && !subscription.plans.plusNumber &&
              //       !subscription.plans.advanceNumber && !subscription.plans.ultimateNumber)
                  (subscription.plans.basic && (!subscription.plans.basicNumber || ((subscription.plans.basicNumber > 0) && !Number.isInteger(subscription.plans.basicNumber)))) ||
                  (subscription.plans.plus && (!subscription.plans.plusNumber  || ((subscription.plans.plusNumber > 0) && !Number.isInteger(subscription.plans.plusNumber)))) ||
                  (subscription.plans.advance && (!subscription.plans.advanceNumber || ((subscription.plans.advanceNumber > 0) && !Number.isInteger(subscription.plans.advanceNumber)))) ||
                  (subscription.plans.ultimate && (!subscription.plans.ultimateNumber || ((subscription.plans.ultimateNumber > 0) && !Number.isInteger(subscription.plans.ultimateNumber))))
                  ) {
                if (subscription.plans.basic && !subscription.plans.basicNumber)
                    activated.basicNumber = true;
                if (subscription.plans.plus && !subscription.plans.plusNumber)
                    activated.plusNumber = true;
                if (subscription.plans.advance && !subscription.plans.advanceNumber)
                    activated.advanceNumber = true;
                if (subscription.plans.ultimate && !subscription.plans.ultimateNumber)
                    activated.ultimateNumber = true;
                // subscription.total = '';
                // subscription.taxes = `${0}.00`;
                // subscription.totalSubscription = '';
                // activated.totalSubscription = true;
                // activated.total = true;
                // activated.taxes = true;
                console.log('returning 2');
                return;
            }
            console.log('not returning');
            subscription.planPurchasingDate = new Date();
            changeSubscriptionField(true, 'submitted');
            store.dispatch('savePrimaryUser', { section: 'subscription', data: subscription });
            // let priceId,
            //   quantity;
            const items = [];
            const assignedPlan = {
                trial: false,
                basic: false,
                plus: false,
                advance: false,
                ultimate: false
            };
            if (subscription.plans.trial) {
                const trialItem = {
                    price: subscription.plans.trialPrice,
                    quantity: 1
                };
                items.push(trialItem);
                assignedPlan.trial = true;
            }
            if (subscription.plans.basic) {
                const basicItem = {
                    price: subscription.subscriptionPeriod === 'Yearly' ?
                        subscription.plans.basicPriceIdYearly : subscription.plans.basicPriceIdMonthly,
                    quantity: subscription.plans.basicNumber
                };
                items.push(basicItem);
                assignedPlan.basic = true;
            }
            if (subscription.plans.plus) {
                const plusItem = {
                    price: subscription.subscriptionPeriod === 'Yearly' ?
                        subscription.plans.plusPriceIdYearly : subscription.plans.plusPriceIdMonthly,
                    quantity: subscription.plans.plusNumber
                };
                items.push(plusItem);
                assignedPlan.plus = true;
            }
            if (subscription.plans.advance) {
                const advanceItem = {
                    price: subscription.subscriptionPeriod === 'Yearly' ?
                        subscription.plans.advancePriceIdYearly : subscription.plans.advancePriceIdMonthly,
                    quantity: subscription.plans.advanceNumber
                };
                items.push(advanceItem);
                assignedPlan.advance = true;
            }
            if (subscription.plans.ultimate) {
                const ultimateItem = {
                    price: subscription.subscriptionPeriod === 'Yearly' ?
                        subscription.plans.ultimatePriceIdYearly : subscription.plans.ultimatePriceIdMonthly,
                    quantity: subscription.plans.ultimateNumber
                };
                items.push(ultimateItem);
                assignedPlan.ultimate = true;
            }
            /*
              updating instead of create new subscription
              if trying to create new subscription get mongoerror
              because of multiply items accepted subscription can't be updated
              so only new subscription should be created
            */
            // store.state.subscriptionPlan.subscriptionId ?
            //   store.dispatch('updateSubscription', {
            //     data: {
            //       ...subscription,
            //       // priceId,
            //       // quantity,
            //       items,
            //       primaryUserId: store.state.primaryUserTenant.id
            //     }
            //   }) :
            if (store.state.authenticated.paymentMethods.length > 0) {
              console.log('entering selectSubscription');
              console.log(store.state.authenticated, '[store.state.authenticated]');
              const selectedPaymentMethodIndex = store.state.authenticated.selectedPayment || 0;
              store.dispatch('selectSubscription', {
                  data: {
                      ...subscription,
                      // priceId,
                      // quantity,
                      items,
                      primaryUserId: store.state.primaryUserTenant.id,
                      paymentMethod: store.state.authenticated.paymentMethods[selectedPaymentMethodIndex].id
                  }
              });
              setTimeout(() => {
                store.dispatch('createSubscription', {
                  data: {
                    ...store.state.savedAdminSubscription
                  }
                });
              }, 100)
            } else {
              store.dispatch('createSubscription', {
                  data: {
                      ...subscription,
                      // priceId,
                      // quantity,
                      items,
                      primaryUserId: store.state.primaryUserTenant.id
                  }
              });
            }
            // store.dispatch('savePrimaryUser', {section: 'subscriptionAssigned', data: {...assignedPlan}});
            // store.dispatch('assignSubscription', {...assignedPlan});
            changeSubscriptionField('represent', 'mode');
            // setTimeout(() => {
            //   changeMainField(true, 'activated')
            // }, 2000);
        };
        onMounted(() => {
            sectionHeight.value = section.value.offsetHeight;
            section.value.style.height = `${section.value.offsetHeight}px`;
            // subscription.taxes = `${subscriptionRates.tax}`
            subscription.plans.basicPrice = `${subscriptionRates.basic}`;
            // subscription.plans.inventoryPrice = `${subscriptionRates.inventory}`
            // subscription.plans.equipmentPrice = `${subscriptionRates.equipment}`
            // subscription.plans.allinclusivePrice = `${subscriptionRates.allinclusive}`
            subscription.plans.plusPrice = `${subscriptionRates.plus}`;
            subscription.plans.advancePrice = `${subscriptionRates.advance}`;
            subscription.plans.ultimatePrice = `${subscriptionRates.ultimate}`;
            if (subscription.plans.basicNumber)
                subscription.plans.basicNumber = Number.parseInt(subscription.plans.basicNumber);
            if (subscription.plans.plusNumber)
                subscription.plans.plusNumber = Number.parseInt(subscription.plans.plusNumber);
            if (subscription.plans.advanceNumber)
                subscription.plans.advanceNumber = Number.parseInt(subscription.plans.advanceNumber);
            if (subscription.plans.ultimateNumber)
                subscription.plans.ultimateNumber = Number.parseInt(subscription.plans.ultimateNumber);
            store.dispatch('addCurrentPath', {
                section: 1,
                val: sectionHeader
            });
        });
        return {
            sectionHeader,
            paymentRequired,
            subscription,
            periodOptions,
            changeSubscriptionField,
            noPlanSubmit,
            disabledSubmit,
            subscriptionRates,
            tooltipIsVisible,
            switchTooltip,
            formatValue,
            // formatPrice,
            cancelChanges,
            submitForm,
            subscriptionTooltip,
            switchSubscriptionTooltip,
            activated,
            focused,
            section,
            boxHeader,
            toggleSection
        };
    },
    components: { DropDownList }
};
</script>

<style lang="scss" scoped src="@/styles/pages/UserProfile/ProfileSubscriptionForm.scss"></style>
