<template>
  <template v-if="tasksList.length > 0">
    <div  class="task-items"
      v-for="(task, index) in tasksList"
      v-bind:key="task.id + '-task-widget'"
    >
      <div class="task-item" v-if="index >= (tasksList.length - 5)">
        <div class="task-item-header">
          <div class="task-item-point">•</div>
          <!-- Upcoming Task:  -->
          <div class="task-link" @click="() => naviagateToTask(task.id)">{{ task.task }}</div>
        </div>
        <div class="task-item-time">
          <div class="task-item-point">•</div>
          Dt {{ 
          `${moment(`${task.date} ${task.startTime}`).format('MM/DD/YYYY hh:mm a')}`
        }}</div>
      </div>
    </div>

    <div class="task-buttons">
      <!-- <button @click="() => updateTask(task.id, task.taskId)">Update</button> -->
      <button @click="navigateToTasksList">More</button>
    </div>
  </template>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'TasksListWidget',
  setup() {

    const store = useStore();

    const router = useRouter();

    const tasks = computed(() => store.state.tasks.filter(task => task.taskStatus !== 'Completed'));

    const tasksList = ref([]);

    const naviagateToTask = (taskId) => {
      router.push({ 
        name: 'page', 
        params: { 
          pagename: 'task',
          selectedTask: true,
          taskId
        } 
      });
    }

    const navigateToTasksList = () => {
      if (router.currentRoute.value.name === 'home') {
        document.getElementsByTagName('main')[0].scroll({
          top: document.getElementsByClassName('dashboard-content-wrap')[0]?.offsetTop,
          left: 0,
          behavior: 'smooth'
        })
      } else {
        router.push({ 
          name: 'home',
          params: { 
            selectedTask: true
          } 
        });
      }
    }

    watch(() => ([...store.state.tasks]), (newVal) => {
      console.log(store.state.tasks);
      if (newVal) {
        tasksList.value = [...tasks.value].filter(task => task.taskStatus !== 'Completed')
      }
    })

    onMounted(() => {
      tasksList.value = [...tasks.value]
    })

    return {
      tasksList,
      moment,
      naviagateToTask,
      navigateToTasksList
    }
  }
})
</script>

<style lang="scss" scoped>
.task-item {
  padding: 5px;
  border-bottom: 1px solid #7e8081;
  cursor: pointer;
  // color: hsl(var(--gray));
  .task-item-header {
    // font-family: var(--fw-semibold);
    font-family: var(--fw-medium);
    // font-size: var(--fs-sm);
    font-size: 1rem;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    .task-link {
      color: hsl(var(--green));
      text-decoration: underline;
    }
  }
  .task-item-time {
    font-family: var(--fw-medium);
    // font-size: var(--fs-sm);
    font-size: 1rem;
    display: flex;
  }
  .task-item-point {
  //   width: 1rem;
  //   height: 1rem;
  //   background-color: #f44336;
  //   border-radius: 1rem;
    width: max-content;
    margin-right: 5px;
  }
}
.task-buttons {
    display: flex;
    justify-content: flex-end;
    button {
      font-family: var(--fw-medium);
      // font-size: var(--fs-sm);
      font-size: 1.4rem;
      color: hsl(var(--green));
      text-decoration: underline;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      margin-right: 0.5rem;
    }
  }
</style>