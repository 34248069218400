<template>

  <section class="section mt-1" ref="section">
    <div class="border-box">

  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5>Subscription Type</h5>
  <div class="info-icons">
    <div class="info-icon lg header-icon tooltip"
      @click="addSubscription"
      data-tooltip="Buy Subscription"
      @mouseover="switchAddHelperTooltip(true)"
      @mouseout="switchAddHelperTooltip(false)"
    >
      <!-- <img src="@/assets/images/plus-green-md.png" alt=""> -->
      <img src="@/assets/images/updated/059-add.png" alt="">
      <!-- <div class="add-helper-tooltip" v-if="addHelperTooltip">Add Subscription</div> -->
    </div>
  </div>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>

  <div class="border-box-content subscription-type-content">

    <div class="content-block">
  <div class="payment-details">
 

<div class="subscription-wrap-block">

  <div class="content-row subscription-info">
    <div>
        <p class="form-label">Billing Type</p>
        <h6>{{ subscription.subscriptionPeriod }}</h6>
        <p class="fs-xs pt-1">10% Discount on Yearly Subscription</p>
    </div>
  </div>
  
<div class="subscription-list-grid-wrap">
  <div class="subscription-list-grid">

  <div></div>
  <div><p class="form-label text-center">Total</p></div>
  <div><p class="form-label text-center">Assign</p></div>
  <div><p class="form-label text-center">Available</p></div>

  <div class="checkbox"><input type="checkbox" id="free30day" v-model="subscription.plans.trial"><label for="free30day">Free 30 Day</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(1) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="free30dayAssign"
        v-model="assigned.trial" 
      />
      <label for="free30dayAssign" class="p-0"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(assigned.trial ? 0 : 1) }}</h6>
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basic" v-model="subscription.plans.basic" disabled /><label for="basic" @click="() => handleMissingSubscription('basic')">Basic</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.basicNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign2"
        v-model="assigned.basic" 
        :disabled="!stateSubscription.plans.basicNumber || (stateSubscription.plans.basicNumber <= 0)"
        @change="() => reassignLeast('basic')"
      />
      <label for="assign2" class="p-0" @click="() => handleMissingSubscription('basic')"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt((assigned.basic ? (stateSubscription.plans.basicAvailable - 1) : stateSubscription.plans.basicAvailable) || 0) }}</h6>
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlus" v-model="subscription.plans.plus" disabled /><label for="basicPlus" @click="() => handleMissingSubscription('plus')">Basic +</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.plusNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign3" 
        v-model="assigned.plus"
        :disabled="!stateSubscription.plans.plusNumber || (stateSubscription.plans.plusNumber <= 0)" 
        @change="() => reassignLeast('plus')"
      />
      <label for="assign3" class="p-0" @click="() => handleMissingSubscription('plus')"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt((assigned.plus ? (stateSubscription.plans.plusAvailable - 1) : stateSubscription.plans.plusAvailable) || 0) }}</h6>
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlusAdvance" v-model="subscription.plans.advance" disabled><label for="basicPlusAdvance" @click="() => handleMissingSubscription('advance')">Basic + Advance</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.advanceNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign4"
        v-model="assigned.advance" 
        :disabled="!stateSubscription.plans.advanceNumber || (stateSubscription.plans.advanceNumber <= 0)"
        @change="() =>reassignLeast('advance')"
      />
      <label for="assign4" class="p-0" @click="() => handleMissingSubscription('advance')"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt((assigned.advance ? (stateSubscription.plans.advanceAvailable - 1) : stateSubscription.plans.advanceAvailable) || 0) }}</h6>
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlusUltimate" v-model="subscription.plans.ultimate" disabled><label for="basicPlusUltimate" @click="() => handleMissingSubscription('ultimate')">Basic + Ultimate</label></div>
  <div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.ultimateNumber || 0) }}</h6></div></div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign5"
        v-model="assigned.ultimate" 
        :disabled="!stateSubscription.plans.ultimateNumber || (stateSubscription.plans.ultimateNumber <= 0)"
        @change="() =>reassignLeast('ultimate')"
      />
      <label for="assign5" class="p-0" @click="() => handleMissingSubscription('ultimate')"></label>
    </div>
  </div>
  <div>
    <div class="w-80"><h6>{{ Number.parseInt((assigned.ultimate ? (stateSubscription.plans.ultimateAvailable - 1) : stateSubscription.plans.ultimateAvailable) || 0) }}</h6></div>
  </div>

  </div>
</div>

</div>
 
<div class="content-row overflow-auto subscription-result-wrap">
<div class="four-column-grid">
<div class="text-center">
  <p class="form-label">Subscription Amount</p>
  <h6>${{ Number.parseFloat(stateSubscription.totalSubscription || 0).toFixed(2) }}</h6>  
</div>
<div class="text-center">
  <p class="form-label">Discount</p>
  <h6>${{ Number.parseFloat(stateSubscription.taxes || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Taxes</p>
  <h6>${{ Number.parseFloat(stateSubscription.discount || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Total</p>
  <h6>${{ Number.parseFloat(stateSubscription.total || 0).toFixed(2) }}</h6>  
</div>
</div>  
</div>

<div class="content-row">
<div class="buttons">
<div class="row">
  <div class="col-auto"><button type="submit" class="btn green" @click="saveAssigned">Save</button></div>
  <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelAssigning">Cancel</button></div>
</div>
</div>
</div>

</div>
</div>

    <!-- <div class="content-block">
  <div class="payment-details">

 <div class="content-row">
<div class="checkbox"><input type="checkbox" id="freeThirtyDay" v-model="assigned.trial" /><label for="freeThirtyDay">Free 30 Day</label></div>  
 </div>
 
  <div class="content-row d-flex justify-content-end">
    <div>
        <p class="form-label">Billing Type</p>
        <h6>{{ subscription.subscriptionPeriod }}</h6>
        <p class="fs-xs pt-1">10% Discount on Yearly Subscription</p>
    </div>
 </div>

<div class="content-row overflow-auto">
<div class="subscription-table-grid">

<div><div class="w-80"><p class="form-label text-center">Total</p></div></div>
<div><div class="w-80"><p class="form-label text-center">Assign</p></div></div>
<div><div class="w-80"><p class="form-label text-center">Available</p></div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basic" v-model="subscription.plans.basic" disabled /><label for="basic" @click="() => handleMissingSubscription('basic')">Basic</label></div></div>  
<div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.basicNumber || 0) }}</h6></div></div>
<div><div class="w-80 checkbox checkbox-lg">
  <input type="checkbox" id="assign2"
    v-model="assigned.basic" 
    :disabled="!stateSubscription.plans.basicNumber || (stateSubscription.plans.basicNumber <= 0)"
    @change="() => reassignLeast('basic')"
  />
  <label for="assign2" class="p-0" @click="() => handleMissingSubscription('basic')"></label>
</div></div>
<div><div class="w-80"><h6>{{ Number.parseInt((assigned.basic ? (stateSubscription.plans.basicAvailable - 1) : stateSubscription.plans.basicAvailable) || 0) }}</h6></div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basicPlus" v-model="subscription.plans.plus" disabled /><label for="basicPlus" @click="() => handleMissingSubscription('plus')">Basic +</label></div></div>  
<div><div class="w-80">
  <h6>{{ Number.parseInt(stateSubscription.plans.plusNumber || 0) }}</h6>
</div></div>
<div><div class="w-80 checkbox checkbox-lg">
  <input type="checkbox" id="assign3" 
    v-model="assigned.plus"
    :disabled="!stateSubscription.plans.plusNumber || (stateSubscription.plans.plusNumber <= 0)" 
    @change="() => reassignLeast('plus')"
  />
  <label for="assign3" class="p-0" @click="() => handleMissingSubscription('plus')"></label>
</div></div>
<div><div class="w-80">
  <h6>{{ Number.parseInt((assigned.plus ? (stateSubscription.plans.plusAvailable - 1) : stateSubscription.plans.plusAvailable) || 0) }}</h6>
</div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basicPlusAdvance" v-model="subscription.plans.advance" disabled><label for="basicPlusAdvance" @click="() => handleMissingSubscription('advance')">Basic + Advance</label></div></div>  
<div><div class="w-80">
  <h6>{{ Number.parseInt(stateSubscription.plans.advanceNumber || 0) }}</h6>
</div></div>
<div><div class="w-80 checkbox checkbox-lg">
  <input type="checkbox" id="assign4"
    v-model="assigned.advance" 
    :disabled="!stateSubscription.plans.advanceNumber || (stateSubscription.plans.advanceNumber <= 0)"
    @change="() =>reassignLeast('advance')"
  />
  <label for="assign4" class="p-0" @click="() => handleMissingSubscription('advance')"></label>
</div></div>
<div><div class="w-80">
  <h6>{{ Number.parseInt((assigned.advance ? (stateSubscription.plans.advanceAvailable - 1) : stateSubscription.plans.advanceAvailable) || 0) }}</h6>
</div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basicPlusUltimate" v-model="subscription.plans.ultimate" disabled><label for="basicPlusUltimate" @click="() => handleMissingSubscription('ultimate')">Basic + Ultimate</label></div></div>  
<div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.ultimateNumber || 0) }}</h6></div></div>
<div><div class="w-80 checkbox checkbox-lg">
  <input type="checkbox" id="assign5"
    v-model="assigned.ultimate" 
    :disabled="!stateSubscription.plans.ultimateNumber || (stateSubscription.plans.ultimateNumber <= 0)"
    @change="() =>reassignLeast('ultimate')"
  />
  <label for="assign5" class="p-0" @click="() => handleMissingSubscription('ultimate')"></label>
</div></div>
<div><div class="w-80"><h6>{{ Number.parseInt((assigned.ultimate ? (stateSubscription.plans.ultimateAvailable - 1) : stateSubscription.plans.ultimateAvailable) || 0) }}</h6></div></div>

</div>
</div>
 
<div class="content-row overflow-auto">
<div class="four-column-grid">
<div class="text-center">
  <p class="form-label">Subscription Amount</p>
  <h6>${{ Number.parseFloat(stateSubscription.totalSubscription || 0).toFixed(2) }}</h6>  
</div>
<div class="text-center">
  <p class="form-label">Discount</p>
  <h6>${{ Number.parseFloat(stateSubscription.taxes || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Taxes</p>
  <h6>${{ Number.parseFloat(stateSubscription.discount || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Total</p>
  <h6>${{ Number.parseFloat(stateSubscription.total || 0).toFixed(2) }}</h6>  
</div>
</div>  
</div>

<div class="content-row">
<div class="buttons">
<div class="row">
  <div class="col-auto"><button type="submit" class="btn green"  @click="saveAssigned">Save</button></div>
  <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelAssigning">Cancel</button></div>
</div>
</div>
</div>

</div>
</div> -->

    <!-- Old content -->

  </div>

  </div>

  <div v-if="popupMessages.init.active">
    <UserNotification
      v-bind:hideDetails="true"
      v-bind:messageText="popupMessages.init.message"
    />
  </div>

  <div v-if="popupMessages.assigned.active">
    <UserNotification
      v-bind:hideDetails="true"
      v-bind:messageText="popupMessages.assigned.message"
    />
  </div>

  <div v-if="popupMessages.missing.active">
    <UserNotification
      v-bind:hideDetails="true"
      v-bind:messageText="popupMessages.missing.message"
      @click="() => {popupMessages.missing.active = false}"
    />
  </div>

  </section>

  <!-- 
    <section class="section mt-1" ref="section">
    <div class="border-box">

  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5>Subscription Type</h5>
  <div class="info-icons">
    <div class="info-icon lg header-icon"
      @click="addSubscription"
      @mouseover="switchAddHelperTooltip(true)"
      @mouseout="switchAddHelperTooltip(false)"
    >
      <img src="@/assets/images/plus-green-md.png" alt="">
      <div class="add-helper-tooltip" v-if="addHelperTooltip">Add Subscription</div>
    </div>
  </div>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>

  <div class="border-box-content subscription-type-content">

  <div class="content-block">

  <div class="content-row grid-4">
  <div class="row">
  <div class="col-3 align-self-end">
  <div class="form-item">
  <div class="checkbox checkbox-lg">
    <input type="checkbox" id="checkbox8" v-model="assigned.trial" disabled />
    <label for="checkbox8">Free 30 Days Trial</label>
  </div>
  </div>
  </div>
  <div class="col-2"></div>
  <div class="col-2">
  <div class="form-item">
  <div class="d-flex flex-column align-items-center">
  <p class="form-label-static">Assigned</p>
  <div class="form-item d-flex">
  <div class="checkbox checkbox-lg d-inline-flex">
    <input 
      type="checkbox" 
      id="assign1" 
      v-model="assigned.trial" 
      @change="() =>reassignLeast('trial')" 
    />
    <label for="assign1" class="p-0"></label></div>
  </div>
  </div>
  </div>
  </div>
  <div class="col-2">
  <div class="form-item">
  <p class="form-label-static">Available</p>
  <h6>{{ assigned.trial ? 0 : 1 }}</h6>
  </div>
  </div>
  </div>
  </div>

  <div class="content-row grid-4">
  <div class="row">
  <div class="col-3 align-self-end">
  <div class="form-item">
  <div class="checkbox checkbox-lg">
    <input type="checkbox" id="checkbox9" v-model="subscription.plans.basic" disabled />
    <label for="checkbox9">Basic Plan</label>
  </div>
  </div>
  </div>

  <div class="col-2">
  <div class="form-item">
  <p class="form-label-static">Total</p>
  <h6>{{ Number.parseInt(stateSubscription.plans.basicNumber || 0) }}</h6>
  </div>
  </div>

  <div class="col-2">
  <div class="form-item">
  <div class="d-flex flex-column align-items-center">
  <p class="form-label-static">Assigned</p>
  <div class="form-item d-flex">
  <div class="checkbox checkbox-lg d-inline-flex">
    <input type="checkbox" id="assign2"
      v-model="assigned.basic" 
      :disabled="!stateSubscription.plans.basicNumber || (stateSubscription.plans.basicNumber <= 0)"
      @change="() =>reassignLeast('basic')"
    />
    <label for="assign2" class="p-0"></label>
  </div>
  </div>
  </div>
  </div>
  </div>
  <div class="col-2">
  <div class="form-item">
  <p class="form-label-static">Available</p>
  <h6>
    {{ Number.parseInt(subscription.plans.basicAvailable - 
    (assigned.basic ? 1 : 0) || 0) }}
  </h6>
  </div>
  </div>
  <div class="col-3">
  <div class="form-item">
  <p class="form-label-static">Billing Type</p>
  <h6>{{ subscription.subscriptionPeriod }}</h6>
  <span class="form-info">*10% Discount on Yearly Subscription</span>
  </div>
  </div>
  </div>
  </div>

  </div>

  <div class="content-block">

  <div class="content-header">
  <h6 class="sub-headding">Add Modules</h6>
  </div>

  <div class="content-section">

  <div class="content-row grid-4">
  <div class="row">
  <div class="col-3 align-self-end">
  <div class="form-item">
  <div class="checkbox checkbox-lg">
    <input type="checkbox" id="checkbox10" v-model="subscription.plans.plus" disabled />
    <label for="checkbox10">Basic +</label>
  </div>
  </div>
  </div>

  <div class="col-2">
  <div class="form-item">
  <p class="form-label-static">Total</p>
  <h6>{{ Number.parseInt(stateSubscription.plans.plusNumber || 0) }}</h6>
  </div>
  </div>

  <div class="col-2">
  <div class="form-item">
  <div class="d-flex flex-column align-items-center">
  <p class="form-label-static">Assigned</p>
  <div class="form-item d-flex">
  <div class="checkbox checkbox-lg d-inline-flex">
    <input type="checkbox" id="assign3" 
      v-model="assigned.plus"
      :disabled="!stateSubscription.plans.plusNumber || (stateSubscription.plans.plusNumber <= 0)" 
      @change="() =>reassignLeast('plus')"
    />
    <label for="assign3" class="p-0"></label>
  </div>
  </div>
  </div>
  </div>
  </div>

  <div class="col-2">
  <div class="form-item">
  <p class="form-label-static">Available</p>
  <h6>
    {{ Number.parseInt(subscription.plans.plusAvailable - 
    (assigned.plus ? 1 : 0) || 0) }} 
  </h6>
  </div>
  </div>

  </div>
  </div>

  <div class="content-row grid-4">
  <div class="row">
  <div class="col-3 align-self-end">
  <div class="form-item">
  <div class="checkbox checkbox-lg">
    <input type="checkbox" id="checkbox11" v-model="subscription.plans.advance" disabled />
    <label for="checkbox11">Basic + Advance</label>
  </div>
  </div>
  </div>

  <div class="col-2">
  <div class="form-item">
  <p class="form-label-static">Total</p>
  <h6>{{ Number.parseInt(stateSubscription.plans.advanceNumber || 0) }}</h6>
  </div>
  </div>

  <div class="col-2">
  <div class="form-item">
  <div class="d-flex flex-column align-items-center">
  <p class="form-label-static">Assigned</p>
  <div class="form-item d-flex">
  <div class="checkbox checkbox-lg d-inline-flex">
    <input type="checkbox" id="assign4"
      v-model="assigned.advance" 
      :disabled="!stateSubscription.plans.advanceNumber || (stateSubscription.plans.advanceNumber <= 0)"
      @change="() =>reassignLeast('advance')"
    />
    <label for="assign4" class="p-0"></label>
  </div>
  </div>
  </div>
  </div>
  </div>

  <div class="col-2">
  <div class="form-item">
  <p class="form-label-static">Available</p>
  <h6>
    {{ Number.parseInt(subscription.plans.advanceAvailable - 
    (assigned.advance ? 1 : 0) || 0) }} 
  </h6>
  </div>
  </div>

  </div>
  </div>

  <div class="content-row grid-4">
  <div class="row">
  <div class="col-3 align-self-end">
  <div class="form-item">
  <div class="checkbox checkbox-lg">
    <input type="checkbox" id="checkbox12"
      v-model="subscription.plans.ultimate" 
      disabled
    />
    <label for="checkbox12">Basic + Ultimate</label>
  </div>
  </div>
  </div>

  <div class="col-2">
  <div class="form-item">
  <p class="form-label-static">Total</p>
  <h6>{{ Number.parseInt(stateSubscription.plans.ultimateNumber || 0) }}</h6>
  </div>
  </div>

  <div class="col-2">
  <div class="form-item">
  <div class="d-flex flex-column align-items-center">
  <p class="form-label-static">Assigned</p>
  <div class="form-item">
  <div class="checkbox checkbox-lg d-inline-flex">
    <input type="checkbox" id="assign5"
      v-model="assigned.ultimate" 
      :disabled="!stateSubscription.plans.ultimateNumber || (stateSubscription.plans.ultimateNumber <= 0)"
      @change="() =>reassignLeast('ultimate')"
    />
    <label for="assign5" class="p-0"></label>
  </div>
  </div>
  </div>
  </div>
  </div>

  <div class="col-2">
  <div class="form-item">
  <p class="form-label-static">Available</p>
  <h6>
    {{ Number.parseInt(subscription.plans.ultimateAvailable - 
    (assigned.ultimate ? 1 : 0) || 0) }} 
  </h6>
  </div>
  </div>

  </div>
  </div>

  <div class="content-row grid-4 grid-full">
  <div class="row">
  <div class="col-3">
  <div class="form-item">
  <p class="form-label-static">Subscription Amount</p>
  <h6>${{ Number.parseFloat(subscription.totalSubscription || 0).toFixed(2) }}</h6>
  </div>
  </div>
  <div class="col-3">
  <div class="form-item">
  <p class="form-label-static">Taxes</p>
  <h6>${{ Number.parseFloat(subscription.taxes || 0).toFixed(2) }}</h6>
  </div>
  </div>
  <div class="col-3">
  <div class="form-item">
  <p class="form-label-static">Discount</p>
  <h6>${{ Number.parseFloat(subscription.discount || 0).toFixed(2) }}</h6>
  </div>
  </div>
  <div class="col-3">
  <div class="form-item">
  <p class="form-label-static">Total</p>
  <h6>${{ Number.parseFloat(subscription.total || 0).toFixed(2) }}</h6>
  </div>
  </div>
  </div>
  </div>

  </div>

  <div class="content-section">
  <div class="buttons">
  <div class="row">
  <div class="col-auto"><button type="submit" class="btn green" @click="saveAssigned">Save</button></div>
  <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelAssigning">Cancel</button></div>
  </div>
  </div>
  </div>

  </div>

  </div>

  </div>

  <UserNotification
    v-if="popupMessages.init.active"
    v-bind:hideDetails="true"
    v-bind:messageText="popupMessages.init.message"
  />

  <UserNotification
    v-if="popupMessages.assigned.active"
    v-bind:hideDetails="true"
    v-bind:messageText="popupMessages.assigned.message"
  />

  </section>
   -->
</template>

<script>
import { ref, watch, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import _ from "lodash";
import UserNotification from "@/components/Shared/UserNotification.vue";

export default {
    name: 'UserSubscription',
    setup() {
        const store = useStore();
        const router = useRouter();
        const subscriptionEditionMode = ref('create');
        const subscription = reactive({
            plans: {
                trial: true,
                trialPrice: 'price_1O5VljHpD3HYJIrgI5y2Jhkq',
                basic: false,
                basicPrice: '',
                basicNumber: '',
                basicPriceIdYearly: 'price_1NXMRQHpD3HYJIrg2IwC8sri',
                basicPriceIdMonthly: 'price_1NXMNxHpD3HYJIrg0hyDtIQO',
                basicAvailable: '',
                // inventory: false,
                // inventoryPrice: '',
                // inventoryNumber: '',
                plus: false,
                plusPrice: '',
                plusNumber: '',
                plusPriceIdYearly: 'price_1NXMQoHpD3HYJIrgJaWbqhOI',
                plusPriceIdMonthly: 'price_1NXMQoHpD3HYJIrgnhtzXnIF',
                plusAvailable: '',
                // equipment: false,
                // equipmentPrice: '',
                // equipmentNumber: '',
                advance: false,
                advancePrice: '',
                advanceNumber: '',
                advancePriceIdYearly: 'price_1NXMhHHpD3HYJIrgUwLCM3C2',
                advancePriceIdMonthly: 'price_1NXMhHHpD3HYJIrgrxWzr1UH',
                advanceAvailable: '',
                // allinclusive: false,
                // allinclusivePrice: '',
                // allinclusiveNumber: '',
                ultimate: false,
                ultimatePrice: '',
                ultimateNumber: '',
                ultimatePriceIdYearly: 'price_1NXMklHpD3HYJIrgUKaTXGU9',
                ultimatePriceIdMonthly: 'price_1NXMklHpD3HYJIrgbrsDHSiM',
                ultimateAvailable: '',
            },
            subscriptionPeriod: 'Monthly',
            totalSubscription: '',
            taxes: '',
            discount: '',
            total: '',
            planPurchasingDate: store.state.authenticated.registrationDate,
            subscriptionsNumber: 0, // remove later
        });
        const defaultSubscription = {
            ...subscription,
            plans: {
                ...subscription.plans
            }
        };
        const stateSubscription = reactive({
            ...store.state.primaryUser.subscription,
            plans: {
                ...store.state.primaryUser?.subscription?.plans
            }
        });
        const assigned = reactive({
            trial: false,
            basic: false,
            plus: false,
            advance: false,
            ultimate: false
        });

        const popupMessages = reactive({
          init: {
            active: false,
            message: 'Please assign a subscription to the account'
          },
          assigned: {
            active: false,
            message: 'Subscription has been added to the account'
          },
          missing: {
            active: false,
            message: 'No subscription available, please purchase by clicking on the Add subscription icon'
          }
        })

        const missingTimeout = ref(null);

        const handleMissingSubscription = (plan) => {
          if (!subscription.plans[plan]) {
            popupMessages.missing.active = true;
            clearTimeout(missingTimeout.value);

            missingTimeout.value = setTimeout(() => {
              popupMessages.missing.active = false;
            }, 5100);
          }
        }

        const changeSubscriptionField = (val, field, index) => {
            if (index) {
                subscription.modules[index].added = val;
            }
            else {
                subscription[field] = val;
            }
        };
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const openedActions = ref(false);
        const editHelperTooltip = ref(false);
        const addHelperTooltip = ref(false);
        // const subscriptionTooltip = ref(false);
        const switchEditHelperTooltip = (toggle) => {
            editHelperTooltip.value = toggle;
        };
        const switchAddHelperTooltip = (toggle) => {
            addHelperTooltip.value = toggle;
        };
        const addSubscription = () => {
            router.push({
                name: 'admin',
                params: {
                    pagename: 'admin-subscription',
                    source: 'user-subscription'
                }
            });
        };
        // const switchSubscriptionTooltip = (toggle) => {
        //   subscriptionTooltip.value = toggle;
        // }
        const openNewForm = () => {
            for (let prop in defaultSubscription) {
                if (prop === 'plans') {
                    for (let plan in defaultSubscription.plans) {
                        subscription.plans[plan] = defaultSubscription.plans[plan];
                    }
                }
                else {
                    subscription[prop] = defaultSubscription[prop];
                }
            }
            subscriptionEditionMode.value = 'create';
        };
        const openEditForm = () => {
            for (let prop in stateSubscription) {
                if (prop === 'plans') {
                    for (let plan in stateSubscription.plans) {
                        subscription.plans[plan] = stateSubscription.plans[plan];
                    }
                }
                else {
                    subscription[prop] = stateSubscription[prop];
                }
            }
            subscriptionEditionMode.value = 'edit';
        };
        const openUpdateForm = () => {
            router.push({
                name: 'admin',
                params: {}
            });
        };
        const cancelAssigning = () => {
            for (let prop in assigned) {
                assigned[prop] = false;
            }
            subscriptionEditionMode.value = 'represent';
        };
        const saveAssigned = () => {
            const assignedPlan = {};
            for (let prop in assigned) {
                for (let subProp in subscription.plans) {
                    if (assigned[prop]) {
                        if (subProp.includes(prop)) {
                            if ((subProp.includes('Number') || subProp.includes('Price'))) {
                                assignedPlan[subProp] = subscription.plans[subProp];
                            }
                            else {
                                assignedPlan[subProp] = assigned[subProp];
                            }
                        }
                    }
                }
            }
            if (!store.state.primaryUser.subscription) {
                store.dispatch('savePrimaryUser', {
                    section: 'subscription',
                    data: {
                        plans: {
                            trial: true,
                            trialPrice: 'price_1O5VljHpD3HYJIrgI5y2Jhkq',
                            basic: false,
                            basicPrice: '',
                            basicNumber: '',
                            basicPriceIdYearly: 'price_1NXMRQHpD3HYJIrg2IwC8sri',
                            basicPriceIdMonthly: 'price_1NXMNxHpD3HYJIrg0hyDtIQO',
                            basicAvailable: '',
                            // plus: false,
                            // plusPrice: '',
                            // plusNumber: '',
                            plus: false,
                            plusPrice: '',
                            plusNumber: '',
                            plusPriceIdYearly: 'price_1NXMQoHpD3HYJIrgJaWbqhOI',
                            plusPriceIdMonthly: 'price_1NXMQoHpD3HYJIrgnhtzXnIF',
                            plusAvailable: '',
                            // advance: false,
                            // advancePrice: '',
                            // advanceNumber: '',
                            advance: false,
                            advancePrice: '',
                            advanceNumber: '',
                            advancePriceIdYearly: 'price_1NXMhHHpD3HYJIrgUwLCM3C2',
                            advancePriceIdMonthly: 'price_1NXMhHHpD3HYJIrgrxWzr1UH',
                            advanceAvailable: '',
                            // ultimate: false,
                            // ultimatePrice: '',
                            // ultimateNumber: '',
                            ultimate: false,
                            ultimatePrice: '',
                            ultimateNumber: '',
                            ultimatePriceIdYearly: 'price_1NXMklHpD3HYJIrgUKaTXGU9',
                            ultimatePriceIdMonthly: 'price_1NXMklHpD3HYJIrgbrsDHSiM',
                            ultimateAvailable: '',
                        },
                        subscriptionPeriod: 'Monthly',
                        totalSubscription: '',
                        taxes: '',
                        discount: '',
                        total: '',
                        planPurchasingDate: store.state.authenticated.registrationDate,
                        subscriptionsNumber: 0
                    }
                });
            }
            store.dispatch('assignSubscriptionToUser', {assignedPlan: {...assignedPlan}, userId: 'primary'});
            store.dispatch('savePrimaryUser', { section: 'subscriptionAssigned', data: { ...assignedPlan } });
            store.dispatch('assignSubscription', { assignedPlan: {...assignedPlan} });

            popupMessages.assigned.active = true;

            setTimeout(() => {
              router.push({
                  name: 'page',
                  params: {
                      pagename: 'user-profile',
                      redirectSource: 'user-subscription'
                  }
              });
            }, 5000)
            // subscriptionEditionMode.value = 'represent';
        };
        const toggleSection = () => {
            sectionExpanded.value = !sectionExpanded.value;
        };
        const reassignLeast = (activeProp) => {
            for (let prop in assigned) {
                if (prop !== activeProp)
                    assigned[prop] = false;
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });
        watch(() => _.cloneDeep(assigned), (newVal, oldVal) => {
            console.log(assigned, 'assigned');
            section.value.style.height = 'auto';
            setTimeout(() => {
                if (sectionHeight.value) {
                    sectionHeight.value = section.value.offsetHeight;
                }
            });
            for (let prop in newVal) {
                if (newVal[prop]) {
                    if (store.state.primaryUser.subscriptionAssigned &&
                        !store.state.primaryUser.subscriptionAssigned[prop]) {
                        openedActions.value = true;
                    }
                    for (let subProp in subscription.plans) {
                        if (newVal[prop] && !oldVal[prop]) {
                            if (subProp.includes(prop) && subProp.includes('Number')) {
                                subscription.plans[subProp] = subscription.plans[subProp] - 1;
                            }
                        }
                    }
                }
                if (!newVal[prop] && oldVal[prop]) {
                    console.log(prop, newVal[prop]);
                    for (let subProp in subscription.plans) {
                        // if (newVal[prop]) {
                        if (subProp.includes(prop) && subProp.includes('Number')) {
                            subscription.plans[subProp] = subscription.plans[subProp] + 1;
                        }
                        // }
                    }
                }
            }
        });

        // watch(() => ({...store.state.authenticated}), (newVal, oldVal) => {

        //   if (!oldVal.accountSubdomain && newVal.accountSubdomain) {
        //     console.log('currentRoute', window.location.href);
            
        //     setTimeout(() => {
        //       router.push({
        //           name: 'page',
        //           params: {
        //               pagename: 'user-profile',
        //               redirectSource: 'user-subscription'
        //           }
        //       });
        //     }, 5000);

        //     setTimeout(() => {
        //       const currentRoute = window.location.href;
        //       const withSubdomain = currentRoute.split('https://')[1];
        //       const baseName = withSubdomain.split('.');
        //       // router.push(`https://${newVal.accountSubdomain}.${baseName[1]}.${baseName[2]}`);
        //       if (baseName.length > 3) {
        //         window.location = `https://${newVal.accountSubdomain}.${baseName[1]}.${baseName[2]}.${baseName[3]}.${baseName[4]}&redirected=1`;
        //       } else {
        //         window.location = `https://${newVal.accountSubdomain}.${baseName[1]}.${baseName[2]}?user_id=${store.state.authenticated._id}&token=${store.state.authenticated.token}&redirected=1`;
        //       }
        //     }, 6000);

        //     // const currentRoute = router.currentRoute.value;
        //   }
        // });

        onMounted(() => {
          popupMessages.init.active = true;
            setTimeout(() => {
                if (section.value) {
                    sectionHeight.value = section.value.offsetHeight;
                    section.value.style.height = `${section.value.offsetHeight}px`;
                }
            });
            if (store.state.primaryUser?.subscription) {
                for (let prop in subscription) {
                    if (prop === 'plans') {
                        for (let plan in subscription.plans) {
                            subscription.plans[plan] = store.state.primaryUser?.subscription?.plans[plan];
                        }
                    }
                    else {
                        // subscription[prop] = store.state.primaryUser?.subscription[prop];
                        changeSubscriptionField(store.state.primaryUser?.subscription[prop], prop);
                    }
                }
                // subscriptionEditionMode.value = 'represent'
            }
            if (store.state.primaryUser.subscriptionAssigned) {
                for (let plan in store.state.primaryUser.subscriptionAssigned) {
                    for (let option in assigned) {
                        if ((option === plan) && store.state.primaryUser.subscriptionAssigned[plan]) {
                            assigned[option] = true;
                            openedActions.value = false;
                            for (let subProp in store.state.primaryUser.subscription.plans) {
                                if (subProp.includes(plan) && subProp.includes('Number')) {
                                    console.log(store.state.primaryUser.subscriptionAssigned);
                                    subscription.plans[subProp] = subscription.plans[subProp] - 1;
                                }
                            }
                        }
                    }
                }
            }
            subscription.plans.trial = true;
        }); 
        return {
            subscriptionEditionMode,
            subscription,
            stateSubscription,
            changeSubscriptionField,
            assigned,
            cancelAssigning,
            saveAssigned,
            openedActions,
            editHelperTooltip,
            addHelperTooltip,
            switchEditHelperTooltip,
            switchAddHelperTooltip,
            addSubscription,
            openEditForm,
            openNewForm,
            openUpdateForm,
            // subscriptionTooltip,
            // switchSubscriptionTooltip,
            section,
            boxHeader,
            toggleSection,
            reassignLeast,
            popupMessages,
            handleMissingSubscription
        };
    },
    components: { UserNotification }
}
</script>

<style lang="scss" scoped>
.section {
  .edit-subscription {
    position: relative;
  }
  .edit-helper-tooltip {
    width: max-content;
    padding: 0 15px;
    height: 30px;
    position: absolute;
    z-index: 999;

    background-color: #3C3838;
    color: #fff;
    top: 50px;
    left: -15px;
    text-indent: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: -7px;
      border-color: #3C3838;
      border-left: 8px solid hsl(0, 3%, 23%);
      border-bottom: 6px solid #3C3838;
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 27px;
      background: #3C3838;
      z-index: -1;
    }
  }
  .col-2 {
    text-align: center;
  }
}
</style>