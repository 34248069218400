<template>
  <TaskHeader />
  <CompletedTaskCalendar v-if="(editionMode === 'calendar') || (editionMode === 'represent')" />
  <CompletedTaskFilled v-if="editionMode === 'represent'" />
  <CompletedTasksList />
</template>

<script>
import { defineComponent, inject, provide, reactive, ref } from 'vue';
import TaskHeader from './TaskHeader.vue';
import CompletedTaskCalendar from './CompletedTaskCalendar.vue';
import CompletedTaskFilled from './CompletedTaskFilled.vue';
import CompletedTasksList from './CompletedTasksList.vue';

export default defineComponent({
    name: "CompletedTasks",
    components: { TaskHeader, CompletedTaskCalendar, CompletedTaskFilled, CompletedTasksList },
    setup() {

      const { isAuth } = inject('mainUI');

      const task = reactive({
        id: '',
        date: '',
        task: '',
        assignedTo: '',
        priority: '',
        startTime: '',
        completionTime: '',
        totalHours: '',
        taskStatus: '',
        comments: '',

        farm: '',
        bed: '',
        crop: '',
        equipment: '',
        input: '',
        brand: '',
        variety: '',
        quantity: '',
        numberOfItems: '',

        cropHarvested: '',
        amtHarvested: '',
        wastage: '',
        wastageCause: '',

        inputTask: false,
        harvestTask: false,
        otherTask: false,

        // salaried: false,
        taskType: false,

        recurring: false
      });

      const selectedTask = reactive({
        ...task
      });

      const defaultTask = {
        ...task
      }

      const editionMode = ref('calendar');

      const selectTask = (taskData, editMode) => {
        if (taskData) {
          for (let field in taskData) {
            task[field] = taskData[field]
            selectedTask[field] = taskData[field]
          }
        }
        editionMode.value = editMode;
      }

      provide('taskDetails', {
        task,
        defaultTask,
        selectedTask,
        editionMode,
        selectTask
      })

      return {
        editionMode,
        isAuth
      }
    }
})
</script>