<template>
  <span class="slider-arrow slider-arrow-prev" @click="swiper.slidePrev()"></span>
  <span class="slider-arrow slider-arrow-next" @click="swiper.slideNext()"></span>
</template>

<script>
  import { useSwiper } from 'swiper/vue';

  export default {
    setup() {
      const swiper = useSwiper();

      return {
        swiper
      }
    }
  }
</script>