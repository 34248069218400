<template>
<section class="section mt-1">
<div class="border-box">
<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Advertisement Questionnaire</h5>
</div>

<div class="content-block">

<div class="content-block-header d-flex align-items-center justify-content-between">
<h6 class="fw-semibold">1. What is your target geography?</h6>
<div class="icon-group">
  <div class="info-icon lg"
    @click="activateGeographyInsert"
  >
    <img src="@/assets/images/plus-green-md.png" alt="" />
  </div>
</div>
</div>

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

  <template v-if="advertisementGeographies.length > 0">
    <template
      v-for="(geographyEntity, index) in advertisementGeographies"
      v-bind:key="index + 'added-advertisement-geography-list-item'"
    >
      <template v-if="(geographiesEditionMode === 'edit') && geographyEntity.id === geographyEditForm.value.id">
        
        <div class="col-3">
        <div class="form-item">
        <p class="form-label" :class="{active: focused.city || (geographyEditForm.value.city.length > 0)}">City</p>
        <input type="text" class="form-control" 
          v-model="geographyEditForm.value.city"
          @focus="() => {
            activated.city = true;
            focused.city = true;
          }"
          @blur="() => {
            focused.city = false;
          }"
        >
        <p v-if="activated.city && geographyEditForm.value.city.length === 0" class="form-info red text-end">* Required</p>
        <p v-if="activated.city && geographyEditForm.value.city.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
        </div>
        </div>

        <div class="col-3">
        <div class="form-item">
        <p class="form-label" :class="{active: focused.state || (geographyEditForm.value.state.length > 0)}">State</p>
        <!-- <select class="form-control" 
          v-model="geographyEditForm.value.state"
          @click="() => {
            activated.state = true;
            focused.state = true;
          }"
          @change="() => {
            focused.state = false;
          }"
        >
        <option
          v-for="stateOption in stateOptions"
          v-bind:key="stateOption + 'state-option-billing'"
        >
          {{ stateOption }}
        </option>
        </select> -->
        <DropDownList
          :mainObject="'geographyEditForm'"
          :mainProperty="'state'"
          :injectionName="'advertisementDetails'"
          :optionKey="'state-option-adv-q'"
          :optionsList="stateOptions"
          :optionProperty="null"
          :optionPropertyTwo="null"
          @click="() => {
            activated.state = true;
          }"
        />
        <p v-if="activated.state && geographyEditForm.value.state.length === 0" class="form-info red text-end">* Required</p>
        </div>
        </div>

        <!-- <div class="col-3">
        <div class="form-item">
        <p class="form-label" :class="{active: focused.zip || (geographyEditForm.zip.length > 0)}">Zip</p>
        <input type="text" class="form-control" 
          v-model="geographyEditForm.zip"
          @focus="() => {
            activated.zip = true;
            focused.zip = true;
          }"
          @blur="() => {
            focused.zip = false;
          }"
        >
        <p v-if="activated.zip && geographyEditForm.zip.length === 0" class="form-info red text-end">* Required</p>
        <p v-if="activated.zip && geographyEditForm.zip.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
        </div>
        </div> -->

        <div class="col-3">
        <div class="form-item">
        <p class="form-label"  :class="{active: focused.country || (geographyEditForm.value.country.length > 0)}">Country</p>
        <!-- <select class="form-control" 
          v-model="geographyEditForm.value.country" 
          @click="() => {
            activated.country = true;
            focused.country = true;
          }"
          @change="() => {
            focused.country = false;
          }"
        >
        <option 
          v-for="countryOption in countryOptions"
          v-bind:key="countryOption + 'country-option-billing'"
        >
          {{ countryOption }}
        </option>
        </select> -->
        <DropDownList
          :mainObject="'geographyEditForm'"
          :mainProperty="'country'"
          :injectionName="'advertisementDetails'"
          :optionKey="'country-option-adv-q'"
          :optionsList="countryOptions"
          :optionProperty="null"
          :optionPropertyTwo="null"
          @click="() => {
            activated.country = true;
          }"
        />
        <p v-if="activated.country && geographyEditForm.value.country.length === 0" class="form-info red text-end">* Required</p>
        </div>
        </div>

        <div class="col-3 flexed-column">
          <button type="submit" class="btn green submit-button" @click="() => editGeography()">Save</button>
          <button class="btn-close-form" @click="() => closeGeographyEdit()"></button>
        </div>
      </template>

      <template v-else>
        <div class="row align-items-end">
          <div class="col-3">
            <p class="form-label-static" v-if="index === 0">City</p>
            <h6>{{ geographyEntity.city }}</h6>
          </div>

          <div class="col-3">
            <p class="form-label-static" v-if="index === 0">State</p>
            <h6>{{ geographyEntity.state }}</h6>
          </div>

          <!-- <div class="col-3">
            <p class="form-label-static" v-if="index === 0">Zip</p>
            <h6>{{ geographyEntity.zip }}</h6>
          </div> -->

          <div class="col-3">
            <p class="form-label-static" v-if="index === 0">Country</p>
            <h6>{{ geographyEntity.country }}</h6>
          </div>

          <div class="col-3">
            <div class="controls">
              <div @click="() => editGeographyEntity(geographyEntity.id)">
                <img src="@/assets/images/edit-icon-2.svg" alt="">
              </div>
              <div @click="() => removeGeographyEntity(geographyEntity.id)">
                <img src="@/assets/images/trash-2.svg" alt="">
              </div>
            </div>
          </div>

        </div>
      </template>

    </template>
  </template>

  <template v-if="geographiesInsertIsActive">
    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.city || (geography.city.length > 0)}">City</p>
    <input type="text" class="form-control" 
      v-model="geography.city"
      @focus="() => {
        activated.city = true;
        focused.city = true;
      }"
      @blur="() => {
        focused.city = false;
      }"
    >
    <p v-if="activated.city && geography.city.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.city && geography.city.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.state || (geography.state.length > 0)}">State</p>
    <!-- <select class="form-control" 
      v-model="geography.state"
      @click="() => {
        activated.state = true;
        focused.state = true;
      }"
      @change="() => {
        focused.state = false;
      }"
    >
    <option
      v-for="stateOption in stateOptions"
      v-bind:key="stateOption + 'state-option-billing'"
    >
      {{ stateOption }}
    </option>
    </select> -->
    <DropDownList
      :mainObject="'geography'"
      :mainProperty="'state'"
      :injectionName="'advertisementDetails'"
      :optionKey="'state-option-adv-q2'"
      :optionsList="stateOptions"
      :optionProperty="null"
      :optionPropertyTwo="null"
      @click="() => {
        activated.state = true;
      }"
    />
    <p v-if="activated.state && geography.state.length === 0" class="form-info red text-end">* Required</p>
    </div>
    </div>

    <!-- <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.zip || (geography.zip.length > 0)}">Zip</p>
    <input type="text" class="form-control" 
      v-model="geography.zip"
      @focus="() => {
        activated.zip = true;
        focused.zip = true;
      }"
      @blur="() => {
        focused.zip = false;
      }"
    >
    <p v-if="activated.zip && geography.zip.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.zip && geography.zip.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
    </div> -->

    <div class="col-3">
    <div class="form-item">
    <p class="form-label"  :class="{active: focused.country || (geography.country.length > 0)}">Country</p>
    <!-- <select class="form-control" 
      v-model="geography.country" 
      @click="() => {
        activated.country = true;
        focused.country = true;
      }"
      @change="() => {
        focused.country = false;
      }"
    >
    <option 
      v-for="countryOption in countryOptions"
      v-bind:key="countryOption + 'country-option-billing'"
    >
      {{ countryOption }}
    </option>
    </select> -->
    <DropDownList
      :mainObject="'geography'"
      :mainProperty="'country'"
      :injectionName="'advertisementDetails'"
      :optionKey="'country-option-adv-q2'"
      :optionsList="countryOptions"
      :optionProperty="null"
      :optionPropertyTwo="null"
      @click="() => {
        activated.country = true;
      }"
    />
    <p v-if="activated.country && geography.country.length === 0" class="form-info red text-end">* Required</p>
    </div>
    </div>

    <div class="col-3 flexed-column">
      <button type="submit" class="btn green submit-button" @click="addGeography">Add</button>
      <button class="btn-close-form" @click="() => closeGeography()"></button>
    </div>
  </template>

</div>
</div>
</div>

</div>

<div class="content-block">

<div class="content-block-header d-flex align-items-center justify-content-between">
<h6 class="fw-semibold">2. How long do you want the ad to run?</h6>
</div>

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.fromDate || (advertisementRange.fromDate.length > 0)}">From Date</p>
<input type="date" class="form-control"
 v-model="advertisementRange.fromDate"
 :style="{color: (focused.fromDate || advertisementRange.fromDate.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
  @focus="() => {
    activated.fromDate = true;
    focused.fromDate = true;
  }"
  @blur="() => {
    focused.fromDate = false;
  }"
>
<div class="date-icon"></div>
 <p v-if="activated.fromDate && advertisementRange.fromDate.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.toDate || (advertisementRange.toDate.length > 0)}">To Date</p>
<input type="date" class="form-control"
 v-model="advertisementRange.toDate"
 :style="{color: (focused.toDate || advertisementRange.toDate.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
  @focus="() => {
    activated.toDate = true;
    focused.toDate = true;
  }"
  @blur="() => {
    focused.toDate = false;
  }"
>
<div class="date-icon"></div>
 <p v-if="activated.toDate && advertisementRange.toDate.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.totalDays || (advertisementRange.totalDays.length > 0)}">Total Days</p>
<input type="text" class="form-control"
 v-model="advertisementRange.totalDays"
  @focus="() => {
    activated.totalDays = true;
    focused.totalDays = true;
  }"
  @blur="() => {
    focused.totalDays = false;
  }"
>
 <p v-if="activated.totalDays && advertisementRange.totalDays.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.totalDays && advertisementRange.totalDays.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

</div>
</div>
</div>

</div>

<div class="content-block">

<div class="content-block-header d-flex align-items-center justify-content-between">
<h6 class="fw-semibold">3. From 1 - 5, what position do you want to ad to appear?</h6>
</div>

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.whatPosition || (advertisementCost.whatPosition.length > 0)}">What position</p>
  <!-- <select class="form-control"
    v-model="advertisementCost.whatPosition"
    @click="() => {
      activated.whatPosition = true;
      focused.whatPosition = true;
    }"
    @change="() => {
      focused.whatPosition = false;
    }"
  >
    <option>1</option>
    <option>2</option>
    <option>3</option>
    <option>4</option>
    <option>5</option>
  </select> -->
  <DropDownList
    :mainObject="'advertisementCost'"
    :mainProperty="'whatPosition'"
    :injectionName="'advertisementDetails'"
    :optionKey="'position-option-adv-q'"
    :optionsList="[1,2,3,4,5]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    @click="() => {
      activated.whatPosition = true;
    }"
  />
 <p v-if="activated.whatPosition && advertisementCost.whatPosition.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.whatPosition && advertisementCost.whatPosition.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

</div>
</div>
</div>

</div>

<div class="content-block">

<div class="single-field-row d-flex justify-content-end">
<div class="form-item">
<p class="form-label">Ad Cost</p>
<span class="dollar-sign-prefix">$</span>
<input type="text" class="form-control" v-model="advertisementCost.adCost" />
</div>
</div>

<div class="single-field-row d-flex justify-content-end">
<div class="form-item">
<p class="form-label">Taxes</p>
<span class="percent-sign-prefix">%</span>
<input type="text" class="form-control" v-model="advertisementCost.taxes" />
</div>
</div>

<div class="single-field-row d-flex justify-content-end">
<div class="form-item">
<p class="form-label">Total Cost</p>
<span class="dollar-sign-prefix">$</span>
<input type="text" class="form-control" v-model="advertisementCost.totalCost" />
</div>
</div>

</div>

<div class="content-block">
<div class="btn-group justify-content-between">
<button type="submit" class="btn light-blue-20 btn-o">Advertisement FAQ <img src="@/assets/images/arrow-right.png" alt=""></button>
<button type="submit" class="btn green w-sm" @click="submitForm">Next</button>
</div>
</div>

</div>

</div>
</section>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, watch, watchEffect, provide } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import stateOptions from '@/sharedData/statesList.js'
import countryOptions from '@/sharedData/countriesList.js'
import moment from 'moment';
import { useRouter } from 'vue-router';
import DropDownList from '@/components/Shared/DropDownList.vue';

const prices = {
  1: {
    priceNumber: 5,
    priceId: 'price_1NihFVHpD3HYJIrg8TMzOi6Y'
  },
  2: {
    priceNumber: 4,
    priceId: 'price_1NihGbHpD3HYJIrg7nP1ey0f'
  },
  3: {
    priceNumber: 3,
    priceId: 'price_1NihGzHpD3HYJIrgsZkq3lho'
  },
  4: {
    priceNumber: 2,
    priceId: 'price_1NihHJHpD3HYJIrgyTSb5vKQ'
  },
  5: {
    priceNumber: 1,
    priceId: 'price_1NihHaHpD3HYJIrganCsiROB'
  }
}

export default defineComponent({
    name: 'AdvertisementQuestionnaireFirst',
    setup() {
        const store = useStore();
        const router = useRouter();
        const activated = reactive({
            city: false,
            state: false,
            // zip: false,
            country: false,
            edit: {
                product: false,
                brand: false,
                variety: false
            },
            fromDate: false,
            toDate: false,
            totalDays: false,
            whatPosition: false
        });
        const focused = reactive({
            city: false,
            state: false,
            // zip: false,
            country: false,
            edit: {
                product: false,
                brand: false,
                variety: false
            },
            fromDate: false,
            toDate: false,
            totalDays: false,
            whatPosition: false
        });
        const geography = reactive({
            city: '',
            state: '',
            // zip: '',
            country: ''
        });
        const geographyEditForm = ref({
            value: {
              city: '',
              state: '',
              // zip: '',
              country: ''
            }
        });
        const advertisementRange = reactive({
            fromDate: '',
            toDate: '',
            totalDays: ''
        });
        const advertisementCost = reactive({
            whatPosition: '',
            adCost: '',
            taxes: '',
            totalCost: ''
        });
        const geographiesInsertIsActive = ref(true);
        const advertisementGeographies = ref([]);
        const geographiesEditionMode = ref('');
        const activateGeographyInsert = () => {
            geographiesInsertIsActive.value = true;
        };
        const addGeography = () => {
            if (geography.city && geography.state && geography.country) {
                if (!geography.id) {
                    geography.id = uuid();
                }
                const formData = {
                    ...geography
                };
                // geographiesEditionMode.value === 'edit' ?
                //   store.dispatch("editVendorInventories", { ...formData }) :
                store.dispatch('addAdvertisementGeographies', { ...formData });
                closeGeography();
            }
        };
        const editGeography = () => {
            if (geographyEditForm.value.city && geographyEditForm.value.state &&
                geographyEditForm.value.country) {
                if (!geographyEditForm.value.id) {
                    geographyEditForm.value.id = uuid();
                }
                const formData = {
                    ...geographyEditForm.value
                };
                store.dispatch("editAdvertisementGeographies", { ...formData });
                closeGeographyEdit();
            }
        };
        const closeGeography = () => {
            for (let prop in geography) {
                geography[prop] = '';
                activated[prop] = false;
            }
            setTimeout(() => {
                geographiesInsertIsActive.value = false;
            }, 300);
        };
        const closeGeographyEdit = () => {
            for (let prop in geographyEditForm.value) {
                geographyEditForm.value[prop] = '';
                activated.edit[prop] = false;
            }
            setTimeout(() => {
                geographiesEditionMode.value = '';
            }, 300);
        };
        const editGeographyEntity = (entityId) => {
            if (geographiesEditionMode.value === 'edit') {
                return;
            }
            geographiesEditionMode.value = 'edit';
            const selected = {
                ...advertisementGeographies.value.find(ag => ag.id === entityId)
            };
            for (let prop in selected) {
                geographyEditForm.value[prop] = selected[prop];
            }
            console.log(geographyEditForm.value);
        };
        const removeGeographyEntity = (entityId) => {
            store.dispatch('removeAdvertisementGeographies', entityId);
        };
        const submitForm = () => {
            // add to state advertisimentAccount geographies + range section + cost section
            if ((advertisementGeographies.value.length === 0) ||
                !advertisementRange.fromDate || !advertisementRange.toDate ||
                !advertisementRange.totalDays || !advertisementCost.whatPosition) {
                if (advertisementGeographies.value.length === 0) {
                    activated.city = true;
                    activated.state = true;
                    activated.country = true;
                }
                if (!advertisementRange.fromDate)
                    activated.fromDate = true;
                if (!advertisementRange.toDate)
                    activated.toDate = true;
                if (!advertisementRange.totalDays)
                    activated.totalDays = true;
                if (!advertisementCost.whatPosition)
                    activated.whatPosition = true;
                return;
            }
            const formData = {
                advertisementGeographies: [...advertisementGeographies.value],
                ...advertisementRange,
                ...advertisementCost
            };
            store.dispatch('createPayment', {
                data: {
                    ...formData,
                    amount: Math.abs(prices[advertisementCost.whatPosition].priceNumber * advertisementGeographies.value.length * advertisementRange.totalDays),
                    primaryUserId: store.state.primaryUserTenant.id
                }
            });
            store.dispatch('saveAdvertisementAccount', {
                section: 'advertisementGeographies',
                data: [...advertisementGeographies.value]
            });
            store.dispatch('saveAdvertisementAccount', {
                section: 'advertisementRange',
                data: { ...advertisementRange }
            });
            store.dispatch('saveAdvertisementAccount', {
                section: 'advertisementCost',
                data: { ...advertisementCost }
            });
            router.push({
                name: 'advertisement',
                params: {
                    pagename: 'advertisement-questionnaire-second'
                }
            });
        };
        watch(store.state.advertisementGeographies, (newVal) => {
            if (newVal)
                advertisementGeographies.value = newVal;
            if (newVal.length === 0) {
                geographiesInsertIsActive.value = true;
            }
        });
        watch(() => (advertisementRange.fromDate), (newVal) => {
            if (newVal && advertisementRange.toDate) {
                advertisementRange.totalDays = moment(advertisementRange.toDate).diff(moment(newVal), 'days');
                activated.totalDays = true;
            }
        });
        watch(() => (advertisementRange.toDate), (newVal) => {
            if (newVal && advertisementRange.fromDate) {
                advertisementRange.totalDays = moment(newVal).diff(moment(advertisementRange.fromDate), 'days');
                activated.totalDays = true;
            }
        });
        watchEffect(() => {
            if ((advertisementGeographies.value.length > 0) && advertisementRange.fromDate &&
                advertisementRange.toDate && advertisementRange.totalDays && advertisementCost.whatPosition) {
                advertisementCost.adCost = Math.abs(advertisementGeographies.value.length * advertisementRange.totalDays * prices[advertisementCost.whatPosition].priceNumber);
                advertisementCost.totalCost = Math.abs((advertisementCost.adCost / 100) * (100 + advertisementCost.taxes)).toFixed(2);
            }
        });
        onMounted(() => {
            advertisementGeographies.value = store.state.advertisementGeographies;
            if (advertisementGeographies.value?.length > 0) {
                geographiesInsertIsActive.value = false;
            }
            advertisementCost.taxes = 6.87;
        });

        provide('advertisementDetails', {
          geographyEditForm,
          geography, 
          advertisementCost
        })
        return {
            stateOptions,
            countryOptions,
            activated,
            focused,
            geography,
            geographyEditForm,
            advertisementGeographies,
            geographiesInsertIsActive,
            geographiesEditionMode,
            advertisementRange,
            advertisementCost,
            activateGeographyInsert,
            addGeography,
            editGeography,
            closeGeography,
            closeGeographyEdit,
            editGeographyEntity,
            removeGeographyEntity,
            submitForm
        };
    },
    components: { DropDownList }
})
</script>

<style lang="scss" scoped>
h6 {
  font-size: var(--h6) !important;
}

.btn-close-form {
  width: 2rem;
  height: 2rem;
  background: url('@/assets/images/close.png');
  background-position: center;
  background-size: contain;
  margin-left: 3rem;
  cursor: pointer;
  margin-bottom: 1.5rem;
}

.date-icon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 3.7rem;
  right: 1rem;
  z-index: 8;
  pointer-events: none;
  border-radius: 1rem;
  background:url('@/assets/images/calendar-icon.svg') center center no-repeat;
  background-color: #fff;
}

.submit-button {
  width: 10rem;
  min-width: auto;
}

.flexed-column {
  display: flex;
  align-items: flex-end;
}

.dollar-sign-prefix,
.percent-sign-prefix {
  position: absolute;
  right: calc(17.4rem - 12px);
  font-size: var(--fs-sm);
  color: hsl(var(--gray));
  font-family: var(--fw-medium);
  &.percent-sign-prefix {
    right: calc(17.4rem - 12px);
  }
}

</style>