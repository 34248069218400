<template>
  <template v-if="notificationsList.length > 0">
    <div  class="notification-item"
      v-for="notification in notificationsList"
      v-bind:key="notification.id + '-notification'"
    >
      <div class="notification-item-header">
        <div v-if="!notification.isRead" class="notification-item-point">•</div>
        <!-- Upcoming Task:  -->
        {{ notification.task }}
      </div>
      <div class="notification-item-time">
        <div v-if="!notification.isRead" class="notification-item-point">•</div>
        Dt {{ 
        `${moment(`${notification.date} ${notification.startTime}`).format('MM/DD/YYYY hh:mm a')}`
      }}</div>
      <div class="notification-buttons">
        <!-- <button @click="() => updateTask(notification.id, notification.taskId)">Update</button> -->
        <button @click="() => dismissNotification(notification.id)">Dismiss</button>
      </div>
    </div>
  </template>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'NotificationsList',
  setup() {

    const store = useStore();

    const router = useRouter();

    const notifications = computed(() => store.state.notifications);

    const notificationsList = ref([]);

    const updateTask = (notificationId, taskId) => {
      store.dispatch('readNotifications', notificationId);

      router.push({ 
        name: 'farm',
        params: { 
          pagename: 'task',
          taskId
        } 
      })
    }

    const dismissNotification = (notificationId) => {
      store.dispatch('removeNotification', notificationId);
    }

    watch(() => ([...store.state.notifications]), () => {
      console.log(store.state.notifications);
      notificationsList.value = [...notifications.value]
    })

    onMounted(() => {
      notificationsList.value = [
      // {
      //   id: 'unique-1',
      //   isRead: false,
      //   task: 'task1',
      //   date: '2024-03-13',
      //   startTime: '20:18'
      // },
      // {
      //   id: 'unique-2',
      //   isRead: false,
      //   task: 'task2',
      //   date: '2024-03-13',
      //   startTime: '20:19'
      // },  
      ...notifications.value]
    })

    return {
      notificationsList,
      moment,
      updateTask,
      dismissNotification
    }
  }
})
</script>

<style lang="scss" scoped>
.notification-item {
  padding: 5px;
  border-bottom: 1px solid #7e8081;
  cursor: pointer;
  // color: hsl(var(--gray));
  .notification-item-header {
    // font-family: var(--fw-semibold);
    font-family: var(--fw-medium);
    // font-size: var(--fs-sm);
    font-size: 1rem;
    display: flex;
    align-items: center;
    // justify-content: space-between;
  }
  .notification-item-time {
    font-family: var(--fw-medium);
    // font-size: var(--fs-sm);
    font-size: 1rem;
    display: flex;
  }
  .notification-item-point {
  //   width: 1rem;
  //   height: 1rem;
  //   background-color: #f44336;
  //   border-radius: 1rem;
    width: max-content;
    margin-right: 5px;
  }
  .notification-buttons {
    display: flex;
    justify-content: flex-end;
    button {
      color: #f44336;
      font-family: var(--fw-medium);
      // font-size: var(--fs-sm);
      font-size: 1rem;
    }
  }
}
</style>