<template>

<section class="section mt-1">
  <div class="border-box">
  <div class="border-box-content">

  <div class="content-block d-flex justify-content-between user-profile-header">
  <h5 class="fw-semibold">Account Information</h5>
  <div class="icon-group">
    <span class="account-id-header form">Account ID: {{ authenticatedId }}</span>
  </div>
  </div>


<div class="content-block">
<div class="admin-update-form">
<div class="row justify-content-end">

<div class="col-auto">
<div class="form-item">
<p class="form-label-static">Email</p>
<input 
  type="text" 
  class="form-control" 
  v-model="mainDetails.email"
  @focus="() => {
    activated.email = true;
    focused.email = true;
  }"
  @blur="() => {
    focused.email = false;
  }"  
  :disabled="!updateIsActive"
>
<p v-if="activated.email && mainDetails.email.length === 0" class="form-info red text-end">* Required</p>
<p 
  v-if="activated.email && !focused.email && ((mainDetails.email.length > 0) && !/\S+@\S+\.\S+/.test(mainDetails.email))" 
  class="form-info red text-end"
  :style="{left: (activated.password && !focused.password && ((mainDetails.password.length > 0) && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._()-^])[A-Za-z\d@$!%*?&._()-^]{8,}$/.test(mainDetails.password))) ? '-40%' : ''}"
>Please use correct email format</p>
</div>
</div>

<div class="col-auto">
<div class="form-item password-field">
<p class="form-label-static">Password</p>

<div style="display: flex; align-items: center; position: relative;">
<input 
  :type="(passwordIsVisible && updateIsActive) ? 'text' : 'password'"
  class="form-control" 
  v-model="mainDetails.password"
  @focus="() => {
    activated.password = true;
    focused.password = true;
  }"
  @blur="() => {
    focused.password = false;
  }"
  :placeholder="passwordMask"
  :disabled="!updateIsActive"
/>
<div v-if="updateIsActive" class="password-icon" :class="{visible: passwordIsVisible, hidden: !passwordIsVisible}" @click="() => {passwordIsVisible = !passwordIsVisible}"></div>
</div>

<p v-if="activated.password && mainDetails.password.length === 0" class="form-info red text-end">* Required</p>
<p v-if="updateIsActive && registration && !activated.password" class="form-info red text-end">*Re-enter Password</p>
<p 
  v-if="updateIsActive && activated.password && !focused.password && ((mainDetails.password.length > 0) && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._()-^])[A-Za-z\d@$!%*?&._()-^]{8,}$/.test(mainDetails.password))" 
  class="form-info red text-end" style="width: 180%; left: -80%;" 
  :style="{left: (activated.email && !focused.email && ((mainDetails.email.length > 0) && !/\S+@\S+\.\S+/.test(mainDetails.email))) ? '-40%' : '-80%'}"
>Please use min 8 symbols at least 1 upper case, 1 lower case, 1 number, 1 special character</p>
</div>
</div>

<div class="col-auto update-btn-sm-wrap">
  <button v-if="updateIsActive" type="submit" class="btn green update-btn-sm" @click="() => switchUpdatable(false)">Save</button>
  <button v-else type="submit" class="btn green update-btn-sm" @click="() => switchUpdatable(true)">Update</button>
</div>

</div>
</div>
</div>

<div class="content-block toggle-switch-wrap justify-content-end mr-170">

<div class="toggle-switch">
  <label class="switch">
    <input type="checkbox" v-model="mainDetails.multifactor">
    <span class="slider round"></span>
  </label>
  <p class="fw-semibold gray">Multi Factor Authentication</p>
</div>

<div class="checkbox-group checkbox-multifactor" style="position: relative;">
  <div class="radio radio-mobile">
    <input 
      type="radio" 
      name="mobileEmail" 
      id="mobile" 
      value="mobile"
      v-model="mainDetails.multifactorType"
      :disabled="!mainDetails.multifactor"
    >
    <label for="mobile">Mobile</label></div>
  <div class="radio">
    <input 
      type="radio" 
      name="mobileEmail" 
      id="email" 
      value="email"
      v-model="mainDetails.multifactorType"
      :disabled="!mainDetails.multifactor"
    >
    <label for="email">Email</label></div>
    <p v-if="activated.multifactorType && (mainDetails.multifactorType.length === 0) && mainDetails.multifactor" class="form-info red text-end" style="position: absolute; right: 0; left: 0; text-align: center; bottom: -2rem;">* Required</p>
</div>
  <span 
    class="info-icon" 
    @mouseover="switchMfaTooltip(true)"
    @mouseout="switchMfaTooltip(false)"
  >
    <img src="@/assets/images/question-mark.png" alt="">
    <div class="mfa-tooltip" v-if="mfaTooltip">If enabled MFA sends code for additional account security</div>
  </span>
</div>

  <div class="content-block">
    <div class="content-row">
      <div class="content-row grid-4 grid-full">
        <div class="row">

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.accountName || (mainDetails.accountName.length > 0)}">Account Name
            <span 
              class="info-icon" 
              style="position: absolute;right: 0;top: 0;"
              @mouseover="switchAccountTooltip(true)"
              @mouseout="switchAccountTooltip(false)"
            >
              <img src="@/assets/images/question-mark.png" alt="">
              <div class="account-tooltip" v-if="accountTooltip">An account can be a company, a farm or a combination of individuals First and Last Name</div>
            </span>
          </p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.accountName"
            @focus="() => {
              activated.accountName = true;
              focused.accountName = true;
            }"
            @blur="() => {
              focused.accountName = false;
            }"
            placeholder="E.g., Savage Farms LLC"
          >
          <p v-if="activated.accountName && mainDetails.accountName.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.firstName || (mainDetails.firstName.length > 0)}">First Name</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.firstName"
            @focus="() => {
              activated.firstName = true;
              focused.firstName = true;
            }"
            @blur="() => {
              focused.firstName = false;
            }"
            placeholder="E.g., James Matthew"
          >
          <p v-if="activated.firstName && mainDetails.firstName.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.firstName && mainDetails.firstName.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.lastName || (mainDetails.lastName.length > 0)}">Last Name</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.lastName"
            @focus="() => {
              activated.lastName = true;
              focused.lastName = true;
            }"
            @blur="() => {
              focused.lastName = false;
            }"
            placeholder="E.g., Henry"
          >
          <p v-if="activated.lastName && mainDetails.lastName.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.lastName && mainDetails.lastName.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.title || (mainDetails.title.length > 0)}">Title</p>
          
          <!-- <select class="form-control" 
            v-if="compensations.length > 0"
            v-model="mainDetails.title"
            @click="() => {
              activated.title = true;
              focused.title = true;
            }"
            @change="() => {
              focused.title = false;
            }"
          >
            <option
              v-for="compensation in compensations"
              v-bind:key="compensation.id + 'compensation-title-option-main'"
            >{{ compensation.employeeTitle }}</option>
            <option>Other</option>
          </select> -->
          <template v-if="compensations.length > 0">
            <DropDownList
              :mainObject="'mainDetails'"
              :mainProperty="'title'"
              :injectionName="'mainDetails'"
              :optionKey="'compensation-title-option-main'"
              :optionsList="[...compensations, {employeeTitle: 'Other'}]"
              :optionProperty="'employeeTitle'"
              :optionPropertyOne="['grade', 'ownershipType']"
              :optionPropertyTwo="null"
              :defaultSelected="mainDetails.title"
              @click="() => {
                activated.title = true;
              }"
            />
          </template>
          
          <input 
            v-else
            type="text" 
            class="form-control" 
            v-model="mainDetails.title"
            @focus="() => {
              activated.title = true;
              focused.title = true;
              gradeFormDialog = true;
            }"
            @blur="() => {
              focused.title = false;
            }"
          >
          <p v-if="activated.title && mainDetails.title.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.mobilePhone || (mainDetails.mobilePhone.length > 0)}">Mobile Phone</p>
          <input 
            type="text" 
            class="form-control" 
            pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
            v-model="mainDetails.mobilePhone"
            ref="mobileField"
            @focus="() => {
              activated.mobilePhone = true;
              focused.mobilePhone = true;
            }"
            @blur="() => {
              phoneNumberFormat('mobilePhone')
              focused.mobilePhone = false;
            }"
            placeholder="E.g., (565) 236-9856"
          >
          <p v-if="activated.mobilePhone && mainDetails.mobilePhone.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.mobilePhone && !focused.mobilePhone && (((completedFields.mobilePhone && (mainDetails.mobilePhone.length !== 10) || (mainDetails.mobilePhone.length > 10))) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(mainDetails.mobilePhone))" class="form-info red text-end">Please use 10 Digit for phone</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.workPhone || (mainDetails.workPhone.length > 0)}">Work Phone</p>
          <input 
            type="text" 
            class="form-control" 
            pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
            v-model="mainDetails.workPhone"
            @focus="() => {
              activated.workPhone = true;
              focused.workPhone = true;
            }"
            @blur="() => {
              phoneNumberFormat('workPhone')
              focused.workPhone = false;
            }"
            placeholder="E.g., (952) 854-5866"
          >
          <p v-if="activated.workPhone && mainDetails.workPhone.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.workPhone && !focused.workPhone && (((completedFields.workPhone && (mainDetails.workPhone.length !== 10) || (mainDetails.workPhone.length > 10))) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(mainDetails.workPhone))" class="form-info red text-end">Please use 10 Digit for phone</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.address || (mainDetails.address.length > 0)}">Address 1</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.address"
            @focus="() => {
              activated.address = true;
              focused.address = true;
            }"
            @blur="() => {
              focused.address = false;
            }"
            placeholder="E.g., 7326 Taylor Dr"
          >
          <p v-if="activated.address && mainDetails.address.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.address && mainDetails.address.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.address2 || (mainDetails.address2.length > 0)}">Address 2</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.address2"
            @focus="() => {
              activated.address2 = true;
              focused.address2 = true;
            }"
            @blur="() => {
              focused.address2 = false;
            }"
            placeholder="E.g., Suite 500"
          >
          <p v-if="activated.address2 && mainDetails.address2.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.city || (mainDetails.city.length > 0)}">City</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.city"
            @focus="() => {
              activated.city = true;
              focused.city = true;
            }"
            @blur="() => {
              focused.city = false;
            }"
            placeholder="E.g., Savage"
          >
          <p v-if="activated.city && mainDetails.city.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.city && mainDetails.city.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.state || (mainDetails.state.length > 0)}">State</p>
          <!-- <select class="form-control" 
            v-model="mainDetails.state"
            @click="() => {
              activated.state = true;
              focused.state = true;
            }"
            @change="() => {
              focused.state = false;
            }"
          >
            <option v-for="stateOption in stateOptions"
              v-bind:key="stateOption + 'state-option-main'"
            >{{ stateOption }}</option>
          </select> -->
          <DropDownList
            :mainObject="'mainDetails'"
            :mainProperty="'state'"
            :injectionName="'mainDetails'"
            :optionKey="'state-option-main'"
            :optionsList="stateOptions"
            :optionProperty="null"
            :optionPropertyTwo="null"
            :defaultSelected="mainDetails.state"
            @click="() => {
              activated.state = true;
            }"
          />
          <p v-if="activated.state && mainDetails.state.length === 0" class="form-info red text-end">* Required</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.zip || (mainDetails.zip.length > 0)}">Zip</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.zip"
            @focus="() => {
              activated.zip = true;
              focused.zip = true;
            }"
            @blur="() => {
              focused.zip = false;
            }"
            placeholder="E.g., 55378"
          >
          <p v-if="activated.zip && mainDetails.zip.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.zip && mainDetails.zip.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.country || (mainDetails.country.length > 0)}">Country</p>
          <!-- <select class="form-control" 
            v-model="mainDetails.country"
            @click="() => {
              activated.country = true;
              focused.country = true;
            }"
            @change="() => {
              focused.country = false;
            }"
          >
            <option
              v-for="countryOption in countryOptions"
              v-bind:key="countryOption + 'country-option-main'"
            >{{ countryOption }}</option>
          </select> -->
          <DropDownList
            :mainObject="'mainDetails'"
            :mainProperty="'country'"
            :injectionName="'mainDetails'"
            :optionKey="'country-option-main'"
            :optionsList="countryOptions"
            :optionProperty="null"
            :optionPropertyTwo="null"
            :defaultSelected="mainDetails.country"
            @click="() => {
              activated.state = true;
            }"
          />
          <p v-if="activated.country && mainDetails.country.length === 0" class="form-info red text-end">* Required</p>
        </div>
      </div>

      </div>
      </div>
    </div>
  </div>

  <div class="content-block">
  <h6 class="sub-headding" ref="roleTypeSection">User Profile</h6>
  <div class="user-role">
  <div class="row">
  <div class="col-auto">
  <p class="title fw-semibold gray">Role Type</p>
  <p v-if="activated.userRole && mainDetails.userRole.length === 0" class="form-info red text-end">* Required</p>
  </div>
  <div class="col">
  <div class="row">
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="admin" id="admin" v-model="mainDetails.userRole"><label for="admin">Admin</label></div></div>
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="edit" id="editonly" v-model="mainDetails.userRole" :disabled="!mainDetails.submitted"><label for="editonly">Edit</label></div></div>
    <!-- <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="contribute" id="contributeonly" v-model="mainDetails.userRole" :disabled="!mainDetails.submitted"><label for="contributeonly">Contribute</label></div></div> -->
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="read" id="readonly" v-model="mainDetails.userRole" :disabled="!mainDetails.submitted"><label for="readonly">Read</label></div></div>
  </div>
  </div>
  </div>
  </div>
  </div>

  <div class="content-block d-lg-flex justify-content-between">
  <div class="checkbox-group task-type">
    <div v-if="employeeHelper" class="employee-tooltip">
      Allows for calculating wages!
    </div>
    <div class="row">
      <div class="col-auto" style="position: relative;">
        <p 
          class="title fw-semibold gray checkbox-title"
          @mouseover="switchEmployeeHelper(true)"
          @mouseout="switchEmployeeHelper(false)"  
          style="position: absolute; right: 1rem;"
        >User Type</p>
        <p class="title fw-semibold gray checkbox-title" style="opacity: 0; pointer-events: none;">Role Type</p>
        <p v-if="activated.employeeType && mainDetails.employeeType.length === 0" class="form-info red text-end" style="position: absolute; right: 1rem;">* Required</p>
      </div>
      <div class="col-auto">
        <div class="radio"><input type="radio" name="taskType" id="regular" value="salary"  v-model="mainDetails.employeeType" disabled><label for="regular">Salary</label></div>
      </div>
      <div class="col-auto">
        <div class="radio"><input type="radio" name="taskType" id="overtime" value="parttime" v-model="mainDetails.employeeType" disabled><label for="overtime">Part-time</label></div>
      </div>
      <div class="col-auto task-type-other">
        <div class="radio"><input type="radio" name="taskType" id="other" value="other" v-model="mainDetails.employeeType" disabled><label for="other">Other</label></div>
      </div>
    </div>
  </div>
  </div>

  <div class="content-block" v-if="mainDetails.employeeType !== 'other'">
  <div class="user-grade">
  <div class="row">
  <div class="col-auto" style="position: relative;">
    <p class="title fw-semibold gray checkbox-title" style="opacity: 0; pointer-events: none;">Role Type</p>
    <p class="title fw-semibold gray checkbox-title"
      style="position: absolute; right: 1rem;top: 0; bottom: 0; display: flex; align-items: center;"
    >Grade</p>
    <p v-if="activated.grade && (`${mainDetails.grade}`.length === 0) && (mainDetails.employeeType !== 'other')" class="form-info red text-end" style="position: absolute; right: 1rem; bottom: 1rem;">* Required</p>
  </div>
  <div v-if="compensations.length > 0" class="col-auto grade-list">
    <!-- <select class="form-control sm" 
      v-model="mainDetails.grade"
      @click="() => {
        activated.grade = true;
        focused.grade = true;
      }"
      @change="() => {
        focused.grade = false;
      }"
      disabled
    >
      <option
        v-for="compensation in compensations"
        v-bind:key="compensation.id + 'compensation-option-main'"
      >{{ compensation.grade }}</option>
    </select> -->
    <DropDownList
      :mainObject="'mainDetails'"
      :mainProperty="'grade'"
      :injectionName="'mainDetails'"
      :optionKey="'compensation-option-main'"
      :optionsList="compensations"
      :optionProperty="'grade'"
      :optionPropertyTwo="null"
      :maxHeight="50"
      :isDisabled="true"
      :defaultSelected="mainDetails.grade"
      @click="() => {
        activated.grade = true;
      }"
    />
  </div>
  <div class="col-auto" v-else>
    <input type="text" class="form-control sm" v-model="mainDetails.grade" @click="() => {addedGrade = true}" />
  </div>
  </div>
  </div>
  </div>

  <FileAttachments :section="'primary'" />

  <div class="content-section">
  <div class="buttons">
  <div class="row">
  <div class="col-auto">
    <button @click="submitForm" type="submit" class="btn green">Save</button>
  </div>
  <div class="col-auto">
    <button @click="cancelChanges" type="submit" class="btn light-blue-20 btn-o">Cancel</button>
  </div>
  </div>
  </div>
  </div>
  
  </div>
  </div>
</section><!--//End Hero-->

<div v-if="notUpdated.active">
  <UserNotification
    v-bind:messageText="notUpdated.message"
  />
</div>

<div v-if="savePassword.active">
  <UserNotification
    v-bind:messageText="savePassword.message"
  />
</div>

<div v-if="missedField">
  <UserNotification
    v-bind:messageText="'Please add the required data'"
  />
</div>

<div class="add-grade-popup" v-if="addedGrade">
  
  <div class="modal modal-md">
  <span class="modal-close" @click="() => redirectToGrade()"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">Please add employee grade in the employee compensation section</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => redirectToGrade()"
        class="approve-button btn green" 
      >Ok</button>
  </div>
  </div>
  </div>
  
</div>


<template  v-if="gradeFormDialog">
  <!-- <draggable-resizable-vue
    v-model:x="element.x"
    v-model:y="element.y"
    v-model:h="element.height"
    v-model:w="element.width"
    v-model:active="element.isActive"
    :resizable="false"
  > -->
    <div class="form-modal">
      <span class="form-modal-close" @click="() => {gradeFormDialog = false}"><img src="@/assets/images/close-green-xs.png" alt=""></span>
      <EmployeeCompensation v-bind:dialogView="true" />
    </div>
  <!-- </draggable-resizable-vue> -->
</template>

<template v-if="updateIsActive && !registration">
  <div class="submit-popup-wrapper-fixed">
    <div class="form-modal">
    <!-- <span class="form-modal-close" @click="() => {gradeFormDialog = false}"><img src="@/assets/images/close-green-xs.png" alt=""></span> -->
      <ResetPasswordPopup />
    </div>
  </div>
</template>

</template>

<script>
import { inject, ref, watch, reactive, computed, onMounted, provide } from "vue";
// import { computed } from '@vue/reactivity';
import { useStore } from "vuex";
import stateOptions from '@/sharedData/statesList.js'
import countryOptions from '@/sharedData/countriesList.js'
import FileAttachments from "@/components/Shared/FileAttachments.vue";
import UserNotification from "@/components/Shared/UserNotification.vue";
import { useRouter } from "vue-router";
import EmployeeCompensation from "../Expenses/EmployeeCompensation.vue";
import DropDownList from "@/components/Shared/DropDownList.vue";
import ResetPasswordPopup from "@/components/Pages/ResetPassword/ResetPasswordPopup.vue";
// import DraggableResizableVue from 'draggable-resizable-vue3'

export default {
    name: "ProfileDetails",
    setup() {
        const store = useStore();
        const router = useRouter();

        const { mainDetails, changeMainField, profileView } = inject("mainDetails");
        const defaultMainDetails = { ...mainDetails };
        const emailValidation = ref(true);
        const phoneValidation = ref(false);
        const disabledSubmit = ref(true);

        const employeeHelper = ref(false);

        const passwordIsVisible = ref(false);

        const updateIsActive = ref(false);

        const addedGrade = ref(false);

        const roleTypeSection = ref(null);

        const accountTooltip = ref(false);

        const authenticatedId = ref('');

        const registration = ref(false);

        const element = ref({
          x: '50%',
          y: '50%',
          width: 800,
          height: 'calc(100svh - 30px)',
          isActive: false,
        })
        
        // const { userRole } = inject("userRole");
        const activated = reactive({
          email: false,
          password: false,
          multifactorType: false,
          accountName: false,
          title: false,
          firstName: false,
          lastName: false,
          mobilePhone: false,
          workPhone: false,
          address: false,
          address2: false,
          city: false,
          state: false,
          zip: false,
          country: false,
          userRole: false,
          employeeType: false,
          grade: false
        })
        const focused = reactive({
          email: false,
          password: false,
          accountName: false,
          title: false,
          firstName: false,
          lastName: false,
          mobilePhone: false,
          workPhone: false,
          address: false,
          address2: false,
          city: false,
          state: false,
          zip: false,
          country: false
        });
        
        const completedFields = reactive({
          mobilePhone: false,
          workPhone: false
        })

        const notUpdated = reactive({
          active: false,
          message: 'Please save, before proceeding'
        });

        const savePassword = reactive({
          active: false,
          message: 'Please re-enter your password and click save'
        });

        const compensationsRaw = computed(() => store.state.compensations);

        const compensations = ref([]);

        const gradeFormDialog = ref(false);

        const mobileField = ref(null);

        const passwordMask = ref('');

        const mfaTooltip = ref(false);

        const missedField = ref(false);

        const phoneNumberFormat = (phoneType) => {
          if (mainDetails[phoneType].length > 0) {
            if (mainDetails[phoneType][0] !== "(") {
                const arrStr = mainDetails[phoneType].split("");
                mainDetails[phoneType] = `(${arrStr.filter((num, indx) => {
                    if (indx < 3)
                        return num;
                }).join("")}) ${arrStr.filter((num, indx) => {
                    if ((indx >= 3) && (indx < 6))
                        return num;
                }).join("")}-${arrStr.filter((num, indx) => {
                    if (indx >= 6)
                        return num;
                }).join("")}`;
                completedFields[phoneType] = true;
            }
          }
        };

        const switchEmployeeHelper = (toggle) => {
          employeeHelper.value = toggle;
        }

        const switchAccountTooltip = (toggle) => {
          accountTooltip.value = toggle;
        }

        const switchMfaTooltip = (toggle) => {
          mfaTooltip.value = toggle;
        }

        const switchUpdatable = (isUpdatable) => {
          if (isUpdatable) {
            updateIsActive.value = true;
          } else {
            updateIsActive.value = false;
            if (mainDetails.email && mainDetails.password) {
              // updateIsActive.value = false;

              store.dispatch("savePrimaryUser", {
                section: "mainDetails",
                data: {
                  ...mainDetails
                }
              });
            } else {
              console.log(mainDetails.email, mainDetails.password, 'notUpdated');
              notUpdated.active = true;

              setTimeout(() => {
                notUpdated.active = false;
              }, 5000)
            }
          }
        }

        const cancelChanges = () => {
            for (let prop in defaultMainDetails) {
                mainDetails[prop] = defaultMainDetails[prop];
            }
            changeMainField("represent", "mode");
        };
        const submitForm = () => {
          const params = new URLSearchParams(document.location.search);
          
          const registration = params.get('registration');
          
            if (!mainDetails.email || (registration && !mainDetails.password) || !mainDetails.firstName ||
                !mainDetails.lastName || !mainDetails.mobilePhone ||
                !mainDetails.address || !mainDetails.city ||
                !mainDetails.state || !mainDetails.zip || !mainDetails.country ||
                !mainDetails.userRole || !mainDetails.employeeType || 
                ((mainDetails.employeeType !== 'other') && !mainDetails.grade)) {
                if (!mainDetails.email) activated.email = true;
                if (updateIsActive.value && !mainDetails.password) activated.password = true;
                if (!mainDetails.firstName) activated.firstName = true;
                if (!mainDetails.lastName) activated.lastName = true;
                if (!mainDetails.mobilePhone) activated.mobilePhone = true;
                if (!mainDetails.address) activated.address = true;
                if (!mainDetails.city) activated.city = true;
                if (!mainDetails.state) activated.state = true;
                if (!mainDetails.zip) activated.zip = true;
                if (!mainDetails.country) activated.country = true;
                if (!mainDetails.userRole) activated.userRole = true;
                if (!mainDetails.employeeType) activated.employeeType = true;
                if ((mainDetails.employeeType !== 'other') && !mainDetails.grade) activated.grade = true;
                if (registration && (!mainDetails.email || !mainDetails.password)) {
                  notUpdated.active = true;

                  setTimeout(() => {
                    notUpdated.active = false;
                  }, 5000)
                }
                if (mainDetails.multifactor && !mainDetails.multifactorType) {
                  activated.multifactorType = true;
                }

                setTimeout(() => {
                  const firstError = document.getElementsByClassName('form-info red text-end')[0];
                
                  const viewPortOffset = firstError.getBoundingClientRect();

                  console.log(Math.abs(viewPortOffset.top + 72));

                  document.getElementsByTagName('main')[0].scroll({
                    top: firstError ? Math.abs(viewPortOffset.top + 72) : 0,
                    left: 0,
                    behavior: 'smooth'
                  })
                }, 100);

                missedField.value = true;

                setTimeout(() => {
                  missedField.value = false;
                }, 5100);

                return;
            }
            changeMainField(true, "submitted");

            // if (mainDetails.password === '********') {
            //   delete mainDetails.password;
            // }

            store.dispatch("savePrimaryUser", {
                section: "mainDetails",
                data: {
                  ...mainDetails
                }
            });

            if (profileView) {
              store.dispatch('updateCompanyData', {data: {
                ...mainDetails
              }});
            }

            // Dispatched after request in user creation/update action
            // store.state.primaryUserTenant.id ?
            //   store.dispatch('updateTenant', {
            //     data: {
            //       ...mainDetails
            //     },
            //     section: 'mainDetails'
            //   }) :
            //   store.dispatch('createTenant', {
            //     data: {
            //       ...mainDetails
            //     },
            //     section: 'mainDetails'
            //   });

            changeMainField("represent", "mode");
            // setTimeout(() => {
            //   changeMainField(true, 'activated')
            // }, 2000);
        };

        const redirectToGrade = () => {
          addedGrade.value = false;

          changeMainField("edit", "mode");

          changeMainField(false, "submitted");

          store.dispatch("savePrimaryUser", {
            section: "mainDetails",
            data: {
              ...mainDetails
            }
          });

          router.push({
            name: 'admin', 
            params: { 
              pagename: 'employee-compensation',
              source: 'user-profile',
              employeeType: mainDetails.employeeType,
              assigned: 'primary'
            } 
          })
        }

        watch(mainDetails, () => {
            disabledSubmit.value = !mainDetails.userRole || !mainDetails.firstName ||
                !mainDetails.lastName || !mainDetails.mobilePhone ||
                !mainDetails.address || !mainDetails.city ||
                !mainDetails.state || !mainDetails.zip || !mainDetails.country;
        });
        watch(emailValidation, (newVal, oldVal) => {
            console.log(newVal, oldVal);
            phoneValidation.value = !emailValidation.value;
        });
        watch(phoneValidation, (newVal, oldVal) => {
            console.log(newVal, oldVal);
            emailValidation.value = !phoneValidation.value;
        });

        watch(() => (mainDetails.multifactor), () => {
          mainDetails.multifactorType = '';
        })

        // watch(() => (mainDetails.employeeType), (newVal) => {
        //   if (newVal) {
        //     compensations.value = compensationsRaw.value.filter(comp => comp.employeeType === newVal);
        //     (compensations.value.length > 0) && compensations.value.sort((a,b) => {if (a.employeeTitle > b.employeeTitle) {return 1} if (a.employeeTitle < b.employeeTitle) {return -1}});
        //   }
        // })

        watch(() => ([...compensationsRaw.value]), (newVal) => {
          if (newVal) {
            compensations.value = [...compensationsRaw.value]; //.filter(comp => comp.employeeType === mainDetails.employeeType);
            (compensations.value.length > 0) && compensations.value.sort((a,b) => {if (a.employeeTitle > b.employeeTitle) {return 1} if (a.employeeTitle < b.employeeTitle) {return -1}});
            
            const selectedCompensation = compensationsRaw.value.find(c => c.employeeTitle === (mainDetails.title && mainDetails.title.split(' - ')[0]));

            if (selectedCompensation) {
              mainDetails.grade = selectedCompensation.grade;
              mainDetails.employeeType = selectedCompensation.employeeType;
            }
          }
        })

        watch(() => (mainDetails.title), (newVal) => {
          if (newVal === 'Other') {
            gradeFormDialog.value = true;
          }
          if (newVal) {
            const selectedCompensation = compensationsRaw.value.find(c => c.employeeTitle === (mainDetails.title && mainDetails.title.split(' - ')[0]));

            if (selectedCompensation) {
              mainDetails.grade = selectedCompensation.grade;
              mainDetails.employeeType = selectedCompensation.employeeType;
            }
          }
        })

        watch(gradeFormDialog, (newVal, oldVal) => {
          if (!newVal && oldVal) {
            setTimeout(() => {
              mobileField.value.focus();
            }, 10);
          }
        })

        watch(() => ({...store.state.authenticated}), (newVal, oldVal) => {
          authenticatedId.value = newVal._id || newVal.id

          if ((newVal.email && !oldVal.email) || (newVal?.mainDetails?.email && !oldVal?.mainDetails?.email)) {
            const params = new URLSearchParams(document.location.search);

            registration.value = params.get('registration');

            if (registration.value) {
              updateIsActive.value = true;
              mainDetails.email = newVal.email;
            }

          }

          if (newVal.email && (updateIsActive.value === false)) {
            passwordMask.value = '********';
          }
        });

        watch(updateIsActive, (newVal) => {
          if (newVal) {
            passwordMask.value = '';
          } else {
            if (store.state.authenticated.email) {
              passwordMask.value = '********';
            }
          }
        });

        watch(() => (mainDetails.password), (newVal) => {
          if (newVal === undefined) {
            mainDetails.password = '';
          }
        });

        onMounted(() => {
          /*
          if (mainDetails.employeeType === 'salary') {
            compensations.value = compensationsRaw.value.filter(comp => comp.employeeType === 'salary');
            (compensations.value.length > 0) && compensations.value.sort((a,b) => {if (a.employeeTitle > b.employeeTitle) {return 1} if (a.employeeTitle < b.employeeTitle) {return -1}});
          }
          if (mainDetails.employeeType === 'parttime') {
            compensations.value = compensationsRaw.value.filter(comp => comp.employeeType === 'parttime');
            (compensations.value.length > 0) && compensations.value.sort((a,b) => {if (a.employeeTitle > b.employeeTitle) {return 1} if (a.employeeTitle < b.employeeTitle) {return -1}});
          }
          */
          compensations.value = [...compensationsRaw.value];
          (compensations.value.length > 0) && compensations.value.sort((a,b) => {if (a.employeeTitle > b.employeeTitle) {return 1} if (a.employeeTitle < b.employeeTitle) {return -1}});
          if (router.currentRoute.value.params.source === 'employee-compensation') {
            setTimeout(() => {
              document.getElementsByTagName('main')[0].scroll({
                top: roleTypeSection?.value?.offsetTop - 100,
                left: 0,
                behavior: 'smooth'
              })
            })
          }

          authenticatedId.value = store.state.authenticated._id || store.state.authenticated.id;
          
          if (store.state.authenticated.email && (updateIsActive.value === false)) {
            passwordMask.value = '********';
          }

          const params = new URLSearchParams(document.location.search);

          const registration = params.get('registration')

          if (registration) {
            savePassword.active = true;
          }

          if (mainDetails.password === undefined) {
            mainDetails.password = '';
          }
        })

        provide('userProfileDetails', {
          mainDetails,
          gradeFormDialog
        });

        provide('passwordReset', {
          updateIsActive,
          switchUpdatable
        });

        return {
            mainDetails,
            stateOptions,
            countryOptions,
            disabledSubmit,
            emailValidation,
            phoneValidation,
            submitForm,
            cancelChanges,
            changeMainField,
            phoneNumberFormat,
            activated,
            focused,
            employeeHelper,
            switchEmployeeHelper,
            notUpdated,
            passwordIsVisible,
            updateIsActive,
            switchUpdatable,
            completedFields,
            addedGrade,
            redirectToGrade,
            compensations,
            roleTypeSection,
            gradeFormDialog,
            element,
            mobileField,
            switchAccountTooltip,
            accountTooltip,
            authenticatedId,
            registration,
            passwordMask,
            mfaTooltip,
            switchMfaTooltip,
            savePassword,
            missedField
        };
    },
    components: {
    FileAttachments,
    UserNotification,
    EmployeeCompensation,
    DropDownList,
    ResetPasswordPopup
}
};
</script>

<style lang="scss" scoped src="@/styles/pages/UserProfile/ProfileDetailsForm.scss"></style>
