<template>

<div class="border-box-content">

<div class="content-block">
<h5 class="fw-semibold">Plan Crop</h5>
</div>

<div class="content-block">

<div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.year || (crop.year.length > 0)}">Year</p>
<!-- <select class="form-control"
  v-model="crop.year"
  @click="() => {
    activated.year = true;
    focused.year = true;
  }"
  @change="() => {
    focused.year = false;
  }"
>
  <option v-for="yearOption in cropYears"
    v-bind:key="yearOption + 'year-option-crop'"
  >{{ yearOption }}</option>
</select> -->
<DropDownList
  :mainObject="'crop'"
  :mainProperty="'year'"
  :injectionName="'cropEdition'"
  :optionKey="'year-option-crop'"
  :optionsList="cropYears"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="crop.year"
  @click="() => {
    activated.year = true;
  }"
/>
<p v-if="activated.year && crop.year.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.season || (crop.season.length > 0)}">Season</p>
<!-- <select class="form-control"
  v-model="crop.season"
  @click="() => {
    activated.season = true;
    focused.season = true;
  }"
  @change="() => {
    focused.season = false;
  }"
>
  <option v-for="seasonOption in cropSeasons"
    v-bind:key="seasonOption + 'season-option-crop'"
  >{{ seasonOption }}</option>
</select> -->
<DropDownList
  :mainObject="'crop'"
  :mainProperty="'season'"
  :injectionName="'cropEdition'"
  :optionKey="'season-option-crop'"
  :optionsList="cropSeasons"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="crop.season"
  @click="() => {
    activated.season = true;
  }"
/>
<p v-if="activated.season && crop.season.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.farmName || (crop.farmName.length > 0)}">Farm</p>
<!-- <select class="form-control"
  v-model="crop.farmName"
  @click="() => {
    activated.farmName = true;
    focused.farmName = true;
  }"
  @change="() => {
    focused.farmName = false;
  }"
>
  <option v-for="farmOption in farmOptions"
    v-bind:key="farmOption + 'farm-option-crop'"
  >{{ farmOption }}</option>
</select> -->
<DropDownList
  :mainObject="'crop'"
  :mainProperty="'farmName'"
  :injectionName="'cropEdition'"
  :optionKey="'farm-option-crop'"
  :optionsList="farmOptions"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="crop.farmName"
  @click="() => {
    activated.farmName = true;
  }"
/>
<p v-if="activated.farmName && crop.farmName.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3" v-if="(bedOptions.length > 0)">
<div class="form-item">
<p class="form-label" :class="{active: focused.bed || (crop.bed.length > 0)}">Bed</p>
<!-- <select class="form-control"
  v-model="crop.bed"
  @click="() => {
    activated.bed = true;
    focused.bed = true;
  }"
  @change="() => {
    focused.bed = false;
  }"
>
  <option v-for="bedOption in bedOptions"
    v-bind:key="bedOption + 'bed-option-crop'"
  >{{ bedOption }}</option>
</select> -->
<DropDownList
  :mainObject="'crop'"
  :mainProperty="'bed'"
  :injectionName="'cropEdition'"
  :optionKey="'bed-option-crop'"
  :optionsList="bedOptions"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="crop.bed"
  @click="() => {
    activated.bed = true;
  }"
/>
<p v-if="activated.bed && crop.bed.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.acreage || (crop.acreage.length > 0)}">Acerage</p>
<input type="text" class="form-control"
  v-model="crop.acreage"
  @focus="() => {
    activated.acreage = true;
    focused.acreage = true;
  }"
  @blur="() => {
    focused.acreage = false;
  }"
  placeholder="E.g., 125"
>
<p v-if="activated.acreage && crop.acreage.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.acreage && crop.acreage.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.cropCycle || (crop.cropCycle.length > 0)}">Crop Cycle</p>
<!-- <input type="text" class="form-control"
  v-model="crop.cropCycle"
  @focus="() => {
    activated.cropCycle = true;
    focused.cropCycle = true;
  }"
  @blur="() => {
    focused.cropCycle = false;
  }"
> -->
  <DropDownList
    :mainObject="'crop'"
    :mainProperty="'cropCycle'"
    :injectionName="'cropEdition'"
    :optionKey="'cycle-option-crop-planned'"
    :optionsList="cropCycles"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="crop.cropCycle"
    @click="() => {
      activated.cropCycle = true;
    }"
  />
<p v-if="activated.cropCycle && crop.cropCycle.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.cropCategory || (crop.cropCategory.length > 0)}">Crop Category</p>
<input type="text" class="form-control"
v-model="crop.cropCategory"
  @focus="() => {
    activated.cropCategory = true;
    focused.cropCategory = true;
  }"
  @blur="() => {
    focused.cropCategory = false;
  }"
  placeholder="E.g., Grain"
>
<p v-if="activated.cropCategory && crop.cropCategory.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.crop || (crop.crop.length > 0)}">Crop</p>
<input type="text" class="form-control"
  v-model="crop.crop"
  @focus="() => {
    activated.crop = true;
    focused.crop = true;
  }"
  @blur="() => {
    focused.crop = false;
  }"
  placeholder="E.g., Rice"
>
<p v-if="activated.crop && crop.crop.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.crop && crop.crop.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.variety || (crop.variety.length > 0)}">Variety</p>
<input type="text" class="form-control"
  v-model="crop.variety"
  @focus="() => {
    activated.variety = true;
    focused.variety = true;
  }"
  @blur="() => {
    focused.variety = false;
  }"
  placeholder="E.g., Basmati"
>
<p v-if="activated.variety && crop.variety.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.dateSeed || (crop.dateSeed.length > 0)}">Date Seed</p>
<input type="date" class="form-control"
  v-model="crop.dateSeed"
  :style="{color: (focused.dateSeed || crop.dateSeed.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
  pattern="\d{2}/\d{2}/\d{4}"
  @focus="() => {
    activated.dateSeed = true;
    focused.dateSeed = true;
  }"
  @blur="() => {
    focused.dateSeed = false;
  }"
>
  <div class="date-icon"></div>
<p v-if="activated.dateSeed && crop.dateSeed.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.dateSeed && crop.dateSeed.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.harvestDate || (crop.harvestDate.length > 0)}">Date Harvest</p>
<input type="date" class="form-control"
  :style="{color: (focused.harvestDate || crop.harvestDate.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
  pattern="\d{2}/\d{2}/\d{4}"
 v-model="crop.harvestDate"
  @focus="() => {
    activated.harvestDate = true;
    focused.harvestDate = true;
  }"
  @blur="() => {
    focused.harvestDate = false;
  }"
>
  <div class="date-icon"></div>
<p v-if="activated.harvestDate && crop.harvestDate.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.harvestDate && crop.harvestDate.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.projHarvestAmount || (crop.projHarvestAmount.length > 0)}">{{projHarvestAmountLabel}}</p>
<input type="text" class="form-control"
 v-model="crop.projHarvestAmount"
  @focus="() => {
    activated.projHarvestAmount = true;
    focused.projHarvestAmount = true;
    changeHarvestLabel(true);
  }"
  @blur="() => {
    focused.projHarvestAmount = false;
    changeHarvestLabel();
  }"
  placeholder="E.g., 500"
>
<p v-if="activated.projHarvestAmount && crop.projHarvestAmount.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.projHarvestAmount && crop.projHarvestAmount.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.projWastage || (crop.projWastage.length > 0)}">{{projWastageLabel}}</p>
<input type="text" class="form-control"
  v-model="crop.projWastage"
  @focus="() => {
    activated.projWastage = true;
    focused.projWastage = true;
    changeWastageLabel(true);
  }"
  @blur="() => {
    focused.projWastage = false;
    changeWastageLabel();
  }"
  placeholder="E.g., 10"
>
<p v-if="activated.projWastage && crop.projWastage.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

</div>
</div>

</div>

<div class="content-block">
<div class="content-header">
<h6 class="fw-semibold">Set Notification</h6>
</div>
<div class="content-section">


<div class="checkbox-group-wrap">

<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="dateSeed" v-model="crop.isDateSeed"><label for="dateSeed">Date Seed</label></div>
<div class="field-box">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control sm" v-model="crop.seedDays">
</div>
</div>

<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="dateHarvest" v-model="crop.isDateHarvest"><label for="dateHarvest">Date Harvest</label></div>
<div class="field-box">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control sm" v-model="crop.harvestDays">
</div>
</div>

</div>


</div>
</div>

<div class="content-block">
<div class="buttons form-buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
</div>
</div>
</div>

</div>

<div v-if="missedField">
  <UserNotification
    v-bind:messageText="'Please add the required data'"
  />
</div>

  <!-- <q-expansion-item
    class="overflow-hidden rounded-borders plan-crop-form"
    style="border-radius: 10px"
    popup
    :header-style="{
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      fontWeight: 700,
    }"
    default-opened
    label=""
  >
    <template v-slot:header>
      <q-item-section>
        <span class="header">{{ sectionHeader }}</span>
      </q-item-section>
    </template>
    <q-card>
      <q-card-section class="flex plan-crop-form-card-section">

    <div class="row wrapper">

      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        label="Year"  
        :model-value="crop.year"  
      >
        <template v-slot:control>
          <q-select
            color="bhoomi"
            style="width: 100%"
            outlined
            v-model="crop.year"
            :options="cropYears"
            :rules="[
              (val) => !!val || '* Required'
            ]"
          />
        </template>
      </q-field>

      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        label="Season"  
        :model-value="crop.season"  
      >
        <template v-slot:control>
          <q-select
            color="bhoomi"
            style="width: 100%"
            outlined
            v-model="crop.season"
            :options="cropSeasons"
            :rules="[
              (val) => !!val || '* Required'
            ]"
          />
        </template>
      </q-field>

      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        label="Farm Name"  
        :model-value="crop.farmName"  
      >
        <template v-slot:control>
          <q-select
            color="bhoomi"
            style="width: 100%"
            outlined
            v-model="crop.farmName"
            :options="farmOptions"
            :rules="[
              (val) => !!val || '* Required'
            ]"
          />
        </template>
      </q-field>

      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        label="Bed"  
        :model-value="crop.bed"
        v-if="(bedOptions.length > 0)"
      >
        <template v-slot:control>
          <q-select
            color="bhoomi"
            style="width: 100%"
            outlined
            v-model="crop.bed"
            :options="bedOptions"
            :rules="[
              (val) => !!val || '* Required'
            ]"
          />
        </template>
      </q-field>

      <q-field 
        v-if="(bedOptions.length <= 0)"
        borderless 
        class="outer-label"
        color="bhoomi"  
        label="Acreage"  
        :model-value="crop.acreage"  
      >
        <template v-slot:control>
          <q-input
            color="bhoomi"
            outlined
            placeholder="Acreage"
            v-model="crop.acreage"
            :rules="[
              (val) => !!val || '* Required',
              (val) => val.length <= 50 || 'Please use maximum 50 characters',
            ]"
          />
        </template>
      </q-field>

    </div>

    <div class="row wrapper">
      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        label="Acreage"  
        :model-value="crop.acreage"  
        v-if="(bedOptions.length > 0)"
      >
        <template v-slot:control>
          <q-input
            type="number"
            color="bhoomi"
            outlined
            placeholder="Acreage"
            v-model="crop.acreage"
            :rules="[
              (val) => !!val || '* Required',
              (val) => val.length <= 50 || 'Please use maximum 50 characters',
            ]"
          />
        </template>
      </q-field>

      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        label="Crop Cycle"  
        :model-value="crop.cropCycle"  
      >
        <template v-slot:control>
          <q-input
            color="bhoomi"
            outlined
            placeholder="Crop Cycle"
            v-model="crop.cropCycle"
            :rules="[
              (val) => val.length <= 50 || 'Please use maximum 50 characters',
            ]"
          />
        </template>
      </q-field>

      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        label="Category"  
        :model-value="crop.cropCategory"  
      >
        <template v-slot:control>
          <q-input
            color="bhoomi"
            outlined
            placeholder="Category"
            v-model="crop.cropCategory"
            :rules="[
              (val) => val.length <= 50 || 'Please use maximum 50 characters',
            ]"
          />
        </template>
      </q-field>
      
      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        label="Crop"  
        :model-value="crop.crop"  
      >
        <template v-slot:control>
          <q-input
            color="bhoomi"
            outlined
            placeholder="Crop"
            v-model="crop.crop"
            :rules="[
              (val) => !!val || '* Required',
              (val) => val.length <= 50 || 'Please use maximum 50 characters',
            ]"
          />
        </template>
      </q-field>

      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        label="Variety"  
        :model-value="crop.variety"
        v-if="(bedOptions.length <= 0)"  
      >
        <template v-slot:control>
          <q-input
            color="bhoomi"
            outlined
            placeholder="Variety"
            v-model="crop.variety"
            :rules="[
              (val) => val.length <= 50 || 'Please use maximum 50 characters',
            ]"
          />
        </template>
      </q-field>
      
    </div>

    <div class="row wrapper">
      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        label="Variety"  
        :model-value="crop.variety"
        v-if="(bedOptions.length > 0)"  
      >
        <template v-slot:control>
          <q-input
            color="bhoomi"
            outlined
            placeholder="Variety"
            v-model="crop.variety"
            :rules="[
              (val) => val.length <= 50 || 'Please use maximum 50 characters',
            ]"
          />
        </template>
      </q-field>
      
      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        label="Date Seed"  
        :model-value="crop.dateSeed"  
      >
        <template v-slot:control>
          <q-input
            color="bhoomi"
            outlined
            placeholder="Date Seed"
            v-model="crop.dateSeed"
            :rules="[
              (val) => !!val || '* Required',
              (val) => val.length <= 50 || 'Please use maximum 50 characters',
            ]"
          >
            <template v-slot:append>
              <q-icon color="bhoomi" name="calendar_month">
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                  <q-date v-model="crop.dateSeed" color="bhoomi"
                    years-in-month-view
                    mask="DD/MM/YY"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="bhoomi" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </template>
      </q-field>
      
      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        label="Harvest Date"  
        :model-value="crop.harvestDate"  
      >
        <template v-slot:control>
          <q-input
            color="bhoomi"
            outlined
            placeholder="Harvest Date"
            v-model="crop.harvestDate"
            :rules="[
              (val) => !!val || '* Required',
              (val) => val.length <= 50 || 'Please use maximum 50 characters',
            ]"
          >
            <template v-slot:append>
              <q-icon color="bhoomi" name="calendar_month">
                <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                  <q-date v-model="crop.harvestDate" color="bhoomi"
                    years-in-month-view
                    mask="DD/MM/YY"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="bhoomi" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </template>
      </q-field>

      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        :label="projHarvestAmountLabel"  
        @focus="changeHarvestLabel(true)"
        @blur="changeHarvestLabel()"  
        :model-value="crop.projHarvestAmount"  
      >
        <template v-slot:control>
          <q-input
            color="bhoomi"
            outlined
            placeholder="Proj. Harvest Amt in lbs"
            v-model="crop.projHarvestAmount"
            :rules="[
              (val) => !!val || '* Required',
              (val) => val.length <= 50 || 'Please use maximum 50 characters',
            ]"
          />
        </template>
      </q-field>

      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        :label="projWastageLabel"  
        :model-value="crop.projWastage"
        @focus="changeWastageLabel(true)"
        @blur="changeWastageLabel()"  
        v-if="(bedOptions.length <= 0)"
      >
        <template v-slot:control>
          <q-input
            color="bhoomi"
            outlined
            placeholder="Proj. Wastage in lbs"
            v-model="crop.projWastage"
            :rules="[
              (val) => val.length <= 50 || 'Please use maximum 50 characters',
            ]"
          />
        </template>
      </q-field>

    </div>

    <div class="row wrapper"  v-if="(bedOptions.length > 0)">

      <q-field 
        borderless 
        class="outer-label"
        color="bhoomi"  
        :label="projWastageLabel"  
        :model-value="crop.projWastage"
        @focus="changeWastageLabel(true)"
        @blur="changeWastageLabel()"  
      >
        <template v-slot:control>
          <q-input
            color="bhoomi"
            outlined
            placeholder="Proj. Wastage in lbs"
            v-model="crop.projWastage"
            :rules="[
              (val) => val.length <= 50 || 'Please use maximum 50 characters',
            ]"
          />
        </template>
      </q-field>

      <q-field borderless ></q-field>
      <q-field borderless ></q-field>
      <q-field borderless ></q-field>

    </div>

    <div class="row wrapper notifications-header">Set Notifications</div>

    <div class="row wrapper notifications-set">
      
      <q-checkbox 
        color="bhoomi" 
        v-model="crop.isDateSeed" 
        label="Date Seed"
        class="custom-checkbox"
      />

      <label class="custom-label">
        <span>Days in Advance</span>
        <q-field 
          borderless 
          class="outer-label"
          color="bhoomi"
        >
          <template v-slot:control>
            <q-input
              class="days-number"
              color="bhoomi"
              outlined
              placeholder="00"
              v-model="crop.seedDays"
            
            />
          </template>
        </q-field>
      </label>

      <q-checkbox 
        color="bhoomi" 
        v-model="crop.isDateHarvest" 
        label="Date Harvest"
        class="custom-checkbox"
      />

      <label class="custom-label">
        <span>Days in Advance</span>
        <q-field 
          borderless 
          class="outer-label"
          color="bhoomi"
        >
          <template v-slot:control>
            <q-input
              class="days-number"
              color="bhoomi"
              outlined
              placeholder="00"
              v-model="crop.harvestDays"
            />
          </template>
        </q-field>
      </label>

    </div>
    
  </q-card-section>

    <q-card-actions class="crop-actions-section" style="justify-content: flex-start">
      <q-btn 
        @click="cancelChanges" 
        class="text-bhoomi cancel-button" 
        style="background-color: #fff" 
        label="Cancel"
      />
      <q-btn 
        @click="submitForm" 
        class="bg-bhoomi save-button" 
        style="color: #fff" 
        label="Save" 
        :disable="disabledSubmit"
      />
    </q-card-actions>
  </q-card>

</q-expansion-item> -->

</template>

<script>
import { inject, onMounted, reactive, watch, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import cropYears from '@/sharedData/yearsList';
import DropDownList from '@/components/Shared/DropDownList.vue';
// import CropNotificationSet from './CropNotificationSet.vue';
import UserNotification from "@/components/Shared/UserNotification.vue";

export default {
    name: "CropForm",
    setup() {
        const store = useStore();
        const sectionHeader = 'Plan Crop';
        const { crop, selectCrop, currentFarm, editCrop, editionMode, options: bedOptions } = inject('cropEdition');
        const farmOptions = store.state.farms.map(farm => farm.farmName);
        
        const cropSeasons = ['Spring', 'Summer', 'Autumn', 'Winter'];
        const cropCycles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10 /* ,11,12,13,14,15 */];

        const activated = reactive({
            farmName: false,
            year: false,
            cropCategory: false,
            dateSeed: false,
            projHarvestAmount: false,
            bed: false,
            season: false,
            crop: false,
            harvestDate: false,
            projWastage: false,
            acreage: false,
            cropCycle: false,
            variety: false
        });
        const focused = reactive({
            farmName: false,
            year: false,
            cropCategory: false,
            dateSeed: false,
            projHarvestAmount: false,
            bed: false,
            season: false,
            crop: false,
            harvestDate: false,
            projWastage: false,
            acreage: false,
            cropCycle: false,
            variety: false
        });
        const projWastageLabel = ref('Proj. Wastage in lbs');
        const changeWastageLabel = (isFocused) => {
            projWastageLabel.value = isFocused ? 'Proj. Wastage' : 'Proj. Wastage in lbs';
        };
        const projHarvestAmountLabel = ref('Proj. Harvest Amt in lbs');
        const changeHarvestLabel = (isFocused) => {
            projHarvestAmountLabel.value = isFocused ?
                'Proj. Harvest Amt' :
                'Proj. Harvest Amt in lbs';
        };
        const crops = computed(() => store.state.crops);

        const missedField = ref(false);

        const submitForm = () => {
            console.log(editionMode.value, 'editionMode.value');
            if (!crop.year || !crop.season || !crop.farmName || !crop.acreage || !crop.crop ||
                !crop.dateSeed || !crop.harvestDate || !crop.projHarvestAmount) {
                if (!crop.year)
                    activated.year = true;
                if (!crop.season)
                    activated.season = true;
                if (!crop.farmName)
                    activated.farmName = true;
                if (!crop.acreage)
                    activated.acreage = true;
                if (!crop.crop)
                    activated.crop = true;
                if (!crop.dateSeed)
                    activated.dateSeed = true;
                if (!crop.harvestDate)
                    activated.harvestDate = true;
                if (!crop.projHarvestAmount)
                    activated.projHarvestAmount = true;

                missedField.value = true;

                setTimeout(() => {
                  missedField.value = false;
                }, 5100);

                return;
            }
            console.log(crop, 'crop');
            const savedCrop = {
                ...crop,
                id: (editionMode.value === 'edit') ? crop.id : uuid(),
            };
            // if (editionMode.value === 'create') {
            //   savedCrop.id = uuid();
            // }
            console.log(savedCrop, 'savedCrop');
            (editionMode.value === 'edit') ? store.dispatch('editCrop', {
                crop: savedCrop
            }) : store.dispatch('createCrop', {
                crop: savedCrop
            });
            selectCrop(savedCrop, 'represent');
        };
        const cancelChanges = () => {
          const cropEdited = crops.value.find(c => c.id === crop.id);
          if (cropEdited) {
            selectCrop({...cropEdited}, 'represent');
          } else {
            for (let field in crop) {
              crop[field] = '';
            }
            editionMode.value = null;
          }
        };
        watch(currentFarm, (newVal) => {
            (newVal.beds.length > 0) ?
                newVal.beds.forEach((bed, index) => {
                    bedOptions[index] = bed.bedNumber;
                }) :
                bedOptions.splice(0, bedOptions.length);
        });

        watch(() => (crop.farmName), (newVal) => {
          if (newVal) {
            const selectedFarmOption = store.state.farms.find(farm => farm.farmName === newVal);
            crop.acreage = selectedFarmOption?.acreage || '';
          }
        })

        onMounted(() => {
            if (currentFarm?.beds?.length > 0) {
                crop.acreage = currentFarm.beds[0].acreage;
                crop.crop = currentFarm.beds[0].primaryCrop;
            }
            else {
                crop.acreage = currentFarm?.acreage;
                crop.crop = currentFarm?.primaryCrop;
            }
        });
        return {
            cropYears,
            cropSeasons,
            sectionHeader,
            farmOptions,
            bedOptions,
            editCrop,
            submitForm,
            cancelChanges,
            crop,
            projWastageLabel,
            changeWastageLabel,
            projHarvestAmountLabel,
            changeHarvestLabel,
            activated,
            focused,
            cropCycles,
            missedField
        };
    },
    components: { DropDownList, UserNotification }
};
</script>

<style lang="scss" scoped>
.date-icon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 3.7rem;
  right: 1rem;
  z-index: 8;
  pointer-events: none;
  border-radius: 1rem;
  background:url('@/assets/images/calendar-icon.svg') center center no-repeat;
  background-color: #fff;
}
.plan-crop-form {
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
  .header {
    font-size: 18px;
    color: #28292B;
    margin-left: 8px;
  }
  .plan-crop-form-card-section {
    padding: 8px 0;
  }
  .row {
    &.wrapper {
      width: 100%;
      justify-content: space-evenly;
      height: 80px;
      .q-field__native, .q-field__prefix {
        color: rgba(40,41,43,0.6);
      }
      label {
        width: 100%;
        max-width: 165px;
      }
      &.notifications-header {
        color: #28292B;
        justify-content: flex-start !important;
        font-size: 16px;
        font-weight: 600;
        height: max-content;
        margin-top: 10px;
        margin-left: 24px;
      }
      &.notifications-set {
        .custom-label {
          display: flex;
          align-items: center;
          justify-content: space-around;
          color: #7E7F80;
          font-weight: 500;
          .days-number {
            width: 60px;
            height: 60px;
          }
          .outer-label {
            width: 50px;
            height: 60px;
          }
        }
        .custom-checkbox {
          color: #28292B;
          width: 165px;
        }
      }
    }
  }
}

.crop-actions-section {
  margin-bottom: 16px;
}

@media screen and (max-width: 1024px) {
  .plan-crop-form {
    .row {
      &.wrapper {
        height: auto;
        .outer-label {
          margin: 0 5px;
        }
      }
    }
  }
}
</style>