<template>
  <section class="section" ref="section">
      <div class="border-box active">
          <div class="border-box-header" ref="boxHeader" @click="toggleSection">
              <h5>Sales By Week</h5>
              <img src="@/assets/images/angle-down.png" alt="" class="angle">
          </div>
          <div class="border-box-content">

              <div class="content-block">

                  <div class="content-row grid-4 grid-full">
                      <div class="row">

                          <div class="col-3">
                              <div class="form-item">
                                  <p class="form-label">Choose a Farm:</p>
                                  <DropDownList
                                    :mainObject="'currentSelection'"
                                    :mainProperty="'farm'"
                                    :injectionName="'selectionDetails'"
                                    :optionKey="'farm-option-sales-by-week'"
                                    :optionsList="[]"
                                    :optionProperty="'farmName'"
                                    :optionPropertyTwo="null"
                                    :defaultSelected="currentSelection.farm"
                                    @click="() => {
                                        // activated.farm = true;
                                    }"
                                  />
                              </div>
                          </div>

                          <div class="col-3">
                              <div class="form-item">
                                  <p class="form-label">Choose a Year:</p>
                                  <DropDownList
                                    :mainObject="'currentSelection'"
                                    :mainProperty="'year'"
                                    :injectionName="'selectionDetails'"
                                    :optionKey="'year-option-sales-by-week'"
                                    :optionsList="yearOptions"
                                    :optionProperty="null"
                                    :optionPropertyTwo="null"
                                    :defaultSelected="yearOptions[0]"
                                    @click="() => {
                                        // activated.year = true;
                                    }"
                                  />
                              </div>
                          </div>

                          <div class="col-3">
                              <div class="form-item">
                                  <p class="form-label">Choose a Month:</p>
                                  <DropDownList
                                    :mainObject="'currentSelection'"
                                    :mainProperty="'month'"
                                    :injectionName="'selectionDetails'"
                                    :optionKey="'month-option-sales-by-week'"
                                    :optionsList="monthOptions"
                                    :optionProperty="null"
                                    :optionPropertyTwo="null"
                                    :defaultSelected="monthOptions[0]"
                                    @click="() => {
                                        // activated.month = true;
                                    }"
                                  />
                              </div>
                          </div>                                            

                      </div>
                  </div>

              </div>

              <div class="content-block">

                  <div class="content-row table-wrapper-block">
                      <div class="table-wrapper">
                          <table class="headding-table">
                              <thead>
                                  <tr><th><p class="form-label">&nbsp;</p></th></tr>
                              </thead>
                              <tbody>
                                  <tr><td><p class="form-label">Total Sales</p></td></tr>
                                  <tr><td><p class="form-label">Total Expenses</p></td></tr>
                                  <tr><td><p class="form-label green">Net Profit</p></td></tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="table-wrap flex-1">
                          <table>

                              <thead>
                                  <tr>
                                      <th class="text-center">
                                          <p class="form-label" style="min-width: 8rem;">Week 1</p>
                                      </th>
                                      <th>
                                          <p class="form-label" style="min-width: 8rem;">Week 2</p>
                                      </th>
                                      <th>
                                          <p class="form-label" style="min-width: 8rem;">Week 3</p>
                                      </th>
                                      <th>
                                          <p class="form-label" style="min-width: 8rem;">Week 4</p>
                                      </th>
                                      <th>
                                          <p class="form-label" style="min-width: 8rem;">Week 5</p>
                                      </th>
                                      <th>
                                          <p class="form-label green" style="min-width: 8rem;">Total</p>
                                      </th>
                                  </tr>
                              </thead>

                              <tbody>

                                  <tr>
                                      <td class="text-center">{{ `$${Number.parseFloat(rows.totalSales.week1).toFixed(2)}` }}</td>
                                      <td>{{ `$${Number.parseFloat(rows.totalSales.week2).toFixed(2)}` }}</td>
                                      <td>{{ `$${Number.parseFloat(rows.totalSales.week3).toFixed(2)}` }}</td>
                                      <td>{{ `$${Number.parseFloat(rows.totalSales.week4).toFixed(2)}` }}</td>
                                      <td>{{ `$${Number.parseFloat(rows.totalSales.week5).toFixed(2)}` }}</td>
                                      <td><span class="bg">{{ `$${Number.parseFloat(rows.totalSales.total).toFixed(2)}` }}</span></td>
                                  </tr>

                                  <tr>
                                      <td class="text-center">{{ `$${Number.parseFloat(rows.totalExpenses.week1).toFixed(2)}` }}</td>
                                      <td>{{ `$${Number.parseFloat(rows.totalExpenses.week2).toFixed(2)}` }}</td>
                                      <td>{{ `$${Number.parseFloat(rows.totalExpenses.week3).toFixed(2)}` }}</td>
                                      <td>{{ `$${Number.parseFloat(rows.totalExpenses.week4).toFixed(2)}` }}</td>
                                      <td>{{ `$${Number.parseFloat(rows.totalExpenses.week5).toFixed(2)}` }}</td>
                                      <td><span class="bg">{{ `$${Number.parseFloat(rows.totalExpenses.total).toFixed(2)}` }}</span></td>
                                  </tr>

                                  <tr>
                                      <td class="text-center"><span class="bg">{{ `$${Number.parseFloat(rows.netProfit.week1).toFixed(2)}` }}</span></td>
                                      <td><span class="bg">{{ `$${Number.parseFloat(rows.netProfit.week2).toFixed(2)}` }}</span></td>
                                      <td><span class="bg">{{ `$${Number.parseFloat(rows.netProfit.week3).toFixed(2)}` }}</span></td>
                                      <td><span class="bg">{{ `$${Number.parseFloat(rows.netProfit.week4).toFixed(2)}` }}</span></td>
                                      <td><span class="bg">{{ `$${Number.parseFloat(rows.netProfit.week5).toFixed(2)}` }}</span></td>
                                      <td><span class="bg">{{ `$${Number.parseFloat(rows.netProfit.total).toFixed(2)}` }}</span></td>
                                  </tr>

                              </tbody>

                          </table>
                      </div>
                      
                  </div>

              </div>

          </div>
      </div>
  </section><!--//End Section-->
</template>

<script>
import { defineComponent, ref, onMounted, watch, provide } from 'vue';
import { useStore } from 'vuex';
import DropDownList from '@/components/Shared/DropDownList.vue';
import yearOptions from '@/sharedData/yearsList';
import monthOptions from '@/sharedData/monthsList';


export default defineComponent({
  name: 'SalesByWeek',
  setup() {
    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);
    const store = useStore();

    const rows = ref({
        totalSales: {
            week1: '35.00',
            week2: '35.00',
            week3: '35.00',
            week4: '35.00',
            week5: '35.00',
            total: '350.00'
        },
        totalExpenses: {
            week1: '35.00',
            week2: '35.00',
            week3: '35.00',
            week4: '35.00',
            week5: '35.00',
            total: '350.00'
        },
        netProfit: {
            week1: '35.00',
            week2: '35.00',
            week3: '35.00',
            week4: '35.00',
            week5: '35.00',
            total: '350.00'
        },
    });
    const farms = ref([...store.state.farms]);

    const currentSelection = ref({
      farm: '',
      year: '',
      month: ''
    });

    const toggleSection = (ev) => {
        if ((ev.target.className !== 'border-box-header') &&
            (ev.target.className !== 'angle') && (ev.target.className !== 'angle revert')) {
            return;
        }
        sectionExpanded.value = !sectionExpanded.value;
    };
    watch(sectionExpanded, (newVal) => {
        section.value.style.height = newVal ?
            `${sectionHeight.value}px` :
            `${boxHeader.value.offsetHeight}px`;
        section.value.style.overflow = newVal ? 'visible' : 'hidden';
    });
    // watch(() => ([...store.state]), (newVal) => { 
    //     rows.value = [...newVal];
    //     setTimeout(() => {
    //         section.value.style.height = 'auto';
    //         sectionHeight.value = section.value.offsetHeight;
    //     });
    // });
    watch(() => ([...store.state.farms]), (newVal) => { 
        farms.value = [...newVal];
    });
    // watch(() => ({...currentSelection}), (newVal) => {
    //     if (newVal.value.farm && newVal.value.year && newVal.month) {
    //         rows.value = [...store.state].filter(e => (e.year === newVal.value.year && e.farm === newVal.value.farm && e.month === newVal.value.month));
    //     }
    // });

    onMounted(() => {
        setTimeout(() => {
            sectionHeight.value = section.value.offsetHeight;
            section.value.style.height = `${section.value.offsetHeight}px`;
        });
        // rows.value = [...store.state];
        // store.dispatch('get');
        store.dispatch('getFarms');
    });

    provide('selectionDetails', {
      currentSelection,
      options: farms 
    })

    return {
        section,
        boxHeader,
        toggleSection,
        rows,
        yearOptions,
        monthOptions,
        currentSelection
    }
  },
  components: { DropDownList }
});
</script>