<template>
  <div class="login">
    <div class="main-logo"></div>
    <q-card>
      <q-card-section>
        <h5>Activate Account</h5>
        <div class="flex flex-center form-row">
          <q-field
            borderless
            label="Email"
            stack-label
            color="bhoomi"
            style="max-width: 200px; width: 100%"
            :model-value="loginForm.email"
          >
            <template v-slot:control>
              <q-input
                color="bhoomi"
                outlined
                rounded-borders
                v-model="loginForm.email"
                placeholder="Enter Email"
                :rules="[
                  (val) => !!val || '* Required',
                  (val) => /\S+@\S+\.\S+/.test(val) || 'Please use correct email format'
                ]"
              />
            </template>
          </q-field>
        </div>
        <div class="flex flex-center form-row">
          <q-field
            borderless
            label="Password (8+ characters)"
            stack-label
            color="bhoomi"
            style="max-width: 200px; width: 100%"
            :model-value="loginForm.password"
          >
            <template v-slot:control>
              <q-input
                type="password"
                color="bhoomi"
                outlined
                rounded-borders
                v-model="loginForm.password"
                placeholder="Create Password"
                :rules="[
                  (val) => !!val || '* Required',
                  // myRule
                ]"
                error-message="Password does not match, please renter"
                :error="!loginForm.passwordMatch"
              >
                <template v-slot:append>
                  <q-avatar @mouseenter="showTooltip" @mouseleave="hideTooltip">
                    <img src="@/assets/login-register_page/edit_button.png">
                    <div v-if="tooltipData.isShown" 
                      class="tooltip" 
                      :style="{left: `${tooltipData.xPos + 20}px`, top: `${tooltipData.yPos + 20}px`}">
                      The password should match previously entered.
                    </div>
                  </q-avatar>
                </template>
              </q-input>
            </template>
          </q-field>
        </div>
      </q-card-section>
    </q-card>
    <div class="flex agreement-checker">
      <q-checkbox 
        color="bhoomi" 
        v-model="loginForm.userAgreed" 
      />
      By checking this box, you agree to the Bhoomi 
      <router-link :to="{ name: 'user-agreement' }">
        User Agreement
      </router-link>, <router-link :to="{ name: 'privacy-policy' }">
        Privacy Policy
      </router-link>, and <router-link :to="{ name: 'cookie-policy' }">
        Cookie Policy
      </router-link>.
    </div>
    <q-card-actions style="justify-content: center">
      <q-btn @click="submitForm" color="bhoomi" label="Join" style="padding: 0 80px;" />
    </q-card-actions>
  </div>
</template>

<script>
import { ref, reactive, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex';

export default {
  name: "LoginPage",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const authenticated = ref(store.state.authenticated.token);
    const loginForm = reactive({
        email: route.query.email,
        password: '',
        passwordMatch: true,
        userAgreed: false
      }),
      tooltipData = reactive({
        isShown: false,
        xPos: 0,
        yPos: 0
      });

    const showTooltip = (ev) => {
      console.log(ev);
      tooltipData.isShown = true;
      tooltipData.xPos = ev.offsetX;
      tooltipData.yPos = ev.offsetY;
    }

    const hideTooltip = () => {
      tooltipData.isShown = false;
    } 

    const submitForm = () => {
      if (loginForm.email && loginForm.password && loginForm.userAgreed) {
        store.dispatch('loginUser', loginForm);
        setTimeout(() => {
          loginForm.passwordMatch = true;
        }, 500)
      }
    }

    const myRule = () => {
        // simulating a delay
        return new Promise((resolve) => { //reject
          setTimeout(() => {
            // call
            //  resolve(true)
            //     --> content is valid
            //  resolve(false)
            //     --> content is NOT valid, no error message
            //  resolve(error_message)
            //     --> content is NOT valid, we have error message
            resolve(loginForm.passwordMatch || 'Password does not match, please renter')

            // calling reject(...) will also mark the input
            // as having an error, but there will not be any
            // error message displayed below the input
            // (only in browser console)
          }, 1000)
        })
      }

    watch(store.state.authenticated, () => {
      console.log(authenticated);
      router.replace({ path: '/user-profile' })
    })


    return {
      loginForm,
      tooltipData,
      showTooltip,
      hideTooltip,
      submitForm,
      myRule
    }
  }
};
</script>

<style lang="scss">
.login {
  .main-logo {
    background-image: url('@/assets/login-register_page/logo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 14vw;
    height: 14vw;
    margin: 5vh calc(50% - 7vw);
    text-align: center;
  }
  .q-card {
    margin: 0 25%;
    h5 {
      text-align: center;
    }
    .form-row {
      .tooltip {
        width: 300px;
        height: max-content;
        line-height: 0.9;
        position: absolute;
      }
    }
  }
  .agreement-checker {
    align-items: center;
    justify-content: center;
    a {
      color: #38AC89;
      margin-left: 5px;
    }
  }
}
</style>