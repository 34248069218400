<template>
    <section class="section" ref="section">
    <div class="border-box">
    <div class="border-box-header" ref="boxHeader" @click="toggleSection">
    <h5>Sales List</h5>
    <div class="info-icons">
      <div class="info-icon lg top-icon tooltip"
        @click="addSales"
        @mouseover="switchHelperTooltip(true)"
        @mouseout="switchHelperTooltip(false)"
        data-tooltip="Add Sales"
        v-if="isAuth('add-new-sales')"
      >
        <img src="@/assets/images/updated/Sales.png" alt="">
        <!-- <div class="helper-tooltip" v-if="helperTooltip">Add Sales</div> -->
      </div>
    </div>
    <img src="@/assets/images/angle-down.png" alt="" class="angle">
    </div>

    <div class="border-box-content">

    <div class="content-row table-wrapper">
    <table>

    <thead>
    <tr>
    <th><p class="form-label-static form-sort-label">
      <span>Date</span>
      <span @click="() => sortTable('date')">
        <button :class="{active: sortOrder.date === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.date === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Customer</span>
      <span @click="() => sortTable('customer')">
        <button :class="{active: sortOrder.customer === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.customer === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Farm</span>
      <span @click="() => sortTable('farm')">
        <button :class="{active: sortOrder.farm === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.farm === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
      <th><p class="form-label-static form-sort-label">
      <span>Crop</span>
      <span @click="() => sortTable('crop')">
        <button :class="{active: sortOrder.crop === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.crop === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Amount</span>
      <span @click="() => sortTableByNumber('qunatitySold')">
        <button :class="{active: sortOrder.quantitySold === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.quantitySold === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Rate</span>
      <span @click="() => sortTableByNumber('rate')">
        <button :class="{active: sortOrder.rate === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.rate === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Total</span>
      <span @click="() => sortTableByNumber('total')">
        <button :class="{active: sortOrder.total === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.total === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th class="w-75">&nbsp;</th>

    </tr>
    </thead>

    <tbody>

      <template
        v-for="(row, rowIndex) in rows"
        v-bind:key="'sales-list-row' + row.id"
      >
        <tr
          v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
          :class="{'light-gray-bg': row.id === selectedSales.id}"
          @click="(ev) => selectByRow(ev, {...row})"
        >
        <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
        <td class="text-center">{{ moment(row.date, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</td>
        <td>{{ row.customer }}</td>
        <td>{{ row.farm }}</td>
        <td>{{ row.crop }}</td>
        <td>{{ row.quantitySold }} Lbs</td>
        <td>${{ row.rate }}</td>
        <td>${{ row.total }}</td>
        <td class="w-75">
        <div class="table-links">
        <a v-if="isAuth('edit-sales')" href="javascript:void();" @click="selectSales({...row}, 'edit')">
          <img src="@/assets/images/edit-icon-2.svg" alt="">
        </a>
        <a v-if="isAuth('remove-sales')" href="javascript:void();" @click="removeSelectedSales(row.id)">
          <img src="@/assets/images/trash-2.svg" alt="">
        </a>
        <a v-if="isAuth('call-sales')" href="javascript:void();" @click="callSales(row.id)">
          <img src="@/assets/images/phone-icon.svg" alt="">
        </a>
        </div>
        </td>
        </tr>
      </template>

    </tbody>

    </table>
    </div>

    <div class="content-row">
    <div class="pagination">
    <div>
    <!-- <p>Records per page:</p> -->
    <!-- <select v-model="maxPage">
    <option>1</option>
    <option>2</option>
    <option>3</option>
    <option>4</option>
    <option>5</option>
    <option>6</option>
    <option>7</option>
    <option>8</option>
    <option>9</option>
    <option>10</option>
    </select> -->
    </div>
    <!-- <p>1-1 to 10</p> -->
    <p class="list-pagination">
      Page: 
      <!-- <select v-model="currentPage" class="pagination-selector">
      <option 
        v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
        v-bind:key="'task-list-pagination-' + page"
      >{{ Number.parseInt(page) + 1 }}</option>
      </select> -->
      <DropDownList 
        :mainObject="'currentPage'"
        :mainProperty="'value'"
        :injectionName="'salesListDetails'"
        :optionKey="'sales-list-pagination'"
        :optionsList="[]"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="currentPage.value"
        :numberList="true"
      />
    </p>
    </div>
    </div>

    </div>

  </div>
  </section>

  <div class="removal-popup" v-if="removedSalesId">

  <div class="modal modal-md">
  <span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">Are you sure you want to delete the sales?</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => approveRemoval(true)"
        class="approve-button btn green" 
      >Yes</button>
      <button
        @click="() => approveRemoval(false)" 
        class="cancel-removal-button btn green btn-o" 
      >No</button>
  </div>
  </div>
  </div>

  </div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import moment from 'moment';
import { 
  defineComponent, ref, onMounted, 
  watch, inject, reactive, provide 
} from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'SalesList',
    props: ['parentModule'],
    setup(props) {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const { isAuth } = inject('mainUI');
        const { customer } = inject("customerDetails");
        // const sales = computed(() => store.state.sales);
        const sales = ref(props.parentModule ? [...store.state.sales].filter(stateSalesItem => stateSalesItem.customerId === customer.id) : [...store.state.sales]);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const sortOrder = reactive({
            date: 0,
            customer: 0,
            farm: 0,
            crop: 0,
            quantitySold: 0,
            rate: 0,
            total: 0
        });
        const { selectedSales, addSales, editSales, selectSales, removeSales } = inject('salesDetails');
        const helperTooltip = ref(false);
        const removedSalesId = ref(null);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...sales.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByNumber = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...sales.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByAmount = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(Math.abs(a.amtHarvested - a.wastage)) - Number.parseInt(Math.abs(b.amtHarvested - b.wastage)));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(Math.abs(b.amtHarvested - b.wastage)) - Number.parseInt(Math.abs(a.amtHarvested - a.wastage)));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...sales.value];
                sortOrder[sortProp] = 0;
            }
        };
        const callSales = (salesId) => {
            console.log(salesId);
        };
        const removeSelectedSales = (salesId) => {
            removedSalesId.value = salesId;
        };
        const approveRemoval = (isApproved) => {
            if (isApproved) {
                removeSales(removedSalesId.value);
            }
            removedSalesId.value = '';
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectSales({ ...row }, 'represent');
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(sales.value, () => {
            rows.value = [...sales.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(() => ([...store.state.sales]), (newVal) => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = props.parentModule ? [...store.state.sales].filter(stateSalesItem => stateSalesItem.customerId === customer.id) : [...newVal];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
          store.dispatch('getSales');
            (sales.value.length > 0) && selectSales({ ...sales.value[0] }, 'represent');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getSales')
            rows.value = props.parentModule ? [...store.state.sales].filter(stateSalesItem => stateSalesItem.customerId === customer.id) : [...store.state.sales];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('salesListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            addSales,
            editSales,
            selectSales,
            removeSales,
            callSales,
            rows,
            maxPage,
            currentPage,
            selectedSales,
            helperTooltip,
            switchHelperTooltip,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            sortTableByNumber,
            sortTableByAmount,
            moment,
            removedSalesId,
            removeSelectedSales,
            approveRemoval,
            selectByRow,
            isAuth
        };
    },
    components: { DropDownList }
})
</script>

<style lang="scss" scoped>
.top-icon {
  position: relative;
}
</style>