<template>

<section class="section" ref="section">
<div class="border-box active">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Bed:</h5>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-block">

<!-- <div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.year || (bed.year.length > 0)}">Year</p>
<select class="form-control" 
  v-model="bed.year"
  @click="() => {
    activated.year = true;
    focused.year = true;
  }"
  @change="() => {
    focused.year = false;
  }"
>
<option 
  v-for="bedYear in bedYears"
  v-bind:key="bedYear + 'year-option-bed'"
>{{ bedYear }}</option>
</select>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.season || (bed.season.length > 0)}">Season</p>
<select class="form-control"
  v-model="bed.season"
  @click="() => {
    activated.season = true;
    focused.season = true;
  }"
  @change="() => {
    focused.season = false;
  }"
>
  <option 
  v-for="bedSeason in bedSeasons"
  v-bind:key="bedSeason + 'year-option-bed'"
>{{ bedSeason }}</option>
</select>
</div>
</div>

</div>
</div> -->

<div class="content-row" v-if="newCropBedEdition">

<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Farm</p>
<h6>{{ bed.farmName }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Bed No.</p>
<h6>{{ bed.bedNumber }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Acreage</p>
<h6>{{ bed.acreage }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Soil type</p>
<h6>{{ bed.soilType }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Irrigation Type</p>
<h6>{{ bed.irrigation }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">IOT Device Type</p>
<h6>{{ bed.IOTdeviceType }}</h6>
</div>

</div>

</div>

<div class="content-row grid-4 grid-full" v-else>
<div class="row">
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.farmName || (bed.farmName.length > 0)}">Farm</p>
<input type="text" class="form-control"
  v-model="bed.farmName"
  @focus="() => {
    activated.farmName = true;
    focused.farmName = true;
  }"
  @blur="() => {
    focused.farmName = false;
  }"
  placeholder="E.g., Minnesota Organic Farm"
>
<p v-if="activated.farmName && bed.farmName.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.bedNumber || (bed.bedNumber.length > 0)}">Bed No.</p>
<select class="form-control" 
  v-if="(bedsList.length > 0) && !otherBedSelected"
  v-model="bed.bedNumber"
  @click="() => {
    activated.bedNumber = true;
    focused.bedNumber = true;
  }"
  @change="() => {
    focused.bedNumber = false;
  }"
>
  <template
    v-for="(bedOption, bedOptionIndex) in bedsList"
    v-bind:key="bedOption.id + 'bed-options-map'"
  >
    <option v-if="bedsList[bedOptionIndex - 1]?.sourceId !== bedOption.sourceId">
      {{ bedOption.bedNumber }}
    </option>
  </template>
  <option>Other</option>
</select>

<input type="text" class="form-control"
  v-else
  v-model="bed.bedNumber"
  @focus="() => {
    activated.bedNumber = true;
    focused.bedNumber = true;
  }"
  @blur="() => {
    focused.bedNumber = false;
  }"
  placeholder="E.g., 1"
>
<p v-if="activated.bedNumber && bed.bedNumber.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.bedNumber && bed.bedNumber.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.acreage || (bed.acreage.length > 0)}">Acreage</p>
<input type="text" class="form-control"
  v-model="bed.acreage"
  @focus="() => {
    activated.acreage = true;
    focused.acreage = true;
  }"
  @blur="() => {
    focused.acreage = false;
  }"
  placeholder="E.g., 34"
>
<p v-if="activated.acreage && bed.acreage.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.acreage && bed.acreage.length > 5" class="form-info red text-end">Please use maximum 5 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.soilType || (bed.soilType.length > 0)}">Soil Type</p>

<!-- <select class="form-control"
  v-if="!soilManual"
  v-model="bed.soilType"
  @click="() => {
    activated.soilType = true;
    focused.soilType = true;
  }"
  @change="() => {
    focused.soilType = false;
  }"
>
  <option
    v-for="soilOption in soilTypes"
    v-bind:key="soilOption + 'soil-option-bed'"
  >{{ soilOption }}</option>
</select> -->

<template v-if="!soilManual">
  <DropDownList
    :mainObject="'bed'"
    :mainProperty="'soilType'"
    :injectionName="'bedEdition'"
    :optionKey="'soil-option-bed'"
    :optionsList="soilTypes"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="bed.soilType"
    @click="() => {
      activated.soilType = true;
    }"
  />
</template>

<input type="text" class="form-control"
  v-else
  v-model="bed.soilType"
  ref="soilField"
  @focus="() => {
    activated.soilType = true;
    focused.soilType = true;
  }"
  @blur="() => {
    focused.soilType = false;
    if (!bed.soilType) {
      soilManual = false;
    }
  }"
  placeholder="E.g., Gravel"
>
<!-- <p v-if="activated.soilType && bed.soilType.length === 0" class="form-info red text-end">* Required</p> -->
<p v-if="activated.soilType && bed.soilType.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.irrigation || (bed.irrigation.length > 0)}">Irrigation Type</p>

<!-- <select class="form-control"
  v-if="!irrigationManual"
  v-model="bed.irrigation"
  @click="() => {
    activated.irrigation = true;
    focused.irrigation = true;
  }"
  @change="() => {
    focused.irrigation = false;
  }"
>
  <option
    v-for="irrigationOption in irrigationSystems"
    v-bind:key="irrigationOption + 'irrigation-option-bed'"
  >{{ irrigationOption }}</option>
</select> -->

<template v-if="!irrigationManual">
  <DropDownList
    :mainObject="'bed'"
    :mainProperty="'irrigation'"
    :injectionName="'bedEdition'"
    :optionKey="'irrigation-option-bed'"
    :optionsList="irrigationSystems"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="bed.irrigation"
    @click="() => {
      activated.irrigation = true;
    }"
  />
</template>

<input type="text" class="form-control" 
  v-else
  v-model="bed.irrigation"
  ref="irrigationField"
  @focus="() => {
    activated.irrigation = true;
    focused.irrigation = true;
  }"
  @blur="() => {
    focused.irrigation = false;
    if (!bed.irrigation) {
      irrigationManual = false;
    }
  }"
  placeholder="E.g., Flood"
>

<p v-if="activated.irrigation && bed.irrigation.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.IOTdeviceType || (bed.IOTdeviceType.length > 0)}">IOT Device Type</p>
<input type="text" class="form-control"
  v-model="bed.IOTdeviceType"
  @focus="() => {
    activated.IOTdeviceType = true;
    focused.IOTdeviceType = true;
  }"
  @blur="() => {
    focused.IOTdeviceType = false;
  }"
  placeholder="E.g., JI890"
>
<p v-if="activated.IOTdeviceType && bed.IOTdeviceType.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>
<!-- <div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.primaryCrop || (bed.primaryCrop.length > 0)}">Primary Crop</p>
<input type="text" class="form-control"
  v-model="bed.primaryCrop"
  @focus="() => {
    activated.primaryCrop = true;
    focused.primaryCrop = true;
  }"
  @blur="() => {
    focused.primaryCrop = false;
  }"
>
<p v-if="activated.primaryCrop && bed.primaryCrop.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.primaryCrop && bed.primaryCrop.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div> -->
<!-- <div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.secondaryCrop || (bed.secondaryCrop.length > 0)}">Secondary Crop</p>
<input type="text" class="form-control"
  v-model="bed.secondaryCrop"
  @focus="() => {
    activated.secondaryCrop = true;
    focused.secondaryCrop = true;
  }"
  @blur="() => {
    focused.secondaryCrop = false;
  }"
>
<p v-if="activated.secondaryCrop && bed.secondaryCrop.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div> -->
</div>
</div>

</div>

<FarmBedPlanCropForm v-if="(crop.sourceType === 'bed')" />

<!-- <FarmPlanCropList v-if="crops.length > 0" /> -->

<div class="content-block">
<div class="buttons form-buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
</div>
</div>
</div>

</div>
</div>
</section>

<div class="removal-popup" v-if="popUps.addAdditional.active">

<div class="modal modal-md">
<span class="modal-close" @click="() => addAdditionalBed(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">{{ popUps.addAdditional.message }}</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => addAdditionalBed(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => addAdditionalBed(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>
</template>

<script>
import { inject, watch, onMounted, reactive, ref, provide, computed } from 'vue';
import { useStore } from 'vuex';
import bedYears from '@/sharedData/yearsList'
import { v4 as uuid } from 'uuid';
import FarmBedPlanCropForm from './FarmBedPlanCropForm.vue';
import soilTypes from '@/sharedData/soilTypes.js';
import irrigationSystems from '@/sharedData/irrigationSystems.js';
import DropDownList from '@/components/Shared/DropDownList.vue';
// import FarmPlanCropList from './FarmPlanCropList.vue';

export default {
    name: "FarmBedProfile",
    setup() {
        const store = useStore();
        const { bed, editBed, cancelBed, editionMode, focusOnBed, mapDraw, bedsList, selectBed } = inject('bedEdition');
        const sectionHeader = 'Bed Profile';
        const { selectedFarm, selectFarm } = inject('farmEdition');
        const { selectedFarmId } = inject('formMode');
        const { 
          crop, 
          activated: activatedCrop, 
          planCropSeasonIsChanged, 
          defaultCrop, selectCrop, 
          newCropBedEdition 
        } = inject('cropEdition');
        const bedSeasons = ['Spring', 'Summer', 'Autumn', 'Winter'];
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const newBedId = ref(null);
        const activated = reactive({
            year: false,
            season: false,
            farmName: false,
            bedNumber: false,
            acreage: false,
            soilType: false,
            irrigation: false,
            IOTdeviceType: false,
            primaryCrop: false,
            secondaryCrop: false
        });
        const focused = reactive({
            year: false,
            season: false,
            farmName: false,
            bedNumber: false,
            acreage: false,
            soilType: false,
            irrigation: false,
            IOTdeviceType: false,
            primaryCrop: false,
            secondaryCrop: false
        });

        const otherBedSelected = ref(false);

        const bedNumberIsChanged = ref(false);

        const crops = computed(() => store.state.crops.filter(crp => crp.sourceId === bed.id));

        const popUps = reactive({
          addAdditional: {
            active: false,
            message: 'Do you want to plan additional crop for this bed?'
          }
        });

        const soilManual = ref(false);

        const irrigationManual = ref(false);

        const soilField = ref(null);

        const irrigationField = ref(null);

        const submitForm = () => {

          console.log(newBedId.value, 'newBedId');
          let savedCrop = {};
          if (!bed.farmName || !bed.bedNumber || !bed.acreage) {
            if (!bed.farmName) activated.farmName = true;
            if (!bed.bedNumber) activated.bedNumber = true;
            if (!bed.acreage) activated.acreage = true;
            return;
          }

          if ((crop.sourceType === 'bed')) {
            if (!crop.year || !crop.season || //!crop.farmName || !crop.acreage || 
            !crop.crop || !crop.dateSeed || !crop.harvestDate || !crop.projHarvestAmount) {
              if (!crop.year) activatedCrop.year = true;
              if (!crop.season) activatedCrop.season = true;
              // if (!crop.farmName) activatedCrop.farmName = true;
              // if (!crop.acreage) activatedCrop.acreage = true;
              if (!crop.crop) activatedCrop.crop = true;
              if (!crop.dateSeed) activatedCrop.dateSeed = true;
              if (!crop.harvestDate) activatedCrop.harvestDate = true;
              if (!crop.projHarvestAmount) activatedCrop.projHarvestAmount = true;
              return;
            }

            savedCrop = {
              ...crop,
              id: crop.id || uuid(),
              farmName: crop.farmName || bed.farmName,
              sourceId: editionMode.value === 'edit' ? bed.sourceId : crop.sourceId ? crop.sourceId : newBedId.value
            };

            console.log('savedCrop: ', savedCrop, `crop.id: ${crop.id}, editionMode: ${editionMode.value}`);

            crop.id ? store.dispatch('editCrop', {
              crop: savedCrop
            }) : store.dispatch('createCrop', {
              crop: savedCrop
            });

            if (planCropSeasonIsChanged.value) {
              store.dispatch('archiveFarmPlan', {
                mapDraw: {...mapDraw},
                crop: {...savedCrop},
                selectedFarmId: selectedFarm.id,
                id: uuid()
              });
              console.log(selectedFarm, bed)
            }
          }

            console.log(bed, 'editionMode', editionMode);

            console.log({
              bed,
              savedCrop,
            }, 'future object');

            (editionMode.value === 'edit' && !bedNumberIsChanged.value) ? 
              store.dispatch('editBed', {
                ...bed,
                ...savedCrop,
                season: savedCrop.season,
                year: savedCrop.year,
                crop: savedCrop.crop,
                farmId: selectedFarm.id || selectedFarmId,
                id: bed.id
              }) : store.dispatch('createBed', {
                ...bed,
                ...savedCrop,
                season: savedCrop.season,
                year: savedCrop.year,
                crop: savedCrop.crop,
                farmId: selectedFarm.id || selectedFarmId,
                // sourceId: newBedId.value,
                sourceId: savedCrop.sourceId,
                // id: newBedId.value
                id: uuid()
              });

            // if (editionMode.value === 'create') {
            //   newBedId.value = uuid();
            // }

            if (editionMode.value !== 'edit') {
              popUps.addAdditional.active = true;
            } else {
              editionMode.value = 'represent';
            }
            newCropBedEdition.value = false;
            // cancelBed()
            // selectFarm({...selectedFarm})
            console.log(selectFarm);
        };

        const addAdditionalBed = (approve) => {
          popUps.addAdditional.active = false;
          if (approve) {
            for (let prop in crop) {
              if ((prop !== 'year') && (prop !== 'season') && (prop !== 'cropCycle') &&
              (prop !== 'sourceType') && (prop !== 'sourceId')) {
                crop[prop] = defaultCrop[prop];
              }
            }
            newCropBedEdition.value = true;
          } else {
            const currentFarm = store.state.farms.find(farm => farm.id === selectedFarm.id);

            editionMode.value = 'represent';
            // selectBed({...bed}, 'represent');
            selectBed({...currentFarm.beds[currentFarm.beds.length - 1]}, 'represent');
          }
        } 

        const cancelChanges = () => {
            newCropBedEdition.value = false;
            cancelBed();
        };
        const toggleSection = () => {
            sectionExpanded.value = !sectionExpanded.value;
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });
        watch(selectedFarm, (newVal) => {
            bed.farmName = newVal.farmName;
        });
        // watch(selectedFarmId, (newVal) => {
        //   bed.farmName = newVal.farmName
        // })

        watch(() => ({...crop}), () => {
          section.value.style.height = 'auto';
          setTimeout(() => {
            sectionHeight.value = section.value.offsetHeight;
          })
        });

        watch(() => (bed.bedNumber), (newVal) => {
          if (newVal === 'Other') {
            otherBedSelected.value = true;
            bed.bedNumber = '';
          }
          bedNumberIsChanged.value = true;
          newBedId.value = uuid();
        })

        watch(() => (bed.soilType), (newVal) => {
          if (newVal === 'Other') {
            bed.soilType = '';
            soilManual.value = true;

            setTimeout(() => {
              soilField.value.focus();
            }, 10)
          }
        });

        watch(() => (bed.irrigation), (newVal) => {
          if (newVal === 'Other') {
            bed.irrigation = '';
            irrigationManual.value = true;
            setTimeout(() => {
              irrigationField.value.focus();
            }, 10)
          }
        });

        onMounted(() => {
          if (!bed.id) {
            newBedId.value = uuid();
          }
          setTimeout(() => {
              sectionHeight.value = section.value.offsetHeight;
              section.value.style.height = `${section.value.offsetHeight}px`;
          });
          bed.farmName = selectedFarm.farmName;
          focusOnBed();

          if (editionMode.value === 'create') {
            // selectCrop({...defaultCrop}, 'create');
            for (let prop in defaultCrop) {
              if ((prop !== 'sourceType') && (prop !== 'sourceId')) {
                crop[prop] = defaultCrop[prop];
                // console.log(store.state.crops);
              }
            }
            console.log(selectCrop);
            bed.acreage = bed.acreage || selectedFarm.acreage;
            bed.soilType = bed.soilType || selectedFarm.soilComposition;
            bed.irrigation = bed.irrigation || selectedFarm.irrigationType;
            bed.IOTdeviceType = bed.IOTdeviceType || selectedFarm.IOTdeviceType;
          }

        });

        provide('bedsCropCreation', {
          newBedId
        })

        return {
            selectedFarm,
            sectionHeader,
            bedYears,
            bedSeasons,
            editBed,
            submitForm,
            bed,
            cancelChanges,
            activated,
            focused,
            section,
            boxHeader,
            toggleSection,
            crop,
            newBedId,
            bedsList,
            otherBedSelected,
            crops,
            editionMode,
            popUps,
            addAdditionalBed,
            soilManual,
            irrigationManual,
            soilTypes,
            irrigationSystems,
            newCropBedEdition
        };
    },
    components: {
    FarmBedPlanCropForm,
    DropDownList
}
};
</script>

<style lang="scss" scoped>
  .farm-bed-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    .header {
      font-size: 18px;
      color: #28292B;
      // margin-left: 15px;
      margin-left: 8px;
    }
    .bed-profile-section {
      padding: 16px 0 16px;
    }
    .row {
        &.wrapper {
          width: 100%;
          justify-content: space-evenly;
          height: 80px;
          .q-field__native, .q-field__prefix {
            color: rgba(40,41,43,0.6);
          }
          label {
            width: 100%;
            max-width: 165px;
          }
        }
      }
    .form-actions {
      a {
        text-decoration: none;
      }
      button {
        margin: 24px;
      }
    }
  }
</style>