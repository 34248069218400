<template>
  <section class="section mt-1">
<div class="border-box">
<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">My Advertisement</h5>
<div class="info-icons">
  <div class="info-icon lg top-icon"
    @click="addAdvertisement"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
  >
    <img src="@/assets/images/plus-green-md.png" alt="">
    <div class="helper-tooltip" v-if="helperTooltip">Add Advertisement</div>
  </div>
</div>
</div>

<div class="content-block">

<div class="content-row table-wrapper">
<div class="grid-table">
<p class="form-label">Date</p>
<p class="form-label">Campaign Title</p>
<p class="form-label">Start Date</p>
<p class="form-label">End Date</p>
<p class="form-label">Location</p>
<p class="form-label"></p>


<template
    v-for="(row, rowIndex) in rows"
    v-bind:key="'advertisement-list-row' + row.id"
  >
    <template
      v-if="(rowIndex + 1) <= maxPage"
    >
    <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
    
    <template v-if="rowIndex === selectedRow">
      <div><input type="text" class="form-control" v-model="row.date"></div>
      <div><input type="text" class="form-control" v-model="row.campaignTitle"></div>
      <div><input type="text" class="form-control" v-model="row.advertisementRange.fromDate"></div>
      <div><input type="text" class="form-control" v-model="row.advertisementRange.toDate"></div>
      <div><input type="text" class="form-control" v-model="row.advertisementGeographies[0].city"></div>
      <div class="table-links">
      <a href="javascript:void();" @click="saveEntity({...row}, rowIndex)">
        <img src="@/assets/images/checkbox-checked.svg" alt="" />
      </a>
      <a href="javascript:void();" @click="cancelChanges">
        <img src="@/assets/images/close.png" alt="" />
      </a>
      </div>
    </template>

    <template v-else>
      <div>{{ row.date }}</div>
      <div>{{ row.campaignTitle }}</div>
      <div>{{ row.advertisementRange.fromDate }}</div>
      <div>{{ row.advertisementRange.toDate }}</div>
      <div>{{ row.advertisementGeographies[0].city }}</div>
      <div class="table-links">
      <a href="javascript:void();" @click="selectEntity({...row}, rowIndex)">
        <img src="@/assets/images/edit-icon-2.svg" alt="">
      </a>
      <a href="javascript:void();" @click="removeEntity(row.id)">
        <img src="@/assets/images/trash-2.svg" alt="">
      </a>
      </div>
    </template>

    </template>
  </template>


<div><input type="text" class="form-control"></div>
<div><input type="text" class="form-control"></div>
<div><input type="text" class="form-control"></div>
<div><input type="text" class="form-control"></div>
<div><input type="text" class="form-control"></div>
<div class="table-links">
<a href="javascript:void();"><img src="@/assets/images/edit-icon-2.svg" alt=""></a>
<a href="javascript:void();"><img src="@/assets/images/trash-2.svg" alt=""></a>
</div>

<div><input type="text" class="form-control"></div>
<div><input type="text" class="form-control"></div>
<div><input type="text" class="form-control"></div>
<div><input type="text" class="form-control"></div>
<div><input type="text" class="form-control"></div>
<div class="table-links">
<a href="javascript:void();"><img src="@/assets/images/edit-icon-2.svg" alt=""></a>
<a href="javascript:void();"><img src="@/assets/images/trash-2.svg" alt=""></a>
</div>
</div>
</div>

<div class="content-row">
<div class="pagination">
<div>
<p>Records per page:</p>
<select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select>
</div>
<p>1-1 to 10</p>
</div>
</div>

</div>

</div>

</div>
</section>
</template>

<script>
import { defineComponent, computed, ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AdvertisementList',
  setup () {
    const store = useStore();
    
    const router = useRouter();

    const myAdvertisements = computed(() => store.state.myAdvertisementsList);

    const rows = ref([]);

    const maxPage = ref(5);

    const selectedRow = ref(null);

    // const sortOrder = reactive({
    //   equipment: 0,
    //   manufacturer: 0,
    //   vendor: 0,
    //   datePurchased: 0,
    //   warrantyExp: 0,
    //   serviceDate: 0
    // })

    const helperTooltip = ref(false);

    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle;
    }

    // const sortTable = (sortProp) => {
    //   console.log(sortOrder[sortProp])
    //   if (sortOrder[sortProp] === 0) {
    //     rows.value.sort((a,b) => {
    //       if (a[sortProp] < b[sortProp]) {
    //         return 1;
    //       }
    //       if (a[sortProp] > b[sortProp]) {
    //         return -1;
    //       }
    //       return 0;
    //     })
    //     sortOrder[sortProp] = 1;
    //   } else if (sortOrder[sortProp] > 0) {
    //     rows.value.sort((a,b) => {
    //       if (a[sortProp] < b[sortProp]) {
    //         return -1;
    //       }
    //       if (a[sortProp] > b[sortProp]) {
    //         return 1;
    //       }
    //       return 0;
    //     })
    //     sortOrder[sortProp] = -1;
    //   } else if (sortOrder[sortProp] < 0) {
    //     rows.value = [...equipments.value];
    //     sortOrder[sortProp] = 0;
    //   }
    // }

    const addAdvertisement = () => {
      router.push({
        name: 'advertisement',
        params: {
          pagename: 'advertisement-questionnaire-first'
        }
      })
    }

    const saveEntity = (entity, index) => {
      console.log(entity, index);
    }
    const cancelChanges = () => {

    }
    const selectEntity = (entity, index) => {
      console.log(entity, index);
      selectedRow.value = index;
    }
    const removeEntity = (rowId) => {
      console.log(rowId);
    }

    watch(() => (store.state.myAdvertisementsList), () => {
      rows.value = [...myAdvertisements.value];
    })

    onMounted(() => {

      // store.dispatch('getVendors')

      rows.value = [...myAdvertisements.value];
    })

    return {
      addAdvertisement, 
      saveEntity,
      cancelChanges,
      selectEntity,
      removeEntity,

      rows,
      maxPage,
      selectedRow,
      
      helperTooltip,
      switchHelperTooltip,
      
      // sortOrder,
      // sortTable
    }
  }
})
</script>