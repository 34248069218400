const subscriptionContent = {
  basic: ['farm-module', 'crops-module', 'task-module', 'labor-module'],
  plus: ['farm-module', 'crops-module', 'task-module', 'labor-module', 'vendor-module',
    'equipment-module', 'inventory-module', 'reports-module'],
  advance: ['farm-module', 'crops-module', 'task-module', 'labor-module', 'vendor-module',
    'equipment-module', 'inventory-module', 'reports-module', 'customers-module', 'sales-module'],
  ultimate: ['farm-module', 'crops-module', 'task-module', 'labor-module', 'vendor-module',
    'equipment-module', 'inventory-module', 'reports-module', 'customers-module', 'sales-module']
}

const isSubscribedCheck = (subscription, permission) => {
  
  let hasAccess = [];

  if (subscription?.basic) {
    subscriptionContent.basic.some((p) => p === permission) && hasAccess.push('basic');
  }

  if (subscription?.plus) {
    subscriptionContent.plus.some((p) => p === permission) && hasAccess.push('plus');
  }

  if (subscription?.advance) {
    subscriptionContent.advance.some((p) => p === permission) && hasAccess.push('advance');
  }

  if (subscription?.ultimate) {
    subscriptionContent.ultimate.some((p) => p === permission) && hasAccess.push('ultimate');
  }

  return (hasAccess.length > 0) ? true : false;
}

export default isSubscribedCheck;