<template>
  <section class="section" ref="section">
  <div class="border-box">
    <div class="border-box-header" ref="boxHeader" @click="toggleSection">
    <h5>Crop List</h5>
    <div class="info-icons">
    </div>
    <img src="@/assets/images/angle-down.png" alt="" class="angle">
    </div>

    <div class="border-box-content">

    <div class="content-row overflow-x-scroll table-container">
    <table>
    
    <thead>
    <tr>
    <th><p class="form-label-static form-sort-label">
      <span>Crop</span>
      <span @click="() => sortTable('crop')">
        <button :class="{active: sortOrder.crop === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.crop === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Year</span>
      <span @click="() => sortTable('year')">
        <button :class="{active: sortOrder.year === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.year === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Season</span>
      <span @click="() => sortTable('season')">
        <button :class="{active: sortOrder.season === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.season === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Cycle</span>
      <span @click="() => sortTableByNumber('cropCycle')">
        <button :class="{active: sortOrder.cropCycle === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.cropCycle === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Date Seed</span>
      <span @click="() => sortTableByNumber('dateSeed')">
        <button :class="{active: sortOrder.dateSeed === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.dateSeed === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Harvest Date</span>
      <span @click="() => sortTableByNumber('harvestDate')">
        <button :class="{active: sortOrder.harvestDate === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.harvestDate === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th class="w-75">&nbsp;</th>
    </tr>
    </thead>
    
    <tbody>

    <template
      v-for="(row, rowIndex) in rows"
      v-bind:key="'farm-list-row' + row.id"
    >
      <tr
        :class="{'light-gray-bg': (crop.id === row.id)}"
        @click="(ev) => selectByRow(ev, {...row})"
      >
        <td class="text-center">{{ row.crop }}</td>
        <td>{{ row.year }}</td>
        <td>{{row.season}}</td>
        <td>{{row.cropCycle}}</td>
        <td>
          {{ moment(row.dateSeed, 'YYYY-MM-DD').format('MM/DD/YYYY') }}
        </td>
        <td>
          {{ moment(row.harvestDate, 'YYYY-MM-DD').format('MM/DD/YYYY') }}
        </td>
        <td class="w-75">
        <div class="table-links">
        <a v-if="isAuth('edit-plan-crop')" href="javascript:void();" @click="editSelectedCrop(row)"><img src="@/assets/images/edit-icon-2.svg" alt=""></a>
        <a v-if="isAuth('remove-plan-crop')" href="javascript:void();" @click="removeCrop(row.id, rowIndex)"><img src="@/assets/images/trash-2.svg" alt=""></a>
        </div>
        </td>
      </tr>
    </template>

    
    </tbody>
    
    </table>
    </div>
    </div>
    </div>

    <div class="removal-popup" v-if="removedCrop">

    <div class="modal modal-md">
    <span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
    <div class="modal-block text-center">
    <p class="fw-bold">Are you sure you want to delete Plan Crop?</p>
    </div>
    <div class="modal-block text-center">
    <div class="buttons justify-content-center">
        <button
          @click="() => approveRemoval(true)"
          class="approve-button btn green" 
        >Yes</button>
        <button
          @click="() => approveRemoval(false)" 
          class="cancel-removal-button btn green btn-o" 
        >No</button>
    </div>
    </div>
    </div>

    </div>
    
  </section>
</template>

<script>
import moment from 'moment';
import { defineComponent, inject, computed, ref, onMounted, watch, reactive } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'FarmPlanCropList',
  setup() {
    const store = useStore();

    const { isAuth } = inject('mainUI');
    const rows = ref([]);
    const { selectedFarmId, mainSectionIsEditable } = inject('formMode');
    const { selectedFarm, farm } = inject('farmEdition');
    const { crop, selectCrop, newCropCreation } = inject('cropEdition');

    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);

    let bed, newBedId;

    const bedEdition = inject('bedEdition');

    const bedsCropCreation = inject('bedsCropCreation');
    
    if (bedEdition) {
      bed = bedEdition.bed;
    }

    if (bedsCropCreation) {
      newBedId = bedsCropCreation.newBedId;
    }

    const crops = computed(() => store.state.crops);

    const removedCrop = ref(null);

    const sortOrder = reactive({
      year: 0,
      season: 0,
      crop: 0,
      cropCycle: 0,
      dateSeed: 0,
      harvestDate: 0
    })

    const sortTable = (sortProp) => {
      console.log(sortOrder[sortProp])
      if (sortOrder[sortProp] === 0) {
        rows.value.sort((a,b) => {
          if (a[sortProp] < b[sortProp]) {
            return 1;
          }
          if (a[sortProp] > b[sortProp]) {
            return -1;
          }
          return 0;
        })
        sortOrder[sortProp] = 1;
      } else if (sortOrder[sortProp] > 0) {
        rows.value.sort((a,b) => {
          if (a[sortProp] < b[sortProp]) {
            return -1;
          }
          if (a[sortProp] > b[sortProp]) {
            return 1;
          }
          return 0;
        })
        sortOrder[sortProp] = -1;
      } else if (sortOrder[sortProp] < 0) {
        rows.value = [...crops.value];
        sortOrder[sortProp] = 0;
      }
    }

    const sortTableByNumber = (sortProp) => {
      if (sortOrder[sortProp] === 0) {
        rows.value.sort((a,b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]))
        sortOrder[sortProp] = 1;
      } else if (sortOrder[sortProp] > 0) {
        rows.value.sort((a,b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
        sortOrder[sortProp] = -1;
      } else if (sortOrder[sortProp] < 0) {
        rows.value = [...crops.value];
        sortOrder[sortProp] = 0;
      }
    }

    const toggleSection = () => {
      sectionExpanded.value = !sectionExpanded.value
    }

    const editSelectedCrop = (row) => {
      mainSectionIsEditable.value = false;
      newCropCreation.value = true;
      selectCrop(row, 'edit')
    }

    const removeCrop = (cropId, cropIndex) => {
      console.log(cropId, cropIndex);
      removedCrop.value = {cropId, cropIndex};
    }

    const approveRemoval = (approved) => {
      if (approved) {
        store.dispatch('removeCrop', removedCrop.value);
      }
      
      removedCrop.value = null;
    }

    const selectByRow = (ev, row) => {
      if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
        return;
      } else {
        selectCrop({...row}, 'represent');
      }
    }

    watch(() => ([...store.state.crops]), (newVal) => {
      console.log(newVal, 'newVal state.crops');
      if (bed || newBedId) {
        rows.value = [...newVal.filter(crp => ((crp.sourceType === 'bed') && ((crp.sourceId === bed.id) || (crp.sourceId === newBedId.value))))];
      } else {
        rows.value = [...newVal.filter(crp => ((crp.sourceType === 'farm') && ((crp.sourceId === farm.id) || (crp.sourceId === selectedFarmId.value) || (crp.sourceId === selectedFarm.id))))];
        if (crop.id) {
          ((rows.value.length > 0) && !newCropCreation.value) && selectCrop({...crop});
        } else {
          ((rows.value.length > 0) && !newCropCreation.value) && selectCrop({...rows.value[0]});
        }
      }

      section.value.style.height = 'auto';
      setTimeout(() => {
        sectionHeight.value = section.value.offsetHeight;
      })
    });

    watch(crops, (newVal) => {
      console.log(newVal, 'just crops is working');
    })

    watch(() => ([...store.state.crops]), (newVal) => {
      console.log(newVal, 'spread crops is working');
    })

    watch(sectionExpanded, (newVal) => {
      section.value.style.height = newVal ? 
        `${sectionHeight.value}px` : 
          `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'    })

    onMounted(() => {
      if (bed || newBedId) {
        rows.value = [...store.state.crops.filter(crp => ((crp.sourceType === 'bed') && ((crp.sourceId === bed.id) || (crp.sourceId === newBedId.value))))];
      } else {
        rows.value = [...store.state.crops.filter(crp => ((crp.sourceType === 'farm') && ((crp.sourceId === farm.id) || (crp.sourceId === selectedFarmId.value) || (crp.sourceId === selectedFarm.id))))];
        ((rows.value.length > 0) && !newCropCreation.value) && selectCrop({...rows.value[0]});
      }

      console.log(store.state.crops, farm.id, 'store.state.crops');

      console.log(rows.value, 'rows.value');

      setTimeout(() => {
        sectionHeight.value = section.value.offsetHeight;
        section.value.style.height = `${section.value.offsetHeight}px`;
      });
    })

    return {
      rows,
      editSelectedCrop,
      removeCrop,
      crop,

      sortOrder,
      sortTable,
      sortTableByNumber,
      selectCrop,
      removedCrop,
      approveRemoval,

      section,
      boxHeader,
      toggleSection,
      moment,
      selectByRow,
      isAuth
    }
  }
})
</script>

