<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Account Information</h5>
<div class="icon-group">
  <div class="icon green icon-o top-icon tooltip"
  @click="addAnotherUser"
  @mouseover="switchAddHelperTooltip(true)"
  @mouseout="switchAddHelperTooltip(false)"
  data-tooltip="Add User"
>
  <!-- <img src="@/assets/images/user-plus.svg" alt=""> -->
  <img src="@/assets/images/updated/064-add-user.png" alt="">
  <!-- <div class="add-helper-tooltip" v-if="addHelperTooltip">Add User</div> -->
</div>
<a href="javascript:void();" class="icon green icon-o">
  <!-- <img src="@/assets/images/edit.svg" alt=""> -->
  <img src="@/assets/images/updated/Edit.png" alt="">
</a>
</div>
</div>

<div class="content-block">
<div class="admin-update-form">
<div class="row justify-content-end">

<div class="col-auto">
<div class="form-item">
<p class="form-label-static">Email</p>
<input 
  type="text" 
  class="form-control" 
  v-model="mainDetails.email"
  @focus="() => {
    activated.email = true;
    focused.email = true;
  }"
  @blur="() => {
    focused.email = false;
  }"  
  :disabled="!updateIsActive"
>
<p v-if="activated.email && mainDetails.email.length === 0" class="form-info red text-end">* Required</p>
<p 
  v-if="activated.email && !focused.email && ((mainDetails.email.length > 0) && !/\S+@\S+\.\S+/.test(mainDetails.email))" 
  class="form-info red text-end"
  :style="{left: (activated.password && !focused.password && ((mainDetails.password.length > 0) && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._()-^])[A-Za-z\d@$!%*?&._()-^]{8,}$/.test(mainDetails.password))) ? '-40%' : ''}"
>Please use correct email format</p>
</div>
</div>

<div class="col-auto">
<div class="form-item password-field">
<p class="form-label-static">Password</p>
<input 
  :type="passwordIsVisible ? 'text' : 'password'"
  class="form-control" 
  v-model="mainDetails.password"
  @focus="() => {
    activated.password = true;
    focused.password = true;
  }"
  @blur="() => {
    focused.password = false;
  }"
  :disabled="!updateIsActive"
  :placeholder="passwordMask"
>
<div v-if="updateIsActive" class="password-icon" :class="{visible: passwordIsVisible, hidden: !passwordIsVisible}" @click="() => {passwordIsVisible = !passwordIsVisible}"></div>
<p v-if="activated.password && mainDetails.password.length === 0" class="form-info red text-end">* Required</p>
<p 
  v-if="updateIsActive && activated.password && !focused.password && ((mainDetails.password.length > 0) && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._-])[A-Za-z\d@$!%*?&._-]{8,}$/.test(mainDetails.password))" 
  class="form-info red text-end" style="width: 180%; left: -80%;"
  :style="{left: (activated.email && !focused.email && ((mainDetails.email.length > 0) && !/\S+@\S+\.\S+/.test(mainDetails.email))) ? '-40%' : '-80%'}"
>Please use min 8 symbols at least 1 upper case, 1 lower case, 1 number, 1 special character</p>
</div>
</div>

<div class="col-auto update-btn-sm-wrap">
  <button v-if="updateIsActive" type="submit" class="btn green update-btn-sm" @click="() => switchUpdatable(false)">Save</button>
  <button v-else type="submit" class="btn green update-btn-sm" @click="() => switchUpdatable(true)">Update</button>
</div>

</div>
</div>
</div>

<div class="content-block toggle-switch-wrap justify-content-end mr-170">

<div class="toggle-switch">
  <label class="switch">
    <input type="checkbox" v-model="mainDetails.multifactor">
    <span class="slider round"></span>
  </label>
  <p class="fw-semibold gray">Multi Factor Authentication</p>
</div>

<div class="checkbox-group checkbox-multifactor">
  <div class="radio radio-mobile">
    <input 
      type="radio" 
      name="mobileEmail" 
      id="mobile" 
      value="mobile"
      v-model="mainDetails.multifactorType"
      :disabled="!mainDetails.multifactor"
    >
    <label for="mobile">Mobile</label></div>
  <div class="radio">
    <input 
      type="radio" 
      name="mobileEmail" 
      id="email" 
      value="email"
      v-model="mainDetails.multifactorType"
      :disabled="!mainDetails.multifactor"
    >
    <label for="email">Email</label></div>
</div>

</div>

<div class="content-block">
  <div class="content-row">
      <div class="content-row grid-4 grid-full">
        <div class="row">

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.accountName || (mainDetails.accountName.length > 0)}">Account Name</p>
          <!-- <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.accountName"
            @focus="() => {
              activated.accountName = true;
              focused.accountName = true;
            }"
            @blur="() => {
              focused.accountName = false;
            }"
          > -->
          <DropDownList
            :mainObject="'mainDetails'"
            :mainProperty="'accountName'"
            :injectionName="'mainDetails'"
            :optionKey="'company-name-option-admin-users'"
            :optionsList="[]"
            :optionProperty="null"
            :optionPropertyTwo="null"
            :defaultSelected="mainDetails.accountName"
            @click="() => {
              activated.accountName = true;
            }"
          />
          <p v-if="activated.accountName && mainDetails.accountName.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.title || (mainDetails.title.length > 0)}">Title</p>

          <template v-if="compensations.length > 0">
            <DropDownList
              :mainObject="'mainDetails'"
              :mainProperty="'title'"
              :injectionName="'mainDetails'"
              :optionKey="'compensation-title-option-admin-users'"
              :optionsList="[...compensations, {employeeTitle: 'Other'}]"
              :optionProperty="'employeeTitle'"
              :optionPropertyTwo="null"
              :defaultSelected="mainDetails.title"
              @click="() => {
                activated.title = true;
              }"
            />
          </template>

          <input 
            v-else
            type="text" 
            class="form-control" 
            v-model="mainDetails.title"
            @focus="() => {
              activated.title = true;
              focused.title = true;
              gradeFormDialog = true;
            }"
            @blur="() => {
              focused.title = false;
            }"
          >
          <p v-if="activated.title && mainDetails.title.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.firstName || (mainDetails.firstName.length > 0)}">First Name</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.firstName"
            ref="firstNameField"
            @focus="() => {
              activated.firstName = true;
              focused.firstName = true;
            }"
            @blur="() => {
              focused.firstName = false;
            }"
          >
          <p v-if="activated.firstName && mainDetails.firstName.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.firstName && mainDetails.firstName.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.lastName || (mainDetails.lastName.length > 0)}">Last Name</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.lastName"
            @focus="() => {
              activated.lastName = true;
              focused.lastName = true;
            }"
            @blur="() => {
              focused.lastName = false;
            }"
          >
          <p v-if="activated.lastName && mainDetails.lastName.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.lastName && mainDetails.lastName.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.mobilePhone || (mainDetails.mobilePhone.length > 0)}">Mobile Phone</p>
          <input 
            type="text" 
            class="form-control" 
            pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
            v-model="mainDetails.mobilePhone"
            @focus="() => {
              activated.mobilePhone = true;
              focused.mobilePhone = true;
            }"
            @blur="() => {
              phoneNumberFormat('mobilePhone')
              focused.mobilePhone = false;
            }"
          >
          <p v-if="activated.mobilePhone && mainDetails.mobilePhone.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.mobilePhone && !focused.mobilePhone && (((completedFields.mobilePhone && (mainDetails.mobilePhone.length !== 10) || (mainDetails.mobilePhone.length > 10))) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(mainDetails.mobilePhone))" class="form-info red text-end">Please use 10 Digit for phone</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.workPhone || (mainDetails.workPhone.length > 0)}">Work Phone</p>
          <input 
            type="text" 
            class="form-control" 
            pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
            v-model="mainDetails.workPhone"
            @focus="() => {
              activated.workPhone = true;
              focused.workPhone = true;
            }"
            @blur="() => {
              phoneNumberFormat('workPhone')
              focused.workPhone = false;
            }"
          >
          <p v-if="activated.workPhone && mainDetails.workPhone.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.workPhone && !focused.workPhone && (((completedFields.workPhone && (mainDetails.workPhone.length !== 10) || (mainDetails.workPhone.length > 10))) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(mainDetails.workPhone))" class="form-info red text-end">Please use 10 Digit for phone</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.address || (mainDetails.address.length > 0)}">Address 1</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.address"
            @focus="() => {
              activated.address = true;
              focused.address = true;
            }"
            @blur="() => {
              focused.address = false;
            }"
          >
          <p v-if="activated.address && mainDetails.address.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.address && mainDetails.address.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.address2 || (mainDetails.address2.length > 0)}">Address 2</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.address2"
            @focus="() => {
              activated.address2 = true;
              focused.address2 = true;
            }"
            @blur="() => {
              focused.address2 = false;
            }"
          >
          <p v-if="activated.address2 && mainDetails.address2.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.city || (mainDetails.city.length > 0)}">City</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.city"
            @focus="() => {
              activated.city = true;
              focused.city = true;
            }"
            @blur="() => {
              focused.city = false;
            }"
          >
          <p v-if="activated.city && mainDetails.city.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.city && mainDetails.city.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.state || (mainDetails.state.length > 0)}">State</p>
          <!-- <select class="form-control" 
            v-model="mainDetails.state"
            @click="() => {
              activated.state = true;
              focused.state = true;
            }"
            @change="() => {
              focused.state = false;
            }"
          >
            <option v-for="stateOption in stateOptions"
              v-bind:key="stateOption + 'state-option-main'"
            >{{ stateOption }}</option>
          </select> -->
          <DropDownList
            :mainObject="'mainDetails'"
            :mainProperty="'state'"
            :injectionName="'mainDetails'"
            :optionKey="'state-option-admin-users'"
            :optionsList="stateOptions"
            :optionProperty="null"
            :optionPropertyTwo="null"
            :defaultSelected="mainDetails.state"
            @click="() => {
              activated.state = true;
            }"
          />
          <p v-if="activated.state && mainDetails.state.length === 0" class="form-info red text-end">* Required</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.zip || (mainDetails.zip.length > 0)}">Zip</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.zip"
            @focus="() => {
              activated.zip = true;
              focused.zip = true;
            }"
            @blur="() => {
              focused.zip = false;
            }"
          >
          <p v-if="activated.zip && mainDetails.zip.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.zip && mainDetails.zip.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.country || (mainDetails.country.length > 0)}">Country</p>
          <!-- <select class="form-control" 
            v-model="mainDetails.country"
            @click="() => {
              activated.country = true;
              focused.country = true;
            }"
            @change="() => {
              focused.country = false;
            }"
          >
            <option
              v-for="countryOption in countryOptions"
              v-bind:key="countryOption + 'country-option-main'"
            >{{ countryOption }}</option>
          </select> -->
          <DropDownList
            :mainObject="'mainDetails'"
            :mainProperty="'country'"
            :injectionName="'mainDetails'"
            :optionKey="'country-option-admin-users'"
            :optionsList="countryOptions"
            :optionProperty="null"
            :optionPropertyTwo="null"
            :defaultSelected="mainDetails.country"
            @click="() => {
              activated.country = true;
            }"
          />
          <p v-if="activated.country && mainDetails.country.length === 0" class="form-info red text-end">* Required</p>
        </div>
      </div>

</div>
</div>
</div>
</div>

  <div class="content-block">
  <h6 class="sub-headding">User Profile</h6>
  <div class="user-role">
  <div class="row">
  <div class="col-auto">
  <p class="title fw-semibold gray">Role Type</p>
  <p v-if="activated.userRole && mainDetails.userRole.length === 0" class="form-info red text-end">* Required</p>
  </div>
  <div class="col">
  <div class="row">
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="admin" id="admin" v-model="mainDetails.userRole"><label for="admin">Admin</label></div></div>
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="edit" id="editonly" v-model="mainDetails.userRole"><label for="editonly">Edit</label></div></div>
    <!-- <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="contribute" id="contributeonly" v-model="mainDetails.userRole"><label for="contributeonly">Contribute</label></div></div> -->
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="read" id="readonly" v-model="mainDetails.userRole"><label for="readonly">Read</label></div></div>
  </div>
  </div>
  </div>
  </div>
  </div>

  <div class="content-block d-lg-flex justify-content-between">
  <div class="checkbox-group task-type">
    <div v-if="employeeHelper" class="employee-tooltip">
      Allows for calculating wages!
    </div>
    <div class="row">
      <div class="col-auto" style="position: relative;">
        <p 
          class="title fw-semibold gray checkbox-title"
          @mouseover="switchEmployeeHelper(true)"
          @mouseout="switchEmployeeHelper(false)"  
          style="position: absolute; right: 1rem;"
        >User Type</p>
        <p class="title fw-semibold gray checkbox-title" style="opacity: 0; pointer-events: none;">Role Type</p>
        <p v-if="activated.employeeType && mainDetails.employeeType.length === 0" class="form-info red text-end" style="position: absolute; right: 1rem;">* Required</p>
      </div>
      <div class="col-auto">
        <div class="radio"><input type="radio" name="taskType" id="regular" value="salary"  v-model="mainDetails.employeeType"><label for="regular">Salary</label></div>
      </div>
      <div class="col-auto">
        <div class="radio"><input type="radio" name="taskType" id="overtime" value="parttime" v-model="mainDetails.employeeType"><label for="overtime">Part-time</label></div>
      </div>
      <div class="col-auto task-type-other">
        <div class="radio"><input type="radio" name="taskType" id="other" value="other" v-model="mainDetails.employeeType"><label for="other">Other</label></div>
      </div>
    </div>
  </div>
  </div>

  <div class="content-block" v-if="mainDetails.employeeType !== 'other'">
  <div class="user-grade">
  <div class="row">
  <div class="col-auto" style="position: relative;">
    <p class="title fw-semibold gray checkbox-title" style="opacity: 0; pointer-events: none;">Role Type</p>
    <p class="title fw-semibold gray checkbox-title"
      style="position: absolute; right: 1rem;top: 0; bottom: 0; display: flex; align-items: center;"
    >Grade</p>
    <p v-if="activated.grade && `${mainDetails.grade}`.length === 0" class="form-info red text-end" style="position: absolute; right: 1rem; bottom: 1rem;">* Required</p>
  </div>
  <div v-if="compensations.length > 0" class="col-auto grade-list">
    <!-- <select class="form-control sm" 
      v-model="mainDetails.grade"
      @click="() => {
        activated.grade = true;
        focused.grade = true;
      }"
      @change="() => {
        focused.grade = false;
      }"
    >
      <option
        v-for="compensation in compensations"
        v-bind:key="compensation.id + 'compensation-option-admin-users'"
      >{{ compensation.grade }}</option>
    </select> -->
    <DropDownList
      :mainObject="'mainDetails'"
      :mainProperty="'grade'"
      :injectionName="'mainDetails'"
      :optionKey="'compensation-option-main'"
      :optionsList="compensations"
      :optionProperty="'grade'"
      :optionPropertyTwo="null"
      :maxHeight="50"
      :isDisabled="true"
      :defaultSelected="mainDetails.grade"
      @click="() => {
        activated.grade = true;
      }"
    />
  </div>
  <div class="col-auto" v-else>
    <input type="text" class="form-control sm" v-model="mainDetails.grade" @click="() => {addedGrade = true}" />
  </div>
  </div>
  </div>
  </div>

<!-- <FileAttachments v-bind:section="'adminUsers'" /> -->
<FileAttachments :key="`form-${mainDetails.id}`" v-bind:section="(mainDetails.id && (mainDetails.id !== 'primary')) ? 'secondary' : 'primary'" v-bind:instance="`${(mainDetails.id && (mainDetails.id !== 'primary')) ? mainDetails.id : ''}`" />

<div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto">
  <button @click="submitForm" type="submit" class="btn green">Save</button>
</div>
<div class="col-auto">
  <button @click="cancelChanges" type="submit" class="btn light-blue-20 btn-o">Cancel</button>
</div>
</div>
</div>
</div>

</div>

<div v-if="notUpdated.active">
  <UserNotification
    v-bind:messageText="notUpdated.message"
  />
</div>

<div v-if="missedField">
  <UserNotification
    v-bind:messageText="'Please add the required data'"
  />
</div>

<template  v-if="gradeFormDialog">
  <!-- <draggable-resizable-vue
    v-model:x="element.x"
    v-model:y="element.y"
    v-model:h="element.height"
    v-model:w="element.width"
    v-model:active="element.isActive"
    :resizable="false"
  > -->
    <div class="form-modal">
      <span class="form-modal-close" @click="() => {gradeFormDialog = false}"><img src="@/assets/images/close-green-xs.png" alt=""></span>
      <EmployeeCompensation v-bind:dialogView="true" />
    </div>
  <!-- </draggable-resizable-vue> -->
</template>

<div class="add-grade-popup" v-if="addedGrade">
  
  <div class="modal modal-md">
  <span class="modal-close" @click="() => redirectToGrade()"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">Please add employee grade in the employee compensation section</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => redirectToGrade()"
        class="approve-button btn green" 
      >Ok</button>
  </div>
  </div>
  </div>
  
</div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { inject, reactive, ref, watch, onMounted, provide } from 'vue';
import { useStore } from 'vuex';
import stateOptions from '@/sharedData/statesList.js'
import countryOptions from '@/sharedData/countriesList.js'
import { useRouter } from 'vue-router';
import UserNotification from '@/components/Shared/UserNotification.vue';
import DropDownList from '@/components/Shared/DropDownList.vue';
import EmployeeCompensation from '@/components/Pages/Expenses/EmployeeCompensation.vue'

export default {
    name: "AdminUsersMainForm",
    components: { FileAttachments, UserNotification, DropDownList, EmployeeCompensation },
    setup() {
      const store = useStore();
      const router = useRouter();
      
      const { 
        mainEditionMode,
        mainDetails,
        defaultMainDetails,
        createNewUser,
        selectMainDetails,
        options: companiesList
      } = inject("mainDetails");

      const activated = reactive({
        email: false,
        password: false,
        accountName: false,
        title: false,
        firstName: false,
        lastName: false,
        mobilePhone: false,
        workPhone: false,
        address: false,
        address2: false,
        city: false,
        state: false,
        zip: false,
        country: false,
        userRole: false,
        employeeType: false,
        grade: false
      })

      const focused = reactive({
        email: false,
        password: false,
        accountName: false,
        title: false,
        firstName: false,
        lastName: false,
        mobilePhone: false,
        workPhone: false,
        address: false,
        address2: false,
        city: false,
        state: false,
        zip: false,
        country: false
      });

      const completedFields = reactive({
        mobilePhone: false,
        workPhone: false
      })

      const notUpdated = reactive({
        active: false,
        message: 'Please save, before proceeding'
      });

      const updateIsActive = ref(false);

      const passwordIsVisible = ref(false);

      const addedGrade = ref(false);

      const compensationsRaw = ref([...store.state.compensations]);

      const compensations = ref([]);

      const addHelperTooltip = ref(false);

      const passwordMask = ref('');

      const farms = ref(store.state.farms);

      const missedField = ref(false);

      const gradeFormDialog = ref(false);

      const firstNameField = ref(null);

      const switchAddHelperTooltip = (toggle) => {
        addHelperTooltip.value = toggle;
      };

      const switchUpdatable = (isUpdatable) => {
        if (isUpdatable) {
          updateIsActive.value = true;
        } else {
          if (mainDetails.email && mainDetails.password) {
            updateIsActive.value = false;

            store.dispatch("saveNewUser", {
              section: "mainDetails",
              data: {
                ...mainDetails,
              }
            });

          } else {
            console.log(mainDetails.email, mainDetails.password, 'notUpdated');
            notUpdated.active = true;

            setTimeout(() => {
              notUpdated.active = false;
            }, 5000)
          }
        }
      }

      const phoneNumberFormat = (phoneType) => {
        if (mainDetails[phoneType].length > 0) {
          if (mainDetails[phoneType][0] !== "(") {
            const arrStr = mainDetails[phoneType].split("");
            mainDetails[phoneType] = `(${arrStr.filter((num, indx) => {
              if (indx < 3)
                return num;
            }).join("")}) ${arrStr.filter((num, indx) => {
              if ((indx >= 3) && (indx < 6))
                return num;
            }).join("")}-${arrStr.filter((num, indx) => {
              if (indx >= 6)
                return num;
            }).join("")}`;
            completedFields[phoneType] = true;
          }
        }
      }

      const cancelChanges = () => {

        const tempMainDetails = {
          ...mainDetails
        }

        for (let prop in defaultMainDetails) {
          mainDetails[prop] = defaultMainDetails[prop];
        }

        for (let prop in activated) {
          activated[prop] = false;
        }

        for (let prop in focused) {
          focused[prop] = false;
        }

        if (tempMainDetails.id.length > 0) {
          selectMainDetails({...tempMainDetails}, 'represent');
        } else {
          mainEditionMode.value = 'create';
        }

      };

      const submitForm = () => {
        if (!mainDetails.email || /*!mainDetails.password || */ (mainDetails.password === '********') ||
          !mainDetails.firstName || !mainDetails.lastName || !mainDetails.mobilePhone ||
          !mainDetails.address || !mainDetails.city ||
          !mainDetails.state || !mainDetails.zip || !mainDetails.country ||
          !mainDetails.userRole || !mainDetails.employeeType || 
          ((mainDetails.employeeType !== 'other') && !mainDetails.grade)) {
          if (!mainDetails.email) activated.email = true;
          // if (!mainDetails.password) activated.password = true;
          // if (mainDetails.password === '********') activated.password = true;
          if (!mainDetails.firstName) activated.firstName = true;
          if (!mainDetails.lastName) activated.lastName = true;
          if (!mainDetails.mobilePhone) activated.mobilePhone = true;
          if (!mainDetails.address) activated.address = true;
          if (!mainDetails.city) activated.city = true;
          if (!mainDetails.state) activated.state = true;
          if (!mainDetails.zip) activated.zip = true;
          if (!mainDetails.country) activated.country = true;
          if (!mainDetails.userRole) activated.userRole = true;
          if (!mainDetails.employeeType) activated.employeeType = true;
          if ((mainDetails.employeeType !== 'other') && !mainDetails.grade) activated.grade = true;
          // if (!mainDetails.email || !mainDetails.password) {
          //   notUpdated.active = true;

          //   setTimeout(() => {
          //     notUpdated.active = false;
          //   }, 5000)
          // }
          if (!mainDetails.email) activated.email = true;
          if (!mainDetails.password) activated.password = true;
          
          if (mainDetails.multifactor && !mainDetails.multifactorType) {
            activated.multifactorType = true;
          }

          setTimeout(() => {
            const firstError = document.getElementsByClassName('form-info red text-end')[0];
          
            const viewPortOffset = firstError.getBoundingClientRect();

            document.getElementsByTagName('main')[0].scroll({
              top: firstError ? Math.abs(viewPortOffset.top) : 0,
              left: 0,
              behavior: 'smooth'
            })
          }, 100);

          missedField.value = true;

          setTimeout(() => {
            missedField.value = false;
          }, 5100);

          return;
        }

        // if (mainDetails.id.length <= 0) {
        //   mainDetails.id = uuid();
        // }
        if (mainDetails.id === 'primary') {

          store.dispatch("savePrimaryUser", {
            section: "mainDetails",
            data: {
              ...mainDetails
            }
          });

          store.state.primaryUserTenant.id ?
            store.dispatch('updateTenant', {
              data: {
                ...mainDetails
              },
              section: 'mainDetails'
            }) :
            store.dispatch('createTenant', {
              data: {
                ...mainDetails
              },
              section: 'mainDetails'
            });

          store.dispatch('updateCompanyData', {data: {
            ...mainDetails
          }})

          selectMainDetails({...mainDetails}, 'represent');
          return;
        }

        store.dispatch("saveNewUser", {
          section: "mainDetails",
          data: {
            ...mainDetails,
          }
        });

        store.dispatch("saveNewUser", {
          section: "subscriptionAssigned",
          data: {
            ...mainDetails,
          }
        });

        store.dispatch('changeNewUser', {
            userId: mainDetails.id,
            section: 'subscriptionAssigned',
            data: { trial: true }
        });

        store.dispatch("saveTemporaryUser", {
          section: "mainDetails",
          data: {}
        });

        // Added 07-03
        store.dispatch('changeUserData', {
            userId: mainDetails.id,
            section: 'mainDetails',
            data: { ...mainDetails }
        });
        
        selectMainDetails({...mainDetails}, 'represent');
      };

      const redirectToGrade = () => {
        addedGrade.value = false;

        if (mainDetails.id === 'primary') {
          store.dispatch("savePrimaryUser", {
            section: "mainDetails",
            data: {
              ...mainDetails
            }
          });

          router.push({
            name: 'admin', 
            params: { 
              pagename: 'employee-compensation',
              source: 'admin-account',
              admin: true,
              employeeType: mainDetails.employeeType,
              assigned: 'primary'
            } 
          })

          return;
        }

        store.dispatch("saveTemporaryUser", {
          section: "mainDetails",
          data: {
            ...mainDetails
          }
        });

        router.push({
          name: 'admin', 
          params: { 
            pagename: 'employee-compensation',
            source: 'admin-users',
            admin: true,
            employeeType: mainDetails.employeeType,
            assigned: mainDetails.id
          } 
        })
      }

      const addAnotherUser = () => {
        // router.push({ name: 'labor', params: { pagename: 'secondary-user' } })
        createNewUser();
      }

      watch(() => (mainDetails.multifactor), () => {
        mainDetails.multifactorType = '';
      })

      watch(() => (mainDetails.employeeType), (newVal) => {
        if (newVal) {
          compensations.value = compensationsRaw.value.filter(comp => comp.employeeType === newVal);
        }
      })

      watch(mainDetails, (newVal, oldVal) => {
        if (!newVal.id && oldVal.id) {
          mainDetails.id = uuid();
        }
      })

      watch(() => (mainDetails.accountName), (newVal) => {
        if (newVal === store.state.primaryUser?.mainDetails?.accountName) {
          mainDetails.workPhone = store.state.primaryUser?.mainDetails?.workPhone || '';
          mainDetails.address = store.state.primaryUser?.mainDetails?.address || '';
          mainDetails.address2 = store.state.primaryUser?.mainDetails?.address2 || '';
          mainDetails.city = store.state.primaryUser?.mainDetails?.city || '';
          mainDetails.state = store.state.primaryUser?.mainDetails?.state || '';
          mainDetails.zip = store.state.primaryUser?.mainDetails?.zip || '';
          mainDetails.country = store.state.primaryUser?.mainDetails?.country || '';
        }
        const currentFarm = farms.value.find(farm => farm.farmName === newVal);

        if (currentFarm) {
          mainDetails.workPhone = currentFarm?.workPhone || '';
          mainDetails.address = currentFarm?.address || '';
          mainDetails.address2 = currentFarm?.address2 || '';
          mainDetails.city = currentFarm?.city || '';
          mainDetails.state = currentFarm?.state || '';
          mainDetails.zip = currentFarm?.zip || '';
          mainDetails.country = currentFarm?.country || '';
        }
      });

      watch(() => ({...store.state.authenticated}), (newVal) => {

        if (newVal.email && (updateIsActive.value === false)) {
          passwordMask.value = '********';
        }
      });

      watch(updateIsActive, (newVal) => {
        if (newVal) {
          passwordMask.value = '';
        } else {
          if (store.state.authenticated.email) {
            passwordMask.value = '********';
          }
        }
      });

      watch(() => ([...store.state.compensations]), (newVal) => {
        if (newVal) {
          compensations.value = [...newVal]; //.filter(comp => comp.employeeType === mainDetails.employeeType);
          (compensations.value.length > 0) && compensations.value.sort((a,b) => {if (a.employeeTitle > b.employeeTitle) {return 1} if (a.employeeTitle < b.employeeTitle) {return -1}});
          
          const selectedCompensation = [newVal].find(c => c.employeeTitle === (mainDetails.title && mainDetails.title.split(' - ')[0]));

          if (selectedCompensation) {
            mainDetails.grade = selectedCompensation.grade;
            mainDetails.employeeType = selectedCompensation.employeeType;
          }
        }
      })

      watch(() => (mainDetails.title), (newVal) => {
        if (newVal === 'Other') {
          console.log('is Other');
          gradeFormDialog.value = true;
        }
        if (newVal) {
          const selectedCompensation = compensationsRaw.value.find(c => c.employeeTitle === (mainDetails.title && mainDetails.title.split(' - ')[0]));

          if (selectedCompensation) {
            mainDetails.grade = selectedCompensation.grade;
            mainDetails.employeeType = selectedCompensation.employeeType;
          }
        }
      })

      watch(gradeFormDialog, (newVal, oldVal) => {
        if (!newVal && oldVal) {
          setTimeout(() => {
            firstNameField.value.focus();
          }, 10);
        }
      })

      watch(() => ([...store.state.farms]), (newVal) => {

        farms.value = [...newVal];

        const primaryCompany = store.state.primaryUser?.mainDetails?.accountName || ''

        companiesList.value = [primaryCompany, ...[...farms.value.filter(farm => !farm.sameAddress)].map(farm => farm.farmName)];
      });

      onMounted(() => {
        store.dispatch('getCompensations');
        if (farms.value.length === 0) {
          store.dispatch('getFarms');
        }
        if (mainDetails.id.length <= 0) {
          mainDetails.id = uuid();
        }
        if (mainDetails.employeeType === 'salary') {
          compensations.value = compensationsRaw.value.filter(comp => comp.employeeType === 'salary')
        }
        if (mainDetails.employeeType === 'parttime') {
          compensations.value = compensationsRaw.value.filter(comp => comp.employeeType === 'parttime')
        }
        const primaryCompany = store.state.primaryUser?.mainDetails?.accountName || ''

        companiesList.value = [primaryCompany, ...[...farms.value.filter(farm => !farm.sameAddress)].map(farm => farm.farmName)];

        if (store.state.authenticated.email && (updateIsActive.value === false)) {
          passwordMask.value = '********';
        }
      })

      provide('userProfileDetails', {
        mainDetails,
        gradeFormDialog
      });

      return {
        mainDetails,
        stateOptions,
        countryOptions,
        createNewUser,
        submitForm,
        cancelChanges,
        phoneNumberFormat,
        activated,
        focused,
        addAnotherUser,
        addHelperTooltip,
        switchAddHelperTooltip,
        addedGrade,
        compensations,
        redirectToGrade,

        notUpdated,
        passwordIsVisible,
        updateIsActive,
        switchUpdatable,
        completedFields,
        passwordMask,
        missedField,
        gradeFormDialog,
        firstNameField
      };
    }
}
</script>

<style lang="scss" scoped>
.admin-update-form .form-label-static:not(:last-child) {
  margin-bottom: 0;
  margin-right: 1.5rem;
}
.title {
  &.fw-semibold {
    font-family: var(--fw-semibold);
  }
}

.radio {
  label {
    color: hsl(var(--gray));
    font-size: var(--fs-lg);
    font-family: var(--fw-medium);
  }
}

.checkbox-group *:not(:last-child) {
  margin: auto;
  &.radio-mobile {
    margin-right: 5rem;
  }
}

// .checkbox-title {
//   margin-left: 6rem !important;
// }

.checkbox-group {
  &.task-type {
    position: relative;
  }
}

.employee-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 30px;
  left: -15px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 90px;
    background: #3C3838;
    z-index: -1;
  }
}

.password-field {
  position: relative;
  .password-icon {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 8;
    border-radius: 1rem;
    background-color: #fff;
    &.visible {
      background:url('@/assets/images/updated/visiblepassword.png') center center no-repeat;
      background-size: contain;
    }
    &.hidden {
      background:url('@/assets/images/updated/hidepassword.png') center center no-repeat;
      background-size: contain;
    }
  }
}

.add-grade-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
}
</style>