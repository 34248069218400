<template>
  <div class="section" ref="section">
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Inventory Plan</h5>
<div class="info-icons">
<div class="info-icon lg top-icon tooltip"
  @click="addPlanInput"
  v-if="isAuth('add-plan-input')"
  data-tooltip="Plan Input"
>
<!--   @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)" -->
  <img src="@/assets/images/updated/new_set/Inventory_Box_Circle.png" alt="">
  <!-- <img src="@/assets/images/updated/add-item.png" alt=""> -->
  <!-- <div class="helper-tooltip" v-if="helperTooltip">Add Input</div> -->
</div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-wrapper">
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label"><span>Item</span>
  <span @click="() => sortTable('item')">
    <button  :class="{active: sortOrder.item === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button  :class="{active: sortOrder.item === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label"><span>In Stock</span>
  <span @click="() => sortTableByNumber('inStockNumber')">
    <button :class="{active: sortOrder.inStockNumber === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.inStockNumber === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label"><span>Required Item</span>
  <span @click="() => sortTableByNumber('requiredNumber')">
    <button :class="{active: sortOrder.requiredNumber === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.requiredNumber === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label"><span>Order Item</span>
  <span @click="() => sortTableByNumber('orderNumber')">
    <button :class="{active: sortOrder.orderNumber === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.orderNumber === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label"><span>Order Weight</span>
  <span @click="() => sortTableByNumber('orderQuantity')">
    <button :class="{active: sortOrder.orderQuantity === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.orderQuantity === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label"><span>Order Date</span>
  <span @click="() => sortTable('orderDate')">
    <button :class="{active: sortOrder.orderDate === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.orderDate === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<!-- <th><p class="form-label-static form-sort-label"><span>Vendor</span>
  <span @click="() => sortTable('vendorName')">
    <button :class="{active: sortOrder.vendorName === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.vendorName === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label"><span>Vendor Phone</span>
  <span @click="() => sortTable('vendorPhone')">
    <button :class="{active: sortOrder.vendorPhone === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.vendorPhone === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th> -->
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

  <template
    v-for="(row, rowIndex) in rows"
    v-bind:key="'inventory-plan-list-row' + row.id"
  >
    <tr
      v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
      :class="{'light-gray-bg': row.id === selectedPlanInput.id}"
      @click="(ev) => selectByRow(ev, {...row})"
    >
    <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
    <td class="text-center">{{ row.item }}</td>
    <td>{{ row.inStockNumber }}</td>
    <td>{{ row.requiredNumber }}</td>
    <td>{{ row.orderNumber }}</td>
    <td>{{ row.orderQuantity }}</td>
    <td>{{ row.orderDate }}</td>
    <!-- <td>{{ row.vendorName }}</td>
    <td>{{ row.vendorPhone }}</td> -->
    <td class="w-75">
    <div class="table-links">
    <a v-if="isAuth('edit-plan-input')" href="javascript:void();" @click="selectPlanInput({...row}, 'edit')">
      <img src="@/assets/images/edit-icon-2.svg" alt="">
    </a>
    <a v-if="isAuth('remove-plan-input')" href="javascript:void();" @click="removeSelectedPlanInput(row.id)">
      <img src="@/assets/images/trash-2.svg" alt="">
    </a>
    </div>
    </td>
    </tr>
  </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'inventoryPlanListDetails'"
    :optionKey="'inventory-plan-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>
</div>

<div class="removal-popup" v-if="removedPlanInputId">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">Are you sure you want to delete the planned item?</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveRemoval(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { ref, onMounted, watch, computed, inject, reactive, provide } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "InventoryPlanList",
    setup() {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const plannedInventories = computed(() => store.state.plannedInventories);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const sortOrder = reactive({
            item: 0,
            inStockNumber: 0,
            requiredNumber: 0,
            orderNumber: 0,
            orderQuantity: 0,
            orderDate: 0,
            vendor: 0,
            vendorPhone: 0
        });
        const { isAuth } = inject('mainUI');
        const { selectedPlanInput, addPlanInput, editPlanInput, selectPlanInput, removePlanInput } = inject('planInputDetails');
        const helperTooltip = ref(false);
        const removedPlanInputId = ref(null);

        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...plannedInventories.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByNumber = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...plannedInventories.value];
                sortOrder[sortProp] = 0;
            }
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectPlanInput({ ...row }, 'represent');
            }
        };
        const approveRemoval = (isApproved) => {
          if (isApproved) {
            removePlanInput(removedPlanInputId.value);
          }
          removedPlanInputId.value = '';
        };
        const removeSelectedPlanInput = (planInputId) => {
          removedPlanInputId.value = planInputId;
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(() => ([...store.state.plannedInventories]), () => {
            console.log(plannedInventories.value, 'addedInventories');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...plannedInventories.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
          store.dispatch('getPlannedInventories');
            (plannedInventories.value.length > 0) && selectPlanInput({ ...plannedInventories.value[0] }, 'represent');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getAddedInputs')
            rows.value = [...plannedInventories.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('inventoryPlanListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            section,
            boxHeader,
            toggleSection,
            addPlanInput,
            editPlanInput,
            selectPlanInput,
            removePlanInput,
            rows,
            maxPage,
            helperTooltip,
            switchHelperTooltip,
            sortOrder,
            sortTable,
            sortTableByNumber,
            selectedPlanInput,
            selectByRow,
            currentPage,
            isAuth,
            approveRemoval,
            removeSelectedPlanInput,
            removedPlanInputId
        };
    },
    components: { DropDownList }
}
</script>

<style lang="scss" scoped>
.top-icon {
  position: relative;
}
</style>