<template>


<section class="section" ref="section">
  <div class="border-box">
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Billing Address</h5>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-block">
<div class="checkbox checkbox-lg"><input type="checkbox" id="addressSame" v-model="sameAddress"><label for="addressSame">Billing Address is same as Account Address</label></div>
</div>

<div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.accountName || (billing.accountName.length > 0)}">Account Name</p>
<input type="text" class="form-control" 
  v-model="billing.accountName"
  @focus="() => {
    activated.accountName = true;
    focused.accountName = true;
  }"
  @blur="() => {
    focused.accountName = false;
  }"
  placeholder="E.g., Savage Farms LLC"
>
<p v-if="activated.accountName && billing.accountName.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.accountName && billing.accountName.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.firstName || (billing.firstName.length > 0)}">First Name</p>
<input type="text" class="form-control" 
  v-model="billing.firstName"
  @focus="() => {
    activated.firstName = true;
    focused.firstName = true;
  }"
  @blur="() => {
    focused.firstName = false;
  }"
  placeholder="E.g., James"
>
<p v-if="activated.firstName && billing.firstName.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.firstName && billing.firstName.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.lastName || (billing.lastName.length > 0)}">Last Name</p>
<input type="text" class="form-control" 
  v-model="billing.lastName"
  @focus="() => {
    activated.lastName = true;
    focused.lastName = true;
  }"
  @blur="() => {
    focused.lastName = false;
  }"
  placeholder="E.g., Henry"
>
<p v-if="activated.lastName && billing.lastName.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.lastName && billing.lastName.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.address || (billing.address.length > 0)}">Address 1</p>
<input type="text" class="form-control" 
  v-model="billing.address"
  @focus="() => {
    activated.address = true;
    focused.address = true;
  }"
  @blur="() => {
    focused.address = false;
  }"
  placeholder="E.g., 7447 Egan Dr"
>
<p v-if="activated.address && billing.address.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.address && billing.address.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.address2 || (billing.address2.length > 0)}">Address 2</p>
<input type="text" class="form-control" 
  v-model="billing.address2"
  @focus="() => {
    activated.address2 = true;
    focused.address2 = true;
  }"
  @blur="() => {
    focused.address2 = false;
  }"
  placeholder="E.g., Suite 500"
>
<p v-if="activated.address2 && billing.address2.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.city || (billing.city.length > 0)}">City</p>
<input type="text" class="form-control" 
  v-model="billing.city"
  @focus="() => {
    activated.city = true;
    focused.city = true;
  }"
  @blur="() => {
    focused.city = false;
  }"
  placeholder="E.g., Savage"
>
<p v-if="activated.city && billing.city.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.city && billing.city.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.state || (billing.state.length > 0)}">State</p>
<!-- <select class="form-control" 
  v-model="billing.state"
  @click="() => {
    activated.state = true;
    focused.state = true;
  }"
  @change="() => {
    focused.state = false;
  }"
>
<option
  v-for="stateOption in stateOptions"
  v-bind:key="stateOption + 'state-option-billing'"
>
  {{ stateOption }}
</option>
</select> -->
<DropDownList
  :mainObject="'billing'"
  :mainProperty="'state'"
  :injectionName="'billing'"
  :optionKey="'state-option-billing'"
  :optionsList="stateOptions"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :maxHeight="150"
  :defaultSelected="billing.state"
  @click="() => {
    activated.state = true;
  }"
/>
<p v-if="activated.state && billing.state.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.zip || (billing.zip.length > 0)}">Zip</p>
<input type="text" class="form-control" 
  v-model="billing.zip"
  @focus="() => {
    activated.zip = true;
    focused.zip = true;
  }"
  @blur="() => {
    focused.zip = false;
  }"
  placeholder="E.g., 55378"
>
<p v-if="activated.zip && billing.zip.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.zip && billing.zip.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label"  :class="{active: focused.country || (billing.country.length > 0)}">Country</p>
<!-- <select class="form-control" 
  v-model="billing.country" 
  @click="() => {
    activated.country = true;
    focused.country = true;
  }"
  @change="() => {
    focused.country = false;
  }"
>
<option 
  v-for="countryOption in countryOptions"
  v-bind:key="countryOption + 'country-option-billing'"
>
  {{ countryOption }}
</option>
</select> -->
<DropDownList
  :mainObject="'billing'"
  :mainProperty="'country'"
  :injectionName="'billing'"
  :optionKey="'country-option-billing'"
  :optionsList="countryOptions"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :maxHeight="50"
  :defaultSelected="billing.country"
  @click="() => {
    activated.country = true;
  }"
/>
<p v-if="activated.country && billing.country.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>
</div>
</div>
</div>

</div>

<div class="content-block">
<div class="buttons form-buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges" >Cancel</button></div>
</div>
</div>
</div>

</div>
</div>
</section>

  <!-- <q-expansion-item
    class="overflow-hidden rounded-borders billing-form"
    style="border-radius: 10px"
    popup
    :header-style="{
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      fontWeight: 700,
    }"
    v-model="billing.expanded"
    label="Billing Address"
  >
    <template v-slot:header>
      <q-item-section>
        <span class="header">{{sectionHeader}}</span>
      </q-item-section>
    </template>
    <q-card>
      <q-card-section class="billing-section">

      
        <div class="row wrapper">
          <q-field
            borderless
            class="outer-label"
            color="bhoomi"
            label="Account Name"
            :model-value="billing.accountName"
          >
            <template v-slot:control>
              <q-input
                outlined
                v-model="billing.accountName"
                color="bhoomi"
                placeholder="Account Name"
                :rules="[
                  (val) => !!val || '* Required',
                  (val) =>
                    val.length <= 100 || 'Please use maximum 100 characters',
                ]"
              />
            </template>
          </q-field>

          <q-field
            borderless
            class="outer-label"
            color="bhoomi"            
            label="First Name"
            :model-value="billing.firstName"
          >
            <template v-slot:control>
              <q-input
                outlined
                v-model="billing.firstName"
                color="bhoomi"
                placeholder="First Name"
                :rules="[
                  (val) => !!val || '* Required',
                  (val) =>
                    val.length <= 50 || 'Please use maximum 50 characters',
                ]"
              />
            </template>
          </q-field>

          <q-field
            borderless
            class="outer-label"
            color="bhoomi"

            label="Last Name"
            :model-value="billing.lastName"
          >
            <template v-slot:control>
              <q-input
                outlined
                v-model="billing.lastName"
                color="bhoomi"
                placeholder="Last Name"
                :rules="[
                  (val) => !!val || '* Required',
                  (val) =>
                    val.length <= 50 || 'Please use maximum 50 characters',
                ]"
              />
            </template>
          </q-field>

          <q-field
            borderless
            class="outer-label"
            color="bhoomi"            
            label="Address 1"
            :model-value="billing.address"
          >
            <template v-slot:control>
              <q-input
                outlined
                v-model="billing.address"
                color="bhoomi"
                placeholder="Address 1"
                :rules="[
                  (val) => !!val || '* Required',
                  (val) =>
                    val.length <= 100 || 'Please use maximum 100 characters',
                ]"
              />
            </template>
          </q-field>
        </div>

        <div class="row wrapper">
          <q-field
            borderless
            class="outer-label"
            color="bhoomi"            
            label="Address 2"
            :model-value="billing.address2"
          >
            <template v-slot:control>
              <q-input
                outlined
                v-model="billing.address2"
                color="bhoomi"
                placeholder="Address 2"
                :rules="[
                  (val) =>
                    val.length <= 75 || 'Please use maximum 75 characters',
                ]"
              />
            </template>
          </q-field>

          <q-field
            borderless
            class="outer-label"
            color="bhoomi"            
            label="City"
            :model-value="billing.city"
          >
            <template v-slot:control>
              <q-input
                outlined
                v-model="billing.city"
                color="bhoomi"
                placeholder="City"
                :rules="[
                  (val) => !!val || '* Required',
                  (val) =>
                    val.length <= 50 || 'Please use maximum 50 characters',
                ]"
              />
            </template>
          </q-field>

          <q-field
            borderless
            class="outer-label"
            color="bhoomi"            
            label="State"
            :model-value="billing.state"
          >
            <template v-slot:control>
              <q-select
                color="bhoomi"
                style="width: 100%"
                outlined
                v-model="billing.state"
                :options="stateOptions"
                :rules="[
                  (val) => !!val || '* Required'
                ]"
              />
            </template>
          </q-field>

          <q-field
            borderless
            class="outer-label"
            color="bhoomi"
            label="ZIP"
            :model-value="billing.zip"
          >
            <template v-slot:label>
              <span>Zip</span>
            </template>
            <template v-slot:control>
              <q-input
                outlined
                v-model="billing.zip"
                color="bhoomi"
                placeholder="ZIP"
                :rules="[
                  (val) => !!val || '* Required',
                  (val) =>
                    val.length <= 10 || 'Please use maximum 10 characters',
                ]"
              />
            </template>
          </q-field>
        </div>

        <div class="row wrapper">

          <q-field
            borderless
            class="outer-label"
            color="bhoomi"            
            label="Country"
            :model-value="billing.country"
          >
            <template v-slot:control>
              <q-select
                color="bhoomi"
                style="width: 100%"
                outlined
                v-model="billing.country"
                :options="countryOptions"
                :rules="[
                  (val) => !!val || '* Required'
                ]"
              />
            </template>
          </q-field>

          <q-field borderless class="blank-label-required"></q-field>
          <q-field borderless class="blank-label"></q-field>
          <q-field borderless class="blank-label"></q-field>

        </div>

        <q-card-actions class="form-actions">
          <q-btn 
            @click="cancelChanges" 
            class="text-bhoomi cancel-button" 
            style="background-color: #fff" 
            label="Cancel"
          />
          <q-btn 
            @click="submitForm" 
            class="bg-bhoomi save-button" 
            style="color: #fff" 
            label="Save" 
            :disable="disabledSubmit"
          />
        </q-card-actions>
      </q-card-section>
    </q-card>
  </q-expansion-item> -->
</template>

<script>
import { inject, ref, watch, reactive, onMounted } from "vue";
import stateOptions from '@/sharedData/statesList.js'
import countryOptions from '@/sharedData/countriesList.js'
import { useStore } from "vuex";
import DropDownList from "@/components/Shared/DropDownList.vue";

export default {
    name: "ProfileBillingForm",
    props: {
        userType: String,
    },
    setup() {
        const store = useStore();
        const { billing, sameAddress, changeBillingField } = inject('billing');
        const defaultBilling = { ...billing };
        // const { confirmPayment } = inject('stripeProps');
        const sectionHeader = 'Billing Address';
        const activated = reactive({
            accountName: false,
            firstName: false,
            lastName: false,
            address: false,
            address2: false,
            city: false,
            state: false,
            zip: false,
            country: false
        });
        const focused = reactive({
            accountName: false,
            firstName: false,
            lastName: false,
            address: false,
            address2: false,
            city: false,
            state: false,
            zip: false,
            country: false
        });
        const disabledSubmit = ref(true);
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const toggleSection = () => {
            sectionExpanded.value = !sectionExpanded.value;
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });
        watch(billing, () => {
            disabledSubmit.value = !billing.country || !billing.zip || !billing.state ||
                !billing.city || !billing.address ||
                !billing.lastName || !billing.firstName || !billing.accountName;
            if (billing.expanded) {
                store.dispatch('addCurrentPath', {
                    section: 1,
                    val: sectionHeader
                });
            }
        });
        const cancelChanges = () => {
            for (let prop in defaultBilling) {
                billing[prop] = defaultBilling[prop];
            }
            changeBillingField('represent', 'mode');
        };
        const submitForm = () => {
            if (!billing.country || !billing.zip ||
                !billing.state || !billing.city ||
                !billing.address || !billing.lastName ||
                !billing.firstName || !billing.accountName) {
                if (!billing.country)
                    activated.country = true;
                if (!billing.zip)
                    activated.zip = true;
                if (!billing.state)
                    activated.state = true;
                if (!billing.city)
                    activated.city = true;
                if (!billing.address)
                    activated.address = true;
                if (!billing.lastName)
                    activated.lastName = true;
                if (!billing.firstName)
                    activated.firstName = true;
                if (!billing.accountName)
                    activated.accountName = true;
                return;
            }
            store.dispatch('updateTenant', {
                data: {
                    ...billing,
                },
                primaryUserId: store.state.primaryUser.id,
                section: 'billing'
            });
            // if (!store.state.primaryUser.billing) {
            // confirmPayment();
            // }
            const currentPayments = [...store.state.authenticated.paymentMethods].reverse();
            changeBillingField(true, 'submitted');
            store.dispatch('savePrimaryUser', { section: 'billing', data: {
                    ...billing,
                    paymentMethodId: currentPayments[currentPayments.length - 1].id
                } });
            // changeBillingField('represent', 'mode');
            setTimeout(() => {
                changeBillingField('represent', 'mode');
            }, 10);
        };
        onMounted(() => {
            sectionHeight.value = section.value.offsetHeight;
            section.value.style.height = `${section.value.offsetHeight}px`;
        });
        return {
            sectionHeader,
            stateOptions,
            countryOptions,
            billing,
            sameAddress,
            changeBillingField,
            disabledSubmit,
            cancelChanges,
            submitForm,
            activated,
            focused,
            section,
            boxHeader,
            toggleSection
        };
    },
    components: { DropDownList }
};
</script>

<style lang="scss" src="@/styles/pages/UserProfile/ProfileBillingForm.scss"></style>
