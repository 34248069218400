<template>
  <section class="section" ref="section">
<div class="border-box">
<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">{{ vendor.vendor || 'Vendor Form' }}</h5>
<div class="icon-group">
<!-- <div class="icon green icon-o tooltip"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
  data-tooltip="Camera"
>
  <img src="@/assets/images/updated/camera-white.png" alt="">
</div> -->
</div>
</div>

<!-- <div class="content-block">
<div class="checkbox-group">
<p class="fw-semibold gray">Select Categories:</p>
<div class="checkbox"><input type="checkbox" name="productServices" id="product" v-model="productMode"><label for="product">Product</label></div>
<div class="checkbox"><input type="checkbox" name="productServices" id="services" v-model="servicesMode"><label for="services">Services</label></div>
</div>
</div> -->

<div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3 product-services-field" v-if="((vendors?.length > 0) && !manualVendorInput)">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendor || (vendor.vendor.length > 0)}">Vendor</p>
  <!-- <select class="form-control"
    v-model="vendor.vendor"
    @click="() => {
      activated.vendor = true;
      focused.vendor = true;
    }"
    @change="() => {
      focused.vendor = false;
    }">
    <option
      v-for="vendorEntity in vendors"
      v-bind:key="vendorEntity.id + 'vendor-option-vendor-form'"
    >{{ vendorEntity.vendor }}</option>
  </select> -->

  <DropDownList
    :mainObject="'vendor'"
    :mainProperty="'vendor'"
    :injectionName="'vendorDetailsTwo'"
    :optionKey="'vendor-option-vendor-form'"
    :optionsList="[]"
    :optionProperty="'vendor'"
    :optionPropertyTwo="null"
    :defaultSelected="vendor.vendor"
    @click="() => {
      activated.vendor = true;
    }"
  />

<p v-if="activated.vendor && vendor.vendor.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.vendor && vendor.vendor.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3 product-services-field" v-else>
<div class="form-item">
<p class="form-label" :class="{active: focused.vendor || (vendor.vendor.length > 0)}">Vendor</p>

<div class="dropdown-open-back-container">
  <input type="text" class="form-control"
    v-model="vendor.vendor"
    ref="vendorVendorField"
    @focus="() => {
      activated.vendor = true;
      focused.vendor = true;
    }"
    @blur="() => {
      focused.vendor = false;
    }"
    placeholder="E.g., Minnesota Equipment"
    >
  <div class="dropdown-open-back" @click="() => {
    manualVendorInput = false
  }"></div>
</div>
<p v-if="activated.vendor && vendor.vendor.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.vendor && vendor.vendor.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3 product-services-field">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorContact || (vendor.vendorContact.length > 0)}">Vendor Contact</p>
<input type="text" class="form-control"
  v-model="vendor.vendorContact"
  @focus="() => {
    activated.vendorContact = true;
    focused.vendorContact = true;
  }"
  @blur="() => {
    focused.vendorContact = false;
  }"
  placeholder="E.g., Henry Roberts"
  >
<p v-if="activated.vendorContact && vendor.vendorContact.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.vendorContact && vendor.vendorContact.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3 product-services-field">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorPhone || (vendor.vendorPhone.length > 0)}">Vendor Phone</p>
<input type="text" class="form-control" v-model="vendor.vendorPhone"
  pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
  @focus="() => {
    activated.vendorPhone = true;
    focused.vendorPhone = true;
  }"
  @blur="() => {
    focused.vendorPhone = false;
    phoneNumberFormat('vendorPhone')
  }"
  placeholder="E.g., (547) 896-5632"
  >
<p v-if="activated.vendorPhone && vendor.vendorPhone.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.vendorPhone && !focused.vendorPhone && ((((vendor.vendorPhone.length !== 10) && (vendor.vendorPhone.length > 0)) || (vendor.vendorPhone.length > 10)) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(vendor.vendorPhone))" class="form-info red text-end">Please use 10 digits for phone</p>
</div>
</div>

<div class="col-3 product-services-field">
<div class="form-item">
<p class="form-label" :class="{active: focused.email || (vendor.email.length > 0)}">Email</p>
<input type="text" class="form-control" v-model="vendor.email"
  @focus="() => {
    activated.email = true;
    focused.email = true;
  }"
  @blur="() => {
    focused.email = false;
  }"
  placeholder="E.g., henry.r@me.com"
  >
<!-- <p v-if="activated.email && vendor.email.length === 0" class="form-info red text-end">* Required</p> -->
<p v-if="activated.email && !focused.email && ((vendor.email.length > 0) && !/\S+@\S+\.\S+/.test(vendor.email))" class="form-info red text-end">Please use correct email format</p>
</div>
</div>

<div class="col-3 product-services-field">
<div class="form-item">
<p class="form-label" :class="{active: focused.address || (vendor.address.length > 0)}">Address 1</p>
<input type="text" class="form-control" v-model="vendor.address"
  @focus="() => {
    activated.address = true;
    focused.address = true;
  }"
  @blur="() => {
    focused.address = false;
  }"
  placeholder="E.g., 7447 Egan Dr"
  >
<p v-if="activated.address && vendor.address.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.address && vendor.address.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3 product-services-field">
<div class="form-item">
<p class="form-label" :class="{active: focused.address2 || (vendor.address2.length > 0)}">Address 2</p>
<input type="text" class="form-control" v-model="vendor.address2"
  @focus="() => {
    activated.address2 = true;
    focused.address2 = true;
  }"
  @blur="() => {
    focused.address2 = false;
  }"
  placeholder="E.g., Suite 110A"
  >
<!-- <p v-if="activated.address2 && vendor.address2.length === 0" class="form-info red text-end">* Required</p> -->
<p v-if="activated.address2 && vendor.address2.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3 product-services-field">
<div class="form-item">
<p class="form-label" :class="{active: focused.city || (vendor.city.length > 0)}">City</p>
<input type="text" class="form-control" v-model="vendor.city"
  @focus="() => {
    activated.city = true;
    focused.city = true;
  }"
  @blur="() => {
    focused.city = false;
  }"
  placeholder="E.g., Savage"
  >
<p v-if="activated.city && vendor.city.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.city && vendor.city.length > 30" class="form-info red text-end">Please use maximum 30 characters</p>
</div>
</div>

<div class="col-3 product-services-field">
<div class="form-item">
<p class="form-label" :class="{active: focused.state || (vendor.state.length > 0)}">State</p>
  <!-- <select class="form-control" 
    v-model="vendor.state"
    @click="() => {
      activated.state = true;
      focused.state = true;
    }"
    @change="() => {
      focused.state = false;
    }"
  >
    <option v-for="stateOption in stateOptions"
      v-bind:key="stateOption + 'state-option-vendor-form'"
    >{{ stateOption }}</option>
  </select> -->
  <DropDownList
    :mainObject="'vendor'"
    :mainProperty="'state'"
    :injectionName="'vendorDetails'"
    :optionKey="'state-option-vendor-form'"
    :optionsList="stateOptions"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="vendor.state"
    @click="() => {
      activated.state = true;
    }"
  />
  <p v-if="activated.state && vendor.state.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3 product-services-field">
<div class="form-item">
<p class="form-label" :class="{active: focused.zip || (vendor.zip.length > 0)}">Zip</p>
<input type="text" class="form-control"  v-model="vendor.zip"
  @focus="() => {
    activated.zip = true;
    focused.zip = true;
  }"
  @blur="() => {
    focused.zip = false;
  }"
  placeholder="E.g., 55378"
  >
<p v-if="activated.zip && vendor.zip.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.zip && vendor.zip.length > 12" class="form-info red text-end">Please use maximum 12 characters</p>
</div>
</div>

<div class="col-3 product-services-field">
<div class="form-item">
<p class="form-label" :class="{active: focused.country || (vendor.country.length > 0)}">Country</p>
  <!-- <select class="form-control" 
    v-model="vendor.country"
    @click="() => {
      activated.country = true;
      focused.country = true;
    }"
    @change="() => {
      focused.country = false;
    }"
  >
    <option
      v-for="countryOption in countryOptions"
      v-bind:key="countryOption + 'country-option-vendor'"
    >{{ countryOption }}</option>
  </select> -->
  
  <DropDownList
    :mainObject="'vendor'"
    :mainProperty="'country'"
    :injectionName="'vendorDetails'"
    :optionKey="'country-option-vendor-form'"
    :optionsList="countryOptions"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="vendor.country"
    @click="() => {
      activated.country = true;
    }"
  />
<p v-if="activated.country && vendor.country.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.country && vendor.country.length > 30" class="form-info red text-end">Please use maximum 30 characters</p>
</div>
</div>

<div class="col-3 product-services-field">
<div class="form-item">
<p class="form-label" :class="{active: focused.status || (vendor.status.length > 0)}">Status</p>
<!-- <select class="form-control" 
  v-model="vendor.status"
  @click="() => {
    activated.status = true;
    focused.status = true;
  }"
  @change="() => {
    focused.status = false;
  }">
<option>Active</option>
<option>Inactive</option>
</select> -->
  <DropDownList
    :mainObject="'vendor'"
    :mainProperty="'status'"
    :injectionName="'vendorDetails'"
    :optionKey="'status-option-vendor-form'"
    :optionsList="['Active', 'Inactive']"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="vendor.status"
    @click="() => {
      activated.status = true;
    }"
  />
  <p v-if="activated.status && vendor.status.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-12 product-services-field">
<div class="form-item">
<p class="form-label" :class="{active: focused.comments || (vendor.comments.length > 0)}">Comments</p>
<textarea class="form-control" v-model="vendor.comments"
  @focus="() => {
    activated.comments = true;
    focused.comments = true;
  }"
  @blur="() => {
    focused.comments = false;
  }"
  placeholder="E.g., Added a new Vendor"
  ></textarea>
<p v-if="activated.comments && vendor.comments.length > 150" class="form-info red text-end">Please use maximum 150 characters</p>
</div>
</div>

<!-- <VendorProductsServices v-bind:formMode="'edit'" /> -->

</div>

</div>
</div>
</div>

<!-- </div> -->

<div class="content-block">

  <FileAttachments :key="`vendor-form-${vendor.id}`" v-bind:section="'vendor'" v-bind:instance="`${vendor.id}`" />
<!--  v-if="isAuth('vendor-attachements')" -->
<div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
</div>
</div>
</div>

</div>

</div>
</div>
</section><!--//End Section-->

<div class="removal-popup" v-if="popUps.addProductsServices.active">

<div class="modal modal-md">
<span class="modal-close" @click="() => confirmAddProductsServices(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">{{ popUps.addProductsServices.message }}</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => confirmAddProductsServices(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => confirmAddProductsServices(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>

<div class="removal-popup" v-if="popUps.addNewVendor.active">

<div class="modal modal-md">
<span class="modal-close" @click="() => confirmAddNewVendor(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">{{ popUps.addNewVendor.message }}</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => confirmAddNewVendor(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => confirmAddNewVendor(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>

<div v-if="missedField">
  <UserNotification
    v-bind:messageText="'Please add the required data'"
  />
</div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { reactive, inject, ref, onMounted, watch, provide } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import countryOptions from '@/sharedData/countriesList.js'
import stateOptions from '@/sharedData/statesList.js'
// import VendorProductsServices from './VendorProductsServices.vue';
import { useRouter } from 'vue-router';
import DropDownList from '@/components/Shared/DropDownList.vue';
import UserNotification from "@/components/Shared/UserNotification.vue";

export default {
    name: "VendorForm",
    setup() {
      const store = useStore();
      const router = useRouter();

      const activated = reactive({
        vendor: false,
        vendorContact: false,
        vendorPhone: false,
        email: false,
        address: false,
        address2: false,
        city: false,
        state: false,
        zip: false,
        country: false,
        status: false,
      });

      const focused = reactive({
        vendor: false,
        vendorContact: false,
        vendorPhone: false,
        email: false,
        address: false,
        address2: false,
        city: false,
        state: false,
        zip: false,
        country: false,
        status: false,
      });

      const section = ref(null);

      const popUps = reactive({
        addProductsServices: {
          active: false,
          message: 'Do you want to add products & Services?'
        },
        addNewVendor: {
          active: false,
          message: 'Do you want to add another vendor?'
        }
      });

      const otherSelected = ref(false);

      const { isAuth } = inject('mainUI');
      const { vendor, editionMode, selectedVendor, 
        selectVendor, defaultVendor, defaultVendorItem, 
        selectVendorItem, isInputDialog, isEquipmentDialog,
        navigateToItemForm, vendorFormSubmitted, vendorItem } = inject("vendorDetails");

      let addTaskDetails;

      if (isInputDialog?.value || isEquipmentDialog?.value) {
        addTaskDetails = inject('addTaskDetails');
      }

      const newId = ref(null);

      const helperTooltip = ref(false);

      const vendors = ref([...[...store.state.vendors].filter(v => v.status === 'Active'), {vendor: 'Add New'}]);

      const manualVendorInput = ref(false);

      const vendorVendorField = ref(null);

      const missedField = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const confirmAddProductsServices = (confirmed) => {
        if (confirmed) {
          // productsServicesMode.value = 'create';
          selectVendor({...vendor}, 'represent');
          selectVendorItem({
            ...defaultVendorItem,
            vendor: vendor.id
          }, 'create');
        } else {
          popUps.addNewVendor.active = true;
        }
        popUps.addProductsServices.active = false;
      }

      const confirmAddNewVendor = (confirmed) => {
        const formData = {
          ...vendor
        };

        if (confirmed) {
          newId.value = uuid();
          closeForm();
          editionMode.value = 'create';
          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: section?.value?.offsetTop - 100,
              left: 0,
              behavior: 'smooth'
            })
          })
        } else {
          if (router.currentRoute.value.params.source === 'equipment') {
            router.push({ 
              name: 'equipment', 
              params: { 
                pagename: 'equipment',
                source: 'vendor',
                otherVendor: router.currentRoute.value.params.other ? formData.vendor : null
              } 
            });
          }
          selectVendor(vendor, 'represent');
        }
        popUps.addNewVendor.active = false;
      }

      const phoneNumberFormat = (phoneType) => {
        if (vendor[phoneType].length > 0) {
          if (vendor[phoneType][0] !== "(") {
              const arrStr = vendor[phoneType].split("");
              vendor[phoneType] = `(${arrStr.filter((num, indx) => {
                  if (indx < 3)
                      return num;
              }).join("")}) ${arrStr.filter((num, indx) => {
                  if ((indx >= 3) && (indx < 6))
                      return num;
              }).join("")}-${arrStr.filter((num, indx) => {
                  if (indx >= 6)
                      return num;
              }).join("")}`;
          }
        }
      };

      const submitForm = () => {

        if (!vendor.vendor || !vendor.vendorContact || !vendor.vendorPhone ||
        !vendor.address || !vendor.city || !vendor.state || !vendor.zip ||
        !vendor.country || !vendor.status ||
        ((((vendor.vendorPhone.length !== 10) && (vendor.vendorPhone.length > 0)) || (vendor.vendorPhone.length > 10)) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(vendor.vendorPhone))
        ) {
        //   || (!!productMode.value && (!vendor.brand || !vendor.product)) || 
        // (!!servicesMode.value && (!vendor.serviceType || !vendor.cellPhone))
          if (!vendor.vendor) activated.vendor = true; //null for numbers
          if (!vendor.vendorContact) activated.vendorContact = true;
          if (!vendor.vendorPhone) activated.vendorPhone = true;
          // if (!vendor.email) activated.email = true;
          if (!vendor.address) activated.address = true;
          // if (!vendor.address2) activated.address2 = true;
          if (!vendor.city) activated.city = true;
          if (!vendor.state) activated.state = true;
          if (!vendor.zip) activated.zip = true;
          if (!vendor.country) activated.country = true;
          if (!vendor.status) activated.status = true;
          // if (!vendor.brand) activated.brand = true;
          // if (!vendor.product) activated.product = true;
          // if (!vendor.serviceType) activated.serviceType = true;
          // if (!vendor.cellPhone) activated.cellPhone = true;

          missedField.value = true;

          setTimeout(() => {
            missedField.value = false;
          }, 5100);

          return;
        }

        if (!vendor.id) {
          // vendor.id = uuid();
          vendor.id = newId.value;
        }

        const formData = {
          ...vendor
        };

        if (editionMode.value === "edit") {
          store.dispatch("editVendor", { ...formData })
        } else {
          delete formData._id;
          if (!isEquipmentDialog.value && !isInputDialog.value) {
            store.dispatch("createVendor", {...formData});
          } else {
            const alreadyCreated = vendors.value.find(stateVendor => stateVendor.vendor === formData.vendor);
            if (!alreadyCreated) {
              store.dispatch("createVendor", {...formData});
            }
          }
        }
        console.log(formData);
        /*
        (editionMode.value === "create") && closeForm();
        if (router.currentRoute.value.params.source === 'equipment') {
          router.push({ 
            name: 'equipment', 
            params: { 
              pagename: 'equipment',
              source: 'vendor',
              otherVendor: router.currentRoute.value.params.other ? formData.vendor : null
            } 
          });
        }
        (editionMode.value === "edit") && selectVendor(vendor, 'represent');
        */
        if (editionMode.value === "create") {
          if (isInputDialog.value || isEquipmentDialog.value) {
            selectVendor(vendor, 'represent');
            navigateToItemForm();
          } else {
            popUps.addProductsServices.active = true;
          }
        }
        if (editionMode.value === "edit") {
          selectVendor(vendor, 'represent')
        }
        vendorFormSubmitted.value = true;
      }

      const closeForm = () => {
        for (let prop in vendor) {
          vendor[prop] = defaultVendor[prop];
        }

        for (let prop in activated) {
          activated[prop] = false;
        }
        
        for (let prop in focused) {
          focused[prop] = false;
        }
      }

      const cancelChanges = () => {
        if (editionMode.value === "edit") {
          for (let prop in vendor) {
            vendor[prop] = selectedVendor[prop];
          }
          editionMode.value = "represent";
        } else {
          for (let prop in vendor) {
            vendor[prop] = defaultVendor[prop];
          }
          editionMode.value = "";
        }
        if (isInputDialog.value) {
          addTaskDetails.inputDialog.value = false
        }
        if (isEquipmentDialog.value) {
          addTaskDetails.equipmentDialog.value = false
        }
      };

      watch(() => (vendor.vendor), (newVal) => {
        if (isInputDialog.value || isEquipmentDialog.value) {
          if (newVal) {
            const selectedVendor = vendors.value.find(stateVendor => stateVendor.vendor === newVal);

            if (selectedVendor) {
              for (let prop in vendor) {
                vendor[prop] = selectedVendor[prop];
              }
            }

            // if (newVal === 'Add New') {
            //   manualVendorInput.value = true;
            //   otherSelected.value = true;
            //   vendor.vendor = '';
            //   for (let prop in vendor) {
            //     vendor[prop] = defaultVendor[prop];
            //   }
            //   setTimeout(() => {
            //     vendorVendorField.value.focus();
            //   }, 10)
            // }
          }
        }
        if (newVal === 'Add New') {
          manualVendorInput.value = true;
          otherSelected.value = true;
          vendor.vendor = '';
          for (let prop in vendor) {
            if (prop !== '_id' && prop !== 'id') {
              vendor[prop] = defaultVendor[prop];
            }
          }
          setTimeout(() => {
            vendorVendorField.value.focus();
          }, 10)
        }
        const choosenVendor = vendors.value.find(v => v.vendor === newVal);
        if (choosenVendor && !manualVendorInput.value) {
          const vendorItemType = vendorItem.type;
          selectVendor({...choosenVendor}, 'represent');
          setTimeout(() => {
            selectVendorItem({...defaultVendorItem, type: vendorItemType}, 'create');
          }, 100);
          vendorFormSubmitted.value = true;
          navigateToItemForm();
        }
      })

      watch(() => ([...store.state.vendors]), (newVal) => {
        vendors.value = [...[...newVal].filter(v => v.status === 'Active'), {vendor: 'Add New'}];
      })

      onMounted(() => {
        if (editionMode.value !== 'edit') {
          newId.value = uuid();
          vendor.id = newId.value;
        }
      });

      provide('otherHandling', {
        otherSelected
      });

      provide('vendorDetailsTwo', {
        vendor,
        options: vendors
      })

      return {
        activated,
        focused,
        vendor,
        countryOptions,
        stateOptions,
        helperTooltip,
        switchHelperTooltip,
        phoneNumberFormat,
        submitForm,
        cancelChanges,
        section,
        popUps,
        confirmAddProductsServices,
        confirmAddNewVendor,
        isInputDialog, 
        isEquipmentDialog,
        vendors,
        manualVendorInput,
        vendorVendorField,
        isAuth,
        missedField
      }
    },
    components: {
      FileAttachments,
      DropDownList,
      UserNotification
    }
}
</script>

<style lang="scss" scoped></style>