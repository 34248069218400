<template>
  <section class="section">

    <div class="border-box">
    <div class="border-box-content">

    <div class="content-block">
    <h5 class="fw-semibold">Admin Dashboard</h5>
    </div>

    <div class="content-block">

    <div class="content-row">
    <div class="content-row grid-4 grid-full">
    <div class="row admin-dashboard">
      <div class="col-3">
        <router-link :to="{ name: 'page', params: { pagename: 'user-profile', source: 'admin-users' } }">
          <div class="link-image account"></div>
          <span>Account</span>
        </router-link>
      </div>
      <div class="col-3">
        <router-link :to="{ name: 'page', params: { pagename: 'secondary-user', source: 'admin-module' } }">
          <div class="link-image add-user"></div>
          <span>Add User</span>
        </router-link>
      </div>
      <div class="col-3">
        <router-link :to="{ name: 'page', params: { pagename: 'farm-profile', source: 'admin-dashboard' } }">
          <div class="link-image add-farm"></div>
          <span>Add Farm</span>
        </router-link>
      </div>
      <div class="col-3">
        <router-link :to="{ name: 'admin', params: { pagename: 'admin-subscription', source: 'admin-module' } }">
          <div class="link-image subscription"></div>
          <span>Add Subscription</span>
        </router-link>
      </div>

      <div class="col-3">
        <router-link :to="{ name: 'admin', params: { pagename: 'admin-payment', source: 'admin-users' } }">
          <div class="link-image payment"></div>
          <span>Add Payment</span>
        </router-link>
      </div>

      <div class="col-3">
        <router-link :to="{ name: 'vendors', params: { pagename: 'vendor-module', source: 'admin-dashboard' } }">
          <div class="link-image vendors"></div>
          <span>Add Vendor</span>
        </router-link>
      </div>

      <div class="col-3">
        <router-link :to="{ name: 'customers', params: { pagename: 'customers-module', source: 'admin-dashboard' } }">
          <div class="link-image customers"></div>
          <span>Add Customer</span>
        </router-link>
      </div>

      <div class="col-3">
        <router-link :to="{ name: 'page', params: { pagename: 'search', source: 'admin-users' } }">
          <div class="link-image import-data"></div>
          <span>Import Data</span>
        </router-link>
      </div>

      <div class="col-3">
        <router-link :to="{ name: 'advertisement', params: { pagename: 'advertisement-account', source: 'admin-users' } }">
          <div class="link-image advertise"></div>
          <span>Advertise</span>
        </router-link>
      </div>

      </div>
      </div>
      </div>
      </div>
    <!-- <div class="q-pa-lg admin-settings" style="width: 100%">
      <router-link :to="{ name: 'page', params: { pagename: 'user-profile' } }">
        <div class="link-image user-profile"></div>
        <span>User Profile</span>
      </router-link>
      <router-link :to="{ name: 'page', params: { pagename: 'secondary-user' } }">
        <div class="link-image secondary-user"></div>
        <span>Secondary User Profile</span>
      </router-link>
      <router-link :to="{ name: 'farm', params: { pagename: 'farm-profile' } }">
        <div class="link-image farm-profile"></div>
        <span>Farm Profile</span>
      </router-link>

      <router-link :to="{ name: 'page', params: { pagename: 'vendor-module' } }">
        <div class="link-image vendor-module"></div>
        <span>Vendor</span>
      </router-link>

      <router-link :to="{ name: 'register' }">
        <div class="link-image farm-profile"></div>
        <span>Register</span>
      </router-link>

      <router-link :to="{ name: 'login' }">
        <div class="link-image farm-profile"></div>
        <span>Login</span>
      </router-link>

      <router-link :to="{ name: 'page', params: { pagename: 'search'} }">
        <div class="link-image farm-profile"></div>
        <span>Search</span>
      </router-link>

      <router-link :to="{ name: 'page', params: { pagename: 'super-admin'} }">
        <div class="link-image farm-profile"></div>
        <span>Super Admin</span>
      </router-link>
    </div> -->
    </div>
    </div>
  </section>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export default {
  name: "AdminModule",
  components: {},
  setup() {
    const store = useStore();
    const primaryIsSubmitted = ref(false);

    onMounted(() => {
      primaryIsSubmitted.value = store.state.primaryUser?.mainDetails?.submitted;
    })

    return {
      primaryIsSubmitted
    }
  },
};
</script>

<style lang="scss">
.admin-settings {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}
.admin-dashboard {
  .col-3 {
    text-align: center;
    a {
      width: 100%;
    }
    span {
      font-size: var(--fs-sm);
      // color: hsl(var(--white));
      font-family: var(--fw-semibold);
    }
  }
}
.link-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 6vw;
  // Admin_Panel_Icons
  &.account {
    // background-image: url('@/assets/images/dashboard/');
    background-image: url('@/assets/images/Admin_Panel_Icons/account.png');
  }
  &.add-user {
    background-image: url('@/assets/images/Admin_Panel_Icons/Add_User_Admin.png');
  }
  &.add-farm {
    background-image: url('@/assets/images/Admin_Panel_Icons/Add_Farm.png');
  }
  &.subscription {
    background-image: url('@/assets/images/Admin_Panel_Icons/subscribe.png');
  }
  &.payment {
    background-image: url('@/assets/images/Admin_Panel_Icons/Payment.png');
  }
  &.vendors {
    background-image: url('@/assets/images/Admin_Panel_Icons/Vendor.png');
  }
  &.customers {
    background-image: url('@/assets/images/Admin_Panel_Icons/Customer.png');
  }
  &.import-data {
    background-image: url('@/assets/images/Admin_Panel_Icons/Import_Data_2.png');
  }
  &.advertise {
    background-image: url('@/assets/images/Admin_Panel_Icons/Advertise.png');
  }
}
@media screen and (max-width: 991px) {
  .link-image {
    height: 12vw;
  }
}
</style>
