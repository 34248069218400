const options = [
  'Alluvial',
  'Black',
  'Chalk',
  'Clay',
  'Gravel',
  'Laterite',
  'Loam',
  'Loamy',
  'Peat',
  'Sand',
  'Sandy',
  'Silt',
  'Silty',
  'Other'
];

export default options;
