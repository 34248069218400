const userRoles = {
  admin: {
    role: {
      id: '0',
      key: 'admin',
      permissions: [
        {
          id: '0',
          key: 'all-access'
        },
        {
          id: '2',
          key: 'create-account'
        }
      ]
    }
  },
  edit: {
    role: {
      id: '0',
      key: 'edit',
      permissions: [
        {
          id: '3',
          key: 'edit-details'
        },
        {
          id: '2',
          key: 'add-user'
        },
        {
          id: '4',
          key: 'edit-subscription'
        },
        {
          id: '5',
          key: 'add-payment'
        },
        {
          id: '6',
          key: 'edit-billing'
        },
        {
          id: '7',
          key: 'add-crop'
        },
        {
          id: '8',
          key: 'add-bed'
        },
        {
          id: '9',
          key: 'edit-farm'
        },
        {
          id: '10',
          key: 'add-farm'
        },
        {
          id: '11',
          key: 'edit-bed'
        },
        {
          id: '12',
          key: 'add-crop'
        },
        {
          id: '13',
          key: 'edit-crop'
        },
        {
          id: '14',
          key: 'change-background'
        },
        {
          id: '15',
          key: 'equipment-module'
        },
        {
          id: '16',
          key: 'inventory-module'
        },
        {
          id: '17',
          key: 'reports-module'
        },
        {
          id: '18',
          key: 'farm-module'
        },
        {
          id: '19',
          key: 'crops-module'
        },
        {
          id: '20',
          key: 'labor-module'
        },
        {
          id: '21',
          key: 'sales-module'
        }
      ]
    }
  },
  contribute: {
    role: {
      id: '0',
      key: 'contribute',
      permissions: [

      ]
    }
  },
  read: {
    role: {
      id: '3',
      key: 'read',
      permissions: [
        
      ]
    }
  }
};

export default userRoles