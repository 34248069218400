<template>

<div class="section" ref="section">

<div class="border-box-header">
<h5>Subscription Type</h5>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

  <div class="content-block">
  <div class="payment-details" style="position: relative;">

<div class="content-row d-flex align-items-start">

<div class="checkbox mh">
  <input type="checkbox" id="freeThirtyDay" v-model="subscription.plans.trial"><label for="freeThirtyDay">Free 30 Day Trial</label>
</div>

<div class="four-column-grid four-column-grid-form text-left" style="max-width: 70%;margin-left: auto;">
<div class="subscription-type-form-dropdown span-3">
<p class="form-label">Billing Type</p>
<!-- <select class="form-control" v-model="subscription.subscriptionPeriod">
<option>Monthly</option>
<option>Yearly</option>
</select> -->
<DropDownList
  :mainObject="'subscription'"
  :mainProperty="'subscriptionPeriod'"
  :injectionName="'subscription'"
  :optionKey="'period-option-subscription-admin'"
  :optionsList="['Monthly', 'Yearly']"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="'Monthly'"
/>
<p class="fs-xs">10% Discount on Yearly Subscription</p>
</div>
</div> 

</div>

<div class="content-row overflow-auto-mobile"> <!-- overflow-auto -->
<div class="four-column-grid text-left align-end four-column-grid-form">

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basic" v-model="subscription.plans.basic" />
    <label for="basic">Basic</label>
  </div>
  <p class="form-info red text-end error" 
    style="text-align: left;position: absolute;"
    v-if="activated.basic && !subscription.plans.basic"
  >* Required</p>
</div>
<div><p class="form-label active">Price per month</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input class="form-control" v-model="subscription.plans.basicPrice" type="number" step="0.01" disabled />
  </div>
</div>
<div  style="position: relative;"><p class="form-label active">No. of Subscriptions</p>
  <input type="number" class="form-control" 
    v-model="subscription.plans.basicNumber" 
    :disabled="!subscription.plans.basic" 
    @focus="() => {
      activated.basicNumber = true;
      focused.basicNumber = true;
    }"
    @blur="() => {
      focused.basicNumber = false;
    }"
  >
  <div v-if="!subscription.plans.basic" 
    class="form-control"
    style="position: absolute; top: 0; opacity: 0;"
    @click="() => {
      activated.basic = true;
    }"
  ></div>
  <p v-if="subscription.plans.basic && (activated.basicNumber && (Number.parseInt(subscription.plans.basicNumber || 0) <= 0))" class="form-info red text-end error">* Required</p>
  <p v-if="subscription.plans.basicNumber && ((subscription.plans.basicNumber < 0) || !Number.isInteger(subscription.plans.basicNumber))" class="form-info red text-end error">Please insert whole positive number</p>
</div>
<div><p class="form-label active">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control" 
      :value="subscription.plans.basic ? (Number.parseInt(subscription.plans.basicNumber || 0) * Number.parseInt(subscription.plans.basicPrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0" 
    />
  </div>
</div>

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basicPlus" v-model="subscription.plans.plus" />
    <label for="basicPlus">Basic +</label>
  </div>
  <p class="form-info red text-end error" 
    style="text-align: left;position: absolute;"
    v-if="activated.plus && !subscription.plans.plus"
  >* Required</p>
</div>
<div>
  <div class="dollar-prefix">
    <span>$</span>
    <input class="form-control" prefix="$"
      v-model="subscription.plans.plusPrice"
      placeholder="Price per month"
      type="number"
      step="0.01"
      disabled />
  </div>
</div>
<div style="position: relative;">
  <input type="number" class="form-control" 
    v-model="subscription.plans.plusNumber" 
    @focus="() => {
      activated.plusNumber = true;
      focused.plusNumber = true;
    }"
    @blur="() => {
      focused.plusNumber = false;
    }"
    :disabled="!subscription.plans.plus">
  <div v-if="!subscription.plans.plus" 
    class="form-control"
    style="position: absolute; top: 0; opacity: 0;"
    @click="() => {
      activated.plus = true;
    }"
  ></div>
  <p v-if="subscription.plans.plus && (activated.plusNumber && (Number.parseInt(subscription.plans.plusNumber || 0) <= 0))" class="form-info red text-end error">* Required</p>
  <p v-if="subscription.plans.plusNumber && ((subscription.plans.plusNumber < 0) || !Number.isInteger(subscription.plans.plusNumber))" class="form-info red text-end error">Please insert whole positive number</p>
</div>
<div>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control" 
      :value="subscription.plans.plus ? (Number.parseInt(subscription.plans.plusNumber || 0) * Number.parseInt(subscription.plans.plusPrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0"
    />
  </div>
</div>

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basicPlusAdvance" v-model="subscription.plans.advance">
    <label for="basicPlusAdvance">Basic + Advance</label>
  </div>
  <p class="form-info red text-end error" 
    style="text-align: left;position: absolute;"
    v-if="activated.advance && !subscription.plans.advance"
  >* Required</p>
</div>
<div>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control" 
      prefix="$"
      v-model="subscription.plans.advancePrice"
      placeholder="Price per month"
      step="0.01"
      disabled />
  </div>
</div>
<div style="position: relative;">
  <input type="number" class="form-control" 
    v-model="subscription.plans.advanceNumber"
    @focus="() => {
      activated.advanceNumber = true;
      focused.advanceNumber = true;
    }"
    @blur="() => {
      focused.advanceNumber = false;
    }"
    :disabled="!subscription.plans.advance"
    >
    <div v-if="!subscription.plans.advance" 
      class="form-control"
      style="position: absolute; top: 0; opacity: 0;"
      @click="() => {
        activated.advance = true;
      }"
    ></div>
    <p v-if="subscription.plans.advance && (activated.advanceNumber && (Number.parseInt(subscription.plans.advanceNumber || 0) <= 0))" class="form-info red text-end error">* Required</p>
    <p v-if="subscription.plans.advanceNumber && ((subscription.plans.advanceNumber < 0) || !Number.isInteger(subscription.plans.advanceNumber))" class="form-info red text-end error">Please insert whole positive number</p>
</div>
<div>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control"
      :value="subscription.plans.advance ? (Number.parseInt(subscription.plans.advanceNumber || 0) * Number.parseInt(subscription.plans.advancePrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0"
    />
  </div>
</div>

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basicPlusUltimate" v-model="subscription.plans.ultimate">
    <label for="basicPlusUltimate">Basic + Ultimate</label>
  </div>
  <p class="form-info red text-end error" 
    style="text-align: left;position: absolute;"
    v-if="activated.ultimate && !subscription.plans.ultimate"
  >* Required</p>
</div>
<div>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      prefix="$"
      v-model="subscription.plans.ultimatePrice"
      step="0.01"
      disabled
    />
  </div>
</div>
<div style="position: relative;">
  <input type="number" class="form-control" 
    v-model="subscription.plans.ultimateNumber"
    @focus="() => {
      activated.ultimateNumber = true;
      focused.ultimateNumber = true;
    }"
    @blur="() => {
      focused.ultimateNumber = false;
    }"
    :disabled="!subscription.plans.ultimate"
  >
  <div v-if="!subscription.plans.ultimate" 
    class="form-control"
    style="position: absolute; top: 0; opacity: 0;"
    @click="() => {
      activated.ultimate = true;
    }"
  ></div>
  <p v-if="subscription.plans.ultimate && (activated.ultimateNumber && (Number.parseInt(subscription.plans.ultimateNumber || 0) <= 0))" class="form-info red text-end error">* Required</p>
  <p v-if="subscription.plans.ultimateNumber && ((subscription.plans.ultimateNumber < 0) || !Number.isInteger(Number(subscription.plans.ultimateNumber)))" class="form-info red text-end error">Please insert whole positive number</p>
</div>
<div>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control"
      :value="subscription.plans.ultimate ? (Number.parseInt(subscription.plans.ultimateNumber || 0) * Number.parseInt(subscription.plans.ultimatePrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0"
    />
  </div>
</div>

</div>  
</div>

<div class="content-row overflow-auto subscription-result-wrap">
<div class="four-column-grid four-column-grid-form text-left">
<div class="text-center">
<p class="form-label active">Subscription Amount</p>
<div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control" 
      prefix="$"
      v-model="subscription.totalSubscription"
      step="0.01"
      @focus="ev => {
        formatValue(ev, 'totalSubscription', true)
        activated.totalSubscription = true;
        focused.totalSubscription = true;
      }"
      @blur="ev => {
        formatValue(ev, 'totalSubscription', false)
        focused.totalSubscription = false;
      }"
    />
  </div>
  <!-- <p v-if="activated.totalSubscription && (Number.parseInt(subscription.totalSubscription || 0) === 0)" class="form-info red text-end error">* Required</p> -->
</div>
<div class="text-center">
<p class="form-label active">Discount</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      v-model="subscription.discount"
      placeholder="Discount"
      step="0.01"
      prefix="$"
      @focus="ev => {
        formatValue(ev, 'discount', true);
        activated.discount = true;
        focused.discount = true;
      }"
      @blur="ev => {
        formatValue(ev, 'discount', false);
        focused.discount = false;
      }"
    />
  </div>
  <!-- <p v-if="activated.discount && (Number.parseInt(subscription.discount || 0) > 10)" class="form-info red text-end error">Too high value</p> -->
</div>
<div class="text-center">
<p class="form-label active">Taxes</p>
<div class="info-icon-wrap">
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      v-model="subscription.taxes"
      placeholder="Taxes"
      step="0.01"
      prefix="$"
      @focus="ev => {
        formatValue(ev, 'taxes', true);
        activated.taxes = true;
        focused.taxes = true;
      }"
      @blur="ev => {
        formatValue(ev, 'taxes', false);
        focused.taxes = false;
      }"              
    />
  </div>
  <!-- <p v-if="activated.taxes && (Number.parseInt(subscription.taxes || 0) === 0)" class="form-info red text-end error">* Required</p> -->
  <!-- <p v-if="activated.taxes && (Number.parseInt(subscription.taxes || 0) > 10)" class="form-info red text-end error">Too high value</p> -->
  <div class="taxes-button">
    <span class="info-icon"  @mouseover="switchTooltip(true)" @mouseout="switchTooltip(false)">
      <!-- <img src="@/assets/images/question.svg" alt=""> -->
      <img src="@/assets/images/question-mark.png" alt="">
    </span>
  </div>
  </div>
</div>
<div class="text-center">
  <p class="form-label active">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      v-model="subscription.total"
      placeholder="Total"
      step="0.01"
      prefix="$"
      @focus="ev => {
        formatValue(ev, 'total', true);
        activated.total = true;
        focused.total = true;
      }"
      @blur="ev => {
        formatValue(ev, 'total', false);
        focused.total = false;
      }"
    />
  </div>
  <!-- <p v-if="activated.total && (Number.parseInt(subscription.total || 0) <= 0)" class="form-info red text-end error">* Required</p> -->
</div>
</div>  
</div>

<div class="content-row">
  <div class="four-column-grid four-column-grid-form text-left overflow-auto-mobile"> <!-- overflow-auto -->
    <div class="info-item  subscription-details-info"> <!-- justify-content-end -->
      <p>Subscription Details</p>
      <div>
        <span class="info-icon"
          @mouseover="switchSubscriptionTooltip(true)"
          @mouseout="switchSubscriptionTooltip(false)"
        >
          <!-- <img src="@/assets/images/question.svg" alt=""> -->
          <img src="@/assets/images/question-mark.png" alt="">
          <div class="tooltip" v-if="subscriptionTooltip">
            Basic: $25/Per Month <br/>
            (Includes User, Farm and Task Management) <br/>
            Basic +: $40/per month <br/>
            (Includes User, Farm, Task Management, Inventory, Equipment and Vendor) <br/>
            Basic + Advance: $55/per month <br/>
            (Includes User, Farm, Task Management, Inventory, Equipment, Vendor, Sales, and Customer) <br/>
            Basic + Ultimate: $65/per month <br/>
            (Includes User, Farm, Task Management, Inventory, Equipment, Vendor, Sales, Customer, and Reports) <br/>
          </div>
        </span>
      </div>
      </div>
      <div class="col-auto d-flex justify-content-end cancel-wrapper"><button type="submit" class="btn light-blue-20 btn-o"  @click="cancelChanges">Cancel</button></div>
      <div class="col-auto d-flex justify-content-end submit-wrapper"><button type="submit" class="btn green"  @click="submitForm">Next</button></div>
  </div>
</div>

<div class="taxes-tooltip" v-if="tooltipIsVisible">
  Tax: {{subscriptionRates.tax}} %
</div>

</div>
</div>

<!-- Old content block -->

<!--<div class="content-block">
  <div style="position: relative;">

 <div class="content-row">
<div class="checkbox"><input type="checkbox" id="freeThirtyDay" v-model="subscription.plans.trial"><label for="freeThirtyDay">Free 30 Day Trial</label></div>  
 </div>

<div class="content-row overflow-auto">
<div class="four-column-grid four-column-grid-form text-left">

<div class="subscription-type-form-dropdown span-3">
<p class="form-label">Billing Type</p>
<select class="form-control" v-model="subscription.subscriptionPeriod">
<option>Monthly</option>
<option>Yearly</option>
</select>
<p class="fs-xs">10% Discount on Yearly Subscription</p>
</div>

</div> 
</div>

<div class="content-row overflow-auto">
<div class="four-column-grid text-left align-end four-column-grid-form">

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basic" v-model="subscription.plans.basic" />
    <label for="basic">Basic</label>
  </div>
  <p class="form-info red text-end" 
    style="text-align: left;position: absolute;"
    v-if="activated.basic && !subscription.plans.basic"
  >* Required</p>
</div>
<div><p class="form-label active">Price per month</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input class="form-control" v-model="subscription.plans.basicPrice" type="number" step="0.01" disabled />
  </div>
</div>
<div style="position: relative;">
  <p class="form-label" :class="{active: focused.basicNumber || (subscription.plans.basicNumber > 0)}">No. of Subscriptions</p>
  <input type="number" class="form-control" 
    v-model="subscription.plans.basicNumber" 
    :disabled="!subscription.plans.basic" 
    @focus="() => {
      activated.basicNumber = true;
      focused.basicNumber = true;
    }"
    @blur="() => {
      focused.basicNumber = false;
    }"
  >
  <div v-if="!subscription.plans.basic" 
    class="form-control"
    style="position: absolute; top: calc(24px + 0.3rem); opacity: 0;"
    @click="() => {
      activated.basic = true;
    }"
  ></div>
  <p v-if="subscription.plans.basicNumber && ((subscription.plans.basicNumber < 0) || !Number.isInteger(subscription.plans.basicNumber))" class="form-info red text-end">Please insert whole positive number</p>
</div>
<div><p class="form-label">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control" 
      :value="subscription.plans.basic ? (Number.parseInt(subscription.plans.basicNumber || 0) * Number.parseInt(subscription.plans.basicPrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0" 
    />
  </div>
</div>

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basicPlus" v-model="subscription.plans.plus" />
    <label for="basicPlus">Basic +</label>
  </div>
  <p class="form-info red text-end" 
    style="text-align: left;position: absolute;"
    v-if="activated.plus && !subscription.plans.plus"
  >* Required</p>
</div>
<div><p class="form-label active">Price per month</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input class="form-control" prefix="$"
      v-model="subscription.plans.plusPrice"
      placeholder="Price per month"
      type="number"
      step="0.01"
      disabled />
  </div>
</div>
<div style="position: relative;">
  <p class="form-label" :class="{active: focused.plusNumber || (subscription.plans.plusNumber > 0)}">No. of Subscriptions</p>
  <input type="number" class="form-control" 
    v-model="subscription.plans.plusNumber" 
    @focus="() => {
      activated.plusNumber = true;
      focused.plusNumber = true;
    }"
    @blur="() => {
      focused.plusNumber = false;
    }"
    :disabled="!subscription.plans.plus">
  <div v-if="!subscription.plans.plus" 
    class="form-control"
    style="position: absolute; top: calc(24px + 0.3rem); opacity: 0;"
    @click="() => {
      activated.plus = true;
    }"
  ></div>
  <p v-if="subscription.plans.plusNumber && ((subscription.plans.plusNumber < 0) || !Number.isInteger(subscription.plans.plusNumber))" class="form-info red text-end">Please insert whole positive number</p>
</div>
<div><p class="form-label">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control" 
      :value="subscription.plans.plus ? (Number.parseInt(subscription.plans.plusNumber || 0) * Number.parseInt(subscription.plans.plusPrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0"
    />
  </div>
</div>

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basicPlusAdvance" v-model="subscription.plans.advance">
    <label for="basicPlusAdvance">Basic + Advance</label>
  </div>
  <p class="form-info red text-end" 
    style="text-align: left;position: absolute;"
    v-if="activated.advance && !subscription.plans.advance"
  >* Required</p>
</div>
<div>
  <p class="form-label active">Price per month</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control" 
      prefix="$"
      v-model="subscription.plans.advancePrice"
      placeholder="Price per month"
      step="0.01"
      disabled />
  </div>
</div>
<div style="position: relative;">
  <p class="form-label" :class="{active: focused.advanceNumber || (subscription.plans.advanceNumber > 0)}">No. of Subscriptions</p>
  <input type="number" class="form-control" 
    v-model="subscription.plans.advanceNumber"
    @focus="() => {
      activated.advanceNumber = true;
      focused.advanceNumber = true;
    }"
    @blur="() => {
      focused.advanceNumber = false;
    }"
    :disabled="!subscription.plans.advance"
    >
    <div v-if="!subscription.plans.advance" 
      class="form-control"
      style="position: absolute; top: calc(24px + 0.3rem); opacity: 0;"
      @click="() => {
        activated.advance = true;
      }"
    ></div>
    <p v-if="subscription.plans.advanceNumber && ((subscription.plans.advanceNumber < 0) || !Number.isInteger(subscription.plans.advanceNumber))" class="form-info red text-end">Please insert whole positive number</p>
</div>
<div><p class="form-label">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control"
      :value="subscription.plans.advance ? (Number.parseInt(subscription.plans.advanceNumber || 0) * Number.parseInt(subscription.plans.advancePrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0"
    />
  </div>
</div>

<div style="position: relative;">
  <div class="checkbox mh">
    <input type="checkbox" id="basicPlusUltimate" v-model="subscription.plans.ultimate">
    <label for="basicPlusUltimate">Basic + Ultimate</label>
  </div>
  <p class="form-info red text-end" 
    style="text-align: left;position: absolute;"
    v-if="activated.ultimate && !subscription.plans.ultimate"
  >* Required</p>
</div>
<div><p class="form-label active">Price per month</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      prefix="$"
      v-model="subscription.plans.ultimatePrice"
      step="0.01"
      disabled
    />
  </div>
</div>
<div style="position: relative;">
  <p class="form-label" :class="{active: focused.ultimateNumber || (subscription.plans.ultimateNumber > 0)}">No. of Subscriptions</p>
  <input type="number" class="form-control" 
    v-model="subscription.plans.ultimateNumber"
    @focus="() => {
      activated.ultimateNumber = true;
      focused.ultimateNumber = true;
    }"
    @blur="() => {
      focused.ultimateNumber = false;
    }"
    :disabled="!subscription.plans.ultimate"
  >
  <div v-if="!subscription.plans.ultimate" 
    class="form-control"
    style="position: absolute; top: calc(24px + 0.3rem); opacity: 0;"
    @click="() => {
      activated.ultimate = true;
    }"
  ></div>
  <p v-if="subscription.plans.ultimateNumber && ((subscription.plans.ultimateNumber < 0) || !Number.isInteger(Number(subscription.plans.ultimateNumber)))" class="form-info red text-end">Please insert whole positive number</p>
</div>
<div><p class="form-label">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control"
      :value="subscription.plans.ultimate ? (Number.parseInt(subscription.plans.ultimateNumber || 0) * Number.parseInt(subscription.plans.ultimatePrice) * (subscription.subscriptionPeriod === 'Monthly' ? 1 : 12)) : 0"
    />
  </div>
</div>

</div>  
</div>

<div class="content-row overflow-auto">
<div class="four-column-grid four-column-grid-form text-left">
<div class="text-center">
  <p class="form-label" :class="{active: focused.totalSubscription || (subscription.totalSubscription >= 0)}">Subscription Amount</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control" 
      prefix="$"
      v-model="subscription.totalSubscription"
      step="0.01"
      @focus="ev => {
        formatValue(ev, 'totalSubscription', true)
        activated.totalSubscription = true;
        focused.totalSubscription = true;
      }"
      @blur="ev => {
        formatValue(ev, 'totalSubscription', false)
        focused.totalSubscription = false;
      }"
    />
  </div>
  <p v-if="activated.totalSubscription && (Number.parseInt(subscription.totalSubscription || 0) === 0)" class="form-info red text-end">* Required</p>
</div>
<div class="text-center">
  <p class="form-label" :class="{active: focused.discount || (subscription.discount >= 0)}">Discount</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      v-model="subscription.discount"
      placeholder="Discount"
      step="0.01"
      prefix="$"
      @focus="ev => {
        formatValue(ev, 'discount', true);
        activated.discount = true;
        focused.discount = true;
      }"
      @blur="ev => {
        formatValue(ev, 'discount', false);
        focused.discount = false;
      }"
    />
  </div>
  <p v-if="activated.discount && (Number.parseInt(subscription.discount || 0) > 10)" class="form-info red text-end">Too high value</p>
</div>
<div class="text-center">
  <p class="form-label" :class="{active: focused.taxes || (subscription.taxes >= 0)}">Taxes</p>
  <div class="info-icon-wrap">
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      v-model="subscription.taxes"
      placeholder="Taxes"
      step="0.01"
      prefix="$"
      @focus="ev => {
        formatValue(ev, 'taxes', true);
        activated.taxes = true;
        focused.taxes = true;
      }"
      @blur="ev => {
        formatValue(ev, 'taxes', false);
        focused.taxes = false;
      }"              
    />
  </div>
  <p v-if="activated.taxes && (Number.parseInt(subscription.taxes || 0) === 0)" class="form-info red text-end">* Required</p>
  <p v-if="activated.taxes && (Number.parseInt(subscription.taxes || 0) > 10)" class="form-info red text-end">Too high value</p>
  <div class="taxes-button">
    <span class="info-icon"  @mouseover="switchTooltip(true)" @mouseout="switchTooltip(false)">
      <img src="@/assets/images/question.svg" alt="">
    </span>
  </div>
  </div>
</div>
<div class="text-center">
  <p class="form-label" :class="{active: focused.total || (subscription.total >= 0)}">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="number" class="form-control"
      v-model="subscription.total"
      placeholder="Total"
      step="0.01"
      prefix="$"
      @focus="ev => {
        formatValue(ev, 'total', true);
        activated.total = true;
        focused.total = true;
      }"
      @blur="ev => {
        formatValue(ev, 'total', false);
        focused.total = false;
      }"
    />
  </div>
  <p v-if="activated.total && (Number.parseInt(subscription.total || 0) <= 0)" class="form-info red text-end">* Required</p>
</div>
</div>  
</div>

<div class="content-row">
<div class="buttons">
<div class="row justify-content-end" style="flex-wrap: nowrap;">
  <div class="info-item justify-content-end subscription-details-info">
  <p>Subscription Details</p>
  <div>
    <span class="info-icon"
      @mouseover="switchSubscriptionTooltip(true)"
      @mouseout="switchSubscriptionTooltip(false)"
    >
      <img src="@/assets/images/question.svg" alt="">
      <div class="tooltip" v-if="subscriptionTooltip">
        Basic: $25/Per Month <br/>
        (Includes User, Farm and Task Management) <br/>
        Basic +: $40/per month <br/>
        (Includes User, Farm, Task Management, Inventory, Equipment and Vendor) <br/>
        Basic + Advance: $55/per month <br/>
        (Includes User, Farm, Task Management, Inventory, Equipment, Vendor, Sales, and Customer) <br/>
        Basic + Ultimate: $65/per month <br/>
        (Includes User, Farm, Task Management, Inventory, Equipment, Vendor, Sales, Customer, and Reports) <br/>
      </div>
    </span>
  </div>
  </div>
<div class="col-auto d-flex justify-content-end"><button type="submit" class="btn green"  @click="submitForm">Next</button></div>
</div>
</div>
</div>
<div class="taxes-tooltip" v-if="tooltipIsVisible">
  Tax: {{subscriptionRates.tax}} %
</div>

</div>
</div>-->

</div>
</div>

  <!-- <div class="section" ref="section">
  
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5>Subscription</h5>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>
  
  <div class="border-box-content">
  
  <div class="content-block">
  <div class="payment-details">

  <div>
    <div class="checkbox">
      <input type="checkbox" id="trialPlan" v-model="subscription.plans.trial">
      <label for="trialPlan">30 Days Trial</label>
    </div>
  </div>
  
  <div class="content-row subscription-grid-wrap">
  <div class="subscription-result-grid align-items-end">
  <div>
    <div class="checkbox">
      <input type="checkbox" id="basicPlan" v-model="subscription.plans.basic">
      <label for="basicPlan">Basic Plan:</label>
    </div>
  </div>
  <div>
    <p class="form-label text-center active">Price per month</p>
    <input class="form-control"  v-model="subscription.plans.basicPrice" type="number" step="0.01" disabled>
  </div>
  <div>
    <p class="form-label text-center active">No. of Subscriptions</p>
    <input type="number" class="form-control" 
      v-model="subscription.plans.basicNumber" 
      :disabled="!subscription.plans.basic" 
      @focus="() => {
        activated.basicNumber = true;
        focused.basicNumber = true;
      }"
      @blur="() => {
        focused.basicNumber = false;
      }"
    >
  </div>
  <div>
    <p class="form-label text-center active">Billing Type</p>
    <select class="form-control" v-model="subscription.subscriptionPeriod">
    <option>Monthly</option>
    <option>Yearly</option>
    </select>
  </div>
  </div>
  </div>
  
  <div class="content-row subscription-grid-wrap">
  <h6>Add Modules</h6>
  <div class="subscription-result-grid align-items-center">
  <div>
    <div class="checkbox">
      <input 
        type="checkbox" 
        id="plusManagement"  
        v-model="subscription.plans.plus" 
      >
      <label for="plusManagement">Basic +</label></div>
    </div>
  <div>
    <input 
      class="form-control"
      prefix="$"
      v-model="subscription.plans.plusPrice"
      placeholder="Price per month"
      type="number"
      step="0.01"
      disabled
    />
  </div>
  <div>
    <input 
      type="number" 
      class="form-control" 
      v-model="subscription.plans.plusNumber" 
      @focus="() => {
        activated.plusNumber = true;
        focused.plusNumber = true;
      }"
      @blur="() => {
        focused.plusNumber = false;
      }"
      :disabled="!subscription.plans.plus"
    />
  </div>
  </div>
  
  <div class="subscription-result-grid align-items-center">
  <div>
    <div class="checkbox">
      <input 
        type="checkbox" 
        id="advanceManagement" 
        v-model="subscription.plans.advance" 
      />
      <label for="advanceManagement">Basic + Advance</label>
    </div>
  </div>
  <div>
    <input
      type="number" 
      class="form-control" 
      prefix="$"
      v-model="subscription.plans.advancePrice"
      placeholder="Price per month"
      step="0.01"
      disabled
    />
  </div>
  <div>
    <input
      type="number" class="form-control" 
      v-model="subscription.plans.advanceNumber"
      @focus="() => {
        activated.advanceNumber = true;
        focused.advanceNumber = true;
      }"
      @blur="() => {
        focused.advanceNumber = false;
      }"
      :disabled="!subscription.plans.advance"
    />
  </div>
  </div>
  
  <div class="subscription-result-grid align-items-center">
  <div>
    <div class="checkbox">
      <input 
        type="checkbox" 
        id="ultimate" 
        v-model="subscription.plans.ultimate"
      />
      <label for="ultimate">Basic + Ultimate</label>
    </div>
  </div>
  <div>
    <input
      type="number" 
      class="form-control"
      prefix="$"
      v-model="subscription.plans.ultimatePrice"
      step="0.01"
      disabled
    />
  </div>
  <div>
    <input
      type="text" class="form-control" 
      v-model="subscription.plans.ultimateNumber"
      @focus="() => {
        activated.ultimateNumber = true;
        focused.ultimateNumber = true;
      }"
      @blur="() => {
        focused.ultimateNumber = false;
      }"
      :disabled="!subscription.plans.ultimate"
    />
  </div>
  </div>
  </div>
  
  <div class="content-row">
  <div class="row">
  <div class="col-3">
  <p class="form-label" :class="{active: focused.totalSubscription || (subscription.totalSubscription >= 0)}">Total Subscription</p>
    <div class="dollar-prefix">
      <span>$</span>
      <input
        type="number" class="form-control" 
        prefix="$"
        v-model="subscription.totalSubscription"
        step="0.01"
        @focus="ev => {
          formatValue(ev, 'totalSubscription', true)
          activated.totalSubscription = true;
          focused.totalSubscription = true;
        }"
        @blur="ev => {
          formatValue(ev, 'totalSubscription', false)
          focused.totalSubscription = false;
        }"
      />
    </div>
    <p v-if="activated.totalSubscription && (Number.parseInt(subscription.totalSubscription || 0) === 0)" class="form-info red text-end">* Required</p>
  </div>
  <div class="col-3">
  <p class="form-label" :class="{active: focused.taxes || (subscription.taxes >= 0)}">Taxes</p>
  <input 
    type="number" class="form-control"
    v-model="subscription.taxes"
    placeholder="Taxes"
    step="0.01"
    prefix="$"
    @focus="ev => {
      formatValue(ev, 'taxes', true);
      activated.taxes = true;
      focused.taxes = true;
    }"
    @blur="ev => {
      formatValue(ev, 'taxes', false);
      focused.taxes = false;
    }"
  />
  <p v-if="activated.taxes && (Number.parseInt(subscription.taxes || 0) === 0)" class="form-info red text-end">* Required</p>
  <p v-if="activated.taxes && (Number.parseInt(subscription.taxes || 0) > 10)" class="form-info red text-end">Too high value</p>

  </div>
  <div class="col-3">
  <p class="form-label" :class="{active: focused.discount || (subscription.discount >= 0)}">Discount (applied)</p>
  <input 
    type="number" class="form-control"
    v-model="subscription.discount"
    placeholder="Discount (applied)"
    step="0.01"
    prefix="$"
    @focus="ev => {
      formatValue(ev, 'discount', true);
      activated.discount = true;
      focused.discount = true;
    }"
    @blur="ev => {
      formatValue(ev, 'discount', false);
      focused.discount = false;
    }"
  />
  <p v-if="activated.discount && (Number.parseInt(subscription.discount || 0) > 10)" class="form-info red text-end">Too high value</p>
  </div>
  <div class="col-3">
  <p class="form-label" :class="{active: focused.total || (subscription.total >= 0)}">Total</p>
    <div class="dollar-prefix">
      <span>$</span>
      <input type="number" class="form-control"
        v-model="subscription.total"
        placeholder="Total"
        step="0.01"
        prefix="$"
        @focus="ev => {
          formatValue(ev, 'total', true);
          activated.total = true;
          focused.total = true;
        }"
        @blur="ev => {
          formatValue(ev, 'total', false);
          focused.total = false;
        }"
      />
    </div>
    <p v-if="activated.total && (Number.parseInt(subscription.total || 0) <= 0)" class="form-info red text-end">* Required</p>
  </div>
  </div>
  </div>

  <div class="content-row">
  <div class="buttons">
  <div class="row justify-content-end">
  <div class="col-auto d-flex justify-content-end"><button type="submit" class="btn green"  @click="submitForm">Next</button></div>
  </div>
  </div>
  </div>
  
  </div>
  </div>
  
  </div>
  
  </div> -->
  </template>
  
  <script>
  import { ref, watch, inject, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import subscriptionRates from '@/sharedData/subscriptionRates';
  import { useRouter } from "vue-router";
import DropDownList from "@/components/Shared/DropDownList.vue";
  
  export default {
    name: 'AdminSubscriptionForm',
    setup() {
        const store = useStore();
        const router = useRouter();
        const { stateSubscription: mainSubscription, changeSubscriptionField, defaultSubscription, subscriptionEditionMode } = inject('subscription');
        // subscription: mainSubscription
        const subscription = reactive({
            plans: {
                trial: false,
                trialPrice: 'price_1O5VljHpD3HYJIrgI5y2Jhkq',
                basic: false,
                basicPrice: '',
                basicNumber: '',
                basicPriceIdYearly: 'price_1NXMRQHpD3HYJIrg2IwC8sri',
                basicPriceIdMonthly: 'price_1NXMNxHpD3HYJIrg0hyDtIQO',
                basicAvailable: '',
                // inventory: false,
                // inventoryPrice: '',
                // inventoryNumber: '',
                plus: false,
                plusPrice: '',
                plusNumber: '',
                plusPriceIdYearly: 'price_1NXMQoHpD3HYJIrgJaWbqhOI',
                plusPriceIdMonthly: 'price_1NXMQoHpD3HYJIrgnhtzXnIF',
                plusAvailable: '',
                // equipment: false,
                // equipmentPrice: '',
                // equipmentNumber: '',
                advance: false,
                advancePrice: '',
                advanceNumber: '',
                advancePriceIdYearly: 'price_1NXMhHHpD3HYJIrgUwLCM3C2',
                advancePriceIdMonthly: 'price_1NXMhHHpD3HYJIrgrxWzr1UH',
                advanceAvailable: '',
                // allinclusive: false,
                // allinclusivePrice: '',
                // allinclusiveNumber: '',
                ultimate: false,
                ultimatePrice: '',
                ultimateNumber: '',
                ultimatePriceIdYearly: 'price_1NXMklHpD3HYJIrgUKaTXGU9',
                ultimatePriceIdMonthly: 'price_1NXMklHpD3HYJIrgbrsDHSiM',
                ultimateAvailable: '',
            },
            subscriptionPeriod: 'Monthly',
            totalSubscription: '',
            taxes: '',
            discount: '',
            total: '',
            planPurchasingDate: store.state.authenticated.registrationDate,
            subscriptionsNumber: 0, // remove later
        });
        const { mainDetails } = inject('mainDetails');
        const periodOptions = ['Monthly', 'Yearly'];
        const noPlanSubmit = ref(false);
        const disabledSubmit = ref(true);
        const tooltipIsVisible = ref(false);
        const sectionHeader = 'Subscription Type';
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const activated = reactive({
            basicNumber: false,
            plusNumber: false,
            advanceNumber: false,
            ultimateNumber: false,
            totalSubscription: false,
            discount: false,
            taxes: false,
            total: false,
            basic: false,
            plus: false,
            advance: false,
            ultimate: false
        });
        const focused = reactive({
            basicNumber: false,
            plusNumber: false,
            advanceNumber: false,
            ultimateNumber: false,
            totalSubscription: false,
            discount: false,
            taxes: false,
            total: false
        });
        // const formatPrice = (val, field) => {
        //   console.log(subscription.plans.basicPrice, `${val}.00`)
        //   changeSubscriptionField(`${val}.00`, field);
        // }
        const formatValue = (ev, field, active) => {
            const props = field.split('.'), val = active ? Number.parseFloat(ev.target.value) :
                `${ev.target.value}${`${ev.target.value}`.split('.')[1] ? '' : '.00'}`;
            if (subscription[props[1]]) {
                subscription[props[0]][props[1]] = val;
            }
            else {
                subscription[props[0]] = val;
            }
        };
        const toggleSection = () => {
            sectionExpanded.value = !sectionExpanded.value;
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(() => (mainSubscription.subscriptionPeriod), (newVal) => {
            subscription.subscriptionPeriod = newVal;
            subscription.discount = (newVal === 'Yearly') ? `${Math.abs(subscription.totalSubscription / 100) * 10}.00` : `${0}.00`;
            calculateTotal();
        });
        watch(subscription, () => {
            if (subscription.plans.basic || subscription.plans.plus ||
                subscription.plans.advance || subscription.plans.ultimate) {
                disabledSubmit.value = !(Number.parseFloat(subscription.total) > 0) ||
                    !(Number.parseFloat(subscription.totalSubscription) > 0) || noPlanSubmit.value;
                subscription.discount = (subscription.subscriptionPeriod === 'Yearly') ? `${Math.abs(subscription.totalSubscription / 100) * 10}.00` : `${0}.00`;
            }
            if (subscription.plans.trial) {
                disabledSubmit.value = !subscription.plans.trial;
            }
        });
        watch(() => ({ ...subscription.plans }), () => {
            /*
            for (let plan in subscription.plans) {
              if (newVal.trial && !oldVal.trial) {
                // if (plan.includes('Price')) subscription.plans[plan] = ''
                if (plan.includes('Number')) subscription.plans[plan] = ''//0
                if (!plan.includes('Number') && !plan.includes('Price') && plan !== 'trial') {
                  subscription.plans[plan] = false
                }
                calculateTotal(true);
              }
              if (newVal.basic && !oldVal.basic) {
                // if (plan.includes('Price') && !plan.includes('basic')) subscription.plans[plan] = ''
                if (plan.includes('Number') && !plan.includes('basic')) subscription.plans[plan] = ''//0
                if (!plan.includes('Number') && !plan.includes('Price') && !plan.includes('basic')) {
                  subscription.plans[plan] = false;
                  noPlanSubmit.value = false;
                }
              }
              if (newVal.plus && !oldVal.plus) {
                // if (plan.includes('Price') && !plan.includes('plus')) subscription.plans[plan] = ''
                if (plan.includes('Number') && !plan.includes('plus')) subscription.plans[plan] = ''//0
                if (!plan.includes('Number') && !plan.includes('Price') && !plan.includes('plus')) {
                  subscription.plans[plan] = false;
                  noPlanSubmit.value = false;
                }
              }
              if (newVal.advance && !oldVal.advance) {
                // if (plan.includes('Price') && !plan.includes('advance')) subscription.plans[plan] = ''
                if (plan.includes('Number') && !plan.includes('advance')) subscription.plans[plan] = ''//0
                if (!plan.includes('Number') && !plan.includes('Price') && !plan.includes('advance')) {
                  subscription.plans[plan] = false;
                  noPlanSubmit.value = false;
                }
              }
              if (newVal.ultimate && !oldVal.ultimate) {
                // if (plan.includes('Price') && !plan.includes('ultimate')) subscription.plans[plan] = ''
                if (plan.includes('Number') && !plan.includes('ultimate')) subscription.plans[plan] = ''//0
                if (!plan.includes('Number') && !plan.includes('Price') && !plan.includes('ultimate')) {
                  subscription.plans[plan] = false;
                  noPlanSubmit.value = false;
                }
              }
            }
            */
            subscription.totalSubscription = '0.00';
            subscription.total = '0.00';
            subscription.taxes = '0.00';
            subscription.discount = '0.00';
            calculateTotal();
        });
        watch(() => (subscription.taxes), (newVal) => {
            if (!newVal)
                subscription.taxes = `${0}.00`;
            calculateTotal();
        });
        watch(() => (subscription.discount), (newVal) => {
            if (!newVal)
                subscription.discount = `${0}.00`;
            calculateTotal();
        });
        const calculateTotal = (trial) => {
            const months = (subscription.subscriptionPeriod === 'Yearly') ? 12 : 1;
            const basicField = subscription.plans.basicPrice * subscription.plans.basicNumber;
            const plusField = subscription.plans.plusPrice * subscription.plans.plusNumber;
            const advanceField = subscription.plans.advancePrice * subscription.plans.advanceNumber;
            const ultimateField = subscription.plans.ultimatePrice * subscription.plans.ultimateNumber;
            const basicCalc = {
                totalSubscription: 0,
                total: 0,
                taxes: 0
            };
            const plusCalc = {
                totalSubscription: 0,
                total: 0,
                taxes: 0
            };
            const advanceCalc = {
                totalSubscription: 0,
                total: 0,
                taxes: 0
            };
            const ultimateCalc = {
                totalSubscription: 0,
                total: 0,
                taxes: 0
            };
            if (subscription.plans.trial && trial) {
                subscription.totalSubscription = `${0}.00`;
                subscription.total = `${0}.00`;
                subscription.taxes = `${0}.00`;
            }
            if (subscription.plans.basic &&
                (subscription.plans.basicPrice > 0) &&
                (subscription.plans.basicNumber > 0)) {
                // const optionsSum = plusField + basicField + advanceField;
                basicCalc.totalSubscription = Math.abs(basicField * months).toFixed(2);
                basicCalc.total = Math.abs(((basicField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * (100 +
                    Number.parseFloat(subscriptionRates.tax))).toFixed(2);
                basicCalc.taxes = Math.abs(((basicField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)).toFixed(2);
            }
            if (subscription.plans.plus &&
                subscription.plans.plusPrice &&
                subscription.plans.plusNumber) {
                plusCalc.totalSubscription = Math.abs(plusField * months).toFixed(2);
                plusCalc.total = Math.abs(((plusField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * (100 +
                    Number.parseFloat(subscriptionRates.tax))).toFixed(2);
                plusCalc.taxes = Math.abs(((plusField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)).toFixed(2);
            }
            if (subscription.plans.advance &&
                subscription.plans.advancePrice &&
                subscription.plans.advanceNumber) {
                advanceCalc.totalSubscription = Math.abs(advanceField * months).toFixed(2);
                advanceCalc.total = Math.abs(((advanceField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * (100 +
                    Number.parseFloat(subscriptionRates.tax))).toFixed(2);
                advanceCalc.taxes = Math.abs(((advanceField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)).toFixed(2);
            }
            if (subscription.plans.ultimate &&
                subscription.plans.ultimatePrice &&
                subscription.plans.ultimateNumber) {
                ultimateCalc.totalSubscription = Math.abs(ultimateField * months).toFixed(2);
                ultimateCalc.total = Math.abs(((ultimateField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * (100 +
                    Number.parseFloat(subscriptionRates.tax))).toFixed(2);
                ultimateCalc.taxes = Math.abs(((ultimateField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)).toFixed(2);
            }
            subscription.totalSubscription = Math.abs(Number.parseFloat(basicCalc.totalSubscription) +
                Number.parseFloat(plusCalc.totalSubscription) +
                Number.parseFloat(advanceCalc.totalSubscription) +
                Number.parseFloat(ultimateCalc.totalSubscription)).toFixed(2);
            // subscription.total = Math.abs(
            //   Number.parseFloat(basicCalc.total) + 
            //   Number.parseFloat(plusCalc.total) +
            //   Number.parseFloat(advanceCalc.total) + 
            //   Number.parseFloat(ultimateCalc.total)
            // ).toFixed(2);
            subscription.total = Math.abs((Number.parseFloat((subscription.totalSubscription) - Number.parseFloat(subscription.discount)) / 100) * Number.parseFloat(106.87)).toFixed(2);
            // subscription.taxes = Math.abs(
            //   Number.parseFloat(basicCalc.taxes) + 
            //   Number.parseFloat(plusCalc.taxes) +
            //   Number.parseFloat(advanceCalc.taxes) + 
            //   Number.parseFloat(ultimateCalc.taxes)
            // ).toFixed(2);
            subscription.taxes = Math.abs((Number.parseFloat((subscription.totalSubscription) - Number.parseFloat(subscription.discount)) / 100) * Number.parseFloat(6.87)).toFixed(2);
            subscription.plans.basicAvailable = subscription.plans.basicNumber;
            subscription.plans.plusAvailable = subscription.plans.plusNumber;
            subscription.plans.advanceAvailable = subscription.plans.advanceNumber;
            subscription.plans.ultimateAvailable = subscription.plans.ultimateNumber;
        };
        const switchTooltip = (toggle) => {
            tooltipIsVisible.value = toggle;
        };
        const subscriptionTooltip = ref(false);
        const switchSubscriptionTooltip = (toggle) => {
            subscriptionTooltip.value = toggle;
        };
        const cancelChanges = () => {
            for (let prop in defaultSubscription) {
                subscription[prop] = defaultSubscription[prop];
                for (let plan in subscription.plans) {
                    subscription.plans[plan] = defaultSubscription.plans[plan];
                }
            }
            subscription.plans.basicPrice = `${subscriptionRates.basic}`;
            subscription.plans.plusPrice = `${subscriptionRates.plus}`;
            subscription.plans.advancePrice = `${subscriptionRates.advance}`;
            subscription.plans.ultimatePrice = `${subscriptionRates.ultimate}`;
            if (router.currentRoute.value.params.source) {
                router.push({
                    name: 'admin',
                    params: {
                        pagename: router.currentRoute.value.params.source,
                        source: 'admin-subscription'
                    }
                });
            }
            else {
                subscriptionEditionMode.value = 'represent';
            }
        };
        const submitForm = () => {
            if (!subscription.plans.trial && !subscription.plans.basic
                && !subscription.plans.plus && !subscription.plans.advance &&
                !subscription.plans.ultimate) {
                noPlanSubmit.value = true;
                return;
            }
            if (
              // (subscription.plans.basic || subscription.plans.plus ||
              //   subscription.plans.advance || subscription.plans.ultimate) &&
              //   (!subscription.plans.basicNumber && !subscription.plans.plusNumber &&
              //       !subscription.plans.advanceNumber && !subscription.plans.ultimateNumber)
                  (subscription.plans.basic && (!subscription.plans.basicNumber || ((subscription.plans.basicNumber > 0) && !Number.isInteger(subscription.plans.basicNumber)))) ||
                  (subscription.plans.plus && (!subscription.plans.plusNumber  || ((subscription.plans.plusNumber > 0) && !Number.isInteger(subscription.plans.plusNumber)))) ||
                  (subscription.plans.advance && (!subscription.plans.advanceNumber || ((subscription.plans.advanceNumber > 0) && !Number.isInteger(subscription.plans.advanceNumber)))) ||
                  (subscription.plans.ultimate && (!subscription.plans.ultimateNumber || ((subscription.plans.ultimateNumber > 0) && !Number.isInteger(subscription.plans.ultimateNumber))))
                  ) {
                if (subscription.plans.basic && !subscription.plans.basicNumber)
                    activated.basicNumber = true;
                if (subscription.plans.plus && !subscription.plans.plusNumber)
                    activated.plusNumber = true;
                if (subscription.plans.advance && !subscription.plans.advanceNumber)
                    activated.advanceNumber = true;
                if (subscription.plans.ultimate && !subscription.plans.ultimateNumber)
                    activated.ultimateNumber = true;
                // subscription.total = '';
                // subscription.taxes = `${0}.00`;
                // subscription.totalSubscription = '';
                // activated.totalSubscription = true;
                // activated.total = true;
                // activated.taxes = true;
                return;
            }
            console.log(mainDetails.id);
            // let priceId,
            //   quantity;
            const items = [];
            const assignedPlan = {
                trial: false,
                basic: false,
                plus: false,
                advance: false,
                ultimate: false
            };
            if (subscription.plans.trial) {
                const trialItem = {
                    price: subscription.plans.trialPrice,
                    quantity: 1
                };
                items.push(trialItem);
                assignedPlan.trial = true;
            }
            if (subscription.plans.basic) {
                const basicItem = {
                    price: subscription.subscriptionPeriod === 'Yearly' ?
                        subscription.plans.basicPriceIdYearly : subscription.plans.basicPriceIdMonthly,
                    quantity: subscription.plans.basicNumber
                };
                items.push(basicItem);
                assignedPlan.basic = true;
            }
            if (subscription.plans.plus) {
                const plusItem = {
                    price: subscription.subscriptionPeriod === 'Yearly' ?
                        subscription.plans.plusPriceIdYearly : subscription.plans.plusPriceIdMonthly,
                    quantity: subscription.plans.plusNumber
                };
                items.push(plusItem);
                assignedPlan.plus = true;
            }
            if (subscription.plans.advance) {
                const advanceItem = {
                    price: subscription.subscriptionPeriod === 'Yearly' ?
                        subscription.plans.advancePriceIdYearly : subscription.plans.advancePriceIdMonthly,
                    quantity: subscription.plans.advanceNumber
                };
                items.push(advanceItem);
                assignedPlan.advance = true;
            }
            if (subscription.plans.ultimate) {
                const ultimateItem = {
                    price: subscription.subscriptionPeriod === 'Yearly' ?
                        subscription.plans.ultimatePriceIdYearly : subscription.plans.ultimatePriceIdMonthly,
                    quantity: subscription.plans.ultimateNumber
                };
                items.push(ultimateItem);
                assignedPlan.ultimate = true;
            }
            /* <- Combine Main Subscription with new data from form */
            subscription.totalSubscription = Math.abs(Number.parseFloat(subscription.totalSubscription) +
                Number.parseFloat(mainSubscription.totalSubscription || store.state?.primaryUser?.subscription?.totalSubscription || 0)).toFixed(2);
            subscription.total = Math.abs(Number.parseFloat(subscription.total) +
                Number.parseFloat(mainSubscription.total || store.state?.primaryUser?.subscription?.total || 0)).toFixed(2);
            subscription.taxes = Math.abs(Number.parseFloat(subscription.taxes) +
                Number.parseFloat(mainSubscription.taxes || store.state?.primaryUser?.subscription?.taxes || 0)).toFixed(2);

            if (mainSubscription.plans.basic ||
              store.state?.primaryUser?.subscription?.plans?.basic
            ) {
                subscription.plans.basic = mainSubscription.plans.basic || store.state?.primaryUser?.subscription?.plans?.basic;
                const currentBasicNumber = Number.parseInt(subscription.plans.basicNumber || 0);
                subscription.plans.basicNumber = Math.abs(currentBasicNumber +
                    Number.parseInt(mainSubscription.plans.basicNumber || store.state?.primaryUser?.subscription?.plans?.basicNumber || 0));
                subscription.plans.basicAvailable = Math.abs(currentBasicNumber +
                    Number.parseInt(mainSubscription.plans.basicAvailable || store.state?.primaryUser?.subscription?.plans?.basicAvailable || 0));
                console.log(subscription.plans.basicAvailable, 'subscription.plans.basicAvailable');
            }
            if (mainSubscription.plans.plus ||
              store.state?.primaryUser?.subscription?.plans?.plus
            ) {
                subscription.plans.plus = mainSubscription.plans.plus || store.state?.primaryUser?.subscription?.plans?.plus;
                const currentPlusNumber = Number.parseInt(subscription.plans.plusNumber || 0);
                subscription.plans.plusNumber = Math.abs(currentPlusNumber +
                    Number.parseInt(mainSubscription.plans.plusNumber || store.state?.primaryUser?.subscription?.plans?.plusNumber || 0));
                subscription.plans.plusAvailable = Math.abs(currentPlusNumber +
                    Number.parseInt(mainSubscription.plans.plusAvailable || store.state?.primaryUser?.subscription?.plans?.plusAvailable || 0));
            }
            if (mainSubscription.plans.advance ||
              store.state?.primaryUser?.subscription?.plans?.advance
            ) {
                subscription.plans.advance = mainSubscription.plans.advance || store.state?.primaryUser?.subscription?.plans?.advance;
                const currentAdvanceNumber = Number.parseInt(subscription.plans.advanceNumber || 0);
                subscription.plans.advanceNumber = Math.abs(currentAdvanceNumber +
                    Number.parseInt(mainSubscription.plans.advanceNumber || store.state?.primaryUser?.subscription?.plans?.advanceNumber || 0));
                subscription.plans.advanceAvailable = Math.abs(currentAdvanceNumber +
                    Number.parseInt(mainSubscription.plans.advanceAvailable || store.state?.primaryUser?.subscription?.plans?.advanceAvailable || 0));
            }
            if (mainSubscription.plans.ultimate ||
              store.state?.primaryUser?.subscription?.plans?.ultimate
            ) {
                subscription.plans.ultimate = mainSubscription.plans.ultimate || store.state?.primaryUser?.subscription?.plans?.ultimate;
                const currentUltimateNumber = Number.parseInt(subscription.plans.ultimateNumber || 0);
                subscription.plans.ultimateNumber = Math.abs(currentUltimateNumber +
                    Number.parseInt(mainSubscription.plans.ultimateNumber || store.state?.primaryUser?.subscription?.plans?.ultimateNumber || 0));
                subscription.plans.ultimateAvailable = Math.abs(currentUltimateNumber +
                    Number.parseInt(mainSubscription.plans.ultimateAvailable || store.state?.primaryUser?.subscription?.plans?.ultimateAvailable || 0));
            }
            /* Combine Main Subscription with new data from form -> */
            console.log('user change', {
                        ...subscription,
                        plans: {
                            ...subscription.plans
                        }
                    });
            // if (router.currentRoute.value.params.source !== 'secondary-user') {
              // router.currentRoute.value.params.source === 'admin-account'
                store.dispatch('savePrimaryUser', {
                    section: 'subscription',
                    data: {
                        ...subscription,
                        plans: {
                            ...subscription.plans,
                            trial: store.state.primaryUser.subscription.plans.trial
                        }
                    }
                });
            // }
            /*
            else {
              // router.currentRoute.value.params.source === 'secondary-user'
                store.dispatch('saveSecondaryUser', {
                    section: 'subscription',
                    data: {
                        ...subscription,
                        plans: {
                            ...subscription.plans,
                            trial: true
                        }
                    }
                });
            }
            */
            // else {
            //     store.dispatch('changeNewUser', {
            //         userId: mainDetails.id,
            //         section: 'subscription',
            //         data: {
            //             ...subscription,
            //             plans: {
            //                 ...subscription.plans
            //             }
            //         }
            //     });
            // }
            /*
              updating instead of create new subscription
              if trying to create new subscription get mongoerror
              because of multiply items accepted subscription can't be updated
              so only new subscription should be created
            */
            // store.state.subscriptionPlan.subscriptionId ?
            //   store.dispatch('updateSubscription', {
            //     data: {
            //       ...subscription,
            //       // priceId,
            //       // quantity,
            //       items,
            //       primaryUserId: store.state.primaryUserTenant.id
            //     }
            //   }) :
            // store.dispatch('createSubscription', {
              console.log('select subscription', {
                        ...subscription,
                        plans: {
                          ...subscription.plans
                        },
                    });
            store.dispatch('selectSubscription', {
                data: {
                    ...subscription,
                    plans: {
                      ...subscription.plans
                    },
                    // priceId,
                    // quantity,
                    items,
                    primaryUserId: store.state.primaryUserTenant.id
                }
            });
            // store.state.subscriptionPlan.subscriptionId ?
            //   console.log('updateSubscription') :
            //   console.log('createSubscription')
            if (!store.state.adminPaymentIsChanged) {
                store.dispatch('changeAdminPayment', { isChanged: true });
            }
            router.push({
                name: 'admin',
                params: {
                    pagename: 'admin-payment',
                    source: router.currentRoute.value.params.source || 'admin-subscription',
                    parentSource: router.currentRoute.value.params.parentSource
                }
            });
            // subscriptionEditionMode.value = 'represent'
        };
        onMounted(() => {
            sectionHeight.value = section.value.offsetHeight;
            section.value.style.height = `${section.value.offsetHeight}px`;
            // subscription.taxes = `${subscriptionRates.tax}`
            subscription.plans.basicPrice = `${subscriptionRates.basic}`;
            subscription.plans.plusPrice = `${subscriptionRates.plus}`;
            subscription.plans.advancePrice = `${subscriptionRates.advance}`;
            subscription.plans.ultimatePrice = `${subscriptionRates.ultimate}`;
            /*
            if (subscription.plans.basicNumber) subscription.plans.basicNumber = Number.parseInt(subscription.plans.basicNumber);
            if (subscription.plans.plusNumber) subscription.plans.plusNumber = Number.parseInt(subscription.plans.plusNumber);
            if (subscription.plans.advanceNumber) subscription.plans.advanceNumber = Number.parseInt(subscription.plans.advanceNumber);
            if (subscription.plans.ultimateNumber) subscription.plans.ultimateNumber = Number.parseInt(subscription.plans.ultimateNumber);
            */
            subscription.subscriptionPeriod = mainSubscription.subscriptionPeriod;
            /*
              if (subscription.plans.basicNumber) subscription.plans.basicNumber = Number.parseInt(defaultSubscription.plans.basicNumber);
              if (subscription.plans.plusNumber) subscription.plans.plusNumber = Number.parseInt(defaultSubscription.plans.plusNumber);
              if (subscription.plans.advanceNumber) subscription.plans.advanceNumber = Number.parseInt(defaultSubscription.plans.advanceNumber);
              if (subscription.plans.ultimateNumber) subscription.plans.ultimateNumber = Number.parseInt(defaultSubscription.plans.ultimateNumber);
    
              if (subscription.plans.basic) subscription.plans.basic = Number.parseInt(defaultSubscription.plans.basic);
              if (subscription.plans.plus) subscription.plans.plus = Number.parseInt(defaultSubscription.plans.plus);
              if (subscription.plans.advance) subscription.plans.advance = Number.parseInt(defaultSubscription.plans.advance);
              if (subscription.plans.ultimate) subscription.plans.ultimate = Number.parseInt(defaultSubscription.plans.ultimate);
            */
            // store.dispatch('addCurrentPath', {
            //   section: 1,
            //   val: sectionHeader      
            // });
        });
        return {
            sectionHeader,
            subscription,
            periodOptions,
            changeSubscriptionField,
            noPlanSubmit,
            disabledSubmit,
            subscriptionRates,
            tooltipIsVisible,
            switchTooltip,
            formatValue,
            // formatPrice,
            cancelChanges,
            submitForm,
            subscriptionTooltip,
            switchSubscriptionTooltip,
            activated,
            focused,
            section,
            boxHeader,
            toggleSection,
            subscriptionEditionMode,
            mainSubscription
        };
    },
    components: { DropDownList }
}
  </script>
  
<style lang="scss" scoped>

.info-icon-wrap .info-icon {
  transform: translateY(-150%);
}

.submit-wrapper {
  grid-column: 4/4;
}

.cancel-wrapper {
  grid-column: 3/4;
}

.four-column-grid.four-column-grid-form div {
  &.subscription-details-info {
    position: absolute;
  }
}

@media (min-width: 768px) {
  .subscription-details-info {
    grid-column: 1/4;
  }
}

@media (max-width: 875px) {
  .overflow-auto-mobile {
    overflow-x: auto;
  }
}

.info-item {
    .info-icon {
      position: relative;
    }
    .tooltip {
      position: absolute;
      z-index: 99999;
      bottom: 40px;
      left: -120px;
      width: 670px;
      display: flex;
      justify-content: center;
      align-items: center;
      // border-radius: 11px;
      // color: #fff;
      // background-color: #3C3838;
      font-family: 'Inter', sans-serif;
      // font-weight: 400;
      // padding: 5px;
      color: hsl(var(--white));
      // background: hsl(207, 11%, 41%);
      background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
      font-weight: var(--fw-semibold);
      padding: 0.5rem;
      font-size: 1.4rem;
      font-family: 'Gilroy';
      &::after {
        content: " ";
        position: absolute;
        display: block;
        bottom: -7px;
        // border-color: #3C3838;
        // border-left: 8px solid hsl(207, 11%, 41%);
        // border-bottom: 6px solid hsl(207, 11%, 41%);
        border-left: 8px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
        border-bottom: 6px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
        border-right: 6px solid transparent;
        border-top: 8px solid transparent;
        -webkit-transform: translateX(190px) rotate(135deg);
        transform: translateX(190px) rotate(135deg);
        box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
        left: -65px;
        // background: #3C3838;
        // background: hsl(207, 11%, 41%);
        background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
        z-index: -1;
      }
    }
    // position: relative;

    .helper-tooltip {
      width: max-content;
      padding: 0 15px;
      height: 30px;
      position: absolute;
      z-index: 999;
  
      background-color: #3C3838;
      color: #fff;
      top: 50px;
      left: -55px;
      text-indent: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 11px;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
  
      &::after {
        content: " ";
        position: absolute;
        display: block;
        top: -7px;
        border-color: #3C3838;
        border-left: 8px solid hsl(0, 3%, 23%);
        border-bottom: 6px solid #3C3838;
        border-right: 6px solid transparent;
        border-top: 8px solid transparent;
        -webkit-transform: translateX(-50%) rotate(135deg);
        transform: translateX(-50%) rotate(135deg);
        box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
        left: 66px;
        background: #3C3838;
        z-index: -1;
      }
    }
  }
.taxes-tooltip {
    position: absolute;
    width: max-content;
    height: max-content;
    // top: 40px;
    // left: -40px;
    right: 22rem;
    bottom: 5rem;
    z-index: 999;
    // padding: 15px;
    padding: 0.5rem;
    // background-color: #3C3838;
    // background: hsl(207, 11%, 41%);
    background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    // border: 1px solid #28292B;
    // border-radius: 11px;
    // font-size: 14px;
    font-size: 1.4rem;
    // font-weight: 500;
    font-weight: var(--fw-semibold);
    font-family: 'Gilroy';
    // color: #fff;
    color: hsl(var(--white));
    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: -7px;
      // border-color: #3C3838;
      // border-left: 8px solid hsl(207, 11%, 41%);
      // border-bottom: 6px solid hsl(207, 11%, 41%);
      border-left: 8px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
      border-bottom: 6px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 40px;
      // background: #3C3838;
      // background: hsl(207, 11%, 41%);
      background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
      z-index: -1;
    }
  }
</style>