<template>
    <section class="section">
<div class="border-box">
 <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Labor Expense</h5>
<div class="icon-group">
    <div class="icon green icon-o tooltip"
      @mouseover="switchHelperTooltip(true)"
      @mouseout="switchHelperTooltip(false)"
      data-tooltip="Camera"
    >
      <img src="@/assets/images/updated/camera-white.png" alt="">
    </div>
</div>
</div>

<div class="content-block d-lg-flex justify-content-between">
<div class="checkbox-group task-category">
<p class="fw-semibold gray checkbox-header">Select Categories:</p>
<div class="checkbox"><input type="checkbox" id="input" v-model="laborExpense.inputTask"><label for="input">Input</label></div>
<div class="checkbox"><input type="checkbox" id="harvest" v-model="laborExpense.harvestTask"><label for="harvest">Harvest</label></div>
<div class="checkbox"><input type="checkbox" id="other" v-model="laborExpense.otherTask"><label for="other">Other</label></div>
</div>
</div>

<div class="content-block d-lg-flex justify-content-between">
<div class="checkbox-group task-type">
<p class="fw-semibold gray checkbox-header">Task Type:</p>
<div class="radio regular"><input type="radio" name="taskType" id="regular" value="regular"  v-model="laborExpense.taskType"><label for="regular">Regular</label></div>
<div class="radio"><input type="radio" name="taskType" id="overtime" value="overtime" v-model="laborExpense.taskType"><label for="overtime">Overtime</label></div>
<!-- checkbox regular
checkbox -->
</div>
</div>

<div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

<template v-if="laborExpense.inputTask || laborExpense.harvestTask || laborExpense.otherTask">

<div class="col-3 ">
<div class="form-item">
  <p class="form-label" :class="{active: focused.farm || (laborExpense.farm.length > 0)}">Farm</p>
  <!-- <select class="form-control" 
    v-model="laborExpense.farm"
    @click="() => {
      activated.farm = true;
      focused.farm = true;
    }"
    @change="() => {
      focused.farm = false;
    }">
    <option
      v-for="farm in farms"
      v-bind:key="farm.farmName + 'farm-option-labor-expense'"
    >{{ farm.farmName }}</option>
  </select> -->
  <DropDownList
    :mainObject="'laborExpense'"
    :mainProperty="'farm'"
    :injectionName="'laborExpenseDetails'"
    :optionKey="'farm-option-labor-expense'"
    :optionsList="farms"
    :optionProperty="'farmName'"
    :optionPropertyTwo="null"
    :defaultSelected="laborExpense.farm"
    @click="() => {
      activated.farm = true;
    }"
  />
<p v-if="activated.farm && laborExpense.farm.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.farm && laborExpense.farm.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3 " v-if="bedsOptions.length > 0">
<div class="form-item">
  <p class="form-label" :class="{active: focused.bed || (laborExpense.bed.length > 0)}">Bed</p>
  <!-- <select class="form-control" 
    v-model="laborExpense.bed"
    @click="() => {
      activated.bed = true;
      focused.bed = true;
    }"
    @change="() => {
      focused.bed = false;
    }">
    <option
      v-for="bed in bedsOptions"
      v-bind:key="bed + 'bed-option-labor-expense'"
    >{{ bed }}</option>
  </select> -->
  <DropDownList
    :mainObject="'laborExpense'"
    :mainProperty="'bed'"
    :injectionName="'laborExpenseDetails'"
    :optionKey="'bed-option-labor-expense'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="laborExpense.bed"
    @click="() => {
      activated.bed = true;
    }"
  />
<p v-if="activated.bed && laborExpense.bed.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.bed && laborExpense.bed.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
</div>
</div>

</template>

<div class="col-3 ">
<div class="form-item">
  <p class="form-label" :class="{active: focused.date || (laborExpense.date.length > 0)}">Date</p>
  <input type="date" class="form-control date-field" 
    :style="{color: (focused.date || laborExpense.date.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
    v-model="laborExpense.date"
    pattern="\d{2}/\d{2}/\d{4}"
    @focus="() => {
      activated.date = true;
      focused.date = true;
    }"
    @blur="() => {
      focused.date = false;
    }" />
  <div class="date-icon"></div>
  <p v-if="activated.date && laborExpense.date.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.task || (laborExpense.task.length > 0)}">Task</p>
<input type="text" class="form-control" 
  v-model="laborExpense.task"
  @focus="() => {
    activated.task = true;
    focused.task = true;
  }"
  @blur="() => {
    focused.task = false;
  }">
<p v-if="activated.task && laborExpense.task.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.task && laborExpense.task.length > 150" class="form-info red text-end">Please use maximum 150 characters</p>
</div>
</div>

<div class="col-3 ">
<div class="form-item">
  <p class="form-label" :class="{active: focused.assignedTo || (laborExpense.assignedTo.length > 0)}">Assigned To</p>
  <!-- <select class="form-control" 
    v-model="laborExpense.assignedTo"
    @click="() => {
      activated.assignedTo = true;
      focused.assignedTo = true;
    }"
    @change="() => {
      focused.assignedTo = false;
  }">
    <option
      v-for="user in users"
      @click="selectAssignedUser(user.id)"
      v-bind:key="user.id + 'user-option-labor-expense'"
    >{{ `${user?.mainDetails?.firstName} ${user?.mainDetails?.lastName}` }}</option>
  </select> -->
  <DropDownList
    :mainObject="'laborExpense'"
    :mainProperty="'assignedTo'"
    :injectionName="'laborExpenseDetails'"
    :optionKey="'user-option-labor-expense'"
    :optionsList="usersList"
    :optionProperty="'mainDetails'"
    :optionPropertyTwo="['firstName', 'lastName']"
    :selectionMethod="selectAssignedUser"
    :defaultSelected="laborExpense.assignedTo"
    @click="() => {
      activated.assignedTo = true;
    }"
  />
<p v-if="activated.assignedTo && laborExpense.assignedTo.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.assignedTo && laborExpense.assignedTo.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<template  v-if="laborExpense.harvestTask || laborExpense.inputTask">
  <div class="col-3 common">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.crop || (laborExpense.crop.length > 0)}">Crop</p>
  <input type="text" class="form-control"
    v-model="laborExpense.crop"
    @focus="() => {
      activated.crop = true;
      focused.crop = true;
    }"
    @blur="() => {
      focused.crop = false;
    }">
  <p v-if="activated.crop && laborExpense.crop.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.crop && laborExpense.crop.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
  </div>
  </div>
</template>

<template v-if="laborExpense.harvestTask">
  <div class="col-3 harvest-item">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.amtHarvested || (laborExpense.amtHarvested.length > 0)}">Amt  Harvested</p>
  <input type="text" class="form-control" 
    v-model="laborExpense.amtHarvested"
    @focus="() => {
      activated.amtHarvested = true;
      focused.amtHarvested = true;
    }"
    @blur="() => {
      focused.amtHarvested = false;
    }">
  <p v-if="activated.amtHarvested && laborExpense.amtHarvested.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.amtHarvested && laborExpense.amtHarvested.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
  </div>
  </div>

</template>

<template v-if="laborExpense.inputTask">

  <div class="col-3 input-item" v-if="products.length > 0">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.inputUsed || (laborExpense.inputUsed.length > 0)}">Input Used</p>
    <select class="form-control" 
      v-model="laborExpense.inputUsed"
      @click="() => {
        activated.inputUsed = true;
        focused.inputUsed = true;
      }"
      @change="() => {
        focused.inputUsed = false;
      }">
      <option
        v-for="prod in products"
        v-bind:key="prod.id + 'product-option-labor-expense'"
      >{{ prod.product }}</option>
    </select>
    <DropDownList
      :mainObject="'laborExpense'"
      :mainProperty="'inputUsed'"
      :injectionName="'laborExpenseDetails'"
      :optionKey="'product-option-labor-expense'"
      :optionsList="products"
      :optionProperty="'item'"
      :optionPropertyTwo="null"
      :defaultSelected="laborExpense.inputUsed"
      @click="() => {
        activated.inputUsed = true;
      }"
    />
  <p v-if="activated.inputUsed && laborExpense.inputUsed.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.inputUsed && laborExpense.inputUsed.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
  </div>
  </div>

  <div class="col-3 input-item" v-else>
  <div class="form-item">
  <p class="form-label" :class="{active: focused.inputUsed || (laborExpense.inputUsed.length > 0)}">Input Used</p>
  <input type="text" class="form-control" 
    v-model="laborExpense.inputUsed"
    @focus="() => {
      activated.inputUsed = true;
      focused.inputUsed = true;
    }"
    @blur="() => {
      focused.inputUsed = false;
    }">
  <p v-if="activated.inputUsed && laborExpense.inputUsed.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.inputUsed && laborExpense.inputUsed.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
  </div>
  </div>
</template>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.taskStatus || (laborExpense.taskStatus.length > 0)}">Status</p>
<!-- <select class="form-control" 
  v-model="laborExpense.taskStatus"
  @click="() => {
    activated.taskStatus = true;
    focused.taskStatus = true;
  }"
  @change="() => {
    focused.taskStatus = false;
  }">
<option>Not started</option>
<option>In progress</option>
<option>Completed</option>
</select> -->
    <DropDownList
      :mainObject="'laborExpense'"
      :mainProperty="'taskStatus'"
      :injectionName="'laborExpenseDetails'"
      :optionKey="'status-option-labor-expense'"
      :optionsList="['Not started', 'In progress', 'Completed']"
      :optionProperty="null"
      :optionPropertyTwo="null"
      :defaultSelected="laborExpense.taskStatus"
      @click="() => {
        activated.taskStatus = true;
      }"
    />
<p v-if="activated.taskStatus && laborExpense.taskStatus.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.totalHours || (laborExpense.totalHours.length > 0)}">Total Hours</p>
<input type="text" class="form-control" 
  v-model="laborExpense.totalHours"
  @focus="() => {
    activated.totalHours = true;
    focused.totalHours = true;
  }"
  @blur="() => {
    focused.totalHours = false;
  }">
<p v-if="activated.totalHours && laborExpense.totalHours.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.totalHours && laborExpense.totalHours.length > 6" class="form-info red text-end">Please use maximum 6 characters</p>
</div>
</div>

<div class="col-3 " v-if="assignedUser?.employeeType !== 'employee'">
<div class="form-item">
  <p class="form-label" :class="{active: focused.rate || (laborExpense.rate.length > 0)}">Rate</p>
  <input type="text" class="form-control" 
    v-model="laborExpense.rate"
    @focus="() => {
      activated.rate = true;
      focused.rate = true;
    }"
    @blur="() => {
      focused.rate = false;
    }" />
<p v-if="activated.rate && laborExpense.rate.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.rate && laborExpense.rate.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3 " v-if="assignedUser?.employeeType !== 'employee'">
<div class="form-item">
  <p class="form-label" :class="{active: focused.total || (laborExpense.total.length > 0)}">Total</p>
  <input type="text" class="form-control" 
    v-model="laborExpense.total"
    @focus="() => {
      activated.total = true;
      focused.total = true;
    }"
    @blur="() => {
      focused.total = false;
    }" />
<p v-if="activated.total && laborExpense.total.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.total && laborExpense.total.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-12 ">
<div class="form-item">
<p class="form-label" :class="{active: focused.comments || (laborExpense.comments.length > 0)}">Comments</p>
<textarea class="form-control" v-model="laborExpense.comments"
  @focus="() => {
    activated.comments = true;
    focused.comments = true;
  }"
  @blur="() => {
    focused.comments = false;
  }"></textarea>
<p v-if="activated.comments && laborExpense.comments.length > 150" class="form-info red text-end">Please use maximum 150 characters</p>
</div>
</div>

</div>
</div>
</div>

</div>

<div class="content-block">
  <FileAttachments :key="`labor-expense-form-${laborExpense.id}`" v-bind:section="'task'" v-bind:instance="`${laborExpense.id}`" />

  <div class="content-section">
    <div class="buttons">
    <div class="row">
    <div class="col-auto"><button type="submit" @click="submitForm" class="btn green">Save</button></div>
    <div class="col-auto"><button type="submit" @click="cancelChanges" class="btn light-blue-20 btn-o">Cancel</button></div>
    </div>
    </div>
  </div>
</div>

</div>
</div>
</section>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { 
  defineComponent, 
  reactive, inject, 
  ref, computed, 
  watch, onMounted 
} from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import DropDownList from '@/components/Shared/DropDownList.vue';

export default defineComponent({
    name: "LaborExpensesForm",
    components: { FileAttachments, DropDownList },
    setup() {
      const store = useStore();

      const activated = reactive({
        date: false,
        task: false, 
        assignedTo: false, 
        status: false,
        totalHours: false, 
        rate: false, 
        total: false,

        farm: false, 
        bed: false,

        amtHarvested: false,
        crop: false,
        inputUsed: false,

        comments: false
      });

      const focused = reactive({
        date: false,
        task: false, 
        assignedTo: false, 
        status: false,
        totalHours: false, 
        rate: false, 
        total: false,

        farm: false, 
        bed: false,
        
        amtHarvested: false,
        crop: false,
        inputUsed: false,
        
        comments: false
      });

      const { 
        laborExpense, 
        editionMode, 
        selectedLaborExpense, 
        selectLaborExpense, 
        defaultLaborExpense,
        options: bedsOptions
      } = inject("laborExpenseDetails");

      const assignedUser = ref({});

      const newId = ref(null);

      const farms = computed(() => store.state.farms);

      const users = computed(() => store.state.users);

      const usersList = ref([
        {
          ...store.state.primaryUser,
          mainDetails: {
            ...store.state.primaryUser.mainDetails,
            id: 'primary'
          }
        },
        ...users.value
      ]);

      const products = computed(() => [...store.state.vendorItems].filter(stateItem => stateItem.type === 'Product'));

      const helperTooltip = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const submitForm = () => {

        const selectedFarm = farms.value.find(frm => frm.farmName === laborExpense.farm);

        // || (
        //     // IS Bed required??
        //     !!laborExpense.inputTask && (
        //       !laborExpense.farm || ((selectedFarm?.beds.length > 0) && !laborExpense.bed) || 
        //       !laborExpense.crop || !laborExpense.equipment || !laborExpense.inputUsed || !laborExpense.brand || 
        //       !laborExpense.quantity
        //     )
        //   ) || (
        //     !!laborExpense.harvestTask && (
        //       // IS Bed required??
        //       !laborExpense.farm || ((selectedFarm?.beds.length > 0) && !laborExpense.bed) || 
        //       !laborExpense.crop || !laborExpense.equipment || !laborExpense.cropHarvested || 
        //       !laborExpense.amtHarvested || (laborExpense.wastage && !laborExpense.wastageCause)
        //     )
        //   )
        if (!laborExpense.date || !laborExpense.task || !laborExpense.assignedTo || 
         !laborExpense.totalHours || !laborExpense.rate || !laborExpense.total ||
         ((laborExpense.inputTask || laborExpense.harvestTask || laborExpense.otherTask) && (!laborExpense.farm || !laborExpense.bed)) || 
         ((laborExpense.taskType === 'overtime') && !laborExpense.farm || 
         ((selectedFarm?.beds.length > 0) && !laborExpense.bed))) {
          if (!laborExpense.date) activated.date = true;
          if (!laborExpense.task) activated.task = true;
          if (!laborExpense.assignedTo) activated.assignedTo = true;
          // if (!laborExpense.status) activated.status = true;
          if (!laborExpense.totalHours) activated.totalHours = true;
          if (!laborExpense.rate) activated.rate = true;
          if (!laborExpense.total) activated.total = true;

          if (!laborExpense.farm) activated.farm = true;
          if (!laborExpense.bed) activated.bed = true;

          return;
        }

        if (!laborExpense.id) {
          // laborExpense.id = uuid();
          laborExpense.id = newId.value;
        }

        const formData = {
          ...laborExpense,
        };

        (editionMode.value === "edit") ?
          store.dispatch("editLaborExpense", { ...formData })
          :
          store.dispatch("createLaborExpense", {...formData})

        console.log(formData);
        (editionMode.value === "create") && closeForm();
        (editionMode.value === "edit") && selectLaborExpense(laborExpense, 'represent');
      }

      const closeForm = () => {
        laborExpense.id = '';
        for (let prop in laborExpense) {
          laborExpense[prop] = defaultLaborExpense[prop];
        }

        for (let prop in activated) {
          activated[prop] = false;
        }
        
        for (let prop in focused) {
          focused[prop] = false;
        }
      }

      const cancelChanges = () => {
        if (editionMode.value === "edit") {
          for (let prop in laborExpense) {
            laborExpense[prop] = selectedLaborExpense[prop];
          }
          editionMode.value = "represent";
        } else {
          for (let prop in laborExpense) {
            laborExpense[prop] = defaultLaborExpense[prop];
          }
          editionMode.value = "";
        }
      };

      const selectAssignedUser = (userId) => {
        if (userId) {
          assignedUser.value = usersList.value.find(usr => usr.id === userId)
        }
      }

      watch(laborExpense, (newVal) => {
        if (newVal?.farm) {
          const selectedFarm = farms.value.find(frm => frm.farmName === newVal.farm);

          bedsOptions.value = selectedFarm.beds.map(bed => bed.bedNumber);

          laborExpense.crop = selectedFarm.primaryCrop;
        }
      })

      watch(() => (laborExpense.harvestTask), (newVal) => {
        if (newVal) laborExpense.otherTask = false;
      })

      watch(() => (laborExpense.inputTask), (newVal) => {
        if (newVal) laborExpense.otherTask = false;
      })

      watch(() => (laborExpense.otherTask), (newVal) => {
        if (newVal) {
          laborExpense.inputTask = false;
          laborExpense.harvestTask = false;
        }
      })

      watch(() => (laborExpense.rate), (newVal) => {
        if (newVal && laborExpense.totalHours) {
          laborExpense.total = newVal * laborExpense.totalHours;
          activated.total = true;
          focused.total = true;
        }
      })

      watch(() => (laborExpense.totalHours), (newVal) => {
        if (newVal && laborExpense.rate) {
          laborExpense.total = newVal * laborExpense.rate;
          activated.total = true;
          focused.total = true;
        }
      })

      watch(() => ([...store.state.users]), (newVal) => {
        if (newVal) {
          usersList.value = [
            {
              ...store.state.primaryUser,
              mainDetails: {
                ...store.state.primaryUser.mainDetails,
                id: 'primary'
              }
            },
            ...newVal
          ];
        }
      });

      onMounted(() => {
        laborExpense.farm  = farms.value[0]?.farmName || '';

        if (editionMode.value !== 'edit') {
          newId.value = uuid();
          laborExpense.id = newId.value;
        }

        usersList.value = [
          {
            ...store.state.primaryUser,
            mainDetails: {
              ...store.state.primaryUser.mainDetails,
              id: 'primary'
            }
          },
          ...users.value
        ];
      })

      return {
        activated,
        focused,
        laborExpense,
        bedsOptions,
        farms,
        users,
        usersList,
        products,
        helperTooltip,
        switchHelperTooltip,
        submitForm,
        cancelChanges,
        selectAssignedUser,
        assignedUser
      }
    }
})
</script>

<style lang="scss" scoped>
.form-control {
  &.date-field {
    padding-right: 1.8rem;
    background: none;
  }
}
.date-icon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 3.7rem;
  right: 1rem;
  z-index: 8;
  pointer-events: none;
  border-radius: 1rem;
  background:url('@/assets/images/calendar-icon.svg') center center no-repeat;
  background-color: #fff;
}

.task-type {
  .fw-semibold {
    &.gray {
      margin-right: 5rem;
      margin-left: 6rem;
    }
  }
  .radio {
    &.regular {
      margin-right: 3rem;
    }
  }
}

.task-category,
.task-type {
  .checkbox {
    label {
      color: hsl(var(--dark-gray)) !important;
      font-size: var(--fs-lg);
      font-family: var(--fw-medium);
    }
  }
  .radio {
    label {
      color: hsl(var(--dark-gray)) !important;
      font-size: var(--fs-lg);
      font-family: var(--fw-medium);
    }
  }
}
.checkbox-header {
  color: hsl(210, 1%, 40%);
}
</style>