<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Inventory Purchased</h5>
<div class="icon-group">
  <!-- <div class="icon green icon-o camera-plus"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
  >
    <img src="@/assets/images/camera-plus.svg" alt="">
    <div class="helper-tooltip" v-if="helperTooltip">Take a shot</div>
  </div> -->
</div>
</div>

<div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.date || (addedInventory.date.length > 0)}">Date Purchased</p>
<input type="date" class="form-control" 
  :style="{color: (focused.date || addedInventory.date.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
  v-model="addedInventory.date"
  pattern="\d{2}/\d{2}/\d{4}"
  @focus="() => {
    activated.date = true;
    focused.date = true;
  }"
  @blur="() => {
    focused.date = false;
  }">
  <div class="date-icon"></div>
<p v-if="activated.date && addedInventory.date.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3" v-if="vendors.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorName || (addedInventory.vendorName.length > 0)}">Vendor</p>
  <select class="form-control" 
    v-model="addedInventory.vendorName"
    @click="() => {
      activated.vendorName = true;
      focused.vendorName = true;
    }"
    @change="() => {
      focused.vendorName = false;
    }">
    <option
      v-for="vend in vendors"
      v-bind:key="vend.vendor + 'vendor-option-inventory'"
    >{{ vend.vendor }}</option>
  </select>
<p v-if="activated.vendorName && addedInventory.vendorName.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.vendorName && addedInventory.vendorName.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-else>
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorName || (addedInventory.vendorName.length > 0)}">Vendor</p>
<input type="text" class="form-control" v-model="addedInventory.vendorName"
  @focus="() => {
    activated.vendorName = true;
    focused.vendorName = true;
  }"
  @blur="() => {
    focused.vendorName = false;
  }">
<p v-if="activated.vendorName && addedInventory.vendorName.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.vendorName && addedInventory.vendorName.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-if="products.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.item || (addedInventory.item.length > 0)}">Item</p>
  <select class="form-control" 
    v-model="addedInventory.item"
    @click="() => {
      activated.item = true;
      focused.item = true;
    }"
    @change="() => {
      focused.item = false;
    }">
    <option
      v-for="prod in products"
      v-bind:key="prod.item + 'product-option-inventory'"
    >{{ prod.item }}</option>
  </select>
<p v-if="activated.item && addedInventory.item.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.item && addedInventory.item.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3" v-else>
<div class="form-item">
<p class="form-label" :class="{active: focused.item || (addedInventory.item.length > 0)}">Item</p>
<input type="text" class="form-control" v-model="addedInventory.item"
  @focus="() => {
    activated.item = true;
    focused.item = true;
  }"
  @blur="() => {
    focused.item = false;
  }">
<p v-if="activated.item && addedInventory.item.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.item && addedInventory.item.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.brand || (addedInventory.brand.length > 0)}">Brand</p>
<input type="text" class="form-control" v-model="addedInventory.brand"
  @focus="() => {
    activated.brand = true;
    focused.brand = true;
  }"
  @blur="() => {
    focused.brand = false;
  }">
<p v-if="activated.brand && addedInventory.brand.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.brand && addedInventory.brand.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.variety || (addedInventory.variety.length > 0)}">Variety</p>
<input type="text" class="form-control" v-model="addedInventory.variety"
  @focus="() => {
    activated.variety = true;
    focused.variety = true;
  }"
  @blur="() => {
    focused.variety = false;
  }">
<p v-if="activated.variety && addedInventory.variety.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.numberOfItems || (addedInventory.numberOfItems > 0)}"># of Items</p>
<input type="number" class="form-control"
  v-model="addedInventory.numberOfItems"
  @focus="() => {
    activated.numberOfItems = true;
    focused.numberOfItems = true;
  }"
  @blur="() => {
    focused.numberOfItems = false;
  }">
<p v-if="activated.numberOfItems && addedInventory.numberOfItems === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.numberOfItems && `${addedInventory.numberOfItems}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.quantity || (addedInventory.quantity > 0)}">Weight (lbs/item)</p>
<input type="number" class="form-control" v-model="addedInventory.quantity"
  @focus="() => {
    activated.quantity = true;
    focused.quantity = true;
  }"
  @blur="() => {
    focused.quantity = false;
  }">
<p v-if="activated.quantity && addedInventory.quantity === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.quantity && `${addedInventory.quantity}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.invoice || (addedInventory.invoice.length > 0)}">Invoice #</p>
<input type="text" class="form-control" v-model="addedInventory.invoice"
  @focus="() => {
    activated.invoice = true;
    focused.invoice = true;
  }"
  @blur="() => {
    focused.invoice = false;
  }">
<p v-if="activated.invoice && addedInventory.invoice.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.amountPaid || (addedInventory.amountPaid > 0)}">Amount Paid</p>
<input type="number" class="form-control" v-model="addedInventory.amountPaid"
  @focus="() => {
    activated.amountPaid = true;
    focused.amountPaid = true;
  }"
  @blur="() => {
    focused.amountPaid = false;
  }">
<p v-if="activated.amountPaid && addedInventory.amountPaid === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.amountPaid && `${addedInventory.amountPaid}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.warrantyExp || (addedInventory.warrantyExp > 0)}">Warranty Exp</p>
  <input type="date" class="form-control" 
    :style="{color: (focused.warrantyExp || addedInventory.warrantyExp.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
    v-model="addedInventory.warrantyExp"
    pattern="\d{2}/\d{2}/\d{4}"
    @focus="() => {
      activated.warrantyExp = true;
      focused.warrantyExp = true;
    }"
    @blur="() => {
      focused.warrantyExp = false;
    }">
  <div class="date-icon"></div>
<p v-if="activated.warrantyExp && `${addedInventory.warrantyExp}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorPhone || (addedInventory.vendorPhone.length > 0)}">Vendor Phone</p>
<input type="text" class="form-control" v-model="addedInventory.vendorPhone"
  @focus="() => {
    activated.vendorPhone = true;
    focused.vendorPhone = true;
  }"
  @blur="() => {
    focused.vendorPhone = false;
    phoneNumberFormat('vendorPhone')
  }" disabled>
<p v-if="activated.vendorPhone && addedInventory.vendorPhone.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.vendorPhone && addedInventory.vendorPhone.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorContact || (addedInventory.vendorContact.length > 0)}">Vendor Contact</p>
<input type="text" class="form-control" v-model="addedInventory.vendorContact"
  @focus="() => {
    activated.vendorContact = true;
    focused.vendorContact = true;
  }"
  @blur="() => {
    focused.vendorContact = false;
  }" disabled>
<p v-if="activated.vendorContact && addedInventory.vendorContact.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.vendorContact && addedInventory.vendorContact.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-12">
<div class="form-item">
<p class="form-label" :class="{active: focused.comments || (addedInventory.comments.length > 0)}">Comments</p>
<textarea class="form-control" v-model="addedInventory.comments"
  @focus="() => {
    activated.comments = true;
    focused.comments = true;
  }"
  @blur="() => {
    focused.comments = false;
  }"></textarea>
</div>
</div>

</div>
</div>
</div>

</div>

<div class="content-block">

  <FileAttachments :key="`vendor-product-form-${addedInventory.id}`" v-bind:section="'vendorItem'"  v-bind:instance="`${addedInventory.id}`" />

<div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
</div>
</div>
</div>

</div>

</div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { computed, reactive, inject, ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
// import moment from 'moment';

export default {
  name: "AddInventory",
  setup() {
    const store = useStore();

    const activated = reactive({
      date: false,
      item: false,
      brand: false,
      variety: false,
      numberOfItems: false,
      quantity: false,
      amountPaid: false,
      invoice: false,
      warrantyExp: false,
      vendorName: false,
      vendorPhone: false,
      vendorContact: false,
      comments: false
    });

    const focused = reactive({
      date: false,
      item: false,
      brand: false,
      variety: false,
      numberOfItems: false,
      quantity: false,
      amountPaid: false,
      invoice: false,
      warrantyExp: false,
      vendorName: false,
      vendorPhone: false,
      vendorContact: false,
      comments: false
    });

    const { addedInventory, addInventoryEditionMode, selectedAddedInventory, 
      selectAddedInventory, defaultAddedInventory } = inject("addedInventoryDetails");

    const vendors = computed(() => store.state.vendors);

    const inventories = computed(() => [...store.state.vendorItems].filter(stateItem => stateItem.type === 'Product'));

    const products = ref([]);

    const helperTooltip = ref(false);

    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle;
    }

    const submitForm = () => {
      if (!addedInventory.date || !addedInventory.item || !addedInventory.brand ||
      !addedInventory.numberOfItems || !addedInventory.amountPaid || !addedInventory.vendorName ||
      !addedInventory.vendorPhone || !addedInventory.vendorContact ||
      ((((addedInventory.vendorPhone.length !== 10) && (addedInventory.vendorPhone.length > 0)) || (addedInventory.vendorPhone.length > 10)) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(addedInventory.vendorPhone))
      ) {
        if (!addedInventory.date) activated.date = true;
        if (!addedInventory.item) activated.item = true;
        if (!addedInventory.brand) activated.brand = true;
        if (!addedInventory.numberOfItems) activated.numberOfItems = true;
        if (!addedInventory.amountPaid) activated.amountPaid = true;
        if (!addedInventory.vendorName) activated.vendorName = true;
        if (!addedInventory.vendorPhone) activated.vendorPhone = true;
        if (!addedInventory.vendorContact) activated.vendorContact = true;
        return;
      }

      // if (!addedInventory.id) {
      //   addedInventory.id = uuid();
      // }
      
      // addedInventory.date = moment(addedInventory.date).format('MM/DD/YYYY');

      const formData = {
        ...addedInventory
      };

      if (vendors.value.length <= 0) {
      //   store.dispatch("createVendor", {
      //     id: uuid(),
      //     vendor: addedInventory.vendor
      //   });

      //   store.dispatch('addVendorItem', {
      //     id: uuid(),
      //     vendor: addedInventory.vendor,
      //     item: addedInventory.item,
      //     brand: addedInventory.brand,
      //     variety: addedInventory.variety
      //   })
      }

      // (addInventoryEditionMode.value === "edit") ?
          // store.dispatch("editAddedInventory", { ...formData }) :
          // store.dispatch("createAddedInventory", {...formData});
      // console.log(formData);
      store.dispatch("editVendorItem", { ...formData });
      // (addInventoryEditionMode.value === "create") && closeForm();
      (addInventoryEditionMode.value === "edit") && selectAddedInventory(addedInventory, 'represent');
    }

    // const closeForm = () => {
    //   for (let prop in addedInventory) {
    //     addedInventory[prop] = defaultAddedInventory[prop];
    //   }

    //   for (let prop in activated) {
    //     activated[prop] = false;
    //   }
      
    //   for (let prop in focused) {
    //     focused[prop] = false;
    //   }
    // }

    const cancelChanges = () => {
      if (addInventoryEditionMode.value === "edit") {
        for (let prop in addedInventory) {
          addedInventory[prop] = selectedAddedInventory[prop];
        }
        addInventoryEditionMode.value = "represent";
      } else {
        for (let prop in addedInventory) {
          addedInventory[prop] = defaultAddedInventory[prop];
        }
      }
    };

    const phoneNumberFormat = (phoneType) => {
      if (addedInventory[phoneType].length > 0) {
        if (addedInventory[phoneType][0] !== "(") {
            const arrStr = addedInventory[phoneType].split("");
            addedInventory[phoneType] = `(${arrStr.filter((num, indx) => {
                if (indx < 3)
                    return num;
            }).join("")}) ${arrStr.filter((num, indx) => {
                if ((indx >= 3) && (indx < 6))
                    return num;
            }).join("")}-${arrStr.filter((num, indx) => {
                if (indx >= 6)
                    return num;
            }).join("")}`;
        }
      }
    };

    watch(() => (addedInventory.vendorName), (newVal) => {
      console.log(newVal, 'changed vendor');
      const selectedVendor = vendors.value.find(stateVendor => stateVendor.vendor === newVal);

      products.value = [...inventories.value.filter(inv => inv.vendor === selectedVendor.id)]
      if (selectedVendor) {
        addedInventory.vendorPhone = selectedVendor.vendorPhone;
        addedInventory.vendorContact = selectedVendor.vendorContact;
      }
    })

    watch(() => (addedInventory.item), (newVal) => {
      const selectedProduct = products.value.find(prd => prd.item === newVal);

      if (selectedProduct) {
        addedInventory.brand = selectedProduct.brand;
        addedInventory.variety = selectedProduct.variety;
      }
    });

    watch(() => ({...addedInventory}), (newVal) => {
      if (!newVal.id) {
        addedInventory.id === uuid();
      }
    });

    onMounted(() => {
      if (!addedInventory.id) {
        console.log(addedInventory.id, 'addedInventory.id');
        addedInventory.id = uuid();
      }
    });

    return {
      activated,
      focused,
      addedInventory,
      vendors,
      products,
      phoneNumberFormat,
      submitForm,
      cancelChanges,
      helperTooltip,
      switchHelperTooltip
    }

  },
  components: { FileAttachments }
}
</script>

<style lang="scss" scoped>
.date-icon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 3.7rem;
  right: 1rem;
  z-index: 8;
  pointer-events: none;
  border-radius: 1rem;
  background:url('@/assets/images/calendar-icon.svg') center center no-repeat;
  background-color: #fff;
}
</style>