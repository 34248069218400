import { createRouter, createWebHistory } from "vue-router";
// import HomeView from '../views/HomeView.vue'
import CurrentPage from "@/views/CurrentPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import RegisterPage from "@/views/RegisterPage.vue";
import UserAgreement from '@/views/UserAgreement.vue';
import CookiePolicy from '@/views/CookiePolicy.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import getUserData from "@/sharedData/authRequest";

const routes = [
  {
    path: "/:pagename",
    name: "page",
    component: CurrentPage,
  },
  {
    path: "/user/:pagename",
    name: "user",
    component: CurrentPage,
  },
  {
    path: "/farm/:pagename",
    name: "farm",
    component: CurrentPage,
  },
  {
    path: "/task/:pagename",
    name: "task",
    component: CurrentPage,
  },
  {
    path: "/crops/:pagename",
    name: "crops",
    component: CurrentPage,
  },
  {
    path: "/labor/:pagename",
    name: "labor",
    component: CurrentPage,
  },
  {
    path: "/crops/:pagename",
    name: "crops",
    component: CurrentPage,
  },
  {
    path: "/inventory/:pagename",
    name: "inventory",
    component: CurrentPage,
  },
  {
    path: "/vendors/:pagename",
    name: "vendors",
    component: CurrentPage,
  },
  {
    path: "/equipment/:pagename",
    name: "equipment",
    component: CurrentPage
  },
  {
    path: "/customers/:pagename",
    name: "customers",
    component: CurrentPage
  },
  {
    path: "/sales/:pagename",
    name: "sales",
    component: CurrentPage
  },
  {
    path: "/resources/:pagename",
    name: "resources",
    component: CurrentPage
  },
  {
    path: "/contact/:pagename",
    name: "contact",
    component: CurrentPage
  },
  {
    path: "/admin/advertisement-module/:pagename",
    name: "advertisement",
    component: CurrentPage
  },
  {
    path: "/admin/:pagename",
    name: "admin",
    component: CurrentPage
  },
  {
    path: "/super-admin/:pagename",
    name: "super-admin",
    component: CurrentPage
  },
  {
    path: "/reset-password/:pagename",
    name: "reset-password",
    component: CurrentPage
  },
  {
    path: "/reports/:pagename",
    name: "reports",
    component: CurrentPage
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    props: route => ({ query: route.query.email })
  },
  {
    path: "/register",
    name: "register",
    component: RegisterPage,
  },
  {
    path: "/user-agreement",
    name: "user-agreement",
    component: UserAgreement
  },
  {
    path: "/cookie-policy",
    name: "cookie-policy",
    component: CookiePolicy
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy
  },
  {
    path: "/",
    name: "home",
    component: CurrentPage,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementsByTagName('main')[0].scroll({
      top: 0,
      behavior: 'smooth'
    });
    // return {
    //   top: 0
    // }
  }
});

router.beforeEach(async (to, from, next) => {
  const user = await getUserData(to.params.id);
  console.log(user);
  //check page is protected or not
  if (to.meta.authRequired === 'true') {

    //get contact's id
    const contactId = to.params.id

    //access check
    if (
      //if user is admin or super admin
      user.role === 'super_admin' ||
      user.role === 'admin' ||
      //if user is the contact itself
      user.id === contactId ||
      //if user is manager and has necessary permissions
      user.role === 'manager' &&
      user.role.permissions.some(p => p.key === 'create-contact') &&
      user.role.permissions.some(p => p.key === 'update-contact')
    ) {
      return next()
    } else {
      router.push({
        name: 'Unauthorized'
      })
    }
  } else if (to.meta.signCheck) {
    const token = user.token;

    if (token) {
      return next()
    } else {
      router.push({
        name: 'Unauthorized'
      })
    }
  } else {
    return next()
  }
});

export default router;
