<template>
  <ExpensesHeader />
  <section class="section">
    <div class="border-box">
      <div ref="adminExpenseForm"></div>
      <AdminExpensesForm v-if="((editionMode === 'edit') || (editionMode === 'create')) && (isAuth('edit-admin-expenses') || isAuth('add-admin-expenses'))" />
      <AdminExpensesFilled v-if="editionMode === 'represent'" />
      <AdminExpensesList />
      <SalaryExpensesList />
    </div>
  </section>
</template>

<script>
import { defineComponent, inject, provide, reactive, ref, onMounted, watch } from 'vue';
import ExpensesHeader from './ExpensesHeader.vue';
import AdminExpensesForm from './AdminExpensesForm.vue';
import AdminExpensesFilled from './AdminExpensesFilled.vue';
import AdminExpensesList from './AdminExpensesList.vue';
import { useStore } from 'vuex';
import SalaryExpensesList from './SalaryExpensesList.vue';

export default defineComponent({
    name: "AdminExpenses",
    setup() {
      const store = useStore();

      const { isAuth } = inject('mainUI');

      const editionMode = ref('');

      const users = ref([...store.state.labors]);

      const grades = ref([...store.state.compensations]);

      const adminExpense = reactive({
        id: '',
        date: '',
        month: '',

        employee: '',
        grade: '',
        salary: '',
        medicalBenefits: '',
        bonus: '',
        other: '',
        taskStatus: '',
        costCenter: '',

        category: '',
        vendor: '',
        invoiceNumber: '',
        amount: '',
        // status: '',
        paymentType: '',
        // costCenter: '',

        expenseType: 'Salary',

        comments: '',
      });

      const selectedAdminExpense = reactive({
        ...adminExpense
      });

      const adminExpenseForm = ref(null);

      const defaultAdminExpense = {
        ...adminExpense
      }

      const addAdminExpense = (expenseType) => {
        selectAdminExpense({
          ...defaultAdminExpense,
          expenseType
        }, 'create');
        
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: adminExpenseForm?.value?.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        })
        // editionMode.value = 'create';
      }

      const editAdminExpense = (field, val) => {
        adminExpense[field] = val;
      }

      const selectAdminExpense = (adminExpenseData, editMode) => {
        if (adminExpenseData) {
          for (let field in adminExpenseData) {
            adminExpense[field] = adminExpenseData[field]
            selectedAdminExpense[field] = adminExpenseData[field]
          }
        }
        editionMode.value = editMode;
      }

      const removeAdminExpense = (adminExpenseId) => {
        store.dispatch('removeAdminExpense', adminExpenseId)
      };

      provide('adminExpenseDetails', {
        adminExpense,
        defaultAdminExpense,
        selectedAdminExpense,
        editionMode,
        addAdminExpense,
        editAdminExpense,
        selectAdminExpense,
        removeAdminExpense,
        users,
        grades
      })

      watch(() => ([...store.state.labors]), (newVal) => {
        users.value = [...newVal];
      });

      watch(() => ([...store.state.compensations]), (newVal) => {
        grades.value = [...newVal];
      });

      onMounted(() => {
        store.dispatch('getLabors');
        store.dispatch('getCompensations');
      })

      return {
        editionMode,
        isAuth,
        adminExpense,
        adminExpenseForm
      }
    },
    components: {
    ExpensesHeader,
    AdminExpensesForm,
    AdminExpensesFilled,
    AdminExpensesList,
    SalaryExpensesList
}
})
</script>

<style lang="scss" scoped></style>