const getOptionsAuth = (data) => {
  return {
    method: 'GET', 
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin', 
    headers: {
      'Authorization': `Bearer ${data.token}`,
      'Content-Type': 'application/json'
    },
    redirect: 'follow', 
    referrerPolicy: 'no-referrer'
  };
}

const postOptionsAuth = (data) => {
  return {
    method: 'POST', 
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin', 
    headers: {
      'Authorization': `Bearer ${data.token}`,
      'Content-Type': 'application/json'
    },
    redirect: 'follow', 
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data)
  }
}

const postOptionsAuthFiles = (data) => {
  const formData = new FormData();

  const getFile = ({file, fileName}) => {
    let arr = file.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type:mime});
  }
  
  for (let prop in data) {
    // if (prop === 'documentsupload') {
    //   formData.append(`${prop}`, data[prop])
    // } else {
      if ((prop !== 'token') && (prop !== 'video') && (prop !== 'image') && (prop !== 'document')) {
        formData.append(`${prop}`, data[prop]);
      }

    //   const getFile = ({file, fileName}) => {
    //     const base64 = file;
    //     const base64Parts = base64.split(",");
    //     // const fileFormat = base64Parts[0].split(";")[1];
    //     const fileFormat = base64Parts[0].split(";")[0].split(':')[1];
    //     const fileContent = base64Parts[1];
    //     const newFile = new File([fileContent], fileName, {type: fileFormat});
    //     console.log(newFile, 'newFile');
    //     return newFile;
    //  }
    if (prop === 'video') {
      for (let i = 0; i < data[prop].length; i++) {
        formData.append("video", getFile(data[prop][i]));
      }
    }

    if (prop === 'image') {
      for (let i = 0; i < data[prop].length; i++) {
        formData.append("image", getFile(data[prop][i]));
      }
    }

    if (prop === 'document') {
      for (let i = 0; i < data[prop].length; i++) {
        formData.append("document", getFile(data[prop][i]));
      }
    }
    // }
  }
  
  return {
    method: 'POST', 
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin', 
    headers: {
      'Authorization': `Bearer ${data.token}`,
      // 'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    },
    redirect: 'follow', 
    referrerPolicy: 'no-referrer',
    body: formData
  };
}

const postOptionsAuthGetFiles = (data) => {
  return {
    method: 'POST', 
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin', 
    headers: {
      'Authorization': `Bearer ${data.token}`,
      'Content-Type': 'application/json'
    },
    redirect: 'follow', 
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data)
  }
}

// fetch with files
// const input = document.querySelector('input[type="file"]')

// const data = new FormData()
// data.append('file', input.files[0])
// data.append('user', 'hubot')

// fetch('/avatars', {
//   method: 'POST',
//   body: data
// })

const patchOptionsAuth = (data) => {
  return {
      method: 'PATCH',
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Authorization': `Bearer ${data.token}`,
        'Content-Type': 'application/json'
      },
      redirect: 'follow', 
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
  }
}

const patchOptionsAuthEmptyAttachments = (data) => {

  const formData = new FormData();

  for (let prop in data) {
    if (prop !== 'token') {
      formData.append(`${prop}`, data[prop]);
    }
  }

  return {
      method: 'PATCH',
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Authorization': `Bearer ${data.token}`,
        // 'Content-Type': 'application/json'
      },
      redirect: 'follow', 
      referrerPolicy: 'no-referrer',
      body: formData
  }
}

const patchOptionsAuthFiles = (data) => {
  const formData = new FormData();

  const getFile = ({file, fileName}) => {
    if (file) {
      let arr = file.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, {type:mime});
    }
  }

  for (let prop in data) {
    // if (prop === 'documentsupload') {
    //   formData.append(`${prop}`, data[prop])
    // } else {
      if ((prop !== 'token') && (prop !== 'video') && (prop !== 'image') && (prop !== 'document')) {
        formData.append(`${prop}`, data[prop]);
      }

    //   const getFile = ({file, fileName}) => {
    //     const base64 = file;
    //     const base64Parts = base64.split(",");
    //     // const fileFormat = base64Parts[0].split(";")[1];
    //     const fileFormat = base64Parts[0].split(";")[0].split(':')[1];
    //     const fileContent = base64Parts[1];
    //     const newFile = new File([fileContent], fileName, {type: fileFormat});
    //     console.log(newFile, 'newFile');
    //     return newFile;
    //  }

      if (prop === 'video') {
        for (let i = 0; i < data[prop].length; i++) {
          formData.append("video", getFile(data[prop][i]));
        }
      }

      if (prop === 'image') {
        for (let i = 0; i < data[prop].length; i++) {
          formData.append("image", getFile(data[prop][i]));
        }
      }

      if (prop === 'document') {
        for (let i = 0; i < data[prop].length; i++) {
          formData.append("document", getFile(data[prop][i]));
        }
      }
    // }
  }
  
  return {
    method: 'PATCH', 
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin', 
    headers: {
      'Authorization': `Bearer ${data.token}`,
      // 'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    },
    redirect: 'follow', 
    referrerPolicy: 'no-referrer',
    body: formData
  };
}

const deleteOptions = () => {
  return {
      method: 'DELETE',
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', 
      referrerPolicy: 'no-referrer',
  }
}

const deleteOptionsAuth = (data) => {
  return {
      method: 'DELETE',
      mode: 'cors', 
      cache: 'no-cache', 
      credentials: 'same-origin', 
      headers: {
        'Authorization': `Bearer ${data.token}`,
        'Content-Type': 'application/json'
      },
      redirect: 'follow', 
      referrerPolicy: 'no-referrer',
  }
}

const postOptionsAuthResources = (data) => {
  console.log(data, 'data utils');
  const formData = new FormData();

  const getFile = ({file, fileName}) => {
    let arr = file.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type:mime});
  }
  
  for (let prop in data) {
    if ((prop !== 'token') && (prop !== 'file')) {
      formData.append(`${prop}`, data[prop]);
    }

    if (prop === 'file') {
      if (data[prop]) {
        formData.append("file", getFile({file: data[prop], fileName: data.title}));
      } else {
        formData.append("file", data[prop]);
      }
    }
  }
  
  return {
    method: 'POST', 
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin', 
    headers: {
      'Authorization': `Bearer ${data.token}`,
      // 'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    },
    redirect: 'follow', 
    referrerPolicy: 'no-referrer',
    body: formData
  };
}

const patchOptionsAuthResources = (data) => {
  const formData = new FormData();

  const getFile = ({file, fileName}) => {
    if (file) {
      let arr = file.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, {type:mime});
    }
  }

  for (let prop in data) {
    // if (prop === 'documentsupload') {
    //   formData.append(`${prop}`, data[prop])
    // } else {
    if ((prop !== 'token') && (prop !== 'file')) {
      formData.append(`${prop}`, data[prop]);
    }

    if (prop === 'file') {
      if (data[prop]) {
        formData.append("file", getFile({file: data[prop], fileName: data.title}));
      } else {
        formData.append("file", data[prop]);
      }
    }
  }
  
  return {
    method: 'PATCH', 
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin', 
    headers: {
      'Authorization': `Bearer ${data.token}`,
      // 'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
    },
    redirect: 'follow', 
    referrerPolicy: 'no-referrer',
    body: formData
  };
}


export {
  postOptionsAuthGetFiles,
  
  postOptionsAuthFiles,
  patchOptionsAuthFiles,

  deleteOptions,

  getOptionsAuth,
  postOptionsAuth,
  patchOptionsAuth,
  deleteOptionsAuth,

  patchOptionsAuthEmptyAttachments,

  postOptionsAuthResources,
  patchOptionsAuthResources
}