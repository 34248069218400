<template>
  <section class="section">
  <div class="border-box">
  <div class="border-box-content">

  <div class="content-block d-flex justify-content-between">
  <h5 class="fw-semibold">Labor Expense</h5>
  <div class="icon-group">
  <div class="icon green icon-o tooltip"
    @click="addLaborExpense"
    @mouseover="switchAddHelperTooltip(true)"
    @mouseout="switchAddHelperTooltip(false)"
    data-tooltip="Add Labor Expense"
  >
    <img src="@/assets/images/updated/059-add.png" alt="">
  </div>
  <div class="icon green icon-o tooltip"
    @click="selectLaborExpense(laborExpense, 'edit');"
    @mouseover="switchEditHelperTooltip(true)"
    @mouseout="switchEditHelperTooltip(false)"
    data-tooltip="Edit Expense"
  >
    <img src="@/assets/images/updated/Edit.png" alt="">
  </div>
  <div class="icon green icon-o tooltip"
      @mouseover="switchHelperTooltip(true)"
      @mouseout="switchHelperTooltip(false)"
      data-tooltip="Camera"
    >
      <img src="@/assets/images/updated/camera-white.png" alt="">
    </div>
  </div>
  </div>

  <div class="content-block d-lg-flex justify-content-between">
  <div class="checkbox-group task-category">
  <p class="fw-semibold gray checkbox-header">Select Categories:</p>
  <div class="checkbox"><input type="checkbox" id="input" :checked="laborExpense.inputTask" disabled><label for="input">Input</label></div>
  <div class="checkbox"><input type="checkbox" id="harvest" :checked="laborExpense.harvestTask" disabled><label for="harvest">Harvest</label></div>
  <div class="checkbox"><input type="checkbox" id="harvest" :checked="laborExpense.otherTask" disabled><label for="harvest">Other</label></div>
  </div>
  </div>

  <div class="content-block d-lg-flex justify-content-between">
  <div class="checkbox-group task-type">
  <p class="fw-semibold gray checkbox-header">Task Type:</p>
  <div class="radio regular"><input type="radio" name="taskType" id="regular" value="regular"  v-model="laborExpense.taskType" disabled><label for="regular">Regular</label></div>
  <div class="radio"><input type="radio" name="taskType" id="overtime" value="overtime" v-model="laborExpense.taskType" disabled><label for="overtime">Overtime</label></div>
  </div>
  </div>

  <div class="content-block">
  <div class="intro-grid">

  <div class="intro-grid-column" v-if="laborExpense.farm">
  <p class="title">Farm</p>
  <h6>{{ laborExpense.farm }}</h6>
  </div>

  <div class="intro-grid-column" v-if="laborExpense.bed">
  <p class="title">Bed</p>
  <h6>{{ laborExpense.bed }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Date</p>
  <h6>{{ laborExpense.date }}</h6>
  </div>
         
  <div class="intro-grid-column">
  <p class="title">Task</p>
  <h6>{{ laborExpense.task }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Assigned To</p>
  <h6>{{ laborExpense.assignedTo }}</h6>
  </div>

  <div class="intro-grid-column" v-if="laborExpense.crop">
  <p class="title">Crop</p>
  <h6>{{ laborExpense.crop }}</h6>
  </div>

  <div class="intro-grid-column" v-if="laborExpense.amtHarvested">
  <p class="title">Amt Harvested</p>
  <h6>{{ laborExpense.amtHarvested }}</h6>
  </div>

  <div class="intro-grid-column" v-if="laborExpense.inputUsed">
  <p class="title">Input Used</p>
  <h6>{{ laborExpense.inputUsed }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Status</p>
  <h6>{{ laborExpense.taskStatus }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Total Hours</p>
  <h6>{{ laborExpense.totalHours }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Rate</p>
  <h6>{{ laborExpense.rate }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Total</p>
  <h6>{{ laborExpense.total }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Farm</p>
  <h6>{{ laborExpense.farm }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Bed</p>
  <h6>{{ laborExpense.bed }}</h6>
  </div>

  <div class="intro-grid-column intro-grid-comment-column">
  <p class="title">Comments</p>
  <h6>{{ laborExpense.comments }}</h6>
  </div>

  </div>
  </div>

  <div class="content-block">

    <FileAttachments :key="`labor-expense-filled-${laborExpense.id}`" v-bind:filled="true" v-bind:section="'task'" v-bind:instance="`${laborExpense.id}`" />

  </div>

  </div>

  </div>
  </section>
 </template>
 
 <script> 
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { defineComponent, inject, ref } from 'vue';

export default defineComponent({
    name: "LaborExpensesFilled",
    components: { FileAttachments },
    setup() {
      const { 
        laborExpense,
        addLaborExpense,
        selectLaborExpense
      } = inject('laborExpenseDetails');

      const editHelperTooltip = ref(false);
      const helperTooltip = ref(false);
      const addHelperTooltip = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const switchEditHelperTooltip = (toggle) => {
        editHelperTooltip.value = toggle;
      }

      const switchAddHelperTooltip = (toggle) => {
        addHelperTooltip.value = toggle;
      }  
      
      return {
        laborExpense,
        addLaborExpense,
        selectLaborExpense,
        editHelperTooltip,
        helperTooltip,
        addHelperTooltip,
        switchHelperTooltip,
        switchEditHelperTooltip,
        switchAddHelperTooltip
      }
    }
  })
 </script>
 
 <style lang="scss" scoped>
.task-type {
  .fw-semibold {
    &.gray {
      margin-right: 5rem;
      margin-left: 6rem;
    }
  }
  .radio {
    &.regular {
      margin-right: 3rem;
    }
  }
}

.task-category,
.task-type {
  .checkbox {
    label {
      color: hsl(var(--dark-gray)) !important;
      font-size: var(--fs-lg);
      font-family: var(--fw-medium);
    }
  }
  .radio {
    label {
      color: hsl(var(--dark-gray)) !important;
      font-size: var(--fs-lg);
      font-family: var(--fw-medium);
    }
  }
}
.checkbox-header {
  color: hsl(210, 1%, 40%);
}
</style>