<template>
  <section class="section">
    <div class="border-box">

    <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Services</h5>
<div class="icon-group">
<div class="icon green icon-o tooltip"
  @click="addNewService"
  data-tooltip="Add Service"
  v-if="isAuth('add-new-equipment-service')"
>
  <img src="@/assets/images/updated/add-item.png" alt="">
</div>
<div class="icon green icon-o tooltip"
  @click="selectService({...service}, 'edit');"
  data-tooltip="Edit"
  v-if="isAuth('edit-equipment-service')"
>
  <img src="@/assets/images/updated/Edit.png" alt="">
</div>
</div>
</div>

<div class="content-block">
<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Date Purchased</p>
<h6>{{ moment(service.date, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Service Type</p>
<h6>{{service.serviceType}}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Equipment</p>
<h6>{{ service.equipment }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Vendor</p>
<h6>{{ service.vendorName }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Invoice #</p>
<h6>{{ service.invoice }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Amount</p>
<h6>{{ service.amountPaid }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Vendor Contact</p>
<h6>{{ service.vendorContact }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Vendor Phone</p>
<h6>{{ service.vendorPhone }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Warranty Exp</p>
<h6>{{ service.warrantyExp ? moment(service.warrantyExp, 'YYYY-MM-DD').format('MM/DD/YYYY') : '' }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Service Date</p>
<h6>{{ service.serviceDate ? moment(service.serviceDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : '' }}</h6>
</div>

<div class="intro-grid-column intro-grid-comment-column">
<p class="title">Comments</p>
<h6>{{ service.comments }}</h6>
</div>

</div>
</div>

<div class="content-block">

  <FileAttachments :key="`${service.id}-services-attachments-filled`" v-bind:filled="true" v-bind:section="'vendorItem'" v-bind:instance="`${service.id}`" />

</div>

</div>
</div>
</section>
</template>

<script>
import { defineComponent, inject } from 'vue';
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import moment from 'moment';

export default defineComponent({
    name: "ServiceFilled",
    components: { FileAttachments },
    setup() {
      const { isAuth } = inject('mainUI');
      const { service, selectService, addNewService } = inject('serviceDetails');

      return {
        service,
        selectService,
        addNewService,
        moment,
        isAuth
      }
    }
})
</script>

<style lang="scss" scoped></style>