<template>
<section class="section mt-1">
    <div class="border-box">

  <div class="border-box-content">
  
  <div class="content-block d-flex justify-content-between">
  <h5 class="fw-semibold">Farm Profile</h5>
  <!-- <div class="icon-group">
  <div class="icon green icon-o"
    @mouseover="switchAddHelperTooltip(true)"
    @mouseout="switchAddHelperTooltip(false)"
  >
      <img src="@/assets/images/updated/User.png" alt="">
    <div class="add-helper-tooltip" v-if="addHelperTooltip">Add Bed</div>
  </div>
  <div class="icon green icon-o"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
  >
      <img src="@/assets/images/updated/Edit.png" alt="">
    <div class="helper-tooltip" v-if="helperTooltip">Edit Farm</div>
  </div>
  </div> -->
  </div>
  
  <div class="content-block">
  <div class="intro-grid">
  
  <div class="intro-grid-column">
  <p class="title">Farm Name</p>
  <h6>{{ farm.farmName }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Address 1</p>
  <h6>{{ farm.address }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Address 2</p>
  <h6>{{ farm.address2 }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">City</p>
  <h6>{{ farm.city }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">State</p>
  <h6>{{ farm.state }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">ZIP</p>
  <h6>{{ farm.zip }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Country</p>
  <h6>{{ farm.country }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Longtitude</p>
  <h6>{{ farm.longtitude }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Latitude</p>
  <h6>{{ farm.latitude }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Acreage</p>
  <h6>{{ farm.acreage }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Soil Composition</p>
  <h6>{{ farm.soilComposition }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Irrigation Type</p>
  <h6>{{ farm.irrigationType }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Climatic Zone</p>
  <h6>{{ farm.climaticZone }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Fencing</p>
  <h6>{{ farm.fencing }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">IOT Device Type</p>
  <h6>{{ farm.IOTdeviceType }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Primary Crop</p>
  <h6>{{ farm.primaryCrop }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Secondary Crop</p>
  <h6>{{ farm.secondaryCrop }}</h6>
  </div>
  
  </div>
  </div>
    
  <!-- <FarmPlanCropFilled v-if="(crop.sourceType === 'farm') && (crop.sourceId === farm.id || selectedFarmId || selectedFarm.id)" /> -->
  <template v-if="(crop.sourceType === 'farm') && ((crop.sourceId === farm.id) || (crop.sourceId === selectedFarmId) || (crop.sourceId === selectedFarm.id))">
    <div class="content-block section-block" ref="sectionBox">

<div class="content-header border-box-header" ref="boxHeader" @click="toggleSection">
<p class="fw-semibold">Plan Crop</p>
<div class="icon-group">
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle" :class="{revert: !sectionExpanded}">
</div>

  <div class="content-section border-box-content p-0">

    <div class="intro-grid">

      <div class="intro-grid-column">
      <p class="title">Year</p>
      <h6>{{ crop.year }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Season</p>
      <h6>{{ crop.season }}</h6>
      </div>

      <div class="intro-grid-column">
      </div>

      <div class="intro-grid-column">
      </div>

      <div class="intro-grid-column">
      <p class="title">Crop Cycle</p>
      <h6>{{ crop.cropCycle }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Crop Category</p>
      <h6>{{ crop.cropCategory }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Crop</p>
      <h6>{{ crop.crop }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Variety</p>
      <h6>{{ crop.variety }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Date Seed</p>
      <h6>{{ crop.dateSeed }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Harvest Date</p>
      <h6>{{ crop.harvestDate }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Proj. Harvest Amt</p>
      <h6>{{ crop.projHarvestAmount }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Proj. Wastage</p>
      <h6>{{ crop.projWastage }}</h6>
      </div>

    </div>

  </div>
  </div>
  </template>
  
  </div>
  </div>
  </section>
  </template>
  
  <script>
  import { inject, ref, onMounted, watch, provide } from "vue";
  // import FarmPlanCropFilled from "./FarmPlanCropFilled.vue";
  
  export default {
      name: "FarmHistoryFarmProfile",
      setup() {
          // const store = useStore();

          const { plan } = inject('planDetails');

          const farm = ref({});

          const crop = ref({});

          const sectionBox = ref(null);
          const boxHeader = ref(null);
          const sectionHeight = ref(0);
          const sectionExpanded = ref(true);

          const toggleSection = (ev) => {
            if ((ev.target.className !== 'content-header border-box-header') && 
              (ev.target.className !== 'angle') && (ev.target.className !== 'angle revert')) {
              return;
            }
            sectionExpanded.value = !sectionExpanded.value
          }

          watch(sectionExpanded, (newVal) => {
            sectionBox.value.style.height = newVal ? 
              `${sectionHeight.value}px` : 
                `${boxHeader.value.offsetHeight}px`;
                sectionBox.value.style.overflow = newVal ? 'visible' : 'hidden'          })

          const helperTooltip = ref(false);
          const switchHelperTooltip = (toggle) => {
              helperTooltip.value = toggle;
          };
          const addHelperTooltip = ref(false);
          const switchAddHelperTooltip = (toggle) => {
              addHelperTooltip.value = toggle;
          };
          const cropHelperTooltip = ref(false);
          const switchCropHelperTooltip = (toggle) => {
              cropHelperTooltip.value = toggle;
          };
          
          const expanded = ref(true);
          const handleCollapseHeaderActions = (evt) => {
              if ((evt.target.className === "add-bed") ||
                  (evt.target.className === "edit-farm") ||
                  (evt.target.className === "add-crop")) {
                  expanded.value = true;
              }
          };
          onMounted(() => {
            farm.value = plan.farm;
            crop.value = plan.crop;

            setTimeout(() => {
              sectionHeight.value = sectionBox.value.offsetHeight;
              sectionBox.value.style.height = `${sectionBox.value.offsetHeight}px`;
            });
          });
          watch(plan, () => {
            
            farm.value = plan.farm;
            crop.value = plan.crop;

          });

          provide('cropEdition', {
            crop: plan.crop
          });


          return {
              expanded,
              handleCollapseHeaderActions,
              farm,
              crop,
              helperTooltip,
              toggleSection,
              sectionExpanded,
              sectionBox,
              boxHeader,
              switchHelperTooltip,
              addHelperTooltip,
              switchAddHelperTooltip,
              cropHelperTooltip,
              switchCropHelperTooltip
          };
      },
      // components: { FarmPlanCropFilled }
  };
  </script>
  
  <style lang="scss">
    .section {
      .add-helper-tooltip {
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;
  
        background-color: #3C3838;
        color: #fff;
        top: 45px;
        left: -35px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
  
        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 51px;
          background: #3C3838;
          z-index: -1;
        }
      }
      .helper-tooltip {
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;
  
        background-color: #3C3838;
        color: #fff;
        top: 45px;
        left: -35px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
  
        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 51px;
          background: #3C3838;
          z-index: -1;
        }
      }
    }
    .farm-profile-details {
      box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
      .header {
        font-size: 18px;
        color: #28292B;
        margin-left: 8px;
      }
      .filled-farm-section {
        padding: 24px;
        padding-top: 0;
      }
      .header-actions {
        flex-direction: row;
        align-items: center !important;
        .add-bed {
          display: flex;
          text-indent: -9999px;
          mask-position: center;
          margin-left: 10px;
          margin-right: 10px;
          width: 25px;
          height: 25px;
          background-image: url('@/assets/farm_profile/add_bed.png');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          position: relative;
          .add-helper-tooltip {
            width: max-content;
            padding: 0 15px;
            height: 30px;
            position: absolute;
            z-index: 999;
  
            background-color: #3C3838;
            color: #fff;
            top: 40px;
            left: -35px;
            text-indent: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 11px;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
  
            &::after {
              content: " ";
              position: absolute;
              display: block;
              top: -7px;
              border-color: #3C3838;
              border-left: 8px solid hsl(0, 3%, 23%);
              border-bottom: 6px solid #3C3838;
              border-right: 6px solid transparent;
              border-top: 8px solid transparent;
              -webkit-transform: translateX(-50%) rotate(135deg);
              transform: translateX(-50%) rotate(135deg);
              box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
              left: 51px;
              background: #3C3838;
              z-index: -1;
            }
          }
        }
        .edit-farm {
          display: flex;
          text-indent: -9999px;
          mask-position: center;
          margin-left: auto;
          width: 25px;
          height: 25px;
          background-image: url('@/assets/profile/edit_button.png');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          position: relative;
          .helper-tooltip {
            width: max-content;
            padding: 0 15px;
            height: 30px;
            position: absolute;
            z-index: 999;
  
            background-color: #3C3838;
            color: #fff;
            top: 40px;
            left: -35px;
            text-indent: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 11px;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
  
            &::after {
              content: " ";
              position: absolute;
              display: block;
              top: -7px;
              border-color: #3C3838;
              border-left: 8px solid hsl(0, 3%, 23%);
              border-bottom: 6px solid #3C3838;
              border-right: 6px solid transparent;
              border-top: 8px solid transparent;
              -webkit-transform: translateX(-50%) rotate(135deg);
              transform: translateX(-50%) rotate(135deg);
              box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
              left: 51px;
              background: #3C3838;
              z-index: -1;
            }
          }
        }
        .add-crop {
          display: flex;
          text-indent: -9999px;
          mask-position: center;
          margin-left: auto;
          width: 25px;
          height: 25px;
          background-image: url('@/assets/farm_profile/add_crop_button.svg');
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          position: relative;
          .crop-helper-tooltip {
            width: max-content;
            padding: 0 15px;
            height: 30px;
            position: absolute;
            z-index: 999;
  
            background-color: #3C3838;
            color: #fff;
            top: 40px;
            left: -30px;
            text-indent: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 11px;
            font-family: 'Inter', sans-serif;
            font-weight: 400;
  
            &::after {
              content: " ";
              position: absolute;
              display: block;
              top: -7px;
              border-color: #3C3838;
              border-left: 8px solid hsl(0, 3%, 23%);
              border-bottom: 6px solid #3C3838;
              border-right: 6px solid transparent;
              border-top: 8px solid transparent;
              -webkit-transform: translateX(-50%) rotate(135deg);
              transform: translateX(-50%) rotate(135deg);
              box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
              left: 41px;
              background: #3C3838;
              z-index: -1;
            }
          }
        }
      }
      .row {
        justify-content: space-between;
        height: 70px;
        // margin-left: 8px;
        &.left-aligned-row {
          margin-left: 15px;
        }
        &.top-section-row {
          .field {
            margin-top: 16px;
          }
        }
        .field {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 165px;
          margin-top: 20px;
          .label {
            color: rgba(40,41,43,0.6);
            font-size: 16px;
            font-weight: 600;
          }
          .field-value {
            color: rgba(40,41,43,1);
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  
  @media screen and (max-width: 1024px) {
    .farm-profile-details {
      .row {
        height: auto;
      }
    }
  }
  </style>