<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Employee Compensation <template v-if="compensation.employeeType === 'parttime'"> - Part Time</template><template v-if="compensation.employeeType === 'salary'"> - Salary</template></h5>
<div class="icon-group">
<!-- <a href="javascript:void();" class="icon green icon-o camera-plus"><img src="@/assets/images/camera-plus.svg" alt=""></a> -->
</div>
</div>

<div class="content-block employee-type">
<div class="checkbox-group">
<p class="fw-semibold gray">Employee Type</p>
<p v-if="isDialogView && !compensation.employeeType" class="form-info red" style="position: absolute; margin-top: 3.5rem;">Select one</p>
<div class="radio"><input type="radio" name="employeeType" id="salaried" value="salary" v-model="compensation.employeeType"><label for="salaried">Salary</label></div>
<div class="radio"><input type="radio" name="employeeType" id="partTime" value="parttime" v-model="compensation.employeeType"><label for="partTime">Part Time</label></div>
<div class="radio"><input type="radio" name="employeeType" id="otherType" value="other" v-model="compensation.employeeType"><label for="otherType">Other</label></div>
</div>
</div>

<template v-if="(isDialogView && compensation.employeeType) || (!isDialogView)">

<div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3" v-if="compensation.employeeType !== 'other'">
<div class="form-item">
<p class="form-label" :class="{active: focused.employeeTitle || (compensation.employeeTitle.length > 0)}">Employee Title</p>
<input type="text" class="form-control"
 v-model="compensation.employeeTitle"
  @focus="() => {
    activated.employeeTitle = true;
    focused.employeeTitle = true;
  }"
  @blur="() => {
    focused.employeeTitle = false;
    promptRepeated();
  }"
  :placeholder="compensation.employeeType === 'salary' ? 'E.g. Supervisor' : 'E.g. Temp Worker'"
>
 <p v-if="activated.employeeTitle && compensation.employeeTitle.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.employeeTitle && compensation.employeeTitle.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-if="compensation.employeeType !== 'other'">
<div class="form-item">
<p class="form-label" :class="{active: focused.grade || (compensation.grade.length > 0)}">Grade</p>
<div class="info-icon-wrap">
  <input type="text" class="form-control"
  v-model="compensation.grade"
    @focus="() => {
      activated.grade = true;
      focused.grade = true;
    }"
    @blur="() => {
      focused.grade = false;
      promptRepeated();
    }"
    placeholder="E.g. 1"
  />
 <p v-if="activated.grade && compensation.grade.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.grade && compensation.grade.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
 <div class="grade-button">
    <span class="info-icon"  @mouseover="switchGradeTooltip(true)" @mouseout="switchGradeTooltip(false)">
      <!-- <img src="@/assets/images/question.svg" alt=""> -->
      <img src="@/assets/images/question-mark.png" alt="">
    </span>
    <div style="position: relative;margin-left: auto; margin-right: 0;width: 1px;height: 1px;">
      <div class="grade-tooltip" v-if="gradeTooltipIsVisible">
        Assigning a grade for Salary and Part time employees, 
        <!-- <br /> -->
        helps in calculating employee expenses and managing employee compensation
      </div>
    </div>
  </div>
</div>
</div>
</div>

<div class="col-3" v-if="compensation.employeeType === 'salary'">
<div class="form-item">
<p class="form-label" :class="{active: focused.annualSalary || (compensation.annualSalary.length > 0)}">Annual Salary</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control"
      v-model="compensation.annualSalary"
      @focus="(ev) => {
        formatAnnualValue(ev, 'annualSalary', true);
        activated.annualSalary = true;
        focused.annualSalary = true;
      }"
      @blur="(ev) => {
        formatAnnualValue(ev, 'annualSalary', false);
        focused.annualSalary = false;
      }"
      placeholder="E.g. $35,000.00"
    />
  </div>
 <p v-if="activated.annualSalary && compensation.annualSalary.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.annualSalary && compensation.annualSalary.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-if="compensation.employeeType === 'salary'">
<div class="form-item">
<p class="form-label" :class="{active: focused.medicalBenefits || (compensation.medicalBenefits.length > 0)}">Medical Benefits</p>
<input type="text" class="form-control"
 v-model="compensation.medicalBenefits"
  @focus="() => {
    activated.medicalBenefits = true;
    focused.medicalBenefits = true;
  }"
  @blur="() => {
    focused.medicalBenefits = false;
  }"
  placeholder="E.g. Full Coverage"
>
 <p v-if="activated.medicalBenefits && compensation.medicalBenefits.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.medicalBenefits && compensation.medicalBenefits.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-if="compensation.employeeType === 'salary'">
<div class="form-item">
<p class="form-label" :class="{active: focused.bonus || (compensation.bonus.length > 0)}">Bonus</p>
<div class="dollar-prefix">
  <span>$</span>
  <input type="text" class="form-control"
    v-model="compensation.bonus"
    @focus="() => {
      formatBonusValue(true);
      activated.bonus = true;
      focused.bonus = true;
    }"
    @blur="() => {
      formatBonusValue(false);
      focused.bonus = false;
    }"
    placeholder="E.g. $3000.00"
  />
</div>
 <p v-if="activated.bonus && compensation.bonus.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.bonus && compensation.bonus.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-if="compensation.employeeType === 'salary'">
<div class="form-item">
<p class="form-label" :class="{active: focused.other || (compensation.other.length > 0)}">Other</p>
<input type="text" class="form-control"
 v-model="compensation.other"
  @focus="() => {
    activated.other = true;
    focused.other = true;
  }"
  @blur="() => {
    focused.other = false;
  }"
  placeholder="E.g. 2 Week Annual Vacation"
>
 <p v-if="activated.other && compensation.other.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.other && compensation.other.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-if="compensation.employeeType === 'parttime'">
<div class="form-item">
<p class="form-label" :class="{active: focused.hourlyRate || (compensation.hourlyRate.length > 0)}">Hourly Rate</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control"
      v-model="compensation.hourlyRate"
      @focus="(ev) => {
        formatValue(ev, 'hourlyRate', true);
        activated.hourlyRate = true;
        focused.hourlyRate = true;
      }"
      @blur="(ev) => {
        formatValue(ev, 'hourlyRate', false);
        focused.hourlyRate = false;
      }"
      placeholder="E.g. $20.00"
    />
  </div>
 <p v-if="activated.hourlyRate && compensation.hourlyRate.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.hourlyRate && compensation.hourlyRate.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-if="compensation.employeeType === 'parttime'">
<div class="form-item">
<p class="form-label" :class="{active: focused.overTimeRate || (compensation.overTimeRate.length > 0)}">Over Time Rate (Hourly)</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input type="text" class="form-control"
      v-model="compensation.overTimeRate"
      @focus="(ev) => {
        formatValue(ev, 'overTimeRate', true);
        activated.overTimeRate = true;
        focused.overTimeRate = true;
      }"
      @blur="(ev) => {
        formatValue(ev, 'overTimeRate', false);
        focused.overTimeRate = false;
      }"
      placeholder="E.g. $25.00"
    />
  </div>
 <p v-if="activated.overTimeRate && compensation.overTimeRate.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.overTimeRate && compensation.overTimeRate.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-if="compensation.employeeType === 'other'">
<div class="form-item">
<p class="form-label" :class="{active: focused.employeeTitle || (compensation.employeeTitle.length > 0)}">Title</p>
  <!-- <select class="form-control" 
    v-if="!manualEmployeeTitle"
    v-model="compensation.employeeTitle"
    @click="() => {
      activated.employeeTitle = true;
      focused.employeeTitle = true;
    }"
    @change="() => {
      focused.employeeTitle = false;
    }"
  >
    <option>CEO</option>
    <option>Owner</option>
    <option>Other</option>
  </select> -->
  <template v-if="!manualEmployeeTitle">
    <DropDownList
      :mainObject="'compensation'"
      :mainProperty="'employeeTitle'"
      :injectionName="'compensationDetails'"
      :optionKey="'employee-title-option-employee-compensation'"
      :optionsList="['Owner', 'Partner', ...compensations.map(c => c.employeeTitle), 'Other']"
      :optionProperty="null"
      :optionPropertyTwo="null"
      :defaultSelected="compensation.employeeTitle"
      @click="() => {
        activated.employeeTitle = true;
      }"
    />
  </template>
  <div v-else class="dropdown-open-back-container">
    <input type="text" class="form-control"
      ref="employeeTitleField"
      v-model="compensation.employeeTitle"
      @focus="() => {
        activated.employeeTitle = true;
        focused.employeeTitle = true;
      }"
      @blur="() => {
        focused.employeeTitle = false;
        promptRepeatedOther();
      }"
      placeholder="E.g. Owner"
    >
    <div class="dropdown-open-back" @click="() => {
      manualEmployeeTitle = false
    }"></div>
  </div>
 <p v-if="activated.employeeTitle && compensation.employeeTitle.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3" v-if="compensation.employeeType === 'other'">
<div class="form-item">
<p class="form-label" :class="{active: focused.ownershipType || (compensation.ownershipType.length > 0)}">Ownership Type</p>
  <!-- <select class="form-control" 
    v-if="!manualOwnershipType"
    v-model="compensation.ownershipType"
    @click="() => {
      activated.ownershipType = true;
      focused.ownershipType = true;
    }"
    @change="() => {
      focused.ownershipType = false;
    }"
  >
    <option>Sole</option>
    <option>Partner</option>
    <option>Other</option>
  </select> -->
  <template v-if="!manualOwnershipType">
    <DropDownList
      :mainObject="'compensation'"
      :mainProperty="'ownershipType'"
      :injectionName="'compensationDetails'"
      :optionKey="'ownership-option-employee-compensation'"
      :optionsList="[
        'Sole Proprietor', 
        'Partnership', 
        'Limited Partnership', 
        'Limited Liability Corp', 
        'Corporation',
        'Lease', 
        'Other'
      ]"
      :optionProperty="null"
      :optionPropertyTwo="null"
      :defaultSelected="compensation.ownershipType"
      @click="() => {
        activated.ownershipType = true;
      }"
    />
  </template>
  <div v-else class="dropdown-open-back-container">
  <input type="text" class="form-control"
    v-model="compensation.ownershipType"
    ref="ownershipTypeField"
    @focus="() => {
      activated.ownershipType = true;
      focused.ownershipType = true;
    }"
    @blur="() => {
      focused.ownershipType = false;
      promptRepeatedOther();
    }"
    placeholder="E.g. Sole Proprietorship"
  >
  <div class="dropdown-open-back" @click="() => {
      manualOwnershipType = false
    }"></div>
  </div>
 <p v-if="activated.ownershipType && compensation.ownershipType.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3" v-if="compensation.employeeType === 'other'">
<div class="form-item">
<p class="form-label" :class="{active: focused.ownershipShare || (compensation.ownershipShare.length > 0)}">Ownership %</p>
  <input type="text" class="form-control"
    v-model="compensation.ownershipShare"
    @focus="() => {
      activated.ownershipShare = true;
      focused.ownershipShare = true;
    }"
    @blur="() => {
      focused.ownershipShare = false;
    }"
    placeholder="E.g. 100"
  />
 <p v-if="activated.ownershipShare && compensation.ownershipShare.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-12">
<div class="form-item">
<p class="form-label" :class="{active: focused.qualificationExperienceRequirements || (compensation.qualificationExperienceRequirements.length > 0)}">Qualification and Experience Requirements</p>
<textarea class="form-control"
 v-model="compensation.qualificationExperienceRequirements"
  @focus="() => {
    activated.qualificationExperienceRequirements = true;
    focused.qualificationExperienceRequirements = true;
  }"
  @blur="() => {
    focused.qualificationExperienceRequirements = false;
  }"
  placeholder="E.g. Must have farming experience"
></textarea>
 <p v-if="activated.qualificationExperienceRequirements && compensation.qualificationExperienceRequirements.length > 500" class="form-info red text-end">Please use maximum 500 characters</p>
</div>
</div>


<div class="col-12">
<div class="form-item">
<p class="form-label" :class="{active: focused.comments || (compensation.comments.length > 0)}">Comments</p>
<textarea class="form-control"
 v-model="compensation.comments"
  @focus="() => {
    activated.comments = true;
    focused.comments = true;
  }"
  @blur="() => {
    focused.comments = false;
  }"
></textarea>
 <p v-if="activated.comments && compensation.comments.length > 500" class="form-info red text-end">Please use maximum 500 characters</p>
</div>
</div>

</div>
</div>
</div>

</div>

<div class="content-block">
  <FileAttachments :key="`form-${compensation.id}`" v-bind:section="'employeeCompensation'" v-bind:instance="`${compensation.id}`" />
</div>

</template>

<div class="content-block">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
</div>
</div>
</div>

</div>

<div :class="{notificationInsideDialog: isDialogView}" v-if="popUps.repeatedGrade.active">
  <UserNotification v-bind:messageText="popUps.repeatedGrade.message" />
</div>

<div :class="{notificationInsideDialog: isDialogView}" v-if="popUps.repeatedTitle.active">
  <UserNotification v-bind:messageText="popUps.repeatedTitle.message" />
</div>

<div :class="{notificationInsideDialog: isDialogView}" v-if="popUps.repeatedTitleGrade.active">
  <UserNotification v-bind:messageText="popUps.repeatedTitleGrade.message" />
</div>

<div :class="{notificationInsideDialog: isDialogView}" v-if="popUps.repeatedTitleOwnership.active">
  <UserNotification v-bind:messageText="popUps.repeatedTitleOwnership.message" />
</div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { reactive, inject, ref, defineComponent, onMounted, watch, computed, provide } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import { useRouter } from 'vue-router';
import UserNotification from '@/components/Shared/UserNotification.vue';
import DropDownList from '@/components/Shared/DropDownList.vue';

export default defineComponent({
    name: "EmployeeCompensationForm",
    components: { FileAttachments, UserNotification, DropDownList },
    setup() {
      const store = useStore();
      const router = useRouter();

      const activated = reactive({
        employeeTitle: false,
        grade: false,
        annualSalary: false,
        medicalBenefits: false,
        bonus: false,
        other: false,

        hourlyRate: false,
        overTimeRate: false,

        ownershipType: false,
        ownershipShare: false,

        qualificationExperienceRequirements: false,

        comments: false,
      });

      const focused = reactive({
        employeeTitle: false,
        grade: false,
        annualSalary: false,
        medicalBenefits: false,
        bonus: false,
        other: false,

        hourlyRate: false,
        overTimeRate: false,

        ownershipType: false,
        ownershipShare: false,

        qualificationExperienceRequirements: false,

        comments: false,
      });

      const { 
        compensation, 
        editionMode, 
        selectedCompensation, 
        selectCompensation, 
        defaultCompensation,
        popups,
        isDialogView
      } = inject("compensationDetails");

      let userProfileDetails;

      if (isDialogView.value) {
        userProfileDetails = inject('userProfileDetails');
        // mainDetails
        // gradeFormDialog
      }

      const compensations = computed(() => store.state.compensations);

      const helperTooltip = ref(false);

      const otherSelected = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const gradeTooltipIsVisible = ref(false);

      const switchGradeTooltip = (toggle) => {
        gradeTooltipIsVisible.value = toggle;
      }

      const popUps = reactive({
        repeatedGrade: {
          active: false,
          message: 'Grade already exists, please add a new grade'
        },
        repeatedTitle: {
          active: false,
          message: 'The title exist please choose a different title'
        },
        repeatedTitleGrade: {
          active: false,
          message: 'The title and grade already exist please choose a different title or grade'
        },
        repeatedTitleOwnership: {
          active: false,
          message: 'The title and ownership type already exist please choose a different title or grade'
        }
      })

      const manualEmployeeTitle = ref(false);

      const manualOwnershipType = ref(false);

      const employeeTitleField = ref(null);

      const ownershipTypeField = ref(null);

      const repeatedGrade = ref(false);

      const repeatedTitle = ref(false);

      const repeatedTitleGrade = ref(false);

      const repeatedTitleOwnership = ref(false);

      const submitForm = () => {

        if ((compensation.employeeType !== 'other' && (!compensation.employeeTitle || !compensation.grade)) ||
        (compensation.employeeType === 'other' && (!compensation.employeeTitle || !compensation.ownershipType || !compensation.ownershipShare))) {
          if (!compensation.employeeTitle) activated.employeeTitle = true;
          if (compensation.employeeType !== 'other' && !compensation.grade) activated.grade = true;
          
          if (compensation.employeeType === 'other' && !compensation.ownershipType) activated.ownershipType = true;
          if (compensation.employeeType === 'other' && !compensation.ownershipShare) activated.ownershipShare = true;

          return;
        }

        // if (!compensation.id) {
        //   compensation.id = uuid();
        // }

        const formData = {
          ...compensation
        };
        
        (editionMode.value === "edit") ?
            store.dispatch("editCompensation", { ...formData }) :
            store.dispatch("createCompensation", {...formData});
        console.log(formData);
        (editionMode.value === "create") && closeForm();
        (editionMode.value === "edit") && selectCompensation(compensation, 'represent');


        console.log(formData.employeeType, 'compensation.employeeType');

        if (router.currentRoute.value.params.source) {
          if (formData.employeeType === 'salary') {
            popups.addAdditionalSalary.active = true;
          }

          if (formData.employeeType === 'parttime') {
            popups.addAdditionalPartTime.active = true;
          }
        }
        // if (router.currentRoute.value.params.source === 'user-profile') {
        //   router.push({
        //     name: 'page', 
        //     params: { 
        //       pagename: 'user-profile',
        //       source: 'employee-compensation'
        //     }
        //   })
        // }
        if (isDialogView.value) {
          // popups.addAdditionalTitle.active = true;
          userProfileDetails.gradeFormDialog.value = false;
        }
      }

      const closeForm = () => {
        for (let prop in compensation) {
          compensation[prop] = defaultCompensation[prop];
        }

        for (let prop in activated) {
          activated[prop] = false;
        }
        
        for (let prop in focused) {
          focused[prop] = false;
        }
      }

      const cancelChanges = () => {
        if (editionMode.value === "edit") {
          for (let prop in compensation) {
            compensation[prop] = selectedCompensation[prop];
          }
          editionMode.value = "represent";
        } else {
          for (let prop in compensation) {
            compensation[prop] = defaultCompensation[prop];
          }
          editionMode.value = "";
        }
        if (isDialogView.value) {
          userProfileDetails.gradeFormDialog.value = false;
        }
      };

      const formatValue = (ev, field, active) => {
        const props = field.split('.');

        const val = active ? (ev.target.value ? Number.parseFloat(ev.target.value) : '') : 
            `${ev.target.value}${`${ev.target.value}`.split('.')[1] ? '' : '.00'}`;
  
        if (compensation[props[1]]) {
          compensation[props[0]][props[1]] = val
        } else {
          compensation[props[0]] = val
        }
  
      }

      const formatAnnualValue = (ev, field, active) => {
        const props = field.split('.');

        const val = active ? (ev.target.value ? Number.parseFloat(ev.target.value) : '') : 
            `${ `${ev.target.value}`.split('.')[1] ?
              ev.target.value : 
                `${(ev.target.value.length >= 4) ? 
                  `${ev.target.value.slice(0,-3)},${ev.target.value.slice(-3, ev.target.value.length)}` : 
                  ev.target.value}`
            }${`${ev.target.value}`.split('.')[1] ? '' : '.00'}`;
  
          

        if (compensation[props[1]]) {
          compensation[props[0]][props[1]] = val
        } else {
          compensation[props[0]] = val
        }
  
      }

      const formatBonusValue = (active) => {
        if (active) {
          compensation.bonus = compensation.bonus.split('.')[0]
        } else {
          compensation.bonus = Number.parseInt(compensation.bonus).toFixed(2);
        }
      }

      // const promptGrade = () => {
      //   if (repeatedGrade.value) {
      //     popUps.repeatedGrade.active = true;
      //     compensation.grade = '';
      //     activated.grade = true;
      //     setTimeout(() => {
      //       popUps.repeatedGrade.active = false;
      //     }, 5100);
      //     return;
      //   }
      // }

      // const promptTitle = () => {
      //   if (repeatedTitle.value) {
      //     popUps.repeatedTitle.active = true;
      //     compensation.employeeTitle = '';
      //     activated.employeeTitle = true;
      //     setTimeout(() => {
      //       popUps.repeatedTitle.active = false;
      //     }, 5100);
      //     return;
      //   }
      // }

      const promptRepeated = () => {
        if (repeatedTitleGrade.value) {
          popUps.repeatedTitleGrade.active = true;
          compensation.grade = '';
          activated.grade = true;
          compensation.employeeTitle = '';
          activated.employeeTitle = true;
          setTimeout(() => {
            popUps.repeatedTitleGrade.active = false;
          }, 5100);
          return;
        }
      }

      const promptRepeatedOther = () => {
        if (repeatedTitleOwnership.value) {
          popUps.repeatedTitleOwnership.active = true;
          compensation.ownershipType = '';
          activated.ownershipType = true;
          compensation.employeeTitle = '';
          activated.employeeTitle = true;
          setTimeout(() => {
            popUps.repeatedTitleOwnership.active = false;
          }, 5100);
        }
      }

      provide('otherHandling', {
        otherSelected
      });

      watch(() => ({...compensation}), (newVal, oldVal) => {
        if (!newVal.id) {
          compensation.id = uuid();
        }
        if (newVal.grade !== oldVal.grade) {
          if (newVal.employeeType === 'salary') {
            const currentCompensations = [...compensations.value].filter(currentCompensation => currentCompensation.employeeType === 'salary');
            repeatedGrade.value = currentCompensations.find(currentCompensation => currentCompensation.grade === newVal.grade);
          }

          if (newVal.employeeType === 'parttime') {
            const currentCompensations = [...compensations.value].filter(currentCompensation => (currentCompensation.employeeType === 'parttime') || (currentCompensation.employeeType === 'other'));
            repeatedGrade.value = currentCompensations.find(currentCompensation => currentCompensation.grade === newVal.grade);
          }
        }

        repeatedTitle.value = [...compensations.value].find(currentCompensation => (currentCompensation.employeeTitle === newVal.employeeTitle) && (currentCompensation.id !== newVal.id));

        repeatedTitleGrade.value = [...compensations.value].find(currentCompensation => ((currentCompensation.grade === newVal.grade) && (currentCompensation.employeeTitle === newVal.employeeTitle)))

        repeatedTitleOwnership.value = [...compensations.value].find(currentCompensation => ((currentCompensation.ownershipType === newVal.ownershipType) && (currentCompensation.employeeTitle === newVal.employeeTitle)))

        /*
        if (newVal.employeeType === 'salary') {
          const currentCompensations = [...compensations.value].filter(currentCompensation => (currentCompensation.employeeType === 'salary'));
          repeatedTitle.value = currentCompensations.find(currentCompensation => currentCompensation.employeeTitle === newVal.employeeTitle);
        }

        if (newVal.employeeType === 'parttime') {
          const currentCompensations = [...compensations.value].filter(currentCompensation => (currentCompensation.employeeType === 'parttime') || (currentCompensation.employeeType === 'other'));
          repeatedTitle.value = currentCompensations.find(currentCompensation => currentCompensation.employeeTitle === newVal.employeeTitle);
        }

        if (newVal.employeeType === 'other') {
          const currentCompensations = [...compensations.value].filter(currentCompensation => (currentCompensation.employeeType === 'parttime') || (currentCompensation.employeeType === 'other'));
          repeatedTitle.value = currentCompensations.find(currentCompensation => currentCompensation.employeeTitle === newVal.employeeTitle);
        }
        */


        if (newVal.employeeType === 'salary') {
          if (isDialogView.value) {
            userProfileDetails.mainDetails.employeeType = 'salary'
          }
        }

        if (newVal.employeeType === 'parttime') {
          if (isDialogView.value) {
            userProfileDetails.mainDetails.employeeType = 'parttime'
          }
        }

        if (newVal.employeeType === 'other') {
          if (isDialogView.value) {
            userProfileDetails.mainDetails.employeeType = 'other'
          }

          if ((!manualEmployeeTitle.value && (newVal.employeeTitle !== oldVal.employeeTitle)) ||
            (!manualOwnershipType.value && (newVal.ownershipType !== oldVal.ownershipType))
          ) {
            promptRepeatedOther();
          }
        }

        if (newVal.employeeTitle) {
          if (isDialogView.value) {
            userProfileDetails.mainDetails.title = newVal.employeeTitle;
          }
        }

        if (newVal.employeeType !== oldVal.employeeType) {
          for (let prop in compensation) {
            if (prop !== 'employeeType') {
              compensation[prop] = defaultCompensation[prop];
            }
          }
        }
      })

      watch(() => (compensation.employeeTitle), (newVal) => {
        if (newVal === 'Other') {
          compensation.employeeTitle = '';
          manualEmployeeTitle.value = true;
          otherSelected.value = true;
          setTimeout(() => {
            employeeTitleField.value.focus();
          }, 10)
        }
        // if (!manualEmployeeTitle.value) {
        //   promptRepeated();
        // }
      });

      watch(() => (compensation.ownershipType), (newVal) => {
        if (newVal === 'Other') {
          compensation.ownershipType = '';
          manualOwnershipType.value = true;
          otherSelected.value = true;
          setTimeout(() => {
            ownershipTypeField.value.focus();
          }, 10)
        }
      });

      onMounted(() => {
        // if (compensation.id.length <= 0) {
        //   compensation.id = uuid();
        // }
        if (!compensation.id) {
          console.log(compensation.id, 'compensation.id');
          compensation.id = uuid();
        }
      });

      return {
        activated,
        focused,
        compensation,
        submitForm,
        cancelChanges,
        helperTooltip,
        switchHelperTooltip,
        gradeTooltipIsVisible,
        switchGradeTooltip,
        formatValue,
        formatAnnualValue,
        formatBonusValue,
        popUps,
        isDialogView,
        manualEmployeeTitle,
        manualOwnershipType,
        employeeTitleField,
        ownershipTypeField,
        // promptGrade,
        // promptTitle
        promptRepeated,
        promptRepeatedOther,
        compensations
      }
    }
})
</script>

<style lang="scss" scoped>
  .grade-tooltip {
    position: absolute;
    width: 250px;//max-content;
    height: max-content;
    // top: 40px;
    // left: -40px;
    // left: 2rem;
    left: -150px;
    // top: 5rem;
    top: 5px;
    z-index: 999;
    // padding: 15px;
    padding: 0.5rem;
    // background-color: #3C3838;
    // background: hsl(207, 11%, 41%);
    background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    // border: 1px solid #28292B;
    // border-radius: 11px;
    font-size: 1.4rem;
    // font-weight: 500;
    font-weight: var(--fw-semibold);
    font-family: 'Gilroy';
    // color: #fff;
    color: hsl(var(--white));
    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: -7px;
      // border-color: #3C3838;
      // background: hsl(207, 11%, 41%);
      background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
      // border-left: 8px solid hsl(0, 3%, 23%);
      // border-bottom: 6px solid #3C3838;
      // border-left: 8px solid hsl(207, 11%, 41%);
      // border-bottom: 6px solid hsl(207, 11%, 41%);
      border-left: 8px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
      border-bottom: 6px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      // left: 17.5rem;
      left: 125px;
      // background: #3C3838;
      z-index: -1;
    }
  }

  .employee-type {
    label {
      font-size: var(--fs-lg);
      font-family: var(--fw-medium);
      color: hsl(var(--dark-gray));
    }
  }

  .form-modal {
    .employee-type {
      label {
        font-size: 14px;
      }
    }
  }

  .notificationInsideDialog {
    .submit-popup-wrapper {
      width: 100%;
      height: 100%;
    }
  }
</style>