const isAuthorized = (user, permission) => {
  console.log(user, 'user', permission);
  return (
    //if user is admin or super admin
    user?.role?.key === "super_admin" ||
    user?.role?.key === "admin" ||
    user?.mainDetails?.userRole === "super_admin" ||
    user?.mainDetails?.userRole === "admin" ||
    //if have necessary permission
    (user?.role?.permissions && user?.role?.permissions.some((p) => p.key === permission))
  ) ? true : false
}

export default isAuthorized;