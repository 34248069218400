<template>

<section class="section">
<div class="border-box">
<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Farm Profile</h5>
<div class="icon-group">

<div class="icon green icon-o tooltip" 
  v-if="isAuth('add-crop') && (bedsList.length <= 0) && (farms.length > 0)"
  @click="() => planNewCrop()"
  data-tooltip="Plan Crop"
>
  <img src="@/assets/images/updated/plan-crop.png" alt="">
</div>

<div class="icon green icon-o tooltip"
  v-if="isAuth('add-bed') && (farms.length > 0)"
  @click="addNewBed"
  @mouseover="switchAddHelperTooltip(true)"
  @mouseout="switchAddHelperTooltip(false)"
  data-tooltip="Add Bed"
>
  <!-- <img src="@/assets/images/user-plus.svg" alt=""> -->
  <img src="@/assets/images/updated/add-bed.png" alt="">
  <!-- <div class="add-helper-tooltip" v-if="addHelperTooltip">Add Bed</div> -->
</div>

<div class="icon green icon-o tooltip"
  v-if="isAuth('edit-farm')"
  @click="editFarm"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
  data-tooltip="Edit Farm"
>
  <!-- <img src="@/assets/images/edit.svg" alt=""> -->
    <img src="@/assets/images/updated/Edit.png" alt="">
  <!-- <div class="helper-tooltip" v-if="helperTooltip">Edit Farm</div> -->
</div>
</div>
</div>

<div class="content-block">
<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Farm Name</p>
<h6>{{ farm.farmName }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Address 1</p>
<h6>{{ farm.address }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Address 2</p>
<h6>{{ farm.address2 }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">City</p>
<h6>{{ farm.city }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">State</p>
<h6>{{ farm.state }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">ZIP</p>
<h6>{{ farm.zip }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Country</p>
<h6>{{ farm.country }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Latitude</p>
<h6>{{ farm.latitude }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Longtitude</p>
<h6>{{ farm.longtitude }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Acreage</p>
<h6>{{ farm.acreage }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Soil Type</p>
<h6>{{ farm.soilComposition }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Irrigation Type</p>
<h6>{{ farm.irrigationType }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Climatic Zone</p>
<h6>{{ farm.climaticZone }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Fencing</p>
<h6>{{ farm.fencing }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">IOT Device Type</p>
<h6>{{ farm.IOTdeviceType }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Primary Crop</p>
<h6>{{ farm.primaryCrop }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Secondary Crop</p>
<h6>{{ farm.secondaryCrop }}</h6>
</div>

</div>
</div>

<FileAttachments v-if="isAuth('farm-attachements')" :key="`farm-${farm.id}`" v-bind:filled="true" v-bind:section="'farm'" v-bind:instance="`${farm.id}`.replaceAll(' ', '')" />

</div>
</div>
</section>

<dev ref="farmPlanCropFilledSection"></dev>
<FarmPlanCropFilled v-if="(crop.sourceType === 'farm') && ((crop.sourceId === farm.id) || (crop.sourceId === selectedFarmId) || (crop.sourceId === selectedFarm.id))" />

<!-- <FarmPlanCropList :key="`farm-croplist-${farm.id}`" v-if="crops.length > 0" /> -->

  <div class="removal-popup" v-if="popUps.farmHasCrop.active">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveCropRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">{{ popUps.farmHasCrop.message }}</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveCropRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveCropRemoval(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>
</template>

<script>
import { inject, onMounted, ref, watch, computed, reactive } from "vue";
import { useStore } from "vuex";
// import { useRouter } from 'vue-router';
import FileAttachments from "@/components/Shared/FileAttachments.vue";
import FarmPlanCropFilled from "./FarmPlanCropFilled.vue";
// import FarmPlanCropList from "./FarmPlanCropList.vue";
import _ from "lodash";

export default {
    name: "FarmProfileFilled",
    setup() {
        const store = useStore();
        // const router = useRouter();
        const { farm, selectedFarm } = inject("farmEdition");
        const { selectedFarmId, openForm } = inject("formMode");
        const { createBeds, editionMode, defaultBed, selectBed, bedsCreation, navigateToMapForm } = inject("bedsCreation");
        const { crop, selectCrop, defaultCrop, newCropCreation } = inject('cropEdition');
        const { isAuth } = inject("mainUI");

        const crops = ref(() => store.state.crops.filter(crp => ((crp.sourceType === 'farm') && (crp.sourceId === farm.id) || (crp.sourceId === selectedFarmId) || (crp.sourceId === selectedFarm.id))));
        const bedsList = ref([]);

        const farms = computed(() => store.state.farms);

        const popUps = reactive({
            farmHasCrop: {
                active: false,
                message: 'The Plan crop associated with this farm will be deleted if you plan to add a bed'
            }
        });

        const farmPlanCropFilledSection = ref(null);

        const editFarm = () => openForm(farm.id);
        const helperTooltip = ref(false);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const addHelperTooltip = ref(false);
        const switchAddHelperTooltip = (toggle) => {
            addHelperTooltip.value = toggle;
        };
        const cropHelperTooltip = ref(false);
        const switchCropHelperTooltip = (toggle) => {
            cropHelperTooltip.value = toggle;
        };
        // const planNewCrop = () => {
        //     router.push({
        //         name: "farm",
        //         params: {
        //             pagename: "plan-crop",
        //             farm: selectedFarm.farmName ? selectedFarm.id : selectedFarmId
        //         }
        //     });
        // };
        const planNewCrop = () => {
          selectCrop({
            ...defaultCrop,
            sourceType: 'farm',
            sourceId: selectedFarm.id
          }, 'edit');
          newCropCreation.value = true;
        }

        const approveCropRemoval = (approved) => {
            if (approved) {
                editionMode.value = 'create';
                createBeds();
                const farmCrops = crops.value.filter(crp => ((crp.sourceType === 'farm') && ((crp.sourceId === farm.id) || (crp.sourceId === selectedFarmId) || (crp.sourceId === selectedFarm.id))));
                farmCrops.forEach(farmCrop => {
                    const cropId = farmCrop.id;
                    const cropIndex = crops.value.findIndex(crp => crp.id === cropId);
                    store.dispatch('removeCrop', { cropId, cropIndex });
                });
                selectCrop({
                    ...defaultCrop,
                    sourceType: 'bed'
                });
                addNewBed();
            }
            else {
                bedsCreation.value = false;
                // setTimeout(() => {
                //     document.getElementsByTagName('main')[0].scroll({
                //         top: bedForm?.value?.offsetTop - 100,
                //         left: 0,
                //         behavior: 'smooth'
                //     });
                // });
            }
            popUps.farmHasCrop.active = false;
        };

        const addNewBed = () => {
          // selectBed({...defaultBed});
          // editionMode.value = "create";
          // createBeds();

          const farmCrops = crops.value.filter(crp => ((crp.sourceType === 'farm') && ((crp.sourceId === farm.id) || (crp.sourceId === selectedFarmId) || (crp.sourceId === selectedFarm.id))));
          if (farmCrops.length > 0) {
              popUps.farmHasCrop.active = true;
          }
          else {
              selectBed({
                  ...defaultBed,
                  farmName: selectedFarm.farmName || farm.farmName,
                  sourceType: 'bed',
                  id: ''
              }, 'create');
              selectCrop({
                  ...defaultCrop,
                  sourceType: 'bed'
              });
              editionMode.value = 'create';
              createBeds();
              crop.sourceType = 'bed';
          }
          navigateToMapForm()
        };
        const expanded = ref(true);
        const handleCollapseHeaderActions = (evt) => {
            if ((evt.target.className === "add-bed") ||
                (evt.target.className === "edit-farm") ||
                (evt.target.className === "add-crop")) {
                expanded.value = true;
            }
        };
        
        watch(store.state.farms, () => {
            if (!farm.id) {
                store.dispatch("getFarm", { selectedFarmId: store.state.farms[0].id });
            }
        });

        watch(crops, (newVal, oldVal) => {
          console.log('crops value was changed');
          if (newVal.length < oldVal.length) {
            console.log('it is entering');
            (newVal.length > 0) ? selectCrop({...crops.value[0]}) : selectCrop({...defaultCrop})
          }
        });

        watch(store.state.crops, (newVal) => {
          crops.value = [...newVal.filter(crp => ((crp.sourceType === 'farm') && ((crp.sourceId === farm.id) || (crp.sourceId === selectedFarmId) || (crp.sourceId === selectedFarm.id))))];
        });

        watch(() => _.cloneDeep(store.state.farms), (newVal) => {
          const currentFarm = newVal.find(f => f.id === selectedFarm.id);
          if (currentFarm) {
            bedsList.value = [...currentFarm.beds];
          }
        })

        watch(() => ({...crop}), (newVal, oldVal) => {
          if (crops.value.length > 0) {
            if ((newVal.id && oldVal.id) && (newVal.id !== oldVal.id)) {
              setTimeout(() => {
                document.getElementsByTagName('main')[0].scroll({
                  top: farmPlanCropFilledSection?.value?.offsetTop,
                  left: 0,
                  behavior: 'smooth'
                })
              })
            }
          }
        })

        onMounted(() => {
            store.dispatch("getFarm", { selectedFarmId: selectedFarmId.value });

            console.log(store.state.farms, farm, selectedFarm, selectedFarmId.value);

            if (bedsList.value.length === 0) {
              bedsList.value = [...selectedFarm.beds];
            }

            crops.value = [...store.state.crops.filter(crp => ((crp.sourceType === 'farm') && ((crp.sourceId === farm.id) || (crp.sourceId === selectedFarmId) || (crp.sourceId === selectedFarm.id))))];
        });

        return {
            expanded,
            handleCollapseHeaderActions,
            farm,
            editFarm,
            helperTooltip,
            switchHelperTooltip,
            addHelperTooltip,
            switchAddHelperTooltip,
            cropHelperTooltip,
            switchCropHelperTooltip,
            planNewCrop,
            addNewBed,
            isAuth,
            selectedFarm,
            selectedFarmId,
            crop,
            crops,
            bedsList,
            farms,
            approveCropRemoval,
            popUps,
            farmPlanCropFilledSection
        };
    },
    components: { 
      FileAttachments, 
      FarmPlanCropFilled, 
      // FarmPlanCropList 
    }
};
</script>

<style lang="scss">
  .section {
    .add-helper-tooltip {
      width: max-content;
      padding: 0 15px;
      height: 30px;
      position: absolute;
      z-index: 999;

      background-color: #3C3838;
      color: #fff;
      top: 45px;
      left: -35px;
      text-indent: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 11px;
      font-family: 'Inter', sans-serif;
      font-weight: 400;

      &::after {
        content: " ";
        position: absolute;
        display: block;
        top: -7px;
        border-color: #3C3838;
        border-left: 8px solid hsl(0, 3%, 23%);
        border-bottom: 6px solid #3C3838;
        border-right: 6px solid transparent;
        border-top: 8px solid transparent;
        -webkit-transform: translateX(-50%) rotate(135deg);
        transform: translateX(-50%) rotate(135deg);
        box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
        left: 51px;
        background: #3C3838;
        z-index: -1;
      }
    }
    .helper-tooltip {
      width: max-content;
      padding: 0 15px;
      height: 30px;
      position: absolute;
      z-index: 999;

      background-color: #3C3838;
      color: #fff;
      top: 45px;
      left: -35px;
      text-indent: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 11px;
      font-family: 'Inter', sans-serif;
      font-weight: 400;

      &::after {
        content: " ";
        position: absolute;
        display: block;
        top: -7px;
        border-color: #3C3838;
        border-left: 8px solid hsl(0, 3%, 23%);
        border-bottom: 6px solid #3C3838;
        border-right: 6px solid transparent;
        border-top: 8px solid transparent;
        -webkit-transform: translateX(-50%) rotate(135deg);
        transform: translateX(-50%) rotate(135deg);
        box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
        left: 51px;
        background: #3C3838;
        z-index: -1;
      }
    }
  }
  .farm-profile-details {
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
    .header {
      font-size: 18px;
      color: #28292B;
      margin-left: 8px;
    }
    .filled-farm-section {
      padding: 24px;
      padding-top: 0;
    }
    .header-actions {
      flex-direction: row;
      align-items: center !important;
      .add-bed {
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: 10px;
        margin-right: 10px;
        width: 25px;
        height: 25px;
        background-image: url('@/assets/farm_profile/add_bed.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        position: relative;
        .add-helper-tooltip {
          width: max-content;
          padding: 0 15px;
          height: 30px;
          position: absolute;
          z-index: 999;

          background-color: #3C3838;
          color: #fff;
          top: 40px;
          left: -35px;
          text-indent: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 11px;
          font-family: 'Inter', sans-serif;
          font-weight: 400;

          &::after {
            content: " ";
            position: absolute;
            display: block;
            top: -7px;
            border-color: #3C3838;
            border-left: 8px solid hsl(0, 3%, 23%);
            border-bottom: 6px solid #3C3838;
            border-right: 6px solid transparent;
            border-top: 8px solid transparent;
            -webkit-transform: translateX(-50%) rotate(135deg);
            transform: translateX(-50%) rotate(135deg);
            box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
            left: 51px;
            background: #3C3838;
            z-index: -1;
          }
        }
      }
      .edit-farm {
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: auto;
        width: 25px;
        height: 25px;
        background-image: url('@/assets/profile/edit_button.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        position: relative;
        .helper-tooltip {
          width: max-content;
          padding: 0 15px;
          height: 30px;
          position: absolute;
          z-index: 999;

          background-color: #3C3838;
          color: #fff;
          top: 40px;
          left: -35px;
          text-indent: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 11px;
          font-family: 'Inter', sans-serif;
          font-weight: 400;

          &::after {
            content: " ";
            position: absolute;
            display: block;
            top: -7px;
            border-color: #3C3838;
            border-left: 8px solid hsl(0, 3%, 23%);
            border-bottom: 6px solid #3C3838;
            border-right: 6px solid transparent;
            border-top: 8px solid transparent;
            -webkit-transform: translateX(-50%) rotate(135deg);
            transform: translateX(-50%) rotate(135deg);
            box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
            left: 51px;
            background: #3C3838;
            z-index: -1;
          }
        }
      }
      .add-crop {
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: auto;
        width: 25px;
        height: 25px;
        background-image: url('@/assets/farm_profile/add_crop_button.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        position: relative;
        .crop-helper-tooltip {
          width: max-content;
          padding: 0 15px;
          height: 30px;
          position: absolute;
          z-index: 999;

          background-color: #3C3838;
          color: #fff;
          top: 40px;
          left: -30px;
          text-indent: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 11px;
          font-family: 'Inter', sans-serif;
          font-weight: 400;

          &::after {
            content: " ";
            position: absolute;
            display: block;
            top: -7px;
            border-color: #3C3838;
            border-left: 8px solid hsl(0, 3%, 23%);
            border-bottom: 6px solid #3C3838;
            border-right: 6px solid transparent;
            border-top: 8px solid transparent;
            -webkit-transform: translateX(-50%) rotate(135deg);
            transform: translateX(-50%) rotate(135deg);
            box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
            left: 41px;
            background: #3C3838;
            z-index: -1;
          }
        }
      }
    }
    .row {
      justify-content: space-between;
      height: 70px;
      // margin-left: 8px;
      &.left-aligned-row {
        margin-left: 15px;
      }
      &.top-section-row {
        .field {
          margin-top: 16px;
        }
      }
      .field {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 165px;
        margin-top: 20px;
        .label {
          color: rgba(40,41,43,0.6);
          font-size: 16px;
          font-weight: 600;
        }
        .field-value {
          color: rgba(40,41,43,1);
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

@media screen and (max-width: 1024px) {
  .farm-profile-details {
    .row {
      height: auto;
    }
  }
}
</style>