import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import erase from './plugins/erase'
import VueGoogleMaps from '@fawmi/vue-google-maps'

import { library } from '@fortawesome/fontawesome-svg-core'

import { faTemperatureThreeQuarters, faDroplet, faDownLeftAndUpRightToCenter, faWind, faWater, faLocationDot } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

import { ProCalendar } from "@lbgm/pro-calendar-vue";

import DraggableResizableVue from 'draggable-resizable-vue3';

library.add(faTemperatureThreeQuarters, faDroplet, faDownLeftAndUpRightToCenter, faWind, faWater, faLocationDot)

store.subscribe((mutation, state) => {
  console.log(mutation, 'mutation');
  if (mutation.type === 'setAuthenticatedData' || 
    mutation.type === 'setPrimaryUserTenant' || 
    mutation.type === 'setPrimaryUserData' || 
    mutation.type === 'setSecondaryUserData' || 
    mutation.type === 'setSubscriptionPlan' || 
    mutation.type === 'setSubscriptionPlanChanges' || 
    mutation.type === 'setSavedAdminSubscription'
  ) {
    const stateFragment = {
      authenticated: state.authenticated,
      primaryUser: state.primaryUser,
      secondaryUser: state.secondaryUser,
      primaryUserTenant: state.primaryUserTenant,
      subscriptionPlan: state.subscriptionPlan,
      savedAdminSubscription: state.savedAdminSubscription
    }
    localStorage.setItem('farmappStore', JSON.stringify(stateFragment));
  }
})
'draggable-resizable-vue3';
createApp(App)
  .use(router)
  .use(store)
  .use(erase)
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyBbcyLxD6mUprwWy25BtgJSiNxFbJo7XLk', // YOUR_API_KEY_COMES_HERE
    },
  })
  .use(VCalendar, {})
  .use(ProCalendar)
  .use(DraggableResizableVue)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
