<template>

  <InventoryHeader />

  <section class="section">
    <div class="border-box">
      <PlanInput v-if="((planInputEditionMode === 'create') || (planInputEditionMode === 'edit')) && (isAuth('add-new-plan-input') || isAuth('edit-plan-input'))" />
      <PlanInputFilled v-else-if="(planInputEditionMode === 'represent')" />
      <InventoryPlanList />
    </div>
  </section>
</template>

<script>
import { ref, reactive, provide, inject, onMounted } from 'vue';
import { useStore } from "vuex";
import InventoryHeader from './InventoryHeader.vue';
import InventoryPlanList from './InventoryPlanList.vue';
import PlanInput from './PlanInput.vue';
import PlanInputFilled from './PlanInputFilled.vue';

export default {
    name: "PlanInventory",
    setup() {

      const store = useStore();

      const { isAuth } = inject('mainUI');

      const planInputEditionMode = ref('');

      const planInput = reactive({
        id: '',
        year: '',
        season: '',
        item: '',
        brand: '',
        variety: '',

        inStockNumber: '',
        inStockQuantity: '',
        
        requiredNumber: '',
        requiredQuantity: '',

        weightPerItem: '',

        orderNumber: '',
        orderQuantity: '',

        orderDate: '',
        // vendorName: '',
        // vendorPhone: '',
        // vendorContact: '',
        daysInAdvance: '',
        setNotifications: false,
        comments: ''
      });

      const selectedPlanInput = reactive({
        ...planInput
      });

      const defaultPlanInput = {
        ...planInput
      }

      const addPlanInput = () => {
        selectPlanInput(defaultPlanInput, 'create');
        // planInputEditionMode.value = 'create';
      }

      const editPlanInput = (field, val) => {
        planInput[field] = val;
      }

      const removePlanInput = (planInputId) => {
        store.dispatch('removePlannedInventory', planInputId)
      };

      const selectPlanInput = (planInputData, editMode) => {
        if (planInputData) {
          for (let field in planInputData) {
            planInput[field] = planInputData[field]
            selectedPlanInput[field] = planInputData[field]
          }
        }
        planInputEditionMode.value = editMode;
      }

      provide('planInputDetails', {
        planInput,
        selectedPlanInput,
        defaultPlanInput,
        planInputEditionMode,
        addPlanInput,
        editPlanInput,
        removePlanInput,
        selectPlanInput
      })

      onMounted(() => {
        store.dispatch('getTasks');
        // store.dispatch('getVendors');
        store.dispatch('getVendorItems');
      })

      return {
        planInputEditionMode,

        planInput,
        isAuth
      }
    },
    components: { InventoryPlanList, PlanInput, PlanInputFilled, InventoryHeader }
}
</script>

<style></style>