<template>
  <ExpensesHeader />
  <!-- <section class="section">
    <div class="border-box"> -->
      <LaborExpensesForm v-if="((editionMode === 'edit') || (editionMode === 'create')) && (isAuth('edit-labor-expenses') || isAuth('add-labor-expenses'))" />
      <LaborExpensesFilled v-if="editionMode === 'represent'" />
      <LaborExpensesList />
    <!-- </div>
  </section> -->
</template>

<script>
import { defineComponent, inject, provide, reactive, ref } from 'vue';
import ExpensesHeader from './ExpensesHeader.vue';
import LaborExpensesForm from './LaborExpensesForm.vue';
import LaborExpensesFilled from './LaborExpensesFilled.vue';
import LaborExpensesList from './LaborExpensesList.vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: "LaborExpenses",
    setup() {
      const store = useStore();

      const { isAuth } = inject('mainUI');

      const editionMode = ref('');

      const laborExpense = reactive({
        id: '',
        date: '',
        task: '', 
        assignedTo: '', 
        taskStatus: '',
        totalHours: '', 
        rate: '', 
        total: '',

        farm: '', 
        bed: '',
        crop: '',
        inputUsed: '',
        amtHarvested: '',
        comments: '',
        
        taskType: '',

        inputTask: false,
        harvestTask: false,
        otherTask: false,
      });

      const selectedLaborExpense = reactive({
        ...laborExpense
      });

      const defaultLaborExpense = {
        ...laborExpense
      }

      const bedsOptions = reactive({
        value: []
      });
      
      const addLaborExpense = () => {
        selectLaborExpense(defaultLaborExpense, 'create');
        // editionMode.value = 'create';
      }

      const editLaborExpense = (field, val) => {
        laborExpense[field] = val;
      }

      const selectLaborExpense = (laborExpenseData, editMode) => {
        if (laborExpenseData) {
          for (let field in laborExpenseData) {
            laborExpense[field] = laborExpenseData[field]
            selectedLaborExpense[field] = laborExpenseData[field]
          }
        }
        editionMode.value = editMode;
      }

      const removeLaborExpense = (laborExpenseId) => {
        store.dispatch('removeLaborExpense', laborExpenseId)
      };

      provide('laborExpenseDetails', {
        laborExpense,
        defaultLaborExpense,
        selectedLaborExpense,
        editionMode,
        addLaborExpense,
        editLaborExpense,
        selectLaborExpense,
        removeLaborExpense,
        options: bedsOptions
      })

      return {
        editionMode,
        isAuth,
        laborExpense
      }
    },
    components: { 
      ExpensesHeader, 
      LaborExpensesForm, 
      LaborExpensesFilled, 
      LaborExpensesList
    }
})
</script>

<style lang="scss" scoped></style>