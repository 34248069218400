<template>
  
  <div class="content-block section-block" ref="sectionBox">

    <div class="content-header border-box-header" ref="boxHeader" @click="toggleSection">
    <p class="fw-semibold">Plan Crop</p>
    <div class="icon-group">
    <!-- <a href="javascript:void();" class="icon green"><img src="@/assets/images/upload.svg" alt=""></a> -->
    </div>
    <img src="@/assets/images/angle-down.png" alt="" class="angle" :class="{revert: !sectionExpanded}">
    </div>

    <div class="content-section border-box-content p-0">

<div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.year || (crop.year.length > 0)}">Year</p>
<!-- <select class="form-control"
  v-model="crop.year"
  @click="() => {
    activated.year = true;
    focused.year = true;
  }"
  @change="() => {
    focused.year = false;
  }"
>
  <option v-for="yearOption in cropYears"
    v-bind:key="yearOption + 'year-option-crop'"
  >{{ yearOption }}</option>
</select> -->
  <DropDownList
    :mainObject="'crop'"
    :mainProperty="'year'"
    :injectionName="'cropEdition'"
    :optionKey="'year-option-crop'"
    :optionsList="cropYears"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="crop.year"
    @click="() => {
      activated.year = true;
    }"
  />
<p v-if="activated.year && crop.year.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.season || (crop.season.length > 0)}">Season</p>
<!-- <select class="form-control"
  v-model="crop.season"
  @click="() => {
    activated.season = true;
    focused.season = true;
  }"
  @change="() => {
    focused.season = false;
  }"
>
  <option v-for="seasonOption in cropSeasons"
    v-bind:key="seasonOption + 'season-option-crop'"
  >{{ seasonOption }}</option>
</select> -->
  <DropDownList
    :mainObject="'crop'"
    :mainProperty="'season'"
    :injectionName="'cropEdition'"
    :optionKey="'season-option-crop'"
    :optionsList="cropSeasons"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="crop.season"
    @click="() => {
      activated.season = true;
    }"
  />
<p v-if="activated.season && crop.season.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.cropCycle || (crop.cropCycle.length > 0)}">Crop Cycle</p>
<!-- <input type="text" class="form-control"
  v-model="crop.cropCycle"
  @focus="() => {
    activated.cropCycle = true;
    focused.cropCycle = true;
  }"
  @blur="() => {
    focused.cropCycle = false;
  }"
> -->
<!-- <select class="form-control"
  v-model="crop.cropCycle"
  @click="() => {
    activated.cropCycle = true;
    focused.cropCycle = true;
  }"
  @change="() => {
    focused.cropCycle = false;
  }"
>
  <option v-for="cycleOption in cropCycles"
    v-bind:key="cycleOption + 'cycle-option-crop'"
  >{{ cycleOption }}</option>
</select> -->
  <DropDownList
    :mainObject="'crop'"
    :mainProperty="'cropCycle'"
    :injectionName="'cropEdition'"
    :optionKey="'cycle-option-crop'"
    :optionsList="cropCycles"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="crop.cropCycle"
    @click="() => {
      activated.cropCycle = true;
    }"
  />
<p v-if="activated.cropCycle && crop.cropCycle.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<!-- <div class="form-item">
<p class="form-label" :class="{active: focused.farmName || (crop.farmName.length > 0)}">Farm</p>
<select class="form-control"
  v-model="crop.farmName"
  @click="() => {
    activated.farmName = true;
    focused.farmName = true;
  }"
  @change="() => {
    focused.farmName = false;
  }"
>
  <option v-for="farmOption in farmOptions"
    v-bind:key="farmOption + 'farm-option-crop'"
  >{{ farmOption }}</option>
</select>
<p v-if="activated.farmName && crop.farmName.length === 0" class="form-info red text-end">* Required</p>
</div> -->
</div>


<!-- <div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.acreage || (crop.acreage.length > 0)}">Acerage</p>
<input type="text" class="form-control"
  v-model="crop.acreage"
  @focus="() => {
    activated.acreage = true;
    focused.acreage = true;
  }"
  @blur="() => {
    focused.acreage = false;
  }"
>
<p v-if="activated.acreage && crop.acreage.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.acreage && crop.acreage.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div> -->


<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.cropCategory || (crop.cropCategory.length > 0)}">Crop Category</p>
<input type="text" class="form-control"
v-model="crop.cropCategory"
  @focus="() => {
    activated.cropCategory = true;
    focused.cropCategory = true;
  }"
  @blur="() => {
    focused.cropCategory = false;
  }"
  placeholder="E.g., Grain"
>
<p v-if="activated.cropCategory && crop.cropCategory.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.crop || (crop.crop.length > 0)}">Crop</p>
<input type="text" class="form-control"
  v-model="crop.crop"
  @focus="() => {
    activated.crop = true;
    focused.crop = true;
  }"
  @blur="() => {
    focused.crop = false;
  }"
  placeholder="E.g., Rice"
>
<p v-if="activated.crop && crop.crop.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.crop && crop.crop.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.variety || (crop.variety.length > 0)}">Variety</p>
<input type="text" class="form-control"
  v-model="crop.variety"
  @focus="() => {
    activated.variety = true;
    focused.variety = true;
  }"
  @blur="() => {
    focused.variety = false;
  }"
  placeholder="E.g., Basmati"
>
<p v-if="activated.variety && crop.variety.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3 info-icons-links">

  <!-- <div class="info-icons" v-if="(crop.sourceType !== 'bed') || (bedsList.length > 0)">
    <div class="info-icon lg tooltip" 
      @click="cancelChanges"
      data-tooltip="Delete Plan Crop"
    >
      <img src="@/assets/images/updated/delete-item.png" alt="">
    </div>
    <div class="info-icon lg tooltip left-aligned-tooltip" 
      @click="addAnotherCrop"
      data-tooltip="Add Plan Crop"  
    >
      <img src="@/assets/images/updated/add-item.png" alt="">
    </div>
  </div> -->

  <!-- v-if="(bedOptions.length > 0)" -->
<!-- <div class="form-item">
<p class="form-label" :class="{active: focused.bed || (crop.bed.length > 0)}">Bed</p>
<select class="form-control"
  v-model="crop.bed"
  @click="() => {
    activated.bed = true;
    focused.bed = true;
  }"
  @change="() => {
    focused.bed = false;
  }"
>
  <option v-for="bedOption in bedOptions"
    v-bind:key="bedOption + 'bed-option-crop'"
  >{{ bedOption }}</option>
</select>
<p v-if="activated.bed && crop.bed.length === 0" class="form-info red text-end">* Required</p>
</div> -->
</div>


<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.dateSeed || (crop.dateSeed.length > 0)}">Date Seed</p>
<input type="date" class="form-control"
  v-model="crop.dateSeed"
  :style="{color: (focused.dateSeed || crop.dateSeed.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
  pattern="\d{2}/\d{2}/\d{4}"
  @focus="() => {
    activated.dateSeed = true;
    focused.dateSeed = true;
  }"
  @blur="() => {
    focused.dateSeed = false;
  }"
>
  <div class="date-icon"></div>
<p v-if="activated.dateSeed && crop.dateSeed.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.dateSeed && crop.dateSeed.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.harvestDate || (crop.harvestDate.length > 0)}">Date Harvest</p>
<input type="date" class="form-control"
  :style="{color: (focused.harvestDate || crop.harvestDate.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
  pattern="\d{2}/\d{2}/\d{4}"
 v-model="crop.harvestDate"
  @focus="() => {
    activated.harvestDate = true;
    focused.harvestDate = true;
  }"
  @blur="() => {
    focused.harvestDate = false;
  }"
>
  <div class="date-icon"></div>
<p v-if="activated.harvestDate && crop.harvestDate.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.harvestDate && crop.harvestDate.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.projHarvestAmount || (crop.projHarvestAmount.length > 0)}">Proj. Harvest Amt. (lbs)</p>
<!-- {{projHarvestAmountLabel}}
changeHarvestLabel(true);
changeHarvestLabel();
-->
<input type="text" class="form-control"
 v-model="crop.projHarvestAmount"
  @focus="() => {
    activated.projHarvestAmount = true;
    focused.projHarvestAmount = true;
  }"
  @blur="() => {
    focused.projHarvestAmount = false;
  }"
  placeholder="E.g., 500"
>
<p v-if="activated.projHarvestAmount && crop.projHarvestAmount.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.projHarvestAmount && crop.projHarvestAmount.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.projWastage || (crop.projWastage.length > 0)}">Proj. Wastage (lbs)</p>
<!-- {{projWastageLabel}} 
changeWastageLabel(true);
changeWastageLabel();
-->
<input type="text" class="form-control"
  v-model="crop.projWastage"
  @focus="() => {
    activated.projWastage = true;
    focused.projWastage = true;
  }"
  @blur="() => {
    focused.projWastage = false;
  }"
  placeholder="E.g., 10"
>
<p v-if="activated.projWastage && crop.projWastage.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

</div>
</div>

<div class="content-block">
<div class="content-header">
<h6 class="fw-semibold">Set Notification</h6>
</div>
<div class="content-section">


<div class="checkbox-group-wrap">

<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="dateSeed" v-model="crop.isDateSeed"><label for="dateSeed">Date Seed</label></div>
<div class="field-box">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control sm" v-model="crop.seedDays" :disabled="!crop.isDateSeed">
</div>
</div>

<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="dateHarvest" v-model="crop.isDateHarvest"><label for="dateHarvest">Date Harvest</label></div>
<div class="field-box">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control sm" v-model="crop.harvestDays" :disabled="!crop.isDateHarvest">
</div>
</div>

</div>


</div>
</div>

</div>

</div>

<div class="removal-popup" v-if="removedCropId">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">Are you sure you want to delete Plan Crop?</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveRemoval(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>

</template>

<script>
import { defineComponent, reactive, inject, ref, watch, onMounted } from 'vue';
import cropYears from '@/sharedData/yearsList';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import DropDownList from '@/components/Shared/DropDownList.vue';

export default defineComponent({
    name: 'FarmPlanCropForm',
    setup() {
        const store = useStore();
        const { crop, activated, defaultCrop, planCropSeasonIsChanged } = inject('cropEdition');
        const { selectedFarmId, formMode } = inject('formMode');
        const { selectedFarm, farm, sameAddress, defaultFarm } = inject('farmEdition');
        // const { bed, cancelBed, editionMode } = inject('bedEdition');
        let bed, cancelBed, editionMode, newBedId, bedsList;
        const bedEdition = inject('bedEdition');
        const bedsCropCreation = inject('bedsCropCreation');
        if (bedEdition) {
            bed = bedEdition.bed;
            cancelBed = bedEdition.cancelBed;
            editionMode = bedEdition.editionMode;
            bedsList = bedEdition.bedsList;
        }
        if (bedsCropCreation) {
            newBedId = bedsCropCreation.newBedId;
        }
        // const { newBedId } = inject('bedsCropCreation');
        const cropSeasons = ['Spring', 'Summer', 'Autumn', 'Winter'];
        const cropCycles = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10 /* ,11,12,13,14,15 */];
        // const activated = reactive({
        //   farmName: false,
        //   year: false,
        //   cropCategory: false,
        //   dateSeed: false,
        //   projHarvestAmount: false,
        //   bed: false,
        //   season: false,
        //   crop: false,
        //   harvestDate: false,
        //   projWastage: false,
        //   acreage: false,
        //   cropCycle: false,
        //   variety: false
        // });
        const focused = reactive({
            farmName: false,
            year: false,
            cropCategory: false,
            dateSeed: false,
            projHarvestAmount: false,
            bed: false,
            season: false,
            crop: false,
            harvestDate: false,
            projWastage: false,
            acreage: false,
            cropCycle: false,
            variety: false
        });
        const projWastageLabel = ref('Proj. Wastage in lbs');
        const changeWastageLabel = (isFocused) => {
            projWastageLabel.value = isFocused ? 'Proj. Wastage' : 'Proj. Wastage in lbs';
        };
        const projHarvestAmountLabel = ref('Proj. Harvest Amt in lbs');
        const changeHarvestLabel = (isFocused) => {
            projHarvestAmountLabel.value = isFocused ?
                'Proj. Harvest Amt' :
                'Proj. Harvest Amt in lbs';
        };
        const sectionBox = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const removedCropId = ref(false);
        const toggleSection = (ev) => {
            if ((ev.target.className !== 'content-header border-box-header') &&
                (ev.target.className !== 'angle') && (ev.target.className !== 'angle revert')) {
                return;
            }
            sectionExpanded.value = !sectionExpanded.value;
        };
        const addAnotherCrop = () => {
            if ((crop.sourceType === 'farm') && ((crop.sourceId === farm.id) || (crop.sourceId === selectedFarmId) || (crop.sourceId === selectedFarm.id))) {
                if (!crop.year || !crop.season || //!crop.farmName || !crop.acreage || 
                    !crop.crop || !crop.dateSeed || !crop.harvestDate || !crop.projHarvestAmount) {
                    if (!crop.year)
                        activated.year = true;
                    if (!crop.season)
                        activated.season = true;
                    // if (!crop.farmName) activatedCrop.farmName = true;
                    // if (!crop.acreage) activatedCrop.acreage = true;
                    if (!crop.crop)
                        activated.crop = true;
                    if (!crop.dateSeed)
                        activated.dateSeed = true;
                    if (!crop.harvestDate)
                        activated.harvestDate = true;
                    if (!crop.projHarvestAmount)
                        activated.projHarvestAmount = true;
                    return;
                }
                const savedCrop = {
                    ...crop,
                    id: uuid()
                    // id: crop.id || uuid()
                };
                // crop.id ? store.dispatch('editCrop', {
                //   crop: savedCrop
                // }) : 
                delete savedCrop._id;
                store.dispatch('createCrop', {
                    crop: savedCrop
                });
                console.log(defaultCrop);
                if (planCropSeasonIsChanged.value) {
                    store.dispatch('archiveFarmPlan', {
                        mapDraw: {},
                        crop: { ...savedCrop },
                        selectedFarmId: selectedFarm.id,
                        id: uuid()
                    });
                    console.log(selectedFarm);
                }
                for (let prop in crop) {
                    if ((prop !== 'year') && (prop !== 'season') && (prop !== 'cropCycle') &&
                        (prop !== 'sourceType') && (prop !== 'sourceId')) {
                        crop[prop] = defaultCrop[prop];
                    }
                }
            }
            if (crop.sourceType === 'bed') {
                const savedCrop = {
                    ...crop
                    // id: crop.id || uuid()
                };
                console.log(editionMode.value);
                // editionMode.value === 'edit' ? store.dispatch('editBed', {
                //   ...savedCrop,
                //   ...bed,
                //   season: savedCrop.season,
                //   year: savedCrop.year,
                //   crop: savedCrop.crop,
                //   farmId: selectedFarm.id || selectedFarmId,
                // }) : 
                console.log('addition create savedCrop: ', savedCrop);
                store.dispatch('createBed', {
                    ...savedCrop,
                    ...bed,
                    season: savedCrop.season,
                    year: savedCrop.year,
                    crop: savedCrop.crop,
                    cropCycle: savedCrop.cropCycle,
                    farmId: selectedFarm.id || selectedFarmId,
                    // id: newBedId.value
                    sourceId: newBedId.value,
                    id: uuid()
                });
                for (let prop in crop) {
                    if ((prop !== 'year') && (prop !== 'season') && (prop !== 'cropCycle') &&
                        (prop !== 'sourceType') && (prop !== 'sourceId')) {
                        crop[prop] = defaultCrop[prop];
                    }
                }
            }
        };
        const cancelChanges = () => {
            removedCropId.value = true;
        };
        const approveRemoval = (approved) => {
            if (approved) {
                if ((crop.sourceType === 'farm') && ((crop.sourceId === farm.id) || (crop.sourceId === selectedFarmId) || (crop.sourceId === selectedFarm.id))) {
                    if (formMode.value === "edit") {
                        for (let prop in farm) {
                            farm[prop] = selectedFarm[prop];
                        }
                        formMode.value = "represent";
                    }
                    else {
                        for (let prop in farm) {
                            farm[prop] = defaultFarm[prop];
                        }
                    }
                    sameAddress.value = false;
                    store.dispatch('removeCrop', { cropId: crop.id });
                }
                if (crop.sourceType === 'bed') {
                    store.dispatch('removeBed', { farmId: farm.id || selectedFarmId || selectedFarm.id, bedId: crop.sourceId, bedIndex: null });
                    cancelBed();
                }
            }
            else {
                removedCropId.value = false;
            }
        };
        watch(sectionExpanded, (newVal) => {
            sectionBox.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
sectionBox.value.style.overflow = newVal ? 'visible' : 'hidden'
        });
        onMounted(() => {
            setTimeout(() => {
                sectionHeight.value = sectionBox.value.offsetHeight;
                sectionBox.value.style.height = `${sectionBox.value.offsetHeight}px`;
            });
        });
        return {
            cropYears,
            crop,
            cropSeasons,
            cropCycles,
            activated,
            focused,
            projWastageLabel,
            changeWastageLabel,
            projHarvestAmountLabel,
            changeHarvestLabel,
            sectionBox,
            boxHeader,
            sectionExpanded,
            toggleSection,
            addAnotherCrop,
            cancelChanges,
            removedCropId,
            approveRemoval,
            bedsList
        };
    },
    components: { DropDownList }
})
</script>

<style lang="scss" scoped>
.section-block {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.content-header {
  &.border-box-header {
    margin-top: 0.1rem;
  }
  h6 {
    font-size: var(--h6);
  }
}

.angle {
  &.revert {
    transform: rotate(180deg);
  }
}

.left-aligned-tooltip {
  &:hover::before {
    left: -110%;
  }
}
</style>