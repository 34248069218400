<template>
  <footer>
    <ul>
      <li><a href="javascript:void();">About</a></li>
      <li><a href="javascript:void();">User Agreement</a></li>
      <li><a href="javascript:void();">Policies</a></li>
      <li><a href="javascript:void();">Help</a></li>
      <li><a href="javascript:void();">Contact us</a></li>
    </ul>
  </footer><!--//End Footer-->
</template>

<script>
export default {
  name: "AppFooter",
  setup() {},
};
</script>
