<template>
  <EquipmentHeader />
  <section class="section">
    <div class="border-box">
      <EquipmentForm v-if="((editionMode === 'edit') || (editionMode === 'create')) && (isAuth('edit-equipment') || isAuth('add-equipment'))" />
      <EquipmentFilled  v-if="(editionMode === 'represent') && (equipments.length > 0)" />
      <EquipmentList />
    </div>
  </section>
</template>

<script>
import { ref, inject, reactive, provide, computed } from 'vue';
import { useStore } from "vuex";
import EquipmentFilled from './EquipmentFilled.vue';
import EquipmentForm from './EquipmentForm.vue';
import EquipmentHeader from './EquipmentHeader.vue';
import EquipmentList from './EquipmentList.vue';
import { useRouter } from 'vue-router';

export default {
    name: "EquipmentModule",
    components: { EquipmentHeader, EquipmentForm, EquipmentList, EquipmentFilled },
    setup() {
      const store = useStore();
      const router = useRouter();

      const { isAuth } = inject('mainUI');

      const editionMode = ref('');

      const equipments = computed(() => [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.type === 'Equipment'));

      const vendors = computed(() => store.state.vendors);

      const equipment = reactive({
        id: '',
        item: '',
        manufacturer: '',
        brand: '',
        numberOfItems: '',
        date: '',
        amountPaid: '',
        invoice: '',
        vendor: '',
        vendorName: '',
        vendorPhone: '',
        vendorContact: '',
        warrantyExp: '',
        serviceDate: '',
        serviceDateNotification: false,
        serviceDateNotificationDays: '',
        warrantyDateNotification: false,
        warrantyDateNotificationDays: '',

        comments: ''
      });

      const selectedEquipment = reactive({
        ...equipment
      });

      const defaultEquipment = {
        ...equipment
      }

      const addEquipment = () => {
        selectEquipment(defaultEquipment, 'create');
        // editionMode.value = 'create';
      }

      const addNewEquipment = () => {
        router.push({
          name: 'vendors', 
          params: {
            pagename: 'vendor-module',
            source: 'equipment-module'
          }
        })
      }

      const editEquipment = (field, val) => {
        equipment[field] = val;
      }

      const selectEquipment = (equipmentData, editMode) => {
        if (equipmentData) {
          for (let field in equipmentData) {
            equipment[field] = equipmentData[field]
            selectedEquipment[field] = equipmentData[field]
          }
        }
        editionMode.value = editMode;

        const selectedVendor = vendors.value.find(stateVendor => stateVendor.id === equipment.vendor);

        if (selectedVendor) {
          equipment.vendorContact = selectedVendor.vendorContact;
          selectedEquipment.vendorContact = selectedVendor.vendorContact;
          equipment.vendorName = selectedVendor.vendor;
          selectedEquipment.vendorName = selectedVendor.vendor;
          equipment.vendorPhone = selectedVendor.vendorPhone;
          selectedEquipment.vendorPhone = selectedVendor.vendorPhone;
        }
      }

      const removeEquipment = (equipmentId) => {
        store.dispatch('removeVendorItem', equipmentId)
      };

      provide('equipmentDetails', {
        equipment,
        defaultEquipment,
        selectedEquipment,
        editionMode,
        addEquipment,
        editEquipment,
        selectEquipment,
        removeEquipment,
        addNewEquipment
      })

      return {
        editionMode,
        isAuth,
        equipment,
        equipments
      }
    }
}
</script>

<style></style>