const items = [
  { name: 'Home', url: '/' },
  { name: 'Farm',
    url: '/farm/farm-profile', 
    subs: [
      { name: 'Farm History', url: '/farm/farm-history'},
    ],
    authReq: 'farm-module' 
  },
  { name: 'Crops', 
    url: '/crops/plan-crop',
    subs: [
      // { name: 'Plan Crop', url: '/crops/plan-crop' }, 
      { name: 'Harvested', url: '/crops/harvest-module' }
    ],
    authReq: 'crops-module'
  },
  { name: 'Task', 
    url: '/task',
    subs: [
      { name: 'Completed Tasks', url: '/task/completed-tasks' }
    ],
    authReq: 'task-module' 
  },
  { name: 'Labor', 
    url: '/labor/labor-module',
    subs: [
      { name: 'Income', url: '/labor/income-module' }
    ],
    authReq: 'labor-module'
  },
  {
    name: 'Vendors',
    url: '/vendors/vendor-module',
    subs: [
      { name: 'Archived', url: '/vendors/archived-vendors' }
    ],
    authReq: 'vendor-module'
  },
  { name: 'Equipment', 
    url: '/equipment/equipment', 
    subs: [
      { name: 'Servicing', url: '/equipment/equipment-service' }
    ],
    authReq: 'equipment-module' 
  },
  { name: 'Inventory', 
    url: '/inventory/inventory',
    subs: [
      { name: 'Plan', url: '/inventory/plan-inventory' }, 
      { name: 'In-Hand', url: '/inventory/in-hand' }
    ],
    authReq: 'inventory-module'
  },
  { 
    name: 'Customers', 
    url: '/customers/customers-module', 
    authReq: 'customers-module' 
  },
  { 
    name: 'Sales', 
    url: '/sales/sales-module', 
    authReq: 'sales-module' 
  },
  { 
    name: 'Reports', 
    url: '/reports/reports-module', 
    authReq: 'reports-module' 
  },
  { 
    name: 'Resources', 
    url: '/resources/resources-module', 
    // authReq: 'resources-module' 
  },
  { 
    name: 'Contact Us', 
    url: '/contact/contact-module', 
    // authReq: 'resources-module' 
  },
  { name: 'Admin', url: '/admin/admin-module', 
    subs: [
      // { name: 'Account', url: '/admin/admin-account' },
      { name: 'Users', url: '/admin/admin-users' },
      { name: 'Subscription', url: '/admin/admin-subscription' },
      { name: 'Payment', url: '/admin/admin-payment' },
      { name: 'Labor Expenses', url: '/admin/labor-expenses' },
      { name: 'Admin Expenses', url: '/admin/admin-expenses' },
      
      { name: 'Emp Compensation', url: '/admin/employee-compensation' },

      { name: 'Advertisement', url: '/admin/advertisement-module/advertisement-account' },
      // { name: 'Advertisement Questionnaire 2', url: '/admin/advertisement-module/advertisement-questionnaire-second' },
      // { name: 'Advertisement Preview', url: '/admin/advertisement-module/advertisement-preview' },
      // { name: 'Advertisement Payment', url: '/admin/advertisement-module/advertisement-payment' },
      // { name: 'Advertisement Publish', url: '/admin/advertisement-module/advertisement-publish' },
      { name: 'My Advertisements', url: '/admin/advertisement-module/advertisement-list' },
      { name: 'Create Ad', url: '/admin/advertisement-module/advertisement-questionnaire-first' }
    ], 
    authReq: 'admin' 
  },
  { name: 'Super Admin', url: '/super-admin/super-admin-list',
    subs: [
      {
        name: 'Archived Accounts', url: '/super-admin/super-admin-archived-list'
      }
    ], 
    authReq: 'super-admin' 
  },
];

export default items;
