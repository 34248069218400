<template>
  <DashboardHeader />
  <DashboardSlider />
  <section class="section dashboard-section">
    <div class="row">
      <DashboardSidebar />

      <div class="col dashboard-content-wrap">
        <div ref="taskListElement"></div>
        <TaskList />
        <!-- <DashboardInventoryStatus /> -->
        <InventoryStatus />
      </div>
    </div>
  </section>
</template>

<script>
import { provide, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import DashboardHeader from './DashboardHeader.vue';
// import DashboardInventoryStatus from './DashboardInventoryStatus.vue';
import DashboardSidebar from './DashboardSidebar.vue';
import DashboardSlider from './DashboardSlider.vue';
// import DashboardTasks from './DashboardTasks.vue';
import TaskList from '@/components/Pages/Task/TaskList.vue';
import InventoryStatus from '../Inventory/InventoryStatus.vue';
import { useStore } from 'vuex';

export default {
    name: "DashboardModule",
    components: { 
      DashboardHeader, 
      DashboardSlider, 
      DashboardSidebar, 
      TaskList, 
      // DashboardInventoryStatus
      InventoryStatus 
    },
    setup() {

      const store = useStore();

      const router = useRouter();

      const selectedTask = {}

      const taskListElement = ref(null);

      const navigateToTask = () => {
        router.push({ 
          name: 'page', 
          params: { 
            pagename: 'task',
            source: 'dashboard-module'
          } 
        })
      }

      const addTask = () => navigateToTask();
      
      const editTask = () => navigateToTask();
      
      const selectTask = (taskData, editMode) => {
        router.push({
          name: 'page',
          params: {
            pagename: 'task',
            taskId: taskData.id,
            taskMode: editMode
          }
        });
      };

      const removeTask = (taskId) => {
        store.dispatch('removeTask', taskId)
      };

      provide('taskDetails', {
        selectedTask,
        addTask,
        editTask,
        selectTask,
        removeTask
      });

      watch(() => ({...store.state.primaryUser}), (newVal) => {
        if (newVal) {
          const params = new URLSearchParams(document.location.search);

          const redirected = params.get('redirected');

          // if (redirected === '1') {
          //   const baseRoute = router.currentRoute.value.fullPath.split('&redirected=1')[0];
          //   setTimeout(() => {
          //     router.push(`${baseRoute}&redirected=2`);
          //     setTimeout(() => {
          //       router.go();
          //     });
          //   });
          // }

          // if (redirected === '2') {
          //   popupMessages.addNew = true;
          //   initialFilling.value = false;
          //   submittedUserProfile.value = true;
          // }

          if (redirected === '3') {
            const baseRoute = router.currentRoute.value.fullPath.split('&redirected=3')[0];
            setTimeout(() => {
              router.push(`${baseRoute}`);
              setTimeout(() => {
                router.go();
              });
            });
          }
        }
      });

      onMounted(() => {
        const params = new URLSearchParams(document.location.search);
        const user_id = params.get("user_id");
        const token = params.get("token");

        if (token && user_id) {
          store.dispatch('authenticateUser', {
            user_id,
            token
          })
        }

        store.dispatch('getUsersList');

        if (router.currentRoute.value.params.taskEdit || 
          router.currentRoute.value.params.selectedTask) {
          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: taskListElement?.value?.offsetTop,
              left: 0,
              behavior: 'smooth'
            })
          })
        }
      })

      return {
        taskListElement
      }
    }
}
</script>

<style></style>