<template>
      <ProfileHeader v-bind:openAdditionalForm="openAdditionalForm" />
    
      <ProfileDetailsForm v-if="mainDetails.mode === 'edit'" />
      <!--  && isAuth('edit-details') -->
      <ProfileDetails v-else />

      <div ref="subscriptionForm" ></div>
      <ProfileSubscriptionForm v-if="subscription.mode === 'edit'" />
      <!--  && isAuth('edit-subscription') -->
      <ProfileSubscription v-bind:userType="'primaryUser'" v-else />

      <div ref="paymentForm"></div>

      <ProfilePaymentForm v-bind:userType="'primaryUser'" v-if="payment.mode === 'edit'" />
      <!--  && isAuth('add-payment') -->
      <ProfilePayment v-bind:userType="'primaryUser'" v-else />

      <div ref="billingForm"></div>
      <ProfileBillingForm  v-bind:userType="'primaryUser'" v-if="billing.mode === 'edit'" />
      <!--  && isAuth('edit-billing') -->
      <ProfileBilling v-bind:userType="'primaryUser'" v-else />

      <ProfileAccountDetails v-if="additionalFormOpened" />
      <!-- <div class="form-actions">
        <q-btn
          style="color: white"
          label="Submit"
          class="bg-bhoomi"
          @click="submitProfileForms"
        />
        <q-btn outline class="text-bhoomi" label="Edit" />
      </div> -->
      <div v-if="submittedUserProfile && popupMessages.purchase.active">
        <UserNotification
          v-bind:messageText="popupMessages.purchase.message"
        />
      </div>

      <div v-if="popupMessages.subscription.active">
        <UserNotification
          v-bind:hideDetails="true"
          v-bind:messageText="popupMessages.subscription.message"
          v-bind:status="popupMessages.subscription.status"
        />
      </div>

      <div v-if="popupMessages.payment.active">
        <UserNotification
          v-bind:hideDetails="true"
          v-bind:messageText="popupMessages.payment.message"
          v-bind:status="popupMessages.payment.status"
        />
      </div>

      <div v-if="popupMessages.billing.active">
        <UserNotification
          v-bind:hideDetails="true"
          v-bind:messageText="popupMessages.billing.message"
          v-bind:status="popupMessages.billing.status"
          v-bind:holdInterval="popupMessages.billing.holdInterval"
        />
      </div>

    <div class="add-new-popup" v-if="submittedUserProfile && popupMessages.addNew">
      
      <div class="modal modal-md">
      <span class="modal-close" @click="() => {
        popupMessages.addNew = false;
        initialFilling = false;  
        redirectToHome();
      }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
      <div class="modal-block text-center">
      <p class="fw-bold">Add another user or create farm profile</p>
      </div>
      <div class="modal-block text-center">
      <div class="buttons justify-content-center">
          <button
            @click="() => createNewUser()"
            class="approve-button btn green" 
          >Add user</button>
          <button
            @click="() => createNewFarm()" 
            class="cancel-removal-button btn green btn-o" 
          >Add farm</button>
      </div>
      </div>
      </div>
      
      <!-- <div class="popup-message">
        <div class="close-button" @click="() => {
          popupMessages.addNew = false;
        }" ></div>
        <span class="popup-text">Add another user or create farm profile</span>
        <div class="popup-buttons">
          <button class="btn green" @click="() => createNewUser()">Add user</button>
          <button class="btn green" @click="() => createNewFarm()">Add farm</button>
        </div>
      </div> -->

    </div>
</template>

<script>
import { onMounted, provide, reactive, ref, watch, watchEffect, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ProfileDetails from "@/components/Pages/UserProfile/ProfileDetails.vue";
import ProfileDetailsForm from "@/components/Pages/UserProfile/ProfileDetailsForm.vue";
import ProfileSubscription from "@/components/Pages/UserProfile/ProfileSubscription.vue";
import ProfileSubscriptionForm from "@/components/Pages/UserProfile/ProfileSubscriptionForm.vue";
import ProfilePayment from "@/components/Pages/UserProfile/ProfilePayment.vue";
import ProfilePaymentForm from "@/components/Pages/UserProfile/ProfilePaymentForm.vue";
import ProfileBilling from "@/components/Pages/UserProfile/ProfileBilling.vue";
import ProfileBillingForm from "@/components/Pages/UserProfile/ProfileBillingForm.vue";
import ProfileAccountDetails from "@/components/Pages/UserProfile/ProfileAccountDetails.vue";
import ProfileHeader from "./ProfileHeader.vue";
import UserNotification from "@/components/Shared/UserNotification.vue";

export default {
  name: "UserProfile",
  components: {
    ProfileDetailsForm,
    ProfileDetails,
    ProfileSubscription,
    ProfileSubscriptionForm,
    ProfilePayment,
    ProfilePaymentForm,
    ProfileBilling,
    ProfileBillingForm,
    ProfileAccountDetails,
    ProfileHeader,
    UserNotification,
},
  setup() {
    const store = useStore();
    const router = useRouter();

    const { isAuth } = inject('mainUI');

    const  mainDetails = reactive({
        accountName: '',
        title: '',
        
        email: '',
        password: '',
        // email: '',
        userRole: 'admin',
        multifactor: false,
        multifactorType: '',

        firstName: '',
        lastName: '',
        address: '',
        address2: '',
        mobilePhone: '',
        workPhone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        employeeType: '',
        grade: '',

        submitted: false,
        activated: false,
        mode: (store.state.primaryUser?.mainDetails?.submitted ||
          store.state.primaryUser?.mainDetails?.email) ? 'represent' : 'edit'
      });

      const defaultMainDetails = {
        ...mainDetails
      }

    // const userRole = reactive({
    //   roles: ['admin', 'edit', 'contribute', 'read'],
    //   selected: 'admin',
    //   mode: mainDetails.submitted ? 'represent' : 'edit'
    // });

    const subscription = reactive({

        plans: {
          trial: false,
          trialPrice: 'price_1O5VljHpD3HYJIrgI5y2Jhkq',
          
          basic: false,
          basicPrice: '',
          basicNumber: '',
          basicPriceIdYearly: 'price_1NXMRQHpD3HYJIrg2IwC8sri',
          basicPriceIdMonthly: 'price_1NXMNxHpD3HYJIrg0hyDtIQO',
          basicAvailable: '',

          // inventory: false,
          // inventoryPrice: '',
          // inventoryNumber: '',
          plus: false,
          plusPrice: '',
          plusNumber: '',
          plusPriceIdYearly: 'price_1NXMQoHpD3HYJIrgJaWbqhOI',
          plusPriceIdMonthly: 'price_1NXMQoHpD3HYJIrgnhtzXnIF',
          plusAvailable: '',
          
          // equipment: false,
          // equipmentPrice: '',
          // equipmentNumber: '',

          advance: false,
          advancePrice: '',
          advanceNumber: '',
          advancePriceIdYearly: 'price_1NXMhHHpD3HYJIrgUwLCM3C2',
          advancePriceIdMonthly: 'price_1NXMhHHpD3HYJIrgrxWzr1UH',
          advanceAvailable: '',
          
          // allinclusive: false,
          // allinclusivePrice: '',
          // allinclusiveNumber: '',

          ultimate: false,
          ultimatePrice: '',
          ultimateNumber: '',
          ultimatePriceIdYearly: 'price_1NXMklHpD3HYJIrgUKaTXGU9',
          ultimatePriceIdMonthly: 'price_1NXMklHpD3HYJIrgbrsDHSiM',
          ultimateAvailable: '',
          
        },
        
        subscriptionPeriod: 'Monthly',
        
        totalSubscription: '',
        taxes: '',
        discount: '',
        total: '',

        planPurchasingDate: '',
        subscriptionsNumber: 0, // remove later
        mode: store.state.primaryUser.subscription ? 'represent' : 'edit',
        submitted: store.state.primaryUser.subscription ? true : false
      });
   
    const defaultSubscription = {
      ...subscription,
      plans: {
        ...subscription.plans
      }
    }
      
    const payment = reactive({
        
        // paymentType: '',
        id: '',
        cardHolderName: '',
        // cardNumber: '',
        // cardType: '',
        expiryDate: '',
        // cvv: '',
        zip: '',

        // accountNickname: '',
        // accountName: '',
        // accountType: '',
        // routing: '',
        // accountNumber: '',

        // saveForFuture: false,
        mode: 'edit',//'represent',
        submitted: false
      });

    const defaultPayment = {
      ...payment
    }

    const selectedPayment = ref(null);

    const billing = reactive({
        accountName: '',
        firstName: '',
        lastName: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        paymentMethodId: '',
        mode: (store.state.primaryUser?.billing?.length > 0) ? 'represent' : 'edit',
        expanded: false,
        sameAddress: false
      })

    const defaultBilling = {
      ...billing,
      mode: 'edit'
    }

    const additionalFormOpened = ref(false),
      submittedUserProfile = ref(false),
      popupMessages = reactive({
        purchase: {
          active: false,
          message: 'Thank you for your purchase, your subscription has been added to your account'
        },
        addNew: false,
        subscription: {
          active: false,
          message: 'Please choose a subscription type!',
          status: ''
        },
        payment: {
          active: false,
          message: 'Please add a payment option, if you have chosen 30-day free trial, your payment will only be charged after the trial period has expired!',
          status: ''
        },
        billing: {
          active: false,
          message: 'Please add your billing address!',
          status: ''
        }
      }),
      paymentRequired = ref(!subscription.trial &&
          ((subscription.plans.basicNumber < 1) || 
          (subscription.plans.allinclusiveNumber < 1))),
      sameAddress = ref(false);

    const selectedPaymentMethod = ref(null);

    const initialFilling = ref(true);

    const subscriptionForm = ref(null);

    const paymentForm = ref(null);

    const billingForm = ref(null);

    const focusOnSubscription = ref(false);

    const focusOnPayment = ref(false);

    const focusOnBilling = ref(false);

    const stripeCard = ref();

    const stripeBank = ref();

    const stripeElements = ref();

    const cardName = ref('');

    const paymentActivated = ref(false);

    const onlyViewMode = ref(false);

    const changeMainField = (val, field) => {
      mainDetails[field] = val;
    }

    const changeSubscriptionField = (val, field, index) => {
      if (index) {
        subscription.modules[index].added = val;
      } else {
        subscription[field] = val;
      }
    }

    const changePaymentField = (val, field) => {
      payment[field] = val;
    }

    const changeBillingField = (val, field) => {
      billing[field] = val;
    }

    const collectCardPayment = (cardElement, elms) => {
      stripeCard.value = cardElement;
      stripeElements.value = elms;
      console.log(
        stripeCard.value,
        stripeElements.value
      )
    }

    const collectPayment = (paymentElement, elms) => {
      stripeBank.value = paymentElement;
      stripeElements.value = elms;
      console.log(
        stripeBank.value,
        stripeElements.value
      )
    }

    const confirmCardPayment = () => {
      console.log(stripeElements.value, stripeCard.value);
      // Access instance methods, e.g. createToken()
      // stripeElements.value.createToken(stripeCard.value).then((result) => {
      //   // Handle result.error or result.token
      //   console.log(result)
      // })

      // stripeElements.value.instance.confirmPayment({
      //   elements: stripeElements.value.elements,
      //   confirmParams: {
      //     return_url: "http://localhost:8080/user-profile",
      //   }
      // }).then((result) => {
      //   // Handle result.error or result.token
      //   console.log(result)
      // }).catch(e => console.log(e))

      // if (store.state.selectedPayment.paymentType === 'card') {

      // popupMessages.billing.active = true;
      // popupMessages.billing.status = 'loading';

      paymentActivated.value = true;

      stripeElements.value.instance.confirmCardPayment(
        store.state.subscriptionPlan.clientSecret,  
        {
          payment_method: {
            card: stripeCard.value,
            billing_details: {
              name: 'Jenny Rosen',
            }
          },
          // customer: store.state.primaryUserTenant.id
        }).then((result) => {
          // Handle result.error or result.token
          console.log(result)
        }).catch(e => console.log(e))
      // }

      // if (store.state.selectedPayment.paymentType === 'bank') {
      // }

    }

    const confirmPayment = () => {
      console.log(stripeElements.value, stripeBank.value);

      paymentActivated.value = true;
      popupMessages.billing.active = true;
      popupMessages.billing.status = 'loading';

      stripeElements.value.instance.confirmPayment({
        elements: stripeElements.value.elements,
        confirmParams: {
          return_url: `https://${store.state.authenticated.accountSubdomain}.${'bhoomiapp.com'}/user-profile`,
          payment_method_data: {
            billing_details: {
              name: cardName.value
            }
          }
        },
        redirect: 'if_required'
      }).then((result) => {
        // Handle result.error or result.token
        console.log(result);
        if (result.error) {
          popupMessages.billing.status = 'failure';
          popupMessages.billing.message = `${result.error.message}`;
        } else {
          popupMessages.billing.status = 'success';

          store.dispatch("savePaymentMethod", { payment, selectedPayment: selectedPayment.value });
          payment.submitted = true;
          store.dispatch("savePrimaryUser", { section: "payment", data: payment });
          payment.mode = 'represent';
          store.dispatch('changePrimaryPayment', {isChanged: false});
        }
      }).catch(e => {
        popupMessages.billing.status = 'failure';
        popupMessages.billing.message = `${e}`;
        console.error(e);
      })
    }

    const confirmSetup = () => {
      console.log(stripeElements.value, stripeBank.value);1

      paymentActivated.value = true;
      popupMessages.billing.active = true;
      popupMessages.billing.status = 'loading';

      stripeElements.value.instance.confirmSetup({
        elements: stripeElements.value.elements,
        confirmParams: {
          return_url: `https://${store.state.authenticated.accountSubdomain}.${'bhoomiapp.com'}/user-profile`,
          payment_method_data: {
            billing_details: {
              name: cardName.value
            }
          }
        },
        redirect: 'if_required'
      }).then((result) => {
        // Handle result.error or result.token
        console.log(result);
        if (result.error) {
          popupMessages.billing.status = 'failure';
          popupMessages.billing.message = `${result.error.message}`;
        } else {
          popupMessages.billing.status = 'success';

          store.dispatch("savePaymentMethod", { payment, selectedPayment: selectedPayment.value });
          payment.submitted = true;
          store.dispatch("savePrimaryUser", { section: "payment", data: payment });
          payment.mode = 'represent';
          store.dispatch('changePrimaryPayment', {isChanged: false});
        }
      }).catch(e => {
        popupMessages.billing.status = 'failure';
        popupMessages.billing.message = `${e}`;
        console.error(e);
      })
    }

    const createPaymentMethod = () => {

      paymentActivated.value = true;
      popupMessages.billing.active = true;
      popupMessages.billing.status = 'loading';

      console.log(stripeElements.value);

      stripeElements.value.instance.confirmSetup({
        elements: stripeElements.value.elements,
        confirmParams: {
          return_url: `https://${store.state.authenticated.accountSubdomain}.${'bhoomiapp.com'}/user-profile`,
          payment_method_data: {
            billing_details: {
              name: cardName.value
            }
          }
        },
        redirect: 'if_required'
      }).then((result) => {
        // Handle result.error or result.token
        console.log(result);
        if (result.error) {
          popupMessages.billing.active = true;
          popupMessages.billing.status = 'failure';
          popupMessages.billing.message = `${result.error.message}`;
        } else {
          popupMessages.billing.active = true;
          popupMessages.billing.status = 'success';

          // store.dispatch("savePaymentMethod", { payment, selectedPayment });
          // payment.submitted = true;
          // store.dispatch("savePrimaryUser", { section: "payment", data: payment });
          payment.mode = 'represent';
          store.dispatch('clearFuturePayment');
        }
      }).catch(e => {
        popupMessages.billing.status = 'failure';
        popupMessages.billing.message = `${e}`;
        console.error(e);
      })

      // stripeElements.value.instance
      //   .createPaymentMethod({
      //     type: 'card',
      //     card: stripeElements.value.elements,
      //     billing_details: {
      //       name: cardName.value
      //     },
      //   })
      //   .then(function(result) {
      //     // Handle result.error or result.paymentMethod
      //     if (result.error) {
      //       console.log(result.error);
      //       popupMessages.billing.active = true;
      //       popupMessages.billing.status = 'failure';
      //       popupMessages.billing.message = `${result.error.message}`;
      //     }

      //     if (result.paymentMethod) {
      //       store.dispatch('attachMethod', {paymentId: result.paymentMethod.id})
      //     }
      //   });

    }

    const redirectToHome = () => {
      popupMessages.addNew = false;

      router.push({ name: 'page', params: { pagename: 'dashboard' } })

    }

    provide('mainDetails', {
      mainDetails,
      defaultMainDetails,
      changeMainField,
      profileView: false
    })

    // provide('userRole', {
    //   userRole
    // })

    provide('subscription', {
      subscription,
      defaultSubscription,
      focusOnSubscription,
      paymentRequired,
      changeSubscriptionField
    })

    provide('payment', {
      payment,
      defaultPayment,
      selectedPayment,
      focusOnPayment,
      selectedPaymentMethod,
      changePaymentField
    })

    provide('stripeProps', {
      collectCardPayment,
      confirmCardPayment,
      collectPayment,
      confirmPayment,
      confirmSetup,
      cardName,
      createPaymentMethod
    })

    provide('billing', {
      billing,
      sameAddress,
      focusOnBilling,
      changeBillingField
    })

    provide('userProfileCommon', {
      onlyViewMode
    })

    watch(sameAddress, () => {
      if (sameAddress.value && mainDetails.submitted) {
        billing.sameAddress = true;
        for (let prop in billing) {
          if (mainDetails[prop] && mainDetails[prop].length > 0 && 
            (prop !== 'submitted') && (prop !== 'mode')) {
            changeBillingField(mainDetails[prop], prop);
          }
        }
        // billing.submitted = true;
        // store.dispatch('savePrimaryUser', {section: 'billing', data: billing});
      }
    })

    watch(selectedPaymentMethod, (newVal) => {
      if (store.state.primaryUser.billing) {
        if (newVal) {

          const currentBilling = store.state.primaryUser.billing.find(bil => bil.paymentMethodId === newVal);

          for (let prop in currentBilling) {
            if ((prop !== 'mode') && (prop !== 'submitted')) {
              billing[prop] = currentBilling[prop];
            }
          }
        }
      }
    });

    watch(() => store.state.authenticated.paymentMethods, (newVal, oldVal) => {
      const currentPayments = [...newVal].reverse();

      if ((selectedPaymentMethod.value === null) && (currentPayments.length > 0)) {
        selectedPaymentMethod.value = currentPayments[currentPayments.length - 1].id;
      }
      if (newVal.length > oldVal.length) {
        for (let prop in defaultBilling) {
          billing[prop] = defaultBilling[prop];
        }
        if (store.state.primaryUser.billing.length > 0) {
          if ((selectedPaymentMethod.value === null) || (selectedPaymentMethod.value === undefined)) {
            const currentPaymentMethod = newVal[0];

            if (currentPaymentMethod) {
              // selectPaymentMethod(currentPaymentMethod.id);
              selectedPaymentMethod.value = currentPaymentMethod.id;
        
              store.dispatch('selectPaymentMethod', currentPaymentMethod.id);

              billing.mode = 'represent';
            }
          }
        }
      }
    }, { deep: true });

    watch(() => ({...store.state.primaryUser.mainDetails}), (newVal) => {
      console.log(store.state.primaryUser.mainDetails, 'store.state.mainDetails');
      if (billing.sameAddress) {console.log
        console.log('billing.sameAddress');
        for (let prop in billing) {
          if (newVal[prop] && newVal[prop].length > 0 && 
            (prop !== 'submitted') && (prop !== 'mode')) {
            console.log('billing.sameAddress entering');
            changeBillingField(newVal[prop], prop);
          }
        }
      }
    })

    watchEffect(() => {
      const allFormsSubmitted = (mainDetails.submitted  || store.state.primaryUser?.mainDetails?.submitted) && 
        (subscription.submitted || store.state.primaryUser?.subscription?.submitted) && 
        (payment.submitted || store.state.primaryUser?.payment?.submitted) && 
        (store.state.primaryUser?.billing?.length > 0);

      if (mainDetails.submitted && initialFilling.value) {
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: subscriptionForm?.value?.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        })

        if (!subscription.submitted) {
          popupMessages.subscription.active = true
          changeSubscriptionField('edit', 'mode')

          focusOnSubscription.value = true;

          setTimeout(() => {
            popupMessages.subscription.active = false
          }, 5100)
        }
      }

      if (subscription.submitted && initialFilling.value) {
        
        if (!allFormsSubmitted) {
          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: paymentForm?.value?.offsetTop,
              left: 0,
              behavior: 'smooth'
            })
          })
        }

        if (!store.state.primaryUser?.payment?.submitted && !paymentActivated.value) {
          if (store.state.authenticated.paymentMethods.length === 0) {
            popupMessages.payment.active = true;
            changePaymentField('edit', 'mode');
          }
          changeSubscriptionField('represent', 'mode')

          focusOnPayment.value = true;

          if (store.state.authenticated.paymentMethods.length === 0) {
            setTimeout(() => {
              popupMessages.payment.active = false;
            }, 5100)
          }
        }
      }

      if ((payment.submitted || store.state.primaryUser?.payment?.submitted) && initialFilling.value) {

        if (!allFormsSubmitted) {
          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: billingForm?.value?.offsetTop - 100,
              left: 0,
              behavior: 'smooth'
            })
          })
        }

        if (!(store.state.primaryUser?.billing?.length > 0)) {
          popupMessages.billing.active = true
          changeBillingField(true, 'expanded')
          changePaymentField('represent', 'mode')

          focusOnBilling.value = true;

          setTimeout(() => {
            popupMessages.billing.active = false
          }, 5100)
        }
      }

      
    });

    // watch(() => ({...billing}), (newVal, oldVal) => {
    watch(() => ([...store.state.primaryUser?.billing]), (newVal, oldVal) => {
      console.log(oldVal.submitted, newVal.submitted, 'oldVal.submitted, newVal.submitted')
      if (newVal) { //!oldVal.submitted && 
        const allFormsSubmitted = (mainDetails.submitted  || store.state.primaryUser?.mainDetails?.submitted) && 
        (subscription.submitted || store.state.primaryUser?.subscription?.submitted) && 
        (payment.submitted || store.state.primaryUser?.payment?.submitted) && 
        (store.state.primaryUser?.billing?.length > 0);
        
        if (allFormsSubmitted && initialFilling.value && (router.currentRoute.value.params.redirectSource !== 'user-subscription')) {
          console.log(allFormsSubmitted, 'allFormsSubmitted', initialFilling.value, 'initialFilling.value');

          submittedUserProfile.value = true;
          // if ((mainDetails.mode !== 'edit') && (subscription.mode !== 'edit') && 
          //   (billing.mode !== 'edit')
          // ) {
            if (!subscription.plans.basic && !subscription.plans.plus &&
              !subscription.plans.advance && !subscription.plans.ultimate
            ) {
              if (subscription.plans.trial) {
                popupMessages.purchase.message = 'Thank you, your free 30-day trial subscription has been added to your account.'
              }
            }
            popupMessages.purchase.active = true;

            setTimeout(() => {
              popupMessages.purchase.active = false;
              initialFilling.value = false;

              if (!store.state.primaryUser.subscriptionAssigned) {
                router.push({
                  name: 'page',
                  params: {
                    pagename: 'user-subscription',
                    source: 'primary-user'
                  }
                });
                return;
              }

              // popupMessages.addNew = true;
            }, 5000)
          // }
        }
      }
    });

    watch(() => store.state.subscriptionPlan, (newVal, oldVal) => {

      // let isChanged;

      // for (let oldProp in oldVal) {
      //   if (oldVal[oldProp]) {
      //     for (let newProp in newVal) {
      //       if (newVal[newProp]) {
      //         // isChanged = true;
      //         if (!store.state.primaryPaymentIsChanged) {
      //           store.dispatch('changePrimaryPayment', {isChanged: true});
      //         }
      //       }
      //     }     
      //   }
      // }

      const previousState = Object.entries(oldVal);
      const currentState = Object.entries(newVal);

      if (previousState.length > 0) {
        if (currentState.length > 0) {
          // isChanged = true;
          if (!store.state.primaryPaymentIsChanged) {
            store.dispatch('changePrimaryPayment', {isChanged: true});
          }
        }
      }

      // move to watch
      if (store.state.primaryPaymentIsChanged) { // isChanged
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: paymentForm?.value?.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        })

        if (store.state.authenticated.paymentMethods.length === 0 && !paymentActivated.value) {
          popupMessages.payment.active = true
          changePaymentField('edit', 'mode')
        }
        changeSubscriptionField('represent', 'mode')

        focusOnPayment.value = true;
      }
    }, { deep: true });

    watch(() => store.state.attachedPayments, (newVal, oldVal) => {
      if (newVal.length > oldVal.length) {
        payment.mode = 'represent';
      }
    })

    watch(() => ({...store.state.primaryUser}), (newVal) => {
      if (newVal) {
        const params = new URLSearchParams(document.location.search);

        const redirected = params.get('redirected');

        // if (redirected === '1') {
        //   const baseRoute = router.currentRoute.value.fullPath.split('&redirected=1')[0];
        //   setTimeout(() => {
        //     router.push(`${baseRoute}&redirected=2`);
        //     setTimeout(() => {
        //       router.go();
        //     });
        //   });
        // }

        // if (redirected === '2') {
        //   popupMessages.addNew = true;
        //   initialFilling.value = false;
        //   submittedUserProfile.value = true;
        // }

        if (redirected === '3') {
          const baseRoute = router.currentRoute.value.fullPath.split('&redirected=3')[0];
          setTimeout(() => {
            router.push(`${baseRoute}`);
            setTimeout(() => {
              router.go();
            });
          });
        }
      }
    });

    const createNewUser = () => {
      router.push({ 
        name: 'page', 
        params: { 
          pagename: 'secondary-user', 
          source: 'primary-user' 
        } 
      })
      // router.push({ name: 'admin', params: { pagename: 'admin-users' } })
    }

    const createNewFarm = () => {
      router.push({ name: 'farm', params: { pagename: 'farm-profile', source: 'user-profile' } })
    }

    const openAdditionalForm = () => {
      additionalFormOpened.value = !additionalFormOpened.value;
    };

    // const submitProfileForms = () => {
    //   changeSubscriptionField(true, 'submitted');
    //   changePaymentField(true, 'submitted');
    //   changeBillingField(true, 'submitted');
    // };

    onMounted(() => {
      const params = new URLSearchParams(document.location.search);
      const user_id = params.get("user_id");
      const token = params.get("token");
      const resetPassword = params.get("resetpassword");

      if (token && user_id) {
        store.dispatch('authenticateUser', {
          user_id,
          token
        })
      }

      if (router.currentRoute.value.params.source === 'admin-users') {
        onlyViewMode.value = true;
      }

      console.log(router.currentRoute.value.fullPath, 'fullPath');

      if (store.state.authenticated.paymentMethods.length > 0) {
        payment.mode = 'represent';
      }

      if ((mainDetails.submitted  || store.state.primaryUser?.mainDetails?.submitted) && 
        (subscription.submitted || store.state.primaryUser?.subscription?.submitted) && 
        (payment.submitted || store.state.primaryUser?.payment?.submitted) && 
        (store.state.primaryUser?.billing?.length > 0)) {
          initialFilling.value = false;
        }

      if (
        // store.state.primaryUser?.mainDetails?.submitted && 
        // store.state.primaryUser?.subscription?.submitted && 
        // (store.state.authenticated.paymentMethods.length > 0) &&
        // store.state.primaryUser?.billing?.submitted &&
        router.currentRoute.value.params.redirectSource === 'user-subscription'
      ) {
        popupMessages.addNew = true;
        initialFilling.value = false;
        submittedUserProfile.value = true;
      }

      if (store.state.authenticated.paymentMethods.length > 0) {
        const currentPayments = [...store.state.authenticated.paymentMethods].reverse();

        if ((selectedPaymentMethod.value === null) && (currentPayments.length > 0)) {
          selectedPaymentMethod.value = currentPayments[currentPayments.length - 1].id;
        }

        if (store.state.primaryUser.billing) {
          const currentBilling = store.state.primaryUser.billing.find(bil => bil.paymentMethodId === selectedPaymentMethod.value) || store.state.primaryUser.billing[0];

          console.log(store.state.primaryUser.billing, 'currentBilling')

          for (let prop in currentBilling) {
            if ((prop !== 'mode') && (prop !== 'submitted')) {
              billing[prop] = currentBilling[prop];
            }
          }
        }
      }
      
      if (resetPassword) {
        router.push({ name: 'page', params: { pagename: 'dashboard' } })
      }

      console.log(
        subscriptionForm,
        paymentForm,
        billingForm,
        subscriptionForm?.value?.offsetTop,
        paymentForm?.value?.offsetTop,
        billingForm?.value?.offsetTop
      )
    })

    return {
      mainDetails,
      subscription,
      payment,
      billing,
      additionalFormOpened,

      popupMessages,
      submittedUserProfile,
      paymentRequired,
      createNewUser,
      createNewFarm,
      openAdditionalForm,

      subscriptionForm,
      paymentForm,
      billingForm,
      isAuth,
      redirectToHome,
      selectedPaymentMethod
    };
  },
};
</script>

<style lang="scss" scoped>
.add-new-popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    .popup-message {
      background: #fff;
      display: flex;
      flex-direction: column;
      max-width: 650px;
      width: 100%;
      height: max-content;
      justify-content: space-around;
      align-items: center;
      position: relative;
      border-radius: 11px;
      color: rgba(40,41,43,1);
      font-size: 21px;
      margin: 0 20px;
      padding: 25px 0;
      text-align: center;
      .close-button {
        position: absolute;
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: auto;
        width: 30px;
        height: 30px;
        background-image: url('@/assets/profile/close_button.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        right: 10px;
        top: 22px;
        cursor: pointer;
      }
      .popup-text {
        margin: 0 40px;
        font-size: 16px;
        font-weight: 600;
      }
      .popup-buttons {
        display: flex;
        width: 60%;
        justify-content: space-evenly;
        margin-top: 30px;
        button {
          width: 160px;
          height: 40px;
          border-radius: 10px;
          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }
.user-profile {
  // padding: 12px 24px 24px;
  padding: 12px 2.083333333vw 24px;
  form {
    flex-direction: column;
    flex-wrap: nowrap;
    .add-details {
      width: max-content;
      align-self: end;
      margin-bottom: 24px;
    }
    .form-actions {
      margin-top: 24px;
      button {
        margin-right: 24px;
      }
    }
  }
  .q-expansion-item--popup.q-expansion-item--expanded,
  .q-expansion-item--popup.q-expansion-item--collapsed {
    padding: 0;
  }
  .q-expansion-item {
    margin-bottom: 45px;
  }
  .add-new-popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    .popup-message {
      background: #fff;
      display: flex;
      flex-direction: column;
      max-width: 650px;
      width: 100%;
      height: max-content;
      justify-content: space-around;
      align-items: center;
      position: relative;
      border-radius: 11px;
      color: rgba(40,41,43,1);
      font-size: 21px;
      margin: 0 20px;
      padding: 25px 0;
      text-align: center;
      .close-button {
        position: absolute;
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: auto;
        width: 30px;
        height: 30px;
        background-image: url('@/assets/profile/close_button.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        right: 10px;
        top: 22px;
        cursor: pointer;
      }
      .popup-text {
        margin: 0 40px;
        font-size: 16px;
        font-weight: 600;
      }
      .popup-buttons {
        display: flex;
        width: 60%;
        justify-content: space-evenly;
        margin-top: 30px;
        button {
          width: 160px;
          height: 40px;
          border-radius: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 757px) {
  .user-profile {
    padding: 12px 12px 0;
    .q-expansion-item {
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-width: 420px) {
  .user-profile {
    padding: 12px 12px 0;
  }
}

</style>
