<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Advertisement Account</h5>
<div class="icon-group">
  <div class="icon green icon-o camera-plus"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
  >
    <img src="@/assets/images/camera-plus.svg" alt="">
    <div class="helper-tooltip" v-if="helperTooltip">Take a shot</div>
  </div>
</div>
</div>

<div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.accountName || (mainDetails.accountName.length > 0)}">Account Name</p>
      <input 
        type="text" 
        class="form-control" 
        v-model="mainDetails.accountName"
        @focus="() => {
          activated.accountName = true;
          focused.accountName = true;
        }"
        @blur="() => {
          focused.accountName = false;
        }"
      >
      <p v-if="activated.accountName && mainDetails.accountName.length === 0" class="form-info red text-end">* Required</p>
      <p v-if="activated.accountName && mainDetails.accountName.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>
  </div>

  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.industry || (mainDetails.industry.length > 0)}">Industry</p>
      <!-- <input 
        type="text" 
        class="form-control" 
        v-model="mainDetails.industry"
        @focus="() => {
          activated.industry = true;
          focused.industry = true;
        }"
        @blur="() => {
          focused.industry = false;
        }"
      > -->

      <!-- <select class="form-control" 
        v-model="mainDetails.industry"
        @click="() => {
          activated.industry = true;
          focused.industry = true;
        }"
        @change="() => {
          focused.industry = false;
        }"
      >
        <option v-for="industryOption in industryOptions"
          v-bind:key="industryOption + 'industry-option-adv'"
        >{{ industryOption }}</option>
      </select> -->
      <DropDownList
        :mainObject="'mainDetails'"
        :mainProperty="'industry'"
        :injectionName="'mainDetails'"
        :optionKey="'industry-option-adv'"
        :optionsList="industryOptions"
        :optionProperty="null"
        :optionPropertyTwo="null"
        @click="() => {
          activated.industry = true;
        }"
      />
      <p v-if="activated.industry && mainDetails.industry.length === 0" class="form-info red text-end">* Required</p>
      <p v-if="activated.industry && mainDetails.industry.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>
  </div>
  
  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.companySize || (mainDetails.companySize.length > 0)}">Company Size</p>
      <!-- <input 
        type="text" 
        class="form-control" 
        v-model="mainDetails.companySize"
        @focus="() => {
          activated.companySize = true;
          focused.companySize = true;
        }"
        @blur="() => {
          focused.companySize = false;
        }"
      > -->

      <!-- <select class="form-control" 
        v-model="mainDetails.companySize"
        @click="() => {
          activated.companySize = true;
          focused.companySize = true;
        }"
        @change="() => {
          focused.companySize = false;
        }"
      >
        <option v-for="companySizeOption in companySizeOptions"
          v-bind:key="companySizeOption + 'company-size-option-adv'"
        >{{ companySizeOption }}</option>
      </select> -->
      <DropDownList
        :mainObject="'mainDetails'"
        :mainProperty="'companySize'"
        :injectionName="'mainDetails'"
        :optionKey="'company-size-option-adv'"
        :optionsList="companySizeOptions"
        :optionProperty="null"
        :optionPropertyTwo="null"
        @click="() => {
          activated.companySize = true;
        }"
      />

      <p v-if="activated.companySize && mainDetails.companySize.length === 0" class="form-info red text-end">* Required</p>
      <p v-if="activated.companySize && mainDetails.companySize.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>
  </div>

  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.firstName || (mainDetails.firstName.length > 0)}">First Name</p>
      <input 
        type="text" 
        class="form-control" 
        v-model="mainDetails.firstName"
        @focus="() => {
          activated.firstName = true;
          focused.firstName = true;
        }"
        @blur="() => {
          focused.firstName = false;
        }"
      >
      <p v-if="activated.firstName && mainDetails.firstName.length === 0" class="form-info red text-end">* Required</p>
      <p v-if="activated.firstName && mainDetails.firstName.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>
  </div>

  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.lastName || (mainDetails.lastName.length > 0)}">Last Name</p>
      <input 
        type="text" 
        class="form-control" 
        v-model="mainDetails.lastName"
        @focus="() => {
          activated.lastName = true;
          focused.lastName = true;
        }"
        @blur="() => {
          focused.lastName = false;
        }"
      >
      <p v-if="activated.lastName && mainDetails.lastName.length === 0" class="form-info red text-end">* Required</p>
      <p v-if="activated.lastName && mainDetails.lastName.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>
  </div>

  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.title || (mainDetails.title.length > 0)}">Title</p>
      <input 
        type="text" 
        class="form-control" 
        v-model="mainDetails.title"
        @focus="() => {
          activated.title = true;
          focused.title = true;
        }"
        @blur="() => {
          focused.title = false;
        }"
      >
      <p v-if="activated.title && mainDetails.title.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
    </div>
  </div>

  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.workPhone || (mainDetails.workPhone.length > 0)}">Work Phone</p>
      <input 
        type="text" 
        class="form-control" 
        pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
        v-model="mainDetails.workPhone"
        @focus="() => {
          activated.workPhone = true;
          focused.workPhone = true;
        }"
        @blur="() => {
          phoneNumberFormat('workPhone')
          focused.workPhone = false;
        }"
      >
      <p v-if="activated.workPhone && mainDetails.workPhone.length === 0" class="form-info red text-end">* Required</p>
      <p v-if="activated.workPhone && ((mainDetails.workPhone.length > 0) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(mainDetails.workPhone))" class="form-info red text-end">Please use correct format</p>
    </div>
  </div>

  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.mobilePhone || (mainDetails.mobilePhone.length > 0)}">Mobile Phone</p>
      <input 
        type="text" 
        class="form-control" 
        pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
        v-model="mainDetails.mobilePhone"
        @focus="() => {
          activated.mobilePhone = true;
          focused.mobilePhone = true;
        }"
        @blur="() => {
          phoneNumberFormat('mobilePhone')
          focused.mobilePhone = false;
        }"
      >
      <p v-if="activated.mobilePhone && mainDetails.mobilePhone.length === 0" class="form-info red text-end">* Required</p>
      <p v-if="activated.mobilePhone && ((mainDetails.mobilePhone.length > 0) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(mainDetails.mobilePhone))" class="form-info red text-end">Please use correct format</p>
    </div>
  </div>

  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.address || (mainDetails.address.length > 0)}">Address 1</p>
      <input 
        type="text" 
        class="form-control" 
        v-model="mainDetails.address"
        @focus="() => {
          activated.address = true;
          focused.address = true;
        }"
        @blur="() => {
          focused.address = false;
        }"
      >
      <p v-if="activated.address && mainDetails.address.length === 0" class="form-info red text-end">* Required</p>
      <p v-if="activated.address && mainDetails.address.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
    </div>
  </div>

  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.address2 || (mainDetails.address2.length > 0)}">Address 2</p>
      <input 
        type="text" 
        class="form-control" 
        v-model="mainDetails.address2"
        @focus="() => {
          activated.address2 = true;
          focused.address2 = true;
        }"
        @blur="() => {
          focused.address2 = false;
        }"
      >
      <p v-if="activated.address2 && mainDetails.address2.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
    </div>
  </div>

  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.city || (mainDetails.city.length > 0)}">City</p>
      <input 
        type="text" 
        class="form-control" 
        v-model="mainDetails.city"
        @focus="() => {
          activated.city = true;
          focused.city = true;
        }"
        @blur="() => {
          focused.city = false;
        }"
      >
      <p v-if="activated.city && mainDetails.city.length === 0" class="form-info red text-end">* Required</p>
      <p v-if="activated.city && mainDetails.city.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>
  </div>

  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.state || (mainDetails.state.length > 0)}">State</p>
      <!-- <select class="form-control" 
        v-model="mainDetails.state"
        @click="() => {
          activated.state = true;
          focused.state = true;
        }"
        @change="() => {
          focused.state = false;
        }"
      >
        <option v-for="stateOption in stateOptions"
          v-bind:key="stateOption + 'state-option-adv'"
        >{{ stateOption }}</option>
      </select> -->
      <DropDownList
        :mainObject="'mainDetails'"
        :mainProperty="'state'"
        :injectionName="'mainDetails'"
        :optionKey="'state-option-adv'"
        :optionsList="stateOptions"
        :optionProperty="null"
        :optionPropertyTwo="null"
        @click="() => {
          activated.state = true;
        }"
      />
      <p v-if="activated.state && mainDetails.state.length === 0" class="form-info red text-end">* Required</p>
    </div>
  </div>

  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.zip || (mainDetails.zip.length > 0)}">Zip</p>
      <input 
        type="text" 
        class="form-control" 
        v-model="mainDetails.zip"
        @focus="() => {
          activated.zip = true;
          focused.zip = true;
        }"
        @blur="() => {
          focused.zip = false;
        }"
      >
      <p v-if="activated.zip && mainDetails.zip.length === 0" class="form-info red text-end">* Required</p>
      <p v-if="activated.zip && mainDetails.zip.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
  </div>

  <div class="col-3">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.country || (mainDetails.country.length > 0)}">Country</p>
      <!-- <select class="form-control" 
        v-model="mainDetails.country"
        @click="() => {
          activated.country = true;
          focused.country = true;
        }"
        @change="() => {
          focused.country = false;
        }"
      >
        <option
          v-for="countryOption in countryOptions"
          v-bind:key="countryOption + 'country-option-adv'"
        >{{ countryOption }}</option>
      </select> -->
      <DropDownList
        :mainObject="'mainDetails'"
        :mainProperty="'country'"
        :injectionName="'mainDetails'"
        :optionKey="'country-option-adv'"
        :optionsList="countryOptions"
        :optionProperty="null"
        :optionPropertyTwo="null"
        @click="() => {
          activated.country = true;
        }"
      />
      <p v-if="activated.country && mainDetails.country.length === 0" class="form-info red text-end">* Required</p>
    </div>
  </div>

</div>
</div>
</div>

</div>

<div class="content-block">
<div class="user-role">
<div class="row">
<div class="col-auto">
<p class="title">User Role:</p>
</div>
<div class="col">
<div class="row">
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="admin" id="admin" v-model="mainDetails.userRole"><label for="admin">Admin</label></div></div>
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="edit" id="editonly" v-model="mainDetails.userRole"><label for="editonly">Edit Only</label></div></div>
    <!-- <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="contribute" id="contributeonly" v-model="mainDetails.userRole"><label for="contributeonly">Contribute Only</label></div></div> -->
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="read" id="readonly" v-model="mainDetails.userRole"><label for="readonly">Read only</label></div></div>
</div>
</div>
</div>
</div>
</div>

<div class="content-block">

<FileAttachments :section="'advertisement'" />

<div class="content-block">
<div class="buttons">
<div class="row">
<div class="col-auto"><button @click="submitForm" type="submit" class="btn green">Save</button></div>
<div class="col-auto"><button @click="cancelChanges" type="submit" class="btn light-blue-20 btn-o">Cancel</button></div>
</div>
</div>
</div>

</div>

</div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { defineComponent, inject, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import stateOptions from '@/sharedData/statesList.js'
import countryOptions from '@/sharedData/countriesList.js'
import industryOptions from '@/sharedData/industriesList.js'
import companySizeOptions from '@/sharedData/companySizesList.js'
import DropDownList from '@/components/Shared/DropDownList.vue';

// import { v4 as uuid } from 'uuid';

export default defineComponent({
    name: 'AdvertisementAccountMainDetails',
    components: { FileAttachments, DropDownList },
    setup() {
      const store = useStore();
      const { mainDetails, changeMainField } = inject("mainDetails");
      const defaultMainDetails = { ...mainDetails };
      const emailValidation = ref(true);
      const phoneValidation = ref(false);

      const activated = reactive({
        accountName: false,
        companySize: false,
        industry: false,
        title: false,
        firstName: false,
        lastName: false,
        workPhone: false,
        mobilePhone: false,
        address: false,
        address2: false,
        city: false,
        state: false,
        zip: false,
        country: false
      });
      const focused = reactive({
        accountName: false,
        companySize: false,
        industry: false,
        title: false,
        firstName: false,
        lastName: false,
        workPhone: false,
        mobilePhone: false,
        address: false,
        address2: false,
        city: false,
        state: false,
        zip: false,
        country: false
      });

      const helperTooltip = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const phoneNumberFormat = (phoneType) => {
        if (mainDetails[phoneType].length > 0) {
          if (mainDetails[phoneType][0] !== "(") {
            const arrStr = mainDetails[phoneType].split("");
            mainDetails[phoneType] = `(${arrStr.filter((num, indx) => {
                if (indx < 3)
                  return num;
            }).join("")}) ${arrStr.filter((num, indx) => {
                if ((indx >= 3) && (indx < 6))
                    return num;
            }).join("")}-${arrStr.filter((num, indx) => {
                if (indx >= 6)
                    return num;
            }).join("")}`;
          }
        }
      };
      const cancelChanges = () => {
          for (let prop in defaultMainDetails) {
              mainDetails[prop] = defaultMainDetails[prop];
          }
          changeMainField("represent", "mode");
      };
      const submitForm = () => {
          if (!mainDetails.firstName ||
              !mainDetails.lastName || !mainDetails.mobilePhone ||
              !mainDetails.address || !mainDetails.city ||
              !mainDetails.state || !mainDetails.zip || !mainDetails.country) {
              if (!mainDetails.firstName) activated.firstName = true;
              if (!mainDetails.lastName) activated.lastName = true;
              if (!mainDetails.mobilePhone) activated.mobilePhone = true;
              if (!mainDetails.address) activated.address = true;
              if (!mainDetails.city) activated.city = true;
              if (!mainDetails.state) activated.state = true;
              if (!mainDetails.zip) activated.zip = true;
              if (!mainDetails.country) activated.country = true;
              return;
          }
          changeMainField(true, "submitted");

          const formData = {
            ...mainDetails
          }

          // if (!store.state.advertisementAccount?.mainDetails?.id) {
          //   formData.id = uuid();
          // }

          store.dispatch("saveAdvertisementAccount", {
              section: "mainDetails",
              data: {
                ...formData
              }
          });

          store.state.primaryUserTenant.id ?
            store.dispatch('updateTenant', {
              data: {
                ...formData
              },
              section: 'mainDetails'
            }) :
            store.dispatch('createTenant', {
              data: {
                ...formData
              },
              section: 'mainDetails'
            });

          changeMainField("represent", "mode");
          // setTimeout(() => {
          //   changeMainField(true, 'activated')
          // }, 2000);
      };

      watch(emailValidation, (newVal, oldVal) => {
          console.log(newVal, oldVal);
          phoneValidation.value = !emailValidation.value;
      });
      watch(phoneValidation, (newVal, oldVal) => {
          console.log(newVal, oldVal);
          emailValidation.value = !phoneValidation.value;
      });
      return {
          mainDetails,
          stateOptions,
          countryOptions,
          industryOptions,
          companySizeOptions,

          emailValidation,
          phoneValidation,
          submitForm,
          cancelChanges,
          changeMainField,
          phoneNumberFormat,
          activated,
          focused,
          helperTooltip,
          switchHelperTooltip
      };
    }
})
</script>