<template>

  <!-- <section class="hero">
  <div class="hero-block">
  <div class="hero-banner">
    <img :src="profileBgImg" alt="">
  </div>
  <a href="javascript:void();" class="hero-edit">
    <input
      type="file"
      name="backgroundupload"
      class="background-file"
      ref="uploadbg"
      accept=".jpg,.png,.jpeg"
      @change="uploadImgDirect"
    />
    <img src="@/assets/images/edit-profile-xs.svg" alt="">
  </a>
  </div>
  </section> -->

  <div class="content-block">
    <h5 class="fw-semibold">Profit and Loss Reports</h5>
  </div>

  <div class="tab-links mt-1">
  <div class="border-box">
  <!-- <router-link 
    :to="{ name: 'reports', params: { pagename: 'reports-sales' } }"    
    :class="{active: route.params.pagename === 'reports-sales'}" data-tag="one">Sales</router-link> -->
  <router-link 
    :to="{ name: 'reports', params: { pagename: 'reports-module' } }"    
    :class="{active: route.params.pagename === 'reports-module'}" data-tag="one">Sales</router-link>
  <router-link 
    :to="{ name: 'reports', params: { pagename: 'reports-crops' } }"
    :class="{active: route.params.pagename === 'reports-crops'}" data-tag="two">Crops</router-link>
  <router-link 
    :to="{ name: 'reports', params: { pagename: 'reports-expenses' } }"
    :class="{active: route.params.pagename === 'reports-expenses'}" data-tag="two">Expenses</router-link>
  </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ResourcesHeader",
  setup() {
    const store = useStore();
    const route = useRoute();
    // const { isAuth } = inject('mainUI');

    const dynamicBgName = ref(require("@/assets/profile/background.png")); // take from store by id
    const profileBg = ref(`url(${dynamicBgName.value})`);
    const profileBgImg = ref(require('@/assets/images/hero-banner.jpg'));

    const uploadImgDirect = (ev) => {
      console.log(ev);
      store.dispatch("uploadMedia", {
        file: ev.target.files[0],
        place: "background-sales",
      });
      createPreview(ev);
    };

    const createPreview = (e) => {
      const reader = new FileReader();
      // let fileName = e.target.value.split("\\");
      // fileName = fileName[fileName.length - 1].split(".")[0];

      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
      reader.onload = function () {
        setTimeout(() => {
          profileBg.value = `url(${reader.result})`;
          profileBgImg.value = `${reader.result}`;
          console.log(reader.result);
        }, 500);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    };

    let dynamicAvatarName = "user-avatar.png"; // take it from id dynamic

    const profileAvatar = computed(() => {
      return require("@/assets/profile/" + dynamicAvatarName);
    });

    onMounted(() => {
      console.log(route.params.pagename)
    })

    return {
      profileBg,
      profileBgImg,
      uploadImgDirect,
      profileAvatar: `url(${profileAvatar.value})`,
      route,
      // isAuth
    }
  }
};
</script>

<style lang="scss" scoped>
.background-file {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 50%;
  cursor: pointer;
}

h5 {
  font-size: var(--h5) !important;
}
</style>