<template>
      <ProfileHeader v-bind:openAdditionalForm="openAdditionalForm" />
    
      <ProfileDetailsForm v-if="mainDetails.mode === 'edit'" />
      <!--  && isAuth('edit-details') -->
      <ProfileDetails v-else />

    <!-- <div class="add-new-popup" v-if="submittedUserProfile && popupMessages.addNew">
      
      <div class="modal modal-md">
      <span class="modal-close" @click="() => {
        popupMessages.addNew = false;
        initialFilling = false;  
        redirectToHome();
      }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
      <div class="modal-block text-center">
      <p class="fw-bold">Add another user or create farm profile</p>
      </div>
      <div class="modal-block text-center">
      <div class="buttons justify-content-center">
          <button
            @click="() => createNewUser()"
            class="approve-button btn green" 
          >Add user</button>
          <button
            @click="() => createNewFarm()" 
            class="cancel-removal-button btn green btn-o" 
          >Add farm</button>
      </div>
      </div>
      </div>

    </div> -->
</template>

<script>
import { onMounted, provide, reactive, ref, watch, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ProfileDetails from "@/components/Pages/UserProfile/ProfileDetails.vue";
import ProfileDetailsForm from "@/components/Pages/UserProfile/ProfileDetailsForm.vue";
import ProfileHeader from "./ProfileHeader.vue";

export default {
  name: "MenuProfile",
  components: {
    ProfileDetailsForm,
    ProfileDetails,
    ProfileHeader
},
  setup() {
    const store = useStore();
    const router = useRouter();

    const { isAuth } = inject('mainUI');

    const  mainDetails = reactive({
        accountName: '',
        title: '',
        
        email: '',
        password: '',
        // email: '',
        userRole: 'admin',
        multifactor: false,
        multifactorType: '',

        firstName: '',
        lastName: '',
        address: '',
        address2: '',
        mobilePhone: '',
        workPhone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        employeeType: '',
        grade: '',

        submitted: false,
        activated: false,
        mode: (store.state.primaryUser?.mainDetails?.submitted ||
          store.state.primaryUser?.mainDetails?.email) ? 'represent' : 'edit'
      });

      const defaultMainDetails = {
        ...mainDetails
      }

    // const userRole = reactive({
    //   roles: ['admin', 'edit', 'contribute', 'read'],
    //   selected: 'admin',
    //   mode: mainDetails.submitted ? 'represent' : 'edit'
    // });


    const additionalFormOpened = ref(false),
      submittedUserProfile = ref(false);

    const changeMainField = (val, field) => {
      mainDetails[field] = val;
    }

    const redirectToHome = () => {
      // popupMessages.addNew = false;

      router.push({ name: 'page', params: { pagename: 'dashboard' } })

    }

    provide('mainDetails', {
      mainDetails,
      defaultMainDetails,
      changeMainField,
      profileView: true
    })

    provide('userProfileCommon', {
      onlyViewMode: false
    })

    watch(() => ({...store.state.primaryUser}), (newVal) => {
      if (newVal) {
        const params = new URLSearchParams(document.location.search);

        const redirected = params.get('redirected');

        if (redirected === '3') {
          const baseRoute = router.currentRoute.value.fullPath.split('&redirected=3')[0];
          setTimeout(() => {
            router.push(`${baseRoute}`);
            setTimeout(() => {
              router.go();
            });
          });
        }
      }
    });

    // const createNewUser = () => {
    //   router.push({ 
    //     name: 'page', 
    //     params: { 
    //       pagename: 'secondary-user', 
    //       source: 'primary-user' 
    //     } 
    //   })
    // }

    // const createNewFarm = () => {
    //   router.push({ name: 'farm', params: { pagename: 'farm-profile', source: 'user-profile' } })
    // }

    const openAdditionalForm = () => {
      additionalFormOpened.value = !additionalFormOpened.value;
    };

    onMounted(() => {
      // 
      store.dispatch('getAttachments');
    })

    return {
      mainDetails,
      submittedUserProfile,
      // createNewUser,
      // createNewFarm,
      openAdditionalForm,

      isAuth,
      redirectToHome
    };
  },
};
</script>

<style lang="scss" scoped>
.add-new-popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    .popup-message {
      background: #fff;
      display: flex;
      flex-direction: column;
      max-width: 650px;
      width: 100%;
      height: max-content;
      justify-content: space-around;
      align-items: center;
      position: relative;
      border-radius: 11px;
      color: rgba(40,41,43,1);
      font-size: 21px;
      margin: 0 20px;
      padding: 25px 0;
      text-align: center;
      .close-button {
        position: absolute;
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: auto;
        width: 30px;
        height: 30px;
        background-image: url('@/assets/profile/close_button.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        right: 10px;
        top: 22px;
        cursor: pointer;
      }
      .popup-text {
        margin: 0 40px;
        font-size: 16px;
        font-weight: 600;
      }
      .popup-buttons {
        display: flex;
        width: 60%;
        justify-content: space-evenly;
        margin-top: 30px;
        button {
          width: 160px;
          height: 40px;
          border-radius: 10px;
          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }
.user-profile {
  // padding: 12px 24px 24px;
  padding: 12px 2.083333333vw 24px;
  form {
    flex-direction: column;
    flex-wrap: nowrap;
    .add-details {
      width: max-content;
      align-self: end;
      margin-bottom: 24px;
    }
    .form-actions {
      margin-top: 24px;
      button {
        margin-right: 24px;
      }
    }
  }
  .q-expansion-item--popup.q-expansion-item--expanded,
  .q-expansion-item--popup.q-expansion-item--collapsed {
    padding: 0;
  }
  .q-expansion-item {
    margin-bottom: 45px;
  }
  .add-new-popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    .popup-message {
      background: #fff;
      display: flex;
      flex-direction: column;
      max-width: 650px;
      width: 100%;
      height: max-content;
      justify-content: space-around;
      align-items: center;
      position: relative;
      border-radius: 11px;
      color: rgba(40,41,43,1);
      font-size: 21px;
      margin: 0 20px;
      padding: 25px 0;
      text-align: center;
      .close-button {
        position: absolute;
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: auto;
        width: 30px;
        height: 30px;
        background-image: url('@/assets/profile/close_button.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        right: 10px;
        top: 22px;
        cursor: pointer;
      }
      .popup-text {
        margin: 0 40px;
        font-size: 16px;
        font-weight: 600;
      }
      .popup-buttons {
        display: flex;
        width: 60%;
        justify-content: space-evenly;
        margin-top: 30px;
        button {
          width: 160px;
          height: 40px;
          border-radius: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 757px) {
  .user-profile {
    padding: 12px 12px 0;
    .q-expansion-item {
      margin-bottom: 25px;
    }
  }
}

@media screen and (max-width: 420px) {
  .user-profile {
    padding: 12px 12px 0;
  }
}

</style>
