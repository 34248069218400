<template>

<div 
  class="content-block section-block" 
  ref="sectionBox" 
  :class="{
    activated: (sortedDocs.document.length > 0) || 
      (sortedDocs.image.length > 0) ||
      (sortedDocs.video.length > 0)
  }"
>

  <div class="content-header border-box-header" ref="boxHeader" @click="toggleSection">
    <p class="fw-semibold">Attachments</p>
<div class="icon-group">
  <div class="icon green tooltip"
  data-tooltip="Upload File"
  @mouseover="switchUploadHelperTooltip(true)"
  @mouseout="switchUploadHelperTooltip(false)"
  :class="{
    empty: (sortedDocs.document.length === 0) && 
      (sortedDocs.image.length === 0) &&
      (sortedDocs.video.length === 0)
  }"
  v-if="isAuth(`upload-attachement-${attachmentSection}`)"
>
  <input
    type="file"
    name="documentsupload"
    class="documents-upload"
    ref="documentsUpload"
    multiple
    @change="uploadDocument"
  />   
  <!-- <div class="upload-helper-tooltip" v-if="uploadHelperTooltip">Upload File</div> -->
  <!-- <img src="@/assets/images/upload.svg" alt=""> -->

  <img src="@/assets/images/updated/Upload_Files.png" alt="">
</div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle" :class="{revert: !sectionExpanded}" />
</div>

<div class="content-section border-box-content p-0" ref="contentSection">

<div class="attachment-table-wrap">
<table class="attachment-table"
  v-if="
    (sortedDocs.document.length > 0) || 
    (sortedDocs.image.length > 0) ||
    (sortedDocs.video.length > 0)
  "
>

<thead>
<tr>
<th><p class="form-label-static">&nbsp;</p></th>
<th><p class="form-label-static">Title</p></th>
<th><p class="form-label-static">Created</p></th>
<th><p class="form-label-static">Size</p></th>
<th><p class="form-label-static">Type</p></th>
<th><p class="form-label-static">&nbsp;</p></th>
</tr>
</thead>

<tbody>

<!-- <tr>
<td><img src="images/doc.svg" alt="" class="m-auto"></td>
<td>abc.docx</td>
<td>03/12/2021</td>
<td>50 MB</td>
<td>word</td>
<td class="text-start"><a href="javascript:void();"><img src="images/trash-2.svg" alt=""></a></td>
</tr> -->

<template v-if="sortedDocs.document.length > 0">
  <template
    v-for="(doc, indx) in sortedDocs.document"
    v-bind:key="('user-profile-doc-'+ indx)"
  >
    <tr
      @click="(ev) => selectFile(indx, ev, doc.fileName, 'document')"
      :class="{selected: doc.selected}"
      style="cursor: pointer;"
    >
    <td class="text-center">
      <div class="table-buttons file">
        <img src="@/assets/images/doc.svg" alt="" class="m-auto">
      </div>
    </td>
    <td style="color:hsl(var(--green)); text-decoration:underline;">{{ doc.fileName }}</td>
    <td>{{ doc.dateCreated }}</td>
    <td>{{ doc.fileSize }}</td>
    <td>{{ doc.fileType }}</td>
    <td class="text-center">
      <div v-if="isAuth(`remove-attachement-${attachmentSection}`)" class="table-buttons remove" @click="() => promptFileRemoval(doc.id)"><img src="@/assets/images/trash-2.svg" alt=""></div>    </td>
      <!-- <div @click="() => removeFileById(doc.id)"><img src="@/assets/images/trash-2.svg" alt=""></div>    </td> -->
    </tr>
  </template>
</template>

<template v-if="sortedDocs.image.length > 0">
  <template
    v-for="(img, indx) in sortedDocs.image"
    v-bind:key="('user-profile-img-'+ indx)"
  >
    <tr
      @click="(ev) => selectFile(indx, ev, img.fileName, 'image')"
      :class="{selected: img.selected}"
    >
    <td class="text-center">
      <div class="table-buttons file image">
        <img src="@/assets/images/image.svg" alt="" class="m-auto">
      </div>
    </td>
    <td style="color:hsl(var(--green)); text-decoration:underline;">{{ img.fileName }}</td>
    <td>{{ img.dateCreated }}</td>
    <td>{{ img.fileSize }}</td>
    <td>{{ img.fileType }}</td>
    <td class="text-start">
      <div v-if="isAuth(`remove-attachement-${attachmentSection}`)" class="table-buttons remove" @click="() => promptFileRemoval(img.id)"><img src="@/assets/images/trash-2.svg" alt=""></div>
      <!-- <div @click="() => removeFileById(img.id)"><img src="@/assets/images/trash-2.svg" alt=""></div> -->
    </td>
    </tr>
  </template>
</template>

<template v-if="sortedDocs.video.length > 0">
  <template
    v-for="(vid, indx) in sortedDocs.video"
    v-bind:key="('user-profile-vid-'+ indx)"
  >
    <tr
      @click="(ev) => selectFile(indx, ev, vid.fileName, 'video')"
      :class="{selected: vid.selected}"
    >
    <td class="text-center">
      <div class="table-buttons file video">
        <img src="@/assets/images/video.svg" alt="" class="m-auto">
      </div>  
    </td>
    <td style="color:hsl(var(--green)); text-decoration:underline;">{{ vid.fileName }}</td>
    <td>{{ vid.dateCreated }}</td>
    <td>{{ vid.fileSize }}</td>
    <td>{{ vid.fileType }}</td>
    <td class="text-start">
      
      <div v-if="isAuth(`remove-attachement-${attachmentSection}`)" class="table-buttons remove" @click="() => promptFileRemoval(vid.id)"><img src="@/assets/images/trash-2.svg" alt=""></div>
      <!-- <div @click="() => removeFileById(vid.id)"><img src="@/assets/images/trash-2.svg" alt=""></div> -->
    </td>
    </tr>
  </template>
</template>

</tbody>

</table>
</div>

</div>

<!-- old header -->
<!-- <div class="content-header">
<h6 class="sub-headding">Attachments</h6>
<div class="icon-group">

<div class="icon red"
  @click="removeSelectedFiles"
  @mouseover="switchRemoveHelperTooltip(true)"
  @mouseout="switchRemoveHelperTooltip(false)"
>
  <img src="@/assets/images/trash.svg" alt="">
  <div class="remove-helper-tooltip" v-if="removeHelperTooltip">Remove Files</div>
</div>
<div class="icon green download-button"
  @click="downloadFiles"
  @mouseover="switchDownloadHelperTooltip(true)"
  @mouseout="switchDownloadHelperTooltip(false)"
>
  <div class="download-helper-tooltip" v-if="downloadHelperTooltip">Download File</div>
  <img src="@/assets/images/upload.svg" alt="">
</div>
<div class="icon green"
  @mouseover="switchUploadHelperTooltip(true)"
  @mouseout="switchUploadHelperTooltip(false)"
>
  <input
    type="file"
    name="documentsupload"
    class="documents-upload"
    ref="documentsUpload"
    multiple
    @change="uploadDocument"
  />   
  <div class="upload-helper-tooltip" v-if="uploadHelperTooltip">Upload File</div>
  <img src="@/assets/images/upload.svg" alt="">
</div>
</div>
</div> -->

<!-- Old docs -->
<!-- <div class="content-section" v-if="sortedDocs.document.length > 0">
  <p class="block-headding">Documents</p>
  <div class="attchments">
    <div class="row">
      <div class="col-auto"
        v-for="(doc, indx) in sortedDocs.document"
        v-bind:key="('user-profile-doc-'+ indx)"
      >
        <div class="attachment doc"
          @click="selectFile(indx, 'document')"
          :class="{selected: doc.selected}"
        >
          <div class="att-icon doc-icon"></div>
          <div class="doc-filename">{{doc.fileName}}</div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Old Images -->
<!-- <div class="content-section" v-if="sortedDocs.image.length > 0">
  <p class="block-headding">Image</p>
  <div class="attchments">
    <div class="row">
      <div class="col-auto"
        v-for="(img, indx) in sortedDocs.image"
        v-bind:key="('user-profile-img-'+ indx)"
      >
        <div class="attachment image"
          @click="selectFile(indx, 'image')"
          :class="{selected: img.selected}"
        >
          <div class="att-icon img-icon"></div>
          <div class="img-filename">{{img.fileName}}</div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Old videos -->
<!-- <div class="content-section" v-if="sortedDocs.video.length > 0">
  <p class="block-headding">Video</p>
    <div class="attchments">
      <div class="row">
      <div class="col-auto">
        <div class="attachment video"
          v-for="(vid, indx) in sortedDocs.video"
          v-bind:key="('user-profile-vid-'+ indx)"
          @click="selectFile(indx, 'video')"
          :class="{selected: vid.selected}"
        >
          <div class="att-icon vid-icon"></div>
          <div class="vid-filename">{{vid.fileName}}</div>
        </div>
      </div>
    </div>
  </div>
</div> -->

</div>

<div class="removal-popup" v-if="removalTry">

  <div class="modal modal-md">
  <span class="modal-close" @click="() => removeFileById(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">Are you sure you want to delete the file?</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => removeFileById(removedFileId)"
        class="approve-button btn green" 
      >Yes</button>
      <button
        @click="() => removeFileById(false)" 
        class="cancel-removal-button btn green btn-o" 
      >No</button>
  </div>
  </div>
  </div>

  <!-- <div class="removal-message">
    <div class="close-button" @click="() => removeFileById(false)" ></div>
    <span class="removal-text">Are you sure you want to delete the file?</span>
    <div class="removal-buttons">
      <button
        @click="() => removeFileById(removedFileId)"
        class="approve-button btn green" 
      >Yes</button>
      <button
        @click="() => removeFileById(false)" 
        class="cancel-removal-button btn light-blue-20 btn-o" 
      >No</button>
    </div>
  </div> -->
</div>

<div class="removal-popup" v-if="saveFilled">

  <div class="modal modal-md">
  <span class="modal-close" @click="() => approveSaveFilled(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">Confirm file upload!</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => approveSaveFilled(true)"
        class="approve-button btn green" 
      >Yes</button>
      <button
        @click="() => approveSaveFilled(false)" 
        class="cancel-removal-button btn green btn-o" 
      >No</button>
  </div>
  </div>
  </div>

</div>

  <!-- <div class="file-attachment">

    <div class="files-list">
      <span v-if="sortedDocs.document.length > 0" class="type-header">Documents</span>
      <div class="documents-list">
        <div
          v-for="(doc, indx) in sortedDocs.document"
          v-bind:key="('user-profile-doc-'+ indx)"
          class="document-column"
          @click="selectFile(indx, 'document')"
          :class="{selected: doc.selected}"
        >
          <div class="doc-icon"></div>
          <div class="doc-filename">{{doc.fileName}}</div>
        </div>
      </div>

      <span v-if="sortedDocs.image.length > 0" class="type-header">Image</span>
      <div class="images-list">
        <div
          v-for="(img, indx) in sortedDocs.image"
          v-bind:key="('user-profile-img-'+ indx)"
          class="image-column"
          @click="selectFile(indx, 'image')"
          :class="{selected: img.selected}"
        >
          <div class="img-icon"></div>
          <div class="img-filename">{{img.fileName}}</div>
        </div>
      </div>

      <span v-if="sortedDocs.video.length > 0" class="type-header">Video</span>
      <div class="videos-list">
        <div
          v-for="(vid, indx) in sortedDocs.video"
          v-bind:key="('user-profile-vid-'+ indx)"
          class="video-column"
          @click="selectFile(indx, 'video')"
          :class="{selected: vid.selected}"
        >
          <div class="vid-icon"></div>
          <div class="vid-filename">{{vid.fileName}}</div>
        </div>
      </div>
    </div>

  </div> -->

  <div v-if="loadingFile" class="submit-popup-wrapper">
    <div class="popup-message">
      <!-- <div class="close-button" @click="() => {
        loadingFile = false;
      }" ></div> -->
      <div class="loader"></div>
    </div>
  </div>
  <OpenedFile />
</template>

<script>
import { ref, reactive, watch, inject } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import moment from "moment";
import { v4 as uuid } from 'uuid';
import { onMounted } from "vue";
import OpenedFile from "./OpenedFile/OpenedFile.vue";

export default {
  name: 'FileAttachments',
  props: ['section', 'instance', 'filled'],
  components: { OpenedFile },
  setup(props) {
    const { isAuth } = inject('mainUI');
    const store = useStore();

    const sectionBox = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);

    const removedFileId = ref(null);
    const removalTry = ref(false);

    const attachmentSection = ref(props.section);

    const isFilled = ref(props.filled);

    const toggleSection = (ev) => {
      if ((ev.target.className !== 'content-header border-box-header') && 
        (ev.target.className !== 'angle') && (ev.target.className !== 'angle revert')) {
        return;
      }
      sectionExpanded.value = !sectionExpanded.value
    }

    const documentsUpload = ref(null);
    const documentsList = ref([]);
    const sortedDocs = reactive({
      ...store.state.attachments[props.section] //props.instance ? `${props.section}${props.instance}` : 
    })
    const uploadHelperTooltip = ref(false);

    // const stateUploads = props.instance ? 
    //   computed(() => store.state.attachments[`${props.section}${props.instance}`]) : 
    //   computed(() => store.state.attachments[`${props.section}`])

    const loadingFile = ref(false);

    const saveFilled = ref(false);

    const uploadedFilesToFilled = ref([]);

    const removedFileData = ref(null);

    const switchUploadHelperTooltip = (toggle) => {
      uploadHelperTooltip.value = toggle
    }

    const removeHelperTooltip = ref(false);
    
    const switchRemoveHelperTooltip = (toggle) => {
      removeHelperTooltip.value = toggle
    }

    const downloadHelperTooltip = ref(false);

    const switchDownloadHelperTooltip = (toggle) => {
      downloadHelperTooltip.value = toggle
    }

    const determineType = (fileFormat) => {
      if (fileFormat === 'doc') return 'word';
      if (fileFormat === 'docx') return 'word';
      return fileFormat;
    }

    // Background Fetch API

    const uploadDocument = (ev) => {
      console.log(ev, ev.target.files, ev.target.value);

      for (let i = 0; i < ev.target.files.length; i++) {

        const fileExtension = ev.target.files[i].name.split('.');

        // improve calculations
        const fileSizeCalculated = () => {
          const sizeInBytes = ev.target.files[i].size
          if (sizeInBytes < 1024) return `1 KB`
          const kbs = Math.abs(
            Number.parseInt(sizeInBytes) / 1024
          )
          if (kbs < 1024) return `${kbs.toFixed(2)} KB`
          if (kbs > 1024) return `${Math.abs(kbs / 1024).toFixed(2)} MB`
          if (kbs > Math.abs(1024 * 1024)) return `${Math.abs(kbs / (1024 * 1024)).toFixed(2)} GB`
        }

        
        const file = ev.target.files[i];
        const reader = new FileReader();
        const fileId = uuid();
        reader.onload = function(base64) {
          console.log(base64);
          documentsList.value.push({
            id: fileId,
            file: reader.result, //base64
            fileName: ev.target.files[i].name,
            dateCreated: moment().format('MM/DD/YYYY'),
            fileType: determineType(fileExtension[fileExtension.length - 1]),
            fileSize: fileSizeCalculated(),
            selected: false
          });
          if (isFilled.value) {
            uploadedFilesToFilled.value.push(fileId);
            if (i === (ev.target.files.length - 1)) {
              // Open save popup
              saveFilled.value = true;
            }
          }
        }
        reader.readAsDataURL(file);

        // documentsList.value.push({
        //   id: uuid(),
        //   file: JSON.stringify(ev.target.files[i]),
        //   fileName: ev.target.files[i].name,
        //   dateCreated: moment().format('MM/DD/YYYY'),
        //   fileType: determineType(fileExtension[fileExtension.length - 1]),
        //   fileSize: fileSizeCalculated(),
        //   selected: false
        // });
      }

      console.log(documentsList.value, sortedDocs)
      // store.dispatch("uploadMedia", {
      //   file: ev.target.files[0],
      //   place: "background",
      // });
      // createPreview(ev);
    };

    const approveSaveFilled = (isApproved) => {
      if (isApproved) {
        store.dispatch('uploadAttachedFileInFilledState', {
          section: props.section,
          instance: props.instance
        });
      } else {
        uploadedFilesToFilled.value.forEach(uploadedFile => removeFileById(uploadedFile));
      }
      saveFilled.value = false;
    }

    const downloadFiles = () => {
      for (let filesType in sortedDocs) {
        sortedDocs[filesType].forEach((uploadedFile) => {
          uploadedFile.selected && console.log(uploadedFile);
        })
      }
    }

    const selectFile = (indx, ev, fileName, fileType) => {
      if (sortedDocs[fileType][indx]) {
        sortedDocs[fileType][indx].selected = !sortedDocs[fileType][indx].selected
      }
      if ((ev.target.tagName === 'IMG') || (ev.target.className === 'table-buttons remove')) {
        return;
      } else {
        loadingFile.value = true;
        store.dispatch('openAttachedFile', {
          fileType,
          fileName,
          section: props.section,
          instance: props.instance
        });
      // if (fileType === 'document') {
      //   store.dispatch('openAttachedDocument');
      // }
      // if (fileType === 'image') {
      //   store.dispatch('openAttachedImage');
      // }
      // if (fileType === 'video') {
      //   store.dispatch('openAttachedVideo');
      // }
      }
    }

    const promptFileRemoval = (fileId) => {
      removedFileId.value = fileId;

      removalTry.value = true;
    }

    const removeFileById = (fileId) => {
      if (fileId) {

        if (sortedDocs.document.find(doc => doc.id === fileId)) {
          removedFileData.value = {...sortedDocs.document.find(doc => doc.id === fileId), fileType: 'document'};
        }

        if (sortedDocs.image.find(img => img.id === fileId)) {
          removedFileData.value = {...sortedDocs.image.find(img => img.id === fileId), fileType: 'image'};
        }

        if (sortedDocs.video.find(vid => vid.id === fileId)) {
          removedFileData.value = {...sortedDocs.video.find(vid => vid.id === fileId), fileType: 'video'};
        }

        sortedDocs.document = sortedDocs.document.filter(doc => doc.id !== fileId)

        sortedDocs.image = sortedDocs.image.filter(img => img.id !== fileId)

        sortedDocs.video = sortedDocs.video.filter(vid => vid.id !== fileId)

        documentsList.value = documentsList.value.filter(doc => doc.id !== fileId);

        store.dispatch('attachFile', {
          uploads: {
            document: [...sortedDocs.document],
            image: [...sortedDocs.image],
            video: [...sortedDocs.video]
          },
          section: props.instance ? `${props.section}${props.instance}` : props.section
        })
      } 

      removalTry.value = false;
      removedFileId.value = null;
    }

    const removeSelectedFiles = () => {
      // sortedDocs.image.forEach((img,indx) => {
      //   img.selected && sortedDocs.image.splice(indx,1)
      // })

      sortedDocs.document = sortedDocs.document.filter(doc => !doc.selected)

      sortedDocs.image = sortedDocs.image.filter(img => !img.selected)

      sortedDocs.video = sortedDocs.video.filter(vid => !vid.selected)

      // for (let i = 0; i < sortedDocs.document.length; i++) {
      //   sortedDocs.document[i].selected && sortedDocs.document.splice(i, 1);
      // }

      // for (let i = 0; i < sortedDocs.image.length; i++) {
      //   sortedDocs.image[i].selected && sortedDocs.image.splice(i, 1);
      // }

      // for (let i = 0; i < sortedDocs.video.length; i++) {
      //   sortedDocs.video[i].selected && sortedDocs.video.splice(i, 1);
      // }

      store.dispatch('attachFile', {
        uploads: {
          document: [...sortedDocs.document],
          image: [...sortedDocs.image],
          video: [...sortedDocs.video]
        },
        section: props.instance ? `${props.section}${props.instance}` : props.section
      })

      console.log(sortedDocs.image.length, 'method')
    }

    watch(sectionExpanded, (newVal) => {
      sectionBox.value.style.height = newVal ? 
        `${sectionHeight.value}px` : 
          `${boxHeader.value.offsetHeight}px`;
          sectionBox.value.style.overflow = newVal ? 'visible' : 'hidden'    })

    watch(() => _.cloneDeep(documentsList.value), (newVal) => {

      const sortedVideo = [/* ...sortedDocs.video, */ ...[...newVal].filter(file => {
        const fileExtension = file.fileName.split('.')
        if (fileExtension[fileExtension.length - 1].includes('mp4') || 
        fileExtension[fileExtension.length - 1].includes('mov') || 
        fileExtension[fileExtension.length - 1].includes('wmv') || 
        fileExtension[fileExtension.length - 1].includes('mkv')) {
          return file
        }
      })]

      // const sortedVideo = [...newVal].filter(file => {
      //   const fileExtension = file.fileName.split('.')
      //   if (fileExtension[fileExtension.length - 1].includes('mp4') || 
      //   fileExtension[fileExtension.length - 1].includes('mov') || 
      //   fileExtension[fileExtension.length - 1].includes('wmv') || 
      //   fileExtension[fileExtension.length - 1].includes('mkv')) {
      //     return file
      //   }
      // })

      const sortedImage = [/* ...sortedDocs.image, */ ...[...newVal].filter(file => {
        const fileExtension = file.fileName.split('.')
        if (fileExtension[fileExtension.length - 1].includes('png') || 
        fileExtension[fileExtension.length - 1].includes('jpg') || 
        fileExtension[fileExtension.length - 1].includes('jpeg') || 
        fileExtension[fileExtension.length - 1].includes('bmp') || 
        fileExtension[fileExtension.length - 1].includes('gif') || 
        fileExtension[fileExtension.length - 1].includes('svg')) {
          return file
        }
      })]

      // const sortedImage = [...newVal].filter(file => {
      //   const fileExtension = file.fileName.split('.')
      //   if (fileExtension[fileExtension.length - 1].includes('png') || 
      //   fileExtension[fileExtension.length - 1].includes('jpg') || 
      //   fileExtension[fileExtension.length - 1].includes('jpeg') || 
      //   fileExtension[fileExtension.length - 1].includes('bmp') || 
      //   fileExtension[fileExtension.length - 1].includes('gif') || 
      //   fileExtension[fileExtension.length - 1].includes('svg')) {
      //     return file
      //   }
      // })

      // const sortedDocument = [...newVal].filter(file => {
      //   const fileExtension = file.fileName.split('.')
      //   if (!fileExtension[fileExtension.length - 1].includes('mp4') && 
      //   !fileExtension[fileExtension.length - 1].includes('mov') && 
      //   !fileExtension[fileExtension.length - 1].includes('wmv') && 
      //   !fileExtension[fileExtension.length - 1].includes('mkv') &&
      //   !fileExtension[fileExtension.length - 1].includes('png') && 
      //   !fileExtension[fileExtension.length - 1].includes('jpg') && 
      //   !fileExtension[fileExtension.length - 1].includes('jpeg') && 
      //   !fileExtension[fileExtension.length - 1].includes('bmp') && 
      //   !fileExtension[fileExtension.length - 1].includes('gif') && 
      //   !fileExtension[fileExtension.length - 1].includes('svg')) {
      //     return file
      //   }
      // })

      const sortedDocument = [/* ...sortedDocs.document, */ ...[...newVal].filter(file => {
        const fileExtension = file.fileName.split('.')
        if (!fileExtension[fileExtension.length - 1].includes('mp4') && 
        !fileExtension[fileExtension.length - 1].includes('mov') && 
        !fileExtension[fileExtension.length - 1].includes('wmv') && 
        !fileExtension[fileExtension.length - 1].includes('mkv') &&
        !fileExtension[fileExtension.length - 1].includes('png') && 
        !fileExtension[fileExtension.length - 1].includes('jpg') && 
        !fileExtension[fileExtension.length - 1].includes('jpeg') && 
        !fileExtension[fileExtension.length - 1].includes('bmp') && 
        !fileExtension[fileExtension.length - 1].includes('gif') && 
        !fileExtension[fileExtension.length - 1].includes('svg')) {
          return file
        }
      })]

      sortedDocs.video = [...sortedVideo];
      sortedDocs.image = [...sortedImage];
      sortedDocs.document = [...sortedDocument];

      store.dispatch('attachFile', {
        uploads: {
          document: [...sortedDocs.document],
          image: [...sortedDocs.image],
          video: [...sortedDocs.video]
        },
        section: props.instance ? `${props.section}${props.instance}` : props.section
      })

      console.log(sortedDocs.image.length, 'watcher')

      sectionBox.value.style.height = 'auto';
      setTimeout(() => {
        sectionHeight.value = sectionBox.value.offsetHeight;
      })
    })

    watch(() => (props.instance), () => {
      if (store.state.attachments[`${props.section}${props.instance}`]) { 
        for (let prop in store.state.attachments[`${props.section}${props.instance}`]) {
          sortedDocs[prop] = store.state.attachments[`${props.section}${props.instance}`][prop];
        } 
      } else {
        for (let prop in store.state.attachments[props.section]) {
          sortedDocs[prop] = store.state.attachments[props.section][prop];
        }
      }
    });

    watch(() => ({...store.state.attachments.contact}), (newVal) => {
      if ((newVal.video.length === 0) && (newVal.image.length === 0) && (newVal.document.length === 0)) {
        sortedDocs.document = [];

        sortedDocs.image = [];

        sortedDocs.video = [];

        documentsList.value = [];
      }
    });

    watch(() => ({...store.state.attachments[`${props.section}${props.instance}`] || store.state.attachments[props.section]}), (newVal, oldVal) => {
      if ((newVal.document.length !== oldVal.document.length) ||
          (newVal.image.length !== oldVal.image.length) ||
          newVal.video.length !== oldVal.video.length
      ) {

        const determineTypeFromServer = (fileFormat) => {
          if ((fileFormat === 'doc') || (fileFormat.includes('word'))) return 'word';
          if (fileFormat === 'docx') return 'word';
          if (fileFormat.includes('document')) return 'document';
          if (fileFormat.includes('image')) return 'image';
          if (fileFormat.includes('video')) return 'video';
          return fileFormat;
        }
        
        const fileSizeCalculatedFromServer = (targetFileSize) => {
          const sizeInBytes = targetFileSize;
          if (sizeInBytes < 1024) return `1 KB`
          const kbs = Math.abs(
            Number.parseInt(sizeInBytes) / 1024
          )

          if (`${sizeInBytes}`.includes('KB') || `${sizeInBytes}`.includes('MB') || `${sizeInBytes}`.includes('GB')) return sizeInBytes;
          if (kbs < 1024) return `${kbs.toFixed(2)} KB`
          if (kbs > 1024) return `${Math.abs(kbs / 1024).toFixed(2)} MB`
          if (kbs > Math.abs(1024 * 1024)) return `${Math.abs(kbs / (1024 * 1024)).toFixed(2)} GB`
        }

        if (store.state.attachments[`${props.section}${props.instance}`]) { 
          for (let prop in newVal) {
            sortedDocs[prop] = newVal[prop].map(file => {
              return {
                id: file.id || uuid(),
                // file: reader.result, //base64
                fileName: file.fileName,
                dateCreated: moment(file.dateCreated).format('MM/DD/YYYY'),
                fileType: determineTypeFromServer(file.fileType),//determineType(fileExtension[fileExtension.length - 1]),
                fileSize: fileSizeCalculatedFromServer(file.fileSize),//fileSizeCalculated(),
                selected: false
              }
            });
          } 
        } else {
          for (let prop in newVal) {
            sortedDocs[prop] = newVal[prop].map(file => {
              return {
                id: file.id || uuid(),
                // file: reader.result, //base64
                fileName: file.fileName,
                dateCreated: moment(file.dateCreated).format('MM/DD/YYYY'),
                fileType: determineTypeFromServer(file.fileType),//determineType(fileExtension[fileExtension.length - 1]),
                fileSize: fileSizeCalculatedFromServer(file.fileSize),//fileSizeCalculated(),
                selected: false
              }
            });
          }
        }

        if (((newVal.document.length < oldVal.document.length) ||
          (newVal.image.length < oldVal.image.length) ||
          (newVal.video.length < oldVal.video.length)) && removedFileData.value && (isFilled.value || ((props.section === 'primary') || (props.section === 'secondary')))
        ) {
          store.dispatch('deleteAttachedFileInFilledState', {
            section: props.section,
            instance: props.instance,
            fileType: removedFileData.value.fileType,
            fileName: removedFileData.value.fileName
          });
          removedFileData.value = null;
        }
      }
    });

    watch(() => ({...store.state.selectedAttachedFile}), (newVal) => {
      if (newVal.file && loadingFile.value) {
        loadingFile.value = false;
      }
    });

    // watch(() => ({...sortedDocs}), (newVal, oldVal) => {
    //   if (
    //     (newVal.document.length < oldVal.document.length) ||
    //     (newVal.image.length < oldVal.image.length) ||
    //     (newVal.video.length < oldVal.video.length)
    //   ) {
    //     for (let i = 0; i < sortedDocs.document.length; i++) {
    //       documentsList[i] = sortedDocs.document[i];
    //     }
    //     for (let i = 0; i < sortedDocs.image.length; i++) {
    //       documentsList[i] = sortedDocs.image[i];
    //     }
    //     for (let i = 0; i < sortedDocs.video.length; i++) {
    //       documentsList[i] = sortedDocs.video[i];
    //     }
    //   }
    // })

    onMounted(() => {
      
      setTimeout(() => {
        if (sectionBox.value) {
          sectionHeight.value = sectionBox.value.offsetHeight;
          sectionBox.value.style.height = `${sectionBox.value.offsetHeight}px`;
        }
      });

      if (store.state.attachments[`${props.section}${props.instance}`]) { 
        for (let prop in store.state.attachments[`${props.section}${props.instance}`]) {
          sortedDocs[prop] = store.state.attachments[`${props.section}${props.instance}`][prop];
        }
        for (let i = 0; i < sortedDocs.document.length; i++) {
          documentsList.value[i] = sortedDocs.document[i];
        } 
      } else {
        for (let prop in store.state.attachments[props.section]) {
          sortedDocs[prop] = store.state.attachments[props.section][prop];
        }
      }
      const shuffledFiles = [...sortedDocs.document, ...sortedDocs.image, ...sortedDocs.video];
      for (let i = 0; i < shuffledFiles.length; i++) {
        documentsList.value[i] = shuffledFiles[i];
      }
      // for (let i = 0; i < sortedDocs.document.length; i++) {
      //   documentsList.value[i] = sortedDocs.document[i];
      // }
      // for (let i = 0; i < sortedDocs.image.length; i++) {
      //   documentsList.value[i] = sortedDocs.image[i];
      // }
      // for (let i = 0; i < sortedDocs.video.length; i++) {
      //   documentsList.value[i] = sortedDocs.video[i];
      // }
    })

    return {
      uploadHelperTooltip,
      switchUploadHelperTooltip,
      removeHelperTooltip,
      switchRemoveHelperTooltip,
      downloadHelperTooltip,
      switchDownloadHelperTooltip,
      uploadDocument,
      downloadFiles,
      documentsUpload,
      documentsList,
      sortedDocs,
      removeFileById,
      removeSelectedFiles,

      removedFileId,
      removalTry,
      promptFileRemoval,

      selectFile,

      sectionBox,
      boxHeader,
      sectionExpanded,
      toggleSection,
      isAuth,
      attachmentSection,
      loadingFile,
      saveFilled,
      approveSaveFilled
    }
  }
}
</script>

<style lang="scss">
.content-block {
  .icon {
    position: relative;
  }
  .documents-upload {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  .remove-helper-tooltip,
  .upload-helper-tooltip,
  .download-helper-tooltip {
    width: max-content;
    padding: 0 15px;
    height: 30px;
    position: absolute;
    z-index: 999;

    background-color: #3C3838;
    color: #fff;
    top: 50px;
    left: -50px;
    text-indent: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: small;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: -7px;
      border-color: #3C3838;
      border-left: 8px solid hsl(0, 3%, 23%);
      border-bottom: 6px solid #3C3838;
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 61px;
      background: #3C3838;
      z-index: -1;
    }
  }
}

.download-button {
  img {
    transform: rotate(180deg);
  }
}

.file-attachment {
  .header-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: -0.5rem;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    .subsection-header {
      font-size: 1.527777778vw;
      font-weight: 600;
      color: rgba(40, 41, 43, 1);
    }
    .subsection-actions {
      display: flex;
      .remove-file,
      .upload-file {
        display: flex;
        text-indent: -9999px;
        width: 2.777777778vw;
        height: 2.777777778vw;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        position: relative;
        &.upload-file {
          background-image: url('@/assets/file_attachment/upload_button.png');
        }
        &.remove-file {
          background-image: url('@/assets/file_attachment/remove_button.png');
          margin-right: 1.805555556vw;
        }
        .remove-helper-tooltip,
        .upload-helper-tooltip {
          width: max-content;
          padding: 0 15px;
          height: 30px;
          position: absolute;
          z-index: 999;

          background-color: #3C3838;
          color: #fff;
          top: 50px;
          left: -30px;
          text-indent: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 11px;
          font-family: 'Inter', sans-serif;
          font-weight: 400;

          &::after {
            content: " ";
            position: absolute;
            display: block;
            top: -7px;
            border-color: #3C3838;
            border-left: 8px solid hsl(0, 3%, 23%);
            border-bottom: 6px solid #3C3838;
            border-right: 6px solid transparent;
            border-top: 8px solid transparent;
            -webkit-transform: translateX(-50%) rotate(135deg);
            transform: translateX(-50%) rotate(135deg);
            box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
            left: 46px;
            background: #3C3838;
            z-index: -1;
          }
        }
        .documents-upload {
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 9999;
          cursor: pointer;
        }
      }
    }
  }

  .files-list {
    // margin-bottom: 2.777777778vw;
    margin-bottom: 1rem;
    .type-header {
      color: rgba(40, 41, 43, 1);
      font-size: var(--fs-lg);
      font-family: var(--fw-semibold);
      margin-bottom: 0.694444444vw;
      display: flex;
    }
    .documents-list,
    .images-list,
    .videos-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .document-column,
      .image-column,
      .video-column {
        flex-basis: 12.5%;
        .doc-icon,
        .img-icon,
        .vid-icon {
          display: flex;
          width: 4.166666667vw;
          height: 4.166666667vw;
          margin: auto;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          &.doc-icon {
            background-image: url('@/assets/file_attachment/document_icon.png');
          }
          &.img-icon {
            background-image: url('@/assets/file_attachment/image_icon.png');
          }
          &.vid-icon {
            background-image: url('@/assets/file_attachment/video_icon.png');
          }
        }
        .doc-filename,
        .img-filename,
        .vid-filename {
          margin: auto;
          text-align: center;
          font-weight: 500;
          font-family: var(--fw-medium);
          line-height: 1;
          padding-top: 1rem;
        }
        &.selected {
          .doc-icon {
            background-image: url('@/assets/file_attachment/document_icon_selected.png');
          }
          .img-icon {
            background-image: url('@/assets/file_attachment/image_icon_selected.png');
          }
          .vid-icon {
            background-image: url('@/assets/file_attachment/video_icon_selected.png');
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .file-attachment {
    .header-row {
      .subsection-header {
        font-size: 4.651162791vw;
      }
      .subsection-actions {
        .remove-file,
        .upload-file {
          width: 6.976744186vw;
          height: 6.976744186vw;
        }
      }
    }
    .files-list {
      .type-header {
        font-size: 3.720930233vw;
      }
      .documents-list,
      .images-list,
      .videos-list {
        .document-column,
        .image-column,
        .video-column {
          flex-basis: 25%;
          .doc-icon,
          .img-icon,
          .vid-icon {
            width: 6.976744186vw;
            height: 6.976744186vw;
          }
        }
      }
    }
  }
}

.section-block {
  transition: height 0.3s ease-in-out;
  &.activated {
    overflow: hidden;
  }
}

.content-header {
  &.border-box-header {
    margin-top: 0.1rem;
  }
}

.angle {
  &.revert {
    transform: rotate(180deg);
  }
}


.removal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  .removal-message {
    background: #fff;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 100%;
    height: max-content;
    justify-content: space-around;
    align-items: center;
    position: relative;
    border-radius: 11px;
    color: rgba(40,41,43,1);
    font-size: 21px;
    margin: 0 20px;
    padding: 25px 0;
    text-align: center;
    .close-button {
      position: absolute;
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 30px;
      height: 30px;
      background-image: url('@/assets/profile/close_button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      right: 10px;
      top: 22px;
      cursor: pointer;
    }
    .removal-text {
      margin: 0 40px;
      font-size: 16px;
      font-weight: 600;
    }
    .removal-buttons {
      display: flex;
      width: 60%;
      justify-content: space-evenly;
      margin-top: 30px;
      button {
        width: 160px;
        height: 40px;
        border-radius: 10px;
        &.cancel-removal-button {
          border: 2px solid #38AC89;
        }
        &.approve-button {
          margin-right: 10px;
        }
      }
    }
  }
}

.submit-popup-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-message {
    background: #fff;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    // width: 100%;
    width: max-content;
    height: max-content;
    justify-content: space-around;
    align-items: center;
    position: relative;
    // border-radius: 11px;
    color: rgba(40,41,43,1);
    font-size: 21px;
    margin: 0 20px;
    padding: 25px;
    text-align: center;
    .close-button {
      position: absolute;
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 30px;
      height: 30px;
      background-image: url('@/assets/profile/close_button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      right: 10px;
      top: 22px;
      cursor: pointer;
    }
    .loader {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      position: relative;
      animation: rotate 1s linear infinite
    }
    .loader::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 5px solid #38ad8a;
      animation: prixClipFix 2s linear infinite ;
    }

    @keyframes rotate {
      100%   {transform: rotate(360deg)}
    }

    @keyframes prixClipFix {
        0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
        25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
        50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
        75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
        100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }
  }
}
</style>