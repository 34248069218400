<template>
<section class="section" ref="section">
<div class="border-box active">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Task Calendar</h5>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

  <div class="border-box-content calendar-content">
    <VueCal 
      :disable-views="['years', 'year']"
      :editable-events="{ title: false, drag: false, resize: true, delete: true, create: true }"
      :drag-to-create-threshold="0"
      @cell-dblclick="($event) => openAddTask($event)"
      @event-focus="changeTaskAction"
      :events="events"
      :snap-to-time="30"
      :twelve-hour="true"
    ><!-- :time-step="30" -->
      <template #title="{ view }">
        <span v-if="view.id === 'week'">{{ moment(view.startDate).format('MMMM YYYY') }}</span>
      </template>

      <template #time-cell="{ hours }">
        <div class="vuecal__time-cell">
          <span class="vuecal__time-cell-line"></span>
          <span class="vuecal__time-cell-label">
            {{ `${(((hours < 10) && (Number.parseInt(hours) !== 0)) || (hours > 12) && ((Number.parseInt(hours) !== 22) && (Number.parseInt(hours) !== 23))) ? '0' : ''}${(hours <= 12) ? ((Number.parseInt(hours) === 0) ? '12' : hours) : (hours - 12)}` }}:00 {{ (hours <= 12) ? 'am' : 'pm' }}
          </span>
        </div>
      </template>

    </VueCal>
    <!-- 
      events: [
        {
          start: '2018-11-20 14:00', // will be received from click
          end: '2018-11-20 17:30', // will be added on status change to completed
          title: 'Boring event', // will be added from form
          content: '<i class="icon material-icons">block</i><br>I am not draggable, not resizable and not deletable.',
          class: 'blue-event',
          deletable: false,
          resizable: false,
          draggable: false
        },
        // other events.
      ]
    -->
  </div>
</div>
</section>
</template>

<script>
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import moment from "moment";
import { useStore } from 'vuex';

export default defineComponent({
  components: { VueCal },
  setup() {
    const { isAuth } = inject('mainUI');
    const store = useStore();

    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);

    const selectedTaskId = ref('');

    const tasks = computed(() => store.state.tasks);

    const events = ref([]);

    const { selectTask, defaultTask } = inject('taskDetails');

    const openAddTask = (eventDate) => {

      if (!isAuth('create-new-task')) {
        return;
      }

      if (selectedTaskId.value.length > 0) {
        return;
      }

      const formattedDate = moment(eventDate);

      const taskToCreate = {
        ...defaultTask,
        date: formattedDate.format('YYYY-MM-DD'),
        startTime: `${((formattedDate.get('hour') > 9) && (formattedDate.get('hour') <= 12) || (formattedDate.get('hour') > 21)) ? '' : '0'}${(formattedDate.get('hour') > 12) ? (formattedDate.get('hour') - 12) : formattedDate.get('hour')}:${
          (formattedDate.get('minutes') >= 15) ? '30' : '00'
        } ${
          (formattedDate.get('hour') >= 12) ? 'PM' : 'AM'
        }`
      }
      // editionMode.value = 'create';
      selectTask(taskToCreate, 'create');
    }

    const changeTaskAction = (ev) => {
      console.log(ev);
      selectedTaskId.value = ev.taskId;

      const taskToSelect = tasks.value.find(tsk => tsk.id === ev.taskId);

      selectTask(taskToSelect, 'represent');
    }

    const toggleSection = (ev) => {
      if (ev.target.className !== 'border-box-header') return;
      sectionExpanded.value = !sectionExpanded.value
    }

    watch(sectionExpanded, (newVal) => {
      section.value.style.height = newVal ? 
        `${sectionHeight.value}px` : 
          `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'    })

    watch(store.state.tasks, () => {
      const filteredTasks = [...tasks.value].filter(t => t);
      events.value = filteredTasks.map(task => {
        return {
          // start: `${moment(task.date).format('YYYY-MM-DD')} ${task.startTime}`,
          start: `${moment(`${task.date} ${task.startTime}`).format('YYYY-MM-DD hh:mm a')}`,
          // end: task.completionTime ? `${moment(task.date).format('YYYY-MM-DD')} ${task.completionTime}` : '',
          end: task.completionTime ? 
            `${moment(`${task.date} ${task.completionTime}`).format('YYYY-MM-DD hh:mm a')}` : 
            `${moment(`${task.date} ${
              (task.startTime[1] === ':') ? 
                `${Number.parseInt(task.startTime[0]) + 1}${task.startTime[1]}${task.startTime[2]}${task.startTime[3]} ${task.startTime.split(' ')[1].toLowerCase()}` :
                `${task.startTime[0]}${Number.parseInt(task.startTime[1]) + 1}${task.startTime[2]}${task.startTime[3]}${task.startTime[4]} ${task.startTime.split(' ')[1].toLowerCase()}`
            }`).format('YYYY-MM-DD hh:mm a')}`,
          title: task.task,
          taskId: task.id,
          // content: '<i class="icon material-icons">block</i><br>I am not draggable, not resizable and not deletable.',
          class: 'task-event',
          deletable: false,
          resizable: false,
          draggable: false
        }
      })
    })

    onMounted(() => {
      
      setTimeout(() => {
        sectionHeight.value = section.value.offsetHeight;
        section.value.style.height = `${section.value.offsetHeight}px`;
      });

      events.value = [...tasks.value].map(task => {
        return {
          // start: `${moment(task.date).format('YYYY-MM-DD')} ${task.startTime}`,
          start: `${moment(`${task.date} ${task.startTime}`).format('YYYY-MM-DD hh:mm a')}`,
          // end: task.completionTime ? `${moment(task.date).format('YYYY-MM-DD')} ${task.completionTime}` : '',
          end: task.completionTime ? 
            `${moment(`${task.date} ${task.completionTime}`).format('YYYY-MM-DD hh:mm a')}` : 
            `${moment(`${task.date} ${
              (task.startTime[1] === ':') ? 
                `${Number.parseInt(task.startTime[0]) + 1}${task.startTime[1]}${task.startTime[2]}${task.startTime[3]} ${task.startTime.split(' ')[1].toLowerCase()}` :
                `${task.startTime[0]}${Number.parseInt(task.startTime[1]) + 1}${task.startTime[2]}${task.startTime[3]}${task.startTime[4]} ${task.startTime.split(' ')[1].toLowerCase()}`
            }`).format('YYYY-MM-DD hh:mm a')}`,
          title: task.task,
          taskId: task.id,
          // content: '<i class="icon material-icons">block</i><br>I am not draggable, not resizable and not deletable.',
          class: 'task-event',
          deletable: false,
          resizable: false,
          draggable: false
        }
      })

    })

    return {
      section,
      boxHeader,
      toggleSection,
      events,
      openAddTask,
      changeTaskAction,
      moment
    }
  }
})
</script>

<style lang="scss">
.calendar-content {
  height: 500px;
}

.vuecal__cell-events {
  // .task-event   
  .task-event:nth-child(even) {
    max-width: 48%;
    margin-left: 1%;
  }
}
.task-event {
  // background-color: hsl(var(--green));
  background-color: hsl(var(--gray));
  // border-radius: 5px;
  // opacity: 0.7;
  cursor: pointer;
  max-width: 98%;
  .vuecal__event-title {
    color: #fff;
    font-family: var(--fw-semibold);
    // font-size: var(--fs-sm);
    font-size: 1rem;
    text-align: left;
    margin-left: 0.5rem;
  }
  .vuecal__event-time {
    // margin: 50% auto;
    font-family: var(--fw-semibold);
    color: #fff;
    // font-size: var(--fs-sm);
    font-size: 1rem;
    text-align: left;
    margin-left: 0.5rem;
    line-height: normal;
    // color: hsl(var(--gray));
  }
  &:hover {
    // opacity: 1;
    background-color: hsl(var(--dark-gray));
  }
}

.vuecal__cell-events-count {
  background-color: hsl(var(--green));
  font-family: var(--fw-semibold);
}

.vuecal__header {
  .vuecal__menu {
    button {
      font-family: var(--fw-semibold);
      // color: hsl(var(--gray));
      color: hsl(var(--dark-gray));
    }
  }
}

.vuecal__title-bar {
  .vuecal__title {
    button {
      font-family: var(--fw-semibold);
      span {
        font-family: var(--fw-semibold);
        // color: hsl(var(--gray));
      }
    }
    span {
        font-family: var(--fw-semibold);
    }
  }
}

.vuecal__cell-date,
.vuecal__no-event {
  font-family: var(--fw-medium);
}

.vuecal__weekdays-headings {
  .weekday-label {
    span {
      font-family: var(--fw-medium);
      color: hsl(var(--gray));
    }
  }
}
.vuecal__time-cell-label {
  color: hsl(var(--dark-gray));
  vertical-align: super;
}
.vuecal__cell {
  cursor: crosshair;
}
</style>


<!-- <script setup lang="ts">
import "@lbgm/pro-calendar-vue/style";
import { ref, type Ref } from "vue";
import type { Configs, Appointment } from "@lbgm/pro-calendar-vue";

const cfg = ref<Configs>({
  viewEvent: undefined,
  reportEvent: {
    icon: true,
    text: "",
  },
  searchPlaceholder: "",
  eventName: "",
  closeText: "",
  nativeDatepicker: true,
});

const evts: Ref<Appointment[]> = ref([
  {
    date: "2022-11-19T14:00:00.000Z",
    comment: "",
    id: "cl32rbkjk1700101o53e3e3uhn",
    keywords: "Projet BAMBA",
    name: "MONTCHO Kévin",
  },
  //...
]);
</script> -->