<template>
    <section class="section" ref="section">
    <div class="border-box">
    <div class="border-box-header" ref="boxHeader" @click="toggleSection">
    <h5>Customer List</h5>
    <div class="info-icons">
      <div class="info-icon lg top-icon tooltip"
        @click="addCustomer"
        @mouseover="switchHelperTooltip(true)"
        @mouseout="switchHelperTooltip(false)"
        data-tooltip="Add Customer"
        v-if="isAuth('add-new-customer')"
      >
        <img src="@/assets/images/updated/Customers.png" alt="">
        <!-- <div class="helper-tooltip" v-if="helperTooltip">Add Customer</div> -->
      </div>
    </div>
    <img src="@/assets/images/angle-down.png" alt="" class="angle">
    </div>

    <div class="border-box-content">

    <div class="content-row table-wrapper">
    <table>

    <thead>
    <tr>
    <th><p class="form-label-static form-sort-label">
      <span>Type</span>
      <span @click="() => sortTable('type')">
        <button :class="{active: sortOrder.type === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.type === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Customer</span>
      <span @click="() => sortTable('customer')">
        <button :class="{active: sortOrder.customer === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.customer === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Contact</span>
      <span @click="() => sortTable('contact')">
        <button :class="{active: sortOrder.contact === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.contact === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Work Phone</span>
      <span @click="() => sortTable('workPhone')">
        <button :class="{active: sortOrder.workPhone === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.workPhone === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Mobile</span>
      <span @click="() => sortTableByNumber('mobile')">
        <button :class="{active: sortOrder.mobile === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.mobile === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>City</span>
      <span @click="() => sortTableByNumber('city')">
        <button :class="{active: sortOrder.city === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.city === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>State</span>
      <span @click="() => sortTableByNumber('state')">
        <button :class="{active: sortOrder.state === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.state === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th class="w-75">&nbsp;</th>

    </tr>
    </thead>

    <tbody>

      <template
        v-for="(row, rowIndex) in rows"
        v-bind:key="'customer-list-row' + row.id"
      >
        <tr
          v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
          :class="{'light-gray-bg': row.id === selectedCustomer.id}"
          @click="(ev) => selectByRow(ev, {...row})"
        >
        <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
        <td>{{ row.type }}</td>
        <td class="text-center">{{ row.customer }}</td>
        <td>{{ row.contact }}</td>
        <td>{{ row.workPhone }}</td>
        <td>{{ row.mobile }}</td>
        <td>{{ row.city }}</td>
        <td>{{ row.state }}</td>
        <td class="w-75">
        <div class="table-links">
        <a v-if="isAuth('edit-customer')" href="javascript:void();" @click="selectCustomer({...row}, 'edit')">
          <img src="@/assets/images/edit-icon-2.svg" alt="">
        </a>
        <a v-if="isAuth('remove-customer')" href="javascript:void();" @click="removeSelectedCustomer(row.id)">
          <img src="@/assets/images/trash-2.svg" alt="">
        </a>
        <a v-if="isAuth('call-customer')" href="javascript:void();" @click="callCustomer(row.id)">
          <img src="@/assets/images/phone-icon.svg" alt="">
        </a>
        </div>
        </td>
        </tr>
      </template>

    </tbody>

    </table>
    </div>

    <div class="content-row">
    <div class="pagination">
    <div>
    <!-- <p>Records per page:</p> -->
    <!-- <select v-model="maxPage">
    <option>1</option>
    <option>2</option>
    <option>3</option>
    <option>4</option>
    <option>5</option>
    <option>6</option>
    <option>7</option>
    <option>8</option>
    <option>9</option>
    <option>10</option>
    </select> -->
    </div>
    <!-- <p>1-1 to 10</p> -->
    <p class="list-pagination">
      Page: 
      <!-- <select v-model="currentPage" class="pagination-selector">
      <option 
        v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
        v-bind:key="'task-list-pagination-' + page"
      >{{ Number.parseInt(page) + 1 }}</option>
      </select> -->
      <DropDownList 
        :mainObject="'currentPage'"
        :mainProperty="'value'"
        :injectionName="'customerListDetails'"
        :optionKey="'customer-list-pagination'"
        :optionsList="[]"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="currentPage.value"
        :numberList="true"
      />
    </p>
    </div>
    </div>
    
    </div>

  </div>
  </section>

  <div class="removal-popup" v-if="removedCustomerId">

  <div class="modal modal-md">
  <span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">Are you sure you want to delete the customer?</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => approveRemoval(true)"
        class="approve-button btn green" 
      >Yes</button>
      <button
        @click="() => approveRemoval(false)" 
        class="cancel-removal-button btn green btn-o" 
      >No</button>
  </div>
  </div>
  </div>

  </div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { 
  defineComponent, ref, onMounted, 
  watch, computed, inject, reactive, provide
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'SalesList',
    setup() {
        const store = useStore();
        const router = useRouter();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const customers = computed(() => store.state.customers);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const sortOrder = reactive({
            type: 0,
            customer: 0,
            contact: 0,
            workPhone: 0,
            mobile: 0,
            city: 0,
            state: 0
        });
        const { isAuth } = inject('mainUI');
        const { selectedCustomer, addCustomer, editCustomer, selectCustomer, removeCustomer } = inject('customerDetails');
        const helperTooltip = ref(false);
        const removedCustomerId = ref(null);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...customers.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByNumber = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...customers.value];
                sortOrder[sortProp] = 0;
            }
        };
        const callCustomer = (customerId) => {
            console.log(customerId);
        };
        const removeSelectedCustomer = (customerId) => {
            removedCustomerId.value = customerId;
        };
        const approveRemoval = (isApproved) => {
            if (isApproved) {
                removeCustomer(removedCustomerId.value);
            }
            removedCustomerId.value = '';
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectCustomer({ ...row }, 'represent');
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(customers.value, () => {
            rows.value = [...customers.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(() => ([...store.state.customers]), () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...customers.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
          store.dispatch('getCustomers');
            if (router.currentRoute.value.params.source !== 'admin-dashboard') {
              (customers.value.length > 0) && selectCustomer({ ...customers.value[0] }, 'represent');
            }
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getCustomers')
            rows.value = [...customers.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('customerListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            selectedCustomer,
            addCustomer,
            editCustomer,
            selectCustomer,
            removeCustomer,
            callCustomer,
            rows,
            maxPage,
            currentPage,
            helperTooltip,
            switchHelperTooltip,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            sortTableByNumber,
            removedCustomerId,
            removeSelectedCustomer,
            approveRemoval,
            selectByRow,
            isAuth
        };
    },
    components: { DropDownList }
})
</script>

<style lang="scss" scoped>
.top-icon {
  position: relative;
}
</style>