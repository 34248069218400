<template>
  <div class="col-auto sidebar-wrap">
    <h6 class="dashboard-sidebar-headding">Promoted</h6>
<div class="dashboard-sidebar">
<div class="dashboard-sidebar-content">
<div class="promoted-list-slider-wrap">
<div class="promoted-list-slider">

  <swiper
    v-if="width <= 1200"
    class="swiper-wrapper dashboard-sidebar-list"
    :slides-per-view="(width >= 576) ? 2 : 1"
    :space-between="(width >= 576) ? 20 : 5"
    :scrollbar="{ draggable: true }"
    :tag="'ul'"
  >
    <template v-for="(ad, indx) in ads" v-bind:key="ad.header + indx + 'advertising-banner'">
      <swiper-slide :tag="'li'">
        <a href="javascript:void();">
        <div class="thumb"><img src="@/assets/images/thumb-1.jpg" alt=""></div>
        <div>
          <p>{{ ad.header }}</p>
          <p>{{ ad.text }}</p>
        </div>
        <span><img src="@/assets/images/anagle-right-xs.png" alt=""></span>
        </a>
      </swiper-slide>
    </template>
  </swiper>

  <ul v-else class="swiper-wrapper desktop dashboard-sidebar-list">

    <template v-for="(ad, indx) in ads" v-bind:key="ad.campaignTitle + indx + 'advertising-banner'">
    <li class="swiper-slide">
    <a :href="ad.url">
    <div class="thumb"><img :src="ad.adImage || defaultThumbBg" alt="thumb" /></div>
    <div>
      <p>{{ ad.campaignTitle }}</p>
      <p v-html="ad.text"></p>
    </div>
    <span>
      <img src="@/assets/images/anagle-right-xs.png" alt="">
    </span>
    </a>
    </li>
  </template> 
  </ul>

</div>
</div>
</div>
</div>
</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { inject } from 'vue';

import 'swiper/css';
import 'swiper/css/scrollbar';
import { ref } from 'vue';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { watch } from 'vue';

export default {
  name: 'DashboardSidebar',
  components: { Swiper, SwiperSlide },
  setup() {
    
    const store = useStore();

    const ads = ref([]);

    const defaultThumbBg = ref(require('@/assets/images/thumb-1.jpg'));

    const { width } = inject('mainUI');

    watch(() => (store.state.advertisementsList), (newVal) => {
      ads.value = newVal;
    })

    onMounted(() => {
      ads.value = store.state.advertisementsList;

      console.log(store.state.advertisementsList);
    });
    
    return {
      ads,
      width,
      defaultThumbBg
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-sidebar-list {
  &.desktop {
    display: flex;
    flex-direction: column;
  }
}
</style>