<template>
      <section class="section" ref="section">
  <div class="border-box active">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Labor Expenses</h5>
<div class="info-icons">
<div class="info-icon lg top-icon tooltip"
@click="addLaborExpense"
@mouseover="switchHelperTooltip(true)"
@mouseout="switchHelperTooltip(false)"
data-tooltip="Add Labor Expense"
v-if="isAuth('add-labor-expense')"
>
<img src="@/assets/images/updated/new_set2/farmer_Icon.png" alt="">
<!-- <img src="@/assets/images/updated/add-item.png" alt=""> -->
<!-- <div class="helper-tooltip" v-if="helperTooltip">Add Labor Expense</div> -->
</div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-wrapper">
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label">
<span>Date</span>
<span @click="() => sortTable('date')">
  <button :class="{active: sortOrder.date === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.date === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
</span></p></th>
<th><p class="form-label-static form-sort-label">
<span>Task</span>
<span @click="() => sortTable('task')">
  <button :class="{active: sortOrder.task === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.task === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
</span></p></th>
<th><p class="form-label-static form-sort-label">
<span>Assigned To</span>
<span @click="() => sortTable('assignedTo')">
  <button :class="{active: sortOrder.assignedTo === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.assignedTo === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
</span></p></th>
<th><p class="form-label-static form-sort-label">
<span>Task Type</span>
<span @click="() => sortTable('taskType')">
  <button :class="{active: sortOrder.taskType === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.taskType === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
</span></p></th>
<th><p class="form-label-static form-sort-label">
<span>Total Hours</span>
<span @click="() => sortTableByNumber('totalHours')">
  <button :class="{active: sortOrder.totalHours === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.totalHours === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
</span></p></th>
<th><p class="form-label-static form-sort-label">
<span>Total</span>
<span @click="() => sortTableByNumber('total')">
  <button :class="{active: sortOrder.total === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.total === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
</span></p></th>
<th class="w-75">&nbsp;</th>

</tr>
</thead>

<tbody>

<template
  v-for="(row, rowIndex) in rows"
  v-bind:key="'harvest-list-row' + row.id"
>
  <tr
    v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
    :class="{'light-gray-bg': row.id === selectedLaborExpense.id}"
    @click="(ev) => selectByRow(ev, {...row})"
  >
  <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
  <td class="text-center">{{ row.date }}</td>
  <td>{{ row.task }}</td>
  <td>{{ row.assignedTo }}</td>
  <td>{{ `${((row.taskType === 'regular') ? 'Regular' : '')} ${((row.taskType === 'overtime') ? 'Overtime' : '')}` }}</td>
  <td>{{ row.totalHours }}</td>
  <td>$ {{ row.total }}</td>
  <td class="w-75">
  <div class="table-links">
  <a v-if="isAuth('edit-labor-expense')" href="javascript:void();" @click="selectLaborExpense({...row}, 'edit')">
    <img src="@/assets/images/edit-icon-2.svg" alt="">
  </a>
  <!-- <a href="javascript:void();" @click="removeVendor(row.id)">
    <img src="@/assets/images/trash-2.svg" alt="">
  </a> -->
  <a v-if="isAuth('remove-labor-expense')" href="javascript:void();" @click="removeLaborExpenseRow(row.id, rowIndex)">
    <img src="@/assets/images/trash-2.svg" alt="">
  </a>
  </div>
  </td>
  </tr>
</template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'incomeListDetails'"
    :optionKey="'income-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>
</div>
</section>

<div class="removal-popup" v-if="removedLaborExpense">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">Are you sure you want to delete the Labor Expense?</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveRemoval(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { 
  ref, onMounted, watch, 
  computed, inject, reactive, 
  defineComponent, provide
} from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'IncomeList',
    setup() {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        // const laborExpenses = computed(() => store.state.authenticated.employeeType === 'part-time' ? store.state.laborExpenses.filter(labExp => 
        //   labExp.assignedTo === `${store.state.authenticated.firstName} ${store.state.authenticated.lastName}`) : []);
        const laborExpenses = computed(() => store.state.laborExpenses);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const sortOrder = reactive({
            date: 0,
            task: 0,
            assignedTo: 0,
            taskType: 0,
            totalHours: 0,
            total: 0
        });
        const removedLaborExpense = ref(null);

        const { isAuth } = inject('mainUI');
        const { selectedLaborExpense, addLaborExpense, editLaborExpense, selectLaborExpense, removeLaborExpense } = inject('laborExpenseDetails');
        const helperTooltip = ref(false);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...laborExpenses.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByNumber = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...laborExpenses.value];
                sortOrder[sortProp] = 0;
            }
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectLaborExpense({ ...row }, 'represent');
            }
        };

        const removeLaborExpenseRow = (id, index) => {
            removedLaborExpense.value = { id, index };
        };
        const approveRemoval = (approved) => {
            if (approved) {
              removeLaborExpense(removedLaborExpense.value.id)
            }
            removedLaborExpense.value = null;
        };

        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(laborExpenses.value, () => {
            rows.value = [...laborExpenses.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(store.state.laborExpenses, () => {
            console.log(laborExpenses.value, 'laborExpenses');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...laborExpenses.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
            (laborExpenses.value.length > 0) && selectLaborExpense({ ...laborExpenses.value[0] }, 'represent');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getHarvests')
            rows.value = [...laborExpenses.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
            // tasks.value.forEach(task => {
            //   if (task.amtHarvested && (task.taskStatus === 'Completed')) {
            //     !laborExpenses.value.find(hrv => hrv.id === task.id) && laborExpenses.value.push({ ...task })
            //   }
            // })
        });
        provide('incomeListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            addLaborExpense,
            editLaborExpense,
            selectLaborExpense,
            removeLaborExpense,
            rows,
            maxPage,
            selectedLaborExpense,
            helperTooltip,
            switchHelperTooltip,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            sortTableByNumber,
            selectByRow,
            currentPage,
            removedLaborExpense,
            removeLaborExpenseRow,
            approveRemoval,
            isAuth
        };
    },
    components: { DropDownList }
})
</script>