<template>
  <section class="section">
<div class="border-box">
<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Add Task</h5>
<div class="icon-group">
  <div class="icon green icon-o tooltip"
    @mouseover="switchCalHelperTooltip(true)"
    @mouseout="switchCalHelperTooltip(false)"
    @click="cancelChanges"
    data-tooltip="Choose date"
  >
    <!-- <img src="@/assets/images/task-calendar.svg" alt=""> -->
    <img src="@/assets/images/updated/calendar-white.png" alt="">
    <!-- <div class="task-calendar"></div> -->
    <!-- <div class="helper-tooltip" v-if="calHelperTooltip">Choose date</div> -->
  </div>
  <!-- <div class="icon green icon-o tooltip"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
    data-tooltip="Camera"
  >
    <img src="@/assets/images/updated/camera-white.png" alt="">
  </div> -->
</div>
</div>

<div class="content-block d-lg-flex justify-content-between">
<div class="checkbox order-lg-2 recurringTaskCheckbox">
  <input type="checkbox" id="recurringTask" v-model="task.recurring" />
  <label for="recurringTask">Recurring Task</label>
</div>
<div class="checkbox order-lg-2 reminderCheckbox reminder-mobile" :class="{active: task.reminder}">
  <div>
    <input type="checkbox" id="reminderTask" v-model="task.reminder" />
    <label for="reminderTask">
      <span class="reminder-main-label">Reminder</span>
      <span class="reminder-placeholder">Recurring Task</span>
    </label>
  </div>

  <div class="col-6 common" v-if="task.reminder">
    <div class="form-item reminder-list">
      <!-- <select class="form-control" v-model="task.reminderTime">
        <option
          v-for="reminderOption in reminderOptions"
          v-bind:key="reminderOption + 'reminder-option-task'"
        >
          {{ reminderOption }}
        </option>
      </select> -->
      <DropDownList
        :mainObject="'task'"
        :mainProperty="'reminderTime'"
        :injectionName="'taskDetails'"
        :optionKey="'reminder-option-task'"
        :optionsList="reminderOptions"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="task.reminderTime"
      />
    </div>
  </div>
</div>
<div class="checkbox-group task-category">
<p class="fw-semibold gray checkbox-header">Select Categories:</p>
<div class="checkbox"><input type="checkbox" id="input" v-model="task.inputTask"><label for="input">Input</label></div>
<div class="checkbox"><input type="checkbox" id="harvest" v-model="task.harvestTask"><label for="harvest">Harvest</label></div>
<div class="checkbox"><input type="checkbox" id="other" v-model="task.otherTask"><label for="other">Other</label></div>
<div class="info-icon category-tooltip-container" @mouseover="switchCategoryTooltip(true)" @mouseout="switchCategoryTooltip(false)">
  <img src="@/assets/images/question-mark.png" alt="" />
  <div class="category-tooltip" v-if="categoryTooltip">Choose Input for tasks that involve input,  harvest for any crop harvesting and Other for general tasks. You can also choose Input and Harvest that involves both tasks.</div>
</div>
</div>
</div>

<div class="content-block d-lg-flex justify-content-between">
<div class="checkbox order-lg-2 reminderCheckbox reminder-desktop" :class="{active: task.reminder}">
  <div>
    <input type="checkbox" id="reminderTask" v-model="task.reminder" />
    <label for="reminderTask">
      <span class="reminder-main-label">Reminder</span>
      <span class="reminder-placeholder">Recurring Task</span>
    </label>
  </div>

  <div class="col-6 common" v-if="task.reminder">
    <div class="form-item reminder-list">
      <!-- <select class="form-control" v-model="task.reminderTime">
        <option
          v-for="reminderOption in reminderOptions"
          v-bind:key="reminderOption + 'reminder-option-task'"
        >
          {{ reminderOption }}
        </option>
      </select> -->

      <DropDownList
        :mainObject="'task'"
        :mainProperty="'reminderTime'"
        :injectionName="'taskDetails'"
        :optionKey="'reminder-option-task'"
        :optionsList="reminderOptions"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="task.reminderTime"
      />
    </div>
  </div>
</div>
<!-- <div class="checkbox-group task-type">
<p class="fw-semibold gray checkbox-header">Task Type:</p>
<div class="checkbox regular"><input type="checkbox" id="regularHours" v-model="task.regularHours"><label for="regularHours">Regular</label></div>
<div class="checkbox"><input type="checkbox" id="overTime" v-model="task.overTime"><label for="overTime">Over Time</label></div>
<p v-if="activated.taskType && (!task.regularHours && !task.overTime)" class="form-info red text-end" style="text-align: left; padding-left: 8rem;">* Required</p>
</div> -->
</div>

<div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.date || (task.date.length > 0)}">Date</p>
  <input type="date" class="form-control date-field" 
    :style="{color: (focused.date || task.date.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
    v-model="task.date"
    pattern="\d{2}/\d{2}/\d{4}"
    @focus="() => {
      activated.date = true;
      focused.date = true;
    }"
    @blur="() => {
      focused.date = false;
    }">
    <div class="date-icon"></div>
  <p v-if="activated.date && task.date.length === 0" class="form-info red text-end">* Required</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.startTime || (task.startTime.length > 0)}">Start Time</p>

  <!-- <input type="text" class="form-control" 
    v-model="task.startTime"
    @focus="() => {
      activated.startTime = true;
      focused.startTime = true;
    }"
    @blur="() => {
      focused.startTime = false;
    }"> -->

  <!-- <select class="form-control" 
    v-model="task.startTime"
    @click="() => {
      activated.startTime = true;
      focused.startTime = true;
    }"
    @change="() => {
      focused.startTime = false;
    }">
  <option 
    v-for="timeOption in timesOptions"
    v-bind:key="timeOption + 'start-time-option-task'"
  >{{ timeOption }}</option>
  </select> -->

  <DropDownList
    :mainObject="'task'"
    :mainProperty="'startTime'"
    :injectionName="'taskDetails'"
    :optionKey="'start-time-option-task'"
    :optionsList="timesOptions"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="task.startTime"
    @click="() => {
      activated.startTime = true;
    }"
  />
    <input type="text" class="form-control time-insert"
      v-model="task.startTime"
      @focus="() => {
        activated.startTime = true;
        focused.startTime = true;
      }"
      @blur="() => {
        focused.startTime = false;
      }"
    />

  <p v-if="activated.startTime && task.startTime.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.startTime && task.startTime.length > 8" class="form-info red text-end">Please use maximum 8 characters</p>
  </div>
  </div>

<template v-if="task.inputTask || task.harvestTask || task.otherTask">
<div class="col-3 common">
<div class="form-item">
<p class="form-label" :class="{active: focused.farm || (task.farm.length > 0)}">Farm</p>
<!-- <input type="text" class="form-control" 
  v-model="task.farm"
  @focus="() => {
    activated.farm = true;
    focused.farm = true;
  }"
  @blur="() => {
    focused.farm = false;
  }"> -->
  <!-- <select class="form-control" 
    v-model="task.farm"
    @click="() => {
      activated.farm = true;
      focused.farm = true;
    }"
    @change="() => {
      focused.farm = false;
    }">
  <option
    v-for="farm in farms"
    v-bind:key="farm.farmName + 'farm-option-task'"
  >{{ farm.farmName }}</option>
</select> -->
<DropDownList
  :mainObject="'task'"
  :mainProperty="'farm'"
  :injectionName="'taskDetailsSix'"
  :optionKey="'farm-option-task'"
  :optionsList="[]"
  :optionProperty="'farmName'"
  :optionPropertyTwo="null"
  :defaultSelected="task.farm"
  @click="() => {
    activated.farm = true;
  }"
/>
<p v-if="activated.farm && task.farm.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.farm && task.farm.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3 common" v-if="bedsOptions.value.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.bed || (task.bed.length > 0)}">Bed</p>
<!-- <input type="text" class="form-control" 
  v-model="task.bed"
  @focus="() => {
    activated.bed = true;
    focused.bed = true;
  }"
  @blur="() => {
    focused.bed = false;
  }"> -->
  <!-- <select class="form-control" 
    v-model="task.bed"
    @click="() => {
      activated.bed = true;
      focused.bed = true;
    }"
    @change="() => {
      focused.bed = false;
    }">
    <option
      v-for="bed in bedsOptions"
      v-bind:key="bed + 'bed-option-task'"
    >{{ bed }}</option>
  </select> -->
  <DropDownList
    :mainObject="'task'"
    :mainProperty="'bed'"
    :injectionName="'taskDetails'"
    :optionKey="'bed-option-task'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="task.bed"
    @click="() => {
      activated.bed = true;
    }"
  />
<p v-if="activated.bed && task.bed.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.bed && task.bed.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
</div>
</div>

<div class="col-3 common">
<div class="form-item">
<p class="form-label" :class="{active: focused.season || (task.season.length > 0)}">Season</p>
<!-- <select class="form-control"
  v-model="task.season"
  @click="() => {
    activated.season = true;
    focused.season = true;
  }"
  @change="() => {
    focused.season = false;
  }"
  disabled
>
  <option v-for="seasonOption in cropSeasons"
    v-bind:key="seasonOption + 'season-option-task'"
  >{{ seasonOption }}</option>
</select> -->

  <DropDownList
    :mainObject="'task'"
    :mainProperty="'season'"
    :injectionName="'taskDetailsSeven'"
    :optionKey="'season-option-task'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="task.season"
    @click="() => {
      activated.season = true;
    }"
  />
<p v-if="activated.season && task.season.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.season && task.season.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3 common">
<div class="form-item">
<p class="form-label" :class="{active: focused.cropCycle || (task.cropCycle.length > 0)}">Crop Cycle</p>

<!-- <input type="text" class="form-control"
  v-model="task.cropCycle"
  @focus="() => {
    activated.cropCycle = true;
    focused.cropCycle = true;
  }"
  @blur="() => {
    focused.cropCycle = false;
  }" disabled /> -->
  
  <DropDownList
    :mainObject="'task'"
    :mainProperty="'cropCycle'"
    :injectionName="'taskDetailsEight'"
    :optionKey="'cycle-option-task'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="task.cropCycle"
    @click="() => {
      activated.cropCycle = true;
    }"
  />
<p v-if="activated.cropCycle && task.cropCycle.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.cropCycle && task.cropCycle.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3 common" v-if="crops.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.crop || (task.crop.length > 0)}">Crop</p>
  <!-- <select class="form-control" 
    v-model="task.crop"
    @click="() => {
      activated.crop = true;
      focused.crop = true;
    }"
    @change="() => {
      focused.crop = false;
    }">
    <option
      v-for="cropItem in crops"
      v-bind:key="cropItem.id + 'crop-option-task'"
    >{{ cropItem.crop }}</option>
  </select> -->
  <DropDownList
    :mainObject="'task'"
    :mainProperty="'crop'"
    :injectionName="'taskDetailsTwo'"
    :optionKey="'crop-option-task'"
    :optionsList="[]"
    :optionProperty="'crop'"
    :optionPropertyTwo="null"
    :defaultSelected="task.crop"
    @click="() => {
      activated.crop = true;
    }"
  />
  <!-- :optionsTwo="true" -->
<p v-if="activated.crop && task.crop.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.crop && task.crop.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3 common" v-else>
<div class="form-item">
<p class="form-label" :class="{active: focused.crop || (task.crop.length > 0)}">Crop</p>
<input type="text" class="form-control"
  v-model="task.crop"
  @focus="() => {
    activated.crop = true;
    focused.crop = true;
  }"
  @blur="() => {
    focused.crop = false;
  }"
  placeholder="E.g., Rice"
  >
<p v-if="activated.crop && task.crop.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.crop && task.crop.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>
</template>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.task || (task.task.length > 0)}">Task</p>
<input type="text" class="form-control" 
  v-model="task.task"
  @focus="() => {
    activated.task = true;
    focused.task = true;
  }"
  @blur="() => {
    focused.task = false;
  }"
  placeholder="E.g., Add Fertilizer"
  >
<p v-if="activated.task && task.task.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.task && task.task.length > 150" class="form-info red text-end">Please use maximum 150 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.priority || (task.priority.length > 0)}">Priority</p>
<!-- <select class="form-control" 
  v-model="task.priority"
  @click="() => {
    activated.priority = true;
    focused.priority = true;
  }"
  @change="() => {
    focused.priority = false;
  }">
<option>High</option>
<option>Medium</option>
<option>Low</option>
</select> -->
<DropDownList
  :mainObject="'task'"
  :mainProperty="'priority'"
  :injectionName="'taskDetails'"
  :optionKey="'priority-option-task'"
  :optionsList="['High', 'Medium', 'Low']"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="task.priority"
  @click="() => {
    activated.priority = true;
  }"
/>
<p v-if="activated.priority && task.priority.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3" v-if="users.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.assignedTo || (task.assignedTo.length > 0)}">Assigned To</p>
  <!-- <select class="form-control" 
    v-model="task.assignedTo"
    @click="() => {
      activated.assignedTo = true;
      focused.assignedTo = true;
    }"
    @change="() => {
      focused.assignedTo = false;
    }">
    <option
      v-for="user in users"
      v-bind:key="user.id + 'user-option-task'"
    >{{ `${user.mainDetails.firstName} ${user.mainDetails.lastName}` }}</option>
  </select> -->

  <DropDownList
    :mainObject="'task'"
    :mainProperty="'assignedTo'"
    :injectionName="'taskDetailsFive'"
    :optionKey="'user-option-task'"
    :optionsList="[]"
    :optionProperty="'mainDetails'"
    :optionPropertyTwo="['firstName', 'lastName']"
    :defaultSelected="task.assignedTo"
    @click="() => {
      activated.farm = true;
    }"
  />
<p v-if="activated.assignedTo && task.assignedTo.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.assignedTo && task.assignedTo.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-else>
<div class="form-item">
<p class="form-label" :class="{active: focused.assignedTo || (task.assignedTo.length > 0)}">Assigned To</p>
<input type="text" class="form-control" 
  v-model="task.assignedTo"
  @focus="() => {
    activated.assignedTo = true;
    focused.assignedTo = true;
  }"
  @blur="() => {
    focused.assignedTo = false;
  }">
<p v-if="activated.assignedTo && task.assignedTo.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.assignedTo && task.assignedTo.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-if="taskForPartTimeUser">
<div class="form-item">
<p class="form-label" :class="{active: focused.taskType || (task.taskType.length > 0)}">Task Type</p>
<!-- <select class="form-control" 
  v-model="task.taskType"
  @click="() => {
    activated.taskType = true;
    focused.taskType = true;
  }"
  @change="() => {
    focused.taskType = false;
  }">
<option>Regular</option>
<option>Over Time</option>
</select> -->
<DropDownList
  :mainObject="'task'"
  :mainProperty="'taskType'"
  :injectionName="'taskDetails'"
  :optionKey="'task-type-option-task'"
  :optionsList="['Regular', 'Over Time']"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="task.taskType"
  @click="() => {
    activated.taskType = true;
  }"
/>
<p v-if="activated.taskType && task.taskType.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<template v-if="task.inputTask">

<div class="col-3 input-item" v-if="products.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.input || (task.input.length > 0)}">Input</p>
  <!-- <select class="form-control" 
    v-model="task.input"
    @click="() => {
      activated.input = true;
      focused.input = true;
    }"
    @change="() => {
      focused.input = false;
    }">
    <option
      v-for="prod in products"
      v-bind:key="prod.id + 'product-option-task'"
    >{{ prod.item }}</option>
    <option>Other</option>
  </select> -->
  <!-- maybe need provide optionsFour -->
  <DropDownList
    :mainObject="'task'"
    :mainProperty="'input'"
    :injectionName="'taskDetailsThree'"
    :optionKey="'product-option-task'"
    :optionsList="[]"
    :optionProperty="'item'"
    :optionPropertyTwo="null"
    :defaultSelected="task.input"
    @click="() => {
      activated.input = true;
    }"
  />
<p v-if="activated.input && task.input.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.input && task.input.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3 input-item" v-else>
<div class="form-item">
<p class="form-label" :class="{active: focused.input || (task.input.length > 0)}">Input</p>
<input type="text" class="form-control" 
  v-model="task.input"
  @focus="() => {
    activated.input = true;
    focused.input = true;
    inputDialog = true;
  }"
  @blur="() => {
    focused.input = false;
  }">
<p v-if="activated.input && task.input.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.input && task.input.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>


<div class="col-3 input-item">
<div class="form-item">
<p class="form-label" :class="{active: focused.variety || (task.variety.length > 0)}">Variety</p>

      <template v-if="!manualVariety">
        <DropDownList
          :mainObject="'task'"
          :mainProperty="'variety'"
          :injectionName="'taskDetailsNine'"
          :optionKey="'variety-option-task'"
          :optionsList="[]"
          :optionProperty="null"
          :optionPropertyTwo="null"
          :defaultSelected="task.variety"
          @click="() => {
            activated.variety = true;
          }"
        />
      </template>

      <template v-else>
        <input type="text" class="form-control" ref="varietyField"
          :disabled="waterIsChoosen"
          v-model="task.variety"
          @focus="() => {
            activated.variety = true;
            focused.variety = true;
          }"
          @blur="() => {
            focused.variety = false;
          }"
          placeholder="E.g., Organic"
        />
      </template>
<p v-if="activated.variety && task.variety.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>


<div class="col-3 input-item">
<div class="form-item">
<p class="form-label" :class="{active: focused.brand || (task.brand.length > 0)}">Brand</p>
<input type="text" class="form-control" v-model="task.brand"
  :disabled="waterIsChoosen"
  @focus="() => {
    activated.brand = true;
    focused.brand = true;
  }"
  @blur="() => {
    focused.brand = false;
  }"
  placeholder="E.g., Hj567"
  >
<p v-if="activated.brand && task.brand.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.brand && task.brand.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

</template>

<template v-if="task.inputTask || task.harvestTask || task.otherTask">
  <div class="col-3 common" v-if="equipments.length > 0">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.equipment || (task.equipment.length > 0)}">Equipment</p>
    <!-- <select class="form-control" 
      v-model="task.equipment"
      @click="() => {
        activated.equipment = true;
        focused.equipment = true;
      }"
      @change="() => {
        focused.equipment = false;
      }">
      <option
        v-for="eq in equipments"
        v-bind:key="eq.equipment + 'equipment-option-task'"
      >{{ eq.item }}</option>
      <option>Other</option>
    </select> -->
    <!-- maybe need provide optionsThree -->
    <DropDownList
      :mainObject="'task'"
      :mainProperty="'equipment'"
      :injectionName="'taskDetailsFour'"
      :optionKey="'equipment-option-task'"
      :optionsList="[]"
      :optionProperty="'item'"
      :optionPropertyTwo="null"
      :defaultSelected="task.equipment"
      @click="() => {
        activated.equipment = true;
      }"
    />
  <!-- <p v-if="activated.equipment && task.equipment.length === 0" class="form-info red text-end">* Required</p> -->
  <p v-if="activated.equipment && task.equipment.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
  </div>
  </div>

  <div class="col-3 common" v-else>
  <div class="form-item">
  <p class="form-label" :class="{active: focused.equipment || (task.equipment.length > 0)}">Equipment</p>
  <input type="text" class="form-control" 
    v-model="task.equipment"
    @focus="() => {
      activated.equipment = true;
      focused.equipment = true;
      // promptToAddEquipment();
      equipmentDialog = true;
    }"
    @blur="() => {
      focused.equipment = false;
    }"
    placeholder="E.g., Drip Irrigation Pipes"
    >
  <!-- <p v-if="activated.equipment && task.equipment.length === 0" class="form-info red text-end">* Required</p> -->
  <p v-if="activated.equipment && task.equipment.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
  </div>
  </div>
</template>

<template v-if="task.inputTask">

<div class="col-3 input-item">
<div class="form-item">
<p class="form-label" :class="{active: focused.numberOfItems || (task.numberOfItems.length > 0)}"># Of Items</p>
<input type="text" class="form-control" 
  :disabled="waterIsChoosen || (task.equipment === 'Not required')"
  v-model="task.numberOfItems"
  @focus="() => {
    activated.numberOfItems = true;
    focused.numberOfItems = true;
  }"
  @blur="() => {
    focused.numberOfItems = false;
  }"
  placeholder="E.g., 2"
  >
<p v-if="activated.numberOfItems && task.numberOfItems.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.numberOfItems && task.numberOfItems.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3 input-item">
<div class="form-item">
<p class="form-label" :class="{active: focused.quantity || (task.quantity.length > 0)}">Weight (lbs/item)</p>
<input type="text" class="form-control" v-model="task.quantity"
  :disabled="waterIsChoosen"
  @focus="() => {
    activated.quantity = true;
    focused.quantity = true;
  }"
  @blur="() => {
    focused.quantity = false;
  }"
  placeholder="E.g., 0.1"
  >
<p v-if="activated.quantity && task.quantity.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.quantity && `${task.quantity}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

</template>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.taskStatus || (task.taskStatus.length > 0)}">Task Status</p>
<!-- <select class="form-control" 
  v-model="task.taskStatus"
  @click="() => {
    activated.taskStatus = true;
    focused.taskStatus = true;
  }"
  @change="() => {
    focused.taskStatus = false;
  }">
<option>Assigned</option>
<option>In Progress</option>
<option>Completed</option>
</select> -->
<DropDownList
  :mainObject="'task'"
  :mainProperty="'taskStatus'"
  :injectionName="'taskDetails'"
  :optionKey="'task-status-option-task'"
  :optionsList="['Assigned', 'In Progress', 'Re-Assigned', 'Deferred', 'Completed']"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="task.taskStatus"
  @click="() => {
    activated.taskStatus = true;
  }"
/>
<p v-if="activated.taskStatus && task.taskStatus.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3" v-if="task.taskStatus === 'Completed'">
<div class="form-item">
<p class="form-label" :class="{active: focused.completionTime || (task.completionTime.length > 0)}">Completion Time</p>

<!-- <input type="text" class="form-control" 
  v-model="task.completionTime"
  @focus="() => {
    activated.completionTime = true;
    focused.completionTime = true;
  }"
  @blur="() => {
    focused.completionTime = false;
  }"> -->

<!-- <select class="form-control" 
  v-model="task.completionTime"
  @click="() => {
    activated.completionTime = true;
    focused.completionTime = true;
  }"
  @change="() => {
    focused.completionTime = false;
  }">
<option 
  v-for="timeOption in timesOptions"
  v-bind:key="timeOption + 'completion-time-option-task'"
>{{ timeOption }}</option>
</select> -->
<DropDownList
  :mainObject="'task'"
  :mainProperty="'completionTime'"
  :injectionName="'taskDetails'"
  :optionKey="'task-status-option-task'"
  :optionsList="timesOptions"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="task.completionTime"
  @click="() => {
    activated.completionTime = true;
  }"
/>
  <input type="text" class="form-control time-insert"
    v-model="task.completionTime"
    @focus="() => {
      activated.completionTime = true;
      focused.completionTime = true;
    }"
    @blur="() => {
      focused.completionTime = false;
    }"
  />

<p v-if="(task.taskStatus === 'Completed') && (activated.completionTime && task.completionTime.length === 0)" class="form-info red text-end">* Required</p>
<!-- <p v-if="activated.completionTime && task.completionTime.length > 8" class="form-info red text-end">Please use maximum 8 characters</p> -->
<p v-if="(timeValidation === false) && (!focused.completionTime) && (task.completionTime.length === 0)" class="form-info red text-end">Validate Completion time</p>
</div>
</div>

<div class="col-3" v-if="task.taskStatus === 'Completed'">
<div class="form-item">
<p class="form-label" :class="{active: focused.totalHours || (`${task.totalHours}`.length > 0)}">Total Hours</p>
<input type="text" class="form-control" v-model="task.totalHours"
  @focus="() => {
    activated.totalHours = true;
    focused.totalHours = true;
  }"
  @blur="() => {
    focused.totalHours = false;
    formatTotalHours();
  }"
  placeholder="E.g., 2"
  >
<p v-if="(task.taskStatus === 'Completed') && (activated.totalHours && task.totalHours.length === 0)" class="form-info red text-end">* Required</p>
<!-- <p v-if="activated.totalHours && task.totalHours.length > 6" class="form-info red text-end">Please use maximum 6 characters</p> -->
<p v-if="(timeValidation === false) && (!focused.totalHours) && (task.totalHours.length === 0)" class="form-info red text-end">Add Hours</p>
</div>
</div>

<template v-if="task.harvestTask && (task.taskStatus === 'Completed')">
<!-- <div class="col-3 harvest-item">
<div class="form-item">
<p class="form-label" :class="{active: focused.cropHarvested || (task.cropHarvested.length > 0)}">Crop Harvested</p>
<input type="text" class="form-control" 
  v-model="task.cropHarvested"
  @focus="() => {
    activated.cropHarvested = true;
    focused.cropHarvested = true;
  }"
  @blur="() => {
    focused.cropHarvested = false;
  }">
<p v-if="(activated.cropHarvested && task.cropHarvested.length === 0) && (task.taskStatus === 'Completed')" class="form-info red text-end">* Required</p>
<p v-if="activated.cropHarvested && task.cropHarvested.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div> -->

<div class="col-3 harvest-item">
<div class="form-item">
<p class="form-label" :class="{active: focused.amtHarvested || (task.amtHarvested.length > 0)}">Amt. Harvested (lbs.)</p>
<input type="text" class="form-control" 
  v-model="task.amtHarvested"
  @focus="() => {
    activated.amtHarvested = true;
    focused.amtHarvested = true;
  }"
  @blur="() => {
    focused.amtHarvested = false;
  }"
  placeholder="E.g., 30"
  >
<p v-if="(activated.amtHarvested && task.amtHarvested.length === 0) && (task.taskStatus === 'Completed')" class="form-info red text-end">* Required</p>
<p v-if="activated.amtHarvested && task.amtHarvested.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3 harvest-item">
<div class="form-item">
<p class="form-label" :class="{active: focused.wastage || (task.wastage.length > 0)}">Wastage (lbs.)</p>
<input type="text" class="form-control" 
  v-model="task.wastage"
  @focus="() => {
    activated.wastage = true;
    focused.wastage = true;
  }"
  @blur="() => {
    focused.wastage = false;
  }"
  placeholder="E.g., 2"
  >
<p v-if="(activated.wastage && task.wastage.length === 0) && (task.taskStatus === 'Completed')" class="form-info red text-end">* Required</p>
<p v-if="activated.wastage && task.wastage.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3 harvest-item">
<div class="form-item">
<p class="form-label" :class="{active: focused.wastageCause || (task.wastageCause.length > 0)}">Wastage Cause</p>
<input type="text" class="form-control" 
  v-model="task.wastageCause"
  @focus="() => {
    activated.wastageCause = true;
    focused.wastageCause = true;
  }"
  @blur="() => {
    focused.wastageCause = false;
  }"
  placeholder="E.g., Insect Damage"
  >
<p v-if="(activated.wastageCause && task.wastageCause.length === 0) && (task.wastage.length > 0) && (task.taskStatus === 'Completed')" class="form-info red text-end">* Required</p>
<p v-if="activated.wastageCause && task.wastageCause.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

</template>

<div class="col-12">
<div class="form-item">
<p class="form-label" :class="{active: focused.comments || (task.comments.length > 0)}">Comments</p>
<textarea class="form-control" 
  v-model="task.comments"
  @focus="() => {
    activated.comments = true;
    focused.comments = true;
  }"
  @blur="() => {
    focused.comments = false;
  }"
  placeholder="E.g., Add Fertilizer"
  ></textarea>
<p v-if="activated.comments && task.comments.length > 150" class="form-info red text-end">Please use maximum 150 characters</p>
</div>
</div>


</div>
</div>
</div>

</div>

<div class="content-block">

<template v-if="!task.recurring">
  <FileAttachments :key="`task-form-${task.id}`" v-bind:section="'task'" v-bind:instance="`${task.id}`" />
</template>

<template v-else>
  <FileAttachments :key="`task-form-${task.id}`" v-bind:section="'task'" v-bind:instance="`${task.recurrenceId}`" />  
</template>

<div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
</div>
</div>
</div>

</div>

</div>
</div>
</section><!--//End Section-->

  <div v-if="task.recurring && recurringIsActive">
    <RecurringTaskForm />
  </div>

<div v-if="timeValidation" class="time-validation-overspace">

  <div class="modal modal-md">
  <span class="modal-close" @click="() => {timeValidation = false;}"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">Please validate completion time</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
         @click="() => {
            timeValidation = false;
          }"
        class="approve-button btn green" 
      >Ok</button>
  </div>
  </div>
  </div>

  <!-- <div class="validation-popup">
    <div class="validation-message">
      <div class="close-button" @click="() => {
        timeValidation = false;
      }" ></div>
      <span class="validation-text">
        Please validate completion time
      </span>
      <div class="validation-buttons">
        <button
          @click="() => {
            timeValidation = false;
          }"
          class="approve-button btn green" 
        >Ok</button>
      </div>
    </div>
  </div> -->
</div>

<div v-if="popUps.isRecurringToEdit.active" class="removal-popup">

<div class="modal modal-md">
<span class="modal-close" @click="() => confirmRecurring(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">{{ popUps.isRecurringToEdit.message }}</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
       @click="() => confirmRecurring(true)"
      class="approve-button btn green" 
    >Recurring Task</button>

    <button
       @click="() => confirmRecurring(false)"
      class="approve-button btn green" 
    >This Task</button>
</div>
</div>
</div>

</div>

<div v-if="popUps.addEquipment.active">
  <UserNotification
    v-bind:messageText="popUps.addEquipment.message"
  />
</div>

<div v-if="missedField">
  <UserNotification
    v-bind:messageText="'Please add the required data'"
  />
</div>

<template  v-if="equipmentDialog">
  <div class="form-modal">
    <span class="form-modal-close" @click="() => {equipmentDialog = false}"><img src="@/assets/images/close-green-xs.png" alt=""></span>
    <VendorModule v-bind:equipmentDialogView="true" />
  </div>
</template>

<template  v-if="inputDialog">
  <div class="form-modal">
    <span class="form-modal-close" @click="() => {inputDialog = false}"><img src="@/assets/images/close-green-xs.png" alt=""></span>
    <VendorModule v-bind:inputDialogView="true" />
  </div>
</template>

</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import RecurringTaskForm from './RecurringTaskForm.vue';
import { reactive, inject, ref, computed, onMounted, watch, provide } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import timesOptions from '@/sharedData/timesOptions';
import reminderOptions from '@/sharedData/reminderIntervals';
import { useRouter } from 'vue-router';
import UserNotification from '@/components/Shared/UserNotification.vue';
import VendorModule from '../Vendor/VendorModule.vue';
import DropDownList from '@/components/Shared/DropDownList.vue';

export default {
    name: "AddTask",
    setup() { 
      const store = useStore();
      const router = useRouter();

      const cropSeasons = ref(['Spring', 'Summer', 'Autumn', 'Winter']);

      const cropCycles = ref([]);

      const activated = reactive({
        date: false,
        task: false,
        assignedTo: false,
        priority: false,
        startTime: false,
        completionTime: false,
        totalHours: false,
        taskStatus: false,
        comments: false,

        farm: false,
        bed: false,
        season: false,
        cropCycle: false,
        crop: false,
        equipment: false,
        input: false,
        brand: false,
        variety: false,
        quantity: false,
        numberOfItems: false,

        cropHarvested: false,
        amtHarvested: false,
        wastage: false,
        wastageCause: false,
        taskType: false
      });

      const focused = reactive({
        date: false,
        task: false,
        assignedTo: false,
        priority: false,
        startTime: false,
        completionTime: false,
        totalHours: false,
        taskStatus: false,
        comments: false,

        farm: false,
        bed: false,
        season: false,
        cropCycle: false,
        crop: false,
        equipment: false,
        input: false,
        brand: false,
        variety: false,
        quantity: false,
        numberOfItems: false,

        cropHarvested: false,
        amtHarvested: false,
        wastage: false,
        wastageCause: false
      });

      // const inputTask = ref(false);

      // const harvestTask = ref(false);

      // const otherTask = ref(false);

      const timeValidation = ref(false);

      // const farms = computed(() => store.state.farms);

      const equipments = ref([{item: 'Not required'}, ...[...store.state.vendorItems].filter(vendorItem => vendorItem.type === 'Equipment'), {item: 'Add New'}]);
      const products = ref([{item: 'Not required'}, ...[...store.state.vendorItems].filter(vendorItem => vendorItem.type === 'Product'), {item: 'Water'}, {item: 'Add New'}]);
  
      // const usersState = computed(() => store.state.users);

      // const users = ref([]);

      const tasks = computed(() => store.state.tasks);

      const crops = ref(store.state.crops);

      const newTaskId = ref(null);

      const recurrenceGroupId = ref(null);

      const { task, editionMode, selectedTask, selectTask, defaultTask, options: bedsOptions, /* optionsTwo:  crops,*/ currentSelectedTask, users, farms } = inject("taskDetails");

      const taskReminder = ref(false);

      const recurringIsActive = ref(false);

      const recurringIsEdited = ref(false);

      const taskForPartTimeUser = ref(false);

      const inputDialog = ref(false);

      const equipmentDialog = ref(false);
    
      const helperTooltip = ref(false);

      const missedField = ref(false);

      const varietyOptions = ref([...new Set(products.value.map(option => option.variety).filter(o => o))]);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const calHelperTooltip = ref(false);

      const waterIsChoosen = ref(false);

      const switchCalHelperTooltip = (toggle) => {
        calHelperTooltip.value = toggle;
      }

      const categoryTooltip = ref(false);
      
      const switchCategoryTooltip = (toggle) => {
        categoryTooltip.value = toggle;
      }

      const popUps = reactive({
        addEquipment: {
          active: false,
          message: 'Add information in Vendor & Equipment Module'
        },
        isRecurringToEdit: {
          active: false,
          message: 'What do you want to update?'
        }
      });

      const selectedProductIndex = ref(-1);

      const promptToAddEquipment = () => {
        popUps.addEquipment.active = true;

        setTimeout(() => {
          popUps.addEquipment.active = false;
        }, 5100);
      }

      const submitForm = () => {

        const selectedFarm = farms.value.find(frm => frm.farmName === task.farm);

        if (!task.task || !task.assignedTo || !task.date || !task.priority || 
          !task.startTime || !task.taskStatus || ((taskForPartTimeUser.value === true) && !task.taskType) || 
          ((task.taskStatus === 'Completed') && (!task.completionTime || !task.totalHours)) || 
          (
            // IS Bed required??
            !!task.inputTask && (
              !task.farm || ((selectedFarm?.beds.length > 0) && !task.bed) || 
              !task.crop || ((task.taskStatus === 'Completed') && (// !task.input || !task.brand || 
              // !task.quantity
              !task.input
              // || (!task.brand && !waterIsChoosen.value) 
              || (!task.numberOfItems && (!waterIsChoosen.value || (task.equipment === 'Not required')))
              ))
            )
          ) || (
            !!task.harvestTask && (
              // IS Bed required??
              !task.farm || ((selectedFarm?.beds.length > 0) && !task.bed) || 
              !task.crop || (task.taskStatus === 'Completed' && (!task.amtHarvested || 
              (task.wastage && !task.wastageCause)))
            )
          )) {

          if (!task.task) activated.task = true; //null for numbers
          if (!task.assignedTo) activated.assignedTo = true;
          if (!task.date) activated.date = true;
          if (!task.priority) activated.priority = true;
          if (!task.startTime) activated.startTime = true;
          
          if (task.taskStatus === 'Completed') {
            if (!task.completionTime) activated.completionTime = true;
            if (!task.totalHours) activated.totalHours = true;
          }
          if ((taskForPartTimeUser.value === true) && !task.taskType) activated.taskType = true;

          if (!task.taskStatus) activated.taskStatus = true;

          if (!task.farm) activated.farm = true;
          if (!task.bed) activated.bed = true;
          if (!task.crop) activated.crop = true;
          // if (!task.equipment) activated.equipment = true;
          if (!task.input) activated.input = true;
          // if (!task.brand && !waterIsChoosen.value) activated.brand = true;
          if (!task.numberOfItems && (!waterIsChoosen.value || (task.equipment === 'Not required'))) activated.numberOfItems = true;
          // if (!task.quantity) activated.quantity = true;

          if (!task.farmName) activated.farmName = true;
          // if (!task.bed) activated.bed = true;
          // if (!task.crop) activated.crop = true;
          if (task.taskStatus === 'Completed') {
            // if (!task.cropHarvested) activated.cropHarvested = true;
            if (!task.amtHarvested) activated.amtHarvested = true;
            if (task.wastage && !task.wastageCause) activated.wastageCause = true;
          }

          missedField.value = true;

          setTimeout(() => {
            missedField.value = false;
          }, 5100);

          return;
        }

        if (task.recurring) {
          
          const formData = {
            ...task
          };
          
          if (formData.taskStatus === 'Completed') {
            
            const assignedUser = users.value.find(user => ((user.mainDetails.firstName === formData.assignedTo.split(' ')[0]) && (user.mainDetails.lastName === formData.assignedTo.split(' ')[1])));

            if (assignedUser.mainDetails.employeeType === 'parttime') {

              const compensations = computed(() => [...store.state.compensations].filter(compensation => compensation.employeeType === 'parttime'));

              const currentGrade = compensations.value.find(compensation => compensation.grade === assignedUser.mainDetails.grade);

              if (currentGrade) {
                if (formData.taskType === 'Regular') {
                  store.dispatch("createLaborExpense", {
                    ...formData, 
                    taskType: 'regular',
                    rate: currentGrade.hourlyRate,
                    total: Math.abs(formData.totalHours * currentGrade.hourlyRate).toFixed(2)
                  });
                }

                if (formData.taskType === 'Over Time') {
                  store.dispatch("createLaborExpense", {
                    ...formData, 
                    taskType: 'overtime',
                    rate: currentGrade.overTimeRate,
                    total: Math.abs(formData.totalHours * currentGrade.overTimeRate).toFixed(2)
                  });
                }
              }
            }
          }

          if (editionMode.value === 'edit') {
            if (recurringIsEdited.value === true) {
              console.log('enters condition reccurringIsActive');
              createMultipleTask();

              if ((router.currentRoute.value.params.taskMode === 'edit') && 
                router.currentRoute.value.params.taskId) {
                router.push({
                  name: 'page',
                  params: {
                    pagename: 'dashboard',
                    taskEdit: 'done'
                  }
                })
              }

              if (currentSelectedTask.value) {
                const taskToNavigate = tasks.value.find(t => t.id === currentSelectedTask.value);
                
                if (taskToNavigate) {
                  currentSelectedTask.value = null;
                  selectTask({...taskToNavigate}, 'represent');
                }
              }

              return;            
            } 
          } else {
            createMultipleTask();

            return;
          }
        }

        if (!task.id) {
          task.id = newTaskId.value;
        }
        
        task.date = moment(task.date).format('MM/DD/YYYY');

        task.creationDate = moment().format('YYYY-MM-DD hh:mm a');

        const formData = {
          ...task
        };

        (editionMode.value === "edit") ?
            store.dispatch("editTask", { ...formData }) :
            store.dispatch("createTask", {...formData});
        console.log(formData);

        if (task.input) {
          const plansWithInput = [...store.state.plannedInventories].filter(pi => pi.item === task.input);

          plansWithInput.forEach(p => {
            p.inStockNumber = `${Math.abs(Number.parseInt(p.inStockNumber) - Number.parseInt(task.numberOfItems))}`;
            p.inStockQuantity = `${Math.abs(Number.parseFloat(p.inStockQuantity) - Number.parseFloat(task.quantity))}`;
            p.orderNumber = `${Math.abs(Number.parseInt(p.orderNumber) + Number.parseInt(task.numberOfItems))}`;
            p.orderQuantity = `${Math.abs(Number.parseFloat(p.orderQuantity) + Number.parseFloat(task.quantity))}`;

            store.dispatch("editPlannedInventory", { ...p });
          });
        }

        (editionMode.value === "create") && closeForm();
        (editionMode.value === "edit") && selectTask(task, 'represent');

        if (formData.taskStatus === 'Completed') {
          
          const assignedUser = users.value.find(user => ((user.mainDetails.firstName === formData.assignedTo.split(' ')[0]) && (user.mainDetails.lastName === formData.assignedTo.split(' ')[1])));

          if (assignedUser.mainDetails.employeeType === 'parttime') {

            const compensations = computed(() => [...store.state.compensations].filter(compensation => compensation.employeeType === 'parttime'));

            const currentGrade = compensations.value.find(compensation => compensation.grade === assignedUser.mainDetails.grade);

            if (formData.taskType === 'Regular') {
              store.dispatch("createLaborExpense", {
                ...formData, 
                taskType: 'regular',
                rate: currentGrade.hourlyRate,
                total: Math.abs(formData.totalHours * currentGrade.hourlyRate).toFixed(2)
              });
            }

            if (formData.taskType === 'Over Time') {
              store.dispatch("createLaborExpense", {
                ...formData, 
                taskType: 'overtime',
                rate: currentGrade.overTimeRate,
                total: Math.abs(formData.totalHours * currentGrade.overTimeRate).toFixed(2)
              });
            }
          }
        }

        if (router.currentRoute.value.params.taskId &&
          (router.currentRoute.value.params.taskMode === 'edit')) {
          router.push({
            name: 'page',
            params: {
              pagename: 'dashboard',
              taskEdit: 'done'
            }
          });
        }

        if (currentSelectedTask.value) {
          const taskToNavigate = tasks.value.find(t => t.id === currentSelectedTask.value);
          
          if (taskToNavigate) {
            currentSelectedTask.value = null;
            selectTask({...taskToNavigate}, 'represent');
          }
        }
      }

      const closeForm = () => {
        for (let prop in task) {
          task[prop] = defaultTask[prop];
        }

        for (let prop in activated) {
          activated[prop] = false;
        }
        
        for (let prop in focused) {
          focused[prop] = false;
        }

        editionMode.value = 'calendar';
      }

      const cancelChanges = () => {
        if (editionMode.value === "edit") {
          for (let prop in task) {
            task[prop] = selectedTask[prop];
          }
        } else {
          for (let prop in task) {
            task[prop] = defaultTask[prop];
          }
        }
        if ((router.currentRoute.value.params.taskMode === 'edit') && 
          router.currentRoute.value.params.taskId) {
          router.push({
            name: 'page',
            params: {
              pagename: 'dashboard',
              taskEdit: 'done'
            }
          })
        }
        if (currentSelectedTask.value) {
          const taskToNavigate = tasks.value.find(t => t.id === currentSelectedTask.value);
          
          if (taskToNavigate) {
            currentSelectedTask.value = null;
            selectTask({...taskToNavigate}, 'represent');
          }
          return;
        }
        editionMode.value = "calendar";
      };

      const createMultipleTask = () => {

          // startTimeRecurring: '',
          // endTimeRecurring: '',
          // durationRecurring: '',

        const startFrom = moment(task.startDateRecurring);

        const endBy = moment(task.endDateRecurring);

        const groupId = editionMode.value === 'create' ? recurrenceGroupId.value : task.recurrenceId;

        let recurringRange;

        const multipleFormData = [];

        const taskOperations = (i, operationsRange) => {

          const startFromDate = moment(startFrom);
          
          const formData = {
            ...task
          };

          // if (!formData.id) {
            formData.id = uuid();
          // }

          if (!formData.recurrenceId) {
            formData.recurrenceId = groupId;
          }

          formData.date = startFromDate.add(i, 'days').format('MM/DD/YYYY');

          console.log(startFromDate, formData.date, 'dates here');

          console.log(i, 'by index');

          formData.creationDate = moment().format('YYYY-MM-DD hh:mm a');

          multipleFormData.push({ ...formData });

          if (i === operationsRange) {
            (editionMode.value === "edit") ?
              store.dispatch("editRecurringTask", {...formData}) :
              store.dispatch("createRecurringTask", [...multipleFormData]);

              if (task.input) {
                const plansWithInput = [...store.state.plannedInventories].filter(pi => pi.item === task.input);

                plansWithInput.forEach(p => {
                  p.inStockNumber = `${Math.abs(Number.parseInt(p.inStockNumber) - Number.parseInt(task.numberOfItems))}`;
                  p.inStockQuantity = `${Math.abs(Number.parseFloat(p.inStockQuantity) - Number.parseFloat(task.quantity))}`;
                  p.orderNumber = `${Math.abs(Number.parseInt(p.orderNumber) + Number.parseInt(task.numberOfItems))}`;
                  p.orderQuantity = `${Math.abs(Number.parseFloat(p.orderQuantity) + Number.parseFloat(task.quantity))}`;

                  store.dispatch("editPlannedInventory", { ...p });
                });
              }
          }
          console.log(formData);
        }

        if (task.recurrencePattern === 'daily') {
          // if (task.recurrenceDaily === 'nday') {

            if (editionMode.value === 'edit') {
              // tasks.value.forEach(task => {
              //   if (task.recurrenceId === groupId) store.dispatch('removeMultipleTasks',task.id);
              // })
            }
            recurringRange = endBy.diff(startFrom, 'days');
            for (let i = 0; i <= recurringRange; i++) {
              taskOperations(i, recurringRange);
            }
            (editionMode.value === "create") && closeForm();
            (editionMode.value === "edit") && selectTask(task, 'calendar');
            /*
            if (editionMode.value === 'edit') {
              tasks.value.forEach(task => {
                if (task.recurrenceId === groupId) store.dispatch('removeMultipleTasks',task.id);
              })
            }
            recurringRange = endBy.diff(startFrom, 'days');
            const daysInterval = Number.parseInt(task.recurrenceDailyDays);
            for (let i = 0; i < recurringRange; i += daysInterval) {
              taskOperations(i);
            }
            (editionMode.value === "create") && closeForm();
            (editionMode.value === "edit") && selectTask(task, 'calendar');
            */
          // }
        }

        if (task.recurrencePattern === 'weekday') {
          if (editionMode.value === 'edit') {
            // tasks.value.forEach(task => {
            //   if (task.recurrenceId === groupId) store.dispatch('removeMultipleTasks',task.id);
            // })
          }
          recurringRange = endBy.diff(startFrom, 'days');
          for (let i = 0; i <= recurringRange; i++) {
            const nextDay = moment(startFrom).add(i, 'days')
            if ((nextDay.isoWeekday() !== 6) && (nextDay.isoWeekday() !== 7)) {
              taskOperations(i, recurringRange);
            }
          }
          (editionMode.value === "create") && closeForm();
          (editionMode.value === "edit") && selectTask(task, 'calendar');
        }

        if (task.recurrencePattern === 'weekly') {
          // weeks case:

          if (editionMode.value === 'edit') {
            // tasks.value.forEach(task => {
            //   if (task.recurrenceId === groupId) store.dispatch('removeMultipleTasks',task.id);
            // })
          }

          recurringRange = endBy.diff(startFrom, 'days');

          // const weeksInterval = Number.parseInt(task.recurrenceWeeklyWeeks) * 7;
          // for (let j = 0; j < recurringRange; j += weeksInterval) {
          //   for (let k = 7; k < j; k++) {

          //   }
          //   const nextDay = moment(startFrom).add(j, 'days')
          // }


          for (let i = 0; i < 7; i++) {

            
            const nextDay = moment(startFrom).add(i, 'days'); //.format('MM/DD/YYYY')
            const weeksInterval = Number.parseInt(task.recurrenceWeeklyWeeks) * 7;

            if ((nextDay.isoWeekday() === 1) && task.recurrenceWeeklyMonday) {
              for (let j = i; j < recurringRange; j += weeksInterval) {
                taskOperations(j, recurringRange);
              }
            }
            if ((nextDay.isoWeekday() === 2) && task.recurrenceWeeklyTuesday) {
              for (let j = i; j < recurringRange; j += weeksInterval) {
                taskOperations(j, recurringRange);
              }
            }
            if ((nextDay.isoWeekday() === 3) && task.recurrenceWeeklyWednesday) {
              for (let j = i; j < recurringRange; j += weeksInterval) {
                taskOperations(j, recurringRange);
              }
            }
            if ((nextDay.isoWeekday() === 4) && task.recurrenceWeeklyThursday) {
              for (let j = i; j < recurringRange; j += weeksInterval) {
                taskOperations(j, recurringRange);
              }
            }
            if ((nextDay.isoWeekday() === 5) && task.recurrenceWeeklyFriday) {
              for (let j = i; j < recurringRange; j += weeksInterval) {
                taskOperations(j, recurringRange);
              }
            }
            if ((nextDay.isoWeekday() === 6) && task.recurrenceWeeklySaturday) {
              for (let j = i; j < recurringRange; j += weeksInterval) {
                taskOperations(j, recurringRange);
              }
            }
            if ((nextDay.isoWeekday() === 7) && task.recurrenceWeeklySunday) {
              for (let j = i; j < recurringRange; j += weeksInterval) {
                taskOperations(j, recurringRange);
              }
            }
          }
          (editionMode.value === "create") && closeForm();
          (editionMode.value === "edit") && selectTask(task, 'calendar');
        }
      }

      const confirmRecurring = (confirmed) => {
        if (confirmed) {
          recurringIsActive.value = true;
          recurringIsEdited.value = true;
          
          // task.startTimeRecurring = `${task.startTime[1] === ':' ? '0' : ''}${task.startTime[0]}${task.startTime[1]}${task.startTime[2]}${task.startTime[3]}${task.startTime[4]}${task.startTime[5]}${task.startTime[6]}${task.startTime[7] ? task.startTime[7] : ''}`;
          // console.log(task.startTimeRecurring);
          // task.endTimeRecurring = task.completionTime ? `${task.completionTime[1] === ':' ? '0' : ''}${task.completionTime[0]}${task.completionTime[1]}${task.completionTime[2]}${task.completionTime[3]}${task.completionTime[4]}${task.completionTime[5]}${task.completionTime[6]}${task.completionTime[7] ? task.completionTime[7] : ''}` : '';
          // task.durationRecurring = task.totalHours ? task.totalHours : '';
          task.startTimeRecurring = task.startTime ? task.startTime : '';
          task.endTimeRecurring = task.completionTime ? task.completionTime : '';
          task.durationRecurring = task.totalHours ? task.totalHours : '';
        }
        popUps.isRecurringToEdit.active = false;
      }

      watch(() => ({...task}), (newVal, oldVal) => {
        if (newVal?.farm) {
          const selectedFarm = farms.value.find(frm => frm.farmName === newVal.farm);

          const selectedFarmBeds = selectedFarm.beds.filter((bed, index) => bed.bedNumber !== selectedFarm.beds[index - 1]?.bedNumber)

          bedsOptions.value = selectedFarmBeds.map(bed => bed.bedNumber);

          if (bedsOptions.value.length > 0) {
            if (!newVal.bed) {
              // task.bed = selectedFarm.beds[0].bedNumber
              crops.value = [...store.state.crops.filter(crp => crp.sourceId === selectedFarm.id)];
              // cropSeasons.value = crops.value.map(crop => crop.season);
              cropSeasons.value = [...new Set(crops.value.map(crop => crop.season))];
              cropCycles.value = [...new Set(crops.value.map(crop => crop.cropCycle))];

              task.crop = '';
              task.cropCycle = '';
              task.season = '';
            } else {
              if (newVal.farm !== oldVal.farm) {
                task.bed = selectedFarm.beds[0].bedNumber || ''
              }
            }
            if (newVal.bed !== oldVal.bed) {
              const selectedBed = selectedFarm.beds.find(bed => bed.bedNumber === newVal.bed);
              crops.value = [...store.state.crops.filter(crp => crp.sourceId === selectedBed.sourceId)];
              console.log(crops.value, 'crops.value', selectedBed, 'selectedBed');
              // cropSeasons.value = crops.value.map(crop => crop.season);
              cropSeasons.value = [...new Set(crops.value.map(crop => crop.season))];
              cropCycles.value = [...new Set(crops.value.map(crop => crop.cropCycle))];

              if (crops.value.length > 0) {
                task.crop = crops.value[0].crop || '';
                task.cropCycle = crops.value[0].cropCycle || '';
                task.season = crops.value[0].season || '';
              }
            }
            if (newVal.season !== oldVal.season) {
              // crops.value = [...store.state.crops.filter(crp => crp.season === newVal.season)];

              const selectedBed = selectedFarm.beds.find(bed => bed.bedNumber === newVal.bed);

              crops.value = [...store.state.crops.filter(crp => (crp.season === newVal.season) && (crp.sourceId === selectedBed.sourceId))];
              cropCycles.value = [...new Set(crops.value.map(crop => crop.cropCycle))];

              if (crops.value.length > 0) {
                task.crop = crops.value[0].crop || '';
                task.cropCycle = crops.value[0].cropCycle || '';
              }
            }
          } else {
            if (newVal.farm !== oldVal.farm) {
              task.bed = '';
              crops.value = [...store.state.crops.filter(crp => crp.sourceId === selectedFarm.id)];
              // cropSeasons.value = crops.value.map(crop => crop.season);
              cropSeasons.value = [...new Set(crops.value.map(crop => crop.season))];
              cropCycles.value = [...new Set(crops.value.map(crop => crop.cropCycle))];

              if (crops.value.length > 0) {
                task.crop = crops.value[0].crop || '';
                task.cropCycle = crops.value[0].cropCycle || '';
                task.season = crops.value[0].season || '';
              } else {
                task.crop = '';
                task.cropCycle = '';
                task.season = '';
              }
            }
            if (newVal.season !== oldVal.season) {
              // crops.value = [...store.state.crops.filter(crp => crp.season === newVal.season)];
              crops.value = [...store.state.crops.filter(crp => (crp.season === newVal.season) && (crp.sourceId === selectedFarm.id))];
              cropCycles.value = [...new Set(crops.value.map(crop => crop.cropCycle))];

              if (crops.value.length > 0) {
                task.crop = crops.value[0].crop || '';
                task.cropCycle = crops.value[0].cropCycle || '';
              }
            }
            if ((newVal.cropCycle && oldVal.cropCycle) && (newVal.cropCycle !== oldVal.cropCycle)) {
              // crops.value = [...store.state.crops.filter(crp => crp.season === newVal.season)];
              crops.value = [...store.state.crops.filter(crp => (crp.cropCycle === newVal.cropCycle) && (crp.sourceId === selectedFarm.id))];

              if (crops.value.length > 0) {
                task.crop = crops.value[0].crop || '';
              }
            }
          }
        }

        if (newVal?.input !== oldVal?.input) {
          setTimeout(() => {
            const newInputValue = newVal.input;
            const selectedProduct = products.value.find((prd, i) => ((prd.item === newInputValue) && (i === selectedProductIndex.value)));

            if (selectedProduct) {
              // task.brand = selectedProduct.brand || task.brand || '';
              // task.variety = selectedProduct.variety || task.variety || '';
              // task.quantity = (selectedProduct.quantity && task.numberOfItems) ? 
              //   `${Math.abs(Number.parseFloat(selectedProduct.quantity) * Number.parseInt(task.numberOfItems))}` : (task.quantity || '');
              task.brand = selectedProduct.brand || '';
              task.variety = selectedProduct.variety || '';
              task.quantity = (selectedProduct.quantity && task.numberOfItems) ? 
                `${Math.abs(Number.parseFloat(selectedProduct.quantity) * Number.parseInt(task.numberOfItems))}` : '';
            } else {
              // task.brand = task.brand || '';
              // task.variety = task.variety || '';
              // task.quantity = task.quantity || '';
              task.brand = '';
              task.variety = '';
              task.quantity = '';
            }
          }, 10)
        }

      })

      watch(() => (task.harvestTask), (newVal) => {
        if (newVal) task.otherTask = false;
      })

      watch(() => (task.inputTask), (newVal) => {
        if (newVal) task.otherTask = false;
      })

      watch(() => (task.otherTask), (newVal) => {
        if (newVal) {
          task.inputTask = false;
          task.harvestTask = false;
        }
      })

      // watch(() => (task.quantity), (newVal) => {
      //   if (!newVal.includes(' lbs')) {
      //     task.quantity = task.quantity + ' lbs';
      //   }
      // })

      watch(() => (task.recurring), (newVal) => {
        if (newVal) {
          const alreadyCreated = tasks.value.find(stateTask => stateTask.id === task.id);
          if (alreadyCreated) {
            popUps.isRecurringToEdit.active = true;
          } else {
            recurringIsActive.value = true;
          
            // task.startTimeRecurring = `${task.startTime[1] === ':' ? '0' : ''}${task.startTime[0]}${task.startTime[1]}${task.startTime[2]}${task.startTime[3]}${task.startTime[4]}${task.startTime[5]}${task.startTime[6]}${task.startTime[7] ? task.startTime[7] : ''}`;
            // console.log(task.startTimeRecurring);
            // task.endTimeRecurring = task.completionTime ? `${task.completionTime[1] === ':' ? '0' : ''}${task.completionTime[0]}${task.completionTime[1]}${task.completionTime[2]}${task.completionTime[3]}${task.completionTime[4]}${task.completionTime[5]}${task.completionTime[6]}${task.completionTime[7] ? task.completionTime[7] : ''}` : '';
            // task.durationRecurring = task.totalHours ? task.totalHours : '';
            task.startTimeRecurring = task.startTime ? task.startTime : '';
            task.endTimeRecurring = task.completionTime ? task.completionTime : '';
            task.durationRecurring = task.totalHours ? task.totalHours : '';
          }
        }
      })

      // watch(() => (task.startTime), (newVal) => {
      //   if (newVal && task.completionTime) {
      //     task.totalHours = moment(task.completionTime, 'hh:mm a').diff(moment(task.startTime, 'hh:mm a'), 'hours', true).toFixed(2);
      //   }
      // })

      // watch(() => (task.completionTime), (newVal) => {
      //   if (newVal && task.startTime) {
      //     task.totalHours = moment(task.completionTime, 'hh:mm a').diff(moment(task.startTime, 'hh:mm a'), 'hours', true).toFixed(2);
      //   }
      // })
      watch(() => ([...store.state.vendorItems]), (newVal) => {
        equipments.value = [{item: 'Not required'}, ...[...newVal].filter(vendorItem => vendorItem.type === 'Equipment'), {item: 'Add New'}];
      
        products.value = [{item: 'Not required'}, ...[...newVal].filter(vendorItem => vendorItem.type === 'Product'), {item: 'Water'}, {item: 'Add New'}];

        varietyOptions.value = [...new Set(products.value.map(option => option.variety).filter(o => o))];

        if (task.input) {
          setTimeout(() => {
            let currentProductIndex = (selectedProductIndex.value === (products.value.length - 2)) ? (selectedProductIndex.value - 1) : selectedProductIndex.value
            const selectedProduct = products.value.find((prd, i) => ((prd.item === task.input) && (i === currentProductIndex)));

            if (selectedProduct) {
              // task.brand = selectedProduct.brand || task.brand || '';
              // task.variety = selectedProduct.variety || task.variety || '';
              // task.quantity = (selectedProduct.quantity && task.numberOfItems) ? 
              //   `${Math.abs(Number.parseFloat(selectedProduct.quantity) * Number.parseInt(task.numberOfItems))}` : (task.quantity || '');
              task.brand = selectedProduct.brand || '';
              task.variety = selectedProduct.variety || '';
              task.quantity = (selectedProduct.quantity && task.numberOfItems) ? 
                `${Math.abs(Number.parseFloat(selectedProduct.quantity) * Number.parseInt(task.numberOfItems))}` : '';
            } else {
              task.brand = '';
              task.variety = '';
              task.quantity = '';
            }
          }, 10)
        }

      });

      const formatTotalHours = () => {
        task.totalHours = Number.parseFloat(task.totalHours || 0).toFixed(2)
      }

      watch(() => (task.taskStatus), (newVal) => {
        if (newVal === 'Completed') {
          timeValidation.value = true;
        }
      })

      watch(() => (task.date), (newVal) => {
        console.log(newVal);
      })

      watch(() => (task.assignedTo), (newVal) => {
        if (newVal) {
          const assignedUser = users.value.find(user => ((user.mainDetails.firstName === newVal.split(' ')[0]) && (user.mainDetails.lastName === newVal.split(' ')[1])));

          if (assignedUser?.mainDetails?.employeeType === 'parttime') {
            taskForPartTimeUser.value = true;
          }
        }
      })

      watch(() => (task.input), (newVal, oldVal) => {
        if (newVal !== oldVal) {
          if (newVal === 'Add New') {
            inputDialog.value = true;
          } else if (newVal === 'Water') {
            waterIsChoosen.value = true;
          } else {
            setTimeout(() => {
              const newInputValue = newVal;
              const currentProduct = products.value.find((p, i) => ((p.item === newInputValue) && (i === selectedProductIndex.value)));

              if (currentProduct) {
                
                varietyOptions.value = [...new Set(products.value.map(option => {
                  if (option.item === newVal) {
                    return option.variety;
                  }
                }).filter(o => o))];
                // task.brand = currentProduct.brand || task.brand || '';
                // task.variety = currentProduct.variety || task.variety || '';
                // task.quantity = (currentProduct.quantity && task.numberOfItems) ? 
                //   `${Math.abs(Number.parseFloat(currentProduct.quantity) * Number.parseInt(task.numberOfItems))}` : (task.quantity || '');
                task.brand = currentProduct.brand || '';
                task.variety = currentProduct.variety || '';
                task.quantity = (currentProduct.quantity && task.numberOfItems) ? 
                  `${Math.abs(Number.parseFloat(currentProduct.quantity) * Number.parseInt(task.numberOfItems))}` : '';
              } else {
                task.brand = '';
                task.variety = '';
                task.quantity = '';
              }
            },10)
          }
        } else {
          task.brand = task.brand || '';
          task.variety = task.variety || '';
          task.quantity = task.quantity || '';
        }
      })

      watch(() => (task.equipment), (newVal) => {
        if (newVal === 'Add New') {
          equipmentDialog.value = true;
        }
      })

      watch(selectedProductIndex, (newVal) => console.log(newVal, 'selectedProduct vendorItems'));
      
      onMounted(() => {
        task.farm = task.farm ? task.farm : (farms.value[0]?.farmName || '');
        console.log(task.date);
        if (editionMode.value === 'create') {
          newTaskId.value = uuid();
          task.id = newTaskId.value;
          recurrenceGroupId.value = uuid();
          task.recurrenceId = recurrenceGroupId.value;
        }
        if (task.date && task.task) task.date = moment(task.date, 'mm/DD/yyyy').format('yyyy-mm-DD');
        console.log(task.date);

        // users.value = store.state.users.find(u => u._id === store.state.authenticated._id) ?
        //   [...store.state.labors] :
        //   [
        //     {
        //       ...store.state.primaryUser,
        //       mainDetails: {
        //         ...store.state.primaryUser.mainDetails,
        //         id: 'primary'
        //       }
        //     },
        //     ...store.state.labors
        //   ]

        console.log(task.recurring, task.assignedTo, task.task, task.taskStatus);

        if (task.recurring && task.assignedTo && task.task && task.taskStatus) {
          popUps.isRecurringToEdit.active = true;
        }

        console.log(users, 'users');
      })

      provide('recurringDetails', {
        recurringIsActive
      })

      provide('addTaskDetails', {
        task,
        inputDialog,
        equipmentDialog
      })

      provide('taskDetailsTwo', {
        task,
        // options: bedsOptions, 
        // optionsTwo: crops
        options: crops
      })

      provide('taskDetailsThree', {
        task,
        // options: bedsOptions, 
        // optionsTwo: crops
        options: products,
        indexSelected: selectedProductIndex
      })

      provide('taskDetailsFour', {
        task,
        // options: bedsOptions, 
        // optionsTwo: crops
        options: equipments 
      })

      provide('taskDetailsFive', {
        task,
        // options: bedsOptions, 
        // optionsTwo: crops
        options: users 
      });

      provide('taskDetailsSix', {
        task,
        // options: bedsOptions, 
        // optionsTwo: crops
        options: farms 
      })

      provide('taskDetailsSeven', {
        task,
        // options: bedsOptions, 
        // optionsTwo: crops
        options: cropSeasons 
      })

      provide('taskDetailsEight', {
        task,
        // options: bedsOptions, 
        // optionsTwo: crops
        options: cropCycles 
      })

      provide('taskDetailsNine', {
        task,
        // options: bedsOptions, 
        // optionsTwo: crops
        options: varietyOptions 
      })

      return {
        timesOptions,
        reminderOptions,
        activated,
        focused,
        task,
        // inputTask,
        // harvestTask,
        // otherTask,
        taskReminder,
        submitForm,
        cancelChanges,
        farms,
        equipments,
        products,
        users,
        bedsOptions,
        helperTooltip,
        switchHelperTooltip,
        calHelperTooltip,
        switchCalHelperTooltip,
        recurringIsActive,
        timeValidation,
        crops,
        cropSeasons,
        formatTotalHours,
        promptToAddEquipment,
        popUps,
        confirmRecurring,
        taskForPartTimeUser,
        inputDialog,
        equipmentDialog,
        waterIsChoosen,
        selectedProductIndex,
        missedField,
        categoryTooltip,
        switchCategoryTooltip
      }
    },
    components: { FileAttachments, RecurringTaskForm, UserNotification, VendorModule, DropDownList }
}
</script>

<style scoped lang="scss">
.task-type {
  .fw-semibold {
    &.gray {
      margin-right: 5rem;
      margin-left: 6rem;
    }
  }
  .checkbox {
    &.regular {
      margin-right: 3rem;
    }
  }
}
.recurringTaskCheckbox {
  position: relative;
}
.reminderCheckbox {

  &.active {
    margin-right: 2rem;
    display: flex;
    align-items: center;
  }
  .col-6 {
    margin-left: 1rem;
  }
}
.reminder-list {
  min-width: 8rem;
}
.quantity-label {
  position: relative;
  width: 50%;
}
.form-control {
  &.date-field {
    padding-right: 1.8rem;
    background: none;
  }
}
.date-icon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 3.7rem;
  right: 1rem;
  z-index: 1;
  pointer-events: none;
  border-radius: 1rem;
  background:url('@/assets/images/calendar-icon.svg') center center no-repeat;
  background-color: #fff;
}

.task-calendar {
  -webkit-mask-image: url('@/assets/images/task-calendar.svg');
  mask-image: url('@/assets/images/task-calendar.svg');
  -webkit-mask-size: 20px 20px;
  mask-size: 20px 20px;
  background-color: hsl(var(--green));
  width: 20px;
  height: 20px;
}

.time-insert {
  width: 85%;
  position: absolute;
  left: 0.2rem;
  height: calc(5.2rem - 0.8rem);
  top: calc(24px + 0.3rem + 0.4rem);
  border: none;
}

.time-validation-overspace {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba($color: #000000, $alpha: 0.5);
  .validation-popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    .validation-message {
      background: #fff;
      display: flex;
      flex-direction: column;
      max-width: 650px;
      width: 100%;
      height: max-content;
      justify-content: space-around;
      align-items: center;
      position: relative;
      border-radius: 11px;
      color: rgba(40,41,43,1);
      font-size: 21px;
      margin: 0 20px;
      padding: 25px 0;
      text-align: center;
      .close-button {
        position: absolute;
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: auto;
        width: 30px;
        height: 30px;
        background-image: url('@/assets/profile/close_button.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        right: 10px;
        top: 22px;
        cursor: pointer;
      }
      .validation-text {
        margin: 0 40px;
        font-size: 16px;
        font-weight: 600;
      }
      .validation-buttons {
        display: flex;
        width: 60%;
        justify-content: space-evenly;
        margin-top: 30px;
        button {
          width: 160px;
          height: 40px;
          border-radius: 10px;
          &.cancel-validation-button {
            border: 2px solid #38AC89;
          }
          &.approve-button {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .reminder-mobile {
    display: block;
    &.active {
      display: flex;
      .col-6 {
        max-width: 17rem;
      }
    }
  }
  .reminder-desktop {
    display: none;
    &.active {
      display: none;
    }
  }
}

@media screen and (min-width: 991px) {
  .reminder-mobile {
    display: none;
    &.active {
      display: none;
    }
  }
  .reminder-desktop {
    display: block;
    margin-left: auto;
    &.active {
      display: flex;
    }
  }
}
.task-category,
.task-type {
  .checkbox {
    label {
      color: hsl(var(--dark-gray)) !important;
      font-size: var(--fs-lg);
      font-family: var(--fw-medium);
    }
  }
}
.recurringTaskCheckbox,
.reminder-desktop,
.reminder-mobile {
  label {
    color: hsl(var(--dark-gray)) !important;
    font-size: var(--fs-lg);
    font-family: var(--fw-medium);
  }
}

.reminder-placeholder {
  opacity: 0;
  pointer-events: none;
  color: hsl(var(--dark-gray)) !important;
  font-size: var(--fs-lg);
  font-family: var(--fw-medium);
}
.reminder-main-label {
  position: absolute;
  color: hsl(var(--dark-gray)) !important;
  font-size: var(--fs-lg);
  font-family: var(--fw-medium);

  margin: auto;
  top: 0;
  bottom: 0;
  height: max-content;
}
// .date-label {
//   position: relative;
//   z-index: 9;
// }
.checkbox-header {
  color: hsl(210, 1%, 40%);
}


.category-tooltip-container {
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: 0;
  }
  .category-tooltip {
    position: absolute;
    user-select: none;
    z-index: 99999;
    bottom: 40px;
    left: -100px;
    width: 380px;
    // display: flex;
    justify-content: center;
    align-items: center;
    // border-radius: 11px;
    // color: #fff;
    // background-color: #3C3838;
    font-family: 'Inter', sans-serif;
    // font-weight: 400;
    // padding: 5px;
    color: hsl(var(--white));
    // background: hsl(207, 11%, 41%);
    background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    font-weight: var(--fw-semibold);
    padding: 0.5rem;
    font-size: 1.4rem;
    font-family: 'Gilroy';
    &::after {
      content: " ";
      position: absolute;
      display: block;
      bottom: -7px;
      // border-color: #3C3838;
      // border-left: 8px solid hsl(207, 11%, 41%);
      // border-bottom: 6px solid hsl(207, 11%, 41%);
      border-left: 8px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
      border-bottom: 6px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(190px) rotate(135deg);
      transform: translateX(190px) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: -85px;
      // background: #3C3838;
      // background: hsl(207, 11%, 41%);
      background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
      z-index: -1;
    }

  }
}
</style>