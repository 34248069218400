<template>
  <div class="register">
    <div class="main-logo"></div>
    <q-card>
      <q-card-section>
        <h5>Activate Account</h5>
        <div class="flex flex-center form-row">
          <q-field
            borderless
            label="Email"
            stack-label
            color="bhoomi"
            style="max-width: 200px; width: 100%"
            :model-value="loginForm.email"
          >
            <template v-slot:control>
              <q-input
                color="bhoomi"
                outlined
                rounded-borders
                v-model="loginForm.email"
                placeholder="Enter Email"
                :rules="[
                  (val) => !!val || '* Required',
                  (val) => /\S+@\S+\.\S+/.test(val) || 'Please use correct email format'  
                ]"
              />
            </template>
          </q-field>
        </div>
        <div class="flex flex-center form-row">
          <q-field
            borderless
            label="Password (8+ characters)"
            stack-label
            color="bhoomi"
            style="max-width: 200px; width: 100%"
            :model-value="loginForm.password"
          >
            <template v-slot:control>
              <q-input
                type="password"
                color="bhoomi"
                outlined
                rounded-borders
                v-model="loginForm.password"
                placeholder="Create Password"
                :rules="[
                  (val) => !!val || '* Required',
                  (val) => val.length <= 16 || 'Please use maximum 16 characters',
                  (val) => val.length >= 8 || 'Please use minimum 8 characters',
                  (val) =>
                    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/g.test(val) ||
                    'Please use at least one digit and at least one upper case letter',
                ]"
              >
                <template v-slot:append>
                  <q-avatar @mouseenter="showTooltip" @mouseleave="hideTooltip">
                    <img src="@/assets/login-register_page/edit_button.png">
                    <div v-if="tooltipData.isShown" 
                      class="tooltip" 
                      :style="{left: `${tooltipData.xPos + 20}px`, top: `${tooltipData.yPos + 20}px`}">
                      The password should match the requirements for creating the password. 
                      Upper case and lower case letter, a number and a special character.
                    </div>
                  </q-avatar>
                </template>
              </q-input>
            </template>
          </q-field>
        </div>
        <div class="flex flex-center form-row">
          <q-field
            borderless
            label="Re-enter Password"
            stack-label
            color="bhoomi"
            style="max-width: 200px; width: 100%"
            :model-value="loginForm.reenterPassword"
          >
            <template v-slot:control>
              <q-input
                type="password"
                color="bhoomi"
                outlined
                rounded-borders
                v-model="loginForm.reenterPassword"
                placeholder="Re-enter Here"
                :rules="[
                  (val) => !!val || '* Required',
                  (val) => val === loginForm.password || 'The password should match the requirements for creating the password. Upper case and lower case letter, a number and a special character.',
                ]"
              />
            </template>
          </q-field>
        </div>
      </q-card-section>
    </q-card>
    <div class="flex agreement-checker">
      <q-checkbox 
        color="bhoomi" 
        v-model="loginForm.userAgreed" 
      />
      By checking this box, you agree to the Bhoomi 
      <router-link :to="{ name: 'user-agreement' }">
        User Agreement
      </router-link>, <router-link :to="{ name: 'privacy-policy' }">
        Privacy Policy
      </router-link>, and <router-link :to="{ name: 'cookie-policy' }">
        Cookie Policy
      </router-link>.
    </div>
    <q-card-actions style="justify-content: center">
      <q-btn @click="submitForm" color="bhoomi" label="Join" style="padding: 0 80px;" />
    </q-card-actions>
  </div>
  <UserNotification
    v-if="userJoined"
    v-bind:messageText="'Click in your email to activate account'"
  />
</template>

<script>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import UserNotification from "@/components/Shared/UserNotification.vue";

export default {
  name: "RegisterPage",
  components: {
    UserNotification
  },
  setup() {
    const store = useStore(),
      userJoined = ref(false),
      tooltipData = reactive({
        isShown: false,
        xPos: 0,
        yPos: 0
      }),
      loginForm = reactive({
        email: '',
        password: '',
        reenterPassword: '',
        userAgreed: false
      })

    const showTooltip = (ev) => {
      console.log(ev);
      tooltipData.isShown = true;
      tooltipData.xPos = ev.offsetX;
      tooltipData.yPos = ev.offsetY;
    }

    const hideTooltip = () => {
      tooltipData.isShown = false;
    }

    const submitForm = () => {
      if (loginForm.email && loginForm.password && 
        loginForm.reenterPassword && loginForm.userAgreed &&
        (loginForm.password === loginForm.reenterPassword)) {
        store.dispatch('registerUser', loginForm);
        userJoined.value = true;
        setTimeout(() => {
          userJoined.value = false;
        }, 10000)
      }
    }

    return {
      userJoined,
      tooltipData,
      loginForm,
      showTooltip,
      hideTooltip,
      submitForm
    }
  }
};
</script>

<style lang="scss">
.register {
  .main-logo {
    background-image: url('@/assets/login-register_page/logo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 14vw;
    height: 14vw;
    margin: 5vh calc(50% - 7vw);
    text-align: center;
  }
  .q-card {
    margin: 0 25%;
    h5 {
      text-align: center;
    }
    .form-row {
      .tooltip {
        width: 300px;
        height: max-content;
        line-height: 0.9;
        position: absolute;
      }
    }
  }
  .agreement-checker {
    align-items: center;
    justify-content: center;
    a {
      color: #38AC89;
      margin-left: 5px;
    }
  }
}
</style>