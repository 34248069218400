<template>
  <div class="section" ref="section">
<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Billing Address</h5>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-block">
<div class="checkbox checkbox-lg"><input type="checkbox" id="addressSame" v-model="sameAddress"><label for="addressSame">Billing Address is same as Account Address</label></div>
</div>

<div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.accountName || (billing.accountName.length > 0)}">Account Name</p>
<input type="text" class="form-control" 
  v-model="billing.accountName"
  @focus="() => {
    activated.accountName = true;
    focused.accountName = true;
  }"
  @blur="() => {
    focused.accountName = false;
  }"
>
<p v-if="activated.accountName && billing.accountName.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.accountName && billing.accountName.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.firstName || (billing.firstName.length > 0)}">First Name</p>
<input type="text" class="form-control" 
  v-model="billing.firstName"
  @focus="() => {
    activated.firstName = true;
    focused.firstName = true;
  }"
  @blur="() => {
    focused.firstName = false;
  }"
>
<p v-if="activated.firstName && billing.firstName.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.firstName && billing.firstName.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.lastName || (billing.lastName.length > 0)}">Last Name</p>
<input type="text" class="form-control" 
  v-model="billing.lastName"
  @focus="() => {
    activated.lastName = true;
    focused.lastName = true;
  }"
  @blur="() => {
    focused.lastName = false;
  }"
>
<p v-if="activated.lastName && billing.lastName.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.lastName && billing.lastName.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.address || (billing.address.length > 0)}">Address 1</p>
<input type="text" class="form-control" 
  v-model="billing.address"
  @focus="() => {
    activated.address = true;
    focused.address = true;
  }"
  @blur="() => {
    focused.address = false;
  }"
>
<p v-if="activated.address && billing.address.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.address && billing.address.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.address2 || (billing.address2.length > 0)}">Address 2</p>
<input type="text" class="form-control" 
  v-model="billing.address2"
  @focus="() => {
    activated.address2 = true;
    focused.address2 = true;
  }"
  @blur="() => {
    focused.address2 = false;
  }"
>
<p v-if="activated.address2 && billing.address2.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.city || (billing.city.length > 0)}">City</p>
<input type="text" class="form-control" 
  v-model="billing.city"
  @focus="() => {
    activated.city = true;
    focused.city = true;
  }"
  @blur="() => {
    focused.city = false;
  }"
>
<p v-if="activated.city && billing.city.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.city && billing.city.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.state || (billing.state.length > 0)}">State</p>
<!-- <select class="form-control" 
  v-model="billing.state"
  @click="() => {
    activated.state = true;
    focused.state = true;
  }"
  @change="() => {
    focused.state = false;
  }"
>
<option
  v-for="stateOption in stateOptions"
  v-bind:key="stateOption + 'state-option-billing'"
>
  {{ stateOption }}
</option>
</select> -->
<DropDownList
  :mainObject="'billing'"
  :mainProperty="'state'"
  :injectionName="'billing'"
  :optionKey="'state-option-billing'"
  :optionsList="stateOptions"
  :optionProperty="null"
  :optionPropertyTwo="null"
  @click="() => {
    activated.state = true;
  }"
/>
<p v-if="activated.state && billing.state.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.zip || (billing.zip.length > 0)}">Zip</p>
<input type="text" class="form-control" 
  v-model="billing.zip"
  @focus="() => {
    activated.zip = true;
    focused.zip = true;
  }"
  @blur="() => {
    focused.zip = false;
  }"
>
<p v-if="activated.zip && billing.zip.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.zip && billing.zip.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label"  :class="{active: focused.country || (billing.country.length > 0)}">Country</p>
<!-- <select class="form-control" 
  v-model="billing.country" 
  @click="() => {
    activated.country = true;
    focused.country = true;
  }"
  @change="() => {
    focused.country = false;
  }"
>
<option 
  v-for="countryOption in countryOptions"
  v-bind:key="countryOption + 'country-option-billing'"
>
  {{ countryOption }}
</option>
</select> -->
<DropDownList
  :mainObject="'billing'"
  :mainProperty="'country'"
  :injectionName="'billing'"
  :optionKey="'country-option-billing'"
  :optionsList="countryOptions"
  :optionProperty="null"
  :optionPropertyTwo="null"
  @click="() => {
    activated.country = true;
  }"
/>
<p v-if="activated.country && billing.country.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>
</div>
</div>
</div>

</div>

<div class="content-block">
<div class="buttons form-buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges" >Cancel</button></div>
</div>
</div>
</div>

</div>
</div>
</template>

<script>
import { defineComponent, inject, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import stateOptions from '@/sharedData/statesList.js'
import countryOptions from '@/sharedData/countriesList.js'
import DropDownList from '@/components/Shared/DropDownList.vue';

export default defineComponent({
    name: 'AdvertisementAccountBilling',
    setup() {
        const store = useStore();
        const { billing, sameAddress, changeBillingField } = inject('billing');
        const defaultBilling = { ...billing };
        const { confirmPayment } = inject('stripeProps');
        const sectionHeader = 'Billing Address';
        const activated = reactive({
            accountName: false,
            firstName: false,
            lastName: false,
            address: false,
            address2: false,
            city: false,
            state: false,
            zip: false,
            country: false
        });
        const focused = reactive({
            accountName: false,
            firstName: false,
            lastName: false,
            address: false,
            address2: false,
            city: false,
            state: false,
            zip: false,
            country: false
        });
        const disabledSubmit = ref(true);
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const toggleSection = () => {
            sectionExpanded.value = !sectionExpanded.value;
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(billing, () => {
            disabledSubmit.value = !billing.country || !billing.zip || !billing.state ||
                !billing.city || !billing.address ||
                !billing.lastName || !billing.firstName || !billing.accountName;
            if (billing.expanded) {
                store.dispatch('addCurrentPath', {
                    section: 1,
                    val: sectionHeader
                });
            }
        });
        const cancelChanges = () => {
            for (let prop in defaultBilling) {
                billing[prop] = defaultBilling[prop];
            }
            changeBillingField('represent', 'mode');
        };
        const submitForm = () => {
            if (!billing.country || !billing.zip ||
                !billing.state || !billing.city ||
                !billing.address || !billing.lastName ||
                !billing.firstName || !billing.accountName) {
                if (!billing.country)
                    activated.country = true;
                if (!billing.zip)
                    activated.zip = true;
                if (!billing.state)
                    activated.state = true;
                if (!billing.city)
                    activated.city = true;
                if (!billing.address)
                    activated.address = true;
                if (!billing.lastName)
                    activated.lastName = true;
                if (!billing.firstName)
                    activated.firstName = true;
                if (!billing.accountName)
                    activated.accountName = true;
                return;
            }
            store.dispatch('updateTenant', {
                data: {
                    ...billing,
                },
                primaryUserId: store.state.advertisementAccount.id,
                section: 'billing'
            });
            // if (!store.state.primaryUser.billing) {
            // confirmPayment();
            console.log(confirmPayment);
            // }
            changeBillingField(true, 'submitted');
            store.dispatch('saveAdvertisementAccount', { section: 'billing', data: billing });
            // changeBillingField('represent', 'mode');
            setTimeout(() => {
                changeBillingField('represent', 'mode');
            }, 10);
        };
        onMounted(() => {
            sectionHeight.value = section.value?.offsetHeight;
            if (section.value)
                section.value.style.height = `${section.value?.offsetHeight}px`;
        });
        return {
            sectionHeader,
            stateOptions,
            countryOptions,
            billing,
            sameAddress,
            changeBillingField,
            disabledSubmit,
            cancelChanges,
            submitForm,
            activated,
            focused,
            section,
            boxHeader,
            toggleSection
        };
    },
    components: { DropDownList }
})
</script>