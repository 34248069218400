<template>
  <aside :class="{active: menuIsActive}">
    <div class="sidebar-content">

      <a href="javascript:void();" class="logo"><img src="@/assets/images/logo-new.png" alt=""></a>

      <nav>
        <ul>
          <template v-for="item in items" v-bind:key="item.name">
            <template v-if="!item.authReq || (item.authReq && (isAuth(item.authReq) || isSubscribed(item.authReq)))">
              <li 
                v-if="!item.subs" 
                :class="{ active: route.path === item.url }"
              >
                <router-link 
                  :to="item.url" 
                  :class="item.name.toLowerCase()"
                >
                  {{ item.name }}
                </router-link>
              </li>
              <li v-else class="dropdown" :class="{active: checkForActivity(item.name)}">
                <router-link 
                  :to="item.url" 
                  :class="item.name.toLowerCase()"
                >
                  {{ item.name }}
                </router-link>
                <div class="dropdown-menus">
                  <template v-for="subitem in item.subs" v-bind:key="subitem.name">
                    <template v-if="subitem.name === 'My Advertisements'">
                      <router-link 
                        v-if="advertisementsList.length > 0"
                        :to="subitem.url" 
                        :class="{[subitem.name.toLowerCase()]:subitem.name.toLowerCase(), active: route.path === subitem.url}"
                      >
                        {{ subitem.name }}
                      </router-link>
                    </template>
                    <template v-else>
                      <router-link 
                        :to="subitem.url" 
                        :class="{[subitem.name.toLowerCase()]:subitem.name.toLowerCase(), active: route.path === subitem.url}"
                      >
                        {{ subitem.name }}
                      </router-link>
                    </template>
                  </template>
                </div>
              </li>
            </template>
          </template>
        </ul>
      </nav>

    </div>
  </aside><!--//End Aside-->
  <!-- <q-drawer v-model="drawer" show-if-above behavior="desktop" :class="width < 1024 ? 'drawer-mobile' : ''" bordered class="bg-main-menu main-menu">
    <q-btn class="mobile-drawer-switcher" color="white" flat @click="switchDrawer()" round dense icon="close" />
    <div class="main-logo" v-if="width > 1024"></div>
    <q-scroll-area class="fit menu-items-list">
      <q-list>
        <template v-for="item in items" v-bind:key="item.name">
          <template v-if="!item.authReq || (item.authReq && isAuth(item.authReq))" >
          <q-item class="menu-button link" v-if="!item.subs" :to="item.url" 
            :active="route.path === item.url" clickable>
            <q-item-section avatar>
              <q-avatar>
                <div class="menu-icon" :class="item.name.toLowerCase()"></div>
              </q-avatar>
            </q-item-section>
            <q-item-section>
              {{ item.name }}
            </q-item-section>
          </q-item>
          <q-expansion-item v-else class="menu-button expandable"
            :class="{'active-parent-item': checkForActivity(item.name) }"
            v-model="item.active"
            @click="() => handleRouteSwitch(item.url)">
            <template v-slot:header>
              <q-item-section avatar>
                <q-avatar>
                  <div class="menu-icon" :class="item.name.toLowerCase()"></div>
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <span class="header">{{ item.name }}</span>
              </q-item-section>
            </template>
            <template v-slot:default>
              <template v-for="subitem in item.subs" v-bind:key="subitem.name">
                <q-item :to="subitem.url" class="menu-button sublink" 
                  :active="route.path === subitem.url" clickable>
                  <q-item-section>
                    {{subitem.name}}
                  </q-item-section>
                </q-item>
              </template>
            </template>
          </q-expansion-item>
          </template>
        </template>
      </q-list>
    </q-scroll-area>
    <div style="color: #fff;">
      Switch user role:
      <div style="cursor: pointer;" @click="selectRole('admin')">Admin</div>
      <div style="cursor: pointer;" @click="selectRole('edit')">Edit</div>
      <div style="cursor: pointer;" @click="selectRole('contribute')">Contribute</div>
      <div style="cursor: pointer;" @click="selectRole('read')">Read</div>
    </div>
    <div class="app-version">{{appVersion}}</div>
  </q-drawer> -->
</template>

<script>
import { watch, inject, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import urlItems from '@/sharedData/menuUrlList';
import { ref } from "vue";

export default {
  name: "MainMenu",
  setup() {
    const items = reactive([...urlItems.map(item => {
        return {
          ...item,
          active: false
        }
      })]);

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const advertisementsList = ref([]);

    const { menuIsActive } = inject('mainMenu');
    const { drawer, switchDrawer, width, isAuth, isSubscribed } = inject('mainUI');

    const checkForActivity = (itemName) => {
      const formattedItemName = (itemName.split(' ').length > 1) ? 
        `${itemName.split(' ')[0].toLowerCase()}-${itemName.split(' ')[1].toLowerCase()}` :
        itemName.toLowerCase()

      if (route.name === 'advertisement' && formattedItemName === 'admin') return true;

      return (route.name === formattedItemName) || 
      (route?.params?.pagename === formattedItemName)
    }

    const handleRouteSwitch = (itemUrl) => {
      router.push(itemUrl);
    }

    const selectRole = (selectedRole) => {
      store.dispatch('selectUserRole', selectedRole)
    }

    watch(width, (newVal, oldVal) => {
      if (newVal >= 1024) {
        switchDrawer(true);
      }
      if (newVal < 1024 && oldVal >= 1024) {
        switchDrawer(false);
      }
    });

    watch(route, (newVal) => {
      console.log(newVal);
      if (width.value < 1024) {
        switchDrawer(false);
      }
      items.forEach(item => {
        item.active = ((route.name === item.name.toLowerCase()) || 
          route?.params?.pagename === item.name.toLowerCase())
      })
    });

    watch(() => (store.state.myAdvertisementsList), (newVal) => {
      advertisementsList.value = newVal;
    })

    onMounted(() => {
      advertisementsList.value = store.state.myAdvertisementsList
      if (width.value < 1024) {
        switchDrawer(false);
      }

    });

    const appVersion = 'v1.0'

    return {
      items,

      isAuth,
      isSubscribed,
      route,
      appVersion,
      width,

      drawer,
      switchDrawer,
      checkForActivity,
      handleRouteSwitch,
      selectRole,

      menuIsActive,
      advertisementsList
    };
  },
};
</script>

<style lang="scss" scoped>
.main-menu {
  // width: 22.94921875vw;
  .mobile-drawer-switcher {
    display: none;
    margin: 5px;
  }
  .main-logo {
    background-image: url('@/assets/main_menu/bhoomi_logo.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    // width: 10vw;
    // height: 10vw;
    // margin: 2vh calc(50% - 5vw);
    width: 115px;
    height: 100px;
    margin: calc(50% - 87px) calc(50% - 58px);
    text-align: center;
  }
  .menu-items-list {
    .menu-button {
      color: #ffffff;
      // font-size: 18px;
      font-family: 'Gilroy';
      font-weight: 500;
      font-size: 1.388888889vw;
      .menu-icon {
        width: 2.197265625vw;
        height: 2.197265625vw;

        display: flex;
        text-indent: -9999px;
        -webkit-mask-position: center;
        mask-position: center;
        -webkit-mask-size: 2.197265625vw 2.197265625vw;
        mask-size: 2.197265625vw 2.197265625vw;
        background-color: #fff;
        &.home {
          mask-image: url('@/assets/main_menu/home_icon.svg');
        }
        &.task {
          // mask-image: url('@/assets/main_menu/task_icon.svg');
        }
        &.farm {
          mask-image: url('@/assets/main_menu/farm_icon.svg');
        }
        &.crops {
          mask-image: url('@/assets/main_menu/crops_icon.svg');
        }
        &.labor {
          mask-image: url('@/assets/main_menu/labor_icon.svg');
        }
        &.equipment {
          mask-image: url('@/assets/main_menu/equipments_icon.svg');
        }
        &.inventory {
          mask-image: url('@/assets/main_menu/inventory_icon.svg');
        }
        &.sales {
          mask-image: url('@/assets/main_menu/sales_icon.svg');
        }
        &.reports {
          mask-image: url('@/assets/main_menu/reports_icon.svg');
        }
      }
      &.sublink {
        padding-left: calc(2.197265625vw + 16px);
      }
      &.q-item--active {
        &:not(.sublink) {
          // color: #CD9C07;
          &::after {
            content: "";
            // border-left: 5px solid #CD9C07;
            // background: rgba(205,156,7,0.1);
            background: rgba(205,156,7,0.4);
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
          .menu-icon {
            // background-color: #CD9C07;
          }
        }
        &.sublink {
          color: #CD9C07;
          &::before {
            content: "•";
            color: #CD9C07;
            margin-right: 5px;
            align-items: center;
            display: flex;
          }
        }
      }
    }
  }
  .app-version {
    color: #fff;
    text-align: center;
    font-family: 'Gilroy';
    font-weight: 400;
    font-size: 1.46484375vw;
  }
}

@media screen and (max-width: 1200px) {
  .main-menu {
    .main-logo {
      width: 100px;
      height: 100px;
      margin: 50px calc(50% - 50px);
    }
    .mobile-drawer-switcher {
      display: flex;
    }
    .menu-items-list {
      .menu-button {
        font-size: 18px;
        .menu-icon {
          width: 30px;
          height: 30px;
          -webkit-mask-size: 30px 30px;
          mask-size: 30px 30px;
        }
      }
    }
    .app-version {
      font-size: 18px;
    }
  }
}
</style>