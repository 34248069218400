<template>
  <!-- <iframe class="doc" :src="`https://docs.google.com/gview?url=${docUrl}&embedded=true`"></iframe> -->
  <iframe class="doc" :src="`${docUrl}`"></iframe>
</template>

<script>
import { ref } from "vue";

export default {
  name: "OpenedFile",
  props: ['docFile'],
  setup(props) {
    const docUrl = ref(props.docFile);

    return {
      docUrl
    }
  },
};
</script>

<style lang="scss" scoped>
iframe.doc {
  width: 100%;
  height: 100%;
}
</style>