<template>
  <section class="section mt-1">
  <div class="border-box">
  <div class="border-box-content">

  <div class="content-block d-flex justify-content-between">
  <h5 class="fw-semibold">Payment Information</h5>
  </div>

  <div class="content-block">
    <template v-if="secretIsLoaded">
      <StripeElements
        v-if="stripeLoaded"
        v-slot="{ elements }"
        ref="elms"
        :stripe-key="stripeKey"
        :instance-options="instanceOptions"
        :elements-options="elementsOptions"
      >
        <StripeElement
          type="payment"
          ref="payment"
          :elements="elements"
          :options="cardOptions"
        />
      </StripeElements>
    </template>
  </div>

  <div class="content-block d-flex justify-content-end">
    <a href="javascript:void();" class="btn green" @click="confirmPayment">Pay</a>
  </div>
  </div>
  </div>
  </section>
</template>

<script>
import { defineComponent, ref, watch, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { loadStripe } from '@stripe/stripe-js'
import { StripeElements, StripeElement } from 'vue-stripe-js'

export default defineComponent({
    name: "AdvertisementPayment",
    setup() {
        const store = useStore();

        const stripeKey = ref(process.env.VUE_APP_STRIPE_KEY) // test key
        const instanceOptions = ref({
          // https://stripe.com/docs/js/initializing#init_stripe_js-options
        })
        const elementsOptions = ref({
          // https://stripe.com/docs/js/elements_object/create#stripe_elements-options

          // Receive client_secret from subscription response
          clientSecret: store.state.advertisementPayment.clientSecret

        })
        const cardOptions = ref({
          // https://stripe.com/docs/stripe.js#element-options
          // value: {
          //   postalCode: '12345',
          // }
        })
        const stripeLoaded = ref(false)
        const payment = ref()
        const elms = ref()
        const secretIsLoaded = ref(false)

        const confirmPayment = () => {
          elms.value.instance.confirmPayment({
            elements: elms.value.elements,
            confirmParams: {
              // return_url: `${process.env.VUE_APP_API_URL}/admin/advertisement-module/advertisement-publish`,
              // localhost:8080
              return_url: `https://${store.state.authenticated.accountSubdomain}.${'bhoomiapp.com'}/admin/advertisement-module/advertisement-publish`,
            },
            // redirect: 'if_required'
          }).then((result) => {
            // Handle result.error or result.token
            console.log(result)
          }).catch(e => console.log(e))
        }
        
        watch(() => ({...store.state.advertisementPayment}), () => {
          if (store.state.advertisementPayment.clientSecret) {
            elementsOptions.value.clientSecret = store.state.advertisementPayment.clientSecret
            secretIsLoaded.value = true;
          }
        })

        onBeforeMount(() => {
          const stripePromise = loadStripe(stripeKey.value)
          stripePromise.then(() => {
            stripeLoaded.value = true
          })
        });
        
        onMounted(() => {

            if (store.state.advertisementPayment.clientSecret) {
              secretIsLoaded.value = true;
            }

            // store.dispatch("addCurrentPath", {
            //     section: 1,
            //     val: 'Payment Information'
            // });

        });

        return {
            stripeKey,
            stripeLoaded,
            instanceOptions,
            elementsOptions,
            cardOptions,
            payment,
            elms,
            secretIsLoaded,
            confirmPayment
        };
    },
    components: { StripeElements, StripeElement }
});
</script>