<template>
  <component
    v-bind:class="{ hidden: nameFromUrl ? false : true }"
    v-bind:is="nameFromUrl"
  ></component>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import AdvertisementAccount from "@/components/Pages/Advertisement/AdvertisementAccount.vue";
import AdvertisementQuestionnaireFirst from './AdvertisementQuestionnaireFirst.vue';
import AdvertisementQuestionnaireSecond from './AdvertisementQuestionnaireSecond.vue';
import AdvertisementPreview from './AdvertisementPreview.vue';
import AdvertisementPayment from './AdvertisementPayment.vue';
import AdvertisementPublish from './AdvertisementPublish.vue';
import AdvertisementList from './AdvertisementList.vue';

export default defineComponent({
  name: 'AdvertisementModule',
  components: {
    AdvertisementAccount,
    AdvertisementQuestionnaireFirst,
    AdvertisementQuestionnaireSecond,
    AdvertisementPreview,
    AdvertisementPayment,
    AdvertisementPublish,
    AdvertisementList
  },
  setup() {
    const router = useRouter();
    let computedName = "advertisement-module";

    const nameFromUrl = computed(() => {
      let routeName = router.currentRoute.value.path.split("/");
      if (routeName[routeName.length - 1].length > 2) {
        let firstPart = routeName[routeName.length - 1].split("-")[0],
          secondPart = routeName[routeName.length - 1].split("-")[1],
          thirdPart = routeName[routeName.length - 1].split("-")[2];

        computedName = `${
          firstPart.charAt(0).toUpperCase() + firstPart.slice(1)
        }${
          secondPart ? secondPart.charAt(0).toUpperCase() + secondPart.slice(1) : 'Module'
        }${
          thirdPart ? thirdPart.charAt(0).toUpperCase() + thirdPart.slice(1) : ''
        }`;
      } else {
        computedName = 'advertisement-module';
      }

      return computedName;
    });

    return {
      nameFromUrl
    }
  }
})
</script>