<template>
  <div class="section" ref="section">
  
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5>Payment Information</h5>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>

  <div class="border-box-content">
    <div class="content-block" v-if="secretIsLoaded">
      <StripeElements
        v-if="stripeLoaded"
        v-slot="{ elements }"
        ref="elms"
        :stripe-key="stripeKey"
        :instance-options="instanceOptions"
        :elements-options="elementsOptions"
      >
        <div class="payment-title">
          <div class="add-card-icon"></div>
          <p class="add-card-text">Add a Card</p>
        </div>
        <label class="name-on-card">
          <span>Name on card (required field)</span>
          <input v-model="cardName" type="text" placeholder="" :instance="instance" :class="{nameInvalid: (cardName.length === 0) && activated.cardName}" />
          <p v-if="(cardName.length === 0) && activated.cardName" class="stripe-error">Name of Card required</p>
        </label>
        <StripeElement
          type="payment"
          ref="payment"
          :elements="elements"
          :options="cardOptions"
          @change="checkPaymentType"
          @ready="loadedStripeForm"
        />
      </StripeElements>
      
    </div>


    <!-- <div class="content-block">
    <div class="checkbox checkbox-lg"><input type="checkbox" id="savePaymentInfo" v-model="payment.saveForFuture"><label for="savePaymentInfo">Save Payment Information for future use</label></div>
    </div> -->

    <div class="content-block" v-if="newPaymentInCreation">
    <div class="buttons form-buttons">
    <div class="row">
    <div class="col-auto"><button type="submit" class="btn green" @click="submitStripeForm">Save</button></div>
    <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
    </div>
    </div>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent, inject, onBeforeMount, onMounted, ref, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import { loadStripe } from '@stripe/stripe-js'
import { StripeElements, StripeElement } from 'vue-stripe-js'

export default defineComponent({
    name: 'AdminPaymentForm',
    setup() {
        const store = useStore();
        const tooltipIsVisible = ref(false);

        const activated = reactive({
          cardName: false
        });

        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);

        const newPaymentInCreation = ref(true)

        const { popups, paymentEditionMode, paymentFormSaved } = inject('payment');
        const { collectPayment, confirmPayment, cardName, confirmSetup, createPaymentMethod } = inject('stripeProps');
        const { billingEditionMode } = inject('billing');

        const switchTooltip = (toggle) => {
            tooltipIsVisible.value = toggle;
        };

        const stripeKey = ref(process.env.VUE_APP_STRIPE_KEY) // test key
        const instanceOptions = ref({
          // https://stripe.com/docs/js/initializing#init_stripe_js-options
        })
        const elementsOptions = ref({
          // https://stripe.com/docs/js/elements_object/create#stripe_elements-options

          // Receive client_secret from subscription response
          clientSecret: store.state.subscriptionPlan.clientSecret,
          appearance: {
            variables: {
              colorText: 'hsl(210, 1%, 40%)',
              fontFamily: 'Gilroy-Semibold, system-ui, sans-serif',
              // fontSizeBase: '1.6rem'
            }
          }

        })
        const cardOptions = ref({
          // https://stripe.com/docs/stripe.js#element-options
          // value: {
          //   postalCode: '12345',
          // }
        })
        const stripeLoaded = ref(false)
        const payment = ref()
        const paymentType = ref('')
        const formIsLoaded = ref(false)
        const elms = ref()
        const secretIsLoaded = ref(false)

        const toggleSection = () => {
          sectionExpanded.value = !sectionExpanded.value;
        };

        const submitStripeForm = () => {
          
          if (!cardName.value) {
            activated.cardName = true;
            return;
          }

          paymentFormSaved.value = true;

          const paymentElement = payment.value.stripeElement

          collectPayment(paymentElement, elms.value);

          // store.state.primaryUser.subscription.plans.trial ?
          //   confirmSetup() : confirmPayment();

          const adminUserPayment = Object.entries(store.state.primaryUser.payment);

          if ((adminUserPayment.length === 0) || ((adminUserPayment.length > 0) && store.state.adminPaymentIsChanged)) {

            console.log('works for plans payment and trial');
            
            /*
            if (store.state.primaryUser.subscription.plans.basic ||
              store.state.primaryUser.subscription.plans.plus || 
              store.state.primaryUser.subscription.plans.advance ||
              store.state.primaryUser.subscription.plans.ultimate
            ) {
              console.log(store.state.subscriptionPlan.clientSecret);
              confirmPayment();
            } else {
              if (store.state.primaryUser.subscription.plans.trial) {
                confirmSetup();
              }
            }
            */
            console.log(confirmPayment);
            confirmSetup();
          }

          if (((adminUserPayment.length > 0) && !store.state.adminPaymentIsChanged)) {
            console.log('works for addition of payment');
            createPaymentMethod();
          }
          newPaymentInCreation.value = false;
        };

        const checkPaymentType = (ev) => {

          console.log(ev.value.type, 'payment type');

          paymentType.value = ev.value.type;

          // {
          //   elementType: 'payment',
          //   complete: false,
          //   empty: false,
          //   collapsed: false,
          //   value: { type: "card" },
          // }
        }

        const loadedStripeForm = () => {
          console.log('form is loaded');
          formIsLoaded.value = true;
        }

        const cancelChanges = () => {
          paymentEditionMode.value = 'represent';
          billingEditionMode.value = 'represent';
        }

        watch(sectionExpanded, (newVal) => {
          section.value.style.height = newVal ?
              `${sectionHeight.value}px` :
              `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });

        watch(formIsLoaded, () => {
          section.value.style.height = "auto";
          setTimeout(() => {
            console.log(section.value.style.height, section.value.offsetHeight)
              sectionHeight.value = section.value.offsetHeight;
          });
        });

        watch(paymentType, (newVal, oldVal) => {
          if (oldVal && newVal) {
            console.log(oldVal, newVal);
            section.value.style.height = "auto";
            setTimeout(() => {
              console.log(section.value.style.height, section.value.offsetHeight)
                sectionHeight.value = section.value.offsetHeight;
            }, 500);
          }
        });

        watch(() => ({...store.state.futurePayment}), (newVal) => {
          // if (Object.entries(newVal).length > 0) {
          if (newVal.clientSecret) {
            elementsOptions.value.clientSecret = newVal.clientSecret
            setTimeout(() => {
              secretIsLoaded.value = true;
            }, 100)
          }
          // }
        })

        watch(() => (popups.billing.active), (newVal) => {
          if (newVal) store.dispatch('getPaymentMethodsList');
        })

        onBeforeMount(() => {
          const stripePromise = loadStripe(stripeKey.value)
          stripePromise.then(() => {
            stripeLoaded.value = true
          })
        })

        onMounted(() => {
          console.log(store.state.subscriptionPlan.clientSecret, 'store.state.subscriptionPlan.clientSecret');
          setTimeout(() => {
            sectionHeight.value = section.value.offsetHeight;
            section.value.style.height = `${section.value.offsetHeight}px`;
          });

          // const adminUserPayment = Object.entries(store.state.primaryUser.payment);

          if (store.state.subscriptionPlan.clientSecret) {
            elementsOptions.value.clientSecret = store.state.subscriptionPlan.clientSecret;
            secretIsLoaded.value = true;
          }

          // if (((adminUserPayment.length > 0) && !store.state.adminPaymentIsChanged)) {
            store.dispatch('createFuturePayment');
            secretIsLoaded.value = false;
          // }
          
          store.dispatch('addCurrentPath', {
              section: 1,
              val: 'Payment'
          });

          cardName.value = '';
        });
        return {
            tooltipIsVisible,
            switchTooltip,
            cancelChanges,
            submitStripeForm,
            stripeKey,
            stripeLoaded,
            instanceOptions,
            elementsOptions,
            cardOptions,
            payment,
            elms,
            activated,
            cardName,

            section,
            boxHeader,
            toggleSection,
            checkPaymentType,
            loadedStripeForm,
            store,
            secretIsLoaded,
            newPaymentInCreation
        };
    },
    components: { 
      StripeElements,
      StripeElement 
    }
})
</script>

<style lang="scss" scoped>

.payment-title {
  border: 2px solid #0570de;
  border-radius: 5px;
  width: 49%;
  padding: 0 1rem;
  margin-bottom: 1.25rem;
  .add-card-text {
    color: #0570de;
    font-size: var(--fs-lg);
    font-family: var(--fw-semibold);
  }
  .add-card-icon {
    display: flex;
    text-indent: -9999px;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('@/assets/images/add-card-stripe.png');
  }
}


.name-on-card {
  display: flex;
  flex-direction: column;
  width: 49%;
  margin-bottom: 1.25rem;
  span {
    color: hsl(210, 1%, 40%);
    font-size: var(--fs-lg);
    font-family: var(--fw-semibold);
  }
  input {
    color: #656667;
    font-size: var(--fs-lg);
    font-family: var(--fw-semibold);
    
    padding: 0.75rem;
    background-color: #fff;
    border-radius: 5px;
    transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
    &:focus {
      outline: 0px;
      border-color: hsla(210, 90%, 45%, 50%);
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0,0,0,0.08);
    }
    &.nameInvalid {
      color: #df1b41;
      border-color: #df1b41;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px #df1b41;
    }
  }
  .stripe-error {
    margin-top: 0.25rem;
    color: #df1b41;
    font-size: 1.25rem;
  }
}
.picked-up-expiry {
    position: absolute;
    right: 1rem;
    top: 1.5rem;
    z-index: 9;
    width: 2.2rem;
    height: 2.2rem;
    opacity: 0;
  }
  .expiry-date-placeholder {
    font-size: var(--fs-sm);
    color: hsl(var(--gray));
    font-family: var(--fw-medium);
    position: absolute;  
    .picked-up-expiry {
      position: absolute;
      right: 1rem;
      top: 1.5rem;
      z-index: 9;
      width: 2.2rem;
      height: 2.2rem;
      opacity: 0;
    }
    .expiry-date-placeholder {
      font-size: var(--fs-sm);
      color: hsl(var(--gray));
      font-family: var(--fw-medium);
      position: absolute;
      left: 1rem;
      top: 1.6rem;
      pointer-events: none;
    }
    .tooltip-container {
      
    }
  
    .bank-tooltip {
      position: absolute;
      width: 414px;
      height: 220px;
      background-image: url('@/assets/profile/payment_tooltip2.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-color: #fff;
      top: 75px;
      left: -160px;
      z-index: 999999;
    }
  }
.bank-tooltip-container {
  .bank-tooltip {
    position: absolute;
    width: 414px;
    height: 220px;
    background-image: url('@/assets/profile/payment_tooltip2.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #fff;
    top: 35px;
    left: -310px;
    z-index: 999;
  }
}
.cards-section {
  display: flex;
  max-width: 250px;
  width: 100%;
  justify-content: space-around;
  background-image: url('@/assets/profile/credit_cards.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% center;
  height: 30px;
  .card-icon {
    display: flex;
    text-indent: -9999px;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &.visa {
      background-image: url('@/assets/profile/visa.png');
    }
    &.mastercard {
      background-image: url('@/assets/profile/master_card.png');
    }
    &.discover {
      background-image: url('@/assets/profile/discover.png');
    }
    &.americanexpress {
      background-image: url('@/assets/profile/american_express.png');
    }
  }
}
</style>