<template>

<section class="section" ref="section">
<div class="border-box active">
<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Map Farm</h5>
<div class="info-icons">
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

  <div class="border-box-content">

<div class="content-block">

<div class="content-row choose-farm">
  <div class="row align-items-end justify-content-between">

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.farmName || (plan.mapDraw.farmName.length > 0)}">Choose a farm</p>
<select class="form-control" 
  v-model="plan.mapDraw.farmName"
  @click="() => {
    activated.farmName = true;
    focused.farmName = true;
  }"
  @change="() => {
    focused.farmName = false;
  }"
  :disabled="(editionMode === 'represent')"
>
  <option 
    v-for="option in options"
    v-bind:key="option + 'farm-options-map'"
  >{{ option }}</option>
</select>
</div>
</div>

<div class="col-3">
  <button 
    type="submit" 
    class="btn green" 
    @click="createNewPlan"
    v-if="(editionMode === 'represent') || imageDataSaved"
  >New Plan</button>
</div>

<div class="col-3">
<!-- <div class="icon-links">
<a href="javascript:void();" class="active"><img src="images/icon-1.png" alt=""></a>
<a href="javascript:void();"><img src="images/icon-2.png" alt=""></a>
<a href="javascript:void();"><img src="images/icon-3.png" alt=""></a>
</div> -->
</div>

<div class="col-3"></div>

</div>
  </div>

  <div class="content-row">
    <!-- <FarmDrawScheme v-if="(editionMode !== 'represent') && !imageDataSaved" />
    <FarmDrawSchemeFilled v-else-if="(editionMode === 'represent') || imageDataSaved" /> -->
    <FarmDrawSchemeFilled :history="true" />
    <!-- <button 
      v-if="imageDataFilled && (editionMode !== 'represent') && !imageDataSaved"
      @click="() => {
        editionMode = 'create';
        imageDataSaved = true;
        // bed.imageData = [];
      }"
      class="btn green"
    >Add Bed Details</button> -->
  </div>

  </div>
  </div>
  </div>
  </section>
</template>

<script>
import { defineComponent, ref, reactive, inject, provide, watch, onMounted } from 'vue';
import FarmDrawSchemeFilled from './FarmDrawSchemeFilled.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'FarmHistoryMap',
    setup() {

      const router = useRouter();
      const store = useStore();

      const { plan } = inject('planDetails');

      const focused = reactive({
        farmName: false
      })

      const createNewPlan = () => {
        router.push({ 
          name: 'farm', 
          params: { 
            pagename: 'farm-profile', 
            fromFarmHistory: true 
          } 
        })
      }
        
      const options = ref([]);

      watch(store.state.farms, () => {//newVal
        // const currentFarm = newVal.find(farm => farm.id === selectedFarm.id)

        // bedsList.value = currentFarm?.beds;

        options.value = [...store.state.farms.map(farm => farm.farmName)]

      });

      watch(plan, (newVal) => {
        console.log(plan, newVal, 'plan from map');
      })

      onMounted(() => {
        options.value = [...store.state.farms.map(farm => farm.farmName)]
      })

      provide('bedEdition', {
        mapDraw: plan.mapDraw
      });

      return {
        plan,
        options,
        createNewPlan,
        focused
      }
    },
    components: { FarmDrawSchemeFilled }
})
</script>

<style lang="scss" scoped>
.new-plan-tab {
  display: flex;
  align-items: center;
  font-size: var(--fs-lg);
  font-family: var(--fw-semibold);
  color: hsl(var(--gray));
  cursor: pointer;
  .info-icon {
    margin-right: 1rem;
  }
}
</style>
