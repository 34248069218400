<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">{{ equipment.item || 'Farm Equipment' }}</h5>
<div class="icon-group">
<!-- <div class="icon green icon-o camera-plus"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
>
  <img src="@/assets/images/camera-plus.svg" alt="">
  <div class="helper-tooltip" v-if="helperTooltip">Take a shot</div>
</div> -->
</div>
</div>

<div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.date || (equipment.date.length > 0)}">Date Purchased</p>
<input type="date" class="form-control"
 v-model="equipment.date"
 :style="{color: (focused.date || equipment.date.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
  @focus="() => {
    activated.date = true;
    focused.date = true;
  }"
  @blur="() => {
    focused.date = false;
  }"
>
<div class="date-icon"></div>
 <p v-if="activated.date && equipment.date.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3" v-if="vendors.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorName || (equipment.vendorName.length > 0)}">Vendor</p>
  <select class="form-control" 
    v-model="equipment.vendorName"
    @click="() => {
      activated.vendorName = true;
      focused.vendorName = true;
    }"
    @change="() => {
      focused.vendorName = false;
    }">
    <option
      v-for="vend in vendors"
      v-bind:key="vend.vendor + 'vendor-option-equipment'"
    >{{ vend.vendor }}</option>
    <option>Other</option>
  </select>
 <p v-if="activated.vendor && equipment.vendorName.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.vendor && equipment.vendorName.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-else>
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorName || (equipment.vendorName.length > 0)}">Vendor</p>
<input type="text" class="form-control"
 v-model="equipment.vendorName"
  @focus="() => {
    activated.vendorName = true;
    focused.vendorName = true;
    openAddVendorForm();
  }"
  @blur="() => {
    focused.vendorName = false;
  }"
>
 <p v-if="activated.vendorName && equipment.vendorName.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.vendorName && equipment.vendorName.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-if="products.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.item || (equipment.item.length > 0)}">Item</p>
  <select class="form-control" 
    v-model="equipment.item"
    @click="() => {
      activated.item = true;
      focused.item = true;
    }"
    @change="() => {
      focused.item = false;
    }">
    <option
      v-for="prod in products"
      v-bind:key="prod.item + 'equipment-option-equipment'"
    >{{ prod.item }}</option>
  </select>
 <p v-if="activated.item && equipment.item.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.item && equipment.item.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3" v-else>
<div class="form-item">
<p class="form-label" :class="{active: focused.item || (equipment.item.length > 0)}">Item</p>
<input type="text" class="form-control"  v-model="equipment.item"
  @focus="() => {
    activated.item = true;
    focused.item = true;
  }"
  @blur="() => {
    focused.item = false;
  }">
 <p v-if="activated.item && equipment.item.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.item && equipment.item.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.manufacturer || (equipment.manufacturer.length > 0)}">Manufacturer</p>
<input type="text" class="form-control"
 v-model="equipment.manufacturer"
  @focus="() => {
    activated.manufacturer = true;
    focused.manufacturer = true;
  }"
  @blur="() => {
    focused.manufacturer = false;
  }"
>
 <p v-if="activated.manufacturer && equipment.manufacturer.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.manufacturer && equipment.manufacturer.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.brand || (equipment.brand.length > 0)}">Brand</p>
<input type="text" class="form-control"
  v-model="equipment.brand"
  @focus="() => {
    activated.brand = true;
    focused.brand = true;
  }"
  @blur="() => {
    focused.brand = false;
  }"
>
 <p v-if="activated.brand && equipment.brand.length === 0" class="form-info red text-end">* Required</p>
 <!-- <p v-if="activated.brand && `${equipment.brand}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p> -->
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.numberOfItems || (`${equipment.numberOfItems}`.length > 0)}"># of ltems</p>
<input type="number" class="form-control"
 v-model="equipment.numberOfItems"
  @focus="() => {
    activated.numberOfItems = true;
    focused.numberOfItems = true;
  }"
  @blur="() => {
    focused.numberOfItems = false;
  }"
>
 <p v-if="activated.numberOfItems && `${equipment.numberOfItems}`.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.numberOfItems && `${equipment.numberOfItems}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.invoice || (equipment.invoice.length > 0)}">Invoice #</p>
<input type="text" class="form-control"
 v-model="equipment.invoice"
  @focus="() => {
    activated.invoice = true;
    focused.invoice = true;
  }"
  @blur="() => {
    focused.invoice = false;
  }"
>
 <p v-if="activated.invoice && equipment.invoice.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.amountPaid || (`${equipment.amountPaid}`.length > 0)}">Amount</p>
<input type="number" class="form-control"
 v-model="equipment.amountPaid"
  @focus="() => {
    activated.amountPaid = true;
    focused.amountPaid = true;
  }"
  @blur="() => {
    focused.amountPaid = false;
  }"
>
 <p v-if="activated.amountPaid && `${equipment.amountPaid}`.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.amountPaid && `${equipment.amountPaid}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.warrantyExp || (equipment.warrantyExp.length > 0)}">Warranty Exp</p>
<input type="date" class="form-control"
 :style="{color: (focused.warrantyExp || equipment.warrantyExp.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
 v-model="equipment.warrantyExp"
  @focus="() => {
    activated.warrantyExp = true;
    focused.warrantyExp = true;
  }"
  @blur="() => {
    focused.warrantyExp = false;
  }"
>
<div class="date-icon"></div>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.serviceDate || (equipment.serviceDate.length > 0)}">Service Date</p>
<input type="date" class="form-control" 
 :style="{color: (focused.serviceDate || equipment.serviceDate.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
 v-model="equipment.serviceDate"
  @focus="() => {
    activated.serviceDate = true;
    focused.serviceDate = true;
  }"
  @blur="() => {
    focused.serviceDate = false;
  }"
>
<div class="date-icon"></div>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorContact || (equipment.vendorContact.length > 0)}">Vendor Contact</p>
<input type="text" class="form-control"
 v-model="equipment.vendorContact"
  @focus="() => {
    activated.vendorContact = true;
    focused.vendorContact = true;
  }"
  @blur="() => {
    focused.vendorContact = false;
  }"
  disabled
>
 <p v-if="activated.vendorContact && equipment.vendorContact.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.vendorContact && equipment.vendorContact.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorPhone || (equipment.vendorPhone.length > 0)}">Vendor Phone</p>
<input type="text" class="form-control"
 v-model="equipment.vendorPhone"
  @focus="() => {
    activated.vendorPhone = true;
    focused.vendorPhone = true;
  }"
  @blur="() => {
    focused.vendorPhone = false;
    phoneNumberFormat('vendorPhone')
  }"
  disabled
>
 <p v-if="activated.vendorPhone && equipment.vendorPhone.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.vendorPhone && equipment.vendorPhone.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
</div>
</div>

<div class="col-12">
<div class="form-item">
<p class="form-label" :class="{active: focused.comments || (equipment.comments.length > 0)}">Comments</p>
<textarea class="form-control"
 v-model="equipment.comments"
  @focus="() => {
    activated.comments = true;
    focused.comments = true;
  }"
  @blur="() => {
    focused.comments = false;
  }"
></textarea>
 <p v-if="activated.comments && equipment.comments.length > 500" class="form-info red text-end">Please use maximum 500 characters</p>
</div>
</div>

</div>
</div>
</div>

</div>

<div class="content-block">

<div class="content-section checkbox-group-section">

<p>Set Nofification:</p>

<div class="checkbox-group-wrap">

<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="serviceDate" v-model="equipment.serviceDateNotification"><label for="serviceDate">Service Date</label></div>
<div class="field-box d-flex">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control xs" v-model="equipment.serviceDateNotificationDays">
</div>
</div>

<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="warrantyDate" v-model="equipment.warrantyDateNotification"><label for="warrantyDate">Warranty Date</label></div>
<div class="field-box d-flex">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control xs" v-model="equipment.warrantyDateNotificationDays">
</div>
</div>

</div>


</div>
</div>

<div class="content-block">

  <FileAttachments :key="`${equipment.id}-equipment-attachments`" v-bind:section="'vendorItem'" v-bind:instance="`${equipment.id}`" />

  <div class="content-section">
  <div class="buttons">
  <div class="row">
  <div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
  <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
  </div>
  </div>
  </div>

</div>

</div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { reactive, inject, ref, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
// import moment from 'moment';
import { useRouter } from 'vue-router';

export default {
    name: "EquipmentForm",
    components: { FileAttachments },
    setup() {
      const store = useStore();
      const router = useRouter();

      const activated = reactive({
        item: false,
        manufacturer: false,
        brand: false,
        numberOfItems: false,
        date: false,
        amountPaid: false,
        invoice: false,
        vendorName: false,
        vendorPhone: false,
        vendorContact: false,
        warrantyExp: false,
        serviceDate: false
      });

      const focused = reactive({
        item: false,
        manufacturer: false,
        brand: false,
        numberOfItems: false,
        date: false,
        amountPaid: false,
        invoice: false,
        vendorName: false,
        vendorPhone: false,
        vendorContact: false,
        warrantyExp: false,
        serviceDate: false
      });

      const { equipment, editionMode, selectedEquipment, selectEquipment, defaultEquipment } = inject("equipmentDetails");

      const vendors = computed(() => store.state.vendors);

      const equipments = computed(() => [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.type === 'Equipment'))

      const products = ref([]);

      const helperTooltip = ref(false);

      // const newEquipmentId = ref(null);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const phoneNumberFormat = (phoneType) => {
        if (equipment[phoneType].length > 0) {
          if (equipment[phoneType][0] !== "(") {
              const arrStr = equipment[phoneType].split("");
              equipment[phoneType] = `(${arrStr.filter((num, indx) => {
                  if (indx < 3)
                      return num;
              }).join("")}) ${arrStr.filter((num, indx) => {
                  if ((indx >= 3) && (indx < 6))
                      return num;
              }).join("")}-${arrStr.filter((num, indx) => {
                  if (indx >= 6)
                      return num;
              }).join("")}`;
          }
        }
      };

      const submitForm = () => {

        if (!equipment.item || !equipment.manufacturer ||
          !equipment.numberOfItems || !equipment.date ||
          !equipment.amountPaid || !equipment.vendor || !equipment.vendorPhone ||
          !equipment.vendorContact) {
          if (!equipment.item) activated.item = true;
          if (!equipment.manufacturer) activated.manufacturer = true;
          if (!equipment.numberOfItems) activated.numberOfItems = true;
          if (!equipment.date) activated.date = true;
          if (!equipment.amountPaid) activated.amountPaid = true;
          if (!equipment.vendor) activated.vendor = true;
          if (!equipment.vendorPhone) activated.vendorPhone = true;
          if (!equipment.vendorContact) activated.vendorContact = true;

          return;
        }

        // if (!equipment.id) {
        //   equipment.id = newEquipmentId.value;
        // }

        // equipment.date = moment(equipment.date).format('MM/DD/YYYY');
        // equipment.warrantyExp = moment(equipment.warrantyExp).format('MM/DD/YYYY');
        // equipment.serviceDate = moment(equipment.serviceDate).format('MM/DD/YYYY');

        const formData = {
          ...equipment
        };

        if (vendors.value.length <= 0) {
          // store.dispatch("createVendor", {
          //   id: uuid(),
          //   vendor: equipment.vendor
          // });

          // store.dispatch('addVendorEquipments', {
          //   id: uuid(),
          //   vendor: equipment.vendor,
          //   item: equipment.item,
          //   manufacturer: equipment.manufacturer
          // })
        }

        // (editionMode.value === "edit") ?
        //     store.dispatch("editEquipment", { ...formData }) :
        //     store.dispatch("createEquipment", {...formData});
        // console.log(formData);
        store.dispatch("editVendorItem", { ...formData });
        console.log(formData, 'formData');
        // (editionMode.value === "create") && closeForm();
        (editionMode.value === "edit") && selectEquipment({...equipment}, 'represent');
      }

      // const closeForm = () => {
      //   for (let prop in equipment) {
      //     equipment[prop] = defaultEquipment[prop];
      //   }

      //   for (let prop in activated) {
      //     activated[prop] = false;
      //   }
        
      //   for (let prop in focused) {
      //     focused[prop] = false;
      //   }
      // }

      const cancelChanges = () => {
        if (editionMode.value === "edit") {
          for (let prop in equipment) {
            equipment[prop] = selectedEquipment[prop];
          }
          editionMode.value = "represent";
        } else {
          for (let prop in equipment) {
            equipment[prop] = defaultEquipment[prop];
          }
        }
      };

      const openAddVendorForm = (other) => {
        if ((vendors.value.length === 0) || other) {
          router.push({ 
            name: 'vendors', 
            params: { 
              pagename: 'vendor-module',
              source: 'equipment',
              other: other
            } 
          });
        }
      }

      watch(() => (equipment.vendorName), (newVal) => {
        if (newVal === 'Other') {
          openAddVendorForm(true);
        }
        const selectedVendor = vendors.value.find(vend => vend.vendor === newVal);
        products.value = [...equipments.value.filter(eq => eq.vendor === selectedVendor.id)];
        if (selectedVendor) {
          equipment.vendorPhone = selectedVendor.vendorPhone;
          equipment.vendorContact = selectedVendor.vendorContact;
        }
      })

      watch(() => (equipment.item), (newVal) => {
        const selectedProduct = products.value.find(prd => prd.item === newVal);

        if (selectedProduct) {
          equipment.manufacturer = selectedProduct.manufacturer;
        }
      });

      watch(() => ({...equipment}), (newVal) => {
        if (!newVal.id) {
          equipment.id === uuid();
        }
      });

      onMounted(() => {
        // newEquipmentId.value = uuid();
        if (!equipment.id) {
          console.log(equipment.id, 'equipment.id');
          equipment.id = uuid();
        }
        // equipment.id = newEquipmentId.value;
        if ((vendors.value.length > 0) && !router.currentRoute.value.params.otherVendor) {
          equipment.vendorName = vendors.value[0].vendor;
        }
        if (router.currentRoute.value.params.otherVendor) {
          equipment.vendorName = router.currentRoute.value.params.otherVendor;
        }
      })
      
      return {
        activated,
        focused,
        equipment,
        vendors,
        products,
        phoneNumberFormat,
        submitForm,
        cancelChanges,
        helperTooltip,
        switchHelperTooltip,
        openAddVendorForm
      }
    }
}
</script>

<style lang="scss" scoped>
.date-icon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 3.7rem;
  right: 1rem;
  z-index: 8;
  pointer-events: none;
  border-radius: 1rem;
  background:url('@/assets/images/calendar-icon.svg') center center no-repeat;
  background-color: #fff;
}
</style>