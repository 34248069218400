<template>
  <!-- <SecondaryProfileHeader /> -->

  <SecondaryProfileDetailsForm v-if="mainDetails.mode === 'edit'" />
  <!--  && isAuth('edit-details') -->
  <SecondaryProfileDetails v-else/>

</template>

<script>
import { ref, reactive, provide, onMounted, inject, watch } from "vue";
import { useStore } from "vuex";
import SecondaryProfileDetails from "./SecondaryProfileDetails.vue";
import SecondaryProfileDetailsForm from "./SecondaryProfileDetailsForm.vue";
import { useRouter } from "vue-router";

export default {
  name: "MenuSecondaryprofile",
  components: {
    SecondaryProfileDetails,
    SecondaryProfileDetailsForm
},
  setup() {
    const store = useStore();
    const router = useRouter();
    const { isAuth } = inject('mainUI');

    const mainDetails = reactive({
      id: '',
      accountName: '',
      email: '',
      title: '',
      password: '',
      // email: '',
      userRole: '',
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      mobilePhone: '',
      workPhone: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      employeeType: '',
      grade: '',
      multifactor: false,

      submitted: false,
      activated: false,
      mode: store.state.secondaryUser?.mainDetails?.submitted ? 'represent' : 'edit'
    });

    const editedByAdmin = ref(false);

    const editedByUser = ref(true);

    const companiesList = reactive({
      value: []
    });

    const createNewUser = () => {
      const routeSource = `${router.currentRoute.value.params.source}`;
      router.push({ name: 'admin', params: { pagename: 'admin-users' } });
      setTimeout(() => {
        router.push({name: 'page', params: {pagename: 'secondary-user', source: routeSource, parentSource: routeSource }})
      })
    }

    const changeMainField = (val, field) => {
      mainDetails[field] = val;
    }

    watch(() => ({...store.state.secondaryUser?.mainDetails}), (newVal) => {
      if (newVal?.submitted) {

        for (let prop in newVal) { 
          if (prop !== 'mode') {
            changeMainField(newVal[prop], prop);
          }
        }
        changeMainField('represent', 'mode');
        changeMainField(true, 'submitted');
      }
    })

    provide('mainDetails', {
      mainDetails,
      changeMainField,
      createNewUser,
      editedByAdmin,
      editedByUser,
      options: companiesList
    });

    onMounted(() => {

      for (let prop in store.state.secondaryUser.mainDetails) {
        if (prop !== '_id') {
          // mainDetails[prop] = store.state.secondaryUser.mainDetails[prop];
        }
      }

      store.dispatch('getAttachments');
      
    })

    return {

      mainDetails,
      isAuth,

      createNewUser,

      editedByAdmin
    };
  },
};
</script>

<style lang="scss" scoped>
.add-new-popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    .popup-message {
      background: #fff;
      display: flex;
      flex-direction: column;
      max-width: 650px;
      width: 100%;
      height: max-content;
      justify-content: space-around;
      align-items: center;
      position: relative;
      border-radius: 11px;
      color: rgba(40,41,43,1);
      font-size: 21px;
      margin: 0 20px;
      padding: 25px 0;
      text-align: center;
      .close-button {
        position: absolute;
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: auto;
        width: 30px;
        height: 30px;
        background-image: url('@/assets/profile/close_button.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        right: 10px;
        top: 22px;
        cursor: pointer;
      }
      .popup-text {
        margin: 0 40px;
        font-size: 16px;
        font-weight: 600;
      }
      .popup-buttons {
        display: flex;
        width: 60%;
        justify-content: space-evenly;
        margin-top: 30px;
        button {
          width: 160px;
          height: 40px;
          border-radius: 10px;
          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }

.secondary-user-profile {
  form {
    flex-direction: column;
    flex-wrap: nowrap;
    .add-details {
      width: max-content;
      align-self: end;
      margin-bottom: 24px;
    }
    .form-actions {
      margin-top: 24px;
      button {
        margin-right: 24px;
      }
    }
  }
  .q-expansion-item--popup.q-expansion-item--expanded,
  .q-expansion-item--popup.q-expansion-item--collapsed {
    padding: 0;
  }

  .q-expansion-item {
    margin-bottom: 45px;
  }
}
</style>
