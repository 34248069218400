<template>
  <section class="section">
<div class="border-box active">

<div class="border-box-content">
<div class="search-results">

<div class="search-result-header">
<h5>Search Results...</h5>
<p>Total results {{ searchResults.length }}...</p>
</div>

<div class="search-result">

<!-- <div class="search-result-item">
<h5><a href="javascript:void();">Add Task for Bed A</a></h5>
<div class="search-item-details">
<span>Dt: 04/15/2023</span>
<span>:</span>
<span>James Smith</span>
<span>:</span>
<span>Tasks</span>
</div>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
</div>

<div class="search-result-item">
<h5><a href="javascript:void();">Add Ferilizer to Inventory</a></h5>
<div class="search-item-details">
<span>Dt: 04/15/2023</span>
<span>:</span>
<span>James Smith</span>
<span>:</span>
<span>Tasks</span>
</div>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
</div>

<div class="search-result-item">
<h5><a href="javascript:void();">Beans Sales</a></h5>
<div class="search-item-details">
<span>Dt: 04/15/2023</span>
<span>:</span>
<span>James Smith</span>
<span>:</span>
<span>Tasks</span>
</div>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
</div>

<div class="search-result-item">
<h5><a href="javascript:void();">Add Task for Bed A</a></h5>
<div class="search-item-details">
<span>Dt: 04/15/2023</span>
<span>:</span>
<span>James Smith</span>
<span>:</span>
<span>Tasks</span>
</div>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
</div>

<div class="search-result-item">
<h5><a href="javascript:void();">Add Ferilizer to Inventory</a></h5>
<div class="search-item-details">
<span>Dt: 04/15/2023</span>
<span>:</span>
<span>James Smith</span>
<span>:</span>
<span>Tasks</span>
</div>
</div>

<div class="search-result-item">
<h5><a href="javascript:void();">Add Task for Bed A</a></h5>
<div class="search-item-details">
<span>Dt: 04/15/2023</span>
<span>:</span>
<span>James Smith</span>
<span>:</span>
<span>Tasks</span>
</div>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
</div> -->

<template 
  v-for="(searchResult, index) in searchResults"
  v-bind:key="searchResult && (searchResult.header + 'search-result')"
>
  <div class="search-result-item"
    v-if="(index >= ((currentPage - 1) * 5) && index <= ((nextPage - 1) * 5) && searchResult)"
  >
  <h5><a href="javascript:void();" @click="() => navigateToSection((searchResult.section  || searchResult?.mainDetails?.section), (searchResult.id  || searchResult?.mainDetails?.id))">{{searchResult.header  || searchResult?.mainDetails?.header}}</a></h5>
  <div class="search-item-details">
  <span>Dt: {{ searchResult.datePosted || searchResult?.mainDetails?.datePosted }}</span>
  <span>:</span>
  <span>{{ searchResult.author || searchResult?.mainDetails?.author  }}</span>
  <span>:</span>
  <span>{{ searchResult.sectionHeader || searchResult?.mainDetails?.sectionHeader  }}</span>
  </div>
  <p>{{ searchResult.text || searchResult?.mainDetails?.text }}</p>
  </div>
</template>

</div>

<div class="post-pagination">
<a href="javascript:void();"
  @click="switchPage('prev', currentPage === 1)"
><img src="@/assets/images/arrow-left.png" alt=""></a>
<a href="javascript:void();" class="active" @click="switchToSelectedPage(currentPage - 1)">{{ currentPage }}</a> 
<a href="javascript:void();" v-if="currentPage < pagination" @click="switchToSelectedPage(nextPage)">{{nextPage}}</a> 
<a href="javascript:void();"
  @click="switchPage('next', currentPage === pagination)"
><img src="@/assets/images/arrow-right.png" alt=""></a>
</div>

</div>
</div>

</div>
</section><!--//End Section-->
</template>

<script>
import store from '@/store';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'SearchPage',
  setup() {
    // const searchResults1 = computed(() => [
    //   {
    //     header: 'Add Task for Bed A',
    //     date: '04/15/2023',
    //     author: 'James Smith',
    //     section: 'Tasks',
    //     text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley`
    //   },
    //   {
    //     header: 'Add Ferilizer to Inventory',
    //     date: '04/15/2023',
    //     author: 'James Smith',
    //     section: 'Tasks',
    //     text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley`
    //   },
    //   {
    //     header: 'Beans Sales',
    //     date: '04/15/2023',
    //     author: 'James Smith',
    //     section: 'Tasks',
    //     text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley`
    //   },
    //   {
    //     header: 'Add Task for Bed A',
    //     date: '04/15/2023',
    //     author: 'James Smith',
    //     section: 'Tasks',
    //     text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley`
    //   },
    //   {
    //     header: 'Add Ferilizer to Inventory',
    //     date: '04/15/2023',
    //     author: 'James Smith',
    //     section: 'Tasks',
    //     text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley`
    //   },
    //   {
    //     header: 'Add Task for Bed A',
    //     date: '04/15/2023',
    //     author: 'James Smith',
    //     section: 'Tasks',
    //     text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley`
    //   },
    //   {
    //     header: '7 header',
    //     date: '04/15/2023',
    //     author: 'James Smith',
    //     section: 'Tasks',
    //     text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley`
    //   },
    //   {
    //     header: '8 header',
    //     date: '04/15/2023',
    //     author: 'James Smith',
    //     section: 'Tasks',
    //     text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley`
    //   },
    //   {
    //     header: '9 header',
    //     date: '04/15/2023',
    //     author: 'James Smith',
    //     section: 'Tasks',
    //     text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley`
    //   },
    //   {
    //     header: '10 header',
    //     date: '04/15/2023',
    //     author: 'James Smith',
    //     section: 'Tasks',
    //     text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley`
    //   },
    //   {
    //     header: '11 header',
    //     date: '04/15/2023',
    //     author: 'James Smith',
    //     section: 'Tasks',
    //     text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley`
    //   }
    // ])

    const searchResults = computed(() => store.state.searchResults);

    const pagination = ref(0);

    const currentPage = ref(1);

    const router = useRouter();

    const nextPage = ref(currentPage.value + 1)

    const switchPage = (dir, disabled) => {
      if (disabled) {
        return
      }
      console.log(dir, 'dir');
      if (dir === 'next') currentPage.value = currentPage.value + 1;
      if (dir === 'prev' && currentPage.value > 0) currentPage.value = currentPage.value -1;
    }

    const switchToSelectedPage = (pageNum) => {
      currentPage.value = pageNum;
    }

    const navigateToSection = (section, resultId) => {
      console.log(resultId);
      router.push(section)
    }

    watch(currentPage, () => {
      nextPage.value = (currentPage.value + 1) <= pagination.value ? (currentPage.value + 1) : pagination.value
    })

    watch(searchResults, (newVal) => {
      console.log(newVal);
    })

    onMounted(() => {
      pagination.value = Math.ceil(searchResults.value.length / 5)
    })

    return {
      searchResults,
      pagination,
      currentPage,
      nextPage,
      switchPage,
      switchToSelectedPage,
      navigateToSection
    }
  }
})
</script>