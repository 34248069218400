<template>
  <TaskHeader />
  <CalendarWidget v-if="(editionMode === 'calendar')" />
  <!--  || (editionMode === 'represent') -->

  <div ref="taskForm"></div>
  <AddTask v-if="((editionMode === 'edit') || (editionMode === 'create')) && (isAuth('edit-task') || isAuth('add-task'))" />
  <AddTaskFilled  v-if="editionMode === 'represent'" />
  <TaskList v-bind:parentModule="'task-module'" />
</template>

<script>
import TaskHeader from "./TaskHeader.vue";
import "@lbgm/pro-calendar-vue/style";
import { reactive, provide, ref, inject, onMounted, watch } from 'vue';
import AddTask from './AddTask.vue';
import TaskList from './TaskList.vue';
// import { onMounted, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import AddTaskFilled from './AddTaskFilled.vue';
import CalendarWidget from "./CalendarWidget.vue";
import { useRouter } from "vue-router";

export default {
  name: "TaskModule",
  components: { AddTask, TaskList, AddTaskFilled, CalendarWidget, TaskHeader },
  setup() {
    const store = useStore();
    const router = useRouter();

    const { isAuth } = inject('mainUI');

    const task = reactive({
      id: '',
      date: '',
      task: '',
      assignedTo: '',
      priority: '',
      startTime: '',
      completionTime: '',
      totalHours: '',
      taskStatus: '',
      comments: '',

      farm: '',
      bed: '',
      season: '',
      cropCycle: '',
      crop: '',
      equipment: '',
      input: '',
      brand: '',
      variety: '',
      quantity: '',
      numberOfItems: '',

      cropHarvested: '',
      amtHarvested: '',
      wastage: '',
      wastageCause: '',

      inputTask: false,
      harvestTask: false,
      otherTask: false,

      // salaried: false,
      taskType: false,

      recurring: false,
      reminder: false,
      reminderTime: '',
      creationDate: '',
      reminderIsSet: false,

      // Recurring
      startTimeRecurring: '',
      endTimeRecurring: '',
      durationRecurring: '',

      recurrencePattern: '',

      recurrenceWeeklyWeeks: '',
      recurrenceWeeklySunday: false,
      recurrenceWeeklyMonday: false,
      recurrenceWeeklyTuesday: false,
      recurrenceWeeklyWednesday: false,
      recurrenceWeeklyThursday: false,
      recurrenceWeeklyFriday: false,
      recurrenceWeeklySaturday: false,

      recurrenceDaily: '',
      recurrenceDailyDays: '',
      
      startDateRecurring: '',
      endDateRecurring: '',
      recurrenceId: ''
    });

    const selectedTask = reactive({
      ...task
    });

    const defaultTask = {
      ...task
    }

    const editionMode = ref('calendar');

    const taskForm = ref(null);

    const bedsOptions = reactive({
      value: []
    });

    const crops = ref([]);

    const currentSelectedTask = ref(null);

    const users = ref([...store.state.users]);

    const farms = ref([...store.state.farms]);

    const addTask = () => {
      selectTask(defaultTask, 'create');
      // editionMode.value = 'create';
    }

    const editTask = (field, val) => {
      task[field] = val;
    }

    const selectTask = (taskData, editMode) => {
      if (taskData) {
        for (let field in taskData) {
          task[field] = taskData[field]
          selectedTask[field] = taskData[field]
        }
      }
      if (editionMode.value === 'represent') {
        currentSelectedTask.value = `${task.id}`
      }
      editionMode.value = editMode;

      setTimeout(() => {
        document.getElementsByTagName('main')[0].scroll({
          top: taskForm?.value?.offsetTop,
          left: 0,
          behavior: 'smooth'
        })
      })
    }

    const removeTask = (taskId) => {
      store.dispatch('removeTask', taskId)
    };

    const removeRecurringTask = (recurrenceId) => {
      store.dispatch('removeRecurringTask', recurrenceId)
    }

    watch(() => ([...store.state.labors]), (newVal) => {
      users.value = //store.state.users.find(u => u._id === store.state.authenticated._id) ?
        [...newVal] //:
        // [
        //   {
        //     ...store.state.primaryUser,
        //     mainDetails: {
        //       ...store.state.primaryUser.mainDetails,
        //       id: 'primary'
        //     }
        //   },
        //   ...newVal
        // ]

        console.log(users.value, 'users.value');
    })

    watch(() => ([...store.state.farms]), (newVal) => {
      if (newVal) {
        farms.value = [...newVal];
      }
    })

    onMounted(() => {

      users.value = store.state.labors;

      farms.value = store.state.farms;

      crops.value = store.state.crops;

      store.dispatch('getLabors');

      store.dispatch('getFarms');

      store.dispatch('getCrops');

      store.dispatch('getVendors');

      store.dispatch('getVendorItems');

      store.dispatch('getPlannedInventories');

      if (router.currentRoute.value.params.source === 'harvest-module') {
        selectTask({
          ...defaultTask,
          harvestTask: true
        }, 'create');
      }
    });

    provide('taskDetails', {
      task,
      defaultTask,
      selectedTask,
      editionMode,
      addTask,
      editTask,
      selectTask,
      removeTask,
      removeRecurringTask,
      options: bedsOptions,
      optionsTwo: crops,
      currentSelectedTask,
      users,
      farms
    })

    return {
      task,
      editionMode,
      isAuth,
      taskForm
    }
  }
}
</script>

<style></style>