const options = [
  'Accounting',
  'Agriculture',
  'Consulting',
  'Energy',
  'Entertainment',
  'Financial',
  'Foreign Trade',
  'Government',
  'Legal',
  'Manufacturing',
  'Medical',
  'Technology',
  'Transportation',
  'Services',
  'Utilities',
  'Other'
];

export default options;
