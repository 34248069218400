<template>
  <section class="section">
<div class="border-box">
<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Task : {{task.task}}</h5>
<div class="icon-group">
  <div class="icon green icon-o tooltip"
    @click="openCalendar"
    data-tooltip="Calendar View"
  >
    <img src="@/assets/images/updated/calendar-white.png" alt="">
  </div>
  <!-- <div class="icon green icon-ox tooltip"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
    data-tooltip="Camera"
    v-if="isAuth('camera-task')"
  >
    <img src="@/assets/images/updated/camera-white.png" alt="">
  </div> -->
<div class="icon green icon-o tooltip"
  @click="selectTask(task, 'edit');"
  @mouseover="switchEditHelperTooltip(true)"
  @mouseout="switchEditHelperTooltip(false)"
  data-tooltip="Edit Task"
  v-if="isAuth('edit-task')"
>
  <img src="@/assets/images/updated/Edit.png" alt="">
  <!-- <div class="edit-helper-tooltip" v-if="editHelperTooltip">Edit Task</div> -->
</div>
</div>
</div>

<div class="content-block d-lg-flex justify-content-between">
<div class="checkbox order-lg-2 recurringTaskCheckbox"><input type="checkbox" id="recurringTask" :checked="task.recurring" disabled><label for="recurringTask">Recurring Task</label></div>
<div class="checkbox order-lg-2 reminderCheckbox reminder-mobile" :class="{active: task.reminder}">
  <div>
    <input type="checkbox" id="reminderTask" v-model="task.reminder" disabled />
    <label for="reminderTask">
      <span class="reminder-main-label">Reminder</span>
      <span class="reminder-placeholder">Recurring Task</span>
    </label>
  </div>

  <div class="col-6 common" v-if="task.reminder">
    <div class="form-item reminder-list">
      <!-- <select class="form-control" v-model="task.reminderTime" disabled>
        <option
          v-for="reminderOption in reminderOptions"
          v-bind:key="reminderOption + 'reminder-option-task'"
        >
          {{ reminderOption }}
        </option>
      </select> -->
      <DropDownList
        :mainObject="'task'"
        :mainProperty="'reminderTime'"
        :injectionName="'taskDetails'"
        :optionKey="'reminder-option-task'"
        :optionsList="reminderOptions"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="task.reminderTime"
        :isDisabled="true"
        :hiddenArrow="true"
      />
    </div>
  </div>
</div>
<div class="checkbox-group task-category">
<p class="fw-semibold gray">Select Categories:</p>
<div class="checkbox"><input type="checkbox" id="input" :checked="task.inputTask" disabled><label for="input">Input</label></div>
<div class="checkbox"><input type="checkbox" id="harvest" :checked="task.harvestTask" disabled><label for="harvest">Harvest</label></div>
<div class="checkbox"><input type="checkbox" id="harvest" :checked="task.otherTask" disabled><label for="harvest">Other</label></div>
</div>
</div>

<div class="content-block d-lg-flex justify-content-between">
<div class="checkbox order-lg-2 reminderCheckbox reminder-desktop" :class="{active: task.reminder}">
  <div>
    <input type="checkbox" id="reminderTask" v-model="task.reminder" disabled />
    <label for="reminderTask">
      <span class="reminder-main-label">Reminder</span>
      <span class="reminder-placeholder">Recurring Task</span>
    </label>
  </div>

  <div class="col-6 common" v-if="task.reminder">
    <div class="form-item reminder-list">
      <!-- <select class="form-control" v-model="task.reminderTime" disabled>
        <option
          v-for="reminderOption in reminderOptions"
          v-bind:key="reminderOption + 'reminder-option-task'"
        >
          {{ reminderOption }}
        </option>
      </select> -->

      <DropDownList
        :mainObject="'task'"
        :mainProperty="'reminderTime'"
        :injectionName="'taskDetails'"
        :optionKey="'reminder-option-task'"
        :optionsList="reminderOptions"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="task.reminderTime"
        :isDisabled="true"
        :hiddenArrow="true"
      />
    </div>
  </div>
</div>
<!-- <div class="checkbox-group task-type">
<p class="fw-semibold gray">Task Type:</p>
<div class="checkbox regular"><input type="checkbox" id="regularHours" :checked="task.regularHours" disabled><label for="regularHours">Regular</label></div>
<div class="checkbox"><input type="checkbox" id="overTime" :checked="task.overTime" disabled><label for="overTime">Over Time</label></div>
</div> -->
</div>

<div class="content-block">
<div class="intro-grid">

  <div class="intro-grid-column">
  <p class="title">Date</p>
  <h6>{{ task.date }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Start Time</p>
  <h6>{{ task.startTime }}</h6>
  </div>

  <template v-if="task.inputTask || task.harvestTask || task.otherTask">
    <div class="intro-grid-column common">
    <p class="title">Farm</p>
    <h6>{{ task.farm }}</h6>
    </div>

    <div class="intro-grid-column common">
    <p class="title">Bed</p>
    <h6>{{ task.bed }}</h6>
    </div>

    <div class="intro-grid-column common">
    <p class="title">Season</p>
    <h6>{{ task.season }}</h6>
    </div>

    <div class="intro-grid-column common">
    <p class="title">Crop Cycle</p>
    <h6>{{ task.cropCycle }}</h6>
    </div>

    <div class="intro-grid-column common">
    <p class="title">Crop</p>
    <h6>{{ task.crop }}</h6>
    </div>

    <div class="intro-grid-column common">
    <p class="title">Equipment</p>
    <h6>{{ task.equipment }}</h6>
    </div>
  </template>

<div class="intro-grid-column">
<p class="title">Task</p>
<h6>{{ task.task }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Assigned To</p>
<h6>{{ task.assignedTo }}</h6>
</div>

<div class="intro-grid-column" v-if="task.taskType">
<p class="title">Task Type</p>
<h6>{{ task.taskType }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Priority</p>
<h6>{{ task.priority }}</h6>
</div>

<div class="intro-grid-column" v-if="task.taskStatus === 'Completed'">
<p class="title">Completion Time</p>
<h6>{{ (task.completionTime[0] === '0') ? `${task.completionTime.slice(1,task.completionTime.length)}` : task.completionTime }}</h6>
</div>

<div class="intro-grid-column" v-if="task.taskStatus === 'Completed'">
<p class="title">Total Hours</p>
<h6>{{ task.totalHours }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Task Status</p>
<h6>{{ task.taskStatus }}</h6>
</div>

<template v-if="task.inputTask">
<!--  && (task.taskStatus === 'Completed') -->
  <div class="intro-grid-column input-item">
  <p class="title">Input</p>
  <h6>{{ task.input }}</h6>
  </div>

  <div class="intro-grid-column input-item">
  <p class="title">Variety</p>
  <h6>{{ task.variety }}</h6>
  </div>

  <div class="intro-grid-column input-item">
  <p class="title">Brand</p>
  <h6>{{ task.brand }}</h6>
  </div>

  <div class="intro-grid-column input-item">
  <p class="title"># Of Items</p>
  <h6>{{ task.numberOfItems }}</h6>
  </div>

  <div class="intro-grid-column input-item">
  <p class="title">Weight (lbs/item)</p>
  <h6>{{ task.quantity }}</h6>
  </div>
</template>

<template v-if="task.harvestTask && (task.taskStatus === 'Completed')">
  <!-- <div class="intro-grid-column harvest-item">
  <p class="title">Crop Harvested</p>
  <h6>{{ task.cropHarvested }} lbs</h6>
  </div> -->

  <div class="intro-grid-column harvest-item">
  <p class="title">Amt. Harvested (lbs.)</p>
  <h6>{{ task.amtHarvested }} lbs</h6>
  </div>

  <div class="intro-grid-column harvest-item">
  <p class="title">Wastage (lbs.)</p>
  <h6>{{ task.wastage }} lbs</h6>
  </div>

  <div class="intro-grid-column harvest-item">
  <p class="title">Wastage Cause</p>
  <h6>{{ task.wastageCause }}</h6>
  </div>

</template>

<div class="intro-grid-column intro-grid-comment-column">
<p class="title">Comments</p>
<h6>{{ task.comments }}</h6>
</div>

</div>
</div>

<div class="content-block">

<template v-if="!task.recurring">
  <FileAttachments :key="`task-form-${task.id}`" v-bind:filled="true" v-bind:section="'task'" v-bind:instance="`${task.id}`" />
</template>

<template v-else>
  <FileAttachments :key="`task-form-${task.id}`" v-bind:filled="true" v-bind:section="'task'" v-bind:instance="`${task.recurrenceId}`" />  
</template>

<!-- <div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o">Cancel</button></div>
</div>
</div>
</div> -->

</div>

</div>
</div>
</section><!--//End Section-->
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { inject, ref } from 'vue';
import reminderOptions from '@/sharedData/reminderIntervals';
import DropDownList from '@/components/Shared/DropDownList.vue';

export default {
    name: "AddTaskFilled",
    setup() {
      const { isAuth } = inject('mainUI');
      const { task, selectTask, editionMode } = inject('taskDetails');

      const editHelperTooltip = ref(false);
      const helperTooltip = ref(false);

      const openCalendar = () => {
        editionMode.value = 'calendar';
      }

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const switchEditHelperTooltip = (toggle) => {
        editHelperTooltip.value = toggle;
      }
      
      return {
        task,
        reminderOptions,
        selectTask,
        editHelperTooltip,
        helperTooltip,
        switchHelperTooltip,
        switchEditHelperTooltip,
        openCalendar,
        isAuth
      }
    },
    components: { FileAttachments, DropDownList }
}
</script>

<style scoped lang="scss">
.task-type {
  .fw-semibold {
    &.gray {
      margin-right: 5rem;
      margin-left: 6rem;
    }
  }
  .checkbox {
    &.regular {
      margin-right: 3rem;
    }
  }
}
.edit-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 40px;
  left: -30px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 41px;
    background: #3C3838;
    z-index: -1;
  }
}

.reminderCheckbox {
  &.active {
    margin-right: 2rem;
    display: flex;
    align-items: center;
  }
  .col-6 {
    margin-left: 1rem;
  }
}
.reminder-list {
  min-width: 8rem;
}

@media screen and (max-width: 991px) {
  .reminder-mobile {
    display: block;
    &.active {
      display: flex;
      .col-6 {
        max-width: 17rem;
      }
    }
  }
  .reminder-desktop {
    display: none;
    &.active {
      display: none;
    }
  }
}

@media screen and (min-width: 991px) {
  .reminder-mobile {
    display: none;
    &.active {
      display: none;
    }
  }
  .reminder-desktop {
    display: block;
    margin-left: auto;
    &.active {
      display: flex;
    }
  }
}

.reminder-placeholder {
  opacity: 0;
  pointer-events: none;
  color: hsl(var(--dark-gray)) !important;
  font-size: var(--fs-lg);
  font-family: var(--fw-medium);
}
.reminder-main-label {
    position: absolute;
    color: hsl(var(--dark-gray)) !important;
    font-size: var(--fs-lg);
    font-family: var(--fw-medium);
    margin: auto;
    top: 0;
    bottom: 0;
    height: max-content;
}

.task-category .checkbox label, .task-type .checkbox label {
  color: hsl(var(--dark-gray)) !important;
  font-size: var(--fs-lg);
  font-family: var(--fw-medium);
}

.recurringTaskCheckbox label, .reminder-desktop label, .reminder-mobile label {
  color: hsl(var(--dark-gray)) !important;
  font-size: var(--fs-lg);
  font-family: var(--fw-medium);
}
</style>