<template>
  <SuperAdminHeader />
  <section class="section">
    <div class="border-box">
      <SuperAdminList v-if="route.params.pagename === 'super-admin-list'" />
      <SuperAdminArchivedList v-if="route.params.pagename === 'super-admin-archived-list'" />
    </div>
  </section>
</template>

<script>
import { defineComponent, onMounted } from 'vue';
import SuperAdminList from './SuperAdminList.vue';
import SuperAdminHeader from './SuperAdminHeader.vue';
import { useRoute } from 'vue-router';
import SuperAdminArchivedList from './SuperAdminArchivedList.vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: "SuperAdmin",
    setup() {
      const route = useRoute();
      const store = useStore();

      onMounted(() => {
        const params = new URLSearchParams(document.location.search);
        // const user_id = params.get("user_id");
        const token = params.get("token");

        if (token) { // && user_id
          store.dispatch('authenticateSuperUser', {
            // user_id,
            superToken: token
          })
        }
      })

      return {
        route
      }
    },
    components: { SuperAdminList, SuperAdminHeader, SuperAdminArchivedList }
})
</script>