<template>
  <AdminHeader />
  <section class="section mt-1">
    <div class="border-box">

      <AdminAccountMainForm v-if="(mainEditionMode === 'create') || (mainEditionMode === 'edit')" />
      <AdminAccountMainFilled v-else />

      <div ref="subscriptionSection"></div>
      <AdminAccountSubscriptionForm v-if="(subscriptionEditionMode === 'create') || (subscriptionEditionMode === 'edit')" />
      <AdminAccountSubscriptionFilled v-else />

      <div style="display: flex; flex-direction: column;">
        <router-link :to="{ name: 'page', params: { pagename: 'user-profile' } }">
          <span>User Profile</span>
        </router-link>
        <router-link :to="{ name: 'page', params: { pagename: 'secondary-user' } }">
          <span>Secondary User Profile</span>
        </router-link>
        <router-link :to="{ name: 'page', params: { pagename: 'search'} }">
          <span>Search</span>
        </router-link>
      </div>
    </div>
  </section><!--//End Section--><!--//End section-->
</template>

<script>
import { onMounted, provide, reactive, ref } from 'vue';
import AdminAccountMainFilled from './AdminAccountMainFilled.vue';
import AdminAccountMainForm from './AdminAccountMainForm.vue';
import AdminAccountSubscriptionFilled from './AdminAccountSubscriptionFilled.vue';
import AdminAccountSubscriptionForm from './AdminAccountSubscriptionForm.vue';
import AdminHeader from './AdminHeader.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: "AdminAccount",
  components: { 
    AdminAccountMainForm, 
    AdminAccountSubscriptionForm, 
    AdminHeader, 
    AdminAccountMainFilled, 
    AdminAccountSubscriptionFilled 
  },
  setup() {

    const store = useStore();

    const router = useRouter();

    const mainEditionMode = ref('create');

    const subscriptionEditionMode = ref('create');

    const subscriptionSection = ref(null);

    const mainDetails = reactive({
      email: '',
      password: '',
      accountName: '',

      title: '',
      
      // email: '',
      multifactor: false,
      multifactorType: '',
      userRole: 'admin',

      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      mobilePhone: '',
      workPhone: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      employeeType: '',
      grade: '',
    })

    const defaultMainDetails = {
      ...mainDetails
    }

    const subscription = reactive({
      plans: {
        trial: false,
        trialPrice: 'price_1O5VljHpD3HYJIrgI5y2Jhkq',
        
        basic: false,
        basicPrice: '',
        basicNumber: '',
        basicPriceIdYearly: 'price_1NXMRQHpD3HYJIrg2IwC8sri',
        basicPriceIdMonthly: 'price_1NXMNxHpD3HYJIrg0hyDtIQO',
        basicAvailable: '',

        // inventory: false,
        // inventoryPrice: '',
        // inventoryNumber: '',
        plus: false,
        plusPrice: '',
        plusNumber: '',
        plusPriceIdYearly: 'price_1NXMQoHpD3HYJIrgJaWbqhOI',
        plusPriceIdMonthly: 'price_1NXMQoHpD3HYJIrgnhtzXnIF',
        plusAvailable: '',
        
        // equipment: false,
        // equipmentPrice: '',
        // equipmentNumber: '',

        advance: false,
        advancePrice: '',
        advanceNumber: '',
        advancePriceIdYearly: 'price_1NXMhHHpD3HYJIrgUwLCM3C2',
        advancePriceIdMonthly: 'price_1NXMhHHpD3HYJIrgrxWzr1UH',
        advanceAvailable: '',
        
        // allinclusive: false,
        // allinclusivePrice: '',
        // allinclusiveNumber: '',

        ultimate: false,
        ultimatePrice: '',
        ultimateNumber: '',
        ultimatePriceIdYearly: 'price_1NXMklHpD3HYJIrgUKaTXGU9',
        ultimatePriceIdMonthly: 'price_1NXMklHpD3HYJIrgbrsDHSiM',
        ultimateAvailable: '',

      },
      
      subscriptionPeriod: 'Monthly',
      
      totalSubscription: '',
      taxes: '',
      discount: '',
      total: '',

      planPurchasingDate: store.state.authenticated.registrationDate,
      subscriptionsNumber: 0, // remove later
    })

    const defaultSubscription = {
      ...subscription,
      plans: {
        ...subscription.plans
      }
    }

    const stateSubscription = reactive({
      ...store.state.primaryUser.subscription,
      plans: {
        ...store.state.primaryUser?.subscription?.plans
      }
    })

    const assigned = reactive({
      trial: false,
      basic: false,
      plus: false,
      advance: false,
      ultimate: false
    })

    const changeMainField = (val, field) => {
      mainDetails[field] = val;
    }

    const selectMainDetails = (mainDetailsData, editMode) => {
      if (mainDetailsData) {
        for (let field in mainDetailsData) {
          mainDetails[field] = mainDetailsData[field]
        }
      }
      mainEditionMode.value = editMode;
    }

    const selectSubscription = (subscriptionData, editMode) => {
      if (subscriptionData) {
        for (let field in subscriptionData) {
          subscription[field] = subscriptionData[field]
        }
      }
      subscriptionEditionMode.value = editMode;
    }

    const changeSubscriptionField = (val, field, index) => {
      if (index) {
        subscription.modules[index].added = val;
      } else {
        subscription[field] = val;
      }
    }

    const createNewUser = () => {
      router.push({ name: 'page', params: { pagename: 'secondary-user' } })
    }

    provide('mainDetails', {
      mainEditionMode,
      mainDetails,
      defaultMainDetails,
      selectMainDetails,
      createNewUser,
      changeMainField
    })

    provide('subscription', {
      subscriptionEditionMode,
      subscription,
      defaultSubscription,
      stateSubscription,
      assigned,
      selectSubscription,
      changeSubscriptionField
    })

    onMounted(() => {
      if (
        store.state.primaryUser.mainDetails?.email ||
        (store.state.primaryUser.mainDetails?.firstName && 
        store.state.primaryUser.mainDetails?.lastName)
      ) mainEditionMode.value = 'represent';
      if (store.state.primaryUser.subscription) subscriptionEditionMode.value = 'represent';

      if (router.currentRoute.value.params.boughtForAdmin) {
        subscriptionEditionMode.value = 'edit';

        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: subscriptionSection?.value?.offsetTop - 100,
            left: 0,
            behavior: 'smooth'
          })
        })
      }

      if (router.currentRoute.value.params.source === 'employee-compensation') {
        mainEditionMode.value = 'edit';
      } 
    })

    return {
      mainEditionMode,
      subscriptionEditionMode,
      subscriptionSection
    }
  },
};
</script>

<style></style>