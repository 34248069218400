import getUserData from "@/sharedData/authRequest";
import userRoles from "@/sharedData/userRoles";
import moment from "moment";
import { v4 as uuid } from 'uuid';
import isAuthorized from "@/components/Shared/authCheckHook";
import { 
  // postOptions, 
  // patchOptions,
  // deleteOptions,
  patchOptionsAuthFiles,
  getOptionsAuth,
  patchOptionsAuth,
  postOptionsAuth,
  deleteOptionsAuth,
  postOptionsAuthFiles,
  postOptionsAuthGetFiles,

  patchOptionsAuthEmptyAttachments,

  postOptionsAuthResources,
  patchOptionsAuthResources
} from './utils'

const api = `${process.env.VUE_APP_API_URL}/api`

const dataFetch = async (url, options) => {
  const response = await fetch(url, options || {});
  console.log(response.status, response, 'response.status');
  const extracted = await response.json();

  if (response.status === 401) {
    extracted.status = 401;
    throw extracted;
  }
  
  console.log(extracted);


  return extracted;
}

const dataFetchFiles = async (url, options) => {
  const response = await fetch(url, options || {});
  console.log(response.status, response, 'response.status');
  const extracted = response.ok ? await response.blob() : await Promise.reject(response);
  
  if (response.status === 401) {
    extracted.status = 401;
    throw extracted;
  }
  
  console.log(extracted);


  return extracted;
}

// dataFetch(api).then(initData => {
//   commit('setValues', initData)
// }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})

// Object.keys({...state.updatingEntity}).length > 0 ? 
      
//         dataFetch(`${api}${updatedEntity.id}`, putOptions(updatedEntity))
//           .then(initData => {
//             console.log(initData)
//             commit('setValues', updatedList)
//           }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }}) :

//         dataFetch(api, postOptions({
//           ...updatedEntity, 
//           user: state.user,
//           dateTime: `${new Date()}`
//         }))
//           .then(initData => {
//             console.log(initData)
//             commit('setValues', updatedList)
//           }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})

// dataFetch(`${api}${selectedID}`, deleteOptions()).then(initData => {
//   console.log(initData)
//   commit('setValues', updatedList)
// }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})


// initial state
const state = () => ({
  authenticated: {
    token: '',
    role: {
      id: '',
      key: 'admin',//'readonly',
      permissions: [
        // {
        //   id: '0',
        //   key: 'all-access'
        // },
        // {
        //   id: '2',
        //   key: 'create-account'
        // }
      ]
    }, // switch to owner to see other form
    userRole: 'admin',//'readonly',
    registrationDate: new Date(),//null
    accountSubdomain: '',
    // paymentMethods: []
    paymentMethods: [],
    selectedPayment: null,
    approvedPayment: false,
    author: 'James Smith'
  },
  currentPath: [],
  primaryUser: {
    payment: {},
    billing: []
  },
  primaryUserTenant: {},
  subscriptionPlan: {},
  savedAdminSubscription: {},
  secondaryUser: {
    payment: {},
    subscriptionAssigned: {}
  },
  temporaryUser: {},
  farms: [],
  // currentFarm: {},
  farmDetails: {
    farmName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    longtitude: '',
    latitude: '',
    acreage: '',
    soilComposition: '',
    irrigationType: '',
    climaticZone: '',
    fencing: '',
    IOTdeviceType: '',
    primaryCrop: '',
    secondaryCrop: '',
    drawMap: []
  },
  advertisementAccount: {},
  crops: [],
  fileUploads: {},
  attachments: {
    primary: {
      video: [],
      image: [],
      document: []
    },
    secondary: {
      video: [],
      image: [],
      document: []
    },
    farm: {
      video: [],
      image: [],
      document: []
    },
    task: {
      video: [],
      image: [],
      document: []
    },
    inventory: {
      video: [],
      image: [],
      document: []
    },
    planInventory: {
      video: [],
      image: [],
      document: []
    },
    vendor: {
      video: [],
      image: [],
      document: []
    },
    vendorItem: {
      video: [],
      image: [],
      document: []
    },
    equipment: {
      video: [],
      image: [],
      document: []
    },
    services: {
      video: [],
      image: [],
      document: []
    },
    harvest: {
      video: [],
      image: [],
      document: []
    },
    adminAccount: {
      video: [],
      image: [],
      document: []  
    },
    adminUsers: {
      video: [],
      image: [],
      document: []  
    },
    sales: {
      video: [],
      image: [],
      document: []  
    },
    customer: {
      video: [],
      image: [],
      document: []
    },
    laborExpense: {
      video: [],
      image: [],
      document: []
    },
    laborModule: {
      video: [],
      image: [],
      document: []
    },
    adminExpense: {
      video: [],
      image: [],
      document: []
    },
    laborIncomes: {
      video: [],
      image: [],
      document: []
    },
    employeeCompensation: {
      video: [],
      image: [],
      document: []  
    },
    advertisement: {
      video: [],
      image: [],
      document: []  
    },
    contact: {
      video: [],
      image: [],
      document: []
    }
  },
  selectedAttachedFile: {
    file: null,
    fileType: '',
    fileId: ''
  },
  tasks: [],
  addedInventories: [],
  inventoriesUsed: [],
  vendors: [],

  // vendorInventories: [],
  // vendorEquipments: [],
  // vendorServices: [],
  vendorItems: [],
  plannedInventories: [],
  
  equipments: [],
  geoLocationCoords: {},
  users: [],
  archivedUsers: [],
  labors: [],
  harvests: [],
  sales: [],
  customers: [],
  laborExpenses: [],
  adminExpenses: [],
  compensations: [],
  searchResults: [],
  accounts: [
    // {
    //   id: '0123-4567',
    //   company: 'company',
    //   contact: 'contact',
    //   workPhone: 'workPhone',
    //   email: 'email',
    //   state: 'state',
    //   country: 'country',
    //   status: 'active'
    // },
    // {
    //   id: '0987-6543',
    //   company: 'company',
    //   contact: 'contact',
    //   workPhone: 'workPhone',
    //   email: 'email',
    //   state: 'state',
    //   country: 'country',
    //   status: 'paused'
    // }
  ],
  // archivedAccounts: [],
  notifications: [],
  advertisementGeographies: [],
  advertisementPayment: {},
  advertisementsList: [
    {
      campaignTitle: 'Lorem Ipsum is simply',
      text: 'Lorem Ipsum is simply dummy text of the printing and'
    },
    {
      campaignTitle: 'Lorem Ipsum is simply',
      text: 'Lorem Ipsum is simply dummy text of the printing and',
    },
    {
      campaignTitle: 'Lorem Ipsum is simply',
      text: 'Lorem Ipsum is simply dummy text of the printing and',
    },
    {
      campaignTitle: 'Lorem Ipsum is simply',
      text: 'Lorem Ipsum is simply dummy text of the printing and',
    },
    {
      campaignTitle: 'Lorem Ipsum is simply',
      text: 'Lorem Ipsum is simply dummy text of the printing and',
    },
    {
      campaignTitle: 'Lorem Ipsum is simply',
      text: 'Lorem Ipsum is simply dummy text of the printing and',
    }
  ],
  myAdvertisementsList: [],
  archiveFarmPlans: [],
  futurePayment: {},
  primaryPaymentIsChanged: false,
  adminPaymentIsChanged: false,
  contactMessageIsSent: false,
  resetPasswordStatus: false,
  resources: [],
  selectedResourceFile: {
    file: null,
    fileType: '',
    fileId: ''
  }
});

// const isAuth = (featureCode) => {
//   return isAuthorized(state.authenticated, featureCode)
// }

// getters
const getters = {};

// actions
const actions = {
  registerUser({commit}, loginForm) {
    // make request
    // add registrationDate to authenticated object
    console.log(commit, loginForm);
  },
  async loginUser({commit}, loginForm) {
    // make request
    console.log(loginForm);
    const response = await getUserData(loginForm)

    console.log(response);

    const field = 'registrationDate';
    commit('setAuthenticatedData', {field, val: new Date()}) // take real date from response
  },
  authenticateUser({commit, state}, data) { //, dispatch
    
    const user_id = data.user_id || data._id || state.authenticated._id;

    if (user_id) {
      dataFetch(`${api}/users/${user_id}`, getOptionsAuth({
        token: data.token || state.authenticated.token
      }))
        .then(resData => {
          console.log(resData);
          const authenticatedUser = {...resData, ...data};
          for (let prop in authenticatedUser) {
            if ((prop === 'mainDetails') || (prop === 'subscription') || 
              (prop === 'payment') || (prop === 'billing') || (prop === 'subscriptionAssigned') || (prop === 'tenantId')) {
              // if (resData.accessRights.length > 1) {
              if (authenticatedUser[prop]?.submitted || (authenticatedUser[prop]?.length > 0)) {
                if (data.secondary || !resData.primary) {
                  commit('setSecondaryUserData', {section: prop, data: authenticatedUser[prop]});
                } else {
                  commit('setPrimaryUserData', {section: prop, data: authenticatedUser[prop]});
                }
              }

              if (prop === 'tenantId') {
                // no tenantId
                console.log(prop, authenticatedUser[prop]);
                // commit('setPrimaryUserTenant', {id: authenticatedUser[prop]?.id});
                commit('setPrimaryUserTenant', {resData: {id: authenticatedUser[prop]}});
              }
              // } else {
              //   commit('setSecondaryUserData', {section: prop, data: authenticatedUser[prop]});
              // }
              if (prop === 'mainDetails') {
                for (let subProp in authenticatedUser[prop]) {
                  if (subProp === 'userRole') {
                    commit('setAuthenticatedData', {field: subProp, val: authenticatedUser[prop][subProp]});
                  }
                }
              }
            } else if ((prop === 'image') || (prop === 'video') || (prop === 'document')) {
              if (data.secondary || !resData.primary) {
                // state.attachments.secondary[prop] = authenticatedUser[prop]
                commit('setServerAttachments', {
                  attachmentType: prop,
                  attachmentSection: 'secondary',
                  attachmentData: authenticatedUser[prop]
                })
              } else {
                // state.attachments.primary[prop] = authenticatedUser[prop]
                commit('setServerAttachments', {
                  attachmentType: prop,
                  attachmentSection: 'primary',
                  attachmentData: authenticatedUser[prop]
                })
              }
            } else {
              const field = `${prop}`;
              if (state.authenticated.role.key === 'super_admin') {
                authenticatedUser.role.key = 'super_admin';
                authenticatedUser.superToken = `${state.authenticated.superToken}`;
              }
              commit('setAuthenticatedData', {field, val: authenticatedUser[prop]});
            }
          }
        }).catch(e => {
          console.log(e); 
          // if (e.status === 401) { 
          //   dispatch('logOutApi') 
          // }
        });
    }
  },
  getAttachments({commit, state}) { //, dispatch
    
    const user_id = state.authenticated._id;

    if (user_id) {
      dataFetch(`${api}/users/${user_id}`, getOptionsAuth({
        token: state.authenticated.token
      }))
        .then(resData => {
          console.log(resData);
          const authenticatedUser = {...resData};
          for (let prop in authenticatedUser) {
            if ((prop === 'image') || (prop === 'video') || (prop === 'document')) {
              if (!resData.primary) {
                // state.attachments.secondary[prop] = authenticatedUser[prop]
                commit('setServerAttachments', {
                  attachmentType: prop,
                  attachmentSection: 'secondary',
                  attachmentData: authenticatedUser[prop],
                  attachmentInstance: `${resData.id || resData.mainDetails.id}`
                })
              } else {
                // state.attachments.primary[prop] = authenticatedUser[prop]
                commit('setServerAttachments', {
                  attachmentType: prop,
                  attachmentSection: 'primary',
                  attachmentData: authenticatedUser[prop]
                })
              }
            }
          }
        }).catch(e => {
          console.log(e); 
          // if (e.status === 401) { 
          //   dispatch('logOutApi') 
          // }
        });
    }
  },
  openAttachedFile({commit, state}, {fileType, fileName, section, instance}) {
    const fileObjectState = instance ? 
      state.attachments[`${section}${instance}`][fileType].find(file => file.fileName === fileName) :
      state.attachments[`${section}`][fileType].find(file => file.fileName === fileName);

    const fileState = fileObjectState ? fileObjectState?.file : null;

    if (fileState && (fileType !== 'document')) {
      commit('setSelectedAttachedFile', {file: fileState, fileType});
    } else {

      if (fileType === 'document') {
        dataFetch(`${api}/attachments`, postOptionsAuthGetFiles({
          fileType, fileName, section, instance,
          token: state.authenticated.token
        }))
          // .then(resData => (resData.ok ? resData.blob() : Promise.reject(resData)))
          .then(resData => {
            console.log(resData);

            commit('setSelectedAttachedFile', {file: resData.file, fileType, fileId: resData.fileId});
          }).catch(e => {
            console.log(e); 
            // if (e.status === 401) { 
            //   dispatch('logOutApi') 
            // }
          });

      } else {
        dataFetchFiles(`${api}/attachments`, postOptionsAuthGetFiles({
          fileType, fileName, section, instance,
          token: state.authenticated.token
        }))
          // .then(resData => (resData.ok ? resData.blob() : Promise.reject(resData)))
          .then(blob => {
            console.log(blob);
            const blobUrl = URL.createObjectURL(blob);
  
            commit('setSelectedAttachedFile', {file: blobUrl, fileType});
          }).catch(e => {
            console.log(e); 
            // if (e.status === 401) { 
            //   dispatch('logOutApi') 
            // }
          });
      }

    }

  },
  closeAttachedFile({commit, state}) {
    if (state.selectedAttachedFile.fileId.length > 0) {
      dataFetch(`${api}/attachments/close`, postOptionsAuthGetFiles({
        fileId: state.selectedAttachedFile.fileId,
        token: state.authenticated.token
      }))
        // .then(resData => (resData.ok ? resData.blob() : Promise.reject(resData)))
        .then(resData => {
          console.log(resData);

          commit('setSelectedAttachedFile', {
            file: null,
            fileType: '',
            fileId: ''
          });

        }).catch(e => {
          console.log(e); 
          // if (e.status === 401) { 
          //   dispatch('logOutApi') 
          // }
        });
    } else {
      commit('setSelectedAttachedFile', {
        file: null,
        fileType: '',
        fileId: ''
      });
    }
  },
  authenticateSuperUser({commit}, data) {
    commit('setAuthenticatedData', {field: 'superToken', val: data.superToken});
  },
  logOut({ commit }) {
    commit('logOut')
  },
  logOutApi({ commit }) {
    commit('logOutApi')
  },
  addCurrentPath({commit}, pathData) {
    commit('setCurrentPath', pathData)
  },
  removeAccount({commit, state}, paymentIndex) {
    // make request
    const payments = state.authenticated.paymentMethods.filter((pm, indx) => indx !== paymentIndex);
    console.log(payments);
    commit('setPaymentMethods', payments);
  },
  savePaymentMethod({commit}, {payment, selectedPayment}) {
    // make request
    console.log(payment, selectedPayment);
    commit('setPaymentMethod', {payment: {...payment}, selectedPayment: selectedPayment})
  },
  savePrimaryUser({commit, state, dispatch}, {section, data}) {
    // make request
    const formattedData = {
      data: JSON.stringify(data),
      section,
      token: state.authenticated.token
    }
    if (state.attachments.primary.video.length > 0) {
      formattedData.video = [...state.attachments.primary.video];
    }
    if (state.attachments.primary.image.length > 0) {
      formattedData.image = [...state.attachments.primary.image];
    }
    if (state.attachments.primary.document.length > 0) {
      formattedData.document = [...state.attachments.primary.document];
    }
    console.log(formattedData);
    dataFetch(`${api}/users/${state.authenticated.user_id || state.authenticated._id}`, patchOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData);
      commit('setPrimaryUserData', {section, data: resData[section]});

      if (section === 'mainDetails') {
        dispatch('authenticateUser', {
          ...resData
        })
        state.primaryUserTenant.id ?
          dispatch('updateTenant', {
            data: {
              ...data
            },
            section
          }) :
          dispatch('createTenant', {
            data: {
              ...data
            },
            section
          });
      }
      if (section === 'subscriptionAssigned') {
        dispatch('authenticateUser', {
          ...resData
        })
      }
    }).catch(e => {
      console.log(e); 
      // if (e.status === 401) { 
      //   dispatch('logOutApi') 
      // }
    });
    // make request

    // commit('setPrimaryUserData', {section, data});
  },
  saveTemporaryUser({commit}, {section, data}) {
    // make request
    commit('setTemporaryUserData', {section, data});
  },
  createTenant({commit, state, dispatch}, {data}) {

    dataFetch(`${api}/customers`, postOptionsAuth({//postOptions({
      ...data,
      token: state.authenticated.token
    })).then(resData => {
      console.log(resData);
      commit('setPrimaryUserTenant', {resData});
      dispatch('savePrimaryUser', {section: 'tenantId', data: {id: resData.id}})
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  updateTenant({commit, state, dispatch}, {data, section}) {
    if (section) {
      dataFetch(`${api}/customers/${state.primaryUserTenant.id}`, patchOptionsAuth({//patchOptions({
        [section]: {...data},
        section,
        token: state.authenticated.token
      })).then(resData => {
        console.log(resData, 'updateTenant response');
        commit('setPrimaryUserTenant', {resData});
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
    } else {
      dataFetch(`${api}/customers/${state.primaryUserTenant.id}`, patchOptionsAuth({//patchOptions({
        ...data,
        token: state.authenticated.token
      })).then(resData => {
        console.log(resData, 'updateTenant response no section');
        commit('setPrimaryUserTenant', {resData});
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
    }
  },
  createSubscription({commit, state, dispatch}, {data}) {
    console.log(state.primaryUserTenant, data, 'create subscrtipiton');
    dataFetch(`${api}/subscriptions`, postOptionsAuth({//postOptions({
      ...data,
      customerId: state.primaryUserTenant.id,
      token: state.authenticated.token
    })).then(resData => {
      console.log(resData, 'createSubscription');
      dispatch('addMultipleAvailableSubscriptions', data);
      commit('setSubscriptionPlan', {resData});
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
  },
  updateSubscription({commit, state, dispatch}, {data}) {
    dataFetch(`${api}/subscriptions/${state.subscriptionPlan.subscriptionId}`, patchOptionsAuth({//patchOptions({
      ...data,
      customerId: state.primaryUserTenant.id,
      subscriptionItemId: state.subscriptionPlan.subscriptionItemId,
      token: state.authenticated.token
    })).then(resData => {
      console.log(resData, 'updateSubscription');
      dispatch('addMultipleAvailableSubscriptions', data);
      commit('setSubscriptionPlanChanges', {resData});
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
  },
  sendContactForm({commit, state, dispatch}, messageData) {
    console.log(messageData);

    const formattedData = {
      data: JSON.stringify(messageData),
    }
    if (state.attachments.contact.video.length > 0) {
      formattedData.video = [...state.attachments.contact.video];
    }
    if (state.attachments.contact.image.length > 0) {
      formattedData.image = [...state.attachments.contact.image];
    }
    if (state.attachments.contact.document.length > 0) {
      formattedData.document = [...state.attachments.contact.document];
    }

    dataFetch(`${api}/contacts`, postOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData, 'sendContactForm');
      commit('setContactMessageStatus', true);
      setTimeout(() => {
        commit('setContactMessageStatus', false);
      }, 5100);
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
  },
  resetPassword({ commit, dispatch }, resetData) {
    dataFetch(`${api}/users/verify`, postOptionsAuth({
      ...resetData
    })).then(resData => {
      console.log(resData, 'resetPassword');
      commit('setResetPasswordStatus', true);
      setTimeout(() => {
        commit('setResetPasswordStatus', false);
      }, 5100);
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
  },
  addMultipleAvailableSubscriptions({commit}, data) {
    const assignedSubscriptions = {};

    for (let plan in data.plans) {
      if (data.plans[plan] === true) {
        assignedSubscriptions[plan] = data.plans[`${plan}Number`];
      }
    }
    console.log('addMultipleAvailableSubscriptions', assignedSubscriptions);
    commit('setMultipleAvailableSubscriptions', assignedSubscriptions);
  },
  selectSubscription({commit, state}, {data}) {
    commit('setSavedAdminSubscription', {
      ...data,
      customerId: state.primaryUserTenant.id
    });
  },
  selectPaymentMethod({commit, state}, paymentMethod) {
    commit('setSavedAdminSubscription', {
      ...state.savedAdminSubscription,
      customerId: state.primaryUserTenant.id,
      paymentMethod
    });
  },
  assignSubscription({commit}, {assignedPlan, unassignedPlan}) {
    commit('setAssignedSubscription', {assignedPlan, unassignedPlan});
  },
  assignSubscriptionToUser({ state, dispatch}, {assignedPlan, userId}) {//commit,
    // commit('setAssignedSubscriptionToUser', {assignedPlan, userId});

    const updatedSubscription = {
      ...state.primaryUser.subscription,
      plans: {
        ...state.primaryUser.subscription.plans
      }
    }

    const currentUser = userId === 'primary' ?
      state.primaryUser :
      state.users.find(user => user.mainDetails.id === userId);

    let unassignedPlan;

    if (currentUser.subscriptionAssigned) {
      unassignedPlan = {...currentUser.subscriptionAssigned};
    }

    for (let plan in state.primaryUser.subscription.plans) {
      
      if ((state.primaryUser.subscription.plans[plan] === assignedPlan[plan]) && assignedPlan[plan] === true) {
        updatedSubscription.plans[`${plan}Available`] = Number.parseInt(state.primaryUser.subscription.plans[`${plan}Available`] || 0) - 1;
      }

      if (unassignedPlan) {
        if ((state.primaryUser.subscription.plans[plan] === unassignedPlan[plan]) && unassignedPlan[plan] === true) {
          updatedSubscription.plans[`${plan}Available`] = Number.parseInt(state.primaryUser.subscription.plans[`${plan}Available`] || 0) + 1;
        }
      }
    }

    console.log(updatedSubscription, 'updatedSubscription');

    dispatch('savePrimaryUser', {
      section: 'subscription',
      data: updatedSubscription
    })

    /*
    setTimeout(() => {
      const updatedSubscription = {
        ...state.primaryUser.subscription,
        plans: {
          ...state.primaryUser.subscription.plans
        }
      }

      console.log(updatedSubscription, 'updatedSubscription');

      dispatch('savePrimaryUser', {
        section: 'subscription',
        data: updatedSubscription
      })

      // store.dispatch('changeNewUser', {
      //   userId: mainDetails.id,
      //   section: 'subscriptionAssigned',
      //   data: { ...assignedPlan }
      // });
    }, 100)
    */
  },
  removeOneSubscription({state, dispatch}, removedType) { //commit, 

    const updatedSubscription = {
      ...state.primaryUser.subscription,
      plans: {
        ...state.primaryUser.subscription.plans
      }
    }

    updatedSubscription.plans[`${removedType}Number`] = Number.parseInt(state.primaryUser.subscription.plans[`${removedType}Number`]) - 1;
    updatedSubscription.plans[`${removedType}Available`] = Number.parseInt(state.primaryUser.subscription.plans[`${removedType}Available`] || 0) - 1; 
    if (Number.parseInt(state.primaryUser.subscription.plans[`${removedType}Number`]) === 0) {
      updatedSubscription.plans[removedType] = false;
    }

    dispatch('savePrimaryUser', {
      section: 'subscription',
      data: updatedSubscription
    })

    /*
    commit('setRemovedSubscription', removedType);
    setTimeout(() => {
      const updatedSubscription = {
        ...state.primaryUser.subscription,
        plans: {
          ...state.primaryUser.subscription.plans
        }
      }
      dispatch('savePrimaryUser', {
        section: 'subscription',
        data: updatedSubscription
      })
    }, 100)
    */
  },
  updateSubscriptionTotals({commit}, totalData) {
    commit('setUpdatedSubscriptionTotals', totalData);
  },
  addOneSubscription({state, dispatch}, addedType) { // commit, 

    const updatedSubscription = {
      ...state.primaryUser.subscription,
      plans: {
        ...state.primaryUser.subscription.plans
      }
    }

    updatedSubscription.plans[`${addedType}Available`] = Number.parseInt(state.primaryUser.subscription.plans[`${addedType}Available`] || 0) + 1;

    dispatch('savePrimaryUser', {
      section: 'subscription',
      data: updatedSubscription
    })

    // commit('setAddedSubscription', addedType);
    // setTimeout(() => {
    //   const updatedSubscription = {
    //     ...state.primaryUser.subscription,
    //     plans: {
    //       ...state.primaryUser.subscription.plans
    //     }
    //   }
    //   dispatch('savePrimaryUser', {
    //     section: 'subscription',
    //     data: updatedSubscription
    //   })
    // }, 100)
  },
  saveSecondaryUser({commit, state, dispatch}, {section, data, editedByAdmin}) {

    const formattedData = {
      data: JSON.stringify({...data, adminId: state.authenticated._id}),
      section,
      token: state.authenticated.token
    }
    // if (state.attachments.secondary.video.length > 0) {
    //   formattedData.video = [...state.attachments.secondary.video];
    // }
    // if (state.attachments.secondary.image.length > 0) {
    //   formattedData.image = [...state.attachments.secondary.image];
    // }
    // if (state.attachments.secondary.document.length > 0) {
    //   formattedData.document = [...state.attachments.secondary.document];
    // }

    if (section === 'mainDetails') {
      if (state.attachments[`secondary${data.id}`]) {
        if (state.attachments[`secondary${data.id}`].video.length > 0) {
          formattedData.video = [...state.attachments[`secondary${data.id}`].video];
        }
        if (state.attachments[`secondary${data.id}`].image.length > 0) {
          formattedData.image = [...state.attachments[`secondary${data.id}`].image];
        }
        if (state.attachments[`secondary${data.id}`].document.length > 0) {
          formattedData.document = [...state.attachments[`secondary${data.id}`].document];
        }
      }
    }

    console.log(formattedData);

    if (editedByAdmin && !data.password) {
      if (section === 'mainDetails') {
        dataFetch(`${api}/users`, postOptionsAuthFiles({
          ...formattedData,
        })).then(resData => {
          console.log(resData);
          // commit('setSecondaryUserData', {section, data: resData[section]});
          for (let prop in resData) {
            commit('setSecondaryUserData', {section: prop, data: resData[prop]});
          }

          dispatch("saveNewUser", {
            section: "mainDetails",
            data: {
              ...resData[section],
            }
          });

          dispatch('getUsersList');
        }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
      }
    } else {
      console.log('entering to admins part');
      dataFetch(`${api}/users/${state.authenticated.user_id || state.authenticated._id}`, patchOptionsAuthFiles({
        ...formattedData,
      })).then(resData => {
        console.log(resData);
        // commit('setSecondaryUserData', {section, data: resData[section]});
        for (let prop in resData) {
          console.log('sec-happening-in-saveSecondaryUser2');
          commit('setSecondaryUserData', {section: prop, data: resData[prop]});
        }

        if (section === 'mainDetails') {
          dispatch('authenticateUser', {
            ...resData
          })
        }

        if (section === 'subscriptionAssigned') {
          setTimeout(() => {
            dispatch('clearSecondaryUser');
          }, 1000);
        }

        dispatch('getUsersList');
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
    }
  },
  saveSecondaryUserSubscription({commit, state, dispatch}, {section, data}) {

    const formattedData = {
      data: JSON.stringify({...data, adminId: state.authenticated._id}),
      section,
      token: state.authenticated.token
    }

    console.log(state.secondaryUser, 'state.secondaryUser');
    if (state.secondaryUser._id) {
      dataFetch(`${api}/users/${state.secondaryUser._id}`, patchOptionsAuthFiles({
        ...formattedData,
      })).then(resData => {
        console.log(resData);
        // commit('setSecondaryUserData', {section, data: resData[section]});
        for (let prop in resData) {
          commit('setSecondaryUserData', {section: prop, data: resData[prop]});
        }

        setTimeout(() => {
          dispatch('clearSecondaryUser');
        }, 1000);
        
        dispatch('getUsersList');
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
    }
  },
  saveAdvertisementAccount({commit}, {section, data}) {
    // make request
    commit('setAdvertisementAccountData', {section, data});
  },
  createPayment({commit, state, dispatch}, {data}) {
    console.log(state.primaryUserTenant, data, 'create payment');
    dataFetch(`${api}/payments`, postOptionsAuth({//postOptions({
      ...data,
      customerId: state.primaryUserTenant.id,
      token: state.authenticated.token
    })).then(resData => {
      console.log(resData);
      commit('setNewPayment', {resData});
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
  },
  createFuturePayment({commit, state, dispatch}) {
    dataFetch(`${api}/payments/future`, postOptionsAuth({//postOptions({
      customerId: state.primaryUserTenant.id,
      // items: [
      //   {
      //     price: 'price_1O5VljHpD3HYJIrgI5y2Jhkq',
      //     quantity: 1
      //   }
      // ]
      token: state.authenticated.token
    })).then(resData => {
      console.log(resData);
      commit('setFuturePayment', {resData});
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
  },
  clearFuturePayment({commit}) {
    commit('setFuturePayment', {});
  },
  changePrimaryPayment({commit}, {isChanged}) {
    commit('setPrimaryPaymentIsChanged', {isChanged})
  },
  changeAdminPayment({commit}, {isChanged}) {
    commit('setAdminPaymentIsChanged', {isChanged})
  },
  getPaymentMethodsList({commit, state, dispatch}) {

    console.log(commit, state);
    // const currentPayments = [...state.authenticated.paymentMethods];

    // setTimeout(() => {
    //   commit('setPaymentMethods', [...currentPayments, ...toBeAdded])
    // }, 10000)
    dataFetch(`${api}/customers/${state.primaryUserTenant.id}/payments`, getOptionsAuth({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setPaymentMethods', [...resData.data]);
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
  },
  changePaymentMethodDetails({ dispatch, state }, {data}) { //, state, commit, 
    dataFetch(`${api}/payments/${data.id}`, postOptionsAuth({//postOptions({
      name: data.cardHolderName,
      expiryDate: data.expiryDate,
      zip: data.zip,
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        // commit('setPaymentMethods', [...resData.data]);
        dispatch('getPaymentMethodsList')
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
  },
  removePaymentMethod({ dispatch, state }, paymentId) { //, state, commit, 
    dataFetch(`${api}/payments/${paymentId}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        dispatch('getPaymentMethodsList')
        dispatch('savePrimaryUser', {section: 'billing', data: {paymentMethodId: paymentId}});
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
  },
  publishAdvertisement({commit}, {data}) {
    commit('setNewAdvertisement', {data});
    commit('clearAdvertisement');
  },
  saveNewUser({commit}, {section, data}) {
    commit('setNewUserData', {section, data})
  },
  changeNewUser({commit}, {userId, section, data}) {
    commit('setNewUserChanges', {userId, section, data})
  },
  removeNewUser({commit}, userId) {
    console.log(userId) // make request
    commit('setFilteredUsers', userId)
  },
  changeUserData({commit, state, dispatch}, {section, data, userId}) {

    const selectedUser = userId === 'primary' ? 
      state.authenticated : 
      state.users.find(user => user.mainDetails.id === userId);

    const user_id = selectedUser._id;

    const formattedData = {
      data: JSON.stringify({...data, adminId: state.authenticated._id}),
      section,
      token: state.authenticated.token
    }

    if (section === 'mainDetails') {
      if (state.attachments[`secondary${data.id}`]) {
        if (state.attachments[`secondary${data.id}`].video.length > 0) {
          formattedData.video = [...state.attachments[`secondary${data.id}`].video];
        }
        if (state.attachments[`secondary${data.id}`].image.length > 0) {
          formattedData.image = [...state.attachments[`secondary${data.id}`].image];
        }
        if (state.attachments[`secondary${data.id}`].document.length > 0) {
          formattedData.document = [...state.attachments[`secondary${data.id}`].document];
        }
      }
    }
  
    if (user_id) {
      dataFetch(`${api}/users/${user_id}`, patchOptionsAuthFiles({
        ...formattedData,
      })).then(resData => {
        console.log(resData);
        commit('setNewUserChanges', {userId, section, data: resData[section]});
        
        dispatch('getUsersList');
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
    }
  },
  archiveSecondaryUser({commit, state, dispatch}, userId) {
    const archivedUser = state.users.find(user => user._id === userId)

    if (archivedUser?.subscriptionAssigned) {
      for (let prop in archivedUser.subscriptionAssigned) {
        if (archivedUser.subscriptionAssigned[prop] === true) {
          dispatch('addOneSubscription', prop);
        }
      }
    }

    dataFetch(`${api}/users/${userId}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setFilteredUsers', userId);
        dispatch('getUsersList');
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
  },
  archivePrimaryUser({commit, state, dispatch}) {

    dataFetch(`${api}/users/${state.authenticated._id}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setArchivedPrimary')
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
  },
  getUsersList({commit, state, dispatch}) {
    dataFetch(`${api}/users`, getOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setUsersList', resData);
        
        resData.forEach(user => {
          if (user.mainDetails.id && (user.mainDetails.id !== 'primary')) {
            if (user?.video?.length > 0) {
              commit('setServerAttachments', {
                attachmentType: 'video',
                attachmentSection: 'secondary',
                attachmentData: user.video,
                attachmentInstance: user.mainDetails.id
              })
            }
            if (user?.image?.length > 0) {
              commit('setServerAttachments', {
                attachmentType: 'image',
                attachmentSection: 'secondary',
                attachmentData: user.image,
                attachmentInstance: user.mainDetails.id
              })
            }
            if (user?.document?.length > 0) {
              commit('setServerAttachments', {
                attachmentType: 'document',
                attachmentSection: 'secondary',
                attachmentData: user.document,
                attachmentInstance: user.mainDetails.id
              })
            }
          }
        })

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }})
  },
  clearSecondaryUser({commit}) {
    commit('setClearedSecondary')
  },
  updateCompanyData({state, dispatch}, {data}) { //commit, 

    const formattedData = {
      accountName: data.accountName,
      workPhone: data.workPhone,
      address: data.address,
      address2: data.address2,
      city: data.city,
      state: data.state,
      zip: data.zip,
      country: data.country
    }

    dataFetch(`${api}/company`, postOptionsAuth({
      data: formattedData,
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        dispatch('getUsersList');
        dispatch('getFarms');
        // commit('setPrimaryUserData', {section: 'billing', data: resData.billing});

        // commit('setCompanyData', data);

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
  },
  submitUserData({ commit, state }, { userType, section }) {
    const commitType = `set${
      userType.charAt(0).toUpperCase() + userType.slice(1)
    }Props`;

    console.log(userType, section); //make request

    section.forEach((sect) => {
      commit(commitType, { section: sect, field: "submitted", value: true });
    });

    /*
      // removal. move from here
      section.forEach(sect => {
        state[userType][sect].submitted = true;
        for (let field in state[userType][sect]) {
          if (field === 'submitted' || field === 'activated') {
            return;
          } 
          if (typeof state[userType][sect][field] === 'string') {
            commit(commitType, {section, field, value: ''})
          }
          if (typeof state[userType][sect][field] === 'boolean') {
            commit(commitType, {section, field, value: false})
          }
        }
      })
      */

    console.log(state[userType]);
    // iterate through sections and fields and make commits with nullified field's value
  },

  activateSubmittion({ commit }, { userType, section }) {
    const commitType = `set${
      userType.charAt(0).toUpperCase() + userType.slice(1)
    }Props`;

    console.log(userType, section); //make request

    section.forEach((sect) => {
      commit(commitType, { section: sect, field: "activated", value: true });
    });
  },

  uploadMedia({ commit }, { file, place }) {
    let formData = new FormData();
    formData.append("file", file);
    console.log(file);
    commit("saveFileLocally", { file, place });
    //request to API
  },

  getLabors({ commit, state, dispatch }) {
    // make request
    dataFetch(`${api}/labors`, getOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setLabors', {rawData: resData})

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
  },

  getFarms({ commit, state, dispatch }) {
    // make request
    dataFetch(`${api}/farms`, getOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setFarms', {rawData: resData})

        resData.forEach(farm => {
          if (farm?.video?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'video',
              attachmentSection: 'farm',
              attachmentData: farm.video,
              attachmentInstance: farm.id
            })
          }
          if (farm?.image?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'image',
              attachmentSection: 'farm',
              attachmentData: farm.image,
              attachmentInstance: farm.id
            })
          }
          if (farm?.document?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'document',
              attachmentSection: 'farm',
              attachmentData: farm.document,
              attachmentInstance: farm.id
            })
          }
        })

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
  },

  getArchivedFarms({ commit, state, dispatch }) {
    // make request
    dataFetch(`${api}/archivedfarms`, getOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setArchivedFarms', {rawData: resData})

        resData.forEach(farm => {
          if (farm?.video?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'video',
              attachmentSection: 'farm',
              attachmentData: farm.video,
              attachmentInstance: farm.id
            })
          }
          if (farm?.image?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'image',
              attachmentSection: 'farm',
              attachmentData: farm.image,
              attachmentInstance: farm.id
            })
          }
          if (farm?.document?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'document',
              attachmentSection: 'farm',
              attachmentData: farm.document,
              attachmentInstance: farm.id
            })
          }
        })

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
  },

  getFarm({ commit, state }, {selectedFarmId}) {
    console.log(selectedFarmId);
    const current = state.farms.find(farm => ((farm.id === selectedFarmId) || (farm._id === selectedFarmId)));
    commit('setCurrentFarm', { current })

    // dataFetch(`${api}/farms/${selectedFarmId}`, getOptionsAuth({//deleteOptions({
    //   token: state.authenticated.token
    // }))
    //   .then(resData => {
    //     console.log(resData);
    //     commit('setFarmDetails', { ...resData });
    //   }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
  
  },
  createFarm({ state, commit, dispatch }, farmData) { //commit, 

    const formattedData = {
      data: JSON.stringify(farmData),
      token: state.authenticated.token
    }

    if (state.attachments[`farm${farmData.id}`]) {
      if (state.attachments[`farm${farmData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`farm${farmData.id}`].video];
      }
      if (state.attachments[`farm${farmData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`farm${farmData.id}`].image];
      }
      if (state.attachments[`farm${farmData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`farm${farmData.id}`].document];
      }
    }

    dataFetch(`${api}/farms`, postOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      commit('setFarms', {rawData: [{...resData}]}); // farmData
      
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
  },
  editFarm({ state, commit, dispatch }, farmData) { //commit,
    const { selectedFarmId } = farmData;

    const formattedData = {
      // data: JSON.stringify(farmData),
      token: state.authenticated.token
    }

    if (state.attachments[`farm${farmData.id}`]) {
      if (state.attachments[`farm${farmData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`farm${farmData.id}`].video];
        farmData.video = [...state.attachments[`farm${farmData.id}`].video];
      }
      if (state.attachments[`farm${farmData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`farm${farmData.id}`].image];
        farmData.image = [...state.attachments[`farm${farmData.id}`].image];
      }
      if (state.attachments[`farm${farmData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`farm${farmData.id}`].document];
        farmData.document = [...state.attachments[`farm${farmData.id}`].document];
      }
    }
    formattedData.data = JSON.stringify(farmData);
    console.log(formattedData, 'formattedData.document');
    dataFetch(`${api}/farms/${farmData._id || selectedFarmId}`, patchOptionsAuthFiles({
        ...formattedData,
      })).then(resData => {
        console.log(resData);

        // dispatch('getFarms');
        commit('setFarmChanges', {...resData}); // farmData

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  removeFarm({ state, commit, dispatch }, {farmId, farm_id, index}) {
    console.log(farmId, 'farmId');
    dataFetch(`${api}/farms/${farm_id}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        // dispatch('getFarms');
        commit('setFarmsList', {index});
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
    // const rawData = state.farms.filter(farm => farm.id !== farmId);
  },
  editFarmMap({ commit, state, dispatch }, {mapDraw, selectedFarmId}) {
    // data: mapDraw in request body

    // const formattedData = {
    //   data: JSON.stringify(mapDraw),
    //   token: state.authenticated.token
    // }

    const selectedFarm = state.farms.find(farm => (farm.id === selectedFarmId));
    let farm_id;

    if (selectedFarm) {
      farm_id = selectedFarm._id;
    }

    console.log(farm_id, 'farm_id');

    dataFetch(`${api}/farms/${farm_id}/maps`, patchOptionsAuth({
      data: {...mapDraw},
      token: state.authenticated.token
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      commit('setFarmMap', {mapDraw, selectedFarmId});

    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  // getBeds({ commit }, bedData) {commit('setBedChanges', bedData)},
  // getBed({ commit }, bedData) {commit('setBedChanges', bedData)},
  createBed({ commit, state, dispatch }, bedData) {
    // make request, getFarms
    // data: bedData in request body

    const selectedFarm = state.farms.find(farm => (farm.id === bedData.farmId));
    let farm_id;

    if (selectedFarm) {
      farm_id = selectedFarm._id;
    }

    console.log(farm_id, 'farm_id');
    
    dataFetch(`${api}/farms/${farm_id}/beds`, postOptionsAuth({
      data: bedData,
      token: state.authenticated.token
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      commit('setNewBed', bedData); // farmData
      
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  editBed({ commit, state, dispatch }, bedData) {

    const selectedFarm = state.farms.find(farm => (farm.id === bedData.farmId));
    let farm_id;

    if (selectedFarm) {
      farm_id = selectedFarm._id;
    }

    console.log(farm_id, 'farm_id');

    dataFetch(`${api}/farms/${farm_id}/beds/${bedData.id}`, patchOptionsAuth({
      data: bedData,
      token: state.authenticated.token
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      commit('setBedChanges', bedData)

    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  removeBed({ state, commit, dispatch }, {farmId, bedId, bedIndex}) {

    const selectedFarm = state.farms.find(farm => (farm.id === farmId));
    let farm_id;

    if (selectedFarm) {
      farm_id = selectedFarm._id;
    }

    console.log(farm_id, 'farm_id');

    dataFetch(`${api}/farms/${farm_id}/beds/${bedId}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        // dispatch('getFarms');
        commit('setBeds', {farmId, bedId, bedIndex})
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
  },

  saveBedDrawing({commit}, imgData) {
    console.log(imgData);
    // data: imgData in request body
    commit('setBedDrawing')
  },

  archiveFarmPlan({dispatch, state}, farmData) { //commit, 

    const selectedFarm = state.farms.find(farm => (farm.id === farmData.selectedFarmId));
    let farm_id;

    if (selectedFarm) {
      farm_id = selectedFarm._id;
    }

    console.log(farm_id, 'farm_id');

    dataFetch(`${api}/farms/${farm_id}/maps/archive`, patchOptionsAuth({
      ...farmData,
      token: state.authenticated.token
    })).then(resData => {
      console.log(resData);

      dispatch('getFarms');
      // commit('setFarmMap', {mapDraw, selectedFarmId});

      // commit('setArchivedFarmPlan', farmData);

    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },

  getCrops({ commit, state, dispatch }) {

    dataFetch(`${api}/crops`, getOptionsAuth({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setCrops', resData)
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
  },
  createCrop({ commit, state, dispatch }, {crop}) {

    const selectedCrop = state.crops.find(cropState => (cropState.id === crop.id));
    let crop_id;

    if (selectedCrop) {
      crop_id = selectedCrop._id;
    }

    console.log(crop_id, 'crop_id');

    delete crop._id;
    
    dataFetch(`${api}/crops`, postOptionsAuth({
      data: crop,
      token: state.authenticated.token
    })).then(resData => {
      console.log(resData);

      dispatch('getCrops');
      commit('setNewCrop', resData);

    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  editCrop({commit, state, dispatch}, {crop}) {

    const selectedCrop = state.crops.find(cropState => (cropState.id === crop.id));
    let crop_id;

    if (selectedCrop) {
      crop_id = selectedCrop._id;
    }

    delete crop._id;

    console.log(crop_id, 'crop_id');

    if (crop_id) {
      dataFetch(`${api}/crops/${crop_id}`, patchOptionsAuth({
        data: crop,
        token: state.authenticated.token
      })).then(resData => {
        console.log(resData);
  
        dispatch('getCrops');
        commit('setCropChanges', {crop: resData})
  
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
    }
  },
  removeCrop({state, dispatch}, {cropId}) { //commit, ,cropIndex

    const selectedCrop = state.crops.find(cropState => (cropState.id === cropId));
    let crop_id;

    if (selectedCrop) {
      crop_id = selectedCrop._id;
    }

    console.log(crop_id, 'crop_id');

    dataFetch(`${api}/crops/${crop_id}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        // commit('setCropsList', {cropId, cropIndex})

        dispatch('getCrops');

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  attachFile({commit}, attachmentData) {
    commit('setAttachment', attachmentData)
  },
  uploadAttachedFileInFilledState({state, dispatch, commit}, {section, instance}) {

    const findEntity  = (entity) => {
      console.log(state[entity], 'state[entity]');
      const foundEntity = (section === 'secondary') ?
        state[entity].find(ent => ent.mainDetails ? (ent.mainDetails.id === instance) : (ent.id === instance)) :
        state[entity].find(ent => ent.id === instance);

      if (foundEntity && foundEntity?._id) {
        return foundEntity._id;
      } else {
        if ((section === 'secondary') && (state.authenticated._id && !state.primaryUser.mainDetails)) {
          return state.authenticated._id;
        }
      }
    }

    const endpoints = {
      primary: {
        url: `${api}/users/${state.authenticated._id}`,
        commit: 'setPrimaryUserData'
        //       commit('setPrimaryUserData', {section, data: resData[section]});
      },
      secondary: {
        url: `${api}/users/${findEntity('users')}`,
        commit: 'setNewUserChanges'
        // commit('setSecondaryUserData', {section: prop, data: resData[prop]});
      },
      farm: {
        url: `${api}/farms/${findEntity('farms')}`,
        commit: 'setFarmChanges'
      },
      // etc
      task: {
        url: `${api}/tasks/${findEntity('tasks')}`,
        commit: 'setTaskChanges'
        //         commit('setTaskChanges', taskData)
      },
      vendor: {
        url: `${api}/vendors/${findEntity('vendors')}`,
        commit: 'setVendorChanges'
        // dispatch('getVendors');
        // commit('setVendorChanges', resData); //vendorData
      },
      vendorItem: {
        url: `${api}/vendoritems/${findEntity('vendorItems')}`,
        commit: 'setVendorItemChanges'
        // dispatch('getVendorItems');
        // commit('setVendorItemChanges', resData);
      },
      planInventory: {
        url: `${api}/plannedinventories/${findEntity('plannedInventories')}`,
        commit: 'setPlannedInventoryChanges'
        // dispatch('getPlannedInventories');
        // commit('setPlannedInventoryChanges', resData);
      },
      sales: {
        url: `${api}/sales/${findEntity('sales')}`,
        commit: 'setSalesChanges'
        // dispatch('getSales');
        // commit('setSalesChanges', resData)
      },
      customer: {
        url: `${api}/salescustomers/${findEntity('customers')}`,
        commit: 'setCustomerChanges'
        // dispatch('getCustomers');
        // commit('setCustomerChanges', customerData)
      },
      laborExpense: {
        url: `${api}/laborexpenses/${findEntity('laborExpenses')}`,
        commit: 'setLaborExpenseChanges'
        // dispatch('getLaborExpenses');
        // commit('setLaborExpenseChanges', resData)
      },
      adminExpense: {
        url: `${api}/adminexpenses/${findEntity('adminExpenses')}`,
        commit: 'setAdminExpenseChanges'
        // dispatch('getAdminExpenses');
        // commit('setAdminExpenseChanges', resData);
      },
      employeeCompensation: {
        url: `${api}/compensations/${findEntity('compensations')}`,
        commit: 'setCompensationChanges'
        // dispatch('getCompensations');
        // commit('setCompensationChanges', resData);        
      }
    };

    const formattedData = {
      data: JSON.stringify({}),
      token: state.authenticated.token
    }

    if ((section === 'primary') || section === 'secondary') {
      formattedData.section = 'mainDetails';
    }

    if (state.attachments[`${section}${instance || ''}`]) {
      if (state.attachments[`${section}${instance || ''}`].video.length > 0) {
        formattedData.video = [...state.attachments[`${section}${instance || ''}`].video];
      }
      if (state.attachments[`${section}${instance || ''}`].image.length > 0) {
        formattedData.image = [...state.attachments[`${section}${instance || ''}`].image];
      }
      if (state.attachments[`${section}${instance || ''}`].document.length > 0) {
        formattedData.document = [...state.attachments[`${section}${instance || ''}`].document];
      }
    }

    dataFetch(endpoints[section].url, patchOptionsAuthFiles({//deleteOptions({
      // token: state.authenticated.token
      ...formattedData
    }))
      .then(resData => {
        console.log(resData);

        if (section === 'primary') {
          commit('setPrimaryUserData', {section: 'mainDetails', data: resData['mainDetails']});
        } else {
          commit(endpoints[section].commit, {...resData});
        }

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
    
  },
  deleteAttachedFileInFilledState({state, dispatch, commit}, {section, instance, fileType, fileName}) {

    const findEntity  = (entity) => {
      console.log(state[entity], 'state[entity]');
      const foundEntity = (section === 'secondary') ?
        state[entity].find(ent => ent.mainDetails ? (ent.mainDetails.id === instance) : (ent.id === instance)) :
        state[entity].find(ent => ent.id === instance);

      if (foundEntity && foundEntity?._id) {
        return foundEntity._id;
      } else {
        if ((section === 'secondary') && (state.authenticated._id && !state.primaryUser.mainDetails)) {
          return state.authenticated._id;
        }
      }
    }

    const endpoints = {
      primary: {
        url: `${api}/users/${state.authenticated._id}`,
        commit: 'setPrimaryUserData'
        //       commit('setPrimaryUserData', {section, data: resData[section]});
      },
      secondary: {
        url: `${api}/users/${findEntity('users')}`,
        commit: 'setNewUserChanges'
        // commit('setSecondaryUserData', {section: prop, data: resData[prop]});
      },
      farm: {
        url: `${api}/farms/${findEntity('farms')}`,
        commit: 'setFarmChanges'
      },
      // etc
      task: {
        url: `${api}/tasks/${findEntity('tasks')}`,
        commit: 'setTaskChanges'
        //         commit('setTaskChanges', taskData)
      },
      vendor: {
        url: `${api}/vendors/${findEntity('vendors')}`,
        commit: 'setVendorChanges'
        // dispatch('getVendors');
        // commit('setVendorChanges', resData); //vendorData
      },
      vendorItem: {
        url: `${api}/vendoritems/${findEntity('vendorItems')}`,
        commit: 'setVendorItemChanges'
        // dispatch('getVendorItems');
        // commit('setVendorItemChanges', resData);
      },
      planInventory: {
        url: `${api}/plannedinventories/${findEntity('plannedInventories')}`,
        commit: 'setPlannedInventoryChanges'
        // dispatch('getPlannedInventories');
        // commit('setPlannedInventoryChanges', resData);
      },
      sales: {
        url: `${api}/sales/${findEntity('sales')}`,
        commit: 'setSalesChanges'
        // dispatch('getSales');
        // commit('setSalesChanges', resData)
      },
      customer: {
        url: `${api}/salescustomers/${findEntity('customers')}`,
        commit: 'setCustomerChanges'
        // dispatch('getCustomers');
        // commit('setCustomerChanges', customerData)
      },
      laborExpense: {
        url: `${api}/laborexpenses/${findEntity('laborExpenses')}`,
        commit: 'setLaborExpenseChanges'
        // dispatch('getLaborExpenses');
        // commit('setLaborExpenseChanges', resData)
      },
      adminExpense: {
        url: `${api}/adminexpenses/${findEntity('adminExpenses')}`,
        commit: 'setAdminExpenseChanges'
        // dispatch('getAdminExpenses');
        // commit('setAdminExpenseChanges', resData);
      },
      employeeCompensation: {
        url: `${api}/compensations/${findEntity('compensations')}`,
        commit: 'setCompensationChanges'
        // dispatch('getCompensations');
        // commit('setCompensationChanges', resData);        
      }
    };

    const formattedData = {
      token: state.authenticated.token
    }

    const data = {};

    if ((section === 'primary') || section === 'secondary') {
      formattedData.section = 'mainDetails';
    }

    if (state.attachments[`${section}${instance || ''}`]) {
      if (state.attachments[`${section}${instance || ''}`].video.length > 0) {
        formattedData.video = [...state.attachments[`${section}${instance || ''}`].video];
        if ((section !== 'primary') && (section !== 'secondary')) {
          data.video = [...state.attachments[`${section}${instance || ''}`].video];
        } else {
          formattedData.video = JSON.stringify(formattedData.video);
        }
      }
      if (state.attachments[`${section}${instance || ''}`].image.length > 0) {
        formattedData.image = [...state.attachments[`${section}${instance || ''}`].image];
        if ((section !== 'primary') && (section !== 'secondary')) {
          data.image = [...state.attachments[`${section}${instance || ''}`].image];
        } else {
          formattedData.image = JSON.stringify(formattedData.image);
        }
      }
      if (state.attachments[`${section}${instance || ''}`].document.length > 0) {
        formattedData.document = [...state.attachments[`${section}${instance || ''}`].document];
        if ((section !== 'primary') && (section !== 'secondary')) {
          data.document = [...state.attachments[`${section}${instance || ''}`].document];
        } else {
          formattedData.document = JSON.stringify(formattedData.document);
        }
      }
    }

    formattedData.data = JSON.stringify({...data});

    if ((section === 'primary') || section === 'secondary') {

      dataFetch(endpoints[section].url, patchOptionsAuthEmptyAttachments({//deleteOptions({
        // token: state.authenticated.token
        ...formattedData
      }))
        .then(resData => {
          console.log(resData);

          if (section === 'primary') {
            commit('setPrimaryUserData', {section: 'mainDetails', data: resData['mainDetails']});
          } else {
            commit(endpoints[section].commit, {...resData});
          }

          dataFetch(`${api}/attachments/remove`, postOptionsAuth({
            fileType, fileName, section, instance,
            token: state.authenticated.token
          }))
            // .then(resData => (resData.ok ? resData.blob() : Promise.reject(resData)))
            .then(resData => {
              console.log(resData);

            }).catch(e => {
              console.log(e); 
              // if (e.status === 401) { 
              //   dispatch('logOutApi') 
              // }
            });

        }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

    } else {

      dataFetch(endpoints[section].url, patchOptionsAuthFiles({//deleteOptions({
        // token: state.authenticated.token
        ...formattedData
      }))
        .then(resData => {
          console.log(resData);

          if (section === 'primary') {
            commit('setPrimaryUserData', {section: 'mainDetails', data: resData['mainDetails']});
          } else {
            commit(endpoints[section].commit, {...resData});
          }

          dataFetch(`${api}/attachments/remove`, postOptionsAuth({
            fileType, fileName, section, instance,
            token: state.authenticated.token
          }))
            // .then(resData => (resData.ok ? resData.blob() : Promise.reject(resData)))
            .then(resData => {
              console.log(resData);

            }).catch(e => {
              console.log(e); 
              // if (e.status === 401) { 
              //   dispatch('logOutApi') 
              // }
            });

        }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
    }
  },
  selectUserRole({commit}, selectedRole) {
    commit('setUserRole', selectedRole)
  },

  getTasks({ commit, state, dispatch }) {

    dataFetch(`${api}/tasks`, getOptionsAuth({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setTasks', resData)

        resData.forEach(task => {
          if (task?.video?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'video',
              attachmentSection: 'task',
              attachmentData: task.video,
              attachmentInstance: task.id
            })
          }
          if (task?.image?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'image',
              attachmentSection: 'task',
              attachmentData: task.image,
              attachmentInstance: task.id
            })
          }
          if (task?.document?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'document',
              attachmentSection: 'task',
              attachmentData: task.document,
              attachmentInstance: task.id
            })
          }
        })

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  getTask({ commit }, taskData) {
    commit('setTask', taskData)
  },
  createTask({ commit, state, dispatch }, taskData) {
    console.log(taskData); // make request

    const formattedData = {
      data: JSON.stringify(taskData),
      token: state.authenticated.token
    }

    if (state.attachments[`task${taskData.id}`]) {
      if (state.attachments[`task${taskData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`task${taskData.id}`].video];
      }
      if (state.attachments[`task${taskData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`task${taskData.id}`].image];
      }
      if (state.attachments[`task${taskData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`task${taskData.id}`].document];
      }
    }
    
    dataFetch(`${api}/tasks`, postOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      // commit('setFarms', {rawData: [{...resData}]}); // farmData
      commit('setNewTask', resData);
      
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  editTask({ commit, state, dispatch }, taskData) {
    console.log(taskData); // make request, getFarms


    const formattedData = {
      // data: JSON.stringify(taskData),
      token: state.authenticated.token
    }

    if (state.attachments[`task${taskData.id}`]) {
      if (state.attachments[`task${taskData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`task${taskData.id}`].video];
        taskData.video = [...state.attachments[`task${taskData.id}`].video];
      }
      if (state.attachments[`task${taskData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`task${taskData.id}`].image];
        taskData.image = [...state.attachments[`task${taskData.id}`].image];
      }
      if (state.attachments[`task${taskData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`task${taskData.id}`].document];
        taskData.document = [...state.attachments[`task${taskData.id}`].document];
      }
    }

    formattedData.data = JSON.stringify(taskData);

    dataFetch(`${api}/tasks/${taskData._id}`, patchOptionsAuthFiles({
        ...formattedData,
      })).then(resData => {
        console.log(resData);

        // dispatch('getFarms');
        // commit('setFarmChanges', {...resData}); // farmData
        commit('setTaskChanges', taskData)

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

    // if (taskData.recurring === true) {
    //   const prototypeRecurring = state.tasks.findIndex(task => task.id === taskData.id)
    //   if (prototypeRecurring === 0) {
    //     // collect uploads information
    //   }
    // }
  },
  createRecurringTask({commit, state, dispatch}, recurringTasks) {
    const formattedData = {
      data: JSON.stringify(recurringTasks),
      token: state.authenticated.token
    }

    if (state.attachments[`task${recurringTasks[0].recurrenceId}`]) {
      if (state.attachments[`task${recurringTasks[0].recurrenceId}`].video.length > 0) {
        formattedData.video = [...state.attachments[`task${recurringTasks[0].recurrenceId}`].video];
      }
      if (state.attachments[`task${recurringTasks[0].recurrenceId}`].image.length > 0) {
        formattedData.image = [...state.attachments[`task${recurringTasks[0].recurrenceId}`].image];
      }
      if (state.attachments[`task${recurringTasks[0].recurrenceId}`].document.length > 0) {
        formattedData.document = [...state.attachments[`task${recurringTasks[0].recurrenceId}`].document];
      }
    }
    
    // formattedData.data.recurrenceIndex = state.tasks.findIndex(stateTask => stateTask.recurrenceId === taskData.recurrenceId) + 1;

    dataFetch(`${api}/tasks/recurring`, postOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      // commit('setFarms', {rawData: [{...resData}]}); // farmData
      resData.forEach(insertedTask => {
        commit('setNewTask', insertedTask);
      });
      
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

    // if (taskData.recurring === true) {
    //   const prototypeRecurring = state.tasks.find(task => task.recurrenceId === taskData.recurrenceId)
    //   if (!prototypeRecurring) {
    //     // collect uploads information + files + add index 0 to data of request
    //   } else {
    //     const prototypeRecurringIndex = state.tasks.findIndex(task => task.id === taskData.id)
    //     if (prototypeRecurringIndex > 0) {
    //       //  collect uploads information without files
    //     }
    //   }
    // }
  },
  editRecurringTask({commit, state, dispatch}, taskData) {
    const formattedData = {
      // data: JSON.stringify(taskData),
      token: state.authenticated.token
    }

    if (state.attachments[`task${taskData.recurrenceId}`]) {
      if (state.attachments[`task${taskData.recurrenceId}`].video.length > 0) {
        formattedData.video = [...state.attachments[`task${taskData.recurrenceId}`].video];
        taskData.video = [...state.attachments[`task${taskData.id}`].video];
      }
      if (state.attachments[`task${taskData.recurrenceId}`].image.length > 0) {
        formattedData.image = [...state.attachments[`task${taskData.recurrenceId}`].image];
        taskData.image = [...state.attachments[`task${taskData.id}`].image];
      }
      if (state.attachments[`task${taskData.recurrenceId}`].document.length > 0) {
        formattedData.document = [...state.attachments[`task${taskData.recurrenceId}`].document];
        taskData.document = [...state.attachments[`task${taskData.id}`].document];
      }
    }

    formattedData.data = JSON.stringify(taskData);

    dataFetch(`${api}/tasks/recurring-update`, postOptionsAuthFiles({
        ...formattedData,
      })).then(resData => {
        console.log(resData);

        // dispatch('getFarms');
        // commit('setFarmChanges', {...resData}); // farmData

        commit('setTaskChangesRecurring', taskData);

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
  },
  removeTask({commit, state, dispatch}, taskId) {
    console.log(taskId) // make request

    const selectedTask = state.tasks.find(taskState => (taskState.id === taskId));
    let task_id;

    if (selectedTask) {
      task_id = selectedTask._id;
    }

    console.log(task_id, 'task_id');

    dataFetch(`${api}/tasks/${task_id}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setFilteredTasks', taskId);
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },

  removeRecurringTask({commit, state, dispatch}, recurrenceId) {

    dataFetch(`${api}/tasks/recurring/${recurrenceId}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setFilteredRecurringTasks', recurrenceId);
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },

  // removeMultipleTasks({commit}, taskId) {
  //   console.log(taskId) // make request
  //   commit('setMultipleFilteredTasks', taskId)
  // },

  getAddedInventories({ commit }, addedInventoriesData) {
    console.log(addedInventoriesData) // request
    const addedInventoriesResponse = [];
    commit('setAddedInventories', addedInventoriesResponse)
  },
  getAddedInventory({ commit }, addedInventoryData) {
    commit('setAddedInventory', addedInventoryData)
  },
  createAddedInventory({ commit }, addedInventoryData) {
    console.log(addedInventoryData); // make request
    commit('setNewAddedInventory', addedInventoryData)
  },
  editAddedInventory({commit}, addedInventoryData) {
    console.log(addedInventoryData); // make request, getFarms
    commit('setAddedInventoryChanges', addedInventoryData)
  },
  removeAddedInventory({commit}, addedInventoryId) {
    console.log(addedInventoryId) // make request
    commit('setFilteredAddedInventories', addedInventoryId)
  },

  getInventoriesUsed({ commit }, inventoriesUsedData) {
    console.log(inventoriesUsedData) // request
    const inventoriesUsedResponse = [];
    commit('setInventoriesUsed', inventoriesUsedResponse)
  },
  getventoryputUsed({ commit }, inventoryUsedData) {
    commit('setInventoryUsed', inventoryUsedData)
  },
  createInventoryUsed({ commit }, inventoryUsedData) {
    console.log(inventoryUsedData); // make request
    commit('setNewInventoryUsed', inventoryUsedData)
  },
  editInventoryUsed({commit}, inventoryUsedData) {
    console.log(inventoryUsedData); // make request, getFarms
    commit('setInventoryUsedChanges', inventoryUsedData)
  },
  removeInventoryUsed({commit}, inventoryUsedId) {
    console.log(inventoryUsedId) // make request
    commit('setFilteredInventoriesUsed', inventoryUsedId)
  },

  getVendors({ commit, state, dispatch }) {

    dataFetch(`${api}/vendors`, getOptionsAuth({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setVendors', resData);

        resData.forEach(vendor => {
          if (vendor?.video?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'video',
              attachmentSection: 'vendor',
              attachmentData: vendor.video,
              attachmentInstance: vendor.id
            })
          }
          if (vendor?.image?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'image',
              attachmentSection: 'vendor',
              attachmentData: vendor.image,
              attachmentInstance: vendor.id
            })
          }
          if (vendor?.document?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'document',
              attachmentSection: 'vendor',
              attachmentData: vendor.document,
              attachmentInstance: vendor.id
            })
          }
        })

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  getVendor({ commit }, vendorData) {
    commit('setVendor', vendorData)
  },
  createVendor({ commit, state, dispatch }, vendorData) {
    console.log(vendorData); // make request

    const formattedData = {
      data: JSON.stringify(vendorData),
      token: state.authenticated.token
    }

    if (state.attachments[`vendor${vendorData.id}`]) {
      if (state.attachments[`vendor${vendorData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`vendor${vendorData.id}`].video];
      }
      if (state.attachments[`vendor${vendorData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`vendor${vendorData.id}`].image];
      }
      if (state.attachments[`vendor${vendorData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`vendor${vendorData.id}`].document];
      }
    }

    console.log(formattedData.document, 'formattedData.document');
    
    dataFetch(`${api}/vendors`, postOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      // commit('setFarms', {rawData: [{...resData}]}); // farmData
      dispatch('getVendors');
      commit('setNewVendor', resData);
      
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});


  },
  editVendor({ commit, state, dispatch }, vendorData) {
    console.log(vendorData); // make request, getFarms

    const formattedData = {
      // data: JSON.stringify(vendorData),
      token: state.authenticated.token
    }

    if (state.attachments[`vendor${vendorData.id}`]) {
      if (state.attachments[`vendor${vendorData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`vendor${vendorData.id}`].video];
        vendorData.video = [...state.attachments[`vendor${vendorData.id}`].video];
      }
      if (state.attachments[`vendor${vendorData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`vendor${vendorData.id}`].image];
        vendorData.image = [...state.attachments[`vendor${vendorData.id}`].image];
      }
      if (state.attachments[`vendor${vendorData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`vendor${vendorData.id}`].document];
        vendorData.document = [...state.attachments[`vendor${vendorData.id}`].document];
      }
    }

    formattedData.data = JSON.stringify(vendorData);

    dataFetch(`${api}/vendors/${vendorData._id}`, patchOptionsAuthFiles({
        ...formattedData,
      })).then(resData => {
        console.log(resData);

        // dispatch('getFarms');
        // commit('setFarmChanges', {...resData}); // farmData
        dispatch('getVendors');
        commit('setVendorChanges', resData); //vendorData

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  removeVendor({ commit, state, dispatch }, vendorId) {
    console.log(vendorId) // make request
    
    const selectedVendor = state.vendors.find(vendorState => (vendorState.id === vendorId));
    let vendor_id;

    if (selectedVendor) {
      vendor_id = selectedVendor._id;
    }

    console.log(vendor_id, 'vendor_id');

    dataFetch(`${api}/vendors/${vendor_id}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setFilteredVendors', vendorId);
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});


  },

  // addVendorInventories ({commit}, vendorInventory) {
  //   commit('setNewVendorInventory', vendorInventory)
  // },
  // addVendorEquipments ({commit}, vendorEquipment) {
  //   commit('setNewVendorEquipment', vendorEquipment)
  // },
  // addVendorServices ({commit}, vendorService) {
  //   commit('setNewVendorService', vendorService)
  // },

  getVendorItems({ commit, state, dispatch }) {

    dataFetch(`${api}/vendoritems`, getOptionsAuth({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setVendorItems', resData);

        resData.forEach(vendorItem => {
          if (vendorItem?.video?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'video',
              attachmentSection: 'vendorItem',
              attachmentData: vendorItem.video,
              attachmentInstance: vendorItem.id
            })
          }
          if (vendorItem?.image?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'image',
              attachmentSection: 'vendorItem',
              attachmentData: vendorItem.image,
              attachmentInstance: vendorItem.id
            })
          }
          if (vendorItem?.document?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'document',
              attachmentSection: 'vendorItem',
              attachmentData: vendorItem.document,
              attachmentInstance: vendorItem.id
            })
          }
        })

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  addVendorItem ({ commit, state, dispatch }, vendorItem) {

    const formattedData = {
      data: JSON.stringify(vendorItem),
      token: state.authenticated.token
    }

    if (state.attachments[`vendorItem${vendorItem.id}`]) {
      if (state.attachments[`vendorItem${vendorItem.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`vendorItem${vendorItem.id}`].video];
      }
      if (state.attachments[`vendorItem${vendorItem.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`vendorItem${vendorItem.id}`].image];
      }
      if (state.attachments[`vendorItem${vendorItem.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`vendorItem${vendorItem.id}`].document];
      }
    }
    
    dataFetch(`${api}/vendoritems`, postOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      // commit('setFarms', {rawData: [{...resData}]}); // farmData
      dispatch('getVendorItems');
      commit('setNewVendorItem', resData);
      
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },

  // editVendorInventories ({commit}, vendorInventoryData) {
  //   commit('setVendorInventoryChanges', vendorInventoryData)
  // },
  // editVendorEquipments ({commit}, vendorEquipmentData) {
  //   commit('setVendorEquipmentChanges', vendorEquipmentData)
  // },
  // editVendorServices ({commit}, vendorServiceData) {
  //   commit('setVendorServiceChanges', vendorServiceData)
  // },

  editVendorItem ({ commit, state, dispatch }, vendorItemData) {

    const formattedData = {
      // data: JSON.stringify(vendorItemData),
      token: state.authenticated.token
    }

    if (state.attachments[`vendorItem${vendorItemData.id}`]) {
      if (state.attachments[`vendorItem${vendorItemData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`vendorItem${vendorItemData.id}`].video];
        vendorItemData.video = [...state.attachments[`vendorItem${vendorItemData.id}`].video];
      }
      if (state.attachments[`vendorItem${vendorItemData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`vendorItem${vendorItemData.id}`].image];
        vendorItemData.image = [...state.attachments[`vendorItem${vendorItemData.id}`].image];
      }
      if (state.attachments[`vendorItem${vendorItemData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`vendorItem${vendorItemData.id}`].document];
        vendorItemData.document = [...state.attachments[`vendorItem${vendorItemData.id}`].document];
      }
    }

    formattedData.data = JSON.stringify(vendorItemData);

    dataFetch(`${api}/vendoritems/${vendorItemData._id}`, patchOptionsAuthFiles({
        ...formattedData,
      })).then(resData => {
        console.log(resData);

        // dispatch('getFarms');
        // commit('setFarmChanges', {...resData}); // farmData
        dispatch('getVendorItems');
        commit('setVendorItemChanges', resData);

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  removeVendorItem ({ commit, state, dispatch }, vendorItemId) {

    const selectedVendorItem = state.vendorItems.find(vendorItemState => (vendorItemState.id === vendorItemId));
    let vendorItem_id;

    if (selectedVendorItem) {
      vendorItem_id = selectedVendorItem._id;
    }

    console.log(vendorItem_id, 'vendorItem_id');

    dataFetch(`${api}/vendoritems/${vendorItem_id}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setFilteredVendorItems', vendorItemId)
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },

  getPlannedInventories({ commit, state, dispatch }) {

    dataFetch(`${api}/plannedinventories`, getOptionsAuth({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setPlannedInventories', resData);

        resData.forEach(planInventory => {
          if (planInventory?.video?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'video',
              attachmentSection: 'planInventory',
              attachmentData: planInventory.video,
              attachmentInstance: planInventory.id
            })
          }
          if (planInventory?.image?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'image',
              attachmentSection: 'planInventory',
              attachmentData: planInventory.image,
              attachmentInstance: planInventory.id
            })
          }
          if (planInventory?.document?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'document',
              attachmentSection: 'planInventory',
              attachmentData: planInventory.document,
              attachmentInstance: planInventory.id
            })
          }
        })

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  addPlannedInventory ({ commit, state, dispatch }, plannedInventory) {

    delete plannedInventory._id;

    const formattedData = {
      data: JSON.stringify(plannedInventory),
      token: state.authenticated.token
    }

    if (state.attachments[`planInventory${plannedInventory.id}`]) {
      if (state.attachments[`planInventory${plannedInventory.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`planInventory${plannedInventory.id}`].video];
      }
      if (state.attachments[`planInventory${plannedInventory.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`planInventory${plannedInventory.id}`].image];
      }
      if (state.attachments[`planInventory${plannedInventory.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`planInventory${plannedInventory.id}`].document];
      }
    }
    
    dataFetch(`${api}/plannedinventories`, postOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      // commit('setFarms', {rawData: [{...resData}]}); // farmData
      dispatch('getPlannedInventories');
      commit('setNewPlannedInventory', resData);
      
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  editPlannedInventory ({ commit, state, dispatch }, plannedInventoryData) {

    const formattedData = {
      // data: JSON.stringify(plannedInventoryData),
      token: state.authenticated.token
    }

    if (state.attachments[`planInventory${plannedInventoryData.id}`]) {
      if (state.attachments[`planInventory${plannedInventoryData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`planInventory${plannedInventoryData.id}`].video];
        plannedInventoryData.video = [...state.attachments[`planInventory${plannedInventoryData.id}`].video];
      }
      if (state.attachments[`planInventory${plannedInventoryData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`planInventory${plannedInventoryData.id}`].image];
        plannedInventoryData.image = [...state.attachments[`planInventory${plannedInventoryData.id}`].image];
      }
      if (state.attachments[`planInventory${plannedInventoryData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`planInventory${plannedInventoryData.id}`].document];
        plannedInventoryData.document = [...state.attachments[`planInventory${plannedInventoryData.id}`].document];
      }
    }

    formattedData.data = JSON.stringify(plannedInventoryData);

    dataFetch(`${api}/plannedinventories/${plannedInventoryData._id}`, patchOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      // commit('setFarmChanges', {...resData}); // farmData
      dispatch('getPlannedInventories');
      commit('setPlannedInventoryChanges', resData);

    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  removePlannedInventory ({ commit, state, dispatch }, plannedInventoryId) {

    const selectedPlannedInventory = state.plannedInventories.find(plannedInventoryState => (plannedInventoryState.id === plannedInventoryId));
    let plannedInventory_id;

    if (selectedPlannedInventory) {
      plannedInventory_id = selectedPlannedInventory._id;
    }

    console.log(plannedInventory_id, 'plannedInventory_id');

    dataFetch(`${api}/plannedinventories/${plannedInventory_id}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setFilteredPlannedInventories', plannedInventoryId)
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },

  // removeVendorInventories ({commit}, vendorInventoryId) {
  //   commit('setFilteredVendorInventories', vendorInventoryId)
  // },
  // removeVendorEquipments ({commit}, vendorEquipmentId) {
  //   commit('setFilteredVendorEquipments', vendorEquipmentId)
  // },
  // removeVendorServices ({commit}, vendorServiceId) {
  //   commit('setFilteredVendorServices', vendorServiceId)
  // },

  getEquipments({ commit }, equipmentsData) {
    console.log(equipmentsData) // request
    const equipmentsResponse = [];
    commit('setEquipments', equipmentsResponse)
  },
  getEquipment({ commit }, equipmentData) {
    commit('setEquipment', equipmentData)
  },
  createEquipment({ commit }, equipmentData) {
    console.log(equipmentData); // make request
    commit('setNewEquipment', equipmentData)
  },
  editEquipment({commit}, equipmentData) {
    console.log(equipmentData); // make request, getFarms
    commit('setEquipmentChanges', equipmentData)
  },
  removeEquipment({commit}, equipmentId) {
    console.log(equipmentId) // make request
    commit('setFilteredEquipments', equipmentId)
  },

  provideCoordinates({commit}, geoCoords) {
    console.log('provideCoordinates', geoCoords);
    commit('setGeolocation', geoCoords);
  },

  getHarvests({ commit }, harvestsData) {
    console.log(harvestsData) // request
    const harvestsResponse = [];
    commit('setHarvests', harvestsResponse)
  },
  getHarvest({ commit }, harvestData) {
    commit('setHarvest', harvestData)
  },
  createHarvest({ commit }, harvestData) {
    console.log(harvestData); // make request
    commit('setNewHarvest', harvestData)
  },
  editHarvest({commit}, harvestData) {
    console.log(harvestData); // make request, getHarvests
    commit('setHarvestChanges', harvestData)
  },
  removeHarvest({commit}, harvestId) {
    console.log(harvestId) // make request
    commit('setFilteredHarvests', harvestId)
  },

  getSales({ commit, state, dispatch }) {

    dataFetch(`${api}/sales`, getOptionsAuth({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setSales', resData);

        resData.forEach(sales => {
          if (sales?.video?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'video',
              attachmentSection: 'sales',
              attachmentData: sales.video,
              attachmentInstance: sales.id
            })
          }
          if (sales?.image?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'image',
              attachmentSection: 'sales',
              attachmentData: sales.image,
              attachmentInstance: sales.id
            })
          }
          if (sales?.document?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'document',
              attachmentSection: 'sales',
              attachmentData: sales.document,
              attachmentInstance: sales.id
            })
          }
        })

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  getSalesOne({ commit }, salesData) {
    commit('setSalesOne', salesData)
  },
  createSales({ commit, state, dispatch }, salesData) {
    console.log(salesData); // make request

    const formattedData = {
      data: JSON.stringify(salesData),
      token: state.authenticated.token
    }

    if (state.attachments[`sales${salesData.id}`]) {
      if (state.attachments[`sales${salesData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`sales${salesData.id}`].video];
      }
      if (state.attachments[`sales${salesData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`sales${salesData.id}`].image];
      }
      if (state.attachments[`sales${salesData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`sales${salesData.id}`].document];
      }
    }
    
    dataFetch(`${api}/sales`, postOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      // commit('setFarms', {rawData: [{...resData}]}); // farmData
      dispatch('getSales');
      commit('setNewSales', resData)

    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  editSales({commit, state, dispatch}, salesData) {
    console.log(salesData); // make request, getSales

    const formattedData = {
      // data: JSON.stringify(salesData),
      token: state.authenticated.token
    }

    if (state.attachments[`sales${salesData.id}`]) {
      if (state.attachments[`sales${salesData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`sales${salesData.id}`].video];
        salesData.video = [...state.attachments[`sales${salesData.id}`].video];
      }
      if (state.attachments[`sales${salesData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`sales${salesData.id}`].image];
        salesData.image = [...state.attachments[`sales${salesData.id}`].image];
      }
      if (state.attachments[`sales${salesData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`sales${salesData.id}`].document];
        salesData.document = [...state.attachments[`sales${salesData.id}`].document];
      }
    }

    formattedData.data = JSON.stringify(salesData);

    dataFetch(`${api}/sales/${salesData._id}`, patchOptionsAuthFiles({
        ...formattedData,
      })).then(resData => {
        console.log(resData);

        // dispatch('getFarms');
        // commit('setFarmChanges', {...resData}); // farmData
        dispatch('getSales');
        commit('setSalesChanges', resData)

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  removeSales({commit, state, dispatch}, salesId) {
    console.log(salesId) // make request

    const selectedSale = state.sales.find(saleState => (saleState.id === salesId));
    let sale_id;

    if (selectedSale) {
      sale_id = selectedSale._id;
    }

    console.log(sale_id, 'sale_id');

    dataFetch(`${api}/sales/${sale_id}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setFilteredSales', salesId)

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },

  getCustomers({ commit, state, dispatch }) {

    dataFetch(`${api}/salescustomers`, getOptionsAuth({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setCustomers', resData);

        resData.forEach(customer => {
          if (customer?.video?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'video',
              attachmentSection: 'customer',
              attachmentData: customer.video,
              attachmentInstance: customer.id
            })
          }
          if (customer?.image?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'image',
              attachmentSection: 'customer',
              attachmentData: customer.image,
              attachmentInstance: customer.id
            })
          }
          if (customer?.document?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'document',
              attachmentSection: 'customer',
              attachmentData: customer.document,
              attachmentInstance: customer.id
            })
          }
        })

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
  },
  getCustomer({ commit }, customerData) {
    commit('setCustomer', customerData)
  },
  createCustomer({ commit, state, dispatch }, customerData) {
    console.log(customerData); // make request

    const formattedData = {
      data: JSON.stringify(customerData),
      token: state.authenticated.token
    }

    if (state.attachments[`customer${customerData.id}`]) {
      if (state.attachments[`customer${customerData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`customer${customerData.id}`].video];
      }
      if (state.attachments[`customer${customerData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`customer${customerData.id}`].image];
      }
      if (state.attachments[`customer${customerData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`customer${customerData.id}`].document];
      }
    }
    
    dataFetch(`${api}/salescustomers`, postOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      // commit('setFarms', {rawData: [{...resData}]}); // farmData
      dispatch('getCustomers');
      commit('setNewCustomer', customerData)
      
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  editCustomer({commit, state, dispatch}, customerData) {
    console.log(customerData); // make request, getCustomers

    const formattedData = {
      // data: JSON.stringify(customerData),
      token: state.authenticated.token
    }

    if (state.attachments[`customer${customerData.id}`]) {
      if (state.attachments[`customer${customerData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`customer${customerData.id}`].video];
        customerData.video = [...state.attachments[`customer${customerData.id}`].video];
      }
      if (state.attachments[`customer${customerData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`customer${customerData.id}`].image];
        customerData.image = [...state.attachments[`customer${customerData.id}`].image];
      }
      if (state.attachments[`customer${customerData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`customer${customerData.id}`].document];
        customerData.document = [...state.attachments[`customer${customerData.id}`].document];
      }
    }

    formattedData.data = JSON.stringify(customerData);

    dataFetch(`${api}/salescustomers/${customerData._id}`, patchOptionsAuthFiles({
        ...formattedData,
      })).then(resData => {
        console.log(resData);

        // dispatch('getFarms');
        // commit('setFarmChanges', {...resData}); // farmData
        dispatch('getCustomers');
        commit('setCustomerChanges', customerData)

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  removeCustomer({commit, state, dispatch}, customerId) {
    console.log(customerId) // make request

    const selectedCustomer = state.customers.find(customerState => (customerState.id === customerId));
    let customer_id;

    if (selectedCustomer) {
      customer_id = selectedCustomer._id;
    }

    console.log(customer_id, 'customer_id');

    dataFetch(`${api}/salescustomers/${customer_id}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setFilteredCustomers', customerId);
      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },

  getLaborExpenses({ commit, state, dispatch }) {
    dataFetch(`${api}/laborexpenses`, getOptionsAuth({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setLaborExpenses', resData);

        resData.forEach(laborExpense => {
          if (laborExpense?.video?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'video',
              attachmentSection: 'laborExpense',
              attachmentData: laborExpense.video,
              attachmentInstance: laborExpense.id
            })
          }
          if (laborExpense?.image?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'image',
              attachmentSection: 'laborExpense',
              attachmentData: laborExpense.image,
              attachmentInstance: laborExpense.id
            })
          }
          if (laborExpense?.document?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'document',
              attachmentSection: 'laborExpense',
              attachmentData: laborExpense.document,
              attachmentInstance: laborExpense.id
            })
          }
        })

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  getLaborExpense({ commit }, laborExpensesData) {
    commit('setLaborExpense', laborExpensesData)
  },
  createLaborExpense({ commit, state, dispatch }, laborExpenseData) {
    console.log(laborExpenseData); // make request

    const formattedData = {
      data: JSON.stringify(laborExpenseData),
      token: state.authenticated.token
    }

    if (state.attachments[`task${laborExpenseData.id}`]) {
      if (state.attachments[`task${laborExpenseData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`task${laborExpenseData.id}`].video];
      }
      if (state.attachments[`task${laborExpenseData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`task${laborExpenseData.id}`].image];
      }
      if (state.attachments[`task${laborExpenseData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`task${laborExpenseData.id}`].document];
      }
    }
    
    dataFetch(`${api}/laborexpenses`, postOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      // commit('setFarms', {rawData: [{...resData}]}); // farmData
      dispatch('getLaborExpenses');
      commit('setNewLaborExpense', laborExpenseData);
      
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  editLaborExpense({commit, state, dispatch}, laborExpensesData) {
    console.log(laborExpensesData); // make request, getSales

    const formattedData = {
      // data: JSON.stringify(laborExpensesData),
      token: state.authenticated.token
    }

    if (state.attachments[`task${laborExpensesData.id}`]) {
      if (state.attachments[`task${laborExpensesData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`task${laborExpensesData.id}`].video];
        laborExpensesData.video = [...state.attachments[`task${laborExpensesData.id}`].video];
      }
      if (state.attachments[`task${laborExpensesData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`task${laborExpensesData.id}`].image];
        laborExpensesData.image = [...state.attachments[`task${laborExpensesData.id}`].image];
      }
      if (state.attachments[`task${laborExpensesData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`task${laborExpensesData.id}`].document];
        laborExpensesData.document = [...state.attachments[`task${laborExpensesData.id}`].document];
      }
    }

    formattedData.data = JSON.stringify(laborExpensesData);

    dataFetch(`${api}/laborexpenses/${laborExpensesData._id}`, patchOptionsAuthFiles({
        ...formattedData,
      })).then(resData => {
        console.log(resData);

        // dispatch('getFarms');
        // commit('setFarmChanges', {...resData}); // farmData
        dispatch('getLaborExpenses');
        commit('setLaborExpenseChanges', resData)

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  removeLaborExpense({commit, state, dispatch}, laborExpenseId) {
    console.log(laborExpenseId) // make request

    const selectedLaborExpense = state.laborExpenses.find(laborExpenseState => (laborExpenseState.id === laborExpenseId));
    let laborExpense_id;

    if (selectedLaborExpense) {
      laborExpense_id = selectedLaborExpense._id;
    }

    console.log(laborExpense_id, 'laborExpense_id');

    dataFetch(`${api}/laborexpenses/${laborExpense_id}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setFilteredLaborExpenses', laborExpenseId);

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },

  getAdminExpenses({ commit, state, dispatch }) {

    dataFetch(`${api}/adminexpenses`, getOptionsAuth({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setAdminExpenses', resData);

        resData.forEach(adminExpense => {
          if (adminExpense?.video?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'video',
              attachmentSection: 'adminExpense',
              attachmentData: adminExpense.video,
              attachmentInstance: adminExpense.id
            })
          }
          if (adminExpense?.image?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'image',
              attachmentSection: 'adminExpense',
              attachmentData: adminExpense.image,
              attachmentInstance: adminExpense.id
            })
          }
          if (adminExpense?.document?.length > 0) {
            commit('setServerAttachments', {
              attachmentType: 'document',
              attachmentSection: 'adminExpense',
              attachmentData: adminExpense.document,
              attachmentInstance: adminExpense.id
            })
          }
        })

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  getAdminExpense({ commit }, adminExpensesData) {
    commit('setAdminExpense', adminExpensesData)
  },
  createAdminExpense({ commit, state, dispatch }, adminExpenseData) {
    console.log(adminExpenseData); // make request

    const formattedData = {
      data: JSON.stringify(adminExpenseData),
      token: state.authenticated.token
    }

    if (state.attachments[`adminExpense${adminExpenseData.id}`]) {
      if (state.attachments[`adminExpense${adminExpenseData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`adminExpense${adminExpenseData.id}`].video];
      }
      if (state.attachments[`adminExpense${adminExpenseData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`adminExpense${adminExpenseData.id}`].image];
      }
      if (state.attachments[`adminExpense${adminExpenseData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`adminExpense${adminExpenseData.id}`].document];
      }
    }
    
    dataFetch(`${api}/adminexpenses`, postOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      // commit('setFarms', {rawData: [{...resData}]}); // farmData
      dispatch('getAdminExpenses');
      commit('setNewAdminExpense', resData);
      
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  editAdminExpense({commit, state, dispatch}, adminExpensesData) {
    console.log(adminExpensesData); // make request, getSales

    const formattedData = {
      // data: JSON.stringify(adminExpensesData),
      token: state.authenticated.token
    }

    if (state.attachments[`adminExpense${adminExpensesData.id}`]) {
      if (state.attachments[`adminExpense${adminExpensesData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`adminExpense${adminExpensesData.id}`].video];
        adminExpensesData.video = [...state.attachments[`adminExpense${adminExpensesData.id}`].video];
      }
      if (state.attachments[`adminExpense${adminExpensesData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`adminExpense${adminExpensesData.id}`].image];
        adminExpensesData.image = [...state.attachments[`adminExpense${adminExpensesData.id}`].image];
      }
      if (state.attachments[`adminExpense${adminExpensesData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`adminExpense${adminExpensesData.id}`].document];
        adminExpensesData.document = [...state.attachments[`adminExpense${adminExpensesData.id}`].document];
      }
    }

    formattedData.data = JSON.stringify(adminExpensesData);

    dataFetch(`${api}/adminexpenses/${adminExpensesData._id}`, patchOptionsAuthFiles({
        ...formattedData,
      })).then(resData => {
        console.log(resData);

        // dispatch('getFarms');
        // commit('setFarmChanges', {...resData}); // farmData
        dispatch('getAdminExpenses');
        commit('setAdminExpenseChanges', resData);

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  removeAdminExpense({commit, state, dispatch}, adminExpenseId) {
    console.log(adminExpenseId) // make request

    const selectedAdminExpense = state.adminExpenses.find(adminExpenseState => (adminExpenseState.id === adminExpenseId));
    let adminExpense_id;

    if (selectedAdminExpense) {
      adminExpense_id = selectedAdminExpense._id;
    }

    console.log(adminExpense_id, 'adminExpense_id');

    dataFetch(`${api}/adminexpenses/${adminExpense_id}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setFilteredAdminExpenses', adminExpenseId);

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },

  getCompensations({ commit, state, dispatch }) {

    dataFetch(`${api}/compensations`, getOptionsAuth({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setCompensations', resData);

        resData.forEach(employeeCompensation => {
          if (employeeCompensation?.video?.length > 0) {
            console.log(employeeCompensation.video, 'employeeCompensation attachment');
            commit('setServerAttachments', {
              attachmentType: 'video',
              attachmentSection: 'employeeCompensation',
              attachmentData: employeeCompensation.video,
              attachmentInstance: employeeCompensation.id
            })
          }
          if (employeeCompensation?.image?.length > 0) {
            console.log(employeeCompensation.image, 'employeeCompensation attachment');
            commit('setServerAttachments', {
              attachmentType: 'image',
              attachmentSection: 'employeeCompensation',
              attachmentData: employeeCompensation.image,
              attachmentInstance: employeeCompensation.id
            })
          }
          if (employeeCompensation?.document?.length > 0) {
            console.log(employeeCompensation.document, 'employeeCompensation attachment');
            commit('setServerAttachments', {
              attachmentType: 'document',
              attachmentSection: 'employeeCompensation',
              attachmentData: employeeCompensation.document,
              attachmentInstance: employeeCompensation.id
            })
          }
        })

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  getCompensation({ commit }, compensationsData) {
    commit('setCompensation', compensationsData)
  },
  createCompensation({ commit, state, dispatch }, compensationData) {
    console.log(compensationData); // make request

    delete compensationData._id;

    const formattedData = {
      data: JSON.stringify(compensationData),
      token: state.authenticated.token
    }

    if (state.attachments[`employeeCompensation${compensationData.id}`]) {
      if (state.attachments[`employeeCompensation${compensationData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`employeeCompensation${compensationData.id}`].video];
      }
      if (state.attachments[`employeeCompensation${compensationData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`employeeCompensation${compensationData.id}`].image];
      }
      if (state.attachments[`employeeCompensation${compensationData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`employeeCompensation${compensationData.id}`].document];
      }
    }
    
    dataFetch(`${api}/compensations`, postOptionsAuthFiles({
      ...formattedData,
    })).then(resData => {
      console.log(resData);

      // dispatch('getFarms');
      // commit('setFarms', {rawData: [{...resData}]}); // farmData
      dispatch('getCompensations');
      commit('setNewCompensation', resData);
      
    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  editCompensation({commit, state, dispatch}, compensationsData) {
    console.log(compensationsData); // make request, getSales

    const formattedData = {
      // data: JSON.stringify(compensationsData),
      token: state.authenticated.token
    }

    if (state.attachments[`employeeCompensation${compensationsData.id}`]) {
      if (state.attachments[`employeeCompensation${compensationsData.id}`].video.length > 0) {
        formattedData.video = [...state.attachments[`employeeCompensation${compensationsData.id}`].video];
        compensationsData.video = [...state.attachments[`employeeCompensation${compensationsData.id}`].video];
      }
      if (state.attachments[`employeeCompensation${compensationsData.id}`].image.length > 0) {
        formattedData.image = [...state.attachments[`employeeCompensation${compensationsData.id}`].image];
        compensationsData.image = [...state.attachments[`employeeCompensation${compensationsData.id}`].image];
      }
      if (state.attachments[`employeeCompensation${compensationsData.id}`].document.length > 0) {
        formattedData.document = [...state.attachments[`employeeCompensation${compensationsData.id}`].document];
        compensationsData.document = [...state.attachments[`employeeCompensation${compensationsData.id}`].document];
      }
    }

    formattedData.data = JSON.stringify(compensationsData);

    dataFetch(`${api}/compensations/${compensationsData._id}`, patchOptionsAuthFiles({
        ...formattedData,
      })).then(resData => {
        console.log(resData);

        // dispatch('getFarms');
        // commit('setFarmChanges', {...resData}); // farmData
        dispatch('getCompensations');
        commit('setCompensationChanges', resData);

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  removeCompensation({commit, state, dispatch}, compensationId) {
    console.log(compensationId) // make request

    const selectedCompensation = state.compensations.find(compensationState => (compensationState.id === compensationId));
    let compensation_id;

    if (selectedCompensation) {
      compensation_id = selectedCompensation._id;
    }

    console.log(compensation_id, 'compensation_id');

    dataFetch(`${api}/compensations/${compensation_id}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        commit('setFilteredCompensations', compensationId);

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },

  getSearchResults({commit, state }, searchRow) {
    console.log(searchRow);

    const isAuth = (featureCode) => {
      return isAuthorized(state.authenticated, featureCode)
    }
    // filter throough these arrays to find results:

    // farms: [],
    const filteredFarms = isAuth('farms') ? state.farms.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.farmName,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Farms',
            section: 'farm/farm-profile',
            text: ''
          }
        }
      }
    }) : []
    // crops: [],
    const filteredCrops = isAuth('crops') ? state.crops.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.crop,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Crops',
            section: 'crops',
            text: ''
          }
        }
      }
    }) : [];
    // tasks: [],
    const filteredTasks = isAuth('tasks') ? state.tasks.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.task,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Tasks',
            section: 'farm/task',
            text: ''
          }
        }
      }
    }) : [];
    // addedInventories: [],
    const filteredAddedInventories = isAuth('added-inventories') ? state.addedInventories.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.product,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Inventories',
            section: 'inventory/inventory',
            text: ''
          }
        }
      }
    }) : [];
    // inventoriesUsed: [],
    const filteredInventoriesUsed = isAuth('inventories-used') ? state.inventoriesUsed.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.product,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Inventories Used',
            section: 'inventory/inventory',
            text: ''
          }
        }
      }
    }) : [];
    // vendors: [],
    const filteredVendores = isAuth('vendors') ? state.vendors.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.vendor,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Vendors',
            section: 'vendors/vendor-module',
            text: ''
          }
        }
      }
    }) : [];
    // vendorInventories: [],
    const filteredVendorInventories = isAuth('vendor-inventories') ? state.vendorInventories.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.product || obj.vendor,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Inventories Vendors',
            section: 'inventory/inventory',
            text: ''
          }
        }
      }
    }) : [];
    // vendorEquipments: [],
    const filteredVendorEquipments = isAuth('vendor-equipments') ? state.vendorEquipments.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.equipment || obj.vendor,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Vendor Equipments',
            section: 'equipment/equipment',
            text: ''
          }
        }
      }
    }) : [];
    // vendorServices: [],
    const filteredVendorServices = isAuth('vendor-services') ? state.vendorServices.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.service || obj.vendor,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Vendor Services',
            section: 'equipment/equipment-service',
            text: ''
          }
        }
      }
    }) : [];
    // equipments: [],
    const filteredEquipments = isAuth('equipments') ? state.equipments.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.equipment,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Equipments',
            section: 'equipments/equipment',
            text: ''
          }
        }
      }
    }) : [];
    // users: [],
    const filteredUsers = isAuth('users') ? state.users.filter((obj) => {
      for (let prop in obj.mainDetails) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj.mainDetails[prop]}`.includes(searchRow)) {
          return {
            ...obj.mainDetails,
            header: obj.mainDetails.email,
            datePosted: obj.mainDetails.datePosted,
            author: obj.mainDetails.author,
            sectionHeader: 'Users',
            section: 'admin/admin-users',
            text: ''
          }
        }
        console.log(obj);
      }
    }) : [];
    // archivedUsers: [],
    const filteredArchivedUsers = isAuth('archived-users') ? state.archivedUsers.map((obj) => {
      for (let prop in obj.mainDetails) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj.mainDetails[prop]}`.includes(searchRow)) {
          return {
            ...obj.mainDetails,
            header: obj.mainDetails.email,
            datePosted: obj.mainDetails.datePosted,
            author: obj.mainDetails.author,
            sectionHeader: 'Users',
            section: 'admin/admin-users',
            text: ''
          }
        }
      }
    }) : [];
    // harvests: [],
    const filteredHarvests = isAuth('harvests') ? state.harvests.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.crop,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Harvests',
            section: 'crops/harvest-module',
            text: ''
          }
        }
      }
    }) : [];
    // sales: [],
    const filteredSales = isAuth('sales') ? state.sales.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.crop || obj.cropCategory || obj.farm,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Sales',
            section: 'sales/sales-module',
            text: ''
          }
        }
      }
    }) : [];
    // customers: [],
    const filteredCustomers = isAuth('customers') ? state.customers.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.customer,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Customers',
            section: 'sales/customers-module',
            text: ''
          }
        }
      }
    }) : [];
    // laborExpenses: [],
    const filteredLaborExpenses = isAuth('labor-expenses') ? state.laborExpenses.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.assignedTo || obj.task,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Labor Expenses',
            section: 'admin/labor-expenses',
            text: ''
          }
        }
      }
    }) : [];
    // adminExpenses: [],
    const filteredAdminExpenses = isAuth('admin-expenses') ? state.adminExpenses.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.employee,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Admin Expenses',
            section: 'admin/admin-expenses',
            text: ''
          }
        }
      }
    }) : [];
    // compensations: []
    const filteredCompensations = isAuth('compensations') ? state.compensations.map((obj) => {
      for (let prop in obj) {
        // console.log(`${obj[prop]}`.includes(searchRow));
        if (`${obj[prop]}`.includes(searchRow)) {
          return {
            ...obj,
            header: obj.employeeTitle,
            datePosted: obj.datePosted,
            author: obj.author,
            sectionHeader: 'Compensations',
            section: 'admin/employee-compensation',
            text: ''
          }
        }
      }
    }) : [];

    const combinedResults = [
      ...filteredFarms,
      ...filteredCrops,
      ...filteredTasks,
      ...filteredAddedInventories,
      ...filteredInventoriesUsed,
      ...filteredVendores,
      ...filteredVendorInventories,
      ...filteredVendorEquipments,
      ...filteredVendorServices,
      ...filteredEquipments,
      ...filteredUsers,
      ...filteredArchivedUsers,
      ...filteredHarvests,
      ...filteredSales,
      ...filteredCustomers,
      ...filteredLaborExpenses,
      ...filteredAdminExpenses,
      ...filteredCompensations
    ]

    commit('setFilteredResults', combinedResults);
  },

  // Accounts
  getAccounts({ commit, state, dispatch}) {

    dataFetch(`${api}/accounts`, getOptionsAuth({
      token: state.authenticated.superToken
    }))
      .then(resData => {
        console.log(resData);
        commit('setAccounts', resData)

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
  },
  // removeAccount({commit}, accountId) {
  //   console.log(accountId) // make request
  //   commit('setRemovedAccount', accountId)
  // },

  getOneAccount({state, dispatch}, account_id) {
    
    dataFetch(`${api}/accounts/${account_id}`, getOptionsAuth({
      token: state.authenticated.superToken
    }))
      .then(resData => {
        console.log(resData);
        dispatch('authenticateUser', resData);

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
  },

  changeAccountStatus({commit, state, dispatch}, {id, status}) {

    const selectedAccount = state.accounts.find(account => (account.id === id));
    let account_id;

    if (selectedAccount) {
      account_id = selectedAccount._id;
    }

    dataFetch(`${api}/accounts/${account_id}`, patchOptionsAuth({
      data: { status },
      token: state.authenticated.superToken
    })).then(resData => {
      console.log(resData);

      dispatch('getAccounts');
      commit('setAccountChanges', resData);

    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },

  archiveAccount({commit, state, dispatch}, accountId) {
    
    const selectedAccount = state.accounts.find(accountState => (accountState.id === accountId));
    let account_id;

    if (selectedAccount) {
      account_id = selectedAccount._id;
    }

    console.log(account_id, 'account_id');

    dataFetch(`${api}/accounts/${account_id}`, deleteOptionsAuth({//deleteOptions({
      token: state.authenticated.superToken
    }))
      .then(resData => {
        console.log(resData);
        commit('setFilteredAccounts', accountId)

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});
    
  },

  addNotification({commit}, notificationData) {
    commit('setNewNotification', notificationData)
  },

  readNotifications({commit}, notificationId) {
    commit('setNotificationChanges', notificationId)
  },

  removeNotification({commit}, notificationId) {
    commit('setFilteredNotifications', notificationId)
  },
  addAdvertisementGeographies ({commit}, advertisementGeography) {
    commit('setNewAdvertisementGeography', advertisementGeography)
  },
  editAdvertisementGeographies ({commit}, advertisementGeographyData) {
    commit('setAdvertisementGeographyChanges', advertisementGeographyData)
  },
  removeAdvertisementGeographies ({commit}, advertisementGeographyId) {
    commit('setFilteredAdvertisementGeographies', advertisementGeographyId)
  },

  getResources({ commit, state, dispatch }) {

    dataFetch(`${api}/resources`, getOptionsAuth({
      token: state.authenticated.token
    }))
      .then(resData => {
        console.log(resData);
        // commit('setSales', resData);
        commit('setResources', resData);


      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  createResource({ commit, state, dispatch }, resourceData) {
    console.log(resourceData); // make request

    const dataOnly = {};

    for (let prop in resourceData) {
      if (prop !== 'file') {
        dataOnly[prop] = resourceData[prop];
      }
    }

    const formattedData = {
      data: JSON.stringify(dataOnly),
      file: resourceData.file,
      token: state.authenticated.token
    }
    
    dataFetch(`${api}/resources`, postOptionsAuthResources({
      ...formattedData,
    })).then(resData => {
      console.log(resData);

      // dispatch('getSales');
      // commit('setNewSales', resData)
      dispatch('getResources');
      commit('setNewResource', resData)

    }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  editResource({commit, state, dispatch}, resourceData) {
    console.log(resourceData); // make request, getSales

    const dataOnly = {};

    for (let prop in resourceData) {
      if (prop !== 'file') {
        dataOnly[prop] = resourceData[prop];
      }
    }

    const formattedData = {
      data: JSON.stringify(dataOnly),
      file: resourceData.file,
      token: state.authenticated.token
    }

    dataFetch(`${api}/resources/${resourceData._id}`, patchOptionsAuthResources({
        ...formattedData,
      })).then(resData => {
        console.log(resData);

        // dispatch('getSales');
        // commit('setSalesChanges', resData)
        dispatch('getResources');
        commit('setResourceChanges', resData);

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  removeResource({commit, state, dispatch}, {_id, id, category, title}) {
    console.log(id) // make request

    // const selectedResource = state.resources.find(resourceState => (resourceState.id === id));
    // let resource_id;

    // if (selectedResource) {
    //   resource_id = selectedResource._id;
    // }
    const resource_id = _id;

    console.log(resource_id, 'resource_id');

    dataFetch(`${api}/resources/remove/${resource_id}`, patchOptionsAuth({//deleteOptions({
      token: state.authenticated.token,
      category, title
    }))
      .then(resData => {
        console.log(resData);
        // commit('setFilteredSales', salesId)
        commit('setFilteredResources', id)

      }).catch(e => {console.log(e); if (e.status === 401) { dispatch('logOutApi') }});

  },
  openResource({commit, state}, {category, title, link}) {

      if (category === 'Document') {
        dataFetch(`${api}/resources/open`, postOptionsAuthGetFiles({
          category, title,
          token: state.authenticated.token
        }))
          // .then(resData => (resData.ok ? resData.blob() : Promise.reject(resData)))
          .then(resData => {
            console.log(resData);

            commit('setSelectedResourceFile', {file: resData.file, fileType: resData.fileType, fileId: resData.fileId});
          }).catch(e => {
            console.log(e); 
            // if (e.status === 401) { 
            //   dispatch('logOutApi') 
            // }
          });

      } else if (category === 'Video' || category === 'Link') {
        commit('setSelectedResourceFile', {file: link, fileType: category, fileId: ''});
      } else {
        dataFetchFiles(`${api}/resources/open`, postOptionsAuthGetFiles({
          category, title,
          token: state.authenticated.token
        }))
          // .then(resData => (resData.ok ? resData.blob() : Promise.reject(resData)))
          .then(blob => {
            console.log(blob);
            const blobUrl = URL.createObjectURL(blob);
  
            commit('setSelectedResourceFile', {file: blobUrl, fileType: category});
          }).catch(e => {
            console.log(e); 
            // if (e.status === 401) { 
            //   dispatch('logOutApi') 
            // }
          });
      }

  },
  closeResource({commit, state}) {
    if (state.selectedResourceFile.fileId.length > 0) {
      dataFetch(`${api}/resources/close`, postOptionsAuthGetFiles({
        fileId: state.selectedAttachedFile.fileId,
        token: state.authenticated.token
      }))
        // .then(resData => (resData.ok ? resData.blob() : Promise.reject(resData)))
        .then(resData => {
          console.log(resData);

          commit('setSelectedResourceFile', {
            file: null,
            fileType: '',
            fileId: ''
          });

        }).catch(e => {
          console.log(e); 
          // if (e.status === 401) { 
          //   dispatch('logOutApi') 
          // }
        });
    } else {
      commit('setSelectedResourceFile', {
        file: null,
        fileType: '',
        fileId: ''
      });
    }
  },
};

// mutations
const mutations = {
  initialiseVars(state) {
    if (localStorage.getItem('farmappStore')) {
      const store = JSON.parse(localStorage.farmappStore);
      if (store.authenticated) {
        state.authenticated = store.authenticated
      }
      if (store.primaryUser) {
        state.primaryUser = store.primaryUser
      }
      if (store.secondaryUser) {
        state.secondaryUser = store.secondaryUser
      }
      if (store.primaryUserTenant) {
        state.primaryUserTenant = store.primaryUserTenant
      }
      if (store.subscriptionPlan) {
        state.subscriptionPlan = store.subscriptionPlan
      }
      if (store.savedAdminSubscription) {
        state.savedAdminSubscription = store.savedAdminSubscription
      }
      /*
      if (store.farms) {
        state.farms = store.farms
      }
      if (store.crops) {
        state.crops = store.crops
      }
      if (store.attachments) {
        state.attachments = store.attachments
      }
      if (store.tasks) {
        state.tasks = store.tasks
      }
      if (store.addedInventories) {
        state.addedInventories = store.addedInventories
      }
      if (store.inventoriesUsed) {
        state.inventoriesUsed = store.inventoriesUsed
      }
      if (store.vendors) {
        state.vendors = store.vendors
      }
      if (store.vendorItems) {
        state.vendorItems = store.vendorItems
      }
      if (store.plannedInventories) {
        state.plannedInventories = store.plannedInventories
      }
      // if (store.vendorInventories) {
      //   state.vendorInventories = store.vendorInventories
      // }
      // if (store.vendorEquipments) {
      //   state.vendorEquipments = store.vendorEquipments
      // }
      // if (store.vendorServices) {
      //   state.vendorServices = store.vendorServices
      // }
      if (store.equipments) {
        state.equipments = store.equipments
      }
      if (store.harvests) {
        state.harvests = store.harvests
      }
      if (store.users) {
        state.users = store.users
      }
      if (store.archivedUsers) {
        state.archivedUsers = store.archivedUsers
      }
      if (store.harvests) {
        state.harvests = store.harvests
      }
      if (store.sales) {
        state.sales = store.sales
      }
      if (store.customers) {
        state.customers = store.customers
      }
      if (store.laborExpenses) {
        state.laborExpenses = store.laborExpenses
      }
      if (store.adminExpenses) {
        state.adminExpenses = store.adminExpenses
      }
      if (store.compensations) {
        state.compensations = store.compensations
      }
      if (store.notifications) {
        state.notifications = store.notifications
      }
      if (store.advertisementAccount) {
        state.advertisementAccount = store.advertisementAccount
      }
      if (store.advertisementGeographies) {
        state.advertisementGeographies = store.advertisementGeographies
      }
      if (store.fileUploads) {
        state.fileUploads = store.fileUploads
      }
      if (store.advertisementPayment) {
        state.advertisementPayment = store.advertisementPayment
      }
      if (store.advertisementsList) {
        state.advertisementsList = store.advertisementsList
      }
      if (store.myAdvertisementsList) {
        state.myAdvertisementsList = store.myAdvertisementsList
      }
      if (store.archiveFarmPlans) {
        state.archiveFarmPlans = store.archiveFarmPlans
      }
      */
    }
  },
  setUserToken(state, token) {
    state.authenticated.token = token;
  },
  setAuthenticatedData(state, {field, val}) {
    state.authenticated[field] = val;
    if (field === 'accountSubdomain') {
      console.log(field, val);
    }
  },
  setCurrentPath(state, {section, val}) {
    state.currentPath[section] = val;
  },
  setPrimaryUserData(state, {section, data}) {
    if (section === 'billing') {
      
      console.log(data.paymentMethodId, state.primaryUser.billing.map(bil => bil.paymentMethodId));
      
      console.log(Array.isArray(data), data, 'Array.isArray(data)');

      if (Array.isArray(data)) {
        state.primaryUser.billing = data;
      } else {
        const toBeUpdated = state.primaryUser.billing.findIndex(bil => bil.paymentMethodId === data.paymentMethodId);

        if (toBeUpdated >= 0) {
          console.log(toBeUpdated, 'toBeUpdated');

          state.primaryUser.billing[toBeUpdated] = {
            ...data
          }
        } else {
          console.log('push billing');
          state.primaryUser.billing.unshift({...data});
        }
      }

    } else {
      state.primaryUser[section] = {
        // ...state.primaryUser[section],
        ...data
      }
      if (section === 'mainDetails') {
        state.farms.forEach(farm => {
          if (farm.sameAddress) {
            for (let prop in farm) {
              if (data[prop] && 
                (data[prop].length > 0)) {
                if (prop !== 'id') {
                  farm[prop] = data[prop];
                }
              }
            }
          }
        })
        state.users.forEach(user => {
          if (user.mainDetails.accountName === data.accountName) {
            user.mainDetails.address = data.address;
            user.mainDetails.address2 = data.address2;
            user.mainDetails.city = data.city;
            user.mainDetails.state = data.state;
            user.mainDetails.zip = data.zip;
            user.mainDetails.country = data.country;
          }
        })
      }
    }
  },
  setTemporaryUserData(state, {section, data}) {
    state.temporaryUser[section] = {
      // ...state.primaryUser[section],
      ...data
    }
  },
  setPrimaryUserTenant(state, {resData}) {
    state.primaryUserTenant = {
      ...resData,
      id: resData.id || state.primaryUserTenant.id
    };
  },
  logOut(state) {
    state.authenticated = {
      token: '',
      role: {
        id: '',
        key: '',
        permissions: []
      },
      userRole: '',
      registrationDate: null,
      paymentMethods: [],
      selectedPayment: null,
      approvedPayment: false,
      author: ''
    },
    state.primaryUser = {
      payment: {},
      billing: []
    },
    state.secondaryUser = {
      payment: {},
      subscriptionAssigned: {}
    }
    localStorage.removeItem("farmappStore");
    setTimeout(() => {
      window.location = `https://bhoomiapp.com/login`;
    }, 1000)
  },
  logOutApi(state) {
    console.log(state);
    // state.authenticated = {
    //   token: '',
    //   role: {
    //     id: '',
    //     key: '',
    //     permissions: []
    //   },
    //   userRole: '',
    //   registrationDate: null,
    //   paymentMethods: [],
    //   selectedPayment: null,
    //   approvedPayment: false,
    //   author: ''
    // },
    // state.primaryUser = {
    //   payment: {},
    //   billing: []
    // },
    // state.secondaryUser = {
    //   payment: {},
    //   subscriptionAssigned: {}
    // }
    // setTimeout(() => {
    //   window.location = `http://bhoomiapp.com/login`;
    // }, 1000)
  },
  setServerAttachments(state, {attachmentType, attachmentSection, attachmentInstance, attachmentData}) {

    const determineTypeFromServer = (fileFormat) => {
      if ((fileFormat === 'doc') || (fileFormat.includes('word'))) return 'word';
      if (fileFormat === 'docx') return 'word';
      if (fileFormat.includes('document')) return 'document';
      if (fileFormat.includes('image')) return 'image';
      if (fileFormat.includes('video')) return 'video';
      return fileFormat;
    }
    
    const fileSizeCalculatedFromServer = (targetFileSize) => {
      const sizeInBytes = targetFileSize;
      if (sizeInBytes < 1024) return `1 KB`
      const kbs = Math.abs(
        Number.parseInt(sizeInBytes) / 1024
      )
      if (kbs < 1024) return `${kbs.toFixed(2)} KB`
      if (kbs > 1024) return `${Math.abs(kbs / 1024).toFixed(2)} MB`
      if (kbs > Math.abs(1024 * 1024)) return `${Math.abs(kbs / (1024 * 1024)).toFixed(2)} GB`
    }

    attachmentData.forEach(file => {
      file.id = file.id || uuid();
      file.dateCreated = moment(file.dateCreated).format('MM/DD/YYYY');
      file.fileType = determineTypeFromServer(file.fileType);
      file.fileSize = fileSizeCalculatedFromServer(file.fileSize);
      // file.selected = false
    });
    
    if (attachmentInstance) {
      if (!state.attachments[`${attachmentSection}${attachmentInstance}`]) {
        state.attachments[`${attachmentSection}${attachmentInstance}`] = {
          document: [],
          video: [],
          image: []
        };
      }

      state.attachments[`${attachmentSection}${attachmentInstance}`][attachmentType] = attachmentData;
    } else {
      state.attachments[attachmentSection][attachmentType] = attachmentData
    }
  },
  setSelectedAttachedFile(state, {file, fileType, fileId}) {
    state.selectedAttachedFile.file = file || null;
    state.selectedAttachedFile.fileType = fileType || '';
    state.selectedAttachedFile.fileId = fileId || '';
  },
  setSubscriptionPlan(state, {resData}) {
    state.subscriptionPlan = {
      ...resData
    }
    const safeTrial = state.primaryUser.subscription.plans.trial;
    state.primaryUser.subscription = {
      ...resData._doc
    }
    if (state.primaryUser.subscription?.plans) {
      state.primaryUser.subscription.plans.trial = safeTrial || resData?._doc?.plans?.trial;
    }
  },
  setSubscriptionPlanChanges(state, {resData}) {
    state.subscriptionPlan = {
      ...state.subscriptionPlan,
      _doc: resData._doc
    }
    state.primaryUser.subscription = {
      ...resData._doc
    }
  },
  setAssignedSubscription(state, {assignedPlan, unassignedPlan}) {
    for (let plan in state.primaryUser.subscription.plans) {
      
      if ((state.primaryUser.subscription.plans[plan] === assignedPlan[plan]) && assignedPlan[plan] === true) {

        const planCounter = Number.parseInt(state.primaryUser.subscription.plans[`${plan}Number`]);

        // state.primaryUser.subscription.plans[`${plan}Available`] = `${planCounter - 1}`;
        // state.primaryUser.subscription.plans[`${plan}Available`] -= 1;

        if (unassignedPlan) {
        //   if (plan === unassignedPlan) {
        //     state.primaryUser.subscription.plans[`${plan}Available`] += 1;
        //   }
        }
        console.log(planCounter);

        // for (let prop in assigned) {
        //   for (let subProp in subscription.plans) {
        //     if (assigned[prop]) {
        //       if (subProp.includes(prop)) {
        //         if ((subProp.includes('Number') || subProp.includes('Price'))) {
        //           assignedPlan[subProp] = subscription.plans[subProp]
        //         } else {
        //           assignedPlan[subProp] = assigned[subProp];
        //         }
  
        //       }
        //     }
        //   }
        // }
      }
      // subProp.includes('Number')
    }
  },
  setAssignedSubscriptionToUser(state, {assignedPlan, userId}) {

    const currentUser = userId === 'primary' ?
      state.primaryUser :
      state.users.find(user => user.mainDetails.id === userId);

    let unassignedPlan;

    if (currentUser.subscriptionAssigned) {
      unassignedPlan = {...currentUser.subscriptionAssigned};
    }

    for (let plan in state.primaryUser.subscription.plans) {
      
      if ((state.primaryUser.subscription.plans[plan] === assignedPlan[plan]) && assignedPlan[plan] === true) {

        const planCounter = Number.parseInt(state.primaryUser.subscription.plans[`${plan}Number`]);

        // state.primaryUser.subscription.plans[`${plan}Available`] = `${planCounter - 1}`;
        state.primaryUser.subscription.plans[`${plan}Available`] = Number.parseInt(state.primaryUser.subscription.plans[`${plan}Available`] || 0) - 1;

        console.log(planCounter);

      }

      if (unassignedPlan) {
        if ((state.primaryUser.subscription.plans[plan] === unassignedPlan[plan]) && unassignedPlan[plan] === true) {
          console.log(state.primaryUser.subscription.plans[`${plan}Available`], `${plan}Available`);
          state.primaryUser.subscription.plans[`${plan}Available`] = Number.parseInt(state.primaryUser.subscription.plans[`${plan}Available`] || 0) + 1;
        }
      }
    }
  },
  setRemovedSubscription(state, removedType) {
    state.primaryUser.subscription.plans[`${removedType}Number`] = Number.parseInt(state.primaryUser.subscription.plans[`${removedType}Number`]) - 1;
    state.primaryUser.subscription.plans[`${removedType}Available`] = Number.parseInt(state.primaryUser.subscription.plans[`${removedType}Available`] || 0) - 1; 
    if (Number.parseInt(state.primaryUser.subscription.plans[`${removedType}Number`]) === 0) {
      state.primaryUser.subscription.plans[removedType] = false;
    }
    // calculate total
  },
  setUpdatedSubscriptionTotals(state, totalData) {
    for (let prop in totalData) {
      state.primaryUser.subscription[prop] = totalData[prop];
    }
  },
  setAddedSubscription(state, addedType) {
    // state.primaryUser.subscription.plans[`${addedType}Number`] += 1;
    state.primaryUser.subscription.plans[`${addedType}Available`] = Number.parseInt(state.primaryUser.subscription.plans[`${addedType}Available`] || 0) + 1;
  },
  setMultipleAvailableSubscriptions(state, assignedSubscriptions) {
    for (let plan in assignedSubscriptions) {
      console.log('setMultipleAvailableSubscriptions', Number.parseInt(state.primaryUser.subscription.plans[`${plan}Available`] || 0))
      // state.primaryUser.subscription.plans[`${plan}Available`] = Number.parseInt(state.primaryUser.subscription.plans[`${plan}Available`] || 0) + Number.parseInt(assignedSubscriptions[plan]);
      console.log('setMultipleAvailableSubscriptions after', Number.parseInt(state.primaryUser.subscription.plans[`${plan}Available`] || 0))
    }
  },
  setSavedAdminSubscription(state, data) {
    state.savedAdminSubscription = {
      ...data
    }
    console.log('setSavedAdminSubscription', state.savedAdminSubscription)
  },
  setSecondaryUserData(state, {section, data}) {
    // state.secondaryUser[section] = {
    //   ...state.secondaryUser[section],
    //   ...data
    // }
    console.log('sec-happening-in', section, data);
    state.secondaryUser[section] = data;
  },
  setUsersList(state, usersData) {
    state.users = [...usersData].filter(user => !user.deleted);
    state.archivedUsers = [...usersData].filter(user => user.deleted);
  },
  setAdvertisementAccountData(state, {section, data}) {
    state.advertisementAccount[section] = {
      // ...state.primaryUser[section],
      ...data
    }
  },
  setNewPayment(state, {resData}) {
    state.advertisementPayment = {
      ...resData
    }
  },
  setFuturePayment(state, {resData}) {
    state.futurePayment = {...resData};
  },
  setPrimaryPaymentIsChanged(state, {isChanged}) {
    state.primaryPaymentIsChanged = isChanged;
  },
  setAdminPaymentIsChanged(state, {isChanged}) {
    state.adminPaymentIsChanged = isChanged;
  },
  setNewAdvertisement(state, {data}) {
    console.log(data, 'new publishment');
    state.advertisementsList.push({
      ...data,
      advertisementGeographies: state.advertisementAccount.advertisementGeographies,
      advertisementRange: state.advertisementAccount.advertisementRange,
      advertisementCost: state.advertisementAccount.advertisementCost
    });
    state.myAdvertisementsList.push({
      ...data,
      advertisementGeographies: state.advertisementAccount.advertisementGeographies,
      advertisementRange: state.advertisementAccount.advertisementRange,
      advertisementCost: state.advertisementAccount.advertisementCost
    });
  },
  setNewUserData (state, {section, data}) {
    const userEdited = 
      state.users.find(user => user.mainDetails?.id === data.id)

    if (userEdited) {
      userEdited[section] = {
        ...data
      }
    } else {
      state.users.push({
        [section]: {
          ...data,
          datePosted: moment().format('MM/DD/YYYY'),
          author: state.authenticated.author,
          header: data.email,
          sectionHeader: 'Users',
          section: 'admin/admin-users',
        }
      })
    }
  },
  setNewUserChanges(state, {userId, section, data}) {
    state.users.forEach(user => {
      if (user.mainDetails.id === userId) {
        user[section] = {
          ...data
        }
      }
    })
  },
  setFilteredUsers(state, userId) {
    const archivedUser = state.users.find(user => user.id === userId)
    archivedUser && state.archivedUsers.push({...archivedUser});
    state.users.splice(state.users.findIndex(user => user.id === userId),1);
  },
  setArchivedPrimary(state) {
    state.primaryUser.mainDetails = {};
    state.primaryUser.subscription = {};
    state.primaryUser.payment = {};
    state.primaryUser.billing = [];
    state.authenticated = {
      role: {
        key: '',
        permissions: []
      }
    };
  },
  setClearedSecondary(state) {
    state.secondaryUser.mainDetails = {};
    state.secondaryUser.subscription = {};
    state.secondaryUser.subscriptionAssigned = {};
  },
  setCompanyData(state, data) {
    console.log(data, 'changing all the users');
    state.users.forEach(user => {
      user.mainDetails.accountName = data.accountName;
      user.mainDetails.workPhone = data.workPhone;
      user.mainDetails.address = data.address;
      user.mainDetails.address2 = data.address2;
      user.mainDetails.city = data.city;
      user.mainDetails.state = data.state;
      user.mainDetails.zip = data.zip;
      user.mainDetails.country = data.country;
    })
  },
  setPaymentMethod(state, {payment, selectedPayment}) {
    console.log(payment, selectedPayment, 'payment, selectedPayment');
    if (typeof selectedPayment === 'number') {
      state.authenticated.paymentMethods[selectedPayment] = payment;
    }
      state.authenticated.selectedPayment = selectedPayment;
      state.authenticated.approvedPayment = true;
    // } else {
    //   state.authenticated.paymentMethods = [
    //     ...state.authenticated.paymentMethods, payment
    //   ];
    // }
  },
  setPaymentMethods(state, paymentMethods) {
    console.log(paymentMethods, 'set paymentMethods');
    state.authenticated.paymentMethods = paymentMethods;
  },
  saveFileLocally(state, { file, place }) {
    state.fileUploads[place] = file;
    console.log(state.fileUploads[place]);
  },
  setPrimaryUserProps(state, { section, field, value }) {
    console.log(state.primaryUser.mainDetails);
    state.primaryUser[section][field] = value;
  },
  setSecondaryUserProps(state, { section, field, value }) {
    console.log(state.secondaryUser.mainDetails);
    state.secondaryUser[section][field] = value;
  },
  setContactMessageStatus(state, status) {
    state.contactMessageIsSent = status;
    state.attachments.contact = {
      video: [],
      image: [],
      document: []
    }
  },
  setResetPasswordStatus(state, status) {
    state.resetPasswordStatus = status;
  },
  setLabors(state, { rawData }) {
    rawData.forEach(entry => {
      const alreadyListed = state.labors.find(farm => farm._id === entry._id);

      if (!alreadyListed) {
        state.labors.push({
          ...entry,
          author: state.authenticated.author,
          datePosted: moment().format('MM/DD/YYYY'),
        })
      }
    });
  },
  setFarms(state, { rawData }) {

    // rawData.forEach(entry => {
    //   const alreadyListed = state.farms.find(farm => farm._id === entry._id);

    //   if (!alreadyListed) {
    //     state.farms.push({
    //       ...entry,
    //       author: state.authenticated.author,
    //       datePosted: moment().format('MM/DD/YYYY'),
    //       beds: [...entry.beds || []]
    //     })
    //   }
    // });
    state.farms = [...rawData];
    
  },
  setFarmsList(state, {index}) {
    state.farms.splice(index,1);

    console.log(state.farms);
  },
  // setCurrentFarm(state, { current }) {
  //   state.currentFarm = current;
  // },
  setFarmProps(state, { field, value }) {
    state.farmDetails[field] = value;
  },
  setFarmDetails(state, farmData) {
    state.farmDetails = farmData
  },
  setFarmChanges(state, farmData) {
    // state.farms[state.farms.findIndex(farm => farm.id === farmData.selectedFarmId)] = {id: farmData.selectedFarmId, ...farmData}

    const currentFarm = state.farms[state.farms.findIndex(farm => (farm.id === farmData.selectedFarmId) || (farm._id === farmData._id))];

    console.log(currentFarm, 'currentFarm');

    for (let prop in currentFarm) {
      if ((prop !== 'drawMap') && (prop !== 'beds')) {
        currentFarm[prop] = farmData[prop];
      }
    }

    for (const key in state.farmDetails) {
      state.farmDetails[key] = ''
    }

 
    if (currentFarm) {
      currentFarm.beds.forEach(stateBed => {
        stateBed.soilType = farmData.soilComposition;
        stateBed.irrigation = farmData.irrigationType;
        stateBed.IOTdeviceType = farmData.IOTdeviceType;
      })
    }

    state.users.forEach(user => {
      if (user.mainDetails.accountName === farmData.farmName) {
        user.mainDetails.address = farmData.address;
        user.mainDetails.address2 = farmData.address2;
        user.mainDetails.city = farmData.city;
        user.mainDetails.state = farmData.state;
        user.mainDetails.zip = farmData.zip;
        user.mainDetails.country = farmData.country;
      }
    })
  },
  setFarmMap(state, {mapDraw, selectedFarmId}) {
    state.farms.forEach(farm => {
      if ((farm.id === selectedFarmId) || (farm._id === selectedFarmId)) {
        // farm.drawMap.push({...mapDraw});
        farm.drawMap = [{...mapDraw}];
      }
    })
  },
  setNewBed(state, bedData) {
    const currentFarm = state.farms.find(farm => farm.id === bedData.farmId);
    bedData.author = state.authenticated.author,
    currentFarm?.beds?.push(bedData);

    if (currentFarm) {
      currentFarm.beds.sort((a,b) => {
        if (a.bedNumber > b.bedNumber) {
          return 1;
        }
        if (a.bedNumber < b.bedNumber) {
          return -1;
        }
        return 0;
      })
    }

    console.log(state.farms);
  },
  setBeds(state, {farmId, bedId, bedIndex}) {
    if (bedIndex) {
      state.farms.forEach(farm => {
        if (farm.id === farmId) {
          farm.beds.splice(bedIndex, 1);
        }
      });
    } else {
      state.farms.forEach(farm => {
        if (farm.id === farmId) {
          farm.beds.splice(farm.beds.findIndex(bed => bed.id === bedId), 1);
        }
      });
    }
  },
  setBedChanges(state, bedData) {
    const currentFarm = state.farms.find(farm => farm.id === bedData.farmId);

    if (currentFarm) {
      currentFarm.beds.forEach(bed => {
        if (bed.id === bedData.id) {
          for (let prop in bedData) {
            bed[prop] = bedData[prop];
          }
        }
        if (bed.sourceId === bedData.sourceId) {
          bed.acreage = bedData.acreage;
          bed.soilType = bedData.soilType;
          bed.irrigation = bedData.irrigation;
          bed.IOTdeviceType = bedData.IOTdeviceType;
        }
      })
    }

    console.log(state.farms);
  },

  setArchivedFarms(state, { rawData }) {
    rawData.forEach(entry => {
      const alreadyListed = state.archiveFarmPlans.find(farm => farm._id === entry._id);

      if (!alreadyListed) {
        state.archiveFarmPlans.push({
          ...entry,
          author: state.authenticated.author,
          datePosted: moment().format('MM/DD/YYYY'),
          beds: [...entry?.farm?.beds || []]
        })
      }
    });
  },
  setArchivedFarmPlan(state, {mapDraw, crop, selectedFarmId}) {
    const selectedFarm = state.farms.find(farm => farm.id === selectedFarmId);

    console.log(selectedFarm, 'selectedFarm');
    if (selectedFarm) {
      const selectedBed = selectedFarm.beds.find(bed => bed.mapId === mapDraw.id);
      let selectedBedCrops = [];
      console.log(selectedFarm.beds, mapDraw.id, 'selectedFarm.beds, mapDraw.id');
      console.log(selectedBed, 'selectedBed');
      if (selectedBed) {
        // selectedFarm.beds = [...selectedFarm.beds].filter(bed => bed.id !== selectedBed.id);
        selectedBedCrops = [...selectedFarm.beds].filter(bed => bed.sourceId === selectedBed.sourceId);
        // selectedFarm.beds = [...selectedFarm.beds].filter(bed => bed.sourceId !== selectedBed.sourceId);
        state.farms.forEach(stateFarm => {
          if (stateFarm.id === selectedFarm.id) {
            stateFarm.beds = [...selectedFarm.beds].filter(bed => bed.sourceId !== selectedBed.sourceId);
          }
        })
      }
      
      selectedFarm.drawMap = [...selectedFarm.drawMap.filter(drawmap => drawmap.id !== mapDraw.id)];

      console.log(selectedFarm, mapDraw, crop, 'selectedFarm, mapDraw, crop');

      state.archiveFarmPlans.push({
        farm: {
          ...selectedFarm,
          // beds: [selectedBed]
          beds: [...selectedBedCrops]
        },
        mapDraw: {...mapDraw},
        crop: {...crop}
      });
    }
  },

  setCrops(state, cropsData) {
    // state.crops = [...state.crops, ...cropsData]

    // cropsData.forEach(entry => {
    //   const alreadyListed = state.crops.find(crop => crop._id === entry._id);

    //   if (!alreadyListed && (cropsData.length > 0)) {
    //     const entryData = {
    //       ...entry,
    //       author: state.authenticated.author,
    //       datePosted: moment().format('MM/DD/YYYY')
    //     };
    //     console.log(entryData, 'entry!!!!!');
    //     if (entryData?._id) {
    //       state.crops.push(entryData);
    //     }
    //   }
    // });
    state.crops = [...cropsData];

    console.log(cropsData, state.crops, 'crops data and state');
  },
  setNewCrop(state, {crop}) {
    const alreadyListed = state.crops.find(cropState => cropState._id === crop._id);

    if (!alreadyListed) {
      state.crops.push({
        ...crop,
        author: state.authenticated.author,
        datePosted: moment().format('MM/DD/YYYY')
      })
    }
  },
  setCropChanges(state, {crop}) {
    state.crops.forEach(stateCrop => {
      if (stateCrop.id === crop.id) {
        for (let prop in stateCrop) {
          stateCrop[prop] = crop[prop]
        }
      }
    })
  },
  setCropsList(state, {cropId, cropIndex}) {
    if (cropIndex) {
      state.crops.splice(cropIndex,1);
    } else {
      state.crops.splice(state.crops.findIndex(crop => crop.id === cropId),1);
    }
  },
  setAttachment(state, {uploads, section}) {
    console.log(uploads);
    state.attachments[section] = {
      ...uploads
    }

    console.log(state.attachments[section], section, 'state.attachments[section]');
  },
  setUserRole(state, selectedRole) {
    state.authenticated.role = userRoles[selectedRole].role
  },

  setTasks(state, taskData) {
    // state.tasks = taskData;
    console.log(state, taskData);
    state.tasks = [...taskData];
  },
  setTask() {},
  setNewTask(state, taskData) {

    const alreadyListed = state.tasks.find(taskState => taskState._id === taskData._id);

    if (!alreadyListed) {
      state.tasks.push({
        ...taskData,
        author: state.authenticated.author,
        datePosted: moment().format('MM/DD/YYYY')
      })
    }
  },
  setTaskChanges(state, task) {
    console.log('setTaskChanges',task)
    state.tasks.forEach(stateTask => {
      if (stateTask.id === task.id) {
        for (let prop in stateTask) {
          if (task[prop]) {
            if ((prop !== '_id') && (prop !== 'id') && (prop !== 'recurrenceId')) {
              stateTask[prop] = task[prop]
            }
          }
        }
      }
    })
  },
  setTaskChangesRecurring(state, task) {
    console.log('setTaskChangesRecurring',task)
    state.tasks.forEach(stateTask => {
      if (stateTask.recurrenceId === task.recurrenceId) {
        for (let prop in stateTask) {
          if (task[prop]) {
            if ((prop !== '_id') && (prop !== 'id') && (prop !== 'recurrenceId') && (prop !== 'date')) {
              stateTask[prop] = task[prop]
            }
          }
        }
      }
    })
  },
  setFilteredTasks(state, taskId) {
    state.tasks.splice(state.tasks.findIndex(tsk => tsk.id === taskId),1);
  },
  setFilteredRecurringTasks(state, recurrenceId) {
    state.tasks = state.tasks.filter(stateTask => stateTask.recurrenceId !== recurrenceId);
  },
  // setMultipleFilteredTasks(state, taskId) {
  //   state.tasks = [...state.tasks].filter(tsk => tsk.id !== taskId)
  // },
  
  setAddedInventories(state, addedInventoryData) {
    // state.addedInventories = addedInventoryData;
    console.log(state, addedInventoryData);
  },
  setAddedInventory() {},
  setNewAddedInventory(state, addedInventoryData) {
    addedInventoryData.author = state.authenticated.author
    addedInventoryData.datePosted = moment().format('MM/DD/YYYY')
    state.addedInventories.push(addedInventoryData)
    console.log(state.addedInventories)
  },
  setAddedInventoryChanges(state, addedInventory) {
    console.log('setAddedInventoryChanges',addedInventory)
    state.addedInventories.forEach(stateAddedInventory => {
      if (stateAddedInventory.id === addedInventory.id) {
        for (let prop in stateAddedInventory) {
          if (addedInventory[prop]) stateAddedInventory[prop] = addedInventory[prop]
        }
      }
    })
  },
  setFilteredAddedInventories(state, addedInventoryId) {
    state.addedInventories.splice(state.addedInventories.findIndex(addedInventory => addedInventory.id === addedInventoryId),1);
  },

  setInventoriesUsed (state, inventoryUsedData) {
    // state.inventoriesUsed = inventoryUsedData;
    console.log(state, inventoryUsedData);
  },
  setInventoryUsed () {},
  setNewInventoryUsed (state, inventoryUsedData) {
    inventoryUsedData.author = state.authenticated.author
    inventoryUsedData.datePosted = moment().format('MM/DD/YYYY')
    state.inventoriesUsed.push(inventoryUsedData)
    console.log(state.inventoriesUsed)
  },
  setInventoryUsedChanges (state, inventoryUsed) {
    console.log('setInventoryUsedChanges',inventoryUsed)
    state.inventoriesUsed.forEach(stateInventoryUsed => {
      if (stateInventoryUsed.id === inventoryUsed.id) {
        for (let prop in stateInventoryUsed) {
          if (inventoryUsed[prop]) stateInventoryUsed[prop] = inventoryUsed[prop]
        }
      }
    })
  },
  setFilteredInventoriesUsed (state, inventoryUsedId) {
    state.inventoriesUsed.splice(state.inventoriesUsed.findIndex(inventoryUsed => inventoryUsed.id === inventoryUsedId),1);
  },

  setVendors (state, vendorData) {
    console.log(vendorData);
    state.vendors = [...vendorData];
    console.log(state.vendors, 'state.vendors');
  },
  setVendor () {},
  setNewVendor (state, vendorData) {

    const alreadyListed = state.vendors.find(vendorState => vendorState._id === vendorData._id);

    if (!alreadyListed) {
      state.vendors.push({
        ...vendorData,
        author: state.authenticated.author,
        datePosted: moment().format('MM/DD/YYYY')
      })
    }
    console.log(state.vendors, 'state.vendors');
  },
  setVendorChanges (state, vendor) {
    console.log('setVendorChanges',vendor)
    state.vendors.forEach(stateVendor => {
      console.log(stateVendor.id, vendor.id);
      if (stateVendor.id === vendor.id) {
        for (let prop in stateVendor) {
          if ((prop !== '_id') && (prop !== 'id')) {
            if (vendor[prop]) stateVendor[prop] = vendor[prop]
          }
        }
      }
    });
    console.log(state.vendors, 'state.vendors');
  },
  setFilteredVendors (state, vendorId) {
    state.vendors.splice(state.vendors.findIndex(vendor => vendor.id === vendorId),1);
  },

  // setNewVendorInventory (state, vendorInventory) {
  //   vendorInventory.author = state.authenticated.author
  //   vendorInventory.datePosted = moment().format('MM/DD/YYYY')
  //   state.vendorInventories.push(vendorInventory)
  // },
  // setNewVendorEquipment (state, vendorEquipment) {
  //   vendorEquipment.author = state.authenticated.author
  //   vendorEquipment.datePosted = moment().format('MM/DD/YYYY')
  //   state.vendorEquipments.push(vendorEquipment)
  // },
  // setNewVendorService (state, vendorService) {
  //   state.vendorServices.push(vendorService)
  // },
  setVendorItems(state, vendorItemsData) {
    state.vendorItems = [...vendorItemsData];
  },
  setNewVendorItem (state, vendorItem) {
    const alreadyListed = state.vendorItems.find(vendorItemState => vendorItemState._id === vendorItem._id);

    if (!alreadyListed) {
      state.vendorItems.push({
        ...vendorItem,
        author: state.authenticated.author,
        datePosted: moment().format('MM/DD/YYYY')
      })
    }

  },
  setVendorItemChanges (state, vendorItem) {
    state.vendorItems.forEach(stateVendorItem => {
      if (stateVendorItem.id === vendorItem.id) {
        for (let prop in stateVendorItem) {
          if ((prop !== '_id') && (prop !== 'id')) {
            if (vendorItem[prop]) {
              stateVendorItem[prop] = vendorItem[prop]
            }
          }
        }
      }
    })
  },
  setFilteredVendorItems (state, vendorItemId) {
    state.vendorItems.splice(
      state.vendorItems.findIndex(vendorItem => 
        vendorItem.id === vendorItemId),
      1
    );
  },

  setPlannedInventories(state, plannedInventoriesData) {
    state.plannedInventories = [...plannedInventoriesData];
  },
  setNewPlannedInventory (state, plannedInventory) {
    const alreadyListed = state.plannedInventories.find(plannedInventoryState => plannedInventoryState._id === plannedInventory._id);

    if (!alreadyListed) {
      state.plannedInventories.push({
        ...plannedInventory,
        author: state.authenticated.author,
        datePosted: moment().format('MM/DD/YYYY')
      })
    }
  },

  setPlannedInventoryChanges(state, plannedInventory) {
    state.plannedInventories.forEach(statePlannedInventory => {
      if (statePlannedInventory.id === plannedInventory.id) {
        for (let prop in statePlannedInventory) {
          if ((prop !== '_id') && (prop !== 'id')) {
            if (plannedInventory[prop]) {
              statePlannedInventory[prop] = plannedInventory[prop]
            }
          }
        }
      }
    })
  },

  setFilteredPlannedInventories (state, plannedInventoryId) {
    state.plannedInventories.splice(
      state.plannedInventories.findIndex(plannedInventory => 
        plannedInventory.id === plannedInventoryId),
      1
    );
  },

  // setVendorInventoryChanges (state, vendorInventory) {
  //   state.vendorInventories.forEach(stateVendorInventory => {
  //     if (stateVendorInventory.id === vendorInventory.id) {
  //       for (let prop in stateVendorInventory) {
  //         if (vendorInventory[prop]) {
  //           stateVendorInventory[prop] = vendorInventory[prop]
  //         }
  //       }
  //     }
  //   })
  // },
  // setVendorEquipmentChanges (state, vendorEquipment) {
  //   state.vendorEquipments.forEach(stateVendorEquipment => {
  //     if (stateVendorEquipment.id === vendorEquipment.id) {
  //       for (let prop in stateVendorEquipment) {
  //         if (vendorEquipment[prop]) {
  //           stateVendorEquipment[prop] = vendorEquipment[prop]
  //         }
  //       }
  //     }
  //   })
  // },
  // setVendorServiceChanges (state, vendorService) {
  //   state.vendorServices.forEach(stateVendorService => {
  //     if (stateVendorService.id === vendorService.id) {
  //       for (let prop in stateVendorService) {
  //         if (vendorService[prop]) {
  //           stateVendorService[prop] = vendorService[prop]
  //         }
  //       }
  //     }
  //   })
  // },

  // setFilteredVendorInventories (state, vendorInventoryId) {
  //   state.vendorInventories.splice(
  //     state.vendorInventories.findIndex(vendorInventory => 
  //       vendorInventory.id === vendorInventoryId),
  //     1
  //   );
  // },  
  // setFilteredVendorEquipments (state, vendorEquipmentId) {
  //   state.vendorEquipments.splice(
  //     state.vendorEquipments.findIndex(vendorEquipment => 
  //       vendorEquipment.id === vendorEquipmentId),
  //     1
  //   );
  // },
  // setFilteredVendorServices (state, vendorServiceId) {
  //   state.vendorServices.splice(
  //     state.vendorServices.findIndex(
  //       vendorService => vendorService.id === vendorServiceId),
  //     1
  //   );
  // },

  setEquipments (state, equipmentData) {
    console.log(state, equipmentData);
  },
  setEquipment () {},
  setNewEquipment (state, equipmentData) {
    equipmentData.author = state.authenticated.author
    equipmentData.datePosted = moment().format('MM/DD/YYYY')
    state.equipments.push(equipmentData)
    console.log(state.equipments)
  },
  setEquipmentChanges (state, equipment) {
    console.log('setEquipmentChanges',equipment)
    state.equipments.forEach(stateEquipment => {
      if (stateEquipment.id === equipment.id) {
        for (let prop in stateEquipment) {
          if (equipment[prop]) stateEquipment[prop] = equipment[prop]
        }
      }
    })
  },
  setFilteredEquipments (state, equipmentId) {
    state.equipments.splice(state.equipments.findIndex(equipment => equipment.id === equipmentId),1);
  },

  setGeolocation(state, geoCoords) {
    console.log('setgeolocation', geoCoords);
    state.geoLocationCoords = {
      ...geoCoords
    }
  },

  setHarvests (state, harvestData) {
    console.log(state, harvestData);
  },
  setHarvest () {},
  setNewHarvest (state, harvestData) {
    harvestData.author = state.authenticated.author
    harvestData.datePosted = moment().format('MM/DD/YYYY')
    state.harvests.push(harvestData)
    console.log(state.harvests)
  },
  setHarvestChanges (state, harvest) {
    console.log('setHarvestChanges',harvest)
    state.harvests.forEach(stateHarvest => {
      if (stateHarvest.id === harvest.id) {
        for (let prop in stateHarvest) {
          if (harvest[prop]) stateHarvest[prop] = harvest[prop]
        }
      }
    })
  },
  setFilteredHarvests (state, harvestId) {
    state.harvests.splice(state.harvests.findIndex(harvest => harvest.id === harvestId),1);
  },

  setSales (state, salesData) {
    console.log(state, salesData);
    state.sales = [...salesData];
  },
  setSalesOne () {},
  setNewSales (state, salesData) {

    const alreadyListed = state.sales.find(saleState => saleState._id === salesData._id);

    if (!alreadyListed) {
      state.sales.push({
        ...salesData,
        author: state.authenticated.author,
        datePosted: moment().format('MM/DD/YYYY')
      })
    }
    console.log(state.sales, 'state.sales');
  },
  setSalesChanges (state, sales) {
    console.log('setHarvestChanges',sales)
    state.sales.forEach(stateSales => {
      if (stateSales.id === sales.id) {
        for (let prop in stateSales) {
          if ((prop !== '_id') && (prop !== 'id')) {
            if (sales[prop]) stateSales[prop] = sales[prop]
          }
        }
      }
    })
  },
  setFilteredSales (state, salesId) {
    state.sales.splice(state.sales.findIndex(sale => sale.id === salesId),1);
  },

  setCustomers (state, customerData) {
    console.log(state, customerData);
    state.customers = [...customerData];
  },
  setCustomer () {},
  setNewCustomer (state, customerData) {

    const alreadyListed = state.customers.find(customerState => customerState._id === customerData._id);

    if (!alreadyListed) {
      state.customers.push({
        ...customerData,
        author: state.authenticated.author,
        datePosted: moment().format('MM/DD/YYYY')
      })
    }
    console.log(state.customers, 'state.customers');
  },
  setCustomerChanges (state, customer) {
    console.log('setCustomerChanges',customer)
    state.customers.forEach(stateCustomer => {
      if (stateCustomer.id === customer.id) {
        for (let prop in stateCustomer) {
          if ((prop !== '_id') && (prop !== 'id')) {
            if (customer[prop]) stateCustomer[prop] = customer[prop]
          }
        }
      }
    })
  },
  setFilteredCustomers (state, customerId) {
    state.customers.splice(state.customers.findIndex(customer => customer.id === customerId),1);
  },

  setLaborExpenses (state, laborExpenseData) {
    state.laborExpenses = [...laborExpenseData];
  },
  setLaborExpense () {},
  setNewLaborExpense (state, laborExpenseData) {

    const alreadyListed = state.laborExpenses.find(laborExpenseState => laborExpenseState._id === laborExpenseData._id);

    if (!alreadyListed) {
      state.laborExpenses.push({
        ...laborExpenseData,
        author: state.authenticated.author,
        datePosted: moment().format('MM/DD/YYYY')
      })
    }

  },
  setLaborExpenseChanges (state, laborExpense) {
    console.log('setLaborExpenseChanges',laborExpense)
    state.laborExpenses.forEach(stateLaborExpense => {
      if (stateLaborExpense.id === laborExpense.id) {
        for (let prop in stateLaborExpense) {
          if ((prop !== '_id') && (prop !== 'id')) {
            if (laborExpense[prop]) stateLaborExpense[prop] = laborExpense[prop]
          }
        }
      }
    })
  },
  setFilteredLaborExpenses (state, laborExpenseId) {
    state.laborExpenses.splice(state.laborExpenses.findIndex(laborExpense => laborExpense.id === laborExpenseId),1);
  },
  
  setAdminExpenses (state, adminExpenseData) {
    state.adminExpenses = [...adminExpenseData];
  },
  setAdminExpense () {},
  setNewAdminExpense (state, adminExpenseData) {

    const alreadyListed = state.adminExpenses.find(adminExpenseState => adminExpenseState._id === adminExpenseData._id);

    if (!alreadyListed) {
      state.adminExpenses.push({
        ...adminExpenseData,
        author: state.authenticated.author,
        datePosted: moment().format('MM/DD/YYYY')
      })
    }
  },
  setAdminExpenseChanges (state, adminExpense) {
    console.log('setAdminExpenseChanges',adminExpense)
    state.adminExpenses.forEach(stateAdminExpense => {
      if (stateAdminExpense.id === adminExpense.id) {
        for (let prop in stateAdminExpense) {
          if ((prop !== '_id') && (prop !== 'id')) {
            if (adminExpense[prop]) stateAdminExpense[prop] = adminExpense[prop]
          }
        }
      }
    })
  },
  setFilteredAdminExpenses (state, adminExpenseId) {
    state.adminExpenses.splice(state.adminExpenses.findIndex(adminExpense => 
      adminExpense.id === adminExpenseId),1);
  },

  setCompensations (state, compensationData) {
    state.compensations = [...compensationData];
  },
  setCompensation () {},
  setNewCompensation (state, compensationData) {

    const alreadyListed = state.compensations.find(compensationState => compensationState._id === compensationData._id);

    if (!alreadyListed) {
      state.compensations.push({
        ...compensationData,
        author: state.authenticated.author,
        datePosted: moment().format('MM/DD/YYYY')
      })
    }
  },
  setCompensationChanges (state, compensation) {
    console.log('setCompensationChanges',compensation)
    state.compensations.forEach(stateCompensation => {
      if (stateCompensation.id === compensation.id) {
        for (let prop in stateCompensation) {
          if ((prop !== '_id') && (prop !== 'id')) {
            if (compensation[prop]) stateCompensation[prop] = compensation[prop]
          }
        }
      }
    })
  },
  setFilteredCompensations (state, compensationId) {
    state.compensations.splice(state.compensations.findIndex(compensation => 
      compensation.id === compensationId),1);
  },
  setFilteredResults (state, combinedResults) {
    state.searchResults = [...combinedResults]

    console.log(state.searchResults, combinedResults);
  },

  // Accounts
  setAccounts(state, accountsData) {
    state.accounts = [...accountsData];
  },
  // setRemovedAccount(state, accountId) {
  //   state.accounts.splice(state.accounts.findIndex(account => account.id === accountId),1);
  // },
  setAccountChanges(state, {id, status}) {
    state.accounts.forEach(stateAccount => {
      if (stateAccount.id === id) {
        stateAccount.status = (status === 'paused') ? 'active' : 'paused'
      }
    })
  },
  setFilteredAccounts(state, accountId) {
    state.accounts.forEach(stateAccount => {
      if (stateAccount._id === accountId) {
        stateAccount.archived = true;
      }
    })
  },

  setNewNotification(state, notificationData) {
    state.notifications.push(notificationData);
  },
  setNotificationChanges(state, notificationId) {
    state.notifications.forEach(notification => {
      if (notification.id === notificationId) notification.isRead = true;
    })
  },
  setFilteredNotifications(state, notificationId) {
    state.notifications.splice(state.notifications.findIndex(notification => notification.id === notificationId),1);
  },

  setNewAdvertisementGeography(state, advertisementGeography) {
    advertisementGeography.author = state.authenticated.author
    advertisementGeography.datePosted = moment().format('MM/DD/YYYY')
    state.advertisementGeographies.push(advertisementGeography)
  },
  setAdvertisementGeographyChanges (state, advertisementGeography) {
    state.advertisementGeographies.forEach(stateAdvertisementGeography => {
      if (stateAdvertisementGeography.id === advertisementGeography.id) {
        for (let prop in stateAdvertisementGeography) {
          if (advertisementGeography[prop]) {
            stateAdvertisementGeography[prop] = advertisementGeography[prop]
          }
        }
      }
    })
  },
  setFilteredAdvertisementGeographies(state, advertisementGeographyId) {
    state.advertisementGeographies.splice(
      state.advertisementGeographies.findIndex(advertisementGeography => 
        advertisementGeography.id === advertisementGeographyId),
      1
    );
  },
  clearAdvertisement(state) {
    state.advertisementGeographies = [];
    state.advertisementAccount.advertisementGeographies = null;
    state.advertisementAccount.advertisementRange = null;
    state.advertisementAccount.advertisementCost = null;
  },

  setResources (state, resourcesData) {
    console.log(state, resourcesData);
    state.resources = [...resourcesData];
  },
  setNewResource (state, resourcesData) {

    const alreadyListed = state.resources.find(resourceState => resourceState._id === resourcesData._id);

    if (!alreadyListed) {
      state.resources.push({
        ...resourcesData,
        author: state.authenticated.author,
        datePosted: moment().format('MM/DD/YYYY')
      })
    }
    console.log(state.resources, 'state.resources');
  },
  setResourceChanges (state, resource) {
    console.log('setResourceChanges',resource)
    state.resources.forEach(stateResource => {
      if (stateResource.id === resource.id) {
        for (let prop in stateResource) {
          if ((prop !== '_id') && (prop !== 'id')) {
            if (resource[prop]) stateResource[prop] = resource[prop]
          }
        }
      }
    })
  },
  setFilteredResources (state, resourceId) {
    state.resources.splice(state.resources.findIndex(sale => sale.id === resourceId),1);
  },
  setSelectedResourceFile(state, {file, fileType, fileId}) {
    state.selectedResourceFile.file = file || null;
    state.selectedResourceFile.fileType = fileType || '';
    state.selectedResourceFile.fileId = fileId || '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
