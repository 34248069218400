<template>
  <UserProfile v-if="primaryAccount" />
  <section v-else class="section mt-1">
    <div class="tab-content farms-content">
      <section class="section">
        <div class="border-box">
          <AdvertisementAccountMainDetails v-if="mainDetails.mode === 'edit'" />
          <AdvertisementAccountMainDetailsFilled v-else />
          
          <AdvertisementAccountPayment />
          
          <AdvertisementAccountBilling v-if="billing.mode === 'edit'" />
          <AdvertisementAccountBillingFilled v-else />
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { defineComponent, onMounted, provide, reactive, ref, watch, watchEffect } from 'vue';
import { useStore } from 'vuex';
import AdvertisementAccountMainDetails from './AdvertisementAccountMainDetails.vue';
import AdvertisementAccountMainDetailsFilled from './AdvertisementAccountMainDetailsFilled.vue';
import AdvertisementAccountPayment from './AdvertisementAccountPayment.vue';
import AdvertisementAccountBilling from './AdvertisementAccountBilling.vue';
import AdvertisementAccountBillingFilled from './AdvertisementAccountBillingFilled.vue';
import { useRouter } from 'vue-router';
import UserProfile from '../UserProfile/UserProfile.vue';

export default defineComponent({
  name: 'AdvertisementAccount',
  components: {
    AdvertisementAccountMainDetails,
    AdvertisementAccountMainDetailsFilled,
    AdvertisementAccountPayment,
    AdvertisementAccountBilling,
    AdvertisementAccountBillingFilled,
    UserProfile
},
  setup () {

    const store = useStore();

    const router = useRouter();

    const mainDetails = reactive({

      accountName: '',
      industry: '',
      companySize: '',

      title: '',
      userRole: '',

      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      workPhone: '',
      mobilePhone: '',
      city: '',
      state: '',
      zip: '',
      country: '',

      submitted: false,
      activated: false,
      mode: (store.state.advertisementAccount?.mainDetails?.submitted ||
        store.state.advertisementAccount?.mainDetails?.firstName) ? 'represent' : 'edit'
    })

    const billing = reactive({
      accountName: '',
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      mode: 'edit', // store.state.primaryUser?.billing?.submitted ? 'represent' : 'edit',
      expanded: false,
      submitted: false
    })

    const sameAddress = ref(false);

    const stripeBank = ref();

    const stripeElements = ref();

    const primaryAccount = ref(false);

    const changeMainField = (val, field) => {
      mainDetails[field] = val;
    }

    const changeBillingField = (val, field) => {
      billing[field] = val;
    }

    const collectPayment = (paymentElement, elms) => {
      stripeBank.value = paymentElement;
      stripeElements.value = elms;
      console.log(
        stripeBank.value,
        stripeElements.value
      )
    }

    const confirmPayment = () => {
      console.log(stripeElements.value, stripeBank.value);

      stripeElements.value.instance.confirmPayment({
        elements: stripeElements.value.elements,
        confirmParams: {
          return_url: `https://${store.state.authenticated.accountSubdomain}.${'bhoomiapp.com'}/user-profile`,
        }
      }).then((result) => {
        // Handle result.error or result.token
        console.log(result)
      }).catch(e => console.log(e))
    }

    provide('mainDetails', {
      mainDetails,
      changeMainField,
    })

    provide('stripeProps', {
      collectPayment,
      confirmPayment
    })

    provide('billing', {
      billing,
      sameAddress,
      changeBillingField
    })

    watch(sameAddress, () => {
      if (sameAddress.value && mainDetails.submitted) {
        billing.sameAddress = true;
        for (let prop in billing) {
          if (mainDetails[prop] && mainDetails[prop].length > 0 && 
            (prop !== 'submitted') && (prop !== 'mode')) {
            changeBillingField(mainDetails[prop], prop);
          }
        }
        // billing.submitted = true;
        // store.dispatch('savePrimaryUser', {section: 'billing', data: billing});
      }
    });

    watchEffect(() => {
      if (mainDetails.submitted && billing.submitted) {// && stripeBank.value
        router.push({ 
          name: 'advertisement', 
          params: { 
            pagename: 'advertisement-questionnaire-first' 
          } 
        })
      }
    })

    onMounted(() => {
      if (store.state.primaryUser.mainDetails) {

        store.dispatch("saveAdvertisementAccount", {
          section: "mainDetails",
          data: {
            ...store.state.primaryUser.mainDetails
          }
        });

        store.dispatch('saveAdvertisementAccount', {
          section: 'billing', 
          data: {
            ...store.state.primaryUser.billing
          }
        });

        primaryAccount.value = true;

      }
    })

    return {
      mainDetails,
      billing,
      primaryAccount
    }
  }
})
</script>

<style lang="scss" scoped>
</style>