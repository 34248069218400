<template>
  <div class="outer-space-recurring" @click="cancelChanges"></div>
  <div class="recurring-task-form" ref="recurringTaskForm">
    
    <section class="section">
    <div class="border-box">
    <div class="border-box-content">

    <div class="content-block d-flex justify-content-between recurring-header">
    <h5 class="fw-semibold">Task Recurrence</h5>
    </div>

    <div class="content-block">

    <div class="content-row">
    <div class="content-row grid-4 grid-full">
    <div class="row">

      <div class="col-3">
      <div class="form-item">
      <p class="form-label" :class="{active: focused.startTimeRecurring || (task.startTimeRecurring.length > 0)}">Start</p>
      <!-- <select class="form-control" 
        v-model="task.startTimeRecurring"
        @click="() => {
          activated.startTimeRecurring = true;
          focused.startTimeRecurring = true;
        }"
        @change="() => {
          focused.startTimeRecurring = false;
        }">
      <option 
        v-for="timeOption in timesOptions"
        v-bind:key="timeOption + 'start-time-option-task'"
      >{{ timeOption }}</option>
      </select> -->
      <DropDownList
        :mainObject="'task'"
        :mainProperty="'startTimeRecurring'"
        :injectionName="'taskDetails'"
        :optionKey="'start-time-option-task'"
        :optionsList="timesOptions"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="task.startTimeRecurring"
        @click="() => {
          activated.startTimeRecurring = true;
        }"
      />

      <input type="text" class="form-control time-insert"
        v-model="task.startTimeRecurring"
        @focus="() => {
          activated.startTimeRecurring = true;
          focused.startTimeRecurring = true;
        }"
        @blur="() => {
          focused.startTimeRecurring = false;
        }"
      />

      <p v-if="activated.startTimeRecurring && task.startTimeRecurring.length === 0" class="form-info red text-end">* Required</p>
      </div>
      </div>

      <div class="col-3">
      <div class="form-item">
      <p class="form-label" :class="{active: focused.endTimeRecurring || (task.endTimeRecurring.length > 0)}">End</p>
      <!-- <select class="form-control" 
        v-model="task.endTimeRecurring"
        @click="() => {
          activated.endTimeRecurring = true;
          focused.endTimeRecurring = true;
        }"
        @change="() => {
          focused.endTimeRecurring = false;
        }">
      <option 
        v-for="timeOption in timesOptions"
        v-bind:key="timeOption + 'start-time-option-task'"
      >{{ timeOption }}</option>
      </select> -->
      <DropDownList
        :mainObject="'task'"
        :mainProperty="'endTimeRecurring'"
        :injectionName="'taskDetails'"
        :optionKey="'start-time-option-task'"
        :optionsList="timesOptions"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="task.endTimeRecurring"
        @click="() => {
          activated.endTimeRecurring = true;
        }"
      />

      <input type="text" class="form-control time-insert"
        v-model="task.endTimeRecurring"
        @focus="() => {
          activated.endTimeRecurring = true;
          focused.endTimeRecurring = true;
        }"
        @blur="() => {
          focused.endTimeRecurring = false;
        }"
      />

      <p v-if="activated.endTimeRecurring && task.endTimeRecurring.length === 0" class="form-info red text-end">* Required</p>
      </div>
      </div>

      <div class="col-3">
      <div class="form-item">
      <p class="form-label" :class="{active: focused.durationRecurring || (`${task.durationRecurring}`.length > 0)}">Duration</p>
      <!-- <select class="form-control" 
        v-model="task.durationRecurring"
        @click="() => {
          activated.durationRecurring = true;
          focused.durationRecurring = true;
        }"
        @change="() => {
          focused.durationRecurring = false;
        }">
      <option 
        v-for="timeOption in timesOptions"
        v-bind:key="timeOption + 'start-time-option-task'"
      >{{ timeOption }}</option>
      </select> -->
      <input type="text" class="form-control" 
        v-model="task.durationRecurring"
        @focus="() => {
          activated.durationRecurring = true;
          focused.durationRecurring = true;
        }"
        @blur="() => {
          focused.durationRecurring = false;
        }"
      />

      <p v-if="activated.durationRecurring && task.durationRecurring.length === 0" class="form-info red text-end">* Required</p>
      </div>
      </div>

    </div>
    </div>
    </div>
    </div>

    <div class="content-block d-flex justify-content-between">
    <h5 class="fw-semibold">Recurrence Pattern</h5>
    </div>

    <div class="content-block d-lg-flex justify-content-between">
    <div class="checkbox-group task-type">
    <!-- <p class="fw-semibold gray">Recurrence Pattern</p> -->
    <div class="radio">
      <input type="radio" 
        name="recurrenceDaily" 
        id="daily" 
        value="daily" 
        v-model="task.recurrencePattern"
      />
        <label for="daily">Daily</label>
        <p v-if="(activated.recurrencePattern && (task.recurrencePattern === 'daily') && (!task.startDateRecurring || !task.endDateRecurring))" class="form-info red text-end daily-notice">Please choose range of recurrence</p>
      <!-- (activated.recurrencePattern && ((task.startDateRecurring.length === 0) || (task.endDateRecurring.length === 0))) ||  -->
    </div>
    <div class="radio">
      <input type="radio" 
        name="recurrenceWeekday" 
        id="weekday" 
        value="weekday" 
        v-model="task.recurrencePattern"
      />
        <label for="weekday">Weekday</label>
        <p v-if="(activated.recurrencePattern && (task.recurrencePattern === 'weekday') && (!task.startDateRecurring || !task.endDateRecurring))" class="form-info red text-end daily-notice">Please choose range of recurrence</p>
    </div>
    <!-- <div class="radio">
      <input type="radio" 
        name="recurrenceWeekly" 
        id="weekly" 
        value="weekly" 
        v-model="task.recurrencePattern"
      />
      <label for="weekly">Weekly</label>
      <p v-if="(activated.recurrencePattern && (task.recurrencePattern === 'weekly') && (!task.startDateRecurring || !task.endDateRecurring))" class="form-info red text-end weekly-notice">Please choose range of recurrence with minimum of a week range</p>
    </div> -->
    </div>
    </div>

    <!-- <div class="content-block d-lg-flex justify-content-between" v-if="task.recurrencePattern === 'weekly'">
    <div class="checkbox-group aligned-grid">
    <p class="fw-semibold gray">Recur every <input type="text" class="form-control xs nweek-value" v-model="task.recurrenceWeeklyWeeks" /> week(s) on:</p>
    <div class="checkbox"><input type="checkbox" name="recurrencesunday" id="sunday" value="sunday"  v-model="task.recurrenceWeeklySunday"><label for="sunday">Sunday</label></div>
    <div class="checkbox"><input type="checkbox" name="recurrencemonday" id="monday" value="monday"  v-model="task.recurrenceWeeklyMonday"><label for="monday">Monday</label></div>
    <div class="checkbox"><input type="checkbox" name="recurrencetuesday" id="tuesday" value="tuesday"  v-model="task.recurrenceWeeklyTuesday"><label for="tuesday">Tuesday</label></div>
    <div class="checkbox"><input type="checkbox" name="recurrencewednesday" id="wednesday" value="wednesday"  v-model="task.recurrenceWeeklyWednesday"><label for="wednesday">Wednesday</label></div>
    <div class="checkbox thursday"><input type="checkbox" name="recurrencethursday" id="thursday" value="thursday"  v-model="task.recurrenceWeeklyThursday"><label for="thursday">Thursday</label></div>
    <div class="checkbox"><input type="checkbox" name="recurrencefriday" id="friday" value="friday"  v-model="task.recurrenceWeeklyFriday"><label for="friday">Friday</label></div>
    <div class="checkbox"><input type="checkbox" name="recurrencesaturday" id="saturday" value="saturday"  v-model="task.recurrenceWeeklySaturday"><label for="saturday">Saturday</label></div>
    </div>
    </div> -->

    <!-- <div class="content-block d-lg-flex justify-content-between" v-if="task.recurrencePattern === 'daily'">
    <div class="checkbox-group task-type">
    <div class="radio"><input type="radio" name="recurrenceNday" id="nday" value="nday"  v-model="task.recurrenceDaily"><label for="nday">
      <p class="fw-semibold gray">Every <input type="text" class="form-control xs nday-value" v-model="task.recurrenceDailyDays" /> day(s)</p>
    </label></div>
    <div class="radio"><input type="radio" name="recurrenceWeekday" id="weekday" value="weekday" v-model="task.recurrenceDaily"><label for="weekday">Weekday</label></div>
    </div>
    </div> -->

    <div class="content-block d-flex justify-content-between recurring-header">
    <h5 class="fw-semibold">Range of recurrence</h5>
    </div>

    <div class="content-block">

    <div class="content-row">
    <div class="content-row grid-4 grid-full">
    <div class="row">
      <div class="col-3">
      <div class="form-item">
      <p class="form-label" :class="{active: focused.startDateRecurring || (task.startDateRecurring.length > 0)}">Start</p>
      <input type="date" class="form-control date-field" 
        :style="{color: (focused.startDateRecurring || task.startDateRecurring.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
        v-model="task.startDateRecurring"
        pattern="\d{2}/\d{2}/\d{4}"
        @focus="() => {
          activated.startDateRecurring = true;
          focused.startDateRecurring = true;
        }"
        @blur="() => {
          focused.startDateRecurring = false;
        }"
      />
      <div class="date-icon"></div>

      <p v-if="activated.startDateRecurring && task.startDateRecurring.length === 0" class="form-info red text-end">* Required</p>
      </div>
      </div>

      <div class="col-3">
      <div class="form-item">
      <p class="form-label" :class="{active: focused.endDateRecurring || (task.endDateRecurring.length > 0)}">End by</p>
      <input 
        type="date" class="form-control date-field" 
        :style="{color: (focused.endDateRecurring || task.endDateRecurring.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
        v-model="task.endDateRecurring"
        pattern="\d{2}/\d{2}/\d{4}"
        @focus="() => {
          activated.endDateRecurring = true;
          focused.endDateRecurring = true;
        }"
        @blur="() => {
          focused.endDateRecurring = false;
        }"
      />
      <div class="date-icon"></div>

      <p v-if="activated.endDateRecurring && task.endDateRecurring.length === 0" class="form-info red text-end">* Required</p>
      </div>
      </div>

    </div>
    </div>
    </div>

    </div>

    <!--  -->
    <div class="content-block">
    <div class="content-section">
    <div class="buttons">
    <div class="row">
    <div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
    <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
    </div>
    </div>
    </div>
    </div>

    </div>

    </div>
    </section>

  </div>
</template>

<script>
import { defineComponent, inject, onMounted, reactive, watch, ref } from 'vue';
import timesOptions from '@/sharedData/recurringTimes';
import moment from 'moment';
import DropDownList from '@/components/Shared/DropDownList.vue';

export default defineComponent({
    name: 'RecurringTaskForm',
    setup() {
        const { recurringIsActive } = inject('recurringDetails');
        const { task } = inject('taskDetails');
        const defaultState = {
            startTimeRecurring: '',
            endTimeRecurring: '',
            durationRecurring: '',
            recurrencePattern: '',
            recurrenceWeeklyWeeks: '',
            recurrenceWeeklySunday: false,
            recurrenceWeeklyMonday: false,
            recurrenceWeeklyTuesday: false,
            recurrenceWeeklyWednesday: false,
            recurrenceWeeklyThursday: false,
            recurrenceWeeklyFriday: false,
            recurrenceWeeklySaturday: false,
            recurrenceDaily: '',
            recurrenceDailyDays: '',
            startDateRecurring: '',
            endDateRecurring: ''
        };

        const recurringTaskForm = ref(null);

        const submitForm = () => {
            // if (!task.startTimeRecurring || !task.endTimeRecurring || !task.startDateRecurring ||
            // !task.endDateRecurring) {
            //   if (!task.startTimeRecurring) activated.startTimeRecurring = true;
            //   if (!task.endTimeRecurring) activated.endTimeRecurring = true;
            //   if (!task.startDateRecurring) activated.startDateRecurring = true;
            //   if (!task.endDateRecurring) activated.endDateRecurring = true;
            //   return;
            // }
            // if (!task.recurrencePattern || (task.recurrencePattern === 'daily' && (!task.recurrenceDaily ||
            //   (task.recurrenceDaily === 'nday' && !task.recurrenceDailyDays)
            // )) || (task.recurrencePattern === 'weekly' && (!task.recurrenceWeeklyWeeks || 
            // (!task.recurrenceWeeklySunday && !task.recurrenceWeeklyMonday && !task.recurrenceWeeklyTuesday &&
            // !task.recurrenceWeeklyWednesday && !task.recurrenceWeeklyThursday && !task.recurrenceWeeklyFriday &&
            // !task.recurrenceWeeklySaturday)))) {
            //   return;
            // }
            if (!task.startTimeRecurring || !task.endTimeRecurring || !task.startDateRecurring ||
                !task.endDateRecurring || !task.startDateRecurring || !task.endDateRecurring || !task.recurrencePattern || (!task.recurrencePattern)) {
                if (!task.startTimeRecurring)
                    activated.startTimeRecurring = true;
                if (!task.endTimeRecurring)
                    activated.endTimeRecurring = true;
                if (!task.startDateRecurring)
                    activated.startDateRecurring = true;
                if (!task.endDateRecurring)
                    activated.endDateRecurring = true;
                if (!task.startDateRecurring || !task.endDateRecurring)
                    activated.recurrencePattern = true;
                return;
            }
            recurringIsActive.value = false;
        };
        const cancelChanges = () => {
            for (let prop in defaultState) {
                task[prop] = defaultState[prop];
            }
            task.recurring = false;
            recurringIsActive.value = false;
        };
        const activated = reactive({
            startTimeRecurring: false,
            endTimeRecurring: false,
            durationRecurring: false,
            startDateRecurring: false,
            endDateRecurring: false,
            recurrencePattern: false
        });
        const focused = reactive({
            startTimeRecurring: false,
            endTimeRecurring: false,
            durationRecurring: false,
            startDateRecurring: false,
            endDateRecurring: false
        });
        const calculateDuration = () => {
            console.log(moment(task.endTimeRecurring, 'hh:mm a')); //.format('hh:mm a')
            const endTime = moment(task.endTimeRecurring, 'hh:mm a');
            const startTime = moment(task.startTimeRecurring, 'hh:mm a');
            let timeDifference = endTime.diff(startTime, 'hours', true);
            if (timeDifference < 0) {
                const circleEndTime = moment(task.endTimeRecurring, 'hh:mm a'); //`${Number.parseInt(task.endTimeRecurring.split(':')[0]) + 24}:${task.endTimeRecurring.split(':')[1]}`;
                circleEndTime.add(24, 'hours');
                timeDifference = circleEndTime.diff(startTime, 'hours', true);
            }
            task.durationRecurring = (timeDifference > 0) ? timeDifference.toFixed(2) : '0.5';
            activated.durationRecurring = true;
            focused.durationRecurring = true;
        };
        watch(() => (task.startTimeRecurring), (newVal) => {
            task.startTime = task.startTimeRecurring;
            if (newVal && task.endTimeRecurring) {
                calculateDuration();
            }
        });
        watch(() => (task.endTimeRecurring), (newVal) => {
            task.completionTime = task.endTimeRecurring;
            if (newVal && task.startTimeRecurring) {
                calculateDuration();
            }
        });
        watch(() => (task.durationRecurring), (newVal) => {
            console.log(newVal);
            // if (newVal) task.totalHours = newVal;
        });
        watch(() => (task.recurrencePattern), (newVal, oldVal) => {
            if (newVal === 'daily') {
                task.recurrenceWeeklyWeeks = '';
                task.recurrenceWeeklySunday = false;
                task.recurrenceWeeklyMonday = false;
                task.recurrenceWeeklyTuesday = false;
                task.recurrenceWeeklyWednesday = false;
                task.recurrenceWeeklyThursday = false;
                task.recurrenceWeeklyFriday = false;
                task.recurrenceWeeklySaturday = false;
            }
            if (newVal === 'weekly') {
                task.recurrenceDaily = '';
                task.recurrenceDailyDays = '';
            }
            if (newVal && !oldVal) {
                activated.recurrencePattern = true;
            }
        });
        watch(() => (task.startDateRecurring), (newVal) => {
            if (newVal) {
                task.date = newVal;
            }
        });
        onMounted(() => {
            if (task.recurrencePattern.length > 0)
                activated.recurrencePattern = false;
          if (task.startTimeRecurring && task.endTimeRecurring) {
            calculateDuration();
          }

          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: recurringTaskForm?.value?.offsetTop,
              left: 0,
              behavior: 'smooth'
            })
          })
        });
        return {
            submitForm,
            cancelChanges,
            task,
            activated,
            focused,
            timesOptions,
            recurringTaskForm
        };
    },
    components: { DropDownList }
})
</script>

<style lang="scss" scoped>
.recurring-task-form {
  // position: absolute;
  // z-index: 10;

  // width: 89rem;
  // right: -3rem;
  // top: -9rem;
  position: absolute;
  z-index: 10;
  width: 100%;
  max-width: 89rem;
  top: 2rem;
  left: 0;
  margin: auto;
  right: 0;
  .border-box {
    background-color: #fff;
  }
  .recurring-header {
    margin-bottom: 0;
  }
  .form-control {
    &.date-field {
      padding-right: 1.8rem;
      background: none;
    }
  }
  .form-item {
    &.end-by {
      .date-icon {
        top: 1rem;
      }    
    }
  }
  .nday-value,
  .nweek-value {
    margin: auto 10px;
  }
  .date-icon {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 3.7rem;
    right: 1rem;
    z-index: 8;
    pointer-events: none;
    border-radius: 1rem;
    background:url('@/assets/images/calendar-icon.svg') center center no-repeat;
    background-color: #fff;
  }
  .checkbox-group {
    &.aligned-grid {
      .fw-semibold {
        &.gray {
          margin-right: 2rem;
        }
      }
      .checkbox {
        margin-right: 0;
        flex-basis: 15%;
        &.thursday {
          margin-left: calc(235px + 2rem);
        }
      }
    }
  }
  .time-insert {
    width: 85%;
    position: absolute;
    left: 0.2rem;
    height: calc(5.2rem - 0.8rem);
    top: calc(24px + 0.3rem + 0.4rem);
    border: none;
  }
  .daily-notice {
    position: absolute;
    width: 20rem;
    display: flex;
  }
  .weekly-notice {
    position: absolute;
    width: 37rem;
    display: flex;
  }
}
@media screen and (max-width: 480px) {
  .weekly-notice {
    left: 2rem;
  }
}

@media screen and (max-width: 991px) {
  .recurring-task-form {
    .checkbox-group {
      &.aligned-grid {
        .checkbox {
          margin-right: 0;
          flex-basis: 25%;
          &.thursday {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 454px) {
  .recurring-task-form {
    .checkbox-group {
      &.aligned-grid {
        .checkbox {
          margin-right: 0;
          flex-basis: 22%;
          &.thursday {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 377px) {
  .recurring-task-form {
    .checkbox-group {
      &.aligned-grid {
        .checkbox {
          margin-right: 0;
          flex-basis: 33%;
          &.thursday {
            margin-left: 0;
          }
        }
      }
    }
  }
}
.outer-space-recurring {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba($color: #000000, $alpha: 0.5);
}
</style>