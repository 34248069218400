<template>

  <section class="section" ref="section">
  <div class="border-box active">
  
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5>Products & Services List</h5>
  <div class="info-icons">
    <!-- <div class="info-icon lg header-icon tooltip"
      @click="() => {}"
      data-tooltip=""
    >
      <img src="@/assets/images/updated/plan-crop.png" alt="">
    </div> -->
    <div class="info-icon lg header-icon tooltip" 
      v-if="!isArchived && isAuth('edit-vendor-item')"
      @click="() => selectVendorItem({...vendorItem}, 'edit')"
      data-tooltip="Edit"
    >
      <img src="@/assets/images/updated/Edit.png" alt="">
    </div>
  </div>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>
  
  <div class="border-box-content">
  
  <div class="content-block">

  <div class="content-row">
  
  <div class="intro-grid">
  
    <template v-if="vendorItem.type === 'Product'">
      <div class="intro-grid-column">
      <p class="title">Item</p>
      <h6>{{ vendorItem.item }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Variety</p>
      <h6>{{ vendorItem.variety }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Manufacturer</p>
      <h6>{{ vendorItem.manufacturer }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Brand</p>
      <h6>{{ vendorItem.brand }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title"># of Items</p>
      <h6>{{ vendorItem.numberOfItems }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Weight (lbs/item)</p>
      <h6>{{ vendorItem.quantity }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Date Purchased</p>
      <h6>{{ vendorItem.date ? moment(vendorItem.date, 'YYYY-MM-DD').format('MM/DD/YYYY') : '' }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Invoice #</p>
      <h6>{{ vendorItem.invoice }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Amount</p>
      <h6>$ {{ vendorItem.amountPaid }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Warranty Exp</p>
      <h6>{{ vendorItem.warrantyExp ? moment(vendorItem.warrantyExp, 'YYYY-MM-DD').format('MM/DD/YYYY') : '' }}</h6>
      </div>
      
    </template>

    <template v-if="vendorItem.type === 'Equipment'">
      
      <div class="intro-grid-column">
      <p class="title">Date Purchased</p>
      <h6>{{ vendorItem.date ? moment(vendorItem.date, 'YYYY-MM-DD').format('MM/DD/YYYY') : '' }}</h6>
      </div>
      
      <div class="intro-grid-column">
      <p class="title">Item</p>
      <h6>{{ vendorItem.item }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Manufacturer</p>
      <h6>{{ vendorItem.manufacturer }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Brand</p>
      <h6>{{ vendorItem.brand }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title"># of Items</p>
      <h6>{{ vendorItem.numberOfItems }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Invoice #</p>
      <h6>{{ vendorItem.invoice }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Amount</p>
      <h6>{{ vendorItem.amountPaid }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Warranty Exp</p>
      <h6>{{ vendorItem.warrantyExp ? moment(vendorItem.warrantyExp, 'YYYY-MM-DD').format('MM/DD/YYYY') : '' }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Next Service Date</p>
      <h6>{{ vendorItem.serviceDate ? moment(vendorItem.serviceDate, 'YYYY-MM-DD').format('MM/DD/YYYY') : '' }}</h6>
      </div>
      
    </template>

    <template v-if="vendorItem.type === 'Service'">
      
      <div class="intro-grid-column">
      <p class="title">Service Type</p>
      <h6>{{ vendorItem.serviceType }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Date Purchased</p>
      <h6>{{ moment(vendorItem.date, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</h6>
      </div>
<!-- vendorItem.date -->
      <div class="intro-grid-column">
      <p class="title">Invoice #</p>
      <h6>{{ vendorItem.invoice }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Amount</p>
      <h6>{{ vendorItem.amountPaid }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Warranty Exp</p>
      <h6>{{ vendorItem.warrantyExp ? moment(vendorItem.warrantyExp, 'YYYY-MM-DD').format('MM/DD/YYYY') : '' }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Next Service Date</p>
      <h6>{{ moment(vendorItem.serviceDate, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</h6>
      </div>
      
    </template>

    <template v-if="vendorItem.type">
      <div class="intro-grid-column intro-grid-comment-column">
      <p class="title">Comments</p>
      <h6>{{ vendorItem.comments }}</h6>
      </div>
    </template>
  
  </div>
  
  </div>
    
  </div>

  <div class="content-block">

    <FileAttachments :key="`vendor-item-filled-${vendorItem.id}`" v-bind:filled="true" v-bind:section="'vendorItem'"  v-bind:instance="`${vendorItem.id}`" />

  </div>
  
  </div>
  </div>
  </section>
  
  </template>
  
  <script>
  import { inject, ref, watch, onMounted, defineComponent } from 'vue';
  // import store from '@/store';
  // import { useStore } from 'vuex';
  import moment from 'moment';
  import FileAttachments from '@/components/Shared/FileAttachments.vue';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: "FarmBedProfileFilled",
    props: ['archived'],
    setup(props) {
        const { isAuth } = inject('mainUI');
        const store = useStore();
        const { vendorItem, 
        // productsServicesMode, 
        selectVendorItem,
        // defaultVendorItem 
         } = inject('vendorDetails');
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const isArchived = ref(props.archived);
        const toggleSection = () => {
            sectionExpanded.value = !sectionExpanded.value;
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });

        watch(() => ({...store.state.attachments}), () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
        });
        // watch(() => ({}), () => {
        //   section.value.style.height = 'auto';
        //   setTimeout(() => {
        //     sectionHeight.value = section.value.offsetHeight;
        //   })
        // });
        onMounted(() => {
            setTimeout(() => {
              if (section.value) {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
              }
            });
        });
        return {
            vendorItem,
            section,
            boxHeader,
            toggleSection,
            moment,
            selectVendorItem,
            isArchived,
            isAuth
        };
    },
    components: { FileAttachments }
});
  </script>
  
  <style lang="scss" scoped>
  </style>