<template>

<section class="section" ref="section">
<div class="border-box">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Subscription Type</h5>
<div class="info-icons">
  <div class="icon info-icon tooltip"
    v-if="isAuth('edit-subscription') && !onlyViewMode"
    @click="changeSubscriptionField('edit', 'mode')"
    data-tooltip="Edit Subscription"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
  >
    <!-- <img src="@/assets/images/edit-profile-icon.svg" alt=""> -->
    <img src="@/assets/images/updated/Edit.png" alt="">
    <!-- <div class="helper-tooltip" v-if="helperTooltip">Edit Subscription</div> -->
  </div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content subscription-type-content">

  <div class="content-block">

    <div class="content-row grid-4" v-if="!subscription.plans.basic && !subscription.plans.plus && !subscription.plans.advance && !subscription.plans.ultimate">
    <div class="row">
    <div class="col-12">
      <div class="form-item">
        <p class="form-label-static">Free 30 Days Trial</p>
        <h6>Expires on {{ moment(subscription.planPurchasingDate).add(30, 'day').format('MM/DD/YYYY') }}</h6>
        <!-- .format('MMMM Do YYYY') -->
      </div>
    </div>
    </div>
    </div>

  <div class="payment-details" v-else>
 

<div class="subscription-wrap-block">

  <div class="content-row subscription-info">
    <div>
        <p class="form-label">Billing Type</p>
        <h6>{{ subscription.subscriptionPeriod }}</h6>
        <p class="fs-xs pt-1">10% Discount on Yearly Subscription</p>
    </div>
  </div>
  
  <div class="subscription-list-grid-wrap">
  <div class="subscription-list-grid">

  <div></div>
  <div><p class="form-label text-center">Total</p></div>
  <div><p class="form-label text-center">Assigned</p></div>
  <div><p class="form-label text-center">Available</p></div>


  <template v-if="subscription.plans.basic">
    <div class="checkbox"><input type="checkbox" id="basic" v-model="subscription.plans.basic" disabled /><label for="basic" @click="() => handleMissingSubscription('basic')">Basic</label></div>
    <div>
      <div class="w-80">
        <h6>{{ Number.parseInt(subscription.plans.basicNumber || 0) }}</h6>
      </div>
    </div>
    <div>
      <div class="w-80">
        <h6>{{ (Number.parseInt(subscription.plans.basicNumber - subscription.plans.basicAvailable) || 0) }}</h6>
      </div>
    </div>
    <div>
      <div class="w-80">
        <h6>{{ Number.parseInt(subscription.plans.basicAvailable || 0) }}</h6>
      </div>
    </div>
  </template>

  <template v-if="subscription.plans.plus">
    <div class="checkbox"><input type="checkbox" id="basicPlus" v-model="subscription.plans.plus" disabled /><label for="basicPlus" @click="() => handleMissingSubscription('plus')">Basic +</label></div>
    <div>
      <div class="w-80">
        <h6>{{ Number.parseInt(subscription.plans.plusNumber || 0) }}</h6>
      </div>
    </div>
    <div>
      <div class="w-80">
        <h6>{{ (Number.parseInt(subscription.plans.plusNumber - subscription.plans.plusAvailable) || 0) }}</h6>
      </div>
    </div>
    <div>
      <div class="w-80">
        <h6>{{ Number.parseInt(subscription.plans.plusAvailable || 0) }}</h6>
      </div>
    </div>
  </template>

  <template v-if="subscription.plans.advance">
    <div class="checkbox"><input type="checkbox" id="basicPlusAdvance" v-model="subscription.plans.advance" disabled><label for="basicPlusAdvance" @click="() => handleMissingSubscription('advance')">Basic + Advance</label></div>
    <div>
      <div class="w-80">
        <h6>{{ Number.parseInt(subscription.plans.advanceNumber || 0) }}</h6>
      </div>
    </div>
    <div>
      <div class="w-80">
        <h6>{{ (Number.parseInt(subscription.plans.advanceNumber - subscription.plans.advanceAvailable) || 0) }}</h6>
      </div>
    </div>
    <div>
      <div class="w-80">
        <h6>{{ Number.parseInt(subscription.plans.advanceAvailable || 0) }}</h6>
      </div>
    </div>
  </template>

  <template v-if="subscription.plans.ultimate">
    <div class="checkbox"><input type="checkbox" id="basicPlusUltimate" v-model="subscription.plans.ultimate" disabled><label for="basicPlusUltimate" @click="() => handleMissingSubscription('ultimate')">Basic + Ultimate</label></div>
    <div><div class="w-80"><h6>{{ Number.parseInt(subscription.plans.ultimateNumber || 0) }}</h6></div></div>
    <div>
      <div class="w-80">
        <h6>{{ (Number.parseInt(subscription.plans.ultimateNumber - subscription.plans.ultimateAvailable) || 0) }}</h6>
      </div>
    </div>
    <div>
      <div class="w-80"><h6>{{ Number.parseInt(subscription.plans.ultimateAvailable || 0) }}</h6></div>
    </div>
  </template>

  </div>
</div>

</div>
 
<div class="content-row overflow-auto subscription-result-wrap">
<div class="four-column-grid">
<div class="text-center">
  <p class="form-label">Subscription Amount</p>
  <h6>${{ Number.parseFloat(subscription.totalSubscription || 0).toFixed(2) }}</h6>  
</div>
<div class="text-center">
  <p class="form-label">Discount</p>
  <h6>${{ Number.parseFloat(subscription.discount || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Taxes</p>
  <h6>${{ Number.parseFloat(subscription.taxes || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Total</p>
  <h6>${{ Number.parseFloat(subscription.total || 0).toFixed(2) }}</h6>  
</div>
</div>  
</div>

</div>
</div>

  <!-- Old content -->

  <!--<div class="content-block">

<div class="content-row grid-4" v-if="!subscription.plans.basic && !subscription.plans.plus && !subscription.plans.advance && !subscription.plans.ultimate">
<div class="row">
<div class="col-12">
  <div class="form-item">
    <p class="form-label-static">Free 30 Days Trial</p>
    <h6>Expires on {{ moment(subscription.planPurchasingDate).add(30, 'day').format('MMMM Do YYYY') }}</h6>
  </div>
</div>
</div>
</div>

<div class="content-row grid-4"  v-if="subscription.plans.basic">
<div class="row">
<div class="col-3 align-self-end">
<div class="form-item">
<div class="checkbox checkbox-lg"><input type="checkbox" id="checkbox9" v-model="subscription.plans.basic" disabled><label for="checkbox9">Basic Plan</label></div>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label-static">Price per month</p>
<h6>${{ Number.parseFloat(subscription.plans.basicPrice).toFixed(2) }}</h6>
</div>
</div>
<div class="col-3">
<div class="form-item d-flex">
<div class="d-flex flex-column align-items-center">
<p class="form-label-static">No. of Subscriptions</p>
<div class="form-item d-flex">
<h6>{{ Number.parseInt(subscription.plans.basicNumber) }}</h6>
</div>
</div>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label-static">Billing Type</p>
<h6>{{ subscription.subscriptionPeriod }}</h6>
<span class="form-info">*10% Discount on Yearly Subscription</span>
</div>
</div>
</div>
</div>

</div>

<div class="content-block">

<div class="content-section">

<div class="content-row grid-4" v-if="subscription.plans.plus">
<div class="row">
<div class="col-3 align-self-end">
<div class="form-item">
<div class="checkbox checkbox-lg"><input type="checkbox" id="checkbox10" v-model="subscription.plans.plus"><label for="checkbox10">Basic +</label></div>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label-static">Price per month</p>
<h6>${{ Number.parseFloat(subscription.plans.plusPrice).toFixed(2) }}</h6>
</div>
</div>
<div class="col-3">
<div class="form-item d-flex">
<div class="d-flex flex-column align-items-center">
<p class="form-label-static">No. of Subscriptions</p>
<div class="form-item d-flex">
<h6>{{ Number.parseInt(subscription.plans.plusNumber) }}</h6>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="content-row grid-4" v-if="subscription.plans.advance">
<div class="row">
<div class="col-3 align-self-end">
<div class="form-item">
<div class="checkbox checkbox-lg"><input type="checkbox" id="checkbox11" v-model="subscription.plans.advance"><label for="checkbox11">Basic + Advance</label></div>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label-static">Price per month</p>
<h6>${{ Number.parseFloat(subscription.plans.advancePrice).toFixed(2) }}</h6>
</div>
</div>
<div class="col-3">
<div class="form-item d-flex">
<div class="d-flex flex-column align-items-center">
<p class="form-label-static">No. of Subscriptions</p>
<div class="form-item d-flex">
<h6>{{ Number.parseInt(subscription.plans.advanceNumber) }}</h6>
</div>
</div>
</div>
</div>
</div>
</div>

<div class="content-row grid-4" v-if="subscription.plans.ultimate">
<div class="row">
<div class="col-3 align-self-end">
<div class="form-item">
<div class="checkbox checkbox-lg"><input type="checkbox" id="checkbox12" v-model="subscription.plans.ultimate"><label for="checkbox12">Basic + Ultimate</label></div>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label-static">Price per month</p>
<h6>${{ Number.parseFloat(subscription.plans.ultimatePrice).toFixed(2) }}</h6>
</div>
</div>
<div class="col-3">
<div class="form-item d-flex">
<div class="d-flex flex-column align-items-center">
<p class="form-label-static">No. of Subscriptions</p>
<div class="form-item d-flex">
  <h6>{{ Number.parseInt(subscription.plans.ultimateNumber) }}</h6>
</div>
</div>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label-static">Billing Type</p>
<h6>{{ subscription.subscriptionPeriod }}</h6>
<span class="form-info">*10% Discount on Yearly Subscription</span>
</div>
</div>
</div>
</div>

<div class="content-row overflow-auto subscription-result-wrap" v-if="!subscription.plans.trial">
<div class="four-column-grid">
<div class="text-center">
  <p class="form-label">Subscription Amount</p>
  <h6>${{ Number.parseFloat(subscription.totalSubscription || 0).toFixed(2) }}</h6>  
</div>
<div class="text-center">
  <p class="form-label">Discount</p>
  <h6>${{ Number.parseFloat(subscription.taxes || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Taxes</p>
  <h6>${{ Number.parseFloat(subscription.discount || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Total</p>
  <h6>${{ Number.parseFloat(subscription.total || 0).toFixed(2) }}</h6>  
</div>
</div>  
</div>

</div>

</div>-->

</div>
</div>
</section>
</template>

<script>
import { inject, ref, watch, onMounted } from "vue";
import moment from 'moment';
import { useStore } from "vuex";

export default {
  name: "ProfileSubscription",
  props: {
    userType: String,
  },
  setup() {
    const store = useStore();

    const { subscription, focusOnSubscription, changeSubscriptionField } = inject('subscription');
    const { isAuth } = inject('mainUI');
    const { onlyViewMode } = inject('userProfileCommon');

    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);

    const helperTooltip = ref(false);
    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle;
    }

    const subscriptionTooltip = ref(false);
    
    const switchSubscriptionTooltip = (toggle) => {
      subscriptionTooltip.value = toggle;
    }

    watch(focusOnSubscription, (newVal) => {
      if (newVal) {
        switchHelperTooltip(true);
        setTimeout(() => switchHelperTooltip(false), 5000);
      }
    })

    const toggleSection = () => {
      sectionExpanded.value = !sectionExpanded.value
    }

    watch(sectionExpanded, (newVal) => {
      section.value.style.height = newVal ? 
        `${sectionHeight.value}px` : 
          `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'    })

    watch(() => ({...store.state.primaryUser.subscription}), (newVal) => {
      if (newVal) {
        for (let prop in subscription) {
          if (prop === 'plans') {
            for (let plan in subscription.plans) {
              subscription.plans[plan] = newVal?.plans[plan];
            }
          } else {
            changeSubscriptionField(newVal[prop], prop);
          }
        }
        changeSubscriptionField(true, 'submitted');
        changeSubscriptionField('represent', 'mode');
      }
    })

    onMounted(() => {

      setTimeout(() => {
        section.value.style.height = 'auto';
      });
      
      setTimeout(() => {
        console.log(section.value.offsetHeight, 'section.value.offsetHeight');
        sectionHeight.value = section.value.offsetHeight;
        section.value.style.height = `${section.value.offsetHeight}px`;
      }, 100);

      if (store.state.primaryUser?.subscription) {
        for (let prop in subscription) {
          if (prop === 'plans') {
            for (let plan in subscription.plans) {
              subscription.plans[plan] = store.state.primaryUser?.subscription?.plans[plan];
            }
          } else {
            // subscription[prop] = store.state.primaryUser?.subscription[prop];
            changeSubscriptionField(store.state.primaryUser?.subscription[prop], prop);
          }
        }
        changeSubscriptionField(true, 'submitted');
        changeSubscriptionField('represent', 'mode');
      }
    })

    return {
      moment,
      subscription,
      changeSubscriptionField,
      isAuth,

      switchHelperTooltip,
      helperTooltip,
      subscriptionTooltip,
      switchSubscriptionTooltip,
      section,
      boxHeader,
      toggleSection,
      onlyViewMode
    };
  },
};
</script>

<style lang="scss" scoped src="@/styles/pages/UserProfile/ProfileSubscription.scss"></style>
