<template>
  <EquipmentHeader />
  
  <ServiceForm v-if="((editionMode === 'edit') || (editionMode === 'create')) && (isAuth('add-new-equipment-service') || isAuth('edit-equipment-service'))" />
  <ServiceFilled v-if="(editionMode === 'represent') && (services.length > 0)" />

  <ServiceList />

</template>

<script>
import { defineComponent, ref, reactive, provide, computed, inject } from 'vue';
import EquipmentHeader from './EquipmentHeader.vue';
import ServiceForm from './ServiceForm.vue';
import ServiceFilled from './ServiceFilled.vue';
import ServiceList from './ServiceList.vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

export default defineComponent({
    name: "EquipmentService",
    setup() {
      const store = useStore();
      const router = useRouter();

      const editionMode = ref('');

      const { isAuth } = inject('mainUI');

      const services = computed(() => [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.type === 'Service'));

      const vendors = computed(() => store.state.vendors);

      const service = reactive({
        id: '',
        date: '',
        serviceType: '',
        equipment: '',
        vendor: '',
        vendorName: '',
        invoice: '',
        amountPaid: '',
        vendorContact: '',
        vendorPhone: '',
        warrantyExp: '',
        serviceDate: '',

        comments: ''
      });

      const selectedService = reactive({
        ...service
      });

      const defaultService = {
        ...service
      }

      const addNewService = () => {
        router.push({
          name: 'vendors', 
          params: {
            pagename: 'vendor-module',
            source: 'service-module'
          }
        })
      }

      const editService = (field, val) => {
        service[field] = val;
      }

      const selectService = (serviceData, editMode) => {
        if (serviceData) {
          for (let field in serviceData) {
            service[field] = serviceData[field]
            selectedService[field] = serviceData[field]
          }
        }
        editionMode.value = editMode;

        const selectedVendor = vendors.value.find(stateVendor => stateVendor.id === service.vendor);

        console.log(selectedVendor, 'selectedVendor');

        if (selectedVendor) {
          service.vendorContact = selectedVendor.vendorContact;
          selectedService.vendorContact = selectedVendor.vendorContact;
          service.vendorName = selectedVendor.vendor;
          selectedService.vendorName = selectedVendor.vendor;
          service.vendorPhone = selectedVendor.vendorPhone;
          selectedService.vendorPhone = selectedVendor.vendorPhone;
        }
      }

      const removeService = (serviceId) => {
        store.dispatch('removeVendorItem', serviceId)
      };

      provide('serviceDetails', {
        service,
        defaultService,
        selectedService,
        editionMode,
        editService,
        selectService,
        removeService,
        addNewService
      })

      return {
        editionMode,
        service,
        services,
        isAuth
      }
    },
    components: { EquipmentHeader, ServiceForm, ServiceFilled, ServiceList }
})
</script>

<style lang="scss" scoped></style>