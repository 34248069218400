<template>
  <div class="wrapper">
    <router-view></router-view>
    <div v-if="userInactive">
      <div class="submit-popup-wrapper-fixed">
        <div class="popup-message">
          <div class="close-button" @click="() => {userInactive = false}" ></div>
          <div class="popup-text">{{ 'Due to non-activity your session will close in 5 minutes!' }}</div>
        </div>
      </div>
    </div>
    <div v-if="sessionIsEnding">
      <UserNotification 
        v-bind:hideDetails="true"
        v-bind:messageText="'Your session has been open for long time it will close in 1/2 hours and you will be required to login again'"
      />
    </div>
  </div>
  <div class="backdrop" :class="{active: menuIsActive}" @click="switchMenu"></div>
</template>

<script>
import { ref, provide, onMounted, watch } from 'vue' //watch
import { useStore } from 'vuex';
import UserNotification from './components/Shared/UserNotification.vue';
// import { useRouter } from 'vue-router';
// import UserProfile from './components/Pages/UserProfile/UserProfile.vue'

export default {
  name: "LayoutDefault",

  components: {
    // UserProfile
    UserNotification
  },
  setup() {

    const store = useStore();

    // const router = useRouter();
    
    const menuIsActive = ref(false);

    const userInactive = ref(false);

    const sessionIsEnding = ref(false);

    const switchMenu = () => {
      menuIsActive.value = !menuIsActive.value
    }

    const inactivityTime = function () {
      let time;
      window.onload = resetTimer;
      // DOM Events
      document.onload = resetTimer;
      document.onmousemove = resetTimer;
      document.onmousedown = resetTimer; // touchscreen presses
      document.ontouchstart = resetTimer;
      document.onclick = resetTimer;     // touchpad clicks
      document.onkeydown = resetTimer;   // onkeypress is deprectaed
      document.addEventListener('scroll', resetTimer, true);

      function logout() {
        userInactive.value = true;
        clearTimeout(time);
        time = setTimeout(() => {
          store.dispatch('logOutApi');
          clearTimeout(time);
        }, 300000)
      }

      function resetTimer() {
        userInactive.value = false;
        clearTimeout(time);
        time = setTimeout(logout, 1500000)
      }
    };

    provide('mainMenu', {
      menuIsActive,
      switchMenu
    });

    // watch(() => ({...store.state.authenticated}), (newVal, oldVal) => {
    //   if (!oldVal.accountSubdomain && newVal.accountSubdomain) {
    //     console.log('currentRoute', window.location.href);
    //     // const currentRoute = router.currentRoute.value;
    //     setTimeout(() => {
    //       const currentRoute = window.location.href;
    //       const withSubdomain = currentRoute.split('https://')[1];
    //       const baseName = withSubdomain.split('.');
    //       // router.push(`https://${newVal.accountSubdomain}.${baseName[1]}.${baseName[2]}`);
    //       if (baseName.length > 3) {
    //         window.location = `https://${newVal.accountSubdomain}.${baseName[1]}.${baseName[2]}.${baseName[3]}.${baseName[4]}`;
    //       } else {
    //         window.location = `https://${newVal.accountSubdomain}.${baseName[1]}.${baseName[2]}`;
    //       }
    //     }, 6000);
    //   }
    // });

    watch(() => ({...store.state.primaryUser?.subscription}), (newVal) => {
      console.log('subscription is changed', newVal);
    });

    onMounted(() => {
      inactivityTime();
      setTimeout(() => {
        sessionIsEnding.value = true;
        setTimeout(() => {
          store.dispatch('logOutApi');
        }, 1800000)
      }, 84600000);

      /*
      if (!store.state.authenticated.accountSubdomain) {
        // const currentRoute = router.currentRoute.value;
        const currentRoute = window.location.href;
        const withSubdomain = currentRoute.split('https://')[1];
        const baseName = withSubdomain.split('.');
        // router.push(`https://newaccount.${baseName[1]}.${baseName[2]}`);
        
        if (baseName.length > 3) {
          if ((window.location.href !== `https://newaccount.${baseName[1]}.${baseName[2]}.${baseName[3]}.${baseName[4]}`) ||
            (window.location !== `https://${store.state.authenticated.accountSubdomain}.${baseName[1]}.${baseName[2]}`)) {
            window.location = `https://newaccount.${baseName[1]}.${baseName[2]}.${baseName[3]}.${baseName[4]}`;
          }
        } else {
          if ((window.location.href !== `https://newaccount.${baseName[1]}.${baseName[2]}`) ||
            (window.location = `https://${store.state.authenticated.accountSubdomain}.${baseName[1]}.${baseName[2]}`)) {
            window.location = `https://newaccount.${baseName[1]}.${baseName[2]}`;
          }
        }
      }
      */
    })

    return {
      menuIsActive,
      switchMenu,
      userInactive,
      sessionIsEnding
    }
  },
  // mounted() {
  // },
  beforeMount() {
    const store = useStore();

    store.commit('initialiseVars')  
  }
};
</script>

<style lang="scss" src="@/styles/app.scss"></style>