<template>
  <section class="section" ref="section">
    <div class="border-box">
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Services List</h5>
<div class="info-icons">
<div class="info-icon lg top-icon tooltip"
  @click="addNewService"
  data-tooltip="Add Service"
  v-if="isAuth('add-new-equipment-service')"
>
  <img src="@/assets/images/updated/new_set2/Servicing_Circle.png" alt="">
  <!-- <img src="@/assets/images/updated/add-item.png" alt=""> -->
</div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row overflow-x-scroll table-container">
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label">
  <span>Service Type</span>
  <span @click="() => sortTable('serviceType')">
    <button :class="{active: sortOrder.serviceType === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.serviceType === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Equipment</span>
  <span @click="() => sortTable('equipment')">
    <button :class="{active: sortOrder.equipment === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.equipment === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Vendor</span>
  <span @click="() => sortTable('vendorName')">
    <button :class="{active: sortOrder.vendorName === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.vendorName === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Date Purchased</span>
  <span @click="() => sortTable('date')">
    <button :class="{active: sortOrder.date === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.date === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Warranty date</span>
  <span @click="() => sortTable('warrantyExp')">
    <button :class="{active: sortOrder.warrantyExp === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.warrantyExp === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Service Date</span>
  <span @click="() => sortTable('serviceDate')">
    <button :class="{active: sortOrder.serviceDate === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.serviceDate === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

<template
    v-for="(row, rowIndex) in rows"
    v-bind:key="'equipment-list-row' + row.id"
  >
    <tr
      v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
      :class="{'light-gray-bg': row.id === selectedService.id}"
      @click="(ev) => selectByRow(ev, {...row})"
    >
    <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
    <td class="text-center">{{ row.serviceType }}</td>
    <td>{{ row.equipment }}</td>
    <td>{{ row.vendorName }}</td>
    <td>{{ row.date }}</td>
    <td>{{ row.warrantyExp }}</td>
    <td>{{ row.serviceDate }}</td>
    <td class="w-75">
    <div class="table-links">
    <a v-if="isAuth('edit-equipment-service')" href="javascript:void();" @click="selectService({...row}, 'edit')">
      <img src="@/assets/images/edit-icon-2.svg" alt="">
    </a>
    <a v-if="isAuth('remove-equipment-service')" href="javascript:void();" @click="removeSelectedService(row.id)">
      <img src="@/assets/images/trash-2.svg" alt="">
    </a>
    <!-- <a href="javascript:void();" @click="callEquipment(row.id, row.phone)">
      <img src="@/assets/images/phone-icon.svg" alt="">
    </a> -->
    </div>
    </td>
    </tr>
  </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'serviceListDetails'"
    :optionKey="'service-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>
</div>
</section>

<div class="removal-popup" v-if="removedServiceId">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">Are you sure you want to delete the service?</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveRemoval(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { defineComponent, ref, onMounted, watch, computed, inject, reactive, provide } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: "ServiceList",
    setup() {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const services = computed(() => [...store.state.vendorItems].filter(stateItem => stateItem.type === 'Service'));
        const vendors = computed(() => store.state.vendors);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const removedServiceId = ref(null);
        const sortOrder = reactive({
            serviceType: 0,
            equipment: 0,
            vendorName: 0,
            date: 0,
            warrantyExp: 0,
            serviceDate: 0
        });
        const { isAuth } = inject('mainUI');
        const { selectedService, addNewService, editService, selectService, removeService } = inject('serviceDetails');
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...services.value];
                sortOrder[sortProp] = 0;
            }
        };
        const removeSelectedService = (serviceId) => {
            removedServiceId.value = serviceId;
        };
        const approveRemoval = (isApproved) => {
            if (isApproved) {
                removeService(removedServiceId.value);
            }
            removedServiceId.value = '';
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectService({ ...row }, 'represent');
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(() => ([...store.state.vendorItems]), () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            // rows.value = [...services.value];
            rows.value = [...store.state.vendorItems].filter(stateItem => stateItem.type === 'Service').map(originService => {
                return {
                    ...originService,
                    vendorName: vendors.value.find(vendor => vendor.id === originService.vendor)?.vendor
                };
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
          store.dispatch('getVendorItems');
            (services.value.length > 0) && selectService({ ...services.value[services.value.length - 1] }, 'represent');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getVendors')
            // rows.value = [...services.value];
            rows.value = [...services.value].map(originService => {
                return {
                    ...originService,
                    vendorName: vendors.value.find(vendor => vendor.id === originService.vendor)?.vendor
                };
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('serviceListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            addNewService,
            editService,
            selectService,
            rows,
            maxPage,
            currentPage,
            selectedService,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            removedServiceId,
            removeSelectedService,
            approveRemoval,
            selectByRow,
            isAuth
        };
    },
    components: { DropDownList }
})
</script>

<style lang="scss" scoped></style>