<template>
  <VendorHeader />
  
  <div ref="vendorForm"></div>
  <VendorForm v-if="((editionMode === 'edit') || (editionMode === 'create'))" />
  <!--  && (isAuth('edit-vendor') || isAuth('add-vendor')) -->
  <VendorFilled  v-if="editionMode === 'represent'" />

  <div ref="vendorItemForm"></div>
  <!-- <VendorProductsServices :key="`${vendor.id}-${vendorItem.id}-vendor-item-form`" v-if="((productsServicesMode === 'create') || (productsServicesMode === 'edit'))" /> -->
  <VendorProductsServicesFilled :key="`${vendor.id}-${vendorItem.id}-vendor-item-filled`" v-bind:archived="true" v-if="(productsServicesMode === 'represent') && (vendorItems.length > 0)" />
  
  <VendorProductsServicesList :key="`${vendor.id}-vendor-item-list`" v-bind:archived="true" v-if="(vendorItems.length > 0) && (!isInputDialog && !isEquipmentDialog)" />
  
  <VendorList v-bind:archived="true" />

</template>

<script>
import { ref, reactive, provide, watch } from 'vue';
import VendorFilled from './VendorFilled.vue';
import VendorForm from './VendorForm.vue';
import VendorHeader from './VendorHeader.vue';
import VendorList from './VendorList.vue';
import VendorProductsServicesFilled from './VendorProductsServicesFilled.vue';
import VendorProductsServicesList from './VendorProductsServicesList.vue';
import { useStore } from 'vuex';

export default {
    name: "ArchivedVendors",
    setup() {

      const store = useStore();

      const editionMode = ref('');

      const productsServicesMode = ref('');

      const vendorForm = ref(null);

      const vendorItemForm = ref(null);

      const vendor = reactive({
        id: '',
        vendor: '',
        vendorContact: '',
        vendorPhone: '',
        email: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        status: '',

        // product: '',
        // brand: '',
        // variety: '',
        mobile: '',

        // serviceType: '',
        // cellPhone: '',
        comments: ''
      });

      const vendorItem = reactive({
        id: '',
        type: '',
        item: '',
        manufacturer: '',
        brand: '',
        variety: '',

        numberOfItems: '',
        quantity: '',
        date: '',
        invoice: '',
        amountPaid: '',
        warrantyExp: '',

        serviceDate: '',

        serviceType: '',
        equipment: '',

        comments: ''
      });

      const selectedVendor = reactive({
        ...vendor
      });

      const defaultVendor = {
        ...vendor
      }

      const defaultVendorItem = {
        ...vendorItem
      }

      const vendorItems = ref(() => [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.vendor === vendor.id));

      const addVendor = () => {
        selectVendor(defaultVendor, 'create');
        // editionMode.value = 'create';
      }

      const editVendor = (field, val) => {
        vendor[field] = val;
      }

      const selectVendor = (vendorData, editMode) => {
        if (vendorData) {
          for (let field in vendorData) {
            vendor[field] = vendorData[field]
            selectedVendor[field] = vendorData[field]
          }
        }
        editionMode.value = editMode;

        if ((editMode === 'edit') || (editMode === 'create')) {
          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: vendorForm?.value?.offsetTop - 100,
              left: 0,
              behavior: 'smooth'
            })
          })
        }
      }

      const selectVendorItem = (itemData, editMode) => {
        if (itemData) {
          for (let prop in itemData) {
            vendorItem[prop] = itemData[prop];
          }
        }
        productsServicesMode.value = editMode;

        if ((editMode === 'edit') || (editMode === 'create')) {
          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: vendorItemForm?.value?.offsetTop - 100,
              left: 0,
              behavior: 'smooth'
            })
          })
        }
      }

      // const removeVendor = (vendorId) => {
      //   store.dispatch('removeVendor', vendorId)
      // };

      // const removeVendorItem = (vendorItemId) => {
      //   store.dispatch('removeVendorItem', vendorItemId);
      // }

      const navigateToItemForm = () => {
        setTimeout(() => {
          document.getElementsByClassName('form-modal')[0].scroll({
            top: vendorItemForm?.value?.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        })
      }

      watch(() => (vendor.id), (newVal) => {
        if (newVal) {
          vendorItems.value = [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.vendor === vendor.id);
        }
      });

      watch(store.state.vendorItems, (newVal) => {
        if (newVal) {
          vendorItems.value = [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.vendor === vendor.id);
        }
      })

      provide('vendorDetails', {
        vendor,
        defaultVendor,
        selectedVendor,
        editionMode,
        addVendor,
        editVendor,
        selectVendor,
        // removeVendor
        productsServicesMode,
        vendorItem,
        defaultVendorItem,
        selectVendorItem,
        // removeVendorItem,
        navigateToItemForm
      })

      return {
        editionMode,
        // isAuth,
        vendor,
        productsServicesMode,
        vendorItems,
        vendorItem,
        vendorForm,
        vendorItemForm
      }
    },
    components: { VendorHeader, VendorForm, VendorFilled, VendorList, VendorProductsServicesFilled, VendorProductsServicesList }
}
</script>

<style></style>