<template>

<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Crop Harvest</h5>
<div class="icon-group">
  <div class="icon green icon-o top-icon tooltip"
  @click="selectHarvest({...harvest}, 'edit');"
  data-tooltip="Edit"
  v-if="isAuth('edit-harvest')"
>
  <img src="@/assets/images/updated/Edit.png" alt="">
</div>
<div class="icon green icon-o tooltip"
  @click="addNewHarvest"
  @mouseover="switchAddHelperTooltip(true)"
  @mouseout="switchAddHelperTooltip(false)"
  data-tooltip="Add Harvest"
  v-if="isAuth('add-new-harvest')"
>
  <img src="@/assets/images/updated/add-item.png" alt="">
</div>
</div>
</div>

<div class="content-block">
<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Date</p>
<h6>{{ harvest.date }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Farm</p>
<h6>{{ harvest.farm }}</h6>
</div>

<div class="intro-grid-column" v-if="harvest.bed">
<p class="title">Bed</p>
<h6>{{ harvest.bed }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Season</p>
<h6>{{ harvest.season }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Crop Cycle</p>
<h6>{{ harvest.cropCycle }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Crop</p>
<h6>{{ harvest.crop }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Assigned To</p>
<h6>{{ harvest.assignedTo }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Amt  Harvested</p>
<h6>{{ harvest.amtHarvested }} lbs</h6>
</div>

<div class="intro-grid-column">
<p class="title">Wastage</p>
<h6>{{ harvest.wastage }} lbs</h6>
</div>

<div class="intro-grid-column">
<p class="title">Wastage Cause</p>
<h6>{{ harvest.wastageCause }}</h6>
</div>

<div class="intro-grid-column intro-grid-comment-column">
<p class="title">Comments</p>
<h6>{{ harvest.comments }}</h6>
</div>

</div>
</div>

<div class="content-block">

  <FileAttachments :key="`harvest-filled-${harvest.id}`" v-bind:filled="true" v-bind:section="'task'"  v-bind:instance="`${harvest.id}`" />

</div>

</div>

</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { inject, ref } from 'vue';

export default {
    name: "HarvestFilled",
    components: { FileAttachments },
    setup() {
      const { isAuth } = inject('mainUI');
      const { harvest, selectHarvest, addNewHarvest } = inject('harvestDetails');

      const editHelperTooltip = ref(false);
      const helperTooltip = ref(false);
      const addHelperTooltip = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const switchEditHelperTooltip = (toggle) => {
        editHelperTooltip.value = toggle;
      }

      const switchAddHelperTooltip = (toggle) => {
        addHelperTooltip.value = toggle;
      }

  
      
      return {
        harvest,
        selectHarvest,
        editHelperTooltip,
        helperTooltip,
        addHelperTooltip,
        switchHelperTooltip,
        switchEditHelperTooltip,
        switchAddHelperTooltip,
        addNewHarvest,
        isAuth
      }
    }
}
</script>

<style lang="scss" scoped>
.edit-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 40px;
  left: -30px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 41px;
    background: #3C3838;
    z-index: -1;
  }
}

.add-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 40px;
  left: -50px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 61px;
    background: #3C3838;
    z-index: -1;
  }
}
</style>