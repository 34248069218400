<template>
  <section class="section" ref="section">
  <div class="border-box">

  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5 class="fw-semibold">Sales</h5>
  <div class="info-icons">
    <div class="info-icon icon green icon-o tooltip"
      @mouseover="switchHelperTooltip(true)"
      @mouseout="switchHelperTooltip(false)"
      data-tooltip="Camera"
      v-if="isAuth('camera-sales')"
    >
      <img src="@/assets/images/updated/camera-white.png" alt="">
    </div>
    <div class="info-icon icon green icon-o tooltip"
      @click="selectSales({...sales}, 'edit');"
      @mouseover="switchEditHelperTooltip(true)"
      @mouseout="switchEditHelperTooltip(false)"
      data-tooltip="Edit"
      v-if="isAuth('edit-sales')"
    >
      <img src="@/assets/images/updated/Edit.png" alt="">
      <!-- <div class="edit-helper-tooltip" v-if="editHelperTooltip">Edit Sales</div> -->
    </div>
  </div>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>

  <div class="border-box-content">

  <div class="content-block">
  <div class="intro-grid">

  <div class="intro-grid-column">
  <p class="title">Date</p>
  <h6>{{ moment(sales.date, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Farm</p>
  <h6>{{ sales.farm }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Crop Category</p>
  <h6>{{ sales.cropCategory }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Crop</p>
  <h6>{{ sales.crop }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Variety</p>
  <h6>{{ sales.variety }}</h6>
  </div>
 
  <!-- <div class="intro-grid-column">
  <p class="title">Customer Type</p>
  <h6>{{ sales.customerType }}</h6>
  </div> -->

  <!-- <div class="intro-grid-column">
  <p class="title">Customer</p>
  <h6>{{ sales.customer }}</h6>
  </div> -->

  <div class="intro-grid-column">
  <p class="title">Quantity Sold</p>
  <h6>{{ sales.quantitySold }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Rate</p>
  <h6>${{ `${Number.parseInt(sales.rate).toFixed(2)}` }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Total</p>
  <h6>${{ `${Number.parseInt(sales.total).toFixed(2)}` }}</h6>
  </div>

  <div class="intro-grid-column intro-grid-comment-column">
  <p class="title">Comments</p>
  <h6>{{ sales.comments }}</h6>
  </div>

  </div>
  </div>

  <div class="content-block">

    <FileAttachments :key="`sales-filled-${sales.id}`" v-bind:filled="true" v-bind:section="'sales'" v-bind:instance="`${sales.id}`" />

  </div>

  </div>
  </div>
  </section>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import moment from 'moment';
import { defineComponent, inject, ref, watch, onMounted } from 'vue';

export default defineComponent({
    name: "SalesFilled",
    setup() {

      const section = ref(null);
      const boxHeader = ref(null);
      const sectionHeight = ref(0);
      const sectionExpanded = ref(true);

      const { isAuth } = inject('mainUI');

      const { sales, selectSales, addSales } = inject('salesDetails');

      const editHelperTooltip = ref(false);
      const helperTooltip = ref(false);

      const toggleSection = (ev) => {
        if (ev.target.tagName === 'IMG') {
          if (ev.target.className !== 'angle') {
            return;
          }
        }
        sectionExpanded.value = !sectionExpanded.value;
      };

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const switchEditHelperTooltip = (toggle) => {
        editHelperTooltip.value = toggle;
      }  

      watch(sectionExpanded, (newVal) => {
        section.value.style.height = newVal ?
          `${sectionHeight.value}px` :
          `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
      });

      onMounted(() => {
        setTimeout(() => {
          if (section.value) {
            sectionHeight.value = section.value.offsetHeight;
            section.value.style.height = `${section.value.offsetHeight}px`;
          }
        });
      });
      
      return {
        sales,
        addSales,
        selectSales,
        editHelperTooltip,
        helperTooltip,
        switchHelperTooltip,
        switchEditHelperTooltip,
        moment,
        section,
        boxHeader,
        toggleSection,
        isAuth
      }
    },
    components: { FileAttachments }
})
</script>

<style lang="scss" scoped>
.edit-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 40px;
  left: -30px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 41px;
    background: #3C3838;
    z-index: -1;
  }
}
</style>