<template>
  <section class="section" ref="section">
      <div class="border-box active">
          <div class="border-box-header" ref="boxHeader" @click="toggleSection">
              <h5>Servicing Expenses</h5>
              <img src="@/assets/images/angle-down.png" alt="" class="angle">
          </div>
          <div class="border-box-content">

              <div class="content-block">

                  <div class="content-row grid-4 grid-full">
                      <div class="row">

                          <div class="col-3">
                              <div class="form-item">
                                  <p class="form-label">Choose a Farm:</p>
                                  <DropDownList
                                    :mainObject="'currentSelection'"
                                    :mainProperty="'farm'"
                                    :injectionName="'selectionDetails'"
                                    :optionKey="'farm-option-inventory-expenses'"
                                    :optionsList="[]"
                                    :optionProperty="'farmName'"
                                    :optionPropertyTwo="null"
                                    :defaultSelected="currentSelection.farm"
                                    @click="() => {
                                        // activated.farm = true;
                                    }"
                                  />
                              </div>
                          </div>

                          <div class="col-3">
                              <div class="form-item">
                                  <p class="form-label">Choose a Year:</p>
                                  <DropDownList
                                    :mainObject="'currentSelection'"
                                    september      :mainProperty="'year'"
                                    :injectionName="'selectionDetails'"
                                    :optionKey="'year-option-inventory-expenses'"
                                    :optionsList="yearOptions"
                                    :optionProperty="null"
                                    :optionPropertyTwo="null"
                                    :defaultSelected="yearOptions[0]"
                                    @click="() => {
                                        // activated.year = true;
                                    }"
                                  />
                              </div>
                          </div>

                      </div>
                  </div>

              </div>

              <div class="content-block">

                  <div class="content-row table-wrapper-block">
                      <div class="table-wrapper">
                          <table class="headding-table">
                              <thead>
                                  <tr><th><p class="form-label">&nbsp;</p></th></tr>
                              </thead>
                              <tbody>
                                  <template v-for="row in rows" v-bind:key="'servicing-expenses-row-header' + row.inventory">
                                    <tr><td><p class="form-label">{{ row.service }}</p></td></tr>
                                  </template>
                                  <tr><td><p class="form-label green">Total Inv. Exp</p></td></tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="table-wrap flex-1">
                          <table>

                              <thead>
                                  <tr>
                                      <th class="text-center" style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Jan</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Feb</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Mar</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Apr</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">May</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Jun</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Jul</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Aug</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Sep</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Oct</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Nov</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Dec</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label green">Total</p>
                                      </th>
                                  </tr>
                              </thead>

                              <tbody>

                                <template v-for="row in rows" v-bind:key="'servicing-expenses-row' + row.service">
                                  <tr>
                                    <td class="text-center">{{ `$${Number.parseFloat(row.january).toFixed(2)}` }}</td>
                                    <td>{{ `$${Number.parseFloat(row.february).toFixed(2)}` }}</td>
                                    <td>{{ `$${Number.parseFloat(row.march).toFixed(2)}` }}</td>
                                    <td>{{ `$${Number.parseFloat(row.april).toFixed(2)}` }}</td>
                                    <td>{{ `$${Number.parseFloat(row.may).toFixed(2)}` }}</td>
                                    <td>{{ `$${Number.parseFloat(row.june).toFixed(2)}` }}</td>
                                    <td>{{ `$${Number.parseFloat(row.july).toFixed(2)}` }}</td>
                                    <td>{{ `$${Number.parseFloat(row.august).toFixed(2)}` }}</td>
                                    <td>{{ `$${Number.parseFloat(row.september).toFixed(2)}` }}</td>
                                    <td>{{ `$${Number.parseFloat(row.october).toFixed(2)}` }}</td>
                                    <td>{{ `$${Number.parseFloat(row.november).toFixed(2)}` }}</td>
                                    <td>{{ `$${Number.parseFloat(row.december).toFixed(2)}` }}</td>
                                    <td><span class="bg">{{ `$${Number.parseFloat(row.total).toFixed(2)}` }}</span></td>
                                  </tr>
                                </template>
                                <tr>
                                      <td class="text-center"><span class="bg">${{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.january),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.february),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.march),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.april),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.may),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.june),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.july),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.august),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.september),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.october),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.november),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.december),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.total),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                  </tr>

                                
                              </tbody>

                          </table>
                      </div>
                      
                  </div>

              </div>

          </div>
      </div>
  </section><!--//End Section-->
</template>

<script>
import { defineComponent, ref, onMounted, watch, provide } from 'vue';
import { useStore } from 'vuex';
import DropDownList from '@/components/Shared/DropDownList.vue';
import yearOptions from '@/sharedData/yearsList';


export default defineComponent({
  name: 'ServicingExpenses',
  setup() {
    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);
    const store = useStore();

    const rows = ref([
        {
            service: 'Repair',
            january: '35.00',
            february: '35.00',
            march: '35.00',
            april: '35.00',
            may: '35.00',
            june: '35.00',
            july: '35.00',
            august: '35.00',
            september: '35.00',
            october: '35.00',
            november: '35.00',
            december: '35.00',
            total: '350.00'
        },
        {
            service: 'Repair 2',
            january: '5.00',
            february: '5.00',
            march: '5.00',
            april: '5.00',
            may: '5.00',
            june: '5.00',
            july: '5.00',
            august: '5.00',
            september: '5.00',
            october: '5.00',
            november: '5.00',
            december: '5.00',
            total: '5.00'
        },
        {
            service: 'Repair 3',
            january: '5.00',
            february: '5.00',
            march: '5.00',
            april: '5.00',
            may: '5.00',
            june: '5.00',
            july: '5.00',
            august: '5.00',
            september: '5.00',
            october: '5.00',
            november: '5.00',
            december: '5.00',
            total: '5.00'
        },
        {
            service: 'Repair 4',
            january: '5.00',
            february: '5.00',
            march: '5.00',
            april: '5.00',
            may: '5.00',
            june: '5.00',
            july: '5.00',
            august: '5.00',
            september: '5.00',
            october: '5.00',
            november: '5.00',
            december: '5.00',
            total: '5.00'
        },
        {
            service: 'Repair 5',
            january: '5.00',
            february: '5.00',
            march: '5.00',
            april: '5.00',
            may: '5.00',
            june: '5.00',
            july: '5.00',
            august: '5.00',
            september: '5.00',
            october: '5.00',
            november: '5.00',
            december: '5.00',
            total: '5.00'
        }
    ]);
    const farms = ref([...store.state.farms]);

    const currentSelection = ref({
      farm: '',
      year: ''
    });

    const toggleSection = (ev) => {
        if ((ev.target.className !== 'border-box-header') &&
            (ev.target.className !== 'angle') && (ev.target.className !== 'angle revert')) {
            return;
        }
        sectionExpanded.value = !sectionExpanded.value;
    };
    watch(sectionExpanded, (newVal) => {
        section.value.style.height = newVal ?
            `${sectionHeight.value}px` :
            `${boxHeader.value.offsetHeight}px`;
        section.value.style.overflow = newVal ? 'visible' : 'hidden';
    });
    // watch(() => ([...store.state]), (newVal) => { 
    //     rows.value = [...newVal];
    //     setTimeout(() => {
    //         section.value.style.height = 'auto';
    //         sectionHeight.value = section.value.offsetHeight;
    //     });
    // });
    watch(() => ([...store.state.farms]), (newVal) => { 
        farms.value = [...newVal];
    });
    // watch(() => ({...currentSelection}), (newVal) => {
    //     if (newVal.value.farm && newVal.value.year) {
    //         rows.value = [...store.state].filter(e => (e.year === newVal.value.year && e.farm === newVal.value.farm));
    //     }
    // });

    onMounted(() => {
        setTimeout(() => {
            sectionHeight.value = section.value.offsetHeight;
            section.value.style.height = `${section.value.offsetHeight}px`;
        });
        // rows.value = [...store.state];
        // store.dispatch('get');
        store.dispatch('getFarms');
    });

    provide('selectionDetails', {
      currentSelection,
      options: farms 
    })

    return {
        section,
        boxHeader,
        toggleSection,
        rows,
        yearOptions,
        currentSelection
    }
  },
  components: { DropDownList }
});
</script>