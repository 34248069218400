<template>

<div class="section" ref="section">
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Employee Compensation List</h5>
<div class="info-icons">
<div class="info-icon lg top-icon tooltip"
  @click="addCompensation"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
  data-tooltip="Add Comp"
>
  <!-- <img src="@/assets/images/plus-green-md.png" alt=""> -->
  <img src="@/assets/images/updated/059-add.png" alt="">
  <!-- <div class="helper-tooltip" v-if="helperTooltip">Add Equipment</div> -->
</div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-wrapper">
<table>

<!-- 
<th><p class="form-label form-sort-label"><span>Employee Title</span><span><button><img src="@/assets/images/caret-up.png" alt=""></button><button><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label form-sort-label"><span>Employee Type</span><span><button><img src="@/assets/images/caret-up.png" alt=""></button><button><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label form-sort-label"><span>Grade</span><span><button><img src="@/assets/images/caret-up.png" alt=""></button><button><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label form-sort-label"><span>Salary</span><span><button><img src="@/assets/images/caret-up.png" alt=""></button><button><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label form-sort-label"><span>Part Time</span><span><button><img src="@/assets/images/caret-up.png" alt=""></button><button><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label form-sort-label"><span>Over Time</span><span><button><img src="@/assets/images/caret-up.png" alt=""></button><button><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th> 
-->

<thead>
<tr>
<th><p class="form-label-static form-sort-label">
  <span>Employee Title</span>
  <span @click="() => sortTable('employeeTitle')">
    <button :class="{active: sortOrder.employeeTitle === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.employeeTitle === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Employee Type</span>
  <span @click="() => sortTable('employeeType')">
    <button :class="{active: sortOrder.employeeType === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.employeeType === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Grade</span>
  <span @click="() => sortTable('grade')">
    <button :class="{active: sortOrder.grade === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.grade === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Salary</span>
  <span @click="() => sortTable('annualSalary')">
    <button :class="{active: sortOrder.annualSalary === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.annualSalary === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Part Time</span>
  <span @click="() => sortTable('hourlyRate')">
    <button :class="{active: sortOrder.hourlyRate === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.hourlyRate === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Over Time</span>
  <span @click="() => sortTable('overTimeRate')">
    <button :class="{active: sortOrder.overTimeRate === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.overTimeRate === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

<template
    v-for="(row, rowIndex) in rows"
    v-bind:key="'compensation-list-row' + row.id"
  >
    <tr
      v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
      :class="{'light-gray-bg': row.id === selectedCompensation.id}"
      @click="(ev) => selectByRow(ev, {...row})"
    >
    <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
    <td class="text-center">{{ row.employeeTitle }}</td>
    <td>
      <template v-if="row.employeeType === 'salary'">Salary</template>
      <template v-if="row.employeeType === 'parttime'">Part Time</template>
      <template v-if="row.employeeType === 'other'">Other</template>
    </td>
    <td>{{ row.grade }}</td>
    <td><template v-if="row.annualSalary">$</template>{{ row.annualSalary }}</td>
    <td><template v-if="row.hourlyRate">$</template>{{ row.hourlyRate }}</td>
    <td><template v-if="row.overTimeRate">$</template>{{ row.overTimeRate }}</td>
    <td class="w-75">
    <div class="table-links">
    <a href="javascript:void();" @click="selectCompensation({...row}, 'edit')">
      <img src="@/assets/images/edit-icon-2.svg" alt="">
    </a>
    <a href="javascript:void();" @click="removeOneCompensation(row.id, row.assigned)">
      <img src="@/assets/images/trash-2.svg" alt="">
    </a>
    </div>
    </td>
    </tr>
  </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'employeeCompensationListDetails'"
    :optionKey="'employee-compensation-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>
</div>

<div class="removal-popup" v-if="removedCompensationId">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">Are you sure you want to delete the record?</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveRemoval(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { defineComponent, ref, onMounted, watch, inject, reactive, provide } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'EmployeeCompensationList',
    setup() {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const compensations = ref(store.state.compensations);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const sortOrder = reactive({
            employeeTitle: 0,
            employeeType: 0,
            grade: 0,
            annualSalary: 0,
            hourlyRate: 0,
            overTimeRate: 0
        });
        const { selectedCompensation, addCompensation, editCompensation, selectCompensation, removeCompensation, popups } = inject('compensationDetails');
        const helperTooltip = ref(false);
        const removedCompensationId = ref(null);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...compensations.value];
                sortOrder[sortProp] = 0;
            }
        };
        const removeOneCompensation = (compensationId, compensationAssigned) => {
            if (compensationAssigned) {
                popups.compensationToBeRemoved.active = true;
                setTimeout(() => {
                    popups.compensationToBeRemoved.active = false;
                }, 5010);
                return;
            }
            removedCompensationId.value = compensationId;
        };
        const approveRemoval = (isApproved) => {
            if (isApproved) {
                removeCompensation(removedCompensationId.value);
                selectCompensation({ ...compensations.value[compensations.value.length - 1] }, 'represent');
                setTimeout(() => {
                    removedCompensationId.value = '';
                }, 100);
            }
            else {
                removedCompensationId.value = '';
            }
        };
        // const sortTableByNumber = (sortProp) => {
        //   if (sortOrder[sortProp] === 0) {
        //     rows.value.sort((a,b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]))
        //     sortOrder[sortProp] = 1;
        //   } else if (sortOrder[sortProp] > 0) {
        //     rows.value.sort((a,b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
        //     sortOrder[sortProp] = -1;
        //   } else if (sortOrder[sortProp] < 0) {
        //     rows.value = [...equipments.value];
        //     sortOrder[sortProp] = 0;
        //   }
        // }
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectCompensation({ ...row }, 'represent');
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(() => ([...store.state.compensations]), (newVal) => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            compensations.value = [...newVal];
            rows.value = [...newVal];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
          store.dispatch('getCompensations');
            // (compensations.value.length > 0) && selectCompensation({...compensations.value[0]}, 'represent')
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getVendors')
            rows.value = [...store.state.compensations];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('employeeCompensationListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            selectedCompensation,
            addCompensation,
            editCompensation,
            selectCompensation,
            removeOneCompensation,
            rows,
            maxPage,
            currentPage,
            helperTooltip,
            switchHelperTooltip,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            removedCompensationId,
            approveRemoval,
            selectByRow
        };
    },
    components: { DropDownList }
})
</script>

<style lang="scss" scoped>
.top-icon {
  position: relative;
}
</style>