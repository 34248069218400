<template>

  <section class="section" ref="section">
  <div class="border-box">
    <div class="border-box-header" ref="boxHeader" @click="toggleSection">
    <h5>Farm List</h5>
    <div class="info-icons">

      <div class="info-icon lg header-icon tooltip" 
          v-if="isAuth('add-crop') && (bedsList.length <= 0) && (rows.length > 0)"
          @click="() => planNewCrop()"
          @mouseover="switchCropHelperTooltip(true)"
          @mouseout="switchCropHelperTooltip(false)"
          data-tooltip="Plan Crop"
        >
          <img src="@/assets/images/updated/plan-crop.png" alt="">
          <!-- <span><img src="@/assets/images/plus-green.png" alt=""></span> C
          <div class="crop-helper-tooltip" v-if="cropHelperTooltip">Plan Crop</div> -->
        </div>
        <div class="info-icon lg header-icon tooltip" 
          v-if="isAuth('add-bed') && (rows.length > 0)"
          @click="addNewBed"
          @mouseover="switchAddHelperTooltip(true)"
          @mouseout="switchAddHelperTooltip(false)"
          data-tooltip="Add Bed"
        >
          <img src="@/assets/images/updated/add-bed.png" alt="">
          <!-- <span><img src="@/assets/images/plus-green.png" alt=""></span> B
          <div class="add-helper-tooltip" v-if="addHelperTooltip">Add Bed</div> -->
        </div>
        <div class="info-icon lg header-icon tooltip" 
          v-if="isAuth('add-farm')"
          @click="() => {
            openForm();
            bedsCreation = false;
          }"
          @mouseover="switchHelperTooltip(true)"
          @mouseout="switchHelperTooltip(false)"
          data-tooltip="Add Farm"
        >
          <img src="@/assets/images/updated/new_set2/New_farm8V3.png" alt="">
          <!-- <img src="@/assets/images/updated/new_farm/New_farm_Circle4.png" alt=""> -->
          <!-- <img src="@/assets/images/updated/Farm1.png" alt=""> -->
          <!-- <img src="@/assets/images/updated/add-farm.png" alt=""> -->
          <!-- <img src="@/assets/images/plus-green-md.png" alt="">
          <div class="helper-tooltip" v-if="helperTooltip">Add Farm</div> -->
        </div>

    </div>
    <img src="@/assets/images/angle-down.png" alt="" class="angle">

    </div>

    <div class="border-box-content">

    <!-- <div class="content-row table-wrapper">
    <table class="farm-list-table"> -->
    <div class="content-row overflow-x-scroll table-container">
    <table>

    <thead>
    <tr>
    <th><p class="form-label-static form-sort-label">
      <span>Farm</span>
      <span @click="() => sortTable('farm')">
        <button :class="{active: sortOrder.farm === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.farm === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Acreage</span>
      <span @click="() => sortTableByNumber('acreage')">
        <button :class="{active: sortOrder.acreage === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.acreage === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>City</span>
      <span @click="() => sortTable('city')">
        <button :class="{active: sortOrder.city === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.city === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>State</span>
      <span @click="() => sortTable('state')">
        <button :class="{active: sortOrder.state === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.state === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Primary Crop</span>
      <span @click="() => sortTable('primaryCrop')">
        <button :class="{active: sortOrder.primaryCrop === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.primaryCrop === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th><p class="form-label-static form-sort-label">
      <span>Secondary Crop</span>
      <span @click="() => sortTable('secondaryCrop')">
        <button :class="{active: sortOrder.secondaryCrop === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.secondaryCrop === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
    <th class="w-75">&nbsp;</th>
    </tr>
    </thead>

    <tbody>

      <template
        v-for="(row, rowIndex) in rows"
        v-bind:key="'farm-list-row' + row.id"
      >
      <!-- :style="{backgroundColor: row.farmIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
        <tr
          v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
          :class="{'light-gray-bg': row.id === farm.id}"
          @click="(ev) => selectByRow(ev, {...row})"
        >
          <td class="text-center">{{row.farmName}}</td>
          <td>{{row.acreage}}</td>
          <td>{{row.city}}</td>
          <td>{{ row.state }}</td>
          <td>{{ row.primaryCrop }}</td>
          <td>{{ row.secondaryCrop }}</td>
          <td class="w-75">
          <div class="table-links">
          <a v-if="isAuth('edit-farm')" href="javascript:void();" @click="editSelectedFarm(row.id)"><img src="@/assets/images/edit-icon-2.svg" alt=""></a>
          <a v-if="isAuth('remove-farm')" href="javascript:void();" @click="removeFarm(row.id, row._id, rowIndex)"><img src="@/assets/images/trash-2.svg" alt=""></a>
          </div>
          </td>
        </tr>
      </template>

    </tbody>

    </table>
    </div>

    <div class="content-row">
    <div class="pagination">
    <div class="records-per-page">
    <!-- <p>Records per page:</p> -->
    <!-- <select v-model="maxPage">
    <option>1</option>
    <option>2</option>
    <option>3</option>
    <option>4</option>
    <option>5</option>
    <option>6</option>
    <option>7</option>
    <option>8</option>
    <option>9</option>
    <option>10</option>
    </select> -->
    <!-- <DropDownList 
      :mainObject="'maxPage'"
      :mainProperty="'value'"
      :injectionName="'farmListDetails'"
      :optionKey="'maxpage-farm-list'"
      :optionsList="[1,2,3,4,5,6,7,8,9,10]"
      :optionProperty="null"
      :optionPropertyTwo="null"
      :defaultSelected="maxPage.value"
    /> -->
    </div>
    <!-- <p>1-1 to 10</p> -->
    <p class="list-pagination">
      Page: 
      <!-- <select v-model="currentPage" class="pagination-selector">
      <option 
        v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
        v-bind:key="'task-list-pagination-' + page"
      >{{ Number.parseInt(page) + 1 }}</option>
      </select> -->
      <DropDownList 
        :mainObject="'currentPage'"
        :mainProperty="'value'"
        :injectionName="'farmListDetails'"
        :optionKey="'farm-list-pagination'"
        :optionsList="[]"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="currentPage.value"
        :numberList="true"
      />
    </p>
    </div>
    </div>

    </div>
  </div>
  </section>

  <div class="removal-popup" v-if="removedFarm">

  <div class="modal modal-md">
  <span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">Are you sure you want to delete the Farm?</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => approveRemoval(true)"
        class="approve-button btn green" 
      >Yes</button>
      <button
        @click="() => approveRemoval(false)" 
        class="cancel-removal-button btn green btn-o" 
      >No</button>
  </div>
  </div>
  </div>

  </div>

  <div class="removal-popup" v-if="popUps.farmHasCrop.active">

  <div class="modal modal-md">
  <span class="modal-close" @click="() => approveCropRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">{{ popUps.farmHasCrop.message }}</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => approveCropRemoval(true)"
        class="approve-button btn green" 
      >Yes</button>
      <button
        @click="() => approveCropRemoval(false)" 
        class="cancel-removal-button btn green btn-o" 
      >No</button>
  </div>
  </div>
  </div>

  </div>
</template>

<script>
import { ref, onMounted, computed, inject, watch, reactive, provide } from 'vue';
import { useStore } from 'vuex';
import _ from "lodash";
import DropDownList from '@/components/Shared/DropDownList.vue';
import { useRouter } from 'vue-router';

export default {
    name: "FarmList",
    setup() {
        const store = useStore();
        const router = useRouter();
        const rows = ref([]);
        const sectionHeader = 'Farm List';
        const { selectedFarmId, openForm } = inject('formMode');
        const { selectedFarm, selectFarm, farm } = inject('farmEdition');
        const { createBeds, editionMode, bedForm, bedsCreation, selectBed, defaultBed, bedFormIsOpened, addDesign } = inject('bedsCreation');
        const { selectCrop, defaultCrop, newCropCreation, crop } = inject('cropEdition');
        const { isAuth } = inject('mainUI');
        const farms = computed(() => store.state.farms);
        const crops = computed(() => store.state.crops);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const sortOrder = reactive({
            farm: 0,
            acreage: 0,
            city: 0,
            state: 0,
            primaryCrop: 0,
            secondaryCrop: 0
        });
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const bedsList = ref([]);
        const popUps = reactive({
            farmHasCrop: {
                active: false,
                message: 'The Plan crop associated with this farm will be deleted if you plan to add a bed'
            }
        });
        const removedFarm = ref(null);
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...farms.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByNumber = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...farms.value];
                sortOrder[sortProp] = 0;
            }
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectFarm({ ...row }, 'represent');
            }
        };
        const helperTooltip = ref(false);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const addHelperTooltip = ref(false);
        const switchAddHelperTooltip = (toggle) => {
            addHelperTooltip.value = toggle;
        };
        const cropHelperTooltip = ref(false);
        const switchCropHelperTooltip = (toggle) => {
            cropHelperTooltip.value = toggle;
        };
        const planNewCrop = () => {
            selectCrop({
                ...defaultCrop,
                sourceType: 'farm',
                sourceId: selectedFarm.id
            }, 'edit');
            newCropCreation.value = true;
        };
        const addNewBed = () => {
            const farmCrops = crops.value.filter(crp => ((crp.sourceType === 'farm') && ((crp.sourceId === farm.id) || (crp.sourceId === selectedFarmId) || (crp.sourceId === selectedFarm.id))));
            if (farmCrops.length > 0) {
                popUps.farmHasCrop.active = true;
            }
            else {
              const currentFarm = farms.value.find(f => f.id === farm.id);
              if (currentFarm) {
                if (currentFarm.beds.length > 0) {
                  selectBed({
                      ...defaultBed,
                      farmName: selectedFarm.farmName || farm.farmName,
                      sourceType: 'bed',
                      id: ''
                  }, 'create');
                  selectCrop({
                      ...defaultCrop,
                      sourceType: 'bed'
                  });
                  editionMode.value = 'create';
                  createBeds();
                  crop.sourceType = 'bed';
                  bedFormIsOpened.value = true;
                } else {
                  addDesign();
                }
              }
            }
        };
        const approveCropRemoval = (approved) => {
            if (approved) {
                editionMode.value = 'create';
                createBeds();
                const farmCrops = crops.value.filter(crp => ((crp.sourceType === 'farm') && ((crp.sourceId === farm.id) || (crp.sourceId === selectedFarmId) || (crp.sourceId === selectedFarm.id))));
                farmCrops.forEach(farmCrop => {
                    const cropId = farmCrop.id;
                    const cropIndex = crops.value.findIndex(crp => crp.id === cropId);
                    store.dispatch('removeCrop', { cropId, cropIndex });
                });
                selectCrop({
                    ...defaultCrop,
                    sourceType: 'bed'
                });
                addNewBed();
            }
            else {
                bedsCreation.value = false;
                setTimeout(() => {
                    document.getElementsByTagName('main')[0].scroll({
                        top: bedForm?.value?.offsetTop - 100,
                        left: 0,
                        behavior: 'smooth'
                    });
                });
            }
            popUps.farmHasCrop.active = false;
        };
        const expanded = ref(true);
        const handleCollapseHeaderActions = (evt) => {
            if (evt.target.className === 'add-farm' ||
                (evt.target.className === 'add-bed') ||
                (evt.target.className === 'add-crop')) {
                expanded.value = true;
            }
        };
        const toggleSection = (ev) => {
            if ((ev.target.className !== 'border-box-header') &&
                (ev.target.className !== 'angle') && (ev.target.className !== 'angle revert')) {
                return;
            }
            sectionExpanded.value = !sectionExpanded.value;
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(() => ([...store.state.farms]), () => { // store.state.farms
            // store.dispatch('getFarms');
            console.log(store.state.farms, 'filtered');
            rows.value = [...farms.value];
            rows.value.forEach(row => {
                row.farmIsSelected = (row.id === selectedFarmId.value) || (row.id === selectedFarm.id);
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
            setTimeout(() => {
                section.value.style.height = 'auto';
                sectionHeight.value = section.value.offsetHeight;
            });
        });
        watch(selectedFarmId, () => {
            rows.value.forEach(row => {
                row.farmIsSelected = (row.id === selectedFarmId.value) || (row.id === selectedFarm.id);
            });
            const currentFarm = rows.value.find(row => ((row.id === selectedFarmId.value) || (row.id === selectedFarm.id)));
            bedsList.value = currentFarm?.beds || [];
        });
        watch(selectedFarm, () => {
            rows.value.forEach(row => {
                row.farmIsSelected = (row.id === selectedFarmId.value) || (row.id === selectedFarm.id);
            });
            const currentFarm = rows.value.find(row => ((row.id === selectedFarmId.value) || (row.id === selectedFarm.id)));
            bedsList.value = currentFarm?.beds || [];
        });
        watch(() => _.cloneDeep(store.state.farms), (newVal) => {
            const currentFarm = newVal.find(f => f.id === selectedFarm.id);
            if (currentFarm) {
                bedsList.value = [...currentFarm.beds];
            }
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        const editSelectedFarm = (id) => {
            openForm(id);
            selectFarm(farms.value.find(farm => farm.id === id), 'edit');
        };
        const removeFarm = (farmId, farm_id, farmIndex) => {
            console.log(farmId, farmIndex);
            removedFarm.value = { farmId, farm_id, farmIndex };
        };
        const approveRemoval = (approved) => {
            if (approved) {
                store.dispatch('removeFarm', removedFarm.value);
            }
            removedFarm.value = null;
        };
        onMounted(() => {
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            console.log(farms);
            store.dispatch('getFarms');
            rows.value = [...farms.value];
            // farms.value.forEach(farm => rows.value.push(farm))
            // rows.value = farms.value;
            if (router.currentRoute.value.params.source !== 'admin-dashboard') {
              (farms.value.length > 0) && selectFarm({ ...farms.value[0] });
            }
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('farmListDetails', {
            maxPage,
            currentPage,
            options
        });
        return {
            sectionHeader,
            rows,
            expanded,
            handleCollapseHeaderActions,
            editSelectedFarm,
            selectFarm,
            removeFarm,
            openForm,
            helperTooltip,
            switchHelperTooltip,
            addHelperTooltip,
            switchAddHelperTooltip,
            cropHelperTooltip,
            switchCropHelperTooltip,
            planNewCrop,
            addNewBed,
            isAuth,
            maxPage,
            currentPage,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            sortTableByNumber,
            bedsList,
            farm,
            selectedFarm,
            approveCropRemoval,
            popUps,
            selectByRow,
            removedFarm,
            approveRemoval,
            bedsCreation
        };
    },
    components: { DropDownList }
};
</script>

<style lang="scss">
  .section {
    .info-icon {
      &.header-icon {
        position: relative;
      }
    }
    .crop-helper-tooltip {
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;

        background-color: #3C3838;
        color: #fff;
        top: 40px;
        left: -30px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 41px;
          background: #3C3838;
          z-index: -1;
        }
      }
  }
  .farm-list {
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
    .header {
      font-size: 18px;
      color: #28292B;
      margin-left: 8px;
    }
    .farm-list-card-section {
      padding: 16px 0 24px;
    }

    .header-actions {
      flex-direction: row;
      align-items: center !important;
    }
    .add-farm {
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 25px;
      height: 25px;
      background-image: url('@/assets/farm_profile/add_button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      position: relative;
      .helper-tooltip {
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;

        background-color: #3C3838;
        color: #fff;
        top: 40px;
        left: -30px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 41px;
          background: #3C3838;
          z-index: -1;
        }
      }
    }

    .add-bed {
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: 10px;
      margin-right: 10px;
      width: 25px;
      height: 25px;
      background-image: url('@/assets/farm_profile/add_bed.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      position: relative;
      .add-helper-tooltip {
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;

        background-color: #3C3838;
        color: #fff;
        top: 40px;
        left: -35px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 51px;
          background: #3C3838;
          z-index: -1;
        }
      }
    }

    .add-crop {
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 25px;
      height: 25px;
      background-image: url('@/assets/farm_profile/add_crop_button.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      position: relative;
      .crop-helper-tooltip {
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;

        background-color: #3C3838;
        color: #fff;
        top: 40px;
        left: -30px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 41px;
          background: #3C3838;
          z-index: -1;
        }
      }
    }

    .custom-table-header {
      font-weight: 600;
      font-size: 14px;
      color: rgba(40, 41, 43, 0.6);
    }
    .custom-table-cell {
      font-weight: 500;
      font-size: 14px;
      color: rgba(40, 41, 43, 1);
    }
    .item-actions {
      border: none;
      background: none;
      cursor: pointer;
      width: 30px;
      height: 30px;
      i {
        width: 100%;
        height: 100%;
      }
    }
    .active-tr {
      background-color: rgba(0,0,0,0.05);
    }
  }
</style>