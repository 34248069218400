<template>
    <div class="content-block">

<div class="content-row grid-4 grid-full">
    <div class="row">

        <div class="col-3">
            <div class="form-item">
                <p class="form-label" :class="{active: focused.category || (resource.category.length > 0)}">Category</p>
                <DropDownList
                  :mainObject="'resource'"
                  :mainProperty="'category'"
                  :injectionName="'resourceDetails'"
                  :optionKey="'category-option-resoruce'"
                  :optionsList="categories"
                  :optionProperty="null"
                  :optionPropertyTwo="null"
                  :defaultSelected="resource.category"
                  @click="() => {
                    activated.category = true;
                  }"
                />
                <p v-if="activated.category && resource.category.length === 0" class="form-info red text-end">* Required</p>
                <!-- <p v-if="activated.category && resource.category.length > 75" class="form-info red text-end">Please use maximum 75 characters</p> -->
            </div>
        </div>

        <div class="col-3">
            <div class="form-item">
                <p class="form-label" :class="{active: focused.subject || (resource.subject.length > 0)}">Subject</p>
                <input type="text" class="form-control"
                  v-model="resource.subject"
                  @focus="() => {
                    activated.subject = true;
                    focused.subject = true;
                  }"
                  @blur="() => {
                    focused.subject = false;
                  }"
                  placeholder="E.g., Subject"
                />
                <p v-if="activated.subject && resource.subject.length === 0" class="form-info red text-end">* Required</p>
                <!-- <p v-if="activated.category && resource.category.length > 75" class="form-info red text-end">Please use maximum 75 characters</p> -->
            </div>
        </div>

        <div class="col-3" v-if="resource.category === 'Video' || resource.category === 'Link'">
            <div class="form-item">
                <p class="form-label" :class="{active: focused.link || (resource.link.length > 0)}">Link</p>
                <input type="text" class="form-control"
                  v-model="resource.link"
                  @focus="() => {
                    activated.link = true;
                    focused.link = true;
                  }"
                  @blur="() => {
                    focused.link = false;
                  }"
                  placeholder="E.g., Link"
                />
                <p v-if="activated.link && (resource.link.length === 0) && ((resource.category === 'Link') || (resource.category === 'Video'))" class="form-info red text-end">* Required</p>
            </div>
        </div>

        <div class="col-3">
          <div style="display: flex;height: 100%;align-items: flex-end;">
            <span class="info-icon" style="margin-bottom: 10%; position: relative;" @mouseover="() => switchHelperTooltip(true)" @mouseout="() => switchHelperTooltip(false)">
              <div v-if="helperTooltip" class="tooltip-upload">Video upload is not currently supported, however you can add links to videos from other platforms like YouTube, Facebook etc.</div>
              <img src="@/assets/images/question-mark.png" alt="">
            </span>
          </div>
        </div>

    </div>
</div>

</div>


<div class="content-block">

<div class="content-section">
    <div class="buttons">
        <div class="row">
            <div class="col-auto">
                <div class="attchment-button">
                    <input
                      type="file"
                      id="attachmentButton"
                      name="resourcesupload"
                      ref="resourcesUpload"
                      @change="uploadResource" 
                      :disabled="resource.category === 'Link' || resource.category === 'Video'"
                    />
                    <label for="attachmentButton" class="btn" :class="{
                      'disabled-button': (resource.category === 'Link' || resource.category === 'Video'),
                      'green': (resource.category !== 'Link' && resource.category !== 'Video')
                      }">Choose File</label>
                </div>
            </div>
            <div class="col-auto"><button type="submit" @click="submitForm" class="btn light-blue-20 btn-o">Save</button></div>
            <div class="col-auto"><button type="submit" @click="cancelChanges" class="btn light-blue-20 btn-o">Cancel</button></div>
        </div>
    </div>
</div>

</div>

<template v-if="popUps.wrongCategory.active">
  <UserNotification
    v-bind:messageText="popUps.wrongCategory.message"
  />
</template>

</template>

<script>
import { defineComponent, reactive, inject, ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import DropDownList from '@/components/Shared/DropDownList.vue';
import UserNotification from "@/components/Shared/UserNotification.vue";
import moment from "moment";

export default defineComponent({
  name: 'ResourcesUserForm',
  setup() {
      const store = useStore();

      const categories = ['Document', 'Link', 'Video', 'Image'];

      const activated = reactive({
        title: false,
        subject: false,
        category: false,
        created: false,
        size: false,
        link: false
      });

      const focused = reactive({
        title: false,
        subject: false,
        category: false,
        created: false,
        size: false,
        link: false
      });

      const { resource, editionMode, selectedResource, defaultResource } = inject("resourceDetails");

      const helperTooltip = ref(false);

      const popUps = reactive({
        wrongCategory: {
          active: false,
          message: `Wrong file type, please choose an ${resource.category.toLowerCase()} file!`
        }
      });

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const determineType = (fileFormat) => {
        // if (fileFormat === 'doc') return 'word';
        // if (fileFormat === 'docx') return 'word';
        return fileFormat;
      }

      const uploadResource = (ev) => {
        console.log(ev, ev.target.files, ev.target.value);

        const fileExtension = ev.target.files[0].name.split('.');

        // improve calculations
        const fileSizeCalculated = () => {
          const sizeInBytes = ev.target.files[0].size
          if (sizeInBytes < 1024) return `1 KB`
          const kbs = Math.abs(
            Number.parseInt(sizeInBytes) / 1024
          )
          if (kbs < 1024) return `${kbs.toFixed(2)} KB`
          if (kbs > 1024) return `${Math.abs(kbs / 1024).toFixed(2)} MB`
          if (kbs > Math.abs(1024 * 1024)) return `${Math.abs(kbs / (1024 * 1024)).toFixed(2)} GB`
        }

        
        const file = ev.target.files[0];
        const reader = new FileReader();
        const fileId = uuid();
        reader.onload = function(base64) {
          console.log(base64);

          resource.id = fileId;
          resource.file = reader.result;
          resource.title = ev.target.files[0].name;
          resource.created = moment().format('MM/DD/YYYY');
          resource.fileType = determineType(fileExtension[fileExtension.length - 1]);
          resource.size = fileSizeCalculated();
        }
        reader.readAsDataURL(file);
      };

      const submitForm = () => {

        if (!resource.category || !resource.subject || 
          ((resource.category === 'Video' || resource.category === 'Link') && !resource.link)) {
          if (!resource.category) activated.category = true;
          if (!resource.subject) activated.subject = true;
          if ((resource.category === 'Video' || resource.category === 'Link') && !resource.link) activated.link = true;
          
          return;
        }

        console.log(resource.fileType, 'resource.fileType');

        if ((resource.category === 'Image') &&
          !resource.fileType.includes('png') &&
          !resource.fileType.includes('jpg') &&
          !resource.fileType.includes('jpeg') &&
          !resource.fileType.includes('bmp') &&
          !resource.fileType.includes('gif') &&
          !resource.fileType.includes('svg')
        ) {
          popUps.wrongCategory.active = true;
          return;
        }

        // if (resource.category === 'Document' &&
        //   (resource.fileType.includes('mp4') ||
        //   resource.fileType.includes('mov') ||
        //   resource.fileType.includes('wmv') ||
        //   resource.fileType.includes('mkv') ||
        //   resource.fileType.includes('png') ||
        //   resource.fileType.includes('jpg') ||
        //   resource.fileType.includes('jpeg') ||
        //   resource.fileType.includes('bmp') ||
        //   resource.fileType.includes('gif') ||
        //   resource.fileType.includes('svg'))
        // ) {
        //   popUps.wrongCategory.active = true;
        //   return;
        // }

        if ((resource.category === 'Document') &&
          !resource.fileType.includes('doc') &&
          !resource.fileType.includes('docx') &&
          !resource.fileType.includes('xsl') &&
          !resource.fileType.includes('xslx') &&
          !resource.fileType.includes('pdf') &&
          !resource.fileType.includes('ppt')
        ) {
          popUps.wrongCategory.active = true;
          return;
        }
        

        if (!resource.id) {
          resource.id = uuid();
        }

        if (!resource.created) {
          resource.created = moment().format('MM/DD/YYYY');
        }

        const formData = {
          ...resource
        };

        if (editionMode.value === "edit") {
          store.dispatch("editResource", { ...formData });
        } else {
          store.dispatch("createResource", {...formData});
        }
        closeForm();
        editionMode.value = '';
      }

      const closeForm = () => {
        resource.id = '';
        for (let prop in resource) {
          resource[prop] = defaultResource[prop];
        }

        for (let prop in activated) {
          activated[prop] = false;
        }
        
        for (let prop in focused) {
          focused[prop] = false;
        }
      }

      const cancelChanges = () => {
        if (editionMode.value === "edit") {
          for (let prop in resource) {
            resource[prop] = selectedResource[prop];
          }
          editionMode.value = "represent";
        } else {
          for (let prop in resource) {
            resource[prop] = defaultResource[prop];
          }
          editionMode.value = '';
        }
      };

      onMounted(() => {

      })

      return {
        activated,
        focused,
        resource,
        categories,
        helperTooltip,
        switchHelperTooltip,
        uploadResource,
        submitForm,
        cancelChanges,
        popUps,
      }
  },
  components: { DropDownList, UserNotification },
})

</script>

<style lang="scss">
.attchment-button input[type="file"] {
  display: none;
}
.tooltip-upload {
  width: 300px;
  padding: 5px 15px;
  height: max-content;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 50px;
  left: -55px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 66px;
    background: #3C3838;
    z-index: -1;
  }
}
.disabled-button {
  background-color:hsl(var(--light-gray));
  border: none;
}
</style>