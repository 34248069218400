<template>
  <EmployeeCompensationHeader />
  <section class="section mt-1">
    <div class="border-box">
      <div ref="compensationForm"></div>
      <EmployeeCompensationForm v-if="((editionMode === 'edit') || (editionMode === 'create'))" />
      <EmployeeCompensationFilled  v-if="(editionMode === 'represent') && !isDialogView" />
      <EmployeeCompensationList v-if="!isDialogView" />
    </div>
  </section>

  <div :class="{insideDialog: isDialogView}" class="add-new-popup" v-if="popups.addAdditionalSalary.active">
      <div class="modal modal-md">
        <span class="modal-close" @click="() => {
          popups.addAdditionalSalary.active = false;  
        }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
        <div class="modal-block text-center">
        <p class="fw-bold">{{ popups.addAdditionalSalary.message }}</p>
        </div>
        <div class="modal-block text-center">
        <div class="buttons justify-content-center">
            <button
              @click="() => {
                addCompensation()
                compensation.employeeType = 'salary'
                popups.addAdditionalSalary.active = false;
              }"
              class="approve-button btn green" 
            >Yes</button>
            <button
              @click="() => declineAddAdditionalSalary()" 
              class="cancel-removal-button btn green btn-o" 
            >No</button>
        </div>
        </div>
      </div>
    </div>

    <div :class="{insideDialog: isDialogView}" class="add-new-popup" v-if="popups.addPartTime.active">
      <div class="modal modal-md">
        <span class="modal-close" @click="() => {
          popups.addPartTime.active = false;  
        }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
        <div class="modal-block text-center">
        <p class="fw-bold">{{ popups.addPartTime.message }}</p>
        </div>
        <div class="modal-block text-center">
        <div class="buttons justify-content-center">
            <button
              @click="() => {
                addCompensation()
                compensation.employeeType = 'parttime'
                popups.addPartTime.active = false;
              }"
              class="approve-button btn green" 
            >Yes</button>
            <button
              @click="() => redirectToPrimary('addPartTime')" 
              class="cancel-removal-button btn green btn-o" 
            >No</button>
        </div>
        </div>
      </div>
    </div>

    <div class="add-new-popup" :class="{insideDialog: isDialogView}" v-if="popups.addAdditionalPartTime.active">
      <div class="modal modal-md">
        <span class="modal-close" @click="() => {
          popups.addAdditionalPartTime.active = false;  
        }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
        <div class="modal-block text-center">
        <p class="fw-bold">{{ popups.addAdditionalPartTime.message }}</p>
        </div>
        <div class="modal-block text-center">
        <div class="buttons justify-content-center">
            <button
              @click="() => {
                addCompensation()
                compensation.employeeType = 'parttime'
                popups.addAdditionalPartTime.active = false;
              }"
              class="approve-button btn green" 
            >Yes</button>
            <button
              @click="() => declineAddAdditionalParttime()" 
              class="cancel-removal-button btn green btn-o" 
            >No</button>
        </div>
        </div>
      </div>
    </div>

    <div class="add-new-popup" :class="{insideDialog: isDialogView}" v-if="popups.addSalary.active">
      <div class="modal modal-md">
        <span class="modal-close" @click="() => {
          popups.addSalary.active = false;  
        }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
        <div class="modal-block text-center">
        <p class="fw-bold">{{ popups.addSalary.message }}</p>
        </div>
        <div class="modal-block text-center">
        <div class="buttons justify-content-center">
            <button
              @click="() => {
                addCompensation()
                compensation.employeeType = 'salary'
                popups.addSalary.active = false;
              }"
              class="approve-button btn green" 
            >Yes</button>
            <button
              @click="() => redirectToPrimary('addSalary')" 
              class="cancel-removal-button btn green btn-o" 
            >No</button>
        </div>
        </div>
      </div>
    </div>

    <div class="add-new-popup" :class="{insideDialog: isDialogView}" v-if="popups.addAdditionalTitle.active">
      <div class="modal modal-md">
        <span class="modal-close" @click="() => {
          popups.addAdditionalTitle.active = false;  
        }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
        <div class="modal-block text-center">
        <p class="fw-bold">{{ popups.addAdditionalTitle.message }}</p>
        </div>
        <div class="modal-block text-center">
        <div class="buttons justify-content-center">
            <button
              @click="() => {
                addCompensation();
                popups.addAdditionalTitle.active = false;
              }"
              class="approve-button btn green" 
            >Yes</button>
            <button
              @click="declineAddAdditionalTitle" 
              class="cancel-removal-button btn green btn-o" 
            >No</button>
        </div>
        </div>
      </div>
    </div>

    <div :class="{notificationInsideDialog: isDialogView}" v-if="popups.compensationToBeRemoved.active">
      <UserNotification
        v-bind:messageText="popups.compensationToBeRemoved.message"
      />
    </div>
</template>

<script>
import { defineComponent, provide, reactive, ref, onMounted, inject, watch } from 'vue';
import EmployeeCompensationHeader from './EmployeeCompensationHeader.vue';
import EmployeeCompensationForm from './EmployeeCompensationForm.vue';
import EmployeeCompensationList from './EmployeeCompensationList.vue';
import { useStore } from 'vuex';
import EmployeeCompensationFilled from './EmployeeCompensationFilled.vue';
import { useRouter } from 'vue-router';
import UserNotification from '@/components/Shared/UserNotification.vue';

export default defineComponent({
    name: "EmployeeCompensation",
    props: ['dialogView'],
    setup(props) {
      const isDialogView = ref(props.dialogView);
      const store = useStore();
      const router = useRouter();

      const editionMode = ref(isDialogView.value ? 'create' : '');

      const compensationForm = ref(null);

      const compensation = reactive({
        id: '',
        employeeTitle: '',
        grade: '',
        annualSalary: '',
        medicalBenefits: '',
        bonus: '',
        other: '',

        hourlyRate: '',
        overTimeRate: '',

        ownershipType: '',
        ownershipShare: '',

        qualificationExperienceRequirements: '',

        employeeType: '',

        comments: '',
        assigned: ''
      });

      const selectedCompensation = reactive({
        ...compensation
      });

      const defaultCompensation = {
        ...compensation
      }

      const popups = reactive({
        addAdditionalSalary: {
          active: false,
          message: 'Do you want to add additional Salary grades?'
        },
        addPartTime: {
          active: false,
          message: 'Do you want to add grade for part-time workers?'
        },
        addSalary: {
          active: false,
          message: 'Do you want to add grade for salary workers?'
        },
        addAdditionalPartTime: {
          active: false,
          message: 'Do you want to add additional part-time grades?'
        },
        compensationToBeRemoved: {
          active: false,
          message: 'This grade cannot be deleted as it has been assigned to a user'
        },
        addAdditionalTitle: {
          active: false,
          message: 'Do you want to add another employee title?'
        }
      })

      let userProfileDetails;

      if (isDialogView.value) {
        userProfileDetails = inject('userProfileDetails');
        // mainDetails
        // gradeFormDialog
      }

      const addCompensation = () => {
        selectCompensation(defaultCompensation, 'create');

        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: compensationForm?.value?.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        })
        // editionMode.value = 'create';
      }

      const editCompensation = (field, val) => {
        compensation[field] = val;
      }

      const selectCompensation = (compensationData, editMode) => {
        if (compensationData) {
          for (let field in compensationData) {
            compensation[field] = compensationData[field]
            selectedCompensation[field] = compensationData[field]
          }
        }
        editionMode.value = editMode;
      }

      const removeCompensation = (compensationId) => {
        store.dispatch('removeCompensation', compensationId);
      };

      const redirectToPrimary = (section) => {
        const parentSource = `${router.currentRoute.value.params.parentSource}`;
        
        if (router.currentRoute.value.params.source) {
          router.push({
            name: router.currentRoute.value.params.admin ? 'admin' : 'page', 
            params: { 
              pagename: router.currentRoute.value.params.source,
              source: 'employee-compensation',
              parentSource
            }
          })
          return;
        }

        popups[section].active = false;
      }

      const declineAddAdditionalParttime = () => {
        if (router.currentRoute.value.params.employeeType === 'parttime') {
          popups.addSalary.active = true;
          popups.addAdditionalPartTime.active = false;
        } else {
          redirectToPrimary('addAdditionalPartTime')
        }
      }

      const declineAddAdditionalSalary = () => {
        if (router.currentRoute.value.params.employeeType === 'salary') {
          popups.addPartTime.active = true;
          popups.addAdditionalSalary.active = false;
        } else {
          redirectToPrimary('addAdditionalSalary')
        }
      }

      const declineAddAdditionalTitle = () => {
        userProfileDetails.gradeFormDialog.value = false;
      }

      provide('compensationDetails', {
        compensation,
        defaultCompensation,
        selectedCompensation,
        editionMode,
        popups,
        addCompensation,
        editCompensation,
        selectCompensation,
        removeCompensation,
        isDialogView
      })

      watch(() => (compensation.id), (newVal) => {
        if (newVal) {
          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: compensationForm.value.offsetTop + 10,
              left: 0,
              behavior: 'smooth'
            })
          }, 100)
        }
      })

      watch(editionMode, (newVal) => {
        if (newVal === 'edit') {
          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: compensationForm.value.offsetTop + 10,
              left: 0,
              behavior: 'smooth'
            })
          }, 100)
        }
      })

      onMounted(() => {
        if (
          router.currentRoute.value.params.source === 'user-profile' ||
          router.currentRoute.value.params.source === 'secondary-user' ||
          router.currentRoute.value.params.source === 'admin-account' ||
          router.currentRoute.value.params.source === 'admin-users'
        ) {
          editionMode.value = 'create'
        }
        if (router.currentRoute.value.params.employeeType) {
          compensation.employeeType = router.currentRoute.value.params.employeeType;
        }
        if (router.currentRoute.value.params.assigned) {
          compensation.assigned = router.currentRoute.value.params.assigned;
        }
      })

      return {
        editionMode,
        compensation,
        popups,
        addCompensation,
        redirectToPrimary,
        compensationForm,
        declineAddAdditionalParttime,
        declineAddAdditionalSalary,
        declineAddAdditionalTitle,
        isDialogView
      }
    },
    components: { EmployeeCompensationHeader, EmployeeCompensationForm, EmployeeCompensationList, EmployeeCompensationFilled, UserNotification }
})
</script>

<style lang="scss" scoped>
.add-new-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  &.insideDialog {
    width: 100%;
    height: 100%;
    .modal.modal-md {
      width: clamp(36rem, 60%, 60%);
    }
  }
}
.notificationInsideDialog {
  .submit-popup-wrapper {
    width: 100%;
    height: 100%;
  }
}
</style>