const options = [
  'Arid',
  'Continental',
  'Desert',
  'Dry',
  'Mediterranean',
  'Mountain',
  'Subartic',
  'Temperate',
  'Tropical',
  'Tropical Rainforest',
  'Tundra',
  'Other'
];

export default options;
