<template>
  <div class="section" ref="section">
    <div class="border-box-header" ref="boxHeader" @click="toggleSection">
    <h5>Payment Information</h5>
    <img src="@/assets/images/angle-down.png" alt="" class="angle">
    </div>

    <div class="border-box-content">

      <div class="content-block">
        <template v-if="secretIsLoaded">
          <StripeElements
            v-if="stripeLoaded"
            v-slot="{ elements }"
            ref="elms"
            :stripe-key="stripeKey"
            :instance-options="instanceOptions"
            :elements-options="elementsOptions"
          >
            <StripeElement
              type="payment"
              ref="payment"
              :elements="elements"
              :options="cardOptions"
              @change="checkPaymentType"
              @ready="loadedStripeForm"
            />
          </StripeElements>
        </template>  
      </div>

    <!-- <div class="content-block">
    <div class="checkbox checkbox-lg"><input type="checkbox" id="savePaymentInfo" v-model="payment.saveForFuture"><label for="savePaymentInfo">Save Payment Information for future use</label></div>
    </div> -->

    <div class="content-block">
    <div class="buttons form-buttons">
    <div class="row">
    <div class="col-auto"><button type="submit" class="btn green" @click="submitStripeForm">Save</button></div>
    <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
    </div>
    </div>
    </div>

    </div>

</div>
</template>

<script>
import { defineComponent, ref, watch, inject, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { loadStripe } from '@stripe/stripe-js'
import { StripeElements, StripeElement } from 'vue-stripe-js'

export default defineComponent({
    name: "AdvertisementAccountPayment",
    setup() {
        const store = useStore();

        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);

        const { collectPayment } = inject('stripeProps');
        
        const toggleSection = () => {
          sectionExpanded.value = !sectionExpanded.value;
        };

        const stripeKey = ref(process.env.VUE_APP_STRIPE_KEY) // test key
        const instanceOptions = ref({
          // https://stripe.com/docs/js/initializing#init_stripe_js-options
        })
        const elementsOptions = ref({
          // https://stripe.com/docs/js/elements_object/create#stripe_elements-options

          // Receive client_secret from subscription response
          clientSecret: store.state.subscriptionPlan.clientSecret

        })
        const cardOptions = ref({
          // https://stripe.com/docs/stripe.js#element-options
          // value: {
          //   postalCode: '12345',
          // }
        })
        const stripeLoaded = ref(false)
        const payment = ref()
        const elms = ref()
        const paymentType = ref('')
        const formIsLoaded = ref(false)
        const secretIsLoaded = ref(false)

        const submitStripeForm = () => {
          const paymentElement = payment.value.stripeElement

          collectPayment(paymentElement, elms.value);
        };

        const checkPaymentType = (ev) => {

          console.log(ev.value.type, 'payment type');

          paymentType.value = ev.value.type;

          // {
          //   elementType: 'payment',
          //   complete: false,
          //   empty: false,
          //   collapsed: false,
          //   value: { type: "card" },
          // }
        }

        const loadedStripeForm = () => {
          console.log('form is loaded');
          formIsLoaded.value = true;
        }
        
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });
        
        watch(paymentType, (newVal, oldVal) => {
          if (oldVal && newVal) {
            console.log(oldVal, newVal);
            section.value.style.height = "auto";
            setTimeout(() => {
              console.log(section.value.style.height, section.value.offsetHeight)
                sectionHeight.value = section.value.offsetHeight;
            }, 500);
          }
        });

        watch(formIsLoaded, () => {
          section.value.style.height = "auto";
          setTimeout(() => {
            console.log(section.value.style.height, section.value.offsetHeight)
              sectionHeight.value = section.value.offsetHeight;
          });
        });

        watch(() => ({...store.state.subscriptionPlan}), () => {
          if (store.state.subscriptionPlan.clientSecret) {
            elementsOptions.value.clientSecret = store.state.subscriptionPlan.clientSecret
            secretIsLoaded.value = true;
          }
        })

        onBeforeMount(() => {
          const stripePromise = loadStripe(stripeKey.value)
          stripePromise.then(() => {
            stripeLoaded.value = true
          })
        });
        
        onMounted(() => {
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });

            if (store.state.subscriptionPlan.clientSecret) {
              secretIsLoaded.value = true;
            }

            store.dispatch("addCurrentPath", {
                section: 1,
                val: 'Payment Information'
            });
            // if (payment.cardType) {
            //   card.value = true;
            // }
            // if (payment.accountType) {
            //   account.value = true;
            // }
        });
        return {
            section,
            boxHeader,
            toggleSection,
            
            submitStripeForm,
            stripeKey,
            stripeLoaded,
            instanceOptions,
            elementsOptions,
            cardOptions,
            payment,
            elms,

            checkPaymentType,
            loadedStripeForm,
            secretIsLoaded
        };
    },
    components: { StripeElements, StripeElement }
});
</script>