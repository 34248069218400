<template>
  <ResourcesHeader />
  <section class="section">

<div class="tab-content farms-content" id="one">

<section class="section">
<div class="border-box">
<div class="border-box-content">

<div class="blog">

<div class="blog-block">
<h5 class="fw-semibold">Application Summary</h5>
<p>Bhoomi is a comprehensive online farm management solution, available as a subscription service compatible with various devices such as computers, tablets, and mobile phones. Specifically designed for small and medium-sized farm owners, this application contains different modules to streamline farm-related tasks.</p>
</div>

<div class="blog-block">
<h5 class="fw-semibold">Application Overview</h5>
<h6 class="fw-semibold">Account Profile:</h6>
<p>Users are required to create either a primary or company account, with the primary account holder serving as the default admin. Account setup involves providing essential information, selecting a subscription type, entering payment details, and completing the billing section.</p>
</div>

<div class="blog-block">
<h6 class="fw-semibold">User Types:</h6>
<p>Farm users are categorized into Salary, Part-Time, and Other, representing distinct payment structures - salary, hourly wages, or profit-sharing from produce sales.</p>
<h6 class="fw-semibold">User Role Types:</h6>
<p>Users can be assigned one of the four roles - Admin, Edit, Contribute, or Read - each granting varying degrees of access to account, subscription, and module management.</p>
<h6 class="fw-semibold">Subscription:</h6>
<p>Bhoomi offers five subscription plans, with options for Monthly or Annual billing. A 30-day free trial allows users to explore all modules before opting for a paid subscription, which includes Basic, Basic+, Basic+ Advance, and Basic+ Ultimate plans, each featuring an escalating set of modules.</p>
<h6 class="fw-semibold">Payment:</h6>
<p>Currently, Bhoomi accepts credit or debit card payments only, with potential for additional payment methods in the future. Users can store multiple cards for convenience.</p>
<h6 class="fw-semibold">Farm Profile:</h6>
<p>Capturing essential farm details, this module includes information on location, size, soil type, irrigation method, primary and secondary crops, and other relevant data.</p>
<h6 class="fw-semibold">Bed Profile:</h6>
<p>Enabling the creation of farm beds, this feature aids small farmers in dividing their land for various crops. Users can manually design beds using the design tool and plan crops for each bed.</p>
<h6 class="fw-semibold">Crop Planning:</h6>
<p>Farmers can plan crops at the farm or bed level for a specific year/season/crop cycle, facilitating effective crop management.</p>
</div>

<div class="blog-block">
<h6 class="fw-semibold">User/Labor Management:</h6>
<p>This module allows farmers to manage labor, distinguishing between salary, part-time, and profit-sharing workers, each subject to a 30-day free trial before requiring a subscription.</p>
<h6 class="fw-semibold">Tasks Management:</h6>
<p>Users can create and manage daily tasks, specifying details such as task type, duration, and labor costs. Recurring tasks and various task categories are supported.</p>
<h6 class="fw-semibold">Vendor Management:</h6>
<p>Farmers can manage vendors, including products, equipment, and services. Vendors can be categorized as active or inactive.</p>
<h6 class="fw-semibold">Equipment Management:</h6>
<p>This module tracks farm equipment, including warranty details and servicing schedules.</p>
<h6 class="fw-semibold">Inventory Management:</h6>
<p>Facilitating planning and tracking of consumables like fertilizers and pesticides, this module monitors inventory levels, purchases, and consumption.</p>
<h6 class="fw-semibold">Customer and Sales Management:</h6>
<p>Enabling farmers to monitor customers and sales, this module aids in maintaining a comprehensive record of transactions.</p>
<h6 class="fw-semibold">Reports:</h6>
<p>Providing an overview of farm performance, this module generates various reports, covering total sales, costs, and profits.</p>
<h6 class="fw-semibold">Admin:</h6>
<p>Reserved for primary/admin only, the admin module grants access to essential functionalities such as user management, subscription purchases, payment details, expense management, and advertising.</p>
<p>Bhoomi emerges as a versatile and user-friendly farm management solution, catering to the diverse needs of small and medium-sized farmers with its extensive array of features.</p>
</div>

<div class="blog-block">
<h5 class="fw-semibold">Knowledge Base</h5>
<p>This application has the following modules. Additional modules and functionality will be added to this application in future releases.</p> 
  <ul class="list bullet-list">
  <li><p>User Management</p></li>
  <li><p>Farm Management</p></li>
  <li><p>Crop Management</p></li>
  <li><p>Task Management</p></li>
  <li><p>Vendor, Product and Equipment Management</p></li>
  <li><p>Customer and Sales Management</p></li>
  </ul>
<h5 class="fw-semibold">Application Data Entry Process</h5>
<p>There is a process on how to enter data into the application in the correct order. Follow these steps to make optimal usage of the application.</p>
    <ol>
    <li><p>1. Create a Primary or company account.
        a. Choose subscription type.
        b. Add payment information and billing information.</p></li>
    <li><p>2. Assign subscription to the user.</p></li>
    <li><p>3. Create a farm profile.
        a. Plan Crop or Create Bed profile & Plan Crop for each bed.</p></li>
    <li><p>4. Add Additional Users, assign roles and subscription.</p></li>
    <li><p>5. Add Vendor information.
        a. Add Equipment, Product, or servicing information.</p></li>
    <li><p>6. Create Task, assign and update task status, as necessary.</p></li>
    <li><p>7. Add Customers and Sales data.</p></li>
    </ol>

<h5 class="fw-semibold">Global Application Features</h5>
<p><span class="fw-semibold">Tool Tips:</span> Tool tips are a feature that appears throughout the application. When the user hovers over a feature these tooltips appear and indicate what the feature does</p>
<p><span class="fw-semibold">Pop-Up Messages:</span> Pop-up messages appear through the application. These direct users what the next steps are in the process.</p>

<h5 class="fw-semibold">Account & User Management</h5>
<p><span class="fw-semibold">Primary User:</span> The account under whom the tenant is registered.</p>
<p><span class="fw-semibold">Title:</span> Each user requires a title. There are three different types of user categories. Salary, Part Time and Other. Salary users are who are employed on a salary and are paid bi-weekly or monthly. The titles for these users can be Manager, Supervisor etc. The next category is Part Time. These are temporary employees and are paid on an hourly basis. The part time employees, depending on the task and duration of a job can be paid based on Regular or Over Time rates,</p>
</div>

<div class="blog-block">
<p><span class="fw-semibold">Grade:</span> This is a value that the admin adds in the Employee compensation module. The combination of Grade and salary or hourly rates help in determining the compensation for an employee/user.</p>
<p><span class="fw-semibold">Role Type:</span> this application has the following role types for a user, Admin, Edit, Contribute and Read. The admin has all rights to the application, including access to the admin section.</p>
<p><span class="fw-semibold">Edit:</span> Add/Delete/Update plan crop, design bed, add bed details, Tasks, Vendors, Inventory, Equipment, Customers and Sales, upload documents. </p>
<p><span class="fw-semibold">Contribute:</span> Add/Delete/Update, plan crop, tasks, inventory, Equipment and sales, upload documents but not delete documents</p>
<p><span class="fw-semibold">Read:</span> View and read only items. Cannot download documents.</p>
<p><span class="fw-semibold">Secondary User:</span> these are additional users that a Primary User (Admin) may add as a user to the application. These users can be salaried, part time or temporary workers. </p>

<h5 class="fw-semibold">Farm Management</h5>
<p><span class="fw-semibold">Account/Farm Name:</span> This is the name under which an account is registered. A farmer may have a company registered for his farming business, or just a farm name assigned. The Company and farm name can be one or the same or may be different depending on how the farmer manages the farm.</p>
<p><span class="fw-semibold">Company/Farm Address:</span> It the farmer has a company that is registered at different location than the farm location, then use the company address. In some cases, the company or farm address may be the same. If the user does not have a company, then use the primary farm address. </p>
<p><span class="fw-semibold">Longitude:</span> This data if for your farm location and comes from Google Maps and is not required</p>
<p><span class="fw-semibold">Latitude:</span> This data is for your farm location and comes from Google Maps and is not required.</p>
<p><span class="fw-semibold">IOT Device:</span> Not required currently, however soon if you are using IOT devices on your farm. You will be able to add it to your application. This feature will allow real-time data on your farm conditions and will suggest actions to take on your farm.</p>
<p><span class="fw-semibold">Climatic Zone:</span> This is the geographical location of your farm. Depending on where your farm is located you may choose an option from the drop-down list.</p>

<figure><img src="@/assets/images/blog-map.png" alt=""></figure>

<p><span class="fw-semibold">Irrigation Type:</span> Types of irrigation system used in a farm. Click on the link to see details, <a href="https://www.cdc.gov/healthywater/other/agricultural/types.html">https://www.cdc.gov/healthywater/other/agricultural/types.html</a> </p>
<p><span class="fw-semibold">Soil Type:</span> The type of soil your farm contains Click on the link to see more details. <a href="https://www.rhs.org.uk/soil-composts-mulches/soil-types">https://www.rhs.org.uk/soil-composts-mulches/soil-types</a> </p>
<p><span class="fw-semibold">Fence Type:</span> Type of fence used on the farm. Choose an option from the drop-down list if your farm is fenced. Click on the link to see more details.</p>
<p><span class="fw-semibold">Primary Crop:</span> This is the main crop grown on your farm.</p>
<p><span class="fw-semibold">Secondary Crop:</span> The second most common crop you grow on your farm.</p>
<p><span class="fw-semibold">Bed:</span> Bed are sections within a Farm lot. Some farmers grow multiple crops on a farm lot and divide their farm lot into multiple beds. Whenever a user creates a bed the bed form displays the acreage entered for the farm. This can be changed to enter the acreage for each bed. Some other information is also carried over to the bed form, this includes Farm, Soil Type, Irrigation Type, IOT Device Type. Except Farm and IOT Device Type, other fields can be changed.</p>
<p><span class="fw-semibold">Bed Design Tool:</span> The Design tool gives users the ability to create bed designs for a farm. Many small urban farmers grow multiple crops on a farm lot. This tool allows them to design beds, and plan crops for each bed. </p>
<p>The design tool has various tools, including free hand, straight line drawing, eraser, color picker and add text. Users can use these to design beds, color code and assign text (numbers, acreage, and crop details).</p>
<p><span class="fw-semibold">Crop Cycle:</span> The cycle of sowing to harvesting a crop is regarded as a Crop Cycle. A farm/bed may have multiple crop cycles within a year.</p>

<p><span class="fw-semibold">Season:</span> A year has four seasons. Spring, Summer, Fall and Winter. Farmers in different parts of the work may have different crop cycles depending on their regional climate. </p>
<p><span class="fw-semibold">New Plan:</span> The New Plan button allows users to create a new bed design for their farm lot. Once the new plan is created the existing, Bed design is archived, and all the data associated with the bed/plan crop is moved to Farm History tab.</p>
<p><span class="fw-semibold">Plan Crop:</span> this allows users to plan crops for a farm or a bed. Users can also project the amount to be harvested and estimated wastage. This helps in comparing against actuals.</p>
<p><span class="fw-semibold">Farm History:</span> This tab contains data from past bed and plan crop. If a new user creates a New Plan, the existing Bed/Plan crop data is moved to Farm History.</p>

</div>

<div class="blog-block">
  <h5 class="fw-semibold">Task Management</h5>
<p><span class="fw-semibold">Default Task:</span> This is the default task form that opens when a user clicks on a calendar to create a task.</p>
<p><span class="fw-semibold">Input:</span> Product/Consumables like fertilizers, seeds etc. are also referred to as Input in this application. If Input is checked additional fields are added to the form. These fields relate to input to be used for the task.</p>
<p><span class="fw-semibold">Harvest:</span> Checking harvest will add fields related to harvest in the task form.</p>
<p><span class="fw-semibold">Notifications:</span> Users can set notification for Seed date and harvest date. Based on the days set, the user will get notification on these items.</p>
<p><span class="fw-semibold">Other:</span> Add fields related to the farm that may not require input or harvest.</p>
<p><span class="fw-semibold">Recurring Task:</span> If a task I repetitive, user can choose recurring tasks feature. This will allow them to add recurring tasks for a length of time.</p>
<p><span class="fw-semibold">Task Type:</span> A task type can be Regular or Part Time. This applies only to the Part Time workers. Regular tasks are calculated using regular hourly rates and Overtime tasks are calculated based on overtime hourly rates.</p>
<p><span class="fw-semibold">Employee Type:</span> Employee types can be Salary, Part Time or Other. A user can be on Salaried, or Part Time, paid on an hourly basis or Other. The Other applies to ownership, partnership, or any other form of profit sharing. Only applies to Part Time workers. The rates applied for this task are applied at Regular rates.</p>

<h5 class="fw-semibold">Labor Management</h5>
<p>This module shows a list of all the users (also referred as labor in this module)</p>
<p><span class="fw-semibold">Income:</span> This tab displays the total income made by a user (labor). This list only displays income of part time users/labor. Each user can only view their income; however, the admin can view all user’s income.</p>

<h5 class="fw-semibold">Vendor Management</h5>
<p>This module allows users to manage their vendors. Users can add vendors they work with. The vendor status can be active or inactive. Once the status of a vendor is changed to inactive the vendor data is moved to Archived tab. The vendor offerings are categorized as Products, Equipment and Services. Products are consumables like Fertilizer, Pesticides, Seeds, and packaging materials. Equipment are tractors, harvesters, and other farm equipment. Services can be for equipment like tractor, harvesters etc.</p>

<h5 class="fw-semibold">Inventory Management</h5>
<p><span class="fw-semibold">Plan Inventory:</span> User can plan inventory. Inventory relates to items that are consumables like fertilizer, seeds, pesticides etc. In stock inventory, required inventory gives the user the order quantity. Order quantity is automatically calculated based on in stock and required amount. </p>
<p><span class="fw-semibold">Inventory Purchased:</span> This list displays the items added via the Vendor module. User can add inventory as they purchase from the Vendor module, by choosing the vendor and item category.</p>
<p><span class="fw-semibold">Inventory Used:</span> This is an auto generated data that is added from the task module. As input (Consumables) are used as part of the task performed on the farm, they are added to the Inventory used list. </p>
<p><span class="fw-semibold">Inventory Status:</span> This list is auto calculated and generated based on the stock in hand, item purchased and consumed. It allows users to keep track of their inventory status. </p>

<h5 class="fw-semibold">Customers Management</h5>
<p>Customer module allows for keeping track of customers a farmer may have. The users can add customers and choose customer type. They can also add custom value by choosing other option. </p>
<p><span class="fw-semibold">Sales:</span> This allows users to track their sales. All sales are associated with a customer. To add sales a customer needs to be added first. Each sales needs to have a customer associated with it.</p>

<h5 class="fw-semibold">Admin Functions</h5>
<p>This module is only accessible by the admin. It allows admin to add new user, farm, purchase, and update subscription and add and delete payment. </p>
<p><span class="fw-semibold">Users:</span> Admin can manage users here, add new users, edit, delete, and update users. The users in this application are also referred to as labor.</p>
<p><span class="fw-semibold">Subscription:</span> This allows for adding new and deleting existing subscriptions. Only unassigned subscription can be deleted. An assigned subscription can only be deleted if it is unassigned to a user and is made available.</p>

<p><span class="fw-semibold">Payment:</span> This module allows for adding new and updating existing payment methods. Currently Bhoomi only accepts Credit and Debit card payments.</p>
<p><span class="fw-semibold">Labor Expenses:</span> This is an auto calculated list. The expense is based on the labor hours, task type and labor rate. The data for this list comes from the task module.</p>
<p><span class="fw-semibold">Admin Expenses: The admin can track all admin expense via this module. </span></p>
<p><span class="fw-semibold">Emp Compensation:</span></p>
<p><span class="fw-semibold">Advertisement:</span></p>
<p><span class="fw-semibold">Create Ad:</span></p>

</div>

<!-- content -->

<div class="blog-block">
<h5 class="fw-semibold">Headding</h5>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent a sagittis enim. Suspendisse cursus lectus sapien, at interdum purus tempor quis. Fusce dolor libero, interdum vel viverra id, mollis quis lacus. Vestibulum cursus ligula congue, auctor nisl in, convallis erat. Praesent dictum tortor ligula, sit amet auctor nunc pellentesque in. <a href="#">Suspendiss</a> e sit amet ante ac ipsum <span>ullamcorper laoreet</span>. Aenean nec ante cursus, ultricies tellus et, porttitor elit. Nullam tristique nibh id gravida faucibus.</p>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent a sagittis enim. Suspendisse cursus lectus sapien, at interdum purus tempor quis. Fusce dolor libero, interdum vel viverra id, mollis quis lacus.</p>
</div>

<div class="blog-block">
<h6 class="fw-semibold">Sub Headding</h6>
<figure><img src="https://placehold.co/500x300?text=image" alt=""></figure>
<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent a sagittis enim. Suspendisse cursus lectus sapien, at interdum purus tempor quis. Fusce dolor libero, interdum vel viverra id, mollis quis lacus.</p>
</div>

<div class="blog-block">
<ul class="list bullet-list">
<li><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p></li>
<li><p>Praesent a sagittis enim</p></li>
<li><p>Suspendisse sit amet ante ac ipsum ullamcorper laoreet</p></li>
<li><p>Nam et sodales ipsum. Pellentesque eget magna eros</p></li>
</ul>
</div>

<div class="blog-block">
<ul class="list arrow-list">
<li><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p></li>
<li><p>Praesent a sagittis enim</p></li>
<li><p>Suspendisse sit amet ante ac ipsum ullamcorper laoreet</p></li>
<li><p>Nam et sodales ipsum. Pellentesque eget magna eros</p></li>
</ul>
</div>

<div class="blog-block">
<ul class="list line-list">
<li><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p></li>
<li><p>Praesent a sagittis enim</p></li>
<li><p>Suspendisse sit amet ante ac ipsum ullamcorper laoreet</p></li>
<li><p>Nam et sodales ipsum. Pellentesque eget magna eros</p></li>
</ul>
</div>

<div class="blog-block">
<ul class="border-list">
<li><h6>Headding</h6><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p></li>
<li><h6>Headding</h6><p>Praesent a sagittis enim</p></li>
<li><h6>Headding</h6><p>Suspendisse sit amet ante ac ipsum ullamcorper laoreet</p></li>
<li><h6>Headding</h6><p>Nam et sodales ipsum. Pellentesque eget magna eros</p></li>
</ul>
</div>

<div class="blog-block">
<div class="thumbnail-gallery">
<a href="#"><img src="https://placehold.co/500x300?text=image" alt=""></a>
<a href="#"><img src="https://placehold.co/500x300?text=image" alt=""></a>
<a href="#"><img src="https://placehold.co/500x300?text=image" alt=""></a>
<a href="#"><img src="https://placehold.co/500x300?text=image" alt=""></a>
<a href="#"><img src="https://placehold.co/500x300?text=image" alt=""></a>
<a href="#"><img src="https://placehold.co/500x300?text=image" alt=""></a>
<a href="#"><img src="https://placehold.co/500x300?text=image" alt=""></a>
<a href="#"><img src="https://placehold.co/500x300?text=image" alt=""></a>
<a href="#"><img src="https://placehold.co/500x300?text=image" alt=""></a>
<a href="#"><img src="https://placehold.co/500x300?text=image" alt=""></a>
</div>
</div>

<div class="blog-block">
<div class="accordion">

<div class="accordion-item">
<div class="accordion-title"><h6>accordion 1</h6><span><img src="@/assets/images/angle-right.svg" alt=""></span></div>
<div class="accordion-content">
<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam neque quisquam cumque, distinctio ducimus pariatur?Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam neque quisquam cumque, distinctio ducimus pariatur?Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam neque quisquam cumque, distinctio ducimus pariatur?</p>
</div>
</div>

<div class="accordion-item">
<div class="accordion-title"><h6>accordion 2</h6><span><img src="@/assets/images/angle-right.svg" alt=""></span></div>
<div class="accordion-content">
<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam neque quisquam cumque, distinctio ducimus pariatur?Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam neque quisquam cumque, distinctio ducimus pariatur?Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam neque quisquam cumque, distinctio ducimus pariatur?</p>
</div>
</div>

<div class="accordion-item">
<div class="accordion-title"><h6>accordion 3</h6><span><img src="@/assets/images/angle-right.svg" alt=""></span></div>
<div class="accordion-content">
<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam neque quisquam cumque, distinctio ducimus pariatur?Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam neque quisquam cumque, distinctio ducimus pariatur?Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam neque quisquam cumque, distinctio ducimus pariatur?</p>
</div>
</div>

</div>
</div>

<div class="blog-block">
<div class="two-column-grid">
<figure><img src="https://placehold.co/500x300?text=image" alt=""></figure>
<article>
<h6 class="fw-semibold">Sub Headding</h6>
<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam neque quisquam cumque, distinctio ducimus pariatur?Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam neque quisquam cumque, distinctio ducimus pariatur?Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam neque quisquam cumque, distinctio ducimus pariatur?</p>
</article>
</div>
</div>

<div class="blog-block">
<div class="banners banner-200"><img src="https://placehold.co/1500x400?text=Banner" alt=""></div>
</div>

<div class="blog-block">
<ul class="contact-form">
<li><p>Name</p><input type="text" class="form-control"></li>
<li><p>Email</p><input type="text" class="form-control"></li>
<li><p>Phone</p><input type="text" class="form-control"></li>
<li><p>Message</p><textarea type="text" class="form-control"></textarea></li>
<li><button type="submit" class="btn green">Button</button></li>
</ul>
</div>

</div>

</div>
</div>
</section><!--//End Section-->
</div>
</section>
</template>

<script>
import { defineComponent } from 'vue';
import ResourcesHeader from './ResourcesHeader.vue';

export default defineComponent({
  name: 'ResourcesModule',
  setup() {},
  components: { ResourcesHeader }
})
</script>