<template>
  <div class="section" ref="section">
    <div class="border-box-header" ref="boxHeader" @click="toggleSection">
    <h5>Subscriptions Assigned</h5>
    <img src="@/assets/images/angle-down.png" alt="" class="angle">
    </div>

    <div class="border-box-content">

    <div class="content-row overflow-x-scroll">
    <table>

    <thead>
    <tr>
      <th><p class="form-label-static form-sort-label">
      <span>Subscribers</span>
      <span @click="() => sortTable('firstName', 'mainDetails')">
        <button :class="{active: sortOrder.firstName === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.firstName === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>Free 30 Day</span>
      <span @click="() => sortTable('trial', 'subscription')">
        <button :class="{active: sortOrder.trial === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.trial === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>Basic</span>
      <span @click="() => sortTable('basic', 'subscription')">
        <button :class="{active: sortOrder.basic === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.basic === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>Basic +</span>
      <span @click="() => sortTable('plus', 'subscription')">
        <button :class="{active: sortOrder.plus === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.plus === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>Basic + Advance</span>
      <span @click="() => sortTable('advance', 'subscription')">
        <button :class="{active: sortOrder.advance === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.advance === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>Basic + Ultimate</span>
      <span @click="() => sortTable('ultimate', 'subscription')">
        <button :class="{active: sortOrder.ultimate === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.ultimate === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th class="w-75">&nbsp;</th>
    </tr>
    </thead>

    <tbody>

      <template
        v-for="(row, rowIndex) in rows"
        v-bind:key="'admin-user-list-row' + row.mainDetails?.id"
      >
        <tr
          v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
          :class="{'light-gray-bg': row.mainDetails.id === mainDetails.id}"
          @click="(ev) => selectByRow(ev, {...row})"
        >
        <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
        <td class="text-center">{{`${row.mainDetails?.firstName} ${row.mainDetails?.lastName}` }}</td>
        <td>
          <div class="checkbox">
            <input type="checkbox" id="chk1" disabled :checked="row.subscriptionAssigned?.trial">
            <label for="chk1"></label>
          </div>
        </td>
        <td>
          <div class="checkbox">
            <input type="checkbox" id="chk2" disabled :checked="row.subscriptionAssigned?.basic">
            <label for="chk2"></label>
          </div>
        </td>
        <td>
          <div class="checkbox">
            <input type="checkbox" id="chk3" disabled :checked="row.subscriptionAssigned?.plus">
            <label for="chk3"></label>
          </div>
        </td>
        <td>
          <div class="checkbox">
            <input type="checkbox" id="chk4" disabled :checked="row.subscriptionAssigned?.advance">
            <label for="chk4"></label>
          </div>
        </td>
        <td>
          <div class="checkbox">
            <input type="checkbox" id="chk5" disabled :checked="row.subscriptionAssigned?.ultimate">
            <label for="chk5"></label>
          </div>
        </td>
        <td class="w-75">
        <div class="table-links">
        <a href="javascript:void();" @click="() => {
          selectMainDetails({...row.mainDetails}, 'edit');
          selectSubscription({...row.subscription}, 'edit');
        }" style="margin: auto;">
          <img src="@/assets/images/edit-icon-2.svg" alt="">
        </a>
        <!-- <a href="javascript:void();" @click="removeUser(row.mainDetails?.id)">
          <img src="@/assets/images/trash-2.svg" alt="">
        </a> -->
        </div>
        </td>
        </tr>
      </template>

    </tbody>

    </table>
    </div>

    <div class="content-row">
    <div class="pagination">
    <div>
    <!-- <p>Records per page:</p> -->
    <!-- <select v-model="maxPage">
    <option>1</option>
    <option>2</option>
    <option>3</option>
    <option>4</option>
    <option>5</option>
    <option>6</option>
    <option>7</option>
    <option>8</option>
    <option>9</option>
    <option>10</option>
    </select> -->
    </div>
    <!-- <p>1-1 to 10</p> -->
    <p class="list-pagination">
      Page: 
      <!-- <select v-model="currentPage" class="pagination-selector">
      <option 
        v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
        v-bind:key="'task-list-pagination-' + page"
      >{{ Number.parseInt(page) + 1 }}</option>
      </select> -->
      <DropDownList 
        :mainObject="'currentPage'"
        :mainProperty="'value'"
        :injectionName="'adminSubscriptionListDetails'"
        :optionKey="'admin-subscription-list-pagination'"
        :optionsList="[]"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="currentPage.value"
        :numberList="true"
      />
    </p>
    </div>
    </div>
    
    </div>

    <div class="removal-popup" v-if="removedUserId">

      <div class="modal modal-md">
      <span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
      <div class="modal-block text-center">
      <p class="fw-bold">Are you sure you want to delete the subscriber?</p>
      </div>
      <div class="modal-block text-center">
      <div class="buttons justify-content-center">
          <button
            @click="() => approveRemoval(true)"
            class="approve-button btn green" 
          >Yes</button>
          <button
            @click="() => approveRemoval(false)" 
            class="cancel-removal-button btn green btn-o" 
          >No</button>
      </div>
      </div>
      </div>

      <!-- <div class="removal-message">
        <div class="close-button" @click="() => approveRemoval(false)" ></div>
        <span class="removal-text">
          Are you sure you want to delete the subscriber?
        </span>
        <div class="removal-buttons">
          <button
            @click="() => approveRemoval(true)"
            class="approve-button btn green" 
          >Yes</button>
          <button
            @click="() => approveRemoval(false)"
            class="cancel-removal-button btn light-blue-20 btn-o" 
          >Cancel</button>
        </div>
      </div> -->
      
    </div>

    <div v-if="userIsRemoved.active">
      <UserNotification
        v-bind:hideDetails="true"
        v-bind:messageText="userIsRemoved.message"
      />
    </div>

  </div>

  <!-- 

    <div class="border-box-header">
<h5>Subscriptions Assigned</h5>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row overflow-x-scroll">
<table>

<thead>
<tr>
<th><p class="form-label form-sort-label"><span>Subscribers</span><span><button><img src="@/assets/images/caret-up.png" alt=""></button><button><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label form-sort-label"><span>Free 30 Day</span><span><button><img src="@/assets/images/caret-up.png" alt=""></button><button><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label form-sort-label"><span>Basic</span><span><button><img src="@/assets/images/caret-up.png" alt=""></button><button><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label form-sort-label"><span>Basic +</span><span><button><img src="@/assets/images/caret-up.png" alt=""></button><button><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label form-sort-label"><span>Basic + Advance</span><span><button><img src="@/assets/images/caret-up.png" alt=""></button><button><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label form-sort-label"><span>Basic + Ultimate</span><span><button><img src="@/assets/images/caret-up.png" alt=""></button><button><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

<tr>
<td class="text-center"><a href="javascript:void();" class="underline">John</a></td>
<td><div class="checkbox"><input type="checkbox" id="chk1" checked><label for="chk1"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk2" checked><label for="chk2"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk3" checked><label for="chk3"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk4" checked><label for="chk4"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk5" checked><label for="chk5"></label></div></td>
<td class="w-75">
<div class="table-links">
<a href="javascript:void();"><img src="@/assets/images/edit-icon-2.svg" alt=""></a>
<a href="javascript:void();"><img src="@/assets/images/trash-2.svg" alt=""></a>
</div>
</td>
</tr>

<tr>
<td class="text-center"><a href="javascript:void();" class="underline">Melissa</a></td>
<td><div class="checkbox"><input type="checkbox" id="chk6" checked><label for="chk6"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk7" checked><label for="chk7"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk8" checked><label for="chk8"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk9"><label for="chk9"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk10"><label for="chk10"></label></div></td>
<td class="w-75">
<div class="table-links">
<a href="javascript:void();"><img src="@/assets/images/edit-icon-2.svg" alt=""></a>
<a href="javascript:void();"><img src="@/assets/images/trash-2.svg" alt=""></a>
</div>
</td>
</tr>

<tr class="light-gray-97-bg">
<td class="text-center"><a href="javascript:void();" class="underline">John</a></td>
<td><div class="checkbox"><input type="checkbox" id="chk11"><label for="chk11"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk12"><label for="chk12"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk13"><label for="chk13"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk14"><label for="chk14"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk15" checked><label for="chk15"></label></div></td>
<td class="w-75">
<div class="table-links">
<a href="javascript:void();"><img src="@/assets/images/edit-icon-2.svg" alt=""></a>
<a href="javascript:void();"><img src="@/assets/images/trash-2.svg" alt=""></a>
</div>
</td>
</tr>

<tr class="light-gray-bg">
<td class="text-center"><a href="javascript:void();" class="underline">Melissa</a></td>
<td><div class="checkbox"><input type="checkbox" id="chk16" checked><label for="chk16"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk17" checked><label for="chk17"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk18" checked><label for="chk18"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk19" checked><label for="chk19"></label></div></td>
<td><div class="checkbox"><input type="checkbox" id="chk20"><label for="chk20"></label></div></td>
<td class="w-75">
<div class="table-links">
<a href="javascript:void();"><img src="@/assets/images/edit-icon-2.svg" alt=""></a>
<a href="javascript:void();"><img src="@/assets/images/trash-2.svg" alt=""></a>
</div>
</td>
</tr>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<p>Records per page:</p>
<select>
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select>
</div>
<p>1-1 to 10</p>
</div>
</div>

</div>

   -->
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import UserNotification from '@/components/Shared/UserNotification.vue';
import { 
  defineComponent, ref, onMounted, 
  watch, computed, inject, reactive, provide 
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'AdminSubscriptionList',
    setup() {
        const store = useStore();
        const router = useRouter();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const users = computed(() => store.state.users);
        const rows = ref([]);
        const maxPage = reactive({
          value: 20
        });
        const options = reactive({
          value: []
        });
        const currentPage = reactive({
          value: 1
        });

        const sortOrder = reactive({
            firstName: 0,
            trial: 0,
            basic: 0,
            plus: 0,
            advance: 0,
            ultimate: 0
        });
        const { mainDetails, selectMainDetails,
        // removeUser
        // selectedEquipment,  
         } = inject('mainDetails');
        const { selectSubscription, addNewSubscription, defaultSubscription } = inject('subscription');
        const helperTooltip = ref(false);
        const removedUserId = ref('');
        const userIsRemoved = reactive({
          active: false,
          message: 'Your farm account has been deleted'
        })

        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortTable = (sortProp, section) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[section][sortProp] < b[section][sortProp]) {
                        return 1;
                    }
                    if (a[section][sortProp] > b[section][sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[section][sortProp] < b[section][sortProp]) {
                        return -1;
                    }
                    if (a[section][sortProp] > b[section][sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...users.value];
                sortOrder[sortProp] = 0;
            }
        };
        const removeUser = (userId) => {
            removedUserId.value = userId || 'primary-user';
        };
        const approveRemoval = (isApproved) => {
            if (isApproved) {
                if ((removedUserId.value === 'primary-user') || (removedUserId.value === store.state.authenticated._id)) {
                  userIsRemoved.active = true;
                  removedUserId.value = null;
                  
                  setTimeout(() => {
                    store.dispatch('archivePrimaryUser');
                    router.push({
                        name: 'page',
                        params: {
                            pagename: 'dashboard',
                            source: 'admin-subscription'
                        }
                    });
                  }, 5000)
                }
                else {
                    store.dispatch('archiveSecondaryUser', removedUserId.value);
                    selectSubscription({
                        ...store.state.primaryUser.subscription || defaultSubscription,
                        plans: {
                            ...store.state.primaryUser.subscription?.plans || defaultSubscription.plans
                        }
                    }, 'represent');
                    setTimeout(() => {
                        removedUserId.value = '';
                    }, 500);
                }
            }
            else {
                removedUserId.value = '';
            }
        };

        const selectByRow = (ev, row) => {
          if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
            return;
          } else {
            selectMainDetails({...row.mainDetails}, 'represent');
            selectSubscription({...row.subscription}, 'represent');
          }
        }

        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });
        
        watch(() => ({...store.state.primaryUser.subscription.plans}), (newVal) => {
          if (newVal) {
            store.dispatch('getUsersList');
          }
        });

        watch(() => ({...store.state.primaryUser.subscriptionAssigned}), (newVal) => {
            if (newVal) {
              const usersWithPrimary = [...store.state.users].map((user) => {
                if (user.mainDetails.id === store.state.primaryUser.id || store.state.primaryUser.mainDetails.id) {
                  return {
                    ...user,
                    mainDetails: {
                      ...user.mainDetails,
                      id: 'primary'
                    }
                  }
                } else {
                  return {
                    ...user
                  }
                }
              });
              
                rows.value = [
                    // { 
                    //   ...store.state.primaryUser, 
                    //   subscriptionAssigned: store.state.primaryUser.subscriptionAssigned || {}, 
                    //   mainDetails: {
                    //     ...store.state.primaryUser.mainDetails,
                    //     id: 'primary'
                    //   }
                    // },
                    // ...users.value
                    ...usersWithPrimary
                ];

              if (rows.value.length > 0) {
                selectMainDetails({ ...rows.value[0].mainDetails }, 'represent');
                selectSubscription({ ...rows.value[0].subscription }, 'represent', true);
              }
              options.value = Array.from({length: Math.ceil(rows.value.length / maxPage.value)}).map((_, indx) => indx + 1);
            }
        });
        watch(() => ([...store.state.users]), () => {
            console.log(users.value, 'users');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            const usersWithPrimary = [...store.state.users].map((user) => {
              if (user.mainDetails.id === store.state.primaryUser.id || store.state.primaryUser.mainDetails.id) {
                return {
                  ...user,
                  mainDetails: {
                    ...user.mainDetails,
                    id: 'primary'
                  }
                }
              } else {
                return {
                  ...user
                }
              }
            });

            rows.value = [
                // { 
                //   ...store.state.primaryUser, 
                //   subscriptionAssigned: store.state.primaryUser.subscriptionAssigned || {}, 
                //   mainDetails: {
                //     ...store.state.primaryUser.mainDetails,
                //     id: 'primary'
                //   }
                // },
                // ...users.value
                ...usersWithPrimary
            ];
            options.value = Array.from({length: Math.ceil(rows.value.length / maxPage.value)}).map((_, indx) => indx + 1);
        });

        watch(() => (mainDetails.id), () => {
          const usersWithPrimary = [...users.value].map((user) => {
            if (user.mainDetails.id === store.state.primaryUser.id || store.state.primaryUser.mainDetails.id) {
              return {
                ...user,
                mainDetails: {
                  ...user.mainDetails,
                  id: 'primary'
                }
              }
            } else {
              return {
                ...user
              }
            }
          });
          rows.value = [
            // {
            //   ...store.state.primaryUser, 
            //   subscriptionAssigned: store.state.primaryUser.subscriptionAssigned || {},
            //   mainDetails: {
            //     ...store.state.primaryUser.mainDetails,
            //     id: 'primary'
            //   }
            // },
            // ...users.value
            ...usersWithPrimary
          ];
          options.value = Array.from({length: Math.ceil(rows.value.length / maxPage.value)}).map((_, indx) => indx + 1);
        })

        watch(maxPage, () => {
          section.value.style.height = 'auto';
          setTimeout(() => {
            sectionHeight.value = section.value.offsetHeight;
          })
          options.value = Array.from({length: Math.ceil(rows.value.length / maxPage.value)}).map((_, indx) => indx + 1);
        });

        onMounted(() => {
            console.log(users.value[0]);
            console.log(store.state.primaryUser.subscriptionAssigned);
            const usersWithPrimary = [...users.value].map((user) => {
              if (user.mainDetails.id === store.state.primaryUser.id || store.state.primaryUser.mainDetails.id) {
                return {
                  ...user,
                  mainDetails: {
                    ...user.mainDetails,
                    id: 'primary'
                  }
                }
              } else {
                return {
                  ...user
                }
              }
            });
            rows.value = [
                // { 
                //   ...store.state.primaryUser, 
                //   subscriptionAssigned: store.state.primaryUser.subscriptionAssigned || {},
                //   mainDetails: {
                //     ...store.state.primaryUser.mainDetails,
                //     id: 'primary'
                //   }
                // },
                // ...users.value
                ...usersWithPrimary
            ];
            store.dispatch('getUsersList');
            if (rows.value.length > 0) {
                selectMainDetails({ ...rows.value[0].mainDetails }, 'represent');
                selectSubscription({ ...rows.value[0].subscription }, 'represent');
            }
            options.value = Array.from({length: Math.ceil(rows.value.length / maxPage.value)}).map((_, indx) => indx + 1);
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getVendors')
        });
        provide('adminSubscriptionListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            mainDetails,
            selectMainDetails,
            removeUser,
            approveRemoval,
            removedUserId,
            addNewSubscription,
            selectSubscription,
            rows,
            maxPage,
            helperTooltip,
            switchHelperTooltip,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            userIsRemoved,
            selectByRow,
            currentPage
        };
    },
    components: { UserNotification, DropDownList }
})
</script>

<style lang="scss" scoped>
.removal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  .removal-message {
    background: #fff;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 100%;
    height: max-content;
    justify-content: space-around;
    align-items: center;
    position: relative;
    border-radius: 11px;
    color: rgba(40,41,43,1);
    font-size: 21px;
    margin: 0 20px;
    padding: 25px 0;
    text-align: center;
    .close-button {
      position: absolute;
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 30px;
      height: 30px;
      background-image: url('@/assets/profile/close_button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      right: 10px;
      top: 22px;
      cursor: pointer;
    }
    .removal-text {
      margin: 0 40px;
      font-size: 16px;
      font-weight: 600;
    }
    .removal-buttons {
      display: flex;
      width: 60%;
      justify-content: space-evenly;
      margin-top: 30px;
      button {
        width: 160px;
        height: 40px;
        border-radius: 10px;
        &.cancel-removal-button {
          border: 2px solid #38AC89;
        }
        &.approve-button {
          margin-right: 10px;
        }
      }
    }
  }
}
td .checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>