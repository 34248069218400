<template>
  <div class="section" ref="section">
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Inventory Used</h5>
<div class="info-icons">
  <!-- <div class="info-icon lg top-icon"
    @click="addInventoryUsed"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
  >
    <img src="@/assets/images/plus-green-md.png" alt="">
    <div class="helper-tooltip" v-if="helperTooltip">Add Inventory</div>
  </div> -->
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-wrapper">
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label">
  <span>Date</span>
  <span @click="() => sortTable('date')">
    <button :class="{active: sortOrder.date === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.date === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Input</span>
  <span @click="() => sortTable('input')">
    <button :class="{active: sortOrder.input === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.input === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Brand</span>
  <span @click="() => sortTable('brand')">
    <button :class="{active: sortOrder.brand === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.brand === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Farm</span>
  <span @click="() => sortTable('farm')">
    <button :class="{active: sortOrder.farm === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.farm === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Bed</span>
  <span @click="() => sortTable('bed')">
    <button :class="{active: sortOrder.bed === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.bed === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Amount</span>
  <span @click="() => sortTableByAmount('amount')">
    <button :class="{active: sortOrder.amount === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.amount === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

      <template
        v-for="(row, rowIndex) in rows"
        v-bind:key="'inventory-used-list-row' + row.id"
      >
        <tr
          v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
          :class="{'light-gray-bg': row.id === selectedInventoryUsed.id}"
          @click="(ev) => selectByRow(ev, {...row})"
        >
        <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
        <td class="text-center">{{row.date}}</td>
        <td>{{row.input}}</td>
        <td>{{row.brand}}</td>
        <td>{{row.farm}}</td>
        <td>{{row.bed}}</td>
        <td>{{ Math.abs(row.numberOfItems * row.quantity) }}</td>
        <td class="w-75">
        <div class="table-links">
        <a v-if="isAuth('edit-inventory-used')" href="javascript:void();" @click="selectInventoryUsed({...row}, 'edit')">
          <img src="@/assets/images/edit-icon-2.svg" alt="">
        </a>
        <a v-if="isAuth('remove-inventory-used')" href="javascript:void();" @click="removeSelectedInventoryUsed(row.id)">
          <img src="@/assets/images/trash-2.svg" alt="">
        </a>
        </div>
        </td>
        </tr>
      </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'inventoryUsedListDetails'"
    :optionKey="'inventory-used-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>
</div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { ref, onMounted, watch, computed, inject, reactive, provide } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "InventoryUsedList",
    setup() {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        // const inventoriesUsed = computed(() => store.state.inventoriesUsed);
        const inventoriesUsed = computed(() => [...store.state.tasks].filter(stateTask => stateTask.inputTask && (stateTask.taskStatus === 'Completed')));
        // const tasks = computed(() => store.state.tasks);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const removedInventoryId = ref(null);
        const { isAuth } = inject('mainUI');
        const { selectedInventoryUsed, addInventoryUsed, editInventoryUsed, selectInventoryUsed, removeInventoryUsed } = inject('inventoryUsedDetails');
        const sortOrder = reactive({
            date: 0,
            input: 0,
            brand: 0,
            farm: 0,
            bed: 0,
            amount: 0
        });
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...inventoriesUsed.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByAmount = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(Math.abs(a.numberOfItems * a.quantity)) - Number.parseInt(Math.abs(b.numberOfItems * b.quantity)));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(Math.abs(b.numberOfItems * b.quantity)) - Number.parseInt(Math.abs(a.numberOfItems * a.quantity)));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...inventoriesUsed.value];
                sortOrder[sortProp] = 0;
            }
        };
        const helperTooltip = ref(false);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const removeSelectedInventoryUsed = (inventoryId) => {
            removedInventoryId.value = inventoryId;
        };
        const approveRemoval = (isApproved) => {
            if (isApproved) {
                removeInventoryUsed(removedInventoryId.value);
            }
            removedInventoryId.value = '';
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectInventoryUsed({ ...row }, 'represent');
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(store.state.inventoriesUsed, () => {
            console.log(inventoriesUsed.value, 'inventoriesUsed');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...inventoriesUsed.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(inventoriesUsed.value, () => {
            rows.value = [...inventoriesUsed.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
            (inventoriesUsed.value.length > 0) &&
                selectInventoryUsed({ ...inventoriesUsed.value[0] }, 'represent');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // tasks.value.forEach(task => {
            //   // if (task.input && (task.taskStatus === 'Completed')) {
            //   if (task.inputTask && (task.taskStatus === 'Completed')) {
            //     !inventoriesUsed.value.find(inv => inv.id === task.id) && inventoriesUsed.value.push(
            //       {
            //         ...task,
            //         product: task.input
            //       }
            //     )
            //   }
            // })
            // store.dispatch('getAddedInventorys')
            rows.value = [...inventoriesUsed.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('inventoryUsedListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            section,
            boxHeader,
            toggleSection,
            addInventoryUsed,
            editInventoryUsed,
            selectInventoryUsed,
            removeInventoryUsed,
            rows,
            maxPage,
            selectedInventoryUsed,
            helperTooltip,
            switchHelperTooltip,
            sortOrder,
            sortTable,
            sortTableByAmount,
            currentPage,
            removedInventoryId,
            removeSelectedInventoryUsed,
            approveRemoval,
            selectByRow,
            isAuth
        };
    },
    components: { DropDownList }
}
</script>

<style lang="scss" scoped>
.top-icon {
  position: relative;
}
</style>