<template>
  <section class="section" ref="section">
      <div class="border-box active">
          <div class="border-box-header" ref="boxHeader" @click="toggleSection">
              <h5>Crop Harvested By Week (lbs) </h5>
              <img src="@/assets/images/angle-down.png" alt="" class="angle">
          </div>
          <div class="border-box-content">

              <div class="content-block">

                  <div class="content-row grid-4 grid-full">
                      <div class="row">

                          <div class="col-3">
                              <div class="form-item">
                                  <p class="form-label">Choose a Farm:</p>
                                  <DropDownList
                                    :mainObject="'currentSelection'"
                                    :mainProperty="'farm'"
                                    :injectionName="'selectionDetails'"
                                    :optionKey="'farm-option-crop-harvested-by-week'"
                                    :optionsList="[]"
                                    :optionProperty="'farmName'"
                                    :optionPropertyTwo="null"
                                    :defaultSelected="currentSelection.farm"
                                    @click="() => {
                                        // activated.farm = true;
                                    }"
                                  />
                              </div>
                          </div>

                          <div class="col-3">
                              <div class="form-item">
                                  <p class="form-label">Choose a Year:</p>
                                  <DropDownList
                                    :mainObject="'currentSelection'"
                                    :mainProperty="'year'"
                                    :injectionName="'selectionDetails'"
                                    :optionKey="'year-option-crop-harvested-by-week'"
                                    :optionsList="yearOptions"
                                    :optionProperty="null"
                                    :optionPropertyTwo="null"
                                    :defaultSelected="yearOptions[0]"
                                    @click="() => {
                                        // activated.year = true;
                                    }"
                                  />
                              </div>
                          </div>

                      </div>
                  </div>

              </div>

              <div class="content-block">

                  <div class="content-row table-wrapper-block">
                      <div class="table-wrapper">
                          <table class="headding-table">
                              <thead>
                                  <tr><th><p class="form-label">&nbsp;</p></th></tr>
                              </thead>
                              <tbody>
                                <template v-for="row in rows" v-bind:key="'crop-harvested-by-week-row-header' + row.crop">
                                    <tr><td><p class="form-label">{{ row.crop }}</p></td></tr>
                                </template>
                                <tr><td><p class="form-label green">Total Harvested</p></td></tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="table-wrap flex-1">
                          <table>

                              <thead>
                                  <tr>
                                      <th class="text-center" style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Week 1</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Week 2</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Week 3</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Week 4</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Week 5</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label green">Total</p>
                                      </th>
                                  </tr>
                              </thead>

                              <tbody>

                                <template v-for="row in rows" v-bind:key="'crop-harvested-by-month-row' + row.crop">
                                  <tr>
                                    <td class="text-center">{{ `${Number.parseFloat(row.week1).toFixed(2)}` }}</td>
                                    <td>{{ `${Number.parseFloat(row.week2).toFixed(2)}` }}</td>
                                    <td>{{ `${Number.parseFloat(row.week3).toFixed(2)}` }}</td>
                                    <td>{{ `${Number.parseFloat(row.week4).toFixed(2)}` }}</td>
                                    <td>{{ `${Number.parseFloat(row.week5).toFixed(2)}` }}</td>
                                    <td><span class="bg">{{ `${Number.parseFloat(row.total).toFixed(2)}` }}</span></td>
                                  </tr>
                                </template>
                                <tr>
                                      <td class="text-center"><span class="bg">{{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.week1),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">{{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.week2),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">{{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.week3),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">{{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.week4),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">{{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.week5),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">{{ 
                                        Number.parseFloat(
                                          rows.reduce(
                                            (accumulator, currentValue) => Number.parseFloat(accumulator) + Number.parseFloat(currentValue.total),
                                            0,
                                          )
                                        ).toFixed(2)
                                      }}</span></td>
                                  </tr>

                              </tbody>

                          </table>
                      </div>
                      
                  </div>

              </div>

          </div>
      </div>
  </section><!--//End Section-->
</template>

<script>
import { defineComponent, ref, onMounted, watch, provide } from 'vue';
import { useStore } from 'vuex';
import DropDownList from '@/components/Shared/DropDownList.vue';
import yearOptions from '@/sharedData/yearsList';


export default defineComponent({
  name: 'CropHarvestedByWeek',
  setup() {
    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);
    const store = useStore();

    const rows = ref([
        {
            crop: 'Corn',
            week1: '35.00',
            week2: '35.00',
            week3: '35.00',
            week4: '35.00',
            week5: '35.00',
            total: '175.00'
        },
        {
            crop: 'Soya',
            week1: '5.00',
            week2: '5.00',
            week3: '5.00',
            week4: '5.00',
            week5: '5.00',
            total: '25.00'
        },
        {
            crop: 'Wheat',
            week1: '5.00',
            week2: '5.00',
            week3: '5.00',
            week4: '5.00',
            week5: '5.00',
            total: '25.00'
        },
        {
            crop: 'Potatoes',
            week1: '5.00',
            week2: '5.00',
            week3: '5.00',
            week4: '5.00',
            week5: '5.00',
            total: '25.00'
        },
        {
            crop: 'Cucumbers',
            week1: '5.00',
            week2: '5.00',
            week3: '5.00',
            week4: '5.00',
            week5: '5.00',
            total: '25.00'
        }
    ]);
    const farms = ref([...store.state.farms]);

    const currentSelection = ref({
      farm: '',
      year: ''
    });

    const toggleSection = (ev) => {
        if ((ev.target.className !== 'border-box-header') &&
            (ev.target.className !== 'angle') && (ev.target.className !== 'angle revert')) {
            return;
        }
        sectionExpanded.value = !sectionExpanded.value;
    };
    watch(sectionExpanded, (newVal) => {
        section.value.style.height = newVal ?
            `${sectionHeight.value}px` :
            `${boxHeader.value.offsetHeight}px`;
        section.value.style.overflow = newVal ? 'visible' : 'hidden';
    });
    // watch(() => ([...store.state]), (newVal) => { 
    //     rows.value = [...newVal];
    //     setTimeout(() => {
    //         section.value.style.height = 'auto';
    //         sectionHeight.value = section.value.offsetHeight;
    //     });
    // });
    watch(() => ([...store.state.farms]), (newVal) => { 
        farms.value = [...newVal];
    });
    // watch(() => ({...currentSelection}), (newVal) => {
    //     if (newVal.value.farm && newVal.value.year) {
    //         rows.value = [...store.state].filter(e => (e.year === newVal.value.year && e.farm === newVal.value.farm));
    //     }
    // });

    onMounted(() => {
        setTimeout(() => {
            sectionHeight.value = section.value.offsetHeight;
            section.value.style.height = `${section.value.offsetHeight}px`;
        });
        // rows.value = [...store.state];
        // store.dispatch('get');
        store.dispatch('getFarms');
    });

    provide('selectionDetails', {
      currentSelection,
      options: farms 
    })

    return {
        section,
        boxHeader,
        toggleSection,
        rows,
        yearOptions,
        currentSelection
    }
  },
  components: { DropDownList }
});
</script>