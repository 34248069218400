<template>
  <section class="section" ref="section">
  <div class="border-box active">
  <!-- <div class="section" ref="section"> -->
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Farm Plan History</h5>
<div class="info-icons">
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-wrapper">
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label">
  <span>Year</span>
  <span @click="() => sortTableByNumber('year')">
    <button :class="{active: sortOrder.year === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.year === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Season</span>
  <span @click="() => sortTable('season')">
    <button :class="{active: sortOrder.season === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.season === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Farm</span>
  <span @click="() => sortTable('farmName')">
    <button :class="{active: sortOrder.farmName === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.farmName === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Acre</span>
  <span @click="() => sortTableByNumber('acreage')">
    <button :class="{active: sortOrder.acreage === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.acreage === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Crop</span>
  <span @click="() => sortTable('primaryCrop')">
    <button :class="{active: sortOrder.primaryCrop === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.primaryCrop === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

<template
    v-for="(row, rowIndex) in rows"
    v-bind:key="'equipment-list-row' + row.id"
  >
    <tr
      v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
      :class="{'light-gray-bg': row?.id === plan?.id}"
      @click="(ev) => selectByRow(ev, {...row})"
    >
    <!-- :class="{'light-gray-bg': row?.farm?.id === plan?.farm?.id}" -->
    <!-- :class="{'light-gray-bg': row.id === plan.id}" -->
    <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
    <td class="text-center">{{ row?.crop?.year || ((row?.farm?.beds?.length > 0) && row?.farm?.beds[0]?.year) }}</td> <!-- || row.year -->
    <td>{{ row?.crop?.season || ((row?.farm?.beds?.length > 0) && row?.farm?.beds[0]?.season) }}</td> <!-- || row.season -->
    <td>{{ row?.farm?.farmName }}</td>
    <td>{{ row?.crop?.acreage || row?.farm?.acreage }}</td> <!-- || row.acreage -->
    <td>{{ row?.crop?.crop || row?.farm?.primaryCrop }}</td> <!-- || row.primaryCrop -->
    <td class="w-75">
    <div class="table-links">
    <!-- <a href="javascript:void();" @click="selectPlan({...row}, 'edit')">
      <img src="@/assets/images/edit-icon-2.svg" alt="">
    </a>
    <a href="javascript:void();" @click="removePlan(row.id)">
      <img src="@/assets/images/trash-2.svg" alt="">
    </a> -->
    <!-- <a href="javascript:void();" @click="callEquipment(row.id, row.phone)">
      <img src="@/assets/images/phone-icon.svg" alt="">
    </a> -->
    </div>
    </td>
    </tr>
  </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'farmHistoryListDetails'"
    :optionKey="'farm-history-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>
</div>
</section>

</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { ref, onMounted, watch, inject, reactive, provide } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'FarmHistoryList',
    setup() {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const archiveFarmPlans = ref([...store.state.archiveFarmPlans]);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const sortOrder = reactive({
            year: 0,
            season: 0,
            farmName: 0,
            acreage: 0,
            primaryCrop: 0
        });
        const { plan, editPlan, selectPlan, removePlan } = inject('planDetails');
        const helperTooltip = ref(false);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...archiveFarmPlans.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByNumber = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...archiveFarmPlans.value];
                sortOrder[sortProp] = 0;
            }
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectPlan({ ...row }, 'represent');
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(() => ([...store.state.archiveFarmPlans]), (newVal) => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...newVal];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(maxPage, () => {
          options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
        });
        onMounted(() => {
            (archiveFarmPlans.value.length > 0) && selectPlan({ ...archiveFarmPlans.value[0] }, 'represent');
            console.log(archiveFarmPlans.value, 'archiveFarmPlans.value');
            store.dispatch('getArchivedFarms');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getVendors')
            rows.value = [...store.state.archiveFarmPlans];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('farmHistoryListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            plan,
            editPlan,
            selectPlan,
            removePlan,
            rows,
            maxPage,
            currentPage,
            helperTooltip,
            switchHelperTooltip,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            sortTableByNumber,
            selectByRow
        };
    },
    components: { DropDownList }
}
</script>

<style lang="scss" scoped>
.top-icon {
  position: relative;
}
</style>