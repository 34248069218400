<template>
    <div class="border-box-content">

    <div class="content-block d-flex justify-content-between">
    <h5 class="fw-semibold">{{mainDetails.firstName}} {{ mainDetails.lastName }}</h5>
    <!-- <div class="icon-group">
    <div class="icon green icon-o top-icon"
      @click="createNewUser"
      @mouseover="switchAddHelperTooltip(true)"
      @mouseout="switchAddHelperTooltip(false)"
    >
      <img src="@/assets/images/user-plus.svg" alt="">
      <div class="add-helper-tooltip" v-if="addHelperTooltip">Add User</div>
    </div>
    <div class="icon green icon-o top-icon" 
      @click="selectMainDetails({...mainDetails},'edit')"
      @mouseover="switchEditHelperTooltip(true)"
      @mouseout="switchEditHelperTooltip(false)"
    >
      <img src="@/assets/images/edit.svg" alt="">
      <div class="edit-helper-tooltip" v-if="editHelperTooltip">Edit</div>
    </div>
    </div> -->
    </div>

    <div class="content-block">
    <div class="intro-grid">

    <div class="intro-grid-column">
    <p class="title">Email</p>
    <h6>{{ mainDetails.email }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">Account Name</p>
    <h6>{{ mainDetails.accountName }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">Title</p>
    <h6>{{ mainDetails.title }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">First Name</p>
    <h6>{{ mainDetails.firstName }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">Last Name</p>
    <h6>{{ mainDetails.lastName }}</h6>
    </div>
    
    <div class="intro-grid-column">
    <p class="title">Mobile Phone</p>
    <h6>{{ mainDetails.mobilePhone }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">Work Phone</p>
    <h6>{{ mainDetails.workPhone }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">Address 1</p>
    <h6>{{ mainDetails.address }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">Address 2</p>
    <h6>{{ mainDetails.address2 }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">City</p>
    <h6>{{ mainDetails.city }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">State</p>
    <h6>{{ mainDetails.state }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">Zip</p>
    <h6>{{ mainDetails.zip }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">Country</p>
    <h6>{{ mainDetails.country }}</h6>
    </div>

    </div>
    </div>

    <template v-if="isPrimaryUser && (!mainDetails.id || (mainDetails.id === 'primary'))">
      <!-- <FileAttachments v-bind:section="'laborModule'" /> -->
      <FileAttachments v-bind:section="'primary'" />
    </template>

    <template v-if="isPrimaryUser && (mainDetails.id && (mainDetails.id !== 'primary'))">
      <!-- <FileAttachments v-bind:section="'laborModule'" /> -->
      <FileAttachments v-bind:section="'secondary'" v-bind:instance="`${mainDetails.id}`" />
    </template>

    <template v-if="isSecondaryUser && (userDetails.id === mainDetails.id)">
      <!-- <FileAttachments v-bind:section="'laborModule'" /> -->
      <FileAttachments v-bind:section="'secondary'" v-bind:instance="`${mainDetails.id}`" />
    </template>

    <!-- <div class="content-block">

    <div class="user-role">
    <div class="row">
    <div class="col-auto">
    <p class="title">Role Type</p>
    </div>
    <div class="col">
    <div class="row">

      <div class="col-auto mobile-input"><div class="radio"><input name="userrole" type="radio" value="admin" id="admin" v-model="mainDetails.userRole" disabled><label for="admin">Admin</label></div></div>
      <div class="col-auto mobile-input"><div class="radio"><input name="userrole" type="radio" value="edit" id="editonly" v-model="mainDetails.userRole" disabled><label for="editonly">Edit Only</label></div></div>
      <div class="col-auto mobile-input"><div class="radio"><input name="userrole" type="radio" value="contribute" id="contributeonly" v-model="mainDetails.userRole" disabled><label for="contributeonly">Contribute Only</label></div></div>
      <div class="col-auto mobile-input"><div class="radio"><input name="userrole" type="radio" value="read" id="readonly" v-model="mainDetails.userRole" disabled><label for="readonly">Read only</label></div></div>

    </div>
    </div>
    </div>
    </div>
    </div> -->

    </div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { defineComponent, inject, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'LaborDetails',
    setup() {
      const store = useStore();
        const { mainDetails, selectMainDetails } = inject('mainDetails');
        const { isAuth } = inject('mainUI');
        const editHelperTooltip = ref(false);
        const addHelperTooltip = ref(false);

        const userDetails = ref({
          id: store.state.authenticated.primary ? 
            (store.state.primaryUser.id || store.state.primaryUser.mainDetails.id) :
            (store.state.secondaryUser.id || store.state.secondaryUser.mainDetails.id)
        });

        const isSecondaryUser = ref(!store.state.authenticated.primary);

        const isPrimaryUser = ref(store.state.authenticated.primary);

        const switchEditHelperTooltip = (toggle) => {
            editHelperTooltip.value = toggle;
        };
        const switchAddHelperTooltip = (toggle) => {
            addHelperTooltip.value = toggle;
        };

        return {
            mainDetails,
            selectMainDetails,
            switchAddHelperTooltip,
            addHelperTooltip,
            switchEditHelperTooltip,
            editHelperTooltip,
            isAuth,
            userDetails,
            isSecondaryUser,
            isPrimaryUser
        };
    },
    components: { FileAttachments }
})
</script>