<template>
  <div class="section" ref="section">
<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Harvest List</h5>
<div class="info-icons">
<div class="info-icon lg top-icon"
@click="addHarvest"
@mouseover="switchHelperTooltip(true)"
@mouseout="switchHelperTooltip(false)"
>
<img src="@/assets/images/plus-green-md.png" alt="">
<div class="helper-tooltip" v-if="helperTooltip">Add Harvest</div>
</div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-wrapper">
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label">
<span>Account Name</span>
<span @click="() => sortTable('accountName')">
<button :class="{active: sortOrder.accountName === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
<button :class="{active: sortOrder.accountName === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
</span></p></th>
<th><p class="form-label-static form-sort-label">
<span>Primary User</span>
<span @click="() => sortTable('primaryUser')">
<button :class="{active: sortOrder.primaryUser === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
<button :class="{active: sortOrder.primaryUser === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
</span></p></th>
<th><p class="form-label-static form-sort-label">
<span>Work Phone</span>
<span @click="() => sortTable('workPhone')">
<button :class="{active: sortOrder.workPhone === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
<button :class="{active: sortOrder.workPhone === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
</span></p></th>
<th><p class="form-label-static form-sort-label">
<span>Email</span>
<span @click="() => sortTable('email')">
<button :class="{active: sortOrder.email === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
<button :class="{active: sortOrder.email === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
</span></p></th>
<th><p class="form-label-static form-sort-label">
<span>State</span>
<span @click="() => sortTable('state')">
<button :class="{active: sortOrder.state === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
<button :class="{active: sortOrder.state === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
</span></p></th>
<th><p class="form-label-static form-sort-label">
<span>Country</span>
<span @click="() => sortTable('country')">
<button :class="{active: sortOrder.country === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
<button :class="{active: sortOrder.country === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
</span></p></th>
<th class="w-75">&nbsp;</th>
<th class="w-75">&nbsp;</th>

</tr>
</thead>

<tbody>

<template
v-for="(row, rowIndex) in rows"
v-bind:key="'harvest-list-row' + row.id"
>
<tr
  v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
  :class="{'light-gray-bg': row.id === 'selectedAccount.id'}"
>
<!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
<td>
  <!-- <a href="javascript:void();" class="underline" @click="selectAccount(row.id)">{{ row.company }}</a> -->
  <a href="javascript:void();" class="underline">{{ row?.mainDetails?.accountName }}</a>
</td>
<td>{{ `${row?.mainDetails?.firstName} ${row?.mainDetails?.lastName}` }}</td>
<td>{{ row?.mainDetails?.workPhone }}</td>
<td>{{ row.email }}</td>
<td>{{ row?.mainDetails?.state }}</td>
<td>{{ row?.mainDetails?.country }}</td>
<!-- <td class="w-75">
<div class="table-links">
<a href="javascript:void();" @click="selectAccount(row.id)">
  <img src="@/assets/images/edit-icon-2.svg" alt="">
</a>
<a href="javascript:void();" @click="removeAccount(row.id)">
  <img src="@/assets/images/trash-2.svg" alt="">
</a>
</div>
</td> -->

<td class="w-75">
<div class="table-links">
<!-- <a href="javascript:void();" @click="changeAccountStatus(row.id, row.status)">
  <img src="@/assets/images/Active_Green.svg" alt="" v-if="row.status === 'paused'">
  <img src="@/assets/images/Pause_Green.svg" alt="" v-if="row.status === 'active'">
</a>
<a href="javascript:void();" @click="archiveTenanv-if="(rowIndex + 1) <= maxPage"t(row.id)">
  <img src="@/assets/images/Archive_Green.svg" alt="">
</a> -->
</div>
</td>
</tr>
</template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'superAdminArchivedListDetails'"
    :optionKey="'super-admin-archived-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>
</div>

<div class="removal-popup" v-if="removalPopup">

  <div class="modal modal-md">
  <span class="modal-close" @click="() => {
    removalPopup = false;
    activeId = null;
  }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">Are you sure you want to delete the account?</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => removeCurrentAccount()"
        class="approve-button btn green" 
      >Yes</button>
      <button
        @click="() => {
          removalPopup = false;
          activeId = null;
        }" 
        class="cancel-removal-button btn green btn-o" 
      >Cancel</button>
  </div>
  </div>
  </div>

<!-- <div class="removal-message">
<div class="close-button" @click="() => {
  removalPopup = false;
  activeId = null;
}" ></div>
<span class="removal-text">Are you sure you want to delete the account?</span>
<div class="removal-buttons">
  <button
    @click="() => removeCurrentAccount()"
    class="approve-button btn green" 
  >Yes</button>
  <button
    @click="() => {
      removalPopup = false;
      activeId = null;
    }" 
    class="cancel-removal-button btn light-blue-20 btn-o" 
  >Cancel</button>
</div>
</div> -->

</div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { defineComponent, ref, onMounted, watch, computed, reactive, provide } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'SuperAdminList',
    setup() {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const accounts = computed(() => store.state.accounts.filter(account => account.archived));
        const activeId = ref(null);
        const removalPopup = ref(false);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const sortOrder = reactive({
            accountName: 0,
            primaryUser: 0,
            workPhone: 0,
            email: 0,
            state: 0,
            country: 0
        });
        const helperTooltip = ref(false);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...accounts.value];
                sortOrder[sortProp] = 0;
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(accounts.value, () => {
            rows.value = [...accounts.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(() => ([...store.state.accounts]), () => {
            console.log(accounts.value, 'accounts');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...accounts.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(() => ({...store.state.authenticated}), (newVal) => {
          if (newVal.superToken) {
            store.dispatch('getAccounts');
          }
        })
        const removeAccount = (id) => {
            activeId.value = id;
            removalPopup.value = true;
        };
        const removeCurrentAccount = () => {
            store.dispatch('removeAccount', `${activeId.value}`);
            setTimeout(() => {
                activeId.value = null;
                removalPopup.value = false;
            }, 100);
        };
        onMounted(() => {
          if (store.state.authenticated.superToken) {
            store.dispatch('getAccounts');
          }
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getHarvests')
            rows.value = [...accounts.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('superAdminArchivedListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            rows,
            maxPage,
            currentPage,
            helperTooltip,
            switchHelperTooltip,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            removeAccount,
            activeId,
            removalPopup,
            removeCurrentAccount
        };
    },
    components: { DropDownList }
})
</script>

<style lang="scss" scoped>
.removal-popup,
.archive-popup,
.status-popup {
position: fixed;
left: 0;
top: 0;
z-index: 999999;
width: 100vw;
height: 100vh;
background: rgba(0,0,0,0.6);
display: flex;
justify-content: center;
align-items: center;
.removal-message,
.archive-message,
.status-message {
background: #fff;
display: flex;
flex-direction: column;
max-width: 650px;
width: 100%;
height: max-content;
justify-content: space-around;
align-items: center;
position: relative;
border-radius: 11px;
color: rgba(40,41,43,1);
font-size: 21px;
margin: 0 20px;
padding: 25px 0;
text-align: center;
.close-button {
  position: absolute;
  display: flex;
  text-indent: -9999px;
  mask-position: center;
  margin-left: auto;
  width: 30px;
  height: 30px;
  background-image: url('@/assets/profile/close_button.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  right: 10px;
  top: 22px;
  cursor: pointer;
}
.removal-text,
.archive-text,
.status-text {
  margin: 0 40px;
  font-size: 16px;
  font-weight: 600;
}
.removal-buttons,
.archive-buttons,
.status-buttons {
  display: flex;
  width: 60%;
  justify-content: space-evenly;
  margin-top: 30px;
  button {
    width: 160px;
    height: 40px;
    border-radius: 10px;
    &.cancel-removal-button {
      border: 2px solid #38AC89;
    }
    &.cancel-archive-button {
      border: 2px solid #38AC89;
    }
    &.cancel-status-button {
      border: 2px solid #38AC89;
    }
    &.approve-button {
      margin-right: 10px;
    }
  }
}
}
}

</style>