<template>
  <div class="section" ref="section">
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5>Admin Expenses</h5>
  <div class="info-icons">
  <div class="info-icon lg top-icon tooltip"
  @click="() => addAdminExpense('Other')"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
  data-tooltip="Add Admin Expense"
  >
  
    <img src="@/assets/images/updated/new_set2/Admin_Expenses_Circle.png" alt="">
    <!-- <img src="@/assets/images/updated/add-item.png" alt=""> -->
  <!-- <div class="helper-tooltip" v-if="helperTooltip">Add Admin Expense</div> -->
  </div>
  </div>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>

  <div class="border-box-content">

  <div class="content-row table-wrapper">
  <table>

  <thead>
  <tr>
  <th><p class="form-label-static form-sort-label">
  <span>Date</span>
  <span @click="() => sortTable('date')">
    <button :class="{active: sortOrder.date === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.date === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
  <th><p class="form-label-static form-sort-label">
  <span>Category</span>
  <span @click="() => sortTable('category')">
    <button :class="{active: sortOrder.category === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.category === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
  <th><p class="form-label-static form-sort-label">
  <span>Vendor</span>
  <span @click="() => sortTable('vendor')">
    <button :class="{active: sortOrder.vendor === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.vendor === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
  <th><p class="form-label-static form-sort-label">
  <span>Invoice #</span>
  <span @click="() => sortTableByNumber('invoiceNumber')">
    <button :class="{active: sortOrder.invoiceNumber === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.invoiceNumber === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
  <th><p class="form-label-static form-sort-label">
  <span>Amount</span>
  <span @click="() => sortTableByNumber('amount')">
    <button :class="{active: sortOrder.amount === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.amount === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
  <th><p class="form-label-static form-sort-label">
  <span>Status</span>
  <span @click="() => sortTable('status')">
    <button :class="{active: sortOrder.status === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.status === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
  <th class="w-75">&nbsp;</th>

  </tr>
  </thead>

  <tbody>

  <template
    v-for="(row, rowIndex) in rows"
    v-bind:key="'harvest-list-row' + row.id"
  >
    <tr
      v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
      :class="{'light-gray-bg': row.id === selectedAdminExpense.id}"
      @click="(ev) => selectByRow(ev, {...row})"
    >
    <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
    <td>{{ row.date ? moment(row.date, 'YYYY-MM-DD').format('MM/DD/YYYY') : '' }}</td>
    <td>{{ row.category }}</td>
    <td>{{ row.vendor }}</td>
    <td>{{ row.invoiceNumber }}</td>
    <td>{{ row.amount }}</td>
    <td>{{ row.status }}</td>
    <td class="w-75">
    <div class="table-links">
    <a href="javascript:void();" @click="selectAdminExpense({...row}, 'edit')">
      <img src="@/assets/images/edit-icon-2.svg" alt="">
    </a>
    <!-- <a href="javascript:void();" @click="removeVendor(row.id)">
      <img src="@/assets/images/trash-2.svg" alt="">
    </a> -->
    <a href="javascript:void();" @click="removeExpense(row.id)">
      <img src="@/assets/images/trash-2.svg" alt="">
    </a>
    </div>
    </td>
    </tr>
  </template>

  </tbody>

  </table>
  </div>

  <div class="content-row">
  <div class="pagination">
  <div>
  <!-- <p>Records per page:</p> -->
  <!-- <select v-model="maxPage">
  <option>1</option>
  <option>2</option>
  <option>3</option>
  <option>4</option>
  <option>5</option>
  <option>6</option>
  <option>7</option>
  <option>8</option>
  <option>9</option>
  <option>10</option>
  </select> -->
  </div>
  <!-- <p>1-1 to 10</p> -->
  <p class="list-pagination">
    Page: 
    <!-- <select v-model="currentPage" class="pagination-selector">
    <option 
      v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
      v-bind:key="'task-list-pagination-' + page"
    >{{ Number.parseInt(page) + 1 }}</option>
    </select> -->
    <DropDownList 
      :mainObject="'currentPage'"
      :mainProperty="'value'"
      :injectionName="'adminExpensesListDetails'"
      :optionKey="'admin-expenses-list-pagination'"
      :optionsList="[]"
      :optionProperty="null"
      :optionPropertyTwo="null"
      :defaultSelected="currentPage.value"
      :numberList="true"
    />
  </p>
  </div>
  </div>
  
  </div>
  </div>

  <div class="removal-popup" v-if="removedExpense">

  <div class="modal modal-md">
  <span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">Are you sure you want to delete the Expense?</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => approveRemoval(true)"
        class="approve-button btn green" 
      >Yes</button>
      <button
        @click="() => approveRemoval(false)" 
        class="cancel-removal-button btn green btn-o" 
      >No</button>
  </div>
  </div>
  </div>

  </div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { 
  ref, onMounted, watch, 
  inject, reactive, 
  defineComponent, provide
} from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';

export default defineComponent({
    name: 'AdminExpensesList',
    setup() {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const adminExpenses = ref(store.state.adminExpenses.filter(adEx => adEx.expenseType !== 'Salary'));
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const sortOrder = reactive({
            date: 0,
            category: 0,
            vendor: 0,
            invoiceNumber: 0,
            amount: 0,
            status: 0
        });
        const { selectedAdminExpense, addAdminExpense, editAdminExpense, selectAdminExpense, removeAdminExpense } = inject('adminExpenseDetails');
        const helperTooltip = ref(false);

        const removedExpense = ref(null);

        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...adminExpenses.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByNumber = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...adminExpenses.value];
                sortOrder[sortProp] = 0;
            }
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectAdminExpense({ ...row }, 'represent');
            }
        };

        const removeExpense = (id) => {
            removedExpense.value = { id };
        };
        const approveRemoval = (approved) => {
            if (approved) {
              removeAdminExpense(removedExpense.value.id)
            }
            removedExpense.value = null;
        };

        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        // watch(adminExpenses.value, () => {
        //     rows.value = [...adminExpenses.value];
        //     options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        // });
        watch(() => ([...store.state.adminExpenses]), (newVal) => {
            console.log(adminExpenses.value, 'adminExpenses');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...newVal].filter(adEx => adEx.expenseType !== 'Salary');
            adminExpenses.value = [...newVal].filter(adEx => adEx.expenseType !== 'Salary');
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
          store.dispatch('getAdminExpenses');
            (adminExpenses.value.length > 0) && selectAdminExpense({ ...adminExpenses.value[0] }, 'represent');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getHarvests')
            rows.value = [...store.state.adminExpenses].filter(adEx => adEx.expenseType !== 'Salary');
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
            // tasks.value.forEach(task => {
            //   if (task.amtHarvested && (task.taskStatus === 'Completed')) {
            //     !laborExpenses.value.find(hrv => hrv.id === task.id) && laborExpenses.value.push({ ...task })
            //   }
            // })
        });
        provide('adminExpensesListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            addAdminExpense,
            editAdminExpense,
            selectAdminExpense,
            // removeAdminExpense,
            rows,
            maxPage,
            currentPage,
            selectedAdminExpense,
            helperTooltip,
            switchHelperTooltip,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            sortTableByNumber,
            selectByRow,
            removeExpense,
            approveRemoval,
            removedExpense,
            moment
        };
    },
    components: { DropDownList }
})
</script>

<style lang="scss" scoped></style>