<template>
  <div class="section" ref="section">
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5>Billing Address</h5>
  <div class="info-icons">
    <div class="info-icon billing-tooltip-container"
      v-if="isAuth('edit-billing')"
            @click="changeBillingField('edit', 'mode')"
            @mouseover="switchHelperTooltip(true)"
            @mouseout="switchHelperTooltip(false)"
      >
      <img src="@/assets/images/edit.svg" alt="">
        <div class="helper-tooltip" v-if="helperTooltip">Edit Billing</div>
      </div>
  </div>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>

  <div class="border-box-content">

  <div class="content-block">
  <div class="intro-grid">

  <!-- <div class="intro-grid-column">
  <p class="title">Company</p>
  <h6>{{ billing.company }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Industry</p>
  <h6>{{ billing.industry }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Company Size</p>
  <h6>{{ billing.companySize }}</h6>
  </div> -->
  <div class="intro-grid-column">
  <p class="title">Account Name</p>
  <h6>{{ billing.accountName }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">First Name</p>
  <h6>{{ billing.firstName }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Last Name</p>
  <h6>{{ billing.lastName }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Address 1</p>
  <h6>{{ billing.address }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Address 2</p>
  <h6>{{ billing.address2 }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">City</p>
  <h6>{{ billing.city }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">State</p>
  <h6>{{ billing.state }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Zip</p>
  <h6>{{ billing.zip }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Country</p>
  <h6>{{ billing.country }}</h6>
  </div>

  </div>
  </div>
  </div>
  </div>
</template>

<script>
import { defineComponent, inject, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AdvertisementAccountBillingFilled',
  setup() {
    const store = useStore();

    const { billing, focusOnBilling, changeBillingField } = inject('billing');
    const { isAuth } = inject('mainUI');

    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);

    const helperTooltip = ref(false);
    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle;
    }

    const toggleSection = (ev) => {
      if (ev.target.className !== 'border-box-header') return;
      sectionExpanded.value = !sectionExpanded.value
    }

    watch(sectionExpanded, (newVal) => {
      section.value.style.height = newVal ? 
        `${sectionHeight.value}px` : 
          `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'    })

    watch(focusOnBilling, (newVal) => {
      if (newVal) {
        switchHelperTooltip(true);
        setTimeout(() => switchHelperTooltip(false), 5000);
      }
    })

    onMounted(() => {

      sectionHeight.value = section.value.offsetHeight;
      section.value.style.height = `${section.value.offsetHeight}px`;

      if (store.state.advertisementAccount?.billing?.submitted) {
        for (let prop in billing) { 
          changeBillingField(store.state.advertisementAccount?.billing[prop], prop);
          changeBillingField('represent', 'mode');
          changeBillingField(true, 'submitted');
        }
      }
    })

    return {
      billing,
      changeBillingField,
      switchHelperTooltip,
      helperTooltip,
      isAuth,

      section,
      boxHeader,
      toggleSection
    };
  }
})
</script>
