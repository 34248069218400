<template>
    <section class="section">
  <div class="border-box">
  <div class="border-box-content">

  <div class="content-block d-flex justify-content-between">
  <h5 class="fw-semibold">Reset Password</h5>
  <div class="icon-group">
    <!-- <div class="icon green icon-o tooltip"
      @mouseover="switchHelperTooltip(true)"
      @mouseout="switchHelperTooltip(false)"
      data-tooltip="Camera"
    >
      <img src="@/assets/images/updated/camera-white.png" alt="">
    </div> -->
  </div>
  </div>

  <div class="content-block">

  <div class="content-row">
  <div class="content-row grid-4 grid-full">
  <div class="row">

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.email || (resetPassword.email.length > 0)}">Email</p>
    <input 
      type="text" 
      class="form-control"
      v-model="resetPassword.email"
      @focus="() => {
        activated.email = true;
        focused.email = true;
      }"
      @blur="() => {
        focused.email = false;
        otherTypeSelected = false;
      }" 
      disabled
    />
  <!-- <p v-if="activated.email && resetPassword.email.length === 0" class="form-info red text-end">* Required</p> -->
  </div>
  </div>

  <div class="col-3">
  <div class="form-item password-field">
  <p class="form-label" :class="{active: focused.password || (resetPassword.password.length > 0)}">New password</p>
    <input 
      :type="passwordIsVisible ? 'text' : 'password'"
      class="form-control"
      v-model="resetPassword.password"
      @focus="() => {
        activated.password = true;
        focused.password = true;
      }"
      @blur="() => {
        focused.password = false;
        otherTypeSelected = false;
      }" 
    />
    <div class="password-icon" :class="{visible: passwordIsVisible, hidden: !passwordIsVisible}" @click="() => {passwordIsVisible = !passwordIsVisible}"></div>
    <!--  style="width: 180%; left: -80%;" -->
    <p 
      v-if="activated.password && !focused.password && ((resetPassword.password.length > 0) && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._()-^])[A-Za-z\d@$!%*?&._()-^]{8,}$/.test(resetPassword.password))" 
      class="form-info red text-end validation" 
    >*Please use minimum of 8 characters at least 1 Upper and 1 lower case letter, 1 number & 1 special character</p>
  <p v-if="activated.password && resetPassword.password.length === 0" class="form-info red text-end">* Required</p>
  </div>
  </div>


  <div class="col-3">
  <div class="form-item password-field">
  <p class="form-label" :class="{active: focused.repeatPassword || (resetPassword.repeatPassword.length > 0)}">Re-enter password</p>
    <input 
      :type="passwordIsVisible ? 'text' : 'password'"
      class="form-control"
      v-model="resetPassword.repeatPassword"
      @focus="() => {
        activated.repeatPassword = true;
        focused.repeatPassword = true;
      }"
      @blur="() => {
        focused.repeatPassword = false;
        otherTypeSelected = false;
      }" 
    />
    <div class="password-icon" :class="{visible: passwordIsVisible, hidden: !passwordIsVisible}" @click="() => {passwordIsVisible = !passwordIsVisible}"></div>
  <p v-if="activated.repeatPassword && (resetPassword.repeatPassword.length > 0) && (resetPassword.repeatPassword !== resetPassword.password)" class="form-info red text-end">Password doesn't match</p>
  <p v-if="activated.repeatPassword && (resetPassword.repeatPassword.length === 0) && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._()-^])[A-Za-z\d@$!%*?&._()-^]{8,}$/.test(resetPassword.password)" class="form-info red text-end">* Required</p>
  </div>
  </div>


  </div>
  </div>
  </div>
  </div>

  <div class="content-block">
    <div class="content-section">
    <div class="buttons">
    <div class="row">
    <div class="col-auto"><button type="submit" @click="submitForm" class="btn green">Submit</button></div>
    <div class="col-auto"><button type="submit" @click="cancelChanges" class="btn light-blue-20 btn-o">Cancel</button></div>
    </div>
    </div>
    </div>
  </div>

  </div>
  </div>
  </section>

  <div v-if="popUps.passwordIsChanged.active">
    <UserNotification
      v-bind:messageText="popUps.passwordIsChanged.message"
    />
  </div>
</template>

<script>
import UserNotification from '@/components/Shared/UserNotification.vue';
import { defineComponent, reactive, onMounted, watch, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ResetPassword',
  components: {
    UserNotification
  },
  setup() {

    const store = useStore();

    const resetPassword = reactive({
      email: '',
      password: '',
      repeatPassword: ''
    });

    const activated = reactive({
      password: '',
      repeatPassword: ''
    });

    const focused = reactive({
      password: '',
      repeatPassword: ''
    });

    const popUps = reactive({
      passwordIsChanged: {
        active: false,
        message: 'Your password has been updated successfully, please log back to your account'
      }
    });

    const passwordIsVisible = ref(false);

    const submitForm = () => {
      if (!resetPassword.email || (!resetPassword.password || !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._()-^])[A-Za-z\d@$!%*?&._()-^]{8,}$/.test(resetPassword.password)) || (resetPassword.password !== resetPassword.repeatPassword)) {
        if (!resetPassword.email) activated.email = true;
        if (!resetPassword.password || !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._()-^])[A-Za-z\d@$!%*?&._()-^]{8,}$/.test(resetPassword.password)) activated.password = true;
        if (!resetPassword.repeatPassword) activated.repeatPassword = true;

        return;
      }
      
      store.dispatch('resetPassword', {
        ...resetPassword
      });
    };

    const cancelChanges = () => {
      for (let prop in resetPassword) {
        if (prop !== 'email') {
          resetPassword[prop] = '';
        }
      }
    };

    watch(() => (store.state.resetPasswordStatus), (newVal) => {
      if (newVal) {
        popUps.passwordIsChanged.active = true;
        setTimeout(() => {
          popUps.passwordIsChanged.active = false;
          store.dispatch('logOutApi');
          window.location = `http://bhoomiapp.com/login?reset=true`;
        }, 5100)
      }
    });

    onMounted(() => {
      const params = new URLSearchParams(document.location.search);
      const email = params.get("email");

      resetPassword.email = email;
    });

    return {
      resetPassword,
      activated,
      focused,
      submitForm,
      cancelChanges,
      popUps,
      passwordIsVisible
    }
  }
})
</script>

<style lang="scss" scoped>
.password-field {
  position: relative;
  .password-icon {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 3.7rem;
    right: 1rem;
    z-index: 8;
    border-radius: 1rem;
    background-color: #fff;
    &.visible {
      background:url('@/assets/images/updated/visiblepassword.png') center center no-repeat;
      background-size: contain;
    }
    &.hidden {
      background:url('@/assets/images/updated/hidepassword.png') center center no-repeat;
      background-size: contain;
    }
  }
  .form-item .form-info {
    width: max-content;
  }
  .validation {
    width: max-content;
    left: -10rem;
  }
}
</style>