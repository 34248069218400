<template>
  <LaborHeader />

  <IncomeForm v-if="((editionMode === 'edit') || (editionMode === 'create')) && (isAuth('create-labor-income') || isAuth('edit-labor-income'))" />
  <IncomeFilled v-if="editionMode === 'represent'" />
  
  <IncomeList />
</template>

<script>
import { defineComponent, inject, provide, reactive, ref } from 'vue';
import LaborHeader from './LaborHeader.vue';
import IncomeForm from './IncomeForm.vue';
import IncomeFilled from './IncomeFilled.vue';
import IncomeList from './IncomeList.vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: "IncomeModule",
    components: { LaborHeader, IncomeForm, IncomeFilled, IncomeList },
    setup() {
      const store = useStore();

      const { isAuth } = inject('mainUI');

      const editionMode = ref('');

      const laborExpense = reactive({
        id: '',
        date: '',
        task: '', 
        assignedTo: '', 
        taskStatus: '',
        totalHours: '', 
        rate: '', 
        total: '',

        farm: '', 
        bed: '',
        crop: '',
        inputUsed: '',
        amtHarvested: '',
        comments: '',
        
        taskType: '',

        inputTask: false,
        harvestTask: false,
        otherTask: false,
      });

      const selectedLaborExpense = reactive({
        ...laborExpense
      });

      const defaultLaborExpense = {
        ...laborExpense
      }

      const bedsOptions = ref([]);

      const addLaborExpense = () => {
        selectLaborExpense(defaultLaborExpense, 'create');
        // editionMode.value = 'create';
      }

      const editLaborExpense = (field, val) => {
        laborExpense[field] = val;
      }

      const selectLaborExpense = (laborExpenseData, editMode) => {
        if (laborExpenseData) {
          for (let field in laborExpenseData) {
            laborExpense[field] = laborExpenseData[field]
            selectedLaborExpense[field] = laborExpenseData[field]
          }
        }
        editionMode.value = editMode;
      }

      const removeLaborExpense = (laborExpenseId) => {
        store.dispatch('removeLaborExpense', laborExpenseId)
      };

      provide('laborExpenseDetails', {
        laborExpense,
        defaultLaborExpense,
        selectedLaborExpense,
        editionMode,
        addLaborExpense,
        editLaborExpense,
        selectLaborExpense,
        removeLaborExpense,
        options: bedsOptions
      })

      return {
        editionMode,
        isAuth,
        laborExpense
      }
    }
})
</script>