<template>
  <ReportsHeader />
  <SalesByMonth />
  <SalesByWeek />
  <SalesByCrop />
  <SalesByCustomer />
</template>

<script>
import { defineComponent } from 'vue'; //, ref, onMounted, inject, watch, reactive, provide
import ReportsHeader from './ReportsHeader.vue';
import SalesByMonth from './SalesByMonth.vue';
import SalesByWeek from './SalesByWeek.vue';
import SalesByCrop from './SalesByCrop.vue';
import SalesByCustomer from './SalesByCustomer.vue';
// import { useStore } from 'vuex';
// import DropDownList from '@/components/Shared/DropDownList.vue';


export default defineComponent({
  name: 'ReportsModule',
  setup() {
  },
  components: { 
    ReportsHeader,
    SalesByMonth,
    SalesByWeek,
    SalesByCrop,
    SalesByCustomer
  }
});
</script>