<template>
  <div v-if="openedFile.file" class="file-container">
    <div v-if="(openedFile.fileType === 'document') || (openedFile.fileType === 'Document')" class="document-container">
      <DocumentFile :docFile="openedFile.file" />
    </div>
    <div v-if="(openedFile.fileType === 'image') || (openedFile.fileType === 'Image')" class="image-container">
      <img :src="openedFile.file" />
    </div>
    <div v-if="(openedFile.fileType === 'video') || (openedFile.fileType === 'Video')" class="video-container">
      <!-- <video :src="openedFile.file" controls></video> -->
      <DocumentFile :docFile="openedFile.file" />
    </div>
    <div v-if="(openedFile.fileType === 'link') || (openedFile.fileType === 'Link')" class="video-container">
      <!-- <video :src="openedFile.file" controls></video> -->
      <DocumentFile :docFile="openedFile.file" />
    </div>
    <div class="close-file" @click="closeFile">
      <img src="@/assets/images/close-green-xs.png" alt="">
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import DocumentFile from "./DocumentFile.vue";

export default {
  name: "OpenedFile",
  components: { DocumentFile },
  setup() {
    const store = useStore();

    const openedFile = ref({
      file: null,
      fileType: ''
    });

    const closeFile = () => {
      store.dispatch('closeResource');
    }

    // const createPreview = (e) => {
    //   const reader = new FileReader();
    //   // let fileName = e.target.value.split("\\");
    //   // fileName = fileName[fileName.length - 1].split(".")[0];

    //   if (e.target.files[0]) {
    //     reader.readAsDataURL(e.target.files[0]);
    //   }
    //   reader.onload = function () {
    //     setTimeout(() => {
    //       profileBg.value = `url(${reader.result})`;
    //       profileBgImg.value = `${reader.result}`;
    //       console.log(reader.result);
    //     }, 500);
    //   };
    //   reader.onerror = function (error) {
    //     console.log("Error: ", error);
    //   };
    // };

    watch(() => ({...store.state.selectedResourceFile}), (newVal) => {
      if (newVal) {
        openedFile.value = newVal;
      }
    });

    return {
      openedFile,
      closeFile
    }
  },
};
</script>

<style lang="scss" scoped>
.file-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 9;
  background: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.document-container,
.image-container,
.video-container {
  width: 80%;
  height: 90%;
  margin: auto;
  img {
    width: auto;
    height: 100%;
    margin: auto;
  }
  video {
    width: 100%;
    height: 100%;
  }
}
.close-file {
  position: absolute;
  right: 3vw;
  top: 3vh;
  cursor: pointer;

  width: 3rem; 
  height: 3rem; 
  border: .2rem solid hsl(var(--green)); 
  border-radius: 100%; 
  display: grid;
  place-items:center;
  background: #fff;
}
</style>