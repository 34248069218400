<template>
  <VendorHeader v-if="!isInputDialog && !isEquipmentDialog" />
  
  <div ref="vendorForm"></div>
  <VendorForm v-if="((editionMode === 'edit') || (editionMode === 'create')) && (isAuth('edit-vendor') || isAuth('add-new-vendor'))" />
  <VendorFilled  v-if="editionMode === 'represent'" />

  <div ref="vendorItemForm"></div>
  <VendorProductsServices :key="`${vendor.id}-${vendorItem.id}-vendor-item-form`" v-if="((productsServicesMode === 'create') || (productsServicesMode === 'edit')) && (isAuth('create-new-vendor-item') || isAuth('edit-vendor-item'))" />
  <VendorProductsServicesFilled :key="`${vendor.id}-${vendorItem.id}-vendor-item-filled`" v-if="(productsServicesMode === 'represent') && (vendorItems.length > 0)" />
  
  <VendorProductsServicesList :key="`${vendor.id}-vendor-item-list`" v-if="(vendorItems.length > 0) && (!isInputDialog && !isEquipmentDialog)" />

  <VendorList v-if="!isInputDialog && !isEquipmentDialog" />
</template>

<script>
import { ref, reactive, provide, onMounted, watch, inject } from 'vue';
import { useStore } from "vuex";
import VendorForm from './VendorForm.vue';
import VendorFilled from './VendorFilled.vue';
import VendorList from './VendorList.vue';
import VendorHeader from './VendorHeader.vue';
import { useRouter } from 'vue-router';
import VendorProductsServices from './VendorProductsServices.vue';
import VendorProductsServicesFilled from './VendorProductsServicesFilled.vue';
import VendorProductsServicesList from './VendorProductsServicesList.vue';

export default {
  name: "VendorModule",
  props: ['equipmentDialogView', 'inputDialogView'],
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const { isAuth } = inject('mainUI');

    const editionMode = ref('');

    const productsServicesMode = ref('');

    const vendorForm = ref(null);

    const vendorItemForm = ref(null);

    const isInputDialog = ref(props.inputDialogView);

    const isEquipmentDialog = ref(props.equipmentDialogView);

    const vendor = reactive({
      id: '',
      vendor: '',
      vendorContact: '',
      vendorPhone: '',
      email: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      status: '',

      // product: '',
      // brand: '',
      // variety: '',
      mobile: '',

      // serviceType: '',
      // cellPhone: '',
      comments: ''
    });

    const vendorItem = reactive({
      id: '',
      type: '',
      item: '',
      manufacturer: '',
      brand: '',
      variety: '',

      numberOfItems: '',
      quantity: '',
      date: '',
      invoice: '',
      amountPaid: '',
      warrantyExp: '',

      serviceDate: '',

      serviceType: '',
      equipment: '',

      comments: ''
    });

    const selectedVendor = reactive({
      ...vendor
    });

    const defaultVendorItem = {
      ...vendorItem
    }

    const defaultVendor = {
      ...vendor
    }

    const vendorItems = ref([...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.vendor === vendor.id));

    const vendorFormSubmitted = ref(false);

    const manualEquipment = ref(false);

    const serviceSaved = ref({});

    const addVendor = () => {
      selectVendor(defaultVendor, 'create');
      // editionMode.value = 'create';
    }

    const editVendor = (field, val) => {
      vendor[field] = val;
    }

    const selectVendor = (vendorData, editMode) => {
      if (vendorData) {
        for (let field in vendorData) {
          vendor[field] = vendorData[field]
          selectedVendor[field] = vendorData[field]
        }
      }
      editionMode.value = editMode;

      if ((editMode === 'edit') || (editMode === 'create')) {
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: vendorForm?.value?.offsetTop - 100,
            left: 0,
            behavior: 'smooth'
          })
        })
      }
    }

    const selectVendorItem = (itemData, editMode, redirected) => {
      if (itemData) {
        for (let prop in itemData) {
          vendorItem[prop] = itemData[prop];
        }
      }
      productsServicesMode.value = editMode;

      if (((editMode === 'edit') || (editMode === 'create')) && !redirected) {
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: vendorItemForm?.value?.offsetTop - 100,
            left: 0,
            behavior: 'smooth'
          })
        })
      }
    }

    const removeVendor = (vendorId) => {
      store.dispatch('removeVendor', vendorId)
    };

    const removeVendorItem = (vendorItemId) => {
      store.dispatch('removeVendorItem', vendorItemId);
    }

    const navigateToItemForm = () => {
      setTimeout(() => {
        document.getElementsByClassName('form-modal')[0].scroll({
          top: vendorItemForm?.value?.offsetTop,
          left: 0,
          behavior: 'smooth'
        })
      })
    }

    const navigateToVendorForm = () => {
      setTimeout(() => {
        document.getElementsByClassName('vendor-modal')[0].scroll({
          top: vendorForm?.value?.offsetTop - 100,
          left: 0,
          behavior: 'smooth'
        })
      })
    }

    watch(() => (vendor.id), (newVal) => {
      if (newVal) {
        console.log(store.state.vendorItems, vendorItems.value, 'vendorItems');
        vendorItems.value = [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.vendor === vendor.id);
      }
    });

    watch(() => ([...store.state.vendorItems]), (newVal) => {
      if (newVal) {
        vendorItems.value = [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.vendor === vendor.id);
      }
    })

    provide('vendorDetails', {
      vendor,
      defaultVendor,
      selectedVendor,
      editionMode,
      addVendor,
      editVendor,
      selectVendor,
      removeVendor,
      productsServicesMode,
      vendorItem,
      defaultVendorItem,
      selectVendorItem,
      removeVendorItem,
      isInputDialog,
      isEquipmentDialog,
      navigateToItemForm,
      vendorFormSubmitted,
      manualEquipment,
      serviceSaved,
      navigateToVendorForm
    })

    onMounted(() => {
      store.dispatch('getVendorItems');
      
      if (router.currentRoute.value.params.source === 'equipment') {
        selectVendor({...defaultVendor}, 'create');
      }

      if (router.currentRoute.value.params.source === 'inventory-module') {
        selectVendor({...defaultVendor}, 'create');
        selectVendorItem({...defaultVendor}, 'create', true);
        vendorItem.type = 'Product';
        setTimeout(() => {
          // document.getElementsByTagName('main')[0].scroll({
          //   top: vendorItemForm?.value?.offsetTop - 100,
          //   left: 0,
          //   behavior: 'smooth'
          // })
          editionMode.value = 'create';
          // productsServicesMode.value = 'edit';
          vendorItem.type = 'Product';
        })
      }

      if (router.currentRoute.value.params.source === 'equipment-module') {
        selectVendor({...defaultVendor}, 'create');
        selectVendorItem({...defaultVendor}, 'create', true);
        vendorItem.type = 'Equipment';
        setTimeout(() => {
          // document.getElementsByTagName('main')[0].scroll({
          //   top: vendorItemForm?.value?.offsetTop - 100,
          //   left: 0,
          //   behavior: 'smooth'
          // })
          editionMode.value = 'create';
          // productsServicesMode.value = 'edit';
          vendorItem.type = 'Equipment';
        })
      }

      if (router.currentRoute.value.params.source === 'service-module') {
        vendorItem.type = 'Service';
        setTimeout(() => {
          // document.getElementsByTagName('main')[0].scroll({
          //   top: vendorItemForm?.value?.offsetTop - 100,
          //   left: 0,
          //   behavior: 'smooth'
          // })
          editionMode.value = 'create';
          // productsServicesMode.value = 'edit';
          vendorItem.type = 'Service';
        })
      }

      if (router.currentRoute.value.params.source === 'admin-dashboard') {
        editionMode.value = 'create';
      }

      if (isInputDialog.value) {
        vendorItem.type = 'Product';
        editionMode.value = 'create';
        productsServicesMode.value = 'create';
      }
      if (isEquipmentDialog.value) {
        vendorItem.type = 'Equipment';
        editionMode.value = 'create';
        productsServicesMode.value = 'create';
      }
    });

    return {
      editionMode,
      isAuth,
      vendor,
      productsServicesMode,
      vendorItems,
      vendorItem,
      vendorForm,
      vendorItemForm,
      isInputDialog,
      isEquipmentDialog
    }
  },
  components: {
    VendorForm,
    VendorFilled,
    VendorList,
    VendorHeader,
    VendorProductsServices,
    VendorProductsServicesFilled,
    VendorProductsServicesList
}
}
</script>

<style lang="scss" scoped></style>