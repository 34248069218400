<template>
<section class="section" ref="section">
  <div class="border-box active">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<p class="fw-semibold">Plan Crop</p>
<div class="info-icons">
  <div class="icon green icon-o tooltip"
    @click="editSelectedCrop"
    data-tooltip="Edit Crop"
    v-if="isAuth('edit-crop')"
  >
    <img src="@/assets/images/updated/Edit.png" alt="">
  </div>
<!-- <a href="javascript:void();" class="icon green"><img src="@/assets/images/upload.svg" alt=""></a> -->
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle" :class="{revert: !sectionExpanded}">
</div>

  <div class="border-box-content">

  <div class="content-section border-box-content p-0">

    <div class="intro-grid">

      <div class="intro-grid-column">
      <p class="title">Year</p>
      <h6>{{ crop.year }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Season</p>
      <h6>{{ crop.season }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Crop Cycle</p>
      <h6>{{ crop.cropCycle }}</h6>
      </div>

      <div class="intro-grid-column">
      <!-- <p class="title">Bed</p>
      <h6>{{ crop.bed }}</h6> -->
      </div>

      <!-- <div class="intro-grid-column">
      <p class="title">Acerage</p>
      <h6>{{ crop.acreage }}</h6>
      </div> -->

      <div class="intro-grid-column">
      <p class="title">Crop Category</p>
      <h6>{{ crop.cropCategory }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Crop</p>
      <h6>{{ crop.crop }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Variety</p>
      <h6>{{ crop.variety }}</h6>
      </div>

      <div class="intro-grid-column">
      <!-- <p class="title">Farm</p>
      <h6>{{ crop.farmName }}</h6> -->
      </div>

      <div class="intro-grid-column">
      <p class="title">Date Seed</p>
      <h6>{{ moment(crop.dateSeed, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Harvest Date</p>
      <h6>{{ moment(crop.harvestDate, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Proj. Harvest Amt (lbs)</p>
      <h6>{{ crop.projHarvestAmount }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Proj. Wastage (lbs)</p>
      <h6>{{ crop.projWastage }}</h6>
      </div>

    </div>

  </div>

  <div class="content-block">
<div class="content-header">
<h6 class="fw-semibold">Set Notification</h6>
</div>
<div class="content-section">


<div class="checkbox-group-wrap">

<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="dateSeed" v-model="crop.isDateSeed" disabled><label for="dateSeed">Date Seed</label></div>
<div class="field-box">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control sm" v-model="crop.seedDays" disabled>
</div>
</div>

<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="dateHarvest" v-model="crop.isDateHarvest" disabled><label for="dateHarvest">Date Harvest</label></div>
<div class="field-box">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control sm" v-model="crop.harvestDays" disabled>
</div>
</div>

</div>


</div>
</div>

  </div>
  </div>
  </section>
</template>

<script>
import moment from 'moment';
import { defineComponent, watch, ref, inject, onMounted } from 'vue';

export default defineComponent({
  name: 'FarmPlanCropFilled',
  setup() {

    const { isAuth } = inject('mainUI');
    const { crop, selectCrop, newCropCreation } = inject('cropEdition');
    const { initialModuleOpening } = inject('farmEdition');
    // const { bed } = inject('bedEdition');
    const { mainSectionIsEditable } = inject('formMode');
    let bed;
    
    const bedEdition = inject('bedEdition');

    if (bedEdition) {
      bed = bedEdition.bed;
    }
    
    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);

    const toggleSection = (ev) => {
      if ((ev.target.className !== 'border-box-header') && 
        (ev.target.className !== 'angle') && (ev.target.className !== 'angle revert')) {
        return;
      }
      sectionExpanded.value = !sectionExpanded.value
    }

    const editSelectedCrop = () => {
      mainSectionIsEditable.value = false;
      newCropCreation.value = true;
      selectCrop({...crop}, 'edit')
    }

    watch(sectionExpanded, (newVal) => {
      section.value.style.height = newVal ? 
        `${sectionHeight.value}px` : 
          `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'    })

    watch(() => ({...bed}), () => {
      for (let prop in crop) {
        crop[prop] = bed[prop];
      }
    });

    onMounted(() => {
  
      setTimeout(() => {
        sectionHeight.value = section.value.offsetHeight;
        section.value.style.height = `${section.value.offsetHeight}px`;
      });

      if (!initialModuleOpening.value) {
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: section.value.offsetTop + 10,
            left: 0,
            behavior: 'smooth'
          })
        }, 100)
      }

      if (bed) {
        for (let prop in crop) {
          crop[prop] = bed[prop];
        }
      }

    });

    return {
      crop,
      toggleSection,
      sectionExpanded,
      section,
      boxHeader,
      moment,
      editSelectedCrop,
      isAuth
    }
  }
})
</script>

<style lang="scss" scoped>
.section-block {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.content-header {
  &.border-box-header {
    margin-top: 0.1rem;
  }
  h6 {
    font-size: var(--h6);
  }
}

.angle {
  &.revert {
    transform: rotate(180deg);
  }
}
</style>