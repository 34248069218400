<template>
  <AdminHeader />
  <section class="section mt-1">
    <div class="border-box">
      <div ref="paymentForm"></div>
      <AdminPaymentForm v-if="(paymentEditionMode === 'create') || (paymentEditionMode === 'edit')" />
      <AdminPaymentFilled v-else-if="paymentEditionMode === 'represent'" />

      <div ref="billingForm"></div>
      <AdminPaymentBillingForm v-if="(billingEditionMode === 'create') || (billingEditionMode === 'edit')" />
      <AdminPaymentBillingFilled v-else-if="paymentEditionMode === 'represent'"/>

      <div class="border-box-content" v-if="paymentEditionMode === 'represent'">

      <div class="content-block d-flex justify-content-end" v-if="currentRouteSource">
        <div class="content-row">
        <div class="buttons">
        <div class="row justify-content-end">
        <div class="col-auto d-flex justify-content-end"><button type="submit" class="btn green"  @click="submitAssigned">Submit</button></div>
        </div>
        </div>
        </div>
      </div>
      </div>

    </div>

    <div v-if="popups.assign.active">
      <UserNotification
        v-bind:messageText="popups.assign.message"
      />
    </div>

    <div v-if="popups.cardData.active">
      <UserNotification 
        v-bind:messageText="popups.cardData.message"
      />
    </div>

    <div v-if="popups.billing.active">
      <UserNotification 
        v-bind:messageText="popups.billing.message"
      />
    </div>

    <div class="notify-popup" v-if="popups.purchased">

      <div class="modal modal-md">
      <span class="modal-close" @click="() => {
          popups.purchased = false;
          // redirectToSource();
        }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
      <div class="modal-block text-center">
      <p class="fw-bold">Thank you for your purchase, your subscription has been added to your account</p>
      </div>
      <!-- <div class="modal-block text-center">
      <div class="buttons justify-content-center">
          <button
            @click="() => {
              popups.purchased = false;
              redirectToSource();
            }"
            class="approve-button btn green" 
          >Ok</button>
      </div>
      </div> -->
      </div>

      <!-- <div class="notify-message">
        <div class="close-button" @click="() => {
          popups.purchased = false;
          redirectToSource();
        }" ></div>
        <span class="notify-text">
          Thank you for your purchase, your subscription has been added to your account
        </span>
        <div class="notify-buttons">
          <button
            @click="() => {
              popups.purchased = false;
              redirectToSource();
            }"
            class="approve-button btn green" 
          >Ok</button>
        </div>
      </div> -->
      
    </div>

    <!-- <div class="prompt-popup" v-if="paymentRemovalIndex.removal">
      <div class="prompt-message">
        <div class="close-button" @click="() => promptApprove(null)" ></div>
        <span class="prompt-text">Select pa</span>
        <div class="prompt-buttons">
          <button
            @click="() => promptApprove(null)" 
            class="cancel-prompt-button btn light-blue-20 btn-o" 
          >Cancel</button>
        </div>
      </div>
    </div> -->
  </section>
</template>

<script>
import { computed, onMounted, provide, reactive, ref, watch } from 'vue';
import AdminPaymentForm from './AdminPaymentForm.vue';
import AdminPaymentFilled from './AdminPaymentFilled.vue';
import AdminHeader from './AdminHeader.vue';
import { useStore } from 'vuex';
import AdminPaymentBillingForm from './AdminPaymentBillingForm.vue';
import AdminPaymentBillingFilled from './AdminPaymentBillingFilled.vue';
import { useRouter } from 'vue-router';
import UserNotification from '@/components/Shared/UserNotification.vue';

export default {
  name: "AdminPayment",
  components: {
    AdminPaymentForm,
    AdminPaymentFilled,
    AdminHeader,
    AdminPaymentBillingForm,
    AdminPaymentBillingFilled,
    UserNotification
},
  setup() {

    const store = useStore();

    const router = useRouter();

    const paymentEditionMode = ref('');

    const billingEditionMode = ref('');

    const sameAddress = ref(false)

    const payment = reactive({
        
      // paymentType: '',

      id: '',
      cardHolderName: '',
      // cardNumber: '',
      // cardType: '',
      expiryDate: '',
      // cvv: '',
      zip: '',

      // accountNickname: '',
      // accountName: '',
      // accountType: '',
      // routing: '',
      // accountNumber: '',

      saveForFuture: false
    });

    const defaultPayment = {
      ...payment
    }

    const appliedPayment = ref(null);

    const billing = reactive({
      accountName: '',
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      paymentMethodId: '',
      sameAddress: false
    })

    const defaultBilling = {
      ...billing,
      // mode: 'edit'
    }

    const selectedPaymentMethod = ref(null);

    const selectedPayment = ref(null);

    const billingForm = ref(null);

    const paymentForm = ref(null);

    const popups = reactive({
      assign: {
        active: false,
        message: 'Please choose a payment method to apply'
      },
      purchased: false,
      cardData: {
        active: false,
        message: 'Please check your card information and submit again or add a new card'
      },
      billing: {
        active: false,
        message: 'Please add your billing address!',
        status: ''
      }
    })

    const stripeCard = ref();

    const stripeBank = ref();

    const stripeElements = ref();

    const cardName = ref('');

    const paymentMethodsList = computed(() => store.state.authenticated.paymentMethods);

    const currentRouteSource = ref(null);

    const paymentWasApplied = ref(false);

    const paymentMethods = //computed(() => store.state.authenticated.paymentMethods);
    reactive([
      ...paymentMethodsList.value.map(method => {
        return {
          ...method,
          detailsOpened: false,
          applied: false
        }
      })
    ]);

    const paymentFormSaved = ref(false);

    const changePaymentField = (val, field) => {
      payment[field] = val;
    }

    const changeBillingField = (val, field) => {
      billing[field] = val;
    }

    const collectCardPayment = (cardElement, elms) => {
      stripeCard.value = cardElement;
      stripeElements.value = elms;
      console.log(
        stripeCard.value,
        stripeElements.value
      )
    }

    const collectPayment = (paymentElement, elms) => {
      stripeBank.value = paymentElement;
      stripeElements.value = elms;
      console.log(
        stripeBank.value,
        stripeElements.value
      )
    }

    const confirmCardPayment = () => {
      console.log(stripeElements.value, stripeCard.value);
      // Access instance methods, e.g. createToken()
      // stripeElements.value.createToken(stripeCard.value).then((result) => {
      //   // Handle result.error or result.token
      //   console.log(result)
      // })

      // stripeElements.value.instance.confirmPayment({
      //   elements: stripeElements.value.elements,
      //   confirmParams: {
      //     return_url: "http://localhost:8080/user-profile",
      //   }
      // }).then((result) => {
      //   // Handle result.error or result.token
      //   console.log(result)
      // }).catch(e => console.log(e))

      // if (store.state.selectedPayment.paymentType === 'card') {
      stripeElements.value.instance.confirmCardPayment(
        store.state.subscriptionPlan.clientSecret,  
        {
          payment_method: {
            card: stripeCard.value,
            billing_details: {
              name: 'Jenny Rosen',
            }
          },
          // customer: store.state.primaryUserTenant.id
        }).then((result) => {
          // Handle result.error or result.token
          console.log(result)
        }).catch(e => console.log(e))
      // }

      // if (store.state.selectedPayment.paymentType === 'bank') {
      // }

    }

    const confirmPayment = () => {
      console.log(stripeElements.value, stripeBank.value);

      stripeElements.value.instance.confirmPayment({
        elements: stripeElements.value.elements,
        confirmParams: {
          return_url: `https://${store.state.authenticated.accountSubdomain}.${'bhoomiapp.com'}/user-profile`,
          payment_method_data: {
            billing_details: {
              name: cardName.value
            }
          }
        },
        redirect: 'if_required'
      }).then((result) => {
        // Handle result.error or result.token
        console.log(result);
        store.dispatch('changeAdminPayment', {isChanged: false});

        // paymentEditionMode.value = 'represent';
        popups.billing.active = true;
        
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: billingForm?.value?.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        });

        setTimeout(() => {
          popups.billing.active = false;
        }, 5100)
      }).catch(e => console.log(e))
    }

    const confirmSetup = () => {
      console.log(stripeElements.value, stripeBank.value);

      stripeElements.value.instance.confirmSetup({
        elements: stripeElements.value.elements,
        confirmParams: {
          return_url: `https://${store.state.authenticated.accountSubdomain}.${'bhoomiapp.com'}/user-profile`,
          payment_method_data: {
            billing_details: {
              name: cardName.value
            }
          }
        },
        redirect: 'if_required'
      }).then((result) => {
        // Handle result.error or result.token
        console.log(result);
        if (result.error) {
          console.error(result.error.message);
        } else {
          // store.dispatch("savePaymentMethod", { payment, selectedPayment });
          // store.dispatch("savePrimaryUser", { section: "payment", data: payment });
          store.dispatch('changeAdminPayment', {isChanged: false});
          // paymentEditionMode.value = 'represent';
          popups.billing.active = true;
        
          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: billingForm?.value?.offsetTop,
              left: 0,
              behavior: 'smooth'
            })
          });

          setTimeout(() => {
            popups.billing.active = false;
          }, 5100)
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const createPaymentMethod = () => {

      stripeElements.value.instance.confirmSetup({
        elements: stripeElements.value.elements,
        confirmParams: {
          return_url: `https://${store.state.authenticated.accountSubdomain}.${'bhoomiapp.com'}/user-profile`,
          payment_method_data: {
            billing_details: {
              name: cardName.value
            }
          }
        },
        redirect: 'if_required'
      }).then((result) => {
        // Handle result.error or result.token
        console.log(result);
        if (result.error) {
          console.error(result.error.message);
        } else {
          // paymentEditionMode.value = 'represent';
          popups.billing.active = true;
        
          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: billingForm?.value?.offsetTop,
              left: 0,
              behavior: 'smooth'
            })
          });

          setTimeout(() => {
            popups.billing.active = false;
          }, 5100)

          store.dispatch('clearFuturePayment');
        }
      }).catch(e => {
        console.error(e);
      })

    }

    const submitAssigned = () => {
      store.dispatch('savePaymentMethod', {
        payment: paymentMethods[appliedPayment.value], 
        selectedPayment: appliedPayment.value
      });

      console.log(store.state.savedAdminSubscription, 'store.state.savedAdminSubscription');
      store.dispatch('createSubscription', {
        data: {
          ...store.state.savedAdminSubscription
        }
      });

      if ((typeof store.state.authenticated.selectedPayment === 'number') && 
        store.state.primaryUser.billing) {
          popups.purchased = true;
          setTimeout(() => {
            popups.purchased = false;
            redirectToSource();
          }, 5000);
        }
      /*
      setTimeout(() => {
        if (router.currentRoute.value.params.source === 'user-subscription') {
          router.push({
            name: 'page', 
            params: {
              pagename: 'user-subscription',
              source: 'user-subscription'
            }
          })
        } else if (router.currentRoute.value.params.source === 'secondary-user') {
          router.push({
            name: 'page', 
            params: {
              pagename: 'secondary-user',
              source: 'admin-subscription'
            }
          })
        } else if (router.currentRoute.value.params.source === 'admin-account') {
          router.push({
            name: 'admin', 
            params: {
              pagename: 'admin-account',
              source: 'admin-subscription'
            }
          })
        } else {
          router.push({
            name: 'admin', 
            params: {
              pagename: 'admin-subscription',
              source: 'admin-users'
            }
          })
        }
      }, 10500)
      */
    }

    const navigateToPaymentForm = () => {
      setTimeout(() => {
        document.getElementsByTagName('main')[0].scroll({
          top: paymentForm?.value?.offsetTop,
          left: 0,
          behavior: 'smooth'
        })
      })
    }

    watch(sameAddress, () => {
      if (sameAddress.value && store.state.primaryUser.mainDetails) {
        billing.sameAddress = true;
        for (let prop in billing) {
          if (store.state.primaryUser.mainDetails[prop] && 
            store.state.primaryUser.mainDetails[prop].length > 0) {
              changeBillingField(store.state.primaryUser.mainDetails[prop], prop);
          }
        }
      }
    })

    provide('payment', {
      payment,
      defaultPayment,
      selectedPayment,
      paymentEditionMode,
      changePaymentField,
      appliedPayment,
      popups,
      selectedPaymentMethod,
      paymentFormSaved,
      navigateToPaymentForm
    })

    provide('billing', {
      billing,
      sameAddress,
      defaultBilling,
      billingEditionMode,
      changeBillingField
    })

    provide('stripeProps', {
      collectCardPayment,
      confirmCardPayment,
      collectPayment,
      confirmPayment,
      confirmSetup,
      cardName,
      createPaymentMethod
    })

    watch(paymentMethodsList, (newVal) => {
      if (newVal.length === 1) {
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: billingForm?.value?.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        })
      }
    })

    watch(selectedPaymentMethod, (newVal) => {
      if (store.state.primaryUser.billing) {
        if (newVal) {

          const currentBilling = store.state.primaryUser.billing.find(bil => bil.paymentMethodId === newVal);

          if (currentBilling) {
            if (router.currentRoute.value.params.source !== 'admin-users') {
              for (let prop in currentBilling) {
                if ((prop !== 'mode') && (prop !== 'submitted')) {
                  billing[prop] = currentBilling[prop];
                }
              }
            }
          }
        }
      }
    });

    watch(() => store.state.authenticated.paymentMethods, (newVal) => {

      const currentPayments = [...newVal].reverse();

      if ((selectedPaymentMethod.value === null) && (currentPayments.length > 0)) {
        selectedPaymentMethod.value = currentPayments[currentPayments.length - 1].id;
      }
      /*
      if (newVal.length > oldVal.length) {
        for (let prop in defaultBilling) {
          billing[prop] = defaultBilling[prop];
        }
        billingEditionMode.value = 'create';
        popups.billing.active = true;
        
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: billingForm?.value?.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        });

        setTimeout(() => {
          popups.billing.active = false;
        }, 5100)
      }
      */
    }, { deep: true });

    const redirectToSource = () => {

      setTimeout(() => {
        store.dispatch('savePaymentMethod', {
          payment: paymentMethods[appliedPayment.value], 
          selectedPayment: null
        });
      })

      if (router.currentRoute.value.params.source) {

        if (router.currentRoute.value.params.source === 'user-subscription') {
          router.push({
            name: 'page', 
            params: {
              pagename: 'user-subscription',
              source: 'admin-subscription'
            }
          });
          return;
        }

        if (router.currentRoute.value.params.source === 'secondary-user') {
          router.push({
            name: 'page', 
            params: {
              pagename: 'secondary-user',
              boughtForLabor: true,
              source: 'admin-subscription',
              parentSource: router.currentRoute.value.params.parentSource
            }
          });
          return;
        }

        if (router.currentRoute.value.params.source === 'admin-account') {
          router.push({
            name: 'admin', 
            params: {
              pagename: 'admin-account',
              source: 'admin-subscription',
              boughtForAdmin: true
            }
          });
          return;
        }

        if (router.currentRoute.value.params.source === 'admin-users') {
          router.push({
            name: 'admin', 
            params: {
              pagename: 'admin-users',
              source: 'admin-subscription',
              boughtForLabor: true
            }
          });
          return;
        }

        router.push({
          name: 'admin', 
          params: {
            pagename: 'admin-subscription',
            source: 'admin-payment'
          }
        })

      } else {
        return;
      }
    }

    watch(store.state.authenticated, (newVal) => {
      if ((typeof newVal.selectedPayment === 'number') && 
        store.state.primaryUser.billing) {

        // popups.purchased = true;

        paymentWasApplied.value = true;
        // setTimeout(() => {
          // popups.purchased = false
          // redirectToSource()
        // }, 10000)
      }
    })


    watch(store.state.primaryUser, (newVal) => {
      if (newVal.billing) {
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: paymentForm?.value?.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        })
        if (typeof store.state.authenticated.selectedPayment === 'number' && paymentWasApplied.value) {
          // popups.purchased = true;
          // setTimeout(() => {
          //   popups.purchased = false;
          //   // redirectToSource();
          // }, 10000)
        }
      }
    })

    onMounted(() => {
      currentRouteSource.value = router.currentRoute.value.params.source

      if (paymentMethodsList.value.length > 0) {
        paymentEditionMode.value = 'represent'
      }

      if (store.state.primaryUser.billing) {
        if (store.state.primaryUser.billing.length > 0) {
          for (let prop in billing) { 
            changeBillingField(store.state.primaryUser.billing[0][prop], prop);
            billingEditionMode.value = 'represent';
          }
        }
      }

      console.log(router.currentRoute.value.params.source, 'router.currentRoute.value.params.source');

      // if (
      //   (router.currentRoute.value.params.source === 'user-subscription') ||
      //   (router.currentRoute.value.params.source === 'secondary-user') ||
      //   (router.currentRoute.value.params.source === 'admin-users')
      // ) {
      if (router.currentRoute.value.params.source) {
        if (typeof store.state.authenticated.selectedPayment !== 'number') {
          if (router.currentRoute.value.params.source !== 'admin-users') {
            popups.assign.active = true;

            setTimeout(() => {
              popups.assign.active = false
            }, 5000);
          }
        }
      }

      // if ((paymentMethodsList.value.length > 0) && 
      //   store.state.primaryUser.billing) {
      //   redirectToSource();
      // }

      if (store.state.authenticated.paymentMethods.length > 0) {
        const currentPayments = [...store.state.authenticated.paymentMethods].reverse();

        if ((selectedPaymentMethod.value === null) && (currentPayments.length > 0)) {
          selectedPaymentMethod.value = currentPayments[currentPayments.length - 1].id;
        }

        if (store.state.primaryUser.billing) {
          const currentBilling = store.state.primaryUser.billing.find(bil => bil.paymentMethodId === selectedPaymentMethod.value);

          for (let prop in currentBilling) {
            if ((prop !== 'mode') && (prop !== 'submitted')) {
              billing[prop] = currentBilling[prop];
            }
          }
        }
      }

      // if (router.currentRoute.value.params.source === 'admin-users') {
      //   paymentEditionMode.value = 'create';
      //   billingEditionMode.value = 'create';
      //   for (let prop in billing) {
      //     if ((prop !== 'mode') && (prop !== 'submitted')) {
      //       billing[prop] = '';
      //     }
      //   }
      // }
      console.log(store.state.primaryUser.billing, 'store.state.primaryUser.billing', selectedPaymentMethod, 'selectedPaymentMethod');

    })

    return {
      paymentEditionMode,
      billingEditionMode,
      billingForm,
      paymentForm,
      popups,
      redirectToSource,
      submitAssigned,
      currentRouteSource
    }
  },
};
</script>

<style lang="scss" scoped>
.notify-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  .notify-message {
    background: #fff;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 100%;
    height: max-content;
    justify-content: space-around;
    align-items: center;
    position: relative;
    border-radius: 11px;
    color: rgba(40,41,43,1);
    font-size: 21px;
    margin: 0 20px;
    padding: 25px 0;
    text-align: center;
    .close-button {
      position: absolute;
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 30px;
      height: 30px;
      background-image: url('@/assets/profile/close_button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      right: 10px;
      top: 22px;
      cursor: pointer;
    }
    .notify-text {
      margin: 0 40px;
      font-size: 16px;
      font-weight: 600;
    }
    .notify-buttons {
      display: flex;
      width: 60%;
      justify-content: space-evenly;
      margin-top: 30px;
      button {
        width: 160px;
        height: 40px;
        border-radius: 10px;
        &.cancel-removal-button,
        &.cancel-removal-button {
          border: 2px solid #38AC89;
        }
        &.approve-button {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>