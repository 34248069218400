<template>

<div class="dashboard-slide">
<div class="dashboard-slide-header">
<h5>{{farmData.name}}, {{farmData.city}} {{farmData.state}}</h5>
</div>
<div class="dashboard-slide-content">
<div class="location-map">
  <!-- <GMapMap
    :center="center"
    :zoom="14"
    map-type-id="terrain"
    style="height: 330px"
  >
    <GMapCluster>
      <GMapMarker
        v-for="(m, index) in markers"
        :key="index + 'gmap-marker'"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="center=m.position"
      />
    </GMapCluster>
  </GMapMap> -->
  <div :key="'google-maps-coords' + center.lat">
    <GoogleMap
      style="width: 100%; height: 330px"
      :center="center"
      :zoom="14"
      api-key="AIzaSyBbcyLxD6mUprwWy25BtgJSiNxFbJo7XLk"
    >
      <Marker 
        v-for="(m, index) in markers"
        :key="index + 'gmap-marker'"
        @click="center=m.position"
        :options="{ position: m.position }" 
      />
    </GoogleMap>
  </div>
</div>
</div>
</div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { GoogleMap, Marker } from 'vue3-google-map'

export default {
  name: 'DashboardLocation',
  props: ['farmCoords', 'farmName'],
  components: {GoogleMap, Marker},
  setup(props) {
    const store = useStore();

    const apiKey = ref(process.env.GOOGLE_MAPS_API_KEY)
    const geoLocationCoords = ref({});

    const farmData = ref({...props.farmName});

    const center = ref({
      // lat: 40.689247, lng: -74.044502
    }); //lat: 51.093048, lng: 6.842120

    const markers = ref([
      {
        position: {
          // lat: 40.689247, lng: -74.044502
          // lat: 51.093048, lng: 6.842120
        },
      }
      , // Along list of clusters
    ]);

    watch(geoLocationCoords, (newVal) => {
      if (newVal.latitude && newVal.longitude) {
        center.value = {
          lat: newVal.latitude,
          lng: newVal.longitude
        }

        markers.value = [
          {
            position: {
              lat: newVal.latitude,
              lng: newVal.longitude
            }
          }
        ]
      }
    });

    watch(() => ({...store.state.geoLocationCoords}), (newVal) => {
      if (!geoLocationCoords.value.latitude) {
        geoLocationCoords.value = {
          ...newVal
        }
      }
    });

    onMounted(() => {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          console.log(position.coords.latitude, position.coords.longitude);
          store.dispatch('provideCoordinates', {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        });
      }

      setTimeout(() => {
        geoLocationCoords.value = props.farmCoords.latitude ? 
        {
          ...props.farmCoords
        } : {
          ...store.state.geoLocationCoords
        };
      }, 300)

    })

    return {
      center,
      markers,
      farmData,
      apiKey
    }
  }
}
</script>

<style></style>