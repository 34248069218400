<template>
  <ReportsHeader />
  <LaborExpenses />
  <InventoryExpenses />
  <EquipmentExpenses />
  <ServicingExpenses />
  <AdministrativeExpenses />
  <TotalExpenses />
</template>

<script>
import { defineComponent } from 'vue'; //, ref, onMounted, inject, watch, reactive, provide
import ReportsHeader from './ReportsHeader.vue';
import LaborExpenses from './LaborExpenses.vue';
import InventoryExpenses from './InventoryExpenses.vue';
import AdministrativeExpenses from './AdministrativeExpenses.vue';
import EquipmentExpenses from './EquipmentExpenses.vue';
import ServicingExpenses from './ServicingExpenses.vue';
import TotalExpenses from './TotalExpenses.vue';
// import { useStore } from 'vuex';
// import DropDownList from '@/components/Shared/DropDownList.vue';


export default defineComponent({
  name: 'ReportsExpenses',
  setup() {
  },
  components: { 
    ReportsHeader,
    LaborExpenses,
    InventoryExpenses,
    AdministrativeExpenses,
    EquipmentExpenses,
    ServicingExpenses,
    TotalExpenses
  }
});
</script>