const pagesList = [
  {
    name: "UserProfile",
    header: "Profile",
  },
  {
    name: "SecondaryUser",
    header: "Add User",
    parent: 'Labor'
  },
  {
    name: "LaborModule",
    header: "Labor",
    parent: 'Labor'
  },
  {
    name: "RevenueModule",
    header: "Revenue",
    parent: 'Labor'
  },
  {
    name:"FarmProfile",
    header: "Farm Profile",
    parent: "Farm"
  },
  {
    name:"FarmPlan",
    header: "Farm Plan",
    parent: "Farm"
  },
  {
    name:"TaskModule",
    header: "Task",
    parent: "Task"
  },
  {
    name:"CompletedTasks",
    header: "Completed Tasks",
    parent: "Task"
  },
  {
    name:"InventoryModule",
    header: "Inventory",
    parent: "Inventory"
  },
  {
    name:"PlanInventory",
    header: "Plan Inventory",
    parent: "Inventory"
  },
  {
    name: "VendorModule",
    header: "Vendor",
    parent: "Vendor"
  },
  {
    name: "ArchivedVendors",
    header: "Archived",
    parent: "Vendor"
  },
  {
    name: "EquipmentModule",
    header: "Equipment",
    parent: "Equipments"
  },
  {
    name: "EquipmentService",
    header: "Equipment Service",
    parent: "Equipments"
  },
  {
    name:"PlanCrop",
    header: "Planned Crops",
    parent: "Crops"
  },
  {
    name:"HarvestModule",
    header: "Harvest",
    parent: "Crops"
  },
  {
    name: "AdminSettings",
    header: "Admin Settings",
  },
  {
    name: "AdminAccount",
    header: "Admin Account",
    parent: "Admin"
  },
  {
    name: "AdminUsers",
    header: "Admin Users",
    parent: "Admin"
  },
  {
    name: "AdminSubscription",
    header: "Admin Subscription",
    parent: "Admin"
  },
  {
    name: "AdminPayment",
    header: "Admin Payment",
    parent: "Admin"
  },
  {
    name: "SalesModule",
    header: "Sales",
    parent: "Sales"
  },
  {
    name: "CustomersModule",
    header: "Customers",
    parent: "Customers"
  },
  {
    name: "LaborExpenses",
    header: "Labor Expenses",
    parent: "Admin"
  },
  {
    name: "AdminExpenses",
    header: "Admin Expenses",
    parent: "Admin"
  },
  {
    name: "EmployeeCompensation",
    header: "Employee Compensation",
    parent: "Admin"
  },
  {
    name: "SearchModule",
    header: "Search",
    parent: 'Search'
  },
  {
    name: "SuperAdmin",
    header: "Super Admin",
    parent: 'SuperAdmin'
  },
  {
    name: "SuperAdminArchivedList",
    header: "Archived Accounts",
    parent: 'SuperAdmin'
  },
  {
    name: "AdvertisementModule",
    header: "Advertisement",
    parent: "Admin"
  },
  {
    name: "AdvertisementAccount",
    header: "Advertisement Account",
    parent: "Advertisement"
  },
  {
    name: "ReportsModule",
    header: "Reports Sales",
    parent: "Reports"
  },
  {
    name: "ReportsCrops",
    header: "Reports Crops",
    parent: "Reports"
  },
  {
    name: "ReportsExpenses",
    header: "Reports Expenses",
    parent: "Reports"
  }
];

export default pagesList;
