<template>
  <SalesHeader />
  <section class="section">
    <div class="border-box">
      <!-- <SalesForm v-if="((editionMode === 'edit') || (editionMode === 'create')) && (isAuth('edit-sales') || isAuth('add-sales'))" /> -->
      <SalesFilled  v-if="editionMode === 'represent'" />
      <SalesList />
    </div>
  </section>
</template>

<script>
import { defineComponent, ref, reactive, provide } from 'vue';
import { useStore } from "vuex";
import SalesHeader from './SalesHeader.vue';
// import SalesForm from './SalesForm.vue';
import SalesFilled from './SalesFilled.vue';
import SalesList from './SalesList.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: "SalesModule",
    setup() {
      const store = useStore();

      const router = useRouter();

      // const { isAuth } = inject('mainUI');

      const editionMode = ref('');
      // const editionMode = ref('represent');

      const sales = reactive({
        id: '',
        date: '',
        farm: '',
        bed: '',
        cropCategory: '',
        crop: '',
        season: '',
        cropCycle: '',
        variety: '',
        // customerType: '',
        customer: '',
        quantitySold: '',
        rate: '',
        total: '',
        comments: ''
      });

      const customer = reactive({
        id: '',
        type: '',
        customer: '',
        contact: '',
        workPhone: '',
        mobile: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        comments: ''
      });

      const selectedSales = reactive({
        ...sales
      });

      const defaultSales = {
        ...sales
      }

      const bedsOptions = ref([]);

      const addSales = () => {
        selectSales({...defaultSales}, 'create');
        router.push({
          name: 'customers',
          params: {
            pagename: 'customers-module',
            source: 'sales-module'
          }
        })
        // editionMode.value = 'create';
      }

      const editSales = (field, val) => {
        sales[field] = val;
      }

      const selectSales = (salesData, editMode) => {
        if (salesData) {
          for (let field in salesData) {
            sales[field] = salesData[field]
            selectedSales[field] = salesData[field]
          }
        }
        editionMode.value = editMode;
      }

      const removeSales = (salesId) => {
        store.dispatch('removeSales', salesId)
      };

      provide('customerDetails', {
        customer
      })

      provide('salesDetails', {
        sales,
        defaultSales,
        selectedSales,
        editionMode,
        addSales,
        editSales,
        selectSales,
        removeSales,
        options: bedsOptions
      })

      return {
        editionMode,
        // isAuth,
        sales
      }
    },
    components: { 
      SalesHeader, 
      // SalesForm, 
      SalesFilled, 
      SalesList 
    }
})
</script>

<style lang="scss" scoped></style>