<template>
    <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Admin Expense</h5>
<div class="icon-group">
  <!-- <div class="icon green icon-o camera-plus"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
  >
    <img src="@/assets/images/camera-plus.svg" alt="">
    <div class="helper-tooltip" v-if="helperTooltip">Take a shot</div>
  </div> -->
<div class="icon green icon-o top-icon tooltip"
  @click="selectAdminExpense({...adminExpense}, 'edit');"
  @mouseover="switchEditHelperTooltip(true)"
  @mouseout="switchEditHelperTooltip(false)"
  data-tooltip="Edit"
>
  <img src="@/assets/images/updated/Edit.png" alt="">
  <!-- <div class="edit-helper-tooltip" v-if="editHelperTooltip">Edit Expense</div> -->
</div>
<div class="icon green icon-o top-icon tooltip"
  @click="addAdminExpense"
  @mouseover="switchAddHelperTooltip(true)"
  @mouseout="switchAddHelperTooltip(false)"
  data-tooltip="Add Expense"
>
  <img src="@/assets/images/updated/add-item.png" alt="">
  <!-- <div class="add-helper-tooltip" v-if="addHelperTooltip">Add Admin Expense</div> -->
</div>
</div>
</div>

<div class="content-block d-lg-flex justify-content-between">
<div class="checkbox-group task-type">
<p class="fw-semibold gray">Expense Type:</p>
<div class="radio"><input type="radio" name="taskType" id="regular" value="Salary"  v-model="adminExpense.expenseType" disabled><label for="regular">Salary</label></div>
<div class="radio"><input type="radio" name="taskType" id="overtime" value="Other" v-model="adminExpense.expenseType" disabled><label for="overtime">Other</label></div>
</div>
</div>

<div class="content-block">
<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Date</p>
<h6>{{ adminExpense.date ? moment(adminExpense.date, 'YYYY-MM-DD').format('MM/DD/YYYY') : '' }}</h6>
</div>
       
<div class="intro-grid-column">
<p class="title">Month</p>
<h6>{{ adminExpense.month }}</h6>
</div>

<template v-if="adminExpense.expenseType === 'Salary'">
  <div class="intro-grid-column">
  <p class="title">Employee</p>
  <h6>{{ adminExpense.employee }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Grade</p>
  <h6>{{ adminExpense.grade }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Salary</p>
  <h6>{{ `$${Number.parseInt(adminExpense.salary).toFixed(2)}` }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Medical Benefits</p>
  <h6>{{ adminExpense.medicalBenefits }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Bonus</p>
  <h6>{{ adminExpense.bonus }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Other</p>
  <h6>{{ adminExpense.other }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Task Status</p>
  <h6>{{ adminExpense.taskStatus }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Cost Center</p>
  <h6>{{ adminExpense.costCenter }}</h6>
  </div>

</template>

<template v-if="adminExpense.expenseType === 'Other'">
  
  <div class="intro-grid-column">
  <p class="title">Category</p>
  <h6>{{ adminExpense.category }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Vendor</p>
  <h6>{{ adminExpense.vendor }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Invoice #</p>
  <h6>{{ adminExpense.invoiceNumber }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Amount</p>
  <h6>{{ `$${Number.parseInt(adminExpense.amount).toFixed(2)}` }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Task Status</p>
  <h6>{{ adminExpense.taskStatus }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Payment Type</p>
  <h6>{{ adminExpense.paymentType }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Cost Center</p>
  <h6>{{ adminExpense.costCenter }}</h6>
  </div>
  
</template>

<div class="intro-grid-column intro-grid-comment-column">
<p class="title">Comments</p>
<h6>{{ adminExpense.comments }}</h6>
</div>

</div>
</div>

<div class="content-block">

  <!-- <FileAttachments v-bind:section="'adminExpenses'" /> -->
  <FileAttachments :key="`admin-expense-filled-${adminExpense.id}`" v-bind:filled="true" v-bind:section="'adminExpense'" v-bind:instance="`${adminExpense.id}`" />


</div>

</div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { defineComponent, inject, ref } from 'vue';
import moment from 'moment';

export default defineComponent({
    name: "AdminExpensesFilled",
    components: { FileAttachments },
    setup() {
      const { 
        adminExpense,
        addAdminExpense,
        selectAdminExpense
      } = inject('adminExpenseDetails');

      const editHelperTooltip = ref(false);
      const helperTooltip = ref(false);
      const addHelperTooltip = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const switchEditHelperTooltip = (toggle) => {
        editHelperTooltip.value = toggle;
      }

      const switchAddHelperTooltip = (toggle) => {
        addHelperTooltip.value = toggle;
      }  
      
      return {
        adminExpense,
        addAdminExpense,
        selectAdminExpense,
        editHelperTooltip,
        helperTooltip,
        addHelperTooltip,
        switchHelperTooltip,
        switchEditHelperTooltip,
        switchAddHelperTooltip,
        moment
      }
    }
})
</script>

<style lang="scss" scoped></style>