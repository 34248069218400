<template>
  <div class="">
    Privacy Policy
  </div>
</template>

<script>

export default {
  name: "PrivacyPolicy"
};
</script>

