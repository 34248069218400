<template>
  <section class="section mt-1">
<div class="border-box">
<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Advertisement Questionnaire</h5>
</div>

<div class="content-block">

<div class="create-advertisement">
<div class="content-block">

<div class="form-item">
<p class="form-label" :class="{active: focused.date || (advertisementContent.date.length > 0)}">Date</p>
<input type="date" class="form-control"
 v-model="advertisementContent.date"
 :style="{color: (focused.date || advertisementContent.date.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
  @focus="() => {
    activated.date = true;
    focused.date = true;
  }"
  @blur="() => {
    focused.date = false;
  }"
  placeholder="Select date"
>
<div class="date-icon"></div>
 <p v-if="activated.date && advertisementContent.date.length === 0" class="form-info red text-end">* Required</p>
</div>

<div class="form-item">
<p class="form-label" :class="{active: focused.campaignTitle || (advertisementContent.campaignTitle.length > 0)}">Campaign Title</p>
<input type="text" class="form-control" 
  v-model="advertisementContent.campaignTitle"
  @focus="() => {
    activated.campaignTitle = true;
    focused.campaignTitle = true;
  }"
  @blur="() => {
    focused.campaignTitle = false;
  }"
  placeholder="August Sale"
/>
<p v-if="activated.campaignTitle && advertisementContent.campaignTitle.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.campaignTitle && advertisementContent.campaignTitle.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>

<div class="form-item">
<p class="form-label">Upload Image</p>
<div class="upload">
<input 
  id="upload"
  type="file"
  name="advbackgroundupload"
  class="background-file"
  ref="uploadadvbg"
  accept=".jpg,.png,.jpeg"
  @change="uploadImgDirect"
/>
<label for="upload"><img src="@/assets/images/img-icon.svg" alt="">Upload Image</label>
<div v-if="profileBgImg" class="uploaded-ad-preview">
  <img :src="profileBgImg" alt="">
</div>
</div>
</div>

<div class="form-item">
<p class="form-label" :class="{active: focused.url || (advertisementContent.url.length > 0)}">Add URL (Landind Page)</p>
<input type="text" class="form-control" 
  v-model="advertisementContent.url"
  @focus="() => {
    activated.url = true;
    focused.url = true;
  }"
  @blur="() => {
    focused.url = false;
  }"
  placeholder="https://www.fiverr.com/"
/>
<p v-if="activated.url && advertisementContent.url.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.url && advertisementContent.url.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>

</div>

<div class="content-block">

  <ckeditor :editor="ClassicEditor" v-model="editorData" :config="editorConfig"></ckeditor>
  <div v-if="textLimit > 75" class="form-info red text-end ckeditor-warn">
    Please use maximum 75 characters
  </div>
  <div v-if="textLimit === 0" class="form-info red text-end ckeditor-warn">
    Please use insert some text
  </div>

</div>

<div class="content-block">
<div class="editor-buttons">
<button type="submit" class="btn light-blue-20 btn-o w-sm" @click="draftForm">Draft</button>
<button type="submit" class="btn green w-sm" @click="saveForm">Save</button>
<button type="submit" class="btn green w-sm" @click="previewForm">Preview</button>
<button type="submit" class="btn green w-sm" @click="switchFormToNext">Next</button>
      
</div>
</div>

</div>

</div>

</div>

</div>
</section>
</template>

<script>
import { defineComponent, reactive, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-custom';
import moment from 'moment';

export default defineComponent({
  name: 'AdvertisementQuestionnaireSecond',
  components: {
    ckeditor: CKEditor.component
  },
  setup() {

    const store = useStore();
    const router = useRouter();
    
    const editorConfig = ref({
      wordCount: {
        onUpdate: stats => {
          textLimit.value = stats.characters;
        }
      },
      fontColor: {
        colorPicker: {
          format: 'hex'
        }
      },
      fontBackgroundColor: {
        colorPicker: {
          format: 'hex'
        }
      },

      toolbar: [ 'bold', 'italic', 'bulletedList', 'fontColor', 'fontBackgroundColor', 'undo', 'redo']
    });

    const editorData = ref('<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>');

    const imageFile = ref(null);

    const profileBgImg = ref(null);

    const textLimit = ref(0);

    const activated = reactive({
      date: false,
      campaignTitle: false,
      url: false
    });

    const focused = reactive({
      date: false,
      campaignTitle: false,
      url: false
    });

    const advertisementContent = reactive({
      date: '',
      campaignTitle: '',
      url: ''
    });

    const uploadImgDirect = (ev) => {
      console.log(ev);

      imageFile.value = ev.target.files[0]

      createPreview(ev);
    };

    const createPreview = (e) => {
      const reader = new FileReader();

      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
      reader.onload = function () {
        setTimeout(() => {
          profileBgImg.value = `${reader.result}`;
        }, 500);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    }

    const draftForm = () => {};

    const saveForm = () => {

      if ((textLimit.value > 75) || (textLimit.value === 0) || 
      (advertisementContent.campaignTitle > 50) || !advertisementContent.campaignTitle ||
      !advertisementContent.date || !advertisementContent.url) {
        if (!advertisementContent.campaignTitle) activated.campaignTitle = true;
        if (!advertisementContent.date) activated.date = true;
        if (!advertisementContent.url) activated.url = true;
        
        return;
      } 

      store.dispatch("uploadMedia", {
        file: imageFile.value,
        place: "backgroundAdvertisement",
      });
      // createPreview(ev);

      store.dispatch('saveAdvertisementAccount', {
        section: 'advertisementContent', 
        data: {
          ...advertisementContent,
          text: editorData.value,
          adImage: profileBgImg.value
        }
      });
    };

    const previewForm = () => {

      saveForm();

      if ((textLimit.value > 75) || (textLimit.value === 0) || 
      (advertisementContent.campaignTitle > 50) || !advertisementContent.campaignTitle ||
      !advertisementContent.date || !advertisementContent.url) {
        if (!advertisementContent.campaignTitle) activated.campaignTitle = true;
        if (!advertisementContent.date) activated.date = true;
        if (!advertisementContent.url) activated.url = true;
        
        return;
      } 

      router.push({
        name: 'advertisement', 
        params: { 
          pagename: 'advertisement-preview' 
        }
      });
    };

    const switchFormToNext = () => {

      if ((textLimit.value > 75) || (textLimit.value === 0) || 
      (advertisementContent.campaignTitle.length > 50) || !advertisementContent.campaignTitle ||
      !advertisementContent.date || !advertisementContent.url) {
        if (!advertisementContent.campaignTitle) activated.campaignTitle = true;
        if (!advertisementContent.date) activated.date = true;
        if (!advertisementContent.url) activated.url = true;
        
        return;
      } 

      saveForm();

      router.push({
        name: 'advertisement', 
        params: { 
          pagename: 'advertisement-payment' 
        }
      });
    };

    onMounted(() => {
      advertisementContent.date = moment().format('YYYY-MM-DD');

      if (store.state.advertisementAccount.advertisementContent) {
        for (let prop in advertisementContent) {
          advertisementContent[prop] = store.state.advertisementAccount.advertisementContent[prop];
          activated[prop] = true;
        }
        editorData.value = store.state.advertisementAccount.advertisementContent.text;
        // imageFile.value = store.state.fileUploads.backgroundAdvertisement
      }
    })

    return {
      activated,
      focused,
      advertisementContent,
      uploadImgDirect,
      ClassicEditor,
      editorConfig,
      editorData,
      profileBgImg,

      draftForm,
      saveForm,
      previewForm,
      switchFormToNext,
      textLimit
    }
  }
})
</script>

<style lang="scss">
.ck-toolbar_grouping {
  background: hsla(var(--light-blue), 20%);
}


.date-icon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 3.7rem;
  right: 1rem;
  z-index: 8;
  pointer-events: none;
  border-radius: 1rem;
  background:url('@/assets/images/calendar-icon.svg') center center no-repeat;
  background-color: #fff;
}

.form-item {
  .upload {
    position: relative;
  }
}

.uploaded-ad-preview {
  width: 14rem; 
  height:14rem; 
  border-radius:var(--radius-15); 
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.uploaded-ad-preview img{object-fit:cover; width:100%; height:100%; border-radius:var(--radius-15);}
.ckeditor-warn {
  margin-top: 10px;;
}
</style>