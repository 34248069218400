<template>
  <div class="section" ref="section">
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5>Payment Information</h5>
  <div class="info-icons">
    <div class="info-icon lg payment-tooltip-container tooltip"
      @click="addNewPayment"
      data-tooltip="Add Payment"
      @mouseover="switchHelperTooltip(true)"
      @mouseout="switchHelperTooltip(false)"
    >
      <!-- <div class="helper-tooltip" v-if="helperTooltip">Add Payment</div> -->
      <!-- <img src="@/assets/images/plus-green-md.png" alt=""> -->
      <!-- <img src="@/assets/images/updated/059-add.png" alt=""> -->
      <img src="@/assets/images/updated/048-credit-card-3.png" alt="">
    </div>
  </div>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>

  <div class="border-box-content">

  <!-- <div class="content-block d-flex justify-content-between">
  <h5 class="fw-semibold">Payment Information</h5>
  <div class="icon-group">
    <div class="info-icon lg payment-tooltip-container"
      @click="addNewPayment"
      @mouseover="switchHelperTooltip(true)"
      @mouseout="switchHelperTooltip(false)"
    >
      <div class="helper-tooltip" v-if="helperTooltip">Add Payment</div>
      <img src="@/assets/images/updated/059-add.png" alt="">
    </div>
  </div>
  </div> -->

  <div class="content-block">
    <div class="payment-details">

<div v-if="paymentMethods.length <= 0" class="payment-info-entry">
  <span>You have not set up any payment methods yet.</span>
  <span class="runs-out">Your payment method will only be charged after your 30 day subsription runs out.</span>
</div>

<template
  v-for="(paymentMethod, index) in paymentMethods"
  v-bind:key="'paymentmethod' + index"
>
  <template v-if="paymentMethod?.cardType">
    <div class="content-row payment-details-header">
    <div class="row">
    <div class="col-4">

    <p class="form-label-static mb-1" v-if="index === 0">Primary Payment</p>
    <p class="form-label-static" v-if="index === 1">Secondary Payment</p>

    <div class="card">
      <!-- <img :src="`@/assets/images/${paymentMethod.cardType}.png`" alt=""> -->
      <div class="selection-payment">
        <input
          type="checkbox"
          :id="`select-payment-${index}`"
          :checked="((index === 0) && !paymentMethod.id) || (paymentMethod.id === selectedPaymentMethod)"
          @change="selectPaymentMethod(paymentMethod.id)" 
        />
        <!-- (selectedPaymentMethod === paymentMethod.id) -->
        <!-- paymentMethod.selected ||  -->
        <label :for="`select-payment-${index}`"></label>
      </div>
      <div class="card-icon" :class="paymentMethod.cardType"></div>
      <h6>{{
        paymentMethod.cardType === 'mastercard' ? 
          `${paymentMethod.cardType[0].toUpperCase()}${paymentMethod.cardType.slice(1, 6)}${paymentMethod.cardType[6].toUpperCase()}${paymentMethod.cardType.slice(7)}` :
          `${paymentMethod.cardType[0].toUpperCase()}${paymentMethod.cardType.slice(1)}`
      }} {{paymentMethod.cardNumber}}</h6>
    </div>

    </div>
    <div class="col-4 align-self-end">
    <p class="form-label-static" v-if="index === 0">Apply</p>
    <div class="apply-list">
      <div class="apply-method">
        <input
          type="checkbox"
          :id="`apply-payment-${index}`"
          v-model="paymentMethod.applied"
          @change="(ev) => applyPaymentMethod(index, ev)" 
        />
        <label :for="`apply-payment-${index}`"></label>
        <!-- <img src="@/assets/images/checkbox-checked.svg" alt="" /> -->
      </div>
      <div
        @click="editPaymentMethod(index)"
        data-tooltip="Edit"
        @mouseover="switchEditHelperTooltip(true, index)"
        @mouseout="switchEditHelperTooltip(false, index)"
        class="edit-method tooltip"
      >
        <img src="@/assets/images/edit-icon.svg" alt="" />
        <!-- <div class="edit-helper-tooltip" v-if="editHelperTooltip && (index === currentTooltip)">Edit</div> -->
      </div>
      <div
        @click="() => removalApprove(index, paymentMethod.id)"
        data-tooltip="Remove"
        @mouseover="switchRemoveHelperTooltip(true, index)"
        @mouseout="switchRemoveHelperTooltip(false, index)"
        class="remove-method tooltip"
      >
        <img src="@/assets/images/trash-icon.svg" alt="" />
        <!-- <div class="remove-helper-tooltip" v-if="removeHelperTooltip && (index === currentTooltip)">Remove</div> -->
      </div>
    </div>
    </div>
    <div class="col-4 align-self-end">
    <p class="form-label-static" @click="() => switchDetails(index)">
      <a href="javascript:void();">Details</a>
    </p>
    </div>

    </div>
    </div>

    <div class="content-row" v-if="paymentMethod.detailsOpened">
      <template v-if="!paymentMethod.isEditable">
        <div class="row">
        <div class="col-3">
        <p class="form-label-static">Name on Card</p>
        <h6>{{ paymentMethod.cardHolderName }}</h6>
        </div>
        <div class="col-3">
        <p class="form-label-static">Card Number</p>
        <h6>**** **** **** {{ paymentMethod.cardNumber }}</h6>
        </div>
        <div class="col-3">
        <p class="form-label-static">Card Type</p>
        <h6>{{ paymentMethod.cardType }}</h6>
        </div>
        <div class="col-3">
        <p class="form-label-static">Expiry Date</p>
        <h6>{{ paymentMethod.expiryDate }}</h6>
        </div>
        <div class="col-3">
        <p class="form-label-static">3 Digit CVV Code</p>
        <h6>***</h6>
        </div>
        <div class="col-3">
        <p class="form-label-static">Zip Code</p>
        <h6>{{ paymentMethod.zip }}</h6>
        </div>
        </div>
      </template>

      <template v-else>
        <div class="row">
        <div class="col-3">
        <p class="form-label-static">Name on Card</p>
        <!--   :value="paymentMethod.cardHolderName"
          @input="event => payment.cardHolderName = event.target.value"   -->
        <input type="text" class="form-control"
          v-model="payment.cardHolderName"  
          @focus="() => {
            activated.cardHolderName = true;
            focused.cardHolderName = true;
          }"
          @blur="() => {
            focused.cardHolderName = false;
          }"
        >
        <p v-if="activated.cardHolderName && payment.cardHolderName.length === 0" class="form-info red text-end">* Required</p>
        </div>
        <div class="col-3">
        <p class="form-label-static">Card Number</p>
        <h6>**** **** **** {{ paymentMethod.cardNumber }}</h6>
        </div>
        <div class="col-3">
        <p class="form-label-static">Card Type</p>
        <h6>{{ paymentMethod.cardType }}</h6>
        </div>
        <div class="col-3">
        <p class="form-label-static">Expiry Date</p>
        <div class="calendar-field">
        <input type="text" class="form-control" id="datepicker"
          v-model="payment.expiryDate"
          @change="expiryDateInputAction"
          @focus="() => {
            activated.expiryDate = true;
            focused.expiryDate = true;
            expiryDateFocusAction();
          }"
          @blur="() => {
            focused.expiryDate = false;
            expiryDateBlurAction();
          }"
        >
        <div v-if="focused.expiryDate && (payment.expiryDate.length === 0)" class="expiry-date-placeholder">MM/YYYY</div>
        <input class="picked-up-expiry" type="date" pattern="\d{2}/\d{2}/\d{4}" v-model="pickeUpExpiryDate" />
        <p v-if="activated.expiryDate && payment.expiryDate.length === 0" class="form-info red text-end error">* Required</p>
        <p v-if="activated.expiryDate && payment.expiryDate.length > 7" class="form-info red text-end error">Please use MM/YYYY format</p>
        <img src="@/assets/images/calendar-icon.svg" alt="">
        </div>
        </div>
        <div class="col-3">
        <p class="form-label-static">3 Digit CVV Code</p>
        <h6>***</h6>
        </div>
        <div class="col-3">
        <p class="form-label-static">Zip Code</p>
        <input type="text" class="form-control" 
          v-model="payment.zip"
          @focus="() => {
            activated.zip = true;
            focused.zip = true;
          }"
          @blur="() => {
            focused.zip = false;
          }"
        >
        <p v-if="activated.zip && payment.zip.length === 0" class="form-info red text-end">* Required</p>
        <p v-if="activated.zip && (payment.zip.length > 0) && !(/^[0-9]{5}-[0-9]{3}.*/.test(payment.zip) || ((payment.zip.length <= 5) && /^[0-9]{5}.*/.test(payment.zip)))" class="form-info red text-end">Use 5 digits or #####-### formats</p>
        </div>
        </div>
        <div class="row">
          <div class="col-auto"><button type="submit" class="btn green" @click="() => submitPaymentForm(index)">Save</button></div>
          <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="() => cancelPaymentChanges(index)">Cancel</button></div>
        </div>
      </template>
    </div>
  </template>

  <template v-if="paymentMethod?.accountType">
    <div class="content-row payment-details-header">
    <div class="row">
    <div class="col-4">
      <p class="form-label-static mb-1" v-if="index === 0">Primary Payment</p>
      <p class="form-label-static" v-if="index === 1">Secondary Payment</p>
    <h6 class="form-label-static">Account Nickname</h6>
    <h6>{{paymentMethod.accountNickname}}</h6>
    </div>
    <div class="col-4 align-self-end">
    <p class="form-label-static">Apply</p>
    <div class="apply-list">
      <div class="apply-method">
        <input
          type="checkbox"
          :id="`apply-payment-sec-${index}`"
          v-model="paymentMethod.applied"
          @change="(ev) => applyPaymentMethod(index, ev)" 
        />
        <label :for="`apply-payment-sec-${index}`"></label>
      </div>
      <div
        @click="editPaymentMethod(index)"
        @mouseover="switchEditHelperTooltip(true, index)"
        @mouseout="switchEditHelperTooltip(false, index)"
        class="edit-method"
      >
        <img src="@/assets/images/edit-icon.svg" alt="" />
        <div class="edit-helper-tooltip" v-if="editHelperTooltip && (index === currentTooltip)">Edit</div>
      </div>
      <div
        @click="() => removalApprove(index)"
        @mouseover="switchRemoveHelperTooltip(true, index)"
        @mouseout="switchRemoveHelperTooltip(false, index)"
        class="remove-method"
      >
        <img src="@/assets/images/trash-icon.svg" alt="" />
        <div class="remove-helper-tooltip" v-if="removeHelperTooltip && (index === currentTooltip)">Remove</div>
      </div>
    </div>
    </div>
    <div class="col-4 align-self-end">
    <p class="form-label-static" @click="() => switchDetails(index)">
      <a href="javascript:void();">Details</a>
    </p>
    </div>
    </div>
    </div>

    <div class="content-row"  v-if="paymentMethod.detailsOpened">
      <div class="row">
      <div class="col-3">
      <p class="form-label-static">Name on Account</p>
      <h6>{{ paymentMethod.accountName }}</h6>
      </div>
      <div class="col-3">
      <p class="form-label-static">Account Type</p>
      <h6>{{ paymentMethod.accountType }}</h6>
      </div>
      <div class="col-3">
      <p class="form-label-static">Routing No.</p>
      <h6>{{ paymentMethod.routing }}</h6>
      </div>
      <div class="col-3">
      <p class="form-label-static">Re-enter Routing No.</p>
      <h6>{{ paymentMethod.routing }}</h6>
      </div>
      <div class="col-3">
      <p class="form-label-static">Account No.</p>
      <h6>{{ paymentMethod.accountNumber }}</h6>
      </div>
      <div class="col-3">
      <p class="form-label-static">Re-enter Account No.</p>
      <h6>{{ paymentMethod.accountNumber }}</h6>
      </div>
      </div>
    </div>
      </template>
    </template>

    </div>
  </div>

  <div class="removal-popup" v-if="paymentRemovalIndex.removal">

    <div class="modal modal-md">
    <span class="modal-close" @click="() => removalApprove(null)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
    <div class="modal-block text-center">
    <p class="fw-bold">Are you sure you want to remove this payment method?</p>
    </div>
    <div class="modal-block text-center">
    <div class="buttons justify-content-center">
        <button
          @click="() => removePayment()"
          class="approve-button btn green" 
        >Yes</button>
        <button
          @click="() => removalApprove(null)" 
          class="cancel-removal-button btn green btn-o" 
        >Cancel</button>
    </div>
    </div>
    </div>

    <!-- <div class="removal-message">
      <div class="close-button" @click="() => removalApprove(null)" ></div>
      <span class="removal-text">
        Are you sure you want to remove this payment method
      </span>
      <div class="removal-buttons">
        <button
          @click="() => removePayment()"
          class="approve-button btn green" 
        >Yes</button>
        <button
          @click="() => removalApprove(null)" 
          class="cancel-removal-button btn light-blue-20 btn-o" 
        >Cancel</button>
      </div>
    </div> -->

  </div>
  
  <!-- <div class="prompt-popup" v-if="paymentRemovalIndex.prompt">
    <div class="prompt-message">
      <span class="prompt-text">
        Are you sure you want to {{ paymentRemovalIndex.prompt }} payment method
      </span>
      <div class="prompt-buttons">
        <button
          @click="() => approveApplyingPayment(true)"
          class="approve-button btn green" 
        >Yes</button>
        <button
          @click="() => approveApplyingPayment(null)" 
          class="cancel-prompt-button btn light-blue-20 btn-o" 
        >Cancel</button>
      </div>
    </div>
  </div> -->

</div>
</div>
</template>

<script>
import moment from 'moment';
import { 
  defineComponent, computed, inject, 
  reactive, ref, watch, onMounted 
} from 'vue';
import { useStore } from "vuex";

export default defineComponent({
  name: 'AdminPaymentFilled',
  setup() {
    const { 
      payment, 
      defaultPayment,
      selectedPayment, 
      changePaymentField,
      paymentEditionMode,
      appliedPayment,
      popups,
      selectedPaymentMethod
    } = inject('payment');

    const {
      billingEditionMode,
      billing,
      defaultBilling,
    } = inject('billing');
    
    const store = useStore();

    const activated = reactive({
      cardHolderName: false,
      expiryDate: false,
      zip: false
    })
    const focused = reactive({
      cardHolderName: false,
      expiryDate: false,
      zip: false
    })

    const paymentMethodsList = computed(() => store.state.authenticated.paymentMethods);

    const paymentMethods = //computed(() => store.state.authenticated.paymentMethods);
    ref([
      ...paymentMethodsList.value.map(method => {
        return {
          ...method,
          detailsOpened: false,
          applied: false,
          isEditable: false
        }
      })
    ]);

    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);

    const currentTooltip = ref(null);

    const paymentMethodIsEditable = ref(false);

    const pickeUpExpiryDate = ref(null);

    const helperTooltip = ref(false);
    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle;
    }

    const editHelperTooltip = ref(false);
    const switchEditHelperTooltip = (toggle, index) => {
      currentTooltip.value = index;
      editHelperTooltip.value = toggle;
    }
    
    const removeHelperTooltip = ref(false);
    const switchRemoveHelperTooltip = (toggle, index) => {
      currentTooltip.value = index;
      removeHelperTooltip.value = toggle;
    }

    const paymentRemovalIndex = reactive({
      removal: false,
      paymentIndex: null,
      prompt: '',
      paymentId: null
    });

    const expiryDateFocusAction = (ev) => {
      if (ev) {
        ev.target.placeholder = "MM/YYYY";
      }
    };

    const expiryDateBlurAction = (ev) => {
      if (ev) {
        ev.target.placeholder = "Expiry Date";
      }
    };

    const removalApprove = (indx, paymentId) => {
      if ((typeof indx === 'number') || paymentId) {
        paymentRemovalIndex.removal = true;
        paymentRemovalIndex.paymentIndex = indx;
        paymentRemovalIndex.paymentId = paymentId;
      } else {
        paymentRemovalIndex.removal = false;
        paymentRemovalIndex.paymentIndex = null;
      }
    }

    const removePayment = () => {
      const paymentId = paymentRemovalIndex.paymentId;
      store.dispatch('removePaymentMethod', paymentId);
      // store.dispatch('removeAccount', parseInt(paymentRemovalIndex.paymentIndex));
      // paymentMethods.value.splice(parseInt(paymentRemovalIndex.paymentIndex), 1)
      paymentRemovalIndex.removal = false;
      paymentRemovalIndex.paymentIndex = null;
      paymentRemovalIndex.paymentId = null;
    }

    const approveApplyingPayment = (approval) => {
      if (approval) {
        // if (paymentRemovalIndex.prompt === 'apply') {
          // store.dispatch('savePaymentMethod', {payment: {}, selectedPayment: -1});
          // store.dispatch('savePrimaryUser', {section: 'payment', data: paymentMethods.value[appliedPayment.value]});
        // } else {
          // store.dispatch('savePaymentMethod', {payment: {}, selectedPayment: -1});
        // }
        // paymentRemovalIndex.prompt = ''
      } else {
        if (paymentRemovalIndex.prompt === 'apply') {
          applyPaymentMethod(null);
          paymentMethods.value.forEach((pMethod, index) => {
            pMethod.applied = (index === store.state.authenticated.selectedPayment);
          })
        } else {
          console.log(appliedPayment.value);
          applyPaymentMethod(appliedPayment.value)
          paymentMethods.value.forEach((pMethod, index) => {
            pMethod.applied = (index === appliedPayment.value);
          })
        }
      }
    }

    const switchDetails = (index) => {
      console.log(paymentMethods.value[index]);
      paymentMethods.value[index].detailsOpened = !paymentMethods.value[index].detailsOpened;
    }

    // const editPaymentMethod = (index) => {
    //   for (let prop in paymentMethods.value[index]) {
    //     payment[prop] = paymentMethods.value[index][prop];
    //   }
    //   selectedPayment.value = index;
    //   paymentEditionMode.value = 'edit'
    // }

    const editPaymentMethod = (index) => {
      if (paymentMethods.value[index].isEditable) {
        return;
      }
      for (let prop in paymentMethods.value[index]) {
        payment[prop] = paymentMethods.value[index][prop];
      }
      selectedPayment.value = index;
      // changePaymentField('edit', 'mode')
      paymentMethods.value[index].detailsOpened = true;
      paymentMethods.value[index].isEditable = true;
    }

    const submitPaymentForm = (index) => {
      store.dispatch('changePaymentMethodDetails', {
        data: {
          ...payment
        }
      });
      paymentMethods.value[index].isEditable = false;
    }

    const cancelPaymentChanges = (index) => {
      paymentMethods.value[index].isEditable = false;
      payment.id = defaultPayment.id;
      payment.cardHolderName = defaultPayment.cardHolderName;
      payment.expiryDate = defaultPayment.expiryDate;
      payment.zip = defaultPayment.zip;
    }

    const addNewPayment = () => {
      selectedPayment.value = null;
      paymentEditionMode.value = 'create';
      for (let prop in billing) {
        billing[prop] = defaultBilling[prop];
      }
      billingEditionMode.value = 'create';
    }

    const applyPaymentMethod = (index, ev) => {
      if (ev) {
        paymentRemovalIndex.prompt = ev.target.checked ? 'apply' : 'refrain'
      } else {
        paymentRemovalIndex.prompt = ''
      }
      paymentMethods.value.forEach((payment, indx) => {
        if (indx !== index) {
          payment.applied = false;
        }
      })
      appliedPayment.value = index;

      const currentApplied = paymentMethods.value.find((pm, indx) => indx === appliedPayment.value)

      if (currentApplied) {
        store.dispatch('selectPaymentMethod', currentApplied.id);
      }
    }

    const selectPaymentMethod = (paymentId) => {
      if (paymentId) {
        selectedPaymentMethod.value = paymentId;

        store.dispatch('selectPaymentMethod', paymentId);
      }
    }

    const cancelApplying = () => {
      paymentMethods.value[appliedPayment.value].applied = false;
      appliedPayment.value = null;
    }

    const saveSelectedMethod = () => {
      store.dispatch('savePaymentMethod', {payment: paymentMethods.value[appliedPayment.value], selectedPayment: appliedPayment.value});
      store.dispatch('savePrimaryUser', {section: 'payment', data: paymentMethods.value[appliedPayment.value]});
      appliedPayment.value = null;
    }

    const selectApplied = () => {
      paymentMethods.value.forEach((pMethod, index) => {
        pMethod.applied = (index === store.state.authenticated.selectedPayment);
      })
      const currentApplied = paymentMethods.value.find(pm => pm.applied)

      if (currentApplied) {
        store.dispatch('selectPaymentMethod', currentApplied.id);
      }
    }

    const toggleSection = () => {
      sectionExpanded.value = !sectionExpanded.value
    }

    watch(sectionExpanded, (newVal) => {
      section.value.style.height = newVal ? 
        `${sectionHeight.value}px` : 
          `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'    })

    watch(selectedPaymentMethod, (newVal) => {
      if (newVal) {
        selectPaymentMethod(newVal);
      }
    })

    watch(store.state.authenticated.selectedPayment, () => {
      selectApplied();
    });

    watch(paymentMethods.value, (newVal) => {
      console.log(newVal, 'watcher payment')

      section.value.style.height = 'auto';
      setTimeout(() => {
        sectionHeight.value = section.value.offsetHeight;
      })

      if (newVal.length === 1) {
        appliedPayment.value = 0;
        // saveSelectedMethod();
        // applyPaymentMethod(0);
        selectApplied();
      }
    });

    watch(() => (payment.expiryDate), (newVal, oldVal) => {
      if (newVal && oldVal) {
        if (!oldVal.includes("/") && (payment.expiryDate.length === 2)) {
          payment.expiryDate = payment.expiryDate + "/";
        }
      }
    });

    watch(pickeUpExpiryDate, (newVal) => {
      const monthYear = `${newVal}`.split("-");
      payment.expiryDate = `${monthYear[1]}/${monthYear[0]}`;
    });

    watch(() => store.state.authenticated.paymentMethods, (newVal, oldVal) => {
      console.log(newVal, 'authenticated.paymentMethods');
      
      section.value.style.height = 'auto';
      setTimeout(() => {
        sectionHeight.value = section.value.offsetHeight;
      })

      paymentMethods.value = [
        ...store.state.authenticated.paymentMethods.map((method, index) => {
          return {
            ...method,
            detailsOpened: false,
            // applied: false
            applied: index === store.state.authenticated.selectedPayment,
            isEditable: false
          }
        })
      ]

      if ((selectedPaymentMethod.value === null) || (selectedPaymentMethod.value === undefined)) {
        const currentPaymentMethod = paymentMethods.value[0];

        if (currentPaymentMethod) {
          selectPaymentMethod(currentPaymentMethod.id);
        }
      }

      if (newVal.length < oldVal.length) {
        const nextPaymentMethod = paymentMethods.value[paymentMethods.value.length - 1];

        if (nextPaymentMethod) {
          selectPaymentMethod(nextPaymentMethod.id);
        }
      }

    }, { deep: true });

    onMounted(() => {

      setTimeout(() => {
        sectionHeight.value = section.value.offsetHeight;
        section.value.style.height = `${section.value.offsetHeight}px`;
      });

      // if (paymentMethods.value.length === 1) {
        // appliedPayment.value = 0;
        // saveSelectedMethod();
        // applyPaymentMethod(0);
      // }

      store.dispatch('getPaymentMethodsList');

      selectApplied();

      if (paymentMethods.value.length > 1) {
        if (!store.state.authenticated.approvedPayment) {
          paymentMethods.value.forEach(pMethod => {
            pMethod.applied = false;
          })
        }
      }

      if (typeof store.state.authenticated.selectedPayment === 'number') {
        const cardDate = moment(paymentMethods.value[store.state.authenticated.selectedPayment].expiryDate, 'MM/YYYY');
        const currentMonth = moment();

        if (currentMonth.diff(cardDate, 'months') > 0) {
          popups.cardData.active = true;

          setTimeout(() => {
            popups.cardData.active = false;
          }, 10000)
        }
      }
    })

    return {
      payment,
      changePaymentField,
      paymentMethods,
      switchHelperTooltip,
      helperTooltip,
      editHelperTooltip,
      switchEditHelperTooltip,
      removeHelperTooltip,
      switchRemoveHelperTooltip,
      paymentRemovalIndex,
      removalApprove,
      removePayment,
      switchDetails,
      editPaymentMethod,
      addNewPayment,
      applyPaymentMethod,
      appliedPayment,
      cancelApplying,
      approveApplyingPayment,
      saveSelectedMethod,
      section,
      boxHeader,
      toggleSection,
      currentTooltip,

      paymentMethodIsEditable,
      activated,
      focused,
      expiryDateFocusAction,
      expiryDateBlurAction,
      pickeUpExpiryDate,
      submitPaymentForm,
      cancelPaymentChanges,
      selectPaymentMethod,
      selectedPaymentMethod
    };    
  }
})
</script>

<style lang="scss" scoped>
.form-label-static:not(:last-child) {
  margin-bottom: .3rem;
}
.apply-list {
  .apply-method {
    label {
      display: flex;
    }
  }
}
.payment-tooltip-container {
    position: relative;
  }
  /*--Checkbox--*/
  .apply-list input[type="checkbox"]{display:none;}
  .apply-list label{padding-left:3rem; display:inline-block; line-height:2.2rem; position:relative; min-width:2.2rem; min-height:2.2rem;}
  .apply-list label::before{width:2.2rem; height:2.2rem; border-radius:.4rem; border:.2rem solid hsla(var(--light-blue), 20%); position:absolute; left:0; top:50%; transform:translateY(-50%); content:"";}
  .apply-list input[type="checkbox"]:checked ~ label::before{border-color:hsl(var(--green)); background:url('@/assets/images/check-white-xs.svg') center center no-repeat hsl(var(--green));}
  .selection-payment input[type="checkbox"]{display:none;}
  .selection-payment label{padding-left:3rem; display:inline-block; line-height:2.2rem; position:relative; min-width:2.2rem; min-height:2.2rem;}
  .selection-payment label::before{width:2.2rem; height:2.2rem; border-radius:.4rem; border:.2rem solid hsla(var(--light-blue), 20%); position:absolute; left:0; top:50%; transform:translateY(-50%); content:"";}
  .selection-payment input[type="checkbox"]:checked ~ label::before{border-color:hsl(var(--green)); background:url('@/assets/images/check-white-xs.svg') center center no-repeat hsl(var(--green));}
  .card-icon {
    display: flex;
    text-indent: -9999px;
    width: 30px;
    height: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 5px;
    &.Visa {
      background-image: url('@/assets/profile/Visa.png');
    }
    &.MasterCard {
      background-image: url('@/assets/profile/master_card.png');
    }
    &.Discover {
      background-image: url('@/assets/profile/discover.png');
    }
    &.AmericanExpress {
      background-image: url('@/assets/profile/american_express.png');
    }
    &.visa {
      background-image: url('@/assets/profile/Visa.png');
    }
    &.mastercard {
      background-image: url('@/assets/profile/master_card.png');
    }
    &.discover {
      background-image: url('@/assets/profile/discover.png');
    }
    &.amex {
      background-image: url('@/assets/profile/american_express.png');
    }
  }
  .apply-method,
  .edit-method,
  .remove-method {
    position: relative;
  }
  .apply-method {
    margin-right: 1.5rem;
    margin-left: 1rem;
  }
  .remove-helper-tooltip {
    width: max-content;
    padding: 0 15px;
    height: 30px;
    position: absolute;
    z-index: 999;

    background-color: #3C3838;
    color: #fff;
    top: 40px;
    left: -33px;
    text-indent: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: -7px;
      border-color: #3C3838;
      border-left: 8px solid hsl(0, 3%, 23%);
      border-bottom: 6px solid #3C3838;
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 46px;
      background: #3C3838;
      z-index: -1;
    }
  }
  .edit-helper-tooltip {
    width: max-content;
    padding: 0 15px;
    height: 30px;
    position: absolute;
    z-index: 999;

    background-color: #3C3838;
    color: #fff;
    top: 40px;
    left: -20px;
    text-indent: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: -7px;
      border-color: #3C3838;
      border-left: 8px solid hsl(0, 3%, 23%);
      border-bottom: 6px solid #3C3838;
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 30px;
      background: #3C3838;
      z-index: -1;
    }
  }
.removal-popup,
.prompt-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  .removal-message,
  .prompt-message {
    background: #fff;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    width: 100%;
    height: max-content;
    justify-content: space-around;
    align-items: center;
    position: relative;
    border-radius: 11px;
    color: rgba(40,41,43,1);
    font-size: 21px;
    margin: 0 20px;
    padding: 25px 0;
    text-align: center;
    .close-button {
      position: absolute;
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 30px;
      height: 30px;
      background-image: url('@/assets/profile/close_button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      right: 10px;
      top: 22px;
      cursor: pointer;
    }
    .removal-text,
    .prompt-text {
      margin: 0 40px;
      font-size: 16px;
      font-weight: 600;
    }
    .removal-buttons,
    .prompt-buttons {
      display: flex;
      width: 60%;
      justify-content: space-evenly;
      margin-top: 30px;
      button {
        width: 160px;
        height: 40px;
        border-radius: 10px;
        &.cancel-removal-button,
        &.cancel-prompt-button {
          border: 2px solid #38AC89;
        }
        &.approve-button {
          margin-right: 10px;
        }
      }
    }
  }
}
.picked-up-expiry {
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  z-index: 9;
  width: 2rem;
  opacity: 0;
}
</style>