<template>
  <!-- <q-expansion-item
    class="overflow-hidden rounded-borders farm-map-form"
    style="border-radius: 10px"
    popup
    :header-style="{
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      fontWeight: 700,
    }"
    default-opened
    label="Map Farm"
    v-if="(bedsList > 0) && (isAuth('edit-bed') || isAuth('add-bed'))"
  >
    <template v-slot:header>
      <q-item-section>
        <span class="header">{{ sectionHeader }}</span>
      </q-item-section>
    </template>
    <q-card class="map-farm-form">
      <q-card-section class="flex farm-map-card-section">
        <div class="row wrapper">
          <q-field
            borderless
            label="Choose a farm"
            stack-label
            color="bhoomi"
            style="max-width: 200px; width: 100%"
          >
            <template v-slot:control>
              <q-select
                color="bhoomi"
                style="width: 100%"
                outlined
                v-model="bed.farmName"
                :options="options"
              />
            </template>
          </q-field>
        </div>
        <div class="row wrapper">
          <FarmDrawScheme />
        </div>
      </q-card-section>
    </q-card>
  </q-expansion-item> -->
  <section class="section" ref="section">
<div class="border-box active">
<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Map Farm</h5>
<div class="info-icons">

  <div class="info-icon lg header-icon tooltip" 
    @click="createNewPlan"
    v-if="((editionMode === 'represent') || imageDataSaved) && isAuth('new-farm-plan')"
    data-tooltip="New Plan"
  >
    <img src="@/assets/images/updated/new-bed-plan.png" alt="">
  </div>

</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>
<div class="border-box-content">

<div class="content-block">

<div class="content-row choose-farm" v-if="isAuth('draw-farm-map')">
<div class="row align-items-end justify-content-between">

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.farmName || (mapDraw.farmName.length > 0)}">Choose a farm</p>
<!-- <select class="form-control" 
  v-model="mapDraw.farmName"
  @click="() => {
    activated.farmName = true;
    focused.farmName = true;
  }"
  @change="() => {
    focused.farmName = false;
  }"
  :disabled="(editionModeMap === 'represent') || (editionMode === 'represent')"
>
  <option 
    v-for="option in options"
    v-bind:key="option + 'farm-options-map'"
  >{{ option }}</option>
</select> -->
  <DropDownList
    :mainObject="'mapDraw'"
    :mainProperty="'farmName'"
    :injectionName="'bedEdition'"
    :optionKey="'farm-option-map'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :isDisabled="((editionModeMap === 'represent') || (editionMode === 'represent'))"
    :defaultSelected="farm.farmName"
    @click="() => {
      activated.farmName = true;
    }"
  />
  <!-- options.value -->
  <p v-if="activated.farmName && mapDraw.farmName.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.year || (mapDraw.year.length > 0)}">Choose a year</p>
<!-- <select class="form-control" 
  v-model="mapDraw.year"
  @click="() => {
    activated.year = true;
    focused.year = true;
  }"
  @change="() => {
    focused.year = false;
  }"
>
  <option 
    v-for="yearOption in yearOptions"
    v-bind:key="yearOption + 'year-options-map'"
  >{{ yearOption }}</option>
</select> -->

<DropDownList
  :mainObject="'mapDraw'"
  :mainProperty="'year'"
  :injectionName="'bedEdition'"
  :optionKey="'year-option-map'"
  :optionsList="yearOptions"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="yearOptions[0]"
  @click="() => {
    activated.year = true;
  }"
/>
<p v-if="activated.year && `${mapDraw.year}`.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<!-- <div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.season || (mapDraw.season.length > 0)}">Choose a season</p>
<select class="form-control" 
  v-model="mapDraw.season"
  @click="() => {
    activated.season = true;
    focused.season = true;
  }"
  @change="() => {
    focused.season = false;
  }"
  :disabled="(editionMode === 'represent')"
>
  <option 
    v-for="seasonOption in seasonOptions"
    v-bind:key="seasonOption + 'season-options-map'"
  >{{ seasonOption }}</option>
</select>
</div>
</div> -->

<div class="col-3">
  <button 
    type="submit" 
    class="btn green" 
    @click="createNewPlan"
    v-if="(editionMode === 'represent') || imageDataSaved"
  >New Plan</button>
</div>

<div class="col-3" style="position: relative;display: flex; justify-content: flex-end; align-items: center;">
<!-- <div class="icon-links">
<a href="javascript:void();" class="active"><img src="images/icon-1.png" alt=""></a>
<a href="javascript:void();"><img src="images/icon-2.png" alt=""></a>
<a href="javascript:void();"><img src="images/icon-3.png" alt=""></a>
</div> -->
<div class="info-icon mapping-tool-info-button"
    @click="() => {infoDialogIsOpen = true;}"
  >
  <!--     @mouseover="switchInfoHelper(true)"
    @mouseout="switchInfoHelper(false)" -->
  <img src="@/assets/images/question-mark.png" alt=""></div>
  <div class="mapping-tool-info" v-if="infoHelper">
    Use this drawing tool to plan/draw beds for your farm. You can number the beds, add text, color code using a mouse, stylus, or finger on a touch screen device.
  </div>
</div>

<div class="col-3">
</div>

</div>
</div>

<div class="content-row grid-4 grid-full">
  <!-- <div style="height: 3rem;" v-if="editionModeMap !== 'represent'"></div> -->
  <div class="design-tool-buttons" v-if="isAuth('draw-farm-map')">
    <div class="helper" :class="toolsHelper" v-if="toolsHelper.length > 0">
      {{ toolsHelper }}
    </div>
    <button 
      v-if="editionModeMap === 'represent'"
      @click="() => {
        editionModeMap = 'edit';
        imageDataSaved = false;
        // bed.imageData = [];
      }"
      class="tooltip edit-design-tool"
      data-tooltip="Edit"
      @mouseover="switchToolsHelper('Edit', true)"
      @mouseout="switchToolsHelper('Edit', false)"
    >
      <!-- <img src="@/assets/images/updated/Edit.png" alt=""> -->
    </button>
    <button 
      @click="submitMapDraw"
      v-if="(editionModeMap === 'edit') || (editionModeMap === 'create')"
      class="tooltip save-design-tool"
      data-tooltip="Save"
      @mouseover="switchToolsHelper('Save', true)"
      @mouseout="switchToolsHelper('Save', false)"
    >
      <!-- <img src="@/assets/images/updated/Save.png" alt=""> -->
    </button>
    <button 
      @click="() => {
        if (editionModeMap === 'edit') {
          editionModeMap = 'represent';
        } else {
          bedsCreation = false;
        }
        // bed.imageData = [];
      }"
      class="tooltip cancel-design-tool"
      data-tooltip="Cancel"
      @mouseover="switchToolsHelper('Cancel', true)"
      @mouseout="switchToolsHelper('Cancel', false)"
    >
      <!-- <img src="@/assets/images/updated/XX_White.png" alt=""> -->
    </button>
  </div>
  <FarmDrawScheme v-if="(editionModeMap === 'create') && isAuth('draw-farm-map')" />
  <FarmDrawSchemeFilled v-else-if="(editionModeMap === 'represent')" />
  <FarmDrawSchemeEdit v-if="(editionModeMap === 'edit') && isAuth('draw-farm-map')" />
  <!-- <div class="row align-items-end justify-content-between">
    <div class="col-3" v-if="editionModeMap === 'represent'">
      <button 
        @click="() => {
          editionModeMap = 'edit';
          imageDataSaved = false;
        }"
        class="btn green"
      >Edit</button>
    </div>
    <div class="col-3" v-if="(editionModeMap === 'edit') || (editionModeMap === 'create')">
      <button 
        @click="submitMapDraw"
        class="btn green"
      >Save</button>
    </div>
    <div class="col-3">
      <button 
        @click="() => {
          if (editionModeMap === 'edit') {
            editionModeMap = 'represent';
          } else {
            bedsCreation = false;
          }
        }"
        class="btn green"
      >Cancel</button>
      
    </div>
    <div class="col-3"></div>
    <div class="col-3"></div>
  </div> -->
</div>

</div>

</div>
</div>
</section><!--//End Section-->

<div class="removal-popup" v-if="popUps.farmHasCrop.active">

  <div class="modal modal-md">
  <span class="modal-close" @click="() => approveCropRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">{{ popUps.farmHasCrop.message }}</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => approveCropRemoval(true)"
        class="approve-button btn green" 
      >Yes</button>
      <button
        @click="() => approveCropRemoval(false)" 
        class="cancel-removal-button btn green btn-o" 
      >No</button>
  </div>
  </div>
  </div>

</div>


<div class="removal-popup" v-if="popUps.createNewPlan.active">

<div class="modal modal-md">
<span class="modal-close" @click="() => confirmNewPlan(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">{{ popUps.createNewPlan.message }}</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => confirmNewPlan(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => confirmNewPlan(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>

<div class="add-bed-popup" v-if="imageDataSaved && addBedPopup">
  
  <div class="modal modal-md" :style="{maxWidth: (popUps.bedDetails.active === 'edit') ? 'max-content' : 'max-content'}">
  <span class="modal-close" @click="() => approveCreateBed(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">{{ popUps.bedDetails.message[popUps.bedDetails.active] }}</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
    <button
      @click="() => approveCreateBed(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveCreateBed(false)"
      class="cancel-removal-button btn green btn-o" 
    >No</button>
  </div>
  </div>
  </div>
  
</div>
  
  <div ref="bedForm"></div>
  <template v-if="bedFormIsOpened">
    <FarmBedProfile v-if="((editionMode === 'edit') || (editionMode === 'create')) && (isAuth('edit-bed') || isAuth('add-bed'))" />
    <FarmBedProfileFilled  v-if="(editionMode === 'represent' && (bedsList.length > 0))" />
  
    <div ref="bedListSection"></div>
    <FarmBedList :key="`farm-bed-list-${selectedFarm.id}`" />
    <!--  v-if="(bedsList.length > 0)" -->
  </template>

  <template  v-if="infoDialogIsOpen">
    <div class="form-modal">
      <span class="form-modal-close" @click="() => {infoDialogIsOpen = false}"><img src="@/assets/images/close-green-xs.png" alt=""></span>
      <FarmDrawInfo />
    </div>
  </template>

</template>

<script>
import { provide, reactive, onMounted, inject, watch, ref, computed } from "vue"; 
import { useStore } from "vuex";
import FarmBedProfile from "./FarmBedProfile.vue";
import FarmBedList from "./FarmBedList.vue";
import FarmDrawScheme from "./FarmDrawScheme.vue";
import FarmBedProfileFilled from "./FarmBedProfileFilled.vue";
import FarmDrawSchemeFilled from "./FarmDrawSchemeFilled.vue";
import yearOptions from '@/sharedData/yearsList';
import { v4 as uuid } from 'uuid';
import FarmDrawSchemeEdit from "./FarmDrawSchemeEdit.vue";
import DropDownList from "@/components/Shared/DropDownList.vue";
import FarmDrawInfo from "@/components/Pages/FarmProfile/FarmDrawInfo.vue"

export default {
  name: "FarmMap",
  components: {
    FarmBedProfile,
    FarmBedList,
    FarmDrawScheme,
    FarmBedProfileFilled,
    FarmDrawSchemeFilled,
    FarmDrawSchemeEdit,
    DropDownList,
    FarmDrawInfo
},
  setup() {
    const store = useStore();
    const { isAuth } = inject('mainUI');

    // const options = ref([]);
    const options = reactive({
      value: []
    });

    const seasonOptions = ['Spring', 'Summer', 'Autumn', 'Winter'];

    const mapDraw = reactive({
      id: '',
      year: '2024',
      season: '',
      farmName: '',
      
      imageData: [],
      imageParams: {},

      linesData: [],
      linesParams: {},

      textsData: [],
      textsParams: {}
    });

    // const bed = reactive({
    //   id: '',
    //   year: '',
    //   season: '',
    //   farmName: '',
    //   bedNumber: '',
    //   acreage: '',
    //   soilType: '',
    //   irrigation: '',
    //   IOTdeviceType: '',
    //   primaryCrop: '',
    //   secondaryCrop: '',
    //   mapId: ''
    //   // imageData: '',
    //   // imageParams: ''
    // });

    // const defaultBed = {
    //   ...bed
    // };

    const { 
      farm, 
      // defaultFarm, 
      selectedFarm, 
      selectFarm,
      selectedFarmId,
      newPlanning
    } = inject('farmEdition');
    const { editionMode, bedsCreation, mapDrawSelected, bed, defaultBed, selectBed, bedFormIsOpened, addDesign} = inject('bedsCreation');
    const { crop, defaultCrop, selectCrop } = inject('cropEdition');
    const sectionHeader = 'Map Farm';
    const imageDataFilled = ref(false);
    const imageDataSaved = ref(false);
    const addBedPopup = ref(false);
    const editionModeMap = ref('create');

    const farms = computed(() => store.state.farms);

    const activated = reactive({
      farmName: false,
      season: false,
      year: false
    })

    const focused = reactive({
      farmName: false,
      season: false,
      year: false
    })

    const bedForm = ref(null);

    const bedsList = ref([]);

    const crops = computed(() => store.state.crops);

    const popUps = reactive({
      farmHasCrop: {
        active: false,
        message: 'The Plan crop associated with this farm will be deleted if you plan to add a bed'
      },
      createNewPlan: {
        active: false,
        message: 'Creating a new plan will move the current farm design to farm history. Are you sure you want to do it?'
      },
      bedDetails: {
        message: {
          edit: 'Do you want to update the bed details?',
          add: 'Do you want to add bed details'
        },
        active: 'add'
      }
    });

    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);

    const selectedFarmFromList = ref({});
    const farmCrops = ref([]);

    const infoHelper = ref(false);
      
    const bedListSection = ref(null);

    const toolsHelper = ref('');

    const infoDialogIsOpen = ref(false);

    const switchToolsHelper = (tool, mode) => {
      if (mode) {
        toolsHelper.value = tool;
      } else {
        toolsHelper.value = '';
      }
    }

    const editBed = (field, val) => {
      bed[field] = val;
    }

    const editMap = (field, val) => {
      mapDraw[field] = val;
    }

    const switchInfoHelper = (toggle) => {
      infoHelper.value = toggle;
    }

    const submitMapDraw = () => {

      const foundFarm = farms.value.find(farm => farm.farmName === mapDraw.farmName)

      selectedFarmFromList.value = foundFarm ? {...foundFarm} : {};
      farmCrops.value = [...crops.value.filter(crp => ((crp.sourceType === 'farm') && ((crp.sourceId === farm.id) || (crp.sourceId === selectedFarmId) || (crp.sourceId === selectedFarm.id) || (crp.sourceId === selectedFarmFromList.value.id))))]

      if (farmCrops.value.length > 0) {
        popUps.farmHasCrop.active = true;
        return;
      }

      if (!mapDraw.farmName || !mapDraw.year) {
        if (!mapDraw.farmName) activated.farmName = true;
        if (!mapDraw.year) activated.year = true;
        return;
      }

      const mapId = uuid();

      console.log(mapDraw, 'mapDraw object before storing');
      store.dispatch('editFarmMap', {
        mapDraw: {
          ...mapDraw,
          id: mapId
        },
        selectedFarmId: selectedFarm.id
      });
      mapDraw.id = mapId;
      imageDataSaved.value = true;
      if ((editionModeMap.value === 'edit') && ((farm.beds.length > 0) || (selectedFarm.beds.length > 0))) {
        popUps.bedDetails.active = 'edit'
      }
      editionModeMap.value = 'represent';
      addBedPopup.value = true;
      bed.mapId = mapId;

      newPlanning.value = false;
    }

    const approveCropRemoval = (approved) => {
      if (approved) {
        farmCrops.value.forEach(farmCrop => {
          const cropId = farmCrop.id;

          const cropIndex = crops.value.findIndex(crp => crp.id === cropId);

          store.dispatch('removeCrop', {cropId, cropIndex});
        });

        setTimeout(() => {
          mapDrawSelected.value = {...mapDraw};
          editionMode.value = 'create';
          bedsCreation.value = true;
        });

        selectCrop({
          ...defaultCrop,
          sourceType: 'bed'
        });

        selectFarm({...selectedFarmFromList.value});
      } else {
        bedsCreation.value = false;
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: section?.value?.offsetTop - 100,
            left: 0,
            behavior: 'smooth'
          })
        })
      }
      popUps.farmHasCrop.active = false;
    }

    const cancelBed = () => {
      const bedEdited = farm.beds.find(b => b.id === bed.id);
      if (bedEdited) {
        selectBed({...bedEdited}, 'represent');
      } else {
        for (let prop in bed) {
          if (prop !== 'farmName') {
            bed[prop] = '';
          }
        }
        editionMode.value = null
      }
    }

    // const selectBed = (bedData, editMode) => {
    //   console.log(bedData, 'bedData');
    //   if (bedData) {
    //     for (let field in bedData) {
    //       bed[field] = bedData[field]
    //     }
    //     for (let prop in crop) {
    //       crop[prop] = bedData[prop];
    //     }
    //   }
    //   editionMode.value = editMode;
    // }

    const removeBed = (farmId, bedId) => {console.log(farmId, bedId)};

    const focusOnBed = () => {
      setTimeout(() => {
        document.getElementsByTagName('main')[0].scroll({
          top: bedForm.value.offsetTop,
          left: 0,
          behavior: 'smooth'
        })
      }, 100)
    }

    const createNewPlan = () => {
      popUps.createNewPlan.active = true;
    }

    const confirmNewPlan = (approved) => {
      if (approved) {
        store.dispatch('archiveFarmPlan', {
          mapDraw: {...mapDraw},
          selectedFarmId: selectedFarm.id
        });
        
        bedFormIsOpened.value = false;

        newPlanning.value = true;

        setTimeout(() => {
          for (let prop in bed) {
            if (prop !== 'farmName') {
              bed[prop] = '';
            }
          }

          bedsList.value = [];
          
          editionModeMap.value = 'create';
          imageDataSaved.value = false;
          // editionMode.value = 'create';
          // bedsCreation.value = true;
          // crop.sourceType = 'bed';
          addDesign();

        }, 100);

        selectFarm({
          ...selectedFarm, 
          beds: [], 
          drawMap: []
        });

      }

      popUps.createNewPlan.active = false;
    }

    const toggleSection = (ev) => {
      if ((ev.target.className === 'border-box-header') || (ev.target.className === 'angle')) {
        sectionExpanded.value = !sectionExpanded.value
      }
    }

    const approveCreateBed = (approved) => {
      if (approved) {
        bedFormIsOpened.value = true;
        editionMode.value = 'create';
        // crop.sourceType = 'bed';
        for (let prop in crop) {
          if ((prop !== 'sourceType') && (prop !== 'sourceId')) {
            crop[prop] = defaultCrop[prop];
          }
        }
        crop.sourceType = 'bed';
        bed.mapId = mapDraw.id;
        // selectBed({
        //   ...defaultBed,
        //   mapId: mapDraw.id,
        // }, 'create');
        // selectCrop({
        //   ...defaultCrop,
        //   sourceType: 'bed'
        // }, 'create');
      }

      addBedPopup.value = false;
      // bed.imageData = [];
    }

    watch(sectionExpanded, (newVal) => {
      section.value.style.height = newVal ? 
        `${sectionHeight.value}px` : 
          `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'    })

    watch(bedsList, () => {
      if (bedsList.value.length > 0) bedFormIsOpened.value = true;
      setTimeout(() => {
        sectionHeight.value = section.value.offsetHeight;
        if (section.value) section.value.style.height = `${section.value.offsetHeight}px`;
      });
    })

    watch(store.state.farms, (newVal) => {
      const currentFarm = newVal.find(farm => farm.id === selectedFarm.id)

      console.log(currentFarm, 'currentFarm farms are changed');

      bedsList.value = currentFarm?.beds || [];

      options.value = [...store.state.farms.map(farm => farm.farmName)]

    })

    watch(() => ({...selectedFarm}), (newVal) => {

      bedsList.value = newVal?.beds || [];

      if (selectedFarm.id) {
        bed.farmName = selectedFarm.farmName;
      }

      if ((farm.beds.length === 0) && (farm.drawMap.length > 0)) {
        addBedPopup.value = true;
      }
    });

    watch(() => (mapDraw.imageData), (newVal) => {
      if (newVal) imageDataFilled.value = true;

      section.value.style.height = 'auto';

      setTimeout(() => {
        sectionHeight.value = section?.value?.offsetHeight;
        if (section.value) section.value.style.height = `${section?.value?.offsetHeight}px`;
      });
    });

    watch(() => (mapDraw.linesData), (newVal, oldVal) => {
      console.log(newVal, oldVal, 'linesData');
      if (newVal) imageDataFilled.value = true;

      section.value.style.height = 'auto';

      setTimeout(() => {
        sectionHeight.value = section?.value?.offsetHeight;
        if (section.value) section.value.style.height = `${section?.value?.offsetHeight}px`;
      });
    });

    watch(() => (mapDraw.textsData), (newVal) => {
      if (newVal) imageDataFilled.value = true;

      section.value.style.height = 'auto';

      setTimeout(() => {
        sectionHeight.value = section?.value?.offsetHeight;
        if (section.value) section.value.style.height = `${section?.value?.offsetHeight}px`;
      });
    });

    watch(() => (editionMode.value), (newVal) => {
      console.log(newVal, 'edition mode is changed');
      section.value.style.height = 'auto';

      setTimeout(() => {
        sectionHeight.value = section?.value?.offsetHeight;
        if (section.value) section.value.style.height = `${section?.value?.offsetHeight}px`;
      });
    });

    watch(() => (editionModeMap.value), (newVal) => {
      console.log(newVal, 'edition mode map is changed');
      section.value.style.height = 'auto';

      setTimeout(() => {
        sectionHeight.value = section?.value?.offsetHeight;
        if (section.value) section.value.style.height = `${section?.value?.offsetHeight}px`;
      });

      toolsHelper.value = '';
    });

    watch(() => (mapDraw.farmName), (newVal) => {
      bed.farmName = newVal;
      // selectFarm({...farms.value.find(stateFarm => stateFarm.farmName === newVal)})
      console.log(selectFarm);
    });

    watch(() => (bed.id), (newVal, oldVal) => {
      if (newVal) {

        console.log(newVal, 'bed-id-is-changed');
        const currentBedCrop = store.state.crops.find(crp => 
          (crp.sourceType === 'bed') && 
          (crp.sourceId === bed.id)
        );

        if (currentBedCrop) {
          console.log(currentBedCrop, 'currentBedCrop');
          for (let prop in crop) {
            crop[prop] = currentBedCrop[prop];
          }
        }
      }
      if ((newVal && oldVal) && (newVal !== oldVal)) {
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: bedForm?.value?.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        })
      }
    })

    watch(() => (farm.id), (newVal) => { //oldVal
      if (newVal) { // (newVal && oldVal) && (newVal !== oldVal)
        const currentFarm = store.state.farms.find(stateFarm => stateFarm.id === newVal);

        console.log('from Map', newVal, farms.value);

        if (!currentFarm) {
          for (let prop in bed) {
            bed[prop] = defaultBed[prop];
          }

          for (let cropProp in crop) {
            crop[cropProp] = defaultCrop[cropProp];
          }
          // selectFarm({...defaultFarm}, 'create');
          editionMode.value = '';
          editionModeMap.value = '';
          bedsCreation.value = false;

        } 

        for (let prop in mapDraw) {
          mapDraw[prop] = currentFarm.drawMap[0][prop];
        }
        if (!mapDraw.year) {
          mapDraw.year = yearOptions[0];
        }

        mapDraw.farmName = currentFarm.farmName;

        const currentBed = currentFarm.beds.find(bed => bed.mapId === currentFarm.drawMap.id);

        if (currentBed) {
          mapDraw.season = currentBed.season;
          mapDraw.year = currentBed.year || yearOptions[0];
        }

      }
    })

    // doesn't work
    watch(() => ([...store.state.farms]), (newVal, oldVal) => {
      const currentFarm = [...newVal].find(f => f.id === selectedFarm.id);
      const currentFarmOld = [...oldVal].find(f => f.id === selectedFarm.id);

      console.log('entering condition in map');
      if ((currentFarm?.drawMap?.length === 0) && (currentFarmOld?.drawMap?.length > 0)) {
        console.log('entering condition in map 2');
        selectFarm({...currentFarm});

        for (let prop in bed) {
          if (prop !== 'farmName') {
            bed[prop] = '';
          }
        }

        bedsList.value = [];

        editionModeMap.value = 'create';
        imageDataSaved.value = false;

        popUps.createNewPlan.active = false;
      }

    });

    onMounted(() => {

      console.log(selectedFarm);

      section.value.style.height = 'auto';

      setTimeout(() => {
        sectionHeight.value = section?.value?.offsetHeight;
        if (section.value) section.value.style.height = `${section?.value?.offsetHeight}px`;
      });

      options.value = [...store.state.farms.map(farm => farm.farmName)]

      if (selectedFarm.id) {
        bed.farmName = selectedFarm.farmName;

        if (selectedFarm.drawMap.length > 0) {
          console.log(selectedFarm.drawMap, 'selectedFarm.drawMap');
          for (let prop in mapDraw) {
            mapDraw[prop] = selectedFarm.drawMap[0][prop] || mapDraw[prop];
            editionModeMap.value = 'represent';
            imageDataSaved.value = true;
          }
          if (!mapDraw.year) {
            mapDraw.year = yearOptions[0];
          }
        }
      }

      bedsList.value = selectedFarm?.beds || [];

      if (mapDrawSelected.value.farmName && mapDrawSelected.value.year) {
        for (let prop in mapDraw) {
          mapDraw[prop] = mapDrawSelected.value[prop];
          console.log(mapDrawSelected.value[prop], 'mapDrawSelected.value[prop]');
        }
        if (!mapDraw.year) {
          mapDraw.year = yearOptions[0];
        }

        const mapId = uuid();

        store.dispatch('editFarmMap', {
          mapDraw: {
            ...mapDraw,
            id: mapId
          },
          selectedFarmId: selectedFarm.id
        });
        mapDraw.id = mapId;
        imageDataSaved.value = true;
        editionModeMap.value = 'represent';
        addBedPopup.value = true;
        mapDrawSelected.value = {};
        
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: section?.value?.offsetTop - 100,
            left: 0,
            behavior: 'smooth'
          })
        })
      }

      if ((farm.beds.length === 0) && (farm.drawMap.length > 0)) {
        addBedPopup.value = true;
      }

      if (farm.beds.length > 0) {
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: bedListSection.value.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        }, 100)
      }
    })

    provide('bedEdition', {
      mapDraw,
      editMap,
      bed,
      defaultBed,
      editionMode,
      editBed,
      cancelBed,
      selectBed,
      removeBed,
      focusOnBed,
      bedsList,
      options
    })

    return {
      bedForm,
      bedsList,
      sectionHeader,
      bed,
      options,
      editionMode,
      selectedFarm,
      isAuth,
      section,
      boxHeader,
      toggleSection,
      activated,
      focused,
      imageDataFilled,
      imageDataSaved,
      createNewPlan,
      bedFormIsOpened,
      yearOptions,
      seasonOptions,
      mapDraw,
      submitMapDraw,
      editionModeMap,
      addBedPopup,
      bedsCreation,
      popUps,
      confirmNewPlan,
      approveCropRemoval,
      infoHelper,
      switchInfoHelper,
      approveCreateBed,
      farm,
      bedListSection,
      toolsHelper,
      switchToolsHelper,
      infoDialogIsOpen
    }
  }
};
</script>

<style lang="scss" scoped>
.farm-map-form {
  .header {
    margin-left: 8px;
  }
  .farm-map-card-section {
    padding: 16px 24px 24px;
  }
  .row {
    &.wrapper {
      height: auto;
    }
  }
}
.q-expansion-item--popup.q-expansion-item--expanded,
  .q-expansion-item--popup.q-expansion-item--collapsed {
    padding: 0;
  }
  .q-expansion-item {
    margin-bottom: 45px;
  }

  .content-row {
    &.choose-farm {
      // margin-bottom: 6rem;
    } 
  }

.add-bed-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapping-tool-info-button {
  cursor: pointer;
}

.mapping-tool-info {
  position: absolute;
  width: 40rem;
  height: max-content;
  right: 0;
  bottom: 5rem;
  z-index: 999;
  padding: 0.5rem;
  background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
  font-size: 1.4rem;
  font-weight: var(--fw-semibold);
  font-family: 'Gilroy';
  color: hsl(var(--white));
  &::after {
    content: " ";
    position: absolute;
    display: block;
    bottom: -7px;
    background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    border-left: 8px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    border-bottom: 6px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    right: 10px;
    z-index: -1;
  }
}
.design-tool-buttons {
  display: flex;
  margin-bottom: 1rem;
  justify-content: end;
  button {
    width: 36px;
    height: 30px;
    -webkit-mask-size: 36px 30px;
    mask-size: 36px 30px;
    background-color: rgba($color: #000000, $alpha: 0.2);
    &:hover {
      background-color: #000000;
    }
  }
  .edit-design-tool {
    -webkit-mask-image: url('@/assets/images/edit_design.svg');
    mask-image: url('@/assets/images/edit_design.svg');
    margin-left: 1rem;
    // margin-right: 1rem;
    margin-right: 2.6rem;
  }
  .save-design-tool {
    -webkit-mask-image: url('@/assets/images/save_design.svg');
    mask-image: url('@/assets/images/save_design.svg');
    margin-left: 1rem;
    // margin-right: 1rem;
    // margin-right: 53.6rem;
    margin-right: 4.6rem;
  }
  .cancel-design-tool {
    -webkit-mask-image: url('@/assets/images/cancel_design.svg');
    mask-image: url('@/assets/images/cancel_design.svg');
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
</style>