<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Inventory Purchased</h5>
<div class="icon-group">
  <div class="icon green icon-o tooltip"
    @click="addNewInventory"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
    data-tooltip="Add Inventory"
    v-if="isAuth('add-new-inventory')"
  >
  
    <img src="@/assets/images/updated/new_set/Inventory_Box_Circle.png" alt="">
    <!-- <img src="@/assets/images/updated/add-item.png" alt=""> -->
  </div>
  <div class="icon green icon-o tooltip"
    @click="selectAddedInventory({...addedInventory}, 'edit');"
    @mouseover="switchEditHelperTooltip(true)"
    @mouseout="switchEditHelperTooltip(false)"
    data-tooltip="Edit"
    v-if="isAuth('edit-inventory')"
  >
    <img src="@/assets/images/updated/Edit.png" alt="">
  </div>
</div>
</div>

<div class="content-block">
<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Date Purchased</p>
<h6>{{ moment(addedInventory.date, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Vendor</p>
<h6>{{ addedInventory.vendorName }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Item</p>
<h6>{{ addedInventory.item }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Brand</p>
<h6>{{ addedInventory.brand }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Variety</p>
<h6>{{ addedInventory.variety }}</h6>
</div>

<div class="intro-grid-column">
<p class="title"># of Items</p>
<h6>{{ addedInventory.numberOfItems }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Weight (lbs/item)</p>
<h6>{{ addedInventory.quantity }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Invoice #</p>
<h6>{{ addedInventory.invoice }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Amount Paid</p>
<h6>$ {{ addedInventory.amountPaid }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Warranty Exp</p>
<h6>{{ moment(addedInventory.warrantyExp, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Vendor Phone</p>
<h6>{{ addedInventory.vendorPhone }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Vendor Contact</p>
<h6>{{ addedInventory.vendorContact }}</h6>
</div>

<div class="intro-grid-column intro-grid-comment-column">
<p class="title">Comments</p>
<h6>{{ addedInventory.comments }}</h6>
</div>

</div>
</div>

<div class="content-block">

  <FileAttachments :key="`vendor-product-filled-${addedInventory.id}`" v-bind:filled="true" v-bind:section="'vendorItem'"  v-bind:instance="`${addedInventory.id}`" />

<!-- <div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o">Cancel</button></div>
</div>
</div>
</div> -->

</div>

</div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { inject, ref } from 'vue';
import moment from 'moment';

export default {
  name: "AddInventoryFilled",
  setup() {
    const { isAuth } = inject('mainUI');
    const { addedInventory, selectAddedInventory, addNewInventory } = inject('addedInventoryDetails');

    const editHelperTooltip = ref(false);
    const helperTooltip = ref(false);

    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle;
    }

    const switchEditHelperTooltip = (toggle) => {
      editHelperTooltip.value = toggle;
    }

    return {
      addedInventory,
      selectAddedInventory,
      editHelperTooltip,
      helperTooltip,
      switchHelperTooltip,
      switchEditHelperTooltip,
      addNewInventory,
      moment,
      isAuth
    }
  },
  components: { FileAttachments }
}
</script>

<style></style>