<template>
  <div class="farm-mapping">
    <div class="map-container">
      <div class="tooltip remove-all-button" 
        style="position: absolute; padding: 0; width: 36px; height: 30px;border: none; top: -40px; margin-right: 0.6rem;"
        data-tooltip="Erase All"
        @click="deleteAll"
        @mouseover="switchToolsHelper('Delete-All', true)"
        @mouseout="switchToolsHelper('Delete-All', false)"
      >
        <!-- <img src="@/assets/images/updated/delete-item.png" alt=""> -->
      </div>
      <div class="button-set">
        <div class="helper" :class="toolsHelper" v-if="toolsHelper.length > 0">
          {{(toolsHelper === 'Delete-All') ? `${toolsHelper.split('-')[0]} ${toolsHelper.split('-')[1]}` : toolsHelper }}
        </div>
                <!-- <span 
          class="edit-text tools" 
          :class="{active: mode === 'edit-text'}" 
          @click="turnOnTextEdit"
          @mouseover="switchToolsHelper('Edit-Text', true)"
          @mouseout="switchToolsHelper('Edit-Text', false)"
        ></span> -->
        <span 
          class="pen tools" 
          :class="{active: mode === 'draw'}" 
          @click="turnOnDraw"
          @mouseover="switchToolsHelper('Pencil', true)"
          @mouseout="switchToolsHelper('Pencil', false)"
        >
        </span>
        <!-- <span 
          class="brush tools" 
          :class="{active: mode === 'brush'}" 
          @click="turnOnBrush"
          @mouseover="switchToolsHelper('Brush', true)"
          @mouseout="switchToolsHelper('Brush', false)"  
        >brush</span> -->
        <span 
          class="straight tools" 
          :class="{active: mode === 'straight'}" 
          @click="turnOnStraight"
          @mouseover="switchToolsHelper('Line', true)"
          @mouseout="switchToolsHelper('Line', false)"  
        >line</span>
        <span class="stroke-range">
          <span>stroke width</span>
          <input type="range" min="1" max="20" :value="stroke_width" @change="selectStrokeWidth" />
        </span>
        <span 
          class="erase tools" 
          :class="{active: mode === 'erase'}"
          @click="turnOnErase"
          @mouseover="switchToolsHelper('Eraser', true)"
          @mouseout="switchToolsHelper('Eraser', false)"  
        >erase</span>

        <!-- <span class="colors tools"
          @mouseover="switchToolsHelper('Colorpicker', true)"
          @mouseout="switchToolsHelper('Colorpicker', false)"
        >
          <input type="color" @change="selectColor" :value="stroke_color" />
        </span> -->
        <span class="eraser-radius">
          <span>eraser radius</span>
          <input type="range" :value="eraser_radius" min="1" max="20" @change="selectEraserRadius" />
        </span>
        <span 
          class="text tools" 
          :class="{active: mode === 'text'}" 
          @click="turnOnText"
          @mouseover="switchToolsHelper('Text', true)"
          @mouseout="switchToolsHelper('Text', false)"  
        >text</span>
      </div>
      <svg class="map-farm" :viewBox="viewBox"></svg>
      <div v-if="newTextInsert.active"
        class="new-text-insert-field"
        :style="{top: `${newTextInsert.points[1] - 20}px`, left: `${newTextInsert.points[0] - 5}px`}"
      >
        <textarea placeholder="Insert text" v-model="newTextInsert.text" :style="{height: `${insertElementHeight}px`}">
        </textarea>
        <button 
          @click="() => addTextToMap(newTextInsert.points)" 
          class="add-text-to-map-button"
          @mouseover="switchAddHelper(true)"
          @mouseout="switchAddHelper(false)"
        >
        </button>
        <div class="add-helper-tooltip in-draw" v-if="addHelperTooltip">Add</div>
        <button 
          @click="() => cancelTextToMap()" 
          class="cancel-text-to-map-button"
          @mouseover="switchCancelHelper(true)"
          @mouseout="switchCancelHelper(false)"
        >
        </button>
        <div class="cancel-helper-tooltip in-draw" v-if="cancelHelperTooltip">Delete</div>
      </div>
      <!-- <div v-if="textToRemove.active"
        class="text-remove-buttons"
        :style="{top: `${textToRemove.points[1]}px`, left: `${textToRemove.points[0]}px`}"
      >
        <button @click="() => confirmTextRemoval(true)" class="remove-text-button"></button>|
        <button @click="() => confirmTextRemoval(false)" class="cancel-removal-button"></button>
      </div> -->
      <div v-for="(group) in groupsToRemove"
        v-bind:key="`text-group-to-remove-${group.id}`"
        class="text-remove-buttons"
        :style="{top: `${group.points[1] - 20}px`, left: `${group.points[0]}px`}"
      >
        <button @click="() => confirmGroupTextRemoval(true, group.id)" class="cancel-removal-button"></button>
        <!-- <button @click="() => confirmGroupTextRemoval(true, group.id)" class="remove-text-button"></button>| -->
        <!-- <button @click="() => confirmGroupTextRemoval(false)" class="cancel-removal-button"></button> -->
      </div>
      <div v-for="(tooltip, index) in tooltips" 
        class="tooltip" v-bind:key="'tooltip' + index"
        :style="{top: `${tooltip.points[0][1]}px`, left: `${tooltip.points[0][0]}px`}"
        @click="addBedProfile(tooltip.points)">
        Add Bed Details
      </div>
    </div>
    <div id="output"></div>
  </div>

  <div class="removal-popup" v-if="removeAllContent.active">

  <div class="modal modal-md">
  <span class="modal-close" @click="() => confirmDeleteAll(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">{{ removeAllContent.message }}</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => confirmDeleteAll(true)"
        class="approve-button btn green" 
      >Yes</button>
      <button
        @click="() => confirmDeleteAll(false)" 
        class="cancel-removal-button btn green btn-o" 
      >No</button>
  </div>
  </div>
  </div>

  </div>
</template>

<script>
import * as d3 from "d3";
import simplify from 'simplify-js';
import { ref } from 'vue';

export default {
  name: 'FarmDrawScheme',
  inject: ['bedEdition'],
  data() {
    return {
      dragging: false,
      drawing: false,
      stroke_color: '#444',
      stroke_width: 3,
      eraser_radius: 20,
      mode: '',
      tooltips: ref([]),
      newTextInsert: {
        active: false,
        points: [],
        text: ''
      },
      textToRemove: {
        active: false,
        points: [],
        id: ''
      },
      insertedTexts: [...this.bedEdition.mapDraw.textsData] || [],
      toolsHelper: '',
      straightLinesList: [],
      ptdata: [],
      session: [],
      lnsession: [],
      txsession: [],
      insertElementHeight: 48,
      groupsToRemove: [],
      removeAllContent: {
        active: false,
        message: 'Are you sure you want to delete the design?'
      },
      addHelperTooltip: false,
      cancelHelperTooltip: false
    }
  },
  computed: {
    viewBox() {
      return `0 0 ${960} ${500}`;
    },
    newTextInsertComputed() {
      return Object.assign({}, this.newTextInsert)
    }
  },
  methods: {
    // old methods with tooltip appearance and click
    // insertTooltip(points) {
    //   console.log(points);
    //   this.tooltips.push({points, id: this.tooltips.length})
    // },
    // addBedProfile(points) {
    //   this.bedEdition.editMap('imageData', points);
    //   this.bedEdition.editionMode.value = 'create';
    // },
    switchToolsHelper(tool, mode) {
      if (mode) {
        this.toolsHelper = tool;
      } else {
        this.toolsHelper = '';
      }
    },
    insertTooltip(points) {
      console.log(points);
      this.bedEdition.editMap('imageData', points);
      this.bedEdition.editMap('imageParams', {
        stroke_color: this.stroke_color,
        stroke_width: this.stroke_width
      });
    },
    insertLines(points) {
      this.bedEdition.editMap('linesData', points);
      this.bedEdition.editMap('linesParams', {
        stroke_color: this.stroke_color,
        stroke_width: this.stroke_width
      });
    },
    insertText(points) {
      this.bedEdition.editMap('textsData', points);
      this.bedEdition.editMap('textsParams', {
        stroke_color: this.stroke_color
      });
    },
    deleteAll() {
      this.removeAllContent.active = true;
    },
    confirmDeleteAll(confirmed) {
      const self = this;
      
      if (confirmed) {
        self.eraseContent();  
      }
      self.removeAllContent.active = false;
    },
    eraseContent() {
      const self = this;
      this.bedEdition.editMap('imageData', []);
      this.bedEdition.editMap('imageParams', {
        stroke_color: this.stroke_color,
        stroke_width: this.stroke_width
      });
      self.ptdata = [];
      self.session = [];
      self.lnsession = [];
      self.txsession = [];
      this.bedEdition.editMap('linesData', []);
      this.bedEdition.editMap('linesParams', {
        stroke_color: this.stroke_color,
        stroke_width: this.stroke_width
      });
      self.straightLinesList = [];
      this.bedEdition.editMap('textsData', []);
      this.bedEdition.editMap('textsParams', {
        stroke_color: this.stroke_color
      });
      self.insertedTexts = [];
      self.groupsToRemove = [];
      d3.select('#svg-texts').selectAll('text').remove();
      d3.select('#texts').selectAll('text').remove();
      d3.select('#paths').selectAll('path').remove();
      d3.select('#lines').selectAll('path').remove();
      d3.selectAll('.svg-text-group').remove();
    },
    addTextToMap(points) {
      const self = this;

      var svg = d3.select("svg.map-farm")

      var g_texts = svg.append("g").attr("id", "svg-texts")

      // const removeText = (textElementEv) => {
      //   if (self.mode === 'erase') {
      //     console.log(textElementEv, 'textElementEv');
      //     self.textToRemove.active = true;

      //     self.textToRemove.points = d3.pointer(textElementEv);

      //     self.textToRemove.id = textElementEv.target.id;
      //   }
      // }

      const textLines = self.newTextInsert.text.split(/\r?\n/);

      const groupId = self.insertedTexts.length;

      const textGroup = g_texts
                          .append('g')
                          .attr('class', 'svg-text-group')
                          .attr('id', `svg-text-group-${groupId}`);
      
      for (let i=0; i < textLines.length; i++) {
        textGroup
          .append("text")
          .attr("x", points[0])
          // .attr("y", points[1])
          .attr("y", points[1] + (17 * i))
          .attr("id", `svg-text-${self.insertedTexts.length}`)
          .style("fill", self.stroke_color)
          .style("font-size", "18px")
          // .style("font-weight", "bold")
          .style("font-family", "Gilroy-Semibold")
          // .text(self.newTextInsert.text)
          .text(textLines[i])
          // .on("touchstart", removeText)
          // .on("mouseenter", removeText);
          // font-family, font-weight

        self.insertedTexts.push({
          // points,
          points: [points[0], points[1] + (17 * i)],
          // text: `${self.newTextInsert.text}`,
          text: `${textLines[i]}`,
          class: `svg-text-group-${groupId}`,
          id: `svg-text-${self.insertedTexts.length}`
        });
      }

      self.insertText([...self.insertedTexts]);

      self.newTextInsert.active = false;
      self.newTextInsert.points = [];
      self.newTextInsert.text = '';

      self.turnOnTextEdit();
      
    },
    cancelTextToMap() {
      const self = this;

      self.newTextInsert.active = false;
      self.newTextInsert.points = [];
      self.newTextInsert.text = '';
    },
    confirmTextRemoval(confirmed) {
      const self = this;
      if (confirmed) {
        d3.select(`#${self.textToRemove.id}`).remove();
        self.insertedTexts.splice(self.insertedTexts.findIndex(txt => txt.id === self.textToRemove.id),1);
        console.log(self.insertedTexts, 'self.insertedTexts');
        self.insertText([...self.insertedTexts]);
      }
      self.textToRemove.active = false;
      self.textToRemove.points = [];
      self.textToRemove.id = '';
    },
    confirmGroupTextRemoval(confirmed, groupId) {
      const self = this;
      if (confirmed) {
        d3.select(`#${groupId}`).remove();
        // self.insertedTexts.splice(self.insertedTexts.findIndex(txt => txt.id === self.textToRemove.id),1)
        self.insertedTexts = self.insertedTexts.filter(it => it.class !== groupId);
        self.insertText([...self.insertedTexts]);
        self.groupsToRemove = self.groupsToRemove.filter(group => group.id !== groupId)
      }
      // self.textToRemove.active = false;
      // self.textToRemove.points = [];
      // self.textToRemove.id = '';
    },
    alignTextElements () {
      const self = this;

      self.groupsToRemove.forEach(gr => {
        const groupTextElement = d3.select(`#${gr.id}`)?.node()?.getBBox() || {};

        gr.points = [(gr.points[0] + (groupTextElement.width || 0)), gr.points[1]]
      });
    },
    turnOnTextEdit() {
      const self = this;

      for (let i=0; i < self.insertedTexts.length; i++) {
        const groupId = self.insertedTexts[i].class;

        for (let j = 0; j <= self.groupsToRemove.length; j++) {
          const previousId = self.groupsToRemove[j - 1]?.id;
          const groupTextElement = d3.select(`#${groupId}`)?.node()?.getBBox() || {};

          if (!self.groupsToRemove[j]) {
            if (previousId) {
              if (previousId !== groupId) {
                const groupExists = self.groupsToRemove.find(gr => gr.id === groupId);
                
                if (!groupExists) {
                  self.groupsToRemove.push({
                    id: groupId,
                    points: [(self.insertedTexts[i].points[0] + (groupTextElement.width || 0)), self.insertedTexts[i].points[1]]
                  })
                }
              }
            } else {
              console.log(self.groupsToRemove.find(gr => gr.id !== groupId), 'self.groupsToRemove');
              // self.groupsToRemove.find(gr => gr.id !== groupId) ?
                self.groupsToRemove.push({
                  id: groupId,
                  points: [(self.insertedTexts[i].points[0] + (groupTextElement.width || 0)), self.insertedTexts[i].points[1]]
                }) //:
                // self.groupsToRemove.forEach(gr => {
                //   return {
                //     ...gr,
                //     points: [(self.insertedTexts[i].points[0] + (groupTextElement.width || 0)), self.insertedTexts[i].points[1]]
                //   }
                // })
            }
          }
        }
      }
    },
    turnOnDraw () {
      this.mode = 'draw';
    },
    turnOnErase () {
      this.mode = 'erase';
    },
    turnOnBrush () {
      this.mode = 'brush'
    },
    turnOnStraight () {
      this.mode = 'straight'
    },
    turnOnText () {
      this.mode = 'text'
    },
    // turnOnTextEdit () {
    //   this.mode = 'edit-text'
    // },
    selectStrokeWidth (ev) {
      this.stroke_width = ev.target.value;
    },
    selectEraserRadius (ev) {
      this.eraser_radius = ev.target.value;
    },
    selectColor(ev) {
      this.stroke_color = ev.target.value;
    },
    switchAddHelper (toggle) {
      this.addHelperTooltip = toggle;
    },
    switchCancelHelper (toggle) {
      this.cancelHelperTooltip = toggle;
    },
    svg(pointsArray, linesArray, textsArray) {

      const self = this;

      var margin = {top: 0, right: 0, bottom: 0, left: 0},
          width = 960 - margin.left - margin.right,
          height = 500 - margin.top - margin.bottom;

        var eraseLine = d3.line()
              .x(function(d) { return d[0] })
              .y(function(d) { return d[1] })
              .curve(d3.curveBundle.beta(1)),
          paths = [...pointsArray],
          erase_path = {};

      this.mode = 'draw';

      // var npoints = 100;
      self.ptdata = [...pointsArray] || []; 
      self.session = [...pointsArray] || [];
      // var lndata = [...linesArray] || [];
      self.lnsession = [...linesArray] || [];
      // var txdata = [...textsArray] || [];
      self.txsession = [...textsArray] || [];
      var path;
      var drawing = false;
      var erasing = false;

      var output = d3.select('#output');

      var line = d3.line()
          // .interpolate("bundle") // basis, see http://bl.ocks.org/mbostock/4342190
          // .tension(1)
          .x(function(d) { return d[0] /*d.x;*/ })
          .y(function(d) { return d[1] /*d.y;*/ })
          .curve(d3.curveBundle.beta(1));

      var svg = d3.select("svg.map-farm")
          // .attr("width", width + margin.left + margin.right)
          // .attr("height", height + margin.top + margin.bottom)


      // -----------------


      // if (mode === 'erase') {
          // var mtouch = mtouch_events()
              // .on('touch', touch)
              // .on('drag', drag)
              // .on('release', release);

          svg.selectAll('g').remove();

          var g_paths = svg.append("g")
              .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
              .attr('id', 'paths');

          var g_straight_lines = svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .attr('id', 'lines');

          // var overlay = svg.append('rect')
          svg.append('rect')
            .style('fill', 'none')
            .style('pointer-events', 'all')
            .attr("width", width)
            .attr("height", height)
              
          d3.select('rect')
              .on('mousedown', touch)
              .on('touchstart', touch)
              // .on('touchmove', drag)
              // .on('mousemove', drag)
              .on('touchend', release)
              // .on('touchleave', release)
              .on('mouseup', release)
              // .on('mouseleave', release);
              // .call(mtouch);
        
          var g_erase = svg.append('g')
              .attr('id', 'erase');

          var g_texts = svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .attr('id', 'texts');
          // mtouch.frame(overlay.node());
      // }

      // if (mode === 'draw') {

          svg
            .on("mousedown", listen)
            .on("touchstart", listen)
            .on("touchend", ignore)
            .on("touchleave", ignore)
            .on("mouseup", ignore)
            .on("mouseleave", ignore);


          // ignore default touch behavior
          // var touchEvents = ['touchstart', 'touchmove', 'touchend'];
          // touchEvents.forEach(function (eventName) {
          //   document.body.addEventListener(eventName, function(e){
          //     e.preventDefault();
          //   });  
          // });
      // }

      for (let onePath of self.session) {
        console.log(onePath);
        g_paths.append("path") // start a new line
          .data([onePath])
          .attr("class", "line")
          .attr("d", line)
          .style('stroke', self.stroke_color)
          .style('stroke-width', self.stroke_width);
      }

      for (let oneLine of self.lnsession) {
        g_straight_lines.append("path") // start a new line
          // .data([self.ptdata])
          .data([oneLine])
          .attr("class", "line")
          .attr("d", line)
          .style('stroke', self.stroke_color)
          .style('stroke-width', self.stroke_width);
      }

      // const removeText = (textElementEv) => {
      //   if (self.mode === 'erase') {
      //     console.log(textElementEv, 'textElementEv');
      //     self.textToRemove.active = true;

      //     self.textToRemove.points = d3.pointer(textElementEv);

      //     self.textToRemove.id = textElementEv.target.id;
      //   }
      // }

      self.insertedTexts = [...self.txsession];

      self.turnOnTextEdit();

      let textGroup;

      for (let oneGroup of self.groupsToRemove) {
        textGroup = g_texts
                      .append('g')
                      .attr('class', 'svg-text-group')
                      .attr('id', `${oneGroup.id}`);

        const textItems = self.insertedTexts.filter(it => it.class === oneGroup.id);

        for (let oneText of textItems) {
          textGroup
            .append("text")
            .attr("x", oneText.points[0])
            // .attr("y", points[1])
            .attr("y", oneText.points[1])
            .attr("id", `${oneText.id}`)
            .style("fill", self.stroke_color)
            .style("font-size", "18px")
            // .style("font-weight", "bold")
            .style("font-family", "Gilroy-Semibold")
            // .text(self.newTextInsert.text)
            .text(oneText.text)
        }
      }

      self.turnOnTextEdit();
      self.alignTextElements();

      // for (let oneText of self.txsession) {
      // // for (const [index, oneText] of txsession.entries()) {
      //   // self.insertedTexts.push({...oneText, id: `svg-text-edited-${index + 1}`});
      //   g_texts
      //     .append("text")
      //     .attr("x", oneText.points[0])
      //     .attr("y", oneText.points[1])
      //     .attr("id", `${oneText.id}`)
      //     .style("fill", self.stroke_color)
      //     .style("font-size", "18px")
      //     .style("font-weight", "bold")
      //     .text(oneText.text)
      //     .on("touchstart", removeText)
      //     .on("mouseenter", removeText);
      // }

      let xy0, 
        // straightPath, 
        xy1,
        keep = false, 
        straightLine = d3.line()
                 .x(function(d){ return d[0]; })
                 .y(function(d){ return d[1]; });
        self.straightLinesList = [...linesArray] || [];

      function listenStraight (ev) {

        keep = true;
        xy0 = d3.pointer(ev);
        
        // straightPath = g_paths
                g_straight_lines
                  .append('path')
                  .attr('d', line([xy0, xy0]))
                  .attr('id', `straight-line-${self.straightLinesList.length}`)
                  .style('stroke', self.stroke_color)
                  .style('stroke-width', self.stroke_width);

        if (ev.type === 'mousedown') {
          svg.on("mousemove", onmoveStraight);
        } else {
          svg.on("touchmove", onmoveStraight);
        }
      }
      function onmoveStraight (ev) {
        if (keep) {
          xy1 = d3.pointer(ev).map(function(x){ return x - 1; });
          const Line = straightLine([xy0, xy1]);
          console.log(Line);
          d3.selectAll(`#straight-line-${self.straightLinesList.length}`).attr('d', Line);
        }
      }
      function ignoreStraight(ev) {
        console.log(ev);
        keep = false;
        self.straightLinesList.push([xy0,xy1]);
        self.insertLines(self.straightLinesList);
      }
      function listenText (ev) {
        console.log(ev);

        self.newTextInsert.active = true;

        self.newTextInsert.points = d3.pointer(ev);

      }

      function listen (ev) {
        if (self.mode === 'erase') return;
        if (self.mode === 'straight') {
          listenStraight(ev);
          return;
        }
        if (self.mode === 'text') {
          listenText(ev);
          return
        }
        drawing = true;
        output.text('event: ' + ev.type + '; mode: ' + self.mode);
        self.ptdata = []; // reset point data
        path = g_paths.append("path") // start a new line
          .data([self.ptdata])
          .attr("class", "line")
          .attr("d", line)
          .style('stroke', self.stroke_color)
          .style('stroke-width', self.stroke_width);

        paths.push(self.ptdata);

        if (ev.type === 'mousedown') {
          svg.on("mousemove", onmove);
        } else {
          svg.on("touchmove", onmove);
        }
      }

      function ignore (ev) {
          console.log(ev, 'works instead of');
        if (self.mode === 'erase') return;
        if (self.mode === 'straight') {
          ignoreStraight(ev);
          return;
        }
        if (self.mode === 'text') return;
        var before, after;
        output.text('event: ' + ev.type);
        svg.on("mousemove", null);
        svg.on("touchmove", null);

        // skip out if we're not drawing
        if (!drawing) return;
        drawing = false;

        before = self.ptdata.length;
        console.group('Line Simplification');
        console.log("Before simplification:", before)
        
        // self.insertTooltip([...session,...self.ptdata]);
        self.insertTooltip([...self.session,[...self.ptdata]]);
        // simplify
        // self.ptdata = simplify(self.ptdata);
        const ptdataSimplified = simplify(self.ptdata);
        after = ptdataSimplified.length;

        console.log("After simplification:", self.ptdata.length)
        console.groupEnd();

        var percentage = parseInt(100 - (after/before)*100, 10);
        output.html('Points: ' + before + ' => ' + after + '. <b>' + percentage + '% simplification.</b>');

        // add newly created line to the drawing session
        self.session.push(self.ptdata);
        
        // redraw the line after simplification
        tick();

        // self.insertTooltip(self.ptdata);
      }


      function onmove (e) {
        var type = e.type;
        var point;

        if (type === 'mousemove') {
          point = d3.pointer(e);
          output.text('event: ' + type + ': ' + d3.pointer(e));
        } else {
          // only deal with a single touch input
          // point = d3.touches(this)[0];
          // output.text('event: ' + type + ': ' + d3.touches(this)[0]);
          e.preventDefault();
          point = d3.pointer(e.touches[0], e.currentTarget);
          output.text('event: ' + type + ': ' + d3.pointer(e.touches[0]));
        }

        // push a new data point onto the back
        // self.ptdata.push({ x: point[0], y: point[1] });
        self.ptdata.push(point);
        tick();
      }

      function tick() {
        path.attr("d", function(d) { return line(d); }) // Redraw the path:
      }



      function touch(ev) {
          if (self.mode === 'draw') return;
          if (self.mode === 'straight') return;
          if (self.mode === 'text') return;
          // var f = ;
          erasing = true;
          // erase_path.data = [[ev.x, ev.y]];
          // erase_path.data = [[ev.offsetX, ev.offsetY]];
          erase_path.data = [[ d3.pointer(ev)[0], d3.pointer(ev)[1] ]];

          if (!erase_path.el) {
              erase_path.el = g_erase.append('path')
                            .style('fill', 'none')
                            .style('stroke', 'gray')
                            .style('opacity', 0.3)
                            .style('stroke-width', self.eraser_radius*2)
                            .style('stroke-linecap', 'round')
                            .style('stroke-linejoin', 'round');
          } else {
              erase_path.el.style('stroke', 'gray');
          }
          erase_path.el.datum(erase_path.data)
                      .attr('d', function(d) { return eraseLine(d) + 'Z'});

          d3.select('rect')
              .on('touchmove', drag)
              .on('mousemove', drag);
      }

      function drag(e) {

        if (self.mode === 'draw') return;
        if (self.mode === 'straight') return;
        if (self.mode === 'text') return;
          // var f = d3.event.finger;
        // if (!erasing) return;
        // erase_path.data.push([e.x, e.y]);
        // erase_path.data.push([e.offsetX, e.offsetY]);
        erase_path.data.push([d3.pointer(e)[0], d3.pointer(e)[1]]);

        erase_path.el.attr('d', eraseLine);

        g_erase.select('path').on('mouseup', release)
      }

      function release() {
        if (self.mode === 'draw') return;
        if (self.mode === 'straight') return;
        if (self.mode === 'text') return;

          d3.select('rect')
              .on('touchmove', null)
              .on('mousemove', null);

        if (!erasing) return;
        erasing = false;
          paths = self.$erase({sourceArr: paths, resultArr: erase_path.data});
          update(paths);
          self.straightLinesList = self.$erase({sourceArr: self.straightLinesList, resultArr: erase_path.data});
          updateLines(self.straightLinesList);
          g_erase.select('path').style('stroke', 'transparent');
      }


      function update(paths) {
          console.log(paths);
          var p = g_paths.selectAll('path')
            .data(paths);

          self.insertTooltip([...paths]);

          p.enter()
          .append('path')
          .style('fill', 'none')
          .style('stroke', self.stroke_color)
          .style('stroke-width', self.stroke_width)
          .style('stroke-linecap', 'round')
          .style('stroke-linejoin', 'round')
          .attr('d', eraseLine);

          p.attr('d', eraseLine);

          p.exit().remove();
      }

      function updateLines(lines) {
          var p = g_straight_lines.selectAll('path')
            .data(lines);

          self.insertLines([...self.straightLinesList]);

          p.enter()
          .append('path')
          .style('fill', 'none')
          .style('stroke', self.stroke_color)
          .style('stroke-width', self.stroke_width)
          .style('stroke-linecap', 'round')
          .style('stroke-linejoin', 'round')
          .attr('d', eraseLine);

          p.attr('d', eraseLine);

          p.exit().remove();
      }

    },
  },
  watch: {
    newTextInsertComputed: {
      handler: function (newVal, oldVal) {
        console.log(newVal, oldVal, 'newVal, oldVal');
        const textLines = newVal.text.split(/\r?\n/);
        const oldTextLines = oldVal.text.split(/\r?\n/);

        if (textLines.length > oldTextLines.length) {
          this.insertElementHeight = Number.parseInt(this.insertElementHeight) + 24;
        }
        if (textLines.length < oldTextLines.length) {
          this.insertElementHeight = Number.parseInt(this.insertElementHeight) - 24;
        }
      },
      deep: true
    }
  //   bedEdition: {
  //     handler: function (new_client, old_client) {
  //       console.log(new_client, old_client, 'new_client, old_client');

  //       const bedPoints = this.bedEdition.mapDraw.imageData;

  //       this.svg(bedPoints);
  //     },
  //     deep: true
  //   }
  },
  mounted() {
    const bedPoints = this.bedEdition.mapDraw.imageData;
    const bedLines = this.bedEdition.mapDraw.linesData;
    const bedTexts = this.bedEdition.mapDraw.textsData;
    
    if (this.bedEdition.mapDraw.imageParams) {
      this.stroke_color = this.bedEdition.mapDraw.imageParams.stroke_color;
      this.stroke_width = this.bedEdition.mapDraw.imageParams.stroke_width;
    }

    this.svg(bedPoints, bedLines, bedTexts);

    // this.svg();
  }
};
</script>

<style lang="scss">
.farm-mapping {
  width: 100%;
  margin-bottom: 1.25rem;
  svg {
  background: #ddd;
  font: 10px sans-serif;
  cursor: crosshair;
}

.line {
  cursor: crosshair;
  fill: none;
  stroke: #000;
  stroke-width: 2px;
  stroke-linejoin: round;
}

#output {
  display: none;
  position: relative;
  top: -2em;
  left: 0.67em;
  font: 12px/1.4 monospace;
}
.map-container {
  position: relative;
  .tooltip {
    // border: 3px solid #38AC89;
    border-bottom: 3px solid #38AC89;
    color: #38AC89;
    // border-radius: 10px;
    // background-color: #fff;
    position: absolute;
    width: max-content;
    padding: 0 5px;
    height: 20px;
    z-index: 9;
    font-weight: 500;
    cursor: pointer;
  }
  .new-text-insert-field {
    // border: 3px solid #38AC89;
    // border-bottom: 3px solid #38AC89;
    // color: #38AC89;
    // border-radius: 10px;
    // background-color: #fff;
    position: absolute;
    width: max-content;
    padding: 0 5px;
    height: 20px;
    z-index: 9;
    font-weight: 500;
    // cursor: pointer;
    button {
      margin-left: 0.5rem;
    }
  }
  .text-remove-buttons {
    position: absolute;
    width: max-content;
    padding: 0 5px;
    height: 20px;
    z-index: 9;
    font-weight: 500;
    display: flex;
    align-items: center;
    button {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }
}

    .button-set {
      display: flex;
      position: absolute;
      // right: 0;
      // top: -50px;
      right: 144px;
      top: -46px;
      // width: 20%;
      span {
        display: flex;
        cursor: pointer;
      }
      .tools {
        display: flex;
        text-indent: -9999px;
        -webkit-mask-position: center;
        mask-position: center;
        margin: auto;
        &.edit-text {
          width: 36px;
          height: 30px;
          -webkit-mask-image: url('@/assets/images/edit_text_design.svg');
          mask-image: url('@/assets/images/edit_text_design.svg');
          -webkit-mask-size: 36px 30px;
          mask-size: 36px 30px;
          background-color: rgba($color: #000000, $alpha: 0.2);
          &.active {
            background-color: rgba($color: #000000, $alpha: 1.0);
          }
        }
        &.pen {
          width: 36px;
          height: 30px;
          -webkit-mask-image: url('@/assets/farm_profile/pencil.svg');
          mask-image: url('@/assets/farm_profile/pencil.svg');
          -webkit-mask-size: 36px 30px;
          mask-size: 36px 30px;
          background-color: rgba($color: #000000, $alpha: 0.2);
          &.active {
            background-color: rgba($color: #000000, $alpha: 1.0);
          }
        }
        &.erase {
          width: 36px;
          height: 30px;
          -webkit-mask-image: url('@/assets/farm_profile/eraser.svg');
          mask-image: url('@/assets/farm_profile/eraser.svg');
          -webkit-mask-size: 36px 30px;
          mask-size: 36px 30px;
          background-color: rgba($color: #000000, $alpha: 0.2);
          &.active {
            background-color: rgba($color: #000000, $alpha: 1.0);
          }
        }
        &.brush {
          width: 36px;
          height: 30px;
          -webkit-mask-image: url('@/assets/farm_profile/brush.svg');
          mask-image: url('@/assets/farm_profile/brush.svg');
          -webkit-mask-size: 36px 30px;
          mask-size: 36px 30px;
          background-color: rgba($color: #000000, $alpha: 0.2);
          &.active {
            background-color: rgba($color: #000000, $alpha: 1.0);
          }
        }
        &.colors {
          // display: none;
          width: 30px;
          height: 30px;
          background-image: url('@/assets/farm_profile/colorwheel.png');
          background-size: contain;
          background-repeat: no-repeat;
          position: relative;
          input {
            opacity: 0;
            width: 100%;
            height: 100%;
            z-index: 999;
            cursor: pointer;
          }
        }
      }
      .stroke-range {
        // display: none;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .eraser-radius {
        // display: none;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
}

.remove-text-button {
  width: 2rem;
  height: 2rem;
  background-image: url('@/assets/images/updated/delete-item.png');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.cancel-removal-button {
  width: 2rem;
  height: 2rem;
  background-image: url('@/assets/images/updated/XX_White.png');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
// .remove-all-button {
//   display: flex;
//   position: absolute;
//   // left: 10.8rem;
//   top: -3.8rem;
// }
.remove-all-button {
  display: flex;
  position: absolute;
  // left: 10.8rem;
  left: auto;
  right: 36px;
  top: -3.8rem;
  width: 36px;
  height: 30px;
  -webkit-mask-size: 36px 30px;
  mask-size: 36px 30px;
  background-color: rgba($color: #000000, $alpha: 0.2);
  -webkit-mask-image: url('@/assets/images/delete_design.svg');
  mask-image: url('@/assets/images/delete_design.svg');
  &:hover {
    background-color: #000000;
  }
}

.add-text-to-map-button {
  transform: rotate(45deg);
  -webkit-mask-image: url('@/assets/images/cancel_design.svg');
  mask-image: url('@/assets/images/cancel_design.svg');
  width: 36px;
  height: 30px;
  -webkit-mask-size: 36px 30px;
  mask-size: 36px 30px;
  background-color: rgba($color: #000000, $alpha: 0.2);
  position: relative;
  &:hover {
    background-color: #000000;
  }
}
.cancel-text-to-map-button {
  -webkit-mask-image: url('@/assets/images/cancel_design.svg');
  mask-image: url('@/assets/images/cancel_design.svg');
  width: 36px;
  height: 30px;
  -webkit-mask-size: 36px 30px;
  mask-size: 36px 30px;
  background-color: rgba($color: #000000, $alpha: 0.2);
  &:hover {
    background-color: #000000;
  }
}

.add-helper-tooltip {
  &.in-draw {
    width: max-content;
    padding: 0 15px;
    height: 30px;
    position: absolute;
    z-index: 999;

    background-color: #3C3838;
    color: #fff;
    top: -20px !important;
    left: 194px !important;
    text-indent: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 !important;
    font-family: 'Inter', sans-serif;
    font-weight: 400;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: 22px !important;
      border-color: #3C3838;
      border-left: 8px solid hsl(0, 3%, 23%);
      border-bottom: 6px solid #3C3838;
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 32px !important;
      background: #3C3838;
      z-index: -1;
    }
  }
}

.cancel-helper-tooltip {
  &.in-draw {
    width: max-content;
    padding: 0 15px;
    height: 30px;
    position: absolute;
    z-index: 999;

    background-color: #3C3838;
    color: #fff;
    top: -20px !important;
    left: 235px !important;
    text-indent: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 !important;
    font-family: 'Inter', sans-serif;
    font-weight: 400;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: 22px !important;
      border-color: #3C3838;
      border-left: 8px solid hsl(0, 3%, 23%);
      border-bottom: 6px solid #3C3838;
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 32px !important;
      background: #3C3838;
      z-index: -1;
    }
  }
}

</style>