<template>
  <div class="section" ref="section">
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Inventory Purchased</h5>
<div class="info-icons">
<div class="info-icon lg top-icon tooltip"
  @click="addNewInventory"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
  data-tooltip="Add Inventory"
  v-if="isAuth('add-new-inventory')"
>
  <img src="@/assets/images/updated/new_set/Inventory_Box_Circle.png" alt="">
  <!-- <img src="@/assets/images/updated/add-item.png" alt=""> -->
    <!-- <div class="helper-tooltip" v-if="helperTooltip">Add Inventory</div> -->
</div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-wrapper">
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label">
  <span>Date Purchased</span>
  <span @click="() => sortTable('date')">
    <button :class="{active: sortOrder.date === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.date === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Item</span>
  <span @click="() => sortTable('item')">
    <button :class="{active: sortOrder.item === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.item === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Vendor</span>
  <span @click="() => sortTable('vendorName')">
    <button :class="{active: sortOrder.vendorName === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.vendorName === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span># of Items</span>
  <span @click="() => sortTableByNumber('numberOfItems')">
    <button :class="{active: sortOrder.numberOfItems === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.numberOfItems === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Weight (lbs/item)</span>
  <span @click="() => sortTableByNumber('quantity')">
    <button :class="{active: sortOrder.quantity === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.quantity === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Amount Paid</span>
  <span @click="() => sortTableByNumber('amountPaid')">
    <button :class="{active: sortOrder.amountPaid === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.amountPaid === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Total Weight (in lbs.)</span>
  <span @click="() => sortTableByTotal('total')">
    <button :class="{active: sortOrder.total === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.total === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

      <template
        v-for="(row, rowIndex) in rows"
        v-bind:key="'inventory-add-list-row' + row.id"
      >
        <tr
          v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
          :class="{'light-gray-bg': row.id === selectedAddedInventory.id}"
          @click="(ev) => selectByRow(ev, {...row})"
        >
        <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
        <td class="text-center">{{ moment(row.date, 'YYYY-MM-DD').format('MM/DD/YYYY')}}</td>
        <td>{{row.item}}</td>
        <td>{{row.vendorName }}</td>
        <td>{{row.numberOfItems}}</td>
        <td>{{row.quantity}} Lbs</td>
        <td>${{ row.amountPaid }}</td>
        <td>{{ Math.abs(Number.parseInt(row.numberOfItems) * Number.parseFloat(row.quantity)) }} Lbs</td>
        <td class="w-75">
        <div class="table-links">
        <a v-if="isAuth('edit-inventory')" href="javascript:void();" @click="selectAddedInventory({...row}, 'edit')">
          <img src="@/assets/images/edit-icon-2.svg" alt="">
        </a>
        <a v-if="isAuth('remove-inventory')" href="javascript:void();" @click="removeSelectedAddedInventory(row.id)">
          <img src="@/assets/images/trash-2.svg" alt="">
        </a>
        </div>
        </td>
        </tr>
      </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'inventoryAddListDetails'"
    :optionKey="'inventory-add-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>
</div>

<div class="removal-popup" v-if="removedInventoryId">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">Are you sure you want to delete the service?</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveRemoval(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>
</template>

<script>
import { ref, onMounted, watch, computed, inject, reactive, provide } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import DropDownList from '@/components/Shared/DropDownList.vue';

export default {
    name: "InventoryAddList",
    setup() {
        const { isAuth } = inject('mainUI');
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const addedInventories = computed(() => [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.type === 'Product'));
        const vendors = computed(() => store.state.vendors);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const removedInventoryId = ref(null);
        const { selectedAddedInventory, addNewInventory, editAddedInventory, selectAddedInventory, removeAddedInventory } = inject('addedInventoryDetails');
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const helperTooltip = ref(false);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const sortOrder = reactive({
            date: 0,
            item: 0,
            vendorName: 0,
            numberOfItems: 0,
            quantity: 0,
            amountPaid: 0,
            total: 0
        });
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...addedInventories.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByNumber = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...addedInventories.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByTotal = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(Math.abs(a.amountPaid * a.quantity)) - Number.parseInt(Math.abs(b.amountPaid * b.quantity)));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(Math.abs(b.amountPaid * b.quantity)) - Number.parseInt(Math.abs(a.amountPaid * a.quantity)));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...addedInventories.value];
                sortOrder[sortProp] = 0;
            }
        };
        const removeSelectedAddedInventory = (inventoryId) => {
            removedInventoryId.value = inventoryId;
        };
        const approveRemoval = (isApproved) => {
            if (isApproved) {
                removeAddedInventory(removedInventoryId.value);
            }
            removedInventoryId.value = '';
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectAddedInventory({ ...row }, 'represent');
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(() => ([...store.state.vendorItems]), () => {
            console.log(addedInventories.value, 'addedInventories');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            // rows.value = [...addedInventories.value];
            rows.value = [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.type === 'Product').map(originInventory => {
                return {
                    ...originInventory,
                    vendorName: vendors.value.find(vendor => vendor.id === originInventory.vendor)?.vendor
                };
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
          store.dispatch('getVendorItems');
            (addedInventories.value.length > 0) && selectAddedInventory({ ...addedInventories.value[addedInventories.value.length - 1] }, 'represent');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getAddedInventorys')
            // rows.value = [...addedInventories.value];
            rows.value = [...addedInventories.value].map(originInventory => {
                return {
                    ...originInventory,
                    vendorName: vendors.value.find(vendor => vendor.id === originInventory.vendor)?.vendor
                };
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('inventoryAddListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            section,
            boxHeader,
            toggleSection,
            addNewInventory,
            editAddedInventory,
            selectAddedInventory,
            removeAddedInventory,
            rows,
            maxPage,
            currentPage,
            helperTooltip,
            switchHelperTooltip,
            sortOrder,
            sortTable,
            sortTableByNumber,
            sortTableByTotal,
            selectedAddedInventory,
            removedInventoryId,
            removeSelectedAddedInventory,
            approveRemoval,
            selectByRow,
            moment,
            isAuth
        };
    },
    components: { DropDownList }
}
</script>

<style lang="scss" scoped>
.top-icon {
  position: relative;
}
</style>