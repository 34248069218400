<template>

<section class="section mt-1">
  <div class="border-box">
  <div class="border-box-content">

  <div class="content-block d-flex justify-content-between user-profile-header">
  <h5 class="fw-semibold">Account Information</h5>
  <span class="account-id-header desktop filled">Account ID: {{ authenticatedId }}</span>

  <div class="icon-group">
    <div class="icon green icon-o tooltip" 
      v-if="isAuth('add-user')"
      @click="createNewUser"
      data-tooltip="Add User"
      @mouseover="switchAddHelperTooltip(true)"
      @mouseout="switchAddHelperTooltip(false)"
    >
      <!-- <img src="@/assets/images/user-plus.svg" alt=""> -->
      <img src="@/assets/images/updated/064-add-user.png" alt="">
      <!-- <div class="add-helper-tooltip" v-if="addHelperTooltip">Add User</div> -->
    </div>

    <div class="icon green icon-o tooltip" 
      v-if="isAuth('edit-details') && !onlyViewMode"
      @click="changeMainField('edit', 'mode')"
      data-tooltip="Edit"
      @mouseover="switchEditHelperTooltip(true)"
      @mouseout="switchEditHelperTooltip(false)"
    >
      <!-- <img src="@/assets/images/edit.svg" alt=""> -->
      <img src="@/assets/images/updated/Edit.png" alt="">
      <!-- <div class="edit-helper-tooltip" v-if="editHelperTooltip">Edit</div> -->
    </div>

  <!-- <a href="javascript:void();" class="icon green icon-o"><img src="@/assets/images/user-plus.svg" alt=""></a>
  <a href="javascript:void();" class="icon green icon-o"><img src="@/assets/images/edit.svg" alt=""></a> -->
  </div>
  <span class="account-id-header mobile filled">Account ID: {{ authenticatedId }}</span>
  </div>

  <div class="content-block">
<div class="admin-update-form">
<div class="row justify-content-end">

<div class="col-auto">
<div class="form-item">
<p class="form-label-static">Email</p>
<input 
  type="text" 
  class="form-control" 
  v-model="mainDetails.email"
  @focus="() => {
    activated.email = true;
    focused.email = true;
  }"
  @blur="() => {
    focused.email = false;
  }"  
  :disabled="!updateIsActive"
/>
<p v-if="activated.email && mainDetails.email.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.email && ((mainDetails.email.length > 0) && !/\S+@\S+\.\S+/.test(mainDetails.email))" class="form-info red text-end">Please use correct email format</p>
</div>
</div>

<div class="col-auto">
<div class="form-item password-field">
<p class="form-label-static">Password</p>
<input 
  :type="(passwordIsVisible && updateIsActive) ? 'text' : 'password'"
  class="form-control" 
  v-model="mainDetails.password"
  @focus="() => {
    activated.password = true;
    focused.password = true;
  }"
  @blur="() => {
    focused.password = false;
  }"
  :disabled="!updateIsActive"
  :placeholder="passwordMask"
/>
<div v-if="updateIsActive" class="password-icon" :class="{visible: !passwordIsVisible, hidden: passwordIsVisible}" @click="() => {passwordIsVisible = !passwordIsVisible}"></div>
<p v-if="activated.password && mainDetails.password.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.password && mainDetails.password.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-auto update-btn-sm-wrap">
  <button v-if="updateIsActive" type="submit" class="btn green update-btn-sm" @click="() => switchUpdatable(false)">Save</button>
  <button v-else type="submit" class="btn green update-btn-sm" @click="() => switchUpdatable(true)">Update</button>
</div>

</div>
</div>
</div>

<div class="content-block toggle-switch-wrap justify-content-end mr-170">

<div class="toggle-switch">
<label class="switch"><input type="checkbox" v-model="mainDetails.multifactor" disabled><span class="slider round" :class="{'default-checked': mainDetails.multifactor}"></span></label>
<p class="fw-semibold gray">Multi Factor Authentication</p>
</div>

<div class="checkbox-group">
<div class="radio radio-mobile"><input type="radio" name="mobileEmail" id="mobile" value="mobile" v-model="mainDetails.multifactorType" disabled><label for="mobile">Mobile</label></div>
<div class="radio"><input type="radio" name="mobileEmail" id="email" value="email" v-model="mainDetails.multifactorType" disabled><label for="email">Email</label></div>
</div>

</div>

  <div class="content-block">
  <div class="intro-grid">

  <div class="intro-grid-column">
  <p class="title">Account Name</p>
  <h6>{{ mainDetails.accountName }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">First Name</p>
  <h6>{{ mainDetails.firstName }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Last Name</p>
  <h6>{{ mainDetails.lastName }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Title</p>
  <h6>{{ mainDetails.title }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Mobile Phone</p>
  <h6>{{ mainDetails.mobilePhone }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Work Phone</p>
  <h6>{{ mainDetails.workPhone }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Address 1</p>
  <h6>{{ mainDetails.address }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Address 2</p>
  <h6>{{ mainDetails.address2 }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">City</p>
  <h6>{{ mainDetails.city }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">State</p>
  <h6>{{ mainDetails.state }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Zip</p>
  <h6>{{ mainDetails.zip }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Country</p>
  <h6>{{ mainDetails.country }}</h6>
  </div>

  </div>
  </div>

  <div class="content-block">
  <h6 class="sub-headding">User Profile</h6>
  <div class="user-role">
  <div class="row">
  <div class="col-auto">
  <p class="title fw-semibold gray">Role Type</p>
  </div>
  <div class="col">
  <div class="row">
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="admin" id="admin" v-model="mainDetails.userRole" disabled><label for="admin">Admin</label></div></div>
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="edit" id="editonly" v-model="mainDetails.userRole" disabled><label for="editonly">Edit</label></div></div>
    <!-- <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="contribute" id="contributeonly" v-model="mainDetails.userRole" disabled><label for="contributeonly">Contribute</label></div></div> -->
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="read" id="readonly" v-model="mainDetails.userRole" disabled><label for="readonly">Read</label></div></div>
  </div>
  </div>
  </div>
  </div>
  </div>

  <div class="content-block d-lg-flex justify-content-between">
  <div class="checkbox-group task-type">
    <div v-if="employeeHelper" class="employee-tooltip">
      Allows for calculating wages!
    </div>
    <div class="row">
      <div class="col-auto" style="position: relative;">
        <p 
          class="title fw-semibold gray checkbox-title"
          @mouseover="switchEmployeeHelper(true)"
          @mouseout="switchEmployeeHelper(false)"  
          style="position: absolute; right: 1rem;"
        >User Type</p>
        <p class="title fw-semibold gray checkbox-title" style="opacity: 0; pointer-events: none;">Role Type</p>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-auto">
            <div class="radio"><input type="radio" name="taskType" id="regular" value="salary"  v-model="mainDetails.employeeType" disabled><label for="regular">Salary</label></div>
          </div>
          <div class="col-auto">
            <div class="radio"><input type="radio" name="taskType" id="overtime" value="parttime" v-model="mainDetails.employeeType" disabled><label for="overtime">Part-time</label></div>
          </div>
          <div class="col-auto">
            <div class="radio"><input type="radio" name="taskType" id="other" value="other" v-model="mainDetails.employeeType" disabled><label for="other">Other</label></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div class="content-block" v-if="mainDetails.employeeType !== 'other'">
  <div class="user-grade">
  <div class="row">
  <div class="col-auto" style="position: relative;">
    <p class="title fw-semibold gray checkbox-title" style="opacity: 0; pointer-events: none;">Role Type</p>
    <p class="title fw-semibold gray checkbox-title"
      style="position: absolute; right: 1rem;top: 0; bottom: 0; display: flex; align-items: center;"
    >Grade</p>
  </div>
  <div class="col-auto">
    <input type="text" class="form-control sm" v-model="mainDetails.grade" disabled />
    <!-- <h6 class="fw-semibold" style="padding-left: 0.5rem;">{{ mainDetails.grade }}</h6> -->
  </div>
  </div>
  </div>
  </div>

  <div :key="`primary-details-attachments-${renderAttachments}`">
    <FileAttachments :section="'primary'" v-bind:filled="true" />
  </div>
  <!-- <div class="content-section">
  <div class="buttons">
  <div class="row">
  <div class="col-auto"><button type="submit" class="btn green">Save</button></div>
  <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o">Cancel</button></div>
  </div>
  </div>
  </div> -->
  
  </div>
  </div>

  <div v-if="notUpdated.active">
    <UserNotification
      v-bind:messageText="notUpdated.message"
    />
  </div>
</section><!--//End Hero-->

<template v-if="updateIsActive">
  <div class="submit-popup-wrapper-fixed">
    <div class="form-modal">
      <!-- <span class="form-modal-close" @click="() => {gradeFormDialog = false}"><img src="@/assets/images/close-green-xs.png" alt=""></span> -->
      <ResetPasswordPopup />
    </div>
  </div>
</template>

</template>

<style></style>

<script>
import { inject, onMounted, ref, reactive, watch, provide } from 'vue';
import { useRouter } from 'vue-router'
import { useStore } from "vuex";
// import roleLabels from '@/sharedData/rolesList';
import FileAttachments from "@/components/Shared/FileAttachments.vue";
import UserNotification from '@/components/Shared/UserNotification.vue';
import ResetPasswordPopup from "@/components/Pages/ResetPassword/ResetPasswordPopup.vue";

export default {
  name: "ProfileDetails",
  components: {
    FileAttachments,
    UserNotification,
    ResetPasswordPopup
},
  setup() {
    const store = useStore();

    const { mainDetails, sameAddress, changeMainField } = inject('mainDetails');
    // const { userRole } = inject('userRole');
    const { isAuth } = inject('mainUI');
    const { onlyViewMode } = inject('userProfileCommon');

    const router = useRouter();

    const activated = reactive({
      email: false,
      password: false
    });

    const focused = reactive({
      email: false,
      password: false
    });

    const notUpdated = reactive({
      active: false,
      message: 'Please save, before proceeding'
    })

    const updateIsActive = ref(false);

    const passwordIsVisible = ref(false);

    const authenticatedId = ref('');

    const passwordMask = ref('');

    const renderAttachments = ref(0);

    const switchUpdatable = (isUpdatable) => {
      if (isUpdatable) {
        updateIsActive.value = true;
      } else {
        updateIsActive.value = false;
        if (mainDetails.email && mainDetails.password) {
          // updateIsActive.value = false;

          store.dispatch("savePrimaryUser", {
            section: "mainDetails",
            data: {
              ...mainDetails
            }
          });
        } else {
          if (!mainDetails.email) activated.email = true;
          if (!mainDetails.password) activated.password = true;

          setTimeout(() => {
            const firstError = document.getElementsByClassName('form-info red text-end')[0];
          
            const viewPortOffset = firstError.getBoundingClientRect();

            console.log(viewPortOffset.top, Math.abs(viewPortOffset.top + 72));

            document.getElementsByTagName('main')[0].scroll({
              top: firstError ? Math.abs(viewPortOffset.top + 72) : 0,
              left: 0,
              behavior: 'smooth'
            })
          }, 100);

          notUpdated.active = true;

          setTimeout(() => {
            notUpdated.active = false;
          }, 5000)
        }
      }
    }

    const createNewUser = () => {
      router.push({ name: 'page', params: { pagename: 'secondary-user', source: 'user-profile' } })
    }

    const editHelperTooltip = ref(false);

    const addHelperTooltip = ref(false);

    const employeeHelper = ref(false);

    const switchEmployeeHelper = (toggle) => {
      employeeHelper.value = toggle;
    }

    const switchEditHelperTooltip = (toggle) => {
      editHelperTooltip.value = toggle;
    }

    const switchAddHelperTooltip = (toggle) => {
      addHelperTooltip.value = toggle;
    }

    watch(() => ({...store.state.primaryUser.mainDetails}), (newVal) => {
      for (let prop in mainDetails) {
        changeMainField(newVal[prop], prop);
      }
    })

    watch(() => ({...store.state.authenticated}), (newVal) => {
      authenticatedId.value = newVal._id || newVal.id;

      if (newVal.email && (updateIsActive.value === false)) {
        passwordMask.value = '********';
      }
    });

    watch(updateIsActive, (newVal) => {
      if (newVal) {
        passwordMask.value = '';
      } else {
        if (store.state.authenticated.email) {
          passwordMask.value = '********';
        }
      }
    })

    watch(() => ({...store.state.primaryUser}), (newVal) => {
      if (newVal) {
        // if (registration.value) {
          renderAttachments.value = renderAttachments.value + 1;
        // }
      }
    })

    watch(() => ({...store.state.attachments.primary}), (newVal) => {
      if (newVal) {
        // if (registration.value) {
        if (renderAttachments.value < 1) {
          console.log(newVal, 'attachments')
          renderAttachments.value = renderAttachments.value + 1;
        }
        // }
      }
    })

    provide('passwordReset', {
      updateIsActive,
      switchUpdatable
    });

    onMounted(() => {

      if (
        store.state.primaryUser?.mainDetails?.submitted ||
        store.state.primaryUser?.mainDetails?.email
      ) {
        for (let prop in mainDetails) { 
          changeMainField(store.state.primaryUser?.mainDetails[prop], prop);
        }
        if (router.currentRoute.value.params.source === 'employee-compensation') {
          if (!mainDetails.grade) {
            changeMainField('edit', 'mode');
            changeMainField(false, 'submitted');
          } else {
            changeMainField('represent', 'mode');
            changeMainField(true, 'submitted');
          }
        } else {
          changeMainField('represent', 'mode');
          changeMainField(true, 'submitted');
        }
      }

      authenticatedId.value = store.state.authenticated._id || store.state.authenticated.id;
      if (store.state.authenticated.email && (updateIsActive.value === false)) {
        passwordMask.value = '********';
      }
    })
    
    return {
      mainDetails,
      // userRole,
      sameAddress,
      changeMainField,
      isAuth,

      editHelperTooltip,
      addHelperTooltip,
      switchEditHelperTooltip,
      switchAddHelperTooltip,
      employeeHelper,
      switchEmployeeHelper,
      createNewUser,
      activated,
      focused,
      updateIsActive,
      switchUpdatable,
      notUpdated,
      passwordIsVisible,
      authenticatedId,
      passwordMask,
      renderAttachments,
      onlyViewMode
    };
  },
};
</script>

<style lang="scss" scoped src="@/styles/pages/UserProfile/ProfileDetails.scss"></style>
