<template>
  <FarmProfileHeader />
  <FarmHistoryFarmProfile v-if="editionMode" />
  <FarmHistoryMap v-if="plan.mapDraw.farmName" />
  <FarmHistoryBedProfile v-if="plan.farm.beds.length > 0" />
  <FarmHistoryBedList v-if="plan.farm.beds.length > 0" />
  <FarmHistoryList />
</template>

<script>
import { defineComponent, reactive, ref, provide, watch } from 'vue';
// ref, provide, reactive, inject, watch, onMounted
import FarmProfileHeader from './FarmProfileHeader.vue';
import { useStore } from 'vuex';
import FarmHistoryMap from './FarmHistoryMap.vue';
import FarmHistoryList from './FarmHistoryList.vue';
import FarmHistoryFarmProfile from './FarmHistoryFarmProfile.vue';
import FarmHistoryBedList from './FarmHistoryBedList.vue';
import FarmHistoryBedProfile from './FarmHistoryBedProfile.vue';

export default defineComponent({
    name: 'FarmPlan',
    components: { FarmProfileHeader, FarmHistoryMap, FarmHistoryList, FarmHistoryFarmProfile, FarmHistoryBedList, FarmHistoryBedProfile },
    setup() {
      const store = useStore();

      const plan = reactive({

        id: '',
        selectedFarmId: '',

        farm: {
          beds: []
        },
        map: {
          id: '',
          year: '',
          season: '',
          farmName: '',
          bedNumber: '',
          acreage: '',
          primaryCrop: '',
          
          imageData: [],
          imageParams: {},

          linesData: [],
          linesParams: {},

          textsData: [],
          textsParams: {}
        },
        mapDraw: {},
        crop: {},
      });

      const selectedPlan = reactive({
        ...plan
      });

      const editionMode = ref('');

      const bed = reactive({
        id: '',
        year: '',
        season: '',
        farmName: '',
        bedNumber: '',
        acreage: '',
        soilType: '',
        irrigation: '',
        IOTdeviceType: '',
        primaryCrop: '',
        secondaryCrop: '',
      });

      const crop = ref({
        id: '',
        farmName: '',
        year: '',
        cropCategory: '',
        dateSeed: '',
        projHarvestAmount: '',
        bed: '',
        season: '',
        crop: '',
        harvestDate: '',
        projWastage: '',
        // acreage: '',
        cropCycle: '',
        variety: '',
        isDateSeed: false,
        seedDays: '',
        isDateHarvest: false,
        harvestDays: '',
        
        sourceType: '',
        sourceId: ''
      });

      const selectBed = (bedData) => {
        if (bedData) {
          for (let field in bedData) {
            bed[field] = bedData[field]
          }
        }
      }

      const editPlan = () => {};

      const selectPlan = (planData, edMode) => {
        for (let prop in planData) {
          plan[prop] = planData[prop];
        }
        console.log(plan, 'plan');
        editionMode.value = edMode;
      };
      
      const removePlan = (planId) => {
        store.dispatch('removeFarmPlan', planId);
      };

      watch(() => (bed.id), (newVal) => {
        if (newVal) {
          console.log('crop should change', newVal);
          if (Object.entries(plan.crop).length > 0) {
            crop.value = plan.crop;
          } else {
            for (let prop in crop.value) {
              crop.value[prop] = bed[prop];
            }
          }
        }
      });

      provide('planDetails', {
        plan,
        editionMode,
        editPlan,
        selectPlan,
        removePlan,
        selectedPlan
      });

      provide('bedEdition', {
        bed,
        selectBed,
        crop
        // mapDraw: {...plan}
      })

      return {
        plan,
        editionMode
      }

    }
})
</script>