<template>
  <div class="q-pa-lg secondary-user-profile-view" style="width: 100%">
    <SecondaryProfileHeader />
    <q-card class="user-details-filled">
      <q-card-section>
        <div class="row wrapper">
          <div class="field">
            <span class="label">Account Name</span>
            <span class="field-value">{{ mainDetails.accountName }}</span>
          </div>

          <div class="field">
            <span class="label">Title</span>
            <span class="field-value">{{ mainDetails.title }}</span>
          </div>

          <div class="field">
            <span class="label">Role</span>
            <span class="field-value">{{ mainDetails.role }}</span>
          </div>

          <div class="field">
            <span class="label">Multi Factor Authentication</span>
            <span class="field-value">
              <q-toggle v-model="mainDetails.multifactor" color="bhoomi" keep-color disable />
            </span>
          </div>

        </div>

        <div class="row wrapper">
          <div class="field">
            <span class="label">First Name</span>
            <span class="field-value">{{ mainDetails.firstName }}</span>
          </div>

          <div class="field">
            <span class="label">Last Name</span>
            <span class="field-value">{{ mainDetails.lastName }}</span>
          </div>

          <div class="field">
            <span class="label">Mobile Phone</span>
            <span class="field-value">{{ mainDetails.mobilePhone }}</span>
          </div>

          <div class="field">
            <span class="label">Work Phone</span>
            <span class="field-value">{{ mainDetails.workPhone }}</span>
          </div>

        </div>

        <div class="row wrapper">

          <div class="field">
            <span class="label">Address</span>
            <span class="field-value">{{ mainDetails.address }}</span>
          </div>

          <div class="field">
            <span class="label">Address 2</span>
            <span class="field-value">{{ mainDetails.address2 }}</span>
          </div>

          <div class="field">
            <span class="label">City</span>
            <span class="field-value">{{ mainDetails.city }}</span>
          </div>

          <div class="field">
            <span class="label">State</span>
            <span class="field-value">{{ mainDetails.state }}</span>
          </div>

        </div>

        <div class="row wrapper">
          
          <div class="field">
            <span class="label">Zip</span>
            <span class="field-value">{{ mainDetails.zip }}</span>
          </div>

          <div class="field">
            <span class="label">Country</span>
            <span class="field-value">{{ mainDetails.country }}</span>
          </div>

          <div class="field" style="max-width: 425px;">
            <q-checkbox
              color="bhoomi"
              v-model="mainDetails.sameAddress"
              disable
              :label="'Billing Address is same as Account Address'"
            />
          </div>

        </div>
      </q-card-section>
    </q-card>

    <q-expansion-item
      class="overflow-hidden rounded-borders subscription-section"
      style="border-radius: 10px"
      popup
      :header-style="{
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        fontWeight: 700,
      }"
      default-opened
      label="Subscription Type"
    >
      <template v-slot:header>
        <q-item-section>
          <span class="header">Billing Type</span>
        </q-item-section>
      </template>

      <q-card>
        <q-card-section>
          <div class="subscription-plan-expired">
            <q-checkbox 
              color="bhoomi" 
              v-model="subscription.plans.trial" 
              label="Free 30 Days Trial"
              disable
            />
            <span class="runs-out">Runs out on {{ moment(subscription.planPurchasingDate).add(30, 'day').format('MMMM Do YYYY') }}</span>
          </div>
          <div class="subscription-plan-expired" v-if="subscription.plans.basic">
            
            <span> 
              <q-checkbox 
                color="bhoomi" 
                v-model="subscription.plans.basic" 
                label="Basic Plan"
                disable
              />
              <span class="subscriptions-number">{{subscription.plans.basicNumber}}</span>Subscriptions</span>
            <span class="runs-out" v-if="subscription.plans.inventory || subscription.plans.equipment">
              Modules included: 
              <span v-if="subscription.plans.inventory">
                Inventory Management
              </span>
              <span v-if="subscription.plans.equipment">
                Equipment Management
              </span>
            </span>
            <span v-else class="runs-out">Runs out on {{ moment(subscription.planPurchasingDate).add(30, 'day').format('MMMM Do YYYY') }}</span>
          </div>
          <div class="subscription-plan-expired" v-if="subscription.plans.allinclusive">
            <span>
              <q-checkbox 
                color="bhoomi" 
                v-model="subscription.plans.allinclusive" 
                label="All Inclusive"
                disable
              />
              <span class="subscriptions-number">{{subscription.plans.allinclusiveNumber}}</span>Subscriptions
            </span>
            <span class="runs-out">Runs out on {{ moment(subscription.planPurchasingDate).add(30, 'day').format('MMMM Do YYYY') }}</span>
          </div>
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </div>
</template>

<script>
import moment from 'moment';
import { useStore } from "vuex";
import SecondaryProfileHeader from "@/components/Pages/SecondaryUser/SecondaryProfileHeader.vue";
import { reactive, provide } from "vue";

export default {
  name: "ProfileView",
  components: {
    SecondaryProfileHeader,
  },
  setup() {
    const store = useStore();

    console.log(store.state.secondaryUser)

    const mainDetails = reactive({
      ...store.state.secondaryUser.mainDetails
    });

    const subscription = reactive({
      ...store.state.secondaryUser.subscription,
      plans: {
        ...store.state.secondaryUser?.subscription?.plans
      }
    })

    provide('mainDetails', {
      mainDetails
    })

    return {
      moment,
      mainDetails,
      subscription
    };
  },
};
</script>

<style lang="scss">
.secondary-user-profile {
  form {
    flex-direction: column;
    flex-wrap: nowrap;
    .add-details {
      width: max-content;
      align-self: end;
      margin-bottom: 24px;
    }
    .form-actions {
      margin-top: 24px;
      button {
        margin-right: 24px;
      }
    }
  }
  .q-expansion-item--popup.q-expansion-item--expanded,
  .q-expansion-item--popup.q-expansion-item--collapsed {
    padding: 0;
  }

  .q-expansion-item {
    margin-bottom: 45px;
  }
}

.row {
  &.wrapper {
    width: 100%;
    justify-content: space-between;
    label {
      width: 100%;
      max-width: 200px;
    }
    &.buttons {
      max-width: 200px;
      margin-bottom: 24px;
    }
  }
}
</style>
