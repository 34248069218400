<template>
<section class="section">
    <div class="border-box">

<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Services</h5>
<div class="icon-group">
</div>
</div>

<div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.date || (service.date.length > 0)}">Date Purchased</p>
<input type="date" class="form-control"
 v-model="service.date"
 :style="{color: (focused.date || service.date.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
  @focus="() => {
    activated.date = true;
    focused.date = true;
  }"
  @blur="() => {
    focused.date = false;
  }"
>
<div class="date-icon"></div>
 <p v-if="activated.date && service.date.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.serviceType || (`${service.serviceType}`.length > 0)}">Service Type</p>
<input type="text" class="form-control"
 v-model="service.serviceType"
  @focus="() => {
    activated.serviceType = true;
    focused.serviceType = true;
  }"
  @blur="() => {
    focused.serviceType = false;
  }"
>
 <p v-if="activated.serviceType && `${service.serviceType}`.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.equipment || (service.equipment.length > 0)}">Equipment</p>
<template v-if="equipmentsList.length > 0">
  <select class="form-control" 
    v-model="service.equipment"
    @click="() => {
      activated.equipment = true;
      focused.equipment = true;
    }"
    @change="() => {
      focused.equipment = false;
    }">
    <option
      v-for="equipmentOption in equipmentsList"
      v-bind:key="equipmentOption + 'equipment-option-service-item'"
    >{{ equipmentOption }}</option> 
  </select>
</template>
<input v-else type="text" class="form-control" v-model="service.equipment"
  @focus="() => {
    activated.equipment = true;
    focused.equipment = true;
  }"
  @blur="() => {
    focused.equipment = false;
  }">
<p v-if="activated.equipment && service.equipment.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.equipment && service.equipment.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3" v-if="vendors.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorName || (service.vendorName.length > 0)}">Vendor</p>
  <select class="form-control" 
    v-model="service.vendorName"
    @click="() => {
      activated.vendorName = true;
      focused.vendorName = true;
    }"
    @change="() => {
      focused.vendorName = false;
    }">
    <option
      v-for="vend in vendors"
      v-bind:key="vend.vendor + 'vendor-option-services'"
    >{{ vend.vendor }}</option>
    <option>Other</option>
  </select>
 <p v-if="activated.vendor && service.vendorName.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.vendor && service.vendorName.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-else>
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorName || (service.vendorName.length > 0)}">Vendor</p>
<input type="text" class="form-control"
 v-model="service.vendorName"
  @focus="() => {
    activated.vendorName = true;
    focused.vendorName = true;
    openAddVendorForm();
  }"
  @blur="() => {
    focused.vendorName = false;
  }"
>
 <p v-if="activated.vendorName && service.vendorName.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.vendorName && service.vendorName.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.invoice || (service.invoice.length > 0)}">Invoice #</p>
<input type="text" class="form-control"
 v-model="service.invoice"
  @focus="() => {
    activated.invoice = true;
    focused.invoice = true;
  }"
  @blur="() => {
    focused.invoice = false;
  }"
>
 <p v-if="activated.invoice && `${service.invoice}`.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.amountPaid || (`${service.amountPaid}`.length > 0)}">Amount</p>
<input type="number" class="form-control"
 v-model="service.amountPaid"
  @focus="() => {
    activated.amountPaid = true;
    focused.amountPaid = true;
  }"
  @blur="() => {
    focused.amountPaid = false;
  }"
>
 <p v-if="activated.amountPaid && `${service.amountPaid}`.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.amountPaid && `${service.amountPaid}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorContact || (service.vendorContact.length > 0)}">Vendor Contact</p>
<input type="text" class="form-control"
 v-model="service.vendorContact"
  @focus="() => {
    activated.vendorContact = true;
    focused.vendorContact = true;
  }"
  @blur="() => {
    focused.vendorContact = false;
  }"
  disabled
>
 <p v-if="activated.vendorContact && service.vendorContact.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.vendorContact && service.vendorContact.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorPhone || (service.vendorPhone.length > 0)}">Vendor Phone</p>
<input type="text" class="form-control"
 v-model="service.vendorPhone"
  @focus="() => {
    activated.vendorPhone = true;
    focused.vendorPhone = true;
  }"
  @blur="() => {
    focused.vendorPhone = false;
    phoneNumberFormat('vendorPhone')
  }"
  disabled
>
 <p v-if="activated.vendorPhone && service.vendorPhone.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.vendorPhone && service.vendorPhone.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.warrantyExp || (service.warrantyExp.length > 0)}">Warranty Exp</p>
<input type="date" class="form-control"
 :style="{color: (focused.warrantyExp || service.warrantyExp.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
 v-model="service.warrantyExp"
  @focus="() => {
    activated.warrantyExp = true;
    focused.warrantyExp = true;
  }"
  @blur="() => {
    focused.warrantyExp = false;
  }"
>
<div class="date-icon"></div>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.serviceDate || (service.serviceDate.length > 0)}">Service Date</p>
<input type="date" class="form-control" 
 :style="{color: (focused.serviceDate || service.serviceDate.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
 v-model="service.serviceDate"
  @focus="() => {
    activated.serviceDate = true;
    focused.serviceDate = true;
  }"
  @blur="() => {
    focused.serviceDate = false;
  }"
>
<div class="date-icon"></div>
</div>
</div>

<div class="col-12">
<div class="form-item">
<p class="form-label" :class="{active: focused.comments || (service.comments.length > 0)}">Comments</p>
<textarea class="form-control"
 v-model="service.comments"
  @focus="() => {
    activated.comments = true;
    focused.comments = true;
  }"
  @blur="() => {
    focused.comments = false;
  }"
></textarea>
 <p v-if="activated.comments && service.comments.length > 500" class="form-info red text-end">Please use maximum 500 characters</p>
</div>
</div>

</div>
</div>
</div>

</div>

<div class="content-block">

  <FileAttachments :key="`${service.id}-services-attachments`" v-bind:section="'vendorItem'" v-bind:instance="`${service.id}`" />

  <div class="content-section">
  <div class="buttons">
  <div class="row">
  <div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
  <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
  </div>
  </div>
  </div>

</div>

</div>
</div>
</section>
</template>

<script>
import { defineComponent } from 'vue';
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { reactive, inject, watch, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
// import moment from 'moment';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: "ServiceForm",
    components: { FileAttachments },
    setup() {
      const store = useStore();
      const router = useRouter();

      const activated = reactive({
        date: false,
        serviceType: false,
        equipment: false,
        vendorName: false,
        invoice: false,
        amountPaid: false,
        vendorContact: false,
        vendorPhone: false,
        warrantyExp: false,
        serviceDate: false
      });

      const focused = reactive({
        date: false,
        serviceType: false,
        equipment: false,
        vendorName: false,
        invoice: false,
        amountPaid: false,
        vendorContact: false,
        vendorPhone: false,
        warrantyExp: false,
        serviceDate: false
      });

      const { service, editionMode, selectedService, selectService, defaultService } = inject("serviceDetails");

      const vendors = computed(() => store.state.vendors);

      const equipmentsList = computed(() => [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.type === 'Equipment').map(stateEquipment => stateEquipment.item))

      // const services = ref([]);

      const phoneNumberFormat = (phoneType) => {
        if (service[phoneType].length > 0) {
          if (service[phoneType][0] !== "(") {
              const arrStr = service[phoneType].split("");
              service[phoneType] = `(${arrStr.filter((num, indx) => {
                  if (indx < 3)
                      return num;
              }).join("")}) ${arrStr.filter((num, indx) => {
                  if ((indx >= 3) && (indx < 6))
                      return num;
              }).join("")}-${arrStr.filter((num, indx) => {
                  if (indx >= 6)
                      return num;
              }).join("")}`;
          }
        }
      };

      const submitForm = () => {

        if (!service.serviceType || !service.equipment ||
          !service.vendorName || !service.date ||
          !service.amountPaid || !service.vendor || !service.vendorPhone ||
          !service.vendorContact || !service.serviceDate) {

          if (!service.serviceType) activated.item = true;
          if (!service.equipment) activated.equipment = true;
          if (!service.vendorName) activated.vendorName = true;
          if (!service.date) activated.date = true;
          if (!service.amountPaid) activated.amountPaid = true;
          if (!service.vendor) activated.vendor = true;
          if (!service.vendorPhone) activated.vendorPhone = true;
          if (!service.vendorContact) activated.vendorContact = true;
          if (!service.serviceDate) activated.vendorContact = true;

          return;
        }


        // service.date = moment(service.date).format('MM/DD/YYYY');
        // service.warrantyExp = moment(service.warrantyExp).format('MM/DD/YYYY');
        // service.serviceDate = moment(service.serviceDate).format('MM/DD/YYYY');

        const formData = {
          ...service
        };

        if (vendors.value.length <= 0) {
          // store.dispatch("createVendor", {
          //   id: uuid(),
          //   vendor: equipment.vendor
          // });

          // store.dispatch('addVendorEquipments', {
          //   id: uuid(),
          //   vendor: equipment.vendor,
          //   item: equipment.item,
          //   manufacturer: equipment.manufacturer
          // })
        }

        store.dispatch("editVendorItem", { ...formData });
        (editionMode.value === "edit") && selectService({...service}, 'represent');
      }

      const cancelChanges = () => {
        if (editionMode.value === "edit") {
          for (let prop in service) {
            service[prop] = selectedService[prop];
          }
          editionMode.value = "represent";
        } else {
          for (let prop in service) {
            service[prop] = defaultService[prop];
          }
        }
      };

      const openAddVendorForm = (other) => {
        if ((vendors.value.length === 0) || other) {
          router.push({ 
            name: 'vendors', 
            params: { 
              pagename: 'vendor-module',
              source: 'service',
              other: other
            } 
          });
        }
      }

      watch(() => (service.vendorName), (newVal) => {
        if (newVal === 'Other') {
          openAddVendorForm(true);
        }
        const selectedVendor = vendors.value.find(vend => vend.vendor === newVal);
        // services.value = [...equipments.value.filter(eq => eq.vendor === selectedVendor.id)];
        if (selectedVendor) {
          service.vendorPhone = selectedVendor.vendorPhone;
          service.vendorContact = selectedVendor.vendorContact;
        }
      });

      watch(() => ({...service}), (newVal) => {
        if (!newVal.id) {
          service.id === uuid();
        }
      });

      onMounted(() => {
        if (!service.id) {
          console.log(service.id, 'service.id');
          service.id = uuid();
        }
        if ((vendors.value.length > 0) && !router.currentRoute.value.params.otherVendor) {
          service.vendorName = vendors.value[0].vendor;
        }
        if (router.currentRoute.value.params.otherVendor) {
          service.vendorName = router.currentRoute.value.params.otherVendor;
        }
      })
      
      return {
        activated,
        focused,
        service,
        vendors,
        // services,
        phoneNumberFormat,
        submitForm,
        cancelChanges,
        openAddVendorForm,
        equipmentsList
      }
    }
})
</script>

<style lang="scss" scoped></style>