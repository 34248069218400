<template>
 <div class="dashboard-slide">
  <div class="dashboard-slide-header">
  <h5>Local Weather</h5>
  <div class="weather-tabs">
    <div v-for="weatherTab in weatherTabs"
      @click="selectForecast(weatherTab.url)"
      v-bind:key="'weather-widget-tab' + weatherTab.label"
      :class="{active: weatherTab.url === forecast}"
    >
      {{ weatherTab.label }}
    </div>
  </div>
  </div>
  <div class="dashboard-slide-content">
  <div class="weather" ref="weatherContent">
    <div class="weather-location-time">
      <div class="weather-location">
        <font-awesome-icon :icon="['fas', 'location-dot']" />
        {{ weather.city }}, {{ weather.state }}
      </div>
      <div class="weather-time">{{ weather.time }} {{ weather.timezone }}</div>
    </div>
    <div class="weather-main">
      <div class="weather-details">
        <div class="weather-temp">
          <font-awesome-icon :icon="['fas', 'temperature-three-quarters']" />
          {{ weather.temperature && Number(weather.temperature).toFixed(0) }}°
        </div>
        <div class="weather-max-min">
          Day {{ weather.max && Number(weather.max).toFixed(0) }}° • Night {{ weather.min && Number(weather.min).toFixed(0) }}°
        </div>
        <div class="weather-feels">Feels like {{ weather.feels && Number(weather.feels).toFixed(0) }}°</div>
      </div>
      <div class="weather-details-two">
        <!-- <div class="weather-high-low"> {{ weather.max }} / {{ weather.min }}</div> -->
        <div class="weather-code-icon" :class="`weathercode-${weather.code}`"></div>
        <div class="weather-current-date">{{ moment().format('dddd, MMMM Do') }}</div>
      </div>
      <div class="weather-details-three">
        <div class="weather-code">{{ weatherCodeTable[weather.code].label }}</div>

        <div class="weather-humidity">
          <font-awesome-icon :icon="['fas', 'water']" /> Humidity
          {{ weather.humidity }}%
        </div>
        <div class="weather-pressure">
          <font-awesome-icon :icon="['fas', 'down-left-and-up-right-to-center']" /> Pressure
          {{ weather.pressure }}hPa
        </div>
        <div class="weather-visibility"></div>

        <div class="weather-wind">
          <font-awesome-icon :icon="['fas', 'wind']" /> Wind
          {{ weather.wind }}m/s
        </div>
        <div class="weather-dew">
          <font-awesome-icon :icon="['fas', 'droplet']" />° Dew
          {{ weather.dew }}°
        </div>

        <!-- <div class="weather-wind">Wind: {{ weather.wind }}km/h</div>
        <div class="weather-dew">Dew: {{ weather.dew }}°C</div> -->
        <!-- <div class="weather-uv">{{ weather.uvIndex }}</div> -->
        <!-- <div class="weather-moon">{{ weather.moonPhase }}</div> -->
        <!-- <div class="weather-feels">Feels like: {{ weather.feels }}°</div> -->
      </div>
    </div>
    <div class="weather-forecast" :class="forecast" :style="{maxWidth: `${weatherContentWidth}px`}">
      <template
        v-for="(forecast, indx) in weather.forecasts"
        v-bind:key="indx + 'weather-forecast'"
      >
        <div v-if="forecast" class="forecast-instance">
          <div class="forecast-time">{{ forecast?.time }}</div>
          <div class="forecast-temperature">{{ Number(forecast?.temperature).toFixed(0) }}°</div>
          <div class="forecast-weathercode" :class="`weathercode-${forecast?.weathercode}`"></div>
          <div class="forecast-precipitation">{{ forecast?.precipitation }} in</div>
        </div>
      </template>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
import { watch, ref, computed, onMounted } from 'vue';
import { useStore } from "vuex";
import moment from 'moment';
import weatherCodeTable from '@/sharedData/weatherCodes';

export default {
  name: 'DashboardWeather',
  props: ['farmCoords'],
  setup (props) {

    console.log(props);

    const store = useStore();

    // const geoLocationCoords = computed(() => store.state.geoLocationCoords);
    console.log(computed);
    const geoLocationCoords = ref({});

    const dataFetch = async (url, options) => {
      const response = await fetch(url, options || {});
      const extracted = await response.json();
      console.log(extracted);

      return extracted;
    }

    const weather = ref({
      code: 0
    });

    const weatherContent = ref(null);

    const weatherContentWidth = ref('');

    const weatherTabs = [
      {label: 'Daily', url: 'daily'},
      {label: 'Hourly', url: 'hourly'},
      {label: '16 Days', url: 'weekly'}
    ]

    const forecast = ref('daily'); // daily | forecast

    const hourlyData = ref({});

    const dailyData = ref({});

    const generateApiUrl = (currentForecast) => {

      const urlParams = {
        hourlyParams: `&hourly=${
          'temperature_2m,relativehumidity_2m,pressure_msl,windspeed_10m,dewpoint_2m,apparent_temperature,weathercode,precipitation'
        }&forecast_days=${1}&current_weather=true&temperature_unit=fahrenheit&wind_speed_unit=ms&precipitation_unit=inch`,
        dailyParams: `&daily=${
          'weathercode,temperature_2m_max,temperature_2m_min,windspeed_10m_max,precipitation_sum'
        }&timezone=GMT&forecast_days=${16}&current_weather=true&temperature_unit=fahrenheit&wind_speed_unit=ms&precipitation_unit=inch`,
        // weeklyParams: `&daily=${
        //   'weathercode,temperature_2m_max,temperature_2m_min,windspeed_10m_max,precipitation_sum'
        // }&timezone=GMT&forecast_days=${16}&current_weather=true`
      }

      return `https://api.open-meteo.com/v1/forecast?latitude=${
        geoLocationCoords.value.latitude
      }&longitude=${
        geoLocationCoords.value.longitude
      }${
        urlParams[`${currentForecast}Params`]
      }`
    }

    const selectForecast = (selected) => {
      forecast.value = selected;
    }

    const generateWeather = () => {

      const findAverage = (arr) => {
        return Math.abs(Number.parseInt(
          arr.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          )
        )  / Number.parseInt(arr.length)).toFixed(0)
      }

      if (forecast.value === 'hourly') {
        console.log(hourlyData.value, 'hourlyData');
        weather.value = {
          city: 'city',
          state: 'state',
          time: moment(hourlyData.value.current_weather.time).format('h:mm a'),
          timezone: hourlyData.value.timezone,
          temperature: hourlyData.value.current_weather.temperature,
          code: hourlyData.value.current_weather.weathercode,
          max: dailyData.value.daily.temperature_2m_max[0],
          min: dailyData.value.daily.temperature_2m_min[0],
          humidity: hourlyData.value.hourly.relativehumidity_2m[
            hourlyData.value.hourly.time.findIndex(timeSnippet => 
              moment(timeSnippet).hour() === moment().hour()
            )
          ],
          pressure: hourlyData.value.hourly.pressure_msl[
            hourlyData.value.hourly.time.findIndex(timeSnippet => 
              moment(timeSnippet).hour() === moment().hour()
            )
          ],
          wind: hourlyData.value.hourly.windspeed_10m[
            hourlyData.value.hourly.time.findIndex(timeSnippet =>
              moment(timeSnippet).hour() === moment().hour()
            )
          ],
          dew: hourlyData.value.hourly.dewpoint_2m[
            hourlyData.value.hourly.time.findIndex(timeSnippet =>
              moment(timeSnippet).hour() === moment().hour()
            )
          ],
          feels: hourlyData.value.hourly.apparent_temperature[
            hourlyData.value.hourly.time.findIndex(timeSnippet =>
              moment(timeSnippet).hour() === moment().hour()
            )
          ],
          forecasts: hourlyData.value.hourly.time.map((tm, indx) => {
            return {
              weathercode: hourlyData.value.hourly.weathercode[indx],
              temperature: hourlyData.value.hourly.temperature_2m[indx],
              precipitation: hourlyData.value.hourly.precipitation[indx],
              time: moment(tm).format('h:00 a')
            }
          })
        }
      }

      if (forecast.value === 'daily') {
        weather.value = {
          city: 'city',
          state: 'state',
          time: moment(dailyData.value.current_weather.time).format('h:mm a'),
          timezone: dailyData.value.timezone,
          temperature: dailyData.value.current_weather.temperature,
          code: dailyData.value.current_weather.weathercode,
          max: dailyData.value.daily.temperature_2m_max[0],
          min: dailyData.value.daily.temperature_2m_min[0],
          humidity: findAverage(hourlyData.value.hourly.relativehumidity_2m),
          pressure: findAverage(hourlyData.value.hourly.pressure_msl),
          wind: dailyData.value.daily.windspeed_10m_max[0],
          dew: findAverage(hourlyData.value.hourly.dewpoint_2m),
          feels: findAverage(hourlyData.value.hourly.apparent_temperature),
          forecasts: hourlyData.value.hourly.time.map((tm, indx) => {
            if ((indx === 6) || (indx === 12) || (indx === 18) || (indx === 23)) {
              const dayTimes = {
                6: 'Morning',
                12: 'Afternoon',
                18: 'Evening',
                23: 'Overnight'
              }
              return {
                weathercode: hourlyData.value.hourly.weathercode[indx],
                temperature: hourlyData.value.hourly.temperature_2m[indx],
                precipitation: hourlyData.value.hourly.precipitation[indx],
                time: dayTimes[indx]
              }
            }
          })
        }
      }

      if (forecast.value === 'weekly') {
        weather.value = {
          city: 'city',
          state: 'state',
          time: moment(dailyData.value.current_weather.time).format('h:mm a'),
          timezone: dailyData.value.timezone,
          temperature: dailyData.value.current_weather.temperature,
          code: dailyData.value.current_weather.weathercode,
          max: dailyData.value.daily.temperature_2m_max[0],
          min: dailyData.value.daily.temperature_2m_min[0],
          humidity: findAverage(hourlyData.value.hourly.relativehumidity_2m),
          pressure: findAverage(hourlyData.value.hourly.pressure_msl),
          wind: dailyData.value.daily.windspeed_10m_max[0],
          dew: findAverage(hourlyData.value.hourly.dewpoint_2m),
          feels: findAverage(hourlyData.value.hourly.apparent_temperature),
          forecasts: dailyData.value.daily.time.map((tm, indx) => {
            return {
              weathercode: dailyData.value.daily.weathercode[indx],
              temperature: Math.abs(
                (Number.parseFloat(dailyData.value.daily.temperature_2m_max[indx]) +
                Number.parseFloat(dailyData.value.daily.temperature_2m_min[indx])) / 2
              ),
              precipitation: dailyData.value.daily.precipitation_sum[indx],
              time: moment(tm).format('dddd, Do')
            }
          })
        }
      }

      console.log(weather.value, 'weather data');

    }

    watch(geoLocationCoords, (newVal) => {
      console.log(newVal, 'coords');
      if (newVal.latitude && newVal.longitude) {
        const apiHourly = generateApiUrl('hourly');
        const apiDaily = generateApiUrl('daily');

        dataFetch(apiHourly).then(dataH => {
          console.log(dataH);

          hourlyData.value = dataH;

          dataFetch(apiDaily).then(dataD => {
            console.log(dataD);

            dailyData.value = dataD;

            generateWeather();
          })

        }).catch(e => console.error(e))

      }
    })

    watch(forecast, (newVal) => {
      if (newVal) {
        generateWeather();
      }
    })

    watch(() => ({...store.state.geoLocationCoords}), (newVal) => {
      if (newVal) {
        console.log(newVal, 'state coords');
        geoLocationCoords.value = {
          latitude: newVal.latitude,
          longitude: newVal.longitude
        }
        // generateWeather();
      }
    });

    onMounted(() => {
      weatherContentWidth.value = weatherContent.value.offsetWidth;
      setTimeout(() => {
        geoLocationCoords.value = props.farmCoords.latitude ? 
        {
          ...props.farmCoords
        } : {
          ...store.state.geoLocationCoords
        };
      }, 300)
    })

    return {
      weather,
      forecast,
      weatherTabs,
      selectForecast,
      weatherCodeTable,
      moment,
      weatherContent,
      weatherContentWidth
    }
  }
}
</script>

<style lang="scss" scoped>
.weather {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #0667b3;
  span {
   color: #999 
  }
  .weather-location-time {
    display: flex;
    background-color: #0000008c;
    color: #fff;
    align-items: self-end;
    padding: 10px 16px;
    .weather-location {
      font-size: 1.25rem;
      font-weight: 600;
      margin-right: 5px;
    }
    .weather-time {
      font-size: 1.125rem;
    }
  }
  .weather-main {
    color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;

    .weather-details {
      display: flex;
      flex-direction: column;
      width: max-content;
      align-items: center;
      .weather-temp {
        font-size: 5rem;
        font-weight: 500;
      }
      .weather-code {
        font-size: 1.5rem;
        font-weight: 700;
      }
      .weather-max-min {
        font-size: 1.5rem;
        font-weight: 700;
      }
      .weather-feels {
        font-size: 1.5rem;
        font-weight: 700;
      }
    }
    .weather-details-two {
      display: flex;
      flex-direction: column;
      width: max-content;
      align-items: center;
      justify-content: space-between;

      font-size: 1.5rem;
      font-weight: 700;

      .weather-current-date {
        background: #d5293c;
        padding: 0 15px;
        border-radius: 20px;
      }
      .weather-code-icon {
        width: 12rem;
        height: 12rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        &.weathercode-0 {
          background-image: url('@/assets/weather/weathercode-0.png');
        }
        &.weathercode-1 {
          background-image: url('@/assets/weather/weathercode-1.png');
        }
        &.weathercode-2 {
          background-image: url('@/assets/weather/weathercode-2.png');
        }
        &.weathercode-3 {
          background-image: url('@/assets/weather/weathercode-3.png');
        }
        &.weathercode-45 {
          background-image: url('@/assets/weather/weathercode-45.png');
        }
        &.weathercode-48 {
          background-image: url('@/assets/weather/weathercode-48.png');
        }
        &.weathercode-51 {
          background-image: url('@/assets/weather/weathercode-51.png');
        }
        &.weathercode-53 {
          background-image: url('@/assets/weather/weathercode-53.png');
        }
        &.weathercode-55 {
          background-image: url('@/assets/weather/weathercode-55.png');
        }
        &.weathercode-56 {
          background-image: url('@/assets/weather/weathercode-56.png');
        }
        &.weathercode-57 {
          background-image: url('@/assets/weather/weathercode-57.png');
        }
        &.weathercode-61 {
          background-image: url('@/assets/weather/weathercode-61.png');
        }
        &.weathercode-63 {
          background-image: url('@/assets/weather/weathercode-63.png');
        }
        &.weathercode-65 {
          background-image: url('@/assets/weather/weathercode-65.png');
        }
        &.weathercode-66 {
          background-image: url('@/assets/weather/weathercode-66.png');
        }
        &.weathercode-67 {
          background-image: url('@/assets/weather/weathercode-67.png');
        }
        &.weathercode-71 {
          background-image: url('@/assets/weather/weathercode-71.png');
        }
        &.weathercode-73 {
          background-image: url('@/assets/weather/weathercode-73.png');
        }
        &.weathercode-75 {
          background-image: url('@/assets/weather/weathercode-75.png');
        }
        &.weathercode-77 {
          background-image: url('@/assets/weather/weathercode-77.png');
        }
        &.weathercode-80 {
          background-image: url('@/assets/weather/weathercode-80.png');
        }
        &.weathercode-81 {
          background-image: url('@/assets/weather/weathercode-81.png');
        }
        &.weathercode-82 {
          background-image: url('@/assets/weather/weathercode-82.png');
        }
        &.weathercode-85 {
          background-image: url('@/assets/weather/weathercode-85.png');
        }
        &.weathercode-86 {
          background-image: url('@/assets/weather/weathercode-86.png');
        }
        &.weathercode-95 {
          background-image: url('@/assets/weather/weathercode-95.png');
        }
        &.weathercode-96 {
          background-image: url('@/assets/weather/weathercode-96.png');
        }
        &.weathercode-99 {
          background-image: url('@/assets/weather/weathercode-99.png');
        }
      }
      // .weather-humidity {}
      // .weather-pressure {}
      // .weather-visibility {}
      // .weather-wind {}
      // .weather-dew {}
    }
    .weather-details-three {
      .weather-code {
        font-size: 1.5rem;
        font-weight: 700;
      }
      .weather-humidity {
        font-size: 1rem;
        font-weight: 400;
      }
      .weather-pressure {
        font-size: 1rem;
        font-weight: 400;
      }
      .weather-visibility {
        font-size: 1rem;
        font-weight: 400;
      }
      .weather-wind {
        font-size: 1rem;
        font-weight: 400;
      }
      .weather-dew {
        font-size: 1rem;
        font-weight: 400;
      }
      
    //   display: flex;
    //   flex-direction: column;
    //   width: max-content;
    //   align-items: center;
    //   justify-content: space-between;
      
    //   font-size: 1.5rem;
    //   font-weight: 700;
    //   .weather-wind {}
    //   .weather-dew {}
    //   .weather-feels {}
    }
  }
  .weather-forecast {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    overflow-x: scroll;
    color: #fff;
    align-self: last baseline;
    background: #045592;
    border-radius: 3rem;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #b5b7b7;
    }

    &::-webkit-scrollbar-thumb {
      background: #606060;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    &.daily {
      justify-content: space-evenly;
    }
    &.weekly {
      .forecast-instance {
        min-width: 12rem;
      }      
    }
    .forecast-instance {
      min-width: 10rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 700;
      .forecast-time {}
      .forecast-temperature {}
      .forecast-precipitation {}
      .forecast-weathercode {
        width: 6rem;
        height: 6rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        &.weathercode-0 {
          background-image: url('@/assets/weather/weathercode-0.png');
        }
        &.weathercode-1 {
          background-image: url('@/assets/weather/weathercode-1.png');
        }
        &.weathercode-2 {
          background-image: url('@/assets/weather/weathercode-2.png');
        }
        &.weathercode-3 {
          background-image: url('@/assets/weather/weathercode-3.png');
        }
        &.weathercode-45 {
          background-image: url('@/assets/weather/weathercode-45.png');
        }
        &.weathercode-48 {
          background-image: url('@/assets/weather/weathercode-48.png');
        }
        &.weathercode-51 {
          background-image: url('@/assets/weather/weathercode-51.png');
        }
        &.weathercode-53 {
          background-image: url('@/assets/weather/weathercode-53.png');
        }
        &.weathercode-55 {
          background-image: url('@/assets/weather/weathercode-55.png');
        }
        &.weathercode-56 {
          background-image: url('@/assets/weather/weathercode-56.png');
        }
        &.weathercode-57 {
          background-image: url('@/assets/weather/weathercode-57.png');
        }
        &.weathercode-61 {
          background-image: url('@/assets/weather/weathercode-61.png');
        }
        &.weathercode-63 {
          background-image: url('@/assets/weather/weathercode-63.png');
        }
        &.weathercode-65 {
          background-image: url('@/assets/weather/weathercode-65.png');
        }
        &.weathercode-66 {
          background-image: url('@/assets/weather/weathercode-66.png');
        }
        &.weathercode-67 {
          background-image: url('@/assets/weather/weathercode-67.png');
        }
        &.weathercode-71 {
          background-image: url('@/assets/weather/weathercode-71.png');
        }
        &.weathercode-73 {
          background-image: url('@/assets/weather/weathercode-73.png');
        }
        &.weathercode-75 {
          background-image: url('@/assets/weather/weathercode-75.png');
        }
        &.weathercode-77 {
          background-image: url('@/assets/weather/weathercode-77.png');
        }
        &.weathercode-80 {
          background-image: url('@/assets/weather/weathercode-80.png');
        }
        &.weathercode-81 {
          background-image: url('@/assets/weather/weathercode-81.png');
        }
        &.weathercode-82 {
          background-image: url('@/assets/weather/weathercode-82.png');
        }
        &.weathercode-85 {
          background-image: url('@/assets/weather/weathercode-85.png');
        }
        &.weathercode-86 {
          background-image: url('@/assets/weather/weathercode-86.png');
        }
        &.weathercode-95 {
          background-image: url('@/assets/weather/weathercode-95.png');
        }
        &.weathercode-96 {
          background-image: url('@/assets/weather/weathercode-96.png');
        }
        &.weathercode-99 {
          background-image: url('@/assets/weather/weathercode-99.png');
        }
      }
    }
  }
}
</style>