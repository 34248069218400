<template>

<section class="section" ref="section" @click="addVendorBefore">
<div class="border-box active">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Add Products & Services</h5>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-block d-lg-flex justify-content-between">
  <div class="checkbox-group task-category">
  <p class="fw-semibold gray checkbox-header">Select a Category:</p>
  <div class="table-select">
    <!-- <select v-model="vendorItem.type">
      <option>Product</option>
      <option>Equipment</option>
      <option>Service</option>
    </select> -->

    <DropDownList
      :mainObject="'vendorItem'"
      :mainProperty="'type'"
      :injectionName="'vendorDetails'"
      :optionKey="'type-option-vendor-item'"
      :optionsList="['Product', 'Equipment', 'Service']"
      :optionProperty="null"
      :optionPropertyTwo="null"
      :defaultSelected="vendorItem.type"
    />
  </div>
  </div>
</div>

<div class="content-block">

<div class="content-row grid-4 grid-full">
<div class="row">

  <template v-if="vendorItem.type === 'Product'">

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.date || (vendorItem.date.length > 0)}">Date Purchased</p>
    <input type="date" class="form-control" 
      :style="{color: (focused.date || vendorItem.date.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
      v-model="vendorItem.date"
      pattern="\d{2}/\d{2}/\d{4}"
      @focus="() => {
        activated.date = true;
        focused.date = true;
      }"
      @blur="() => {
        focused.date = false;
      }">
      <div class="date-icon"></div>
    <p v-if="activated.date && vendorItem.date === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.date && vendorItem.date.length === 0" class="form-info red text-end">* Required</p>
    </div>
    </div>

    <div class="col-3" v-if="!manualInput">

    <div class="form-item">
    <p class="form-label" :class="{active: focused.item || (vendorItem.item.length > 0)}">Item</p>
      <DropDownList
        :mainObject="'vendorItem'"
        :mainProperty="'item'"
        :injectionName="'vendorDetailsThree'"
        :optionKey="'product-option-vendor-item'"
        :optionsList="[]"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="vendorItem.item"
        @click="() => {
          activated.item = true;
        }"
      />
      <!-- :optionProperty="'item'" -->
    <p v-if="activated.item && vendorItem.item.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.item && vendorItem.item.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>

    </div>

    <div class="col-3" v-else >

    <div class="form-item">
    <p class="form-label" :class="{active: focused.item || (vendorItem.item.length > 0)}">Item</p>
      <div class="dropdown-open-back-container">
        <input type="text" class="form-control" v-model="vendorItem.item" ref="itemField"
          @focus="() => {
            activated.item = true;
            focused.item = true;
          }"
          @blur="() => {
            focused.item = false;
          }"
          placeholder="E.g., Seeds"
          >
        <div class="dropdown-open-back" @click="() => {
          manualInput = false
        }"></div>
      </div>
    <p v-if="activated.item && vendorItem.item.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.item && vendorItem.item.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>

    </div>



    <div class="col-3">

    <div class="form-item">
    <p class="form-label" :class="{active: focused.variety || (vendorItem.variety.length > 0)}">Variety</p>
    
      <template v-if="!manualVariety">
        <DropDownList
          :mainObject="'vendorItem'"
          :mainProperty="'variety'"
          :injectionName="'vendorDetailsFour'"
          :optionKey="'variety-option-vendor-item'"
          :optionsList="[]"
          :optionProperty="null"
          :optionPropertyTwo="null"
          :defaultSelected="vendorItem.variety"
          @click="() => {
            activated.variety = true;
          }"
        />
      </template>

      <template v-else >
        <div class="dropdown-open-back-container">
          <input type="text" class="form-control" v-model="vendorItem.variety" ref="varietyField"
          @focus="() => {
            activated.variety = true;
            focused.variety = true;
          }"
          @blur="() => {
            focused.variety = false;
          }"
          placeholder="E.g., Organic"
          >
          <div class="dropdown-open-back" @click="() => {
            manualVariety = false
          }"></div>
      </div>
      </template>
      <!-- <p v-if="activated.variety && vendorItem.variety.length === 0" class="form-info red text-end">* Required</p> -->
      <p v-if="activated.variety && vendorItem.variety.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>

    </div>



    <div class="col-3">

    <div class="form-item">
    <p class="form-label" :class="{active: focused.manufacturer || (vendorItem.manufacturer.length > 0)}">Manufacturer</p>

    <template v-if="!manualManufacturer">
        <DropDownList
          :mainObject="'vendorItem'"
          :mainProperty="'manufacturer'"
          :injectionName="'vendorDetailsFive'"
          :optionKey="'manufacturer-option-vendor-item'"
          :optionsList="[]"
          :optionProperty="null"
          :optionPropertyTwo="null"
          :defaultSelected="vendorItem.manufacturer"
          @click="() => {
            activated.manufacturer = true;
          }"
        />
      </template>
      <template v-else >
        <div class="dropdown-open-back-container">

          <input type="text" class="form-control" v-model="vendorItem.manufacturer" ref="manufacturerField"
            @focus="() => {
              activated.manufacturer = true;
              focused.manufacturer = true;
            }"
            @blur="() => {
              focused.manufacturer = false;
            }"
            placeholder="E.g., John Deere"
            >
          <div class="dropdown-open-back" @click="() => {
              manualManufacturer = false
            }"></div>
        </div>
      </template>
    <p v-if="activated.manufacturer && vendorItem.manufacturer.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.manufacturer && vendorItem.manufacturer.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>

    </div>


    <div class="col-3">

    <div class="form-item">
    <p class="form-label" :class="{active: focused.brand || (vendorItem.brand.length > 0)}">Brand</p>

      <template v-if="!manualBrand">
        <DropDownList
          :mainObject="'vendorItem'"
          :mainProperty="'brand'"
          :injectionName="'vendorDetailsSix'"
          :optionKey="'brand-option-vendor-item'"
          :optionsList="[]"
          :optionProperty="null"
          :optionPropertyTwo="null"
          :defaultSelected="vendorItem.brand"
          @click="() => {
            activated.brand = true;
          }"
        />
      </template>
      <template v-else >
        <div class="dropdown-open-back-container">
          <input type="text" class="form-control" v-model="vendorItem.brand" ref="brandField"
            @focus="() => {
              activated.brand = true;
              focused.brand = true;
            }"
            @blur="() => {
              focused.brand = false;
            }"
            placeholder="E.g., JH789"
            >
          <div class="dropdown-open-back" @click="() => {
              manualBrand = false
            }"></div>
        </div>
      </template>
    <!-- <p v-if="activated.brand && vendorItem.brand.length === 0" class="form-info red text-end">* Required</p> -->
    <p v-if="activated.brand && vendorItem.brand.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>

    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.numberOfItems || (vendorItem.numberOfItems > 0)}"># of Items</p>
    <input type="number" class="form-control"
      v-model="vendorItem.numberOfItems"
      @focus="() => {
        activated.numberOfItems = true;
        focused.numberOfItems = true;
      }"
      @blur="() => {
        focused.numberOfItems = false;
      }"
      placeholder="E.g., 3"
      >
    <p v-if="activated.numberOfItems && `${vendorItem.numberOfItems}`.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.numberOfItems && `${vendorItem.numberOfItems}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.quantity || (vendorItem.quantity > 0)}">Weight (lbs/item)</p>
    <input type="number" class="form-control" v-model="vendorItem.quantity"
      @focus="() => {
        activated.quantity = true;
        focused.quantity = true;
      }"
      @blur="() => {
        focused.quantity = false;
      }"
      placeholder="E.g., 4"
      >
    <!-- <p v-if="activated.quantity && `${vendorItem.quantity}`.length === 0" class="form-info red text-end">* Required</p> -->
    <p v-if="activated.quantity && `${vendorItem.quantity}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.invoice || (vendorItem.invoice.length > 0)}">Invoice #</p>
    <input type="text" class="form-control" v-model="vendorItem.invoice"
      @focus="() => {
        activated.invoice = true;
        focused.invoice = true;
      }"
      @blur="() => {
        focused.invoice = false;
      }"
      placeholder="E.g., 5623"
      >
    <p v-if="activated.invoice && `${vendorItem.invoice}`.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.invoice && vendorItem.invoice.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.amountPaid || (vendorItem.amountPaid > 0)}">Amount</p>
    <div class="dollar-prefix">
    <span>$</span>
      <input type="text" class="form-control" v-model="vendorItem.amountPaid"
        @focus="() => {
          activated.amountPaid = true;
          focused.amountPaid = true;
        }"
        @blur="() => {
          focused.amountPaid = false;
        }"
        placeholder="E.g., 102.5"
        >
    </div>
    <p v-if="activated.amountPaid && `${vendorItem.amountPaid}`.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.amountPaid && `${vendorItem.amountPaid}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.warrantyExp || (vendorItem.warrantyExp > 0)}">Warranty Exp</p>
    <!-- <input type="number" class="form-control" v-model="vendorItem.warrantyExp"
      @focus="() => {
        activated.warrantyExp = true;
        focused.warrantyExp = true;
      }"
      @blur="() => {
        focused.warrantyExp = false;
      }"> -->
      <input type="date" class="form-control" 
        :style="{color: (focused.warrantyExp || vendorItem.warrantyExp.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
        v-model="vendorItem.warrantyExp"
        pattern="\d{2}/\d{2}/\d{4}"
        @focus="() => {
          activated.warrantyExp = true;
          focused.warrantyExp = true;
        }"
        @blur="() => {
          focused.warrantyExp = false;
        }">
      <div class="date-icon"></div>
    <p v-if="activated.warrantyExp && `${vendorItem.warrantyExp}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
    </div>

    <div class="col-12">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.comments || (vendorItem.comments.length > 0)}">Comments</p>
    <textarea class="form-control" v-model="vendorItem.comments"
      @focus="() => {
        activated.comments = true;
        focused.comments = true;
      }"
      @blur="() => {
        focused.comments = false;
      }"
      placeholder="E.g., Purchased new seed. Updated the data"
      ></textarea>
    </div>
    </div>

  </template>

  <template v-if="vendorItem.type === 'Equipment'">

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.date || (vendorItem.date.length > 0)}">Date Purchased</p>
    <input type="date" class="form-control" 
      :style="{color: (focused.date || vendorItem.date.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
      v-model="vendorItem.date"
      pattern="\d{2}/\d{2}/\d{4}"
      @focus="() => {
        activated.date = true;
        focused.date = true;
      }"
      @blur="() => {
        focused.date = false;
      }">
      <div class="date-icon"></div>
    <p v-if="activated.date && vendorItem.date === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.date && vendorItem.date.length === 0" class="form-info red text-end">* Required</p>
    </div>
    </div>

    <div class="col-3">

    <div class="form-item">
    <p class="form-label" :class="{active: focused.item || (vendorItem.item.length > 0)}">Item</p>
    <input type="text" class="form-control" v-model="vendorItem.item"
      @focus="() => {
        activated.item = true;
        focused.item = true;
      }"
      @blur="() => {
        focused.item = false;
      }"
      placeholder="E.g., Tractor"
      >
    <p v-if="activated.item && vendorItem.item.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.item && vendorItem.item.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>

    </div>


    <div class="col-3">

    <div class="form-item">
    <p class="form-label" :class="{active: focused.manufacturer || (vendorItem.manufacturer.length > 0)}">Manufacturer</p>

      <template v-if="!manualManufacturer">
        <DropDownList
          :mainObject="'vendorItem'"
          :mainProperty="'manufacturer'"
          :injectionName="'vendorDetailsFive'"
          :optionKey="'manufacturer-option-vendor-item'"
          :optionsList="[]"
          :optionProperty="null"
          :optionPropertyTwo="null"
          :defaultSelected="vendorItem.manufacturer"
          @click="() => {
            activated.manufacturer = true;
          }"
        />
      </template>
      <template v-else >
        <div class="dropdown-open-back-container">

          <input type="text" class="form-control" v-model="vendorItem.manufacturer" ref="manufacturerField"
            @focus="() => {
              activated.manufacturer = true;
              focused.manufacturer = true;
            }"
            @blur="() => {
              focused.manufacturer = false;
            }"
            placeholder="E.g., John Deere"
            >
          <div class="dropdown-open-back" @click="() => {
            manualManufacturer = false
          }"></div>
        </div>
      </template>
    <p v-if="activated.manufacturer && vendorItem.manufacturer.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.manufacturer && vendorItem.manufacturer.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>

    </div>


    <div class="col-3">

    <div class="form-item">
    <p class="form-label" :class="{active: focused.brand || (vendorItem.brand.length > 0)}">Brand</p>
    
    <template v-if="!manualBrand">
      <DropDownList
        :mainObject="'vendorItem'"
        :mainProperty="'brand'"
        :injectionName="'vendorDetailsSix'"
        :optionKey="'brand-option-vendor-item'"
        :optionsList="[]"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="vendorItem.brand"
        @click="() => {
          activated.brand = true;
        }"
      />
    </template>
    <template v-else >
      <div class="dropdown-open-back-container">    
        <input type="text" class="form-control" v-model="vendorItem.brand" ref="brandField"
          @focus="() => {
            activated.brand = true;
            focused.brand = true;
          }"
          @blur="() => {
            focused.brand = false;
          }"
          placeholder="E.g., MN790"
          >
        <div class="dropdown-open-back" @click="() => {
            manualBrand = false
          }"></div>
      </div>
    </template>
    <p v-if="activated.brand && vendorItem.brand.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.brand && vendorItem.brand.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>

    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.numberOfItems || (vendorItem.numberOfItems > 0)}"># of Items</p>
    <input type="number" class="form-control"
      v-model="vendorItem.numberOfItems"
      @focus="() => {
        activated.numberOfItems = true;
        focused.numberOfItems = true;
      }"
      @blur="() => {
        focused.numberOfItems = false;
      }"
      placeholder="E.g., 1"
      >
    <p v-if="activated.numberOfItems && `${vendorItem.numberOfItems}`.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.numberOfItems && `${vendorItem.numberOfItems}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.invoice || (vendorItem.invoice.length > 0)}">Invoice #</p>
    <input type="text" class="form-control" v-model="vendorItem.invoice"
      @focus="() => {
        activated.invoice = true;
        focused.invoice = true;
      }"
      @blur="() => {
        focused.invoice = false;
      }"
      placeholder="E.g., 5412"
      >
    <p v-if="activated.invoice && `${vendorItem.invoice}`.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.invoice && vendorItem.invoice.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.amountPaid || (vendorItem.amountPaid > 0)}">Amount</p>
    <div class="dollar-prefix">
    <span>$</span>
      <input type="text" class="form-control" v-model="vendorItem.amountPaid"
        @focus="() => {
          activated.amountPaid = true;
          focused.amountPaid = true;
        }"
        @blur="() => {
          focused.amountPaid = false;
        }"
        placeholder="E.g., 65,750.00"
        >
    </div>
    <p v-if="activated.amountPaid && `${vendorItem.amountPaid}`.length === 0" class="form-info red text-end">* Required</p>
    <!-- <p v-if="activated.amountPaid && `${vendorItem.amountPaid}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p> -->
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.warrantyExp || (vendorItem.warrantyExp > 0)}">Warranty Exp</p>
    <!-- <input type="number" class="form-control" v-model="vendorItem.warrantyExp"
      @focus="() => {
        activated.warrantyExp = true;
        focused.warrantyExp = true;
      }"
      @blur="() => {
        focused.warrantyExp = false;
      }"> -->
      <input type="date" class="form-control" 
        :style="{color: (focused.warrantyExp || vendorItem.warrantyExp.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
        v-model="vendorItem.warrantyExp"
        pattern="\d{2}/\d{2}/\d{4}"
        @focus="() => {
          activated.warrantyExp = true;
          focused.warrantyExp = true;
        }"
        @blur="() => {
          focused.warrantyExp = false;
        }">
      <div class="date-icon"></div>
    <p v-if="activated.warrantyExp && `${vendorItem.warrantyExp}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.serviceDate || (vendorItem.serviceDate > 0)}">Next Service Date</p>
    <input type="date" 
        pattern="\d{2}/\d{2}/\d{4}"
        class="form-control"
        v-model="vendorItem.serviceDate"
      @focus="() => {
        activated.serviceDate = true;
        focused.serviceDate = true;
      }"
      @blur="() => {
        focused.serviceDate = false;
      }">
      <div class="date-icon"></div>
    <!-- <p v-if="activated.serviceDate && `${vendorItem.serviceDate}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p> -->
    </div>
    </div>

    <div class="col-12">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.comments || (vendorItem.comments.length > 0)}">Comments</p>
    <textarea class="form-control" v-model="vendorItem.comments"
      @focus="() => {
        activated.comments = true;
        focused.comments = true;
      }"
      @blur="() => {
        focused.comments = false;
      }"
      placeholder="E.g., Added a new tractor"
      ></textarea>
    </div>
    </div>
  </template>

  <template v-if="vendorItem.type === 'Service'">

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.date || (vendorItem.date.length > 0)}">Date Purchased</p>
    <input type="date" class="form-control" 
      :style="{color: (focused.date || vendorItem.date.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
      v-model="vendorItem.date"
      pattern="\d{2}/\d{2}/\d{4}"
      @focus="() => {
        activated.date = true;
        focused.date = true;
      }"
      @blur="() => {
        focused.date = false;
      }">
      <div class="date-icon"></div>
    <p v-if="activated.date && vendorItem.date === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.date && vendorItem.date.length === 0" class="form-info red text-end">* Required</p>
    </div>
    </div>
    
    <div class="col-3">

    <div class="form-item">
    <p class="form-label" :class="{active: focused.serviceType || (vendorItem.serviceType.length > 0)}">Service Type</p>
    <input type="text" class="form-control" v-model="vendorItem.serviceType"
      @focus="() => {
        activated.serviceType = true;
        focused.serviceType = true;
      }"
      @blur="() => {
        focused.serviceType = false;
      }"
      placeholder="E.g., Service"
      >
    <p v-if="activated.serviceType && vendorItem.serviceType.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.serviceType && vendorItem.serviceType.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>

    </div>

    <div class="col-3">

    <div class="form-item">
    <p class="form-label" :class="{active: focused.equipment || (vendorItem.equipment.length > 0)}">Equipment</p>
    <template v-if="(equipmentsList.length > 0) && !manualEquipment">
      <!-- <select class="form-control" 
        v-model="vendorItem.equipment"
        @click="() => {
          activated.equipment = true;
          focused.equipment = true;
        }"
        @change="() => {
          focused.equipment = false;
        }">
        <option
          v-for="equipmentOption in equipmentsList"
          v-bind:key="equipmentOption + 'equipment-option-vendor-item'"
        >{{ equipmentOption }}</option> 
      </select> -->
      <DropDownList
        :mainObject="'vendorItem'"
        :mainProperty="'equipment'"
        :injectionName="'vendorDetailsTwo'"
        :optionKey="'equipment-option-vendor-item'"
        :optionsList="[]"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="vendorItem.equipment"
        @click="() => {
          activated.completionTime = true;
        }"
      />
    </template>
    <div v-else class="dropdown-open-back-container">
      <input class="form-control" v-model="vendorItem.equipment"
        ref="equipmentField"
        @focus="() => {
          activated.equipment = true;
          focused.equipment = true;
        }"
        @blur="() => {
          focused.equipment = false;
          if (!vendorItem.equipment) {
            // manualEquipment = false;
          }
        }"
        placeholder="E.g., Tractor"
        >
        <div class="dropdown-open-back" @click="() => {
          // manualEquipment = false
        }"></div>
      </div>
    <p v-if="activated.equipment && vendorItem.equipment.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.equipment && vendorItem.equipment.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>

    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.invoice || (vendorItem.invoice.length > 0)}">Invoice #</p>
    <input type="text" class="form-control" v-model="vendorItem.invoice"
      @focus="() => {
        activated.invoice = true;
        focused.invoice = true;
      }"
      @blur="() => {
        focused.invoice = false;
      }"
      placeholder="E.g., 5423"
      >
    <p v-if="activated.invoice && `${vendorItem.invoice}`.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.invoice && vendorItem.invoice.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.amountPaid || (vendorItem.amountPaid > 0)}">Amount</p>
    <div class="dollar-prefix">
      <span>$</span>
      <input type="text" class="form-control" v-model="vendorItem.amountPaid"
        @focus="() => {
          activated.amountPaid = true;
          focused.amountPaid = true;
        }"
        @blur="() => {
          focused.amountPaid = false;
        }"
        placeholder="E.g., 102.5"
        >
      </div>
    <p v-if="activated.amountPaid && `${vendorItem.amountPaid}`.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.amountPaid && `${vendorItem.amountPaid}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.warrantyExp || (vendorItem.warrantyExp > 0)}">Warranty Exp</p>
    <!-- <input type="number" class="form-control" v-model="vendorItem.warrantyExp"
      @focus="() => {
        activated.warrantyExp = true;
        focused.warrantyExp = true;
      }"
      @blur="() => {
        focused.warrantyExp = false;
      }"> -->
      <input type="date" class="form-control" 
        :style="{color: (focused.warrantyExp || vendorItem.warrantyExp.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
        v-model="vendorItem.warrantyExp"
        pattern="\d{2}/\d{2}/\d{4}"
        @focus="() => {
          activated.warrantyExp = true;
          focused.warrantyExp = true;
        }"
        @blur="() => {
          focused.warrantyExp = false;
        }">
      <div class="date-icon"></div>
    <p v-if="activated.warrantyExp && `${vendorItem.warrantyExp}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.serviceDate || (vendorItem.serviceDate > 0)}">Next Service Date</p>
    <!-- <input type="number" class="form-control" v-model="vendorItem.serviceDate"
      @focus="() => {
        activated.serviceDate = true;
        focused.serviceDate = true;
      }"
      @blur="() => {
        focused.serviceDate = false;
      }"> -->
      <input type="date" class="form-control" 
        :style="{color: (focused.serviceDate || vendorItem.serviceDate.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
        v-model="vendorItem.serviceDate"
        pattern="\d{2}/\d{2}/\d{4}"
        @focus="() => {
          activated.serviceDate = true;
          focused.serviceDate = true;
        }"
        @blur="() => {
          focused.serviceDate = false;
        }">
      <div class="date-icon"></div>
    <!-- <p v-if="activated.serviceDate && `${vendorItem.serviceDate}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p> -->
    </div>
    </div>

    <div class="col-12">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.comments || (vendorItem.comments.length > 0)}">Comments</p>
    <textarea class="form-control" v-model="vendorItem.comments"
      @focus="() => {
        activated.comments = true;
        focused.comments = true;
      }"
      @blur="() => {
        focused.comments = false;
      }"
      placeholder="E.g., Comment"
      ></textarea>
    </div>
    </div>

  </template>

</div>
</div>

</div>

<div class="content-block">

  <template v-if="vendorItem.type">
    <FileAttachments :key="`vendor-item-form-${vendorItem.id}`" v-bind:section="'vendorItem'"  v-bind:instance="`${vendorItem.id}`" />
  </template>

<div class="content-section">
<div class="buttons form-buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
</div>
</div>
</div>
</div>

</div>
</div>
</section>

<div class="removal-popup" v-if="popUps.addAnotherItem.active">

<div class="modal modal-md">
<span class="modal-close" @click="() => confirmAddAnother(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">{{ popUps.addAnotherItem.message }}</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => confirmAddAnother(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => confirmAddAnother(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>

<template  v-if="isInputDialog || isEquipmentDialog">
  <div v-if="popUps.addVendor.active" class="add-vendor-dialog">
    <div class="popup-message">
      <div class="close-button" @click="() => {
        popUps.addVendor.active = false;
      }" ></div>
      <div class="popup-text">{{popUps.addVendor.message}}</div>
    </div>
  </div>
</template>

<template v-else>
  <div v-if="popUps.addVendor.active">
    <UserNotification
      v-bind:hideDetails="true"
      v-bind:messageText="popUps.addVendor.message"
    />
  </div>
</template>

</template>

<script>
import { reactive, ref, inject, watch, onMounted, provide } from 'vue'
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import DropDownList from '@/components/Shared/DropDownList.vue';
import UserNotification from "@/components/Shared/UserNotification.vue";
import inventoriesOptions from '@/sharedData/inventoriesList.js';

export default {
    name: 'VendorProductsServices',
    setup() {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const activated = reactive({
            item: false,
            manufacturer: false,
            brand: false,
            variety: false,
            numberOfItems: false,
            quantity: false,
            date: false,
            invoice: false,
            amountPaid: false,
            warrantyExp: false,
            serviceDate: false,
            serviceType: false,
            equipment: false
        });
        const focused = reactive({
            item: false,
            manufacturer: false,
            brand: false,
            variety: false,
            numberOfItems: false,
            quantity: false,
            date: false,
            invoice: false,
            amountPaid: false,
            warrantyExp: false,
            serviceDate: false,
            serviceType: false,
            equipment: false
        });
        const { vendor, vendorItem, productsServicesMode, selectVendorItem, 
                defaultVendorItem, isInputDialog, isEquipmentDialog, 
                vendorFormSubmitted, manualEquipment, serviceSaved, navigateToVendorForm
              } = inject('vendorDetails');

        let addTaskDetails;

        if (isInputDialog.value || isEquipmentDialog.value) {
          addTaskDetails = inject('addTaskDetails');
        }

        const popUps = reactive({
            addAnotherItem: {
                active: false,
                message: 'Do you want to add another product or service?'
            },
            addVendor: {
              active: false,
              message: 'Please click save before proceeding'
            }
        });

        const newId = ref(null);

        const equipmentsList = ref([...[...store.state.vendorItems].filter(stateItem => stateItem.type === 'Equipment').map(stateEquipment => stateEquipment.item), 'Add New']);

        const products = ref([...[...store.state.vendorItems].filter(stateItem => stateItem.type === 'Product')]);

        const inputOptions = ref([...inventoriesOptions, ...products.value.map(option => {
          if (!inventoriesOptions.includes(option.item)) {
            return option.item
          }
        }).filter(o => o), 'Add New']);

        const varietyOptions = ref([...new Set(products.value.map(option => option.variety).filter(o => o)), 'Add New']);

        const manufacturerOptions = ref([...new Set([...store.state.vendorItems].map(option => option.manufacturer).filter(o => o)), 'Add New']);

        const brandOptions = ref([...new Set([...store.state.vendorItems].map(option => option.brand).filter(o => o)), 'Add New']);

        const otherSelected = ref(false);

        const equipmentField = ref(null);

        const manualInput = ref(false);

        const manualVariety = ref(false);

        const manualManufacturer = ref(false);

        const manualBrand = ref(false);

        const itemField = ref(null);

        const varietyField = ref(null);

        const manufacturerField = ref(null);

        const brandField = ref(null);

        const toggleSection = () => {
            sectionExpanded.value = !sectionExpanded.value;
        };
        const submitForm = () => {
          if (!vendorFormSubmitted.value) {
            if (productsServicesMode.value !== 'edit') {
              popUps.addVendor.active = true;
              return;
            }
          }
            if ((vendorItem.type === 'Product' && (!vendorItem.item || !vendorItem.manufacturer ||
                !vendorItem.numberOfItems || //!vendorItem.quantity ||
                !vendorItem.date || !vendorItem.invoice || !vendorItem.amountPaid)) || (vendorItem.type === 'Equipment' && (!vendorItem.item || !vendorItem.manufacturer ||
                !vendorItem.brand || !vendorItem.numberOfItems ||
                !vendorItem.date || !vendorItem.invoice || !vendorItem.amountPaid)) || (vendorItem.type === 'Service' && !vendorItem.serviceType ||
                !vendorItem.date || !vendorItem.invoice || !vendorItem.amountPaid)) {
                if (!vendorItem.item)
                    activated.item = true;
                if (!vendorItem.manufacturer)
                    activated.manufacturer = true;
                // if (!vendorItem.brand) activated.brand = true;
                // if (!vendorItem.variety) activated.variety = true;
                if (!vendorItem.numberOfItems)
                    activated.numberOfItems = true;
                // if (!vendorItem.quantity)
                //     activated.quantity = true;
                if (!vendorItem.date)
                    activated.date = true;
                if (!vendorItem.invoice)
                    activated.invoice = true;
                if (!vendorItem.amountPaid)
                    activated.amountPaid = true;
                if (!vendorItem.serviceType)
                    activated.serviceType = true;
                return;
            }
            if (!vendorItem.id) {
                // vendorItem.id = uuid();
              vendorItem.id = newId.value;
            }
            const formData = {
              ...vendorItem,
              vendor: vendorItem.vendor || vendor.id,
            };

            if (productsServicesMode.value !== 'edit') {
              delete formData._id;
            }

            productsServicesMode.value === 'edit' ?
                store.dispatch('editVendorItem', { ...formData }) :
                store.dispatch('addVendorItem', { ...formData });

            if (isInputDialog.value) {
              addTaskDetails.task.input = formData.item;
              addTaskDetails.inputDialog.value = false
            }
            if (isEquipmentDialog.value) {
              addTaskDetails.task.equipment = formData.item;
              addTaskDetails.equipmentDialog.value = false
            }

            if (manualEquipment?.value) {
              const currentEquipment = equipmentsList.value[equipmentsList.value.length - 2];
              selectVendorItem({ ...serviceSaved.value, equipment: currentEquipment }, 'create');
              vendorItem.type = 'Service';
              manualEquipment.value = false;
              serviceSaved.value = {};
            }

            if (!manualEquipment?.value) {
              popUps.addAnotherItem.active = true;
            }
        };
        const cancelChanges = () => {
            if (productsServicesMode.value === "edit") {
                productsServicesMode.value = "represent";
            }
            else {
                for (let prop in vendorItem) {
                    vendorItem[prop] = defaultVendorItem[prop];
                }
            }
            if (isInputDialog.value) {
              addTaskDetails.inputDialog.value = false
            }
            if (isEquipmentDialog.value) {
              addTaskDetails.equipmentDialog.value = false
            }
        };
        const confirmAddAnother = (confirmed) => {
            if (confirmed) {
                selectVendorItem({ ...defaultVendorItem }, 'create');
            }
            else {
                selectVendorItem({ ...vendorItem }, 'represent');
            }
            popUps.addAnotherItem.active = false;
        };

        const addVendorBefore = () => {
          if (!vendorFormSubmitted.value) {
            if (productsServicesMode.value !== 'edit') {
              popUps.addVendor.active = true;
              navigateToVendorForm();
              setTimeout(() => {
                popUps.addVendor.active = false;
              }, 5000)
              return;
            }
          }
        }

        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });
        watch(() => (vendorItem.type), () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
        });
        watch(() => ({...store.state.attachments}), () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
        });
        // const removeVendorEntity = (section, entityId) => {
        //   if (section === 'inventories') store.dispatch('removeVendorInventories', entityId)
        // };
        // watch(store.state.vendorInventories, (newVal) => {
        //   if (newVal) vendorInventories.value = newVal.filter(vi => vi.vendor === vendor.vendor);
        // })
        // watch(section.value, (newVal) => {
        //   if (newVal) {

        //   }
        // });
        
        watch(() => (vendorItem.vendor), (newVal) => {
          if (newVal) {
            if (isEquipmentDialog.value) {
              vendorItem.type = 'Equipment'
            }
            if (isInputDialog.value) {
              vendorItem.type = 'Product'
            }
          }
        })

        watch(() => (focused.amountPaid), (newVal, oldVal) => {
          if (oldVal && !newVal) {
            const fixedAmount = `${Number.parseFloat(vendorItem.amountPaid || 0).toFixed(2)}`;
            const formattedAmount = (fixedAmount.length > 7) ? 
              `${(fixedAmount.length === 8) ? '' : `${(fixedAmount.length > 9) ? `${fixedAmount.slice(0,-9)},` : fixedAmount.slice(0,-8)}`}${(fixedAmount.length > 9) ? fixedAmount.slice(-9,-6) : fixedAmount.slice(-8,-6)},${fixedAmount.slice(-6)}` : 
              (fixedAmount.length > 5) ? `${(fixedAmount.length === 7) ? fixedAmount.slice(-8,-6) : fixedAmount.slice(-7,-5)}${(fixedAmount.length > 6) ? `${fixedAmount.slice(-6)}` : `${fixedAmount.slice(-5)}`}` :
              `${fixedAmount}`
            vendorItem.amountPaid = formattedAmount;
          }
        })

        watch(() => ([...store.state.vendorItems]), (newVal) => {
          if (newVal) {
            equipmentsList.value = [...[...newVal].filter(stateItem => stateItem.type === 'Equipment').map(stateEquipment => stateEquipment.item), 'Add New']

            const inventoriesList = [...newVal].filter(stateItem => stateItem.type === 'Product');

            products.value = [...inventoriesList];

            inputOptions.value = [...inventoriesOptions, ...products.value.map(option => {
              if (!inventoriesOptions.includes(option.item)) {
                return option.item
              }
            }).filter(o => o), 'Add New'];

            varietyOptions.value = [...new Set(products.value.map(option => option.variety).filter(o => o)), 'Add New'];

            if (vendorItem.type === 'Product') {
              manufacturerOptions.value = [...new Set(products.value.map(option => option.manufacturer).filter(o => o)), 'Add New']
              brandOptions.value = [...new Set(products.value.map(option => option.brand).filter(o => o)), 'Add New']
            }

            if (vendorItem.type === 'Equipment') {
              manufacturerOptions.value = [...[...newVal].filter(stateItem => stateItem.type === 'Equipment').map(stateEquipment => stateEquipment.manufacturer).filter(o => o), 'Add New'];
              brandOptions.value = [...[...newVal].filter(stateItem => stateItem.type === 'Equipment').map(stateEquipment => stateEquipment.brand).filter(o => o), 'Add New'];
            }

          }
        });

        watch(() => (vendorItem.equipment), (newVal) => {
          if (newVal === 'Add New') {
            manualEquipment.value = true;
            serviceSaved.value = {
              ...vendorItem
            };
            console.log(manualEquipment.value, serviceSaved.value, 'manualEquipment')
            selectVendorItem({ ...defaultVendorItem }, 'create');
            vendorItem.type = 'Equipment';
            // vendorItem.equipment = '';
            // otherSelected.value = true;
            // setTimeout(() => {
            //   equipmentField.value.focus()
            // }, 10)
          }
        });

        watch(() => (vendorItem.item), (newVal) => {
          if (newVal === 'Add New') {
            manualInput.value = true;
            otherSelected.value = true;
            vendorItem.item = '';
            setTimeout(() => {
              itemField.value.focus();
            }, 10)
          }
          varietyOptions.value = [...new Set(products.value.map(option => {
            if (option.item === newVal) {
              return option.variety;
            }
          }).filter(o => o)), 'Add New'];
        })
        
        watch(() => (vendorItem.variety), (newVal) => {
          if (newVal === 'Add New') {
            manualVariety.value = true;
            otherSelected.value = true;
            vendorItem.variety = '';
            setTimeout(() => {
              varietyField.value.focus();
            }, 10)
          }
        })

        watch(() => (vendorItem.manufacturer), (newVal) => {
          if (newVal === 'Add New') {
            manualManufacturer.value = true;
            otherSelected.value = true;
            vendorItem.manufacturer = '';
            setTimeout(() => {
              manufacturerField.value.focus();
            }, 10)
          }
        })

        watch(() => (vendorItem.brand), (newVal) => {
          if (newVal === 'Add New') {
            manualBrand.value = true;
            otherSelected.value = true;
            vendorItem.brand = '';
            setTimeout(() => {
              brandField.value.focus();
            }, 10)
          }
        })

        watch(() => (vendorItem.type), (newVal) => {
          if (newVal === 'Product') {
            manufacturerOptions.value = [...new Set(products.value.map(option => option.manufacturer).filter(o => o)), 'Add New'];
            brandOptions.value = [...new Set(products.value.map(option => option.brand).filter(o => o)), 'Add New'];
          }

          if (newVal === 'Equipment') {
            manufacturerOptions.value = [...[...store.state.vendorItems].filter(stateItem => stateItem.type === 'Equipment').map(stateEquipment => stateEquipment.manufacturer).filter(o => o), 'Add New'];
            brandOptions.value = [...[...store.state.vendorItems].filter(stateItem => stateItem.type === 'Equipment').map(stateEquipment => stateEquipment.brand).filter(o => o), 'Add New'];
          }
        });

        onMounted(() => {
          equipmentsList.value = [...[...store.state.vendorItems].filter(stateItem => stateItem.type === 'Equipment').map(stateEquipment => stateEquipment.item), 'Add New'];
          console.log(equipmentsList, 'equipmentsList');
            // vendorInventories.value = store.state.vendorInventories.filter(vi => vi.vendor === vendor.vendor)
            setTimeout(() => {
              if (section.value) {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
              }
            });
            if (productsServicesMode.value !== 'edit') {
              if (!vendorItem.id) {
                newId.value = uuid();
                vendorItem.id = newId.value;
              }
            }

          if (vendorItem.type === 'Product') {
            manufacturerOptions.value = [...new Set(products.value.map(option => option.manufacturer).filter(o => o)), 'Add New'];
            brandOptions.value = [...new Set(products.value.map(option => option.brand).filter(o => o)), 'Add New'];
          }

          if (vendorItem.type === 'Equipment') {
            manufacturerOptions.value = [...[...store.state.vendorItems].filter(stateItem => stateItem.type === 'Equipment').map(stateEquipment => stateEquipment.manufacturer).filter(o => o), 'Add New'];
            brandOptions.value = [...[...store.state.vendorItems].filter(stateItem => stateItem.type === 'Equipment').map(stateEquipment => stateEquipment.brand).filter(o => o), 'Add New'];
          }
        });

        provide('vendorDetailsTwo', {
          vendorItem,
          options: equipmentsList
        })

        provide('vendorDetailsThree', {
          vendorItem,
          options: inputOptions
        });

        provide('vendorDetailsFour', {
          vendorItem,
          options: varietyOptions
        })

        provide('vendorDetailsFive', {
          vendorItem,
          options: manufacturerOptions
        })

        provide('vendorDetailsSix', {
          vendorItem,
          options: brandOptions
        })

        provide('otherHandling', {
          otherSelected
        });

        return {
            activated,
            focused,
            section,
            boxHeader,
            toggleSection,
            vendorItem,
            popUps,
            submitForm,
            cancelChanges,
            confirmAddAnother,
            vendor,
            equipmentsList,
            manualEquipment,
            equipmentField,
            manualInput,
            itemField,
            inputOptions,
            isInputDialog, 
            isEquipmentDialog,
            addVendorBefore,
            manualVariety,
            varietyField,
            manualManufacturer,
            manufacturerField,
            manualBrand,
            brandField
        };
    },
    components: { FileAttachments, DropDownList, UserNotification }
}
</script>

<style lang="scss" scoped>
.align-items-end {
  // .form-label-static {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   transform: translateY(-110%);
  // }
  h6 {
    font-family: var(--fw-semibold);
  }
  .btn-close-form {
    width: 2rem;
    height: 2rem;
    background: url('@/assets/images/close.png');
    background-position: center;
    background-size: contain;
    margin-left: 3rem;
    cursor: pointer;
  }
}

.add-vendor-dialog {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-message {
    background: #fff;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    // width: 100%;
    width: max-content;
    height: max-content;
    justify-content: space-around;
    align-items: center;
    position: relative;
    // border-radius: 11px;
    color: rgba(40,41,43,1);
    font-size: 21px;
    margin: 0 20px;
    padding: 25px;
    text-align: center;
    .close-button {
      position: absolute;
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 30px;
      height: 30px;
      background-image: url('@/assets/profile/close_button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      right: 10px;
      top: 22px;
      cursor: pointer;
    }
    .popup-text {
      margin: 0 40px;
      font-size: 16px;
      font-weight: 600;
    }
    .popup-details {
      font-size: 16px;
      .contact-row {
        color: #38AC89;
      }
    }
    .popup-buttons {
      display: flex;
      width: 60%;
      justify-content: space-evenly;
      margin-top: 30px;
      button {
        width: 160px;
        height: 40px;
        border-radius: 10px;
      }
    }
  }
}
</style>