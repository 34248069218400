const options = [
  'Center-Pivot',
  'Drip',
  'Flood',
  'Furrow',
  'Gravity',
  'Irrigation Scheduling',
  'Linear Move',
  'Localized',
  'Manual', 
  'Micro',
  'Micro Irrigation',
  'Micro Sprinklers',
  'Sprinkler',
  'Surface',
  'Travelling Big Gun',
  'Other'
];

export default options;
