<template>

<section class="section" ref="section">
<div class="border-box">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Payment Information</h5>
<div class="info-icons">
  <div class="icon info-icon payment-tooltip-container tooltip"
    v-if="isAuth('add-payment') && !onlyViewMode"
    @click="addNewPayment"
    data-tooltip="Add Payment"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
  >
    <!-- <img src="@/assets/images/plus-green-md.png" alt=""> -->
    <img src="@/assets/images/updated/059-add.png" alt="">
    <!-- <div class="helper-tooltip" v-if="helperTooltip">Add Payment</div> -->
  </div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-block">
<div class="payment-details">

<p  v-if="paymentMethods.length > 0" class="form-label-static">Choose Payment Type</p>

<div v-if="paymentMethods.length <= 0" class="payment-info-entry">
  <span>You have not set up any payment methods yet.</span>
  <span class="runs-out">Your payment method will only be charged after your 30 day subsription runs out.</span>
</div>

<template
  v-for="(paymentMethod, index) in paymentMethods"
  v-bind:key="'paymentmethod' + index"
>
  <template v-if="paymentMethod?.cardType">
    <div class="content-row payment-details-header">
<div class="row">
<div class="col-4">

<p class="form-label-static mb-1" v-if="index === 0">Primary Payment</p>
<p class="form-label-static" v-if="index === 1">Secondary Payment</p>

<div class="card">
  <!-- <img :src="`@/assets/images/${paymentMethod.cardType}.png`" alt=""> -->
  <div class="selection-payment">
    <input
      type="checkbox"
      :id="`select-payment-${index}`"
      :checked="((index === 0) && !paymentMethod.id) || (paymentMethod.id === selectedPaymentMethod)"
      @change="selectPaymentMethod(paymentMethod.id)" 
    />
    <!-- (selectedPaymentMethod === paymentMethod.id) -->
    <!-- paymentMethod.selected ||  -->
    <label :for="`select-payment-${index}`"></label>
  </div>
  <div class="card-icon" :class="paymentMethod.cardType"></div>
  <h6>{{
    paymentMethod.cardType === 'mastercard' ? 
      `${paymentMethod.cardType[0].toUpperCase()}${paymentMethod.cardType.slice(1, 6)}${paymentMethod.cardType[6].toUpperCase()}${paymentMethod.cardType.slice(7)}` :
      `${paymentMethod.cardType[0].toUpperCase()}${paymentMethod.cardType.slice(1)}`
  }} {{paymentMethod.cardNumber}}</h6>
</div>

</div>
<div class="col-4">
<p class="form-label-static mb-1">Apply</p>
<div class="apply-list">
  <div class="apply-method">
    <input
      type="checkbox"
      :id="`apply-payment-${index}`"
      v-model="paymentMethod.applied"
      @change="applyPaymentMethod(index)" 
    />
    <label :for="`apply-payment-${index}`"></label>
    <!-- <img src="@/assets/images/checkbox-checked.svg" alt="" /> -->
  </div>
  <div
    @click="editPaymentMethod(index)"
    data-tooltip="Edit"
    @mouseover="switchEditHelperTooltip(true, index)"
    @mouseout="switchEditHelperTooltip(false, index)"
    class="edit-method tooltip"
  >
    <img src="@/assets/images/edit-icon.svg" alt="" />
    <!-- <div class="edit-helper-tooltip" v-if="editHelperTooltip && (index === currentTooltip)">Edit</div> -->
  </div>
  <div
    @click="() => removalApprove(index, paymentMethod.id)"
    data-tooltip="Remove"
    @mouseover="switchRemoveHelperTooltip(true, index)"
    @mouseout="switchRemoveHelperTooltip(false, index)"
    class="remove-method tooltip"
  >
    <img src="@/assets/images/trash-icon.svg" alt="" />
    <!-- <div class="remove-helper-tooltip" v-if="removeHelperTooltip && (index === currentTooltip)">Remove</div> -->
  </div>
</div>
</div>
<div class="col-4 align-self-center">
<p class="form-label-static" @click="() => switchDetails(index)"><a href="javascript:void();">Details</a></p>
</div>

</div>
</div>

<div class="content-row" v-if="paymentMethod.detailsOpened">

<template v-if="!paymentMethod.isEditable">
  <div class="row">
  <div class="col-3">
  <p class="form-label-static">Name on Card</p>
  <h6>{{ paymentMethod.cardHolderName }}</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">Card Number</p>
  <h6>**** **** **** {{ paymentMethod.cardNumber }}</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">Card Type</p>
  <h6>{{ paymentMethod.cardType }}</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">Expiry Date</p>
  <h6>{{ paymentMethod.expiryDate }}</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">3 Digit CVV Code</p>
  <h6>***</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">Zip Code</p>
  <h6>{{ paymentMethod.zip }}</h6>
  </div>
  </div>
</template>

<template v-else>
  <div class="row">
  <div class="col-3">
  <p class="form-label-static">Name on Card</p>
  <!--   :value="paymentMethod.cardHolderName"
    @input="event => payment.cardHolderName = event.target.value"   -->
  <input type="text" class="form-control"
    v-model="payment.cardHolderName"  
    @focus="() => {
      activated.cardHolderName = true;
      focused.cardHolderName = true;
    }"
    @blur="() => {
      focused.cardHolderName = false;
    }"
  >
  <p v-if="activated.cardHolderName && payment.cardHolderName.length === 0" class="form-info red text-end">* Required</p>
  </div>
  <div class="col-3">
  <p class="form-label-static">Card Number</p>
  <h6>**** **** **** {{ paymentMethod.cardNumber }}</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">Card Type</p>
  <h6>{{ paymentMethod.cardType }}</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">Expiry Date</p>
  <div class="calendar-field">
  <input type="text" class="form-control" id="datepicker"
    v-model="payment.expiryDate"
    @change="expiryDateInputAction"
    @focus="() => {
      activated.expiryDate = true;
      focused.expiryDate = true;
      expiryDateFocusAction();
    }"
    @blur="() => {
      focused.expiryDate = false;
      expiryDateBlurAction();
    }"
  >
  <div v-if="focused.expiryDate && (payment.expiryDate.length === 0)" class="expiry-date-placeholder">MM/YYYY</div>
  <input class="picked-up-expiry" type="date" pattern="\d{2}/\d{2}/\d{4}" v-model="pickeUpExpiryDate" />
  <p v-if="activated.expiryDate && payment.expiryDate.length === 0" class="form-info red text-end error">* Required</p>
  <p v-if="activated.expiryDate && payment.expiryDate.length > 7" class="form-info red text-end error">Please use MM/YYYY format</p>
  <img src="@/assets/images/calendar-icon.svg" alt="">
  </div>
  </div>
  <div class="col-3">
  <p class="form-label-static">3 Digit CVV Code</p>
  <h6>***</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">Zip Code</p>
  <input type="text" class="form-control" 
    v-model="payment.zip"
    @focus="() => {
      activated.zip = true;
      focused.zip = true;
    }"
    @blur="() => {
      focused.zip = false;
    }"
  >
  <p v-if="activated.zip && payment.zip.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.zip && (payment.zip.length > 0) && !(/^[0-9]{5}-[0-9]{3}.*/.test(payment.zip) || ((payment.zip.length <= 5) && /^[0-9]{5}.*/.test(payment.zip)))" class="form-info red text-end">Use 5 digits or #####-### formats</p>
  </div>
  </div>
  <div class="row">
    <div class="col-auto"><button type="submit" class="btn green" @click="() => submitPaymentForm(index)">Save</button></div>
    <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="() => cancelPaymentChanges(index)">Cancel</button></div>
  </div>
</template>

</div>
  </template>

  <template v-if="paymentMethod?.accountType">
    <div class="content-row payment-details-header">
<div class="row">
<div class="col-4">
  <p class="form-label-static mb-1" v-if="index === 0">Primary Payment</p>
  <p class="form-label-static" v-if="index === 1">Secondary Payment</p>
<h6 class="form-label-static">Account Nickname</h6>
<h6>{{paymentMethod.accountNickname}}</h6>
</div>
<div class="col-4">
<p class="form-label-static mb-1">Apply</p>
<div class="apply-list">
  <div class="apply-method">
    <input
      type="checkbox"
      :id="`apply-payment-sec-${index}`"
      v-model="paymentMethod.applied"
      @change="applyPaymentMethod(index)" 
    />
    <label :for="`apply-payment-sec-${index}`"></label>
  </div>
  <div
    @click="editPaymentMethod(index)"
    @mouseover="switchEditHelperTooltip(true, index)"
    @mouseout="switchEditHelperTooltip(false, index)"
    class="edit-method"
  >
    <img src="@/assets/images/edit-icon.svg" alt="" />
    <div class="edit-helper-tooltip" v-if="editHelperTooltip && (index === currentTooltip)">Edit</div>
  </div>
  <div
    @click="() => removalApprove(index)"
    @mouseover="switchRemoveHelperTooltip(true, index)"
    @mouseout="switchRemoveHelperTooltip(false, index)"
    class="remove-method"
  >
    <img src="@/assets/images/trash-icon.svg" alt="" />
    <div class="remove-helper-tooltip" v-if="removeHelperTooltip && (index === currentTooltip)">Remove</div>
  </div>
</div>
</div>
<div class="col-4 align-self-center">
<p class="form-label-static" @click="() => switchDetails(index)"><a href="javascript:void();">Details</a></p>
</div>
</div>
</div>

<div class="content-row"  v-if="paymentMethod.detailsOpened">
<div class="row">
<div class="col-3">
<p class="form-label-static">Name on Account</p>
<h6>{{ paymentMethod.accountName }}</h6>
</div>
<div class="col-3">
<p class="form-label-static">Account Type</p>
<h6>{{ paymentMethod.accountType }}</h6>
</div>
<div class="col-3">
<p class="form-label-static">Routing No.</p>
<h6>{{ paymentMethod.routing }}</h6>
</div>
<div class="col-3">
<p class="form-label-static">Re-enter Routing No.</p>
<h6>{{ paymentMethod.routing }}</h6>
</div>
<div class="col-3">
<p class="form-label-static">Account No.</p>
<h6>{{ paymentMethod.accountNumber }}</h6>
</div>
<div class="col-3">
<p class="form-label-static">Re-enter Account No.</p>
<h6>{{ paymentMethod.accountNumber }}</h6>
</div>
</div>
</div>
  </template>
</template>

</div>
</div>

</div>

</div>
</section><!--//End Section-->

<div class="removal-popup" v-if="paymentRemovalIndex.removal">

  <div class="modal modal-md">
    <span class="modal-close" @click="() => removalApprove(null)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
    <div class="modal-block text-center">
    <p class="fw-bold">Are you sure you want to remove this payment method?</p>
    </div>
    <div class="modal-block text-center">
    <div class="buttons justify-content-center">
        <button
          @click="() => removePayment()"
          class="approve-button btn green" 
        >Yes</button>
        <button
          @click="() => removalApprove(null)" 
          class="cancel-removal-button btn green btn-o" 
        >Cancel</button>
    </div>
    </div>
    </div>

  <!-- <div class="removal-message">
    <div class="close-button" @click="() => removalApprove(null)" ></div>
    <span class="removal-text">Are you sure you want to remove this payment method</span>
    <div class="removal-buttons">
      <button
        @click="() => removePayment()"
        class="approve-button btn green" 
      >Yes</button>
      <button
        @click="() => removalApprove(null)" 
        class="cancel-removal-button btn light-blue-20 btn-o" 
      >Cancel</button>
    </div>
  </div> -->
  
</div>

</template>

<script>
import { inject, reactive, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "ProfilePayment",
  props: {
    userType: String,
  },
  setup() {
    const { payment, defaultPayment, selectedPayment, focusOnPayment, changePaymentField, selectedPaymentMethod } = inject('payment');
    const store = useStore();
    const { isAuth } = inject('mainUI');
    const { onlyViewMode } = inject('userProfileCommon');

    const sectionHeader = 'Payment Information';

    const activated = reactive({
      cardHolderName: false,
      expiryDate: false,
      zip: false
    })
    const focused = reactive({
      cardHolderName: false,
      expiryDate: false,
      zip: false
    })

    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);

    const paymentMethods = ref([]);

    const appliedPayment = ref(null);

    const currentTooltip = ref(null);

    const paymentMethodIsEditable = ref(false);

    const pickeUpExpiryDate = ref(null);

    const helperTooltip = ref(false);
    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle;
    }

    const editHelperTooltip = ref(false);
    const switchEditHelperTooltip = (toggle, index) => {
      currentTooltip.value = index;
      editHelperTooltip.value = toggle;
    }
    
    const removeHelperTooltip = ref(false);
    const switchRemoveHelperTooltip = (toggle, index) => {
      currentTooltip.value = index;
      removeHelperTooltip.value = toggle;
    }

    const expiryDateFocusAction = (ev) => {
      if (ev) {
        ev.target.placeholder = "MM/YYYY";
      }
    };

    const expiryDateBlurAction = (ev) => {
      if (ev) {
        ev.target.placeholder = "Expiry Date";
      }
    };


    const paymentRemovalIndex = reactive({
      removal: false,
      paymentIndex: null,
      paymentId: null
    });

    const removalApprove = (indx, paymentId) => {
      if ((typeof indx === 'number') || paymentId) {
        paymentRemovalIndex.removal = true;
        paymentRemovalIndex.paymentIndex = indx;
        paymentRemovalIndex.paymentId = paymentId;
      } else {
        paymentRemovalIndex.removal = false;
        paymentRemovalIndex.paymentIndex = null;
      }
    }

    const removePayment = () => {
      const paymentId = paymentRemovalIndex.paymentId;
      store.dispatch('removePaymentMethod', paymentId);
      // store.dispatch('removeAccount', parseInt(paymentRemovalIndex.paymentIndex));
      // paymentMethods.value.splice(parseInt(paymentRemovalIndex.paymentIndex), 1)
      paymentRemovalIndex.removal = false;
      paymentRemovalIndex.paymentIndex = null;
      paymentRemovalIndex.paymentId = null;
    }

    const switchDetails = (index) => {
      console.log(paymentMethods.value[index]);
      paymentMethods.value[index].detailsOpened = !paymentMethods.value[index].detailsOpened;

      section.value.style.height = 'auto';
      setTimeout(() => {
        sectionHeight.value = section.value.offsetHeight;
      })
    }

    const editPaymentMethod = (index) => {
      if (paymentMethods.value[index].isEditable) {
        return;
      }
      for (let prop in paymentMethods.value[index]) {
        payment[prop] = paymentMethods.value[index][prop];
      }
      selectedPayment.value = index;
      // changePaymentField('edit', 'mode')
      paymentMethods.value[index].detailsOpened = true;
      paymentMethods.value[index].isEditable = true;
    }

    const submitPaymentForm = (index) => {
      store.dispatch('changePaymentMethodDetails', {
        data: {
          ...payment
        }
      });
      paymentMethods.value[index].isEditable = false;
    }

    const cancelPaymentChanges = (index) => {
      paymentMethods.value[index].isEditable = false;
      payment.id = defaultPayment.id;
      payment.cardHolderName = defaultPayment.cardHolderName;
      payment.expiryDate = defaultPayment.expiryDate;
      payment.zip = defaultPayment.zip;
    }

    const addNewPayment = () => {
      selectedPayment.value = null;
      changePaymentField('edit', 'mode')
    }

    const applyPaymentMethod = (index) => {
      paymentMethods.value.forEach((payment, indx) => {
        if (indx !== index) {
          payment.applied = false;
        }
      })
      appliedPayment.value = index;

      const currentApplied = paymentMethods.value.find((pm, indx) => indx === appliedPayment.value)

      if (currentApplied) {
        store.dispatch('selectPaymentMethod', currentApplied.id);
      }
    }

    const selectPaymentMethod = (paymentId) => {
      if (paymentId) {
        selectedPaymentMethod.value = paymentId;
        
        store.dispatch('selectPaymentMethod', paymentId);
      }
    }

    const cancelApplying = () => {
      paymentMethods.value[appliedPayment.value].applied = false;
      appliedPayment.value = null;
    }

    const saveSelectedMethod = () => {
      store.dispatch('savePaymentMethod', {payment: paymentMethods.value[appliedPayment.value], selectedPayment: appliedPayment.value});
      store.dispatch('saveSecondaryUser', {section: 'payment', data: paymentMethods.value[appliedPayment.value]});
      appliedPayment.value = null;
    }

    const selectApplied = () => {
      paymentMethods.value.forEach((pMethod, index) => {
        pMethod.applied = (index === store.state.authenticated.selectedPayment);
      })
    }

    const toggleSection = () => {
      sectionExpanded.value = !sectionExpanded.value
    }

    watch(sectionExpanded, (newVal) => {
      section.value.style.height = newVal ? 
        `${sectionHeight.value}px` : 
          `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'    })

    watch(selectedPaymentMethod, (newVal) => {
      if (newVal) {
        selectPaymentMethod(newVal);
      }
    })

    watch(store.state.authenticated.selectedPayment, () => {
      selectApplied();
    });

    watch(() => ({...store.state.primaryUserTenant}), () => {
      store.dispatch('getPaymentMethodsList');
    })

    watch(focusOnPayment, (newVal) => {
      if (newVal) {
        switchHelperTooltip(true);
        setTimeout(() => switchHelperTooltip(false), 5000);
      }
    });

    watch(() => (payment.expiryDate), (newVal, oldVal) => {
      if (newVal && oldVal) {
        if (!oldVal.includes("/") && (payment.expiryDate.length === 2)) {
          payment.expiryDate = payment.expiryDate + "/";
        }
      }
    });

    watch(pickeUpExpiryDate, (newVal) => {
      const monthYear = `${newVal}`.split("-");
      payment.expiryDate = `${monthYear[1]}/${monthYear[0]}`;
    });

    watch(() => store.state.authenticated.paymentMethods, (newVal) => {
      console.log(newVal, 'authenticated.paymentMethods');
      
      section.value.style.height = 'auto';
      setTimeout(() => {
        sectionHeight.value = section.value.offsetHeight;
      })

      paymentMethods.value = [
        ...store.state.authenticated.paymentMethods.map(method => {
          return {
            ...method,
            detailsOpened: false,
            applied: false,
            isEditable: false
          }
        })
      ]
      
      if ((selectedPaymentMethod.value === null) || (selectedPaymentMethod.value === undefined)) {
        const currentPaymentMethod = paymentMethods.value[0];

        if (currentPaymentMethod) {
          selectPaymentMethod(currentPaymentMethod.id);
        }
      }
    }, { deep: true });

    onMounted(() => {

      console.log(store.state.primaryUser.billing, store.state.authenticated.paymentMethods, selectedPaymentMethod.value, '3 items');
      
      setTimeout(() => {
        sectionHeight.value = section.value.offsetHeight;
        section.value.style.height = `${section.value.offsetHeight}px`;
      });

      store.dispatch('getPaymentMethodsList');

      paymentMethods.value = [
        ...store.state.authenticated.paymentMethods.map(method => {
          return {
            ...method,
            detailsOpened: false,
            applied: false,
            isEditable: false
          }
        })
      ];

      selectApplied();

      // create action - mutation - request - response - save

      if ((paymentMethods.value.length > 1) && store.state.secondaryUser?.mainDetails?.submitted) {
        if (!store.state.authenticated.approvedPayment) {
          paymentMethods.value.forEach(pMethod => {
            pMethod.applied = false;
          })
        }
      }
    })

    return {
      sectionHeader,
      payment,
      changePaymentField,
      paymentMethods,
      switchHelperTooltip,
      helperTooltip,
      editHelperTooltip,
      switchEditHelperTooltip,
      removeHelperTooltip,
      switchRemoveHelperTooltip,
      paymentRemovalIndex,
      removalApprove,
      removePayment,
      switchDetails,
      editPaymentMethod,
      addNewPayment,
      applyPaymentMethod,
      appliedPayment,
      cancelApplying,
      saveSelectedMethod,
      isAuth,
      section,
      boxHeader,
      toggleSection,
      currentTooltip,
      
      paymentMethodIsEditable,
      activated,
      focused,
      expiryDateFocusAction,
      expiryDateBlurAction,
      pickeUpExpiryDate,
      submitPaymentForm,
      cancelPaymentChanges,
      selectPaymentMethod,
      selectedPaymentMethod,
      onlyViewMode
    };
  },
};
</script>

<style lang="scss" scoped src="@/styles/pages/UserProfile/ProfilePayment.scss"></style>
