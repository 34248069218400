<template>
  <!-- <section class="hero">
    <div class="hero-block">
      <div class="hero-banner">
        <img :src="profileBgImg" alt="">
      </div>
      <a href="javascript:void();" class="hero-edit">
        <input
          type="file"
          name="backgroundupload"
          class="background-file"
          ref="uploadbg"
          accept=".jpg,.png,.jpeg"
          @change="uploadImgDirect"
        />
        <img src="@/assets/images/edit-profile-xs.svg" alt="">
      </a>
    </div>
    <div class="user">
      <div class="user-image">
        <img src="@/assets/images/user.jpg" alt="">
      </div>
      <h5>{{ `${ firstName} ${ lastName}` }}</h5>
    </div>
  </section> -->
  <!--//End Hero-->
  <div></div>
  
  <!-- <div class="profile-header-card">
    <div
      class="profile-background"
      :style="{
        backgroundImage: profileBg,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 100%',
        backgroundPosition: 'center center',
        borderRadius: '10px'
      }"
    >
      <div class="change-background" v-if="isAuth('change-background')">
        <input
          type="file"
          name="backgroundupload"
          class="background-file"
          ref="uploadbg"
          accept=".jpg,.png,.jpeg"
          @change="uploadImgDirect"
        />
      </div>
    </div>
    <div class="row wrapper">
      <div class="avatar-wrapper">
        <div
          class="avatar"
          :style="{
            backgroundImage: profileAvatar,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }"
        ></div>
        <div class="name">{{ `${ firstName} ${ lastName}` }}</div>
      </div>
    </div>
  </div> -->
</template>

<script>
import { ref, computed, inject, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "SecondaryProfileHeader",
  props: {
    openAdditionalForm: Function,
  },
  setup() {
    const store = useStore();

    const dynamicBgName = ref(require("@/assets/profile/background.png")); // take from store by id
    const profileBg = ref(`url(${dynamicBgName.value})`);
    const profileBgImg = ref(require('@/assets/images/hero-banner.jpg'));

    const { mainDetails } = inject('mainDetails');
    const { isAuth } = inject('mainUI');

    const firstName = ref(''),
      lastName = ref('');
    
    watch(mainDetails, (newVal) => {
      if (newVal.submitted) {
        console.log(newVal.submitted);
        firstName.value = newVal.firstName || '';
        lastName.value = newVal.lastName || '';
      }
    })

    const uploadImgDirect = (ev) => {
      console.log(ev);
      store.dispatch("uploadMedia", {
        file: ev.target.files[0],
        place: "background",
      });
      createPreview(ev);
    };

    const createPreview = (e) => {
      const reader = new FileReader();
      // let fileName = e.target.value.split("\\");
      // fileName = fileName[fileName.length - 1].split(".")[0];

      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
      reader.onload = function () {
        setTimeout(() => {
          profileBg.value = `url(${reader.result})`;
          profileBgImg.value = `${reader.result}`;
          console.log(reader.result);
        }, 500);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    };

    let dynamicAvatarName = "user-avatar.png"; // take it from id dynamic

    const profileAvatar = computed(() => {
      return require("@/assets/profile/" + dynamicAvatarName);
    });

    return {
      firstName,
      lastName,
      profileBg,
      profileBgImg,
      uploadImgDirect,
      profileAvatar: `url(${profileAvatar.value})`,
      isAuth
    };
  },
};
</script>

<style lang="scss" scoped>
.background-file {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 50%;
  cursor: pointer;
}
.profile-header-card {
  position: relative;

  border-radius: 10px;
  overflow: hidden;
}
.profile-background {
  width: 100%;
  height: 15vw;
  // border-radius: 10px;
  position: absolute;

  // margin-left: -16px;
  // margin-top: -18px;
  .change-background {
    background-image: url("@/assets/profile/edit-icon.svg");
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: center;
    width: 35px;
    height: 35px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    top: auto;

    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 50%;
    backdrop-filter: blur(6px);
    .background-file {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      opacity: 0;
      cursor: pointer;
    }
  }
}
.avatar-wrapper {
  display: flex;
  margin-top: 11vw;
  margin-bottom: 1vw;
  margin-left: 2vw;
  align-items: flex-end;
  z-index: 1;
  .avatar {
    width: 8vw;
    height: 8vw;
    border-radius: 10px;
    border: 3px solid #fff;
  }
  .name {
    margin-bottom: 1.17vw;
    margin-left: 1.17vw;
    color: #28292B;
    font-family: 'Gilroy';
    font-weight: 700;
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .profile-background {
    height: 30vw;
  }
  .avatar-wrapper {
    margin-top: 24vw;
    .avatar {
      width: 100px;
      height: 100px;
    }
    .name {
      font-size: 21px;
    }
  }
}

@media screen and (max-width: 600px) {
  .profile-background {
    height: 35vw;
  }
}

@media screen and (max-width: 420px) {
  .profile-background {
    height: 40vw;
  }
}
</style>
