<template>

<section class="section" ref="section">
<div class="border-box active">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Bed: {{ bed.bedNumber }}</h5>
<div class="info-icons">
  <div class="info-icon lg header-icon tooltip"
    v-if="isAuth('add-crop')"
    @click="() => planNewCrop()"
    data-tooltip="Plan Crop"
  >
  <!--     @mouseover="switchCropHelperTooltip(true)"
    @mouseout="switchCropHelperTooltip(false)" -->
    <img src="@/assets/images/updated/plan-crop.png" alt="">
    <!-- <div class="crop-helper-tooltip" v-if="cropHelperTooltip">Plan Crop</div>
    <span><img src="@/assets/images/plus-green.png" alt=""></span> C -->
  </div>
  <div class="info-icon lg header-icon tooltip" 
    v-if="isAuth('edit-bed')"
    @click="editCurrentBed"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
    data-tooltip="Edit Bed"
  >
    <img src="@/assets/images/updated/Edit.png" alt="">
    <!-- <img src="@/assets/images/edit-icon-2.svg" alt="">
    <div class="helper-tooltip" v-if="helperTooltip">Edit Bed</div> -->
  </div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-block">

<!-- <div class="content-row">
<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Year</p>
<h6>{{ bed.year }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Season</p>
<h6>{{ bed.season }}</h6>
</div>

</div>
</div> -->

<div class="content-row">

<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Farm</p>
<h6>{{ bed.farmName }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Bed No.</p>
<h6>{{ bed.bedNumber }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Acreage</p>
<h6>{{ bed.acreage }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Soil type</p>
<h6>{{ bed.soilType }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Irrigation Type</p>
<h6>{{ bed.irrigation }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">IOT Device Type</p>
<h6>{{ bed.IOTdeviceType }}</h6>
</div>

<!-- <div class="intro-grid-column">
<p class="title">Primary Crop</p>
<h6>{{ bed.primaryCrop }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Secondary Crop</p>
<h6>{{ bed.secondaryCrop }}</h6>
</div> -->

</div>

</div>

<FarmBedPlanCropFilled v-if="(bed.sourceType === 'bed')" />
<!-- && (crop.sourceId === bed.id) -->
<!-- <FarmPlanCropList v-if="crops.length > 0" /> -->

</div>

</div>
</div>
</section>

</template>

<script>
import { inject, ref, watch, onMounted, computed } from 'vue';
import FarmBedPlanCropFilled from './FarmBedPlanCropFilled.vue';
// import FarmPlanCropList from './FarmPlanCropList.vue';
import store from '@/store';
// import { useStore } from 'vuex';

export default {
    name: "FarmBedProfileFilled",
    setup() {
        const { bed, editionMode } = inject('bedEdition');
        const { isAuth } = inject('mainUI');
        const { crop, selectCrop, defaultCrop, newCropBedEdition } = inject('cropEdition');
        const { initialModuleOpening } = inject('farmEdition');
        const sectionHeader = 'Bed Profile';
        const helperTooltip = ref(false);
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const editCurrentBed = () => {
            editionMode.value = 'edit';
        };
        const toggleSection = () => {
            sectionExpanded.value = !sectionExpanded.value;
        };

        const crops = computed(() => store.state.crops.filter(crp => crp.sourceId === bed.id));

        const planNewCrop = () => {
          editionMode.value = 'create';
          newCropBedEdition.value = true;
          const cropToEdit = {
            ...defaultCrop,
            sourceType: 'bed',
            sourceId: crop.sourceId,
            cropCycle: crop.cropCycle || bed.cropCycle,
            season: crop.season || bed.season,
            year: crop.year || bed.year
          }
          selectCrop({
            ...cropToEdit
            // sourceId: bed.id
          })
        };

        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });

        watch(crop, () => {
          section.value.style.height = 'auto';
          setTimeout(() => {
            sectionHeight.value = section.value.offsetHeight;
          })
        });

        watch(crop, () => {
          section.value.style.height = 'auto';
          setTimeout(() => {
            sectionHeight.value = section.value.offsetHeight;
          })
        });

        watch(() => (bed.id), () => {
          section.value.style.height = 'auto';
          setTimeout(() => {
            sectionHeight.value = section.value.offsetHeight;
          })
        });

        onMounted(() => {
          console.log(crop, 'crop');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });

            if (!initialModuleOpening.value) {
              setTimeout(() => {
                document.getElementsByTagName('main')[0].scroll({
                  top: section.value.offsetTop + 10,
                  left: 0,
                  behavior: 'smooth'
                })
              }, 100)
            }
        });
        return {
            sectionHeader,
            bed,
            editCurrentBed,
            helperTooltip,
            switchHelperTooltip,
            isAuth,
            section,
            boxHeader,
            toggleSection,
            crop,
            planNewCrop,
            crops
        };
    },
    components: { 
      FarmBedPlanCropFilled, 
      // FarmPlanCropList 
    }
};
</script>

<style lang="scss" scoped>

  .section {
    .info-icon {
      &.header-icon {
        position: relative;
      }
    }
    .helper-tooltip {
      width: max-content;
      padding: 0 15px;
      height: 30px;
      position: absolute;
      z-index: 999;

      background-color: #3C3838;
      color: #fff;
      top: 40px;
      left: -30px;
      text-indent: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 11px;
      font-family: 'Inter', sans-serif;
      font-weight: 400;

      &::after {
        content: " ";
        position: absolute;
        display: block;
        top: -7px;
        border-color: #3C3838;
        border-left: 8px solid hsl(0, 3%, 23%);
        border-bottom: 6px solid #3C3838;
        border-right: 6px solid transparent;
        border-top: 8px solid transparent;
        -webkit-transform: translateX(-50%) rotate(135deg);
        transform: translateX(-50%) rotate(135deg);
        box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
        left: 41px;
        background: #3C3838;
        z-index: -1;
      }
    }
  }
  .farm-profile-filled {
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
    .header {
      font-size: 18px;
      color: #28292B;
      margin-left: 8px;
    }
    .filled-bed-section {
      padding: 24px;
      padding-top: 0;
    }
    .edit-bed {
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 25px;
      height: 25px;
      background-image: url('@/assets/profile/edit_button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      position: relative;
      .helper-tooltip {
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;

        background-color: #3C3838;
        color: #fff;
        top: 40px;
        left: -30px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 41px;
          background: #3C3838;
          z-index: -1;
        }
      }
    }
    .row {
      justify-content: space-between;
      height: 70px;
      // margin-left: 8px;
      &.top-section-row {
        .field {
          margin-top: 16px;
        }
      }
      .field {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 165px;
        margin-top: 20px;
        .label {
          color: rgba(40,41,43,0.6);
          font-size: 16px;
          font-weight: 600;
        }
        .field-value {
          color: rgba(40,41,43,1);
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
</style>