<template>
  <section class="section" ref="section">
  <div class="border-box active">
<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Bed List</h5>
<!-- <div class="info-icons">
<div class="info-icon lg header-icon"
  v-if="isAuth('add-crop')"
  @click="() => planNewCrop()"
  @mouseover="switchCropHelperTooltip(true)"
  @mouseout="switchCropHelperTooltip(false)"
>
  <div class="crop-helper-tooltip" v-if="cropHelperTooltip">Plan Crop</div>
  <span><img src="@/assets/images/plus-green.png" alt=""></span> C
</div>
<div class="info-icon lg header-icon"
  v-if="isAuth('add-bed')"
  @click="addNewBed"
  @mouseover="switchBedHelperTooltip(true)"
  @mouseout="switchBedHelperTooltip(false)"
>
  <div class="bed-helper-tooltip" v-if="bedHelperTooltip">Add Bed</div>
  <span><img src="@/assets/images/plus-green.png" alt=""></span> B
</div>
</div> -->
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row">
<div class="table-wrapper">
<table>

<thead>
  <tr>
<th><p class="form-label-static form-sort-label">
  <span>Bed</span>
  <span @click="() => sortTable('bed')">
    <button :class="{active: sortOrder.bed === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.bed === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Year</span>
  <span @click="() => sortTable('year')">
    <button :class="{active: sortOrder.year === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.year === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Season</span>
  <span @click="() => sortTable('season')">
    <button :class="{active: sortOrder.season === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.season === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Cycle</span>
  <span @click="() => sortTableByNumber('cycle')">
    <button :class="{active: sortOrder.cycle === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.cycle === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Acreage</span>
  <span @click="() => sortTableByNumber('acreage')">
    <button :class="{active: sortOrder.acreage === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.acreage === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Crop</span>
  <span @click="() => sortTable('crop')">
    <button :class="{active: sortOrder.crop === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.crop === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

  <template
    v-for="(row, rowIndex) in rows"
    v-bind:key="'farm-bed-list-row' + row.id"
  >
    <tr 
      v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
      :class="{'light-gray-bg': (bed.id === row.id)}"
      @click="(ev) => selectRow(ev, {...row}, 'represent')"
    >
    <td class="text-center">
      {{(farm.beds[rowIndex - 1]?.sourceId === row.sourceId) ? '' : row.bedNumber}}
    </td>
    <td>{{row.year}}</td>
    <td>{{ row.season }}</td>
    <td>{{ row.cropCycle }}</td>
    <td>{{row.acreage}}</td>
    <td>{{row.crop}}</td>
    <td class="w-75">
    <div class="table-links">
      <!-- <a href="javascript:void();" @click="selectBed({...row, farmName: farm.farmName}, 'edit')"><img src="@/assets/images/edit-icon-2.svg" alt=""></a> -->
      <!-- <a href="javascript:void();" @click="removeSelectedBed(farm.id, row.id, rowIndex)"><img src="@/assets/images/trash-2.svg" alt=""></a> -->
    </div>
    </td>
    </tr>
  </template>

</tbody>

</table>
</div>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'farmHistoryBedListDetails'"
    :optionKey="'farm-history-bed-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>

</div>
</section>

</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { inject, ref, watch, onMounted, reactive, provide } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "FarmBedList",
    setup() {
        const store = useStore();
        // const farms = reactive([...store.state.farms]);
        const sectionHeader = 'Bed List';
        const { bed, selectBed, /* cancelBed */ } = inject('bedEdition');
        const { plan } = inject('planDetails');
        // const { selectedFarmId } = inject('formMode');
        // const { selectedFarm } = inject('farmEdition');
        const expanded = ref(true);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        // const farm = reactive({
        //   ...selectedFarm.farmName ? selectedFarm : farms.find(farm => farm.id === selectedFarmId)
        // })
        const rows = ref([]);
        const farm = ref({});
        const currentFarmBeds = ref([]);
        // const removeSelectedBed = (farmId, bedId, index) => {
        //   store.dispatch('removeBed', {farmId, bedId, bedIndex: index})
        //   cancelBed();
        // }
        const sortOrder = reactive({
            year: 0,
            season: 0,
            bedNumber: 0,
            acreage: 0,
            primaryCrop: 0,
            secondaryCrop: 0
        });
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...currentFarmBeds.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByNumber = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...currentFarmBeds.value];
                sortOrder[sortProp] = 0;
            }
        };
        const cropHelperTooltip = ref(false);
        const switchCropHelperTooltip = (toggle) => {
            cropHelperTooltip.value = toggle;
        };
        const bedHelperTooltip = ref(false);
        const switchBedHelperTooltip = (toggle) => {
            bedHelperTooltip.value = toggle;
        };
        const handleCollapseHeaderActions = (evt) => {
            if ((evt.target.className === 'add-bed') || (evt.target.className === 'add-crop')) {
                expanded.value = true;
            }
        };
        const findBeds = () => {
            // const currentFarm = newVal.find(farm => farm.id === plan.farm.id)
            // if (farm.value?.beds) {
            //   rows.value = currentFarm?.beds;
            //   currentFarmBeds.value = currentFarm?.beds;
            // }
            rows.value = farm.value?.beds;
            currentFarmBeds.value = farm.value?.beds;
        };
        const toggleSection = () => {
            sectionExpanded.value = !sectionExpanded.value;
        };
        const selectRow = (ev, bedData, formState) => {
            if (ev.target.className === 'row-button') {
                return;
            }
            selectBed(bedData, formState);
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(() => (bed.farmName), () => {
            // const currentFarm = farms.find(frm => frm.farmName === newVal)
            // for (let prop in farm) {
            //   farm[prop] = currentFarm[prop]
            // }
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        // watch(selectedFarmId, (newVal) => {
        //   const farmToBeSelected = farms.find(farm => farm.id === newVal);
        //   for (let prop in farm) {
        //     farm[prop] = farmToBeSelected[prop]
        //   }
        //   section.value.style.height = 'auto';
        //   setTimeout(() => {
        //     sectionHeight.value = section.value.offsetHeight;
        //   })
        // })
        watch(plan.farm, (newVal) => {
            for (let prop in farm) {
                farm.value[prop] = newVal[prop];
            }
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(store.state.archiveFarmPlans, (newVal) => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            findBeds(newVal);
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(() => (plan.id), (newVal) => {
          if (newVal) {
            rows.value = plan.farm.beds;
            farm.value = plan.farm;
            if (rows.value.length > 0) {
              selectBed(rows.value[0], 'represent');
            }
          }
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
            farm.value = plan.farm;
            rows.value = plan.farm.beds;
            if (rows.value.length > 0) {
              selectBed(rows.value[0], 'represent');
            }
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            findBeds(store.state.archiveFarmPlans);
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('farmHistoryBedListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            sectionHeader,
            handleCollapseHeaderActions,
            farm,
            bed,
            rows,
            expanded,
            selectBed,
            // removeSelectedBed,
            cropHelperTooltip,
            switchCropHelperTooltip,
            bedHelperTooltip,
            switchBedHelperTooltip,
            maxPage,
            currentPage,
            section,
            boxHeader,
            toggleSection,
            currentFarmBeds,
            sortOrder,
            sortTable,
            sortTableByNumber,
            selectRow
        };
    },
    components: { DropDownList }
};
</script>

<style lang="scss" scoped>
.section {
  .info-icon {
    &.header-icon {
      position: relative;
    }
  }
  .crop-helper-tooltip {
    width: max-content;
    padding: 0 15px;
    height: 30px;
    position: absolute;
    z-index: 999;

    background-color: #3C3838;
    color: #fff;
    top: 40px;
    left: -30px;
    text-indent: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: -7px;
      border-color: #3C3838;
      border-left: 8px solid hsl(0, 3%, 23%);
      border-bottom: 6px solid #3C3838;
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 41px;
      background: #3C3838;
      z-index: -1;
    }
  }

  .bed-helper-tooltip {
    width: max-content;
    padding: 0 15px;
    height: 30px;
    position: absolute;
    z-index: 999;

    background-color: #3C3838;
    color: #fff;
    top: 40px;
    left: -30px;
    text-indent: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: -7px;
      border-color: #3C3838;
      border-left: 8px solid hsl(0, 3%, 23%);
      border-bottom: 6px solid #3C3838;
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 41px;
      background: #3C3838;
      z-index: -1;
    }
  }
}
.farm-bed-list {
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
  
  .header {
    font-size: 18px;
    color: #28292B;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
  }
  .farm-bed-list-card-section {
    padding: 16px 0 24px;
  }
  .header-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    .add-crop {
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 25px;
      height: 25px;
      background-image: url('@/assets/farm_profile/add_crop_button.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      position: relative;
      margin-right: 10px;
      .crop-helper-tooltip {
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;

        background-color: #3C3838;
        color: #fff;
        top: 40px;
        left: -30px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 41px;
          background: #3C3838;
          z-index: -1;
        }
      }
    }

    .add-bed {
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 25px;
      height: 25px;
      background-image: url('@/assets/farm_profile/add_bed.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      position: relative;
      .bed-helper-tooltip {
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;

        background-color: #3C3838;
        color: #fff;
        top: 40px;
        left: -30px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 41px;
          background: #3C3838;
          z-index: -1;
        }
      }
    }
  }

  .custom-table-header {
    font-weight: 600;
    font-size: 14px;
    color: rgba(40, 41, 43, 0.6);
  }
  .custom-table-cell {
    font-weight: 500;
    font-size: 14px;
    color: rgba(40, 41, 43, 1);
  }

  .item-actions {
    border: none;
    background: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    i {
      width: 100%;
      height: 100%;
    }
  }
}
</style>