<template>

  <section class="section" ref="section">
  <div class="border-box active">
  
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5>Bed: {{ bed.bedNumber }}</h5>
  <div class="info-icons">
  
    <!-- <div class="info-icon lg header-icon" 
      @mouseover="switchHelperTooltip(true)"
      @mouseout="switchHelperTooltip(false)"
    >
      <img src="@/assets/images/edit-icon-2.svg" alt="">
      <div class="helper-tooltip" v-if="helperTooltip">Edit Bed</div>
    </div> -->
  </div>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>
  
  <div class="border-box-content">
  
  <div class="content-block">
  
  <div class="content-row">
  <div class="intro-grid">
  
  <div class="intro-grid-column">
  <p class="title">Year</p>
  <h6>{{ bed.year }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Season</p>
  <h6>{{ bed.season }}</h6>
  </div>
  
  </div>
  </div>
  
  <div class="content-row">
  
  <div class="intro-grid">
  
  <div class="intro-grid-column">
  <p class="title">Farm</p>
  <h6>{{ bed.farmName }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Bed No.</p>
  <h6>{{ bed.bedNumber }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Acreage</p>
  <h6>{{ bed.acreage }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Soil type</p>
  <h6>{{ bed.soilType }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Irrigation</p>
  <h6>{{ bed.irrigation }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">IOT Device Type</p>
  <h6>{{ bed.IOTdeviceType }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Primary Crop</p>
  <h6>{{ bed.primaryCrop }}</h6>
  </div>
  
  <div class="intro-grid-column">
  <p class="title">Secondary Crop</p>
  <h6>{{ bed.secondaryCrop }}</h6>
  </div>
  
  </div>
  
  </div>
  
  <template v-if="(bed.sourceType === 'bed')">
    <!-- (crop.sourceType === 'bed') && (crop.sourceId === bed.id) -->
    <div class="content-block section-block" ref="sectionBox">
    
<div class="content-header border-box-header" ref="cropBoxHeader" @click="toggleCropSection">
<p class="fw-semibold">Plan Crop</p>
<div class="icon-group">
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle" :class="{revert: !cropSectionExpanded}">
</div>

  <div class="content-section border-box-content p-0">

    <div class="intro-grid">

      <div class="intro-grid-column">
      <p class="title">Year</p>
      <h6>{{ crop.year }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Season</p>
      <h6>{{ crop.season }}</h6>
      </div>

      <div class="intro-grid-column">
      </div>

      <div class="intro-grid-column">
      </div>

      <div class="intro-grid-column">
      <p class="title">Crop Cycle</p>
      <h6>{{ crop.cropCycle }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Crop Category</p>
      <h6>{{ crop.cropCategory }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Crop</p>
      <h6>{{ crop.crop }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Variety</p>
      <h6>{{ crop.variety }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Date Seed</p>
      <h6>{{ crop.dateSeed }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Harvest Date</p>
      <h6>{{ crop.harvestDate }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Proj. Harvest Amt (lbs)</p>
      <h6>{{ crop.projHarvestAmount }}</h6>
      </div>

      <div class="intro-grid-column">
      <p class="title">Proj. Wastage (lbs)</p>
      <h6>{{ crop.projWastage }}</h6>
      </div>

    </div>

  </div>
  </div>
  </template>


  </div>
  
  </div>
  </div>
  </section>

  </template>
  
  <script>
  import { inject, ref, watch, onMounted } from 'vue';
  // import FarmPlanCropFilled from './FarmPlanCropFilled.vue';
  // import { useStore } from 'vuex';
  
  export default {
      name: "FarmHistoryBedProfile",
      setup() {
        const { plan } = inject('planDetails');
        const { bed, selectBed, crop } = inject('bedEdition');

        // const bed = ref({});

          
          const helperTooltip = ref(false);

          const section = ref(null);
          const boxHeader = ref(null);
          const sectionHeight = ref(0);
          const sectionExpanded = ref(true);


          const sectionBox = ref(null);
          const cropBoxHeader = ref(null);
          const cropSectionHeight = ref(0);
          const cropSectionExpanded = ref(true);

          const toggleCropSection = (ev) => {
            if ((ev.target.className !== 'content-header border-box-header') && 
              (ev.target.className !== 'angle') && (ev.target.className !== 'angle revert')) {
              return;
            }
            cropSectionExpanded.value = !cropSectionExpanded.value
          }

          watch(cropSectionExpanded, (newVal) => {
            sectionBox.value.style.height = newVal ? 
              `${cropSectionHeight.value}px` : 
                `${cropBoxHeader.value.offsetHeight}px`
            sectionBox.value.style.overflow = 'hidden';// newVal ? 'visible' : 'hidden'

            section.value.style.height = 'auto';

            setTimeout(() => {
              sectionHeight.value = section.value.offsetHeight;
              section.value.style.height = `${section.value.offsetHeight}px`;
            }, 300);
          });

          const switchHelperTooltip = (toggle) => {
              helperTooltip.value = toggle;
          };
          const toggleSection = () => {
              sectionExpanded.value = !sectionExpanded.value;
          };
          watch(sectionExpanded, (newVal) => {
              section.value.style.height = newVal ?
                  `${sectionHeight.value}px` :
                  `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
          });

          watch(plan, () => {
            // bed.value = plan.farm.beds[0] || {};
            if (Object.entries(plan.crop).length > 0) {
              crop.value = plan.crop;
            } else {
              for (let prop in crop.value) {
                crop.value[prop] = bed[prop];
              }
            }
          });

          onMounted(() => {
 

            // if (plan.farm.beds) {
            //   bed.value = plan.farm.beds[0]
            // } else {
            //   bed.value = {};
            // }
            if (plan.farm.beds) {
              selectBed({...plan.farm.beds[0]})
            }

            if (Object.entries(plan.crop).length > 0) {
              crop.value = plan.crop;
            } else {
              for (let prop in crop.value) {
                crop.value[prop] = bed[prop];
              }
            }

            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });

            setTimeout(() => {
              cropSectionHeight.value = sectionBox.value.offsetHeight;
              sectionBox.value.style.height = `${sectionBox.value.offsetHeight}px`;
            });

          });
          return {
              bed,
              crop,
              helperTooltip,
              switchHelperTooltip,
              section,
              boxHeader,
              toggleSection,

              toggleCropSection,
              cropSectionExpanded,
              sectionBox,
              cropBoxHeader
          };
      },
      // components: { FarmPlanCropFilled }
  };
  </script>
  
  <style lang="scss" scoped>
  
    .section {
      .info-icon {
        &.header-icon {
          position: relative;
        }
      }
      .helper-tooltip {
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;
  
        background-color: #3C3838;
        color: #fff;
        top: 40px;
        left: -30px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
  
        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 41px;
          background: #3C3838;
          z-index: -1;
        }
      }
    }
    .farm-profile-filled {
      box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
      .header {
        font-size: 18px;
        color: #28292B;
        margin-left: 8px;
      }
      .filled-bed-section {
        padding: 24px;
        padding-top: 0;
      }
      .edit-bed {
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: auto;
        width: 25px;
        height: 25px;
        background-image: url('@/assets/profile/edit_button.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        position: relative;
        .helper-tooltip {
          width: max-content;
          padding: 0 15px;
          height: 30px;
          position: absolute;
          z-index: 999;
  
          background-color: #3C3838;
          color: #fff;
          top: 40px;
          left: -30px;
          text-indent: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 11px;
          font-family: 'Inter', sans-serif;
          font-weight: 400;
  
          &::after {
            content: " ";
            position: absolute;
            display: block;
            top: -7px;
            border-color: #3C3838;
            border-left: 8px solid hsl(0, 3%, 23%);
            border-bottom: 6px solid #3C3838;
            border-right: 6px solid transparent;
            border-top: 8px solid transparent;
            -webkit-transform: translateX(-50%) rotate(135deg);
            transform: translateX(-50%) rotate(135deg);
            box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
            left: 41px;
            background: #3C3838;
            z-index: -1;
          }
        }
      }
      .row {
        justify-content: space-between;
        height: 70px;
        // margin-left: 8px;
        &.top-section-row {
          .field {
            margin-top: 16px;
          }
        }
        .field {
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 165px;
          margin-top: 20px;
          .label {
            color: rgba(40,41,43,0.6);
            font-size: 16px;
            font-weight: 600;
          }
          .field-value {
            color: rgba(40,41,43,1);
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  </style>