<template>
  <SalesHeader />

  <div ref="customerForm"></div>
  <CustomersForm v-if="((editionMode === 'edit') || (editionMode === 'create')) && (isAuth('add-new-customer') || isAuth('edit-customer'))" />
  <!--  && (isAuth('edit-sales') || isAuth('add-sales')) -->
  <CustomersFilled  v-if="editionMode === 'represent'" />

  <div ref="salesForm"></div>
  <SalesForm :key="`${customer.id}-${sales.id}-sales-form`" v-if="((salesEditionMode === 'create') || (salesEditionMode === 'edit')) && (isAuth('add-new-sales') || isAuth('edit-sales'))" />
  <SalesFilled :key="`${customer.id}-${sales.id}-sales-filled`" v-if="(salesEditionMode === 'represent') && (salesList.length > 0)" />
  <SalesList :key="`${customer.id}-sales-list`" v-if="salesList.length > 0" :parentModule="'customer'" />

  <CustomersList />
</template>

<script>
import { defineComponent, ref, reactive, provide, computed, onMounted, inject } from 'vue';
import { useStore } from "vuex";
import CustomersForm from './CustomersForm.vue';
import CustomersFilled from './CustomersFilled.vue';
import CustomersList from './CustomersList.vue';
import SalesHeader from './SalesHeader.vue';
import SalesForm from './SalesForm.vue';
import SalesFilled from './SalesFilled.vue';
import SalesList from './SalesList.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: "CustomersModule",
    setup() {
      const store = useStore();

      const router = useRouter();

      const { isAuth } = inject('mainUI');

      const editionMode = ref('');

      const salesEditionMode = ref('');

      const salesForm = ref(null);

      const customerForm = ref(null);

      const customer = reactive({
        id: '',
        type: '',
        customer: '',
        contact: '',
        workPhone: '',
        mobile: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        comments: ''
      });

      const sales = reactive({
        id: '',
        date: '',
        farm: '',
        bed: '',
        cropCategory: '',
        crop: '',
        season: '',
        cropCycle: '',
        variety: '',
        // customerType: '',
        customer: '',
        quantitySold: '',
        rate: '',
        total: '',
        comments: ''
      });

      const selectedCustomer = reactive({
        ...customer
      });

      const defaultCustomer = {
        ...customer
      }

      const selectedSales = reactive({
        ...sales
      });

      const defaultSales = {
        ...sales
      }

      const salesList = computed(() => [...store.state.sales].filter(stateSalesItem => stateSalesItem.customerId === customer.id));

      const bedsOptions = ref([]);

      const addCustomer = () => {
        selectCustomer({...defaultCustomer}, 'create');
        // editionMode.value = 'create';
      }

      const editCustomer = (field, val) => {
        customer[field] = val;
      }

      const selectCustomer = (customerData, editMode) => {
        if (customerData) {
          for (let field in customerData) {
            customer[field] = customerData[field]
            selectedCustomer[field] = customerData[field]
          }
        }
        editionMode.value = editMode;

        if ((editMode === 'create') || (editMode === 'edit')) {
          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: customerForm?.value?.offsetTop - 100,
              left: 0,
              behavior: 'smooth'
            })
          }) 
        }
      }

      const removeCustomer = (customerId) => {
        store.dispatch('removeCustomer', customerId)
      };

      const addSales = () => {
        selectSales({...defaultSales}, 'create');
        // editionMode.value = 'create';
      }

      const editSales = (field, val) => {
        sales[field] = val;
      }

      const selectSales = (salesData, editMode) => {
        if (salesData) {
          for (let field in salesData) {
            sales[field] = salesData[field]
            selectedSales[field] = salesData[field]
          }
        }
        
        salesEditionMode.value = editMode;

        if ((editMode === 'create') || (editMode === 'edit')) {
          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: salesForm?.value?.offsetTop - 100,
              left: 0,
              behavior: 'smooth'
            })
          }) 
        }
      }

      const removeSales = (salesId) => {
        store.dispatch('removeSales', salesId)
      };

      provide('customerDetails', {
        customer,
        defaultCustomer,
        selectedCustomer,
        editionMode,
        addCustomer,
        editCustomer,
        selectCustomer,
        removeCustomer
      })

      provide('salesDetails', {
        sales,
        defaultSales,
        selectedSales,
        salesEditionMode,
        addSales,
        editSales,
        selectSales,
        removeSales,
        options: bedsOptions
      })

      onMounted(() => {
        if (router.currentRoute.value.params.source === 'sales-module') {
          editionMode.value = 'create';
          salesEditionMode.value = 'create';
        }
        if (router.currentRoute.value.params.source === 'admin-dashboard') {
          editionMode.value = 'create';
          salesEditionMode.value = 'create';
        }
        store.dispatch('getFarms');
        store.dispatch('getCrops');
      })

      return {
        editionMode,
        salesEditionMode,
        isAuth,
        customer,
        sales,
        salesList,
        customerForm,
        salesForm
      }
    },
    components: { CustomersForm, CustomersFilled, CustomersList, SalesHeader, SalesForm, SalesFilled, SalesList }
})
</script>

<style lang="scss" scoped></style>