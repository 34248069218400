<template>
  <section class="section" ref="section">
  <div class="border-box active" v-if="rows.length > 0">
<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Bed List</h5>
<div class="info-icons">
<!-- <div class="info-icon lg header-icon tooltip"
  v-if="isAuth('add-bed')"
  @click="addNewBed"
  @mouseover="switchBedHelperTooltip(true)"
  @mouseout="switchBedHelperTooltip(false)"
  data-tooltip="Add Bed"
>
  <img src="@/assets/images/updated/add-bed.png" alt="">
</div> -->

<div class="info-icon lg header-icon tooltip"
  v-if="isAuth('add-crop')"
  @click="() => planNewCrop()"
  data-tooltip="Plan Crop"
>
  <img src="@/assets/images/updated/plan-crop.png" alt="">
</div>

</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<!-- <div class="content-row">
<div class="table-wrapper"> -->
<div class="content-row overflow-x-scroll table-container">
<table>

<thead>

<tr>
<th><p class="form-label-static form-sort-label">
  <span>Bed</span>
  <span @click="() => sortTable('bed')">
    <button :class="{active: sortOrder.bed === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.bed === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Year</span>
  <span @click="() => sortTable('year')">
    <button :class="{active: sortOrder.year === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.year === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Season</span>
  <span @click="() => sortTable('season')">
    <button :class="{active: sortOrder.season === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.season === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Cycle</span>
  <span @click="() => sortTableByNumber('cycle')">
    <button :class="{active: sortOrder.cycle === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.cycle === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Acreage</span>
  <span @click="() => sortTableByNumber('acreage')">
    <button :class="{active: sortOrder.acreage === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.acreage === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Crop</span>
  <span @click="() => sortTable('crop')">
    <button :class="{active: sortOrder.crop === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.crop === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

  <template
    v-for="(row, rowIndex) in rows"
    v-bind:key="'farm-bed-list-row' + row.id"
  >
  <!-- :style="{backgroundColor: (bed.bedNumber === row.bedNumber) ? 'rgba(0,0,0,0.05)' : ''}" -->
  <!-- class="light-gray-bg" -->
    <tr 
      v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
      :class="{'light-gray-bg': (bed.id === row.id)}"
      @click="(ev) => selectRow(ev, {...row, farmName: farm.farmName}, 'represent')"
    >
    <td class="text-center">
      <!-- <a href="javascript:void();" 
        
        class="underline"
      > -->
      <!-- @click="selectBed({...row, farmName: farm.farmName}, 'represent')" -->
        {{(farm.beds[rowIndex - 1]?.sourceId === row.sourceId) ? '' : row.bedNumber}}
      <!-- </a> -->
    </td>
    <td>{{row.year}}</td>
    <td>{{ row.season }}</td>
    <td>{{ row.cropCycle }}</td>
    <td>{{row.acreage}}</td>
    <td>{{row.crop}}</td>
    <td class="w-75">
    <div class="table-links">
      <a v-if="isAuth('edit-bed')" class="row-button" href="javascript:void();" @click="selectBed({...row, farmName: farm.farmName}, 'edit')"><img class="row-button" src="@/assets/images/edit-icon-2.svg" alt=""></a>
      <a v-if="isAuth('remove-bed')" class="row-button" href="javascript:void();" @click="removeSelectedBed(farm.id, row.id, rowIndex)"><img class="row-button" src="@/assets/images/trash-2.svg" alt=""></a>
    </div>
    </td>
    </tr>
  </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'bedListDetails'"
    :optionKey="'bed-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>

<div class="removal-popup" v-if="removedBed">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">Are you sure you want to delete Plan Crop?</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveRemoval(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>

</div>
</section>

</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { inject, reactive, ref, watch, onMounted, provide } from 'vue';
// import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: "FarmBedList",
    setup() {
        const store = useStore();
        // const router = useRouter();
        const farms = reactive([...store.state.farms]);
        const sectionHeader = 'Bed List';
        const { bed, selectBed, cancelBed, editionMode, /* defaultBed, */ bedsList } = inject('bedEdition');
        const { selectedFarmId } = inject('formMode');
        const { selectedFarm } = inject('farmEdition');
        const { defaultCrop, selectCrop, crop, newCropBedEdition } = inject('cropEdition');
        const { isAuth } = inject('mainUI');
        const expanded = ref(true);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const rows = ref([]);
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const sortOrder = reactive({
            bed: 0,
            year: 0,
            season: 0,
            cycle: 0,
            acreage: 0,
            crop: 0
        });
        const farm = reactive({
            ...selectedFarm.farmName ? selectedFarm : farms.find(farm => farm.id === selectedFarmId)
        });
        const removedBed = ref(null);
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...farm.beds];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByNumber = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...farm.beds];
                sortOrder[sortProp] = 0;
            }
        };
        const removeSelectedBed = (farmId, bedId, index) => {
            removedBed.value = { farmId, bedId, bedIndex: index };
        };
        const approveRemoval = (approved) => {
            if (approved) {
                store.dispatch('removeBed', removedBed.value);
                cancelBed();
            }
            removedBed.value = null;
        };
        // const addNewBed = () => {
        const planNewCrop = () => {
            // selectBed({
            //   ...defaultBed,
            //   farmName: selectedFarm.farmName || farm.farmName,
            //   sourceType: 'bed',
            //   id: ''
            // }, 'create');
            editionMode.value = 'create';
            newCropBedEdition.value = true;
            const cropToEdit = {
                ...defaultCrop,
                sourceType: 'bed',
                sourceId: crop.sourceId,
                cropCycle: crop.cropCycle || bed.cropCycle,
                season: crop.season || bed.season,
                year: crop.year || bed.year
            };
            selectCrop({
                // ...defaultCrop,
                // sourceType: 'bed',
                // sourceId: crop.sourceId
                ...cropToEdit
            });
            // editionMode.value = 'create';
        };
        // const planNewCrop = () => {
        //   router.push({ 
        //     name: 'farm', 
        //     params: { 
        //       pagename: 'plan-crop', 
        //       farm: selectedFarm.farmName ? selectedFarm.id : selectedFarmId
        //     } 
        //   })
        // }
        const cropHelperTooltip = ref(false);
        const switchCropHelperTooltip = (toggle) => {
            cropHelperTooltip.value = toggle;
        };
        const bedHelperTooltip = ref(false);
        const switchBedHelperTooltip = (toggle) => {
            bedHelperTooltip.value = toggle;
        };
        const handleCollapseHeaderActions = (evt) => {
            if ((evt.target.className === 'add-bed') || (evt.target.className === 'add-crop')) {
                expanded.value = true;
            }
        };
        const findBeds = (newVal) => {
            const currentFarm = newVal.find(farm => farm.id === selectedFarm.id);
            if (farm?.beds) {
                farm.beds = currentFarm?.beds;
                rows.value = currentFarm?.beds;
            }
            if (bed.id) {
                const currentBed = rows.value.find(row => row.id === bed.id);
                if (currentBed) {
                    selectBed({ ...currentBed, farmName: farm.farmName }, 'represent');
                }
            }
        };
        const toggleSection = (ev) => {
            if (ev.target.tagName !== 'IMG') {
                sectionExpanded.value = !sectionExpanded.value;
            }
        };
        const selectRow = (ev, bedData, formState) => {
            if (ev.target.className === 'row-button') {
                return;
            }
            selectBed(bedData, formState);
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(() => (bed.farmName), (newVal) => {
            const currentFarm = farms.find(frm => frm.farmName === newVal);
            if (currentFarm) {
                for (let prop in farm) {
                    farm[prop] = currentFarm[prop];
                }
            }
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
        });
        watch(selectedFarmId, (newVal) => {
            const farmToBeSelected = farms.find(farm => farm.id === newVal);
            for (let prop in farm) {
                farm[prop] = farmToBeSelected[prop];
            }
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
        });
        watch(selectedFarm, (newVal) => {
            for (let prop in farm) {
                farm[prop] = newVal[prop];
            }
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(store.state.farms, (newVal) => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            findBeds(newVal);
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(bedsList, (newVal) => {
            if (newVal) {
                findBeds(store.state.farms);
            }
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(editionMode, (newVal) => {
            if (newVal === 'represent') {
                if (bed.id) {
                    selectBed({ ...rows.value.find(row => row.id === bed.id) }, 'represent');
                }
            }
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(() => ({...selectedFarm}), (newVal, oldVal) => {
          if (newVal.beds.length !== oldVal.beds.length) {
            console.log(newVal, 'bedsList was changed in spread')
            selectCrop({...newVal.beds[newVal.beds.length - 1]}, 'represent');
          }
        })
        onMounted(() => {
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            findBeds(store.state.farms);
            if (rows.value.length > 0) {
                // (editionMode.value === 'represent') && selectBed({...rows.value[0], farmName: farm.farmName}, 'represent');
                selectBed({ ...rows.value[0], farmName: farm.farmName }, 'represent');
            }
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('bedListDetails', {
            maxPage,
            currentPage,
            options
        });
        return {
            sectionHeader,
            handleCollapseHeaderActions,
            farm,
            bed,
            expanded,
            selectBed,
            removeSelectedBed,
            cropHelperTooltip,
            switchCropHelperTooltip,
            bedHelperTooltip,
            switchBedHelperTooltip,
            // addNewBed,
            planNewCrop,
            isAuth,
            maxPage,
            currentPage,
            section,
            boxHeader,
            toggleSection,
            rows,
            sortOrder,
            sortTable,
            sortTableByNumber,
            approveRemoval,
            removedBed,
            editionMode,
            selectRow
        };
    },
    components: { DropDownList }
};
</script>

<style lang="scss" scoped>
.section {
  .info-icon {
    &.header-icon {
      position: relative;
    }
  }
  .crop-helper-tooltip {
    width: max-content;
    padding: 0 15px;
    height: 30px;
    position: absolute;
    z-index: 999;

    background-color: #3C3838;
    color: #fff;
    top: 40px;
    left: -30px;
    text-indent: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: -7px;
      border-color: #3C3838;
      border-left: 8px solid hsl(0, 3%, 23%);
      border-bottom: 6px solid #3C3838;
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 41px;
      background: #3C3838;
      z-index: -1;
    }
  }

  .bed-helper-tooltip {
    width: max-content;
    padding: 0 15px;
    height: 30px;
    position: absolute;
    z-index: 999;

    background-color: #3C3838;
    color: #fff;
    top: 40px;
    left: -30px;
    text-indent: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: -7px;
      border-color: #3C3838;
      border-left: 8px solid hsl(0, 3%, 23%);
      border-bottom: 6px solid #3C3838;
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 41px;
      background: #3C3838;
      z-index: -1;
    }
  }
}
.farm-bed-list {
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
  
  .header {
    font-size: 18px;
    color: #28292B;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
  }
  .farm-bed-list-card-section {
    padding: 16px 0 24px;
  }
  .header-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    .add-crop {
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 25px;
      height: 25px;
      background-image: url('@/assets/farm_profile/add_crop_button.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      position: relative;
      margin-right: 10px;
      .crop-helper-tooltip {
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;

        background-color: #3C3838;
        color: #fff;
        top: 40px;
        left: -30px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 41px;
          background: #3C3838;
          z-index: -1;
        }
      }
    }

    .add-bed {
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 25px;
      height: 25px;
      background-image: url('@/assets/farm_profile/add_bed.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      position: relative;
      .bed-helper-tooltip {
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;

        background-color: #3C3838;
        color: #fff;
        top: 40px;
        left: -30px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 41px;
          background: #3C3838;
          z-index: -1;
        }
      }
    }
  }

  .custom-table-header {
    font-weight: 600;
    font-size: 14px;
    color: rgba(40, 41, 43, 0.6);
  }
  .custom-table-cell {
    font-weight: 500;
    font-size: 14px;
    color: rgba(40, 41, 43, 1);
  }

  .item-actions {
    border: none;
    background: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    i {
      width: 100%;
      height: 100%;
    }
  }
}
</style>