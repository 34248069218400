<template>
  <div class="section" ref="section">
    <div class="border-box-header" ref="boxHeader" @click="toggleSection">
    <h5>User List</h5>
    <!-- <div class="info-icons">
    <div class="info-icon lg top-icon"
      @click="addNewUser"
      @mouseover="switchHelperTooltip(true)"
      @mouseout="switchHelperTooltip(false)"
    >
      <img src="@/assets/images/user-plus.svg" alt="">
      <div class="helper-tooltip" v-if="helperTooltip">Add User</div>
    </div>
    </div> -->
    <img src="@/assets/images/angle-down.png" alt="" class="angle">
    </div>

    <div class="border-box-content">

    <div class="content-row overflow-x-scroll">
    <table>

    <thead>
    <tr>
      <th><p class="form-label-static form-sort-label">
      <span>First Name</span>
      <span @click="() => sortTable('firstName')">
        <button :class="{active: sortOrder.firstName === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.firstName === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>Last Name</span>
      <span @click="() => sortTable('lastName')">
        <button :class="{active: sortOrder.lastName === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.lastName === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>Title</span>
      <span @click="() => sortTable('title')">
        <button :class="{active: sortOrder.title === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.title === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>Mobile</span>
      <span @click="() => sortTable('mobilePhone')">
        <button :class="{active: sortOrder.mobilePhone === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.mobilePhone === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>City</span>
      <span @click="() => sortTable('city')">
        <button :class="{active: sortOrder.city === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.city === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>State</span>
      <span @click="() => sortTable('state')">
        <button :class="{active: sortOrder.state === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.state === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
      <th class="w-75">&nbsp;</th>
    </tr>
    </thead>

    <tbody>

      <template
        v-for="(row, rowIndex) in rows"
        v-bind:key="'admin-user-list-row' + row.mainDetails?.id"
      >
        <tr
          v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
          :class="{'light-gray-bg': ((row.mainDetails.id === mainDetails.id) || ((mainDetails.id === 'primary') && (rowIndex === 0)))}"
          @click="(ev) => selectByRow(ev, {...row})"
        >
        <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
        <td class="text-center">{{row.mainDetails?.firstName}}</td>
        <td>{{row.mainDetails?.lastName}}</td>
        <td>{{row.mainDetails?.title}}</td>
        <td>{{row.mainDetails?.mobilePhone}}</td>
        <td>{{ row.mainDetails?.city }}</td>
        <td>{{ row.mainDetails?.state }}</td>
        <td class="w-75">
        <div class="table-links">
        <!-- <a href="javascript:void();" @click="() => {
          selectMainDetails({...row.mainDetails}, 'edit');
        }">
          <img src="@/assets/images/edit-icon-2.svg" alt="">
        </a>
        <a href="javascript:void();" @click="removeUser(row.mainDetails?.id)">
          <img src="@/assets/images/trash-2.svg" alt="">
        </a> -->
        </div>
        </td>
        </tr>
      </template>

    </tbody>

    </table>
    </div>

    <div class="content-row">
    <div class="pagination">
    <div>
    <!-- <p>Records per page:</p> -->
    <!-- <select v-model="maxPage">
    <option>1</option>
    <option>2</option>
    <option>3</option>
    <option>4</option>
    <option>5</option>
    <option>6</option>
    <option>7</option>
    <option>8</option>
    <option>9</option>
    <option>10</option>
    </select> -->
    </div>
    <!-- <p>1-1 to 10</p> -->
    <p class="list-pagination">
      Page: 
      <!-- <select v-model="currentPage" class="pagination-selector">
      <option 
        v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
        v-bind:key="'task-list-pagination-' + page"
      >{{ Number.parseInt(page) + 1 }}</option>
      </select> -->
      <DropDownList 
        :mainObject="'currentPage'"
        :mainProperty="'value'"
        :injectionName="'laborListDetails'"
        :optionKey="'labor-list-pagination'"
        :optionsList="[]"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="currentPage.value"
        :numberList="true"
      />
    </p>
    </div>
    </div>

    </div>
  </div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { 
  defineComponent, ref, onMounted, 
  watch, computed, inject, reactive, provide
} from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'LaborList',
    setup() {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const labors = computed(() => store.state.labors);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const sortOrder = reactive({
            firstName: 0,
            lastName: 0,
            title: 0,
            mobilePhone: 0,
            city: 0,
            state: 0
        });
        const { mainDetails, 
        // userRole,
        selectMainDetails,
        // removeUser,
        // addNewUser
        // selectedEquipment,  
         } = inject('mainDetails');
        const helperTooltip = ref(false);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...labors.value];
                sortOrder[sortProp] = 0;
            }
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectMainDetails({ ...row.mainDetails }, 'represent');
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(() => ([...store.state.labors]), (newVal) => {
            console.log(labors.value, 'labors');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...newVal];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
            (labors.value.length > 0) && selectMainDetails({ ...labors.value[0] }, 'represent');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getVendors')
            rows.value = [...store.state.labors];
            store.dispatch('getLabors');

            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('laborListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            // selectedEquipment,
            mainDetails,
            // userRole,
            selectMainDetails,
            // removeUser,
            // addNewUser,
            rows,
            maxPage,
            helperTooltip,
            switchHelperTooltip,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            selectByRow,
            currentPage
        };
    },
    components: { DropDownList }
})
</script>