<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Plan Input</h5>
<div class="icon-group">
<!-- <div class="icon green icon-o camera-plus"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
>
  <img src="@/assets/images/camera-plus.svg" alt="">
  <div class="helper-tooltip" v-if="helperTooltip">Take a shot</div>
</div> -->
</div>
</div>

<div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.year || (planInput.year.length > 0)}">Year</p>
<!-- <select class="form-control"
  v-model="planInput.year"
  @click="() => {
    activated.year = true;
    focused.year = true;
  }"
  @change="() => {
    focused.year = false;
  }"
>
  <option v-for="yearOption in inputYears"
    v-bind:key="yearOption + 'year-option-plan-input'"
  >{{ yearOption }}</option>
</select> -->
<DropDownList
  :mainObject="'planInput'"
  :mainProperty="'year'"
  :injectionName="'planInputDetails'"
  :optionKey="'year-option-plan-input'"
  :optionsList="inputYears"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="planInput.year"
  @click="() => {
    activated.year = true;
  }"
/>
<p v-if="activated.year && planInput.year.length === 0" class="form-info red text-end">* Required</p>

</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.season || (planInput.season.length > 0)}">Season</p>
<!-- <select class="form-control"
  v-model="planInput.season"
  @click="() => {
    activated.season = true;
    focused.season = true;
  }"
  @change="() => {
    focused.season = false;
  }"
>
  <option v-for="seasonOption in inputSeasons"
    v-bind:key="seasonOption + 'season-option-plan-input'"
  >{{ seasonOption }}</option>
</select> -->
<DropDownList
  :mainObject="'planInput'"
  :mainProperty="'season'"
  :injectionName="'planInputDetails'"
  :optionKey="'season-option-plan-input'"
  :optionsList="inputSeasons"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="planInput.season"
  @click="() => {
    activated.season = true;
  }"
/>
<p v-if="activated.season && planInput.season.length === 0" class="form-info red text-end">* Required</p>

</div>
</div>

<div class="col-3" v-if="!manualInput">
<div class="form-item">
<p class="form-label" :class="{active: focused.item || (planInput.item.length > 0)}">Item</p>
  <!-- <select class="form-control" 
    v-model="planInput.item"
    @click="() => {
      activated.item = true;
      focused.item = true;
    }"
    @change="() => {
      focused.item = false;
    }">
    <option
      v-for="prod in products"
      v-bind:key="prod.item + 'product-option-inventory-plan'"
    >{{ prod.item }}</option>
  </select> -->
  <DropDownList
    :mainObject="'planInput'"
    :mainProperty="'item'"
    :injectionName="'planInputDetailsTwo'"
    :optionKey="'product-option-plan-input'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="planInput.item"
    @click="() => {
      activated.item = true;
    }"
  />
<p v-if="activated.item && planInput.item.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.item && planInput.item.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3" v-else>
<div class="form-item">
<p class="form-label" :class="{active: focused.item || (planInput.item.length > 0)}">Item</p>

<div class="dropdown-open-back-container">
  <input type="text" class="form-control" v-model="planInput.item" ref="itemField"
    @focus="() => {
      activated.item = true;
      focused.item = true;
      inputDialog = true;
    }"
    @blur="() => {
      focused.item = false;
    }">
  <div class="dropdown-open-back" @click="() => {
    manualInput = false
  }"></div>
</div>
<p v-if="activated.item && planInput.item.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.item && planInput.item.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.brand || (planInput.brand.length > 0)}">Brand</p>
<input type="text" class="form-control"  v-model="planInput.brand"
  @focus="() => {
    activated.brand = true;
    focused.brand = true;
  }"
  @blur="() => {
    focused.brand = false;
  }">
<!-- <p v-if="activated.brand && planInput.brand.length === 0" class="form-info red text-end">* Required</p> -->
<p v-if="activated.brand && planInput.brand.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.variety || (planInput.variety.length > 0)}">Variety</p>
<input type="text" class="form-control"  v-model="planInput.variety"
  @focus="() => {
    activated.variety = true;
    focused.variety = true;
  }"
  @blur="() => {
    focused.variety = false;
  }">
<p v-if="activated.variety && planInput.variety.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: true || focused.inStockNumber || (planInput.inStockNumber.length > 0)}">In Stock # of Items</p>
<input type="text" class="form-control" v-model="planInput.inStockNumber"
  @focus="() => {
    activated.inStockNumber = true;
    focused.inStockNumber = true;
  }"
  @blur="() => {
    focused.inStockNumber = false;
  }">
<p v-if="activated.inStockNumber && planInput.inStockNumber.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.inStockNumber && `${planInput.inStockNumber}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: true || focused.inStockQuantity || (planInput.inStockQuantity.length > 0)}">In Stock Weight (lbs/item)</p>
<input type="text" class="form-control" v-model="planInput.inStockQuantity"
  @focus="() => {
    activated.inStockQuantity = true;
    focused.inStockQuantity = true;
  }"
  @blur="() => {
    focused.inStockQuantity = false;
  }">
<!-- <p v-if="activated.inStockQuantity && planInput.inStockQuantity.length === 0" class="form-info red text-end">* Required</p> -->
<p v-if="activated.inStockQuantity && `${planInput.inStockQuantity}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: true || focused.requiredNumber || (planInput.requiredNumber.length > 0)}">Required # of Items</p>
<input type="text" class="form-control" v-model="planInput.requiredNumber"
  @focus="() => {
    activated.requiredNumber = true;
    focused.requiredNumber = true;
  }"
  @blur="() => {
    focused.requiredNumber = false;
  }">
<p v-if="activated.requiredNumber && planInput.requiredNumber.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.requiredNumber && `${planInput.requiredNumber}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: true || focused.weightPerItem || (planInput.weightPerItem.length > 0)}">Weight/lbs</p>
<input type="text" class="form-control" v-model="planInput.weightPerItem"
  @focus="() => {
    activated.weightPerItem = true;
    focused.weightPerItem = true;
  }"
  @blur="() => {
    focused.weightPerItem = false;
  }">
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: true || focused.requiredQuantity || (planInput.requiredQuantity.length > 0)}">Total Weight (lbs/item)</p>
<input type="text" class="form-control" v-model="planInput.requiredQuantity"
  @focus="() => {
    activated.requiredQuantity = true;
    focused.requiredQuantity = true;
  }"
  @blur="() => {
    focused.requiredQuantity = false;
  }">
<!-- <p v-if="activated.requiredQuantity && planInput.requiredQuantity.length === 0" class="form-info red text-end">* Required</p> -->
<p v-if="activated.requiredQuantity && `${planInput.requiredQuantity}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: true || focused.orderNumber || (planInput.orderNumber.length > 0)}">Order # of Items</p>
<input type="text" class="form-control" v-model="planInput.orderNumber"
  @focus="() => {
    activated.orderNumber = true;
    focused.orderNumber = true;
  }"
  @blur="() => {
    focused.orderNumber = false;
  }">
<p v-if="activated.orderNumber && planInput.orderNumber.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.orderNumber && `${planInput.orderNumber}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: true || focused.orderQuantity || (planInput.orderQuantity.length > 0)}">Order Weight (lbs/item)</p>
<input type="text" class="form-control" v-model="planInput.orderQuantity"
  @focus="() => {
    activated.orderQuantity = true;
    focused.orderQuantity = true;
  }"
  @blur="() => {
    focused.orderQuantity = false;
  }">
<!-- <p v-if="activated.orderQuantity && planInput.orderQuantity.length === 0" class="form-info red text-end">* Required</p> -->
<p v-if="activated.orderQuantity && `${planInput.orderQuantity}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.orderDate || (planInput.orderDate.length > 0)}">Order Date</p>
<input type="date" class="form-control" 
  :style="{color: (focused.orderDate || planInput.orderDate.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
  v-model="planInput.orderDate"
  pattern="\d{2}/\d{2}/\d{4}"
  @focus="() => {
    activated.orderDate = true;
    focused.orderDate = true;
  }"
  @blur="() => {
    focused.orderDate = false;
  }">
  <div class="date-icon"></div>
<p v-if="activated.orderDate && planInput.orderDate.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<!-- <div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendor || (planInput.vendor.length > 0)}">Vendor</p>
<input type="text" class="form-control"  v-model="planInput.vendor"
  @focus="() => {
    activated.vendor = true;
    focused.vendor = true;
  }"
  @blur="() => {
    focused.vendor = false;
  }">
<p v-if="activated.vendor && planInput.vendor.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.vendor && planInput.vendor.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div> -->

<!-- <div class="col-3" v-if="vendors.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorName || (planInput.vendorName.length > 0)}">Vendor</p>
  <DropDownList
    :mainObject="'planInput'"
    :mainProperty="'vendorName'"
    :injectionName="'planInputDetailsThree'"
    :optionKey="'vendor-option-plan-input'"
    :optionsList="[]"
    :optionProperty="'vendor'"
    :optionPropertyTwo="null"
    :defaultSelected="planInput.vendorName"
    @click="() => {
      activated.vendorName = true;
    }"
  />
<p v-if="activated.vendorName && planInput.vendorName.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.vendorName && planInput.vendorName.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3" v-else>
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorName || (planInput.vendorName.length > 0)}">Vendor</p>
<input type="text" class="form-control" v-model="planInput.vendorName"
  @focus="() => {
    activated.vendorName = true;
    focused.vendorName = true;
  }"
  @blur="() => {
    focused.vendorName = false;
  }">
<p v-if="activated.vendorName && planInput.vendorName.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.vendorName && planInput.vendorName.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div> -->

<!-- <div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorPhone || (planInput.vendorPhone.length > 0)}">Vendor Phone</p>
<input type="text" class="form-control"  v-model="planInput.vendorPhone"
  pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
  @focus="() => {
    activated.vendorPhone = true;
    focused.vendorPhone = true;
  }"
  @blur="() => {
    focused.vendorPhone = false;
    phoneNumberFormat('vendorPhone')
  }">
<p v-if="activated.vendorPhone && planInput.vendorPhone.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.vendorPhone && (`${planInput.vendorPhone}`.length > 14  && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(planInput.vendorPhone))" class="form-info red text-end">Please use correct format</p>
</div>
</div> -->

<!-- <div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.vendorContact || (planInput.vendorContact.length > 0)}">Vendor Contact</p>
<input type="text" class="form-control"  v-model="planInput.vendorContact"
  @focus="() => {
    activated.vendorContact = true;
    focused.vendorContact = true;
  }"
  @blur="() => {
    focused.vendorContact = false;
  }">
<p v-if="activated.vendorContact && planInput.vendorContact.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.vendorContact && planInput.vendorContact.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div> -->

<div class="col-12">
<div class="form-item">
<p class="form-label" :class="{active: focused.comments || (planInput.comments.length > 0)}">Comments</p>
<textarea class="form-control"  v-model="planInput.comments"
  @focus="() => {
    activated.comments = true;
    focused.comments = true;
  }"
  @blur="() => {
    focused.comments = false;
  }"></textarea>
<p v-if="activated.comments && planInput.comments.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

</div>
</div>
</div>

</div>

<div class="content-block">
<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="setNotification" v-model="planInput.setNotifications"><label for="setNotification">Set Notification</label></div>
<div class="field-box d-flex">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control sm" v-model="planInput.daysInAdvance">
</div>
</div>


</div>

<div class="content-block">

<FileAttachments :key="`vendor-plan-input-form-${planInput.id}`" v-bind:section="'planInventory'" v-bind:instance="`${planInput.id}`" />

<div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
</div>
</div>
</div>

</div>

</div>

<template  v-if="inputDialog">
  <div class="form-modal vendor-modal">
    <span class="form-modal-close" @click="() => {inputDialog = false}"><img src="@/assets/images/close-green-xs.png" alt=""></span>
    <VendorModule v-bind:inputDialogView="true" />
  </div>
</template>

<div class="removal-popup" v-if="popUps.addAnotherItem.active">

<div class="modal modal-md">
<span class="modal-close" @click="() => confirmAddAnother(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">{{ popUps.addAnotherItem.message }}</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => confirmAddAnother(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => confirmAddAnother(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>

</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { reactive, inject, watch, ref, computed, onMounted, provide } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import inputYears from '@/sharedData/yearsList';
import DropDownList from '@/components/Shared/DropDownList.vue';
import VendorModule from '@/components/Pages/Vendor/VendorModule.vue';
import inventoriesOptions from '@/sharedData/inventoriesList.js';

export default {
  name: "PlanInput",
  setup() {
    const store = useStore();

    const inputSeasons = ['Spring', 'Summer', 'Autumn', 'Winter'];

    const activated = reactive({
      year: false,
      season: false,
      item: false,
      brand: false,
      variety: false,

      // inStockItem: false,
      // inStockNumber: false,
      // requiredQuantityItem: false,
      // requiredQuantityNumber: false,
      // orderAmountItem: false,
      // orderAmountNumber: false,

      inStockNumber: false,
      inStockQuantity: false,
      
      requiredNumber: false,
      requiredQuantity: false,

      weightPerItem: false,

      orderNumber: false,
      orderQuantity: false,
      
      orderDate: false,
      vendorName: false,
      vendorPhone: false,
      vendorContact: false,
      comments: false
    });

    const focused = reactive({
      year: false,
      season: false,
      item: false,
      brand: false,
      variety: false,

      // inStockItem: false,
      // inStockNumber: false,
      // requiredQuantityItem: false,
      // requiredQuantityNumber: false,
      // orderAmountItem: false,
      // orderAmountNumber: false,

      inStockNumber: false,
      inStockQuantity: false,
      
      requiredNumber: false,
      requiredQuantity: false,

      weightPerItem: false,

      orderNumber: false,
      orderQuantity: false,

      orderDate: false,
      vendorName: false,
      vendorPhone: false,
      vendorContact: false,
      comments: false
    });

    const { planInput, planInputEditionMode, selectedPlanInput, 
      selectPlanInput, defaultPlanInput } = inject("planInputDetails");

    // const vendors = ref([...store.state.vendors].filter(vendor => vendor.status === 'Active'));

    const inventories = computed(() => [...store.state.vendorItems].filter(stateItem => stateItem.type === 'Product'));

    const products = ref([...inventories.value]);

    const inputOptions = ref([...inventoriesOptions, ...products.value.map(option => {
      if (!inventoriesOptions.includes(option.item)) {
        return option.item
      }
    }).filter(o => o), 'Add New']);

    const manualInput = ref(false);

    const itemField = ref(null);

    const otherSelected = ref(false);

    const helperTooltip = ref(false);

    const inputDialog = ref(false);

    const popUps = reactive({
      addAnotherItem: {
        active: false,
        message: 'Do you want to plan another item?'
      }
    });

    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle;
    }

    const submitForm = () => {
      if (!planInput.year || !planInput.season || !planInput.item || 
      // !planInput.brand || 
      
      // !planInput.inStockItem || !planInput.inStockNumber ||
      // !planInput.requiredQuantityItem || !planInput.requiredQuantityNumber ||
      // !planInput.orderAmountItem || !planInput.orderAmountNumber 

      !planInput.inStockNumber ||
      // !planInput.inStockQuantity ||
      
      !planInput.requiredNumber ||
      // !planInput.requiredQuantity ||

      !planInput.orderNumber ||
      // !planInput.orderQuantity ||
      
      !planInput.orderDate
      // || !planInput.vendorName || !planInput.vendorPhone || !planInput.vendorContact
    ) {
        if (!planInput.year) activated.year = true;
        if (!planInput.season) activated.season = true;
        if (!planInput.item) activated.item = true;
        // if (!planInput.brand) activated.brand = true;

        // if (!planInput.inStockNumber) activated.inStockNumber = true;
        // if (!planInput.requiredQuantityNumber) activated.requiredQuantityNumber = true;
        // if (!planInput.orderAmountNumber) activated.orderAmountNumber = true;
        
        if (!planInput.inStockNumber) activated.inStockNumber = true;
        // if (!planInput.inStockQuantity) activated.inStockQuantity = true;
        if (!planInput.requiredNumber) activated.requiredNumber = true;
        // if (!planInput.requiredQuantity) activated.requiredQuantity = true;
        if (!planInput.orderNumber) activated.orderNumber = true;
        // if (!planInput.orderQuantity) activated.orderQuantity = true;

        if (!planInput.orderDate) activated.orderDate = true;
        // if (!planInput.vendorName) activated.vendorName = true;
        // if (!planInput.vendorPhone) activated.vendorPhone = true;
        // if (!planInput.vendorContact) activated.vendorContact = true;

        return;
      }

      if (!planInput.id) {
        planInput.id = uuid();
      }
      
      planInput.orderDate = moment(planInput.orderDate).format('MM/DD/YYYY');

      const formData = {
        ...planInput
      };

      (planInputEditionMode.value === "edit") ?
          store.dispatch("editPlannedInventory", { ...formData }) :
          store.dispatch("addPlannedInventory", {...formData}); // createAddedInventory
      console.log(formData);
      (planInputEditionMode.value === "create") && closeForm();
      (planInputEditionMode.value === "edit") && selectPlanInput({...planInput}, 'represent');

      popUps.addAnotherItem.active = true;
    }
    
    const closeForm = () => {
      for (let prop in planInput) {
        planInput[prop] = defaultPlanInput[prop];
      }

      for (let prop in activated) {
        activated[prop] = false;
      }
      
      for (let prop in focused) {
        focused[prop] = false;
      }
    }

    const cancelChanges = () => {
      if (planInputEditionMode.value === "edit") {
        for (let prop in planInput) {
          planInput[prop] = selectedPlanInput[prop];
        }
        planInputEditionMode.value = "represent";
      } else {
        for (let prop in planInput) {
          planInput[prop] = defaultPlanInput[prop];
        }
        planInputEditionMode.value = "";
      }
    };

    const phoneNumberFormat = (phoneType) => {
      if (planInput[phoneType].length > 0) {
        if (planInput[phoneType][0] !== "(") {
            const arrStr = planInput[phoneType].split("");
            planInput[phoneType] = `(${arrStr.filter((num, indx) => {
                if (indx < 3)
                    return num;
            }).join("")}) ${arrStr.filter((num, indx) => {
                if ((indx >= 3) && (indx < 6))
                    return num;
            }).join("")}-${arrStr.filter((num, indx) => {
                if (indx >= 6)
                    return num;
            }).join("")}`;
        }
      }
    };

    const confirmAddAnother = (confirmed) => {
      if (confirmed) {
        selectPlanInput({ ...defaultPlanInput }, 'create');
      } else {
        selectPlanInput({ ...store.state.plannedInventories[store.state.plannedInventories.length - 1] }, 'represent');
      }
      popUps.addAnotherItem.active = false;
    };

    watch(planInput, (newVal) => {
      if (newVal.requiredQuantityNumber && newVal.inStockNumber) {
        planInput.orderAmountNumber = `${Math.abs(Number.parseInt(newVal.requiredQuantityNumber || 0) - Number.parseInt(newVal.inStockNumber || 0))}`
      }
    })

    // watch(() => (planInput.vendorName), (newVal) => {
    //   const selectedVendor = vendors.value.find(stateVendor => stateVendor.vendor === newVal);

    //   if (selectedVendor) {
    //     products.value = [...inventories.value.filter(inv => inv.vendor === selectedVendor.id)]

    //     planInput.vendorPhone = selectedVendor.vendorPhone;
    //     planInput.vendorContact = selectedVendor.vendorContact;
    //   }
    // })

    watch(() => (planInput.item), (newVal) => {
      if (newVal === 'Add New') {
        manualInput.value = true;
        otherSelected.value = true;
        planInput.item = '';
        setTimeout(() => {
          itemField.value.focus();
        }, 10)
      } else {
        // const selectedProduct = products.value.find(prd => prd.item === newVal);
        const selectedProducts = products.value.filter(prd => prd.item === newVal);

        if (selectedProducts) {
          // planInput.brand = selectedProduct.brand;
          // planInput.variety = selectedProduct.variety;
          const numberOfItems = selectedProducts.reduce(
            (accumulator, currentValue) => accumulator + currentValue.numberOfItems,
            0,
          );

          const quantity = selectedProducts.reduce(
            (accumulator, currentValue) => accumulator + currentValue.quantity,
            0,
          );

          planInput.inStockNumber = numberOfItems;//selectedProduct.numberOfItems;
          // planInput.inStockQuantity = `${Math.abs(Number.parseInt(selectedProduct.numberOfItems) * Number.parseInt(selectedProduct.quantity))}`;
          planInput.inStockQuantity = `${Math.abs(Number.parseInt(numberOfItems) * Number.parseInt(quantity))}`;

          const usedInTask = [...store.state.tasks].find(task => task.input === newVal);

          if (usedInTask) {
            planInput.inStockNumber = `${Math.abs(Number.parseInt(planInput.inStockNumber) - Number.parseInt(usedInTask.numberOfItems))}`;
            planInput.inStockQuantity = `${Math.abs(Number.parseInt(quantity) * Number.parseInt(planInput.inStockNumber))}`;
            // `${Math.abs(Number.parseInt(planInput.inStockQuantity) - (Number.parseInt(usedInTask.numberOfItems) * Number.parseInt(usedInTask.quantity)))}`;
          }

          // const selectedVendor = vendors.value.find(stateVendor => stateVendor.id === selectedProduct.vendor);

          // if (selectedVendor) {
          //   planInput.vendorName = selectedVendor.vendor;
          //   planInput.vendorPhone = selectedVendor.vendorPhone;
          //   planInput.vendorContact = selectedVendor.vendorContact;
          // }
        }
      }
    });

    watch(() => (planInput.requiredNumber), (newVal) => {
      if (newVal) {
        const selectedProduct = products.value.find(prd => prd.item === planInput.item);

        if (selectedProduct) {
          planInput.requiredQuantity = `${Math.abs(Number.parseInt(newVal) * Number.parseInt(selectedProduct.quantity))}`;
          planInput.orderNumber = `${Math.abs(Number.parseInt(newVal) - Number.parseInt(planInput.inStockNumber))}`;
          planInput.orderQuantity = `${Math.abs(Number.parseInt(planInput.requiredQuantity) - Number.parseInt(planInput.inStockQuantity))}`
        }
      }
    });

    watch(() => ({...planInput}), (newVal) => {
      if (!newVal.id) {
        planInput.id === uuid();
      }
    });

    // watch(() => ([...store.state.vendors]), (newVal) => {
    //   if (newVal) {
    //     vendors.value = [...newVal].filter(vendor => vendor.status === 'Active');
    //   }
    // })

    watch(() => ([...store.state.vendorItems]), (newVal) => {

      if (newVal) {
        // const selectedVendor = [...store.state.vendors].filter(vendor => vendor.status === 'Active').find(stateVendor => stateVendor.vendor === planInput.vendorName);

        const inventoriesList = [...newVal].filter(stateItem => stateItem.type === 'Product');

        // products.value = selectedVendor ? [...inventoriesList.filter(inv => inv.vendor === selectedVendor.id)] : [...inventoriesList];
        
        products.value = [...inventoriesList];

        inputOptions.value = [...inventoriesOptions, ...products.value.map(option => {
          if (!inventoriesOptions.includes(option.item)) {
            return option.item
          }
        }).filter(o => o), 'Add New'];

      }

    });

    provide('addTaskDetails', {
      task: {},
      inputDialog
    });

    provide('planInputDetailsTwo', {
      planInput,
      options: inputOptions
    });

    // provide('planInputDetailsThree', {
    //   planInput,
    //   options: vendors
    // })

    provide('otherHandling', {
      otherSelected
    });

    onMounted(() => {
      if (!planInput.id) {
        console.log(planInput.id, 'planInput.id');
        planInput.id = uuid();
      }
    });

    return {
      inputYears,
      inputSeasons,
      activated,
      focused,
      planInput,
      // vendors,
      products,
      submitForm,
      cancelChanges,
      phoneNumberFormat,
      helperTooltip,
      switchHelperTooltip,
      inputDialog,
      popUps,
      confirmAddAnother,
      inputOptions,
      manualInput,
      itemField
    }
  },
  components: { FileAttachments, DropDownList, VendorModule }
}
</script>

<style lang="scss" scoped>
.date-icon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 3.7rem;
  right: 1rem;
  z-index: 8;
  pointer-events: none;
  border-radius: 1rem;
  background:url('@/assets/images/calendar-icon.svg') center center no-repeat;
  background-color: #fff;
}
</style>