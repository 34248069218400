const options = [
  '1 - 10',
  '11 - 25',
  '26 - 50',
  '51 - 100',
  '101 - 500',
  '501 - 1000',
  '1001 - 5000',
  '5000 +'
];

export default options;
