const getUserData = async (userId) => {
  console.log(userId);
  return {
    token: '',
    role: {
      id: '0',
      key: 'admin',
      permissions: [
        {
          id: '0',
          key: 'all-access'
        },
        {
          id: '2',
          key: 'create-account'
        }
      ]
    },
    registrationDate: new Date(),
    paymentMethods: [],
    selectedPayment: null,
    approvedPayment: false
  }
}

export default getUserData;