<template>
  <section class="section" ref="section">
<!-- <div class="border-box active"> -->

  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Inventory Status</h5>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-wrapper">
<!-- <div class="content-row table-container"> -->
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label">
  <span>Item</span>
  <span @click="() => sortTable('item')">
    <button :class="{active: sortOrder.item === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.item === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Variety</span>
  <span @click="() => sortTable('variety')">
    <button :class="{active: sortOrder.variety === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.variety === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Required Items</span>
  <span @click="() => sortTableByNumber('requiredNumber')">
    <button :class="{active: sortOrder.requiredNumber === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.requiredNumber === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>In Stock</span>
  <span @click="() => sortTableByNumber('inStock')">
    <button :class="{active: sortOrder.inStock === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.inStock === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Order Amt</span>
  <span @click="() => sortTableByNumber('orderAmount')">
    <button :class="{active: sortOrder.orderAmount === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.orderAmount === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Vendor</span>
  <span @click="() => sortTable('vendorName')">
    <button :class="{active: sortOrder.vendorName === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.vendorName === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Vendor Phone</span>
  <span @click="() => sortTable('vendorPhone')">
    <button :class="{active: sortOrder.vendorPhone === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.vendorPhone === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<!-- <th class="w-75">&nbsp;</th> -->
</tr>
</thead>

<tbody>

<!-- <tr>
<td><input type="text" class="table-form-control"></td>
<td><input type="text" class="table-form-control"></td>
<td><input type="text" class="table-form-control"></td>
<td><input type="text" class="table-form-control"></td>
<td><input type="text" class="table-form-control"></td>
<td><input type="text" class="table-form-control"></td>
<td><input type="text" class="table-form-control"></td>
<td class="w-75">
<div class="table-links">
<a href="javascript:void();"><img src="@/assets/images/phone-icon.svg" alt=""></a>
</div>
</td>
</tr> -->

  <template
    v-for="(row, rowIndex) in rows"
    v-bind:key="'inventory-plan-list-row' + row.id"
  >
    <tr
      v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
    >
    <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
    <td class="text-center">{{ row.item }}</td>
    <td>{{ row.variety }}</td>
    <td>{{ row.requiredNumber }}</td>
    <!-- <td>{{ row.inStockNumber }}</td> -->
    <td>{{ row.inStock }}</td>
    <td>{{ row.orderAmount }}</td>
    <td>{{ row.vendorName }}</td>
    <td>{{ row.vendorPhone }}</td>
    <!-- <td class="w-75">
    <div class="table-links">
    </div>
    </td> -->
    </tr>
  </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select>
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'inventory-status-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'inventoryStatusListDetails'"
    :optionKey="'inventory-status-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>
<!-- </div> -->
</section>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { defineComponent, ref, computed, reactive, watch, onMounted, provide } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: "InventoryStatus",
    setup() {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const addedInventories = computed(() => [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.type === 'Product'));
        const plannedInventories = computed(() => store.state.plannedInventories);
        const inventoriesUsed = computed(() => [...store.state.tasks].filter(stateTask => stateTask.inputTask && (stateTask.taskStatus === 'Completed')));
        const vendors = computed(() => store.state.vendors);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortOrder = reactive({
            item: 0,
            variety: 0,
            requiredNumber: 0,
            inStock: 0,
            orderAmount: 0,
            vendorName: 0,
            vendorPhone: 0
        });
        const sortTable = (sortProp) => {
            const originRows = [...rows.value];
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...originRows];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByNumber = (sortProp) => {
            const originRows = [...rows.value];
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...originRows];
                sortOrder[sortProp] = 0;
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        const calculateRow = (added) => {//used
            // rows.value = [...addedInventories.value];

            // filter out repeated with the same item & variety
            const filterAdded = [...added].filter((value, index, self) =>
              index === self.findIndex((t) => (
                t.item === value.item && t.variety === value.variety
              ))
            );

            rows.value = [...filterAdded].map(originInventory => {
                // const usedInventory = used.find(u => u.input === originInventory.item);
                const plannedInventory = plannedInventories.value.find(pi => pi.item === originInventory.item);
                const vendorDetails = vendors.value.find(vendor => vendor.id === originInventory.vendor);

                const selectedProducts = addedInventories.value.filter(prd => (prd.item === originInventory.item) && (prd.variety === originInventory.variety));

                let numberOfItems;
                
                if (selectedProducts) {
                  numberOfItems = selectedProducts.reduce(
                    (accumulator, currentValue) => accumulator + currentValue.numberOfItems,
                    0,
                  )
                }

                return {
                    ...originInventory,
                    // requiredNumber: plannedInventory && plannedInventory.requiredNumber,
                    requiredNumber: plannedInventory?.requiredNumber || '',
                    inStock: numberOfItems || 0,//Math.abs((Number.parseInt((plannedInventory ? plannedInventory.inStockNumber : 0)) + Number.parseInt(originInventory.numberOfItems)) - Number.parseInt((usedInventory ? usedInventory.numberOfItems : 0))),
                    orderAmount: Math.abs(Number.parseInt((plannedInventory ? plannedInventory.requiredNumber : 0)) - Number.parseInt((plannedInventory ? plannedInventory.inStockNumber : 0))),
                    vendorName: vendorDetails && vendorDetails.vendor,
                    vendorPhone: vendorDetails && vendorDetails.vendorPhone
                };
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
            // console.log(added, used);
        };
        watch(() => ([...addedInventories.value]), (newVal) => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            calculateRow(newVal, inventoriesUsed.value);
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        }, { deep: true });
        watch(() => ([...inventoriesUsed.value]), (newVal) => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            calculateRow(addedInventories.value, newVal);
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        }, { deep: true });
        // addedInventories
        // inventoriesUsed
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        // change
        onMounted(() => {
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getAddedInventorys')
            // rows.value = [...addedInventories.value];
            calculateRow(addedInventories.value, inventoriesUsed.value);
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('inventoryStatusListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            section,
            boxHeader,
            toggleSection,
            rows,
            maxPage,
            currentPage,
            sortOrder,
            sortTable,
            sortTableByNumber
        };
    },
    components: { DropDownList }
})
</script>

<style></style>