<template>
  <MainMenu @click="handleAppWideElements" />
  <main @click="handleAppWideElements">
    <UserInfoHeader />
    <NavigationBreadcrumbs />
    <component
      v-bind:class="{ hidden: nameFromUrl ? false : true }"
      v-bind:is="nameFromUrl"
    ></component>
    <AppFooter />
  </main>
</template>

<script>
import { useRouter } from "vue-router";
import { computed, toRefs, ref , provide, watch, onMounted } from "vue";
import { useStore } from "vuex";

import MainMenu from "@/components/Shared/MainMenu.vue";
import UserInfoHeader from "@/components/Shared/UserInfoHeader.vue";
import AppFooter from "@/components/Shared/AppFooter.vue";
import NavigationBreadcrumbs from "@/components/Shared/NavigationBreadcrumbs.vue";

import DashboardModule from "@/components/Pages/Dashboard/DashboardModule.vue";

import UserProfile from "@/components/Pages/UserProfile/UserProfile.vue";
import SecondaryUser from "@/components/Pages/SecondaryUser/SecondaryUser.vue";
import FarmProfile from "@/components/Pages/FarmProfile/FarmProfile.vue";
import FarmHistory from "@/components/Pages/FarmProfile/FarmHistory.vue";

import PlanCrop from "@/components/Pages/Crops/PlanCrop.vue";

import TaskModule from "@/components/Pages/Task/TaskModule.vue";
import CompletedTasks from "@/components/Pages/Task/CompletedTasks.vue";

import InventoryModule from "@/components/Pages/Inventory/InventoryModule.vue";
import PlanInventory from "@/components/Pages/Inventory/PlanInventory.vue";

import VendorModule from "@/components/Pages/Vendor/VendorModule.vue";
import ArchivedVendors from "@/components/Pages/Vendor/ArchivedVendors.vue";

import EquipmentModule from "@/components/Pages/Equipments/EquipmentModule.vue";
import EquipmentService from "@/components/Pages/Equipments/EquipmentService.vue";

// import AdminSettings from "@/components/Pages/AdminSettings/AdminSettings.vue";
import AdminModule from "@/components/Pages/Admin/AdminModule.vue";
import AdminAccount from "@/components/Pages/Admin/AdminAccount.vue";
import AdminUsers from "@/components/Pages/Admin/AdminUsers.vue";
import AdminSubscription from "@/components/Pages/Admin/AdminSubscription.vue";
import AdminPayment from "@/components/Pages/Admin/AdminPayment.vue";

import HarvestModule from "@/components/Pages/Harvest/HarvestModule.vue";

import SalesModule from "@/components/Pages/Sales/SalesModule.vue";
import CustomersModule from "@/components/Pages/Sales/CustomersModule.vue";

import LaborExpenses from "@/components/Pages/Expenses/LaborExpenses.vue";
import AdminExpenses from "@/components/Pages/Expenses/AdminExpenses.vue";
import EmployeeCompensation from "@/components/Pages/Expenses/EmployeeCompensation.vue";
import LaborModule from "@/components/Pages/Labor/LaborModule.vue";
import IncomeModule from "@/components/Pages/Labor/IncomeModule.vue";
import SearchModule from "@/components/Pages/Search/SearchModule.vue";
import SuperAdmin from "@/components/Pages/SuperAdmin/SuperAdmin.vue";
import SuperAdminArchivedList from "@/components/Pages/SuperAdmin/SuperAdminArchivedList.vue";
import SuperAdminList from "@/components/Pages/SuperAdmin/SuperAdminList.vue";

import AdvertisementModule from "@/components/Pages/Advertisement/AdvertisementModule.vue";

import UserSubscription from "@/components/Pages/UserProfile/UserSubscription.vue";

import ResourcesModule from "@/components/Pages/Resources/ResourcesModule.vue";

import ResourcesUser from "@/components/Pages/Resources/ResourcesUser.vue";

import ContactModule from "@/components/Pages/Contact/ContactModule.vue";

import ResetPassword from "@/components/Pages/ResetPassword/ResetPassword.vue"

import pagesList from "@/sharedData/pagesList";

import ProfileView from "@/components/Pages/SecondaryUser/ProfileView.vue";
import isAuthorized from "@/components/Shared/authCheckHook";
import isSubscribedCheck from "@/components/Shared/subscribedCheckHook";

import MenuProfile from "@/components/Pages/UserProfile/MenuProfile.vue"
import MenuSecondaryprofile from "@/components/Pages/SecondaryUser/MenuSecondaryprofile.vue"
import ReportsModule from "@/components/Pages/Reports/ReportsModule.vue"
import ReportsCrops from "@/components/Pages/Reports/ReportsCrops.vue"
import ReportsExpenses from "@/components/Pages/Reports/ReportsExpenses.vue"

export default {
  name: "CurrentPage",
  components: {
    MainMenu,
    UserInfoHeader,
    AppFooter,
    NavigationBreadcrumbs,
    DashboardModule,
    UserProfile,
    SecondaryUser,
    FarmProfile,
    FarmHistory,
    PlanCrop,
    TaskModule,
    CompletedTasks,
    InventoryModule,
    PlanInventory,
    VendorModule,
    ArchivedVendors,
    EquipmentModule,
    EquipmentService,
    // AdminSettings,
    AdminModule,
    AdminAccount,
    AdminUsers,
    AdminSubscription,
    AdminPayment,
    HarvestModule,
    SalesModule,
    CustomersModule,
    LaborExpenses,
    AdminExpenses,
    EmployeeCompensation,
    LaborModule,
    IncomeModule,
    SearchModule,
    SuperAdmin,
    SuperAdminArchivedList,
    SuperAdminList,
    AdvertisementModule,
    UserSubscription,
    ResourcesModule,
    ResourcesUser,
    ContactModule,
    ProfileView,
    ResetPassword,
    MenuProfile,
    MenuSecondaryprofile,
    ReportsModule,
    ReportsCrops,
    ReportsExpenses
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let computedName = "dashboard-module";
    const pages = [...pagesList];
    const drawer = ref(true);
    const width = ref(null);
    const userProfileMenu = ref(false);

    // remove old calendar
    const calendarWidgetExpanded = ref(false);

    const isAuth = (featureCode) => {
      return isAuthorized(store.state.authenticated, featureCode)
    }

    const isSubscribed = (featureCode) => {
      if (store.state.authenticated.userRole === 'admin') {
        if (store.state.primaryUser?.subscription?.plans) {
          return isSubscribedCheck(store.state.primaryUser.subscription.plans, featureCode)
        }
      } else {
        if (store.state.secondaryUser?.subscription?.plans) {
          return isSubscribedCheck(store.state.secondaryUser.subscription.plans, featureCode)
        }
      }
    }
    console.log(pages);

    const nameFromUrl = computed(() => {
      let routeName = router.currentRoute.value.path.split("/");
      if (routeName[2] === 'advertisement-module') {
        computedName = 'AdvertisementModule';

        return computedName;
      }
      if (routeName[routeName.length - 1].length > 1) {
        let firstPart = routeName[routeName.length - 1].split("-")[0],
          secondPart = routeName[routeName.length - 1].split("-")[1];

        computedName = `${
          firstPart.charAt(0).toUpperCase() + firstPart.slice(1)
        }${
          secondPart ? secondPart.charAt(0).toUpperCase() + secondPart.slice(1) : 'Module'
        }`;
      } else {
        computedName = 'dashboard-module';
      }

      return computedName;
    });

    const page = computed(() => {
      return pages.find((pg) => pg.name === nameFromUrl?.value)
    })

    const handleAppWideElements = (ev) => {
      if (ev.target.className !== 'user-menu-button') {
        userProfileMenu.value = 'released';
      }

      // remove old calendar
      if (ev.target.className !== 'calendar-widget-button') {
        calendarWidgetExpanded.value = 'released';
      }
    }

    const state = toRefs({
      nameFromUrl,
      page,
      handleAppWideElements
    });

    const switchDrawer = (val) => {
      drawer.value = !drawer.value
      if (typeof val === 'boolean') drawer.value = val;
    }

    const resize = () => {
      width.value = window.innerWidth;

      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: 'smooth'
      // });

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }

    provide('mainUI', {
      drawer,
      switchDrawer,
      width,
      isAuth,
      isSubscribed,
      userProfileMenu,
      // remove old calendar
      calendarWidgetExpanded
    })

    watch(page, () => {
      if (page?.value?.parent) {
        store.dispatch('addCurrentPath', {
          section: 0,
          val: page?.value?.parent      
        });
        store.dispatch('addCurrentPath', {
          section: 1,
          val: page?.value?.header      
        });
      } else {
        store.dispatch('addCurrentPath', {
          section: 0,
          val: page?.value?.header      
        });

        store.dispatch('addCurrentPath', {
          section: 1,
          val: null
        });
      }
    })

    onMounted(() => {
      resize()
      window.addEventListener("resize", resize);

      if (page?.value?.header) {
        if (page?.value?.parent) {
          store.dispatch('addCurrentPath', {
            section: 0,
            val: page.value.parent      
          });
          store.dispatch('addCurrentPath', {
            section: 1,
            val: page.value.header      
          });
        } else {
          store.dispatch('addCurrentPath', {
            section: 0,
            val: page.value.header      
          });
        }
      }
    })

    console.log(state, state.nameFromUrl, state.page);
    return state;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.page-wrapper {
  width: 100%;
  .page-header {
    padding: 0 24px;
    // font-size: 2.9vw;
    font-size: 1.4vw;
    color: #28292B;
    font-family: 'Gilroy';
    font-weight: 700;
    margin: 0;
  }
}
</style>
