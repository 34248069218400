<template>

<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Subscription Type</h5>
<div class="icon-group">
  <div class="info-icon lg top-icon icon tooltip"
    @click="() => selectSubscription({...subscription}, 'create')"
    data-tooltip="Buy Subscription"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
  >
    <!-- <img src="@/assets/images/plus-green-md.png" alt=""> -->
    <img src="@/assets/images/updated/059-add.png" alt="">
    <!-- <div class="edit-helper-tooltip" v-if="helperTooltip">Add Subscription</div> -->
  </div>
  <!-- <div class="info-icon lg edit-subscription tooltip"
    @click="() => selectSubscription({...subscription}, 'edit')"
    data-tooltip="Edit Subscription"
    @mouseover="switchEditHelperTooltip(true)"
    @mouseout="switchEditHelperTooltip(false)"
  > -->
    <!-- <img src="@/assets/images/edit-profile-icon.svg" alt=""> -->
    <!-- <img src="@/assets/images/updated/Edit.png" alt=""> -->
    <!-- <div class="edit-helper-tooltip" v-if="editHelperTooltip">Edit Subscription</div> -->
  <!-- </div> -->
</div>
</div>

<div class="content-block">
  <div class="payment-details">
 

<div class="subscription-wrap-block">

  <div class="content-row subscription-info sixth-column">
    <div>
        <p class="form-label">Billing Type</p>
        <h6>{{ subscription.subscriptionPeriod }}</h6>
        <p class="fs-xs pt-1">10% Discount on Yearly Subscription</p>
    </div>

    <div>
      <span class="info-icon"
        @mouseover="switchSubscriptionTooltip(true)"
        @mouseout="switchSubscriptionTooltip(false)"
      >
        <img src="@/assets/images/question-mark.png" alt="">
        <div class="tooltip subscription-info-tooltip" v-if="subscriptionTooltip">
          Subscription can only be deleted from available subscriptions. <br/>
          If you want to delete an assigned subscription, go to <b>Users</b> under <br/>
          the admin section, delete a user so the subscription becomes available <br/>
          and then from the subscription section delete the available subscription.
        </div>
      </span>
    </div>
  </div>
  
<div class="subscription-list-grid-wrap">
  <div class="subscription-list-grid five-column-grid">

  <div></div>
  <div><p class="form-label text-center">Total</p></div>
  <div><p class="form-label text-center">Assigned</p></div>
  <div><p class="form-label text-center">Available</p></div>
  <div></div>

  <div :class="{greyedout: !subscriptionAssigned.trial}" class="checkbox"><input type="checkbox" id="free30day" v-model="subscriptionAssigned.trial" disabled><label for="free30day">Free 30 Day</label></div>
  <div :class="{greyedout: !subscriptionAssigned.trial}">
    <div class="w-80">
      <h6>{{ subscriptionAssigned.trial ? Number.parseInt(1) : Number.parseInt(0) }}</h6>
    </div>
  </div>
  <div :class="{greyedout: !subscriptionAssigned.trial}">
    <div class="w-80 checkbox checkbox-lg">
      <h6>{{ subscriptionAssigned.trial ? Number.parseInt(1) : Number.parseInt(0) }}</h6>
    </div>
  </div>
  <div :class="{greyedout: !subscriptionAssigned.trial}">
    <div class="w-80">
      <h6>{{ subscriptionAssigned.trial ? Number.parseInt(assigned.trial ? 0 : 1) : Number.parseInt(0) }}</h6>
    </div>
  </div>
  <div></div>

  <div class="checkbox"><input type="checkbox" id="basic" v-model="availablePlans.basic" disabled /><label for="basic" @click="() => handleMissingSubscription('basic')">Basic</label></div>
  <!-- subscriptionAssigned.basic -->
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.basicNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ (Number.parseInt((Number.parseInt(stateSubscription.plans.basicNumber) - Number.parseInt(stateSubscription.plans.basicAvailable)) || 0)) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.basicAvailable || 0) }}</h6>
    </div>
  </div>
  <div>
    <div v-if="(assigned.basic ? ((Number.parseInt(stateSubscription.plans.basicAvailable)-1) >= 0) : Number.parseInt(stateSubscription.plans.basicAvailable)) > 0"
      class="remove-subscription-icon tooltip"
      data-tooltip="Remove"
      @click="() => removeSubscription('basic')"
    >
      <img src="@/assets/images/trash-2.svg" alt="">
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlus" v-model="availablePlans.plus" disabled /><label for="basicPlus" @click="() => handleMissingSubscription('plus')">Basic +</label></div>
<!-- subscriptionAssigned.plus -->
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.plusNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ (Number.parseInt((Number.parseInt(stateSubscription.plans.plusNumber) - Number.parseInt(stateSubscription.plans.plusAvailable)) || 0)) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.plusAvailable || 0) }}</h6>
    </div>
  </div>
  <div>
    <div v-if="(assigned.plus ? ((Number.parseInt(stateSubscription.plans.plusAvailable)-1) >= 0) : Number.parseInt(stateSubscription.plans.plusAvailable)) > 0"
      class="remove-subscription-icon tooltip"  
      data-tooltip="Remove"
      @click="() => removeSubscription('plus')"
    >
      <img src="@/assets/images/trash-2.svg" alt="">
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlusAdvance" v-model="availablePlans.advance" disabled><label for="basicPlusAdvance" @click="() => handleMissingSubscription('advance')">Basic + Advance</label></div>
<!-- subscriptionAssigned.advance -->
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.advanceNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ (Number.parseInt((Number.parseInt(stateSubscription.plans.advanceNumber) - Number.parseInt(stateSubscription.plans.advanceAvailable)) || 0)) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.advanceAvailable || 0) }}</h6>
    </div>
  </div>
  <div>
    <div v-if="(assigned.advance ? ((Number.parseInt(stateSubscription.plans.advanceAvailable)-1) >= 0) : Number.parseInt(stateSubscription.plans.advanceAvailable)) > 0"
      class="remove-subscription-icon tooltip"  
      data-tooltip="Remove"
      @click="() => removeSubscription('advance')"
    >
      <img src="@/assets/images/trash-2.svg" alt="">
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlusUltimate" v-model="availablePlans.ultimate" disabled><label for="basicPlusUltimate" @click="() => handleMissingSubscription('ultimate')">Basic + Ultimate</label></div>
<!-- subscriptionAssigned.ultimate -->
  <div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.ultimateNumber || 0) }}</h6></div></div>
  <div>
    <div class="w-80">
      <h6>{{ (Number.parseInt((Number.parseInt(stateSubscription.plans.ultimateNumber) - Number.parseInt(stateSubscription.plans.ultimateAvailable)) || 0)) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.ultimateAvailable || 0) }}</h6></div>
  </div>
  <div>
    <div v-if="(assigned.ultimate ? ((Number.parseInt(stateSubscription.plans.ultimateAvailable)-1) >= 0) : Number.parseInt(stateSubscription.plans.ultimateAvailable)) > 0"
      class="remove-subscription-icon tooltip" 
      data-tooltip="Remove" 
      @click="() => removeSubscription('ultimate')"
    >
      <img src="@/assets/images/trash-2.svg" alt="">
    </div>
  </div>

  </div>
</div>

</div>
 
<div class="content-row overflow-auto subscription-result-wrap">
<div class="four-column-grid">
<div class="text-center">
  <p class="form-label">Subscription Amount</p>
  <h6>${{ Number.parseFloat(stateSubscription.totalSubscription || 0).toFixed(2) }}</h6>  
</div>
<div class="text-center">
  <p class="form-label">Discount</p>
  <h6>${{ Number.parseFloat(stateSubscription.discount || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Taxes</p>
  <h6>${{ Number.parseFloat(stateSubscription.taxes || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Total</p>
  <h6>${{ Number.parseFloat(stateSubscription.total || 0).toFixed(2) }}</h6>  
</div>
</div>  
</div>

</div>
</div>

<!-- Old content -->

<!--<div class="content-block">
  <div class="payment-details">

 <div class="content-row">
<div class="checkbox"><input type="checkbox" id="freeThirtyDay" v-model="assigned.trial" disabled><label for="freeThirtyDay">Free 30 Day</label></div>  
 </div>
 
  <div class="content-row d-flex justify-content-end">
    <div>
        <p class="form-label">Billing Type</p>
        <h6>{{ subscription.subscriptionPeriod }}</h6>
        <p class="fs-xs pt-1">10% Discount on Yearly Subscription</p>
    </div>
 </div>

<div class="content-row overflow-auto">
<div class="subscription-table-grid">

<div><div class="w-80"><p class="form-label text-center">Total</p></div></div>
<div><div class="w-80"><p class="form-label text-center">Assigned</p></div></div>
<div><div class="w-80"><p class="form-label text-center">Available</p></div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basic" v-model="subscription.plans.basic" disabled /><label for="basic">Basic</label></div></div>  
<div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.basicNumber || 0) }}</h6></div></div>
<div><div class="w-80"><h6>{{ (Number.parseInt(stateSubscription.plans.basicNumber - subscription.plans.basicAvailable) || 0) }}</h6></div></div>
<div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.basicAvailable || 0) }}</h6></div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basicPlus" v-model="subscription.plans.plus" disabled /><label for="basicPlus">Basic +</label></div></div>  
<div><div class="w-80">
  <h6>{{ Number.parseInt(stateSubscription.plans.plusNumber || 0) }}</h6>
</div></div>
<div><div class="w-80">
  <h6>{{ (Number.parseInt(stateSubscription.plans.plusNumber - subscription.plans.plusAvailable) || 0) }}</h6>
</div></div>
<div><div class="w-80">
  <h6>{{ Number.parseInt(stateSubscription.plans.plusAvailable || 0) }}</h6>
</div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basicPlusAdvance" v-model="subscription.plans.advance" disabled><label for="basicPlusAdvance">Basic + Advance</label></div></div>  
<div><div class="w-80">
  <h6>{{ Number.parseInt(stateSubscription.plans.advanceNumber || 0) }}</h6>
</div></div>
<div><div class="w-80">
  <h6>{{ (Number.parseInt(stateSubscription.plans.advanceNumber - subscription.plans.advanceAvailable) || 0) }}</h6>
</div></div>
<div><div class="w-80">
  <h6>{{ Number.parseInt(stateSubscription.plans.advanceAvailable || 0) }}</h6>
</div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basicPlusUltimate" v-model="subscription.plans.ultimate" disabled><label for="basicPlusUltimate">Basic + Ultimate</label></div></div>  
<div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.ultimateNumber || 0) }}</h6></div></div>
<div><div class="w-80"><h6>{{ (Number.parseInt(stateSubscription.plans.ultimateNumber - subscription.plans.ultimateAvailable) || 0) }}</h6></div></div>
<div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.ultimateAvailable || 0) }}</h6></div></div>

</div>
</div>
 
<div class="content-row overflow-auto">
<div class="four-column-grid">
<div class="text-center">
  <p class="form-label">Subscription Amount</p>
  <h6>${{ Number.parseFloat(stateSubscription.totalSubscription || 0).toFixed(2) }}</h6>  
</div>
<div class="text-center">
  <p class="form-label">Discount</p>
  <h6>${{ Number.parseFloat(stateSubscription.taxes || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Taxes</p>
  <h6>${{ Number.parseFloat(stateSubscription.discount || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Total</p>
  <h6>${{ Number.parseFloat(stateSubscription.total || 0).toFixed(2) }}</h6>  
</div>
</div>  
</div>

</div>
</div>-->

</div>

<div class="removal-popup" v-if="toBeRemovedSubscription">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveSubscriptionRemoval(null)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">Are you sure, you want to delete the subscription?</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveSubscriptionRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveSubscriptionRemoval(null)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>

  <!-- <div class="border-box-content">

  <div class="content-block d-flex justify-content-between">
  <h5 class="fw-semibold">Subscription</h5>
  <div class="icon-group">
  <div class="info-icon lg top-icon"
    @click="() => selectSubscription({...subscription}, 'edit')"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
  >
    <img src="@/assets/images/plus-green-md.png" alt="">
    <div class="edit-helper-tooltip" v-if="helperTooltip">Edit Subscription</div>
  </div>
  </div>
  </div>

  <div class="content-block">
  <div class="payment-details">

  <div class="content-row subscription-grid-wrap">
  <div class="subscription-grid align-items-end">
  <div>
    <div class="checkbox">
      <input type="checkbox" id="trialPlan" :checked="subscription.plans.trial">
      <label for="trialPlan">Free 30 days trial</label>
    </div>
  </div>
  <div><div class="w-80"><p class="form-label-static">Total</p><h6>{{ 1 }}</h6></div></div>
  <div><div class="w-80"><p class="form-label-static">Assigned</p><h6>{{ subscriptionAssigned?.trial ? 1 : 0 }}</h6></div></div>
  <div><div class="w-80"><p class="form-label-static">Available</p><h6>
    {{ subscriptionAssigned?.trial ? 0 : 1 }}
  </h6></div></div>
  </div>
  </div>

  <div class="content-row subscription-grid-wrap">
  <div class="subscription-grid align-items-end">
  <div>
    <div class="checkbox">
      <input type="checkbox" id="basicPlan" :checked="subscription.plans.basic">
      <label for="basicPlan">Basic Plan:</label>
    </div>
  </div>
  <div><div class="w-80"><h6>{{ subscription.plans.basicNumber || 0 }}</h6></div></div>
  <div><div class="w-80"><h6>{{ subscriptionAssigned?.basic ? 1 : 0 }}</h6></div></div>
  <div><div class="w-80"><h6>
    {{ 
      subscription.plans.basicNumber > 0 ? 
        (subscription.plans.basicNumber - (subscriptionAssigned?.basic ? 1 : 0)) : 0 }}
  </h6></div></div>
  <div><div class="w-150"><p class="form-label-static">Billing Type</p><h6>{{ subscription.subscriptionPeriod }}</h6></div></div>
  </div>
  </div>

  <div class="content-row subscription-grid-wrap">
  <h6>Add Modules</h6>
  <div class="subscription-grid">
  <div>
    <div class="checkbox">
      <input type="checkbox" id="plusManagement" :checked="subscription.plans.plus">
      <label for="plusManagement">Basic +</label>
    </div>
  </div>
  <div><div class="w-80"><h6>{{ subscription.plans.plusNumber }}</h6></div></div>
  <div><div class="w-80"><h6>{{ subscriptionAssigned?.plus ? 1 : 0 }}</h6></div></div>
  <div><div class="w-80"><h6>
    {{ 
      subscription.plans.plusNumber > 0 ? 
        (subscription.plans.plusNumber - (subscriptionAssigned?.plus ? 1 : 0)) : 0 
    }}</h6></div></div>
  </div>
  <div class="subscription-grid">
  <div>
    <div class="checkbox">
      <input type="checkbox" id="advanceManagement" :checked="subscription.plans.advance">
      <label for="advanceManagement">Basic + Advance</label>
    </div>
  </div>
  <div><div class="w-80"><h6>{{ subscription.plans.advanceNumber }}</h6></div></div>
  <div><div class="w-80"><h6>{{ subscriptionAssigned?.advance ? 1 : 0 }}</h6></div></div>
  <div><div class="w-80"><h6>
    {{ 
      subscription.plans.advanceNumber > 0 ? 
        (subscription.plans.advanceNumber - (subscriptionAssigned?.advance ? 1 : 0)) : 0 
    }}</h6></div></div>
  </div>
  <div class="subscription-grid">
  <div>
    <div class="checkbox">
      <input type="checkbox" id="ultimate" :checked="subscription.plans.ultimate">
      <label for="ultimate">Basic + ultimate</label>
    </div>
  </div>
  <div><div class="w-80"><h6>{{ subscription.plans.ultimateNumber }}</h6></div></div>
  <div><div class="w-80"><h6>{{ subscriptionAssigned?.ultimate ? 1 : 0 }}</h6></div></div>
  <div><div class="w-80"><h6>
    {{ 
      subscription.plans.ultimateNumber > 0 ? 
        (subscription.plans.ultimateNumber - (subscriptionAssigned?.ultimate ? 1 : 0)) : 0 }}
  </h6></div></div>
  </div>
  </div>

  <div class="content-row">
  <div class="row">
  <div class="col-3">
  <p class="form-label-static">Subscription Amount</p>
  <h6>${{ Number.parseFloat(subscription.totalSubscription || 0).toFixed(2) }}</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">Discount</p>
  <h6>${{ Number.parseFloat(subscription.discount || 0).toFixed(2) }}</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">Taxes</p>
  <h6>${{ Number.parseFloat(subscription.taxes || 0).toFixed(2) }}</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">Total</p>
  <h6>${{ Number.parseFloat(subscription.total || 0).toFixed(2) }}</h6>
  </div>
  </div>
  </div>

  </div>
  </div>

  </div> -->
</template>

<script>
import { inject, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import _ from "lodash";
import subscriptionRates from '@/sharedData/subscriptionRates';

export default {
  name: 'AdminSubscriptionFilled',
  setup() {

    const store = useStore();

    const {
      selectSubscription,
      subscription,
      stateSubscription,
      assigned,
      availablePlans
      // changeSubscriptionField
    } = inject('subscription');

    const {
      mainDetails
    } = inject('mainDetails');

    const subscriptionAssigned = ref({});

    const toBeRemovedSubscription = ref(null);

    const helperTooltip = ref(false);

    const subscriptionTooltip = ref(false);
      
    const switchSubscriptionTooltip = (toggle) => {
      subscriptionTooltip.value = toggle;
    }

    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle
    }

    const editHelperTooltip = ref(false);

    const switchEditHelperTooltip = (toggle) => {
      editHelperTooltip.value = toggle
    }

    const removeSubscription = (subType) => {
      toBeRemovedSubscription.value = subType;
    };

    const approveSubscriptionRemoval = (approval) => {
      if (approval) {
        const toBeRemovedType = `${toBeRemovedSubscription.value}`;
        store.dispatch('removeOneSubscription', toBeRemovedType);
      }
      
      toBeRemovedSubscription.value = null;
    }

    const calculateTotal = (trial) => {
      const months = (stateSubscription.subscriptionPeriod === 'Yearly') ? 12 : 1;
      const basicField = stateSubscription.plans.basicPrice * stateSubscription.plans.basicNumber;
      const plusField = stateSubscription.plans.plusPrice * stateSubscription.plans.plusNumber;
      const advanceField = stateSubscription.plans.advancePrice * stateSubscription.plans.advanceNumber;
      const ultimateField = stateSubscription.plans.ultimatePrice * stateSubscription.plans.ultimateNumber;

      const basicCalc = {
        totalSubscription: 0,
        total: 0,
        taxes: 0
      };

      const plusCalc = {
        totalSubscription: 0,
        total: 0,
        taxes: 0
      };

      const advanceCalc = {
        totalSubscription: 0,
        total: 0,
        taxes: 0
      };

      const ultimateCalc = {
        totalSubscription: 0,
        total: 0,
        taxes: 0
      };

      if (stateSubscription.plans.trial && trial) {
        stateSubscription.totalSubscription = `${0}.00`;
        stateSubscription.total = `${0}.00`;
        stateSubscription.taxes = `${0}.00`;
      } 

      if (
        stateSubscription.plans.basic &&
        (stateSubscription.plans.basicPrice > 0) &&
        (stateSubscription.plans.basicNumber > 0)
      ) {
        // const optionsSum = plusField + basicField + advanceField;
        basicCalc.totalSubscription = Math.abs(
          basicField * months
        ).toFixed(2)
        basicCalc.total = Math.abs(
          ((basicField * months - (Number.parseFloat(stateSubscription.discount) || 0)) / 100) * (100 + 
            Number.parseFloat(subscriptionRates.tax))
        ).toFixed(2)
        basicCalc.taxes = Math.abs(
          ((basicField * months - (Number.parseFloat(stateSubscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)
        ).toFixed(2)
      }

      if (
        stateSubscription.plans.plus &&
        stateSubscription.plans.plusPrice &&
        stateSubscription.plans.plusNumber
      ) {
        plusCalc.totalSubscription = Math.abs(
          plusField * months
        ).toFixed(2)
        plusCalc.total = Math.abs(
          ((plusField * months - (Number.parseFloat(stateSubscription.discount) || 0)) / 100) * (100 + 
            Number.parseFloat(subscriptionRates.tax))
        ).toFixed(2)
        plusCalc.taxes = Math.abs(
          ((plusField * months - (Number.parseFloat(stateSubscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)
        ).toFixed(2)
      }

      if (
        stateSubscription.plans.advance &&
        stateSubscription.plans.advancePrice &&
        stateSubscription.plans.advanceNumber
      ) {
        advanceCalc.totalSubscription = Math.abs(
          advanceField * months
        ).toFixed(2)
        advanceCalc.total = Math.abs(
          ((advanceField * months - (Number.parseFloat(stateSubscription.discount) || 0)) / 100) * (100 + 
            Number.parseFloat(subscriptionRates.tax))
        ).toFixed(2)
        advanceCalc.taxes = Math.abs(
          ((advanceField * months - (Number.parseFloat(stateSubscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)
        ).toFixed(2)
      }

      if (
        stateSubscription.plans.ultimate &&
        stateSubscription.plans.ultimatePrice &&
        stateSubscription.plans.ultimateNumber
      ) {
        ultimateCalc.totalSubscription = Math.abs(
          ultimateField * months
        ).toFixed(2)
        ultimateCalc.total = Math.abs(
          ((ultimateField * months - (Number.parseFloat(stateSubscription.discount) || 0)) / 100) * (100 + 
            Number.parseFloat(subscriptionRates.tax))
        ).toFixed(2)
        ultimateCalc.taxes = Math.abs(
          ((ultimateField * months - (Number.parseFloat(stateSubscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)
        ).toFixed(2)
      }

      stateSubscription.totalSubscription = Math.abs(
        Number.parseFloat(basicCalc.totalSubscription) + 
        Number.parseFloat(plusCalc.totalSubscription) +
        Number.parseFloat(advanceCalc.totalSubscription) + 
        Number.parseFloat(ultimateCalc.totalSubscription)
      ).toFixed(2);

      console.log(
        'calcs',
        Number.parseFloat(basicCalc.totalSubscription), 
        Number.parseFloat(plusCalc.totalSubscription),
        Number.parseFloat(advanceCalc.totalSubscription), 
        Number.parseFloat(ultimateCalc.totalSubscription)
      )

      stateSubscription.discount = (stateSubscription.subscriptionPeriod === 'Yearly') ? `${
        Math.abs(Number.parseFloat(stateSubscription.totalSubscription) / 100) * 10
      }.00` : `${0}.00`

      // subscription.total = Math.abs(
      //   Number.parseFloat(basicCalc.total) + 
      //   Number.parseFloat(plusCalc.total) +
      //   Number.parseFloat(advanceCalc.total) + 
      //   Number.parseFloat(ultimateCalc.total)
      // ).toFixed(2);
      stateSubscription.total = Math.abs(
        (Number.parseFloat((stateSubscription.totalSubscription) - Number.parseFloat(stateSubscription.discount))/100)*Number.parseFloat(106.87)
      ).toFixed(2);

      // subscription.taxes = Math.abs(
      //   Number.parseFloat(basicCalc.taxes) + 
      //   Number.parseFloat(plusCalc.taxes) +
      //   Number.parseFloat(advanceCalc.taxes) + 
      //   Number.parseFloat(ultimateCalc.taxes)
      // ).toFixed(2);
      stateSubscription.taxes = Math.abs(
        (Number.parseFloat((stateSubscription.totalSubscription) - Number.parseFloat(stateSubscription.discount))/100)*Number.parseFloat(6.87)
      ).toFixed(2);

      // stateSubscription.totalSubscription = store.state.primaryUser?.subscription?.totalSubscription;
      // stateSubscription.total = store.state.primaryUser?.subscription?.total
      // stateSubscription.taxes = store.state.primaryUser?.subscription?.taxes
      // stateSubscription.discount = (store.state.primaryUser?.subscription?.subscriptionPeriod === 'Yearly') ? `${
      //   Math.abs(Number.parseFloat(store.state.primaryUser?.subscription?.totalSubscription) / 100) * 10
      // }.00` : `${0}.00`

      console.log(
        stateSubscription.totalSubscription,
        stateSubscription.total,
        stateSubscription.taxes,
        stateSubscription.discount
      )

      store.dispatch('updateSubscriptionTotals', {
        totalSubscription: stateSubscription.totalSubscription,
        total: stateSubscription.total,
        taxes: stateSubscription.taxes,
        discount: stateSubscription.discount
      });

    }

    // watch(() => ({ ...subscription.plans }), () => {
    //   subscription.totalSubscription = '0.00';
    //   subscription.total = '0.00';
    //   subscription.taxes = '0.00';
    //   // subscription.discount = '0.00';
    //   subscription.discount = (subscription.subscriptionPeriod === 'Yearly') ? `${
    //     Math.abs(Number.parseFloat(subscription.totalSubscription) / 100) * 10
    //   }.00` : `${0}.00`
    //   calculateTotal();
    // });
    watch(() => ({...store.state.primaryUser.subscription.plans}), () => {
      calculateTotal();
    })

    watch(() => (mainDetails.id), (newVal) => {
      if (newVal) {
        const currentUser = newVal === 'primary' ?
          store.state.primaryUser :
          store.state.users.find(user => user.mainDetails.id === newVal)

        subscriptionAssigned.value = currentUser?.subscriptionAssigned || {};

        if (currentUser.subscriptionAssigned) {
          for (let opt in assigned) {
            assigned[opt] = false;
          }
          for (let plan in currentUser.subscriptionAssigned) {
            for (let option in assigned) {
              if ((option === plan) && currentUser.subscriptionAssigned[plan]) {
                assigned[option] = true;
              }
            }
          }
        }
      }
    })

    watch(() => _.cloneDeep(subscription), (newVal, oldVal) => {
      console.log(newVal, oldVal, 'just subscription');

      /*
      if ((newVal.plans.basicNumber !== oldVal.plans.basicNumber) ||
        (newVal.plans.plusNumber !== oldVal.plans.plusNumber) ||
        (newVal.plans.advanceNumber !== oldVal.plans.advanceNumber) ||
        (newVal.plans.ultimateNumber !== oldVal.plans.ultimateNumber)) {
          for (let prop in stateSubscription) {
            if (prop === 'plans') {
              for (let plan in stateSubscription.plans) {
                stateSubscription.plans[plan] = newVal?.plans[plan];
              }
            }
            else {
              stateSubscription[prop] = newVal[prop];
              // changeSubscriptionField(newVal[prop], prop);
            }
          }
        }
        */
    })

    onMounted(() => {
      console.log(store.state.primaryUser, 'primary user');
      const currentUser = mainDetails.id === 'primary' ?
        store.state.primaryUser :
        store.state.users.find(user => user.mainDetails.id === mainDetails.id);

      subscriptionAssigned.value = currentUser?.subscriptionAssigned || {};
      
      /*
      if (store.state.primaryUser?.subscription) {
        // for (let prop in subscription) {
        //   if (prop === 'plans') {
        //     for (let plan in subscription.plans) {
        //       subscription.plans[plan] = store.state.primaryUser?.subscription?.plans[plan];
        //     }
        //   }
        //   else {
        //     changeSubscriptionField(store.state.primaryUser?.subscription[prop], prop);
        //   }
        // }
        for (let prop in stateSubscription) {
          if (prop === 'plans') {
            for (let plan in stateSubscription.plans) {
              stateSubscription.plans[plan] = store.state.primaryUser?.subscription?.plans[plan];
            }
          }
          else {
            stateSubscription[prop] = store.state.primaryUser?.subscription[prop];
            // changeSubscriptionField(newVal[prop], prop);
          }
        }
      }
      */

      if (store.state.primaryUser.subscriptionAssigned) {
        for (let plan in store.state.primaryUser.subscriptionAssigned) {
          for (let option in assigned) {
            if ((option === plan) && store.state.primaryUser.subscriptionAssigned[plan]) {
              assigned[option] = true;
              for (let subProp in store.state.primaryUser.subscription.plans) {
                if (subProp.includes(plan) && subProp.includes('Number')) {
                  console.log(store.state.primaryUser.subscriptionAssigned);
                  // subscription.plans[subProp] = subscription.plans[subProp] - 1;
                }
              }
            }
          }
        }
      }

      if (currentUser?.subscriptionAssigned) {
        for (let plan in currentUser.subscriptionAssigned) {
          for (let option in assigned) {
            if ((option === plan) && currentUser.subscriptionAssigned[plan]) {
              assigned[option] = true;
            }
          }
        }
      }

      // subscription.discount = (subscription.subscriptionPeriod === 'Yearly') ? `${
      //   Math.abs(Number.parseFloat(subscription.totalSubscription) / 100) * 10
      // }.00` : `${0}.00`
      
      stateSubscription.discount = (subscription.subscriptionPeriod === 'Yearly') ? `${
        Math.abs(Number.parseFloat(subscription.totalSubscription) / 100) * 10
      }.00` : `${0}.00`
    })

    return {
      subscription,
      stateSubscription,
      selectSubscription,
      subscriptionAssigned,
      helperTooltip,
      switchHelperTooltip,
      assigned,
      editHelperTooltip,
      switchEditHelperTooltip,

      subscriptionTooltip,
      switchSubscriptionTooltip,
      removeSubscription,
      approveSubscriptionRemoval,
      toBeRemovedSubscription,
      availablePlans
    }
  }
}
</script>

<style lang="scss" scoped>
.top-icon,
.edit-subscription {
  position: relative;
}
.edit-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 50px;
  left: -70px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 85px;
    background: #3C3838;
    z-index: -1;
  }
}

.info-icon {
  position: relative;
}
.subscription-info-tooltip {
  position: absolute;
  z-index: 99999;
  bottom: 40px;
  left: -310px;
  width: 490px;
  // display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 11px;
  // color: #fff;
  // background-color: #3C3838;
  font-family: 'Inter', sans-serif;
  // font-weight: 400;
  // padding: 5px;
  color: hsl(var(--white));
  // background: hsl(207, 11%, 41%);
  background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
  font-weight: var(--fw-semibold);
  padding: 0.5rem;
  font-size: 1.4rem;
  font-family: 'Gilroy';
  &::after {
    content: " ";
    position: absolute;
    display: block;
    bottom: -7px;
    // border-color: #3C3838;
    // border-left: 8px solid hsl(207, 11%, 41%);
    // border-bottom: 6px solid hsl(207, 11%, 41%);
    border-left: 8px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    border-bottom: 6px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(190px) rotate(135deg);
    transform: translateX(190px) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 125px;
    // background: #3C3838;
    // background: hsl(207, 11%, 41%);
    background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    z-index: -1;
  }
}
.remove-subscription-icon {
  display: flex;
  cursor: pointer;
  width: max-content;
}
.removal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>