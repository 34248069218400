<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Plan Input: {{ planInput.product }}</h5>
<div class="icon-group">
<!-- <div class="icon green icon-o camera-plus"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
>
  <img src="@/assets/images/camera-plus.svg" alt="">
  <div class="helper-tooltip" v-if="helperTooltip">Take a shot</div>
</div> -->
<div class="icon green icon-o tooltip"
  @click="selectPlanInput(planInput, 'edit');"
  v-if="isAuth('edit-plan-input')"
  data-tooltip="Edit Input"
>
<!--   @mouseover="switchEditHelperTooltip(true)"
  @mouseout="switchEditHelperTooltip(false)" -->
  <img src="@/assets/images/edit-icon.svg" alt="">
  <!-- <div class="edit-helper-tooltip" v-if="editHelperTooltip">Edit Input</div> -->
</div>
</div>
</div>

<div class="content-block">
<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Year</p>
<h6>{{ planInput.year }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Season</p>
<h6>{{ planInput.season }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Item</p>
<h6>{{ planInput.item }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Brand</p>
<h6>{{ planInput.brand }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Variety</p>
<h6>{{ planInput.variety }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">In Stock # of Items</p>
<h6>{{ planInput.inStockNumber }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">In Stock Weight (lbs/item)</p>
<h6>{{ planInput.inStockQuantity }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Required # of Items</p>
<h6>{{ planInput.requiredNumber }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Weight/lbs</p>
<h6>{{ planInput.weightPerItem }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Total Weight (lbs/item)</p>
<h6>{{ planInput.requiredQuantity }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Order # of Items</p>
<h6>{{ planInput.orderNumber }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Order Weight (lbs/item)</p>
<h6>{{ planInput.orderQuantity }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Order Date</p>
<h6>{{ planInput.orderDate }}</h6>
</div>

<!-- <div class="intro-grid-column">
<p class="title">Vendor</p>
<h6>{{ planInput.vendorName }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Vendor Phone</p>
<h6>{{ planInput.vendorPhone }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Vendor Contact</p>
<h6>{{ planInput.vendorContact }}</h6>
</div> -->

<div class="intro-grid-column intro-grid-comment-column">
<p class="title">Comments</p>
<h6>{{ planInput.comments }}</h6>
</div>

</div>
</div>

<div class="content-block">
<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="setNotification" v-model="planInput.setNotifications" disabled checked><label for="setNotification">Set Notification</label></div>
<div class="field-box d-flex">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control sm text-center" v-model="planInput.daysInAdvance" disabled>
</div>
</div>


</div>

<div class="content-block">

  <FileAttachments :key="`vendor-plan-input-filled-${planInput.id}`" v-bind:filled="true" v-bind:section="'planInventory'" v-bind:instance="`${planInput.id}`" />

<!-- 
<div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o">Cancel</button></div>
</div>
</div>
</div> -->

</div>

</div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { inject, ref } from 'vue';

export default {
  name: "PlanInputFilled",
  setup() {
    const { isAuth } = inject('mainUI');
    const { planInput, selectPlanInput } = inject('planInputDetails');

    const editHelperTooltip = ref(false);
    const helperTooltip = ref(false);

    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle;
    }

    const switchEditHelperTooltip = (toggle) => {
      editHelperTooltip.value = toggle;
    }

    return {
      planInput,
      selectPlanInput,
      editHelperTooltip,
      helperTooltip,
      switchHelperTooltip,
      switchEditHelperTooltip,
      isAuth
    }
  },
  components: { FileAttachments }
}
</script>

<style lang="scss" scoped>
.edit-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 40px;
  left: -30px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 41px;
    background: #3C3838;
    z-index: -1;
  }
}
</style>