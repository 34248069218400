<template>
  <!-- <SecondaryProfileHeader /> -->

  <SecondaryProfileDetailsForm v-if="mainDetails.mode === 'edit'" />
  <!--  && isAuth('edit-details') -->
  <SecondaryProfileDetails v-else/>
      
  <div ref="assignSubscriptionForm"></div>
  <!-- <SecondaryProfileSubscription /> -->
  <section class="section" v-if="!secondaryAuthentication">
    <div class="border-box">
       <!-- <SecondaryProfileSubscriptionNewForm v-if="subscriptionMode === 'create' && isAuth('edit-subscription')" /> -->
      <SecondaryProfileSubscriptionForm v-if="subscriptionMode === 'edit'" />
      <!--  && isAuth('edit-subscription') -->
      <SecondaryProfileSubscription v-if="(subscriptionMode === 'represent') || (subscriptionMode === '')" /> 
      <!-- <SecondaryProfileSubscriptionList /> -->
    </div>
  </section>

  <div v-if="submittedUserProfile && popupMessages.purchase.active">
    <UserNotification
      v-bind:messageText="popupMessages.purchase.message"
    />
  </div>

  <div v-if="popupMessages.subscription.active">
    <UserNotification
      v-bind:hideDetails="true"
      v-bind:messageText="popupMessages.subscription.message"
    />
  </div>

  <div v-if="popupMessages.assigned.active">
    <UserNotification
      v-bind:messageText="popupMessages.assigned.message"
    />
  </div>

  <div v-if="popupMessages.noPassword.active">
    <UserNotification
      v-bind:messageText="popupMessages.noPassword.message"
    />
  </div>

  <div v-if="popupMessages.logBack.active">
    <UserNotification
      v-bind:messageText="popupMessages.logBack.message"
    />
  </div>

    <div class="add-new-popup" v-if="popupMessages.addNew">
      
      <div class="modal modal-md">
      <span class="modal-close" @click="refreshPage"><img src="@/assets/images/close-green-xs.png" alt=""></span>
      <div class="modal-block text-center">
      <p class="fw-bold">Add another user or create farm profile</p>
      </div>
      <div class="modal-block text-center">
      <div class="buttons justify-content-center">
          <button
            @click="() => createNewUser()"
            class="approve-button btn green" 
          >Add user</button>
          <button
            @click="() => createNewFarm()" 
            class="cancel-removal-button btn green btn-o" 
          >Add farm</button>
      </div>
      </div>
      </div>

      <!-- <div class="popup-message">
        <div class="close-button" @click="refreshPage" ></div>
        <span class="popup-text">Add another user or create farm profile</span>
        <div class="popup-buttons">
          <button class="btn green" @click="() => createNewUser()">Add user</button>
          <button class="btn green" @click="() => createNewFarm()">Add farm</button>
        </div>
      </div> -->

    </div>

    <div class="add-new-popup" v-if="submittedUserProfile && popupMessages.userAdded.active">
      
      <div class="modal modal-md">
      <span class="modal-close" @click="() => {
          popupMessages.userAdded.active = false;
        }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
      <div class="modal-block text-center">
      <p class="fw-bold">{{ popupMessages.userAdded.message }}</p>
      </div>
      <div class="modal-block text-center">
      <div class="buttons justify-content-center">
          <button
            @click="() => {
              createNewUser();
              popupMessages.userAdded.active = false;
            }"
            class="approve-button btn green" 
          >Yes</button>
          <button
            @click="() => {
              if (farms.length === 0) {
                popupMessages.addFarm.active = true;
              } else {
                navigateToUsersList();
              }
              popupMessages.userAdded.active = false;
            }"
            class="cancel-removal-button btn green btn-o" 
          >No</button>
      </div>
      </div>
      </div>

    </div>

    <div class="add-new-popup" v-if="submittedUserProfile && popupMessages.addFarm.active">
      
      <div class="modal modal-md">
      <span class="modal-close" @click="() => {
          popupMessages.addFarm.active = false;
        }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
      <div class="modal-block text-center">
      <p class="fw-bold">{{ popupMessages.addFarm.message }}</p>
      </div>
      <div class="modal-block text-center">
      <div class="buttons justify-content-center">
          <button
            @click="() => {
              createNewFarm();
              popupMessages.addFarm.active = false;
            }"
            class="approve-button btn green" 
          >Yes</button>
          <button
            @click="() => {
              popupMessages.addFarm.active = false;
              navigateToUsersList();
            }" 
            class="cancel-removal-button btn green btn-o" 
          >No</button>
      </div>
      </div>
      </div>

    </div>
</template>

<script>
import { ref, reactive, provide, onMounted, watchEffect, watch, inject, computed } from "vue";
import { useStore } from "vuex";
import SecondaryProfileDetails from "./SecondaryProfileDetails.vue";
import SecondaryProfileDetailsForm from "./SecondaryProfileDetailsForm.vue";
import SecondaryProfileSubscription from "./SecondaryProfileSubscription.vue";
import SecondaryProfileSubscriptionForm from "./SecondaryProfileSubscriptionForm.vue";
import UserNotification from "@/components/Shared/UserNotification.vue";
// import SecondaryProfileSubscriptionNewForm from "./SecondaryProfileSubscriptionNewForm.vue";
// import SecondaryProfileHeader from "./SecondaryProfileHeader.vue";
// import SecondaryProfileSubscriptionList from "./SecondaryProfileSubscriptionList.vue";
import { useRouter } from "vue-router";

export default {
  name: "SecondaryUser",
  components: {
    SecondaryProfileDetails,
    SecondaryProfileDetailsForm,
    SecondaryProfileSubscription,
    SecondaryProfileSubscriptionForm,
    UserNotification,
    // SecondaryProfileSubscriptionNewForm,
    // SecondaryProfileHeader,
    // SecondaryProfileSubscriptionList
},
  setup() {
    const store = useStore();
    const router = useRouter();
    const { isAuth } = inject('mainUI');

    const submittedUserProfile = ref(false),
      popupMessages = reactive({
        purchase: {
          active: false,
          message: 'Your account has been activated, please login again'
        },
        addNew: false,
        subscription: {
          active: false,
          message: 'Please choose a subscription type!'
        },
        assigned: {
          active: false,
          message: 'Your subscription has been assigned to the account'
        },
        noPassword: {
          active: false,
          // message: 'Please add your password to activate your account'
          message: 'Please enter your password and click save'
        },
        userAdded: {
          active: false,
          message: 'A new user has been added to the account. Do you want to add another user?'
        },
        addFarm: {
          active: false,
          message: 'Do you want to add a Farm?'
        },
        logBack: {
          active: false,
          message: 'Please log back to validate your account.'
        }
      }),
      purchasedSubscription = ref(false);

    const mainDetails = reactive({
      id: '',
      accountName: '',
      email: '',
      title: '',
      password: '',
      // email: '',
      userRole: '',
      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      mobilePhone: '',
      workPhone: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      employeeType: '',
      grade: '',
      multifactor: false,

      submitted: false,
      activated: false,
      mode: store.state.secondaryUser?.mainDetails?.submitted ? 'represent' : 'edit'
    });

    const defaultMainDetails = {
      ...mainDetails
    }

    // const sameAddress = ref(
    //   store.state.primaryUser.mainDetails?.sameAddress ? 
    //     computed(() => store.state.primaryUser.mainDetails?.sameAddress) :
    //     false
    // );

    const subscription = reactive({
      plans: {
        trial: true,
        trialPrice: 'price_1O5VljHpD3HYJIrgI5y2Jhkq',
          
        basic: false,
        basicPrice: '',
        basicNumber: '',
        basicPriceIdYearly: 'price_1NXMRQHpD3HYJIrg2IwC8sri',
        basicPriceIdMonthly: 'price_1NXMNxHpD3HYJIrg0hyDtIQO',
        basicAvailable: '',

        // inventory: false,
        // inventoryPrice: '',
        // inventoryNumber: '',
        plus: false,
        plusPrice: '',
        plusNumber: '',
        plusPriceIdYearly: 'price_1NXMQoHpD3HYJIrgJaWbqhOI',
        plusPriceIdMonthly: 'price_1NXMQoHpD3HYJIrgnhtzXnIF',
        plusAvailable: '',
        
        // equipment: false,
        // equipmentPrice: '',
        // equipmentNumber: '',

        advance: false,
        advancePrice: '',
        advanceNumber: '',
        advancePriceIdYearly: 'price_1NXMhHHpD3HYJIrgUwLCM3C2',
        advancePriceIdMonthly: 'price_1NXMhHHpD3HYJIrgrxWzr1UH',
        advanceAvailable: '',
        
        // allinclusive: false,
        // allinclusivePrice: '',
        // allinclusiveNumber: '',

        ultimate: false,
        ultimatePrice: '',
        ultimateNumber: '',
        ultimatePriceIdYearly: 'price_1NXMklHpD3HYJIrgUKaTXGU9',
        ultimatePriceIdMonthly: 'price_1NXMklHpD3HYJIrgbrsDHSiM',
        ultimateAvailable: '',
      },
      
      subscriptionPeriod: 'Monthly',
      
      totalSubscription: '',
      taxes: '',
      discount: '',
      total: '',

      planPurchasingDate: store.state.authenticated.registrationDate,
      subscriptionsNumber: 0, // remove later
      mode: 'represent'
    });

    const subscriptionMode = ref('represent');

    const stateSubscription = reactive({
      ...store.state.primaryUser.subscription,
      plans: {
        ...store.state.primaryUser?.subscription?.plans,
        trial: true
      }
    })

    const defaultSubscription = {
      ...subscription,
      plans: {
        ...subscription.plans
      },
      mode: 'edit'
    }

    const assigned = reactive({
      trial: false,
      basic: false,
      plus: false,
      advance: false,
      ultimate: false
    });

    const assignSubscriptionForm = ref(null);

    const users = computed(() => store.state.users);

    const farms = computed(() => store.state.farms);

    const editedByAdmin = ref(true);

    const companiesList = reactive({
      value: []
    });

    const secondaryAuthentication = ref(false);

    const changeMainField = (val, field) => {
      mainDetails[field] = val;
    }

    const changeSubscriptionField = (val, field, index) => {
      if (index) {
        subscription.modules[index].added = val;
      } else {
        subscription[field] = val;
      }
    }

    const addSubscription = () => {
      // s+ click
      // default subscription form, if billing and payment then auto fill if not then blank forms
    }

    const refreshPage = () => {
      popupMessages.addNew = false;

      router.push({ name: 'admin', params: { pagename: 'admin-users' } });
      setTimeout(() => {
        router.push({name: 'page', params: {pagename: 'secondary-user'}})
      })
    }

    const createNewFarm = () => {
      router.push({ name: 'farm', params: { pagename: 'farm-profile' } })
    }

    const createNewUser = () => {
      const routeSource = `${router.currentRoute.value.params.source}`;
      router.push({ name: 'admin', params: { pagename: 'admin-users' } });
      setTimeout(() => {
        router.push({name: 'page', params: {pagename: 'secondary-user', source: routeSource, parentSource: routeSource }})
      })
    }

    const navigateToUsersList = () => {
      router.push({ name: 'admin', params: { pagename: 'admin-users', source: 'secondary-user' } });
    }

    provide('mainDetails', {
      mainDetails,
      changeMainField,
      createNewUser,
      editedByAdmin,
      options: companiesList
    });

    provide('subscription', {
      assigned,
      subscription,
      subscriptionMode,
      stateSubscription,
      defaultSubscription,
      purchasedSubscription,
      changeSubscriptionField
    })

    watch(() => ({...store.state.secondaryUser.subscriptionAssigned}), (newVal) => {
      console.log(newVal, 'assigned');
    })

    watch(() => ({...store.state.secondaryUser}), (newVal) => {
      if (newVal) {
        const params = new URLSearchParams(document.location.search);

        const registration = params.get('registration');

        if (registration === '1') {
          const baseRoute = router.currentRoute.value.fullPath.split('&registration=1')[0];
          setTimeout(() => {
            router.push(`${baseRoute}&registration=2`);
            setTimeout(() => {
              router.go();
            });
          });
        }
      }
    });

    watchEffect(() => {
      const allFormsSubmitted = 
        (mainDetails.submitted  || store.state.secondaryUser?.mainDetails?.submitted) && 
        (store.state.secondaryUser.subscriptionAssigned.trial || 
        store.state.secondaryUser.subscriptionAssigned.basic || 
        store.state.secondaryUser.subscriptionAssigned.plus ||
        store.state.secondaryUser.subscriptionAssigned.advance ||
        store.state.secondaryUser.subscriptionAssigned.ultimate
        );
      const params = new URLSearchParams(document.location.search);
      const user_id = params.get("user_id");
      const token = params.get("token");
      const isRegistration = params.get("registration");

      if (mainDetails.submitted && (!user_id && !token)) {
        popupMessages.subscription.active = true;

        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: assignSubscriptionForm?.value?.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        })
        // if (!subscription.submitted) {
        //   // popupMessages.subscription.active = true
        //   if (stateSubscription.plans.basic || stateSubscription.plans.allinclusive) {
        //     changeSubscriptionField('edit', 'mode')
        //   } else {
        //     changeSubscriptionField('create', 'mode')
        //   }
        // }
      }

      // if (subscription.submitted) {
      //   if (!store.state.secondaryUser?.payment?.submitted) {
      //     // popupMessages.payment.active = true
      //     changeSubscriptionField('represent', 'mode')
      //   }
      // }

      if (allFormsSubmitted) {
        console.log(store.state.secondaryUser.subscriptionAssigned, 'allFormsSubmitted')
        submittedUserProfile.value = true;
        if (!router.currentRoute.value.params.source && !isRegistration) {
          console.log('!router.currentRoute.value.params.source');
          popupMessages.purchase.active = true;
        }
        if (
          router.currentRoute.value.params.source === 'admin-users' ||
          router.currentRoute.value.params.source === 'primary-user' ||
          router.currentRoute.value.params.source === 'user-profile' ||
          router.currentRoute.value.params.source === 'admin-module' ||
          router.currentRoute.value.params.source === 'admin-account' ||
          (router.currentRoute.value.params.source === 'admin-subscription' &&
            router.currentRoute.value.params.parentSource
          ) || (router.currentRoute.value.params.source === 'employee-compensation' &&
            router.currentRoute.value.params.parentSource
          )
        ) {
          console.log(`router.currentRoute.value.params.source: ${router.currentRoute.value.params.source} parentSource: ${router.currentRoute.value.params.parentSource}`);
          popupMessages.userAdded.active = true;
        }
      }
      
      console.log(users.value, 'users-list', store.state.secondaryUser, 'secondary-user');

      if (mainDetails.submitted && subscription.submitted) {
        // if (router.currentRoute.value.params.source !== 'admin-subscription') {}
        let assignmentAccomplished = false;
        const currentUser = users.value.find(usr => usr.mainDetails.id === mainDetails.id);

        console.log(currentUser, 'currentUser');

          for (let prop in currentUser.subscriptionAssigned) {
            if (currentUser.subscriptionAssigned[prop]) {
              assignmentAccomplished = true;
            }
          }

          if (assignmentAccomplished) {
            console.log('assignmentAccomplished');
            popupMessages.assigned.active = true;

            setTimeout(() => {
              popupMessages.assigned.active = false;

                popupMessages.addNew = true;
                
                store.dispatch("saveSecondaryUser", { section: "mainDetails", data: {
                  ...defaultMainDetails
                } });

                store.dispatch("saveSecondaryUser", { section: "subscription", data: {
                  ...defaultSubscription
                } });

                store.dispatch("saveSecondaryUser", { section: "payment", data: {} });

                store.dispatch("saveSecondaryUser", { section: "subscriptionAssigned", data: {} });

                if (router.currentRoute.value.params.source !== 'admin-users' &&
                  router.currentRoute.value.params.source !== 'primary-user' &&
                  router.currentRoute.value.params.source !== 'user-profile' &&
                  router.currentRoute.value.params.source !== 'admin-module' &&
                  router.currentRoute.value.params.source !== 'admin-account' &&
                  !router.currentRoute.value.params.parentSource) {
                    popupMessages.logBack.active = true;

                    setTimeout(() => {
                      popupMessages.logBack.active = false;
                    }, 5100)
                }

            }, 5000)
          } else {
            console.log(store.state.secondaryUser.subscriptionAssigned);
            popupMessages.subscription.active = true;

            setTimeout(() => {
              popupMessages.subscription.active = false;
            }, 5000)
          }
      }
    })

    onMounted(() => {

      const params = new URLSearchParams(document.location.search);
      const user_id = params.get("user_id");
      const token = params.get("token");
      const resetPassword = params.get("resetpassword");

      if (token && user_id) {
        secondaryAuthentication.value = true;
        store.dispatch('authenticateUser', {
          user_id,
          token,
          secondary: true
        })
      }

      console.log(
        store.state.secondaryUser.subscription,
        store.state.secondaryUser.subscriptionAssigned, 
        'sec-us-sub'
      );

      for (let prop in store.state.primaryUser.mainDetails) {
        if ((prop !== 'firstName') && (prop !== 'lastName') && (prop !== 'userRole') && 
          (prop !== 'title') && (prop !== 'mobilePhone') && (prop !== 'email') && (prop !== 'password') && 
          (prop !== 'submitted') && (prop !== 'employeeType') && (prop !== 'grade')) {
          mainDetails[prop] = store.state.primaryUser.mainDetails[prop];
        }
      }

      // if (stateSubscription?.plans?.allinclusive || stateSubscription?.plans?.basic) {
      //   for (let prop in stateSubscription) {
  
      //     if (prop === 'plans') {
      //       for (let plan in stateSubscription?.plans) {
      //         subscription.plans[plan] = stateSubscription.plans[plan];
      //         if (plan.includes('Number') && (subscription.plans[plan] > 0)) {
      //           stateSubscription.plans[plan] = subscription.plans[plan] -1;
      //           subscription.plans[plan] = subscription.plans[plan] -1;
      //         }
      //       }
      //     } else {
      //       subscription[prop] = stateSubscription[prop];
      //     }
      //     // changeSubscriptionField(stateSubscription[prop], prop);
      //   }
      // }

      if (subscription.plans.trial) {
        // catch payment, billing or prompt to fill payment billing
      }

      // if (subscription.plans.basic || subscription.plans.allinclusive) {
      //   // show subscriptions Number of all available subscriptions
      //   // including trial on top, all with checkers
      //   changeSubscriptionField(true, 'submitted');
      //   store.dispatch('saveSecondaryUser', {section: 'subscription', data: subscription});
      // }

      // subscriptionMode = 'represent';

      if (router.currentRoute.value.params.source === 'employee-compensation') {
        mainDetails.mode = 'edit';
        mainDetails.submitted = false;

        for (let prop in mainDetails) {
          // mainDetails[prop] = store.state.temporaryUser.mainDetails[prop];
          mainDetails[prop] = store.state.secondaryUser.mainDetails[prop];
        }
      }

      if (resetPassword) {
        router.push({ name: 'page', params: { pagename: 'dashboard' } })
      }

      if (
        router.currentRoute.value.params.source !== 'admin-users' &&
        router.currentRoute.value.params.source !== 'primary-user' &&
        router.currentRoute.value.params.source !== 'user-profile' &&
        router.currentRoute.value.params.source !== 'admin-module' &&
        router.currentRoute.value.params.source !== 'admin-account' &&
        !router.currentRoute.value.params.parentSource
      ) {
        console.log('editedByAdmin.value = false');
        editedByAdmin.value = false;
        if (!mainDetails.password && !(store.state.authenticated._id && !store.state.primaryUser.mainDetails)) {//!store.state.authenticated.secondary
          popupMessages.noPassword.active = true;
          setTimeout(() => {
            popupMessages.noPassword.active = false;
          }, 5000);
        }
      }
      
    })

    return {

      mainDetails,
      subscription,
      subscriptionMode,

      submittedUserProfile,
      popupMessages,

      addSubscription,
      isAuth,

      assignSubscriptionForm,
      createNewUser,
      refreshPage,
      createNewFarm,
      editedByAdmin,
      farms,
      navigateToUsersList,
      secondaryAuthentication
    };
  },
};
</script>

<style lang="scss" scoped>
.add-new-popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    .popup-message {
      background: #fff;
      display: flex;
      flex-direction: column;
      max-width: 650px;
      width: 100%;
      height: max-content;
      justify-content: space-around;
      align-items: center;
      position: relative;
      border-radius: 11px;
      color: rgba(40,41,43,1);
      font-size: 21px;
      margin: 0 20px;
      padding: 25px 0;
      text-align: center;
      .close-button {
        position: absolute;
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: auto;
        width: 30px;
        height: 30px;
        background-image: url('@/assets/profile/close_button.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        right: 10px;
        top: 22px;
        cursor: pointer;
      }
      .popup-text {
        margin: 0 40px;
        font-size: 16px;
        font-weight: 600;
      }
      .popup-buttons {
        display: flex;
        width: 60%;
        justify-content: space-evenly;
        margin-top: 30px;
        button {
          width: 160px;
          height: 40px;
          border-radius: 10px;
          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }

.secondary-user-profile {
  form {
    flex-direction: column;
    flex-wrap: nowrap;
    .add-details {
      width: max-content;
      align-self: end;
      margin-bottom: 24px;
    }
    .form-actions {
      margin-top: 24px;
      button {
        margin-right: 24px;
      }
    }
  }
  .q-expansion-item--popup.q-expansion-item--expanded,
  .q-expansion-item--popup.q-expansion-item--collapsed {
    padding: 0;
  }

  .q-expansion-item {
    margin-bottom: 45px;
  }
}
</style>
