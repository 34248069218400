<template>
  <section class="section" ref="section">
<div class="border-box active">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Task List</h5>
<div class="info-icons">
<!-- <div class="info-icon lg header-icon"
  @click="addTask"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
>
  <img src="@/assets/images/plus-green-md.png" alt="">
  <div class="helper-tooltip" v-if="helperTooltip">Add Task</div>
</div> -->
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-container">
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label justify-content-start">
  <span>Date</span>
  <span @click="() => sortTable('date')">
    <button :class="{active: sortOrder.date === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.date === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Farm</span>
  <span @click="() => sortTable('farm')">
    <button :class="{active: sortOrder.farm === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.farm === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Bed No.</span>
  <span @click="() => sortTable('bed')">
    <button :class="{active: sortOrder.bed === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.bed === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
  </p></th>
<th><p class="form-label-static form-sort-label">
  <span>Task Type</span>
  <span @click="() => sortTable('task')">
    <button :class="{active: sortOrder.task === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.task === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
  </p></th>
<th><p class="form-label-static form-sort-label">
  <span>Start Time</span>
  <span @click="() => sortTable('startTime')">
    <button :class="{active: sortOrder.startTime === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.startTime === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
  </p></th>
<th><p class="form-label-static form-sort-label">
  <span>Assigned To</span>
  <span @click="() => sortTable('assignedTo')">
    <button :class="{active: sortOrder.assignedTo === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.assignedTo === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
  </p></th>
<th><p class="form-label-static form-sort-label">
  <span>Status</span>
  <span @click="() => sortTable('taskStatus')">
    <button :class="{active: sortOrder.taskStatus === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.taskStatus === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

      <template
        v-for="(row, rowIndex) in rows"
        v-bind:key="'farm-list-row' + row.id"
      >
        <tr
          v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
          :class="{'light-gray-bg': row.id === selectedTask.id}"
          @click="selectTask({...row}, 'represent')"
        >
        <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
        <td>{{row.date}}</td>
        <td>{{row.farm}}</td>
        <td>{{row.bed}}</td>
        <td>
          <!-- <a href="javascript:void();" @click="selectTask({...row}, 'represent')"> -->
            {{ row.task }}
          <!-- </a> -->
        </td>
        <td>{{row.startTime}}</td>
        <td>{{ row.assignedTo }}</td>
        <td>
        <div class="table-select">
        <select v-model="row.taskStatus" disabled>
          <option>Completed</option>
        </select>
        </div>
        </td>
        <td class="w-75">
        <div class="table-links">
        <!-- <a href="javascript:void();" @click="selectTask({...row}, 'edit')">
          <img src="@/assets/images/edit-icon-2.svg" alt="">
        </a>
        <a href="javascript:void();" @click="removeTask(row.id)">
          <img src="@/assets/images/trash-2.svg" alt="">
        </a> -->
        </div>
        </td>
        </tr>
      </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div class="records-per-page">
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'completedTaskListDetails'"
    :optionKey="'completed-task-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>

</div>
</section><!--//End Section-->

</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { ref, onMounted, watch, computed, inject, reactive, provide } from 'vue';
import { useStore } from 'vuex';


export default {
    name: "CompletedTasksList",
    props: ['parentModule'],
    setup() {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const helperTooltip = ref(false);
        const tasks = computed(() => store.state.tasks);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const { selectedTask, addTask, selectTask } = inject('taskDetails');
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        // const changeTaskStatus = (ev, id) => {
        //   store.dispatch('editTask', {
        //     id,
        //     taskStatus: ev.target.value
        //   })
        // }
        const sortOrder = reactive({
            date: 0,
            farm: 0,
            bed: 0,
            task: 0,
            startTime: 0,
            assignedTo: 0,
            taskStatus: 0
        });
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...tasks.value];
                sortOrder[sortProp] = 0;
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(store.state.tasks, () => {
            console.log(tasks.value, 'tasks');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...tasks.value].filter((task) => task.taskStatus === 'Completed');
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
            // rows.value.forEach(row => {
            //   row.taskIsSelected = row.id === selectedTask.id
            // })
        });
        // watch(() => ({...selectedTask.id}), () => {
        //   rows.value.forEach(row => {
        //     row.taskIsSelected = row.id === selectedTask.id
        //   })
        // })
        onMounted(() => {
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getTasks')
            rows.value = [...tasks.value].filter((task) => task.taskStatus === 'Completed');
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
            // tasks.value.forEach((tsk) => {
            //   if (!tsk.taskStatus || 
            //     ((tsk.taskStatus !== 'Pending') && 
            //     (tsk.taskStatus !== 'Completed') && 
            //     (tsk.taskStatus !== 'Reassigned'))
            //     ) {
            //     store.dispatch('editTask', {
            //       id: tsk.id,
            //       taskStatus: 'Pending'
            //     })
            //   }
            // })
        });
        provide('completedTaskListDetails', {
          maxPage,
          currentPage,
          options
        })
        return {
            section,
            boxHeader,
            toggleSection,
            addTask,
            selectTask,
            rows,
            maxPage,
            currentPage,
            helperTooltip,
            switchHelperTooltip,
            sortTable,
            selectedTask,
            sortOrder
        };
    },
    components: { DropDownList }
}
</script>

<style scoped lang="scss">
.header-icon {
  position: relative;
}
.form-sort-label {
  button {
    opacity: 0.5;
    &.active {
      opacity: 1;
    }
  }
}

.table-container {
  overflow-x: scroll;
}

@media screen and (max-width: 900px) {
  .table-container {
    overflow-x: scroll;
  }
}
</style>