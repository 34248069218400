<template>

<section class="section mt-1">
  <div class="border-box">
  <div class="border-box-content">

  <div class="content-block d-flex justify-content-between user-profile-header">
  <h5 v-if="editedByUser" class="fw-semibold">{{ `${mainDetails.firstName} ${mainDetails.lastName}` }}</h5>
  <h5 v-else class="fw-semibold">Add User</h5>
  <div class="icon-group">
    <span class="account-id-header filled">Account ID: {{ authenticatedId }}</span>
  </div>
  </div>

  <div class="content-block">
  <div class="admin-update-form">
  <div class="row justify-content-end">

  <div class="col-auto">
  <div class="form-item">
  <p class="form-label-static">Email</p>
  <input 
    type="text" 
    class="form-control" 
    v-model="mainDetails.email"
    @focus="() => {
      activated.email = true;
      focused.email = true;
    }"
    @blur="() => {
      focused.email = false;
    }"  
    :disabled="!updateIsActive && !editedByAdmin"
  >
  <p v-if="activated.email && mainDetails.email.length === 0" class="form-info red text-end" ref="errorOne">* Required</p>
  <p 
    v-if="activated.email && !focused.email && ((mainDetails.email.length > 0) && !/\S+@\S+\.\S+/.test(mainDetails.email))" 
    class="form-info red text-end" ref="errorOne"
    :style="{left: (activated.password && !focused.password && ((mainDetails.password.length > 0) && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._()-^])[A-Za-z\d@$!%*?&._()-^]{8,}$/.test(mainDetails.password))) ? '-40%' : ''}"
  >Please use correct email format</p>
  </div>
  </div>

  <div class="col-auto">
  <div class="form-item password-field">
  <p class="form-label-static">Password <span v-if="passwordPrompt.active" class="password-prompt-tooltip">{{ passwordPrompt.message }}</span></p>
  <input 
    :type="passwordIsVisible ? 'text' : 'password'"
    class="form-control" 
    v-model="mainDetails.password"
    @focus="() => {
      activated.password = true;
      focused.password = true;
      passwordPrompt.active = false;
    }"
    @blur="() => {
      focused.password = false;
    }"
    :disabled="!updateIsActive || editedByAdmin"
    :placeholder="passwordMask"
  >
  <div v-if="updateIsActive" class="password-icon" :class="{visible: passwordIsVisible, hidden: !passwordIsVisible}" @click="() => {passwordIsVisible = !passwordIsVisible}"></div>
  <p v-if="activated.password && mainDetails.password.length === 0" class="form-info red text-end" ref="errorTwo">* Required</p>
  <p v-if="updateIsActive && registration && !activated.password" class="form-info red text-end" ref="errorTwo">*Re-enter Password</p>
  <p 
    v-if="updateIsActive && activated.password && !focused.password && ((mainDetails.password.length > 0) && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._()-^])[A-Za-z\d@$!%*?&._()-^]{8,}$/.test(mainDetails.password))" 
    class="form-info red text-end" ref="errorTwo" style="width: 180%; left: -80%;"
    :style="{left: (activated.email && !focused.email && ((mainDetails.email.length > 0) && !/\S+@\S+\.\S+/.test(mainDetails.email))) ? '-40%' : '-80%'}"
  >Please use min 8 symbols at least 1 upper case, 1 lower case, 1 number, 1 special character</p>

  </div>
  </div>

  <div class="col-auto update-btn-sm-wrap" v-if="!editedByAdmin">
    <button v-if="updateIsActive" type="submit" class="btn green update-btn-sm" @click="() => switchUpdatable(false)">Save</button>
    <button v-else type="submit" class="btn green update-btn-sm" @click="() => switchUpdatable(true)">Update</button>
  </div>

  </div>
  </div>
  </div>

  <div class="content-block toggle-switch-wrap justify-content-end mr-170">

  <div class="toggle-switch">
    <label class="switch">
      <input type="checkbox" v-model="mainDetails.multifactor">
      <span class="slider round"></span>
    </label>
    <p class="fw-semibold gray">Multi Factor Authentication</p>
  </div>

  <div class="checkbox-group checkbox-multifactor">
    <div class="radio radio-mobile">
      <input 
        type="radio" 
        name="mobileEmail" 
        id="mobile" 
        value="mobile"
        v-model="mainDetails.multifactorType"
        :disabled="!mainDetails.multifactor"
      >
      <label for="mobile">Mobile</label></div>
    <div class="radio">
      <input 
        type="radio" 
        name="mobileEmail" 
        id="email" 
        value="email"
        v-model="mainDetails.multifactorType"
        :disabled="!mainDetails.multifactor"
      >
      <label for="email">Email</label></div>
  </div>

  </div>

  <div class="content-block">
    <div class="content-row">
      <div class="content-row grid-4 grid-full">
        <div class="row">

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.accountName || (mainDetails.accountName.length > 0)}">Account Name</p>
          <!-- <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.accountName"
            @focus="() => {
              activated.accountName = true;
              focused.accountName = true;
            }"
            @blur="() => {
              focused.accountName = false;
            }"
          > -->
          <DropDownList
            :mainObject="'mainDetails'"
            :mainProperty="'accountName'"
            :injectionName="'mainDetails'"
            :optionKey="'company-name-option-secondary'"
            :optionsList="[]"
            :optionProperty="null"
            :optionPropertyTwo="null"
            :defaultSelected="mainDetails.accountName"
            @click="() => {
              activated.accountName = true;
            }"
          />
          <p v-if="activated.accountName && mainDetails.accountName.length > 75" class="form-info red text-end" ref="errorThree">Please use maximum 75 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.firstName || (mainDetails.firstName.length > 0)}">First Name</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.firstName"
            @focus="() => {
              activated.firstName = true;
              focused.firstName = true;
            }"
            @blur="() => {
              focused.firstName = false;
            }"
            placeholder="E.g., Robert"
          >
          <p v-if="activated.firstName && mainDetails.firstName.length === 0" class="form-info red text-end" ref="errorFour">* Required</p>
          <p v-if="activated.firstName && mainDetails.firstName.length > 50" class="form-info red text-end" ref="errorFour">Please use maximum 50 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.lastName || (mainDetails.lastName.length > 0)}">Last Name</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.lastName"
            @focus="() => {
              activated.lastName = true;
              focused.lastName = true;
            }"
            @blur="() => {
              focused.lastName = false;
            }"
            placeholder="E.g., Tendall"
          >
          <p v-if="activated.lastName && mainDetails.lastName.length === 0" class="form-info red text-end" ref="errorFive">* Required</p>
          <p v-if="activated.lastName && mainDetails.lastName.length > 50" class="form-info red text-end" ref="errorFive">Please use maximum 50 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.title || (mainDetails.title.length > 0)}">Title</p>
          
          <template v-if="compensations.length > 0">
            <DropDownList
              :mainObject="'mainDetails'"
              :mainProperty="'title'"
              :injectionName="'mainDetails'"
              :optionKey="'compensation-title-option-secondary'"
              :optionsList="[...compensations, {employeeTitle: 'Other'}]"
              :optionProperty="'employeeTitle'"
              :optionPropertyOne="['grade', 'ownershipType']"
              :optionPropertyTwo="null"
              :defaultSelected="mainDetails.title"
              @click="() => {
                activated.title = true;
              }"
            />
          </template>

          <input 
            v-else
            type="text" 
            class="form-control" 
            v-model="mainDetails.title"
            @focus="() => {
              activated.title = true;
              focused.title = true;
              gradeFormDialog = true;
            }"
            @blur="() => {
              focused.title = false;
            }"
          >
          <p v-if="activated.title && mainDetails.title.length > 25" class="form-info red text-end" ref="errorSix">Please use maximum 25 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.mobilePhone || (mainDetails.mobilePhone.length > 0)}">Mobile Phone</p>
          <input 
            type="text" 
            class="form-control" 
            ref="mobileField"
            pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
            v-model="mainDetails.mobilePhone"
            @focus="() => {
              activated.mobilePhone = true;
              focused.mobilePhone = true;
            }"
            @blur="() => {
              phoneNumberFormat('mobilePhone')
              focused.mobilePhone = false;
            }"
            placeholder="E.g., (546) 985-6935"
          >
          <p v-if="activated.mobilePhone && mainDetails.mobilePhone.length === 0" class="form-info red text-end" ref="errorSeven">* Required</p>
          <p v-if="activated.mobilePhone && !focused.mobilePhone && (((completedFields.mobilePhone && (mainDetails.mobilePhone.length !== 10) || (mainDetails.mobilePhone.length > 10))) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(mainDetails.mobilePhone))" class="form-info red text-end" ref="errorSeven">Please use 10 Digit for phone</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.workPhone || (mainDetails.workPhone.length > 0)}">Work Phone</p>
          <input 
            type="text" 
            class="form-control" 
            pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
            v-model="mainDetails.workPhone"
            @focus="() => {
              activated.workPhone = true;
              focused.workPhone = true;
            }"
            @blur="() => {
              phoneNumberFormat('workPhone')
              focused.workPhone = false;
            }"
            placeholder="E.g., (952) 854-5866"
          >
          <!-- :disabled="!editedByAdmin" -->
          <p v-if="activated.workPhone && mainDetails.workPhone.length === 0" class="form-info red text-end" ref="errorEight">* Required</p>
          <p v-if="activated.workPhone && !focused.workPhone && (((completedFields.workPhone && (mainDetails.workPhone.length !== 10) || (mainDetails.workPhone.length > 10))) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(mainDetails.workPhone))" class="form-info red text-end" ref="errorEight">Please use 10 Digit for phone</p>
        </div>
      </div>
      
      <!-- <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.email || (mainDetails.email.length > 0)}">Email</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.email"
            @focus="() => {
              activated.email = true;
              focused.email = true;
            }"
            @blur="() => {
              focused.email = false;
            }"
          >
          <p v-if="activated.email && mainDetails.email.length === 0" class="form-info red text-end">* Required</p>
          <p v-if="activated.email && ((mainDetails.email.length > 0) && !/\S+@\S+\.\S+/.test(mainDetails.email))" class="form-info red text-end">Please use correct email format</p>
        </div>
      </div> -->

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.address || (mainDetails.address.length > 0)}">Address 1</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.address"
            :disabled="true"
            @focus="() => {
              activated.address = true;
              focused.address = true;
            }"
            @blur="() => {
              focused.address = false;
            }"
            placeholder="E.g., 7326 Taylor Dr"
          >
          <p v-if="activated.address && mainDetails.address.length === 0" class="form-info red text-end" ref="errorNine">* Required</p>
          <p v-if="activated.address && mainDetails.address.length > 100" class="form-info red text-end" ref="errorNine">Please use maximum 100 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.address2 || (mainDetails.address2.length > 0)}">Address 2</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.address2"
            :disabled="true"
            @focus="() => {
              activated.address2 = true;
              focused.address2 = true;
            }"
            @blur="() => {
              focused.address2 = false;
            }"
            placeholder="E.g., Suite 500"
          >
          <p v-if="activated.address2 && mainDetails.address2.length > 75" class="form-info red text-end" ref="errorTen">Please use maximum 75 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.city || (mainDetails.city.length > 0)}">City</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.city"
            :disabled="true"
            @focus="() => {
              activated.city = true;
              focused.city = true;
            }"
            @blur="() => {
              focused.city = false;
            }"
            placeholder="E.g., Savage"
          >
          <p v-if="activated.city && mainDetails.city.length === 0" class="form-info red text-end" ref="errorEleven">* Required</p>
          <p v-if="activated.city && mainDetails.city.length > 50" class="form-info red text-end" ref="errorEleven">Please use maximum 50 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.state || (mainDetails.state.length > 0)}">State</p>
          <!-- <select class="form-control" 
            v-model="mainDetails.state"
            @click="() => {
              activated.state = true;
              focused.state = true;
            }"
            @change="() => {
              focused.state = false;
            }"
          >
            <option v-for="stateOption in stateOptions"
              v-bind:key="stateOption + 'state-option-main'"
            >{{ stateOption }}</option>
          </select> -->
          <DropDownList
            :mainObject="'mainDetails'"
            :mainProperty="'state'"
            :injectionName="'mainDetails'"
            :optionKey="'state-option-main'"
            :optionsList="stateOptions"
            :optionProperty="null"
            :optionPropertyTwo="null"
            :isDisabled="true"
            @click="() => {
              activated.state = true;
            }"
          />
          <p v-if="activated.state && mainDetails.state.length === 0" class="form-info red text-end" ref="errorTwelve">* Required</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.zip || (mainDetails.zip.length > 0)}">Zip</p>
          <input 
            type="text" 
            class="form-control" 
            v-model="mainDetails.zip"
            :disabled="true"
            @focus="() => {
              activated.zip = true;
              focused.zip = true;
            }"
            @blur="() => {
              focused.zip = false;
            }"
            placeholder="E.g., 55378"
          >
          <p v-if="activated.zip && mainDetails.zip.length === 0" class="form-info red text-end" ref="errorThirteen">* Required</p>
          <p v-if="activated.zip && mainDetails.zip.length > 10" class="form-info red text-end" ref="errorThirteen">Please use maximum 10 characters</p>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
          <p class="form-label" :class="{active: focused.country || (mainDetails.country.length > 0)}">Country</p>
          <!-- <select class="form-control" 
            v-model="mainDetails.country"
            @click="() => {
              activated.country = true;
              focused.country = true;
            }"
            @change="() => {
              focused.country = false;
            }"
          >
            <option
              v-for="countryOption in countryOptions"
              v-bind:key="countryOption + 'country-option-main'"
            >{{ countryOption }}</option>
          </select> -->
          <DropDownList
            :mainObject="'mainDetails'"
            :mainProperty="'country'"
            :injectionName="'mainDetails'"
            :optionKey="'country-option-main'"
            :optionsList="countryOptions"
            :optionProperty="null"
            :optionPropertyTwo="null"
            :isDisabled="true"
            @click="() => {
              activated.country = true;
            }"
          />
          <p v-if="activated.country && mainDetails.country.length === 0" class="form-info red text-end" ref="errorFourteen">* Required</p>
        </div>
      </div>

      </div>
      </div>
    </div>
  </div>

  <div class="content-block">
  <h6 class="sub-headding" ref="roleTypeSection">User Profile</h6>
  <div class="user-role">
  <div class="row">
  <div class="col-auto">
  <p class="title fw-semibold gray">Role Type</p>
  <p v-if="activated.userRole && mainDetails.userRole.length === 0" class="form-info red text-end" ref="errorSixteen">* Required</p>
  </div>
  <div class="col">
  <div class="row">
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="admin" id="admin" v-model="mainDetails.userRole"><label for="admin">Admin</label></div></div>
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="edit" id="editonly" v-model="mainDetails.userRole"><label for="editonly">Edit</label></div></div>
    <!-- <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="contribute" id="contributeonly" v-model="mainDetails.userRole"><label for="contributeonly">Contribute</label></div></div> -->
    <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="read" id="readonly" v-model="mainDetails.userRole"><label for="readonly">Read</label></div></div>
  </div>
  </div>
  </div>
  </div>
  </div>

  <div class="content-block d-lg-flex justify-content-between">
  <div class="checkbox-group task-type">
    <div v-if="employeeHelper" class="employee-tooltip">
      Allows for calculating wages!
    </div>
    <div class="row">
      <div class="col-auto" style="position: relative;">
        <p 
          class="title fw-semibold gray checkbox-title"
          @mouseover="switchEmployeeHelper(true)"
          @mouseout="switchEmployeeHelper(false)"  
          style="position: absolute; right: 1rem;"
        >User Type</p>
        <p class="title fw-semibold gray checkbox-title" style="opacity: 0; pointer-events: none;">Role Type</p>
        <p v-if="activated.employeeType && mainDetails.employeeType.length === 0" class="form-info red text-end" ref="errorSeventeen" style="position: absolute; right: 1rem;">* Required</p>
      </div>
      <div class="col-auto">
        <div class="radio"><input type="radio" name="taskType" id="regular" value="salary"  v-model="mainDetails.employeeType" disabled><label for="regular">Salary</label></div>
      </div>
      <div class="col-auto">
        <div class="radio"><input type="radio" name="taskType" id="overtime" value="parttime" v-model="mainDetails.employeeType" disabled><label for="overtime">Part-time</label></div>
      </div>
      <div class="col-auto">
        <div class="radio"><input type="radio" name="taskType" id="other" value="other" v-model="mainDetails.employeeType" disabled><label for="other">Other</label></div>
      </div>
    </div>
  </div>
  </div>

  <div class="content-block" v-if="mainDetails.employeeType !== 'other'">
  <div class="user-grade">
  <div class="row">
  <div class="col-auto" style="position: relative;">
    <p class="title fw-semibold gray checkbox-title" style="opacity: 0; pointer-events: none;">Role Type</p>
    <p class="title fw-semibold gray checkbox-title"
      style="position: absolute; right: 1rem;top: 0; bottom: 0; display: flex; align-items: center;"
    >Grade</p>
    <p v-if="activated.grade && `${mainDetails.grade}`.length === 0" class="form-info red text-end" ref="errorEighteen" style="position: absolute; right: 1rem; bottom: 1rem;">* Required</p>
  </div>
  <div v-if="compensations.length > 0" class="col-auto grade-list">
    <!-- <select class="form-control sm" 
      v-model="mainDetails.grade"
      @click="() => {
        activated.grade = true;
        focused.grade = true;
      }"
      @change="() => {
        focused.grade = false;
      }"
    >
      <option
        v-for="compensation in compensations"
        v-bind:key="compensation.id + 'compensation-option-secondary'"
      >{{ compensation.grade }}</option>
    </select> -->
    <DropDownList
      :mainObject="'mainDetails'"
      :mainProperty="'grade'"
      :injectionName="'mainDetails'"
      :optionKey="'compensation-option-secondary'"
      :optionsList="compensations"
      :optionProperty="'grade'"
      :optionPropertyTwo="null"
      :isDisabled="true"
      :defaultSelected="mainDetails.grade"
      @click="() => {
        activated.grade = true;
      }"
    />
  </div>
  <div class="col-auto" v-else>
    <input type="text" class="form-control sm" v-model="mainDetails.grade" @click="() => {addedGrade = true}" />
  </div>
  </div>
  </div>
  </div>

  <!-- <FileAttachments :section="'secondary'" /> -->
  <FileAttachments v-bind:section="'secondary'" v-bind:instance="`${mainDetails.id}`" />

  <div class="content-section">
  <div class="buttons">
  <div class="row">
  <div class="col-auto">
    <button @click="submitForm" type="submit" class="btn green">Save</button>
  </div>
  <div class="col-auto">
    <button @click="cancelChanges" type="submit" class="btn light-blue-20 btn-o">Cancel</button>
  </div>
  </div>
  </div>
  </div>
  
  </div>
  </div>
</section><!--//End Hero-->

<div v-if="notUpdated.active">
  <UserNotification
    v-bind:messageText="notUpdated.message"
  />
</div>

<div v-if="missedField">
  <UserNotification
    v-bind:messageText="'Please add the required data'"
  />
</div>
  
<div class="add-grade-popup" v-if="addedGrade">
  
  <div class="modal modal-md">
  <span class="modal-close" @click="() => redirectToGrade()"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">Please add employee grade in the employee compensation section</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => redirectToGrade()"
        class="approve-button btn green" 
      >Ok</button>
  </div>
  </div>
  </div>
  
</div>

<template  v-if="gradeFormDialog">
    <div class="form-modal">
      <span class="form-modal-close" @click="() => {gradeFormDialog = false}"><img src="@/assets/images/close-green-xs.png" alt=""></span>
      <EmployeeCompensation v-bind:dialogView="true" />
    </div>
</template>

<template v-if="updateIsActive && !registration">
  <div class="submit-popup-wrapper-fixed">
    <div class="form-modal">
    <!-- <span class="form-modal-close" @click="() => {gradeFormDialog = false}"><img src="@/assets/images/close-green-xs.png" alt=""></span> -->
      <ResetPasswordPopup />
    </div>
  </div>
</template>

</template>

<script>
import { inject, ref, watch, reactive, onMounted, provide } from "vue";
import { useStore } from "vuex";
import { v4 as uuid } from 'uuid';
import stateOptions from '@/sharedData/statesList.js'
import countryOptions from '@/sharedData/countriesList.js'
import FileAttachments from "@/components/Shared/FileAttachments.vue";
import { useRouter } from 'vue-router';
import UserNotification from '@/components/Shared/UserNotification.vue';
import DropDownList from "@/components/Shared/DropDownList.vue";
import EmployeeCompensation from "../Expenses/EmployeeCompensation.vue";
import ResetPasswordPopup from '@/components/Pages/ResetPassword/ResetPasswordPopup.vue'

export default {
    name: "SecondaryProfileDetailsForm",
    setup() {
        const store = useStore();
        const router = useRouter();

        const { mainDetails, changeMainField, editedByAdmin, editedByUser, options: companiesList } = inject("mainDetails");

        const employeeHelper = ref(false);

        const activated = reactive({
          accountName: false,
          title: false,
          firstName: false,
          lastName: false,
          mobilePhone: false,
          workPhone: false,
          email: false,
          address: false,
          address2: false,
          city: false,
          state: false,
          zip: false,
          country: false,
          userRole: false,
          employeeType: false,
          grade: false
        });
        
        const focused = reactive({
          accountName: false,
          title: false,
          firstName: false,
          lastName: false,
          mobilePhone: false,
          workPhone: false,
          email: false,
          address: false,
          address2: false,
          city: false,
          state: false,
          zip: false,
          country: false,
          userRole: false,
          employeeType: false
        });
 
        const defaultMainDetails = { ...mainDetails };

        const disabledSubmit = ref(true);

        const completedFields = reactive({
          mobilePhone: false,
          workPhone: false
        })

        const notUpdated = reactive({
          active: false,
          message: 'Please save, before proceeding'
        });

        const updateIsActive = ref(false);

        const passwordIsVisible = ref(false);

        const addedGrade = ref(false);

        const compensations = ref(store.state.compensations);

        const gradeFormDialog = ref(false);

        const roleTypeSection = ref(null);

        const mobileField = ref(null);

        const farms = ref(store.state.farms);

        const authenticatedId = ref('');

        const registration = ref(false);

        const passwordPrompt = reactive({
          active: false,
          message: 'Please add your password'
        })

        const passwordMask = ref('');
        // const companiesList = reactive({
        //   value: []
        // });

        const errorEighteen = ref(null);
        const errorSeventeen = ref(null);
        const errorSixteen = ref(null);
        const errorFourteen = ref(null);
        const errorThirteen = ref(null);
        const errorTwelve = ref(null);
        const errorEleven = ref(null);
        const errorTen = ref(null);
        const errorNine = ref(null);
        const errorEight = ref(null);
        const errorSeven = ref(null);
        const errorSix = ref(null);
        const errorFive = ref(null);
        const errorFour = ref(null);
        const errorThree = ref(null);
        const errorTwo = ref(null);
        const errorOne = ref(null);

        const missedField = ref(false);

        watch(mainDetails, () => {
            disabledSubmit.value = !mainDetails.role || !mainDetails.firstName ||
                !mainDetails.lastName || !mainDetails.mobilePhone ||
                !mainDetails.address || !mainDetails.city || !mainDetails.email ||
                !mainDetails.state || !mainDetails.zip || !mainDetails.country
        });

        watch(() => (mainDetails.employeeType), (newVal) => {
          if (newVal === 'salary') {
            compensations.value = [...store.state.compensations].filter(comp => comp.annualSalary)
          }
          if (newVal === 'parttime') {
            compensations.value = [...store.state.compensations].filter(comp => comp.hourlyRate)
          }
          if (newVal === 'other') {
            compensations.value = [...store.state.compensations].filter(comp => comp.ownershipType)
          }
        })

        watch(() => ({...store.state.authenticated}), (newVal, oldVal) => {
          authenticatedId.value = newVal._id || newVal.id;

          if (newVal.email && !oldVal.email) {
            const params = new URLSearchParams(document.location.search);

            registration.value = params.get('registration');

            if (registration.value) {
              updateIsActive.value = true;
              mainDetails.email = newVal.email;
            }

          }

          if (newVal.email && (updateIsActive.value === false)) {
            passwordMask.value = '********';
          }
        });

        watch(updateIsActive, (newVal) => {
          if (newVal) {
            passwordMask.value = '';
          } else {
            if (store.state.authenticated.email) {
              passwordMask.value = '********';
            }
          }
        })

        // watch(() => (mainDetails.title), (newVal) => {
        //   if (newVal === 'Other') {
        //     gradeFormDialog.value = true;
        //   }
        //   if (newVal) {
        //     const selectedCompensation = [...store.state.compensations].find(c => c.employeeTitle === mainDetails.title);

        //     if (selectedCompensation) {
        //       mainDetails.grade = selectedCompensation.grade;
        //       mainDetails.employeeType = selectedCompensation.employeeType;
        //     }
        //   }
        // })

        const switchUpdatable = (isUpdatable) => {
          if (isUpdatable) {
            updateIsActive.value = true;
          } else {
            updateIsActive.value = false;
            if (mainDetails.email && mainDetails.password && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&._()-^])[A-Za-z\d@$!%*?&._()-^]{8,}$/.test(mainDetails.password)) {
              // updateIsActive.value = false;

              store.dispatch("saveSecondaryUser", { 
                section: "mainDetails", 
                data: mainDetails,
                editedByAdmin: editedByAdmin.value
              });

              // store.dispatch("saveNewUser", {
              //   section: "mainDetails",
              //   data: {
              //     ...mainDetails,
              //   }
              // });

            } else {
              console.log(mainDetails.email, mainDetails.password, 'notUpdated');
              notUpdated.active = true;

              setTimeout(() => {
                notUpdated.active = false;
              }, 5000)
            }
          }
        }

        const phoneNumberFormat = (phoneType) => {
          if (mainDetails[phoneType].length > 0) {
            if (mainDetails[phoneType][0] !== '(') {
              const arrStr = mainDetails[phoneType].split('');
            
              mainDetails[phoneType] = `(${arrStr.filter((num,indx) => {
                if (indx < 3) return num
              }).join('')}) ${arrStr.filter((num, indx) => {
                if ((indx >= 3) && (indx < 6)) return num
              }).join('')}-${arrStr.filter((num, indx) => {
                if (indx >= 6) return num
              }).join('')}`;
              completedFields[phoneType] = true;
            }
          }
        };

        const switchEmployeeHelper = (toggle) => {
          employeeHelper.value = toggle;
        }
        
        const cancelChanges = () => {
          for (let prop in defaultMainDetails) {
              mainDetails[prop] = defaultMainDetails[prop];
          }
          changeMainField("represent", "mode");
          router.push({
            name: (router.currentRoute.value.params.source === 'user-profile') ? 'page' : 'admin',
            params: {
              pagename: router.currentRoute.value.params.source,
              source: 'secondary-user'
            }
          })
        };

        const submitForm = () => {
            if (!mainDetails.email || (!mainDetails.password && !editedByAdmin.value && !editedByUser?.value) || (mainDetails.password === '********') ||
                !mainDetails.firstName || !mainDetails.lastName || !mainDetails.mobilePhone ||
                !mainDetails.address || !mainDetails.city || !mainDetails.email ||
                !mainDetails.state || !mainDetails.zip || !mainDetails.country ||
                !mainDetails.userRole || !mainDetails.employeeType || 
                ((mainDetails.employeeType !== 'other') && !mainDetails.grade)) {
                if (!mainDetails.email) activated.email = true;
                if (!mainDetails.password && !editedByAdmin.value && !editedByUser?.value) activated.password = true;
                if (mainDetails.password === '********') activated.password = true;
                if (!mainDetails.firstName) activated.firstName = true;
                if (!mainDetails.lastName) activated.lastName = true;
                if (!mainDetails.mobilePhone) activated.mobilePhone = true;
                if (!mainDetails.address) activated.address = true;
                if (!mainDetails.city) activated.city = true;
                if (!mainDetails.email) activated.email = true;
                if (!mainDetails.state) activated.state = true;
                if (!mainDetails.zip) activated.zip = true;
                if (!mainDetails.country) activated.country = true;
                if (!mainDetails.userRole) activated.userRole = true;
                if (!mainDetails.employeeType) activated.employeeType = true;
                if ((mainDetails.employeeType !== 'other') && !mainDetails.grade) activated.grade = true;
                // if (!mainDetails.email || (!mainDetails.password && !editedByAdmin)) {
                //   notUpdated.active = true;

                //   setTimeout(() => {
                //     notUpdated.active = false;
                //   }, 5000)
                // }
                if (!mainDetails.email) activated.email = true;
                if (!mainDetails.password) activated.password = true;

                if (mainDetails.multifactor && !mainDetails.multifactorType) {
                  activated.multifactorType = true;
                }

                setTimeout(() => {
                  // const firstError = document.getElementsByClassName('form-info red text-end')[0];
                
                  // const viewPortOffset = firstError.getBoundingClientRect();

                  // console.log(Math.abs(viewPortOffset.top), firstErrorInTheList);
                  let firstErrorInTheList;

                  if (errorEighteen.value) {
                    firstErrorInTheList = errorEighteen.value;
                  }
                  if (errorSeventeen.value) {
                    firstErrorInTheList = errorSeventeen.value;
                  }
                  if (errorSixteen.value) {
                    firstErrorInTheList = errorSixteen.value;
                  }
                  if (errorFourteen.value) {
                    firstErrorInTheList = errorFourteen.value;
                  }
                  if (errorThirteen.value) {
                    firstErrorInTheList = errorThirteen.value;
                  }
                  if (errorTwelve.value) {
                    firstErrorInTheList = errorTwelve.value;
                  }
                  if (errorEleven.value) {
                    firstErrorInTheList = errorEleven.value;
                  }
                  if (errorTen.value) {
                    firstErrorInTheList = errorTen.value;
                  }
                  if (errorNine.value) {
                    firstErrorInTheList = errorNine.value;
                  }
                  if (errorEight.value) {
                    firstErrorInTheList = errorEight.value;
                  }
                  if (errorSeven.value) {
                    firstErrorInTheList = errorSeven.value;
                  }
                  if (errorSix.value) {
                    firstErrorInTheList = errorSix.value;
                  }
                  if (errorFive.value) {
                    firstErrorInTheList = errorFive.value;
                  }
                  if (errorFour.value) {
                    firstErrorInTheList = errorFour.value;
                  }
                  if (errorThree.value) {
                    firstErrorInTheList = errorThree.value;
                  }
                  if (errorTwo.value) {
                    firstErrorInTheList = errorTwo.value;
                  }
                  if (errorOne.value) {
                    firstErrorInTheList = errorOne.value;
                  }

                  if (firstErrorInTheList) {
                    document.getElementsByTagName('main')[0].scroll({
                      top: firstErrorInTheList.offsetTop ? (firstErrorInTheList.offsetTop - 72) : 0,
                      left: 0,
                      behavior: 'smooth'
                    })
                  }
                }, 100);

                missedField.value = true;

                setTimeout(() => {
                  missedField.value = false;
                }, 5100);

                return;
            }

            console.log(mainDetails, 'mainDetails');

            changeMainField(true, "submitted");
            
            store.dispatch("saveSecondaryUser", { 
              section: "mainDetails", 
              data: mainDetails,
              editedByAdmin: editedByAdmin.value
            });
            
            store.dispatch("saveNewUser", {
              section: "mainDetails",
              data: {
                ...mainDetails,
              }
            });

            changeMainField("represent", "mode");
        };

        const redirectToGrade = () => {
          addedGrade.value = false;

          mainDetails.mode = 'edit';
          mainDetails.submitted = false;
          
          store.dispatch("saveSecondaryUser", { 
            section: "mainDetails", 
            data: {
              ...mainDetails
            },
            editedByAdmin: editedByAdmin.value
          });

          const parentSource = `${router.currentRoute.value.params.source}`

          router.push({
            name: 'admin',
            params: { 
              pagename: 'employee-compensation',
              source: 'secondary-user',
              employeeType: mainDetails.employeeType,
              assigned: mainDetails.id,
              parentSource
            } 
          })
        }

        watch(() => (mainDetails.multifactor), () => {
          mainDetails.multifactorType = '';
        })

        watch(mainDetails, (newVal, oldVal) => {
          if (!newVal.id && oldVal.id) {
            mainDetails.id = uuid();
          }
        })

        // watch(() => (mainDetails.employeeType), (newVal) => {
        //   if (newVal) {
        //     compensations.value = [...store.state.compensations].filter(comp => comp.employeeType === newVal);
        //   }
        // })

        watch(() => ([...store.state.compensations]), (newVal) => {
          if (newVal) {
            compensations.value = [...newVal]; //.filter(comp => comp.employeeType === mainDetails.employeeType);
            (compensations.value.length > 0) && compensations.value.sort((a,b) => {if (a.employeeTitle > b.employeeTitle) {return 1} if (a.employeeTitle < b.employeeTitle) {return -1}});
            
            const selectedCompensation = [...newVal].find(c => c.employeeTitle === (mainDetails.title && mainDetails.title.split(' - ')[0]));

            if (selectedCompensation) {
              mainDetails.grade = selectedCompensation.grade;
              mainDetails.employeeType = selectedCompensation.employeeType;
            }
          }
        })

        watch(() => (mainDetails.title), (newVal) => {
          if (newVal === 'Other') {
            gradeFormDialog.value = true;
          }
          if (newVal) {
            const selectedCompensation = store.state.compensations.find(c => c.employeeTitle === (mainDetails.title && mainDetails.title.split(' - ')[0]));

            if (selectedCompensation) {
              mainDetails.grade = selectedCompensation.grade;
              mainDetails.employeeType = selectedCompensation.employeeType;
            }
          }
        })

        watch(gradeFormDialog, (newVal, oldVal) => {
          if (!newVal && oldVal) {
            setTimeout(() => {
              mobileField.value.focus();
            }, 10);
          }
        })

        watch(() => (mainDetails.accountName), (newVal) => {
          if (newVal === store.state.primaryUser?.mainDetails?.accountName) {
            mainDetails.workPhone = store.state.primaryUser?.mainDetails?.workPhone || '';
            mainDetails.address = store.state.primaryUser?.mainDetails?.address || '';
            mainDetails.address2 = store.state.primaryUser?.mainDetails?.address2 || '';
            mainDetails.city = store.state.primaryUser?.mainDetails?.city || '';
            mainDetails.state = store.state.primaryUser?.mainDetails?.state || '';
            mainDetails.zip = store.state.primaryUser?.mainDetails?.zip || '';
            mainDetails.country = store.state.primaryUser?.mainDetails?.country || '';
          }
          const currentFarm = farms.value.find(farm => farm.farmName === newVal);

          if (currentFarm) {
            // mainDetails.workPhone = currentFarm?.workPhone || '';
            mainDetails.address = currentFarm?.address || '';
            mainDetails.address2 = currentFarm?.address2 || '';
            mainDetails.city = currentFarm?.city || '';
            mainDetails.state = currentFarm?.state || '';
            mainDetails.zip = currentFarm?.zip || '';
            mainDetails.country = currentFarm?.country || '';
          }
        });

        watch(() => ({...store.state.authenticated}), (newVal) => {

          

          if (newVal.email && (updateIsActive.value === false)) {
            passwordMask.value = (mainDetails.password && (mainDetails.password !== '********')) ? mainDetails.password : '********';
          }
        });

        watch(updateIsActive, (newVal) => {
          if (newVal) {
            passwordMask.value = '';
          } else {
            if (store.state.authenticated.email) {
              passwordMask.value = '********';
            }
          }
        });

        watch(() => ([...store.state.farms]), (newVal) => {

          farms.value = [...newVal];

          const primaryCompany = store.state.primaryUser?.mainDetails?.accountName || ''

          companiesList.value = [primaryCompany, ...[...farms.value.filter(farm => !farm.sameAddress)].map(farm => farm.farmName)];

        });


        onMounted(() => {
          const params = new URLSearchParams(document.location.search);
          const user_id = params.get("user_id");
          const token = params.get("token");

          if (token && user_id) {
            // passwordPrompt.active = true;
          }

          if (mainDetails.id.length <= 0) {
            mainDetails.id = uuid();
          }

          /*
          if (mainDetails.employeeType === 'salary') {
            compensations.value = [...store.state.compensations].filter(comp => comp.employeeType === 'salary')
          }
          if (mainDetails.employeeType === 'parttime') {
            compensations.value = [...store.state.compensations].filter(comp => comp.employeeType === 'parttime')
          }
          */
          if (farms.value.length === 0) {
            store.dispatch('getFarms');
          }
          store.dispatch('getCompensations');
          compensations.value = [...store.state.compensations];
          (compensations.value.length > 0) && compensations.value.sort((a,b) => {if (a.employeeTitle > b.employeeTitle) {return 1} if (a.employeeTitle < b.employeeTitle) {return -1}});
          if (router.currentRoute.value.params.source === 'employee-compensation') {
            setTimeout(() => {
              document.getElementsByTagName('main')[0].scroll({
                top: roleTypeSection?.value?.offsetTop - 100,
                left: 0,
                behavior: 'smooth'
              })
            })
          }

          const primaryCompany = store.state.primaryUser?.mainDetails?.accountName || ''

          companiesList.value = [primaryCompany, ...[...farms.value.filter(farm => !farm.sameAddress)].map(farm => farm.farmName)];
          console.log(companiesList.value, 'companiesList.value');

          authenticatedId.value = store.state.authenticated._id || store.state.authenticated.id;

          if (store.state.authenticated.email && (updateIsActive.value === false)) {
            passwordMask.value = '********';
          }
        })

        provide('userProfileDetails', {
          mainDetails,
          gradeFormDialog
        })

        provide('passwordReset', {
          updateIsActive,
          switchUpdatable
        });

        return {
          mainDetails,
          stateOptions,
          countryOptions,
          disabledSubmit,
          submitForm,
          cancelChanges,
          changeMainField,
          phoneNumberFormat,
          activated,
          focused,
          employeeHelper,
          switchEmployeeHelper,
          addedGrade,
          compensations,
          redirectToGrade,

          notUpdated,
          passwordIsVisible,
          updateIsActive,
          switchUpdatable,
          completedFields,
          editedByAdmin,
          gradeFormDialog,
          roleTypeSection,
          companiesList,
          authenticatedId,
          passwordPrompt,
          passwordMask,
          editedByUser,
          registration,

          errorEighteen,
          errorSeventeen,
          errorSixteen,
          errorFourteen,
          errorThirteen,
          errorTwelve,
          errorEleven,
          errorTen,
          errorNine,
          errorEight,
          errorSeven,
          errorSix,
          errorFive,
          errorFour,
          errorThree,
          errorTwo,
          errorOne,

          missedField
        };
    },
    components: { FileAttachments, UserNotification, DropDownList, EmployeeCompensation, ResetPasswordPopup }
};
</script>

<style lang="scss" scoped>
.profile-details-form-mobile {
  display: none;
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
}
.profile-details-form {
  margin-bottom: 45px;
  padding-top: 24px;
  .q-card__actions {
    margin-bottom: 20px;
  }
}
.main-role-actions {
    margin-bottom: 45px;
  }
.row.wrapper {
  width: 100%; 
  justify-content: space-around;
  &.user-role-header {
    color: #28292B;
    justify-content: flex-start !important;
    font-size: 16px;
    font-weight: 600;
    height: max-content;
    margin-top: 10px;
    margin-left: 24px;
  }
  &.role-row {
    align-items: center;
    flex-wrap: wrap;
    color: rgba(40,41,43,1);
    font-weight: 500;
    margin-left: -8px;
    width: calc(100% - 48px) !important;
    height: 68px;
    .role-label {
      font-weight: 600;
      color: rgba(40,41,43,0.6);
    }
  }
}
.bordered-field {
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    &.round {
      border-radius: 34px;
    }

    &.round:before {
      border-radius: 50%;
    }
  }

  // input:checked + .slider {
  //   background-color: bhoomi;
  // }

  // input:focus + .slider {
  //   box-shadow: 0 0 1px bhoomi;
  // }

  // input:checked + .slider:before {
  //   -webkit-transform: translateX(26px);
  //   -ms-transform: translateX(26px);
  //   transform: translateX(26px);
  // }
  input:checked + .slider{background-color:hsl(var(--green));}
  input:checked + .slider:before{transform:translateX(2.4rem);}
}

.custom-aligned {
  .zip-field {
    .q-field__label {
      background: red !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .profile-details-form-mobile {
    display: block;
  }
  .profile-details-form {
    display: none;
  }
  .profile-details-form,
  .profile-details-form-mobile {
    .row {
      &.wrapper {
        height: auto;
        .outer-label {
          margin: 0 6px;
        }
      }
    }
  }
}


@media screen and (max-width: 897px) {
  .profile-details-form,
  .profile-details-form-mobile {
    .row {
      &.wrapper {
        .blank-field {
          display: none;
        }
        .blank-field-required {
          max-width: calc(40% - 12px);
        }
        .outer-label {
          max-width: calc(40% - 12px);
        }
        label {
          max-width: none;
        }
      }
    }
  }
}

@media screen and (max-width: 747px) {
  .profile-details-form,
  .profile-details-form-mobile {
    .row {
      &.wrapper {
        label {
          // max-width: 256px;
          max-width: none;
        }
        .outer-label {
          max-width: calc(50% - 12px);
        }
        .blank-field-required {
          max-width: calc(50% - 12px);
        }

        &.role-row {
          margin: auto;
          height: auto;
          .role-label {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 863px) {
  .custom-aligned {
    .zip-field {
      flex-basis: 50% !important;
    }
    .country-field {
      flex-basis: 50% !important;
    }
    .same-address {
      flex-basis: 100% !important;
    }
  }
}

.title {
  &.fw-semibold {
    font-family: var(--fw-semibold);
  }
}

.radio {
  label {
    color: hsl(var(--gray));
    font-size: var(--fs-lg);
    font-family: var(--fw-medium);
  }
}

.checkbox-group *:not(:last-child) {
  margin: auto;
}

// .checkbox-title {
//   margin-left: 6rem !important;
// }

.checkbox-group {
  &.task-type {
    position: relative;
  }
}

.employee-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 30px;
  left: -15px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 90px;
    background: #3C3838;
    z-index: -1;
  }
}

.password-field {
  position: relative;
  .password-icon {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 8;
    border-radius: 1rem;
    background-color: #fff;
    &.visible {
      background:url('@/assets/images/updated/visiblepassword.png') center center no-repeat;
      background-size: contain;
    }
    &.hidden {
      background:url('@/assets/images/updated/hidepassword.png') center center no-repeat;
      background-size: contain;
    }
  }
}
.admin-update-form .form-label-static:not(:last-child) {
  margin-bottom: 0;
  margin-right: 1.5rem;
}
.checkbox-group *:not(:last-child) {
  // margin: auto;
  &.radio-mobile {
    margin-right: 5rem;
  }
}

.password-prompt-tooltip {
  position: absolute;
  top: -4rem;
  left: 5rem;
  font-size: 1.4rem;
  // background: hsl(207, 11%, 41%);
  background-color: hsl(220, 4%, calc(16% + 10%));
  padding: .5rem;
  color: hsl(var(--white));
  z-index: 99;
  font-weight: var(--fw-semibold);
  &::after {
    position: absolute;
    transform: rotate(180deg);
    bottom: -1.2rem;
    left: 30%;
    content: "";
    border: .6rem solid transparent;
    border-bottom-color: hsl(220, 4%, calc(16% + 10%));
  }
}
</style>
