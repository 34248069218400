<template>
  <FarmProfileHeader />
  <CropHeader />
  <!-- <div ref="cropForm">
    <CropForm v-if="((editionMode === 'create') || (editionMode === 'edit')) && (isAuth('add-crop') || isAuth('edit-crop'))" />
    <CropFilled v-if="(editionMode === 'represent')" />
  </div> -->

  <div ref="cropForm"></div>
  <section class="section">
    <div class="border-box active">
      <CropForm v-if="((editionMode === 'create') || (editionMode === 'edit')) && (isAuth('add-crop') || isAuth('edit-crop'))" />
      <CropFilled v-if="(editionMode === 'represent')" />
      <CropList/>
    </div>
  </section>
</template>

<script>
import { onMounted, reactive, provide, ref, watch, inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import CropList from './CropList.vue';
import CropForm from './CropForm.vue';
import CropFilled from './CropFilled.vue'
import CropHeader from './CropHeader.vue';

export default {
  name: "PlanCrop",
  components: {
    CropList,
    CropForm,
    CropFilled,
    CropHeader
  },
  setup() {

    const store = useStore();

    const router = useRouter();
    
    const { isAuth } = inject('mainUI');

    const cropForm = ref(null);

    const currentFarm = reactive({
      id: '',
      farmName: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      longtitude: '',
      latitude: '',
      acreage: '',
      soilComposition: '',
      irrigationType: '',
      climaticZone: '',
      fencing: '',
      IOTdeviceType: '',
      primaryCrop: '',
      secondaryCrop: '',
      beds: [],
      sameAddress: false
    });

    const defaultFarm = {
      ...currentFarm
    }

    const selectCurrentFarm = (farmId) => {
      if (farmId) {
        console.log(farmId);
        const currentFarmDetails = 
          store.state.farms.find(farm => ((farm.id === farmId) || farm.farmName === farmId))
        console.log(currentFarmDetails);

        for (let prop in currentFarmDetails) {
          currentFarm[prop] = currentFarmDetails[prop];
        }

        crop.farmName = currentFarmDetails?.farmName || ''
        crop.bed = currentFarmDetails?.beds[0]?.bedNumber
      } else {
        for (let prop in defaultFarm) {
          currentFarm[prop] = defaultFarm[prop]
        }
      }
    }

    const crop = reactive({
      id: '',
      farmName: '',
      year: '',
      cropCategory: '',
      dateSeed: '',
      projHarvestAmount: '',
      bed: '',
      season: '',
      crop: '',
      harvestDate: '',
      projWastage: '',
      acreage: '',
      cropCycle: '',
      variety: '',
      isDateSeed: false,
      seedDays: '',
      isDateHarvest: false,
      harvestDays: ''
    })

    const defaultCrop = {
      ...crop
    }

    const editCrop = (field, val) => {
      crop[field] = val;
    }
      
    const editionMode = ref('');

    const bedOptions = reactive([
      ...currentFarm?.beds?.map(bed => bed.bedNumber)
    ]);

    const selectCrop = (cropData, editMode) => {
      if (cropData) {
        for (let field in cropData) {
          crop[field] = cropData[field]
        }
        editionMode.value = editMode;
      } else {
        console.log(defaultCrop, 'defaultCrop')
        for (let field in defaultCrop) {
          crop[field] = defaultCrop[field]
        }
        editionMode.value = 'create'
      }
    }

    const removeCrop = (cropId) => {console.log(cropId)};

    const openForm = () => {
      router.push({ name: 'farm', params: { pagename: 'farm-profile' } })
    }

    provide('formMode', {
      openForm
    })

    provide('cropEdition', {
      crop,
      defaultCrop,
      currentFarm,
      editionMode,
      editCrop,
      selectCrop,
      removeCrop,
      options: bedOptions
    })

    watch(() => (crop.farmName), (newVal, oldVal) => {
      if (newVal !== oldVal) {
        selectCurrentFarm(newVal);
      }
    })

    onMounted(() => {

      // get crops
      selectCurrentFarm(router.currentRoute.value.params.farm);
      store.dispatch('getCrops')

      console.log(router.currentRoute.value.params, 'route');

      if (store.state.crops.length <= 0) {
        // editionMode.value = 'create'
      }

      // if ((store.state.crops.length > 0) && !router.currentRoute.value.params.farm) {
      //   editionMode.value = '';
      // }
      
      setTimeout(() => {
        document.getElementsByTagName('main')[0].scroll({
          top: cropForm.value.offsetTop,
          left: 0,
          behavior: 'smooth'
        })
      }, 100)
    })

    return {
      editionMode,
      cropForm,
      isAuth
    }
  }
};
</script>

<style lang="scss">
.plan-crop-page {
  padding: 12px 24px 24px;

  .q-expansion-item--popup.q-expansion-item--expanded,
  .q-expansion-item--popup.q-expansion-item--collapsed {
    padding: 0;
  }
  .q-expansion-item {
    margin-bottom: 45px;
  }
}
</style>