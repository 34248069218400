<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Subscription Type</h5>
<div class="icon-group">
  <div class="info-icon lg top-icon icon tooltip"
    @click="() => selectSubscription({...subscription}, 'create')"
    data-tooltip="Buy Subscription"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
  >
    <!-- <img src="@/assets/images/plus-green-md.png" alt=""> -->
    <img src="@/assets/images/updated/059-add.png" alt="">
    <!-- <div class="edit-helper-tooltip" v-if="helperTooltip">Add Subscription</div> -->
  </div>
</div>
</div>

<div class="content-block">
  <div class="payment-details">
 

<div class="subscription-wrap-block">

  <div class="content-row subscription-info sixth-column">
    <div>
        <p class="form-label">Billing Type</p>
        <h6>{{ subscription.subscriptionPeriod }}</h6>
        <p class="fs-xs pt-1">10% Discount on Yearly Subscription</p>
    </div>

    <div>
      <span class="info-icon"
        @mouseover="switchSubscriptionTooltip(true)"
        @mouseout="switchSubscriptionTooltip(false)"
      >
        <img src="@/assets/images/question-mark.png" alt="">
        <div class="tooltip subscription-info-tooltip" v-if="subscriptionTooltip">
          Subscription can only be deleted from available subscriptions. <br/>
          If you want to delete an assigned subscription, go to <b>Users</b> under <br/>
          the admin section, delete a user so the subscription becomes available <br/>
          and then from the subscription section delete the available subscription.
        </div>
      </span>
    </div>
  </div>
  
<div class="subscription-list-grid-wrap">
  <div class="subscription-list-grid five-column-grid">

  <div></div>
  <div><p class="form-label text-center">Total</p></div>
  <div><p class="form-label text-center">Assign</p></div>
  <div><p class="form-label text-center">Available</p></div>
  <div></div>

  <div class="checkbox" :class="{greyedout: !subscriptionAssigned.trial}"><input type="checkbox" id="free30day" v-model="subscriptionAssigned.trial" disabled><label for="free30day">Free 30 Day</label></div>
  <div :class="{greyedout: !subscriptionAssigned.trial}">
    <div class="w-80">
      <h6>{{ subscriptionAssigned.trial ? Number.parseInt(1) : Number.parseInt(0) }}</h6>
    </div>
  </div>
  <div :class="{greyedout: !subscriptionAssigned.trial}">
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="free30dayAssign"
        v-model="assigned.trial" 
        @change="() => reassignLeast('trial')"
        :disabled="!subscriptionAssigned.trial"
      />
      <label for="free30dayAssign" class="p-0"></label>
    </div>
  </div>
  <div :class="{greyedout: !subscriptionAssigned.trial}">
    <div class="w-80">
      <h6>{{ subscriptionAssigned.trial ? Number.parseInt(assigned.trial ? 0 : 1) : Number.parseInt(0) }}</h6>
    </div>
  </div>
  <div></div>

  <div class="checkbox"><input type="checkbox" id="basic" v-model="availablePlans.basic" disabled /><label for="basic" @click="() => handleMissingSubscription('basic')">Basic</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.basicNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign2"
        v-model="assigned.basic" 
        :disabled="!stateSubscription.plans.basicNumber || (stateSubscription.plans.basicNumber <= 0) || ((stateSubscription.plans.basicAvailable <= 0) && !subscriptionAssigned.basic)"
        @change="() => reassignLeast('basic')"
      />
      <label for="assign2" class="p-0" @click="() => handleMissingSubscription('basic', subscriptionAssigned.basic)"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>
        {{
          Number.parseInt(
            (assigned.basic ? 
              (subscriptionAssigned.basic ? 
                stateSubscription.plans.basicAvailable : 
                (Number.parseInt(stateSubscription.plans.basicAvailable) - 1)
              ) : 
              (subscriptionAssigned.basic ? 
                (Number.parseInt(stateSubscription.plans.basicAvailable) + 1) : 
                stateSubscription.plans.basicAvailable)) || 0)       
        }}
      </h6>
    </div>
  </div>
  <div>
    <div v-if="(assigned.basic ? (Number.parseInt(stateSubscription.plans.basicAvailable)-1) : Number.parseInt(stateSubscription.plans.basicAvailable)) > 0"
      class="remove-subscription-icon tooltip"
      data-tooltip="Remove"
      @click="() => removeSubscription('basic')"
    >
      <img src="@/assets/images/trash-2.svg" alt="">
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlus" v-model="availablePlans.plus" disabled /><label for="basicPlus" @click="() => handleMissingSubscription('plus')">Basic +</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.plusNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign3" 
        v-model="assigned.plus"
        :disabled="!stateSubscription.plans.plusNumber || (stateSubscription.plans.plusNumber <= 0) || ((stateSubscription.plans.plusAvailable <= 0) && !subscriptionAssigned.plus)" 
        @change="() => reassignLeast('plus')"
      />
      <label for="assign3" class="p-0" @click="() => handleMissingSubscription('plus', subscriptionAssigned.plus)"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>
        {{ 
          Number.parseInt(
            (assigned.plus ? 
              (subscriptionAssigned.plus ? 
                stateSubscription.plans.plusAvailable : 
                (Number.parseInt(stateSubscription.plans.plusAvailable) - 1)
              ) : 
              (subscriptionAssigned.plus ? 
                (Number.parseInt(stateSubscription.plans.plusAvailable) + 1) : 
                stateSubscription.plans.plusAvailable)) || 0) 
        }}
      </h6>
    </div>
  </div>
  <div>
    <div v-if="(assigned.plus ? (Number.parseInt(stateSubscription.plans.plusAvailable)-1) : Number.parseInt(stateSubscription.plans.plusAvailable)) > 0"
      class="remove-subscription-icon tooltip"  
      data-tooltip="Remove"
      @click="() => removeSubscription('plus')"
    >
      <img src="@/assets/images/trash-2.svg" alt="">
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlusAdvance" v-model="availablePlans.advance" disabled><label for="basicPlusAdvance" @click="() => handleMissingSubscription('advance')">Basic + Advance</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.advanceNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign4"
        v-model="assigned.advance" 
        :disabled="!stateSubscription.plans.advanceNumber || (stateSubscription.plans.advanceNumber <= 0) || ((stateSubscription.plans.advanceAvailable <= 0) && !subscriptionAssigned.advance)"
        @change="() =>reassignLeast('advance')"
      />
      <label for="assign4" class="p-0" @click="() => handleMissingSubscription('advance', subscriptionAssigned.advance)"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>
        {{ 
          Number.parseInt(
            (assigned.advance ? 
              (subscriptionAssigned.advance ? 
                stateSubscription.plans.advanceAvailable : 
                (Number.parseInt(stateSubscription.plans.advanceAvailable) - 1)
              ) : 
              (subscriptionAssigned.advance ? 
                (Number.parseInt(stateSubscription.plans.advanceAvailable) + 1) : 
                stateSubscription.plans.advanceAvailable)) || 0) 
        }}
      </h6>
    </div>
  </div>
  <div>
    <div v-if="(assigned.advance ? (Number.parseInt(stateSubscription.plans.advanceAvailable)-1) : Number.parseInt(stateSubscription.plans.advanceAvailable)) > 0"
      class="remove-subscription-icon tooltip"  
      data-tooltip="Remove"
      @click="() => removeSubscription('advance')"
    >
      <img src="@/assets/images/trash-2.svg" alt="">
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlusUltimate" v-model="availablePlans.ultimate" disabled><label for="basicPlusUltimate" @click="() => handleMissingSubscription('ultimate')">Basic + Ultimate</label></div>
  <div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.ultimateNumber || 0) }}</h6></div></div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign5"
        v-model="assigned.ultimate" 
        :disabled="!stateSubscription.plans.ultimateNumber || (stateSubscription.plans.ultimateNumber <= 0) || ((stateSubscription.plans.ultimateAvailable <= 0) && !subscriptionAssigned.ultimate)"
        @change="() =>reassignLeast('ultimate')"
      />
      <label for="assign5" class="p-0" @click="() => handleMissingSubscription('ultimate', subscriptionAssigned.ultimate)"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>
        {{ 
          Number.parseInt(
            (assigned.ultimate ? 
              (subscriptionAssigned.ultimate ? 
                stateSubscription.plans.ultimateAvailable : 
                (Number.parseInt(stateSubscription.plans.ultimateAvailable) - 1)
              ) : 
              (subscriptionAssigned.ultimate ? 
                (Number.parseInt(stateSubscription.plans.ultimateAvailable) + 1) : 
                stateSubscription.plans.ultimateAvailable)) || 0)  
        }}
      </h6>
    </div>
  </div>
  <div>
    <div v-if="(assigned.ultimate ? (Number.parseInt(stateSubscription.plans.ultimateAvailable)-1) : Number.parseInt(stateSubscription.plans.ultimateAvailable)) > 0"
      class="remove-subscription-icon tooltip" 
      data-tooltip="Remove" 
      @click="() => removeSubscription('ultimate')"
    >
      <img src="@/assets/images/trash-2.svg" alt="">
    </div>
  </div>

  </div>
</div>

</div>
 
<div class="content-row overflow-auto subscription-result-wrap">
<div class="four-column-grid">
<div class="text-center">
  <p class="form-label">Subscription Amount</p>
  <h6>${{ Number.parseFloat(stateSubscription.totalSubscription || 0).toFixed(2) }}</h6>  
</div>
<div class="text-center">
  <p class="form-label">Discount</p>
  <h6>${{ Number.parseFloat(stateSubscription.discount || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Taxes</p>
  <h6>${{ Number.parseFloat(stateSubscription.taxes || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Total</p>
  <h6>${{ Number.parseFloat(stateSubscription.total || 0).toFixed(2) }}</h6>  
</div>
</div>  
</div>

<div class="content-row">
<div class="buttons">
<div class="row">
  <div class="col-auto"><button type="submit" class="btn green" @click="() => saveAssigned()">Save</button></div>
  <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelAssigning">Cancel</button></div>
</div>
</div>
</div>

</div>
</div>

<!-- Old content -->

<!--<div class="content-block">
  <div class="payment-details">
 

<div class="subscription-wrap-block">

  <div class="content-row subscription-info">
    <div>
        <p class="form-label">Billing Type</p>
        <h6>{{ subscription.subscriptionPeriod }}</h6>
        <p class="fs-xs pt-1">10% Discount on Yearly Subscription</p>
    </div>
  </div>
  
<div class="subscription-list-grid-wrap">
  <div class="subscription-list-grid">

  <div></div>
  <div><p class="form-label text-center">Total</p></div>
  <div><p class="form-label text-center">Assign</p></div>
  <div><p class="form-label text-center">Available</p></div>

  <div class="checkbox"><input type="checkbox" id="free30day" v-model="assigned.trial"><label for="free30day">Free 30 Day</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(1) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="free30dayAssign"
        v-model="assigned.trial" 
      />
      <label for="free30dayAssign" class="p-0"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(assigned.trial ? 0 : 1) }}</h6>
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basic" v-model="subscription.plans.basic" disabled /><label for="basic" @click="() => handleMissingSubscription('basic')">Basic</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.basicNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign2"
        v-model="assigned.basic" 
        :disabled="!stateSubscription.plans.basicNumber || (stateSubscription.plans.basicNumber <= 0)"
        @change="() => reassignLeast('basic')"
      />
      <label for="assign2" class="p-0" @click="() => handleMissingSubscription('basic')"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt((assigned.basic ? (stateSubscription.plans.basicAvailable - 1) : stateSubscription.plans.basicAvailable) || 0) }}</h6>
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlus" v-model="subscription.plans.plus" disabled /><label for="basicPlus" @click="() => handleMissingSubscription('plus')">Basic +</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.plusNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign3" 
        v-model="assigned.plus"
        :disabled="!stateSubscription.plans.plusNumber || (stateSubscription.plans.plusNumber <= 0)" 
        @change="() => reassignLeast('plus')"
      />
      <label for="assign3" class="p-0" @click="() => handleMissingSubscription('plus')"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt((assigned.plus ? (stateSubscription.plans.plusAvailable - 1) : stateSubscription.plans.plusAvailable) || 0) }}</h6>
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlusAdvance" v-model="subscription.plans.advance" disabled><label for="basicPlusAdvance" @click="() => handleMissingSubscription('advance')">Basic + Advance</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.advanceNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign4"
        v-model="assigned.advance" 
        :disabled="!stateSubscription.plans.advanceNumber || (stateSubscription.plans.advanceNumber <= 0)"
        @change="() =>reassignLeast('advance')"
      />
      <label for="assign4" class="p-0" @click="() => handleMissingSubscription('advance')"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt((assigned.advance ? (stateSubscription.plans.advanceAvailable - 1) : stateSubscription.plans.advanceAvailable) || 0) }}</h6>
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlusUltimate" v-model="subscription.plans.ultimate" disabled><label for="basicPlusUltimate" @click="() => handleMissingSubscription('ultimate')">Basic + Ultimate</label></div>
  <div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.ultimateNumber || 0) }}</h6></div></div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign5"
        v-model="assigned.ultimate" 
        :disabled="!stateSubscription.plans.ultimateNumber || (stateSubscription.plans.ultimateNumber <= 0)"
        @change="() =>reassignLeast('ultimate')"
      />
      <label for="assign5" class="p-0" @click="() => handleMissingSubscription('ultimate')"></label>
    </div>
  </div>
  <div>
    <div class="w-80"><h6>{{ Number.parseInt((assigned.ultimate ? (stateSubscription.plans.ultimateAvailable - 1) : stateSubscription.plans.ultimateAvailable) || 0) }}</h6></div>
  </div>

  </div>
</div>

</div>
 
<div class="content-row overflow-auto subscription-result-wrap">
<div class="four-column-grid">
<div class="text-center">
  <p class="form-label">Subscription Amount</p>
  <h6>${{ Number.parseFloat(stateSubscription.totalSubscription || 0).toFixed(2) }}</h6>  
</div>
<div class="text-center">
  <p class="form-label">Discount</p>
  <h6>${{ Number.parseFloat(stateSubscription.taxes || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Taxes</p>
  <h6>${{ Number.parseFloat(stateSubscription.discount || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Total</p>
  <h6>${{ Number.parseFloat(stateSubscription.total || 0).toFixed(2) }}</h6>  
</div>
</div>  
</div>

<div class="content-row">
<div class="buttons">
<div class="row">
  <div class="col-auto"><button type="submit" class="btn green" @click="saveAssigned">Save</button></div>
  <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelAssigning">Cancel</button></div>
</div>
</div>
</div>

</div>
</div>-->

<!-- <div class="content-block">
  <div class="payment-details">

 <div class="content-row">
<div class="checkbox"><input type="checkbox" id="freeThirtyDay" v-model="assigned.trial" /><label for="freeThirtyDay">Free 30 Day</label></div>  
 </div>
 
  <div class="content-row d-flex justify-content-end">
    <div>
        <p class="form-label">Billing Type</p>
        <h6>{{ subscription.subscriptionPeriod }}</h6>
        <p class="fs-xs pt-1">10% Discount on Yearly Subscription</p>
    </div>
 </div>

<div class="content-row overflow-auto">
<div class="subscription-table-grid">

<div><div class="w-80"><p class="form-label text-center">Total</p></div></div>
<div><div class="w-80"><p class="form-label text-center">Assign</p></div></div>
<div><div class="w-80"><p class="form-label text-center">Available</p></div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basic" v-model="subscription.plans.basic" disabled /><label for="basic" @click="() => handleMissingSubscription('basic')">Basic</label></div></div>  
<div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.basicNumber || 0) }}</h6></div></div>
<div><div class="w-80 checkbox checkbox-lg">
  <input type="checkbox" id="assign2"
    v-model="assigned.basic" 
    :disabled="!stateSubscription.plans.basicNumber || (stateSubscription.plans.basicNumber <= 0)"
    @change="() => reassignLeast('basic')"
  />
  <label for="assign2" class="p-0" @click="() => handleMissingSubscription('basic')"></label>
</div></div>
<div><div class="w-80"><h6>{{ Number.parseInt((assigned.basic ? (stateSubscription.plans.basicAvailable - 1) : stateSubscription.plans.basicAvailable) || 0) }}</h6></div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basicPlus" v-model="subscription.plans.plus" disabled /><label for="basicPlus" @click="() => handleMissingSubscription('plus')">Basic +</label></div></div>  
<div><div class="w-80">
  <h6>{{ Number.parseInt(stateSubscription.plans.plusNumber || 0) }}</h6>
</div></div>
<div><div class="w-80 checkbox checkbox-lg">
  <input type="checkbox" id="assign3" 
    v-model="assigned.plus"
    :disabled="!stateSubscription.plans.plusNumber || (stateSubscription.plans.plusNumber <= 0)" 
    @change="() => reassignLeast('plus')"
  />
  <label for="assign3" class="p-0" @click="() => handleMissingSubscription('plus')"></label>
</div></div>
<div><div class="w-80">
  <h6>{{ Number.parseInt((assigned.plus ? (stateSubscription.plans.plusAvailable - 1) : stateSubscription.plans.plusAvailable) || 0) }}</h6>
</div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basicPlusAdvance" v-model="subscription.plans.advance" disabled><label for="basicPlusAdvance" @click="() => handleMissingSubscription('advance')">Basic + Advance</label></div></div>  
<div><div class="w-80">
  <h6>{{ Number.parseInt(stateSubscription.plans.advanceNumber || 0) }}</h6>
</div></div>
<div><div class="w-80 checkbox checkbox-lg">
  <input type="checkbox" id="assign4"
    v-model="assigned.advance" 
    :disabled="!stateSubscription.plans.advanceNumber || (stateSubscription.plans.advanceNumber <= 0)"
    @change="() =>reassignLeast('advance')"
  />
  <label for="assign4" class="p-0" @click="() => handleMissingSubscription('advance')"></label>
</div></div>
<div><div class="w-80">
  <h6>{{ Number.parseInt((assigned.advance ? (stateSubscription.plans.advanceAvailable - 1) : stateSubscription.plans.advanceAvailable) || 0) }}</h6>
</div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basicPlusUltimate" v-model="subscription.plans.ultimate" disabled><label for="basicPlusUltimate" @click="() => handleMissingSubscription('ultimate')">Basic + Ultimate</label></div></div>  
<div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.ultimateNumber || 0) }}</h6></div></div>
<div><div class="w-80 checkbox checkbox-lg">
  <input type="checkbox" id="assign5"
    v-model="assigned.ultimate" 
    :disabled="!stateSubscription.plans.ultimateNumber || (stateSubscription.plans.ultimateNumber <= 0)"
    @change="() =>reassignLeast('ultimate')"
  />
  <label for="assign5" class="p-0" @click="() => handleMissingSubscription('ultimate')"></label>
</div></div>
<div><div class="w-80"><h6>{{ Number.parseInt((assigned.ultimate ? (stateSubscription.plans.ultimateAvailable - 1) : stateSubscription.plans.ultimateAvailable) || 0) }}</h6></div></div>

</div>
</div>
 
<div class="content-row overflow-auto">
<div class="four-column-grid">
<div class="text-center">
  <p class="form-label">Subscription Amount</p>
  <h6>${{ Number.parseFloat(stateSubscription.totalSubscription || 0).toFixed(2) }}</h6>  
</div>
<div class="text-center">
  <p class="form-label">Discount</p>
  <h6>${{ Number.parseFloat(stateSubscription.taxes || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Taxes</p>
  <h6>${{ Number.parseFloat(stateSubscription.discount || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Total</p>
  <h6>${{ Number.parseFloat(stateSubscription.total || 0).toFixed(2) }}</h6>  
</div>
</div>  
</div>

<div class="content-row">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelAssigning">Cancel</button></div>
<div class="col-auto"><button type="submit" class="btn green"  @click="saveAssigned">Save</button></div>
</div>
</div>
</div>

</div>
</div> -->
</div>

<div v-if="popupMessages.missing.active">
  <UserNotification
    v-bind:hideDetails="true"
    v-bind:messageText="popupMessages.missing.message"
    @click="() => {popupMessages.missing.active = false}"
  />
</div>

<div class="removal-popup" v-if="toBeRemovedSubscription">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveSubscriptionRemoval(null)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">Are you sure, you want to delete the subscription?</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveSubscriptionRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveSubscriptionRemoval(null)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>

<div class="removal-popup" v-if="popupMessages.assignPaid.active">

<div class="modal modal-md">
<span class="modal-close" @click="() => {popupMessages.assignPaid.active = false;}"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">{{ popupMessages.assignPaid.message }}</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => {
        saveAssigned(true);
        popupMessages.assignPaid.active = false;
      }"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => {
        popupMessages.assignPaid.active = false;
      }" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>

</template>

<script>
import { defineComponent, inject, onMounted, ref, watch, reactive } from 'vue';
import { useStore } from 'vuex';
import _ from "lodash";
import UserNotification from '@/components/Shared/UserNotification.vue';
import subscriptionRates from '@/sharedData/subscriptionRates';

export default defineComponent({
    name: 'AdminSubscriptionAssignment',
    setup() {
        const store = useStore();
        const { selectSubscription, subscription, stateSubscription, assigned, subscriptionEditionMode, changeSubscriptionField, availablePlans, isPrimaryUpdate } = inject('subscription');
        const { mainDetails } = inject('mainDetails');
        const subscriptionAssigned = ref({});
        const helperTooltip = ref(false);

        const popupMessages = reactive({
          // init: {
          //   active: false,
          //   message: 'Please assign a subscription to the account'
          // },
          // assigned: {
          //   active: false,
          //   message: 'Subscription has been added to the account'
          // },
          missing: {
            active: false,
            message: 'No subscription available, please purchase by clicking on the Add subscription icon'
          },
          assignPaid: {
            active: false,
            message: 'Your free 30 days trial subscription will be removed. Are you sure you want to do it?'
          }
        })

        const toBeRemovedSubscription = ref(null);

        const missingTimeout = ref(null);

        const handleMissingSubscription = (plan, currentAssigned) => {
          if (!subscription.plans[plan] || ((stateSubscription.plans[`${plan}Available`] <= 0) && !currentAssigned)) {
            popupMessages.missing.active = true;
            clearTimeout(missingTimeout.value);

            missingTimeout.value = setTimeout(() => {
              popupMessages.missing.active = false;
            }, 5100);
          }
        }

        const subscriptionTooltip = ref(false);
      
        const switchSubscriptionTooltip = (toggle) => {
          subscriptionTooltip.value = toggle;
        }

        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const editHelperTooltip = ref(false);
        const switchEditHelperTooltip = (toggle) => {
            editHelperTooltip.value = toggle;
        };
        const cancelAssigning = () => {
            for (let prop in assigned) {
                assigned[prop] = false;
            }
            subscriptionEditionMode.value = 'represent';
        };
        const saveAssigned = (approvedPaid) => {
          if (!mainDetails.id) {
            return;
          }

          if (subscriptionAssigned.value.trial && !approvedPaid && !assigned.trial) {
            popupMessages.assignPaid.active = true;
            return;
          }

          const selectedUser = store.state.users.find(user => user.mainDetails.id === mainDetails.id);
            const assignedPlan = {};
            for (let prop in assigned) {
                for (let subProp in subscription.plans) {
                    if (assigned[prop]) {
                        if (subProp.includes(prop)) {
                            if ((subProp.includes('Number') || subProp.includes('Price'))) {
                                assignedPlan[subProp] = subscription.plans[subProp];
                            }
                            else {
                                assignedPlan[subProp] = assigned[subProp];
                            }
                        }
                    }
                }
            }

            if (mainDetails.id === 'primary') {
              if (!store.state.primaryUser.subscription) {
                store.dispatch('savePrimaryUser', {
                    section: 'subscription',
                    data: {
                        plans: {
                            trial: true,
                            trialPrice: 'price_1O5VljHpD3HYJIrgI5y2Jhkq',
                            
                            basic: false,
                            basicPrice: '',
                            basicNumber: '',
                            basicPriceIdYearly: 'price_1NXMRQHpD3HYJIrg2IwC8sri',
                            basicPriceIdMonthly: 'price_1NXMNxHpD3HYJIrg0hyDtIQO',
                            basicAvailable: '',
                            // plus: false,
                            // plusPrice: '',
                            // plusNumber: '',
                            plus: false,
                            plusPrice: '',
                            plusNumber: '',
                            plusPriceIdYearly: 'price_1NXMQoHpD3HYJIrgJaWbqhOI',
                            plusPriceIdMonthly: 'price_1NXMQoHpD3HYJIrgnhtzXnIF',
                            plusAvailable: '',
                            // advance: false,
                            // advancePrice: '',
                            // advanceNumber: '',
                            advance: false,
                            advancePrice: '',
                            advanceNumber: '',
                            advancePriceIdYearly: 'price_1NXMhHHpD3HYJIrgUwLCM3C2',
                            advancePriceIdMonthly: 'price_1NXMhHHpD3HYJIrgrxWzr1UH',
                            advanceAvailable: '',
                            // ultimate: false,
                            // ultimatePrice: '',
                            // ultimateNumber: '',
                            ultimate: false,
                            ultimatePrice: '',
                            ultimateNumber: '',
                            ultimatePriceIdYearly: 'price_1NXMklHpD3HYJIrgUKaTXGU9',
                            ultimatePriceIdMonthly: 'price_1NXMklHpD3HYJIrgbrsDHSiM',
                            ultimateAvailable: '',
                        },
                        subscriptionPeriod: 'Monthly',
                        totalSubscription: '',
                        taxes: '',
                        discount: '',
                        total: '',
                        planPurchasingDate: store.state.authenticated.registrationDate,
                        subscriptionsNumber: 0
                    }
                });
              }
              store.dispatch('assignSubscriptionToUser', {assignedPlan: {...assignedPlan}, userId: 'primary'});
              isPrimaryUpdate.value.assignedPlan = {...assignedPlan};
              // store.dispatch('savePrimaryUser', { section: 'subscriptionAssigned', data: { ...assignedPlan } });
              // store.dispatch('assignSubscription', { assignedPlan: {...assignedPlan} });
              // store.dispatch('getUsersList');
              subscriptionEditionMode.value = 'represent';
              return;
            }

            if (!selectedUser.subscription) {
                const tempSubscription = { ...subscription, plans: { ...subscription.plans } };
                for (let option in assignedPlan) {
                    for (let plan in tempSubscription.plans) {
                        if (option === plan) {
                            tempSubscription.plans[plan] = assignedPlan[option];
                        }
                    }
                }
                console.log(selectedUser, 'changing anyway');
                store.dispatch('changeNewUser', {
                    userId: mainDetails.id,
                    section: 'subscription',
                    // data: {...tempSubscription}
                    data: {
                        plans: {
                            trial: true,
                            trialPrice: 'price_1O5VljHpD3HYJIrgI5y2Jhkq',
                            basic: false,
                            basicPrice: '',
                            basicNumber: '',
                            basicPriceIdYearly: 'price_1NXMRQHpD3HYJIrg2IwC8sri',
                            basicPriceIdMonthly: 'price_1NXMNxHpD3HYJIrg0hyDtIQO',
                            basicAvailable: '',
                            // plus: false,
                            // plusPrice: '',
                            // plusNumber: '',
                            plus: false,
                            plusPrice: '',
                            plusNumber: '',
                            plusPriceIdYearly: 'price_1NXMQoHpD3HYJIrgJaWbqhOI',
                            plusPriceIdMonthly: 'price_1NXMQoHpD3HYJIrgnhtzXnIF',
                            plusAvailable: '',
                            // advance: false,
                            // advancePrice: '',
                            // advanceNumber: '',
                            advance: false,
                            advancePrice: '',
                            advanceNumber: '',
                            advancePriceIdYearly: 'price_1NXMhHHpD3HYJIrgUwLCM3C2',
                            advancePriceIdMonthly: 'price_1NXMhHHpD3HYJIrgrxWzr1UH',
                            advanceAvailable: '',
                            // ultimate: false,
                            // ultimatePrice: '',
                            // ultimateNumber: '',
                            ultimate: false,
                            ultimatePrice: '',
                            ultimateNumber: '',
                            ultimatePriceIdYearly: 'price_1NXMklHpD3HYJIrgUKaTXGU9',
                            ultimatePriceIdMonthly: 'price_1NXMklHpD3HYJIrgbrsDHSiM',
                            ultimateAvailable: '',
                        },
                        subscriptionPeriod: 'Monthly',
                        totalSubscription: '',
                        taxes: '',
                        discount: '',
                        total: '',
                        planPurchasingDate: store.state.authenticated.registrationDate,
                        subscriptionsNumber: 0
                    }
                });
            }
            console.log(mainDetails.id);
            store.dispatch('assignSubscriptionToUser', {assignedPlan: {...assignedPlan}, userId: mainDetails.id});
            // store.dispatch('changeNewUser', {
            //     userId: mainDetails.id,
            //     section: 'subscriptionAssigned',
            //     data: { ...assignedPlan }
            // });
            console.log('changeUserData works?');
            store.dispatch('changeUserData', {
                userId: mainDetails.id,
                section: 'subscriptionAssigned',
                data: { ...assignedPlan }
            });
            store.dispatch('assignSubscription', { assignedPlan: {...assignedPlan} });
            subscriptionEditionMode.value = 'represent';
            
            subscriptionEditionMode.value = 'represent';
        };
        const reassignLeast = (activeProp) => {
            for (let prop in assigned) {
                if (prop !== activeProp)
                    assigned[prop] = false;
            }
        };

        const removeSubscription = (subType) => {
          toBeRemovedSubscription.value = subType;
        };

        const approveSubscriptionRemoval = (approval) => {
          if (approval) {
            const toBeRemovedType = `${toBeRemovedSubscription.value}`;
            store.dispatch('removeOneSubscription', toBeRemovedType);
          }
          
          toBeRemovedSubscription.value = null;
        }

        const calculateTotal = (trial) => {
          const months = (subscription.subscriptionPeriod === 'Yearly') ? 12 : 1;
          const basicField = subscription.plans.basicPrice * subscription.plans.basicNumber;
          const plusField = subscription.plans.plusPrice * subscription.plans.plusNumber;
          const advanceField = subscription.plans.advancePrice * subscription.plans.advanceNumber;
          const ultimateField = subscription.plans.ultimatePrice * subscription.plans.ultimateNumber;

          const basicCalc = {
            totalSubscription: 0,
            total: 0,
            taxes: 0
          };

          const plusCalc = {
            totalSubscription: 0,
            total: 0,
            taxes: 0
          };

          const advanceCalc = {
            totalSubscription: 0,
            total: 0,
            taxes: 0
          };

          const ultimateCalc = {
            totalSubscription: 0,
            total: 0,
            taxes: 0
          };

          if (subscription.plans.trial && trial) {
            subscription.totalSubscription = `${0}.00`;
            subscription.total = `${0}.00`;
            subscription.taxes = `${0}.00`;
          } 

          if (
            subscription.plans.basic &&
            (subscription.plans.basicPrice > 0) &&
            (subscription.plans.basicNumber > 0)
          ) {
            // const optionsSum = plusField + basicField + advanceField;
            basicCalc.totalSubscription = Math.abs(
              basicField * months
            ).toFixed(2)
            basicCalc.total = Math.abs(
              ((basicField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * (100 + 
                Number.parseFloat(subscriptionRates.tax))
            ).toFixed(2)
            basicCalc.taxes = Math.abs(
              ((basicField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)
            ).toFixed(2)
          }

          if (
            subscription.plans.plus &&
            subscription.plans.plusPrice &&
            subscription.plans.plusNumber
          ) {
            plusCalc.totalSubscription = Math.abs(
              plusField * months
            ).toFixed(2)
            plusCalc.total = Math.abs(
              ((plusField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * (100 + 
                Number.parseFloat(subscriptionRates.tax))
            ).toFixed(2)
            plusCalc.taxes = Math.abs(
              ((plusField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)
            ).toFixed(2)
          }

          if (
            subscription.plans.advance &&
            subscription.plans.advancePrice &&
            subscription.plans.advanceNumber
          ) {
            advanceCalc.totalSubscription = Math.abs(
              advanceField * months
            ).toFixed(2)
            advanceCalc.total = Math.abs(
              ((advanceField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * (100 + 
                Number.parseFloat(subscriptionRates.tax))
            ).toFixed(2)
            advanceCalc.taxes = Math.abs(
              ((advanceField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)
            ).toFixed(2)
          }

          if (
            subscription.plans.ultimate &&
            subscription.plans.ultimatePrice &&
            subscription.plans.ultimateNumber
          ) {
            ultimateCalc.totalSubscription = Math.abs(
              ultimateField * months
            ).toFixed(2)
            ultimateCalc.total = Math.abs(
              ((ultimateField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * (100 + 
                Number.parseFloat(subscriptionRates.tax))
            ).toFixed(2)
            ultimateCalc.taxes = Math.abs(
              ((ultimateField * months - (Number.parseFloat(subscription.discount) || 0)) / 100) * Number.parseFloat(subscriptionRates.tax)
            ).toFixed(2)
          }

          subscription.totalSubscription = Math.abs(
            Number.parseFloat(basicCalc.totalSubscription) + 
            Number.parseFloat(plusCalc.totalSubscription) +
            Number.parseFloat(advanceCalc.totalSubscription) + 
            Number.parseFloat(ultimateCalc.totalSubscription)
          ).toFixed(2);

          subscription.discount = (subscription.subscriptionPeriod === 'Yearly') ? `${
            Math.abs(Number.parseFloat(subscription.totalSubscription) / 100) * 10
          }.00` : `${0}.00`

          // subscription.total = Math.abs(
          //   Number.parseFloat(basicCalc.total) + 
          //   Number.parseFloat(plusCalc.total) +
          //   Number.parseFloat(advanceCalc.total) + 
          //   Number.parseFloat(ultimateCalc.total)
          // ).toFixed(2);
          subscription.total = Math.abs(
            (Number.parseFloat((subscription.totalSubscription) - Number.parseFloat(subscription.discount))/100)*Number.parseFloat(106.87)
          ).toFixed(2);

          // subscription.taxes = Math.abs(
          //   Number.parseFloat(basicCalc.taxes) + 
          //   Number.parseFloat(plusCalc.taxes) +
          //   Number.parseFloat(advanceCalc.taxes) + 
          //   Number.parseFloat(ultimateCalc.taxes)
          // ).toFixed(2);
          subscription.taxes = Math.abs(
            (Number.parseFloat((subscription.totalSubscription) - Number.parseFloat(subscription.discount))/100)*Number.parseFloat(6.87)
          ).toFixed(2);

          stateSubscription.totalSubscription = subscription.totalSubscription;
          stateSubscription.total = subscription.total
          stateSubscription.taxes = subscription.taxes
          stateSubscription.discount = (subscription.subscriptionPeriod === 'Yearly') ? `${
            Math.abs(Number.parseFloat(subscription.totalSubscription) / 100) * 10
          }.00` : `${0}.00`

          store.dispatch('updateSubscriptionTotals', {
            totalSubscription: stateSubscription.totalSubscription,
            total: stateSubscription.total,
            taxes: stateSubscription.taxes,
            discount: stateSubscription.discount
          });
        }

        watch(() => ({ ...subscription.plans }), () => {
          subscription.totalSubscription = '0.00';
          subscription.total = '0.00';
          subscription.taxes = '0.00';
          // subscription.discount = '0.00';
          subscription.discount = (subscription.subscriptionPeriod === 'Yearly') ? `${
            Math.abs(Number.parseFloat(subscription.totalSubscription) / 100) * 10
          }.00` : `${0}.00`
          calculateTotal();
        });

        watch(() => (mainDetails.id), (newVal) => {
          if (newVal) {
            const currentUser = newVal === 'primary' ?
              store.state.primaryUser :
              store.state.users.find(user => user.mainDetails.id === newVal);

            subscriptionAssigned.value = currentUser?.subscriptionAssigned || {};

            if (currentUser.subscriptionAssigned) {
              for (let opt in assigned) {
                assigned[opt] = false;
              }
              for (let plan in currentUser.subscriptionAssigned) {
                for (let option in assigned) {
                  if ((option === plan) && currentUser.subscriptionAssigned[plan]) {
                    assigned[option] = true;
                  }
                }
              }
            }              
          }
        });
        watch(() => _.cloneDeep(assigned), (newVal, oldVal) => {
            for (let prop in newVal) {
                if (newVal[prop]) {
                    for (let subProp in subscription.plans) {
                        if (newVal[prop] && !oldVal[prop]) {
                            if (subProp.includes(prop) && subProp.includes('Number')) {
                                // subscription.plans[subProp] = subscription.plans[subProp] - 1;
                                console.log(subscription.plans[subProp]);
                            }
                        }
                    }
                }
                if (!newVal[prop] && oldVal[prop]) {
                    console.log(prop, newVal[prop]);
                    for (let subProp in subscription.plans) {
                        // if (newVal[prop]) {
                        if (subProp.includes(prop) && subProp.includes('Number')) {
                            // subscription.plans[subProp] = subscription.plans[subProp] + 1;
                            console.log(subscription.plans[subProp], 'Number');
                        }
                        if (subProp.includes(prop) && subProp.includes('Available')) {
                            // subscription.plans[subProp] = subscription.plans[subProp] + 1;
                            console.log(subscription.plans[subProp], 'Available');
                        }
                        // }
                    }
                }
            }
        });

        watch(() => _.cloneDeep(subscription), (newVal, oldVal) => {
          if ((newVal.plans.basicNumber !== oldVal.plans.basicNumber) ||
            (newVal.plans.plusNumber !== oldVal.plans.plusNumber) ||
            (newVal.plans.advanceNumber !== oldVal.plans.advanceNumber) ||
            (newVal.plans.ultimateNumber !== oldVal.plans.ultimateNumber)) {
              for (let prop in stateSubscription) {
                if (prop === 'plans') {
                  for (let plan in stateSubscription.plans) {
                    stateSubscription.plans[plan] = newVal?.plans[plan];
                  }
                }
                else {
                  changeSubscriptionField(newVal[prop], prop);
                }
              }
            }
        })

        onMounted(() => {
            const currentUser = mainDetails.id === 'primary' ?
              store.state.primaryUser :
              store.state.users.find(user => user.mainDetails.id === mainDetails.id);

            subscriptionAssigned.value = currentUser?.subscriptionAssigned || {};

            if (store.state.primaryUser?.subscription) {
                for (let prop in subscription) {
                    if (prop === 'plans') {
                        for (let plan in subscription.plans) {
                            subscription.plans[plan] = store.state.primaryUser?.subscription?.plans[plan];
                        }
                    }
                    else {
                        // subscription[prop] = store.state.primaryUser?.subscription[prop];
                        changeSubscriptionField(store.state.primaryUser?.subscription[prop], prop);
                    }
                }
                // subscriptionEditionMode.value = 'represent'
            }
            if (store.state.primaryUser.subscriptionAssigned) {
                for (let plan in store.state.primaryUser.subscriptionAssigned) {
                    for (let option in assigned) {
                        if ((option === plan) && store.state.primaryUser.subscriptionAssigned[plan]) {
                            // assigned[option] = true;
                            console.log(option);
                            for (let subProp in store.state.primaryUser.subscription.plans) {
                                if (subProp.includes(plan) && subProp.includes('Number')) {
                                  console.log(subscription.plans[subProp], 'Number', 'assigned')
                                    console.log(store.state.primaryUser.subscriptionAssigned);
                                    // subscription.plans[subProp] = subscription.plans[subProp] - 1;
                                }
                            }
                        }
                    }
                }
            }

            if (currentUser?.subscriptionAssigned) {
              for (let plan in currentUser.subscriptionAssigned) {
                for (let option in assigned) {
                  if ((option === plan) && currentUser.subscriptionAssigned[plan]) {
                    assigned[option] = true;
                  }
                }
              }
            }

            subscription.discount = (subscription.subscriptionPeriod === 'Yearly') ? `${
              Math.abs(Number.parseFloat(subscription.totalSubscription) / 100) * 10
            }.00` : `${0}.00`

            stateSubscription.discount = (subscription.subscriptionPeriod === 'Yearly') ? `${
              Math.abs(Number.parseFloat(subscription.totalSubscription) / 100) * 10
            }.00` : `${0}.00`
        });
        return {
            subscription,
            stateSubscription,
            selectSubscription,
            subscriptionAssigned,
            helperTooltip,
            switchHelperTooltip,
            assigned,
            editHelperTooltip,
            switchEditHelperTooltip,
            cancelAssigning,
            saveAssigned,
            reassignLeast,
            popupMessages,
            handleMissingSubscription,

            subscriptionTooltip,
            switchSubscriptionTooltip,
            removeSubscription,
            approveSubscriptionRemoval,
            toBeRemovedSubscription,
            availablePlans
        };
    },
    components: { UserNotification }
})
</script>

<style lang="scss" scoped> 
.edit-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 50px;
  left: -70px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 85px;
    background: #3C3838;
    z-index: -1;
  }
}

.info-icon {
  position: relative;
}
.subscription-info-tooltip {
  position: absolute;
  z-index: 99999;
  bottom: 40px;
  left: -310px;
  width: 490px;
  // display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 11px;
  // color: #fff;
  // background-color: #3C3838;
  font-family: 'Inter', sans-serif;
  // font-weight: 400;
  // padding: 5px;
  color: hsl(var(--white));
  // background: hsl(207, 11%, 41%);
  background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
  font-weight: var(--fw-semibold);
  padding: 0.5rem;
  font-size: 1.4rem;
  font-family: 'Gilroy';
  &::after {
    content: " ";
    position: absolute;
    display: block;
    bottom: -7px;
    // border-color: #3C3838;
    // border-left: 8px solid hsl(207, 11%, 41%);
    // border-bottom: 6px solid hsl(207, 11%, 41%);
    border-left: 8px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    border-bottom: 6px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(190px) rotate(135deg);
    transform: translateX(190px) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 125px;
    // background: #3C3838;
    // background: hsl(207, 11%, 41%);
    background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    z-index: -1;
  }
}
.remove-subscription-icon {
  display: flex;
  cursor: pointer;
  width: max-content;
}
.removal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>