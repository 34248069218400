<template>
  <div class="content-block">

<div class="content-row">
    <div class="table-wrapper">
        <table style="min-width: 650px;">

            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th><p class="form-label-static form-sort-label">
                    <span>Title</span>
                    <span @click="() => sortTable('title')">
                      <button :class="{active: sortOrder.title === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
                      <button :class="{active: sortOrder.title === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
                    </span></p></th>

                    <th><p class="form-label-static form-sort-label">
                    <span>Subject</span>
                    <span @click="() => sortTable('subject')">
                      <button :class="{active: sortOrder.subject === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
                      <button :class="{active: sortOrder.subject === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
                    </span></p></th>
                    
                    <th><p class="form-label-static form-sort-label">
                    <span>Category</span>
                    <span @click="() => sortTable('category')">
                      <button :class="{active: sortOrder.category === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
                      <button :class="{active: sortOrder.category === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
                    </span></p></th>

                    <th><p class="form-label-static form-sort-label">
                    <span>Created</span>
                    <span @click="() => sortTable('created')">
                      <button :class="{active: sortOrder.created === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
                      <button :class="{active: sortOrder.created === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
                    </span></p></th>

                    <th><p class="form-label-static form-sort-label">
                    <span>Size</span>
                    <span @click="() => sortTable('size')">
                      <button :class="{active: sortOrder.size === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
                      <button :class="{active: sortOrder.size === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
                    </span></p></th>
                    
                    <th>&nbsp;</th>
                </tr>
            </thead>

            <tbody>

              <template
                v-for="(row, rowIndex) in rows"
                v-bind:key="'resource-list-row' + row.id"
              >
                <tr
                  v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
                  :class="{'light-gray-bg': row.id === resource.id}"
                  @click="(ev) => selectByRow(ev, {...row})"
                >
                    <td class="text-center">
                      <img v-if="row.category === 'Document'" src="@/assets/images/doc.svg" alt="" class="m-auto">
                      <img v-if="row.category === 'Image'" src="@/assets/images/image.svg" alt="" class="m-auto">
                      <img v-if="row.category === 'Video'" src="@/assets/images/video.svg" alt="" class="m-auto">
                      <img v-if="row.category === 'Link'" src="@/assets/images/video.svg" alt="" class="m-auto">
                    </td>
                    <td style="color:hsl(var(--green)); text-decoration:underline; cursor: pointer;">{{ row.title || row.link }}</td>
                    <td>{{ row.subject }}</td>
                    <td>{{ row.category }}</td>
                    <td>{{ row.created }}</td>
                    <td>{{ row.size }}</td>
                    <td class="w-75">
                    <div class="table-links">
                    <a  href="javascript:void();" @click="selectResource({...row}, 'edit')">
                      <!-- v-if="isAuth('edit-resource')" -->
                        <img src="@/assets/images/edit-icon-2.svg" alt="">
                    </a>
                    <a  href="javascript:void();" @click="removeSelectedResource({...row})">
                       <!-- v-if="isAuth('remove-resource')" -->
                        <img src="@/assets/images/trash-2.svg" alt="">
                    </a>
                    </div>
                    </td>
                </tr>
              </template>
                
                
                </tbody>

        </table>
    </div>
</div>

<div class="content-row">
    <div class="pagination">
    <div class="records-per-page">
    </div>
    <p class="list-pagination">
      Page: 
      <DropDownList 
        :mainObject="'currentPage'"
        :mainProperty="'value'"
        :injectionName="'resourceListDetails'"
        :optionKey="'resource-list-pagination'"
        :optionsList="[]"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="currentPage.value"
        :numberList="true"
      />
    </p>
    </div>
    </div>

</div>


<div class="removal-popup" v-if="removedResource">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">Are you sure you want to delete the resource?</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveRemoval(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>


<div v-if="loadingFile" class="submit-popup-wrapper">
    <div class="popup-message">
      <!-- <div class="close-button" @click="() => {
        loadingFile = false;
      }" ></div> -->
      <div class="loader"></div>
    </div>
  </div>


</template>

<script>
import { defineComponent, ref, onMounted, inject, watch, reactive, provide } from 'vue';
import { useStore } from 'vuex';
import DropDownList from '@/components/Shared/DropDownList.vue';

export default defineComponent({
  name: 'ResourcesUserList',
  setup() {
    const store = useStore();
    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);
    // const harvests = computed(() => store.state.harvests);
    // const tasks = computed(() => store.state.tasks);
    const resources = ref(([...store.state.resources]));
    const rows = ref([]);
    const maxPage = reactive({
        value: 20
    });
    const options = reactive({
        value: []
    });
    const currentPage = reactive({
        value: 1
    });
    const sortOrder = reactive({
      title: 0,
      subject: 0,
      category: 0,
      created: 0,
      size: 0
    });

    const loadingFile = ref(false);

    const { isAuth } = inject('mainUI');
    const { selectedResource, editResource, selectResource, removeResource, resource } = inject('resourceDetails');

    const removedResource = ref(null)

    const helperTooltip = ref(false);
    const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
    };
    const toggleSection = (ev) => {
        if (ev.target.className !== 'border-box-header')
            return;
        sectionExpanded.value = !sectionExpanded.value;
    };
    const sortTable = (sortProp) => {
        console.log(sortOrder[sortProp]);
        if (sortOrder[sortProp] === 0) {
            rows.value.sort((a, b) => {
                if (a[sortProp] < b[sortProp]) {
                    return 1;
                }
                if (a[sortProp] > b[sortProp]) {
                    return -1;
                }
                return 0;
            });
            sortOrder[sortProp] = 1;
        }
        else if (sortOrder[sortProp] > 0) {
            rows.value.sort((a, b) => {
                if (a[sortProp] < b[sortProp]) {
                    return -1;
                }
                if (a[sortProp] > b[sortProp]) {
                    return 1;
                }
                return 0;
            });
            sortOrder[sortProp] = -1;
        }
        else if (sortOrder[sortProp] < 0) {
            rows.value = [...resources.value];
            sortOrder[sortProp] = 0;
        }
    };
    const sortTableByNumber = (sortProp) => {
        if (sortOrder[sortProp] === 0) {
            rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
            sortOrder[sortProp] = 1;
        }
        else if (sortOrder[sortProp] > 0) {
            rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
            sortOrder[sortProp] = -1;
        }
        else if (sortOrder[sortProp] < 0) {
            rows.value = [...resources.value];
            sortOrder[sortProp] = 0;
        }
    };
    const sortTableByAmount = (sortProp) => {
        if (sortOrder[sortProp] === 0) {
            rows.value.sort((a, b) => Number.parseInt(Math.abs(a.amtHarvested - a.wastage)) - Number.parseInt(Math.abs(b.amtHarvested - b.wastage)));
            sortOrder[sortProp] = 1;
        }
        else if (sortOrder[sortProp] > 0) {
            rows.value.sort((a, b) => Number.parseInt(Math.abs(b.amtHarvested - b.wastage)) - Number.parseInt(Math.abs(a.amtHarvested - a.wastage)));
            sortOrder[sortProp] = -1;
        }
        else if (sortOrder[sortProp] < 0) {
            rows.value = [...resources.value];
            sortOrder[sortProp] = 0;
        }
    };
    const selectByRow = (ev, row) => {
        if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
            return;
        }
        else {
            // selectResource({ ...row }, 'represent');
            // open resource
            loadingFile.value = true;
            store.dispatch('openResource', {...row});
        }
    };
    const approveRemoval = (isApproved) => {
        if (isApproved) {
            removeResource(removedResource.value);
        }
        removedResource.value = null;
    };
    const removeSelectedResource = (resource) => {
        removedResource.value = resource;
    };
    watch(sectionExpanded, (newVal) => {
        section.value.style.height = newVal ?
            `${sectionHeight.value}px` :
            `${boxHeader.value.offsetHeight}px`;
        section.value.style.overflow = newVal ? 'visible' : 'hidden';
    });
    watch(() => ([...store.state.resources]), (newVal) => {
        // section.value.style.height = 'auto';
        // setTimeout(() => {
        //     sectionHeight.value = section.value.offsetHeight;
        // });
        rows.value = [...newVal];
        resources.value = [...newVal];
        console.log(rows.value, 'rows.value');
        options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
    });
    watch(maxPage, () => {
        // section.value.style.height = 'auto';
        // setTimeout(() => {
        //     sectionHeight.value = section.value.offsetHeight;
        // });
        options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
    });
    watch(() => ({...store.state.selectedResourceFile}), (newVal) => {
      if (newVal.file && loadingFile.value) {
        loadingFile.value = false;
      }
    });
    // watch(store.state.harvests, () => {
    //   console.log(harvests.value, 'harvests');
    //   section.value.style.height = 'auto';
    //   setTimeout(() => {
    //     sectionHeight.value = section.value.offsetHeight;
    //   })
    //   rows.value = [...harvests.value];
    // })
    onMounted(() => {
        // setTimeout(() => {
        //     sectionHeight.value = section.value.offsetHeight;
        //     section.value.style.height = `${section.value.offsetHeight}px`;
        // });
        rows.value = [...store.state.resources];
        store.dispatch('getResources');
        options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
    });
    provide('resourceListDetails', {
      maxPage,
      currentPage,
      options
    });
    return {
        editResource,
        selectResource,
        removeResource,
        rows,
        maxPage,
        currentPage,
        selectedResource,
        helperTooltip,
        switchHelperTooltip,
        section,
        boxHeader,
        toggleSection,
        sortOrder,
        sortTable,
        sortTableByNumber,
        sortTableByAmount,
        selectByRow,
        approveRemoval,
        removeSelectedResource,
        removedResource,
        isAuth,
        resource,
        loadingFile
    };
  },
  components: { DropDownList }
})

</script>

<style lang="scss" scoped>
.submit-popup-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-message {
    background: #fff;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    // width: 100%;
    width: max-content;
    height: max-content;
    justify-content: space-around;
    align-items: center;
    position: relative;
    // border-radius: 11px;
    color: rgba(40,41,43,1);
    font-size: 21px;
    margin: 0 20px;
    padding: 25px;
    text-align: center;
    .close-button {
      position: absolute;
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 30px;
      height: 30px;
      background-image: url('@/assets/profile/close_button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      right: 10px;
      top: 22px;
      cursor: pointer;
    }
    .loader {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      position: relative;
      animation: rotate 1s linear infinite
    }
    .loader::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 5px solid #38ad8a;
      animation: prixClipFix 2s linear infinite ;
    }

    @keyframes rotate {
      100%   {transform: rotate(360deg)}
    }

    @keyframes prixClipFix {
        0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
        25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
        50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
        75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
        100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }
  }
}
</style>