<template>
  <div class="">
    User Agreement
  </div>
</template>

<script>

export default {
  name: "UserAgreement"
};
</script>

