<template>
  <HarvestHeader />
  <section class="section">
    <div class="border-box">
      <HarvestForm v-if="((editionMode === 'edit') || (editionMode === 'create')) && (isAuth('edit-harvest') || isAuth('add-harvest'))" />
      <HarvestFilled  v-if="(editionMode === 'represent') && (harvestTasks.length > 0)" />
      <HarvestList />
      <HarvestStatus />
    </div>
  </section>
</template>

<script>
import { ref, inject, reactive, provide, computed } from 'vue';
import { useStore } from "vuex";
import HarvestFilled from './HarvestFilled.vue';
import HarvestForm from './HarvestForm.vue';
import HarvestHeader from './HarvestHeader.vue';
import HarvestList from './HarvestList.vue';
import HarvestStatus from './HarvestStatus.vue'
import { useRouter } from 'vue-router';

export default {
    name: "HarvestModule",
    components: { HarvestHeader, HarvestForm, HarvestFilled, HarvestList, HarvestStatus },
    setup() {
      const store = useStore();
      const router = useRouter();

      const { isAuth } = inject('mainUI');

      const editionMode = ref('');

      const harvestTasks = computed(() => [...store.state.tasks].filter(stateTask => stateTask.harvestTask && (stateTask.taskStatus === 'Completed')));

      const harvest = reactive({
        id: '',
        date: '',
        farm: '',
        bed: '',
        crop: '',
        season: '',
        cropCycle: '',

        assignedTo: '',
        amtHarvested: '',
        wastage: '',
        wastageCause: '',
        comments: ''
      });

      const selectedHarvest = reactive({
        ...harvest
      });

      const defaultHarvest = {
        ...harvest
      }

      const addHarvest = () => {
        selectHarvest({...defaultHarvest}, 'create');
        // editionMode.value = 'create';
      }

      const addNewHarvest = () => {
        router.push({
          name: 'task', 
          params: {
            pagename: 'task',
            source: 'harvest-module'
          }
        })
      }

      const editHarvest = (field, val) => {
        harvest[field] = val;
      }

      const selectHarvest = (harvestData, editMode) => {
        if (harvestData) {
          for (let field in harvestData) {
            harvest[field] = harvestData[field]
            selectedHarvest[field] = harvestData[field]
          }
        }
        editionMode.value = editMode;
      }

      const removeHarvest = (harvestId) => {
        store.dispatch('removeTask', harvestId)
      };

      provide('harvestDetails', {
        harvest,
        defaultHarvest,
        selectedHarvest,
        editionMode,
        addNewHarvest,
        addHarvest,
        editHarvest,
        selectHarvest,
        removeHarvest
      })

      return {
        editionMode,
        isAuth,
        harvest,
        harvestTasks
      }
    }
}
</script>

<style lang="scss" scoped></style>