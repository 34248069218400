<template>
  <section class="section">
  
    <h3 class="dashboard-headding">My Farm</h3>
  
    <div class="dashboard-slider-wrap">
  
      <!-- <span class="slider-arrow slider-arrow-prev" @click="slide(-1)"></span>
      <span class="slider-arrow slider-arrow-next" @click="slide(1)"></span>
   -->
      <!-- <div class="dashboard-slider">
        <div class="swiper-wrapper">
        </div>
      </div> -->
      <swiper
        v-if="farms.length > 0"
        class="dashboard-slider"
        :modules="modules"
        :slides-per-view="1"
        :space-between="50"
        
        :pagination="{ clickable: true }"

        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
      <!--         :scrollbar="{ draggable: true, hide: true }" -->
      navigation
        <DashboardSliderNav />
      <!-- navigation -->
        <template v-for="farm in farms" v-bind:key="farm.id + 'dashboard-slide-farm'">
          <swiper-slide>
            <DashboardLocation 
              v-bind:farmCoords="{
                latitude: Number.parseFloat(farm.latitude), 
                longitude: Number.parseFloat(farm.longtitude)
                }"
              v-bind:farmName="{
                name: farm.farmName,
                city: farm.city,
                state: farm.state
              }"
            />
            <DashboardWeather 
              v-bind:farmCoords="{
                latitude: Number.parseFloat(farm.latitude), 
                longitude: Number.parseFloat(farm.longtitude)
                }" 
            />
          </swiper-slide>
        </template>
        <!-- <swiper-slide>
          <DashboardLocation />
          <DashboardWeather />
        </swiper-slide>
        <swiper-slide>
          <DashboardLocation />
          <DashboardWeather />
        </swiper-slide> -->
      </swiper>
    </div>
  
  </section>
  
  </template>
  
  <script>
  // import { onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import DashboardLocation from './DashboardLocation.vue';
  import DashboardWeather from './DashboardWeather.vue';
  import DashboardSliderNav from './DashboardSliderNav.vue';
  import { Navigation, Pagination } from 'swiper';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  
  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';
  import { onMounted, watch, ref } from 'vue'; //computed, 

  export default {
      name: "DashboardSlider",
      components: { DashboardLocation, DashboardWeather, Swiper, SwiperSlide, DashboardSliderNav },
      setup() {

        const store = useStore();

        const farms = ref(store.state.farms);
  
        const onSwiper = (swiper) => {
          console.log(swiper);
        };
        const onSlideChange = () => {
          console.log('slide change');
        };

        watch(() => ([...store.state.farms]), (newVal) => {
          if (newVal) {
            farms.value = newVal;
          }
        })

        watch(() => ({...store.state.authenticated}), (newVal) => {
          if (newVal) {
            store.dispatch('getFarms');
          }
        })

        onMounted(() => {
          store.dispatch('getFarms');
        });

        return {
          farms,
          onSwiper,
          onSlideChange,
          modules: [Navigation, Pagination ],
        };
        
      }
  }
  </script>
  
  <style lang="scss" scoped>
    .swiper-button-prev,
    .swiper-button-next {
      width: 3rem;
      height: 8rem;
      border-radius: 20rem;
      background: hsla(var(--black), 20%);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 99;
      cursor: pointer;
      &.swiper-button-prev {
        left: 1rem;
        background: url('@/assets/images/slider-arrow-left.png') center center no-repeat hsla(var(--black), 20%);
      }
      &.swiper-button-next {
        right: 1rem;
        background: url('@/assets/images/slider-arrow-right.png') center center no-repeat hsla(var(--black), 20%);
      }
    }
  </style>