<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">{{ equipment.item }}</h5>
<div class="icon-group">
<div class="icon green icon-o tooltip"
  @click="addNewEquipment"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
  data-tooltip="Add Equipment"
  v-if="isAuth('add-new-equipment')"
>
  <!-- <img src="@/assets/images/updated/add-item.png" alt=""> -->
  <img src="@/assets/images/updated/tractor-white.png" alt="">
</div>
<!-- <div class="icon green icon-o"
  @click="scheduleEquipment();"
  @mouseover="switchScheduleHelperTooltip(true)"
  @mouseout="switchScheduleHelperTooltip(false)"
>
  <img src="@/assets/images/excel-icon.svg" alt="">
  <div class="schedule-helper-tooltip" v-if="scheduleHelperTooltip">Schedule</div>
</div> -->
<div class="icon green icon-o tooltip"
  @click="selectEquipment({...equipment}, 'edit');"
  @mouseover="switchEditHelperTooltip(true)"
  @mouseout="switchEditHelperTooltip(false)"
  data-tooltip="Edit"
  v-if="isAuth('edit-equipment')"
>
  <img src="@/assets/images/updated/Edit.png" alt="">
</div>
</div>
</div>

<div class="content-block">
<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Date Purchased</p>
<h6>{{ moment(equipment.date, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Vendor</p>
<h6>{{ equipment.vendorName }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Item</p>
<h6>{{equipment.item}}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Manufacturer</p>
<h6>{{ equipment.manufacturer }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Brand</p>
<h6>{{ equipment.brand }}</h6>
</div>

<div class="intro-grid-column">
<p class="title"># of ltems</p>
<h6>{{ equipment.numberOfItems }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Invoice #</p>
<h6>{{ equipment.invoice }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Amount</p>
<h6>{{ equipment.amountPaid }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Warranty Exp</p>
<h6>{{ moment(equipment.warrantyExp, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Service Date</p>
<h6>{{ moment(equipment.serviceDate, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Vendor Phone</p>
<h6>{{ equipment.vendorPhone }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Vendor Contact</p>
<h6>{{ equipment.vendorContact }}</h6>
</div>

<div class="intro-grid-column intro-grid-comment-column">
<p class="title">Comments</p>
<h6>{{ equipment.comments }}</h6>
</div>

</div>
</div>

<div class="content-block">

<div class="content-section checkbox-group-section">

<p>Set Nofification:</p>

<div class="checkbox-group-wrap">

<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="serviceDate" v-model="equipment.serviceDateNotification" disabled><label for="serviceDate">Service Date</label></div>
<div class="field-box d-flex">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control xs" v-model="equipment.serviceDateNotificationDays" disabled>
</div>
</div>

<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="warrantyDate" v-model="equipment.warrantyDateNotification" disabled><label for="warrantyDate">Warranty Date</label></div>
<div class="field-box d-flex">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control xs" v-model="equipment.warrantyDateNotificationDays" disabled>
</div>
</div>

</div>


</div>
</div>

<div class="content-block">

  <FileAttachments :key="`${equipment.id}-equipment-attachments`" v-bind:filled="true" v-bind:section="'vendorItem'" v-bind:instance="`${equipment.id}`" />

<!-- <div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o">Cancel</button></div>
</div>
</div>
</div> -->

</div>

</div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { inject, ref } from 'vue';
import moment from 'moment';

export default {
    name: "EquipmentFilled",
    components: { FileAttachments },
    setup() {
      const { isAuth } = inject('mainUI');
      const { equipment, selectEquipment, addNewEquipment } = inject('equipmentDetails');

      const editHelperTooltip = ref(false);
      const scheduleHelperTooltip = ref(false);
      const helperTooltip = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const switchScheduleHelperTooltip = (toggle) => {
        scheduleHelperTooltip.value = toggle;
      }

      const switchEditHelperTooltip = (toggle) => {
        editHelperTooltip.value = toggle;
      }

      const scheduleEquipment = () => {
        console.log(equipment);
      }

      return {
        equipment,
        selectEquipment,
        editHelperTooltip,
        scheduleHelperTooltip,
        helperTooltip,
        switchHelperTooltip,
        switchScheduleHelperTooltip,
        switchEditHelperTooltip,
        scheduleEquipment,
        addNewEquipment,
        moment,
        isAuth
      }
    }
}
</script>

<style lang="scss" scoped>
.edit-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 40px;
  left: -30px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 41px;
    background: #3C3838;
    z-index: -1;
  }
}

.schedule-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 40px;
  left: -30px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 41px;
    background: #3C3838;
    z-index: -1;
  }
}
</style>