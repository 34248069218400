<template>
  <section class="section" ref="section">
  <div class="border-box active">
  
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5 class="fw-semibold">Sales</h5>
  <div class="info-icons">
    <div class="icon green icon-o tooltip"
      @mouseover="switchHelperTooltip(true)"
      @mouseout="switchHelperTooltip(false)"
      data-tooltip="Camera"
    >
      <img src="@/assets/images/updated/camera-white.png" alt="">
      <!-- <div class="helper-tooltip" v-if="helperTooltip">Take a shot</div> -->
    </div>
  </div>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>

  <div class="border-box-content">

  <div class="content-block">

  <div class="content-row">
  <div class="content-row grid-4 grid-full">
  <div class="row">

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.date || (sales.date.length > 0)}">Date</p>
  <input 
    type="date" 
    class="form-control date-field"
    :style="{color: (focused.date || sales.date.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
    v-model="sales.date"
    pattern="\d{2}/\d{2}/\d{4}"
    @focus="() => {
      activated.date = true;
      focused.date = true;
    }"
    @blur="() => {
      focused.date = false;
    }" 
  />
  <div class="date-icon"></div>
  <p v-if="activated.date && sales.date.length === 0" class="form-info red text-end">* Required</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.farm || (sales.farm.length > 0)}">Farm</p>
  <!-- <select class="form-control" 
    v-model="sales.farm"
    @click="() => {
      activated.farm = true;
      focused.farm = true;
    }"
    @change="() => {
      focused.farm = false;
    }"
  >
    <option
      v-for="farm in farms"
      v-bind:key="farm.farmName + 'farm-option-sales'"
    >{{ farm.farmName }}</option>
  </select> -->
  <DropDownList
    :mainObject="'sales'"
    :mainProperty="'farm'"
    :injectionName="'salesDetails'"
    :optionKey="'farm-option-sales'"
    :optionsList="farms"
    :optionProperty="'farmName'"
    :optionPropertyTwo="null"
    :defaultSelected="sales.farm"
    @click="() => {
      activated.farm = true;
    }"
  />
  <p v-if="activated.farm && sales.farm.length === 0" class="form-info red text-end">* Required</p>
  </div>
  </div>

  <div class="col-3 common" v-if="bedsOptions.length > 0">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.bed || (sales.bed.length > 0)}">Bed</p>
  <!-- <input type="text" class="form-control" 
    v-model="task.bed"
    @focus="() => {
      activated.bed = true;
      focused.bed = true;
    }"
    @blur="() => {
      focused.bed = false;
    }"> -->
    <!-- <select class="form-control" 
      v-model="sales.bed"
      @click="() => {
        activated.bed = true;
        focused.bed = true;
      }"
      @change="() => {
        focused.bed = false;
      }">
      <option
        v-for="bed in bedsOptions"
        v-bind:key="bed + 'bed-option-sales'"
      >{{ bed }}</option>
    </select> -->
    <DropDownList
      :mainObject="'sales'"
      :mainProperty="'bed'"
      :injectionName="'salesDetails'"
      :optionKey="'bed-option-sales'"
      :optionsList="[]"
      :optionProperty="null"
      :optionPropertyTwo="null"
      :defaultSelected="sales.bed"
      @click="() => {
        activated.bed = true;
      }"
    />
  <p v-if="activated.bed && sales.bed.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.bed && sales.bed.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.cropCategory || (sales.cropCategory.length > 0)}">Crop Category</p>
    <input 
      type="text" 
      class="form-control" 
      v-model="sales.cropCategory"
      @focus="() => {
        activated.cropCategory = true;
        focused.cropCategory = true;
      }"
      @blur="() => {
        focused.cropCategory = false;
      }"
      placeholder="E.g., Crop Category"
    />
    <p v-if="activated.cropCategory && sales.cropCategory.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
  </div>
  </div>

  <div class="col-3 common" v-if="crops.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.crop || (sales.crop.length > 0)}">Crop</p>
  <!-- <select class="form-control" 
    v-model="task.crop"
    @click="() => {
      activated.crop = true;
      focused.crop = true;
    }"
    @change="() => {
      focused.crop = false;
    }">
    <option
      v-for="cropItem in crops"
      v-bind:key="cropItem.id + 'crop-option-task'"
    >{{ cropItem.crop }}</option>
  </select> -->
  <DropDownList
    :mainObject="'sales'"
    :mainProperty="'crop'"
    :injectionName="'salesDetailsTwo'"
    :optionKey="'crop-option-sales'"
    :optionsList="[]"
    :optionProperty="'crop'"
    :optionPropertyTwo="null"
    :defaultSelected="sales.crop"
    @click="() => {
      activated.crop = true;
    }"
  />
  <!-- :optionsTwo="true" -->
<p v-if="activated.crop && sales.crop.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.crop && sales.crop.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

  <div class="col-3" v-else>
  <div class="form-item">
  <p class="form-label" :class="{active: focused.crop || (sales.crop.length > 0)}">Crop</p>
    <input 
      type="text" 
      class="form-control" 
      v-model="sales.crop"
      @focus="() => {
        activated.crop = true;
        focused.crop = true;
      }"
      @blur="() => {
        focused.crop = false;
      }"
      placeholder="E.g., Rice"
    />
    <p v-if="activated.crop && sales.crop.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.crop && sales.crop.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
  </div>
  </div>

  <div class="col-3 common">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.season || (sales.season.length > 0)}">Season</p>
  <!-- <select class="form-control"
    v-model="sales.season"
    @click="() => {
      activated.season = true;
      focused.season = true;
    }"
    @change="() => {
      focused.season = false;
    }"
    disabled
  >
    <option v-for="seasonOption in cropSeasons"
      v-bind:key="seasonOption + 'season-option-sales'"
    >{{ seasonOption }}</option>
  </select> -->
  <DropDownList
    :mainObject="'sales'"
    :mainProperty="'season'"
    :injectionName="'salesDetailsThree'"
    :optionKey="'season-option-sales'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :isDisabled="true"
    :defaultSelected="sales.season"
    @click="() => {
      activated.season = true;
    }"
  />
  <p v-if="activated.season && sales.season.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.season && sales.season.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
  </div>
  </div>

  <div class="col-3 common">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.cropCycle || (sales.cropCycle.length > 0)}">Crop Cycle</p>
  <input type="text" class="form-control"
    v-model="sales.cropCycle"
    @focus="() => {
      activated.cropCycle = true;
      focused.cropCycle = true;
    }"
    @blur="() => {
      focused.cropCycle = false;
    }" disabled />
  <p v-if="activated.cropCycle && sales.cropCycle.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.cropCycle && sales.cropCycle.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.variety || (sales.variety.length > 0)}">Variety</p>
  <input 
    type="text" 
    class="form-control" 
    pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
    v-model="sales.variety"
    @focus="() => {
      activated.variety = true;
      focused.variety = true;
    }"
    @blur="() => {
      focused.variety = false;
    }"
    placeholder="E.g., Grain"
  />
  <p v-if="activated.variety && sales.variety.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
  </div>
  </div>

  <!-- <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.customerType || (sales.customerType.length > 0)}">Customer Type</p>
  <input 
    v-if="insertTypeManually"
    type="text" 
    class="form-control" 
    v-model="sales.customerType"
    @focus="() => {
      activated.customerType = true;
      focused.customerType = true;
    }"
    @blur="() => {
      focused.customerType = false;
    }"
  />
  <select class="form-control" 
    v-else
    v-model="sales.customerType"
    @click="() => {
      activated.type = true;
      focused.type = true;
    }"
    @change="() => {
      focused.type = false;
    }"
  >
    <option
      v-for="typeOption in typeOptions"
      v-bind:key="typeOption + 'customer-type-option-sales'"
    >{{ typeOption }}</option>
  </select>

  <p v-if="activated.customerType && sales.customerType.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.customerType && sales.customerType.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
  </div>
  </div> -->

  <!-- <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.customer || (sales.customer.length > 0)}">Customer</p>
  <select class="form-control" 
    v-if="customers.length > 0"
    v-model="sales.customer"
    @click="() => {
      activated.customer = true;
      focused.customer = true;
    }"
    @change="() => {
      focused.customer = false;
    }"
  >
    <option
      v-for="customerOption in customers"
      v-bind:key="customerOption + 'customer-option-sales'"
    >{{ customerOption.customer }}</option>
  </select>
  
  <input
    v-else
    type="text" 
    class="form-control"
    v-model="sales.customer"
    @focus="() => {
      activated.customer = true;
      focused.customer = true;
    }"
    @blur="() => {
      focused.customer = false;
    }"
  />
  <p v-if="activated.customer && sales.customer.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.customer && sales.customer.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
  </div>
  </div> -->

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.quantitySold || (`${sales.quantitySold}`.length > 0)}">Quantity Sold (In lbs.)</p>
  <input 
    type="number" 
    class="form-control"
    v-model="sales.quantitySold"
    @focus="() => {
      activated.quantitySold = true;
      focused.quantitySold = true;
    }"
    @blur="() => {
      focused.quantitySold = false;
    }"
    placeholder="E.g., 25"
  />
  <p v-if="activated.quantitySold && sales.quantitySold.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.quantitySold && `${sales.quantitySold}`.length > 30" class="form-info red text-end">Please use maximum 30 characters</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.rate || (sales.rate.length > 0)}">Rate (In lbs.)</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input 
      type="text" 
      class="form-control"
      v-model="sales.rate"
      @focus="() => {
        activated.rate = true;
        focused.rate = true;
      }"
      @blur="() => {
        focused.rate = false;
      }"
      placeholder="E.g., 2.75"
    />
  </div>
  <p v-if="activated.rate && sales.rate.length === 0" class="form-info red text-end">* Required</p>
  <!-- <p v-if="activated.rate && `${sales.rate}`.length > 5" class="form-info red text-end">Please use maximum 5 characters</p> -->
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.total || (`${sales.total}`.length > 0)}">Total</p>
  <div class="dollar-prefix">
    <span>$</span>
    <input 
      type="text" 
      class="form-control"
      v-model="sales.total"
      @focus="() => {
        activated.total = true;
        focused.total = true;
      }"
      @blur="() => {
        focused.total = false;
      }"
      placeholder="E.g., 68.75"
    />
  </div>
  <p v-if="activated.total && sales.total.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.total && sales.total.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
  </div>
  </div>

  <div class="col-12">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.comments || (sales.comments.length > 0)}">Comments</p>
  <textarea class="form-control" 
    v-model="sales.comments"
    @focus="() => {
      activated.comments = true;
      focused.comments = true;
    }"
    @blur="() => {
      focused.comments = false;
    }"
    placeholder="E.g., First Sales"
  ></textarea>
  <p v-if="activated.comments && sales.comments.length > 150" class="form-info red text-end">Please use maximum 150 characters</p>
  </div>
  </div>

  </div>
  </div>
  </div>

  </div>

  <div class="content-block">
  
    <FileAttachments :key="`sales-form-${sales.id}`" v-bind:section="'sales'" v-bind:instance="`${sales.id}`" />

    <div class="content-section">
      <div class="buttons">
      <div class="row">
      <div class="col-auto"><button type="submit" @click="submitForm" class="btn green">Save</button></div>
      <div class="col-auto"><button type="submit" @click="cancelChanges" class="btn light-blue-20 btn-o">Cancel</button></div>
      </div>
      </div>
    </div>
  </div>

  </div>
  </div>
  </section>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { defineComponent, reactive, inject, ref, computed, watch, onMounted, provide } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import stateOptions from '@/sharedData/statesList.js'
import countryOptions from '@/sharedData/countriesList.js'
import DropDownList from '@/components/Shared/DropDownList.vue';

const typeOptions = [
  'Individual',
  'Business',
  'Farmers Market',
  'Restaurant',
  'Non-Profit',
  'Government',
  'Facebook',
  'Other'
]

export default defineComponent({
    name: "SalesForm",
    setup() {
      const store = useStore();

      const cropSeasons = ref(['Spring', 'Summer', 'Autumn', 'Winter']);

      const activated = reactive({
        date: false,
        farm: false,
        cropCategory: false,
        crop: false,
        variety: false,
        // customerType: false,
        // customer: false,
        quantitySold: false,
        rate: false,
        total: false,
        comments: false
      });

      const focused = reactive({
        date: false,
        farm: false,
        cropCategory: false,
        crop: false,
        variety: false,
        // customerType: false,
        // customer: false,
        quantitySold: false,
        rate: false,
        total: false,
        comments: false
      });

      const { customer } = inject('customerDetails');

      const { 
        sales, 
        salesEditionMode, 
        selectedSales, 
        selectSales, 
        defaultSales,
        options: bedsOptions
      } = inject("salesDetails");

      const section = ref(null);
      const boxHeader = ref(null);
      const sectionHeight = ref(0);
      const sectionExpanded = ref(true);

      const insertTypeManually = ref(false);

      const farms = computed(() => store.state.farms);

      const customers = computed(() => store.state.customers);

      const crops = ref(store.state.crops);

      const newId = ref(null);
 
      const helperTooltip = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const toggleSection = (ev) => {
        if (ev.target.tagName === 'IMG') {
          if (ev.target.className !== 'angle') {
            return;
          }
        }
        sectionExpanded.value = !sectionExpanded.value;
      };

      const submitForm = () => {

        if (
          !sales.date || !sales.farm || !sales.crop || //!sales.customerType || 
          //!sales.customer || 
          !sales.quantitySold || !sales.rate || !sales.total
        ) {
          if (!sales.date) activated.date = true;
          if (!sales.farm) activated.farm = true;
          if (!sales.crop) activated.crop = true;
          // if (!sales.customerType) activated.customerType = true;
          // if (!sales.customer) activated.customer = true;
          if (!sales.quantitySold) activated.quantitySold = true;
          if (!sales.rate) activated.rate = true;
          if (!sales.total) activated.total = true;

          return;
        }

        if (!sales.id) {
          // sales.id = uuid();
          sales.id = newId.value;
        }

        const formData = {
          ...sales,
          customer: customer.customer,
          customerId: customer.id
        };

        (salesEditionMode.value === "edit") ?
          store.dispatch("editSales", { ...formData })
          :
          store.dispatch("createSales", {...formData});

        // (customers.value.length === 0) && 
        //   store.dispatch("createCustomer", {customer: formData.customer});

        (salesEditionMode.value === "create") && closeForm();
        (salesEditionMode.value === "edit") && selectSales({...formData}, 'represent');
      }

      const closeForm = () => {

        for (let prop in sales) {
          sales[prop] = defaultSales[prop];
        }

        for (let prop in activated) {
          activated[prop] = false;
        }
        
        for (let prop in focused) {
          focused[prop] = false;
        }
      }

      const cancelChanges = () => {
        if (salesEditionMode.value === "edit") {
          for (let prop in sales) {
            sales[prop] = selectedSales[prop];
          }
          salesEditionMode.value = "represent";
        } else {
          for (let prop in sales) {
            sales[prop] = defaultSales[prop];
          }
          salesEditionMode.value = "";
        }
      };
      
      watch(() => ({...sales}), (newVal, oldVal) => {
        // if (newVal?.farm) {
        //   const selectedFarm = farms.value.find(frm => frm.farmName === newVal.farm);

        //   sales.crop = selectedFarm.primaryCrop;
        // }

        if (newVal?.farm) {
          const selectedFarm = farms.value.find(frm => frm.farmName === newVal.farm);

          const selectedFarmBeds = selectedFarm.beds.filter((bed, index) => bed.bedNumber !== selectedFarm.beds[index - 1]?.bedNumber)

          bedsOptions.value = selectedFarmBeds.map(bed => bed.bedNumber);

          if (bedsOptions.value.length > 0) {
            if (!newVal.bed) {
              sales.bed = selectedFarm.beds[0].bedNumber

              crops.value = [...store.state.crops.filter(crp => crp.sourceId === selectedFarm.id)];
              cropSeasons.value = crops.value.map(crop => crop.season);

              sales.crop = '';
              sales.cropCycle = '';
              sales.season = '';
              sales.cropCategory = '';
            } else {
              if (newVal.farm !== oldVal.farm) {
                sales.bed = selectedFarm.beds[0].bedNumber || ''
              }
            }
            if (newVal.bed !== oldVal.bed) {
              const selectedBed = selectedFarm.beds.find(bed => bed.bedNumber === newVal.bed);
              crops.value = [...store.state.crops.filter(crp => crp.sourceId === selectedBed.sourceId)];
              if (crops.value.length > 0) {
                sales.crop = crops.value[0].crop || '';
                sales.cropCycle = crops.value[0].cropCycle || '';
                sales.season = crops.value[0].season || '';
                sales.cropCategory = crops.value[0].cropCategory || '';
              }
            }
            if (newVal.season !== oldVal.season) {
              crops.value = [...store.state.crops.filter(crp => crp.season === newVal.season)];
              cropSeasons.value = crops.value.map(crop => crop.season);

              if (crops.value.length > 0) {
                sales.crop = crops.value[0].crop || '';
                sales.cropCycle = crops.value[0].cropCycle || '';
                sales.cropCategory = crops.value[0].cropCategory || '';
              }
            }
          } else {
            if (newVal.farm !== oldVal.farm) {
              sales.bed = '';
              crops.value = [...store.state.crops.filter(crp => crp.sourceId === selectedFarm.id)];
              cropSeasons.value = crops.value.map(crop => crop.season);

              if (crops.value.length > 0) {
                sales.crop = crops.value[0].crop || '';
                sales.cropCycle = crops.value[0].cropCycle || '';
                sales.season = crops.value[0].season || '';
                sales.cropCategory = crops.value[0].cropCategory || '';
              } else {
                sales.crop = '';
                sales.cropCycle = '';
                sales.season = '';
                sales.cropCategory = crops.value[0].cropCategory || '';
              }
            }
            if (newVal.season !== oldVal.season) {
              crops.value = [...store.state.crops.filter(crp => crp.season === newVal.season)];
              if (crops.value.length > 0) {
                sales.crop = crops.value[0].crop || '';
                sales.cropCycle = crops.value[0].cropCycle || '';
                sales.cropCategory = crops.value[0].cropCategory || '';
              }
            }
          }
        }

        if (newVal.customerType === 'Other') {
          insertTypeManually.value = true;
          setTimeout(() => {
            sales.customerType = ''
          }, 10)
        }

        if (newVal.quantitySold && newVal.rate) {
          sales.total = Math.abs(
            Number.parseInt(newVal.quantitySold) * 
            Number.parseFloat(newVal.rate)
          ).toFixed(2)
        }
      })

      watch(() => (focused.total), (newVal, oldVal) => {
        if (oldVal && !newVal) {
          const fixedAmount = `${Number.parseFloat(sales.total).toFixed(2)}`;
          const formattedAmount = (fixedAmount.length > 7) ? 
            `${fixedAmount.slice(0,-7)}, ${fixedAmount.slice(-7,-5)}, ${fixedAmount.slice(-5)}` : 
            (fixedAmount.length > 5) ? `${(fixedAmount.length === 7) ? fixedAmount.slice(-8,-5) : fixedAmount.slice(-7,-5)}, ${fixedAmount.slice(-5)}` :
            `${fixedAmount}`
          sales.total = formattedAmount;
        }
      })

      watch(() => (focused.rate), (newVal, oldVal) => {
        if (oldVal && !newVal) {
          const fixedAmount = `${Number.parseFloat(sales.rate).toFixed(2)}`;
          const formattedAmount = (fixedAmount.length > 7) ? 
            `${fixedAmount.slice(0,-7)}, ${fixedAmount.slice(-7,-5)}, ${fixedAmount.slice(-5)}` : 
            (fixedAmount.length > 5) ? `${(fixedAmount.length === 7) ? fixedAmount.slice(-8,-5) : fixedAmount.slice(-7,-5)}, ${fixedAmount.slice(-5)}` :
            `${fixedAmount}`
          sales.rate = formattedAmount;
        }
      })

      watch(sectionExpanded, (newVal) => {
        section.value.style.height = newVal ?
          `${sectionHeight.value}px` :
          `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
      });

      onMounted(() => {
        
        setTimeout(() => {
          if (section.value) {
            sectionHeight.value = section.value.offsetHeight;
            section.value.style.height = `${section.value.offsetHeight}px`;
          }
        });

        sales.farm  = farms.value[0]?.farmName || ''

        if (salesEditionMode.value !== 'edit') {
          if (!sales.id) {
            newId.value = uuid();
            sales.id = newId.value;
          }
        }
      })

      provide('salesDetailsTwo', {
        sales,
        options: crops
      })

      provide('salesDetailsThree', {
        sales,
        // options: bedsOptions, 
        // optionsTwo: crops
        options: cropSeasons 
      })

      return {
        activated,
        focused,
        sales,
        farms,
        bedsOptions,
        crops,
        cropSeasons,
        customers,
        insertTypeManually,
        helperTooltip,
        stateOptions,
        countryOptions,
        typeOptions,
        switchHelperTooltip,
        submitForm,
        cancelChanges,
        section,
        boxHeader,
        toggleSection
      }
    },
    components: { FileAttachments, DropDownList }
})
</script>

<style lang="scss" scoped>
.form-control {
  &.date-field {
    padding-right: 1.8rem;
    background: none;
  }
}
.date-icon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 3.7rem;
  right: 1rem;
  z-index: 8;
  pointer-events: none;
  border-radius: 1rem;
  background:url('@/assets/images/calendar-icon.svg') center center no-repeat;
  background-color: #fff;
}
</style>