<template>
  <ResourcesHeader />
  <!-- <section class="section"> -->

<!-- <div class="tab-content farms-content" id="one"> -->

<section class="section">
<div class="border-box">
<div class="border-box-content">

  <div class="content-block d-flex justify-content-between">
      <h5 class="fw-semibold">Resource Center</h5>
      <div class="icon-group">
        <div class="info-icon lg header-icon tooltip"
          @click="addResource"
          data-tooltip="Add Resource"
        >
          <img src="@/assets/images/updated/new_set2/search_green_circle.png" alt="">
        </div>
      </div>
  </div>

  <ResourcesUserForm v-if="((editionMode === 'edit') || (editionMode === 'create'))" />
<!--  && (isAuth('edit-resource') || isAuth('add-resource')) -->
  <ResourcesUserList />
  
  <OpenedFile />

</div>
</div>
</section>
<!-- </div> -->
</template>

<script>
import { defineComponent, ref, inject, reactive, provide } from 'vue';
import ResourcesHeader from './ResourcesHeader.vue';
import ResourcesUserForm from './ResourcesUserForm.vue';
import ResourcesUserList from './ResourcesUserList.vue';
import { useStore } from "vuex";
import OpenedFile from "./OpenedFile/OpenedFile.vue";

export default defineComponent({
  name: 'ResourcesUser',
  setup() {
    const store = useStore();

    const { isAuth } = inject('mainUI');

    const editionMode = ref('');

    const resource = reactive({
      id: '',
      file: '',
      fileType: '',
      title: '',
      subject: '',
      category: '',
      created: '',
      size: '',
      link: ''
    });

    const selectedResource = reactive({
      ...resource
    });

    const defaultResource = {
      ...resource
    }

    const addResource = () => {
      selectResource({...defaultResource}, 'create');
    // editionMode.value = 'create';
    }

    const editResource = (field, val) => {
      resource[field] = val;
    }

    const selectResource = (resourceData, editMode) => {
      if (resourceData) {
        for (let field in resourceData) {
          resource[field] = resourceData[field]
          selectedResource[field] = resourceData[field]
        }
      }
      editionMode.value = editMode;
    }

    const removeResource = (resourceDataId) => {
      store.dispatch('removeResource', resourceDataId)
    };

    provide('resourceDetails', {
      resource,
      defaultResource,
      selectedResource,
      editionMode,
      addResource,
      editResource,
      selectResource,
      removeResource
    })

    return {
      editionMode,
      isAuth,
      resource,
      addResource
    }
  },
  components: { ResourcesHeader, ResourcesUserForm, ResourcesUserList, OpenedFile }
})
</script>