const options = [
  'None',
  'Barbed Wire',
  'Board Fence',
  'Cattle Fence',
  'Crossbuck',
  'Deer Fence',
  'Electric Fence',
  'Fence Posts',
  'Goat Fence',
  'High Tensile Wire',
  'Horse Fence',
  'Netting',
  'Post and Rail',
  'Split-rail Fence',
  'Welded Wire',
  'Wire Fencing',
  'Wood Fence',
  'Woven Wire',
  'Other'
];

export default options;
