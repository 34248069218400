const rates = {
  basic: 25,
  // inventory: 10,
  // equipment: 10,
  // allinclusive: 40,
  plus: 40,
  advance: 55,
  ultimate: 65,
  tax: 6.87
};

export default rates;