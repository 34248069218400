<template>
  <div class="">
    Cookie Policy
  </div>
</template>

<script>

export default {
  name: "CookiePolicy"
};
</script>

