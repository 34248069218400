<template>
  <div class="farm-mapping">
    <div class="map-container">
      <div class="remove-all-button" 
        style="position: absolute; padding: 0; width: 36px; height: 30px;border: none; top: -40px; margin-right: 0.6rem;"
        data-tooltip="Erase All"
        @click="eraseContent"
        @mouseover="switchToolsHelper('Erase-All', true)"
        @mouseout="switchToolsHelper('Erase-All', false)"
        v-if="!history"
      >
        <!-- <img src="@/assets/images/updated/delete-item.png" alt=""> -->
      </div>
      <div class="button-set button-set-filled">
        <div class="helper" :class="toolsHelper" v-if="(toolsHelper.length > 0) && !history">
          {{(toolsHelper === 'Erase-All') ? `${toolsHelper.split('-')[0]} ${toolsHelper.split('-')[1]}` : toolsHelper }}
        </div>
      </div>
      <svg class="map-farm" :viewBox="viewBox"></svg>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
// import simplify from 'simplify-js';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'FarmDrawSchemeFilled',
  inject: ['bedEdition'],
  props: ['history'],
  data() {
    return {
      dragging: false,
      drawing: false,
      stroke_color: '#444',
      stroke_width: 3,
      eraser_radius: 20,
      mode: '',
      tooltips: ref([]),
      toolsHelper: ''
    }
  },
  computed: {
    viewBox() {
      return `0 0 ${960} ${500}`;
    }
  },
  methods: {
    // old methods with tooltip appearance and click
    // insertTooltip(points) {
    //   console.log(points);
    //   this.tooltips.push({points, id: this.tooltips.length})
    // },
    // addBedProfile(points) {
    //   this.bedEdition.editMap('imageData', points);
    //   this.bedEdition.editionMode.value = 'create';
    // },
    eraseContent() {
      const self = this;
      this.bedEdition.editMap('imageData', []);
      this.bedEdition.editMap('imageParams', {
        stroke_color: this.stroke_color,
        stroke_width: this.stroke_width
      });
      self.ptdata = [];
      self.session = [];
      this.bedEdition.editMap('linesData', []);
      this.bedEdition.editMap('linesParams', {
        stroke_color: this.stroke_color,
        stroke_width: this.stroke_width
      });
      self.straightLinesList = [];
      this.bedEdition.editMap('textsData', []);
      this.bedEdition.editMap('textsParams', {
        stroke_color: this.stroke_color
      });
      self.insertedTexts = [];
      d3.select('#svg-texts').selectAll('text').remove();
      d3.select('#paths').selectAll('path').remove();
      d3.select('#lines').selectAll('path').remove();
    },
    switchToolsHelper(tool, mode) {
      if (mode) {
        this.toolsHelper = tool;
      } else {
        this.toolsHelper = '';
      }
    },
    insertTooltip(points) {
      console.log(points);
      this.bedEdition.editMap('imageData', points);
    },
    turnOnDraw () {
      this.mode = 'draw';
    },
    turnOnErase () {
      this.mode = 'erase';
    },
    selectStrokeWidth (ev) {
      this.stroke_width = ev.target.value;
    },
    selectEraserRadius (ev) {
      this.eraser_radius = ev.target.value;
    },
    selectColor(ev) {
      this.stroke_color = ev.target.value;
    },
    svg(pointsArray, linesArray, textsArray) {

      console.log(pointsArray, 'pointsArray');

      const self = this;

      var margin = {top: 0, right: 0, bottom: 0, left: 0},
          width = 960 - margin.left - margin.right,
          height = 500 - margin.top - margin.bottom;

        // var eraseLine = d3.line()
        //       .x(function(d) { return d[0] })
        //       .y(function(d) { return d[1] })
        //       .curve(d3.curveBundle.beta(1)),
        //   paths = [...pointsArray] || [],
        //   erase_path = {};

      this.mode = 'draw';

      // var npoints = 100;
      var ptdata = [...pointsArray] || [];
      var lndata = [...linesArray] || [];
      var txdata = [...textsArray] || [];
      // var session = [...pointsArray] || [];
      // var path;
      // var drawing = false;
      // var erasing = false;

      // var output = d3.select('#output');

      var line = d3.line()
          // .interpolate("bundle") // basis, see http://bl.ocks.org/mbostock/4342190
          // .tension(1)
          .x(function(d) { return d[0] /*d.x;*/ })
          .y(function(d) { return d[1] /*d.y;*/ })
          .curve(d3.curveBundle.beta(1));

      var svg = d3.select("svg.map-farm")
          // .attr("width", width + margin.left + margin.right)
          // .attr("height", height + margin.top + margin.bottom)


      // -----------------


      // if (mode === 'erase') {
          // var mtouch = mtouch_events()
              // .on('touch', touch)
              // .on('drag', drag)
              // .on('release', release);
          svg.selectAll('g').remove();

          var g_paths = svg.append("g")
              .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
              .attr('id', 'paths');

          var g_straight_lines = svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .attr('id', 'lines');

          var g_texts = svg.append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
            .attr('id', 'texts');

          // var overlay = svg.append('rect')
          svg.append('rect')
            .style('fill', 'none')
            .style('pointer-events', 'all')
            .attr("width", width)
            .attr("height", height)
              
          // d3.select('rect')
          //     .on('mousedown', touch)
          //     .on('touchstart', touch)
          //     // .on('touchmove', drag)
          //     // .on('mousemove', drag)
          //     .on('touchend', release)
          //     // .on('touchleave', release)
          //     .on('mouseup', release)
          //     // .on('mouseleave', release);
          //     // .call(mtouch);
        
          // var g_erase = svg.append('g')
          //     .attr('id', 'erase');

          // mtouch.frame(overlay.node());
      // }

      // if (mode === 'draw') {

          // svg
          //   .on("mousedown", listen)
          //   .on("touchstart", listen)
          //   .on("touchend", ignore)
          //   .on("touchleave", ignore)
          //   .on("mouseup", ignore)
          //   .on("mouseleave", ignore);


          // ignore default touch behavior
          var touchEvents = ['touchstart', 'touchmove', 'touchend'];
          touchEvents.forEach(function (eventName) {
            document.body.addEventListener(eventName, function(e){
              e.preventDefault();
            });  
          });
      // }

      for (let onePath of ptdata) {
        g_paths.append("path") // start a new line
          // .data([ptdata])
          .data([onePath])
          .attr("class", "line")
          .attr("d", line)
          .style('stroke', self.stroke_color)
          .style('stroke-width', self.stroke_width);
      }

      for (let oneLine of lndata) {
        g_straight_lines.append("path") // start a new line
          // .data([ptdata])
          .data([oneLine])
          .attr("class", "line")
          .attr("d", line)
          .style('stroke', self.stroke_color)
          .style('stroke-width', self.stroke_width);
      }

      for (let oneText of txdata) {
        g_texts
          .append("text")
          .attr("x", oneText.points[0])
          .attr("y", oneText.points[1])
          .style("fill", self.stroke_color)
          .style("font-size", "18px")
          .style("font-family", "Gilroy-Semibold")
          .text(oneText.text);
      }

      // function listen (ev) {
      //   if (self.mode === 'erase') return;
      //   drawing = true;
      //   output.text('event: ' + ev.type + '; mode: ' + self.mode);
      //   ptdata = []; // reset point data
      //   path = g_paths.append("path") // start a new line
      //     .data([ptdata])
      //     .attr("class", "line")
      //     .attr("d", line)
      //     .style('stroke', self.stroke_color)
      //     .style('stroke-width', self.stroke_width);

      //   paths.push(ptdata);

      //   if (ev.type === 'mousedown') {
      //     svg.on("mousemove", onmove);
      //   } else {
      //     svg.on("touchmove", onmove);
      //   }
      // }

      // function ignore (ev) {
      //     console.log(ev, 'works instead of');
      //   if (self.mode === 'erase') return;
      //   var before, after;
      //   output.text('event: ' + ev.type);
      //   svg.on("mousemove", null);
      //   svg.on("touchmove", null);

      //   // skip out if we're not drawing
      //   if (!drawing) return;
      //   drawing = false;

      //   before = ptdata.length;
      //   console.group('Line Simplification');
      //   console.log("Before simplification:", before)
        
      //   // simplify
      //   ptdata = simplify(ptdata);
      //   after = ptdata.length;

      //   console.log("After simplification:", ptdata.length)
      //   console.groupEnd();

      //   var percentage = parseInt(100 - (after/before)*100, 10);
      //   output.html('Points: ' + before + ' => ' + after + '. <b>' + percentage + '% simplification.</b>');

      //   // add newly created line to the drawing session
      //   session.push(ptdata);
        
      //   // redraw the line after simplification
      //   tick();

      //   self.insertTooltip(ptdata);
      // }


      // function onmove (e) {
      //   var type = e.type;
      //   var point;

      //   // if (type === 'mousemove') {
      //     point = d3.pointer(e);
      //     output.text('event: ' + type + ': ' + d3.pointer(e));
      //   // } else {
      //     // only deal with a single touch input
      //     // point = d3.touches(this)[0];
      //     // output.text('event: ' + type + ': ' + d3.touches(this)[0]);
      //   // }

      //   // push a new data point onto the back
      //   // ptdata.push({ x: point[0], y: point[1] });
      //   ptdata.push(point);
      //   tick();
      // }

      // function tick() {
      //   path.attr("d", function(d) { return line(d); }) // Redraw the path:
      // }



      // function touch(ev) {
      //     if (self.mode === 'draw') return;
      //     // var f = ;
      //     erasing = true;
      //     // erase_path.data = [[ev.x, ev.y]];
      //     // erase_path.data = [[ev.offsetX, ev.offsetY]];
      //     erase_path.data = [[ d3.pointer(ev)[0], d3.pointer(ev)[1] ]];

      //     if (!erase_path.el) {
      //         erase_path.el = g_erase.append('path')
      //                       .style('fill', 'none')
      //                       .style('stroke', 'gray')
      //                       .style('opacity', 0.3)
      //                       .style('stroke-width', self.eraser_radius*2)
      //                       .style('stroke-linecap', 'round')
      //                       .style('stroke-linejoin', 'round');
      //     } else {
      //         erase_path.el.style('stroke', 'gray');
      //     }
      //     erase_path.el.datum(erase_path.data)
      //                 .attr('d', function(d) { return eraseLine(d) + 'Z'});

      //     d3.select('rect')
      //         .on('touchmove', drag)
      //         .on('mousemove', drag);
      // }

      // function drag(e) {

      //   if (self.mode === 'draw') return;
      //     // var f = d3.event.finger;
      //   // if (!erasing) return;
      //   // erase_path.data.push([e.x, e.y]);
      //   // erase_path.data.push([e.offsetX, e.offsetY]);
      //   erase_path.data.push([d3.pointer(e)[0], d3.pointer(e)[1]]);

      //   erase_path.el.attr('d', eraseLine);

      //   g_erase.select('path').on('mouseup', release)
      // }

      // function release() {
      //   if (self.mode === 'draw') return;

      //     d3.select('rect')
      //         .on('touchmove', null)
      //         .on('mousemove', null);

      //   if (!erasing) return;
      //   erasing = false;
      //     paths = self.$erase({sourceArr: paths, resultArr: erase_path.data});
      //     update(paths);
      //     g_erase.select('path').style('stroke', 'transparent');
      // }


      // function update(paths) {
      //     console.log(paths);
      //     var p = g_paths.selectAll('path')
      //       .data(paths);

      //     p.enter()
      //     .append('path')
      //     .style('fill', 'none')
      //     .style('stroke', self.stroke_color)
      //     .style('stroke-width', self.stroke_width)
      //     .style('stroke-linecap', 'round')
      //     .style('stroke-linejoin', 'round')
      //     .attr('d', eraseLine);

      //     p.attr('d', eraseLine);

      //     p.exit().remove();
      // }

    },
  },
  watch: {
    bedEdition: {
      handler: function (new_client, old_client) {
        console.log(new_client, old_client, 'new_client, old_client');

        const bedPoints = this.bedEdition.mapDraw.imageData;
        const bedLines = this.bedEdition.mapDraw.linesData;
        const bedTexts = this.bedEdition.mapDraw.textsData;

        if (this.bedEdition.mapDraw.imageParams) {
          this.stroke_color = this.bedEdition.mapDraw.imageParams.stroke_color;
          this.stroke_width = this.bedEdition.mapDraw.imageParams.stroke_width;
        }

        this.svg(bedPoints, bedLines, bedTexts);
      },
      deep: true
    }
  },
  mounted() {
    const bedPoints = this.bedEdition.mapDraw.imageData;
    const bedLines = this.bedEdition.mapDraw.linesData;
    const bedTexts = this.bedEdition.mapDraw.textsData;

    if (this.bedEdition.mapDraw.imageParams) {
      this.stroke_color = this.bedEdition.mapDraw.imageParams.stroke_color;
      this.stroke_width = this.bedEdition.mapDraw.imageParams.stroke_width;
    }

    this.svg(bedPoints, bedLines, bedTexts);
  }
});
</script>

<style lang="scss">
.farm-mapping {
  width: 100%;
  margin-bottom: 1.25rem;
  svg {
  background: #ddd;
  font: 10px sans-serif;
  cursor: crosshair;
}

.line {
  cursor: crosshair;
  fill: none;
  stroke: #000;
  stroke-width: 2px;
  stroke-linejoin: round;
}

#output {
  position: relative;
  top: -2em;
  left: 0.67em;
  font: 12px/1.4 monospace;
}
.map-container {
  position: relative;
  .tooltip {
    // border: 3px solid #38AC89;
    border-bottom: 3px solid #38AC89;
    color: #38AC89;
    // border-radius: 10px;
    // background-color: #fff;
    position: absolute;
    width: max-content;
    padding: 0 5px;
    height: 20px;
    z-index: 9;
    font-weight: 500;
    cursor: pointer;
  }
}

    .button-set {
      display: flex;
      position: absolute;
      // right: 0;
      // top: -50px;
      right: 144px;
      top: -46px;
      // width: 20%;
      &.button-set-filled {
        top: -10px;
      }
      span {
        display: flex;
        cursor: pointer;
      }
      .tools {
        display: flex;
        text-indent: -9999px;
        -webkit-mask-position: center;
        mask-position: center;
        margin: auto;
        &.pen {
          width: 36px;
          height: 30px;
          -webkit-mask-image: url('@/assets/farm_profile/pencil.svg');
          mask-image: url('@/assets/farm_profile/pencil.svg');
          -webkit-mask-size: 36px 30px;
          mask-size: 36px 30px;
          background-color: rgba($color: #000000, $alpha: 0.2);
          &.active {
            background-color: rgba($color: #000000, $alpha: 1.0);
          }
        }
        &.erase {
          width: 36px;
          height: 30px;
          -webkit-mask-image: url('@/assets/farm_profile/eraser.svg');
          mask-image: url('@/assets/farm_profile/eraser.svg');
          -webkit-mask-size: 36px 30px;
          mask-size: 36px 30px;
          background-color: rgba($color: #000000, $alpha: 0.2);
          &.active {
            background-color: rgba($color: #000000, $alpha: 1.0);
          }
        }
        &.brush {
          width: 36px;
          height: 30px;
          -webkit-mask-image: url('@/assets/farm_profile/brush.svg');
          mask-image: url('@/assets/farm_profile/brush.svg');
          -webkit-mask-size: 36px 30px;
          mask-size: 36px 30px;
          background-color: rgba($color: #000000, $alpha: 0.2);
          &.active {
            background-color: rgba($color: #000000, $alpha: 1.0);
          }
        }
        &.colors {
          // display: none;
          width: 30px;
          height: 30px;
          background-image: url('@/assets/farm_profile/colorwheel.png');
          background-size: contain;
          background-repeat: no-repeat;
          position: relative;
          input {
            opacity: 0;
            width: 100%;
            height: 100%;
            z-index: 999;
            cursor: pointer;
          }
        }
      }
      // .stroke-range {
      //   display: none;
      // }
      // .eraser-radius {
      //   display: none;
      // }
    }
}

.remove-all-button {
  display: flex;
  position: absolute;
  // left: 10.8rem;
  left: auto;
  right: 36px;
  top: -3.8rem;
  width: 36px;
  height: 30px;
  -webkit-mask-size: 36px 30px;
  mask-size: 36px 30px;
  background-color: rgba($color: #000000, $alpha: 0.2);
  -webkit-mask-image: url('@/assets/images/delete_design.svg');
  mask-image: url('@/assets/images/delete_design.svg');
  &:hover {
    background-color: #000000;
  }
}
</style>