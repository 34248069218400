<template>
    <FarmProfileHeader />

    <div ref="farmForm"></div>
    <FarmProfileForm v-if="((formMode === 'edit') || (formMode === 'create')) && (isAuth('edit-farm') || isAuth('add-farm') || isAuth('add-farm-crop'))" />
    <FarmProfileFilled v-if="formMode === 'represent'" /> 

    <div ref="farmPlanCropListSection"></div>
    <FarmPlanCropList :key="`farm-croplist-${farm.id}`" v-if="(crops.length > 0) && (farm.beds.length === 0)" />

    <div ref="bedForm"></div>
    <FarmMap v-if="bedsCreation || ((formMode === 'represent') && (farm.beds.length > 0))" />
    <FarmList />

    <div class="add-new-popup" v-if="popups.farmCreated">

      <div class="modal modal-md">
      <span class="modal-close" @click="() => {popups.farmCreated = false;}"><img src="@/assets/images/close-green-xs.png" alt=""></span>
      <div class="modal-block text-center">
      <p class="fw-bold">Add Beds or Plan Crop</p>
      </div>
      <div class="modal-block text-center">
      <div class="buttons justify-content-center">
          <button
            @click="() => addBed()"
            @mouseover="switchPlanBedTooltip(true)"
            @mouseout="switchPlanBedTooltip(false)"
            class="approve-button btn green" 
          >Add Beds</button>
          <button
            @click="() => {
              planCrop();
              popups.farmCreated = false;
            }" 
            class="cancel-removal-button btn green btn-o" 
          >Plan Crop</button>
      </div>
      <!-- <div class="popup-tooltip" v-if="planBedTooltip">Tooltip</div> -->
      </div>
      </div>

      <!-- <div class="popup-message">
        <div class="close-button" @click="popups.farmCreated = false" ></div>
        <span class="popup-text">Plan Beds or Plan Crop</span>
        <div class="popup-buttons">
          <button 
            type="submit" 
            class="btn green" 
            @click="() => addBed()"
            @mouseover="switchPlanBedTooltip(true)"
            @mouseout="switchPlanBedTooltip(false)"
          >Plan Beds</button>
          <button type="submit" class="btn green" @click="() => planCrop()">Plan Crop</button>
        </div>
        <div class="popup-tooltip" v-if="planBedTooltip">Tooltip</div>
      </div> -->
      
    </div>

</template>

<script>
import { ref, provide, reactive, watch, onMounted, inject, computed } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import FarmProfileForm from "./FarmProfileForm.vue";
import FarmList from "./FarmList.vue";
import FarmProfileFilled from "./FarmProfileFilled.vue";
import FarmMap from "./FarmMap.vue";
import FarmProfileHeader from "./FarmProfileHeader.vue";
import FarmPlanCropList from "./FarmPlanCropList.vue";

export default {
  name: "FarmProfile",
  components: {
    FarmProfileForm,
    FarmList,
    FarmProfileFilled,
    FarmMap,
    FarmProfileHeader,
    FarmPlanCropList
},
  setup() {
    const store = useStore();
    const router = useRouter();
    const { isAuth } = inject('mainUI');

    const formMode = ref('');
    const selectedFarmId = ref('');
    const bedsCreation = ref(false);
    const popups = reactive({
        farmCreated: false
      })
    const farmForm = ref(null);
    const farmFormTop = ref(null);
    const bedForm = ref(null);
    const editionMode = ref(null);
    const planCropSeasonIsChanged = ref(false);

    const planBedTooltip = ref(false);

    const newCropCreation = ref(false);

    const newCropBedEdition = ref(false);

    const farms = computed(() => store.state.farms);

    const crops = ref(() => store.state.crops);

    const farmSubmitted = ref(false);

    const mainSectionIsEditable = ref(false);

    const bedFormIsOpened = ref(false);

    const farmPlanCropListSection = ref(null);

    const switchPlanBedTooltip = (toggle) => {
      planBedTooltip.value = toggle;
    }

    const openForm = (farmId) => {
      mainSectionIsEditable.value = true;
      formMode.value = farmId ? 'edit' : 'create';
      selectedFarmId.value = farmId;

      if (!farmId) {
        console.log(defaultFarm, 'defaultFarm');
        selectedFarmId.value = ''
        
        for (let prop in farm) {
          farm[prop] = defaultFarm[prop];
        }

        for (let prop in selectedFarm) {
          selectedFarm[prop] = defaultFarm[prop]
        }

        for (let prop in crop) {
          crop[prop] = defaultCrop[prop];
        }

        popups.farmCreated = false;
        farmSubmitted.value = false;

        setTimeout(() => {
          console.log(farmFormTop);
          document.getElementsByTagName('main')[0].scroll({
            top: farmFormTop.value + 10,
            left: 0,
            behavior: 'smooth'
          })
        }, 100)
      }
    }

    const closeForm = (cropIdOrBeds, formData) => {
      // formMode.value = 'represent'
      // if (farm.id) {
      //   popups.farmCreated = true;
      // }
      const currentMode = `${formMode.value}`;
      if ((currentMode === 'create') || !cropIdOrBeds) {
        popups.farmCreated = true;
        if (!cropIdOrBeds) {
          formMode.value = 'represent'
        }
        (currentMode === "create") && selectFarm(formData);
      } else {
        formMode.value = 'represent'
      }
      newCropCreation.value = false;
    }

    const createBeds = () => {
      bedsCreation.value = true
    };

    const addBed = () => {
      selectFarm(farm);
      editionMode.value = 'create'
      createBeds();
      popups.farmCreated = false;
      crop.sourceType = 'bed';

      setTimeout(() => {
        document.getElementsByTagName('main')[0].scroll({
          top: bedForm.value.offsetTop + 10,
          left: 0,
          behavior: 'smooth'
        })
      }, 100)
    }

    const navigateToMapForm = () => {
      setTimeout(() => {
        document.getElementsByTagName('main')[0].scroll({
          top: bedForm.value.offsetTop + 10,
          left: 0,
          behavior: 'smooth'
        })
      }, 100)
    }

    const planCrop = () => {
      // router.push({ name: 'crops', params: { pagename: 'plan-crop' } })

      selectCrop({
        ...defaultCrop,
        year: crop.year || '',
        season: crop.season || '',
        cropCycle: crop.cropCycle || ''
      });

      crop.sourceType = 'farm';
      crop.sourceId = selectedFarmId.value || selectedFarm.id || farm.id;
      formMode.value = 'edit';
      mainSectionIsEditable.value = false;
    }

    const closePopup = () => {
      popups.farmCreated = false;

      crop.sourceType = 'none';

      selectFarm({...store.state.farms[store.state.farms.length - 1]});
    }

    const farm = reactive({
      id: '',
      farmName: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      longtitude: '',
      latitude: '',
      acreage: '',
      soilComposition: '',
      irrigationType: '',
      climaticZone: '',
      fencing: '',
      IOTdeviceType: '',
      primaryCrop: '',
      secondaryCrop: '',
      beds: [],
      drawMap: [],
      sameAddress: false
    });

    const crop = reactive({
      id: '',
      farmName: '',
      year: '',
      cropCategory: '',
      dateSeed: '',
      projHarvestAmount: '',
      bed: '',
      season: '',
      crop: '',
      harvestDate: '',
      projWastage: '',
      // acreage: '',
      cropCycle: '',
      variety: '',
      isDateSeed: false,
      seedDays: '',
      isDateHarvest: false,
      harvestDays: '',
      
      sourceType: '',
      sourceId: ''
    });

    const defaultCrop = {
      ...crop
    }

    const activatedCrop = reactive({
      farmName: false,
      year: false,
      cropCategory: false,
      dateSeed: false,
      projHarvestAmount: false,
      bed: false,
      season: false,
      crop: false,
      harvestDate: false,
      projWastage: false,
      acreage: false,
      cropCycle: false,
      variety: false
    });

    const sameAddress = ref(false);

    const selectedFarm = reactive({
      ...farm
    });

    const defaultFarm = {
      ...farm
    }

    const mapDrawSelected = ref({});

    const initialModuleOpening = ref(true);

    const newPlanning = ref(false);

    const editFarm = (field, val) => {
      farm[field] = val;
    }

    const selectFarm = (farmData, editable) => {
      console.log('selectFarm', farmData)
      if (farmData) {
        for (let field in farmData) {
          farm[field] = farmData[field]
          selectedFarm[field] = farmData[field]
        }
      }
      // if (formMode.value !== 'represent') {
      if (editable === 'edit') {
        formMode.value = 'edit';
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: farmForm?.value?.offsetTop,
            left: 0,
            behavior: 'smooth'
          })
        })
      } else {
        formMode.value = 'represent'
      }

      // new code
      if (farm.beds.length === 0) {
        selectBed({...defaultBed}, 'represent');
      }
    }

    const selectCrop = (cropData, cropMode) => {
      console.log(cropData, 'cropData');
      if (cropData) {
        for (let prop in cropData) {
          crop[prop] = cropData[prop];
        }
      }
      if (cropMode) {
        formMode.value = cropMode;
      }
    }

    // bed migration

    const bed = reactive({
      id: '',
      year: '',
      season: '',
      farmName: '',
      bedNumber: '',
      acreage: '',
      soilType: '',
      irrigation: '',
      IOTdeviceType: '',
      primaryCrop: '',
      secondaryCrop: '',
      mapId: ''
      // imageData: '',
      // imageParams: ''
    });

    const defaultBed = {
      ...bed
    };

    const selectBed = (bedData, editMode) => {
      console.log(bedData, 'bedData');
      if (bedData) {
        for (let field in bedData) {
          bed[field] = bedData[field]
        }
        for (let prop in crop) {
          crop[prop] = bedData[prop];
        }
      }
      editionMode.value = editMode;
    }

    provide('bedEdition', {
      
    })

    // bed migration

    provide('formMode', {
      formMode,
      selectedFarmId,
      openForm,
      closeForm,
      mainSectionIsEditable
    })

    provide('farmEdition', {
      farm,
      sameAddress,
      defaultFarm,
      selectedFarm,
      editFarm,
      selectFarm,
      farmSubmitted,
      initialModuleOpening,
      newPlanning
    })

    provide('bedsCreation', {
      bedsCreation,
      editionMode,
      createBeds,
      mapDrawSelected,
      bedForm,

      bed,
      defaultBed,
      selectBed,
      navigateToMapForm,
      bedFormIsOpened,

      addDesign: addBed
    })

    provide('cropEdition', {
      crop,
      defaultCrop,
      activated: activatedCrop,
      selectCrop,
      planCropSeasonIsChanged,
      newCropCreation,
      newCropBedEdition
    })

    watch(farm, (newVal) => {
      console.log('farm object is changed', newVal);
      console.log(newVal.drawMap, 'drawmap')
      if (formMode.value === 'represent') {
        bedsCreation.value = false;
      }
      if (farm.drawMap.length > 0) {
        bedsCreation.value = true;
      }
      // const currentCrop = crops.value.find(stateCrop => stateCrop.sourceId === newVal.id);

      crops.value = [...store.state.crops.filter(crp => ((crp.sourceType === 'farm') && (crp.sourceId === newVal.id)))];

      if (newVal.farmName && (formMode.value === 'represent')) {
        farmSubmitted.value = true;
      }

      // fix condition bed-farm || crop-farm
      // if (farmSubmitted.value && (farm.drawMap.length === 0) && !currentCrop && (farm.beds.length === 0)) {
        if (farmSubmitted.value && (farm.drawMap.length === 0) && (crops.value.length === 0) && (farm.beds.length === 0)) {
        if (formMode.value === 'represent') {
          console.log(`farmSubmitted.value: ${farmSubmitted.value} (farm.drawMap.length === 0): ${(farm.drawMap.length === 0)} (crops.value.length === 0): ${crops.value.length === 0} (farm.beds.length === 0): ${(farm.beds.length === 0)}`)
          if ((farm.drawMap.length === 0) || (crops.value.length === 0) || (crops.value.length === 0)) {
            if (!newPlanning.value) {
              popups.farmCreated = true;
            } else {
              // newPlanning.value = false;
              addBed();
            }
          }
        }
      }
    })

    watch(() => ({...farm}), (newVal, oldVal) => {
      console.log(`crops.value.length > 0 ${crops.value.length > 0}`)

      if ((newVal.id && oldVal.id) && (newVal.id !== oldVal.id)) {
        initialModuleOpening.value = false;
      }
      if (crops.value.length > 0) {
        if ((newVal.id && oldVal.id) && (newVal.id !== oldVal.id)) {
          setTimeout(() => {
            document.getElementsByTagName('main')[0].scroll({
              top: farmPlanCropListSection?.value?.offsetTop,
              left: 0,
              behavior: 'smooth'
            })
          })
        }
      }
      if (newVal?.beds?.length > 0) {
        popups.farmCreated = false;
      }
    })

    watch(sameAddress, (newVal, oldVal) => {
      if (sameAddress.value && store.state?.primaryUser?.mainDetails) {
        for (let prop in farm) {
          if (store.state.primaryUser.mainDetails[prop] && 
            (store.state.primaryUser.mainDetails[prop].length > 0)) {
            farm[prop] = store.state.primaryUser.mainDetails[prop]
          }
        }
        farm.farmName = store.state.primaryUser.mainDetails.accountName;
      }
      if (!newVal && oldVal) {
        if (formMode.value === 'create') {
          for (let prop in farm) {
            if (prop !== 'id') {
              farm[prop] = defaultFarm[prop]
            }
          }
        }
        if (formMode.value === 'edit') {
          for (let prop in farm) {
            if (prop !== 'id') {
              farm[prop] = selectedFarm[prop]
            }
          }
        }
      }
    })

    watch(() => (crop.season), (newVal, oldVal) => {
      if ((newVal && oldVal) && (newVal !== oldVal)) {
        console.log('archive farm, map, bed, crop');
      } 
    })

    watch(() => ([...farms.value]), (newVal, oldVal) => {
      if (newVal.length < oldVal.length) {
        (newVal.length > 0) ? selectFarm({...farms.value[0]}) : selectFarm({...defaultFarm})
      }
    });

    watch(() => ([...store.state.crops]), (newVal) => {
      crops.value = [...newVal.filter(crp => ((crp.sourceType === 'farm') && ((crp.sourceId === farm.id) || (crp.sourceId === selectedFarmId.value) || (crp.sourceId === selectedFarm.id))))];
      if (crops.value.length > 0) {
        popups.farmCreated = false;
      }
    });

    onMounted(() => {
      console.log(store.state.farms);
      farmFormTop.value = farmForm?.value?.offsetTop, farmForm;

      initialModuleOpening.value = true;

      store.dispatch('getCrops');

      if (store.state.farms.length <= 0) {
        // formMode.value = 'create'
      } else {
        formMode.value = 'represent'
        if (farm.beds.length > 0) {
          bedsCreation.value = true;
          editionMode.value = 'create'
        }
        if (selectedFarm.drawMap.length > 0) {
          bedsCreation.value = true;
        }
      }

      if (router.currentRoute.value.params.fromFarmHistory) {
        // selectBed({}, 'create')
        editionMode.value = 'create';
        bedsCreation.value = true;
      }

      if ((router.currentRoute.value.params.source === 'admin-dashboard') ||
        (router.currentRoute.value.params.source === 'user-profile')) {
        formMode.value = 'create';
        mainSectionIsEditable.value = true;
      }

      const currentFarmCrop = store.state.crops.find(crp => 
        (crp.sourceType === 'farm') && 
        (crp.sourceId === selectedFarmId.value || selectedFarm.id)
      );

      if (currentFarmCrop) {
        for (let prop in crop) {
          crop[prop] = currentFarmCrop[prop];
        }
      }

      if (farm.drawMap.length > 0) {
        bedsCreation.value = true;
      }

      crops.value = [...store.state.crops.filter(crp => ((crp.sourceType === 'farm') && ((crp.sourceId === farm.id) || (crp.sourceId === selectedFarmId.value) || (crp.sourceId === selectedFarm.id))))];

      setTimeout(() => {
        if ((store.state.crops.length === 0) && ((farm.beds.length === 0) && (selectedFarm.beds.length === 0))) {
          document.getElementsByTagName('main')[0].scroll({
            top: farmForm.value.offsetTop + 10,
            left: 0,
            behavior: 'smooth'
          })
        }
      }, 100)

    })

    return {
      farmForm,
      bedForm,
      formMode,
      bedsCreation,
      addBed,
      planCrop,
      popups,
      isAuth,
      selectedFarm,
      planBedTooltip,
      switchPlanBedTooltip,
      farm,
      closePopup,
      crops,
      farmPlanCropListSection
    }
  }
};
</script>

<style lang="scss" scoped>
// .farm-profile {
//   padding: 12px 24px 24px;
  .add-new-popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    .popup-message {
      background: #fff;
      display: flex;
      flex-direction: column;
      max-width: 650px;
      width: 100%;
      height: max-content;
      justify-content: space-around;
      align-items: center;
      position: relative;
      border-radius: 11px;
      color: rgba(40,41,43,1);
      font-size: 21px;
      margin: 0 20px;
      padding: 25px 0;
      text-align: center;
      .close-button {
        position: absolute;
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: auto;
        width: 30px;
        height: 30px;
        background-image: url('@/assets/profile/close_button.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        right: 10px;
        top: 22px;
        cursor: pointer;
      }
      .popup-text {
        margin: 0 40px;
        font-size: 16px;
        font-weight: 600;
      }
      .popup-buttons {
        display: flex;
        width: 60%;
        justify-content: space-evenly;
        margin-top: 30px;
        button {
          width: 160px;
          height: 40px;
          border-radius: 10px;
          margin: auto 10px;
        }
      }
      .popup-tooltip {
        position: absolute;
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;

        background-color: #3C3838;
        color: #fff;
        top: 140px;
        left: 60px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 41px;
          background: #3C3838;
          z-index: -1;
        }
      }
    }
  }
  .popup-tooltip {
        position: absolute;
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;

        background-color: #3C3838;
        color: #fff;
        top: 140px;
        left: 60px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 41px;
          background: #3C3838;
          z-index: -1;
        }
      }
// }
.q-expansion-item--popup.q-expansion-item--expanded,
  .q-expansion-item--popup.q-expansion-item--collapsed {
    padding: 0;
  }
  .q-expansion-item {
    margin-bottom: 45px;
  }
</style>
