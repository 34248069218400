<template>
  <ReportsHeader />
  <CropHarvestedByMonth />
  <CropHarvestedByWeek />
  <WastageByMonth />
  <WastageByWeek />
  <CropHarvestedTotalByMonth />
  <CropHarvestedTotalByWeek />
</template>

<script>
import { defineComponent } from 'vue'; //, ref, onMounted, inject, watch, reactive, provide
import ReportsHeader from './ReportsHeader.vue';
import CropHarvestedByMonth from './CropHarvestedByMonth.vue';
import CropHarvestedByWeek from './CropHarvestedByWeek.vue';
import WastageByMonth from './WastageByMonth.vue';
import WastageByWeek from './WastageByWeek.vue';
import CropHarvestedTotalByMonth from './CropHarvestedTotalByMonth.vue';
import CropHarvestedTotalByWeek from './CropHarvestedTotalByWeek.vue';
// import { useStore } from 'vuex';
// import DropDownList from '@/components/Shared/DropDownList.vue';


export default defineComponent({
  name: 'ReportsCrops',
  setup() {
  },
  components: { 
    ReportsHeader, CropHarvestedByMonth, 
    CropHarvestedByWeek, WastageByMonth, 
    WastageByWeek, CropHarvestedTotalByMonth,
    CropHarvestedTotalByWeek
  }
});
</script>