<template>
  <section class="section" ref="section">
<div class="border-box active">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Task List</h5>
<div class="info-icons">
<div class="info-icon lg header-icon tooltip"
  v-if="isAuth('add-new-task')"
  @click="addTask"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
  data-tooltip="Add Task"
>
  <img src="@/assets/images/updated/Task-White.png" alt="">
  <!-- <div class="helper-tooltip" v-if="helperTooltip">Add Task</div> -->
</div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-container">
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label justify-content-start">
  <span>Date</span>
  <span @click="() => sortTable('date')">
    <button :class="{active: sortOrder.date === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.date === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Farm</span>
  <span @click="() => sortTable('farm')">
    <button :class="{active: sortOrder.farm === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.farm === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th><p class="form-label-static form-sort-label">
  <span>Bed No.</span>
  <span @click="() => sortTable('bed')">
    <button :class="{active: sortOrder.bed === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.bed === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
  </p></th>
<th><p class="form-label-static form-sort-label">
  <span>Task Type</span>
  <span @click="() => sortTable('task')">
    <button :class="{active: sortOrder.task === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.task === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
  </p></th>
<th><p class="form-label-static form-sort-label">
  <span>Start Time</span>
  <span @click="() => sortTable('startTime')">
    <button :class="{active: sortOrder.startTime === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.startTime === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
  </p></th>
<th><p class="form-label-static form-sort-label">
  <span>Assigned To</span>
  <span @click="() => sortTable('assignedTo')">
    <button :class="{active: sortOrder.assignedTo === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.assignedTo === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
  </p></th>
<th><p class="form-label-static form-sort-label">
  <span>Status</span>
  <span @click="() => sortTable('taskStatus')">
    <button :class="{active: sortOrder.taskStatus === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.taskStatus === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span>
</p></th>
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

      <template
        v-for="(row, rowIndex) in rows"
        v-bind:key="'task-list-row' + row.id"
      >
        <tr
          v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
          :class="{'light-gray-bg': (row.id === selectedTask.id)}"
          @click="(ev) => selectByRow(ev, {...row})"
        >
        <!-- || (row.id === clickDetails.selectedDashboardTask) -->
        <!-- <tr
          v-if="(rowIndex + 1) <= maxPage"
          :class="{'light-gray-bg': row.id === selectedTask.id}"
        > -->
        <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
        <td>{{row.date}}</td>
        <td>{{row.farm}}</td>
        <td>{{row.bed}}</td>
        <td>
          <!-- <a href="javascript:void();" @click="selectTask({...row}, 'represent')"> -->
            {{ row.task }}
          <!-- </a> -->
        </td>
        <td>{{row.startTime}}</td>
        <td>{{ row.assignedTo }}</td>
        <td>
        <div class="table-select">
        <select v-model="row.taskStatus"
          @change="(ev) => changeTaskStatus(ev, row.id)">
          <option>Assigned</option>
          <option>In Progress</option>
          <option>Re-Assigned</option>
          <option>Deferred</option>
          <option>Completed</option>
        </select>
        </div>
        </td>
        <td class="w-75">
        <div class="table-links">
        <a v-if="isAuth('edit-task')" href="javascript:void();" @click="selectTask({...row}, 'edit')">
          <img src="@/assets/images/edit-icon-2.svg" alt="">
        </a>
        <a v-if="isAuth('remove-task')" href="javascript:void();" @click="removeOneTask({...row})">
          <img src="@/assets/images/trash-2.svg" alt="">
        </a>
        </div>
        </td>
        </tr>
      </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div class="records-per-page">
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
<!-- <DropDownList 
  :mainObject="'maxPage'"
  :mainProperty="'value'"
  :injectionName="'taskListDetails'"
  :optionKey="'maxpage-task-list'"
  :optionsList="[1,2,3,4,5,6,7,8,9,10]"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="maxPage.value"
/> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->

  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'taskListDetails'"
    :optionKey="'task-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>

</div>
</section><!--//End Section-->

<div v-if="popUps.isRecurringToRemove.active" class="removal-popup">

<div class="modal modal-md">
<span class="modal-close" @click="() => confirmRecurring(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">{{ popUps.isRecurringToRemove.message }}</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
       @click="() => confirmRecurring('recurring')"
      class="approve-button btn green" 
    >Recurring Task</button>

    <button
       @click="() => confirmRecurring(true)"
      class="approve-button btn green" 
    >This Task</button>
</div>
</div>
</div>

</div>

<div class="removal-popup" v-if="popUps.isOneTaskRemoval.active">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveOneTaskRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">{{ popUps.isOneTaskRemoval.message }}</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveOneTaskRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveOneTaskRemoval(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>

</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { ref, onMounted, watch, computed, inject, reactive, provide } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';


export default {
    name: "TaskList",
    props: ['parentModule'],
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const helperTooltip = ref(false);
        const tasks = computed(() => store.state.tasks);
        const rows = ref([]);
        const maxPage = reactive({
          value: 20
        });
        const options = reactive({
          value: []
        });
        const currentPage = reactive({
          value: 1
        });
        const popUps = reactive({
            isRecurringToRemove: {
                active: false,
                message: 'What do you want to delete?'
            },
            isOneTaskRemoval: {
              active: false,
              message: 'Are you sure you want to delete the Task?'
            }
        });
        const taskToRemove = ref({
            id: '',
            groupId: ''
        });
        const { isAuth } = inject('mainUI');
        const { selectedTask, addTask, editTask, selectTask, removeTask, removeRecurringTask } = inject('taskDetails');
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const changeTaskStatus = (ev, id) => {
            if (router.currentRoute.value.name === 'home') {
                // if (ev.target.value === 'Completed') {
                router.push({
                    name: 'page',
                    params: {
                        pagename: 'task',
                        taskId: id,
                        taskMode: 'edit'
                    }
                });
                // }
            }
            else {
                store.dispatch('editTask', {
                    id,
                    taskStatus: ev.target.value
                });
                const selectedTask = {
                    ...tasks.value.find(task => task.id === id)
                };
                if (selectedTask) {
                    selectTask(selectedTask, 'edit');
                }
            }
        };
        // const oneClick = (ev) => {
        //   clickDetails.clicks++;
        //   if (clickDetails.clicks === 1) {
        //     clickDetails.timer = setTimeout( () => {
        //       clickDetails.result.push(ev.type);
        //       clickDetails.clicks = 0
        //     }, clickDetails.delay);
        //   } else {
        //      clearTimeout(clickDetails.timer);  
        //      clickDetails.result.push('dblclick');
        //      clickDetails.clicks = 0;
        //   }         
        // }      
        
        // const clickDetails = reactive({
        //   // result: [],
        //   delay: 700,
        //   clicks: 0,
        //   timer: null,
        //   selectedDashboardTask: null
        // })
        
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
              return;
            }
            else {
              // if (router.currentRoute.value.name === 'home') {
              //   clickDetails.clicks++;
              //   if (clickDetails.clicks === 1) {
              //     clickDetails.timer = setTimeout( () => {
              //       clickDetails.selectedDashboardTask = row.id
              //       clickDetails.clicks = 0
              //     }, clickDetails.delay);
              //   } else {
              //     clearTimeout(clickDetails.timer);  
              //     clickDetails.clicks = 0;
              //     selectTask({ ...row }, 'represent');
              //   }
              // } else {
                selectTask({ ...row }, 'represent');
              // }
            }
        };
        const sortOrder = reactive({
            date: 0,
            farm: 0,
            bed: 0,
            task: 0,
            startTime: 0,
            assignedTo: 0,
            taskStatus: 0
        });
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...tasks.value];
                sortOrder[sortProp] = 0;
            }
        };
        const confirmRecurring = (confirmed) => {
          if (confirmed) {
            if (confirmed === 'recurring') {
              // const groupToBeRemoved = [...tasks.value.filter(stateTask => stateTask.recurrenceId === taskToRemove.value.groupId)];
              // groupToBeRemoved.forEach(groupItem => removeTask(groupItem.id));
              removeRecurringTask(taskToRemove.value.groupId);
            } else {
              removeTask(taskToRemove.value.id);
            }
          }
          taskToRemove.value = {id: '', groupId: ''}
          popUps.isRecurringToRemove.active = false;
        };
        const approveOneTaskRemoval = (confirmed) => {
          if (confirmed) {
            removeTask(taskToRemove.value.id);
          }
          taskToRemove.value = {id: '', groupId: ''}
          popUps.isOneTaskRemoval.active = false;
        }
        const removeOneTask = (taskData) => {
            if (taskData.recurring) {
                taskToRemove.value = {
                    id: taskData.id,
                    groupId: taskData.recurrenceId
                };
                popUps.isRecurringToRemove.active = true;
            }
            else {
              taskToRemove.value = {
                id: taskData.id
              }
              popUps.isOneTaskRemoval.active = true;
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
            section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });
        watch(store.state.tasks, () => {
            console.log(tasks.value, 'tasks');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...tasks.value].sort((a, b) => new Date(a.date) - new Date(b.date));
            options.value = Array.from({length: Math.ceil(rows.value.length / maxPage.value)}).map((_, indx) => indx + 1);
            console.log(options.value, 'options.value');
            // rows.value.forEach(row => {
            //   row.taskIsSelected = row.id === selectedTask.id
            // })
        });
        watch(currentPage, () => {
          section.value.style.height = 'auto';
          setTimeout(() => {
              sectionHeight.value = section.value.offsetHeight;
          });
        });
        watch(() => ([...tasks.value]), () => {
          // store.dispatch('getTasks');
            console.log(tasks.value, 'tasks');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...tasks.value].sort((a, b) => new Date(a.date) - new Date(b.date));
            options.value = Array.from({length: Math.ceil(rows.value.length / maxPage.value)}).map((_, indx) => indx + 1);
            console.log(options.value, 'options.value')
            // rows.value.forEach(row => {
            //   row.taskIsSelected = row.id === selectedTask.id
            // })
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({length: Math.ceil(rows.value.length / maxPage.value)}).map((_, indx) => indx + 1);
            console.log(options.value, 'options.value');
        });
        // watch(() => ({...selectedTask.id}), () => {
        //   rows.value.forEach(row => {
        //     row.taskIsSelected = row.id === selectedTask.id
        //   })
        // })
        onMounted(() => {
          store.dispatch('getTasks');
            setTimeout(() => {
                // (tasks.value.length > 0 && props.parentModule) && selectTask({...tasks.value[0]}, 'represent');
                console.log(props.parentModule);
            });
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getTasks')
            rows.value = [...tasks.value].sort((a, b) => new Date(a.date) - new Date(b.date));
            if (router.currentRoute.value.params.taskId) {
              const selectedTask = {
                ...tasks.value.find(task => task.id === router.currentRoute.value.params.taskId)
              };
              selectTask(selectedTask, router.currentRoute.value.params.taskMode);
                // if (router.currentRoute.value.params.taskMode) {
                //     const selectedTask = {
                //         ...tasks.value.find(task => task.id === router.currentRoute.value.params.taskId)
                //     };
                //     selectTask(selectedTask, 'edit');
                // }
                // else {
                //     selectTask({
                //         ...tasks.value.find(task => task.id === router.currentRoute.value.params.taskId)
                //     }, 'represent');
                // }
            }
            options.value = Array.from({length: Math.ceil(rows.value.length / maxPage.value)}).map((_, indx) => indx + 1);
            console.log(options.value, 'options.value');
            // tasks.value.forEach((tsk) => {
            //   if (!tsk.taskStatus || 
            //     ((tsk.taskStatus !== 'Pending') && 
            //     (tsk.taskStatus !== 'Completed') && 
            //     (tsk.taskStatus !== 'Reassigned'))
            //     ) {
            //     store.dispatch('editTask', {
            //       id: tsk.id,
            //       taskStatus: 'Pending'
            //     })
            //   }
            // })
        });
        provide('taskListDetails', {
            maxPage,
            currentPage,
            options
        });
        return {
            section,
            boxHeader,
            toggleSection,
            addTask,
            editTask,
            selectTask,
            removeTask,
            rows,
            maxPage,
            currentPage,
            changeTaskStatus,
            helperTooltip,
            switchHelperTooltip,
            sortTable,
            selectedTask,
            sortOrder,
            popUps,
            confirmRecurring,
            removeOneTask,
            selectByRow,
            approveOneTaskRemoval,
            // clickDetails,
            isAuth
        };
    },
    components: { DropDownList }
}
</script>

<style scoped lang="scss">
.header-icon {
  position: relative;
}
.form-sort-label {
  button {
    opacity: 0.5;
    &.active {
      opacity: 1;
    }
  }
}

.table-container {
  overflow-x: scroll;
}

@media screen and (max-width: 900px) {
  .table-container {
    overflow-x: scroll;
  }
}
</style>