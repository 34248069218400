const weatherCodeTable = {
  0: { label: 'Clear sky', selector: 'clear-sky'},	
  1: { label: 'Mainly clear', selector: 'mainly-clear'},
  2: { label: 'Partly cloudy', selector: 'partly-cloudy'},
  3: { label: 'Overcast', selector: 'overcast'},
  45: { label: 'Fog', selector: 'fog'},
  48: { label: 'Depositing rime fog', selector: 'depositing-rime-fog'},
  51: { label: 'Drizzle light', selector: 'drizzle-light'},
  53: { label: 'Drizzle', selector: 'drizzle'},
  55: { label: 'Drizzle dense', selector: 'drizzle-dense'},
  56: { label: 'Freezing Drizzle light', selector: 'freezing-drizzle-light'},
  57: { label: 'Freezing Drizzle dense', selector: 'freezing-drizzle-dense'},
  61: { label: 'Rain slight', selector: 'rain-slight'},
  63: { label: 'Rain', selector: 'rain'},
  65: { label: 'Rain heavy', selector: 'rain-heavy'},
  66: { label: 'Freezing Rain light', selector: 'freezing-rain-light'},
  67: { label: 'Freezing Rain heavy', selector: 'freezing-rain-heavy'},
  71: { label: 'Snow fall slight', selector: 'snow-fall-slight'},
  73: { label: 'Snow fall', selector: 'snow-fall'},
  75: { label: 'Snow fall heavy', selector: 'snow-fall-heavy'},
  77: { label: 'Snow grains', selector: 'snow-grains'},
  80: { label: 'Rain showers slight', selector: 'rain-showers-slight'},
  81: { label: 'Rain showers', selector: 'rain-showers'},
  82: { label: 'Rain showers violent', selector: 'rain-showers-violent'},
  85: { label: 'Snow showers slight', selector: 'snow-showers-slight'},
  86: { label: 'Snow showers heavy', selector: 'snow-showers-heavy'},
  95: { label: 'Thunderstorm', selector: 'thunderstorm'},
  96: { label: 'Thunderstorm slight hail', selector: 'thunderstorm-slight-hail'},
  99: { label: 'Thunderstorm heavy hail', selector: 'thunderstorm-heavy-hail'}
};

export default weatherCodeTable;