<template>

<section class="section" ref="section">
<div class="border-box">
<div class="border-box-content">

<div class="content-block">
<h5 class="fw-semibold">Farm Profile</h5>
</div>

<div class="content-block">
<div class="checkbox checkbox-lg same-farm-address">
  <input type="checkbox" id="addressSame" v-model="sameAddress">
  <label for="addressSame">Farm address same as company address</label>
</div>
</div>

<div class="content-block">

<div class="content-row" v-if="mainSectionIsEditable">
<div class="content-row grid-4 grid-full">
<div class="row">
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.farmName || (farm.farmName.length > 0)}">Farm Name</p>
<input type="text" class="form-control"
  v-model="farm.farmName"
  @focus="() => {
    activated.farmName = true;
    focused.farmName = true;
  }"
  @blur="() => {
    focused.farmName = false;
  }"
  placeholder="E.g., Savage Farms LLC"
>
<p v-if="activated.farmName && farm.farmName.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.farmName && farm.farmName.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.address || (farm.address.length > 0)}">Address 1</p>
<input type="text" class="form-control"
  v-model="farm.address"
  @focus="() => {
    activated.address = true;
    focused.address = true;
  }"
  @blur="() => {
    focused.address = false;
  }"
  placeholder="E.g., 7326 Taylor Dr"
  :disabled="farm.sameAddress"
>
<p v-if="activated.address && farm.address.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.address && farm.address.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.address2 || (farm.address2.length > 0)}">Address 2</p>
<input type="text" class="form-control"
  v-model="farm.address2"
  @focus="() => {
    activated.address2 = true;
    focused.address2 = true;
  }"
  @blur="() => {
    focused.address2 = false;
  }"
  placeholder="E.g., Suite 500"
  :disabled="farm.sameAddress"
>
<p v-if="activated.address2 && farm.address2.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.city || (farm.city.length > 0)}">City</p>
<input type="text" class="form-control"
  v-model="farm.city"
  @focus="() => {
    activated.city = true;
    focused.city = true;
  }"
  @blur="() => {
    focused.city = false;
  }"
  placeholder="E.g., Savage"
  :disabled="farm.sameAddress"
>
<p v-if="activated.city && farm.city.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.city && farm.city.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.state || (farm.state.length > 0)}">State</p>
<!-- <select class="form-control"
  v-model="farm.state"
  @click="() => {
    activated.state = true;
    focused.state = true;
  }"
  @change="() => {
    focused.state = false;
  }"
>
  <option v-for="stateOption in stateOptions"
    v-bind:key="stateOption + 'state-option-farm'"
  >{{ stateOption }}</option>
</select> -->
<DropDownList
  :mainObject="'farm'"
  :mainProperty="'state'"
  :injectionName="'farmEdition'"
  :optionKey="'state-option-farm'"
  :optionsList="stateOptions"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :isDisabled="farm.sameAddress"
  :defaultSelected="farm.state"
  @click="() => {
    activated.state = true;
  }"
/>
<p v-if="activated.state && farm.state.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.zip || (farm.zip.length > 0)}">Zip</p>
<input type="text" class="form-control" 
  v-model="farm.zip"
  @focus="() => {
    activated.zip = true;
    focused.zip = true;
  }"
  @blur="() => {
    focused.zip = false;
  }"
  placeholder="E.g., 55378"
  :disabled="farm.sameAddress"
>
<p v-if="activated.zip && farm.zip.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.zip && farm.zip.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.country || (farm.country.length > 0)}">Country</p>
<!-- <select class="form-control"
  v-model="farm.country"
  @click="() => {
    activated.country = true;
    focused.country = true;
  }"
  @change="() => {
    focused.country = false;
  }"
>
  <option
    v-for="countryOption in countryOptions"
    v-bind:key="countryOption + 'country-option-farm'"
  >{{ countryOption }}</option>
</select> -->
<DropDownList
  :mainObject="'farm'"
  :mainProperty="'country'"
  :injectionName="'farmEdition'"
  :optionKey="'country-option-farm'"
  :optionsList="countryOptions"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="farm.country"
  :isDisabled="farm.sameAddress"
  @click="() => {
    activated.country = true;
  }"
/>
<p v-if="activated.country && farm.country.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.latitude || (farm.latitude.length > 0)}">Latitude</p>
<input type="text" class="form-control"
  v-model="farm.latitude"
  @focus="() => {
    activated.latitude = true;
    focused.latitude = true;
  }"
  @blur="() => {
    focused.latitude = false;
  }"
  disabled
>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.longtitude || (farm.longtitude.length > 0)}">Longtitude</p>
<input type="text" class="form-control"
  v-model="farm.longtitude"
  @focus="() => {
    activated.longtitude = true;
    focused.longtitude = true;
  }"
  @blur="() => {
    focused.longtitude = false;
  }"
  disabled
>
</div>
</div>
<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.acreage || (farm.acreage.length > 0)}">Acreage</p>
<input type="text" class="form-control" 
  v-model="farm.acreage"
  @focus="() => {
    activated.acreage = true;
    focused.acreage = true;
  }"
  @blur="() => {
    focused.acreage = false;
  }"
  placeholder="E.g., 125"
>
<p v-if="activated.acreage && farm.acreage.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.soilComposition || (farm.soilComposition.length > 0)}">Soil Type</p>

<!-- <select class="form-control"
  v-if="!soilManual"
  v-model="farm.soilComposition"
  @click="() => {
    activated.soilComposition = true;
    focused.soilComposition = true;
  }"
  @change="() => {
    focused.soilComposition = false;
  }"
>
  <option
    v-for="soilOption in soilTypes"
    v-bind:key="soilOption + 'soil-option-farm'"
  >{{ soilOption }}</option>
</select> -->

<template v-if="!soilManual">
  <DropDownList
    :mainObject="'farm'"
    :mainProperty="'soilComposition'"
    :injectionName="'farmEdition'"
    :optionKey="'soil-option-farm'"
    :optionsList="soilTypes"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="farm.soilComposition"
    @click="() => {
      activated.soilComposition = true;
    }"
  />
</template>

  <div v-else class="dropdown-open-back-container">

    <input type="text" class="form-control"
      ref="soilField"
      v-model="farm.soilComposition"
      @focus="() => {
        activated.soilComposition = true;
        focused.soilComposition = true;
      }"
      @blur="() => {
        focused.soilComposition = false;
        if (!farm.soilComposition) {
          soilManual = false;
        }
      }"
      placeholder="E.g., Black"
    >
    <div class="dropdown-open-back" @click="() => {
      soilManual = false
    }"></div>
  </div>
<p v-if="activated.soilComposition && farm.soilComposition.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.irrigationType || (farm.irrigationType.length > 0)}">Irrigation Type</p>

<!-- <select class="form-control"
  v-if="!irrigationManual"
  v-model="farm.irrigationType"
  @click="() => {
    activated.irrigationType = true;
    focused.irrigationType = true;
  }"
  @change="() => {
    focused.irrigationType = false;
  }"
>
  <option
    v-for="irrigationOption in irrigationSystems"
    v-bind:key="irrigationOption + 'irrigation-option-farm'"
  >{{ irrigationOption }}</option>
</select> -->

<template v-if="!irrigationManual">
  <DropDownList
    :mainObject="'farm'"
    :mainProperty="'irrigationType'"
    :injectionName="'farmEdition'"
    :optionKey="'irrigation-option-farm'"
    :optionsList="irrigationSystems"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="farm.irrigationType"
    @click="() => {
      activated.irrigationType = true;
    }"
  />
</template>

  <div v-else class="dropdown-open-back-container">
    <input type="text" class="form-control" 
      ref="irrigationField"
      v-model="farm.irrigationType"
      @focus="() => {
        activated.irrigationType = true;
        focused.irrigationType = true;
      }"
      @blur="() => {
        focused.irrigationType = false;
        if (!farm.irrigationType) {
          irrigationManual = false;
        }
      }"
      placeholder="E.g., Drip"
    >
    <div class="dropdown-open-back" @click="() => {
      irrigationManual = false
    }"></div>
  </div>
<p v-if="activated.irrigationType && farm.irrigationType.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.climaticZone || (farm.climaticZone.length > 0)}">Climatic Zone</p>

<!-- <select class="form-control"
  v-if="!climaticManual"
  v-model="farm.climaticZone"
  @click="() => {
    activated.climaticZone = true;
    focused.climaticZone = true;
  }"
  @change="() => {
    focused.climaticZone = false;
  }"
>
  <option
    v-for="climaticOption in climaticZones"
    v-bind:key="climaticOption + 'climatic-option-farm'"
  >{{ climaticOption }}</option>
</select> -->

<template v-if="!climaticManual">
  <DropDownList
    :mainObject="'farm'"
    :mainProperty="'climaticZone'"
    :injectionName="'farmEdition'"
    :optionKey="'climatic-option-farm'"
    :optionsList="climaticZones"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :maxHeight="200"
    :defaultSelected="farm.climaticZone"
    @click="() => {
      activated.climaticZone = true;
    }"
  />
</template>

  <div v-else class="dropdown-open-back-container">
    <input type="text" class="form-control"
      ref="climaticField"
      v-model="farm.climaticZone"
      @focus="() => {
        activated.climaticZone = true;
        focused.climaticZone = true;
      }"
      @blur="() => {
        focused.climaticZone = false;
        if (!farm.climaticZone) {
          climaticManual = false;
        }
      }"
      placeholder="E.g., Continental"
    >
    <div class="dropdown-open-back" @click="() => {
      climaticManual = false
    }"></div>
  </div>
<p v-if="activated.climaticZone && farm.climaticZone.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.fencing || (farm.fencing.length > 0)}">Fencing</p>

<!-- <select class="form-control"
  v-if="!fencingManual"
  v-model="farm.fencing"
  @click="() => {
    activated.fencing = true;
    focused.fencing = true;
  }"
  @change="() => {
    focused.fencing = false;
  }"
>
  <option
    v-for="fenceOption in fenceTypes"
    v-bind:key="fenceOption + 'fence-option-farm'"
  >{{ fenceOption }}</option>
</select> -->

<template v-if="!fencingManual">
  <DropDownList
    :mainObject="'farm'"
    :mainProperty="'fencing'"
    :injectionName="'farmEdition'"
    :optionKey="'fence-option-farm'"
    :optionsList="fenceTypes"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :maxHeight="200"
    :defaultSelected="farm.fencing"
    @click="() => {
      activated.fencing = true;
    }"
  />
</template>

  <div v-else class="dropdown-open-back-container">

    <input type="text" class="form-control"
      ref="fencingField"
      v-model="farm.fencing"
      @focus="() => {
        activated.fencing = true;
        focused.fencing = true;
      }"
      @blur="() => {
        focused.fencing = false;
        if (!farm.fencing) {
          fencingManual = false;
        }
      }"
      placeholder="E.g., Barbed Wire"
    >
    <div class="dropdown-open-back" @click="() => {
      fencingManual = false
    }"></div>
  </div>
<p v-if="activated.fencing && farm.fencing.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.IOTdeviceType || (farm.IOTdeviceType.length > 0)}">IOT Device Type</p>
<input type="text" class="form-control"
  v-model="farm.IOTdeviceType"
  @focus="() => {
    activated.IOTdeviceType = true;
    focused.IOTdeviceType = true;
  }"
  @blur="() => {
    focused.IOTdeviceType = false;
  }"
  placeholder="E.g., LKO9876"
>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.primaryCrop || (farm.primaryCrop.length > 0)}">Primary Crop</p>
<input type="text" class="form-control"
  v-model="farm.primaryCrop"
  @focus="() => {
    activated.primaryCrop = true;
    focused.primaryCrop = true;
  }"
  @blur="() => {
    focused.primaryCrop = false;
  }"
  placeholder="E.g., Wheat"
>
<p v-if="activated.primaryCrop && farm.primaryCrop.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.secondaryCrop || (farm.secondaryCrop.length > 0)}">Secondary Crop</p>
<input type="text" class="form-control"
  v-model="farm.secondaryCrop"
  @focus="() => {
    activated.secondaryCrop = true;
    focused.secondaryCrop = true;
  }"
  @blur="() => {
    focused.secondaryCrop = false;
  }"
  placeholder="E.g., Rice"
>
</div>
</div>


</div>
</div>
</div>

<template v-else>
  <div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Farm Name</p>
<h6>{{ farm.farmName }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Address 1</p>
<h6>{{ farm.address }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Address 2</p>
<h6>{{ farm.address2 }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">City</p>
<h6>{{ farm.city }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">State</p>
<h6>{{ farm.state }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">ZIP</p>
<h6>{{ farm.zip }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Country</p>
<h6>{{ farm.country }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Longtitude</p>
<h6>{{ farm.longtitude }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Latitude</p>
<h6>{{ farm.latitude }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Acreage</p>
<h6>{{ farm.acreage }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Soil Type</p>
<h6>{{ farm.soilComposition }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Irrigation Type</p>
<h6>{{ farm.irrigationType }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Climatic Zone</p>
<h6>{{ farm.climaticZone }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Fencing</p>
<h6>{{ farm.fencing }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">IOT Device Type</p>
<h6>{{ farm.IOTdeviceType }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Primary Crop</p>
<h6>{{ farm.primaryCrop }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Secondary Crop</p>
<h6>{{ farm.secondaryCrop }}</h6>
</div>

</div>
</template>

</div>

<!-- <FarmPlanCropForm v-if="planCropFormSection === 'farm'" /> -->
<div ref="farmPlanCropForm"></div>

<FileAttachments :key="`farm-${farm.id}`" v-bind:section="'farm'" v-bind:instance="`${farm.id}`.replaceAll(' ', '')" />

<FarmPlanCropForm v-if="(crop.sourceType === 'farm') && ((crop.sourceId === farm.id) || (crop.sourceId === selectedFarmId) || (crop.sourceId === selectedFarm.id))" />
<!--  && formMode === 'edit' -->

<div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
</div>
</div>
</div>

</div>
</div>
</section>

<!-- <FarmPlanCropList v-if="crops.length > 0" /> -->
<div v-if="missedField">
  <UserNotification
    v-bind:messageText="'Please add the required data'"
  />
</div>

</template>

<script>

  import { ref, inject, onMounted, watch, reactive, computed, provide } from 'vue';
  import { useStore } from 'vuex';
  import { v4 as uuid } from 'uuid';
  import stateOptions from '@/sharedData/statesList.js'
  import countryOptions from '@/sharedData/countriesList.js'

  import irrigationSystems from '@/sharedData/irrigationSystems.js'
  import climaticZones from '@/sharedData/climaticZones.js'
  import soilTypes from '@/sharedData/soilTypes.js'
  import fenceTypes from '@/sharedData/fenceTypes.js'

  import FileAttachments from '@/components/Shared/FileAttachments.vue';
  import FarmPlanCropForm from './FarmPlanCropForm.vue';
  // import FarmPlanCropList from './FarmPlanCropList.vue';
  import DropDownList from '@/components/Shared/DropDownList.vue';
  import UserNotification from "@/components/Shared/UserNotification.vue";

  export default {
    name: "FarmProfileForm",
    setup() {
        const store = useStore();
        const sectionHeader = "Farm Profile";
        const { formMode, selectedFarmId, closeForm, mainSectionIsEditable } = inject("formMode");
        const { farm, sameAddress, defaultFarm, selectedFarm, editFarm, selectFarm, farmSubmitted } = inject("farmEdition");
        const { createBeds } = inject("bedsCreation");
        const { crop, activated: activatedCrop, planCropSeasonIsChanged, selectCrop, defaultCrop } = inject('cropEdition');
        const helperTooltip = ref(false);

        const farmPlanCropForm = ref(null);

        const mainFarmForm = ref(null);

        const soilField = ref(null);

        const irrigationField = ref(null);

        const climaticField = ref(null);

        const fencingField = ref(null);

        const activated = reactive({
          farmName: false,
          address: false,
          address2: false,
          city: false,
          state: false,
          zip: false,
          country: false,
          longtitude: false,
          latitude: false,
          acreage: false,
          soilComposition: false,
          irrigationType: false,
          climaticZone: false,
          fencing: false,
          IOTdeviceType: false,
          primaryCrop: false,
          secondaryCrop: false
        });
        const focused = reactive({
          farmName: false,
          address: false,
          address2: false,
          city: false,
          state: false,
          zip: false,
          country: false,
          longtitude: false,
          latitude: false,
          acreage: false,
          soilComposition: false,
          irrigationType: false,
          climaticZone: false,
          fencing: false,
          IOTdeviceType: false,
          primaryCrop: false,
          secondaryCrop: false
        });

        const crops = computed(() => store.state.crops.filter(crp => ((crp.sourceType === 'farm') && (crp.sourceId === farm.id) || (crp.sourceId === selectedFarmId) || (crp.sourceId === selectedFarm.id))));

        const soilManual = ref(false);
        const irrigationManual = ref(false);
        const climaticManual = ref(false);
        const fencingManual = ref(false);

        const otherSelected = ref(false);
        
        const missedField = ref(false);

        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const cancelChanges = () => {
            if (formMode.value === "edit") {
                for (let prop in farm) {
                    farm[prop] = selectedFarm[prop];
                }
                formMode.value = "represent";
            }
            else {
                for (let prop in farm) {
                    farm[prop] = defaultFarm[prop];
                }
                formMode.value = '';
            }
            sameAddress.value = false;
            if ((crop.sourceType === 'farm') && ((crop.sourceId === farm.id) || (crop.sourceId === selectedFarmId) || (crop.sourceId === selectedFarm.id))) {
              const cropEdited = crops.value.find(crp => crp.id === crop.id);
              if (cropEdited) {
                selectCrop({...cropEdited}, 'represent');
              } else {
                selectCrop({...defaultCrop}, '');
              }
            }
        };
        const submitForm = () => {
            if (!farm.farmName || !farm.address || !farm.city || !farm.state ||
                !farm.zip || !farm.country || !farm.acreage || !farm.primaryCrop) {
                if (!farm.farmName) activated.farmName = true;
                if (!farm.address) activated.address = true;
                if (!farm.city) activated.city = true;
                if (!farm.state) activated.state = true;
                if (!farm.zip) activated.zip = true;
                if (!farm.country) activated.country = true;
                if (!farm.acreage) activated.acreage = true;
                if (!farm.primaryCrop) activated.primaryCrop = true;

                setTimeout(() => {
                  const firstError = document.getElementsByClassName('form-info red text-end')[0];
                
                  const viewPortOffset = firstError.getBoundingClientRect();

                  console.log(Math.abs(viewPortOffset.top + 72));

                  document.getElementsByTagName('main')[0].scroll({
                    top: firstError ? Math.abs(viewPortOffset.top + 72) : 0,
                    left: 0,
                    behavior: 'smooth'
                  })
                }, 100);
                
                missedField.value = true;

                setTimeout(() => {
                  missedField.value = false;
                }, 5100);

                return;
            }

            if ((crop.sourceType === 'farm') && ((crop.sourceId === farm.id) || (crop.sourceId === selectedFarmId) || (crop.sourceId === selectedFarm.id))) {
              if (!crop.year || !crop.season || //!crop.farmName || !crop.acreage || 
              !crop.crop || !crop.dateSeed || !crop.harvestDate || !crop.projHarvestAmount) {
                if (!crop.year) activatedCrop.year = true;
                if (!crop.season) activatedCrop.season = true;
                // if (!crop.farmName) activatedCrop.farmName = true;
                // if (!crop.acreage) activatedCrop.acreage = true;
                if (!crop.crop) activatedCrop.crop = true;
                if (!crop.dateSeed) activatedCrop.dateSeed = true;
                if (!crop.harvestDate) activatedCrop.harvestDate = true;
                if (!crop.projHarvestAmount) activatedCrop.projHarvestAmount = true;

                setTimeout(() => {
                  const firstError = document.getElementsByClassName('form-info red text-end')[0];
                
                  const viewPortOffset = firstError.getBoundingClientRect();

                  console.log(Math.abs(viewPortOffset.top + 72));

                  document.getElementsByTagName('main')[0].scroll({
                    top: firstError ? Math.abs(viewPortOffset.top + 72) : 0,
                    left: 0,
                    behavior: 'smooth'
                  })
                }, 100);

                missedField.value = true;

                setTimeout(() => {
                  missedField.value = false;
                }, 5100);

                return;
              }

              const savedCrop = {
                ...crop,
                farmName: crop.farmName || farm.farmName,
                id: crop.id || uuid()
              };
            console.log(crop.id, 'crop.id');
              crop.id ? store.dispatch('editCrop', {
                crop: savedCrop
              }) : store.dispatch('createCrop', {
                crop: savedCrop
              });

              if (planCropSeasonIsChanged.value) {
                store.dispatch('archiveFarmPlan', {
                  mapDraw: {},
                  crop: {...savedCrop},
                  selectedFarmId: selectedFarm.id,
                  id: uuid()
                });
                console.log(selectedFarm, farm);
              }
            }

            // if (!farm.id) {
            //     farm.id = uuid();
            // }
            const formData = {
                ...farm
            };
            farmSubmitted.value = true;
            // make request
            (formMode.value === "edit") ?
                store.dispatch("editFarm", { ...formData, selectedFarmId: selectedFarmId.value }) :
                store.dispatch("createFarm", formData);
            console.log(formData);

            if (crop.sourceType) {
              closeForm(true, {...formData});
            } else {
              closeForm(false, {...formData});
            }
            (formMode.value === "create") && selectFarm(formData);
        };
        watch(sameAddress, (newVal) => {
            // if (formMode.value !== "edit") {
                farm.sameAddress = newVal;
            // }
        });
        watch(() => ({...farm}), (newVal) => {
          if (!newVal.id) {
            farm.id === uuid();
          }
        });

        watch(() => (farm.soilComposition), (newVal) => {
          if (newVal === 'Other') {
            farm.soilComposition = '';
            soilManual.value = true;
            otherSelected.value = true;
            setTimeout(() => {
              soilField.value.focus()
            }, 10)
          }
        });

        watch(() => (farm.irrigationType), (newVal) => {
          if (newVal === 'Other') {
            farm.irrigationType = '';
            irrigationManual.value = true;
            otherSelected.value = true;
            setTimeout(() => {
              irrigationField.value.focus();
            }, 10)
          }
        });

        watch(() => (farm.climaticZone), (newVal) => {
          if (newVal === 'Other') {
            farm.climaticZone = '';
            climaticManual.value = true;
            otherSelected.value = true;
            setTimeout(() => {
              climaticField.value.focus();
            }, 10)
          }
        });

        watch(() => (farm.fencing), (newVal) => {
          if (newVal === 'Other') {
            farm.fencing = '';
            fencingManual.value = true;
            otherSelected.value = true;
            setTimeout(() => {
              fencingField.value.focus();
            }, 10)
          }
        });

        onMounted(() => {
            console.log("mounted", formMode);
            console.log(farm.farmName);
            
            if (!farm.id) {
              console.log(farm.id, 'farm.id');
              farm.id = uuid();
            }

            (formMode.value === "edit") && store.dispatch("getFarm", { selectedFarmId: selectedFarmId.value });
            if (formMode.value !== "edit") {
                sameAddress.value = false;
            }
            else {
                sameAddress.value = farm.sameAddress;
            }
            if ((crop.sourceType === 'farm') && ((crop.sourceId === farm.id) || (crop.sourceId === selectedFarmId) || (crop.sourceId === selectedFarm.id))) {

              if (mainSectionIsEditable.value) {
                setTimeout(() => {
                  document.getElementsByTagName('main')[0].scroll({
                    top: mainFarmForm.value.offsetTop + 10,
                    left: 0,
                    behavior: 'smooth'
                  })
                }, 100)
              } else {
                setTimeout(() => {
                  document.getElementsByTagName('main')[0].scroll({
                    top: farmPlanCropForm.value.offsetTop + 10,
                    left: 0,
                    behavior: 'smooth'
                  })
                }, 100)
              }
            }
        });

        provide('otherHandling', {
          otherSelected
        });

        return {
            sectionHeader,
            farm,
            sameAddress,
            editFarm,
            stateOptions,
            countryOptions,
            irrigationSystems,
            climaticZones,
            soilTypes,
            fenceTypes,
            createBeds,
            submitForm,
            cancelChanges,
            helperTooltip,
            switchHelperTooltip,
            activated,
            focused,
            crop,
            formMode,
            selectedFarmId,
            selectedFarm,
            crops,
            farmPlanCropForm,
            mainFarmForm,
            soilManual,
            irrigationManual,
            climaticManual,
            fencingManual,
            soilField,
            irrigationField,
            climaticField,
            fencingField,
            mainSectionIsEditable,
            missedField
        };
    },
    components: { 
      FileAttachments, 
      FarmPlanCropForm, 
      // FarmPlanCropList, 
      DropDownList,
      UserNotification
    }
}
</script>

<style lang="scss">
    .farm-profile-form {
      box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
      .header {
        font-size: 18px;
        color: #28292B;
        // margin-left: 15px;
        margin-left: 4px;
      }
      .same-address {
        margin: 0 0 14px 14px;
      }
      .farm-profile-section {
        padding: 6px 0 16px;
      }
      .form-actions {
        display: flex;
        justify-content: flex-end;
        button {
          margin: 24px 0px 24px 24px;
        }
      }
      .add-bed {
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: auto;
        width: 25px;
        height: 25px;
        background-image: url('@/assets/farm_profile/add_bed.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        position: relative;
        .helper-tooltip {
          width: max-content;
          padding: 0 15px;
          height: 30px;
          position: absolute;
          z-index: 999;

          background-color: #3C3838;
          color: #fff;
          top: 40px;
          left: -30px;
          text-indent: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 11px;
          font-family: 'Inter', sans-serif;
          font-weight: 400;

          &::after {
            content: " ";
            position: absolute;
            display: block;
            top: -7px;
            border-color: #3C3838;
            border-left: 8px solid hsl(0, 3%, 23%);
            border-bottom: 6px solid #3C3838;
            border-right: 6px solid transparent;
            border-top: 8px solid transparent;
            -webkit-transform: translateX(-50%) rotate(135deg);
            transform: translateX(-50%) rotate(135deg);
            box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
            left: 41px;
            background: #3C3838;
            z-index: -1;
          }
        }
      }
      .row {
        &.wrapper {
          width: 100%;
          justify-content: space-evenly;
          height: 80px;
          .q-field__native, .q-field__prefix {
            color: rgba(40,41,43,0.6);
          }
          label {
            width: 100%;
            max-width: 165px;
          }
        }
      }
      .farm-profile-actions {
        margin-top: 5px;
      }
    }

@media screen and (max-width: 1024px) {
  .farm-profile-form {
    .row {
      &.wrapper {
        height: auto;
        .outer-label {
          margin: 0 5px;
        }
      }
    }
  }
}

.same-farm-address {
  &.checkbox.checkbox-lg {
    label {
      color: hsl(210, 1%, 40%);
    }  
  }
}
  </style>