<template>
  <AdminHeader />
  <section class="section mt-1">
    <div class="border-box">

      <div ref="mainForm"></div>
      <AdminUsersMainForm v-if="(mainEditionMode === 'create') || (mainEditionMode === 'edit')" />
      <AdminUsersMainFilled v-else />

      <!-- <AdminUsersSubscriptionForm v-if="(subscriptionEditionMode === 'create') || (subscriptionEditionMode === 'edit')" /> -->
      <AdminUsersSubscriptionFilled />
      <!-- v-else-if="subscriptionEditionMode === 'represent'" -->
      <div ref="usersList"></div>
      <!-- <AdminUsersSubscriptionList /> -->

      <AdminUsersList />

      <div v-if="submittedUserProfile && popupMessages.assigned.active">
        <UserNotification
          v-bind:messageText="popupMessages.assigned.message"
        />
      </div>
    
      <div class="add-new-popup" v-if="submittedUserProfile && popupMessages.addNew">
        
        <div class="modal modal-md">
        <span class="modal-close" @click="() => {
            popupMessages.addNew = false;
          }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
        <div class="modal-block text-center">
        <p class="fw-bold">Add another user or create farm profile</p>
        </div>
        <div class="modal-block text-center">
        <div class="buttons justify-content-center">
            <button
              @click="() => createNewUser()"
              class="approve-button btn green" 
            >Add user</button>
            <button
              @click="() => createNewFarm()" 
              class="cancel-removal-button btn green btn-o" 
            >Add farm</button>
        </div>
        </div>
        </div>

        <!-- <div class="popup-message">
          <div class="close-button" @click="() => {
            popupMessages.addNew = false;
          }" ></div>
          <span class="popup-text">Add another user or create farm profile</span>
          <div class="popup-buttons">
            <button class="btn green" @click="() => createNewUser()">Add user</button>
            <button class="btn green" @click="() => createNewFarm()">Add farm</button>
          </div>
        </div> -->
        
      </div>

      <div class="add-new-popup" v-if="submittedUserProfile && popupMessages.userAdded.active">
        
        <div class="modal modal-md">
        <span class="modal-close" @click="() => {
            popupMessages.userAdded.active = false;
          }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
        <div class="modal-block text-center">
        <p class="fw-bold">{{ popupMessages.userAdded.message }}</p>
        </div>
        <div class="modal-block text-center">
        <div class="buttons justify-content-center">
            <button
              @click="() => {
                createNewUser();
                popupMessages.userAdded.active = false;
              }"
              class="approve-button btn green" 
            >Yes</button>
            <button
              @click="() => {
                if (farms.length === 0) {
                  popupMessages.addFarm.active = true;
                } else {
                  scrollToUsersList();
                }
                popupMessages.userAdded.active = false;
              }"
              class="cancel-removal-button btn green btn-o" 
            >No</button>
        </div>
        </div>
        </div>

      </div>

      <div class="add-new-popup" v-if="submittedUserProfile && popupMessages.addFarm.active">
        
        <div class="modal modal-md">
        <span class="modal-close" @click="() => {
            popupMessages.addFarm.active = false;
          }"><img src="@/assets/images/close-green-xs.png" alt=""></span>
        <div class="modal-block text-center">
        <p class="fw-bold">{{ popupMessages.addFarm.message }}</p>
        </div>
        <div class="modal-block text-center">
        <div class="buttons justify-content-center">
            <button
              @click="() => {
                createNewFarm();
                popupMessages.addFarm.active = false;
              }"
              class="approve-button btn green" 
            >Yes</button>
            <button
              @click="() => {
                popupMessages.addFarm.active = false;
                scrollToUsersList();
              }" 
              class="cancel-removal-button btn green btn-o" 
            >No</button>
        </div>
        </div>
        </div>

      </div>

    </div>
  </section><!--//End Section--><!--//End section-->
</template>

<script>
import { onMounted, provide, reactive, ref, watch, computed } from 'vue';
import AdminUsersMainFilled from './AdminUsersMainFilled.vue';
import AdminUsersMainForm from './AdminUsersMainForm.vue';
import AdminUsersSubscriptionFilled from './AdminUsersSubscriptionFilled.vue';
// import AdminUsersSubscriptionForm from './AdminUsersSubscriptionForm.vue';
import AdminHeader from './AdminHeader.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import AdminUsersList from './AdminUsersList.vue';
// import AdminUsersSubscriptionList from './AdminUsersSubscriptionList.vue'
import UserNotification from '@/components/Shared/UserNotification.vue';
import _ from "lodash";

export default {
  name: "AdminUsers",
  components: {
    AdminUsersMainForm,
    // AdminUsersSubscriptionForm,
    AdminHeader,
    AdminUsersMainFilled,
    AdminUsersSubscriptionFilled,
    AdminUsersList,
    // AdminUsersSubscriptionList,
    UserNotification
},
  setup() {

    const store = useStore();

    const router = useRouter();

    const mainEditionMode = ref('create');

    const subscriptionEditionMode = ref('edit');

    const submittedUserProfile = ref(false);

    const farms = computed(() => store.state.farms);

    const users = computed(() => store.state.users);

    const newUserIsAdded = ref(false);

    const usersList = ref(null);

    const mainForm = ref(null);

    const popupMessages = reactive({
      assigned: {
        active: false,
        message: 'Your subscription has been assigned to the account'
      },
      userAdded: {
        active: false,
        message: 'A new user has been added to the account. Do you want to add another user?'
      },
      addFarm: {
        active: false,
        message: 'Do you want to add a Farm?'
      },
      addNew: false
    })

    const mainDetails = reactive({
      id: '',
      email: '',
      password: '',
      accountName: '',

      title: '',
      
      // email: '',
      multifactor: false,
      multifactorType: '',
      userRole: '',

      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      mobilePhone: '',
      workPhone: '',
      city: '',
      state: '',
      zip: '',
      country: '',

      employeeType: '',
      grade: '',
    })

    const defaultMainDetails = {
      ...mainDetails
    }

    const subscription = reactive({
      plans: {
        trial: false,
        trialPrice: 'price_1O5VljHpD3HYJIrgI5y2Jhkq',
        
        basic: false,
        basicPrice: '',
        basicNumber: '',
        basicPriceIdYearly: 'price_1NXMRQHpD3HYJIrg2IwC8sri',
        basicPriceIdMonthly: 'price_1NXMNxHpD3HYJIrg0hyDtIQO',
        basicAvailable: '',

        // inventory: false,
        // inventoryPrice: '',
        // inventoryNumber: '',
        plus: false,
        plusPrice: '',
        plusNumber: '',
        plusPriceIdYearly: 'price_1NXMQoHpD3HYJIrgJaWbqhOI',
        plusPriceIdMonthly: 'price_1NXMQoHpD3HYJIrgnhtzXnIF',
        plusAvailable: '',
        
        // equipment: false,
        // equipmentPrice: '',
        // equipmentNumber: '',

        advance: false,
        advancePrice: '',
        advanceNumber: '',
        advancePriceIdYearly: 'price_1NXMhHHpD3HYJIrgUwLCM3C2',
        advancePriceIdMonthly: 'price_1NXMhHHpD3HYJIrgrxWzr1UH',
        advanceAvailable: '',
        
        // allinclusive: false,
        // allinclusivePrice: '',
        // allinclusiveNumber: '',

        ultimate: false,
        ultimatePrice: '',
        ultimateNumber: '',
        ultimatePriceIdYearly: 'price_1NXMklHpD3HYJIrgUKaTXGU9',
        ultimatePriceIdMonthly: 'price_1NXMklHpD3HYJIrgbrsDHSiM',
        ultimateAvailable: '',
        
      },
      
      subscriptionPeriod: 'Monthly',
      
      totalSubscription: '',
      taxes: '',
      discount: '',
      total: '',

      planPurchasingDate: '',
      subscriptionsNumber: 0, // remove later
    })

    const defaultSubscription = {
      ...subscription,
      plans: {
        ...subscription.plans
      }
    }

    const stateSubscription = reactive({
      ...store.state.primaryUser.subscription,
      plans: {
        ...store.state.primaryUser?.subscription?.plans
      }
    })

    const assigned = reactive({
      trial: false,
      basic: false,
      plus: false,
      advance: false,
      ultimate: false
    })

    const companiesList = reactive({
      value: []
    });

    const changeMainField = (val, field) => {
      mainDetails[field] = val;
    }

    const selectMainDetails = (mainDetailsData, editMode) => {
      if (mainDetailsData) {
        for (let field in mainDetailsData) {
          mainDetails[field] = mainDetailsData[field]
        }
      }
      mainEditionMode.value = editMode;
    }

    const selectSubscription = (subscriptionData, editMode) => {
      if (subscriptionData) {
        for (let field in subscriptionData) {
          subscription[field] = subscriptionData[field]
          for (let plan in subscription.plans) {
            subscription.plans[plan] = subscriptionData.plans[plan];
          }
        }
      }
      subscriptionEditionMode.value = editMode;
    }

    const changeSubscriptionField = (val, field, index) => {
      if (index) {
        subscription.modules[index].added = val;
      } else {
        subscription[field] = val;
      }
    }

    const createNewUser = () => {
      router.push({ name: 'page', params: { pagename: 'secondary-user', source: 'admin-users' } })
      // popupMessages.addNew = false;

      // selectMainDetails({...defaultMainDetails}, 'create');
      // selectSubscription({...defaultSubscription}, 'edit');
      // for (let plan in assigned) {
      //   assigned[plan] = false;
      // }

      // setTimeout(() => {
      //   document.getElementsByTagName('main')[0].scroll({
      //     top: mainForm?.value?.offsetTop - 100,
      //     left: 0,
      //     behavior: 'smooth'
      //   })
      // })

    }

    const scrollToMainForm = () => {
      setTimeout(() => {
        document.getElementsByTagName('main')[0].scroll({
          top: mainForm?.value?.offsetTop - 100,
          left: 0,
          behavior: 'smooth'
        })
      })
    }

    const removeUser = (userId) => {
      store.dispatch('removeNewUser', userId)
    };

    const addNewUser = () => {
      mainEditionMode.value = 'create';
    }

    const redirectToHome = () => {
      popupMessages.addNew = false;

      router.push({ name: 'page', params: { pagename: 'dashboard' } })
    }

    const createNewFarm = () => {
      router.push({ name: 'farm', params: { pagename: 'farm-profile' } })
    }

    const scrollToUsersList = () => {
      setTimeout(() => {
        document.getElementsByTagName('main')[0].scroll({
          top: usersList?.value?.offsetTop - 100,
          left: 0,
          behavior: 'smooth'
        })
      })
    }

    const keepSubscriptionInState = () => {
      const selectedUser = store.state.users.find(user => user.id === mainDetails.id)

      if (selectedUser?.subscriptionAssigned) {
        for (let prop in selectedUser.subscriptionAssigned) {
          assigned[prop] = selectedUser.subscriptionAssigned[prop];
          // for (let subProp in selectedUser.subscription.plans) {
          //   stateSubscription.plans[subProp] = selectedUser.subscription.plans[subProp]
          // }
        }
      }
    }

    watch(() => (mainDetails.id), (newVal) => {
      if (newVal) {
        keepSubscriptionInState();        
      }
    })

    watch(() => _.cloneDeep(store.state.primaryUser.subscription), (newVal, oldVal) => {
      console.log(newVal, oldVal, 'in state subscription changed')

      if (newVal) {
        for (let prop in stateSubscription) {
          if (prop === 'plans') {
            for (let plan in stateSubscription.plans) {
              stateSubscription.plans[plan] = newVal.plans[plan];
            }
          }
          else {
            changeSubscriptionField(newVal[prop], prop);
          }
        }
      }
    })

    /*
    watchEffect(() => {
      const selectedUser = store.state.users.find(user => user?.mainDetails?.id === mainDetails.id)

      if (mainDetails.id && selectedUser?.subscriptionAssigned) {
        submittedUserProfile.value = true;
        
        // popupMessages.assigned.active = true;
        popupMessages.userAdded.active = true;

        // setTimeout(() => {
        //   popupMessages.assigned.active = false;
        //   popupMessages.addNew = true;
        // }, 5000)
      } 

    })
    */

    watch(users, (newVal) => {
      const selectedUser = newVal.find(user => user?.mainDetails?.id === mainDetails.id);

      if (mainDetails.id && (selectedUser?.subscriptionAssigned && newUserIsAdded.value)) {
        submittedUserProfile.value = true;
        popupMessages.userAdded.active = true;
        newUserIsAdded.value = false;
      }
    }, {deep: true})

    provide('mainDetails', {
      mainEditionMode,
      mainDetails,
      defaultMainDetails,
      selectMainDetails,
      createNewUser,
      changeMainField,
      removeUser,
      addNewUser,
      newUserIsAdded,
      scrollToMainForm,
      options: companiesList
    })

    provide('subscription', {
      subscriptionEditionMode,
      subscription,
      defaultSubscription,
      stateSubscription,
      assigned,
      selectSubscription,
      changeSubscriptionField
    })

    onMounted(() => {
      (store.state.users.length > 0) && keepSubscriptionInState();

      // if (store.state.users[0]?.mainDetails) { 
      //   selectMainDetails({...store.state.users[0]?.mainDetails}, 'repesent')
      //   console.log(store.state.users[0]);
      // }
      // if (store.state.users[0]?.subscription || store.state.users[0]?.subscriptionAssigned) {
      //   selectSubscription({...store.state.users[0]?.subscription}, 'represent')
      // }
      selectMainDetails({
        ...store.state.primaryUser.mainDetails,
        id: 'primary'
      }, 'represent');

      selectSubscription({...store.state.primaryUser.subscription}, 'represent')

      if (router.currentRoute.value.params.boughtForLabor) {
        subscriptionEditionMode.value = 'edit';
      }

      if (router.currentRoute.value.params.source === 'employee-compensation') {
        mainEditionMode.value = 'edit';

        for (let prop in mainDetails) {
          mainDetails[prop] = store.state.temporaryUser.mainDetails[prop];
        }
      }

      if (router.currentRoute.value.params.source === 'secondary-user') {
        scrollToUsersList();
      }
    })

    return {
      mainEditionMode,
      subscriptionEditionMode,
      submittedUserProfile,
      popupMessages,
      redirectToHome,
      createNewFarm,
      createNewUser,
      farms,
      scrollToUsersList,
      usersList,
      mainForm
    }
  },
};
</script>

<style lang="scss" scoped>
.add-new-popup {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    .popup-message {
      background: #fff;
      display: flex;
      flex-direction: column;
      max-width: 650px;
      width: 100%;
      height: max-content;
      justify-content: space-around;
      align-items: center;
      position: relative;
      border-radius: 11px;
      color: rgba(40,41,43,1);
      font-size: 21px;
      margin: 0 20px;
      padding: 25px 0;
      text-align: center;
      .close-button {
        position: absolute;
        display: flex;
        text-indent: -9999px;
        mask-position: center;
        margin-left: auto;
        width: 30px;
        height: 30px;
        background-image: url('@/assets/profile/close_button.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        right: 10px;
        top: 22px;
        cursor: pointer;
      }
      .popup-text {
        margin: 0 40px;
        font-size: 16px;
        font-weight: 600;
      }
      .popup-buttons {
        display: flex;
        width: 60%;
        justify-content: space-evenly;
        margin-top: 30px;
        button {
          width: 160px;
          height: 40px;
          border-radius: 10px;
          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }
</style>