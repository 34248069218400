<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Inventory Used: {{inventoryUsed.input}}</h5>
<div class="icon-group">
  <!-- <div class="icon green icon-o camera-plus"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
  >
    <img src="@/assets/images/camera-plus.svg" alt="">
    <div class="helper-tooltip" v-if="helperTooltip">Take a shot</div>
  </div> -->
</div>
</div>

<div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.date || (inventoryUsed.date.length > 0)}">Date</p>
<input type="date" class="form-control date-field" :style="{color: (focused.date || inventoryUsed.date.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
  v-model="inventoryUsed.date"
  pattern="\d{2}/\d{2}/\d{4}"
  @focus="() => {
    activated.date = true;
    focused.date = true;
  }"
  @blur="() => {
    focused.date = false;
  }">
  <div class="date-icon"></div>
<p v-if="activated.date && inventoryUsed.date.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.farm || (inventoryUsed.farm.length > 0)}">Farm</p>
  <select class="form-control" 
    v-model="inventoryUsed.farm"
    @click="() => {
      activated.farm = true;
      focused.farm = true;
    }"
    @change="() => {
      focused.farm = false;
    }">
  <option
    v-for="farm in farms"
    v-bind:key="farm.farmName + 'farm-option-input-used'"
  >{{ farm.farmName }}</option>
</select>
<p v-if="activated.farm && inventoryUsed.farm.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.farm && inventoryUsed.farm.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3" v-if="bedsOptions.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.bed || (inventoryUsed.bed.length > 0)}">Bed</p>
<select class="form-control" 
    v-model="inventoryUsed.bed"
    @click="() => {
      activated.bed = true;
      focused.bed = true;
    }"
    @change="() => {
      focused.bed = false;
    }">
    <option
      v-for="bed in bedsOptions"
      v-bind:key="bed + 'bed-option-task'"
    >{{ bed }}</option>
  </select>
<p v-if="activated.bed && inventoryUsed.bed.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.bed && inventoryUsed.bed.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
</div>
</div>

<div class="col-3 common">
<div class="form-item">
<p class="form-label" :class="{active: focused.season || (inventoryUsed.season.length > 0)}">Season</p>
<select class="form-control"
  v-model="inventoryUsed.season"
  @click="() => {
    activated.season = true;
    focused.season = true;
  }"
  @change="() => {
    focused.season = false;
  }"
  disabled
>
  <option v-for="seasonOption in cropSeasons"
    v-bind:key="seasonOption + 'season-option-task'"
  >{{ seasonOption }}</option>
</select>
<p v-if="activated.season && inventoryUsed.season.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.season && inventoryUsed.season.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3 common">
<div class="form-item">
<p class="form-label" :class="{active: focused.cropCycle || (inventoryUsed.cropCycle.length > 0)}">Crop Cycle</p>
<input type="text" class="form-control"
  v-model="inventoryUsed.cropCycle"
  @focus="() => {
    activated.cropCycle = true;
    focused.cropCycle = true;
  }"
  @blur="() => {
    focused.cropCycle = false;
  }" disabled />
<p v-if="activated.cropCycle && inventoryUsed.cropCycle.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.cropCycle && inventoryUsed.cropCycle.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3 common" v-if="crops.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.crop || (inventoryUsed.crop.length > 0)}">Crop</p>
  <select class="form-control" 
    v-model="inventoryUsed.crop"
    @click="() => {
      activated.crop = true;
      focused.crop = true;
    }"
    @change="() => {
      focused.crop = false;
    }">
    <option
      v-for="cropItem in crops"
      v-bind:key="cropItem.id + 'crop-option-task'"
    >{{ cropItem.crop }}</option>
  </select>
<p v-if="activated.crop && inventoryUsed.crop.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.crop && inventoryUsed.crop.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3 common" v-else>
<div class="form-item">
<p class="form-label" :class="{active: focused.crop || (inventoryUsed.crop.length > 0)}">Crop</p>
<input type="text" class="form-control"
  v-model="inventoryUsed.crop"
  @focus="() => {
    activated.crop = true;
    focused.crop = true;
  }"
  @blur="() => {
    focused.crop = false;
  }">
<p v-if="activated.crop && inventoryUsed.crop.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.crop && inventoryUsed.crop.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.task || (inventoryUsed.task.length > 0)}">Task</p>
<input type="text" class="form-control" v-model="inventoryUsed.task"
  @focus="() => {
    activated.task = true;
    focused.task = true;
  }"
  @blur="() => {
    focused.task = false;
  }">
<p v-if="activated.task && inventoryUsed.task.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.task && inventoryUsed.task.length > 150" class="form-info red text-end">Please use maximum 150 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.assignedTo || (inventoryUsed.assignedTo.length > 0)}">Assigned To</p>
<input type="text" class="form-control" v-model="inventoryUsed.assignedTo"
  @focus="() => {
    activated.assignedTo = true;
    focused.assignedTo = true;
  }"
  @blur="() => {
    focused.assignedTo = false;
  }">
<p v-if="activated.assignedTo && inventoryUsed.assignedTo.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.assignedTo && inventoryUsed.assignedTo.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3 input-item" v-if="products.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.input || (inventoryUsed.input.length > 0)}">Input</p>
  <select class="form-control" 
    v-model="inventoryUsed.input"
    @click="() => {
      activated.input = true;
      focused.input = true;
    }"
    @change="() => {
      focused.input = false;
    }">
    <option
      v-for="prod in products"
      v-bind:key="prod.id + 'product-option-task'"
    >{{ prod.item }}</option>
  </select>
<p v-if="activated.input && inventoryUsed.input.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.input && inventoryUsed.input.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3 input-item" v-else>
<div class="form-item">
<p class="form-label" :class="{active: focused.input || (inventoryUsed.input.length > 0)}">Input</p>
<input type="text" class="form-control" 
  v-model="inventoryUsed.input"
  @focus="() => {
    activated.input = true;
    focused.input = true;
  }"
  @blur="() => {
    focused.input = false;
  }">
<p v-if="activated.input && inventoryUsed.input.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.input && inventoryUsed.input.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.brand || (inventoryUsed.brand.length > 0)}">Brand</p>
<input type="text" class="form-control"  v-model="inventoryUsed.brand"
  @focus="() => {
    activated.brand = true;
    focused.brand = true;
  }"
  @blur="() => {
    focused.brand = false;
  }">
<p v-if="activated.brand && inventoryUsed.brand.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.brand && inventoryUsed.brand.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.variety || (inventoryUsed.variety.length > 0)}">Variety</p>
<input type="text" class="form-control"  v-model="inventoryUsed.variety"
  @focus="() => {
    activated.variety = true;
    focused.variety = true;
  }"
  @blur="() => {
    focused.variety = false;
  }">
<p v-if="activated.variety && inventoryUsed.variety.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.numberOfItems || (inventoryUsed.numberOfItems > 0)}"># of Items</p>
<input type="number" class="form-control" v-model="inventoryUsed.numberOfItems"
  @focus="() => {
    activated.numberOfItems = true;
    focused.numberOfItems = true;
  }"
  @blur="() => {
    focused.numberOfItems = false;
  }">
<p v-if="activated.numberOfItems && inventoryUsed.numberOfItems.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.numberOfItems && `${inventoryUsed.numberOfItems}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<!-- <div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.quantity || (inventoryUsed.quantity > 0)}">Quantity</p>
<input type="number" class="form-control" v-model="inventoryUsed.quantity"
  @focus="() => {
    activated.quantity = true;
    focused.quantity = true;
  }"
  @blur="() => {
    focused.quantity = false;
  }">
<p v-if="activated.quantity && inventoryUsed.quantity.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.quantity && `${inventoryUsed.quantity}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div> -->

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.quantity || (inventoryUsed.quantity > 0)}">Weight (lbs/item)</p>
<input type="number" class="form-control" v-model="inventoryUsed.quantity"
  @focus="() => {
    activated.quantity = true;
    focused.quantity = true;
  }"
  @blur="() => {
    focused.quantity = false;
  }">
<p v-if="activated.quantity && inventoryUsed.quantity.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.quantity && `${inventoryUsed.quantity}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

</div>
</div>
</div>

</div>

<div class="content-block">

<div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
</div>
</div>
</div>

</div>

</div>
</template>

<script>
import { reactive, inject, ref, computed, watch, onMounted } from 'vue';
// import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { useStore } from 'vuex';


export default {
  name: "InventoryUsed",
  setup() {
    const store = useStore();

    const cropSeasons = ['Spring', 'Summer', 'Autumn', 'Winter'];

    const activated = reactive({
      date: false,
      input: false,
      brand: false,
      variety: false,
      numberOfItems: false,
      quantity: false,
      task: false,
      assignedTo: false,
      farm: false,
      bed: false,
      season: false,
      cropCycle: false,
      crop: false
    });

    const focused = reactive({
      date: false,
      input: false,
      brand: false,
      variety: false,
      numberOfItems: false,
      quantity: false,
      task: false,
      assignedTo: false,
      farm: false,
      bed: false,
      season: false,
      cropCycle: false,
      crop: false
    });

    const bedsOptions = ref([]);

    const farms = computed(() => store.state.farms);

    const products = computed(() => [...store.state.vendorItems].filter(vendorItem => vendorItem.type === 'Product'));

    const usersState = computed(() => store.state.users);

    const users = ref([]);

    const cropsState = computed(() => store.state.crops);

    const crops = ref([]);

    const { inventoryUsed, inventoryUsedEditionMode, selectedInventoryUsed, 
      selectInventoryUsed, defaultInventoryUsed } = inject("inventoryUsedDetails");

    const helperTooltip = ref(false);

    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle;
    }

    const submitForm = () => {
      if (!inventoryUsed.date || !inventoryUsed.input || !inventoryUsed.brand ||
      !inventoryUsed.numberOfItems || !inventoryUsed.quantity ||
       !inventoryUsed.task || !inventoryUsed.assignedTo || !inventoryUsed.farm) {
        if (!inventoryUsed.date) activated.date = true;
        if (!inventoryUsed.input) activated.product = true;
        if (!inventoryUsed.brand) activated.brand = true;
        if (!inventoryUsed.numberOfItems) activated.numberOfItems = true;
        if (!inventoryUsed.quantity) activated.quantity = true;
        if (!inventoryUsed.task) activated.task = true;
        if (!inventoryUsed.assignedTo) activated.assignedTo = true;
        if (!inventoryUsed.farm) activated.farm = true;

        return;
      }

      // if (!inventoryUsed.id) {
      //   inventoryUsed.id = uuid();
      // }
      
      inventoryUsed.date = moment(inventoryUsed.date).format('MM/DD/YYYY');

      const formData = {
        ...inventoryUsed
      };

      // (inventoryUsedEditionMode.value === "edit") ?
      //     store.dispatch("editInventoryUsed", { ...formData }) :
      //     store.dispatch("createInventoryUsed", {...formData});
      // console.log(formData);
      // (inventoryUsedEditionMode.value === "create") && closeForm();
      store.dispatch("editTask", { ...formData });
      (inventoryUsedEditionMode.value === "edit") && selectInventoryUsed({...inventoryUsed}, 'represent');
    }

    // const closeForm = () => {
    //   for (let prop in inventoryUsed) {
    //     inventoryUsed[prop] = defaultInventoryUsed[prop];
    //   }

    //   for (let prop in activated) {
    //     activated[prop] = false;
    //   }
      
    //   for (let prop in focused) {
    //     focused[prop] = false;
    //   }
    // }

    const cancelChanges = () => {
      if (inventoryUsedEditionMode.value === "edit") {
        for (let prop in inventoryUsed) {
          inventoryUsed[prop] = selectedInventoryUsed[prop];
        }
        inventoryUsedEditionMode.value = "represent";
      } else {
        for (let prop in inventoryUsed) {
          inventoryUsed[prop] = defaultInventoryUsed[prop];
        }
      }
    };

    watch(() => ({...inventoryUsed}), (newVal, oldVal) => {
      if (newVal?.farm) {
        const selectedFarm = farms.value.find(frm => frm.farmName === newVal.farm)

        const selectedFarmBeds = selectedFarm.beds.filter((bed, index) => bed.bedNumber !== selectedFarm.beds[index - 1]?.bedNumber)

        bedsOptions.value = selectedFarmBeds.map(bed => bed.bedNumber);

        if (bedsOptions.value.length > 0) {
          if (!newVal.bed) {
            inventoryUsed.bed = selectedFarm.beds[0].bedNumber
          }
          if (newVal.bed !== oldVal.bed) {
            const selectedBed = selectedFarm.beds.find(bed => bed.bedNumber === newVal.bed);
            crops.value = [...cropsState.value.filter(crp => crp.sourceId === selectedBed.sourceId)];
            console.log(crops.value, 'crops.value', selectedBed, 'selectedBed');
            if (crops.value.length > 0) {
              inventoryUsed.crop = crops.value[0].crop || '';
              inventoryUsed.cropCycle = crops.value[0].cropCycle || '';
              inventoryUsed.season = crops.value[0].season || '';
            }
          }
        } else {
          if (newVal.farm !== oldVal.farm) {
            inventoryUsed.bed = '';
            crops.value = [...cropsState.value.filter(crp => crp.sourceId === selectedFarm.id)];
            if (crops.value.length > 0) {
              inventoryUsed.crop = crops.value[0].crop || '';
              inventoryUsed.cropCycle = crops.value[0].cropCycle || '';
              inventoryUsed.season = crops.value[0].season || '';
            }
          }
        }

        if (newVal?.input) {
          const selectedProduct = products.value.find(prd => prd.product === newVal.input);
          
          if (selectedProduct) {
            inventoryUsed.brand = selectedProduct.brand;
            inventoryUsed.variety = selectedProduct.variety;
          }
        }
      }

    })

    onMounted(() => {
      inventoryUsed.farm = farms.value[0]?.farmName || '';

      users.value = store.state.users.find(u => u._id === store.state.authenticated._id) ?
        [...usersState.value] :
        [
          {
            ...store.state.primaryUser,
            mainDetails: {
              ...store.state.primaryUser.mainDetails,
              id: 'primary'
            }
          },
          ...usersState.value
        ]
    })

    return {
      activated,
      focused,
      inventoryUsed,
      farms,
      bedsOptions,
      products,
      users,
      crops,
      cropSeasons,
      submitForm,
      cancelChanges,
      helperTooltip,
      switchHelperTooltip
    }
    
  }
}
</script>

<style lang="scss" scoped>
.form-control {
  &.date-field {
    padding-right: 1.8rem;
    background: none;
  }
}
.date-icon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 3.7rem;
  right: 1rem;
  z-index: 8;
  pointer-events: none;
  border-radius: 1rem;
  background:url('@/assets/images/calendar-icon.svg') center center no-repeat;
  background-color: #fff;
}
</style>