<template>

  <div class="section" ref="section">

  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5>Subscription Type</h5>
  <div class="info-icons">
    <div class="info-icon lg edit-subscription tooltip"
      @click="() => {
        subscriptionEditionMode = 'edit'
      }"
      data-tooltip="Edit Subscription"
      @mouseover="switchEditHelperTooltip(true)"
      @mouseout="switchEditHelperTooltip(false)"
    >
      <!-- <img src="@/assets/images/edit-profile-icon.svg" alt=""> -->
      <img src="@/assets/images/updated/Edit.png" alt="">
      <!-- <div class="edit-helper-tooltip" v-if="editHelperTooltip">Edit Subscription</div> -->
    </div>
    <!-- <div class="info-icon lg header-icon"
      @click="addSubscription"
      @mouseover="switchAddHelperTooltip(true)"
      @mouseout="switchAddHelperTooltip(false)"
    >
      <img src="@/assets/images/updated/059-add.png" alt="">
      <div class="add-helper-tooltip" v-if="addHelperTooltip">Add Subscription</div>
    </div> -->
  </div>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>

  <div class="border-box-content subscription-type-content">

  <div class="content-block">

    <div class="content-row grid-4" v-if="assigned.trial || (noAssignment && !subscription.plans.basic && !subscription.plans.plus && !subscription.plans.advance && !subscription.plans.ultimate)">
      <div class="row">
      <div class="col-6">
        <div class="form-item">
          <p class="form-label-static">Free 30 Days Trial</p>
          <h6>Expires on {{ moment(subscription.planPurchasingDate).add(30, 'day').format('MM/DD/YYYY') }}</h6>
          // .format('MMMM Do YYYY') }}
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
        <div class="d-flex flex-column align-items-center">
        <p class="form-label-static">Assigned</p>
        <div class="form-item d-flex">
        <div class="checkbox checkbox-lg d-inline-flex">
          <input type="checkbox" id="assign3" checked="true" />
          <label for="assign3" class="p-0"></label>
        </div>
        </div>
        </div>
        </div>
      </div>

      </div>
      </div>

      <div class="content-row grid-4" v-else>
      <div class="row">
      <div class="col-6">
        <div class="form-item">
          <template v-if="assigned.basic">
            <!--  || (noAssignment && subscription.plans.basic) -->
            <p class="form-label-static">Basic</p>
          </template>
          <template v-if="assigned.plus">
            <!--  || (noAssignment && subscription.plans.plus) -->
            <p class="form-label-static">Basic +</p>
          </template>
          <template v-if="assigned.advance">
            <!--  || (noAssignment && subscription.plans.advance) -->
            <p class="form-label-static">Basic + Advance</p>
          </template>
          <template v-if="assigned.ultimate">
            <!--  || (noAssignment && subscription.plans.ultimate) -->
            <p class="form-label-static">Basic + Ultimate</p>
          </template>
          <template v-if="subscription.subscriptionPeriod === 'Yearly'">
            <h6>Expires on {{ moment(subscription.planPurchasingDate).add(365, 'day').format('MM/DD/YYYY') }}</h6>
            <!-- .format('MMMM Do YYYY') -->
          </template>
          <template v-if="subscription.subscriptionPeriod === 'Monthly'">
            <h6>Expires on {{ moment(subscription.planPurchasingDate).add(30, 'day').format('MM/DD/YYYY') }}</h6>
          </template>
        </div>
      </div>

      <div class="col-3">
        <div class="form-item">
        <div class="d-flex flex-column align-items-center">
        <p class="form-label-static">Assigned</p>
        <div class="form-item d-flex">
        <div class="checkbox checkbox-lg d-inline-flex">
          <input type="checkbox" id="assign3" checked="true" />
          <label for="assign3" class="p-0"></label>
        </div>
        </div>
        </div>
        </div>
      </div>

      </div>
      </div>

  </div>

  </div>

  </div>
</template>

<script>
import { inject, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import _ from "lodash";
import { useRouter } from "vue-router";
import moment from "moment";

export default {
  name: 'AdminAccountSubscriptionFilled',
  setup() {
    const store = useStore();
    const router = useRouter();

    const { 
      assigned, 
      subscription, 
      stateSubscription, 
      changeSubscriptionField,
      subscriptionEditionMode
    } = inject('subscription');

    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);

    const openedActions = ref(false);

    const editHelperTooltip = ref(false);
    const addHelperTooltip = ref(false);

    const subscriptionAssigned = ref({});

    const noAssignment = ref(null);

    // const subscriptionTooltip = ref(false);

    const switchEditHelperTooltip = (toggle) => {
      editHelperTooltip.value = toggle;
    }

    const switchAddHelperTooltip = (toggle) => {
      addHelperTooltip.value = toggle;
    }

    // const switchSubscriptionTooltip = (toggle) => {
    //   subscriptionTooltip.value = toggle;
    // }

    const toggleSection = () => {
      sectionExpanded.value = !sectionExpanded.value
    }

    const addSubscription = () => {
      router.push({
        name: 'admin', 
        params: {
          pagename: 'admin-subscription',
          source: 'admin-account'
        }
      })
    }

    watch(sectionExpanded, (newVal) => {
      section.value.style.height = newVal ? 
        `${sectionHeight.value}px` : 
          `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'    })

    watch(() => _.cloneDeep(assigned), (newVal, oldVal) => {
      section.value.style.height = 'auto';
      setTimeout(() => {
        sectionHeight.value = section.value.offsetHeight;
      })
      for (let prop in newVal) {
        if (newVal[prop]) {
          if (store.state.primaryUser.subscriptionAssigned &&
            !store.state.primaryUser.subscriptionAssigned[prop]
          ) {
            openedActions.value = true;
          }

          for (let subProp in subscription.plans) {
            if (newVal[prop] && !oldVal[prop]) {
              if (subProp.includes(prop) && subProp.includes('Number')) {
                subscription.plans[subProp] = subscription.plans[subProp] - 1;
              }
            }
          }
        }
        if (!newVal[prop] && oldVal[prop]) {
          for (let subProp in subscription.plans) {
            // if (newVal[prop]) {
              if (subProp.includes(prop) && subProp.includes('Number')) {
                subscription.plans[subProp] = subscription.plans[subProp] + 1;
              }
            // }
          }
        }
      }
    })

    onMounted(() => {

      setTimeout(() => {
        sectionHeight.value = section.value.offsetHeight;
        section.value.style.height = `${section.value.offsetHeight}px`;
      });

      subscriptionAssigned.value = {...store.state.primaryUser.subscriptionAssigned};

      if (store.state.primaryUser?.subscription) {
        for (let prop in subscription) {
          if (prop === 'plans') {
            for (let plan in subscription.plans) {
              subscription.plans[plan] = store.state.primaryUser?.subscription?.plans[plan];
            }
          } else {
            // subscription[prop] = store.state.primaryUser?.subscription[prop];
            changeSubscriptionField(store.state.primaryUser?.subscription[prop], prop);
          }
        }
        // subscriptionEditionMode.value = 'represent'
      }

      console.log(store.state.primaryUser.subscriptionAssigned, 'store.state.primaryUser.subscriptionAssigned');

      if (store.state.primaryUser.subscriptionAssigned) {
        for (let plan in store.state.primaryUser.subscriptionAssigned) {
          for (let option in assigned) {
            if ((option === plan) && store.state.primaryUser.subscriptionAssigned[plan]) {
              assigned[option] = true;
              openedActions.value = false;

              for (let subProp in store.state.primaryUser.subscription.plans) {
                if (subProp.includes(plan) && subProp.includes('Number')) {
                  subscription.plans[subProp] = subscription.plans[subProp] - 1;
                }
              }
            }
          }
        }
      }

      for (let plan in assigned) {
        if (assigned[plan]) {
          noAssignment.value = false;
        } else {
          noAssignment.value = true;
        }
      }
    })

    return {
      subscriptionEditionMode,
      subscription,
      stateSubscription,
      changeSubscriptionField,
      assigned,

      editHelperTooltip,
      switchEditHelperTooltip,
      addHelperTooltip,
      switchAddHelperTooltip,
      addSubscription,

      // subscriptionTooltip,
      // switchSubscriptionTooltip,
      section,
      boxHeader,
      toggleSection,
      subscriptionAssigned,

      moment,
      noAssignment
    };
  }
}
</script>

<style lang="scss" scoped>
.section {
  .edit-subscription {
    position: relative;
  }

  .edit-helper-tooltip {
    width: max-content;
    padding: 0 15px;
    height: 30px;
    position: absolute;
    z-index: 999;

    background-color: #3C3838;
    color: #fff;
    top: 50px;
    left: -15px;
    text-indent: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: -7px;
      border-color: #3C3838;
      border-left: 8px solid hsl(0, 3%, 23%);
      border-bottom: 6px solid #3C3838;
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 27px;
      background: #3C3838;
      z-index: -1;
    }
  }
  .col-2 {
    text-align: center;
  }
}
</style>