<template>
  <AdminHeader />
  <section class="section mt-1">
    <div class="border-box">
      <div ref="subscriptionForm"></div>
      <AdminSubscriptionForm  v-if="(subscriptionEditionMode === 'create')" />
      <AdminSubscriptionAssignment v-if="(subscriptionEditionMode === 'edit') " />
      <AdminSubscriptionFilled v-if="subscriptionEditionMode === 'represent'" />
      <AdminSubscriptionList />
      
    </div>
  </section>
</template>

<script>
import { onMounted, provide, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
// import { useRouter } from 'vue-router';

import AdminSubscriptionFilled from './AdminSubscriptionFilled.vue';
import AdminSubscriptionForm from './AdminSubscriptionForm.vue';
import AdminSubscriptionList from './AdminSubscriptionList.vue';
import AdminHeader from './AdminHeader.vue';
import _ from "lodash";
import { useRouter } from 'vue-router';
import AdminSubscriptionAssignment from './AdminSubscriptionAssignment.vue';

export default {
  name: "AdminSubscription",
  components: {
    AdminSubscriptionForm,
    AdminSubscriptionFilled,
    AdminSubscriptionList,
    AdminHeader,
    AdminSubscriptionAssignment
},
  setup() {

    const store = useStore();
    const router = useRouter();

    const subscriptionEditionMode = ref('represent');
    const subscriptionForm = ref(null);

    const mainDetails = reactive({
      email: '',
      password: '',
      accountName: '',

      title: '',
      
      // email: '',
      role: 'admin',
      multifactor: false,
      multifactorType: '',
      userRole: '',

      firstName: '',
      lastName: '',
      address: '',
      address2: '',
      mobilePhone: '',
      workPhone: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    })
    
    const defaultMainDetails = {
      ...mainDetails
    }

    const subscription = reactive({
      plans: {
        trial: false,
        trialPrice: 'price_1O5VljHpD3HYJIrgI5y2Jhkq',
        
        basic: false,
        basicPrice: '',
        basicNumber: '',
        basicPriceIdYearly: 'price_1NXMRQHpD3HYJIrg2IwC8sri',
        basicPriceIdMonthly: 'price_1NXMNxHpD3HYJIrg0hyDtIQO',
        basicAvailable: '',

        // inventory: false,
        // inventoryPrice: '',
        // inventoryNumber: '',
        plus: false,
        plusPrice: '',
        plusNumber: '',
        plusPriceIdYearly: 'price_1NXMQoHpD3HYJIrgJaWbqhOI',
        plusPriceIdMonthly: 'price_1NXMQoHpD3HYJIrgnhtzXnIF',
        plusAvailable: '',
        
        // equipment: false,
        // equipmentPrice: '',
        // equipmentNumber: '',

        advance: false,
        advancePrice: '',
        advanceNumber: '',
        advancePriceIdYearly: 'price_1NXMhHHpD3HYJIrgUwLCM3C2',
        advancePriceIdMonthly: 'price_1NXMhHHpD3HYJIrgrxWzr1UH',
        advanceAvailable: '',
        
        // allinclusive: false,
        // allinclusivePrice: '',
        // allinclusiveNumber: '',

        ultimate: false,
        ultimatePrice: '',
        ultimateNumber: '',
        ultimatePriceIdYearly: 'price_1NXMklHpD3HYJIrgUKaTXGU9',
        ultimatePriceIdMonthly: 'price_1NXMklHpD3HYJIrgbrsDHSiM',
        ultimateAvailable: '',
      },
      
      subscriptionPeriod: 'Monthly',
      
      totalSubscription: '',
      taxes: '',
      discount: '',
      total: '',

      planPurchasingDate: store.state.authenticated.registrationDate,
      subscriptionsNumber: 0, // remove later
    })

    const defaultSubscription = {
      ...subscription,
      plans: {
        ...subscription.plans
      }
    }

    const stateSubscription = reactive({
        ...store.state.primaryUser.subscription,
        plans: {
            ...store.state.primaryUser?.subscription?.plans
        }
    });

    const assigned = reactive({
      trial: false,
      basic: false,
      plus: false,
      advance: false,
      ultimate: false
    })

    const availablePlans = reactive({
      basic: store.state.primaryUser?.subscription?.plans?.basicNumber > 0,
      plus: store.state.primaryUser?.subscription?.plans?.plusNumber > 0,
      advance: store.state.primaryUser?.subscription?.plans?.advanceNumber > 0,
      ultimate: store.state.primaryUser?.subscription?.plans?.ultimateNumber > 0
    });

    const isPrimaryUpdate = ref({
      assignedPlan: null
    });

    const selectMainDetails = (mainDetailsData) => {
      if (mainDetailsData) {
        for (let field in mainDetailsData) {
          mainDetails[field] = mainDetailsData[field]
        }
      }
    }

    const selectSubscription = (subscriptionData, editMode, noScroll) => {
      if (subscriptionData) {
        for (let field in subscriptionData) {
          subscription[field] = subscriptionData[field]
        }
      }
      subscriptionEditionMode.value = editMode;

      if (!noScroll) {
        setTimeout(() => {
          document.getElementsByTagName('main')[0].scroll({
            top: subscriptionForm?.value?.offsetTop - 100,
            left: 0,
            behavior: 'smooth'
          })
        })
      }
    }

    const changeSubscriptionField = (val, field, index) => {
        if (index) {
            subscription.modules[index].added = val;
        }
        else {
            subscription[field] = val;
        }
    };

    watch(() => (mainDetails.id), (newVal) => {
      if (newVal) {
        const selectedUser = store.state.users.find(user => user.id === mainDetails.id)

        if (selectedUser?.subscription) {
          for (let prop in selectedUser.subscription) {
            stateSubscription[prop] = selectedUser.subscription[prop];
            for (let subProp in selectedUser.subscription.plans) {
              stateSubscription.plans[subProp] = selectedUser.subscription.plans[subProp]
            }
          }
        }
      }
    })

    watch(() => _.cloneDeep(subscription), (newVal, oldVal) => {
      console.log(newVal, oldVal, 'subscription changed')
    })

    // watch(() => _.cloneDeep(store.state.primaryUser.subscription), (newVal, oldVal) => {
    watch(() => ({...store.state.primaryUser.subscription}), (newVal, oldVal) => {
      console.log(newVal, oldVal, 'in state subscription changed')

      if (newVal) {
        for (let prop in stateSubscription) {
          if (prop === 'plans') {
            for (let plan in stateSubscription.plans) {
              stateSubscription.plans[plan] = newVal.plans[plan];
            }
          }
          else {
            stateSubscription[prop] = newVal[prop];
            // changeSubscriptionField(newVal[prop], prop);
          }
        }

        availablePlans.basic = newVal?.plans?.basicNumber > 0;
        availablePlans.plus = newVal?.plans?.plusNumber > 0;
        availablePlans.advance = newVal?.plans?.advanceNumber > 0;
        availablePlans.ultimate = newVal?.plans?.ultimateNumber > 0;
      }

      if (newVal && isPrimaryUpdate.value.assignedPlan) {
        store.dispatch('savePrimaryUser', { section: 'subscriptionAssigned', data: { ...isPrimaryUpdate.value.assignedPlan } });
        store.dispatch('assignSubscription', { assignedPlan: {...isPrimaryUpdate.value.assignedPlan} });
        store.dispatch('getUsersList');
        isPrimaryUpdate.value.assignedPlan = null;
      }
    })

    // watch(() => store.state.subscriptionPlan, (newVal, oldVal) => {

    //   const previousState = Object.entries(oldVal);
    //   const currentState = Object.entries(newVal);

    //   if (previousState.length > 0) {
    //     if (currentState.length > 0) {
    //       // isChanged = true;
    //       if (!store.state.adminPaymentIsChanged) {
    //         store.dispatch('changeAdminPayment', {isChanged: true});
    //       }
    //     }
    //   }

    // }, { deep: true });

    provide('mainDetails', {
      mainDetails,
      defaultMainDetails,
      selectMainDetails
    })

    provide('subscription', {
      subscriptionEditionMode,
      subscription,
      defaultSubscription,
      stateSubscription,
      assigned,
      availablePlans,
      selectSubscription,
      changeSubscriptionField,
      isPrimaryUpdate
    })

    onMounted(() => {
      if (
        router.currentRoute.value.params.source === 'admin-account' ||
        router.currentRoute.value.params.source === 'admin-users' ||
        router.currentRoute.value.params.source === 'secondary-user' ||
        router.currentRoute.value.params.source === 'primary-user' ||
        router.currentRoute.value.params.source === 'admin-module'
      ) {
        subscriptionEditionMode.value = 'create';
      }

      if (router.currentRoute.value.params.source === 'admin-payment') {
        subscriptionEditionMode.value = 'edit';
      }

      if (store.state.primaryUser?.subscription) {
        for (let prop in stateSubscription) {
          if (prop === 'plans') {
            for (let plan in stateSubscription.plans) {
              stateSubscription.plans[plan] = store.state.primaryUser?.subscription?.plans[plan];
            }
          }
          else {
            stateSubscription[prop] = store.state.primaryUser?.subscription[prop];
          }
        }
      }
    })

    return {
      subscriptionEditionMode,
      subscriptionForm
    }
  },
};
</script>

<style></style>