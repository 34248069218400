<template>
  <section class="section" ref="section">
      <div class="border-box active">
          <div class="border-box-header" ref="boxHeader" @click="toggleSection">
              <h5>Total Expenses</h5>
              <img src="@/assets/images/angle-down.png" alt="" class="angle">
          </div>
          <div class="border-box-content">

              <div class="content-block">

                  <div class="content-row grid-4 grid-full">
                      <div class="row">

                          <div class="col-3">
                              <div class="form-item">
                                  <p class="form-label">Choose a Farm:</p>
                                  <DropDownList
                                    :mainObject="'currentSelection'"
                                    :mainProperty="'farm'"
                                    :injectionName="'selectionDetails'"
                                    :optionKey="'farm-option-inventory-expenses'"
                                    :optionsList="[]"
                                    :optionProperty="'farmName'"
                                    :optionPropertyTwo="null"
                                    :defaultSelected="currentSelection.farm"
                                    @click="() => {
                                        // activated.farm = true;
                                    }"
                                  />
                              </div>
                          </div>

                          <div class="col-3">
                              <div class="form-item">
                                  <p class="form-label">Choose a Year:</p>
                                  <DropDownList
                                    :mainObject="'currentSelection'"
                                    :mainProperty="'year'"
                                    :injectionName="'selectionDetails'"
                                    :optionKey="'year-option-inventory-expenses'"
                                    :optionsList="yearOptions"
                                    :optionProperty="null"
                                    :optionPropertyTwo="null"
                                    :defaultSelected="yearOptions[0]"
                                    @click="() => {
                                        // activated.year = true;
                                    }"
                                  />
                              </div>
                          </div>

                      </div>
                  </div>

              </div>

              <div class="content-block">

                  <div class="content-row table-wrapper-block">
                      <div class="table-wrapper">
                          <table class="headding-table">
                              <thead>
                                  <tr><th><p class="form-label">&nbsp;</p></th></tr>
                              </thead>
                              <tbody>
                                  <tr><td><p class="form-label">Employee Expenses</p></td></tr>
                                  <tr><td><p class="form-label">Inventory Expenses</p></td></tr>
                                  <tr><td><p class="form-label">Equipment Expenses</p></td></tr>
                                  <tr><td><p class="form-label">Servicing Expenses</p></td></tr>
                                  <tr><td><p class="form-label">Administrative Expenses</p></td></tr>
                                  <tr><td><p class="form-label green">Total Expenses</p></td></tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="table-wrap flex-1">
                          <table>

                              <thead>
                                  <tr>
                                      <th class="text-center" style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Jan</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Feb</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Mar</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Apr</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">May</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Jun</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Jul</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Aug</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Sep</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Oct</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Nov</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label">Dec</p>
                                      </th>
                                      <th style="width: 10%; min-width: 8rem;">
                                          <p class="form-label green">Total</p>
                                      </th>
                                  </tr>
                              </thead>

                              <tbody>

                                <tr>
                                  <td class="text-center">{{ `$${Number.parseFloat(rows.labor.january).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.labor.february).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.labor.march).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.labor.april).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.labor.may).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.labor.june).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.labor.july).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.labor.august).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.labor.september).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.labor.october).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.labor.november).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.labor.december).toFixed(2)}` }}</td>
                                  <td><span class="bg">{{ `$${Number.parseFloat(rows.labor.total).toFixed(2)}` }}</span></td>
                                </tr>

                                <tr>
                                  <td class="text-center">{{ `$${Number.parseFloat(rows.inventory.january).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.inventory.february).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.inventory.march).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.inventory.april).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.inventory.may).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.inventory.june).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.inventory.july).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.inventory.august).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.inventory.september).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.inventory.october).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.inventory.november).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.inventory.december).toFixed(2)}` }}</td>
                                  <td><span class="bg">{{ `$${Number.parseFloat(rows.inventory.total).toFixed(2)}` }}</span></td>
                                </tr>

                                <tr>
                                  <td class="text-center">{{ `$${Number.parseFloat(rows.equipment.january).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.equipment.february).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.equipment.march).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.equipment.april).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.equipment.may).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.equipment.june).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.equipment.july).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.equipment.august).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.equipment.september).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.equipment.october).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.equipment.november).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.equipment.december).toFixed(2)}` }}</td>
                                  <td><span class="bg">{{ `$${Number.parseFloat(rows.equipment.total).toFixed(2)}` }}</span></td>
                                </tr>

                                <tr>
                                  <td class="text-center">{{ `$${Number.parseFloat(rows.servicing.january).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.servicing.february).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.servicing.march).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.servicing.april).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.servicing.may).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.servicing.june).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.servicing.july).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.servicing.august).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.servicing.september).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.servicing.october).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.servicing.november).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.servicing.december).toFixed(2)}` }}</td>
                                  <td><span class="bg">{{ `$${Number.parseFloat(rows.servicing.total).toFixed(2)}` }}</span></td>
                                </tr>

                                <tr>
                                  <td class="text-center">{{ `$${Number.parseFloat(rows.administrative.january).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.administrative.february).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.administrative.march).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.administrative.april).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.administrative.may).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.administrative.june).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.administrative.july).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.administrative.august).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.administrative.september).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.administrative.october).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.administrative.november).toFixed(2)}` }}</td>
                                  <td>{{ `$${Number.parseFloat(rows.administrative.december).toFixed(2)}` }}</td>
                                  <td><span class="bg">{{ `$${Number.parseFloat(rows.administrative.total).toFixed(2)}` }}</span></td>
                                </tr>
                                
                                <tr>
                                      <td class="text-center"><span class="bg">${{ 
                                        Number.parseFloat(
                                          Number.parseFloat(rows.labor.january) +
                                          Number.parseFloat(rows.inventory.january) + Number.parseFloat(rows.equipment.january) +
                                          Number.parseFloat(rows.servicing.january) + Number.parseFloat(rows.administrative.january)
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          Number.parseFloat(rows.labor.february) +
                                          Number.parseFloat(rows.inventory.february) + Number.parseFloat(rows.equipment.february) +
                                          Number.parseFloat(rows.servicing.february) + Number.parseFloat(rows.administrative.february)
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          Number.parseFloat(rows.labor.march) +
                                          Number.parseFloat(rows.inventory.march) + Number.parseFloat(rows.equipment.march) +
                                          Number.parseFloat(rows.servicing.march) + Number.parseFloat(rows.administrative.march)
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          Number.parseFloat(rows.labor.april) +
                                          Number.parseFloat(rows.inventory.april) + Number.parseFloat(rows.equipment.april) +
                                          Number.parseFloat(rows.servicing.april) + Number.parseFloat(rows.administrative.april)
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          Number.parseFloat(rows.labor.may) +
                                          Number.parseFloat(rows.inventory.may) + Number.parseFloat(rows.equipment.may) +
                                          Number.parseFloat(rows.servicing.may) + Number.parseFloat(rows.administrative.may)
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          Number.parseFloat(rows.labor.june) +
                                          Number.parseFloat(rows.inventory.june) + Number.parseFloat(rows.equipment.june) +
                                          Number.parseFloat(rows.servicing.june) + Number.parseFloat(rows.administrative.june)
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          Number.parseFloat(rows.labor.july) +
                                          Number.parseFloat(rows.inventory.july) + Number.parseFloat(rows.equipment.july) +
                                          Number.parseFloat(rows.servicing.july) + Number.parseFloat(rows.administrative.july)
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          Number.parseFloat(rows.labor.august) +
                                          Number.parseFloat(rows.inventory.august) + Number.parseFloat(rows.equipment.august) +
                                          Number.parseFloat(rows.servicing.august) + Number.parseFloat(rows.administrative.august)
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          Number.parseFloat(rows.labor.september) +
                                          Number.parseFloat(rows.inventory.september) + Number.parseFloat(rows.equipment.september) +
                                          Number.parseFloat(rows.servicing.september) + Number.parseFloat(rows.administrative.september)
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          Number.parseFloat(rows.labor.october) +
                                          Number.parseFloat(rows.inventory.october) + Number.parseFloat(rows.equipment.october) +
                                          Number.parseFloat(rows.servicing.october) + Number.parseFloat(rows.administrative.october)
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          Number.parseFloat(rows.labor.november) +
                                          Number.parseFloat(rows.inventory.november) + Number.parseFloat(rows.equipment.november) +
                                          Number.parseFloat(rows.servicing.november) + Number.parseFloat(rows.administrative.november)
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          Number.parseFloat(rows.labor.december) +
                                          Number.parseFloat(rows.inventory.december) + Number.parseFloat(rows.equipment.december) +
                                          Number.parseFloat(rows.servicing.december) + Number.parseFloat(rows.administrative.december)
                                        ).toFixed(2)
                                      }}</span></td>
                                      <td><span class="bg">${{ 
                                        Number.parseFloat(
                                          Number.parseFloat(rows.labor.total) +
                                          Number.parseFloat(rows.inventory.total) + Number.parseFloat(rows.equipment.total) +
                                          Number.parseFloat(rows.servicing.total) + Number.parseFloat(rows.administrative.total)
                                        ).toFixed(2)
                                      }}</span></td>
                                  </tr>

                                
                              </tbody>

                          </table>
                      </div>
                      
                  </div>

              </div>

          </div>
      </div>
  </section><!--//End Section-->
</template>

<script>
import { defineComponent, ref, onMounted, watch, provide } from 'vue';
import { useStore } from 'vuex';
import DropDownList from '@/components/Shared/DropDownList.vue';
import yearOptions from '@/sharedData/yearsList';


export default defineComponent({
  name: 'TotalExpenses',
  setup() {
    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);
    const store = useStore();

    const rows = ref({
        labor: {
          january: '25.00',
          february: '25.00',
          march: '25.00',
          april: '25.00',
          may: '25.00',
          june: '25.00',
          july: '25.00',
          august: '25.00',
          september: '25.00',
          october: '25.00',
          november: '25.00',
          december: '25.00',
          total: '300.00'
        },
        inventory: {
          january: '25.00',
          february: '25.00',
          march: '25.00',
          april: '25.00',
          may: '25.00',
          june: '25.00',
          july: '25.00',
          august: '25.00',
          september: '25.00',
          october: '25.00',
          november: '25.00',
          december: '25.00',
          total: '300.00'
        },
        equipment: {
          january: '25.00',
          february: '25.00',
          march: '25.00',
          april: '25.00',
          may: '25.00',
          june: '25.00',
          july: '25.00',
          august: '25.00',
          september: '25.00',
          october: '25.00',
          november: '25.00',
          december: '25.00',
          total: '300.00'
        },
        servicing: {
          january: '25.00',
          february: '25.00',
          march: '25.00',
          april: '25.00',
          may: '25.00',
          june: '25.00',
          july: '25.00',
          august: '25.00',
          september: '25.00',
          october: '25.00',
          november: '25.00',
          december: '25.00',
          total: '300.00'
        },
        administrative: {
          january: '25.00',
          february: '25.00',
          march: '25.00',
          april: '25.00',
          may: '25.00',
          june: '25.00',
          july: '25.00',
          august: '25.00',
          september: '25.00',
          october: '25.00',
          november: '25.00',
          december: '25.00',
          total: '300.00'
        }
    });
    const farms = ref([...store.state.farms]);

    const currentSelection = ref({
      farm: '',
      year: ''
    });

    const toggleSection = (ev) => {
        if ((ev.target.className !== 'border-box-header') &&
            (ev.target.className !== 'angle') && (ev.target.className !== 'angle revert')) {
            return;
        }
        sectionExpanded.value = !sectionExpanded.value;
    };
    watch(sectionExpanded, (newVal) => {
        section.value.style.height = newVal ?
            `${sectionHeight.value}px` :
            `${boxHeader.value.offsetHeight}px`;
        section.value.style.overflow = newVal ? 'visible' : 'hidden';
    });
    // watch(() => ([...store.state]), (newVal) => { 
    //     rows.value = [...newVal];
    //     setTimeout(() => {
    //         section.value.style.height = 'auto';
    //         sectionHeight.value = section.value.offsetHeight;
    //     });
    // });
    watch(() => ([...store.state.farms]), (newVal) => { 
        farms.value = [...newVal];
    });
    // watch(() => ({...currentSelection}), (newVal) => {
    //     if (newVal.value.farm && newVal.value.year) {
    //         rows.value = [...store.state].filter(e => (e.year === newVal.value.year && e.farm === newVal.value.farm));
    //     }
    // });

    onMounted(() => {
        setTimeout(() => {
            sectionHeight.value = section.value.offsetHeight;
            section.value.style.height = `${section.value.offsetHeight}px`;
        });
        // rows.value = [...store.state];
        // store.dispatch('get');
        store.dispatch('getFarms');
    });

    provide('selectionDetails', {
      currentSelection,
      options: farms 
    })

    return {
        section,
        boxHeader,
        toggleSection,
        rows,
        yearOptions,
        currentSelection
    }
  },
  components: { DropDownList }
});
</script>