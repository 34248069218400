<template>

  <div ref="section" class="sub-section">
    <div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Planned Crops</h5>
<div class="info-icons">
<div class="info-icon lg tooltip-icon"
  v-if="isAuth('add-crop')"
  @click="() => selectCrop()"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
>
  <!-- <img src="@/assets/images/plus-green-md.png" alt=""> -->
  <img src="@/assets/images/updated/plan-crop.png" alt="">
  <div class="helper-tooltip" v-if="helperTooltip">Plan Crop</div>
</div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-wrapper">
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label justify-content-start">
  <span>Year</span>
  <span @click="() => sortByYear()">
    <button :class="{active: sortOrder.year === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.year === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Season</span>
  <span @click="() => sortTable('season')">
    <button :class="{active: sortOrder.season === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.season === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Farm</span>
  <span @click="() => sortTable('farmName')">
    <button :class="{active: sortOrder.farmName === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.farmName === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th v-if="rows.some(r => r.sourceType === 'bed')"><p class="form-label-static form-sort-label">
  <span>Bed</span>
  <span @click="() => sortTable('bed')">
    <button :class="{active: sortOrder.bed === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.bed === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Crop</span>
  <span @click="() => sortTable('crop')">
    <button :class="{active: sortOrder.crop === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.crop === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Dt. Seed</span>
  <span @click="() => sortTable('dateSeed')">
    <button :class="{active: sortOrder.dateSeed === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.dateSeed === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Dt. Harvest</span>
  <span @click="() => sortTable('harvestDate')">
    <button :class="{active: sortOrder.harvestDate === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.harvestDate === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Projected Amt.</span>
  <span @click="() => sortTable('projHarvestAmount')">
    <button :class="{active: sortOrder.projHarvestAmount === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.projHarvestAmount === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

<!-- <tr class="light-gray-bg">
<td>2023</td>
<td><a href="javascript:void();">Spring</a></td>
<td>ABC</td>
<td>D</td>
<td>Spinach</td>
<td>2/10/2022</td>
<td>2/10/2022</td>
<td class="w-75">
<div class="table-links">
<a href="javascript:void();"><img src="@/assets/images/edit-icon-2.svg" alt=""></a>
<a href="javascript:void();"><img src="@/assets/images/trash-2.svg" alt=""></a>
</div>
</td>
</tr> -->

  <template
    v-for="(row, rowIndex) in rows"
    v-bind:key="'crop-list-row' + row.id"
  >
    <tr 
      v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
      :class="{'light-gray-bg': row.id === crop.id}" 
      @click="(ev) => selectByRow(ev, {...row})"
    >
    <td>{{ row.year }}</td>
    <td>{{ row.season }}</td>
    <td>{{ row.farmName }}</td>
    <td v-if="rows.some(r => r.sourceType === 'bed')">{{ row.bed }}</td>
    <td>{{ row.crop }}</td>
    <td>{{ row.dateSeed }}</td>
    <td>{{ row.harvestDate }}</td>
    <td>{{ row.projHarvestAmount }}</td>
    <td class="w-75">
    <div class="table-links">
    <a v-if="isAuth('edit-crop')" href="javascript:void();" @click="selectCrop({...row}, 'edit')" ><img src="@/assets/images/edit-icon-2.svg" alt=""></a>
    <a v-if="isAuth('remove-crop')" href="javascript:void();" @click="removeSelectedCrop(row.id,rowIndex)" ><img src="@/assets/images/trash-2.svg" alt=""></a>
    </div>
    </td>
    </tr>
  </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'crops-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'cropListDetails'"
    :optionKey="'crop-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>
  </div>

  <div class="removal-popup" v-if="removedCrop">

  <div class="modal modal-md">
  <span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
  <div class="modal-block text-center">
  <p class="fw-bold">Are you sure you want to delete the Crop?</p>
  </div>
  <div class="modal-block text-center">
  <div class="buttons justify-content-center">
      <button
        @click="() => approveRemoval(true)"
        class="approve-button btn green" 
      >Yes</button>
      <button
        @click="() => approveRemoval(false)" 
        class="cancel-removal-button btn green btn-o" 
      >No</button>
  </div>
  </div>
  </div>

  </div>
  <!-- <q-expansion-item
    class="overflow-hidden rounded-borders crop-list"
    style="border-radius: 10px"
    popup
    :header-style="{
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      fontWeight: 700,
    }"
    default-opened
    label="Crop List"
    v-model="expanded"
    @before-hide="handleCollapseHeaderActions"
  >
    <template v-slot:header>
      <q-item-section>
        <span class="header">{{ sectionHeader }}</span>
      </q-item-section>
      <q-item-section side>
        <div class="add-crop" 
          v-if="isAuth('add-crop')"
          @click="() => selectCrop()"
          @mouseover="switchHelperTooltip(true)"
          @mouseout="switchHelperTooltip(false)"
        >
          <div class="helper-tooltip" v-if="helperTooltip">Plan Crop</div>
        </div>
      </q-item-section>
    </template>
    <q-card>
      <q-card-section class="crop-list-card-section">
        <q-table
          flat
          :rows="crops"
          :columns="columns"
          row-key="name">
          
          <template v-slot:header="props">
            <q-tr :props="props">
              <q-th
                v-for="col in props.cols"
                :key="col.name"
                :props="props"
                class="custom-table-header"
              >
                {{ col.label }}
              </q-th>
              <q-th auto-width />
            </q-tr>
          </template>

          <template v-slot:body="props">
            <q-tr :props="props" :style="{backgroundColor: (crop.id === props.row.id) ? 'rgba(0,0,0,0.05)' : ''}">
              <q-td
                v-for="col in props.cols"
                :key="col.name"
                :props="props"
                class="custom-table-cell"
              >
                <span 
                  v-if="col.name === 'bedNumber'"
                  @click="selectCrop({...props.row}, 'represent')"
                  style="cursor: pointer; color: #38AC89;"
                >{{ col.value }}</span>
                <span 
                  v-else-if="col.name === 'farmName'"
                  @click="selectCrop({...props.row}, 'represent')"
                  style="cursor: pointer; color: #38AC89;"
                >{{ col.value }}</span>
                <span v-else>{{ col.value }}</span>
              </q-td>
              <q-td auto-width class="list-actions">
                <button
                  style="margin-right: 5px"  
                  @click="selectCrop({...props.row}, 'edit')" 
                  class="item-actions"
                >
                  <q-icon>
                    <img :src="require('@/assets/farm_profile/edit_list_item.svg')" />
                  </q-icon>
                </button>
                <button
                  class="item-actions"
                  @click="removeSelectedCrop(props.row.id,props.rowIndex)" 
                >
                  <q-icon>
                    <img :src="require('@/assets/farm_profile/remove_list_item.svg')" />
                  </q-icon>
                </button>
              </q-td>
            </q-tr>
          </template>
        
        </q-table>
      </q-card-section>
    </q-card>
  </q-expansion-item> -->
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { computed, inject, ref, watch, onMounted, reactive, provide } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "CropList",
    setup() {
        const store = useStore();
        const sectionHeader = 'Planned Crop';
        const crops = computed(() => store.state.crops);
        const { crop, selectCrop } = inject('cropEdition');
        const { isAuth } = inject('mainUI');
        const expanded = ref(true);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const rows = ref([]);
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const sortOrder = reactive({
            year: 0,
            season: 0,
            farmName: 0,
            bed: 0,
            crop: 0,
            dateSeed: 0,
            harvestDate: 0,
            projHarvestAmount: 0
        });
        const removedCrop = ref(null);

        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...crops.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortByYear = () => {
            if (sortOrder.year === 0) {
                rows.value.sort((a, b) => Number.parseInt(a.year) - Number.parseInt(b.year));
                sortOrder.year = 1;
            }
            else if (sortOrder.year > 0) {
                rows.value.sort((a, b) => Number.parseInt(b.year) - Number.parseInt(a.year));
                sortOrder.year = -1;
            }
            else if (sortOrder.year < 0) {
                rows.value = [...crops.value];
                sortOrder.year = 0;
            }
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const removeSelectedCrop = (cropId, cropIndex) => {
          removedCrop.value = { cropId, cropIndex };
        };
        const approveRemoval = (approved) => {
          if (approved) {
            store.dispatch('removeCrop', removedCrop.value);  
          }
          removedCrop.value = null;
        };
        const helperTooltip = ref(false);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const handleCollapseHeaderActions = (evt) => {
            if (evt.target.className === 'add-crop') {
                expanded.value = true;
            }
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectCrop({ ...row }, 'represent');
            }
        };
        watch(() => ([...store.state.crops]), (newVal) => {
            console.log(newVal, 'crops');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...store.state.crops];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
          store.dispatch('getCrops');
          console.log(store.state.crops, 'store.state.crops');
            (store.state.crops.length > 0) && selectCrop({ ...store.state.crops[0] }, 'represent');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getCrops')
            rows.value = [...store.state.crops];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('cropListDetails', {
            maxPage,
            currentPage,
            options
        });
        return {
            sectionHeader,
            expanded,
            handleCollapseHeaderActions,
            crops,
            rows,
            crop,
            selectCrop,
            removeSelectedCrop,
            helperTooltip,
            switchHelperTooltip,
            isAuth,
            maxPage,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            sortByYear,
            selectByRow,
            currentPage,
            removedCrop,
            approveRemoval
        };
    },
    components: { DropDownList }
};
</script>

<style lang="scss" scoped>
.tooltip-icon {
  position: relative;
}
  .crop-list {
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
    .header {
      font-size: 18px;
      color: #28292B;
      margin-left: 8px;
    }
    .crop-list-card-section {
      padding: 16px 0 24px;
    }
    .add-crop {
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 25px;
      height: 25px;
      background-image: url('@/assets/farm_profile/add_button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      position: relative;
      .helper-tooltip {
        width: max-content;
        padding: 0 15px;
        height: 30px;
        position: absolute;
        z-index: 999;

        background-color: #3C3838;
        color: #fff;
        top: 40px;
        left: -30px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;

        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 41px;
          background: #3C3838;
          z-index: -1;
        }
      }
    }

    .custom-table-header {
      font-weight: 600;
      font-size: 14px;
      color: rgba(40, 41, 43, 0.6);
    }
    .custom-table-cell {
      font-weight: 500;
      font-size: 14px;
      color: rgba(40, 41, 43, 1);
    }
    
    .item-actions {
      border: none;
      background: none;
      cursor: pointer;
      width: 30px;
      height: 30px;
      i {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>