<template>

<section class="section">

<div class="tab-content farms-content" id="one">

<section class="section">
<div class="border-box">
<div class="border-box-content">

<div class="blog">

  <div class="blog-block">
  <h5 class="fw-semibold">Bed Design Tool</h5>

  <h6 class="fw-semibold">ICONS Definition:</h6>
  <h6 class="fw-semibold icons-definition-list">
    Draw Freehand: <span class="draw-icon"><img src="@/assets/farm_profile/pencil.svg" alt=""></span> Draw Straight Line: <span class="draw-icon"><img src="@/assets/images/straight-line-draw.png" alt=""></span> Eraser: <span class="draw-icon"><img src="@/assets/farm_profile/eraser.svg" alt=""></span> Thickness Adjust: <span class="draw-icon"><img src="@/assets/images/adjust_info_icon.png" alt="" style="margin-top: 30%;"></span>
  </h6>
  <h6 class="fw-semibold icons-definition-list-2">
    Add Text: <span class="draw-icon"><img src="@/assets/farm_profile/text.svg" alt=""></span> Save: <span class="draw-icon"><img src="@/assets/images/save_design.svg" alt=""></span> Edit Design: <span class="draw-icon"><img src="@/assets/images/edit_design.svg" alt=""></span> Delete Design: <span class="draw-icon"><img src="@/assets/images/delete_design.svg" alt="" style="width: 100%;"></span> Cancel: <span class="draw-icon"><img src="@/assets/images/cancel_design.svg" alt=""></span>   
  </h6>
  </div>

  <div class="blog-block">
  <h6 class="fw-semibold">Bed Design Tool Bar:</h6>
  <figure><img src="@/assets/images/bed-design-tool-bar.png" alt=""></figure>
  </div>

  <div class="blog-block">
  <h6 class="fw-semibold">Bed Design Process:</h6>
  <p class="fw-medium draw-info-text" style="font-size: 16px;">
    1. Click on the Pencil or the Line icon to draw line and create a bed structure. You can adjust the thickness of the lines by moving the stroke width to the right.
    <br />
    2. Use the eraser icon to erase any errors while drawing the lines. Click on the eraser icon and then drag it on to the section of the line you want to erase by holding the mouse down the selecting the section. You can adjust the thickness of the eraser by moving the eraser radius to the right. 
    <br />
    3. Use the text icon to add text on the bed sections. Click on the text icon and bring your mouse icon to the section where you want to add the text. Click on the section and a text box will appear. Add the text and click on the + icon to add the text. Click on x icon to delete the text. You can enter multiple lines of text as show below by clicking the enter menu on your device.
    <br />
    4. Once done click on the Save icon on the top right section of the design board. Other icons allow you to execute edit, delete and cancel functions.
    <br />
    5. Delete icon will delete the entire design. Once deleted, if there are any bed details associated with it, it will prompt you to delete the bed details as well. 
    <br />
    6. Edit icon will make the design and the text editable. Once in edit mode you can update the design/text and click on save to save the design. 
    <br />
    7. If you want to cancel any function, click on the cancel icon. No changes will be applied to the design.
  </p>
  <h6 class="fw-semibold">Other Tips:</h6>
  <p class="fw-medium draw-info-text" style="font-size: 16px;">
    1. Each icon has a tooltip. If you hover over it, a tooltip will appear that will tell you what that icon does.
  <br />
    2. When in active mode the icon will appear in darker color.
  </p>
  </div>

  <div class="blog-block">
  <h6 class="fw-semibold">Sample Bed Design:</h6>
  <figure><img src="@/assets/images/sample-bed-design.png" alt=""></figure>
  </div>

</div>
</div>
</div>
</section>
</div>
</section>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FarmDrawInfo',
  setup() {

  } 
})
</script>

<style lang="scss">
span.draw-icon {
  max-width: 2.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.icons-definition-list {
  // margin-bottom: 0 !important;
  display: flex;
  // justify-content: space-between;
  width: max-content;
  font-size: 15px;
  align-items: center;
}
.icons-definition-list-2 {
  display: flex;
  // justify-content: space-between;
  width: max-content;
  font-size: 15px;
  align-items: center;
}
.draw-info-text {
  font-size: 16px;
}
</style>