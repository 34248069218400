<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Employee Compensation <template v-if="compensation.employeeType === 'parttime'"> - Part Time</template><template v-if="compensation.employeeType === 'salary'"> - Salary</template></h5>
<div class="icon-group">
<!-- <div class="icon green icon-o camera-plus tooltip"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
  data-tooltip="Take a shot"
>
  <img src="@/assets/images/camera-plus.svg" alt="">
  <div class="helper-tooltip" v-if="helperTooltip">Take a shot</div>
</div> -->
<div class="icon green icon-o tooltip compensation-section"
  @click="selectCompensation(compensation, 'edit');"
  @mouseover="switchEditHelperTooltip(true)"
  @mouseout="switchEditHelperTooltip(false)"
  data-tooltip="Edit Compensation"
>
  <!-- <img src="@/assets/images/edit.svg" alt=""> -->
  <img src="@/assets/images/updated/Edit.png" alt="">
  <!-- <div class="edit-helper-tooltip" v-if="editHelperTooltip">Edit Compensation</div> -->
</div>
</div>
</div>

<div class="content-block">
<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Employee Title</p>
<h6>{{compensation.employeeTitle}}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Grade</p>
<h6>{{compensation.grade}}</h6>
</div>

<div class="intro-grid-column" v-if="compensation.annualSalary">
<p class="title">Annual Salary</p>
<h6>${{compensation.annualSalary}}</h6>
</div>

<div class="intro-grid-column" v-if="compensation.medicalBenefits">
<p class="title">Medical Benefits</p>
<h6>{{compensation.medicalBenefits}}</h6>
</div>

<div class="intro-grid-column" v-if="compensation.bonus">
<p class="title">Bonus</p>
<h6>${{compensation.bonus}}</h6>
</div>

<div class="intro-grid-column" v-if="compensation.other">
<p class="title">Other</p>
<h6>{{compensation.other}}</h6>
</div>

<div class="intro-grid-column" v-if="compensation.hourlyRate">
<p class="title">Hourly Rate</p>
<h6>${{compensation.hourlyRate}}</h6>
</div>

<div class="intro-grid-column" v-if="compensation.overTimeRate">
<p class="title">Over Time Rate</p>
<h6>${{compensation.overTimeRate}}</h6>
</div>

<div class="intro-grid-column" v-if="compensation.ownershipType">
<p class="title">Ownership Type</p>
<h6>{{compensation.ownershipType}}</h6>
</div>

<div class="intro-grid-column" v-if="compensation.ownershipShare">
<p class="title">Ownership %</p>
<h6>{{compensation.ownershipShare}}</h6>
</div>

<div class="intro-grid-column intro-grid-comment-column" v-if="compensation.qualificationExperienceRequirements">
<p class="title">Qualification and Experience Requirements</p>
<h6>{{ compensation.qualificationExperienceRequirements }}</h6>
</div>


<div class="intro-grid-column intro-grid-comment-column">
<p class="title">Comments</p>
<h6>{{ compensation.comments }}</h6>
</div>

</div>
</div>

<div class="content-block">

  <FileAttachments :key="`filled-${compensation.id}`" v-bind:filled="true" v-bind:section="'employeeCompensation'" v-bind:instance="`${compensation.id}`" />

<!-- <div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o">Cancel</button></div>
</div>
</div>
</div> -->

</div>

</div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { defineComponent, inject, ref } from 'vue';

export default defineComponent({
    name: "EmployeeCompensationFilled",
    components: { FileAttachments },
    setup() {
      const { compensation, selectCompensation } = inject('compensationDetails');

      const editHelperTooltip = ref(false);
      const helperTooltip = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const switchEditHelperTooltip = (toggle) => {
        editHelperTooltip.value = toggle;
      }

      return {
        compensation,
        selectCompensation,
        editHelperTooltip,
        helperTooltip,
        switchHelperTooltip,
        switchEditHelperTooltip
      }
    }
})
</script>

<style lang="scss" scoped>
.edit-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 40px;
  left: -30px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 41px;
    background: #3C3838;
    z-index: -1;
  }
}

.schedule-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 40px;
  left: -30px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 41px;
    background: #3C3838;
    z-index: -1;
  }
}
</style>