<template>
  <LaborHeader />
  <section class="section">
    <div class="border-box">
      <LaborDetails v-if="(labors.length > 0) && editMode === 'represent'" />
      <LaborList />
    </div>
  </section>
</template>

<script>
import { defineComponent, onMounted, provide, reactive, computed, ref } from 'vue';
import LaborHeader from './LaborHeader.vue';
import LaborDetails from './LaborDetails.vue';
import LaborList from './LaborList.vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: "LaborModule",
    components: { LaborHeader, LaborDetails, LaborList },
    setup() {
      const store = useStore();

      const mainDetails = reactive({
        id: '',
        email: '',
        password: '',
        accountName: '',

        title: '',
        
        // email: '',
        role: 'admin',
        multifactor: false,
        multifactorType: '',
        userRole: '',

        firstName: '',
        lastName: '',
        address: '',
        address2: '',
        mobilePhone: '',
        workPhone: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        employeeType: ''
      })

      const userRole = reactive({
        roles: ['admin', 'edit', 'contribute', 'read'],
        selected: 'admin',
      })

      const labors = computed(() => store.state.labors);

      const editMode = ref('')

      const selectMainDetails = (mainDetailsData, editModeVal) => {
        if (mainDetailsData) {
          for (let field in mainDetailsData) {
            mainDetails[field] = mainDetailsData[field]
            if (!mainDetailsData.id) {
              mainDetails.id = 'primary';
            }
          }
        }
        if (editModeVal) {
          editMode.value = editModeVal;
        }
      }

      provide('mainDetails', {
        mainDetails,
        userRole,
        selectMainDetails
      })

      onMounted(() => {
        if (store.state.labors[0]?.mainDetails) { 
          selectMainDetails({...store.state.labors[0]?.mainDetails}, 'repesent')
          console.log(store.state.labors[0]);
        }
      })

      return {
        labors,
        editMode
      }
    }
})
</script>