<template>
<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Account Information</h5>
<div class="icon-group">

  <!-- <div class="icon green icon-o" 
    v-if="isAuth('add-user')"
    @click="createNewUser"
    @mouseover="switchAddHelperTooltip(true)"
    @mouseout="switchAddHelperTooltip(false)"
  >
    <img src="@/assets/images/user-plus.svg" alt="">
    <div class="add-helper-tooltip" v-if="addHelperTooltip">Add User</div>
  </div> -->

  <div class="icon green icon-o" 
    v-if="isAuth('edit-details')"
    @click="changeMainField('edit', 'mode')"
    @mouseover="switchEditHelperTooltip(true)"
    @mouseout="switchEditHelperTooltip(false)"
  >
    <img src="@/assets/images/edit.svg" alt="">
    <div class="edit-helper-tooltip" v-if="editHelperTooltip">Edit</div>
  </div>

</div>
</div>

<div class="content-block">
<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Company</p>
<h6>{{ mainDetails.company }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Industry</p>
<h6>{{ mainDetails.industry }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Company Size</p>
<h6>{{ mainDetails.companySize }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">First Name</p>
<h6>{{ mainDetails.firstName }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Last Name</p>
<h6>{{ mainDetails.lastName }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Title</p>
<h6>{{ mainDetails.title }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Work Phone</p>
<h6>{{ mainDetails.workPhone }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Mobile Phone</p>
<h6>{{ mainDetails.mobilePhone }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Address 1</p>
<h6>{{ mainDetails.address }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Address 2</p>
<h6>{{ mainDetails.address2 }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">City</p>
<h6>{{ mainDetails.city }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">State</p>
<h6>{{ mainDetails.state }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Zip</p>
<h6>{{ mainDetails.zip }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Country</p>
<h6>{{ mainDetails.country }}</h6>
</div>

</div>
</div>

<div class="content-block">
<div class="user-role">
<div class="row">
<div class="col-auto">
<p class="title">User Role:</p>
</div>
<div class="col">
<div class="row">
  <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="admin" id="admin" v-model="mainDetails.userRole" disabled><label for="admin">Admin</label></div></div>
  <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="edit" id="editonly" v-model="mainDetails.userRole" disabled><label for="editonly">Edit Only</label></div></div>
  <!-- <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="contribute" id="contributeonly" v-model="mainDetails.userRole" disabled><label for="contributeonly">Contribute Only</label></div></div> -->
  <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="read" id="readonly" v-model="mainDetails.userRole" disabled><label for="readonly">Read only</label></div></div>
</div>
</div>
</div>
</div>
</div>

<FileAttachments :section="'advertisement'" v-bind:filled="true" />

</div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'AdvertisementAccountMainDetailsFilled',
    setup() {
      const store = useStore();

      const { mainDetails, sameAddress, changeMainField } = inject('mainDetails');
      // const { userRole } = inject('userRole');
      const { isAuth } = inject('mainUI');

      const editHelperTooltip = ref(false),
        addHelperTooltip = ref(false);

      const switchEditHelperTooltip = (toggle) => {
        editHelperTooltip.value = toggle;
      }

      const switchAddHelperTooltip = (toggle) => {
        addHelperTooltip.value = toggle;
      }

      onMounted(() => {

        if (
          store.state.advertisementAccount?.mainDetails?.submitted ||
          store.state.advertisementAccount?.mainDetails?.firstName
        ) {
          for (let prop in mainDetails) { 
            changeMainField(store.state.advertisementAccount?.mainDetails[prop], prop);
            changeMainField('represent', 'mode');
            changeMainField(true, 'submitted');
          }
        }
      })

      return {
        mainDetails,
        sameAddress,
        changeMainField,
        isAuth,

        editHelperTooltip,
        addHelperTooltip,
        switchEditHelperTooltip,
        switchAddHelperTooltip
      };
    },
    components: { FileAttachments }
})
</script>

<style lang="scss" scoped>
.edit-helper-tooltip {
    width: max-content;
    padding: 0 15px;
    height: 30px;
    position: absolute;
    z-index: 999;

    background-color: #3C3838;
    color: #fff;
    top: 50px;
    left: -15px;
    text-indent: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: -7px;
      border-color: #3C3838;
      border-left: 8px solid hsl(0, 3%, 23%);
      border-bottom: 6px solid #3C3838;
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 27px;
      background: #3C3838;
      z-index: -1;
    }
  }
</style>