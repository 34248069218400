<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">{{mainDetails.firstName}} {{ mainDetails.lastName }}</h5>
<div class="icon-group">
<div class="icon green icon-o top-icon tooltip"
  @click="createNewUser"
  @mouseover="switchAddHelperTooltip(true)"
  @mouseout="switchAddHelperTooltip(false)"
  data-tooltip="Add User"
>
  <!-- <img src="@/assets/images/user-plus.svg" alt=""> -->
  <img src="@/assets/images/updated/064-add-user.png" alt="">
  <!-- <div class="add-helper-tooltip" v-if="addHelperTooltip">Add User</div> -->
</div>
<div class="icon green icon-o top-icon tooltip" 
  @click="selectMainDetails({...mainDetails},'edit')"
  @mouseover="switchEditHelperTooltip(true)"
  @mouseout="switchEditHelperTooltip(false)"
  data-tooltip="Edit"
>
  <!-- <img src="@/assets/images/edit.svg" alt=""> -->
  <img src="@/assets/images/updated/Edit.png" alt="">
  <!-- <div class="edit-helper-tooltip" v-if="editHelperTooltip">Edit</div> -->
</div>
</div>
</div>

<div class="content-block">
<div class="admin-update-form">
<div class="row justify-content-end">

<div class="col-auto">
<div class="form-item">
<p class="form-label-static">Email</p>
<input 
  type="text" 
  class="form-control" 
  v-model="mainDetails.email"
  @focus="() => {
    activated.email = true;
    focused.email = true;
  }"
  @blur="() => {
    focused.email = false;
  }"  
  :disabled="!updateIsActive"
/>
<p v-if="activated.email && mainDetails.email.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.email && ((mainDetails.email.length > 0) && !/\S+@\S+\.\S+/.test(mainDetails.email))" class="form-info red text-end">Please use correct email format</p>
</div>
</div>

<div class="col-auto">
<div class="form-item password-field">
<p class="form-label-static">Password</p>
<input 
  :type="passwordIsVisible ? 'text' : 'password'"
  class="form-control" 
  v-model="mainDetails.password"
  @focus="() => {
    activated.password = true;
    focused.password = true;
  }"
  @blur="() => {
    focused.password = false;
  }"
  :disabled="!updateIsActive"
  :placeholder="passwordMask"
/>
<div v-if="updateIsActive" class="password-icon" :class="{visible: !passwordIsVisible, hidden: passwordIsVisible}" @click="() => {passwordIsVisible = !passwordIsVisible}"></div>
<p v-if="activated.password && mainDetails.password.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.password && mainDetails.password.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-auto update-btn-sm-wrap">
  <button v-if="updateIsActive" type="submit" class="btn green update-btn-sm" @click="() => switchUpdatable(false)">Save</button>
  <button v-else type="submit" class="btn green update-btn-sm" @click="() => switchUpdatable(true)">Update</button>
</div>

</div>
</div>
</div>

<div class="content-block toggle-switch-wrap justify-content-end mr-170">

<div class="toggle-switch">
<label class="switch"><input type="checkbox" v-model="mainDetails.multifactor" disabled><span class="slider round"></span></label>
<p class="fw-semibold gray">Multi Factor Authentication</p>
</div>

<div class="checkbox-group">
<div class="radio radio-mobile"><input type="radio" name="mobileEmail" id="mobile" value="mobile" v-model="mainDetails.multifactorType" disabled><label for="mobile">Mobile</label></div>
<div class="radio"><input type="radio" name="mobileEmail" id="email" value="email" v-model="mainDetails.multifactorType" disabled><label for="email">Email</label></div>
</div>

</div>

<div class="content-block">
<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Account Name</p>
<h6>{{ mainDetails.accountName }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Title</p>
<h6>{{ mainDetails.title }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">First Name</p>
<h6>{{ mainDetails.firstName }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Last Name</p>
<h6>{{ mainDetails.lastName }}</h6>
</div>
 
<div class="intro-grid-column">
<p class="title">Mobile Phone</p>
<h6>{{ mainDetails.mobilePhone }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Work Phone</p>
<h6>{{ mainDetails.workPhone }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Address 1</p>
<h6>{{ mainDetails.address }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Address 2</p>
<h6>{{ mainDetails.address2 }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">City</p>
<h6>{{ mainDetails.city }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">State</p>
<h6>{{ mainDetails.state }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Zip</p>
<h6>{{ mainDetails.zip }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Country</p>
<h6>{{ mainDetails.country }}</h6>
</div>

</div>
</div>

<div class="content-block">
<h6 class="sub-headding">User Profile</h6>
<div class="user-role">
<div class="row">
<div class="col-auto">
<p class="title fw-semibold gray">Role Type</p>
</div>
<div class="col">
<div class="row">
  <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="admin" id="admin" v-model="mainDetails.userRole" disabled><label for="admin">Admin</label></div></div>
  <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="edit" id="editonly" v-model="mainDetails.userRole" disabled><label for="editonly">Edit</label></div></div>
  <!-- <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="contribute" id="contributeonly" v-model="mainDetails.userRole" disabled><label for="contributeonly">Contribute</label></div></div> -->
  <div class="col-auto"><div class="radio"><input name="userrole" type="radio" value="read" id="readonly" v-model="mainDetails.userRole" disabled><label for="readonly">Read</label></div></div>
</div>
</div>
</div>
</div>
</div>

<div class="content-block d-lg-flex justify-content-between">
  <div class="checkbox-group task-type">
    <div v-if="employeeHelper" class="employee-tooltip">
      Allows for calculating wages!
    </div>
    <div class="row">
      <div class="col-auto" style="position: relative;">
        <p 
          class="title fw-semibold gray checkbox-title"
          @mouseover="switchEmployeeHelper(true)"
          @mouseout="switchEmployeeHelper(false)"  
          style="position: absolute; right: 1rem;"
        >User Type</p>
        <p class="title fw-semibold gray checkbox-title" style="opacity: 0; pointer-events: none;">Role Type</p>
      </div>
      <div class="col-auto">
        <div class="radio"><input type="radio" name="taskType" id="regular" value="salary"  v-model="mainDetails.employeeType" disabled><label for="regular">Salary</label></div>
      </div>
      <div class="col-auto">
        <div class="radio"><input type="radio" name="taskType" id="overtime" value="parttime" v-model="mainDetails.employeeType" disabled><label for="overtime">Part-time</label></div>
      </div>
      <div class="col-auto">
        <div class="radio"><input type="radio" name="taskType" id="other" value="other" v-model="mainDetails.employeeType" disabled><label for="other">Other</label></div>
      </div>
    </div>
  </div>
  </div>

  <div class="content-block" v-if="mainDetails.employeeType !== 'other'">
  <div class="user-grade">
  <div class="row">
  <div class="col-auto" style="position: relative;">
    <p class="title fw-semibold gray checkbox-title" style="opacity: 0; pointer-events: none;">Role Type</p>
    <p class="title fw-semibold gray checkbox-title"
      style="position: absolute; right: 1rem;top: 0; bottom: 0; display: flex; align-items: center;"
    >Grade</p>
  </div>
  <div class="col-auto">
    <input type="text" class="form-control sm" v-model="mainDetails.grade" disabled />
    <!-- <h6 class="fw-semibold" style="padding-left: 0.5rem;">{{ mainDetails.grade }}</h6> -->
  </div>
  </div>
  </div>
  </div>

<!-- <FileAttachments v-bind:section="'adminUsers'" /> -->
<FileAttachments :key="`filled-${mainDetails.id}`" v-bind:filled="true" v-bind:section="(mainDetails.id && (mainDetails.id === 'primary')) ? 'primary' : 'secondary'" v-bind:instance="`${(mainDetails.id && (mainDetails.id !== 'primary')) ? mainDetails.id : ''}`" />

</div>

<div v-if="notUpdated.active">
  <UserNotification
    v-bind:messageText="notUpdated.message"
  />
</div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { inject, ref, reactive, watch, onMounted } from 'vue';
// import { useRouter } from 'vue-router';
import UserNotification from '@/components/Shared/UserNotification.vue';
import { useStore } from 'vuex';

export default {
    name: "AdminUsersMainFilled",
    components: { FileAttachments, UserNotification },
    setup() {
      const store = useStore();
      // const router = useRouter();

      const { mainDetails, selectMainDetails, createNewUser } = inject('mainDetails');

      const editHelperTooltip = ref(false);
      const addHelperTooltip = ref(false);

      const activated = reactive({
        email: false,
        password: false
      });

      const focused = reactive({
        email: false,
        password: false
      });

      const notUpdated = reactive({
        active: false,
        message: 'Please save, before proceeding'
      })

      const updateIsActive = ref(false);

      const passwordIsVisible = ref(false);

      const passwordMask = ref('');

      const switchUpdatable = (isUpdatable) => {
        if (isUpdatable) {
          updateIsActive.value = true;
        } else {
          if (mainDetails.email && mainDetails.password) {
            updateIsActive.value = false;

            store.dispatch("saveNewUser", {
              section: "mainDetails",
              data: {
                ...mainDetails,
              }
            });
            
          } else {
            if (!mainDetails.email) activated.email = true;
            if (!mainDetails.password) activated.password = true;

            setTimeout(() => {
              const firstError = document.getElementsByClassName('form-info red text-end')[0];
            
              const viewPortOffset = firstError.getBoundingClientRect();

              console.log(viewPortOffset.top, Math.abs(viewPortOffset.top + 72));

              document.getElementsByTagName('main')[0].scroll({
                top: firstError ? Math.abs(viewPortOffset.top + 72) : 0,
                left: 0,
                behavior: 'smooth'
              })
            }, 100);

            notUpdated.active = true;

            setTimeout(() => {
              notUpdated.active = false;
            }, 5000)
          }
        }
      }

      const switchEditHelperTooltip = (toggle) => {
          editHelperTooltip.value = toggle;
      };
      const switchAddHelperTooltip = (toggle) => {
          addHelperTooltip.value = toggle;
      };
      // const createNewUser = () => {
      //   router.push({ name: 'labor', params: { pagename: 'secondary-user' } })
      // }

      watch(() => ({...store.state.authenticated}), (newVal) => {
        if (newVal.email && (updateIsActive.value === false)) {
          mainDetails.password = (mainDetails.password && (mainDetails.password !== '********')) ? mainDetails.password : '********';
        }
      });

      watch(updateIsActive, (newVal) => {
        if (newVal) {
          passwordMask.value = '';
        } else {
          if (store.state.authenticated.email) {
            passwordMask.value = '********';
          }
        }
      });

      onMounted(() => {
        if (store.state.authenticated.email && (updateIsActive.value === false)) {
          passwordMask.value = '********';
        }
      });

      return {
        mainDetails,
        selectMainDetails,
        switchAddHelperTooltip,
        addHelperTooltip,
        switchEditHelperTooltip,
        editHelperTooltip,
        createNewUser,
        activated,
        focused,
        updateIsActive,
        switchUpdatable,
        notUpdated,
        passwordIsVisible,
        passwordMask
      }
    }
}
</script>

<style lang="scss" scoped>
.admin-update-form .form-label-static:not(:last-child) {
  margin-bottom: 0;
  margin-right: 1.5rem;
}
  .top-icon {
    position: relative;
  }
  .edit-helper-tooltip {
    width: max-content;
    padding: 0 15px;
    height: 30px;
    position: absolute;
    z-index: 999;

    background-color: #3C3838;
    color: #fff;
    top: 50px;
    left: -15px;
    text-indent: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      top: -7px;
      border-color: #3C3838;
      border-left: 8px solid hsl(0, 3%, 23%);
      border-bottom: 6px solid #3C3838;
      border-right: 6px solid transparent;
      border-top: 8px solid transparent;
      -webkit-transform: translateX(-50%) rotate(135deg);
      transform: translateX(-50%) rotate(135deg);
      box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
      left: 27px;
      background: #3C3838;
      z-index: -1;
    }
  }
  .title {
    &.fw-semibold {
      font-family: var(--fw-semibold);
    }
  }

  .radio {
    label {
      color: hsl(var(--gray));
      font-size: var(--fs-lg);
      font-family: var(--fw-medium);
    }
  }

.checkbox-group *:not(:last-child) {
  margin: auto;
  &.radio-mobile {
    margin-right: 5rem;
  }
}

// .checkbox-title {
//   margin-left: 6rem !important;
// }

.checkbox-group {
  &.task-type {
    position: relative;
  }
}

.employee-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 30px;
  left: -15px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 90px;
    background: #3C3838;
    z-index: -1;
  }
}

.password-field {
  position: relative;
  .password-icon {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 8;
    border-radius: 1rem;
    background-color: #fff;
    &.visible {
      background:url('@/assets/images/updated/visiblepassword.png') center center no-repeat;
      background-size: contain;
    }
    &.hidden {
      background:url('@/assets/images/updated/hidepassword.png') center center no-repeat;
      background-size: contain;
    }
  }
}

</style>