<template>
  <div class="submit-popup-wrapper">
    <div class="popup-message">
      <div class="close-button" @click="() => close()" ></div>
      <div v-if="status === 'loading'" class="loader"></div>
      <div v-else class="popup-text">{{ messageText }}</div>
      <!-- <div v-if="!hideDetails" class="popup-details">
        <br />
        <span class="contact-row">Customer Support</span>
        <br />
        Email: xxx@xxx.com
        <br />
        Phone: xxx-xxx-xxxx
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "UserNotification",
  props: {
    messageText: String,
    status: String,
    hideDetails: Boolean,
    holdInterval: Number
  },
  methods: {
    close () {
      // this.$destroy();
      if (this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }
    }
  },
  mounted() {
    setTimeout(() => this.close(), this.holdInterval || 5000)
  }
};
</script>

<style lang="scss" scoped>
.submit-popup-wrapper {
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;

  .popup-message {
    background: #fff;
    display: flex;
    flex-direction: column;
    max-width: 650px;
    // width: 100%;
    width: max-content;
    height: max-content;
    justify-content: space-around;
    align-items: center;
    position: relative;
    // border-radius: 11px;
    color: rgba(40,41,43,1);
    font-size: 21px;
    margin: 0 20px;
    padding: 25px;
    text-align: center;
    .close-button {
      position: absolute;
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 30px;
      height: 30px;
      background-image: url('@/assets/profile/close_button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      right: 10px;
      top: 22px;
      cursor: pointer;
    }
    .popup-text {
      margin: 0 40px;
      font-size: 16px;
      font-weight: 600;
    }
    .popup-details {
      font-size: 16px;
      .contact-row {
        color: #38AC89;
      }
    }
    .popup-buttons {
      display: flex;
      width: 60%;
      justify-content: space-evenly;
      margin-top: 30px;
      button {
        width: 160px;
        height: 40px;
        border-radius: 10px;
      }
    }
  }

  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
  }
  .loader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #38ad8a;
    animation: prixClipFix 2s linear infinite ;
  }

  @keyframes rotate {
    100%   {transform: rotate(360deg)}
  }

  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }
}
</style>
