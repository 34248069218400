<template>
  <section class="section" ref="section">
<div class="border-box active">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Products & Services</h5>
<div class="info-icons">
<div class="info-icon lg top-icon tooltip"
  v-if="!isArchived && isAuth('add-new-vendor-item')"
  @click="addVendorItem"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
  data-tooltip="Add Product or Service"
>
  <img src="@/assets/images/updated/057-gardening-tool-2.png" alt="">
  <!-- <div class="helper-tooltip" v-if="helperTooltip">Add Item</div> -->
</div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-wrapper">
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label"><span>Item</span>
  <span @click="() => sortTable('item')">
    <button :class="{active: sortOrder.item === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.item === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Date Purchased</span>
  <span  @click="() => sortTable('date')">
  <button :class="{active: sortOrder.date === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.date === 1}"><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label-static form-sort-label">
  <span># of Items</span>
  <span  @click="() => sortTableByNumber('numberOfItems')">
  <button :class="{active: sortOrder.numberOfItems === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.numberOfItems === 1}"><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Invoice #</span>
  <span  @click="() => sortTableByNumber('invoice')">
  <button :class="{active: sortOrder.invoice === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.invoice === 1}"><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Amount</span>
  <span  @click="() => sortTableByNumber('amountPaid')">
  <button :class="{active: sortOrder.amountPaid === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.amountPaid === 1}"><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Warranty Exp</span>
  <span  @click="() => sortTable('warrantyExp')">
  <button :class="{active: sortOrder.warrantyExp === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.warrantyExp === 1}"><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

  <template
    v-for="(row, rowIndex) in rows"
    v-bind:key="'vendor-list-row' + row.id"
  >
    <tr
      v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
      :class="{'light-gray-bg': row.id === vendorItem.id}"
      @click="(ev) => selectByRow(ev, {...row})"
    >
    <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
    <td  class="text-center">{{ row.item || row.serviceType }}</td>
    <td>{{ row.date ? moment(row.date, 'YYYY-MM-DD').format('MM/DD/YYYY') : '' }}</td>
    <td>{{ row.numberOfItems }}</td>
    <td>{{ row.invoice }}</td>
    <td>$ {{ row.amountPaid }}</td>
    <td>{{ row.warrantyExp ? moment(row.warrantyExp, 'YYYY-MM-DD').format('MM/DD/YYYY') : '' }}</td>
    <td class="w-75">
    <div class="table-links">
    <a v-if="!isArchived && isAuth('edit-vendor-item')" href="javascript:void();" @click="selectVendorItem({...row}, 'edit')">
      <img src="@/assets/images/edit-icon-2.svg" alt="">
    </a>
    <a v-if="!isArchived && isAuth('remove-vendor-item')" href="javascript:void();" @click="removeSelectedVendorItem(row.id)">
      <img src="@/assets/images/trash-2.svg" alt="">
    </a>
    </div>
    </td>
    </tr>
  </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'vendorItemListDetails'"
    :optionKey="'vendor-item-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>

</div>
</section><!--//End Section-->

<div class="removal-popup" v-if="removedVendorItemId">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">Are you sure you want to delete the vendor item?</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveRemoval(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import moment from 'moment';
import { ref, onMounted, watch, computed, inject, reactive, provide } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "VendorProductsServicesList",
    props: ['archived'],
    setup(props) {
        const { isAuth } = inject('mainUI');
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const isArchived = ref(props.archived);
        const vendorItems = computed(() => [...store.state.vendorItems].filter(stateVendorItem => stateVendorItem.vendor === vendor.id));
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const { selectedVendor, vendor, vendorItem, selectVendorItem, defaultVendorItem, removeVendorItem, vendorFormSubmitted } = inject('vendorDetails');
        const sortOrder = reactive({
            item: 0,
            manufacturer: 0,
            brand: 0,
            variety: 0,
            serviceType: 0
        });
        const helperTooltip = ref(false);
        const removedVendorItemId = ref(null);
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...vendorItems.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByNumber = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...vendorItems.value];
                sortOrder[sortProp] = 0;
            }
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const addVendorItem = () => {
          vendorFormSubmitted.value = true;
            selectVendorItem({
                ...defaultVendorItem,
                vendor: vendor.id
            }, 'create');
        };
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const approveRemoval = (isApproved) => {
            if (isApproved) {
                removeVendorItem(removedVendorItemId.value);
            }
            removedVendorItemId.value = '';
        };
        const removeSelectedVendorItem = (vendorItemId) => {
            removedVendorItemId.value = vendorItemId;
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectVendorItem({ ...row }, 'represent');
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(() => ([...store.state.vendorItems]), () => {
          console.log(vendorItems.value, 'vendorItems');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...vendorItems.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
            // rows.value = [...newVal].filter(stateVendorItem => stateVendorItem.vendor === vendor.id);
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
          store.dispatch('getVendorItems');
            (vendorItems.value.length > 0) && selectVendorItem({ ...vendorItems.value[0] }, 'represent');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getVendors')
            rows.value = [...vendorItems.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('vendorItemListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            addVendorItem,
            selectVendorItem,
            // removeVendorItem,
            rows,
            maxPage,
            currentPage,
            selectedVendor,
            helperTooltip,
            switchHelperTooltip,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            vendorItem,
            sortTableByNumber,
            moment,
            removedVendorItemId,
            approveRemoval,
            removeSelectedVendorItem,
            selectByRow,
            isArchived,
            isAuth
        };
    },
    components: { DropDownList }
}
</script>

<style lang="scss" scoped>
.top-icon {
  position: relative;
}
</style>