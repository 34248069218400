<template>
  <section class="section">
<div class="border-box">
<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">{{ vendor.vendor }}</h5>
<div class="icon-group">
<!-- <div class="icon green icon-o tooltip"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
  data-tooltip="Camera"
>
  <img src="@/assets/images/updated/camera-white.png" alt="">
</div> -->
<div class="icon green icon-o tooltip"
  @click="addVendorItem"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
  data-tooltip="Add Product or Service"
  v-if="isAuth('add-new-vendor-item')"
>
  <img src="@/assets/images/updated/057-gardening-tool-2.png" alt="">
</div>
<div class="icon green icon-o tooltip"
  @click="selectVendor(vendor, 'edit');"
  @mouseover="switchEditHelperTooltip(true)"
  @mouseout="switchEditHelperTooltip(false)"
  data-tooltip="Edit Vendor"
  v-if="isAuth('edit-vendor')"
>
  <img src="@/assets/images/updated/Edit.png" alt="">
  <!-- <div class="edit-helper-tooltip" v-if="editHelperTooltip">Edit Vendor</div> -->
</div>
</div>
</div>

<!-- <div class="content-block">
<div class="checkbox-group">
<p class="fw-semibold gray">Select Categories:</p>
<div class="checkbox"><input type="checkbox" name="productServices" id="product" v-model="productSelected" disabled><label for="product">Product</label></div>
<div class="checkbox"><input type="checkbox" name="productServices" id="services" v-model="servicesSelected" disabled><label for="services">Services</label></div>
</div>
</div> -->

<div class="content-block">
<div class="intro-grid">

<div class="intro-grid-column product-services-field">
<p class="title">Vendor</p>
<h6>{{ vendor.vendor }}</h6>
</div>

<div class="intro-grid-column product-services-field">
<p class="title">Vendor Contact</p>
<h6>{{ vendor.vendorContact }}</h6>
</div>

<!-- <div class="intro-grid-column" v-if="servicesSelected">
<p class="title">Service Type</p>
<h6>{{ vendor.serviceType }}</h6>
</div>

<div class="intro-grid-column" v-if="servicesSelected">
<p class="title">Vendor Phone</p>
<h6>{{ vendor.cellPhone }}</h6>
</div>
 
<div class="intro-grid-column" v-if="productSelected">
<p class="title">Product</p>
<h6>{{ vendor.product }}</h6>
</div>

<div class="intro-grid-column" v-if="productSelected">
<p class="title">Brand</p>
<h6>{{ vendor.brand }}</h6>
</div>

<div class="intro-grid-column" v-if="productSelected">
<p class="title">Variety</p>
<h6>{{ vendor.variety }}</h6>
</div> -->

<div class="intro-grid-column product-services-field">
<p class="title">Vendor Phone</p>
<h6>{{ vendor.vendorPhone }}</h6>
</div>

<!-- <div class="intro-grid-column" v-if="productSelected">
<p class="title">Mobile</p>
<h6>{{ vendor.mobile }}</h6>
</div> -->

<div class="intro-grid-column product-services-field">
<p class="title">Email</p>
<h6>{{ vendor.email }}</h6>
</div>

<div class="intro-grid-column product-services-field">
<p class="title">Address 1</p>
<h6>{{ vendor.address }}</h6>
</div>

<div class="intro-grid-column product-services-field">
<p class="title">Address 2</p>
<h6>{{ vendor.address2 }}</h6>
</div>

<div class="intro-grid-column product-services-field">
<p class="title">City</p>
<h6>{{ vendor.city }}</h6>
</div>

<div class="intro-grid-column product-services-field">
<p class="title">State</p>
<h6>{{ vendor.state }}</h6>
</div>

<div class="intro-grid-column product-services-field">
<p class="title">Zip</p>
<h6>{{ vendor.zip }}</h6>
</div>

<div class="intro-grid-column product-services-field">
<p class="title">Country</p>
<h6>{{ vendor.country }}</h6>
</div>

<div class="intro-grid-column product-services-field">
<p class="title">Status</p>
<h6>{{ vendor.status }}</h6>
</div>

<div class="intro-grid-column intro-grid-comment-column product-services-field">
<p class="title">Comments</p>
<h6>{{ vendor.comments }}</h6>
</div>

  <!-- <VendorProductsServices v-bind:formMode="'filled'" /> -->

</div>
</div>



<div class="content-block">

  <FileAttachments v-if="isAuth('vendor-attachements')" :key="`vendor-form-${vendor.id}`" v-bind:filled="true" v-bind:section="'vendor'" v-bind:instance="`${vendor.id}`" />

<!-- <div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o">Cancel</button></div>
</div>
</div>
</div> -->

</div>

</div>
</div>
</section><!--//End Section-->
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { inject, ref } from 'vue';
// import VendorProductsServices from './VendorProductsServices.vue';

export default {
    name: "VendorFilled",
    setup() { 
      const { isAuth } = inject('mainUI');
      const { vendor, selectVendor, selectVendorItem, defaultVendorItem, vendorFormSubmitted } = inject('vendorDetails');

      const productSelected = ref(vendor.product ? true : false);
      const servicesSelected = ref(vendor.serviceType ? true : false);

      const editHelperTooltip = ref(false);
      const helperTooltip = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const switchEditHelperTooltip = (toggle) => {
        editHelperTooltip.value = toggle;
      }
      
      const addVendorItem = () => {
        vendorFormSubmitted.value = true;
        selectVendorItem({
          ...defaultVendorItem,
          vendor: vendor.id
        }, 'create');
      }

      // onMounted(() => {
      //   if (vendor.product.length > 0) productSelected.value = true;
      //   if (vendor.servicesSelected.length > 0) servicesSelected.value = true;
      // })

      return {
        vendor,
        selectVendor,
        productSelected,
        servicesSelected,
        editHelperTooltip,
        helperTooltip,
        switchHelperTooltip,
        switchEditHelperTooltip,
        addVendorItem,
        isAuth
      }

    },
    components: { 
      FileAttachments, 
      // VendorProductsServices 
    }
}
</script>

<style lang="scss" scoped>
.edit-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 40px;
  left: -30px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 41px;
    background: #3C3838;
    z-index: -1;
  }
}
</style>