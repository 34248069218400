<template>
<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Crop Harvest</h5>
<div class="icon-group">
<!-- <div class="icon green icon-o camera-plus"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
>
  <img src="@/assets/images/camera-plus.svg" alt="">
</div> -->
</div>
</div>

<!-- <div class="content-block">
<div class="checkbox-group">
<p class="fw-semibold gray">Select Categories:</p>
<div class="checkbox"><input type="checkbox" name="productServices" id="product" v-model="productMode"><label for="product">Product</label></div>
<div class="checkbox"><input type="checkbox" name="productServices" id="services" v-model="servicesMode"><label for="services">Services</label></div>
</div>
</div> -->

<div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3 ">
<div class="form-item">
  <p class="form-label" :class="{active: focused.date || (harvest.date.length > 0)}">Date</p>
<input type="date" class="form-control date-field" 
  :style="{color: (focused.date || harvest.date.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
  v-model="harvest.date"
  pattern="\d{2}/\d{2}/\d{4}"
  @focus="() => {
    activated.date = true;
    focused.date = true;
  }"
  @blur="() => {
    focused.date = false;
  }">
  <div class="date-icon"></div>
<p v-if="activated.date && harvest.date.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3 ">
<div class="form-item">
  <p class="form-label" :class="{active: focused.farm || (harvest.farm.length > 0)}">Farm</p>
<!-- <input type="text" class="form-control" 
  v-model="task.farm"
  @focus="() => {
    activated.farm = true;
    focused.farm = true;
  }"
  @blur="() => {
    focused.farm = false;
  }"> -->
  <!-- <select class="form-control" 
    v-model="harvest.farm"
    @click="() => {
      activated.farm = true;
      focused.farm = true;
    }"
    @change="() => {
      focused.farm = false;
    }">
  <option
    v-for="farm in farms"
    v-bind:key="farm.farmName + 'farm-option-harvest'"
  >{{ farm.farmName }}</option>
</select> -->
<DropDownList
  :mainObject="'harvest'"
  :mainProperty="'farm'"
  :injectionName="'harvestDetails'"
  :optionKey="'farm-option-harvest'"
  :optionsList="farms"
  :optionProperty="'farmName'"
  :optionPropertyTwo="null"
  :defaultSelected="harvest.farm"
  @click="() => {
    activated.farm = true;
  }"
/>
<p v-if="activated.farm && harvest.farm.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.farm && harvest.farm.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
</div>
</div>

<div class="col-3 " v-if="bedsOptions.length > 0">
<div class="form-item">
  <p class="form-label" :class="{active: focused.bed || (harvest.bed.length > 0)}">Bed</p>
<!-- <input type="text" class="form-control" 
  v-model="task.bed"
  @focus="() => {
    activated.bed = true;
    focused.bed = true;
  }"
  @blur="() => {
    focused.bed = false;
  }"> -->
  <!-- <select class="form-control" 
    v-model="harvest.bed"
    @click="() => {
      activated.bed = true;
      focused.bed = true;
    }"
    @change="() => {
      focused.bed = false;
    }">
    <option
      v-for="bed in bedsOptions"
      v-bind:key="bed + 'bed-option-harvest'"
    >{{ bed }}</option>
  </select> -->
  <DropDownList
    :mainObject="'harvest'"
    :mainProperty="'bed'"
    :injectionName="'harvestDetails'"
    :optionKey="'bed-option-harvest'"
    :optionsList="bedsOptions"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="harvest.bed"
    @click="() => {
      activated.bed = true;
    }"
  />
<p v-if="activated.bed && harvest.bed.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.bed && harvest.bed.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
</div>
</div>

<div class="col-3 common">
<div class="form-item">
<p class="form-label" :class="{active: focused.season || (harvest.season.length > 0)}">Season</p>
<!-- <select class="form-control"
  v-model="harvest.season"
  @click="() => {
    activated.season = true;
    focused.season = true;
  }"
  @change="() => {
    focused.season = false;
  }"
  disabled
>
  <option v-for="seasonOption in cropSeasons"
    v-bind:key="seasonOption + 'season-option-task'"
  >{{ seasonOption }}</option>
</select> -->
<DropDownList
  :mainObject="'harvest'"
  :mainProperty="'season'"
  :injectionName="'harvestDetails'"
  :optionKey="'season-option-harvest'"
  :optionsList="cropSeasons"
  :optionProperty="null"
  :optionPropertyTwo="null"
  :defaultSelected="harvest.season"
  @click="() => {
    activated.season = true;
  }"
/>
<p v-if="activated.season && harvest.season.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.season && harvest.season.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3 common">
<div class="form-item">
<p class="form-label" :class="{active: focused.cropCycle || (harvest.cropCycle.length > 0)}">Crop Cycle</p>
<input type="text" class="form-control"
  v-model="harvest.cropCycle"
  @focus="() => {
    activated.cropCycle = true;
    focused.cropCycle = true;
  }"
  @blur="() => {
    focused.cropCycle = false;
  }" disabled />
<p v-if="activated.cropCycle && harvest.cropCycle.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.cropCycle && harvest.cropCycle.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3 common" v-if="crops.length > 0">
<div class="form-item">
<p class="form-label" :class="{active: focused.crop || (harvest.crop.length > 0)}">Crop</p>
  <!-- <select class="form-control" 
    v-model="harvest.crop"
    @click="() => {
      activated.crop = true;
      focused.crop = true;
    }"
    @change="() => {
      focused.crop = false;
    }">
    <option
      v-for="cropItem in crops"
      v-bind:key="cropItem.id + 'crop-option-harvest'"
    >{{ cropItem.crop }}</option>
  </select> -->
  <DropDownList
    :mainObject="'harvest'"
    :mainProperty="'crop'"
    :injectionName="'harvestDetails'"
    :optionKey="'crop-option-harvest'"
    :optionsList="crops"
    :optionProperty="'crop'"
    :optionPropertyTwo="null"
    :defaultSelected="harvest.crop"
    @click="() => {
      activated.crop = true;
    }"
  />
<p v-if="activated.crop && harvest.crop.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.crop && harvest.crop.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3" v-else>
<div class="form-item">
  <p class="form-label" :class="{active: focused.crop || (harvest.crop.length > 0)}">Crop</p>
<input type="text" class="form-control"
  v-model="harvest.crop"
  @focus="() => {
    activated.crop = true;
    focused.crop = true;
  }"
  @blur="() => {
    focused.crop = false;
  }"
  placeholder="E.g., Okra"
  >
<p v-if="activated.crop && harvest.crop.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.crop && harvest.crop.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
</div>
</div>

<div class="col-3 ">
<div class="form-item">
  <p class="form-label" :class="{active: focused.assignedTo || (harvest.assignedTo.length > 0)}">Assigned To</p>
  <!-- <select class="form-control" 
    v-model="harvest.assignedTo"
    @click="() => {
      activated.assignedTo = true;
      focused.assignedTo = true;
    }"
    @change="() => {
      focused.assignedTo = false;
    }">
    <option
      v-for="user in users"
      v-bind:key="user.id + 'user-option-harvest'"
    >{{ `${user.mainDetails.firstName} ${user.mainDetails.lastName}` }}</option>
  </select> -->
  <DropDownList
    :mainObject="'harvest'"
    :mainProperty="'assignedTo'"
    :injectionName="'harvestDetails'"
    :optionKey="'user-option-harvest'"
    :optionsList="users"
    :optionProperty="'mainDetails'"
    :optionPropertyTwo="['firstName', 'lastName']"
    :defaultSelected="harvest.assignedTo"
    @click="() => {
      activated.assignedTo = true;
    }"
  />
<p v-if="activated.assignedTo && harvest.assignedTo.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.assignedTo && harvest.assignedTo.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-3 ">
<div class="form-item">
  <p class="form-label" :class="{active: focused.amtHarvested || (harvest.amtHarvested.length > 0)}">Amt  Harvested</p>
<input type="text" class="form-control" 
  v-model="harvest.amtHarvested"
  @focus="() => {
    activated.amtHarvested = true;
    focused.amtHarvested = true;
  }"
  @blur="() => {
    focused.amtHarvested = false;
  }"
  placeholder="E.g., 30"
  >
<p v-if="activated.amtHarvested && harvest.amtHarvested.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.amtHarvested && harvest.amtHarvested.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3 ">
<div class="form-item">
  <p class="form-label" :class="{active: focused.wastage || (harvest.wastage.length > 0)}">Wastage</p>
<input type="text" class="form-control" 
  v-model="harvest.wastage"
  @focus="() => {
    activated.wastage = true;
    focused.wastage = true;
  }"
  @blur="() => {
    focused.wastage = false;
  }"
  placeholder="E.g., 2"
  >
<p v-if="activated.wastage && harvest.wastage.length === 0" class="form-info red text-end">* Required</p>
<p v-if="activated.wastage && `${harvest.wastage}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
</div>
</div>

<div class="col-3 ">
<div class="form-item">
  <p class="form-label" :class="{active: focused.wastageCause || (harvest.wastageCause.length > 0)}">Wastage Cause</p>
<input type="text" class="form-control" 
  v-model="harvest.wastageCause"
  @focus="() => {
    activated.wastageCause = true;
    focused.wastageCause = true;
  }"
  @blur="() => {
    focused.wastageCause = false;
  }"
  placeholder="E.g., Insect Damage"
  >
<p v-if="(activated.wastageCause && harvest.wastageCause.length === 0) && (harvest.wastage.length > 0)" class="form-info red text-end">* Required</p>
<p v-if="activated.wastageCause && harvest.wastageCause.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
</div>
</div>

<div class="col-12 ">
<div class="form-item">
<p class="form-label" :class="{active: focused.comments || (harvest.comments.length > 0)}">Comments</p>
<textarea class="form-control" v-model="harvest.comments"
  @focus="() => {
    activated.comments = true;
    focused.comments = true;
  }"
  @blur="() => {
    focused.comments = false;
  }"></textarea>
<p v-if="activated.comments && harvest.comments.length > 150" class="form-info red text-end">Please use maximum 150 characters</p>
</div>
</div>

</div>

</div>
</div>
</div>

<!-- </div> -->

<div class="content-block">

  <FileAttachments :key="`harvest-form-${harvest.id}`" v-bind:section="'task'"  v-bind:instance="`${harvest.id}`" />

<div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
</div>
</div>
</div>

</div>

</div>

<div v-if="missedField">
  <UserNotification
    v-bind:messageText="'Please add the required data'"
  />
</div>

</template>

<script>
import { reactive, inject, ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
// import { v4 as uuid } from 'uuid';
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import DropDownList from '@/components/Shared/DropDownList.vue';
import UserNotification from "@/components/Shared/UserNotification.vue";

export default {
    name: "HarvestForm",
    components: { FileAttachments, DropDownList, UserNotification },
    setup() {
      const store = useStore();

      const cropSeasons = ['Spring', 'Summer', 'Autumn', 'Winter'];

      const activated = reactive({
        date: false,
        farm: false,
        bed: false,
        season: false,
        cropCycle: false,
        crop: false,
        assignedTo: false,
        amtHarvested: false,
        wastage: false,
        wastageCause: false,
        comments: false
      });

      const focused = reactive({
        date: false,
        farm: false,
        bed: false,
        season: false,
        cropCycle: false,
        crop: false,
        assignedTo: false,
        amtHarvested: false,
        wastage: false,
        wastageCause: false,
        comments: false
      });

      const { harvest, editionMode, selectedHarvest, selectHarvest, defaultHarvest } = inject("harvestDetails");

      const bedsOptions = ref([]);

      const farms = computed(() => store.state.farms);

      const usersState = computed(() => store.state.users);

      const users = ref([]);

      const cropsState = computed(() => store.state.crops);

      const crops = ref([]);

      const helperTooltip = ref(false);

      const missedField = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const submitForm = () => {

        if (!harvest.date || !harvest.farm || !harvest.crop || !harvest.assignedTo || 
        !harvest.amtHarvested || !harvest.wastage || !harvest.wastageCause) {
          if (!harvest.date) activated.date = true;
          if (!harvest.farm) activated.farm = true;
          // if (!harvest.bed) activated.bed = true;
          if (!harvest.crop) activated.crop = true;
          if (!harvest.assignedTo) activated.assignedTo = true;
          if (!harvest.amtHarvested) activated.amtHarvested = true;
          if (!harvest.wastage) activated.wastage = true;
          if (!harvest.wastageCause) activated.wastageCause = true;

          missedField.value = true;

          setTimeout(() => {
            missedField.value = false;
          }, 5100);

          return;
        }

        // if (!harvest.id) {
        //   harvest.id = uuid();
        // }

        const formData = {
          ...harvest
        };

        // if (editionMode.value === "edit") {
        //   store.dispatch("editHarvest", { ...formData });
        //   formData.taskStatus = 'In Progress';
        //   store.dispatch("editTask", { ...formData })
        // } else {
        //   store.dispatch("createHarvest", {...formData});
        //   formData.taskStatus = 'In Progress';
        //   store.dispatch("createTask", { ...formData });
        // }
        // console.log(formData);
        // (editionMode.value === "create") && closeForm();
        store.dispatch("editTask", { ...formData });

        (editionMode.value === "edit") && selectHarvest({...harvest}, 'represent');
      }

      // const closeForm = () => {
      //   harvest.id = '';
      //   for (let prop in harvest) {
      //     harvest[prop] = defaultHarvest[prop];
      //   }

      //   for (let prop in activated) {
      //     activated[prop] = false;
      //   }
        
      //   for (let prop in focused) {
      //     focused[prop] = false;
      //   }
      // }

      const cancelChanges = () => {
        if (editionMode.value === "edit") {
          for (let prop in harvest) {
            harvest[prop] = selectedHarvest[prop];
          }
          editionMode.value = "represent";
        } else {
          for (let prop in harvest) {
            harvest[prop] = defaultHarvest[prop];
          }
          editionMode.value = '';
        }
      };

      watch(() => ({...harvest}), (newVal, oldVal) => {
        if (newVal?.farm) {
          const selectedFarm = farms.value.find(frm => frm.farmName === newVal.farm)

          const selectedFarmBeds = selectedFarm.beds.filter((bed, index) => bed.bedNumber !== selectedFarm.beds[index - 1]?.bedNumber)

          bedsOptions.value = selectedFarmBeds.map(bed => bed.bedNumber);

          if (bedsOptions.value.length > 0) {
            if (!newVal.bed) {
              harvest.bed = selectedFarm.beds[0].bedNumber
            }
            if (newVal.bed !== oldVal.bed) {
              const selectedBed = selectedFarm.beds.find(bed => bed.bedNumber === newVal.bed);
              crops.value = [...cropsState.value.filter(crp => crp.sourceId === selectedBed.sourceId)];
              console.log(crops.value, 'crops.value', selectedBed, 'selectedBed');
              if (crops.value.length > 0) {
                harvest.crop = crops.value[0].crop || '';
                harvest.cropCycle = crops.value[0].cropCycle || '';
                harvest.season = crops.value[0].season || '';
              }
            }
          } else {
            if (newVal.farm !== oldVal.farm) {
              harvest.bed = '';
              crops.value = [...cropsState.value.filter(crp => crp.sourceId === selectedFarm.id)];
              if (crops.value.length > 0) {
                harvest.crop = crops.value[0].crop || '';
                harvest.cropCycle = crops.value[0].cropCycle || '';
                harvest.season = crops.value[0].season || '';
              }
            }
          }
        }

      })

      onMounted(() => {
        harvest.farm  = farms.value[0]?.farmName || '';

        users.value = store.state.users.find(u => u._id === store.state.authenticated._id) ?
          [...usersState.value] :
          [
            {
              ...store.state.primaryUser,
              mainDetails: {
                ...store.state.primaryUser.mainDetails,
                id: 'primary'
              }
            },
            ...usersState.value
          ]
      })

      return {
        activated,
        focused,
        harvest,
        bedsOptions,
        farms,
        crops,
        cropSeasons,
        users,
        helperTooltip,
        switchHelperTooltip,
        submitForm,
        cancelChanges,
        missedField
      }
    }
}
</script>

<style lang="scss" scoped>
.form-control {
  &.date-field {
    padding-right: 1.8rem;
    background: none;
  }
}
.date-icon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 3.7rem;
  right: 1rem;
  z-index: 8;
  pointer-events: none;
  border-radius: 1rem;
  background:url('@/assets/images/calendar-icon.svg') center center no-repeat;
  background-color: #fff;
}
</style>