<template>
  <ul class="breadcrumbs">
    <template
      v-for="(tag, index) in navTags"
      v-bind:key="'nav-tag' + index"
    >
      <li class="nav-tag" 
        :class="{active: index === (navTags.length - 1)}"
      ><span v-if="(index > 0) && navTags[index]" class="arrow">></span> {{tag}}</li>
    </template>
  </ul>

  <!-- <ul class="breadcrumbs">
    <li><a href="javascript:void();">Profile</a></li>  
    <li>Payment Information</li>
  </ul> -->
</template>

<script>
import { onMounted, reactive, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  name: "NavigationBreadcrumbs",
  setup() {
    const store = useStore();
    const navTags = reactive([]);

    watch(store.state.currentPath, (newVal) => {
      console.log(store.state.currentPath, 'store.state.currentPath')
      for (let i = 0; i < newVal.length; i++) {
        navTags[i] = newVal[i];
      }
      if (!newVal[0]) {
        navTags[0] = 'Dashboard'
      }
    })

    onMounted(() => {
      for (let i = 0; i < store.state.currentPath.length; i++) {
        navTags[i] = store.state.currentPath[i];
      }
    })

    return {
      navTags
    }
  }
}
</script>

<style lang="scss" scoped>
  .breadcrumbs {
    .nav-tag {
      font-family: var(--fw-semibold);
      padding: 0 1.2rem;
      color: hsl(210, 1%, 50%);
      position: relative;
      &::after {
        display: none;
      }
      .arrow {
        position: absolute;
        transform: translateX(-1.6rem);
        color: hsl(210, 1%, 50%);
        font-family: var(--fw-semibold);
      }
      &.active {
        color: hsl(var(--green));
      }
    }
  }
</style>