<template>
  <div class="border-box-content">

  <div class="content-block d-flex justify-content-between">
  <h5 class="fw-semibold">Admin Expense</h5>
  <div class="icon-group">
  <!-- <div class="icon green icon-o camera-plus"
    @mouseover="switchHelperTooltip(true)"
    @mouseout="switchHelperTooltip(false)"
  >
    <img src="@/assets/images/camera-plus.svg" alt="">
    <div class="helper-tooltip" v-if="helperTooltip">Take a shot</div>
  </div> -->
  </div>
  </div>

  <div class="content-block d-lg-flex justify-content-between">
  <div class="checkbox-group task-type">
  <p class="fw-semibold gray">Expense Type:</p>
  <div class="radio"><input type="radio" name="taskType" id="regular" value="Salary"  v-model="adminExpense.expenseType"><label for="regular">Salary</label></div>
  <div class="radio"><input type="radio" name="taskType" id="overtime" value="Other" v-model="adminExpense.expenseType"><label for="overtime">Other</label></div>
  </div>
  </div>

  <div class="content-block">

  <div class="content-row">
  <div class="content-row grid-4 grid-full">
  <div class="row">

  <div class="col-3 ">
  <div class="form-item">
    <p class="form-label" :class="{active: focused.date || (adminExpense.date.length > 0)}">Date</p>
    <input type="date" class="form-control date-field" 
      :style="{color: (focused.date || adminExpense.date.length > 0) ? 'hsl(var(--gray))' : 'transparent'}"
      v-model="adminExpense.date"
      pattern="\d{2}/\d{2}/\d{4}"
      @focus="() => {
        activated.date = true;
        focused.date = true;
      }"
      @blur="() => {
        focused.date = false;
      }" />
    <div class="date-icon"></div>
    <p v-if="activated.date && adminExpense.date.length === 0" class="form-info red text-end">* Required</p>
  </div>
  </div>

  <div class="col-3 ">
  <div class="form-item">
    <p class="form-label" :class="{active: focused.month || (adminExpense.month.length > 0)}">Month</p>
    <!-- <select class="form-control" 
      v-model="adminExpense.month"
      @click="() => {
        activated.month = true;
        focused.month = true;
      }"
      @change="() => {
        focused.month = false;
      }">
      <option
        v-for="month in months"
        v-bind:key="month + 'month-option-admin-expense'"
      >{{ month }}</option>
    </select> -->
    <DropDownList
      :mainObject="'adminExpense'"
      :mainProperty="'month'"
      :injectionName="'adminExpenseDetails'"
      :optionKey="'month-option-admin-expense'"
      :optionsList="months"
      :optionProperty="null"
      :optionPropertyTwo="null"
      :defaultSelected="adminExpense.month"
      @click="() => {
        activated.month = true;
      }"
    />
  <p v-if="activated.month && adminExpense.month.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.month && adminExpense.month.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
  </div>
  </div>

  <template v-if="adminExpense.expenseType === 'Salary'">
    
    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.employee || (adminExpense.employee.length > 0)}">Employee</p>
    <!-- <input type="text" class="form-control" 
      v-model="adminExpense.employee"
      @focus="() => {
        activated.employee = true;
        focused.employee = true;
      }"
      @blur="() => {
        focused.employee = false;
      }"> -->
      <DropDownList
        :mainObject="'adminExpense'"
        :mainProperty="'employee'"
        :injectionName="'adminExpenseDetailsTwo'"
        :optionKey="'user-option-expenses'"
        :optionsList="[]"
        :optionProperty="'mainDetails'"
        :optionPropertyTwo="['firstName', 'lastName']"
        :defaultSelected="adminExpense.employee"
        @click="() => {
          activated.employee = true;
        }"
      />
    <p v-if="activated.employee && adminExpense.employee.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.employee && adminExpense.employee.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.grade || (adminExpense.grade.length > 0)}">Grade</p>
    <!-- <input type="text" class="form-control" 
      v-model="adminExpense.grade"
      @focus="() => {
        activated.grade = true;
        focused.grade = true;
      }"
      @blur="() => {
        focused.grade = false;
      }"> -->

      <DropDownList
        :mainObject="'adminExpense'"
        :mainProperty="'grade'"
        :injectionName="'adminExpenseDetailsThree'"
        :optionKey="'grade-option-expenses'"
        :optionsList="[]"
        :optionProperty="'grade'"
        :defaultSelected="adminExpense.grade"
        @click="() => {
          activated.grade = true;
        }"
      />
    <p v-if="activated.grade && adminExpense.grade.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.salary || (adminExpense.salary.length > 0)}">Salary</p>
    <input type="text" class="form-control" 
      v-model="adminExpense.salary"
      @focus="() => {
        activated.salary = true;
        focused.salary = true;
      }"
      @blur="() => {
        focused.salary = false;
      }">
    <p v-if="activated.salary && adminExpense.salary.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.salary && `${adminExpense.salary}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.medicalBenefits || (adminExpense.medicalBenefits.length > 0)}">Medical Benefits</p>
    <input type="text" class="form-control" 
      v-model="adminExpense.medicalBenefits"
      @focus="() => {
        activated.medicalBenefits = true;
        focused.medicalBenefits = true;
      }"
      @blur="() => {
        focused.medicalBenefits = false;
      }">
    <p v-if="activated.medicalBenefits && adminExpense.medicalBenefits.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.bonus || (adminExpense.bonus.length > 0)}">Bonus</p>
    <input type="text" class="form-control" 
      v-model="adminExpense.bonus"
      @focus="() => {
        activated.bonus = true;
        focused.bonus = true;
      }"
      @blur="() => {
        focused.bonus = false;
      }">
    <p v-if="activated.bonus && `${adminExpense.bonus}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.other || (adminExpense.other.length > 0)}">Other</p>
    <input type="text" class="form-control" 
      v-model="adminExpense.other"
      @focus="() => {
        activated.other = true;
        focused.other = true;
      }"
      @blur="() => {
        focused.other = false;
      }">
    <p v-if="activated.other && `${adminExpense.other}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
    </div>

    <div class="col-3 ">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.taskStatus || (adminExpense.taskStatus.length > 0)}">Status</p>
      <!-- <select class="form-control" 
        v-model="adminExpense.taskStatus"
        @click="() => {
          activated.taskStatus = true;
          focused.taskStatus = true;
        }"
        @change="() => {
          focused.taskStatus = false;
        }">
        <option>Unpaid</option>
        <option>Paid</option>
      </select> -->
      <DropDownList
        :mainObject="'adminExpense'"
        :mainProperty="'taskStatus'"
        :injectionName="'adminExpenseDetails'"
        :optionKey="'task-status-option-admin-expense'"
        :optionsList="['Unpaid', 'Paid']"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="adminExpense.taskStatus"
        @click="() => {
          activated.taskStatus = true;
        }"
      />
    <p v-if="activated.taskStatus && adminExpense.taskStatus.length === 0" class="form-info red text-end">* Required</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.costCenter || (adminExpense.costCenter.length > 0)}">Cost Center</p>
    <input type="text" class="form-control" 
      v-model="adminExpense.costCenter"
      @focus="() => {
        activated.costCenter = true;
        focused.costCenter = true;
      }"
      @blur="() => {
        focused.costCenter = false;
      }">
    <p v-if="activated.costCenter && adminExpense.costCenter.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>
    </div>

  </template>

  <template v-if="adminExpense.expenseType === 'Other'">

    <div class="col-3 ">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.category || (adminExpense.category.length > 0)}">Category</p>
      <!-- <select class="form-control" 
        v-model="adminExpense.category"
        @click="() => {
          activated.category = true;
          focused.category = true;
        }"
        @change="() => {
          focused.category = false;
      }">
        <option>Electricity</option>
        <option>Phone</option>
        <option>Mobile Phone</option>
        <option>Water</option>
        <option>Transportation</option>
        <option>Subscription</option>
        <option>Software</option>
        <option>Rental</option>
        <option>Parking</option>
        <option>Taxes</option>
        <option>Training</option>
        <option>Education</option>
        <option>Other</option>
      </select> -->
      <DropDownList
        :mainObject="'adminExpense'"
        :mainProperty="'category'"
        :injectionName="'adminExpenseDetails'"
        :optionKey="'category-option-admin-expense'"
        :optionsList="[
          'Electricity',
          'Phone',
          'Mobile Phone',
          'Water',
          'Transportation',
          'Subscription',
          'Software',
          'Rental',
          'Parking',
          'Taxes',
          'Training',
          'Education',
          'Other'
        ]"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="adminExpense.category"
        @click="() => {
          activated.category = true;
        }"
      />
    <p v-if="activated.category && adminExpense.category.length === 0" class="form-info red text-end">* Required</p>
    </div>
    </div>


    <div class="col-3 " v-if="vendors.length > 0">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.vendor || (adminExpense.vendor.length > 0)}">Vendor</p>
      <!-- <select class="form-control" 
        v-model="adminExpense.vendor"
        @click="() => {
          activated.vendor = true;
          focused.vendor = true;
        }"
        @change="() => {
          focused.vendor = false;
      }">
        <option v-for="vendorOption in vendors"
          v-bind:key="vendorOption.vendor + 'vendor-option-admin-expense'"
        >{{ vendorOption.vendor }}</option>
      </select> -->
      <DropDownList
        :mainObject="'adminExpense'"
        :mainProperty="'vendor'"
        :injectionName="'adminExpenseDetails'"
        :optionKey="'vendor-option-admin-expense'"
        :optionsList="vendors"
        :optionProperty="'vendor'"
        :optionPropertyTwo="null"
        :defaultSelected="adminExpense.vendor"
        @click="() => {
          activated.vendor = true;
        }"
      />
    <p v-if="activated.vendor && adminExpense.vendor.length === 0" class="form-info red text-end">* Required</p>
    </div>
    </div>

    <div class="col-3" v-else>
    <div class="form-item">
    <p class="form-label" :class="{active: focused.vendor || (adminExpense.vendor.length > 0)}">Vendor</p>
    <input type="text" class="form-control" 
      v-model="adminExpense.vendor"
      @focus="() => {
        activated.vendor = true;
        focused.vendor = true;
      }"
      @blur="() => {
        focused.vendor = false;
      }">
    <p v-if="activated.vendor && adminExpense.vendor.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.vendor && adminExpense.vendor.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.invoiceNumber || (adminExpense.invoiceNumber.length > 0)}">Invoice #</p>
    <input type="text" class="form-control" 
      v-model="adminExpense.invoiceNumber"
      @focus="() => {
        activated.invoiceNumber = true;
        focused.invoiceNumber = true;
      }"
      @blur="() => {
        focused.invoiceNumber = false;
      }">
    <p v-if="activated.invoiceNumber && adminExpense.invoiceNumber.length > 25" class="form-info red text-end">Please use maximum 25 characters</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.amount || (adminExpense.amount.length > 0)}">Amount</p>
    <input type="text" class="form-control" 
      v-model="adminExpense.amount"
      @focus="() => {
        activated.amount = true;
        focused.amount = true;
      }"
      @blur="() => {
        focused.amount = false;
      }">
    <p v-if="activated.amount && `${adminExpense.amount}`.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
    </div>
    </div>

    <div class="col-3 ">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.taskStatus || (adminExpense.taskStatus.length > 0)}">Status</p>
      <!-- <select class="form-control" 
        v-model="adminExpense.taskStatus"
        @click="() => {
          activated.taskStatus = true;
          focused.taskStatus = true;
        }"
        @change="() => {
          focused.taskStatus = false;
        }">
        <option>Unpaid</option>
        <option>Paid</option>
      </select> -->
      <DropDownList
        :mainObject="'adminExpense'"
        :mainProperty="'taskStatus'"
        :injectionName="'adminExpenseDetails'"
        :optionKey="'task-status-option-admin-expense'"
        :optionsList="['Unpaid', 'Paid']"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="adminExpense.taskStatus"
        @click="() => {
          activated.taskStatus = true;
        }"
      />
    <p v-if="activated.taskStatus && adminExpense.taskStatus.length === 0" class="form-info red text-end">* Required</p>
    </div>
    </div>

    <div class="col-3 ">
    <div class="form-item">
      <p class="form-label" :class="{active: focused.paymentType || (adminExpense.paymentType.length > 0)}">Payment Type</p>
      <!-- <select class="form-control" 
        v-model="adminExpense.paymentType"
        @click="() => {
          activated.paymentType = true;
          focused.paymentType = true;
        }"
        @change="() => {
          focused.paymentType = false;
        }">
        <option>Check</option>
        <option>Cash</option>
        <option>Credit Card</option>
        <option>Electronic Transfer</option>
        <option>Venmo</option>
        <option>PayPal</option>
        <option>Xoom</option>
        <option>Cash App</option>
        <option>Zelle</option>
        <option>Other</option>
      </select> -->
      <DropDownList
        :mainObject="'adminExpense'"
        :mainProperty="'paymentType'"
        :injectionName="'adminExpenseDetails'"
        :optionKey="'payment-type-option-admin-expense'"
        :optionsList="[
          'Check',
          'Cash',
          'Credit Card',
          'Electronic Transfer',
          'Venmo',
          'PayPal',
          'Xoom',
          'Cash App',
          'Zelle',
          'Other'
        ]"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="adminExpense.paymentType"
        @click="() => {
          activated.paymentType = true;
        }"
      />
    <p v-if="activated.paymentType && adminExpense.paymentType.length === 0" class="form-info red text-end">* Required</p>
    </div>
    </div>

    <div class="col-3">
    <div class="form-item">
    <p class="form-label" :class="{active: focused.costCenter || (adminExpense.costCenter.length > 0)}">Cost Center</p>
    <input type="text" class="form-control" 
      v-model="adminExpense.costCenter"
      @focus="() => {
        activated.costCenter = true;
        focused.costCenter = true;
      }"
      @blur="() => {
        focused.costCenter = false;
      }">
    <p v-if="activated.costCenter && adminExpense.costCenter.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
    </div>
    </div>

  </template>

  <div class="col-12 ">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.comments || (adminExpense.comments.length > 0)}">Comments</p>
  <textarea class="form-control" v-model="adminExpense.comments"
    @focus="() => {
      activated.comments = true;
      focused.comments = true;
    }"
    @blur="() => {
      focused.comments = false;
    }"></textarea>
  <p v-if="activated.comments && adminExpense.comments.length > 150" class="form-info red text-end">Please use maximum 150 characters</p>
  </div>
  </div>

  </div>
  </div>
  </div>

  </div>

  <div class="content-block">
    
    <!-- <FileAttachments v-bind:section="'adminExpenses'" /> -->
    <FileAttachments :key="`admin-expense-form-${adminExpense.id}`" v-bind:section="'adminExpense'" v-bind:instance="`${adminExpense.id}`" />

    <div class="content-section">
      <div class="buttons">
      <div class="row">
      <div class="col-auto"><button type="submit" @click="submitForm" class="btn green">Save</button></div>
      <div class="col-auto"><button type="submit" @click="cancelChanges" class="btn light-blue-20 btn-o">Cancel</button></div>
      </div>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import { computed, defineComponent, inject, reactive, ref, onMounted, provide } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import months from '@/sharedData/monthsList';
import DropDownList from '@/components/Shared/DropDownList.vue';

export default defineComponent({
    name: "AdminExpensesForm",
    setup() {
        const store = useStore();
        const activated = reactive({
            date: false,
            month: false,
            employee: false,
            grade: false,
            salary: false,
            medicalBenefits: false,
            bonus: false,
            other: false,
            taskStatus: false,
            costCenter: false,
            category: false,
            vendor: false,
            invoiceNumber: false,
            amount: false,
            // status: '',
            paymentType: false,
            // costCenter: '',
            expenseType: false,
            comments: false
        });
        const focused = reactive({
            date: false,
            month: false,
            employee: false,
            grade: false,
            salary: false,
            medicalBenefits: false,
            bonus: false,
            other: false,
            taskStatus: false,
            costCenter: false,
            category: false,
            vendor: false,
            invoiceNumber: false,
            amount: false,
            // status: '',
            paymentType: false,
            // costCenter: '',
            expenseType: false,
            comments: false
        });
        const { adminExpense, editionMode, selectedAdminExpense, selectAdminExpense, defaultAdminExpense, users, grades } = inject("adminExpenseDetails");

        const helperTooltip = ref(false);

        const newId = ref(null);

        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };

        const vendors = computed(() => store.state.vendors);

        const submitForm = () => {
            if (!adminExpense.date || !adminExpense.month ||
                ((adminExpense.expenseType === "Salary") &&
                    (!adminExpense.employee || !adminExpense.salary || !adminExpense.taskStatus)) ||
                ((adminExpense.expenseType === "Other") && (!adminExpense.category ||
                    !adminExpense.vendor || !adminExpense.amount || !adminExpense.paymentType))) {
                if (!adminExpense.date) activated.date = true;
                if (!adminExpense.month) activated.month = true;
                if (!adminExpense.employee) activated.employee = true;
                if (!adminExpense.salary) activated.salary = true;
                if (!adminExpense.taskStatus) activated.taskStatus = true;
                if (!adminExpense.category) activated.category = true;
                if (!adminExpense.vendor) activated.vendor = true;
                if (!adminExpense.amount) activated.amount = true;
                if (!adminExpense.paymentType) activated.paymentType = true;
                return;
            }
            if (!adminExpense.id) {
                adminExpense.id = newId.value;
            }

            const formData = {
                ...adminExpense,
            };
            (editionMode.value === "edit") ?
                store.dispatch("editAdminExpense", { ...formData })
                :
                store.dispatch("createAdminExpense", { ...formData });
            console.log(formData);
            (editionMode.value === "create") && closeForm();
            (editionMode.value === "edit") && selectAdminExpense(adminExpense, "represent");
        };
        const closeForm = () => {
            adminExpense.id = "";
            for (let prop in adminExpense) {
                adminExpense[prop] = defaultAdminExpense[prop];
            }
            for (let prop in activated) {
                activated[prop] = false;
            }
            for (let prop in focused) {
                focused[prop] = false;
            }
            editionMode.value = '';
        };
        const cancelChanges = () => {
            if (editionMode.value === "edit") {
                for (let prop in adminExpense) {
                    adminExpense[prop] = selectedAdminExpense[prop];
                }
                editionMode.value = "represent";
            }
            else {
                for (let prop in adminExpense) {
                    adminExpense[prop] = defaultAdminExpense[prop];
                }
                editionMode.value = "";
            }
        };

        onMounted(() => {

          if (editionMode.value !== 'edit') {
            newId.value = uuid();
            adminExpense.id = newId.value;
          }
        });

        provide('adminExpenseDetailsTwo', {
          adminExpense,
          options: users
        });

        provide('adminExpenseDetailsThree', {
          adminExpense,
          options: grades
        });

        return {
            activated,
            focused,
            adminExpense,
            vendors,
            months,
            helperTooltip,
            switchHelperTooltip,
            submitForm,
            cancelChanges
        };
    },
    components: { FileAttachments, DropDownList }
})
</script>

<style lang="scss" scoped>
.form-control {
  &.date-field {
    padding-right: 1.8rem;
    background: none;
  }
}
.date-icon {
  width: 3rem;
  height: 3rem;
  position: absolute;
  top: 3.7rem;
  right: 1rem;
  z-index: 8;
  pointer-events: none;
  border-radius: 1rem;
  background:url('@/assets/images/calendar-icon.svg') center center no-repeat;
  background-color: #fff;
}
</style>