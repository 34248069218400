<template>
  <section class="section">
  <div class="border-box">
  <div class="border-box-content">

  <div class="content-block d-flex justify-content-between">
  <h5 class="fw-semibold">Customer</h5>
  <div class="icon-group">
    <!-- <div class="icon green icon-o tooltip"
      @mouseover="switchHelperTooltip(true)"
      @mouseout="switchHelperTooltip(false)"
      data-tooltip="Camera"
    >
      <img src="@/assets/images/updated/camera-white.png" alt="">
    </div> -->
  </div>
  </div>

  <div class="content-block">

  <div class="content-row">
  <div class="content-row grid-4 grid-full">
  <div class="row">

  <div class="col-3" v-if="otherTypeSelected">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.type || (customer.type.length > 0)}">Type</p>
    <input 
      type="text" 
      class="form-control"
      v-model="customer.type"
      ref="typeInput"
      @focus="() => {
        activated.type = true;
        focused.type = true;
      }"
      @blur="() => {
        focused.type = false;
        otherTypeSelected = false;
      }" 
      placeholder="E.g., Restaurant"
    />
  <p v-if="activated.type && customer.type.length === 0" class="form-info red text-end">* Required</p>
  </div>
  </div>

  <div class="col-3" v-else>
  <div class="form-item">
  <p class="form-label" :class="{active: focused.type || (customer.type.length > 0)}">Type</p>
  <!-- <select class="form-control" 
    v-model="customer.type"
    @click="() => {
      activated.type = true;
      focused.type = true;
    }"
    @change="() => {
      focused.type = false;
    }"
  >
    <option
      v-for="typeOption in typeOptions"
      v-bind:key="typeOption + 'type-option-sales'"
    >{{ typeOption }}</option>
  </select> -->
  <DropDownList
    :mainObject="'customer'"
    :mainProperty="'type'"
    :injectionName="'customerDetails'"
    :optionKey="'type-option-sales'"
    :optionsList="typeOptions"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="customer.type"
    @click="() => {
      activated.type = true;
    }"
  />
  <p v-if="activated.type && customer.type.length === 0" class="form-info red text-end">* Required</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.customer || (customer.customer.length > 0)}">Organization</p>
  <input 
    type="text" 
    class="form-control"
        v-model="customer.customer"
    @focus="() => {
      activated.customer = true;
      focused.customer = true;
    }"
    @blur="() => {
      focused.customer = false;
    }" 
    placeholder="E.g., Dunn Bros"
  />
  <template 
    v-if="(customer.type !== 'Facebook') && 
      (customer.type !== 'Individual') && 
      (customer.type !== 'Other') && 
      (customer.type !== 'Farmers Market')">
    <p v-if="activated.customer && customer.customer.length === 0" class="form-info red text-end">* Required</p>
  </template>
  <p v-if="activated.customer && customer.customer.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.contact || (customer.contact.length > 0)}">Contact Name</p>
    <input 
      type="text" 
      class="form-control" 
      v-model="customer.contact"
      @focus="() => {
        activated.contact = true;
        focused.contact = true;
      }"
      @blur="() => {
        focused.contact = false;
      }"
      placeholder="E.g., James David"
    />
    <p v-if="activated.contact && customer.contact.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.contact && customer.contact.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.workPhone || (customer.workPhone.length > 0)}">Work Phone</p>
  <input 
    type="text" 
    class="form-control" 
    pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
    v-model="customer.workPhone"
    @focus="() => {
      activated.workPhone = true;
      focused.workPhone = true;
    }"
    @blur="() => {
      focused.workPhone = false;
      phoneNumberFormat('workPhone')
    }"
    placeholder="E.g., (952) 456-9874"
  />
  <template 
    v-if="(customer.type !== 'Facebook') && 
      (customer.type !== 'Individual') && 
      (customer.type !== 'Other') && 
      (customer.type !== 'Farmers Market')">
    <p v-if="activated.workPhone && customer.workPhone.length === 0" class="form-info red text-end">* Required</p>
    <p v-if="activated.workPhone && !focused.workPhone && ((((customer.workPhone.length !== 10) && (customer.workPhone.length > 0)) || (customer.workPhone.length > 10)) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(customer.workPhone))" class="form-info red text-end">Please use 10 digits for phone</p>

  </template>
  <!-- <p v-if="activated.workPhone && `${customer.workPhone}`.length > 12" class="form-info red text-end">Please use maximum 50 characters</p> -->
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.mobile || (customer.mobile.length > 0)}">Mobile</p>
  <input 
    type="text" 
    class="form-control" 
    pattern="[(][0-9]{3}[)] [0-9]{3}-[0-9]{4}"
    v-model="customer.mobile"
    @focus="() => {
      activated.mobile = true;
      focused.mobile = true;
    }"
    @blur="() => {
      focused.mobile = false;
      phoneNumberFormat('mobile')
    }"
    placeholder="E.g., (651) 236-5478"
  />
  <!-- <template v-if="(customer.type !== 'Facebook') || (customer.type !== 'Individual')">
    <p v-if="activated.mobile && customer.mobile.length === 0" class="form-info red text-end">* Required</p>
  </template> -->
  <template v-if="(customer.type === 'Facebook') || 
      (customer.type === 'Individual') || 
      (customer.type === 'Other') || 
      (customer.type === 'Farmers Market')">
    <p v-if="activated.mobile && customer.mobile.length === 0" class="form-info red text-end">* Required</p>
  </template>
  <!-- <p v-if="activated.mobile && `${customer.mobile}`.length > 12" class="form-info red text-end">Please use maximum 50 characters</p> -->
  <p v-if="activated.mobile && !focused.mobile && ((((customer.mobile.length !== 10) && (customer.mobile.length > 0)) || (customer.mobile.length > 10)) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(customer.mobile))" class="form-info red text-end">Please use 10 digits for phone</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.email || (customer.email.length > 0)}">Email</p>
  <input 
    type="text" 
    class="form-control"
    v-model="customer.email"
    @focus="() => {
      activated.email = true;
      focused.email = true;
    }"
    @blur="() => {
      focused.email = false;
    }"
    placeholder="E.g., jamesd@dunn.com"
  />
  <template 
    v-if="(customer.type !== 'Facebook') && 
      (customer.type !== 'Individual') && 
      (customer.type !== 'Other') && 
      (customer.type !== 'Farmers Market')">
    <p v-if="activated.email && customer.email.length === 0" class="form-info red text-end">* Required</p>
  </template>
  <p v-if="activated.email && customer.email.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
  <p v-if="activated.email && !focused.email && ((customer.email.length > 0) && !/\S+@\S+\.\S+/.test(customer.email))" class="form-info red text-end">Please use correct email format</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.address1 || (customer.address1.length > 0)}">Address 1</p>
  <input 
    type="text" 
    class="form-control"
    v-model="customer.address1"
    @focus="() => {
      activated.address1 = true;
      focused.address1 = true;
    }"
    @blur="() => {
      focused.address1 = false;
    }"
    placeholder="E.g., 7447 Egan Dr"
  />
  <template v-if="(customer.type !== 'Facebook') && 
      (customer.type !== 'Individual') && 
      (customer.type !== 'Other') && 
      (customer.type !== 'Farmers Market')">
    <p v-if="activated.address1 && customer.address1.length === 0" class="form-info red text-end">* Required</p>
  </template>
  <p v-if="activated.address1 && customer.address1.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.address2 || (customer.address2.length > 0)}">Address 2</p>
  <input 
    type="text" 
    class="form-control"
    v-model="customer.address2"
    @focus="() => {
      activated.address2 = true;
      focused.address2 = true;
    }"
    @blur="() => {
      focused.address2 = false;
    }"
    placeholder="E.g., Suite 500"
  />
  <!-- <p v-if="activated.address2 && customer.address2.length === 0" class="form-info red text-end">* Required</p> -->
  <p v-if="activated.address2 && customer.address2.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.city || (customer.city.length > 0)}">City</p>
  <input 
    type="text" 
    class="form-control"
    v-model="customer.city"
    @focus="() => {
      activated.city = true;
      focused.city = true;
    }"
    @blur="() => {
      focused.city = false;
    }"
    placeholder="E.g., Savage"
  />
  <template v-if="(customer.type !== 'Facebook') && 
      (customer.type !== 'Individual') && 
      (customer.type !== 'Other') && 
      (customer.type !== 'Farmers Market')">
    <p v-if="activated.city && customer.city.length === 0" class="form-info red text-end">* Required</p>
  </template>
  <p v-if="activated.city && customer.city.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.state || (customer.state.length > 0)}">State</p>
    <!-- <select class="form-control" 
      v-model="customer.state"
      @click="() => {
        activated.state = true;
        focused.state = true;
      }"
      @change="() => {
        focused.state = false;
      }"
    >
      <option v-for="stateOption in stateOptions"
        v-bind:key="stateOption + 'state-option-sales'"
      >{{ stateOption }}</option>
    </select> -->
    <DropDownList
      :mainObject="'customer'"
      :mainProperty="'state'"
      :injectionName="'customerDetails'"
      :optionKey="'state-option-sales'"
      :optionsList="stateOptions"
      :optionProperty="null"
      :optionPropertyTwo="null"
      :defaultSelected="customer.state"
      @click="() => {
        activated.state = true;
      }"
    />
    <template v-if="(customer.type !== 'Facebook') && 
      (customer.type !== 'Individual') && 
      (customer.type !== 'Other') && 
      (customer.type !== 'Farmers Market')">
      <p v-if="activated.state && customer.state.length === 0" class="form-info red text-end">* Required</p>
    </template>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.zip || (customer.zip.length > 0)}">Zip</p>
  <input 
    type="text" 
    class="form-control"
    v-model="customer.zip"
    @focus="() => {
      activated.zip = true;
      focused.zip = true;
    }"
    @blur="() => {
      focused.zip = false;
    }"
    placeholder="E.g., 55378"
  />
  <template v-if="(customer.type !== 'Facebook') && 
      (customer.type !== 'Individual') && 
      (customer.type !== 'Other') && 
      (customer.type !== 'Farmers Market')">
    <p v-if="activated.zip && customer.zip.length === 0" class="form-info red text-end">* Required</p>
  </template>
  <p v-if="activated.zip && `${customer.zip}`.length > 10" class="form-info red text-end">Please use maximum 50 characters</p>
  </div>
  </div>

  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.country || (customer.country.length > 0)}">Country</p>
    <!-- <select class="form-control" 
      v-model="customer.country"
      @click="() => {
        activated.country = true;
        focused.country = true;
      }"
      @change="() => {
        focused.country = false;
      }"
    >
      <option
        v-for="countryOption in countryOptions"
        v-bind:key="countryOption + 'country-option-sales'"
      >{{ countryOption }}</option>
    </select> -->
    <DropDownList
      :mainObject="'customer'"
      :mainProperty="'country'"
      :injectionName="'customerDetails'"
      :optionKey="'country-option-sales'"
      :optionsList="countryOptions"
      :optionProperty="null"
      :optionPropertyTwo="null"
      :defaultSelected="customer.country"
      @click="() => {
        activated.country = true;
      }"
    />
    <template v-if="(customer.type !== 'Facebook') && 
      (customer.type !== 'Individual') && 
      (customer.type !== 'Other') && 
      (customer.type !== 'Farmers Market')">
      <p v-if="activated.country && customer.country.length === 0" class="form-info red text-end">* Required</p>
    </template>
  </div>
  </div>

  <div class="col-12">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.comments || (customer.comments.length > 0)}">Comments</p>
  <textarea class="form-control" 
    v-model="customer.comments"
    @focus="() => {
      activated.comments = true;
      focused.comments = true;
    }"
    @blur="() => {
      focused.comments = false;
    }"
    placeholder="E.g., New Customer"
  ></textarea>
  <p v-if="activated.comments && customer.comments.length > 150" class="form-info red text-end">Please use maximum 150 characters</p>
  </div>
  </div>

  </div>
  </div>
  </div>

  </div>

  <div class="content-block">

    <FileAttachments :key="`customer-form-${customer.id}`" v-bind:section="'customer'" v-bind:instance="`${customer.id}`" />

  <div class="content-section">
  <div class="buttons">
  <div class="row">
  <div class="col-auto"><button type="submit" @click="submitForm" class="btn green">Save</button></div>
  <div class="col-auto"><button type="submit" @click="cancelChanges" class="btn light-blue-20 btn-o">Cancel</button></div>
  </div>
  </div>
  </div>
  </div>

  </div>
  </div>
  </section>

  <div v-if="missedField">
    <UserNotification
      v-bind:messageText="'Please add the required data'"
    />
  </div>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { defineComponent, reactive, inject, ref, onMounted, watch, provide } from 'vue';
import { useStore } from 'vuex';
import { v4 as uuid } from 'uuid';
import stateOptions from '@/sharedData/statesList.js'
import countryOptions from '@/sharedData/countriesList.js'
import DropDownList from '@/components/Shared/DropDownList.vue';
import UserNotification from "@/components/Shared/UserNotification.vue";

const typeOptions = [
  'Business',
  'Facebook',
  'Farmers Market',
  'Government',
  'Individual',
  'Non-Profit',
  'Restaurant',
  'School',
  'Other'
]

export default defineComponent({
    name: "CustomersForm",
    setup() {
      const store = useStore();

      const activated = reactive({
        type: false,
        customer: false,
        contact: false,
        workPhone: false,
        mobile: false,
        email: false,
        address1: false,
        address2: false,
        city: false,
        state: false,
        zip: false,
        country: false,
        comments: false
      });

      const focused = reactive({
        type: false,
        customer: false,
        contact: false,
        workPhone: false,
        mobile: false,
        email: false,
        address1: false,
        address2: false,
        city: false,
        state: false,
        zip: false,
        country: false,
        comments: false
      });

      const { 
        customer, 
        editionMode, 
        selectedCustomer, 
        selectCustomer, 
        defaultCustomer 
      } = inject("customerDetails");

      const { selectSales, defaultSales } = inject('salesDetails');

      const newId = ref(null);

      const helperTooltip = ref(false);

      const otherTypeSelected = ref(false);

      const typeInput = ref(null);

      const otherSelected = ref(false);

      const missedField = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const submitForm = () => {
      
        if (!customer.type || !customer.contact || 
          (((customer.type === 'Facebook') || 
            (customer.type === 'Individual') || 
            (customer.type === 'Other') || 
            (customer.type === 'Farmers Market')) && !customer.mobile) ||
          (((customer.type !== 'Facebook') && 
            (customer.type !== 'Individual') && 
            (customer.type !== 'Other') && 
            (customer.type !== 'Farmers Market')) && (!customer.customer || !customer.email || !customer.workPhone || !customer.country
            || !customer.address1 || !customer.city || !customer.state || !customer.zip))) {
          if (!customer.type) activated.type = true;
          if (!customer.customer) activated.customer = true;
          if (!customer.contact) activated.contact = true;
          if (((customer.type !== 'Facebook') && 
            (customer.type !== 'Individual') && 
            (customer.type !== 'Other') && 
            (customer.type !== 'Farmers Market')) && !customer.country) activated.country = true;
          if (((customer.type !== 'Facebook') && 
            (customer.type !== 'Individual') && 
            (customer.type !== 'Other') && 
            (customer.type !== 'Farmers Market')) && !customer.address1) activated.address1 = true;
          if (((customer.type !== 'Facebook') && 
            (customer.type !== 'Individual') && 
            (customer.type !== 'Other') && 
            (customer.type !== 'Farmers Market')) && !customer.city) activated.city = true;
          if (((customer.type !== 'Facebook') && 
            (customer.type !== 'Individual') && 
            (customer.type !== 'Other') && 
            (customer.type !== 'Farmers Market')) && !customer.state) activated.state = true;
          if (((customer.type !== 'Facebook') && 
            (customer.type !== 'Individual') && 
            (customer.type !== 'Other') && 
            (customer.type !== 'Farmers Market')) && !customer.zip) activated.zip = true;
          if (((customer.type !== 'Facebook') && 
            (customer.type !== 'Individual') && 
            (customer.type !== 'Other') && 
            (customer.type !== 'Farmers Market')) && (!customer.email)) {
              activated.email = true;
            }
          if (((customer.type !== 'Facebook') && 
            (customer.type !== 'Individual') && 
            (customer.type !== 'Other') && 
            (customer.type !== 'Farmers Market')) && (!customer.workPhone)) {
              activated.workPhone = true;
          }
          if (((customer.type === 'Facebook') || 
            (customer.type === 'Individual') || 
            (customer.type === 'Other') || 
            (customer.type === 'Farmers Market')) && !customer.mobile) {
              activated.mobile = true;
            }

          missedField.value = true;

          setTimeout(() => {
            missedField.value = false;
          }, 5100);

          return;
        }

        if (!customer.id) {
          // customer.id = uuid();
          customer.id = newId.value;
        }

        const formData = {
          ...customer,
        };

        (editionMode.value === "edit") ?
          store.dispatch("editCustomer", { ...formData })
          :
          store.dispatch("createCustomer", {...formData});

        // (editionMode.value === "create") && closeForm();
        // (editionMode.value === "edit") && selectCustomer({...customer}, 'represent');
        (editionMode.value === "create") && selectSales({...defaultSales}, 'create');
        selectCustomer({...customer}, 'represent');
      }

      // const closeForm = () => {

      //   for (let prop in customer) {
      //     customer[prop] = defaultCustomer[prop];
      //   }

      //   for (let prop in activated) {
      //     activated[prop] = false;
      //   }
        
      //   for (let prop in focused) {
      //     focused[prop] = false;
      //   }
      // }

      const cancelChanges = () => {
        if (editionMode.value === "edit") {
          for (let prop in customer) {
            customer[prop] = selectedCustomer[prop];
          }
          editionMode.value = "represent";
        } else {
          for (let prop in customer) {
            customer[prop] = defaultCustomer[prop];
          }
          editionMode.value = "";
        }
      };

      const phoneNumberFormat = (phoneType) => {
        if (customer[phoneType].length > 0) {
          if (customer[phoneType][0] !== "(") {
            const arrStr = customer[phoneType].split("");
            customer[phoneType] = `(${arrStr.filter((num, indx) => {
              if (indx < 3)
                return num;
            }).join("")}) ${arrStr.filter((num, indx) => {
              if ((indx >= 3) && (indx < 6))
                return num;
            }).join("")}-${arrStr.filter((num, indx) => {
              if (indx >= 6)
                return num;
            }).join("")}`;
          }
        }
      };

      watch(() => (customer.type), (newVal) => {
        if (newVal === 'Other') {
          otherTypeSelected.value = true;
          customer.type = '';
          otherSelected.value = true;
          setTimeout(() => {
            typeInput.value.focus();
          }, 10)
        }
      })

      onMounted(() => {
        if (editionMode.value !== 'edit') {
          newId.value = uuid();
          customer.id = newId.value;
        }
      });

      provide('otherHandling', {
        otherSelected
      });

      return {
        activated,
        focused,
        customer,
        helperTooltip,
        stateOptions,
        countryOptions,
        typeOptions,
        switchHelperTooltip,
        phoneNumberFormat,
        submitForm,
        cancelChanges,
        otherTypeSelected,
        typeInput,
        missedField
      }
    },
    components: { FileAttachments, DropDownList, UserNotification }
})
</script>

<style lang="scss" scoped></style>