<template>
 
 <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Plan Crop: {{ crop.farmName }}</h5>
<div class="icon-group">
<div class="icon green icon-o"
  v-if="isAuth('edit-crop')"
  @click="() => editCrop()"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
>
  <img src="@/assets/images/edit.svg" alt="">
  <div class="helper-tooltip" v-if="helperTooltip">Edit Crop</div>
</div>
</div>
</div>

<div class="content-block">
<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Year</p>
<h6>{{ crop.year }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Season</p>
<h6>{{ crop.season }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Farm</p>
<h6>{{ crop.farmName }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Bed</p>
<h6>{{ crop.bed }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Acerage</p>
<h6>{{ crop.acreage }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Crop Cycle</p>
<h6>{{ crop.cropCycle }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Crop Category</p>
<h6>{{ crop.cropCategory }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Crop</p>
<h6>{{ crop.crop }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Variety</p>
<h6>{{ crop.variety }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Date Seed</p>
<h6>{{ moment(crop.dateSeed, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Harvest Date</p>
<h6>{{ moment(crop.harvestDate, 'YYYY-MM-DD').format('MM/DD/YYYY') }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Proj. Harvest Amt (lbs)</p>
<h6>{{ crop.projHarvestAmount }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Proj. Wastage (lbs)</p>
<h6>{{ crop.projWastage }}</h6>
</div>

</div>
</div>

<div class="content-block">
<div class="content-header">
<h6 class="fw-semibold">Set Notification</h6>
</div>
<div class="content-section">
<div class="checkbox-group-wrap">

<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="dateSeed" v-model="crop.isDateSeed" disabled><label for="dateSeed">Date Seed</label></div>
<div class="field-box">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control sm" v-model="crop.seedDays" disabled>
</div>
</div>

<div class="checkbox-group">
<div class="checkbox"><input type="checkbox" id="dateHarvest" v-model="crop.isDateHarvest"><label for="dateHarvest">Date Harvest</label></div>
<div class="field-box">
<p class="fw-semibold gray">Days in Advance</p>
<input type="text" class="form-control sm" v-model="crop.harvestDays" disabled>
</div>
</div>

</div>

</div>
</div>

</div>
 
  <!-- <q-expansion-item
    class="overflow-hidden rounded-borders plan-crop-filled"
    style="border-radius: 10px"
    popup
    :header-style="{
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      fontWeight: 700,
    }"
    default-opened
    label=""
  >
    <template v-slot:header>
      <q-item-section>
        <span class="header">{{ sectionHeader }}</span>
      </q-item-section>
      <q-item-section side>
        <div class="edit-crop" 
          v-if="isAuth('edit-crop')"
          @click="() => editCrop()"
          @mouseover="switchHelperTooltip(true)"
          @mouseout="switchHelperTooltip(false)"
        >
          <div class="helper-tooltip" v-if="helperTooltip">Edit Crop</div>
        </div>
      </q-item-section>
    </template>
    <q-card>
      <q-card-section class="filled-crop-card-section">

    <div class="row top-section-row">

      <div class="field">
        <span class="label">Year</span>
        <span class="field-value">{{ crop.year }}</span>
      </div>

      <div class="field">
        <span class="label">Season</span>
        <span class="field-value">{{ crop.season }}</span>
      </div>

      <div class="field">
        <span class="label">Farm Name</span>
        <span class="field-value">{{ crop.farmName }}</span>
      </div>

      <div class="field" v-if="crop.bed">
        <span class="label">Bed</span>
        <span class="field-value">{{ crop.bed }}</span>
      </div>

      <div class="field" v-if="!crop.bed">
        <span class="label">Acreage</span>
        <span class="field-value">{{ crop.acreage }}</span>
      </div>

    </div>

    <div class="row">

      <div class="field" v-if="crop.bed">
        <span class="label">Acreage</span>
        <span class="field-value">{{ crop.acreage }}</span>
      </div>
      
      <div class="field">
        <span class="label">Crop Cycle</span>
        <span class="field-value">{{ crop.cropCycle }}</span>
      </div>

      <div class="field">
        <span class="label">Category</span>
        <span class="field-value">{{ crop.cropCategory }}</span>
      </div>

      <div class="field">
        <span class="label">Crop</span>
        <span class="field-value">{{ crop.crop }}</span>
      </div>

      <div class="field" v-if="!crop.bed">
        <span class="label">Variety</span>
        <span class="field-value">{{ crop.variety }}</span>
      </div>
      
    </div>

    <div class="row">

      <div class="field" v-if="crop.bed">
        <span class="label">Variety</span>
        <span class="field-value">{{ crop.variety }}</span>
      </div>

      <div class="field">
        <span class="label">Date Seed</span>
        <span class="field-value">{{ crop.dateSeed }}</span>
      </div>

      <div class="field">
        <span class="label">Harvest Date</span>
        <span class="field-value">{{ crop.harvestDate }}</span>
      </div>

      <div class="field">
        <span class="label">Proj. Harvest Amount</span>
        <span class="field-value">{{ crop.projHarvestAmount }}</span>
      </div>

      <div class="field"  v-if="!crop.bed">
        <span class="label">Proj. Wastage</span>
        <span class="field-value">{{ crop.projWastage }}</span>
      </div>

    </div>

    <div class="row wrapper" v-if="crop.bed">

      <div class="field">
        <span class="label">Proj. Wastage</span>
        <span class="field-value">{{ crop.projWastage }}</span>
      </div>

      <div class="field"></div>
      <div class="field"></div>
      <div class="field"></div>
    </div>


    <div class="row notifications-set">
      
      <q-checkbox 
        color="bhoomi" 
        v-model="crop.isDateSeed" 
        label="Date Seed"
        class="custom-checkbox"
        disable
      />

      <label class="custom-label">
        <span>Days in Advance</span>
        <span>{{crop.seedDays}}</span>
      </label>

      <q-checkbox 
        color="bhoomi" 
        v-model="crop.isDateHarvest" 
        label="Date Harvest"
        class="custom-checkbox"
        disable
      />

      <label class="custom-label">
        <span>Days in Advance</span>
        <span>{{crop.harvestDays}}</span>
      </label>

    </div>
    
  </q-card-section>

  </q-card>

</q-expansion-item> -->

</template>

<script>
import { inject, ref } from 'vue';
import moment from 'moment';

export default {
  name: "CropFilled",
  setup() {
    const sectionHeader = 'Plan Crop';
    const { crop, editionMode } = inject('cropEdition');
    const { isAuth } = inject('mainUI');

    const editCrop = () => {
      editionMode.value = 'edit';
    }

    const helperTooltip = ref(false);

    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle;
    }
        
    return {
      sectionHeader,
      crop,
      editCrop,
      helperTooltip,
      switchHelperTooltip,
      isAuth,
      moment
    }
  },
};
</script>

<style lang="scss" scoped>
.plan-crop-filled {
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
  .header {
    font-size: 18px;
    color: #28292B;
    margin-left: 8px;
  }
  .edit-crop {
    display: flex;
    text-indent: -9999px;
    mask-position: center;
    margin-left: auto;
    width: 25px;
    height: 25px;
    background-image: url('@/assets/profile/edit_button.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    position: relative;
    .helper-tooltip {
      width: max-content;
      padding: 0 15px;
      height: 30px;
      position: absolute;
      z-index: 999;

      background-color: #3C3838;
      color: #fff;
      top: 40px;
      left: -30px;
      text-indent: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 11px;
      font-family: 'Inter', sans-serif;
      font-weight: 400;

      &::after {
        content: " ";
        position: absolute;
        display: block;
        top: -7px;
        border-color: #3C3838;
        border-left: 8px solid hsl(0, 3%, 23%);
        border-bottom: 6px solid #3C3838;
        border-right: 6px solid transparent;
        border-top: 8px solid transparent;
        -webkit-transform: translateX(-50%) rotate(135deg);
        transform: translateX(-50%) rotate(135deg);
        box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
        left: 41px;
        background: #3C3838;
        z-index: -1;
      }
    }
  }
  .filled-crop-card-section {
    padding: 24px;
    padding-top: 0;
  }
  .row {
    justify-content: space-between;
    height: 70px;
    // margin-left: 8px;
    &.top-section-row {
      .field {
        margin-top: 16px;
      }
    }
    &.notifications-set {
      .custom-label {
        display: flex;
        align-items: center;
      }
      .custom-checkbox {}
    }
    .field {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 165px;
      margin-top: 20px;
      .label {
        color: rgba(40,41,43,0.6);
        font-size: 16px;
        font-weight: 600;
      }
      .field-value {
        color: rgba(40,41,43,1);
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .plan-crop-filled {
    .row {
      &.wrapper {
        height: auto;
        .outer-label {
          margin: 0 5px;
        }
      }
    }
  }
}
</style>