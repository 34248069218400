<template>
  <SearchHeader />

  <section class="section search-header-section">
  <div class="row">
  <div class="col-6 d-flex align-item-center">
  <div class="search-links">
  <a href="javascript:void();" class="active">All</a>
  <a href="javascript:void();">Date</a>
  <a href="javascript:void();">Author</a>
  <a href="javascript:void();">Documents</a>
  </div>
  </div>
  <div class="col-6">
  <div class="search">
  <input type="text" class="form-control" placeholder="Search here" @input="(ev) => startSearch(ev)">
  </div>
  </div>
  </div>
  </section><!--//End Section-->

  <section class="section dashboard-section">
    <div class="row">
      <DashboardSidebar />
      <div class="col dashboard-content-wrap">
        <SearchPage />
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import SearchPage from './SearchPage.vue';
import SearchHeader from './SearchHeader.vue';
import DashboardSidebar from '../Dashboard/DashboardSidebar.vue';
import { useStore } from 'vuex';

export default defineComponent({
    name: "SearchModule",
    components: { SearchPage, SearchHeader, DashboardSidebar },
    setup() {
      
      const store = useStore();

      const startSearch = (ev) => {
        console.log(ev);
        store.dispatch('getSearchResults', `${ev.target.value}`)
      }

      return {
        startSearch
      }
    }
})
</script>

<style></style>