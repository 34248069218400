<template>

  <div class="section" ref="section">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Subscription Type</h5>
<div class="info-icons">
  <!-- <div class="info-icon edit-subscription"
    v-if="isAuth('edit-subscription')"
    @click="switchToEditable"
    @mouseover="switchEditHelperTooltip(true)"
    @mouseout="switchEditHelperTooltip(false)"
  >
    <img src="@/assets/images/updated/Edit.png" alt="">
    <div class="helper-tooltip" v-if="editHelperTooltip">Update Subscription</div>
  </div> -->
  <div class="info-icon lg header-icon"
      @click="() => openUpdateForm()"
      @mouseover="switchAddHelperTooltip(true)"
      @mouseout="switchAddHelperTooltip(false)"
    >
      <!-- <img src="@/assets/images/plus-green-md.png" alt=""> -->
      <img src="@/assets/images/updated/059-add.png" alt="">
      <div class="add-helper-tooltip" v-if="addHelperTooltip">Add Subscription</div>
    </div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content subscription-type-content">

  <div class="content-block">
  <div class="payment-details">
 

<div class="subscription-wrap-block">

  <div class="content-row subscription-info">
    <div>
        <p class="form-label">Billing Type</p>
        <h6>{{ stateSubscription.subscriptionPeriod }}</h6>
        <p class="fs-xs pt-1">10% Discount on Yearly Subscription</p>
    </div>
  </div>
  
<div class="subscription-list-grid-wrap">
  <div class="subscription-list-grid">

  <div></div>
  <div><p class="form-label text-center">Total</p></div>
  <div><p class="form-label text-center">Assign</p></div>
  <div><p class="form-label text-center">Available</p></div>

  <div class="checkbox"><input type="checkbox" id="free30day" v-model="stateSubscription.plans.trial"><label for="free30day">Free 30 Day</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(1) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="free30dayAssign"
        v-model="assigned.trial" 
      />
      <label for="free30dayAssign" class="p-0"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(assigned.trial ? 0 : 1) }}</h6>
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basic" v-model="stateSubscription.plans.basic" disabled /><label for="basic" @click="() => handleMissingSubscription('basic')">Basic</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.basicNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign2"
        v-model="assigned.basic" 
        :disabled="!stateSubscription.plans.basicNumber || (stateSubscription.plans.basicNumber <= 0) || ((stateSubscription.plans.basicAvailable <= 0) && !assigned.basic)"
        @change="() => reassignLeast('basic')"
      />
      <label for="assign2" class="p-0" @click="() => handleMissingSubscription('basic', assigned.basic)"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt((assigned.basic ? (stateSubscription.plans.basicAvailable - 1) : stateSubscription.plans.basicAvailable) || 0) }}</h6>
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlus" v-model="stateSubscription.plans.plus" disabled /><label for="basicPlus" @click="() => handleMissingSubscription('plus')">Basic +</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.plusNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign3" 
        v-model="assigned.plus"
        :disabled="!stateSubscription.plans.plusNumber || (stateSubscription.plans.plusNumber <= 0) || ((stateSubscription.plans.plusAvailable <= 0) && !assigned.plus)" 
        @change="() => reassignLeast('plus')"
      />
      <label for="assign3" class="p-0" @click="() => handleMissingSubscription('plus', assigned.plus)"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt((assigned.plus ? (stateSubscription.plans.plusAvailable - 1) : stateSubscription.plans.plusAvailable) || 0) }}</h6>
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlusAdvance" v-model="stateSubscription.plans.advance" disabled><label for="basicPlusAdvance" @click="() => handleMissingSubscription('advance')">Basic + Advance</label></div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt(stateSubscription.plans.advanceNumber || 0) }}</h6>
    </div>
  </div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign4"
        v-model="assigned.advance" 
        :disabled="!stateSubscription.plans.advanceNumber || (stateSubscription.plans.advanceNumber <= 0) || ((stateSubscription.plans.advanceAvailable <= 0) && !assigned.advance)"
        @change="() =>reassignLeast('advance')"
      />
      <label for="assign4" class="p-0" @click="() => handleMissingSubscription('advance', assigned.advance)"></label>
    </div>
  </div>
  <div>
    <div class="w-80">
      <h6>{{ Number.parseInt((assigned.advance ? (stateSubscription.plans.advanceAvailable - 1) : stateSubscription.plans.advanceAvailable) || 0) }}</h6>
    </div>
  </div>

  <div class="checkbox"><input type="checkbox" id="basicPlusUltimate" v-model="stateSubscription.plans.ultimate" disabled><label for="basicPlusUltimate" @click="() => handleMissingSubscription('ultimate')">Basic + Ultimate</label></div>
  <div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.ultimateNumber || 0) }}</h6></div></div>
  <div>
    <div class="w-80 checkbox checkbox-lg">
      <input type="checkbox" id="assign5"
        v-model="assigned.ultimate" 
        :disabled="!stateSubscription.plans.ultimateNumber || (stateSubscription.plans.ultimateNumber <= 0) || ((stateSubscription.plans.ultimateAvailable <= 0) && !assigned.ultimate)"
        @change="() =>reassignLeast('ultimate')"
      />
      <label for="assign5" class="p-0" @click="() => handleMissingSubscription('ultimate', assigned.ultimate)"></label>
    </div>
  </div>
  <div>
    <div class="w-80"><h6>{{ Number.parseInt((assigned.ultimate ? (stateSubscription.plans.ultimateAvailable - 1) : stateSubscription.plans.ultimateAvailable) || 0) }}</h6></div>
  </div>

  </div>
</div>

</div>
 
<div class="content-row overflow-auto subscription-result-wrap">
<div class="four-column-grid">
<div class="text-center">
  <p class="form-label">Subscription Amount</p>
  <h6>${{ Number.parseFloat(stateSubscription.totalSubscription || 0).toFixed(2) }}</h6>  
</div>
<div class="text-center">
  <p class="form-label">Discount</p>
  <h6>${{ Number.parseFloat(stateSubscription.discount || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Taxes</p>
  <h6>${{ Number.parseFloat(stateSubscription.taxes || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Total</p>
  <h6>${{ Number.parseFloat(stateSubscription.total || 0).toFixed(2) }}</h6>  
</div>
</div>  
</div>

<div class="content-row">
<div class="buttons">
<div class="row">
  <div class="col-auto"><button type="submit" class="btn green" @click="saveAssigned">Save</button></div>
  <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelAssigning">Cancel</button></div>
</div>
</div>
</div>

</div>
</div>

  <div v-if="popupMessages.missing.active">
    <UserNotification
      v-bind:hideDetails="true"
      v-bind:messageText="popupMessages.missing.message"
      @click="() => {popupMessages.missing.active = false}"
    />
  </div>

  <!-- Old content -->

  <!--<div class="content-block">
  <div class="payment-details">

 <div class="content-row">
<div class="checkbox"><input type="checkbox" id="freeThirtyDay" :checked="subscription.plans.trial" ><label for="freeThirtyDay">Free 30 Day</label></div>  
 </div>
 
  <div class="content-row d-flex justify-content-end">
    <div>
        <p class="form-label">Billing Type</p>
        <h6>{{ subscription.subscriptionPeriod }}</h6>
        <p class="fs-xs pt-1">10% Discount on Yearly Subscription</p>
    </div>
 </div>

<div class="content-row overflow-auto">
<div class="subscription-table-grid">

<div><div class="w-80"><p class="form-label text-center">Total</p></div></div>
<div><div class="w-80"><p class="form-label text-center">Assigned</p></div></div>
<div><div class="w-80"><p class="form-label text-center">Available</p></div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basic" :checked="subscription.plans.basic" disabled /><label for="basic">Basic</label></div></div>  
<div><div class="w-80"><h6>{{ Number.parseInt(subscription.plans.basicNumber || 0) }}</h6></div></div>
<div><div class="w-80"><h6>{{ (Number.parseInt(stateSubscription.plans.basicNumber - subscription.plans.basicAvailable) || 0) }}</h6></div></div>
<div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.basicAvailable || 0) }}</h6></div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basicPlus" v-model="subscription.plans.plus" disabled /><label for="basicPlus">Basic +</label></div></div>  
<div><div class="w-80">
  <h6>{{ Number.parseInt(stateSubscription.plans.plusNumber || 0) }}</h6>
</div></div>
<div><div class="w-80">
  <h6>{{ (Number.parseInt(stateSubscription.plans.plusNumber - subscription.plans.plusAvailable) || 0) }}</h6>
</div></div>
<div><div class="w-80">
  <h6>{{ Number.parseInt(stateSubscription.plans.plusAvailable || 0) }}</h6>
</div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basicPlusAdvance" v-model="subscription.plans.advance" disabled><label for="basicPlusAdvance">Basic + Advance</label></div></div>  
<div><div class="w-80">
  <h6>{{ Number.parseInt(stateSubscription.plans.advanceNumber || 0) }}</h6>
</div></div>
<div><div class="w-80">
  <h6>{{ (Number.parseInt(stateSubscription.plans.advanceNumber - subscription.plans.advanceAvailable) || 0) }}</h6>
</div></div>
<div><div class="w-80">
  <h6>{{ Number.parseInt(stateSubscription.plans.advanceAvailable || 0) }}</h6>
</div></div>

<div class="text-left"><div class="checkbox"><input type="checkbox" id="basicPlusUltimate" v-model="subscription.plans.ultimate" disabled><label for="basicPlusUltimate">Basic + Ultimate</label></div></div>  
<div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.ultimateNumber || 0) }}</h6></div></div>
<div><div class="w-80"><h6>{{ (Number.parseInt(stateSubscription.plans.ultimateNumber - subscription.plans.ultimateAvailable) || 0) }}</h6></div></div>
<div><div class="w-80"><h6>{{ Number.parseInt(stateSubscription.plans.ultimateAvailable || 0) }}</h6></div></div>

</div>
</div>
 
<div class="content-row overflow-auto">
<div class="four-column-grid">
<div class="text-center">
  <p class="form-label">Subscription Amount</p>
  <h6>${{ Number.parseFloat(stateSubscription.totalSubscription || 0).toFixed(2) }}</h6>  
</div>
<div class="text-center">
  <p class="form-label">Discount</p>
  <h6>${{ Number.parseFloat(stateSubscription.taxes || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Taxes</p>
  <h6>${{ Number.parseFloat(stateSubscription.discount || 0).toFixed(2) }}</h6>
</div>
<div class="text-center">
  <p class="form-label">Total</p>
  <h6>${{ Number.parseFloat(stateSubscription.total || 0).toFixed(2) }}</h6>  
</div>
</div>  
</div>

</div>
</div> -->

  <!-- <div class="content-block">
  <div class="payment-details">

  <div class="content-row subscription-grid-wrap">
  <div class="subscription-grid align-items-end">
  <div>
    <div class="checkbox">
      <input type="checkbox" id="trialPlan" :checked="subscription.plans.trial">
      <label for="trialPlan">Free 30 days trial</label>
    </div>
  </div>
  <div><div class="w-80"><p class="form-label-static">Total</p><h6>{{ 1 }}</h6></div></div>
  <div><div class="w-80"><p class="form-label-static">Assigned</p><h6>{{ subscriptionAssigned?.trial ? 1 : 0 }}</h6></div></div>
  <div><div class="w-80"><p class="form-label-static">Available</p><h6>
    {{ subscriptionAssigned?.trial ? 0 : 1 }}
  </h6></div></div>
  </div>
  </div>

  <div class="content-row subscription-grid-wrap">
  <div class="subscription-grid align-items-end">
  <div>
    <div class="checkbox">
      <input type="checkbox" id="basicPlan" :checked="subscription.plans.basic" disabled />
      <label for="basicPlan">Basic Plan:</label>
    </div>
  </div>
  <div><div class="w-80"><h6>{{ subscription.plans.basicNumber || 0 }}</h6></div></div>
  <div><div class="w-80"><h6>{{ subscriptionAssigned?.basic ? 1 : 0 }}</h6></div></div>
  <div><div class="w-80"><h6>
    {{ 
      subscription.plans.basicNumber > 0 ? 
        (subscription.plans.basicNumber - (subscriptionAssigned?.basic ? 1 : 0)) : 0 }}
  </h6></div></div>
  <div><div class="w-150"><p class="form-label-static">Billing Type</p><h6>{{ subscription.subscriptionPeriod }}</h6></div></div>
  </div>
  </div>

  <div class="content-row subscription-grid-wrap">
  <h6>Add Modules</h6>
  <div class="subscription-grid">
  <div>
    <div class="checkbox">
      <input type="checkbox" id="plusManagement" :checked="subscription.plans.plus" disabled />
      <label for="plusManagement">Basic +</label>
    </div>
  </div>
  <div><div class="w-80"><h6>{{ subscription.plans.plusNumber }}</h6></div></div>
  <div><div class="w-80"><h6>{{ subscriptionAssigned?.plus ? 1 : 0 }}</h6></div></div>
  <div><div class="w-80"><h6>
    {{ 
      subscription.plans.plusNumber > 0 ? 
        (subscription.plans.plusNumber - (subscriptionAssigned?.plus ? 1 : 0)) : 0 
    }}</h6></div></div>
  </div>
  <div class="subscription-grid">
  <div>
    <div class="checkbox">
      <input type="checkbox" id="advanceManagement" :checked="subscription.plans.advance" disabled />
      <label for="advanceManagement">Basic + Advance</label>
    </div>
  </div>
  <div><div class="w-80"><h6>{{ subscription.plans.advanceNumber }}</h6></div></div>
  <div><div class="w-80"><h6>{{ subscriptionAssigned?.advance ? 1 : 0 }}</h6></div></div>
  <div><div class="w-80"><h6>
    {{ 
      subscription.plans.advanceNumber > 0 ? 
        (subscription.plans.advanceNumber - (subscriptionAssigned?.advance ? 1 : 0)) : 0 
    }}</h6></div></div>
  </div>
  <div class="subscription-grid">
  <div>
    <div class="checkbox">
      <input type="checkbox" id="ultimate" :checked="subscription.plans.ultimate" disabled />
      <label for="ultimate">Basic + ultimate</label>
    </div>
  </div>
  <div><div class="w-80"><h6>{{ subscription.plans.ultimateNumber }}</h6></div></div>
  <div><div class="w-80"><h6>{{ subscriptionAssigned?.ultimate ? 1 : 0 }}</h6></div></div>
  <div><div class="w-80"><h6>
    {{ 
      subscription.plans.ultimateNumber > 0 ? 
        (subscription.plans.ultimateNumber - (subscriptionAssigned?.ultimate ? 1 : 0)) : 0 }}
  </h6></div></div>
  </div>
  </div>

  <div class="content-row">
  <div class="row">
  <div class="col-3">
  <p class="form-label-static">Subscription Amount</p>
  <h6>${{ Number.parseFloat(subscription.totalSubscription || 0).toFixed(2) }}</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">Discount</p>
  <h6>${{ Number.parseFloat(subscription.discount || 0).toFixed(2) }}</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">Taxes</p>
  <h6>${{ Number.parseFloat(subscription.taxes || 0).toFixed(2) }}</h6>
  </div>
  <div class="col-3">
  <p class="form-label-static">Total</p>
  <h6>${{ Number.parseFloat(subscription.total || 0).toFixed(2) }}</h6>
  </div>
  </div>
  </div>

  </div>
  </div> -->

</div>

</div>

</template>

<script>
import { inject, ref, watch, onMounted, reactive } from "vue";
import moment from 'moment';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';
import _ from "lodash";
import UserNotification from "@/components/Shared/UserNotification.vue";


export default {
    name: "SecondaryProfileSubscription",
    props: {
        userType: String,
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const { assigned, subscription, subscriptionMode, stateSubscription, 
        // defaultSubscription, 
        changeSubscriptionField } = inject('subscription');
        const { mainDetails } = inject('mainDetails');
        const { isAuth } = inject('mainUI');
        const subscriptionAssigned = ref({});
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const openedActions = ref(false);
        const editHelperTooltip = ref(false), addHelperTooltip = ref(false), updateHelperTooltip = ref(false), subscriptionTooltip = ref(false);
        const popupMessages = reactive({
            missing: {
                active: false,
                message: 'No subscription available, please purchase by clicking on the Add subscription icon'
            },
        });
        const switchEditHelperTooltip = (toggle) => {
            editHelperTooltip.value = toggle;
        };
        const switchAddHelperTooltip = (toggle) => {
            addHelperTooltip.value = toggle;
        };
        const switchUpdateHelperTooltip = (toggle) => {
            updateHelperTooltip.value = toggle;
        };
        const switchSubscriptionTooltip = (toggle) => {
            subscriptionTooltip.value = toggle;
        };
        const openUpdateForm = () => {
            const parentSource = `${router.currentRoute.value.params.source}`;
            router.push({
                name: 'admin',
                params: {
                    pagename: 'admin-subscription',
                    source: 'secondary-user',
                    parentSource
                }
            });
        };
        const switchToEditable = () => {
            subscriptionMode.value = 'edit';
            console.log(subscriptionMode);
        };
        const missingTimeout = ref(null);
        const handleMissingSubscription = (plan, currentAssigned) => {
            if ((!subscription.plans[plan] && !stateSubscription.plans[plan]) ||
            ((stateSubscription.plans[`${plan}Available`] <= 0) && !currentAssigned)) {
                popupMessages.missing.active = true;
                clearTimeout(missingTimeout.value);
                missingTimeout.value = setTimeout(() => {
                    popupMessages.missing.active = false;
                }, 5100);
            }
        };
        const cancelAssigning = () => {
            for (let prop in assigned) {
                assigned[prop] = false;
            }
        };
        const saveAssigned = () => {
            const selectedUser = store.state.users.find(user => user.mainDetails.id === mainDetails.id);
            const assignedPlan = {};
            // const selectedRow = {};
            for (let prop in assigned) {
                for (let subProp in subscription.plans) {
                    // if (assigned[prop]) {
                    if (subProp.includes(prop)) {
                        if ((subProp.includes('Number') || subProp.includes('Price'))) {
                            assignedPlan[subProp] = subscription.plans[subProp];
                        }
                        else {
                            console.log(`${assignedPlan[subProp]}-${assigned[subProp]}-${subProp}`);
                            assignedPlan[subProp] = assigned[subProp];
                        }
                    }
                    // }
                }
            }
            console.log(assignedPlan);
            store.dispatch('saveSecondaryUserSubscription', {
                section: 'subscriptionAssigned',
                data: { ...assignedPlan }
            });
            if (mainDetails.id) {
                if (!selectedUser.subscription) {
                    const tempSubscription = { ...subscription, plans: { ...subscription.plans } };
                    for (let option in assignedPlan) {
                        for (let plan in tempSubscription.plans) {
                            if (option === plan) {
                                tempSubscription.plans[plan] = assignedPlan[option];
                            }
                        }
                    }
                    console.log(selectedUser, 'changing anyway');
                    store.dispatch('changeNewUser', {
                        userId: mainDetails.id,
                        section: 'subscription',
                        // data: {...tempSubscription}
                        data: {
                            plans: {
                                trial: true,
                                trialPrice: 'price_1O5VljHpD3HYJIrgI5y2Jhkq',
                                basic: false,
                                basicPrice: '',
                                basicNumber: '',
                                basicPriceIdYearly: 'price_1NXMRQHpD3HYJIrg2IwC8sri',
                                basicPriceIdMonthly: 'price_1NXMNxHpD3HYJIrg0hyDtIQO',
                                basicAvailable: '',
                                // plus: false,
                                // plusPrice: '',
                                // plusNumber: '',
                                plus: false,
                                plusPrice: '',
                                plusNumber: '',
                                plusPriceIdYearly: 'price_1NXMQoHpD3HYJIrgJaWbqhOI',
                                plusPriceIdMonthly: 'price_1NXMQoHpD3HYJIrgnhtzXnIF',
                                plusAvailable: '',
                                // advance: false,
                                // advancePrice: '',
                                // advanceNumber: '',
                                advance: false,
                                advancePrice: '',
                                advanceNumber: '',
                                advancePriceIdYearly: 'price_1NXMhHHpD3HYJIrgUwLCM3C2',
                                advancePriceIdMonthly: 'price_1NXMhHHpD3HYJIrgrxWzr1UH',
                                advanceAvailable: '',
                                // ultimate: false,
                                // ultimatePrice: '',
                                // ultimateNumber: '',
                                ultimate: false,
                                ultimatePrice: '',
                                ultimateNumber: '',
                                ultimatePriceIdYearly: 'price_1NXMklHpD3HYJIrgUKaTXGU9',
                                ultimatePriceIdMonthly: 'price_1NXMklHpD3HYJIrgbrsDHSiM',
                                ultimateAvailable: '',
                            },
                            subscriptionPeriod: 'Monthly',
                            totalSubscription: '',
                            taxes: '',
                            discount: '',
                            total: '',
                            planPurchasingDate: store.state.authenticated.registrationDate,
                            subscriptionsNumber: 0
                        }
                    });
                }
                store.dispatch('assignSubscriptionToUser', { assignedPlan: { ...assignedPlan }, userId: mainDetails.id });
                console.log('entering and changing');
                store.dispatch('changeNewUser', {
                    userId: mainDetails.id,
                    section: 'subscriptionAssigned',
                    data: { ...assignedPlan }
                });
                store.dispatch('assignSubscription', { assignedPlan: { ...assignedPlan } });
                // Goes to Action request
                // setTimeout(() => {
                //     // router.push({ name: 'home' });
                //     store.dispatch('clearSecondaryUser');
                // }, 1000);
            }
            openedActions.value = false;
        };
        const reassignLeast = (activeProp) => {
            for (let prop in assigned) {
                if (prop !== activeProp)
                    assigned[prop] = false;
            }
        };
        const toggleSection = (ev) => {
            if ((ev.target.className === 'info-icon edit-subscription') || (ev.target.tagName === 'IMG')) {
                return;
            }
            sectionExpanded.value = !sectionExpanded.value;
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });
        watch(() => _.cloneDeep(assigned), (newVal, oldVal) => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            console.log(newVal, oldVal);
            for (let prop in newVal) {
                if (newVal[prop]) {
                    for (let subProp in subscription.plans) {
                        if (newVal[prop] && !oldVal[prop]) {
                            if (subProp.includes(prop) && subProp.includes('Number')) {
                                // subscription.plans[subProp] = subscription.plans[subProp] - 1;
                            }
                        }
                    }
                }
                if (!newVal[prop] && oldVal[prop]) {
                    console.log(prop, newVal[prop]);
                    for (let subProp in subscription.plans) {
                        // if (newVal[prop]) {
                        if (subProp.includes(prop) && subProp.includes('Number')) {
                            // subscription.plans[subProp] = subscription.plans[subProp] + 1;
                        }
                        // }
                    }
                }
            }
        });
        const switchActions = () => {
            openedActions.value = true;
        };
        onMounted(() => {
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // if (store.state.secondaryUser?.subscription?.submitted) {
            /*
            if (store.state.secondaryUser?.subscription) {
              for (let prop in subscription) {
                if (prop === 'plans') {
                  for (let plan in subscription.plans) {
                    subscription.plans[plan] = store.state.secondaryUser?.subscription?.plans[plan];
                  }
                } else {
                  subscription[prop] = store.state.secondaryUser?.subscription[prop];
                  // changeSubscriptionField(store.state.secondaryUser?.subscription[prop], prop);
                }
              }
              changeSubscriptionField(true, 'submitted');
              changeSubscriptionField('represent', 'mode');
            }
            */
            for (let plan in store.state.secondaryUser.subscriptionAssigned) {
                for (let option in assigned) {
                    if ((option === plan) && store.state.secondaryUser.subscriptionAssigned[plan]) {
                        assigned[option] = true;
                        openedActions.value = false;
                    }
                }
            }
            subscriptionAssigned.value = assigned;
        });
        return {
            moment,
            subscription,
            stateSubscription,
            changeSubscriptionField,
            assigned,
            cancelAssigning,
            saveAssigned,
            openedActions,
            switchActions,
            editHelperTooltip,
            addHelperTooltip,
            updateHelperTooltip,
            switchEditHelperTooltip,
            switchAddHelperTooltip,
            switchUpdateHelperTooltip,
            openUpdateForm,
            switchToEditable,
            subscriptionTooltip,
            switchSubscriptionTooltip,
            isAuth,
            section,
            boxHeader,
            toggleSection,
            reassignLeast,
            popupMessages,
            handleMissingSubscription
        };
    },
    components: { UserNotification }
};
</script>

<style lang="scss" scoped>
.section {
  .edit-subscription {
    position: relative;
  }
}
.subscription-plan-expired {
  display: flex;
  flex-direction: column;
  color: rgba(40,41,43,0.6);
  font-size: 14px;
  margin-left: 15px;
  .runs-out {
    font-weight: 700;
    color: rgba(40,41,43,1);
    font-size: 16px;
  }
}
.subscription-section {
  box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
  .prepended-actions {
    display: none;
  }
  .header {
    font-size: 18px;
    color: #28292B;
    margin-left: 15px;
  }
  .subscription-card-section {
    padding: 24px;
    padding-top: 16px;
  }
  .header-buttons {
    display: flex;
    .edit-subscription,
    .add-subscription,
    .update-subscription {
      display: flex;
      text-indent: -9999px;
      mask-position: center;
      margin-left: auto;
      width: 25px;
      height: 25px;
      background-image: url('@/assets/profile/edit_button.png');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      position: relative;
      &.add-subscription {
        background-image: url('@/assets/profile/add-subscription.png');
        margin-left: 5px;
      }
      &.update-subscription {
        background-image: url('@/assets/profile/update_button.svg');
        margin-left: 5px;
      }
      .helper-tooltip {
        width: 130px;
        height: 30px;
        position: absolute;
        z-index: 999;

        background-color: #3C3838;
        color: #fff;
        top: 40px;
        left: -45px;
        text-indent: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        &::after {
          content: " ";
          position: absolute;
          display: block;
          top: -7px;
          border-color: #3C3838;
          border-left: 8px solid hsl(0, 3%, 23%);
          border-bottom: 6px solid #3C3838;
          border-right: 6px solid transparent;
          border-top: 8px solid transparent;
          -webkit-transform: translateX(-50%) rotate(135deg);
          transform: translateX(-50%) rotate(135deg);
          box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
          left: 61px;
          background: #3C3838;
          z-index: -1;
        }
      }
    }
  }
  .basic-row {
    margin-top: -20px;
    .subscriptions-number {
      border: 1px solid rgba(40,41,43,0.6);
      border-radius: 5px;
      padding: 3px;
      margin: 0 5px 0 10px;
    }
  }
  .allinclusive-row {
    margin-top: -20px;
    .subscriptions-number {
      border: 1px solid rgba(40,41,43,0.6);
      border-radius: 5px;
      padding: 3px;
      margin: 0 5px 0 15px;
    }
  }

  .row {
    &.wrapper {
      justify-content: space-around;
      height: 70px;
      margin-left: 15px;
      &.assign {}
      &.modules-header {
        justify-content: flex-start;
        font-size: 16px;
        font-weight: 600;
        height: max-content;
        margin-top: 10px;
      }
      .field {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 174px;
        margin-top: 20px;
 
        .label {
          color: rgba(40,41,43,0.6);
          // margin-bottom: 10px;
          // font-size: 14px;
          font-size: 16px;
          font-weight: 600;
        }
        .field-value {
          // font-size: 14px;
          font-size: 16px;
          color: rgba(40,41,43,1);
          font-weight: 600;
          &.plan-check {
            margin-left: -15px;
            .assign-checkbox {
              margin-left: 20px;
            }
          }
        }
        .yearly-disclaimer {
          // font-size: 8px;
          font-size: 9px;
          line-height: 9px;
        }
      }
      &.result-line {
        display: none;
      }
    }
  }
  .subscription-tooltip-container {
    // display: flex;
    display: none;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 34px;
    bottom: 14px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('@/assets/profile/help_icon.png');
    &.opened {
      bottom: -34px;
    }
    .tooltip {
      position: absolute;
      z-index: 99999;
      bottom: 40px;
      left: -245px;
      width: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 11px;
      color: #fff;
      background-color: #3C3838;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      &::after {
        content: " ";
        position: absolute;
        display: block;
        bottom: -7px;
        border-color: #3C3838;
        border-left: 8px solid hsl(0, 3%, 23%);
        border-bottom: 6px solid #3C3838;
        border-right: 6px solid transparent;
        border-top: 8px solid transparent;
        -webkit-transform: translateX(190px) rotate(135deg);
        transform: translateX(190px) rotate(135deg);
        box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
        left: 61px;
        background: #3C3838;
        z-index: -1;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .subscription-section {
    .prepended-actions {
      display: flex;
    }
    .appended-actions {
      display: none;
    }
    .row {
      &.wrapper {
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 793px) {
  .subscription-section {
    .row {
      &.wrapper {
        .field {
          max-width: 50%;
          &.checkbox-field {
            max-width: none;
          }
        }
        &.result-line {
          display: flex;
          width: calc(100% - 12px);
          height: 1px;
          background-color: #28292B;
        }
      }
    }
    .form-actions {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      button {
        width: calc(50% - 20px);
        margin: auto;
      }
    }
  }
}

.q-expansion-item__container {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
</style>
