<template>
  <section class="section">
  <div class="border-box">
  <div class="border-box-content">

  <div class="content-block d-flex justify-content-between">
  <h5 class="fw-semibold">Customer</h5>
  <div class="icon-group">
    <!-- <div class="icon green icon-o tooltip"
      @mouseover="switchHelperTooltip(true)"
      @mouseout="switchHelperTooltip(false)"
      data-tooltip="Camera"
    >
      <img src="@/assets/images/updated/camera-white.png" alt="">
    </div> -->
    <div class="icon green icon-o lg top-icon tooltip"
        @click="addSales"
        @mouseover="switchHelperTooltip(true)"
        @mouseout="switchHelperTooltip(false)"
        data-tooltip="Add Sales"
        v-if="isAuth('add-new-sales')"
      >
      <img src="@/assets/images/updated/Sales.png" alt="">
    </div>
    <div class="icon green icon-o tooltip"
      @click="selectCustomer({...customer}, 'edit');"
      @mouseover="switchEditHelperTooltip(true)"
      @mouseout="switchEditHelperTooltip(false)"
      data-tooltip="Edit"
      v-if="isAuth('edit-customer')"
    >
      <img src="@/assets/images/updated/Edit.png" alt="">
      <!-- <div class="edit-helper-tooltip" v-if="editHelperTooltip">Edit Sales</div> -->
    </div>
  </div>
  </div>

  <div class="content-block">
  <div class="intro-grid">

  <div class="intro-grid-column">
  <p class="title">Type</p>
  <h6>{{ customer.type }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Customer</p>
  <h6>{{ customer.customer }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Contact</p>
  <h6>{{ customer.contact }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Work Phone</p>
  <h6>{{ customer.workPhone }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Mobile</p>
  <h6>{{ customer.mobile }}</h6>
  </div>
 
  <div class="intro-grid-column">
  <p class="title">Email</p>
  <h6>{{ customer.email }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Address 1</p>
  <h6>{{ customer.address1 }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Address 2</p>
  <h6>{{ customer.address2 }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">City</p>
  <h6>{{ customer.city }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">State</p>
  <h6>{{ customer.state }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Zip</p>
  <h6>{{ customer.zip }}</h6>
  </div>

  <div class="intro-grid-column">
  <p class="title">Country</p>
  <h6>{{ customer.country }}</h6>
  </div>

  <div class="intro-grid-column intro-grid-comment-column">
  <p class="title">Comments</p>
  <h6>{{ customer.comments }}</h6>
  </div>

  </div>
  </div>

  <div class="content-block">

    <FileAttachments v-if="isAuth('customer-attachements')" :key="`customer-filled-${customer.id}`" v-bind:filled="true" v-bind:section="'customer'" v-bind:instance="`${customer.id}`" />

  </div>

  </div>
  </div>
  </section>
</template>

<script>
import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { defineComponent, inject, ref } from 'vue';

export default defineComponent({
    name: "CustomersFilled",
    setup() {
      const { isAuth } = inject('mainUI');
      const { customer, selectCustomer, addCustomer } = inject('customerDetails');
      const { addSales } = inject('salesDetails');

      const editHelperTooltip = ref(false);
      const helperTooltip = ref(false);

      const switchHelperTooltip = (toggle) => {
        helperTooltip.value = toggle;
      }

      const switchEditHelperTooltip = (toggle) => {
        editHelperTooltip.value = toggle;
      }  
      
      return {
        customer,
        addCustomer,
        selectCustomer,
        editHelperTooltip,
        helperTooltip,
        switchHelperTooltip,
        switchEditHelperTooltip,
        addSales,
        isAuth
      }
    },
    components: { FileAttachments }
})
</script>

<style lang="scss" scoped>
.edit-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 40px;
  left: -30px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 41px;
    background: #3C3838;
    z-index: -1;
  }
}
</style>