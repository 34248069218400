<template>
  <section class="section" ref="section">
<div class="border-box active">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Vendor List</h5>
<div class="info-icons">
<div class="info-icon lg top-icon tooltip"
  @click="addVendor"
  @mouseover="switchHelperTooltip(true)"
  @mouseout="switchHelperTooltip(false)"
  data-tooltip="Add Vendor"
  v-if="isAuth('add-new-vendor')"
>
  <img src="@/assets/images/updated/Vendors.png" alt="">
  <!-- <div class="helper-tooltip" v-if="helperTooltip">Add Vendor</div> -->
</div>
</div>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-wrapper">
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label"><span>Vendor</span>
  <span @click="() => sortTable('vendor')">
    <button :class="{active: sortOrder.vendor === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.vendor === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<!-- <th><p class="form-label-static form-sort-label">
  <span>Category</span>
  <span  @click="() => sortTable('category')">
  <button :class="{active: sortOrder.category === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.category === 1}"><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th> -->
<th><p class="form-label-static form-sort-label">
  <span>Contact</span>
  <span  @click="() => sortTable('contact')">
  <button :class="{active: sortOrder.contact === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.contact === 1}"><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Vendor Phone</span>
  <span  @click="() => sortTable('phone')">
  <button :class="{active: sortOrder.vendorPhone === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.vendorPhone === 1}"><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>City</span>
  <span  @click="() => sortTable('city')">
  <button :class="{active: sortOrder.city === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.city === 1}"><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>State</span>
  <span  @click="() => sortTable('state')">
  <button :class="{active: sortOrder.state === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.state === 1}"><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Status</span>
  <span  @click="() => sortTable('status')">
  <button :class="{active: sortOrder.status === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
  <button :class="{active: sortOrder.status === 1}"><img src="@/assets/images/caret-down.png" alt=""></button></span></p></th>
<th class="w-75">&nbsp;</th>
</tr>
</thead>

<tbody>

  <template
    v-for="(row, rowIndex) in rows"
    v-bind:key="'vendor-list-row' + row.id"
  >
    <tr
      v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
      :class="{'light-gray-bg': row.id === selectedVendor.id}"
      @click="(ev) => selectByRow(ev, {...row})"
    >
    <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
    <td  class="text-center">{{ row.vendor }}</td>
    <!-- <td>{{row.category}}</td> -->
    <td>{{ row.vendorContact }}</td>
    <td>{{ row.vendorPhone }}</td>
    <td>{{ row.city }}</td>
    <td>{{ row.state }}</td>
    <td>{{ row.status }}</td>
    <td class="w-75">
    <div class="table-links">
    <a v-if="isAuth('edit-vendor')" href="javascript:void();" @click="selectVendor({...row}, 'edit')">
      <img src="@/assets/images/edit-icon-2.svg" alt="">
    </a>
    <a v-if="(row.status === 'Active') && isAuth('remove-vendor')" href="javascript:void();" @click="removeSelectedVendor(row.id)">
      <img src="@/assets/images/trash-2.svg" alt="">
    </a>
    <a v-if="isAuth('call-vendor')" href="javascript:void();" @click="callVendor(row.id, row.vendorPhone)">
      <img src="@/assets/images/phone-icon.svg" alt="">
    </a>
    </div>
    </td>
    </tr>
  </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'vendorListDetails'"
    :optionKey="'vendor-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>

</div>
</section><!--//End Section-->

<div class="removal-popup" v-if="removedVendorId">

<div class="modal modal-md">
<span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
<div class="modal-block text-center">
<p class="fw-bold">Are you sure you want to delete the vendor?</p>
</div>
<div class="modal-block text-center">
<div class="buttons justify-content-center">
    <button
      @click="() => approveRemoval(true)"
      class="approve-button btn green" 
    >Yes</button>
    <button
      @click="() => approveRemoval(false)" 
      class="cancel-removal-button btn green btn-o" 
    >No</button>
</div>
</div>
</div>

</div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { ref, onMounted, watch, computed, inject, reactive, provide } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: "VendorList",
    props: ['archived'],
    setup(props) {
        const store = useStore();
        const router = useRouter();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const vendors = props.archived ?
            computed(() => [...store.state.vendors].filter(stateVendor => stateVendor.status === 'Inactive')) :
            computed(() => [...store.state.vendors].filter(stateVendor => stateVendor.status === 'Active'));
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const { isAuth } = inject('mainUI');
        const { selectedVendor, addVendor, editVendor, selectVendor, removeVendor } = inject('vendorDetails');
        const sortOrder = reactive({
            category: 0,
            contact: 0,
            vendorPhone: 0,
            city: 0,
            state: 0
        });
        const helperTooltip = ref(false);
        const removedVendorId = ref(null);
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...vendors.value];
                sortOrder[sortProp] = 0;
            }
        };
        const approveRemoval = (isApproved) => {
            if (isApproved) {
                removeVendor(removedVendorId.value);
            }
            removedVendorId.value = '';
        };
        const removeSelectedVendor = (vendorId) => {
            removedVendorId.value = vendorId;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const callVendor = (id, phone) => {
            console.log(id, phone);
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectVendor({ ...row }, 'represent');
            }
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(() => ([...store.state.vendors]), () => {
          // store.dispatch('getVendors');
            console.log(store.state.vendors, 'vendors');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = //[...store.state.vendors];
              props.archived ?
                [...store.state.vendors].filter(stateVendor => stateVendor.status === 'Inactive') :
                [...store.state.vendors].filter(stateVendor => stateVendor.status === 'Active');
            rows.value.forEach(row => {
                if (row.product)
                    row.category = 'Product';
                if (row.serviceType)
                    row.category = 'Services';
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
          store.dispatch('getVendors');
          if (router.currentRoute.value.params.source !== 'admin-dashboard') {
            (vendors.value.length > 0) && selectVendor({ ...vendors.value[0] }, 'represent');
          }
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getVendors')
            rows.value = //[...vendors.value];
              props.archived ?
                [...store.state.vendors].filter(stateVendor => stateVendor.status === 'Inactive') :
                [...store.state.vendors].filter(stateVendor => stateVendor.status === 'Active');
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        provide('vendorListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            addVendor,
            editVendor,
            selectVendor,
            removeVendor,
            rows,
            maxPage,
            currentPage,
            selectedVendor,
            callVendor,
            helperTooltip,
            switchHelperTooltip,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            removedVendorId,
            approveRemoval,
            removeSelectedVendor,
            selectByRow,
            isAuth
        };
    },
    components: { DropDownList }
}
</script>

<style lang="scss" scoped>
.top-icon {
  position: relative;
}
</style>