<template>
  <div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Inventory Used: {{ inventoryUsed.input }}</h5>
<div class="icon-group">
<div class="icon green icon-o top-icon tooltip"
  @click="selectInventoryUsed({...inventoryUsed}, 'edit');"
  data-tooltip="Edit"
  v-if="isAuth('edit-inventory-used')"
>
  <img src="@/assets/images/updated/Edit.png" alt="">
</div>
</div>
</div>

<div class="content-block">
<div class="intro-grid">

<div class="intro-grid-column">
<p class="title">Date</p>
<h6>{{ inventoryUsed.date }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Farm</p>
<h6>{{ inventoryUsed.farm }}</h6>
</div>

<div class="intro-grid-column" v-if="inventoryUsed.bed">
<p class="title">Bed</p>
<h6>{{ inventoryUsed.bed }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Season</p>
<h6>{{ inventoryUsed.season }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Crop Cycle</p>
<h6>{{ inventoryUsed.cropCycle }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Crop</p>
<h6>{{ inventoryUsed.crop }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Task</p>
<h6>{{ inventoryUsed.task }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Assigned To</p>
<h6>{{ inventoryUsed.assignedTo }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Input</p>
<h6>{{ inventoryUsed.input }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Brand</p>
<h6>{{ inventoryUsed.brand }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Variety</p>
<h6>{{ inventoryUsed.variety }}</h6>
</div>

<div class="intro-grid-column">
<p class="title"># of Items</p>
<h6>{{ inventoryUsed.numberOfItems }}</h6>
</div>

<div class="intro-grid-column">
<p class="title">Weight (lbs/item)</p>
<h6>{{ inventoryUsed.quantity }}</h6>
</div>

</div>
</div>

<div class="content-block">

  <!-- <FileAttachments v-bind:section="'inventory'" /> -->

<!-- <div class="content-section">
<div class="buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o">Cancel</button></div>
</div>
</div>
</div> -->
</div>

</div>
</template>

<script>
// import FileAttachments from '@/components/Shared/FileAttachments.vue';
import { inject, ref } from 'vue';

export default {
  name: "InventoryUsedFilled",
  setup() {
    const { isAuth } = inject('mainUI');
    const { inventoryUsed, selectInventoryUsed } = inject('inventoryUsedDetails');

    const editHelperTooltip = ref(false);
    const helperTooltip = ref(false);

    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle;
    }

    const switchEditHelperTooltip = (toggle) => {
      editHelperTooltip.value = toggle;
    }

    return {
      inventoryUsed,
      selectInventoryUsed,
      editHelperTooltip,
      helperTooltip,
      switchHelperTooltip,
      switchEditHelperTooltip,
      isAuth
    }
  },
  // components: { FileAttachments }
}
</script>

<style lang="scss" scoped>
.edit-helper-tooltip {
  width: max-content;
  padding: 0 15px;
  height: 30px;
  position: absolute;
  z-index: 999;

  background-color: #3C3838;
  color: #fff;
  top: 40px;
  left: -30px;
  text-indent: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;

  &::after {
    content: " ";
    position: absolute;
    display: block;
    top: -7px;
    border-color: #3C3838;
    border-left: 8px solid hsl(0, 3%, 23%);
    border-bottom: 6px solid #3C3838;
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(-50%) rotate(135deg);
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 41px;
    background: #3C3838;
    z-index: -1;
  }
}
</style>