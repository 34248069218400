<template>
    <VueCal 
      @cell-click="($event) => openAddTask($event)"
      :events="events"
      hide-view-selector
      :time="false"
      active-view="month"
      xsmall
    />
</template>

<script>
import moment from 'moment';
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'CalendarWidget',
  components: { VueCal },
  setup() {

    const store = useStore();

    const router = useRouter();

    const tasks = computed(() => store.state.tasks);

    const events = ref([]);

    const { calendarWidgetExpanded, calendarSectionExpanded } = inject('calendarDetails');

    const openAddTask = (eventDate) => {

      const formattedDate = moment(eventDate);

      const date = formattedDate.format('YYYY-MM-DD');

      // const startTime = `${formattedDate.get('hour')}:${
      //   (formattedDate.get('minutes') >= 15) ? '30' : '00'
      // } ${
      //   (formattedDate.get('hour') >= 12) ? 'PM' : 'AM'
      // }`

      router.push({
        name: 'farm',
        params: {
          pagename: 'task',
          date
          // ,startTime
        }
      });

      calendarWidgetExpanded.value = 'released'
      calendarSectionExpanded.value = false;

    }

    watch(store.state.tasks, (newVal) => {
      if (newVal?.length > 0) {
        events.value = tasks.value.map(task => {
          return {
            // start: `${moment(task.date).format('YYYY-MM-DD')} ${task.startTime}`,
            start: `${moment(`${task.date} ${task.startTime}`).format('YYYY-MM-DD hh:mm a')}`,
            // end: task.completionTime ? `${moment(task.date).format('YYYY-MM-DD')} ${task.completionTime}` : '',
            end: task.completionTime ? 
              `${moment(`${task.date} ${task.completionTime}`).format('YYYY-MM-DD hh:mm a')}` : 
              `${moment(`${task.date} ${
                (task.startTime[1] === ':') ? 
                  `${Number.parseInt(task.startTime[0]) + 1}${task.startTime[1]}${task.startTime[2]}${task.startTime[3]}` :
                  `${task.startTime[0]}${Number.parseInt(task.startTime[1]) + 1}${task.startTime[2]}${task.startTime[3]}${task.startTime[4]}`
              }`).format('YYYY-MM-DD hh:mm a')}`,
            title: task.task,
            taskId: task.id,
            // content: '<i class="icon material-icons">block</i><br>I am not draggable, not resizable and not deletable.',
            class: 'task-event',
            deletable: false,
            resizable: false,
            draggable: false
          }
        });
      }
    })

    onMounted(() => {
      if (tasks.value.length > 0) {
        events.value = [...tasks.value].map(task => {
          return {
            // start: `${moment(task.date).format('YYYY-MM-DD')} ${task.startTime}`,
            start: `${moment(`${task.date} ${task.startTime}`).format('YYYY-MM-DD hh:mm a')}`,
            // end: task.completionTime ? `${moment(task.date).format('YYYY-MM-DD')} ${task.completionTime}` : '',
            end: task.completionTime ? 
              `${moment(`${task.date} ${task.completionTime}`).format('YYYY-MM-DD hh:mm a')}` : 
              `${moment(`${task.date} ${
                (task.startTime[1] === ':') ? 
                  `${Number.parseInt(task.startTime[0]) + 1}${task.startTime[1]}${task.startTime[2]}${task.startTime[3]}` :
                  `${task.startTime[0]}${Number.parseInt(task.startTime[1]) + 1}${task.startTime[2]}${task.startTime[3]}${task.startTime[4]}`
              }`).format('YYYY-MM-DD hh:mm a')}`,
            title: task.task,
            taskId: task.id,
            // content: '<i class="icon material-icons">block</i><br>I am not draggable, not resizable and not deletable.',
            class: 'task-event',
            deletable: false,
            resizable: false,
            draggable: false
          }
        });
      }

    })

    return {
      events,
      openAddTask
    }
  }
})
</script>