const options = [
  'Seeds',
  'Organic Seeds',
  'Pesticide',
  'Organic Pesticide',
  'Insecticides',
  'Organic Insecticides',
  'Fungicide',
  'Organic Fungicide',
  'Soil',
  'Organic Soil',
  'Sacks',
  'Crates',
  'Pallet Boxes',
  'Baskets'
];


const list = ['a', 'b', 'c'];

const dList = [{o: 'a'}, {o: 'd'}];

const rList = [...list, ...dList.map(item => {
  if (!list.includes(item.o)) {
    return item.o
  }
}).filter(o => o)];

console.log(rList);

export default options;
