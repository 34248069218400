<template>
  <q-expansion-item
    class="overflow-hidden rounded-borders"
    style="border-radius: 10px"
    popup
    :header-style="{
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      fontWeight: 700,
    }"
    default-opened
    label="Add Account Details"
  >
    <q-card class="additional-details">
      <q-card-section class="flex">
        <div class="media">
          <div
            class="preview"
            :style="{
              backgroundImage: imagePreview,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }"
          ></div>
          <q-btn outline style="color: bhoomi" label="Upload Image">
            <input
              type="file"
              name="avatarupload"
              class="avatar-file"
              ref="uploadavatar"
              accept=".jpg,.png,.jpeg"
              @change="uploadImgDirect"
            />
          </q-btn>
          <q-btn outline style="color: bhoomi" label="Upload Video">
            <input
              type="file"
              name="avataruploadvid"
              class="avatar-file"
              ref="uploadavatarvid"
              accept=".mpeg,.mp4,.wmv,.mkv"
              @change="uploadVideoDirect"
            />
          </q-btn>
        </div>
        <div class="main-form">
          <div class="row wrapper">
            <q-field
              borderless
              label="App Role"
              stack-label
              color="bhoomi"
              style="width: 100%; max-width: 400px"
            >
              <template v-slot:control>
                <div
                  class="flex"
                  style="
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    width: 100%;
                  "
                >
                  <q-checkbox color="bhoomi" v-model="adminRole" label="Admin:" />
                  <q-checkbox color="bhoomi" v-model="editRole" label="Edit:" />
                  <q-checkbox
                    color="bhoomi"
                    v-model="contributeRole"
                    label="Contribute:"
                  />
                  <q-checkbox color="bhoomi" v-model="readRole" label="Read:" />
                </div>
              </template>
            </q-field>
          </div>

          <div class="row wrapper">
            <q-field
              borderless
              label="First Name"
              stack-label
              color="bhoomi"
              style="max-width: 200px; width: 100%"
            >
              <template v-slot:control>
                <q-input
                  outlined
                  v-model="firstName"
                  color="bhoomi"
                  placeholder="Enter Here"
                />
              </template>
            </q-field>

            <q-field
              borderless
              label="MI"
              stack-label
              color="bhoomi"
              style="max-width: 200px; width: 100%"
            >
              <template v-slot:control>
                <q-checkbox color="bhoomi" v-model="mi" label="Yes" />
              </template>
            </q-field>

            <q-field
              borderless
              label="Last Name"
              stack-label
              color="bhoomi"
              style="max-width: 200px; width: 100%"
            >
              <template v-slot:control>
                <q-input
                  outlined
                  v-model="lastName"
                  color="bhoomi"
                  placeholder="Enter Here"
                />
              </template>
            </q-field>
          </div>

          <div class="row wrapper">
            <q-field
              borderless
              label="Address 1"
              stack-label
              color="bhoomi"
              style="max-width: 200px; width: 100%"
            >
              <template v-slot:control>
                <q-input
                  outlined
                  v-model="addressFirst"
                  color="bhoomi"
                  placeholder="Enter Here"
                />
              </template>
            </q-field>

            <q-field
              borderless
              label="Address 2"
              stack-label
              color="bhoomi"
              style="max-width: 200px; width: 100%"
            >
              <template v-slot:control>
                <q-input
                  outlined
                  v-model="addressSecond"
                  color="bhoomi"
                  placeholder="Enter Here"
                />
              </template>
            </q-field>
          </div>

          <div class="row wrapper">
            <q-field
              borderless
              label="City"
              stack-label
              color="bhoomi"
              style="max-width: 200px; width: 100%"
            >
              <template v-slot:control>
                <q-input
                  outlined
                  v-model="city"
                  color="bhoomi"
                  placeholder="Enter Here"
                />
              </template>
            </q-field>

            <q-field
              borderless
              label="State"
              stack-label
              color="bhoomi"
              style="max-width: 200px; width: 100%"
            >
              <template v-slot:control>
                <q-input
                  outlined
                  v-model="state"
                  color="bhoomi"
                  placeholder="Enter Here"
                />
              </template>
            </q-field>
          </div>

          <div class="row wrapper">
            <q-field
              borderless
              label="ZIP"
              stack-label
              color="bhoomi"
              style="max-width: 200px; width: 100%"
            >
              <template v-slot:control>
                <q-input
                  outlined
                  v-model="zip"
                  color="bhoomi"
                  placeholder="Enter Here"
                />
              </template>
            </q-field>

            <q-field
              borderless
              label="Country"
              stack-label
              color="bhoomi"
              style="max-width: 200px; width: 100%"
            >
              <template v-slot:control>
                <q-input
                  outlined
                  v-model="country"
                  color="bhoomi"
                  placeholder="Enter Here"
                />
              </template>
            </q-field>
          </div>

          <div class="row wrapper">
            <q-field
              borderless
              label="Work Phone"
              stack-label
              color="bhoomi"
              style="max-width: 200px; width: 100%"
            >
              <template v-slot:control>
                <q-input
                  outlined
                  v-model="workPhone"
                  color="bhoomi"
                  placeholder="Enter Here"
                />
              </template>
            </q-field>
          </div>
        </div>

        <div class="form-actions">
          <div class="row wrapper buttons">
            <q-btn class="bg-bhoomi" style="color: white" label="Save" />
            <q-btn outline class="text-bhoomi" label="Edit" />
          </div>
          <q-btn outline class="text-bhoomi" label="+ Add Additional Users" />
          <q-btn outline class="text-bhoomi" label="+ Add Farm Details" />

          <q-btn outline class="text-bhoomi" label="Additional Users" />
        </div>
      </q-card-section>
    </q-card>
  </q-expansion-item>
</template>

<script>
import { ref } from "vue"; //computed
import { useStore } from "vuex";

export default {
  name: "ProfileAccountDetails",
  setup() {
    const store = useStore();

    const dynamicPreviewName = ref(require("@/assets/profile/user-avatar.png")); // take from store by id
    const imagePreview = ref(`url(${dynamicPreviewName.value})`);

    const uploadImgDirect = (ev) => {
      console.log(ev);
      store.dispatch("uploadMedia", {
        file: ev.target.files[0],
        place: "avatar",
      });
      createPreview(ev);
    };

    const uploadVideoDirect = (ev) => {
      console.log(ev);
      store.dispatch("uploadMedia", {
        file: ev.target.files[0],
        place: "avatar",
      });
      createPreview(ev);
    };

    const createPreview = (e) => {
      const reader = new FileReader();
      // let fileName = e.target.value.split("\\");
      // fileName = fileName[fileName.length - 1].split(".")[0];

      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
      reader.onload = function () {
        setTimeout(() => {
          imagePreview.value = `url(${reader.result})`;
          console.log(reader.result);
        }, 500);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    };

    return {
      adminRole: ref(false),
      editRole: ref(false),
      contributeRole: ref(false),
      readRole: ref(false),

      firstName: ref(""),
      mi: ref(false),
      lastName: ref(""),

      addressFirst: ref(""),
      addressSecond: ref(""),

      imagePreview,
      uploadImgDirect,
      uploadVideoDirect,
    };
  },
};
</script>

<style lang="scss">
.additional-details {
  .main-form {
    width: 75%;
  }
  .media {
    width: 25%;
    .preview {
      width: 10vw;
      height: 10vw;
      border-radius: 50%;
    }
    button {
      margin-top: 24px;
    }
    .avatar-file {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
  .row {
    &.wrapper {
      width: 100%;
      justify-content: space-between;
      label {
        width: 100%;
        max-width: 200px;
      }
      &.buttons {
        max-width: 200px;
        margin-bottom: 24px;
      }
    }
  }
}
</style>
