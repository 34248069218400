<template>
  <div v-if="scheduledTask" class="notification-point"></div>
</template>

<script>
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'NotificationWidget',
  setup() {

    const store = useStore();

    const tasks = computed(() => store.state.tasks);

    const notificationsList = computed(() => store.state.notifications); // receive from inject

    const scheduledTask = ref(false);

    const createSchedulers = () => {
      // let scheduleTimer;
      // clearTimeout(scheduleTimer);
      tasks.value.forEach(task => {
        console.log(task);
        if (task.taskStatus !== 'Completed') {
          if (task.reminder && !task.reminderIsSet) {
            const reminderTime = task.reminderTime;
            // reminderTime[2] === 'd' || 'w' convert to time

            let remainingTime;

            if (reminderTime[2] === 'd') {
              remainingTime = moment(`${task.date} ${task.startTime}`).subtract(reminderTime[0], 'days').format('YYYY-MM-DD hh:mm a')
              // remainingTime = moment(task.creationDate).add(reminderTime[0], 'days').format('YYYY-MM-DD hh:mm a')
            }

            if (reminderTime[2] === 'w') {
              remainingTime = moment(`${task.date} ${task.startTime}`).subtract(reminderTime[0], 'weeks').format('YYYY-MM-DD hh:mm a')
            }

            if (reminderTime[1] === '0') {
              remainingTime = moment(`${task.date} ${task.startTime}`).subtract(`${reminderTime[3]}${reminderTime[4]}`, 'minutes').format('YYYY-MM-DD hh:mm a')
            }

            if (reminderTime[3] === '0') {
              remainingTime = moment(`${task.date} ${task.startTime}`).subtract(reminderTime[1], 'hours').format('YYYY-MM-DD hh:mm a')
            }

            console.log(remainingTime);

            // const notificationTime = Math.abs(moment().diff(remainingTime));
            const currentTime = moment().format('YYYY-MM-DD hh:mm a');

            console.log(currentTime);

            const notificationTime = moment(remainingTime, 'YYYY-MM-DD hh:mm a').diff(moment(currentTime, 'YYYY-MM-DD hh:mm a'));

            console.log(notificationTime);

            // scheduleTimer = 
            setTimeout(() => {
              scheduledTask.value = true;
              store.dispatch('addNotification', {
                ...task,
                taskId: task.id,
                id: uuid(),
                remainingTime: `${remainingTime}`,
                isRead: false
              })
            }, notificationTime)

            store.dispatch('editTask', {
              id: task.id,
              reminderIsSet: true
            })
          }
        }
      })
    }

    watch(store.state.tasks, () => createSchedulers());

    watch(store.state.notifications, () => {
      scheduledTask.value = notificationsList.value.some(notification => !notification.isRead)
    })

    onMounted(() => {
      createSchedulers()
    })

    return {
      scheduledTask
    }
  }
})
</script>

<style>
.notification-point {
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: #f44336;
  border-radius: 1rem;
  left: 0;
  top: 1.8rem;
}
</style>