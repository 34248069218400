<template>

<section class="section" ref="section">
<div class="border-box">

<div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Payment Information</h5>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

  <div class="content-block">
    <!-- <div class="payment-title">
      <div class="add-card-icon"></div>
      <p class="add-card-text">Add a Card</p>
    </div> -->
    <template v-if="secretIsLoaded">
      <StripeElements
        v-if="stripeLoaded"
        v-slot="{ elements }"
        ref="elms"
        :stripe-key="stripeKey"
        :instance-options="instanceOptions"
        :elements-options="elementsOptions"
      >
        <div class="payment-title">
          <div class="add-card-icon"></div>
          <p class="add-card-text">Add a Card</p>
        </div>
        <label class="name-on-card">
          <span>Name on card (required field)</span>
          <input v-model="cardName" type="text" placeholder="" :instance="instance" :class="{nameInvalid: (cardName.length === 0) && activated.cardName}" />
          <p v-if="(cardName.length === 0) && activated.cardName" class="stripe-error">Name of Card required</p>
        </label>
        <StripeElement
          type="payment"
          ref="payment"
          :elements="elements"
          :options="cardOptions"
          @change="checkPaymentType"
          @ready="loadedStripeForm"
        />
      </StripeElements>
    </template>  
  </div>

<!-- <div class="content-block">
<div class="checkbox checkbox-lg"><input type="checkbox" id="savePaymentInfo" v-model="payment.saveForFuture"><label for="savePaymentInfo">Save Payment Information for future use</label></div>
</div> -->

<div class="content-block">
<div class="buttons form-buttons">
<div class="row">
<div class="col-auto"><button type="submit" class="btn green" @click="submitStripeForm">Save</button></div>
<div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
</div>
</div>
</div>

</div>

</div>
</section>
</template>

<script>
import { defineComponent, ref, reactive, watch, inject, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { loadStripe } from '@stripe/stripe-js'
import { StripeElements, StripeElement } from 'vue-stripe-js'

export default defineComponent({
    name: "ProfilePaymentForm",
    props: {
        userType: String,
    },
    setup() {
        const store = useStore();

        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);

        const activated = reactive({
          cardName: false
        })

        const { collectPayment, cardName, confirmPayment, confirmSetup, createPaymentMethod } = inject('stripeProps');
        
        const { subscription, defaultSubscription } = inject('subscription')

        const toggleSection = () => {
          sectionExpanded.value = !sectionExpanded.value;
        };

        const stripeKey = ref(process.env.VUE_APP_STRIPE_KEY) // test key
        const instanceOptions = ref({
          // https://stripe.com/docs/js/initializing#init_stripe_js-options
        })
        const elementsOptions = ref({
          // https://stripe.com/docs/js/elements_object/create#stripe_elements-options

          // Receive client_secret from subscription response
          clientSecret: store.state.subscriptionPlan.clientSecret,
          appearance: {
            variables: {
              colorText: 'hsl(210, 1%, 40%)',
              fontFamily: 'Gilroy-Semibold, system-ui, sans-serif',
              // fontSizeBase: '1.6rem'
            }
          }

        })
        const cardOptions = ref({
          // https://stripe.com/docs/stripe.js#element-options
          // value: {
          //   postalCode: '12345',
          // }
          defaultValues: {
            billingDetails: {
              name: ''
            }
          }
        })
        const stripeLoaded = ref(false)
        const payment = ref()
        const elms = ref()
        const paymentType = ref('')
        const formIsLoaded = ref(false)
        const secretIsLoaded = ref(false)
        const canceledSubscriptionProcess = ref(false)

        const submitStripeForm = () => {

          if (!cardName.value) {
            activated.cardName = true;
            return;
          }

          const paymentElement = payment.value.stripeElement

          collectPayment(paymentElement, elms.value, cardName.value);

          // store.state.primaryUser.subscription.plans.trial ?
          //   confirmSetup() : confirmPayment();

          const primaryUserPayment = Object.entries(store.state.primaryUser.payment);

          if ((primaryUserPayment.length === 0) || ((primaryUserPayment.length > 0) && store.state.primaryPaymentIsChanged)) {
            console.log('works for plans payment and trial');
            if (store.state.primaryUser.subscription.plans.basic ||
              store.state.primaryUser.subscription.plans.plus || 
              store.state.primaryUser.subscription.plans.advance ||
              store.state.primaryUser.subscription.plans.ultimate
            ) {
              confirmPayment();
            } else {
              if (store.state.primaryUser.subscription.plans.trial) {
                confirmSetup();
              }
            }
          }

          if (((primaryUserPayment.length > 0) && !store.state.primaryPaymentIsChanged)) {
            console.log('works for addition of payment');
            createPaymentMethod();
          }
          
        };

        const cancelChanges = () => {
          secretIsLoaded.value = false;

          const defaultSubscriptionFormatted = defaultSubscription;
          console.log(defaultSubscriptionFormatted, 'defaultSubscription');
          store.dispatch('savePrimaryUser', { section: 'subscription', data: defaultSubscriptionFormatted });
          canceledSubscriptionProcess.value = true;
        }

        const checkPaymentType = (ev) => {

          console.log(ev.value.type, 'payment type');

          paymentType.value = ev.value.type;

          // {
          //   elementType: 'payment',
          //   complete: false,
          //   empty: false,
          //   collapsed: false,
          //   value: { type: "card" },
          // }
        }

        const loadedStripeForm = () => {
          console.log('form is loaded');
          formIsLoaded.value = true;
        }
        
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });
        
        watch(paymentType, (newVal, oldVal) => {
          if (oldVal && newVal) {
            console.log(oldVal, newVal);
            section.value.style.height = "auto";
            setTimeout(() => {
              console.log(section.value.style.height, section.value.offsetHeight)
                sectionHeight.value = section.value.offsetHeight;
            }, 500);
          }
        });

        watch(formIsLoaded, () => {
          section.value.style.height = "auto";
          setTimeout(() => {
            console.log(section.value.style.height, section.value.offsetHeight)
              sectionHeight.value = section.value.offsetHeight;
          });
        });

        watch(() => ({...store.state.subscriptionPlan}), () => {
          if (store.state.subscriptionPlan.clientSecret) {
            elementsOptions.value.clientSecret = store.state.subscriptionPlan.clientSecret
            secretIsLoaded.value = true;
          }
        })

        watch(() => ({...store.state.futurePayment}), (newVal) => {
          // if (Object.entries(newVal).length > 0) {
          if (newVal.clientSecret) {
            elementsOptions.value.clientSecret = newVal.clientSecret
            setTimeout(() => {
              secretIsLoaded.value = true;
            }, 100)
          }
          // }
        })

        watch(() => ({...store.state.primaryUser.subscription}), () => {
          if (canceledSubscriptionProcess.value) {
            for (let prop in store.state.primaryUser.subscription) {
              if (prop === 'plans') {
                for (let plansProp in store.state.primaryUser.subscription.plans) {
                  subscription.plans[plansProp] = store.state.primaryUser.subscription?.plans[plansProp]
                }
              } else {
                subscription[prop] = store.state.primaryUser.subscription[prop]
              }
            }
            subscription.submitted = false;
            subscription.mode = 'edit'
            canceledSubscriptionProcess.value = false;
          }
        })

        onBeforeMount(() => {
          const stripePromise = loadStripe(stripeKey.value)
          stripePromise.then(() => {
            stripeLoaded.value = true
          })
        });
        
        onMounted(() => {
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });

            if (store.state.subscriptionPlan.clientSecret) {
              secretIsLoaded.value = true;
            }

            const primaryUserPayment = Object.entries(store.state.primaryUser.payment);

            if (((primaryUserPayment.length > 0) && !store.state.primaryPaymentIsChanged)) {
              store.dispatch('createFuturePayment');
              secretIsLoaded.value = false;
            }

            store.dispatch("addCurrentPath", {
                section: 1,
                val: 'Payment Information'
            });
            // if (payment.cardType) {
            //   card.value = true;
            // }
            // if (payment.accountType) {
            //   account.value = true;
            // }
        });
        return {
            section,
            boxHeader,
            toggleSection,
            
            submitStripeForm,
            stripeKey,
            stripeLoaded,
            instanceOptions,
            elementsOptions,
            cardOptions,
            payment,
            elms,

            checkPaymentType,
            loadedStripeForm,
            secretIsLoaded,
            cardName,
            activated,
            cancelChanges
        };
    },
    components: { StripeElements, StripeElement }
});
</script>

<style lang="scss" scoped src="@/styles/pages/UserProfile/ProfilePaymentForm.scss"></style>
