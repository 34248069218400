
<template>
  <section class="section mt-1">
<div class="border-box">
<div class="border-box-content">

<div class="content-block d-flex justify-content-between">
<h5 class="fw-semibold">Preview Advertisement</h5>
</div>

<div class="content-block">

<div class="preview-advertisement">
<div class="dashboard-sidebar-list">
<ul>
<li>
<a :href="advertisementContent.url">
<div class="thumb"><img :src="profileBgImg" alt="" /></div>
<div>
<h5>{{ advertisementContent.campaignTitle }}</h5>
<p v-html="advertisementContent.text"></p>
</div>
<span><img src="@/assets/images/angle-right-lg.png" alt=""></span>
</a>
</li>
</ul>
</div>
</div>

</div>

<div class="content-block">
<div class="btn-group justify-content-end">
<button type="submit" class="btn green w-sm" @click="savePreview">Save</button>
<button type="submit" class="btn green w-sm" @click="editPreview">Edit</button>
<button type="submit" class="btn green w-sm" @click="navigateFurther">Next</button>
</div>
</div>

</div>

</div>
</section>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'AdvertisementPreview',
  setup() {

    const store = useStore();

    const router = useRouter();

    // const dynamicBgName = ref(require("@/assets/profile/background.png")); // take from store by id
    // const profileBg = ref(`url(${dynamicBgName.value})`);
    const profileBgImg = ref(require('@/assets/images/adv-img.jpg'));

    const advertisementContent = ref({});

    const createPreview = () => {
      const reader = new FileReader();
      // let fileName = e.target.value.split("\\");
      // fileName = fileName[fileName.length - 1].split(".")[0];

      if (store.state.fileUploads.backgroundAdvertisement && 
      store.state.fileUploads.backgroundAdvertisement.type) {

        reader.readAsDataURL(store.state.fileUploads.backgroundAdvertisement);

        reader.onload = function () {
          setTimeout(() => {
            // profileBg.value = `url(${reader.result})`;
            profileBgImg.value = `${reader.result}`;
            console.log(reader.result);
            store.dispatch("uploadMedia", {
              file: `${reader.result}`,
              place: "backgroundAdvertisement",
            });
          }, 500);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      } else {
        profileBgImg.value = store.state.fileUploads.backgroundAdvertisement;
      }
    };

    const savePreview = () => {

    }

    const editPreview = () => {
      router.push({
        name: 'advertisement', 
        params: { 
          pagename: 'advertisement-questionnaire-second' 
        }
      })
    }

    const navigateFurther = () => {
      router.push({
        name: 'advertisement', 
        params: { 
          pagename: 'advertisement-payment' 
        }
      })
    }

    onMounted(() => {
      createPreview();
      console.log(store.state.fileUploads.backgroundAdvertisement, 'preview mounted');

      advertisementContent.value = store.state.advertisementAccount.advertisementContent;
    })

    return {
      profileBgImg,
      advertisementContent,
      savePreview,
      editPreview,
      navigateFurther
    }
  }
})
</script>