<template>
    <div class="section" ref="section">
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
<h5>Crop In Stock</h5>
<img src="@/assets/images/angle-down.png" alt="" class="angle">
</div>

<div class="border-box-content">

<div class="content-row table-wrapper">
<table>

<thead>
<tr>
<th><p class="form-label-static form-sort-label">
  <span>Farm</span>
  <span @click="() => sortTable('farm')">
    <button :class="{active: sortOrder.farm === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.farm === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Season</span>
  <span @click="() => sortTable('season')">
    <button :class="{active: sortOrder.season === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.season === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Crop</span>
  <span @click="() => sortTable('crop')">
    <button :class="{active: sortOrder.crop === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.crop === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Harvested (lbs.)</span>
  <span @click="() => sortTableByNumber('amtHarvested')">
    <button :class="{active: sortOrder.amtHarvested === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.amtHarvested === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>Sold (lbs.)</span>
  <span @click="() => sortTableByNumber('sold')">
    <button :class="{active: sortOrder.sold === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.sold === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th><p class="form-label-static form-sort-label">
  <span>In Stock (lbs.)</span>
  <span @click="() => sortTableByNumber('inStock')">
    <button :class="{active: sortOrder.inStock === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
    <button :class="{active: sortOrder.inStock === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
  </span></p></th>
<th class="w-75">&nbsp;</th>

</tr>
</thead>

<tbody>

<template
    v-for="(row, rowIndex) in rows"
    v-bind:key="'harvest-list-row' + row.id"
  >
    <tr
      v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
      :class="{'light-gray-bg': row.id === selectedHarvest.id}"
    >
    <!-- @click="(ev) => selectByRow(ev, {...row})" -->
    <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
    <td>{{ row.farm }}</td>
    <td>{{ row.season }}</td>
    <td>{{ row.crop }}</td>
    <td>{{ row.amtHarvested }}</td>
    <td>{{ row.sold }}</td>
    <td>{{ row.inStock }}</td>
    <td class="w-75">
    <div class="table-links">
    <!-- <a v-if="isAuth('edit-harvest')" href="javascript:void();" @click="selectHarvest({...row}, 'edit')">
      <img src="@/assets/images/edit-icon-2.svg" alt="">
    </a> -->
    <!-- <a href="javascript:void();" @click="removeVendor(row.id)">
      <img src="@/assets/images/trash-2.svg" alt="">
    </a> -->
    <!-- <a v-if="isAuth('remove-harvest')" href="javascript:void();" @click="removeHarvest(row.id)">
      <img src="@/assets/images/trash-2.svg" alt="">
    </a> -->
    </div>
    </td>
    </tr>
  </template>

</tbody>

</table>
</div>

<div class="content-row">
<div class="pagination">
<div>
<!-- <p>Records per page:</p> -->
<!-- <select v-model="maxPage">
<option>1</option>
<option>2</option>
<option>3</option>
<option>4</option>
<option>5</option>
<option>6</option>
<option>7</option>
<option>8</option>
<option>9</option>
<option>10</option>
</select> -->
</div>
<!-- <p>1-1 to 10</p> -->
<p class="list-pagination">
  Page: 
  <!-- <select v-model="currentPage" class="pagination-selector">
  <option 
    v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
    v-bind:key="'task-list-pagination-' + page"
  >{{ Number.parseInt(page) + 1 }}</option>
  </select> -->
  <DropDownList 
    :mainObject="'currentPage'"
    :mainProperty="'value'"
    :injectionName="'harvestListDetails'"
    :optionKey="'harvest-list-pagination'"
    :optionsList="[]"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="currentPage.value"
    :numberList="true"
  />
</p>
</div>
</div>

</div>
</div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import { ref, onMounted, watch, inject, reactive, provide } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'HarvestList',
    setup() {
        const store = useStore();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        // const harvests = computed(() => store.state.harvests);
        // const tasks = computed(() => store.state.tasks);
        const harvestTasks = ref([...store.state.tasks].filter(stateTask => stateTask.harvestTask && (stateTask.taskStatus === 'Completed')));
        const salesList = ref([...store.state.sales])
        const rowsOrdered = ref([]);
        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const sortOrder = reactive({
            farm: 0,
            season: 0,
            crop: 0,
            amtHarvested: 0,
            sold: 0,
            inStock: 0
        });
        const { isAuth } = inject('mainUI');
        const { selectedHarvest, addNewHarvest, editHarvest, selectHarvest, removeHarvest } = inject('harvestDetails');
        const helperTooltip = ref(false);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...rowsOrdered.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByNumber = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(a[sortProp]) - Number.parseInt(b[sortProp]));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(b[sortProp]) - Number.parseInt(a[sortProp]));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...rowsOrdered.value];
                sortOrder[sortProp] = 0;
            }
        };
        const sortTableByAmount = (sortProp) => {
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => Number.parseInt(Math.abs(a.amtHarvested - a.wastage)) - Number.parseInt(Math.abs(b.amtHarvested - b.wastage)));
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => Number.parseInt(Math.abs(b.amtHarvested - b.wastage)) - Number.parseInt(Math.abs(a.amtHarvested - a.wastage)));
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...rowsOrdered.value];
                sortOrder[sortProp] = 0;
            }
        };
        const selectByRow = (ev, row) => {
            if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
                return;
            }
            else {
                selectHarvest({ ...row }, 'represent');
            }
        };
        const harvestCalc = () => {
          rows.value = [...harvestTasks.value].map(harvest => {

            const currentCrop = [...salesList.value].filter(sale => sale.crop === harvest.crop);

            const currentSold = currentCrop.reduce(
              (accumulator, currentValue) => accumulator + currentValue.quantitySold,
              0,
            );

            return {
              farm: harvest.farm,
              season: harvest.season,
              crop: harvest.crop,
              amtHarvested: Number.parseFloat(harvest.amtHarvested).toFixed(2),
              sold: Number.parseFloat(currentSold).toFixed(2),
              inStock: Math.abs(Number.parseFloat(harvest.amtHarvested || 0) - Number.parseFloat(currentSold || 0)).toFixed(2)
            }
          });
          rowsOrdered.value = [...rows.value];
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(() => ([...harvestTasks.value]), () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            rows.value = [...harvestTasks.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(maxPage, () => {
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });

        watch(() => [...store.state.tasks], (newVal) => {
          harvestTasks.value = ([...newVal].filter(stateTask => stateTask.harvestTask && (stateTask.taskStatus === 'Completed')));
          harvestCalc();
        });

        watch(() => [...store.state.sales], (newVal) => {
          salesList.value = [...newVal];
          harvestCalc();
        });

        // watch(store.state.harvests, () => {
        //   console.log(harvests.value, 'harvests');
        //   section.value.style.height = 'auto';
        //   setTimeout(() => {
        //     sectionHeight.value = section.value.offsetHeight;
        //   })
        //   rows.value = [...harvests.value];
        // })
        onMounted(() => {
          store.dispatch('getTasks');
          store.dispatch('getSales');

            (harvestTasks.value.length > 0) && selectHarvest({ ...harvestTasks.value[harvestTasks.value.length - 1] }, 'represent');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getHarvests')
            rows.value = [...harvestTasks.value];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
            // tasks.value.forEach(task => {
            //   if (task.amtHarvested && (task.taskStatus === 'Completed')) {
            //     !harvests.value.find(hrv => hrv.id === task.id) && harvests.value.push({ ...task })
            //   }
            // })
        });
        provide('harvestListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            addNewHarvest,
            editHarvest,
            selectHarvest,
            removeHarvest,
            rows,
            maxPage,
            currentPage,
            selectedHarvest,
            helperTooltip,
            switchHelperTooltip,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            sortTableByNumber,
            sortTableByAmount,
            selectByRow,
            isAuth
        };
    },
    components: { DropDownList }
}
</script>

<style lang="scss" scoped>
.top-icon {
  position: relative;
}
</style>