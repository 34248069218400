const options = [
  '00:15 mins',
  '00:30 mins',
  '01:00 hrs',
  '02:00 hrs',
  '03:00 hrs',
  '04:00 hrs',
  '05:00 hrs',
  '1 day',
  '2 days',
  '3 days',
  '4 days',
  '5 days',
  '1 week',
  '2 weeks'
];

export default options;
