<template>
  <header>
    <div class="header-strip">
      <a href="javascript:void();"><img src="@/assets/images/mobile-logo.svg" alt=""></a>
    </div>
    <div class="header-content">
      <div class="header-links-wrap">
        <span class="nav-icon" @click="switchMenu"><img src="@/assets/images/nav-icon.svg" alt=""></span>
        <div class="header-links">
          <a href="javascript:void();"
            @mouseover="() => expandTasksList(true)"
            @mouseout="switchTimerActionTasks"
            class="tasks-label"
          >
            <!-- <img src="@/assets/images/list.svg" alt=""> -->
            <img src="@/assets/images/tasks-sized.png" alt="">
          </a>

          <div 
            class="tasks-list-wrapper" 
            :class="{released: tasksSectionExpanded}"
            ref="taskssection"
            @mouseover="turnOffTimerActionTasks"
            @mouseout="switchTimerActionTasks"
            >
            <div class="internal-frame">
              <TasksListWidget />
            </div>
          </div>

          <!-- remove old calendar -->
          <div class="widget-closer"
            v-if="calendarSectionExpanded"
            @click="() => {
              calendarWidgetExpanded = 'released';
              calendarSectionExpanded = false;
            }"
          ></div>
          
          <!-- remove old calendar -->
          <a href="javascript:void();" class="calendar-widget-button"
            @click="navigateToTask"
            @mouseover="expandCalendarWidget"
            @mouseout="switchTimerActionCalendar"
          ><!-- expandCalendarWidget -->
            <img src="@/assets/images/calendar.svg" alt="" class="calendar-widget-button" />
          </a>

          <!-- remove old calendar -->
          <div 
            class="calendar-widget-wrapper" 
            :class="calendarWidgetExpanded"
            ref="calendarsection"
            @mouseover="turnOffTimerActionCalendar"
            @mouseout="switchTimerActionCalendar"
            >
            <CalendarWidget />
          </div>
          
          <a href="javascript:void();"><img src="@/assets/images/chat.svg" alt=""></a>
          <a href="javascript:void();" class="notifications-label"
            @mouseover="() => expandNotificationsList(true)"
            @mouseout="switchTimerActionNotification"
          >
            <img src="@/assets/images/notifications.svg" alt="">
            <NotificationWidget />
          </a>

          <div 
            class="notifications-list-wrapper" 
            :class="{released: notificationSectionExpanded}"
            ref="notificationsection"
            @mouseover="turnOffTimerActionNotification"
            @mouseout="switchTimerActionNotification"
            >
            <template v-if="notificationsList.length > 0">
              <div class="internal-frame">
                <NotificationsList />
              </div>
            </template>
          </div>

        </div>
        <div class="avatar user-menu-button" @click="expandUserMenu">
          <img class="user-menu-button" src="@/assets/images/avatar.jpg" alt="">
        </div>
        <div class="user-menu" :class="userProfileMenu">
          <div 
            class="menu-item"
            @click="() => {userProfileMenu = 'released'}"
          >
            <router-link :to="{ 
              name: 'page', 
              params: { pagename: isAuth('primary-user') ? 'menu-profile' : 'menu-secondaryprofile' } 
            }">
              <span v-if="profileName.firstName && profileName.lastName">{{ `${profileName.firstName} ${profileName.lastName}` }}</span>
              <span v-else>Profile</span>
            </router-link>
          </div>
          <div 
            class="menu-item" 
            v-if="isAuth('admin')"
            @click="() => {userProfileMenu = 'released'}"
          >
            <router-link :to="{ 
              name: 'page', 
              params: { pagename: 'admin' } 
            }">
              <span>Admin</span>
            </router-link>
          </div>
          <div 
            class="menu-item" 
            @click="() => logOut()"
          >Logout</div>
        </div>
      </div>
      <div class="search"><input type="text" placeholder="Search here"></div>
    </div>
</header><!--//End Header-->
</template>

<script>
import { ref, inject, watch, onMounted, provide, computed } from "vue";
import isAuthorized from "@/components/Shared/authCheckHook";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import CalendarWidget from "./widgets/CalendarWidget.vue";
import NotificationWidget from "./widgets/NotificationWidget.vue";
import NotificationsList from "./widgets/NotificationsList.vue";
import TasksListWidget from "./widgets/TasksListWidget.vue";

export default {
    name: "UserInfoHeader",
    setup() {
        const store = useStore();
        const router = useRouter();
        const searchIcon = ref(require("@/assets/signed_panel/search_icon.png"))
        const searchText = ref("");
        // userProfileMenu = ref(''),
        const searchFieldActive = ref(false), searchFieldElement = ref(null);

        const calendarsection = ref(null);
        const calendarSectionHeight = ref(0);
        const calendarSectionExpanded = ref(false);
        const calendarMode = ref('represent');
        

        const notificationsection = ref(null);
        const notificationSectionHeight = ref(0);
        const notificationSectionExpanded = ref(false);

        const taskssection = ref(null);
        const tasksSectionHeight = ref(0);
        const tasksSectionExpanded = ref(false);

        const { switchMenu } = inject("mainMenu");
        const { switchDrawer, width, userProfileMenu, calendarWidgetExpanded } = inject("mainUI");

        const notifications = computed(() => store.state.notifications);

        const notificationsList = ref([]);

        const profileName = ref({
          firstName: '',
          lastName: ''
        });

        const isAuth = (featureCode) => {
            return isAuthorized(store.state.authenticated, featureCode);
        };
        const expandUserMenu = () => {
            userProfileMenu.value = (userProfileMenu.value !== "expanded") ?
                "expanded" : "released";
        };
        const expandCalendarWidget = () => {
            calendarWidgetExpanded.value = (calendarWidgetExpanded.value !== "expanded") ?
                "expanded" : "released";
          calendarSectionExpanded.value = !calendarSectionExpanded.value
          setTimeout(() => {
            calendarMode.value = 'represent';
          }, 300)
        };

        const expandNotificationsList = (expanded) => {
          // calendarWidgetExpanded.value = (calendarWidgetExpanded.value !== "expanded") ?
          //       "expanded" : "released";
          notificationSectionExpanded.value = expanded;
        }

        const expandTasksList = (expanded) => {
          // calendarWidgetExpanded.value = (calendarWidgetExpanded.value !== "expanded") ?
          //       "expanded" : "released";
          tasksSectionExpanded.value = expanded;
        }

        let switchTimerCalendar;
        
        const switchTimerActionCalendar = () => {
          switchTimerCalendar = setTimeout(expandCalendarWidget, 100);
        };

        const turnOffTimerActionCalendar = () => {
          clearTimeout(switchTimerCalendar);
        };

        let switchTimerNotification;

        const switchTimerActionNotification = () => {
          switchTimerNotification = setTimeout(() => expandNotificationsList(false), 100);
        };

        const turnOffTimerActionNotification = () => {
          clearTimeout(switchTimerNotification);
        }

        let switchTimerTasks;

        const switchTimerActionTasks = () => {
          switchTimerTasks = setTimeout(() => expandTasksList(false), 100);
        };

        const turnOffTimerActionTasks = () => {
          clearTimeout(switchTimerTasks);
        }

        const navigateToTask = () => {
          router.push({
            name: 'farm',
            params: {
              pagename: 'task'
            }
          })
        }

        const navigateTo = (direction) => {
            const routerParams = {};
            if (direction === "profile") {
                routerParams.name = isAuth("admin") ? "user-profile" : "labor";
                if (!isAuth("admin"))
                    routerParams.pagename = "secondary-user";
            }
            else {
                routerParams.name = direction,
                    routerParams.pagename = "/";
            }
            router.push(routerParams);
        };
        const activateSearchField = (val) => {
            searchFieldActive.value = val;
        };

        const logOut = () => {
          userProfileMenu.value = 'released';
          store.dispatch('logOut');
        }

        watch(searchFieldActive, (newVal) => {
            if (newVal)
                searchFieldElement.value.focus();
        });

        watch(calendarSectionExpanded, (newVal) => {
          calendarsection.value.style.height = newVal ? 
          `${calendarSectionHeight.value}px` : 
            `${0}px`
        })

        watch(notificationSectionExpanded, (newVal) => {
          setTimeout(() => {
            notificationsection.value.style.height = newVal ? 
              `${notificationSectionHeight.value}px` : 
                `${0}px`
          }, 100)
        })

        watch(tasksSectionExpanded, (newVal) => {
          setTimeout(() => {
            taskssection.value.style.height = newVal ? 
              `${tasksSectionHeight.value}px` : 
                `${0}px`
          }, 100)
        })

        watch(calendarMode, (newVal, oldVal) => {
          console.log(newVal, oldVal);
          // if (newVal === 'create' && oldVal === 'represent') {
            calendarsection.value.style.height = 'auto';
            setTimeout(() => {
              calendarSectionHeight.value = calendarsection.value.offsetHeight;
            })
          // }
        })

        watch(() => ([...store.state.notifications]), () => {
          console.log(store.state.notifications);
          notificationsection.value.style.height = 'auto';
          setTimeout(() => {
            notificationSectionHeight.value = notificationsection.value.offsetHeight;
          })
          notificationsList.value = [...notifications.value]
        })

        watch(() => ({...store.state.authenticated}), (newVal) => {
          if (newVal?.mainDetails?.firstName && newVal?.mainDetails?.lastName) {
            profileName.value.firstName = newVal?.mainDetails?.firstName;
            profileName.value.lastName = newVal?.mainDetails?.lastName;
          }
        });

        watch(() => ({...store.state.primaryUser}), (newVal) => {
          if (newVal?.mainDetails?.firstName && newVal?.mainDetails?.lastName) {
            if (!profileName.value.firstName && !profileName.value.lastName) {
              profileName.value.firstName = newVal?.mainDetails?.firstName;
              profileName.value.lastName = newVal?.mainDetails?.lastName;
            }
          }
        });

        watch(() => ({...store.state.secondaryUser}), (newVal) => {
          if (!store.state.primaryUser?.mainDetails?.firstName && !store.state.primaryUser?.mainDetails?.lastName) {
            if (newVal?.mainDetails?.firstName && newVal?.mainDetails?.lastName) {
              profileName.value.firstName = newVal?.mainDetails?.firstName;
              profileName.value.lastName = newVal?.mainDetails?.lastName;
            }
          }
        });

        provide('calendarDetails', {
          calendarMode,
          calendarWidgetExpanded,
          calendarSectionExpanded
        })
        
        onMounted(() => {
          notificationsList.value = [...notifications.value];

          if ((store.state.authenticated?.mainDetails?.firstName && store.state.authenticated?.mainDetails?.lastName) ||
            store.state.primaryUser?.mainDetails?.firstName && store.state.primaryUser?.mainDetails?.lastName
          ) {
            profileName.value.firstName = store.state.authenticated?.mainDetails?.firstName || store.state.primaryUser?.mainDetails?.firstName;
            profileName.value.lastName = store.state.authenticated?.mainDetails?.lastName || store.state.primaryUser?.mainDetails?.lastName;
          } else if (
            !store.state.primaryUser?.mainDetails?.firstName && !store.state.primaryUser?.mainDetails?.lastName
          ) {
            profileName.value.firstName = store.state.authenticated?.mainDetails?.firstName || store.state.secondaryUser?.mainDetails?.firstName;
            profileName.value.lastName = store.state.authenticated?.mainDetails?.lastName || store.state.secondaryUser?.mainDetails?.lastName;
          }

          setTimeout(() => {
            calendarSectionHeight.value = calendarsection.value.offsetHeight;
            calendarsection.value.style.height = `${0}px`;

            notificationSectionHeight.value = notificationsection.value.offsetHeight
            notificationsection.value.style.height = `${0}px`

            tasksSectionHeight.value = taskssection.value.offsetHeight
            taskssection.value.style.height = `${0}px`
          });
        })

        return {
            isAuth,
            searchIcon,
            searchText,
            switchDrawer,
            expandUserMenu,
            calendarsection,
            expandCalendarWidget,
            navigateToTask,
            userProfileMenu,
            calendarWidgetExpanded,
            calendarSectionExpanded,
            searchFieldActive,
            activateSearchField,
            searchFieldElement,
            width,
            switchMenu,
            navigateTo,

            switchTimerActionCalendar,
            turnOffTimerActionCalendar,

            notificationsection,
            expandNotificationsList,
            switchTimerActionNotification,
            turnOffTimerActionNotification,
            notificationSectionExpanded,

            taskssection,
            expandTasksList,
            switchTimerActionTasks,
            turnOffTimerActionTasks,
            tasksSectionExpanded,

            notificationsList,
            logOut,
            profileName
        };
    },
    components: { CalendarWidget, NotificationWidget, NotificationsList, TasksListWidget }
};
</script>

<style lang="scss" scoped>
.header-links-wrap {
  position: relative;
}
.user-menu {
  display: none;

  position: absolute;
  top: 70px;
  right: 0px;
  flex-direction: column;
  // border: 1px solid;
  border: 0px solid;
  // border-radius: 15px;
  // padding: 10px;
  padding: 0;
  z-index: 999;
  background-color: #fff;
  .menu-item {
    overflow: hidden;
    cursor: pointer;
    a {
      color: #000;
    }
    &:hover {
      background-color: hsl(var(--light-blue-20-h), var(--light-blue-20-s), calc(var(--light-blue-20-l) - 5%) );
    }
  }
  &.expanded {
    display: flex;
    animation: usermenuexpand 0.2s linear forwards;
    .menu-item {
      animation: usermenuitemexpand 0.2s linear forwards;
    }
  }
  &.released {
    display: flex;
    animation: usermenurelease 0.2s linear forwards;
    &::after {
      display: none;
    }
    .menu-item {
      animation: usermenuitemrelease 0.2s linear forwards;
    }
  }

  &::after {
    content: " ";
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    top: -7px;
    border-color: #3C3838;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    /* border-right: 6px solid transparent; */
    /* border-top: 8px solid transparent; */
    transform: translateX(-50%) rotate(135deg);
    box-shadow: -6px 4px 5px rgba(57, 73, 76, 0.15);
    right: 20px;
    background: #fff;
    z-index: -1;
  }
}

@keyframes usermenuexpand {
  from {
    padding: 0;
    border: 0px solid;
  }
  to {
    padding: 10px;
    border: 1px solid;
  }
}

@keyframes usermenuitemexpand {
  from {
    height: 0;
  }
  to {
    height: 2.4rem;
  }
}

@keyframes usermenurelease {
  from {
    padding: 10px;
    border: 1px solid;
  }
  to {
    padding: 0;
    border: 0px solid;
  }
}

@keyframes usermenuitemrelease {
  from {
    height: 2.4rem;
  }
  to {
    height: 0;
  }
}

.calendar-widget-wrapper {
  position: absolute;
  top: 4.1rem;
  right: 5px;
  z-index: 9;
  background-color: #fff;
  overflow: hidden;
  transition: all .15s linear;
  &.released {
    // height: 0;
  }
}
@media screen and (max-width:767px) {
  .calendar-widget-wrapper {
    right: 0px;
  }
}

.widget-closer {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.notifications-label {
  position: relative;
}

.notifications-list-wrapper {
  position: absolute;
  top: 4.1rem;
  right: 5px;
  z-index: 9;
  background-color: #fff;
  overflow: hidden;
  transition: all .15s linear;
  background-color: transparent;
  padding: 1.3rem;
  opacity: 0;
  .internal-frame {
    background-color: #fff;
    box-shadow: 0 0 1rem hsla(var(--black), 10%);
    border: 0.3rem solid hsl(var(--light-blue), 20%);
  }
  &.released {
    opacity: 1;
    padding: 1.3rem;
    // height: 0;
  }
}

.tasks-label {
  position: relative;
}

.tasks-list-wrapper {
  position: absolute;
  top: 4.1rem;
  left:-70px;
  z-index: 9;
  background-color: #fff;
  overflow: hidden;
  transition: all .15s linear;
  background-color: transparent;
  padding: 1.3rem;
  opacity: 0;
  .internal-frame {
    background-color: #fff;
    box-shadow: 0 0 1rem hsla(var(--black), 10%);
    border: 0.3rem solid hsl(var(--light-blue), 20%);
  }
  &.released {
    opacity: 1;
    padding: 1.3rem;
    // height: 0;
  }
}

// old styles
.user-panel {
  display: flex;
}
.user-panel-mobile {
  display: none;
}
.user-panel,
.user-panel-mobile {
  padding: 0 24px;
  align-items: center;
  .mobile-drawer-switcher {
    display: none;
  }
  .search-field {
    width: 50%;
    display: flex;
    align-items: center;
    .search-field-label {
      width: 90%;
    }
  }
  .user-menu {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    // padding-left: 24px;
    padding-left: 160px;
    position: relative;
    .menu-options {
      flex-basis: 80%;
      display: flex;
      justify-content: space-around;
      .option {
        display: flex;
        .option-title {
          display: none;
        }
        .icon {
          border: none;
          cursor: pointer;
          background: transparent;
          width: 20px;
          height: 20px;
          &.tasks {
            background-image: url("@/assets/signed_panel/add_note.png");
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.notifications {
            background-image: url("@/assets/signed_panel/notification_icon.png");
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.schedule {
            background-image: url("@/assets/signed_panel/list_icon.png");
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.calendar {
            background-image: url("@/assets/signed_panel/calendar_icon.png");
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.messages {
            background-image: url("@/assets/signed_panel/messages_icon.png");
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  .user-avatar {
    background-image: url("@/assets/signed_panel/user_avatar.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-left: auto;
  }
}

@media screen and (max-width: 1200px) {
  .user-panel-mobile {
    .mobile-drawer-switcher {
      display: flex;
    }
  }
}

// @media screen and (max-width: 1024px) {
//   .user-panel {
//     flex-wrap: wrap;
//     .search-field {
//       flex-basis: 100%;
//     }
//     .user-menu {
//       flex-basis: 100%;
//     }
//   }
// }

@media screen and (max-width: 1024px) {
  .user-panel {
    display: none;
  }
  .user-panel-mobile {
    display: flex;
    width: 100vw;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    .logo-avatar {
      width: 100%;
      padding: 8px 24px;
      display: flex;
      background: #28292B;
      align-items: center;
      .menu-logo {
        background-image: url("@/assets/signed_panel/bhoomi_logo_mobile.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 145px;
        height: 37px;
      }
    }
    .user-menu-mobile {
      width: 100%;
      padding: 8px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .menu-button {
        display: flex;
        .mobile-drawer-switcher {}
        .menu-text {
          font-family: 'Inter', sans-serif;
          font-weight: 400;
          font-size: 15px;
          color: #28292B;
          display: flex;
          align-items: center;
        }
      }
      .menu-options {
        display: flex;
        justify-content: space-between;
        width: 35%;
        .option {
          display: flex;
          .icon {
            border: none;
            cursor: pointer;
            background: transparent;
            width: 20px;
            height: 20px;
            &.tasks {
              background-image: url("@/assets/signed_panel/add_note.png");
              background-size: contain;
              background-repeat: no-repeat;
            }
            &.notifications {
              background-image: url("@/assets/signed_panel/notification_icon.png");
              background-size: contain;
              background-repeat: no-repeat;
            }
            &.schedule {
              background-image: url("@/assets/signed_panel/list_icon.png");
              background-size: contain;
              background-repeat: no-repeat;
            }
            &.calendar {
              background-image: url("@/assets/signed_panel/calendar_icon.png");
              background-size: contain;
              background-repeat: no-repeat;
            }
            &.messages {
              background-image: url("@/assets/signed_panel/messages_icon.png");
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
    .mobile-drawer-switcher {
      flex-basis: 10%;
    }
    .search-field {
      // flex-basis: 68%;
      width: 100%;
      flex-basis: 100%;
      padding: 8px 24px;
      display: flex;
      .search-field-label {
        max-width: none;
        width: 100%;
        // width: 10%;
        // max-width: 25px;
        // &.active {
        //   max-width: none;
        //   animation: searchexpand 0.2s linear forwards;
        // }
      }
    }
    .user-menu {
      flex-basis: 10%;
      padding-left: 0;
      // width: 100%;
      // flex-basis: 32%;
      .menu-options {
        position: absolute;
        flex-direction: column;
        right: 0;
        top: 60px;
        border: 1px solid #000;
        display: flex;
        // transform: scale(0);
        // &.active {
          // display: flex;
          // animation: usermenuexpand 0.2s linear forwards;
        // }
        .option {
          display: flex;
          padding: 10px 20px;
          background: #ffff;
          border: 1px solid #000;
          .option-title {
            display: flex;
            color: #000;
            margin-left: 5px;
            font-size: 18px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .user-panel-mobile {
    .logo-avatar,
    .user-menu-mobile,
    .search-field {
      padding: 8px 12px;
    }
  }
}

@keyframes searchexpand {
  from {
    width: 10%;
  }
  to {
    width: 100%;
  }
}
// @keyframes usermenuexpand {
//   from {
//     transform: scale(0);
//   }
//   to {
//     transform: scale(1);
//   }
// }
</style>
