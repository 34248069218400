<template>

  <!-- <section class="hero">
  <div class="hero-block">
  <div class="hero-banner"><img src="@/assets/images/hero-banner.jpg" alt=""></div>
  <a href="javascript:void();" class="hero-edit"><img src="@/assets/images/edit-profile-xs.svg" alt=""></a>
  </div>
  </section> -->
  <!--//End Hero-->

  <div class="tab-links mt-1">
  <div class="border-box">
  <router-link 
    :to="{ name: 'inventory', params: { pagename: 'plan-inventory' } }"
    :class="{active: route.params.pagename === 'plan-inventory'}" data-tag="two">Plan Inventory</router-link>
  <router-link 
    :to="{ name: 'inventory', params: { pagename: 'inventory' } }"    
    :class="{active: route.params.pagename === 'inventory'}" data-tag="one">Inventory</router-link>
  </div>
  </div>

  <!-- <div class="farm-profile-header-card">  
    <div
      class="farm-profile-background"
      :style="{
        backgroundImage: profileBg,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 100%',
        backgroundPosition: 'center center',
        borderRadius: '10px'
      }"
    >
      <div class="change-background" v-if="isAuth('change-background')">
        <input
          type="file"
          name="backgroundupload"
          class="background-file"
          ref="uploadbg"
          accept=".jpg,.png,.jpeg"
          @change="uploadImgDirect"
        />
      </div>
    </div>

    <div class="header-actions">
      <div class="farm-navigation">
        <router-link 
          class="farm-tab" 
          :class="{active: route.params.pagename === 'farm-profile'}"
          :to="{ name: 'farm', params: { pagename: 'farm-profile' } }">
          Farms
        </router-link>
        <router-link 
          class="farm-tab"
          :class="{active: route.params.pagename === 'plan-crop'}" 
          :to="{ name: 'farm', params: { pagename: 'plan-crop' } }">
          Planned Crops
        </router-link>
      </div>
    </div>
  </div> -->
</template>

<script>
import { ref, computed, onMounted, inject } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "InventoryHeader",
  setup() {
    const store = useStore();
    const route = useRoute();
    const { isAuth } = inject('mainUI');

    const dynamicBgName = ref(require("@/assets/profile/background.png")); // take from store by id
    const profileBg = ref(`url(${dynamicBgName.value})`);

    const uploadImgDirect = (ev) => {
      console.log(ev);
      store.dispatch("uploadMedia", {
        file: ev.target.files[0],
        place: "background-farm",
      });
      createPreview(ev);
    };

    const createPreview = (e) => {
      const reader = new FileReader();
      // let fileName = e.target.value.split("\\");
      // fileName = fileName[fileName.length - 1].split(".")[0];

      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
      reader.onload = function () {
        setTimeout(() => {
          profileBg.value = `url(${reader.result})`;
          console.log(reader.result);
        }, 500);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    };

    let dynamicAvatarName = "user-avatar.png"; // take it from id dynamic

    const profileAvatar = computed(() => {
      return require("@/assets/profile/" + dynamicAvatarName);
    });

    onMounted(() => {
      console.log(route.params.pagename)
    })

    return {
      profileBg,
      uploadImgDirect,
      profileAvatar: `url(${profileAvatar.value})`,
      route,
      isAuth
    }
  }
};
</script>

<style lang="scss">
.farm-profile-header-card {
  position: relative;

  border-radius: 10px;
  // overflow: hidden;
  .add-farm {
    display: flex;
    margin-left: auto;
    margin-top: 0px;
    margin-bottom: 24px;
    background: none;
    border: 3px solid #38AC89;
    padding: 10px 15px;
    border-radius: 15px;
    font-weight: 600;
    color: #38AC89;
    cursor: pointer;
  }
  .header-actions {
    .farm-navigation {
      height: 65px;
      border: 3px solid rgba(134, 145, 168, 0.2);
      border-radius: 15px;
      margin-bottom: 23px;
      margin-top: 21px;
      display: flex;
      align-items: center;
      .farm-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: 50%;
        height: 55px;
        font-size: 20px;
        border: none;
        background: none;
        font-weight: 600;
        border-radius: 10px;
        color: rgba(40, 41, 43, 0.6);
        cursor: pointer;
        &.active {
          background: #38AC89;
          color: #fff;
        }
      }
    }
  }

  .farm-profile-background {
    width: 100%;
    height: 15vw;
    // border-radius: 10px;
    position: relative;

    // margin-left: -16px;
    // margin-top: -18px;
    .change-background {
      background-image: url("@/assets/profile/edit-icon.svg");
      background-repeat: no-repeat;
      background-size: 18px;
      background-position: center;
      width: 35px;
      height: 35px;
      position: absolute;
      right: 10px;
      bottom: 10px;
      top: auto;

      cursor: pointer;
      border: 2px solid #fff;
      border-radius: 50%;
      backdrop-filter: blur(6px);
      .background-file {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}
</style>