<template>
  <div class="custom-select" :tabindex="0"
    @keyup.up="() => selectTab('up')"
    @keyup.down="() => selectTab('down')"
    @keyup.tab="() => setFocus(true)"
    @keydown.tab="() => setFocus(false)"
    :class="{'hidden-arrow': arrowIsHidden}"
  >
  <!-- @blur="open = false" -->
    <div class="selected" :class="{ open: open, hightlighted: tabFocused }" @click="() => {
      if (!listIsDisabled) {
        open = !open
      }
    }">
      {{ selected }}
    </div>
    <div class="items" :class="{ selectHide: !open }" :style="{'max-height': `${maxHeightNumber}px` || '300px'}">
      <div
        v-for="(option, i) of options"
        :key="`${i}-${optionKeyName}`"
        @click="() => {
          console.log(option, 'option');
          selected = option;
          open = false;
          selectTabIndex(i);
          $emit('input', option);
        }"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, ref, watch, onMounted } from 'vue';

export default defineComponent({
  name: 'DropDownList',
  props:[
    'mainObject', 
    'mainProperty', 
    'injectionName', 
    'optionKey', 
    'optionsList', 
    'optionProperty', 
    'optionPropertyOne',
    'optionPropertyTwo', 
    'defaultSelected', 
    'numberList',
    'maxHeight',
    'isDisabled',
    'optionsTwo',
    'selectionMethod',
    'hiddenArrow'
  ],
  setup(props){
    const injection = inject(`${props.injectionName}`);

    const selected = ref(null);

    const open = ref(false);

    const options = ref([]);

    const optionKeyName = ref(`${props.optionKey}`);
    
    const selectedIndex = ref(-1);

    const tabFocused = ref(false);

    const maxHeightNumber = ref(props.maxHeight);

    const listIsDisabled = ref(props.isDisabled);

    const arrowIsHidden = ref(props.hiddenArrow);

    const selectTab = (dir) => {

      if (dir === 'up') {
        if (selectedIndex.value === -1) {
          return;
        } 
        selectedIndex.value -= 1;
        selected.value = options.value[selectedIndex.value] || '';
      }
      if (dir === 'down') {
        if (selectedIndex.value === (options.value.length - 1)) {
          return;
        } 
        selectedIndex.value += 1;
        selected.value = options.value[selectedIndex.value] || '';
      }

      // window.removeEventListener("keydown", preventPageMove, true);
    }

    const selectTabIndex = (index) => {
      if (injection?.indexSelected) {
        injection.indexSelected.value = index;
      }
    }

    const setFocus = (isFocused) => {
      tabFocused.value = isFocused;
      if (!isFocused) {
        open.value = false;
      }
    }

    watch(selected, (newVal) => {
      console.log(newVal);
      if (props.mainProperty) {
        injection[props.mainObject][props.mainProperty] = newVal;
      } else {
        injection[props.mainObject] = newVal;
      }
      if (props.selectionMethod) {
        const selectedObj = [...props.optionsList].find(optObj => `${optObj[props.optionProperty][props.optionPropertyTwo[0]]} ${optObj[props.optionProperty][props.optionPropertyTwo[1]]}` === newVal) || {};
        props.selectionMethod(selectedObj?.id || selectedObj[props.optionProperty]?.id);
      }
    })

    watch(() => ({...injection[props.mainObject]}), (newVal) => {
      if (props.mainProperty) {
        if (newVal[props.mainProperty] || typeof newVal[props.mainProperty] === 'string') {
          selected.value = newVal[props.mainProperty]
        }
      }
    })

    watch(injection.options, (newVal) => {
      if (!props.optionsTwo) {
        if ((props.optionsList.length === 0) && ((newVal.length > 0) || (newVal?.value?.length > 0))) {
          if (newVal.value) {
            if (props.optionProperty) {
              options.value = [...newVal.value].map(optObj => optObj[props.optionProperty]);
            } else {
              options.value = [...newVal.value];
            }
          } else {
            if (props.optionProperty) {
              options.value = [...newVal].map(optObj => optObj[props.optionProperty]);
            } else {
              options.value = [...newVal];
            }
            console.log(options.value, 'injection.options watch');
          }
        }
        if (props.optionsList.length > 0) {
          // options.value = [...props.optionsList];
        }
      }
    })

    watch(injection.optionsTwo, (newVal) => {
      if (props.optionsTwo) {
        if ((props.optionsList.length === 0) && (newVal.length > 0)) {
          options.value = [...newVal];
        }
      }
    })

    watch(() => (props.isDisabled), (newVal) => {
      listIsDisabled.value = newVal;
    })

    onMounted(() => {
      console.log(props.optionsList, 'props.optionsList');
      if (props.optionsList.length > 0) {
        if (props.optionProperty) {
          if (props.optionPropertyTwo) {          
            options.value = [...props.optionsList].map(optObj => {
              if (optObj) {
                if (optObj[props.optionProperty]) {
                  return `${optObj[props.optionProperty][props.optionPropertyTwo[0]]} ${optObj[props.optionProperty][props.optionPropertyTwo[1]]}`
                }
              }
            });
          } else if (props.optionPropertyOne) {
            options.value = [...props.optionsList].map(optObj => {
              if (optObj) {
                if (optObj[props.optionProperty]) {
                  return `${optObj[props.optionProperty]}${(optObj[props.optionPropertyOne[0]] || optObj[props.optionPropertyOne[1]]) ? ` - ${optObj[props.optionPropertyOne[0]] || optObj[props.optionPropertyOne[1]]}` : ''}`
                }
              }
            });
          } else {
            options.value = [...props.optionsList].map(optObj => optObj[props.optionProperty]);
          }
        } else {
          if (props.numberList) {
            options.value = [...props.optionsList].map(opt => Number.parseInt(opt) + 1);
          } else {
            options.value = [...props.optionsList];
          }
        }
      } else {
        if (!props.optionsTwo) {
          console.log(injection.options, 'injection.options');
          // options.value = injection.options?.value;
          if (props.optionProperty) {
            if (injection?.options?.value?.length) {
              options.value = [...injection?.options?.value].map(optObj => optObj[props.optionProperty]);
            }
          } else {
            options.value = injection.options?.value;
          }
        } else {
          options.value = injection.optionsTwo;
        }
      }
      if (props.defaultSelected) {
        selected.value = props.defaultSelected;
      }

      const preventPageMove = function(e) {
        if(["ArrowUp","ArrowDown"].indexOf(e.code) > -1) {
          e.preventDefault();
        }
      }

      window.addEventListener("keydown", preventPageMove, true);

      if (inject('otherHandling')) {
        const { otherSelected } = inject('otherHandling');
        if (props.mainProperty) {
          if (otherSelected.value) {
            selected.value = injection[props.mainObject][props.mainProperty];
            open.value = true;
            otherSelected.value = false;
          }
        }
      }

      if (injection.options) {
        console.log(injection.options, 'injection.options is here')
        if (!props.optionsTwo) {
          if ((props.optionsList.length === 0) && ((injection.options.length > 0) || (injection.options?.value?.length > 0))) {
            if (injection.options.value) {
              if (props.optionProperty) {
                if (props.optionPropertyTwo) {          
                  options.value = [...injection.options.value].map(optObj => {
                    if (optObj) {
                      if (optObj[props.optionProperty]) {
                        return `${optObj[props.optionProperty][props.optionPropertyTwo[0]]} ${optObj[props.optionProperty][props.optionPropertyTwo[1]]}`
                      }
                    }
                  });
                } else {
                  options.value = [...injection.options.value].map(optObj => optObj[props.optionProperty]);
                }
              } else {
                options.value = [...injection.options.value];
              }
            } else {
              if (props.optionProperty) {
                options.value = [...injection.options].map(optObj => optObj[props.optionProperty]);
              } else {
                options.value = [...injection.options];
              }
              console.log(options.value, 'injection.options watch');
            }
          }
          if (props.optionsList.length > 0) {
            // options.value = [...props.optionsList];
          }
        }
      }
    })

    return {
      selected,
      open,
      options,
      optionKeyName,
      selectTab,
      setFocus,
      tabFocused,
      maxHeightNumber,
      listIsDisabled,
      arrowIsHidden,
      selectTabIndex
    }
  }
})
</script>

<style scoped lang="scss">
.records-per-page {
  .custom-select {
    position: absolute;
    max-width: 58px;
    // right: 208px;
    right: 0;
    height: 3.2rem;
    line-height: 3.2rem;
    .selected:after {
      top: 16px;
    }
  }
}
.list-pagination {
  .custom-select {
    position: absolute;
    max-width: 58px;
    // right: 28px;
    right: 0;
    height: 3.2rem;
    line-height: 3.2rem;
    .selected:after {
      top: 16px;
    }
  }
}
.table-select {
  .custom-select {
    height: 3.4rem;
    // width: 100%;
    font-size: 1.4rem;
    font-family: var(--fw-medium);
    width: 13.5rem;
    border: 0.1rem solid hsl(var(--gray));
    // border-radius: 0.4rem;
    // padding: 0 3.4rem 0 1rem;
    padding: 0;
    font-weight: normal;
    background: none;
    position: relative;
    // width: max-content;
    .selected { 
      border: none;
      margin-right: 0;
      display: flex;
      align-items: center;
      border-radius: 0.4rem;
    }
    .items {
      div {
        margin-right: 0;
      }
    }
  }
}

.form-modal {
  .custom-select {
    height: 4.2rem;
    line-height: 4.2rem;
  }
}
// .dashboard-content-wrap {
//   .records-per-page {
//     .custom-select {
//       right: 408px;
//     }
//   }
//   .list-pagination {
//     .custom-select {
//       right: 228px;
//     }
//   }
// }
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 5.2rem;
  line-height: 5.2rem;
}

.custom-select .selected {
  background-color: #fff;
  border: .2rem solid hsla(var(--light-blue), 20%);
  color: hsl(var(--gray));
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  min-height: 100%;
  max-height: 100%;
  font-family: var(--fw-medium);
  font-size: var(--fs-sm);
}

.custom-select .selected.open {
  border: 1px solid lightgray;
}

.custom-select .selected.hightlighted {
  border: 2px solid black;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: hsl(var(--gray)) transparent transparent transparent;
}

.custom-select {
  &.hidden-arrow {
    .selected {
      &::after {
        display: none;
      }
    }
  }
}

.custom-select .items {
  color: #fff;
  overflow: hidden;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  z-index: 9;
  max-height: 300px;
  overflow-y: scroll;
}

.custom-select .items div {
  color: hsl(var(--gray));
  padding-left: 1em;
  cursor: pointer;
  // user-select: none;
  font-size: var(--fs-sm);
  font-family: var(--fw-semibold);
  height: 3rem;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
}

.custom-select .items div:hover {
  background-color: #9b9b9b;
  color: #fff;
}

.selectHide {
  display: none;
}
</style>