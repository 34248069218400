<template>
  <div class="section" ref="section">
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5>Billing Address</h5>
  <div class="info-icons">
    <div class="info-icon billing-tooltip-container tooltip"
      v-if="!billing.sameAddress"
      @click="() => {
        billingEditionMode = 'edit'
      }"
      data-tooltip="Edit Billing"
      @mouseover="switchHelperTooltip(true)"
      @mouseout="switchHelperTooltip(false)"
    >
      <!-- <div class="helper-tooltip" v-if="helperTooltip">Edit Billing</div> -->
      <!-- <img src="@/assets/images/edit-profile-icon.svg" alt=""> -->
      <img src="@/assets/images/updated/Edit.png" alt="">
    </div>
  </div>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>

  <div class="border-box-content subscription-type-content">


  <div class="content-block">

  <div class="content-section">
    <div class="intro-grid">

    <div class="intro-grid-column">
    <p class="title">Account Name</p>
    <h6>{{ billing.accountName }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">First Name</p>
    <h6>{{ billing.firstName }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">Last Name</p>
    <h6>{{ billing.lastName }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">Address 1</p>
    <h6>{{ billing.address }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">Address 2</p>
    <h6>{{ billing.address2 }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">City</p>
    <h6>{{ billing.city }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">State</p>
    <h6>{{ billing.state }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">Zip</p>
    <h6>{{ billing.zip }}</h6>
    </div>

    <div class="intro-grid-column">
    <p class="title">Country</p>
    <h6>{{ billing.country }}</h6>
    </div>

    </div>
  </div>

  </div>

  </div>
  </div>
</template>

<script>
import { inject, onMounted, ref, watch } from "vue";

export default {
  name: "AdminPaymentBillingFilled",
  setup() {

    const { 
      billing, 
      billingEditionMode
    } = inject('billing');

    const section = ref(null);
    const boxHeader = ref(null);
    const sectionHeight = ref(0);
    const sectionExpanded = ref(true);

    const helperTooltip = ref(false);
    const switchHelperTooltip = (toggle) => {
      helperTooltip.value = toggle;
    }

    const toggleSection = () => {
      sectionExpanded.value = !sectionExpanded.value
    }

    watch(sectionExpanded, (newVal) => {
      section.value.style.height = newVal ? 
        `${sectionHeight.value}px` : 
          `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'    })

    onMounted(() => {

      sectionHeight.value = section.value.offsetHeight;
      section.value.style.height = `${section.value.offsetHeight}px`;

      
    })

    return {
      billingEditionMode,
      billing,
      switchHelperTooltip,
      helperTooltip,

      section,
      boxHeader,
      toggleSection
    };
  },
};
</script>

<style lang="scss" scoped></style>