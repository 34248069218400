<template>
  <div class="section" ref="section">
  <div class="border-box-header" ref="boxHeader" @click="toggleSection">
  <h5>Billing Address</h5>
  <img src="@/assets/images/angle-down.png" alt="" class="angle">
  </div>

  <div class="border-box-content">

  <div class="content-block">
  <div class="checkbox checkbox-lg"><input type="checkbox" id="addressSame" v-model="sameAddress" @click="preventFormFilling"><label for="addressSame">Billing Address is same as Account Address</label></div>
  </div>

  <div class="content-block">

  <div class="content-row">
  <div class="content-row grid-4 grid-full">
  <div class="row">
  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.accountName || (billing.accountName.length > 0)}">Account Name</p>
  <input type="text" class="form-control" 
    v-model="billing.accountName"
    @focus="() => {
      activated.accountName = true;
      focused.accountName = true;
      preventFormFilling();
    }"
    @blur="() => {
      focused.accountName = false;
    }"
  >
  <p v-if="activated.accountName && billing.accountName.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.accountName && billing.accountName.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
  </div>
  </div>
  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.firstName || (billing.firstName.length > 0)}">First Name</p>
  <input type="text" class="form-control" 
    v-model="billing.firstName"
    @focus="() => {
      activated.firstName = true;
      focused.firstName = true;
      preventFormFilling();
    }"
    @blur="() => {
      focused.firstName = false;
    }"
  >
  <p v-if="activated.firstName && billing.firstName.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.firstName && billing.firstName.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
  </div>
  </div>
  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.lastName || (billing.lastName.length > 0)}">Last Name</p>
  <input type="text" class="form-control" 
    v-model="billing.lastName"
    @focus="() => {
      activated.lastName = true;
      focused.lastName = true;
      preventFormFilling();
    }"
    @blur="() => {
      focused.lastName = false;
    }"
  >
  <p v-if="activated.lastName && billing.lastName.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.lastName && billing.lastName.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
  </div>
  </div>
  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.address || (billing.address.length > 0)}">Address 1</p>
  <input type="text" class="form-control" 
    v-model="billing.address"
    @focus="() => {
      activated.address = true;
      focused.address = true;
      preventFormFilling();
    }"
    @blur="() => {
      focused.address = false;
    }"
  >
  <p v-if="activated.address && billing.address.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.address && billing.address.length > 100" class="form-info red text-end">Please use maximum 100 characters</p>
  </div>
  </div>
  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.address2 || (billing.address2.length > 0)}">Address 2</p>
  <input type="text" class="form-control" 
    v-model="billing.address2"
    @focus="() => {
      activated.address2 = true;
      focused.address2 = true;
      preventFormFilling();
    }"
    @blur="() => {
      focused.address2 = false;
    }"
  >
  <p v-if="activated.address2 && billing.address2.length > 75" class="form-info red text-end">Please use maximum 75 characters</p>
  </div>
  </div>
  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.city || (billing.city.length > 0)}">City</p>
  <input type="text" class="form-control" 
    v-model="billing.city"
    @focus="() => {
      activated.city = true;
      focused.city = true;
      preventFormFilling();
    }"
    @blur="() => {
      focused.city = false;
    }"
  >
  <p v-if="activated.city && billing.city.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.city && billing.city.length > 50" class="form-info red text-end">Please use maximum 50 characters</p>
  </div>
  </div>
  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.state || (billing.state.length > 0)}">State</p>
  <!-- <select class="form-control" 
    v-model="billing.state"
    @click="() => {
      activated.state = true;
      focused.state = true;
    }"
    @change="() => {
      focused.state = false;
    }"
  >
  <option
    v-for="stateOption in stateOptions"
    v-bind:key="stateOption + 'state-option-billing'"
  >
    {{ stateOption }}
  </option>
  </select> -->
  <DropDownList
    :mainObject="'billing'"
    :mainProperty="'state'"
    :injectionName="'billing'"
    :optionKey="'state-option-billing-admin'"
    :optionsList="stateOptions"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :maxHeight="150"
    :defaultSelected="billing.state"
    @click="() => {
      activated.state = true;
      preventFormFilling();
    }"
  />
  <p v-if="activated.state && billing.state.length === 0" class="form-info red text-end">* Required</p>
  </div>
  </div>
  <div class="col-3">
  <div class="form-item">
  <p class="form-label" :class="{active: focused.zip || (billing.zip.length > 0)}">Zip</p>
  <input type="text" class="form-control" 
    v-model="billing.zip"
    @focus="() => {
      activated.zip = true;
      focused.zip = true;
      preventFormFilling();
    }"
    @blur="() => {
      focused.zip = false;
    }"
  >
  <p v-if="activated.zip && billing.zip.length === 0" class="form-info red text-end">* Required</p>
  <p v-if="activated.zip && billing.zip.length > 10" class="form-info red text-end">Please use maximum 10 characters</p>
  </div>
  </div>
  <div class="col-3">
  <div class="form-item">
  <p class="form-label"  :class="{active: focused.country || (billing.country.length > 0)}">Country</p>
  <!-- <select class="form-control" 
    v-model="billing.country" 
    @click="() => {
      activated.country = true;
      focused.country = true;
    }"
    @change="() => {
      focused.country = false;
    }"
  >
  <option 
    v-for="countryOption in countryOptions"
    v-bind:key="countryOption + 'country-option-billing'"
  >
    {{ countryOption }}
  </option>
  </select> -->
  <DropDownList
    :mainObject="'billing'"
    :mainProperty="'country'"
    :injectionName="'billing'"
    :optionKey="'country-option-billing-admin'"
    :optionsList="countryOptions"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :maxHeight="50"
    :defaultSelected="billing.country"
    @click="() => {
      activated.country = true;
      preventFormFilling();
    }"
  />
  <p v-if="activated.country && billing.country.length === 0" class="form-info red text-end">* Required</p>
  </div>
  </div>
  </div>
  </div>
  </div>

  </div>

  <div class="content-block">
  <div class="buttons form-buttons">
  <div class="row">
  <div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Save</button></div>
  <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges" >Cancel</button></div>
  </div>
  </div>
  </div>

  </div>

  </div>

  <div v-if="popups.paymentFormSaved.active">
    <UserNotification 
      v-bind:messageText="popups.paymentFormSaved.message"
    />
  </div>
</template>

<script>
import { inject, ref, watch, reactive, onMounted } from "vue";
import stateOptions from '@/sharedData/statesList.js'
import countryOptions from '@/sharedData/countriesList.js'
import { useStore } from "vuex";
import DropDownList from "@/components/Shared/DropDownList.vue";
import UserNotification from '@/components/Shared/UserNotification.vue';

export default {
    name: "ProfileBillingForm",
    setup() {
        const store = useStore();
        const { billing, sameAddress, changeBillingField, billingEditionMode } = inject('billing');
        const { paymentEditionMode, selectedPaymentMethod, paymentFormSaved, navigateToPaymentForm } = inject('payment');
        // const { confirmBankPayment } = inject('stripeProps');
        const defaultBilling = {
            ...billing
        };
        const activated = reactive({
            accountName: false,
            firstName: false,
            lastName: false,
            address: false,
            address2: false,
            city: false,
            state: false,
            zip: false,
            country: false
        });
        const focused = reactive({
            accountName: false,
            firstName: false,
            lastName: false,
            address: false,
            address2: false,
            city: false,
            state: false,
            zip: false,
            country: false
        });
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const paymentMethods = ref([
            ...store.state.authenticated.paymentMethods
        ]);
        const popups = reactive({
          paymentFormSaved: {
            active: false,
            message: 'Please save payment information before proceeding further'
          }
        });
        const toggleSection = () => {
            sectionExpanded.value = !sectionExpanded.value;
        };
        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
            section.value.style.overflow = newVal ? 'visible' : 'hidden';
        });
        watch(billing, () => {
            if (billing.expanded) {
                store.dispatch('addCurrentPath', {
                    section: 1,
                    val: 'Billing Address'
                });
            }
        });
        watch(() => store.state.authenticated.paymentMethods, (newVal) => {
            // if (newVal) store.dispatch('getPaymentMethodsList');
            console.log(newVal, 'dispatching from billing form');
            if (newVal) {
                paymentMethods.value = [...newVal];
            }
        });
        const cancelChanges = () => {
            for (let prop in defaultBilling) {
                billing[prop] = defaultBilling[prop];
            }
            paymentEditionMode.value = 'represent';
            billingEditionMode.value = 'represent';
        };
        const submitForm = () => {
            if (!billing.country || !billing.zip ||
                !billing.state || !billing.city ||
                !billing.address || !billing.lastName ||
                !billing.firstName || !billing.accountName) {
                if (!billing.country)
                    activated.country = true;
                if (!billing.zip)
                    activated.zip = true;
                if (!billing.state)
                    activated.state = true;
                if (!billing.city)
                    activated.city = true;
                if (!billing.address)
                    activated.address = true;
                if (!billing.lastName)
                    activated.lastName = true;
                if (!billing.firstName)
                    activated.firstName = true;
                if (!billing.accountName)
                    activated.accountName = true;
                return;
            }
            if (!paymentFormSaved.value && (billingEditionMode.value !== 'edit')) {
              popups.paymentFormSaved.active = true;
              navigateToPaymentForm();
              return;
            }
            // store.dispatch('getPaymentMethodsList');
            const currentPayments = [...paymentMethods.value].reverse();
            // console.log(currentPayments, currentPayments[currentPayments.length - 1].id, paymentEditionMode)
            store.dispatch('savePrimaryUser', { section: 'billing', data: {
                    ...billing,
                    paymentMethodId: currentPayments[currentPayments.length - 1].id
                } });
            // changeBillingField('represent', 'mode');
            store.dispatch('updateTenant', {
                data: {
                    ...billing,
                },
                primaryUserId: store.state.primaryUser.id,
                section: 'billing'
            });
            // confirmBankPayment();
            setTimeout(() => {
                selectedPaymentMethod.value = currentPayments[currentPayments.length - 1]?.id;
                paymentEditionMode.value = 'represent';
                billingEditionMode.value = 'represent';
            }, 10);
        };

        const preventFormFilling = () => {
          if (!paymentFormSaved.value && (billingEditionMode.value !== 'edit')) {
            popups.paymentFormSaved.active = true;
            navigateToPaymentForm();
            return;
          }
        }

        onMounted(() => {
            sectionHeight.value = section.value.offsetHeight;
            section.value.style.height = `${section.value.offsetHeight}px`;
        });
        return {
            stateOptions,
            countryOptions,
            billing,
            sameAddress,
            changeBillingField,
            cancelChanges,
            submitForm,
            activated,
            focused,
            section,
            boxHeader,
            toggleSection,
            popups,
            preventFormFilling
        };
    },
    components: { DropDownList, UserNotification }
};
</script>

<style lang="scss" scoped>
</style>