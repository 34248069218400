<template>
  <section class="section mt-1">
    <div class="border-box">
      <div class="border-box-content">
        <div class="content-block d-flex justify-content-between">
          <h5 class="fw-semibold">Contact Us</h5>
        </div>
        <div class="content-block">

<div class="content-row">
<div class="content-row grid-4 grid-full">
<div class="row">

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.requestType || (contact.requestType.length > 0)}">Request Type</p>

<template v-if="!manualRequestType">
  <DropDownList
    :mainObject="'contact'"
    :mainProperty="'requestType'"
    :injectionName="'contactDetails'"
    :optionKey="'request-type-option-contact-us'"
    :optionsList="['Sales', 'Technical Support', 'Feature Suggestion', 'Other']"
    :optionProperty="null"
    :optionPropertyTwo="null"
    :defaultSelected="contact.requestType"
    @click="() => {
      activated.requestType = true;
    }"
  />
</template>

  <div v-else class="dropdown-open-back-container">
    <input type="text" class="form-control"
    v-model="contact.requestType"
    ref="requestTypeField"
      @focus="() => {
        activated.requestType = true;
        focused.requestType = true;
      }"
      @blur="() => {
        focused.requestType = false;
      }"
    >
    <div class="dropdown-open-back" @click="() => {
      manualRequestType = false
    }"></div>
  </div>
 <p v-if="activated.requestType && contact.requestType.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.accountId || (contact.accountId.length > 0)}">
  Account ID
  <span 
    class="info-icon" 
    style="position: absolute;right: 0;top: 0;"
    @mouseover="switchAccountIdTooltip(true)"
    @mouseout="switchAccountIdTooltip(false)"
  >
    <img src="@/assets/images/question-mark.png" alt="">
    <div class="account-tooltip" v-if="accountIdTooltip">Account ID can be found on the top right of your profile page.</div>
  </span>
</p>
<input type="text" class="form-control"
 v-model="contact.accountId"
  @focus="() => {
    activated.accountId = true;
    focused.accountId = true;
  }"
  @blur="() => {
    focused.accountId = false;
  }"
>
 <p v-if="activated.accountId && contact.accountId.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.farmName || (contact.farmName.length > 0)}">Farm Name</p>
<input type="text" class="form-control"
 v-model="contact.farmName"
  @focus="() => {
    activated.farmName = true;
    focused.farmName = true;
  }"
  @blur="() => {
    focused.farmName = false;
  }"
>
 <p v-if="activated.farmName && contact.farmName.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.name || (contact.name.length > 0)}">Name</p>
<input type="text" class="form-control"
 v-model="contact.name"
  @focus="() => {
    activated.name = true;
    focused.name = true;
  }"
  @blur="() => {
    focused.name = false;
  }"
>
 <p v-if="activated.name && contact.name.length === 0" class="form-info red text-end">* Required</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.email || (contact.email.length > 0)}">Email</p>
<input type="text" class="form-control"
 v-model="contact.email"
  @focus="() => {
    activated.email = true;
    focused.email = true;
  }"
  @blur="() => {
    focused.email = false;
  }"
>
 <p v-if="activated.email && contact.email.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.email && !focused.email && ((contact.email.length > 0) && !/\S+@\S+\.\S+/.test(contact.email))" 
  class="form-info red text-end">Please use correct email format</p>
</div>
</div>

<div class="col-3">
<div class="form-item">
<p class="form-label" :class="{active: focused.phoneNumber || (contact.phoneNumber.length > 0)}">Phone Number</p>
<input type="text" class="form-control"
 v-model="contact.phoneNumber"
  @focus="() => {
    activated.phoneNumber = true;
    focused.phoneNumber = true;
  }"
  @blur="() => {
    focused.phoneNumber = false;
    phoneNumberFormat('phoneNumber')
  }"
>
 <!-- <p v-if="activated.phoneNumber && contact.phoneNumber.length === 0" class="form-info red text-end">* Required</p> -->
 <p v-if="activated.phoneNumber && !focused.phoneNumber && (((completedFields.phoneNumber && (contact.phoneNumber.length !== 10) || (contact.phoneNumber.length > 10))) && !/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/.test(contact.phoneNumber))" class="form-info red text-end">Please use 10 Digit for phone</p>
</div>
</div>

<div class="col-12">
<div class="form-item">
<p class="form-label" :class="{active: focused.comments || (contact.comments.length > 0)}">Comments</p>
<textarea class="form-control"
 v-model="contact.comments"
  @focus="() => {
    activated.comments = true;
    focused.comments = true;
  }"
  @blur="() => {
    focused.comments = false;
  }"
></textarea>
 <p v-if="activated.comments && contact.comments.length === 0" class="form-info red text-end">* Required</p>
 <p v-if="activated.comments && contact.comments.length > 500" class="form-info red text-end">Please use maximum 500 characters</p>
</div>
</div>

</div>
</div>
</div>
</div>

<div class="content-block">
  <FileAttachments :key="`form-contact-module`" v-bind:section="'contact'" />
</div>

      <div class="content-block">
      <div class="buttons">
      <div class="row">
      <div class="col-auto"><button type="submit" class="btn green" @click="submitForm">Submit</button></div>
      <div class="col-auto"><button type="submit" class="btn light-blue-20 btn-o" @click="cancelChanges">Cancel</button></div>
      </div>
      </div>
      </div>

      </div>
    </div>
  </section>

  <div v-if="popUps.messageIsSent.active">
    <UserNotification
      v-bind:messageText="popUps.messageIsSent.message"
    />
  </div>

  <div v-if="popUps.pendingMessage.active">
    <UserNotification
      v-bind:status="popUps.pendingMessage.active ? 'loading' : ''"
    />
  </div>
</template>

<script>
import { reactive, defineComponent, onMounted, provide, ref, watch } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import DropDownList from '@/components/Shared/DropDownList.vue';
import UserNotification from '@/components/Shared/UserNotification.vue';
import FileAttachments from '@/components/Shared/FileAttachments.vue'

export default defineComponent({
  name: "ContactModule",
  components: { DropDownList, UserNotification, FileAttachments },
  setup() {
    const store = useStore();

    const contact = reactive({
      requestType: '',
      accountId: '',
      farmName: '',
      name: '',
      email: '',
      phoneNumber: '',
      comments: ''
    });

    const clientData = reactive({
      date: '',
      time: '',
      pageURL: '',
      userAgent: '',
      remoteIp: ''
    });

    const defaultContact = {
      ...contact
    };

    const activated = reactive({
      requestType: false,
      accountId: false,
      farmName: false,
      name: false,
      email: false,
      phoneNumber: false,
      comments: false,
    });

    const focused = reactive({
      requestType: false,
      accountId: false,
      farmName: false,
      name: false,
      email: false,
      phoneNumber: false,
      comments: false,
    });

    const completedFields = reactive({
      phoneNumber: false
    });

    const popUps = reactive({
      messageIsSent: {
        message: 'Thank you for submitting your query. We will review it and get back to you soon!',
        active: false
      },
      pendingMessage: {
        active: false
      }
    })

    const manualRequestType = ref(false);

    const requestTypeField = ref(null);

    const accountIdTooltip = ref(false);

    const otherSelected = ref(false);

    const phoneNumberFormat = (phoneType) => {
      if (contact[phoneType].length > 0) {
        if (contact[phoneType][0] !== "(") {
          const arrStr = contact[phoneType].split("");
          contact[phoneType] = `(${arrStr.filter((num, indx) => {
            if (indx < 3) return num;
          }).join("")}) ${arrStr.filter((num, indx) => {
            if ((indx >= 3) && (indx < 6)) return num;
          }).join("")}-${arrStr.filter((num, indx) => {
            if (indx >= 6) return num;
          }).join("")}`;
          completedFields[phoneType] = true;
        }
      }
    };

    const switchAccountIdTooltip = (toggle) => {
      accountIdTooltip.value = toggle;
    }

    const submitForm = () => {

      if (!contact.requestType || !contact.accountId || 
      !contact.farmName || !contact.name || !contact.email || !contact.comments) {
        if (!contact.requestType) activated.requestType = true;
        if (!contact.accountId) activated.accountId = true;
        if (!contact.farmName) activated.farmName = true;
        if (!contact.name) activated.name = true;
        if (!contact.email) activated.email = true;
        if (!contact.comments) activated.comments = true;

        return;
      }

      clientData.date = moment().format('MMMM DD, YYYY');
      clientData.time = moment().format('hh:mm a');

      const formData = {
        ...contact,
        ...clientData
      }

      formData.id = uuid();

      popUps.pendingMessage.active = true;
      store.dispatch("sendContactForm", {...formData});
    }

    const cancelChanges = () => {
      for (let prop in contact) {
        contact[prop] = defaultContact[prop];
      }
      for (let prop in activated) {
        activated[prop] = false;
        focused[prop] = false;
      }
    };

    provide('contactDetails', {
      contact,
      defaultContact
    });

    provide('otherHandling', {
      otherSelected
    });

    watch(() => (contact.requestType), (newVal) => {
      if (newVal === 'Other') {
        contact.requestType = '';
        manualRequestType.value = true;
        otherSelected.value = true;
        setTimeout(() => {
          requestTypeField.value.focus();
        }, 10)
      }
    });

    watch(() => (store.state.contactMessageIsSent), (newVal) => {
      if (newVal) {
        popUps.pendingMessage.active = false;
        popUps.messageIsSent.active = true;

        cancelChanges();

        setTimeout(() => {
          popUps.messageIsSent.active = false;
        }, 5100);
      }
    })

    onMounted(() => {
      async function getClientIp() {
        const { ip } = await fetch('https://api.ipify.org?format=json', { method: 'GET' })
          .then(res => res.json())
          .catch(error => console.error(error));
    
        clientData.remoteIp = ip || "0.0.0.0";
      }

      getClientIp();
      
      // get data 
      clientData.pageURL = document.location.href;
      clientData.userAgent = window.navigator.userAgent;
    });

    return {
      contact,
      activated,
      focused,
      submitForm,
      cancelChanges,
      phoneNumberFormat,
      completedFields,
      manualRequestType,
      requestTypeField,
      accountIdTooltip,
      switchAccountIdTooltip,
      popUps
    }
  }
})
</script>

<style lang="scss" scoped>
.info-icon {
  position: relative;
}
.account-tooltip {
  position: absolute;
  z-index: 99999;
  bottom: 40px;
  left: -220px;
  width: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  // border-radius: 11px;
  // color: #fff;
  color: hsl(var(--white));
  // background-color: #3C3838;
  // background: hsl(207, 11%, 41%);
  background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
  font-family: 'Inter', sans-serif;
  // font-weight: 400;
  font-weight: var(--fw-semibold);
  // padding: 5px;
  padding: 0.5rem;
  font-size: 1.4rem;
  font-family: 'Gilroy';
  &::after {
    content: " ";
    position: absolute;
    display: block;
    bottom: -7px;
    // border-color: #3C3838;
    // border-left: 8px solid hsl(207, 11%, 41%);
    // border-bottom: 6px solid hsl(207, 11%, 41%);
    border-left: 8px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    border-bottom: 6px solid hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    border-right: 6px solid transparent;
    border-top: 8px solid transparent;
    -webkit-transform: translateX(190px) rotate(135deg);
    transform: translateX(190px) rotate(135deg);
    box-shadow: -6px 4px 5px rgb(57 73 76 / 15%);
    left: 30px;
    // background: #3C3838;
    // background: hsl(207, 11%, 41%);
    background: hsl(var(--dark-gray-h),var(--dark-gray-s),calc(var(--dark-gray-l) + 10%));
    z-index: -1;
  }
}
</style>