<template>
  <div class="section" ref="section">
    <div class="border-box-header" ref="boxHeader" @click="toggleSection">
    <h5>User List</h5>
    <div class="info-icons">
    <div class="info-icon lg top-icon tooltip"
      @click="addAnotherUser"
      @mouseover="switchHelperTooltip(true)"
      @mouseout="switchHelperTooltip(false)"
      data-tooltip="Add User"
    >
      <!-- <img src="@/assets/images/user-plus.svg" alt=""> -->
      <img src="@/assets/images/updated/064-add-user.png" alt="">
      <!-- <div class="helper-tooltip" v-if="helperTooltip">Add User</div> -->
    </div>
    </div>
    <img src="@/assets/images/angle-down.png" alt="" class="angle">
    </div>

    <div class="border-box-content">

    <div class="content-row overflow-x-scroll">
    <table>

    <thead>
    <tr>
      <th><p class="form-label-static form-sort-label">
      <span>First Name</span>
      <span @click="() => sortTable('firstName')">
        <button :class="{active: sortOrder.firstName === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.firstName === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>Last Name</span>
      <span @click="() => sortTable('lastName')">
        <button :class="{active: sortOrder.lastName === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.lastName === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>Role</span>
      <span @click="() => sortTable('userRole')">
        <button :class="{active: sortOrder.userRole === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.userRole === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>Mobile</span>
      <span @click="() => sortTable('mobilePhone')">
        <button :class="{active: sortOrder.mobilePhone === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.mobilePhone === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>City</span>
      <span @click="() => sortTable('city')">
        <button :class="{active: sortOrder.city === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.city === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>

      <th><p class="form-label-static form-sort-label">
      <span>State</span>
      <span @click="() => sortTable('state')">
        <button :class="{active: sortOrder.state === -1}"><img src="@/assets/images/caret-up.png" alt=""></button>
        <button :class="{active: sortOrder.state === 1}"><img src="@/assets/images/caret-down.png" alt=""></button>
      </span></p></th>
      <th class="w-75">&nbsp;</th>
    </tr>
    </thead>

    <tbody>

      <template
        v-for="(row, rowIndex) in rows"
        v-bind:key="'admin-user-list-row' + row.mainDetails?.id"
      >
        <tr
          v-if="((rowIndex + 1) <= (maxPage.value * currentPage.value)) && ((rowIndex + 1) > (maxPage.value * (currentPage.value - 1)))"
          :class="{'light-gray-bg': ((row.mainDetails.id === mainDetails.id))}"
          @click="(ev) => selectByRow(ev, {...row})"
        >
        <!-- && !clickDetails.selectedUserEntity  || (row.mainDetails.id === clickDetails.selectedUserEntity) -->
        <!--           :style="{backgroundColor: row.taskIsSelected ? 'rgba(0,0,0,0.05)' : ''}" -->
        <td class="text-center">{{row.mainDetails?.firstName}}</td>
        <td>{{row.mainDetails?.lastName}}</td>
        <td>{{row.mainDetails?.userRole && `${row.mainDetails?.userRole[0].toUpperCase()}${row.mainDetails?.userRole.slice(1)}`}}</td>
        <td>{{row.mainDetails?.mobilePhone}}</td>
        <td>{{ row.mainDetails?.city }}</td>
        <td>{{ row.mainDetails?.state }}</td>
        <td class="w-75">
        <div class="table-links">
        <a href="javascript:void();" @click="() => {
          selectMainDetails({...row.mainDetails}, 'edit');
          selectSubscription({...row.subscription}, 'represent');
          scrollToMainForm();
        }">
          <img src="@/assets/images/edit-icon-2.svg" alt="">
        </a>
        <a href="javascript:void();" @click="removeUser(row._id || row.mainDetails?.id)">
          <img src="@/assets/images/trash-2.svg" alt="">
        </a>
        </div>
        </td>
        </tr>
      </template>

    </tbody>

    </table>
    </div>

    <div class="content-row">
    <div class="pagination">
    <div>
    <!-- <p>Records per page:</p> -->
    <!-- <select v-model="maxPage">
    <option>1</option>
    <option>2</option>
    <option>3</option>
    <option>4</option>
    <option>5</option>
    <option>6</option>
    <option>7</option>
    <option>8</option>
    <option>9</option>
    <option>10</option>
    </select> -->
    </div>
    <!-- <p>1-1 to 10</p> -->
    <p class="list-pagination">
      Page: 
      <!-- <select v-model="currentPage" class="pagination-selector">
      <option 
        v-for="(_, page) in Array.from({length: Math.ceil(rows.length / maxPage)})"
        v-bind:key="'task-list-pagination-' + page"
      >{{ Number.parseInt(page) + 1 }}</option>
      </select> -->
      <DropDownList 
        :mainObject="'currentPage'"
        :mainProperty="'value'"
        :injectionName="'adminUsersListDetails'"
        :optionKey="'admin-users-list-pagination'"
        :optionsList="[]"
        :optionProperty="null"
        :optionPropertyTwo="null"
        :defaultSelected="currentPage.value"
        :numberList="true"
      />
    </p>
    </div>
    </div>

    </div>

    <div class="removal-popup" v-if="removedUserId">

    <div class="modal modal-md">
    <span class="modal-close" @click="() => approveRemoval(false)"><img src="@/assets/images/close-green-xs.png" alt=""></span>
    <div class="modal-block text-center">
    <p class="fw-bold">Are you sure you want to delete the subscriber?</p>
    </div>
    <div class="modal-block text-center">
    <div class="buttons justify-content-center">
        <button
          @click="() => approveRemoval(true)"
          class="approve-button btn green" 
        >Yes</button>
        <button
          @click="() => approveRemoval(false)" 
          class="cancel-removal-button btn green btn-o" 
        >No</button>
    </div>
    </div>
    </div>

    </div>

    <div v-if="userIsRemoved.active">
      <UserNotification
        v-bind:hideDetails="true"
        v-bind:messageText="userIsRemoved.message"
      />
    </div>
  </div>
</template>

<script>
import DropDownList from '@/components/Shared/DropDownList.vue';
import UserNotification from '@/components/Shared/UserNotification.vue';
import { 
  defineComponent, ref, onMounted, 
  watch, computed, inject, reactive, provide 
} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
    name: 'AdminUsersList',
    setup() {
        const store = useStore();
        const router = useRouter();
        const section = ref(null);
        const boxHeader = ref(null);
        const sectionHeight = ref(0);
        const sectionExpanded = ref(true);
        const users = computed(() => store.state.users);
        const removedUserId = ref(null);
        const userIsRemoved = reactive({
          active: false,
          message: 'Your farm account has been deleted'
        });

        const rows = ref([]);
        const maxPage = reactive({
            value: 20
        });
        const options = reactive({
            value: []
        });
        const currentPage = reactive({
            value: 1
        });
        const sortOrder = reactive({
            firstName: 0,
            lastName: 0,
            userRole: 0,
            mobilePhone: 0,
            city: 0,
            state: 0
        });
        const { mainDetails, userRole, selectMainDetails, 
        // removeUser,
        addNewUser, createNewUser, scrollToMainForm
        // selectedEquipment,  
         } = inject('mainDetails');
        const { selectSubscription, defaultSubscription } = inject('subscription');
        const helperTooltip = ref(false);
        const switchHelperTooltip = (toggle) => {
            helperTooltip.value = toggle;
        };
        const toggleSection = (ev) => {
            if (ev.target.className !== 'border-box-header')
                return;
            sectionExpanded.value = !sectionExpanded.value;
        };
        const sortTable = (sortProp) => {
            console.log(sortOrder[sortProp]);
            if (sortOrder[sortProp] === 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return 1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return -1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = 1;
            }
            else if (sortOrder[sortProp] > 0) {
                rows.value.sort((a, b) => {
                    if (a[sortProp] < b[sortProp]) {
                        return -1;
                    }
                    if (a[sortProp] > b[sortProp]) {
                        return 1;
                    }
                    return 0;
                });
                sortOrder[sortProp] = -1;
            }
            else if (sortOrder[sortProp] < 0) {
                rows.value = [...users.value];
                sortOrder[sortProp] = 0;
            }
        };
        const addAnotherUser = () => {
            createNewUser();
            // router.push({ name: 'labor', params: { pagename: 'secondary-user' } })
        };
        const removeUser = (userId) => {
            removedUserId.value = userId;
        };
        const approveRemoval = (isApproved) => {
            if (isApproved) {
                if ((removedUserId.value === 'primary-user') || (removedUserId.value === store.state.authenticated._id)) {
                    userIsRemoved.active = true;
                    removedUserId.value = null;
                    setTimeout(() => {
                        store.dispatch('archivePrimaryUser');
                        router.push({
                            name: 'page',
                            params: {
                                pagename: 'dashboard',
                                source: 'admin-subscription'
                            }
                        });
                    }, 5000);
                }
                else {
                    store.dispatch('archiveSecondaryUser', removedUserId.value);
                    selectSubscription({
                        ...store.state.primaryUser.subscription || defaultSubscription,
                        plans: {
                            ...store.state.primaryUser.subscription?.plans || defaultSubscription.plans
                        }
                    }, 'represent');
                    setTimeout(() => {
                        removedUserId.value = '';
                    }, 500);
                }
            }
            else {
                removedUserId.value = '';
            }
        };

        // const clickDetails = reactive({
        //   // result: [],
        //   delay: 700,
        //   clicks: 0,
        //   timer: null,
        //   selectedUserEntity: null
        // })

        const selectByRow = (ev, row) => {
          if ((ev.target.tagName === 'SELECT') || (ev.target.tagName === 'IMG')) {
            return;
          } else {

            // clickDetails.clicks++;
            // if (clickDetails.clicks === 1) {
            //   clickDetails.timer = setTimeout( () => {
            //     clickDetails.selectedUserEntity = row.mainDetails.id
            //     clickDetails.clicks = 0
            //   }, clickDetails.delay);
            // } else {
            //   clearTimeout(clickDetails.timer);  
            //   clickDetails.clicks = 0;
            //   selectMainDetails({...row.mainDetails}, 'represent');
            //   selectSubscription({...row.subscription}, 'represent');
            //   scrollToMainForm();
            // }
            selectMainDetails({...row.mainDetails}, 'represent');
            selectSubscription({...row.subscription}, 'represent');
            scrollToMainForm();
          }
        }

        watch(sectionExpanded, (newVal) => {
            section.value.style.height = newVal ?
                `${sectionHeight.value}px` :
                `${boxHeader.value.offsetHeight}px`;
section.value.style.overflow = newVal ? 'visible' : 'hidden'
        });
        watch(() => ([...store.state.users]), () => {
            console.log(users.value, 'users');
            section.value.style.height = 'auto';
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
            });

            const usersWithPrimary = [...users.value].map((user) => {
              if (user.mainDetails.id === store.state.primaryUser.id || store.state.primaryUser.mainDetails.id) {
                return {
                  ...user,
                  mainDetails: {
                    ...user.mainDetails,
                    id: 'primary'
                  }
                }
              } else {
                return {
                  ...user
                }
              }
            });

            rows.value = [
                // {
                //     ...store.state.primaryUser,
                //     mainDetails: {
                //         ...store.state.primaryUser.mainDetails,
                //         id: 'primary'
                //     }
                // },
                // ...users.value
                ...usersWithPrimary
            ];
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        watch(() => ([...rows.value]), (newVal, oldVal) => {
          if (newVal.length < oldVal.length) {

            const selectedIsRemovedIndex = [...oldVal].findIndex(row => row.mainDetails.id === mainDetails.id);

            if (selectedIsRemovedIndex) {
              let nextSelectedEntry = newVal[selectedIsRemovedIndex + 1] || newVal[newVal.length - 1];

              if (nextSelectedEntry) {

                if (nextSelectedEntry.mainDetails.id === mainDetails.id) {
                  nextSelectedEntry = newVal[newVal.length];
                }

                selectMainDetails({...nextSelectedEntry.mainDetails}, 'represent');
                selectSubscription({...nextSelectedEntry.subscription}, 'represent');
                scrollToMainForm();
              }
            }

            /*

            const selectedIsRemoved = [...newVal].find(row => row.mainDetails.id === mainDetails.id);

            if (!selectedIsRemoved) {

              const nextSelectedIndex = oldVal.find(row => row.mainDetails.id === mainDetails.id);

              const nextSelectedEntry = newVal[nextSelectedIndex];

              if (nextSelectedEntry) {

                selectMainDetails({...nextSelectedEntry.mainDetails}, 'represent');
                selectSubscription({...nextSelectedEntry.subscription}, 'represent');
                scrollToMainForm();
              }
            }

            */

            }
        });
        watch(maxPage, () => {
          section.value.style.height = 'auto';
          setTimeout(() => {
            sectionHeight.value = section.value.offsetHeight;
          })
          options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
        });
        onMounted(() => {
            (users.value.length > 0) && selectMainDetails({ ...users.value[0] }, 'represent');
            setTimeout(() => {
                sectionHeight.value = section.value.offsetHeight;
                section.value.style.height = `${section.value.offsetHeight}px`;
            });
            // store.dispatch('getVendors')
            const usersWithPrimary = [...users.value].map((user) => {
              if (user.mainDetails.id === store.state.primaryUser.id || store.state.primaryUser.mainDetails.id) {
                return {
                  ...user,
                  mainDetails: {
                    ...user.mainDetails,
                    id: 'primary'
                  }
                }
              } else {
                return {
                  ...user
                }
              }
            });
            console.log(usersWithPrimary, 'usersWithPrimary');
            rows.value = [
                // {
                //     ...store.state.primaryUser,
                //     mainDetails: {
                //         ...store.state.primaryUser.mainDetails,
                //         id: 'primary'
                //     }
                // },
                // ...users.value
                ...usersWithPrimary
            ];
            store.dispatch('getUsersList');
            options.value = Array.from({ length: Math.ceil(rows.value.length / maxPage.value) }).map((_, indx) => indx + 1);
            // selectMainDetails({...rows.value[0].mainDetails}, 'represent');
            // selectSubscription({...rows.value[0].subscription}, 'represent');
        });
        provide('adminUsersListDetails', {
          maxPage,
          currentPage,
          options
        });
        return {
            // selectedEquipment,
            mainDetails,
            userRole,
            selectMainDetails,
            removeUser,
            addNewUser,
            addAnotherUser,
            selectSubscription,
            rows,
            maxPage,
            helperTooltip,
            switchHelperTooltip,
            section,
            boxHeader,
            toggleSection,
            sortOrder,
            sortTable,
            approveRemoval,
            removedUserId,
            userIsRemoved,
            scrollToMainForm,
            currentPage,
            selectByRow,
            // clickDetails
        };
    },
    components: { UserNotification, DropDownList }
})
</script>

<style lang="scss" scoped>
.section {
  .top-icon {
    position: relative;
  }
}
</style>